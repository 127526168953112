import {
    ad_attibuted_sales__sum,
    ad_contribution,
    ad_contribution_shipped,
    ad_impressions__sum,
    ad_spend__sum,
    organic_sales__sum,
    organic_shipped_sales__sum,
    roas,
    sales__sum,
    shipped_sales__sum,
    tacos,
    tacos_shipped,
    troas,
    troas_shipped,
} from 'configuration/fieldCreators/metrics/orgFacts'
import {
    sessions__sum,
    total_aov_order_items,
    total_aov_shipped_order_items,
    total_average_ordered_unit_value,
    total_average_ordered_unit_value_shipped,
    total_cpa_order_items,
    total_cpa_units,
    total_cvr_order_items,
    total_cvr_units,
    total_order_items__sum,
    units_ordered__sum,
} from 'configuration/fieldCreators/metrics/sellerCentral'
import {
    accountType,
    country,
    organizationId,
} from 'configuration/fieldCreators/orgFacts'

import { makeMapping } from './helpers'

const ORG_FACT_METRICS_COLUMNS = [
    ad_spend__sum({ isVisible: true }),
    ad_attibuted_sales__sum({ isVisible: true }),
    ad_impressions__sum({ isVisible: true }),
    sales__sum({ isVisible: true }),
    shipped_sales__sum({ isVisible: true }),
    tacos({ isVisible: true }),
    tacos_shipped({ isVisible: true }),
    troas({ isVisible: true }),
    troas_shipped({ isVisible: true }),
    roas({ isVisible: true }),
    organic_sales__sum({ isVisible: true }),
    organic_shipped_sales__sum({ isVisible: true }),
    ad_contribution({ isVisible: true }),
    ad_contribution_shipped({ isVisible: true }),
    sessions__sum({ isVisible: true }),
    total_order_items__sum({
        isVisible: true,
        releaseDate: '2023-11-27',
    }),
    units_ordered__sum({
        isVisible: true,
        releaseDate: '2023-11-27',
    }),
    total_cpa_order_items({
        isVisible: true,
        releaseDate: '2023-11-27',
    }),
    total_cpa_units({ isVisible: true, releaseDate: '2023-11-27' }),
    total_aov_order_items({
        isVisible: true,
        releaseDate: '2023-11-27',
    }),
    total_average_ordered_unit_value({
        isVisible: true,
        releaseDate: '2023-11-27',
    }),
    total_aov_shipped_order_items({
        isVisible: true,
        releaseDate: '2023-11-27',
    }),
    total_average_ordered_unit_value_shipped({
        isVisible: true,
        releaseDate: '2023-11-27',
    }),
    total_cvr_order_items({
        isVisible: true,
        releaseDate: '2023-11-27',
    }),
    total_cvr_units({ isVisible: true, releaseDate: '2023-11-27' }),
]

export const ORG_FACT_METRICS_CONFIG = makeMapping(ORG_FACT_METRICS_COLUMNS)

export const SPECIAL_ORG_FACT_GROUP_BY_COLUMNS = [
    organizationId({
        dataIndex: ['organization_id'],
    }),
    country({ dataIndex: ['country'] }),
    accountType({
        dataIndex: ['account_type'],
    }),
]
