import {
    adGroupName,
    adGroupState,
} from 'configuration/fieldCreators/walmartAdGroup'
import { Field, WalmartAdGroupGroupBy } from 'types'

import { WM_ADVERTISING_METRICS_TABLE_COLUMNS } from '../dataSources/walmartAdvertising'

export const walmartCampaignAdGroupsTabTableColumnsConfig: Field<WalmartAdGroupGroupBy>[] =
    [
        adGroupName({
            fixed: true,
            dataIndex: ['ad_group', 'name'],
        }),
        adGroupState({
            dataIndex: ['ad_group', 'state'],
        }),
        ...(WM_ADVERTISING_METRICS_TABLE_COLUMNS as Field<WalmartAdGroupGroupBy>[]),
    ]
