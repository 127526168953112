import { CSSProperties, ReactElement, useCallback } from 'react'

import { Select, Spin } from 'antd'

import {
    PREFETCH_RESULTS_PER_QUERY,
    SEARCH_RESULTS_PER_QUERY,
} from 'configuration/typeahead'
import { useTypeahead } from 'hooks'
import { getUserOrganizationGroups } from 'services/cerebroApi/orgScope/resourceApi'
import { OrganizationGroup } from 'types'

interface Props {
    value?: string
    onChange: (value: string) => void
    defaultOptions: OrganizationGroup[]
    className?: string
    style?: CSSProperties
    prefetchRecordLimit?: number
}

const serializeOrgGroup = ({
    organizationId,
    organizationGroupId,
}: {
    organizationId: string
    organizationGroupId: string
}): string => JSON.stringify([organizationId, organizationGroupId])

const generateLabel = ({
    organizationName,
    organizationId,
    organizationGroupName,
    organizationAgencyName,
}: {
    organizationName?: string
    organizationId?: string
    organizationGroupName?: string
    organizationAgencyName?: string
}): string => {
    if (!organizationName || !organizationGroupName) {
        return 'Loading...'
    }
    const organizationAgency: string = organizationAgencyName
        ? ` | ${organizationAgencyName}`
        : ''
    return `(${organizationId}) ${organizationName}${organizationAgency} | ${organizationGroupName}`
}

interface OrgGroupOption {
    key: string
    value: string
    title: string
}

const sortOrgGroupOptions = (
    optionA: OrgGroupOption,
    optionB: OrgGroupOption
): number => {
    if (!optionA?.title) {
        return -1
    }
    if (!optionB?.title) {
        return 1
    }
    const aSplit = optionA.title.split(') ')
    const bSplit = optionB.title.split(') ')
    const idA = aSplit[0].replace('(', '')
    const idB = bSplit[0].replace('(', '')
    const titleA = aSplit[1]
    const titleB = bSplit[1]

    // order by id first, then title
    if (Number(idA) < Number(idB)) {
        return -1
    }
    if (Number(idA) > Number(idB)) {
        return 1
    }
    if (titleA < titleB) {
        return -1
    }
    if (titleA > titleB) {
        return 1
    }
    return 0
}

const OrganizationGroupSearchSelect = ({
    value,
    onChange,
    defaultOptions = [],
    className,
    style = { width: '100%' },
    prefetchRecordLimit = PREFETCH_RESULTS_PER_QUERY,
}: Props): ReactElement => {
    const [options, loading, onSearch, resetLoadingState] =
        useTypeahead<OrganizationGroup>({
            apiSearchFunc: (query) => {
                return getUserOrganizationGroups({
                    limit: SEARCH_RESULTS_PER_QUERY,
                    name_or_org_id__matches: query,
                })
            },
            optionFormatter: ({
                id: organizationGroupId,
                name: organizationGroupName,
                organization: { id: organizationId, name: organizationName },
                agency,
            }) => ({
                value: serializeOrgGroup({
                    organizationGroupId,
                    organizationId,
                }),
                label: generateLabel({
                    organizationName,
                    organizationId,
                    organizationGroupName,
                    organizationAgencyName: agency?.name || undefined,
                }),
            }),
            defaultOptions,
            prefetchApiFunc: useCallback(
                () =>
                    getUserOrganizationGroups({
                        limit: prefetchRecordLimit,
                        ordering: '-created_date',
                    }),
                [prefetchRecordLimit]
            ),
        })

    return (
        <Select
            className={className}
            style={style}
            filterOption
            optionFilterProp="title"
            optionLabelProp="title"
            placeholder="Select an Organization Group"
            loading={loading}
            notFoundContent={loading ? <Spin size="small" /> : null}
            onChange={onChange}
            onSearch={onSearch}
            onSelect={resetLoadingState}
            filterSort={sortOrgGroupOptions}
            showSearch
            value={value}
        >
            {options.map((option, idx) => (
                <Select.Option
                    key={idx}
                    value={option.value}
                    title={option.label}
                >
                    {option.label}
                </Select.Option>
            ))}
        </Select>
    )
}

export default OrganizationGroupSearchSelect
