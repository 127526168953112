import { ReactElement } from 'react'

import { AxiosResponse } from 'axios'
import { Field } from 'formik'
import get from 'lodash/get'
import noop from 'lodash/noop'
import { object, string } from 'yup'

import { FormikDatePicker } from 'components/formik'
import { UNDEFINED_VALUE, DAY_FORMAT } from 'const/formatting'
import { formatDate } from 'helpers/dates'
import { isUnset } from 'helpers/utilities'
import { useCerebroApiRequest } from 'hooks'
import message from 'utilities/message'
import moment from 'utilities/moment'

import { SingleValueField } from '../SingleValueField'

interface Props<RecordType> {
    disabled?: boolean
    fieldName?: string
    fieldPath: string[]
    placeholder: string
    readOnly: boolean
    record: RecordType
    serializeFieldValues?: (value: {
        [fieldName: string]: moment.Moment | null
    }) => object
    updateRequestApi: (...args: any) => Promise<AxiosResponse<any>>
    updateRequestData: any
    updateRequestSuccessMesg: string
    required?: boolean
    updateRequestCallback?: () => void
}

function DateField<RecordType>({
    disabled = false,
    fieldName = 'date',
    fieldPath = [],
    placeholder,
    readOnly = false,
    required = true,
    record,
    serializeFieldValues = (values) => values,
    updateRequestApi,
    updateRequestData,
    updateRequestSuccessMesg,
    updateRequestCallback = noop,
}: Props<RecordType>): ReactElement {
    const makeCerebroApiRequest = useCerebroApiRequest()
    const validationSchema = object().shape({
        [fieldName]: required ? string().required() : string().nullable(),
    })
    const initialValues = {
        [fieldName]: get(record, fieldPath)
            ? moment(get(record, fieldPath))
            : null,
    }

    return (
        <SingleValueField
            disabled={disabled}
            fieldName={fieldName}
            formatFieldValue={(values) =>
                isUnset(values[fieldName])
                    ? UNDEFINED_VALUE
                    : formatDate(values[fieldName])
            }
            initialValues={initialValues}
            onSave={(value) =>
                makeCerebroApiRequest({
                    request: updateRequestApi(
                        updateRequestData,
                        serializeFieldValues(value)
                    ),
                    onRequestSuccess: () => {
                        message.success(updateRequestSuccessMesg)
                    },
                })
            }
            readOnly={readOnly}
            validationSchema={validationSchema}
            updateRequestCallback={updateRequestCallback}
        >
            {({ isSubmitting }) => (
                <Field
                    component={FormikDatePicker}
                    disabled={isSubmitting}
                    format={DAY_FORMAT}
                    name={fieldName}
                    placeholder={placeholder}
                />
            )}
        </SingleValueField>
    )
}

export default DateField
