import {
    SPIntegrationsCount,
    adAcountsCount,
    groupCount,
    memberCount,
    organizationName,
    ownerFullName,
    sovCapCount,
    sovEnabledCount,
    walmartAdIntegrationCount,
    walmartMarketplaceIntegrationCount,
} from 'configuration/fieldCreators/organization'
import { ChildOrganization, Field } from 'types'

export const childOrganizationsTableColumnsConfig: Field<ChildOrganization>[] =
    [
        organizationName(),
        ownerFullName(),
        groupCount(),
        memberCount(),
        sovCapCount(),
        sovEnabledCount(),
        adAcountsCount(),
        SPIntegrationsCount(),
        walmartMarketplaceIntegrationCount(),
        walmartAdIntegrationCount(),
    ]
