import produce from 'immer'
import { call, select, put } from 'redux-saga/effects'

import {
    makeFetchTableSuccess,
    makeFetchTableRequest,
    makeDownloadTableSuccess,
    makeDownloadTableFailure,
} from 'actions/ui/shared'
import { childOrganizationsTableColumnsConfig } from 'configuration/tables'
import { ORGANIZATION_PAGE } from 'const/pages'
import { downloadCsv } from 'helpers/downloads'
import { formatPagination, formatSorter, formatColumns } from 'helpers/params'
import { cerebroApiSaga } from 'sagas/common'
import uiSagaRegistry from 'sagas/ui/registry'
import { selectDomainValue as selectAuthDomainValue } from 'selectors/auth'
import { selectTableSettings, selectVisibleColumnsOfTable } from 'selectors/ui'
import {
    getOrganizationChildren,
    getOrganizationChildrenSyncExport,
} from 'services/cerebroApi/orgScope/resourceApi'

export const CHILD_ORGANIZATIONS_TABLE_KEY = 'childOrgs'
export const CHILD_ORGANIZATIONS_TAB_PATH = [
    ORGANIZATION_PAGE,
    CHILD_ORGANIZATIONS_TABLE_KEY,
]
export const CHILD_ORGANIZATIONS_TABLE_PATH = [
    ...CHILD_ORGANIZATIONS_TAB_PATH,
    'table',
]

function* fetchTableSaga() {
    const organizationId = yield select(selectAuthDomainValue, 'organizationId')
    const { pagination, sorter } = yield select(
        selectTableSettings,
        CHILD_ORGANIZATIONS_TABLE_PATH
    )
    const params = {
        ...formatPagination(pagination),
        ...formatSorter(sorter),
    }

    yield call(
        cerebroApiSaga,
        makeFetchTableSuccess(CHILD_ORGANIZATIONS_TABLE_PATH),
        getOrganizationChildren,
        organizationId,
        params
    )
}

function* fetchTabDataSaga() {
    yield put(makeFetchTableRequest(CHILD_ORGANIZATIONS_TABLE_PATH)())
}

function* downloadTableSaga() {
    const organizationId = yield select(selectAuthDomainValue, 'organizationId')
    const { sorter } = yield select(
        selectTableSettings,
        CHILD_ORGANIZATIONS_TABLE_PATH
    )
    const columns = yield select(
        selectVisibleColumnsOfTable,
        CHILD_ORGANIZATIONS_TABLE_PATH,
        childOrganizationsTableColumnsConfig
    )

    const params = {
        ...formatSorter(sorter),
        ...formatColumns(
            produce(columns, (draft) => {
                // owner is a complex object, so we pick the column we want in the csv
                draft.forEach((column, index, array) => {
                    if (column.id === 'owner') {
                        array[index].dataIndex = 'owner__email'
                    }
                })
            })
        ),
    }

    const response = yield call(
        cerebroApiSaga,
        null,
        getOrganizationChildrenSyncExport,
        organizationId,
        params
    )

    if (response?.status === 200) {
        downloadCsv(response, `organization-${organizationId}-client-list`)
        yield put(makeDownloadTableSuccess(CHILD_ORGANIZATIONS_TABLE_PATH)())
    } else {
        yield put(makeDownloadTableFailure(CHILD_ORGANIZATIONS_TABLE_PATH)())
    }
}

// Register Sagas
uiSagaRegistry.registerSagas(CHILD_ORGANIZATIONS_TAB_PATH, {
    fetchTabDataSaga,
})

uiSagaRegistry.registerSagas(CHILD_ORGANIZATIONS_TABLE_PATH, {
    fetchTableSaga,
    downloadTableSaga,
})
