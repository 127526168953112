import flow from 'lodash/fp/flow'
import set from 'lodash/fp/set'
import update from 'lodash/fp/update'
import { Action, combineActions, handleActions } from 'redux-actions'

import {
    changeCurrencyCode,
    disablePageContent,
    dismissUserSurveySuccess,
    downloadBulkUpdate,
    downloadReport,
    fetchAutomationCapabilitiesSuccess,
    fetchCurrencySettingsSuccess,
    fetchCustomEventsSuccess,
    fetchHiddenAutomationCapabilities,
    fetchHomeDashboardIdSuccess,
    fetchOrganizationLabelsSuccess,
    fetchReportsForDrawerFailure,
    fetchReportsForDrawerRequest,
    fetchReportsForDrawerSuccess,
    fetchSovPermissionsSuccess,
    fetchTriggeredAlertsForDrawerFailure,
    fetchTriggeredAlertsForDrawerRequest,
    fetchTriggeredAlertsForDrawerSuccess,
    fetchUpdatesForDrawerFailure,
    fetchUpdatesForDrawerRequest,
    fetchUpdatesForDrawerSuccess,
    fetchUserSurveyLastReadDateSuccess,
    generateBulkUpdateTemplateSuccess,
    initiateUpdateSuccess,
    mountAppFailure,
    mountAppRequest,
    mountAppSuccess,
    setGlobalNotification,
    setHiddenAutomationCapabilities,
    setHomeDashboardId,
    toggleBulkUpdatesAppDrawer,
    toggleReportsAppDrawer,
    toggleSidebarCollapsed,
} from 'actions/ui/app'
import { exportToPdfSuccess } from 'actions/ui/dashboardPage'
import { downloadDataSuccess } from 'actions/ui/shared'
import { exportWidgetToCsvSuccess } from 'actions/ui/shared/dashboard'
import { defaultCurrencyCode } from 'configuration/currency'
import { AGGREGATION, DATES } from 'const/filters'
import { AGG_UNIT, FILTER_SETTINGS, FILTERS } from 'const/reducerKeys'
import { ActionForPathPayload, PaginatedResponse } from 'types'

import brandGroupsReducer from './brandGroups'
import { defaultDatesFilter } from '../defaults'

const defaultState = {
    mounting: true,
    currencyCode: defaultCurrencyCode,
    globalNotification: null,
    organizationLabels: [],
    automationCapabilities: [],
    hiddenAutomationCapabilities: [],
    brandGroups: [],
    customEvents: [],
    newReports: [],
    drawerReports: {
        isVisible: false,
        loading: false,
        error: null,
        reports: [],
    },
    newUpdates: [],
    drawerUpdates: {
        isVisible: false,
        loading: false,
        error: null,
        updates: [],
    },
    homeDashboardId: null,
    triggeredAlerts: {
        loading: false,
        error: null,
        results: [],
    },
    layout: {
        isPageContentDisabled: false,
        isSidebarCollapsed: false,
    },
    userSurveyLastRead: null,
    rulebookDryRun: {
        loading: false,
        error: null,
        results: [],
    },
    [FILTERS]: {
        [AGGREGATION]: AGG_UNIT.DAY,
        [DATES]: defaultDatesFilter,
    },
    [FILTER_SETTINGS]: {
        anchored: [DATES],
        order: [],
        displayState: {},
    },
}

export default handleActions(
    {
        [mountAppRequest.toString()](state) {
            return set(['mounting'], true)(state)
        },

        [mountAppSuccess.toString()](state) {
            return set(['mounting'], false)(state)
        },

        [mountAppFailure.toString()](state) {
            return set(['mounting'], false)(state)
        },

        [disablePageContent.toString()](
            state,
            action: Action<{ isPageContentDisabled: boolean }>
        ) {
            const { isPageContentDisabled } = action.payload
            return set(
                ['layout', 'isPageContentDisabled'],
                isPageContentDisabled,
                state
            )
        },

        [changeCurrencyCode.toString()](state, action) {
            const { currencyCode } = action.payload
            return set(['currencyCode'], currencyCode, state)
        },

        [fetchCurrencySettingsSuccess.toString()](state, action) {
            const currencyCode = action.payload

            return set(
                ['currencyCode'],
                currencyCode || defaultCurrencyCode,
                state
            )
        },

        [setHiddenAutomationCapabilities.toString()](state, action) {
            const automationCapabilities = action.payload

            return set(
                ['hiddenAutomationCapabilities'],
                automationCapabilities,
                state
            )
        },

        [fetchHiddenAutomationCapabilities.toString()](state, action) {
            const automationCapabilities = action.payload

            return set(
                ['hiddenAutomationCapabilities'],
                automationCapabilities,
                state
            )
        },

        // global notification
        [setGlobalNotification.toString()](state, action) {
            return set(['globalNotification'], action.payload, state)
        },

        // organization labels
        [fetchOrganizationLabelsSuccess.toString()](
            state,
            action: Action<PaginatedResponse<any>>
        ) {
            return set(['organizationLabels'], action.payload.results, state)
        },

        // automation capabilities
        [fetchAutomationCapabilitiesSuccess.toString()](
            state,
            action: Action<PaginatedResponse<any>>
        ) {
            return set(
                ['automationCapabilities'],
                action.payload.results,
                state
            )
        },

        // new report downloads
        [exportToPdfSuccess.toString()](state, action) {
            return set(
                'newReports',
                [...state.newReports, action.payload],
                state
            )
        },

        [combineActions(
            downloadDataSuccess,
            exportWidgetToCsvSuccess,
            generateBulkUpdateTemplateSuccess
        ).toString()](state, action: Action<ActionForPathPayload>) {
            return action.payload.data
                ? set('newReports', [...state.newReports, action.payload.data])(
                      state
                  )
                : state
        },

        // remove new reports when downloaded
        [downloadReport.toString()](
            state,
            action: Action<{
                reportId: string
            }>
        ) {
            const { reportId } = action.payload

            return update(['newReports'], (newReports) =>
                newReports.filter((report: any) => report.id !== reportId)
            )(state)
        },

        // drawer reports
        [fetchReportsForDrawerRequest.toString()](state) {
            return set(['drawerReports', 'loading'], true, state)
        },
        [fetchReportsForDrawerSuccess.toString()](
            state,
            action: Action<PaginatedResponse<any>>
        ) {
            const { results: reports } = action.payload
            return flow(
                set(['drawerReports', 'loading'], false),
                set(['drawerReports', 'error'], null),
                set(['drawerReports', 'reports'], reports)
            )(state)
        },
        [fetchReportsForDrawerFailure.toString()](state, action: Action<any>) {
            const { message } = action.payload
            return flow(
                set(['drawerReports', 'loading'], false),
                set(['drawerReports', 'error'], message)
            )(state)
        },

        // new bulk update initiated
        [initiateUpdateSuccess.toString()](state, action) {
            return set(
                'newUpdates',
                [...state.newUpdates, action.payload],
                state
            )
        },

        // remove new updates when downloaded
        [downloadBulkUpdate.toString()](
            state,
            action: Action<{
                id: string
            }>
        ) {
            const { id } = action.payload

            return update(['newUpdates'], (newUpdates) =>
                newUpdates.filter((each: any) => each.id !== id)
            )(state)
        },

        // drawer updates
        [fetchUpdatesForDrawerRequest.toString()](state) {
            return set(['drawerUpdates', 'loading'], true, state)
        },
        [fetchUpdatesForDrawerSuccess.toString()](
            state,
            action: Action<PaginatedResponse<any>>
        ) {
            const { results: updates } = action.payload
            return flow(
                set(['drawerUpdates', 'loading'], false),
                set(['drawerUpdates', 'error'], null),
                set(['drawerUpdates', 'updates'], updates)
            )(state)
        },
        [fetchUpdatesForDrawerFailure.toString()](state, action: Action<any>) {
            const { message } = action.payload
            return flow(
                set(['drawerUpdates', 'loading'], false),
                set(['drawerUpdates', 'error'], message)
            )(state)
        },

        // drawer alerts
        [fetchTriggeredAlertsForDrawerRequest.toString()](state) {
            return set(['triggeredAlerts', 'loading'], true, state)
        },
        [fetchTriggeredAlertsForDrawerSuccess.toString()](
            state,
            action: Action<PaginatedResponse<any>>
        ) {
            const { results } = action.payload
            return flow(
                set(['triggeredAlerts', 'loading'], false),
                set(['triggeredAlerts', 'error'], null),
                set(['triggeredAlerts', 'results'], results)
            )(state)
        },
        [fetchTriggeredAlertsForDrawerFailure.toString()](
            state,
            action: Action<any>
        ) {
            const { message } = action.payload
            return flow(
                set(['triggeredAlerts', 'loading'], false),
                set(['triggeredAlerts', 'error'], message)
            )(state)
        },
        [toggleReportsAppDrawer.toString()](state, action) {
            return set(['drawerReports', 'isVisible'], action.payload, state)
        },
        [toggleBulkUpdatesAppDrawer.toString()](state, action) {
            return set(['drawerUpdates', 'isVisible'], action.payload, state)
        },
        [toggleSidebarCollapsed.toString()](state) {
            return update(
                ['layout', 'isSidebarCollapsed'],
                (collapsed) => !collapsed
            )(state)
        },

        [fetchHomeDashboardIdSuccess.toString()](state, action) {
            return set(['homeDashboardId'], action.payload)(state)
        },
        [setHomeDashboardId.toString()](
            state,
            action: Action<{ dashboardId: string }>
        ) {
            const { dashboardId } = action.payload
            return set(['homeDashboardId'], dashboardId)(state)
        },

        // Custom Events
        [fetchCustomEventsSuccess.toString()](
            state,
            action: Action<PaginatedResponse<any>>
        ) {
            return set(['customEvents'], action.payload.results, state)
        },

        // User Survey
        [fetchUserSurveyLastReadDateSuccess.toString()](state, action) {
            return set(['userSurveyLastRead'], action.payload, state)
        },

        [dismissUserSurveySuccess.toString()](state, action) {
            return set(['userSurveyLastRead'], action.payload, state)
        },

        // SOV Permissions
        [fetchSovPermissionsSuccess.toString()](state, action) {
            return set(['sovPermissions'], action.payload)(state)
        },
        ...brandGroupsReducer,
    },
    defaultState
)
