import { call, put, select } from 'redux-saga/effects'

import {
    makeDownloadTableSuccess,
    makeFetchTableRequest,
    makeFetchTableSuccess,
} from 'actions/ui/shared'
import { eventsChangelogTableColumnsConfig } from 'configuration/tables'
import { EVENTS_SUMMARY_PAGE } from 'const/pages'
import { formatFilters } from 'helpers/filters/changelog'
import { generateReportNotification } from 'helpers/notifications'
import { formatColumns, formatPagination, formatSorter } from 'helpers/params'
import { cerebroApiSaga } from 'sagas/common'
import uiSagaRegistry from 'sagas/ui/registry'
import {
    selectTableSettings,
    selectVisibleColumnsOfTable,
    selectVisibleCombinedFilters,
} from 'selectors/ui'
import {
    getChangelog,
    getChangelogExport,
} from 'services/cerebroApi/orgScope/resourceApi'

const TAB_PATH = [EVENTS_SUMMARY_PAGE, 'changelog']
const TABLE_PATH = [...TAB_PATH, 'table']

/**
 * Fetch table data
 */
function* fetchTableSaga(noCount) {
    const filters = yield select(selectVisibleCombinedFilters, TAB_PATH)
    const { pagination, sorter } = yield select(selectTableSettings, TABLE_PATH)
    const params = {
        ...formatFilters(filters),
        ...formatPagination(pagination),
        ...formatSorter(sorter),
    }

    yield call(
        cerebroApiSaga,
        makeFetchTableSuccess(TABLE_PATH),
        getChangelog,
        params,
        { headers: { noCount } }
    )
}

function* downloadTableSaga() {
    const filters = yield select(selectVisibleCombinedFilters, TAB_PATH)
    const { sorter } = yield select(selectTableSettings, TABLE_PATH)
    const columns = yield select(
        selectVisibleColumnsOfTable,
        TABLE_PATH,
        eventsChangelogTableColumnsConfig
    )

    const params = {
        ...formatFilters(filters),
        ...formatSorter(sorter),
        ...formatColumns(columns, false),
    }

    if (columns?.some((col) => col.id === 'date')) {
        params.columns += ',profile_tz_date'
    }

    const reportName = `Changelog`
    yield call(
        cerebroApiSaga,
        makeDownloadTableSuccess(TABLE_PATH),
        getChangelogExport,
        {
            ...params,
            async_download_name: reportName,
        }
    )

    generateReportNotification(reportName)
}

/**
 * Fetch all data for changelog tab
 */
function* fetchTabDataSaga() {
    yield put(makeFetchTableRequest(TABLE_PATH)())
}

// Register sagas
uiSagaRegistry.registerSagas(TAB_PATH, {
    fetchTabDataSaga,
})

uiSagaRegistry.registerSagas(TABLE_PATH, {
    fetchTableSaga,
    downloadTableSaga,
})
