import { ReactElement } from 'react'

import { SearchOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'

import { ToolTip } from 'components/ToolTip'
import { userHasSovReadPermissions } from 'helpers/featurePermissions'
import { sovAsinReverseResearchUrl, sovKeywordResearchUrl } from 'helpers/urls'
import { useUserHasPermissions } from 'hooks'
import { useAnalytics } from 'services/analytics'
import { CountryCode } from 'types'

import styles from './styles.scss'

interface ResearchKeywordLinkProps {
    countryCode: CountryCode
    keyword?: string
    asin?: string
}

export function ResearchKeywordLink({
    countryCode,
    keyword,
    asin,
}: ResearchKeywordLinkProps): ReactElement | null {
    const { t } = useTranslation('sov')
    const { trackCtaClick } = useAnalytics()

    const hasShelfIntelligenceModule = useUserHasPermissions(
        userHasSovReadPermissions
    )

    const location = useLocation()

    if (!hasShelfIntelligenceModule || (!keyword && !asin)) {
        return null
    }

    const title = keyword
        ? t(
              'sov:keywordResearch.searchThisKeyword.tooltip',
              'Research this keyword'
          )
        : t(
              'sov:keywordResearch.searchThisAsin.tooltip',
              'Research this product'
          )

    const url = keyword
        ? sovKeywordResearchUrl(countryCode, keyword)
        : sovAsinReverseResearchUrl(countryCode, asin ?? '')

    return (
        <section className={styles.actions}>
            <div className={styles['icon-actions']}>
                <ToolTip title={title} className={styles['research-link']}>
                    <Link
                        to={url}
                        onClick={() =>
                            trackCtaClick({
                                destination: url,
                                text: 'Keyword Research - Search Icon Clicked',
                                type: 'icon',
                                location: location.pathname,
                            })
                        }
                    >
                        <SearchOutlined />
                    </Link>
                </ToolTip>
            </div>
        </section>
    )
}
