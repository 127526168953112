import { ReactNode } from 'react'

import { PlacementMultiplierField } from 'components/EditableFields'
import { CampaignPlacementGroupBy, CellRenderProps } from 'types'

type RenderProps<RecordType> = {
    cellRenderProps: CellRenderProps<RecordType>
    dataIndex?: string[]
}

export function placementMultiplierRenderer<
    RecordType extends CampaignPlacementGroupBy,
>({
    cellRenderProps: { readonly, record, rowIndex, updateRecord },
    dataIndex,
}: RenderProps<RecordType>): ReactNode {
    return (
        <PlacementMultiplierField
            dataIndex={dataIndex}
            record={record}
            readOnly={readonly}
            rowIndex={rowIndex}
            updateRecord={updateRecord}
            type={record.type}
        />
    )
}
