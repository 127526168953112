import { ReactElement } from 'react'

import classNames from 'classnames'

import { SELLER_BRAND_TYPE } from 'const/brands'
import { Campaign } from 'types'

import CreateProductAdFormAllRow from './CreateProductAdFormAllRow'
import CreateProductAdFormHeader from './CreateProductAdFormHeader'
import CreateProductAdFormRow from './CreateProductAdFormRow'
import { ProductAdField } from './localTypes'
import styles from './styles.scss'

interface Props {
    campaign: Campaign
    products: ProductAdField[]
    remove: (index: number) => void
    setFieldValue: (name: string, values: any) => void
}

const CreateProductAdForm = ({
    campaign,
    products = [],
    remove,
    setFieldValue,
}: Props): ReactElement => {
    const isSellerBrandType = campaign?.profile.type === SELLER_BRAND_TYPE
    const buttonLabel = isSellerBrandType ? 'SKU' : 'ASIN'

    return (
        <div className="d-flex flex-column mt-2 flex-shrink-0">
            {products.length > 0 ? (
                <div>
                    <CreateProductAdFormHeader type={buttonLabel} />
                    <CreateProductAdFormAllRow
                        campaign={campaign}
                        products={products}
                        setFieldValue={setFieldValue}
                    />
                    {products.map((product, idx) => (
                        <CreateProductAdFormRow
                            campaign={campaign}
                            key={idx}
                            product={product}
                            productIndex={idx}
                            remove={remove}
                        />
                    ))}
                </div>
            ) : (
                <div
                    className={classNames(
                        'mt-2 p-3',
                        styles['empty-table-text']
                    )}
                >
                    To get started, add some Product Ad {buttonLabel}s in the
                    field above
                </div>
            )}
        </div>
    )
}

export default CreateProductAdForm
