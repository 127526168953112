/// <reference types="@welldone-software/why-did-you-render" />

import React from 'react'

const suppressWdyr: boolean = SUPPRESS_WDYR.toLowerCase() === 'true'

if (process.env.NODE_ENV === 'development' && !suppressWdyr) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require
    const whyDidYouRender = require('@welldone-software/why-did-you-render')
    whyDidYouRender(React, {
        trackAllPureComponents: true,
    })
}
