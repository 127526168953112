import { ReactElement, ReactNode } from 'react'

import { Card, type CardProps, Divider } from 'antd'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'

import { Collapse } from 'components/Collapse'

import styles from './styles.scss'

interface Props {
    title?: string | ReactNode
    actions?: ReactNode[]
    subTitle?: string
    collapseOpen?: boolean
    collapseContent?: ReactNode
    showActionsOnHover?: boolean
    display?: ReactNode
}

const ContentCard = ({
    title,
    actions = [],
    subTitle,
    collapseOpen,
    collapseContent,
    showActionsOnHover,
    children,
    className,
    display,
    ...rest
}: Props & CardProps): ReactElement => {
    const getTitle = (): ReactElement => (
        <>
            <div className={styles['title-container']}>
                <div>
                    {title && <div className={styles.title}>{title}</div>}

                    {subTitle && (
                        <div className={styles['sub-title']}>{subTitle}</div>
                    )}
                </div>

                {!isEmpty(actions) && (
                    <div
                        className={classNames({
                            [styles['show-on-hover']]: showActionsOnHover,
                        })}
                        style={{ zIndex: 999 }}
                    >
                        {actions.map((action, idx) => (
                            <span key={idx}>{action}</span>
                        ))}
                    </div>
                )}
            </div>

            {display && <div>{display}</div>}

            {collapseContent && (
                <Collapse isOpened={collapseOpen}>
                    <Divider />
                    {collapseContent}
                </Collapse>
            )}
        </>
    )

    return (
        <Card
            className={classNames(styles.card, className)}
            title={getTitle()}
            {...rest}
        >
            {children}
        </Card>
    )
}

export default ContentCard
