import { ReactElement } from 'react'

import ReactQuill from 'react-quill'

import { TextWidgetConfig } from 'types'

interface TextWidgetProps {
    widget: TextWidgetConfig
}

function TextWidget({ widget }: TextWidgetProps): ReactElement {
    return (
        <ReactQuill theme={null as any} readOnly defaultValue={widget.json} />
    )
}

export default TextWidget
