import { Translation } from 'react-i18next'

import { profileIdRenderer } from 'components/FieldRenderers/Renderers/brand'
import { Flag } from 'components/Flag'
import { REGION_LABELS } from 'const/codes'
import { UNDEFINED_VALUE } from 'const/formatting'
import { isUnset } from 'helpers/utilities'
import { Field } from 'types'

import { createActionField, createField, createTitleField } from './createField'
import { FieldCreatorOptions } from './localTypes'

export function brandId<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['profile', 'id']
    const name = (
        <Translation>
            {(t) => t('common:advertisingAccount', 'Advertising Account')}
        </Translation>
    )
    return createField({
        ...options,
        id: 'profile_id',
        name,
        shortName: name,
        dataIndex,
        sorter: options.sorter ?? true,
        renderOptions: options.renderOptions ?? {
            render: (props) => profileIdRenderer<any>(props),
        },
        minWidth: 100,
        width: 200,
        columnTitle: name,
    })
}

export function brandCountryCode<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['profile', 'country_code']
    const name = (
        <Translation>
            {(t) =>
                t('table:fields.brandCountryCode.name', 'Ad Account Country')
            }
        </Translation>
    )
    const shortName = (
        <Translation>
            {(t) => t('table:fields.brandCountryCode.shortName', 'Country')}
        </Translation>
    )
    return createField({
        ...options,
        id: 'profile__country_code',
        name,
        shortName,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: ({ value }) =>
                isUnset(value) ? (
                    UNDEFINED_VALUE
                ) : (
                    <span className="fg-flag-container">
                        <Flag country={value} />
                        {value}
                    </span>
                ),
        },
        minWidth: 80,
        width: 80,
    })
}

export function brandCurrencyCode<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['profile', 'currency_code']
    const name = (
        <Translation>
            {(t) =>
                t(
                    'table:fields.brandCurrencyCode.name',
                    'Ad Account Currency Code'
                )
            }
        </Translation>
    )
    const shortName = (
        <Translation>
            {(t) => t('table:fields.brandCurrencyCode.shortName', 'Currency')}
        </Translation>
    )
    return createField({
        ...options,
        id: 'profile__currency_code',
        name,
        shortName,
        dataIndex,
        minWidth: 80,
    })
}

export function brandRegion<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['profile', 'region']
    const name = (
        <Translation>
            {(t) => t('table:fields.brandRegion.name', 'Ad Account Region')}
        </Translation>
    )
    const shortName = (
        <Translation>
            {(t) => t('table:fields.brandRegion.shortName', 'Region')}
        </Translation>
    )
    return createField({
        ...options,
        id: 'profile__region',
        name,
        shortName,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: ({ value }) =>
                isUnset(value)
                    ? UNDEFINED_VALUE
                    : REGION_LABELS[value] || value,
        },
        minWidth: 80,
    })
}

export function brandTimezone<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['profile', 'timezone']
    const name = (
        <Translation>
            {(t) => t('table:fields.brandTimezone.name', 'Ad Account Timezone')}
        </Translation>
    )
    const shortName = (
        <Translation>
            {(t) => t('table:fields.brandTimezone.shortName', 'Timezone')}
        </Translation>
    )
    return createField({
        ...options,
        id: 'brandTimeZone',
        name,
        shortName,
        dataIndex,
        minWidth: 135,
        width: 170,
        columnTitle: name,
    })
}

export function brandType<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['profile', 'type']
    const name = (
        <Translation>
            {(t) => t('table:fields.brandType.name', 'Ad Account Type')}
        </Translation>
    )
    const shortName = (
        <Translation>
            {(t) => t('table:fields.brandType.shortName', 'Type')}
        </Translation>
    )
    return createTitleField({
        ...options,
        id: 'profile__type',
        name,
        shortName,
        dataIndex,
        minWidth: 75,
    })
}

export function brandEntityId<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['profile', 'brand_entity_id']
    const name = (
        <Translation>
            {(t) =>
                t('table:fields.brandEntityId.name', 'Ad Account Entity Id')
            }
        </Translation>
    )
    const shortName = (
        <Translation>
            {(t) => t('table:fields.brandEntityId.shortName', 'Entity Id')}
        </Translation>
    )
    return createField({
        ...options,
        id: 'profile__brand_entity_id',
        name,
        shortName,
        dataIndex,
        minWidth: 150,
        columnTitle: name,
    })
}

export function brandLabelId<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['profile__label_id']
    return createField({
        ...options,
        id: 'profile__label_id',
        name: 'Ad Account Label',
        shortName: 'Ad Account Label',
        dataIndex,
        minWidth: 50,
        width: 110,
    })
}

export function removeBrandFromLabel<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['profile', 'id']
    return createActionField({
        ...options,
        id: 'remove_from_label',
        dataIndex,
        isResizeable: false,
        minWidth: 68,
        sorter: false,
        fixed: 'right',
        align: 'center',
    })
}
