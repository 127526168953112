import { ReactElement, useCallback } from 'react'

import { Select, Spin } from 'antd'
import noop from 'lodash/noop'

import { SelectOptionContent } from 'components/SelectOptionContent'
import {
    PREFETCH_RESULTS_PER_QUERY,
    SEARCH_RESULTS_PER_QUERY,
} from 'configuration/typeahead'
import { ENABLED } from 'const/resourceStates'
import { titleCase } from 'helpers/formatting'
import { useTypeahead } from 'hooks'
import { getKeywordsSearch } from 'services/cerebroApi/orgScope/resourceApi'
import { KeywordSearchResult } from 'types'

interface KeywordOption {
    keywordId: string
    keywordText: string
}

interface Props {
    value?: string
    onChange: (value: string) => void
    campaignId: string
    onSelect?: (keywordId: string, option: KeywordOption) => void
    disabled?: boolean
}

const CampaignKeywordSearchSelect = ({
    campaignId,
    value,
    onChange,
    onSelect = noop,
    disabled = false,
}: Props): ReactElement => {
    const [options, loading, onSearch, resetLoadingState] =
        useTypeahead<KeywordSearchResult>({
            apiSearchFunc: (query) =>
                getKeywordsSearch(
                    {
                        limit: SEARCH_RESULTS_PER_QUERY,
                        campaign: campaignId,
                        state: ENABLED,
                        text__icontains: query.trim(),
                    },
                    { headers: { noCount: true } }
                ),
            optionFormatter: (keyword) => ({
                value: keyword.id,
                label: keyword.text,
                metadata: `${titleCase(keyword.match_type || '')}`,
            }),
            prefetchApiFunc: useCallback(() => {
                if (!campaignId) {
                    return null
                }
                return getKeywordsSearch(
                    {
                        limit: PREFETCH_RESULTS_PER_QUERY,
                        campaign: campaignId,
                        state: ENABLED,
                    },
                    { headers: { noCount: true } }
                )
            }, [campaignId]),
        })

    return (
        <Select
            filterOption
            optionFilterProp="title"
            optionLabelProp="title"
            placeholder="Search Campaign Keywords..."
            loading={loading}
            notFoundContent={loading ? <Spin size="small" /> : null}
            onChange={onChange}
            onSearch={onSearch}
            onSelect={(
                selectedValue: string,
                selectedOption: { value: string; title: any }
            ) => {
                onSelect(selectedValue as string, {
                    keywordId: selectedOption.value as string,
                    keywordText: selectedOption.title,
                })
                resetLoadingState()
            }}
            showSearch
            value={value}
            disabled={disabled}
        >
            {options.map((option, idx) => (
                <Select.Option
                    key={idx}
                    value={option.value}
                    title={option.label}
                    disabled={option.disabled}
                >
                    <SelectOptionContent
                        label={option.label}
                        metadata={option.metadata}
                    />
                </Select.Option>
            ))}
        </Select>
    )
}

export default CampaignKeywordSearchSelect
