import { ReactElement, useState } from 'react'

import {
    DownloadOutlined,
    LoadingOutlined,
    CloseCircleOutlined,
} from '@ant-design/icons'
import { Badge, Button, Dropdown, Modal, Popconfirm } from 'antd'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { downloadBulkUpdate } from 'actions/ui/app'
import { IconButton } from 'components/Buttons'
import { CheckIcon, MoreIcon } from 'components/Icons'
import {
    isUpdateComplete,
    isUpdateFailed,
    isUpdatePending,
    isUpdateDryRun,
} from 'helpers/bulkUpdate'
import { useAction, useCerebroApiRequest } from 'hooks'
import { selectDomainValue as selectUIDomainValue } from 'selectors/ui'
import { updateAsyncUpdate } from 'services/cerebroApi/orgScope/updatesApi'
import { AsyncUpdate, RootReduxState } from 'types'
import message from 'utilities/message'

interface Props {
    update: AsyncUpdate
    reloadData: () => void
    useSingleButton?: boolean
}

const AsyncUpdateAction = ({
    update,
    reloadData,
    useSingleButton = true,
}: Props): ReactElement => {
    const { t } = useTranslation('asyncUpdates')
    const [dryRunLoading, setDryRunLoading] = useState(false)
    const makeCerebroApiRequest = useCerebroApiRequest()
    const recordDownload = useAction<{ id: string }>(downloadBulkUpdate)
    const newUpdates = useSelector<RootReduxState, AsyncUpdate[]>((state) =>
        selectUIDomainValue(state, ['app', 'newUpdates'])
    )

    const acceptDryRun = async (): Promise<void> => {
        setDryRunLoading(true)

        await makeCerebroApiRequest({
            request: updateAsyncUpdate(update.id, {
                state: 'in_queue',
            }),
            onRequestSuccess: () => {
                reloadData()
                message.success(
                    t(
                        'asyncUpdates:AsyncUpdateAction.dryRun.successMsg',
                        'Successfully started update "{{updateName}}"',
                        { updateName: update.name }
                    )
                )
            },
        })

        setDryRunLoading(false)
    }

    const confirmAndAcceptDryRun = (): void => {
        Modal.confirm({
            title: t(
                'asyncUpdates:AsyncUpdateAction.dryRun.confirmText',
                'Are you sure you want to accept this dry run?'
            ),
            onOk() {
                acceptDryRun()
            },
        })
    }

    let button = <span />

    if (isUpdatePending(update)) {
        button = (
            <IconButton
                icon={<LoadingOutlined />}
                tooltipTitle={t(
                    'asyncUpdates:AsyncUpdateAction.pendingBtn.tooltipTitle',
                    'Bulk Update is Still Processing'
                )}
                disabled
            />
        )
    }

    if (isUpdateComplete(update)) {
        if (!isUpdateDryRun(update)) {
            button = (
                <IconButton
                    icon={<DownloadOutlined />}
                    tooltipTitle={t(
                        'asyncUpdates:AsyncUpdateAction.downloadBtn.tooltipTitle',
                        'Download Changes'
                    )}
                    onClick={() => recordDownload({ id: update.id })}
                    href={update.response_url ?? undefined}
                />
            )
        } else if (useSingleButton) {
            button = (
                <Dropdown
                    menu={{
                        items: [
                            {
                                onClick: () =>
                                    recordDownload({ id: update.id }),
                                key: 'download',
                                label: (
                                    <a href={update.original_url ?? undefined}>
                                        {t(
                                            'asyncUpdates:AsyncUpdateAction.dryRun.downloadText',
                                            'Download Dry Run'
                                        )}
                                    </a>
                                ),
                            },
                            {
                                onClick: confirmAndAcceptDryRun,
                                key: 'accept',
                                label: t(
                                    'asyncUpdates:AsyncUpdateAction.dryRun.acceptText',
                                    'Accept Dry Run'
                                ),
                                disabled: dryRunLoading,
                            },
                        ],
                    }}
                >
                    <IconButton loading={dryRunLoading} icon={<MoreIcon />} />
                </Dropdown>
            )
        } else {
            button = (
                <>
                    <Popconfirm
                        disabled={dryRunLoading}
                        placement="topRight"
                        title={t(
                            'asyncUpdates:AsyncUpdateAction.dryRun.confirmChangesText',
                            'Are you sure you want to accept these changes?'
                        )}
                        onConfirm={acceptDryRun}
                        okType="primary"
                        cancelText={t('common:cancel', 'Cancel')}
                    >
                        <Button
                            type="primary"
                            ghost
                            icon={<CheckIcon />}
                            disabled={dryRunLoading}
                        >
                            Accept changes
                        </Button>
                    </Popconfirm>
                    <IconButton
                        icon={<DownloadOutlined />}
                        tooltipTitle={t(
                            'asyncUpdates:AsyncUpdateAction.dryRun.downloadProposedChangesText',
                            'Download Proposed Changes'
                        )}
                        onClick={() => recordDownload({ id: update.id })}
                        href={update.original_url ?? undefined}
                    />
                </>
            )
        }
    }

    if (isUpdateFailed(update)) {
        if (update.response_url) {
            button = (
                <IconButton
                    icon={<DownloadOutlined />}
                    tooltipTitle={t(
                        'asyncUpdates:AsyncUpdateAction.failed.postProcessedBtn.tooltipTitle',
                        'Update Failed - Download Post-Processed Bulk Update'
                    )}
                    danger
                    type="link"
                    onClick={() => recordDownload({ id: update.id })}
                    href={update.response_url}
                />
            )
        } else if (update.original_url) {
            button = (
                <IconButton
                    icon={<DownloadOutlined />}
                    tooltipTitle={t(
                        'asyncUpdates:AsyncUpdateAction.failed.preProcessedBtn.tooltipTitle',
                        'Update Failed - Download Pre-Processed Bulk Update'
                    )}
                    danger
                    type="link"
                    onClick={() => recordDownload({ id: update.id })}
                    href={update.original_url}
                />
            )
        } else {
            button = (
                <IconButton
                    icon={<CloseCircleOutlined />}
                    tooltipTitle={t(
                        'asyncUpdates:AsyncUpdateAction.failed.genericBtn.tooltipTitle',
                        'Update Failed'
                    )}
                    danger
                    type="link"
                />
            )
        }
    }

    // Add a badge to new updates
    if (newUpdates.some((each) => each.id === update.id)) {
        return <Badge dot>{button}</Badge>
    }

    return button
}

export default AsyncUpdateAction
