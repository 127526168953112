import { SVGAttributes, ReactElement } from 'react'

type Props = SVGAttributes<SVGElement>

function SvgWalmartIcon(props: Props): ReactElement {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M10.021 7.00031C10.4566 7.00031 10.8152 6.77664 10.8636 6.48785L11.2939 1.68654C11.2939 1.21187 10.7311 0.822266 10.0226 0.822266C9.31516 0.822266 8.75293 1.21187 8.75293 1.68654L9.18218 6.48785C9.23006 6.77664 9.58857 7.00031 10.0226 7.00031H10.0209" />
            <path d="M7.39218 8.49989C7.6108 8.12813 7.59378 7.70966 7.36402 7.52429L3.36614 4.7561C2.95069 4.51877 2.32729 4.80492 1.97357 5.41035C1.61878 6.0147 1.68155 6.69048 2.09644 6.92781L6.5225 8.96248C6.79963 9.06383 7.17676 8.869 7.39378 8.49727L7.39208 8.49978" />
            <path d="M12.6521 8.49727C12.8702 8.86903 13.2457 9.06383 13.5228 8.96248L17.9494 6.92781C18.3665 6.69047 18.426 6.01472 18.0739 5.41034C17.7186 4.80494 17.0941 4.51877 16.6798 4.7561L12.6819 7.52431C12.4532 7.70965 12.4361 8.12815 12.6537 8.49988L12.652 8.49737" />
            <path d="M10.021 12.998C10.4566 12.998 10.8152 13.2202 10.8636 13.5089L11.2939 18.3097C11.2939 18.7854 10.7311 19.1745 10.0226 19.1745C9.31516 19.1745 8.75293 18.7854 8.75293 18.3097L9.18218 13.5089C9.23006 13.2202 9.58857 12.998 10.0226 12.998H10.0209" />
            <path d="M12.6521 11.4974C12.8702 11.1251 13.2457 10.9313 13.5228 11.0343L17.9494 13.0668C18.3665 13.3047 18.426 13.981 18.0739 14.5864C17.7186 15.1897 17.0941 15.4764 16.6798 15.2396L12.6819 12.474C12.4532 12.2865 12.4361 11.8681 12.6537 11.4969L12.652 11.4973" />
            <path d="M7.39218 11.4969C7.6108 11.8681 7.59378 12.2866 7.36401 12.474L3.36614 15.2396C2.95071 15.4764 2.32731 15.1897 1.97358 14.5864C1.61877 13.981 1.68154 13.3047 2.09643 13.0668L6.5225 11.0343C6.79962 10.9313 7.17675 11.1251 7.39378 11.4974L7.39208 11.497" />
        </svg>
    )
}

export default SvgWalmartIcon
