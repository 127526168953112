import { useCallback, useEffect, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { useFormikContext } from 'formik'

import { useCerebroApiRequest } from 'hooks'
import { getAdGroups } from 'services/cerebroApi/orgScope/resourceApi'
import { AdGroup, Campaign, CerebroPaginatedResponse } from 'types'

import { Option } from './localTypes'

export const useFetchAdGroups = (
    campaign: Campaign,
    name?: string,
    isFormikField = false
): [boolean, Option[]] => {
    const makeCerebroApiRequest = useCerebroApiRequest()
    const { setFieldTouched } = useFormikContext()

    const [isFetching, setFetching] = useState(false)
    const [options, setOptions] = useState<Option[]>(() => {
        return campaign.ad_groups?.length > 0
            ? [
                  {
                      label: campaign.ad_groups[0].name,
                      value: campaign.ad_groups[0].id,
                  },
              ]
            : []
    })

    const fetchAdGroups = useCallback(
        async (campaignId: string): Promise<void> => {
            await makeCerebroApiRequest<
                AdGroup,
                CerebroPaginatedResponse<AdGroup>
            >({
                request: getAdGroups({
                    campaign: campaignId,
                    'state!': 'archived',
                }),
                onRequestSuccess: ({ data }) => {
                    const fetchedOptions: Option[] = data.results.map(
                        ({ id, name: adGroupName }) => ({
                            value: id,
                            label: adGroupName,
                        })
                    )

                    setOptions(fetchedOptions)
                    setFetching(false)
                },
            })
        },
        [makeCerebroApiRequest]
    )

    useEffect(() => {
        if (isFormikField && name) {
            setFieldTouched(name, false)
        }

        setOptions([])
        setFetching(true)
        fetchAdGroups(campaign.id)
    }, [campaign.id, name, setFieldTouched, isFormikField, fetchAdGroups])

    return [isFetching, options]
}

export const useFetchAdGroupsCached = (
    campaign: Campaign,
    name?: string,
    isFormikField = false
): [boolean, Option[]] => {
    const { setFieldTouched } = useFormikContext()

    const [options, setOptions] = useState<Option[]>(() => {
        return campaign.ad_groups?.length > 0
            ? campaign.ad_groups.map((adGroup) => ({
                  label: adGroup.name,
                  value: adGroup.id,
              }))
            : []
    })

    const { isFetching } = useQuery({
        queryKey: ['adGroups', campaign.id, { 'state!': 'archived' }],
        queryFn: () => {
            return getAdGroups({
                campaign: campaign.id,
                'state!': 'archived',
            })
        },
        enabled: !!campaign.id,
        onSuccess: ({ data }) => {
            const fetchedOptions: Option[] = data.results.map(
                ({ id, name: adGroupName }: { id: string; name: string }) => ({
                    value: id,
                    label: adGroupName,
                })
            )

            setOptions(fetchedOptions)
        },
    })

    useEffect(() => {
        if (isFormikField && name) {
            setFieldTouched(name, false)
        }
    }, [name, setFieldTouched, isFormikField])

    return [isFetching, options]
}
