import { call, select, put, all } from 'redux-saga/effects'

import {
    fetchCapabilitiesSuccess,
    fetchTasksSuccess,
    fetchAutomationProfilesSuccess,
    fetchAutomationProfilesFailure,
} from 'actions/ui/campaignPage/automation'
import { CAMPAIGN_PAGE } from 'const/pages'
import { cerebroApiSaga } from 'sagas/common'
import uiSagaRegistry from 'sagas/ui/registry'
import { selectResourceParamsOfPage } from 'selectors/ui'
import {
    getAutomationTasks,
    getCampaignAutomationCapabilities,
    getAutomationProfiles,
} from 'services/cerebroApi/orgScope/resourceApi'

const TAB_PATH = [CAMPAIGN_PAGE, 'automation']

/**
 * Fetch all Time Parting profiles
 */
function* fetchAutomationProfilesSaga() {
    yield call(
        cerebroApiSaga,
        fetchAutomationProfilesSuccess,
        getAutomationProfiles,
        {
            limit: 'null', // fetch all available profiles when loading app
        }
    )
}

/**
 * Fetch automation capabilities of campaign
 *
 * @param {string} campaignId - Campaign Id
 */
function* fetchCapabilitiesSaga(campaignId) {
    yield call(
        cerebroApiSaga,
        fetchCapabilitiesSuccess,
        getCampaignAutomationCapabilities,
        campaignId
    )
}

/**
 * Fetch automation tasks of campaign
 *
 * @param {string} campaignId - Campaign Id
 */
export function* fetchTasksSaga(campaignId) {
    yield call(cerebroApiSaga, fetchTasksSuccess, getAutomationTasks, {
        campaign: campaignId,
    })
}

export function* fetchAutomationProfilesWorker() {
    try {
        yield call(fetchAutomationProfilesSaga)
    } catch (error) {
        yield put(fetchAutomationProfilesFailure(error))
    }
}

export function* fetchCapabilitiesWorker() {
    const { campaignId } = yield select(
        selectResourceParamsOfPage,
        CAMPAIGN_PAGE
    )

    if (campaignId) {
        yield call(fetchCapabilitiesSaga, campaignId)
    }
}

export function* fetchTasksWorker() {
    const { campaignId } = yield select(
        selectResourceParamsOfPage,
        CAMPAIGN_PAGE
    )

    yield call(fetchTasksSaga, campaignId)
}

/**
 * Fetches all data for automation tab
 */
function* fetchTabDataSaga() {
    yield all([call(fetchTasksWorker)])
}

function* mountTabSaga() {
    yield all([
        call(fetchCapabilitiesWorker),
        call(fetchAutomationProfilesWorker),
    ])
}

uiSagaRegistry.registerSagas(TAB_PATH, {
    mountTabSaga,
    fetchTabDataSaga,
})
