import isNil from 'lodash/isNil'
import keyBy from 'lodash/keyBy'
import { createSelector } from 'reselect'

import { AUTOMATIONS_ORDER } from 'const/automations'
import {
    AutomationCapability,
    AutomationCapabilityId,
    CurrencyCode,
    RootReduxState,
} from 'types'

import { selectDomainValue } from './ui'

export const selectCurrencyCode = (state: RootReduxState): CurrencyCode =>
    selectDomainValue(state, ['app', 'currencyCode'])

export const selectDashboardCurrencyCode = (
    state: RootReduxState
): CurrencyCode =>
    selectDomainValue(state, ['dashboardPage', 'currencyCode']) ||
    selectCurrencyCode(state)

const selectAutomationCapabilities = (
    state: RootReduxState
): AutomationCapability[] =>
    selectDomainValue(state, ['app', 'automationCapabilities'])

export const selectAutomationCapabilitiesMap = createSelector(
    selectAutomationCapabilities,
    (capabilities) => keyBy(capabilities, 'id')
)

export const selectAutomationCapabilitiesWithProfileSupport = createSelector(
    selectAutomationCapabilities,
    (capabilities) => {
        const capabilitiesMap = keyBy(
            capabilities.filter((x) => x.support_automation_profile),
            'id'
        )
        return AUTOMATIONS_ORDER.map((each) => capabilitiesMap[each]).filter(
            (each) => !isNil(each)
        )
    }
)

export const selectHiddenAutomationCapabilities = (
    state: RootReduxState
): AutomationCapabilityId[] =>
    selectDomainValue(state, ['app', 'hiddenAutomationCapabilities'])

export const selectIsSidebarCollapsed = (state: RootReduxState): boolean =>
    selectDomainValue(state, ['app', 'layout', 'isSidebarCollapsed'])

export const selectIsPageContentDisabled = (state: RootReduxState): boolean =>
    selectDomainValue(state, ['app', 'layout', 'isPageContentDisabled'])

const selectBrandGroups = (state: RootReduxState): any[] =>
    selectDomainValue(state, ['app', 'brandGroups'])

export const selectBrandGroupsMap = createSelector(
    selectBrandGroups,
    (brandGroups) => keyBy(brandGroups, 'name')
)
