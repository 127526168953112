import { ReactElement, ReactNode, CSSProperties } from 'react'

import styles from './styles.scss'

interface Props {
    text?: ReactNode
    style?: CSSProperties
}
export default function DetailText({ text, style }: Props): ReactElement {
    return (
        <div className={styles['details-text']} style={style}>
            {text}
        </div>
    )
}
