import { ReactElement } from 'react'

import { FormikHelpers } from 'formik'
import pluralize from 'pluralize'
import { useTranslation } from 'react-i18next'
import { array, number, object, string } from 'yup'

import { BROAD, EXACT, PHRASE } from 'const/matchTypes'
import { ENABLED } from 'const/resourceStates'
import { WALMART_KEYWORD_BID_MIN, WALMART_KEYWORD_BID_MAX } from 'const/walmart'
import { useCerebroApiRequest, useAuthDomainValue } from 'hooks'
import { createWalmartKeyword } from 'services/cerebroApi/orgScope/walmartApi'
import { WalmartCampaign, WalmartAdvertiser, WalmartKeyword } from 'types'
import displayMessage from 'utilities/message'

import { FormValues, KeywordField } from './localTypes'
import { CreateKeywordModal } from '../CreateKeywordModal'
import { getWalmartCampaignKeywordCreationColumnsConfig } from '../Shared/CreateKeywordModalColumnsConfigs'

interface Props {
    campaign: WalmartCampaign
    isModalVisible: boolean
    onModalCancel: () => void
    toggleModalVisible: () => void
    reloadData: () => void
}

function CreateWalmartCampaignKeywordsModal({
    campaign,
    isModalVisible,
    onModalCancel,
    reloadData,
    toggleModalVisible,
}: Props): ReactElement {
    const { t } = useTranslation()
    const makeCerebroApiRequest = useCerebroApiRequest()
    const { advertiser_id } = useAuthDomainValue<WalmartAdvertiser>(
        'latestWalmartAdvertiser'
    )

    const closeModal = (callback: any): void => {
        callback()
        toggleModalVisible()
        reloadData()
    }

    const submitForm = async (
        { keywords }: FormValues,
        { resetForm }: FormikHelpers<FormValues>
    ): Promise<void> => {
        const keywordItems: Partial<WalmartKeyword>[] = keywords.map(
            (keyword) =>
                ({
                    keyword_text: keyword.text,
                    bid: keyword.bid,
                    match_type: keyword.matchType,
                    state: keyword.state,
                    ad_group_id: keyword.adGroupId,
                    advertiser_id,
                    campaign_id: campaign?.id,
                }) as WalmartKeyword
        )
        await makeCerebroApiRequest({
            request: createWalmartKeyword({
                items: keywordItems,
            }),
            onRequestSuccess: () => {
                displayMessage.success(
                    `Successfully created ${pluralize(
                        'keyword',
                        keywords.length,
                        true
                    )} on Walmart campaign "${campaign?.name}"`
                )
                closeModal(resetForm)
            },
        })
    }

    const initialValues: FormValues = { keywords: [] }

    const validationSchema = object().shape({
        keywords: array()
            .of(
                object().shape({
                    // static values
                    text: string().required(),

                    // form values
                    adGroupId: string().label('Ad Group Target').required(),
                    matchType: string()
                        .label('Match Type')
                        .matches(new RegExp(`${EXACT}|${PHRASE}|${BROAD}`))
                        .required(),
                    bid: number()
                        .label('Keyword Bid')
                        .max(WALMART_KEYWORD_BID_MAX)
                        .min(WALMART_KEYWORD_BID_MIN)
                        .nullable()
                        .required(),
                })
            )
            .min(1)
            .required(),
    })

    const serializeKeywordField = (keyword: string): KeywordField => {
        return {
            text: keyword,
            adGroupId: '',
            state: ENABLED,
            matchType: EXACT,
            bid: WALMART_KEYWORD_BID_MIN,
        }
    }

    return (
        <CreateKeywordModal<KeywordField>
            isModalVisible={isModalVisible}
            onModalCancel={onModalCancel}
            toggleModalVisible={toggleModalVisible}
            columnsConfig={getWalmartCampaignKeywordCreationColumnsConfig(
                t,
                campaign
            )}
            initialValues={initialValues}
            validationSchema={validationSchema}
            submitForm={submitForm}
            serializeKeywordField={serializeKeywordField}
        />
    )
}

export default CreateWalmartCampaignKeywordsModal
