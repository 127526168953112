import { memo, ReactElement } from 'react'

import { AxiosResponse } from 'axios'
import { Field } from 'formik'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import { number, object } from 'yup'

import { FormikInputNumber } from 'components/formik'
import { UNDEFINED_VALUE } from 'const/formatting'
import { formatCurrency } from 'helpers/formatting'
import { isUnset } from 'helpers/utilities'
import { useCerebroApiRequest } from 'hooks'
import { CurrencyCode } from 'types'
import message from 'utilities/message'

import { SingleValueField } from '../SingleValueField'

interface Props<RecordType> {
    bidMax: number
    bidMin: number
    bidPrecision: number
    bidStep: number
    currencyCode: CurrencyCode
    disabled?: boolean
    fieldName?: string
    fieldPath: string[]
    readOnly?: boolean
    record: RecordType
    serializeFieldValues?: (values: { [fieldName: string]: number }) => object
    updateRequestApi: (...args: any) => Promise<AxiosResponse<any>>
    updateRequestData: any
    updateRequestSuccessMesg: string
}

function BidField<RecordType>({
    bidMax,
    bidMin,
    bidPrecision,
    bidStep,
    currencyCode,
    disabled = false,
    fieldName = 'bid',
    fieldPath = [],
    readOnly = false,
    record,
    serializeFieldValues = (values) => values,
    updateRequestApi,
    updateRequestData,
    updateRequestSuccessMesg,
}: Props<RecordType>): ReactElement {
    const makeCerebroApiRequest = useCerebroApiRequest()
    const validationSchema = object().shape({
        [fieldName]: number()
            .min(bidMin, 'Must be above the minimum bid.')
            .max(bidMax, 'Must be below the maximum bid.')
            .required(),
    })

    return (
        <SingleValueField
            disabled={disabled}
            fieldName={fieldName}
            formatFieldValue={(values) =>
                isUnset(values[fieldName])
                    ? UNDEFINED_VALUE
                    : formatCurrency(values[fieldName], {
                          currencyCode,
                          decimal: true,
                      })
            }
            initialValues={{ [fieldName]: get(record, fieldPath) }}
            onSave={(values) =>
                makeCerebroApiRequest({
                    request: updateRequestApi(
                        updateRequestData,
                        serializeFieldValues(values)
                    ),
                    onRequestSuccess: () => {
                        message.success(updateRequestSuccessMesg)
                    },
                })
            }
            readOnly={readOnly}
            validationSchema={validationSchema}
        >
            {({ isSubmitting }) => (
                <Field
                    component={FormikInputNumber}
                    disabled={isSubmitting}
                    max={bidMax}
                    min={bidMin}
                    name={fieldName}
                    placeholder="Bid"
                    precision={bidPrecision}
                    step={bidStep}
                />
            )}
        </SingleValueField>
    )
}

export default memo(BidField, isEqual) as typeof BidField
