import {
    changeSetActivity,
    changeSetChangeCount,
    changeSetCreatedBy,
    changeSetUpdatedAtDate,
} from 'configuration/fieldCreators/changeSet'
import { ChangeSet, Field } from 'types'

export const changeSetsTableColumnsConfig: Field<ChangeSet>[] = [
    changeSetUpdatedAtDate({
        fixed: 'left',
    }),
    changeSetActivity(),
    changeSetChangeCount(),
    changeSetCreatedBy(),
]
