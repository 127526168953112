import find from 'lodash/find'
import get from 'lodash/get'
import isNull from 'lodash/isNull'
import queryString from 'query-string'
import { matchPath, match as MatchType } from 'react-router-dom'

import { PAGES_AND_PATHS, PAGE_TO_REDUCER_KEY_MAP } from 'configuration/pages'
import history from 'utilities/history'

export const getPath = (page: string): string =>
    get(find(PAGES_AND_PATHS, { page }), 'path', '')

interface Page {
    page?: string
    match?: MatchType | null
}

export const getPage = (path: string, exact = true): Page => {
    let match = null
    const pageAndPath = find(PAGES_AND_PATHS, (item) => {
        match = matchPath(path, { path: item.path, exact })
        return !isNull(match)
    })
    if (pageAndPath) {
        const { page } = pageAndPath
        return { page: get(PAGE_TO_REDUCER_KEY_MAP, [page], page), match }
    }
    return {}
}

export const getCurrentPage = (exact = true): Page => {
    const {
        location: { pathname },
    } = history

    return getPage(pathname, exact)
}

export const getCurrentTab = (): string | undefined => {
    const {
        location: { search },
    } = history

    const query: { tab?: string } = queryString.parse(search)

    return query.tab
}
