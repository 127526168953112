import { call, put } from 'redux-saga/effects'

import { makeFetchTableRequest, makeFetchListSuccess } from 'actions/ui/shared'
import { ALERTS_SUMMARY_PAGE } from 'const/pages'
import { cerebroApiSaga } from 'sagas/common'
import uiSagaRegistry from 'sagas/ui/registry'
import { getTriggeredAlerts } from 'services/cerebroApi/orgScope/alertsApi'

const TAB_PATH = [ALERTS_SUMMARY_PAGE, 'triggeredAlerts']
const LIST_PATH = [...TAB_PATH, 'list']

function* fetchTriggeredAlertsSaga() {
    yield call(
        cerebroApiSaga,
        makeFetchListSuccess(LIST_PATH),
        getTriggeredAlerts,
        {
            ordering: '-triggered_at',
            am_subscribed: true,
            subscription_type: 'in-app',
        }
    )
}

function* fetchTabDataSaga() {
    yield put(makeFetchTableRequest(LIST_PATH)())
}

// Register Sagas
uiSagaRegistry.registerSagas(TAB_PATH, {
    fetchTabDataSaga,
})

uiSagaRegistry.registerSagas(LIST_PATH, {
    fetchDataSaga: fetchTriggeredAlertsSaga,
})
