import { call, put, select } from 'redux-saga/effects'

import { resetChangesTable } from 'actions/ui/rulebookPage'
import { makeFetchResourceSuccess } from 'actions/ui/shared/resource'
import { RULEBOOK_PAGE } from 'const/pages'
import { cerebroApiSaga } from 'sagas/common'
import uiSagaRegistry from 'sagas/ui/registry'
import { selectResourceParamsOfPage } from 'selectors/ui'
import { getRulebook } from 'services/cerebroApi/orgScope/rulebooksApi'

function* fetchRulebookSaga(): any {
    const resourceParams = yield select(
        selectResourceParamsOfPage,
        RULEBOOK_PAGE
    )

    const { rulebookId } = resourceParams

    yield call(
        cerebroApiSaga,
        makeFetchResourceSuccess(RULEBOOK_PAGE),
        getRulebook,
        rulebookId
    )
}

/**
 * Mounts the Rulebook Page and fetches data
 */
function* mountPageSaga(): any {
    yield call(fetchRulebookSaga)
}

function* unmountPageSaga(): any {
    yield put(resetChangesTable())
}

uiSagaRegistry.registerSagas([RULEBOOK_PAGE], {
    mountPageSaga,
    unmountPageSaga,
})
