import { SVGAttributes, ReactElement } from 'react'

type Props = SVGAttributes<SVGElement>

function SvgExternalLinkIcon(props: Props): ReactElement {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M12.583 2a.75.75 0 01.75-.75H18a.75.75 0 01.75.765v4.652a.75.75 0 01-1.5 0V3.81l-7.275 7.275a.75.75 0 11-1.061-1.06l7.275-7.276h-2.856a.75.75 0 01-.75-.75z" />
            <path d="M5.556 5.083a.806.806 0 00-.806.806v8.555a.806.806 0 00.806.806h8.555a.805.805 0 00.806-.806V9.778a.75.75 0 011.5 0v4.666a2.305 2.305 0 01-2.306 2.306H5.556a2.305 2.305 0 01-2.306-2.306V5.89a2.306 2.306 0 012.306-2.306h4.666a.75.75 0 010 1.5H5.556z" />
        </svg>
    )
}

export default SvgExternalLinkIcon
