import { ReactNode, ReactElement } from 'react'

import {
    PortfolioBudgetField,
    PortfolioEndDateField,
    PortfolioStartDateField,
    RemoveFromLabelField,
} from 'components/EditableFields/PortfolioFields'
import { ResourceLink } from 'components/Links/ResourceLink'
import { UNDEFINED_VALUE } from 'const/formatting'
import { labelUrl, portfolioUrl } from 'helpers/urls'
import { isUnset } from 'helpers/utilities'
import { CellRenderProps, PortfolioGroupBy, ProfileGroupBy, Label } from 'types'

type RenderProps<RecordType> = {
    cellRenderProps: CellRenderProps<RecordType>
    dataIndex: string[]
}

export function portfolioIdRenderer<RecordType extends PortfolioGroupBy>({
    value,
    record,
    undefinedValue = UNDEFINED_VALUE,
}: CellRenderProps<RecordType>): ReactNode {
    return isUnset(value) ? (
        undefinedValue
    ) : (
        <ResourceLink to={portfolioUrl(value)}>
            {record.portfolio.name}
        </ResourceLink>
    )
}

export function portfolioNameRenderer<RecordType extends PortfolioGroupBy>({
    value,
    record,
    undefinedValue = UNDEFINED_VALUE,
}: CellRenderProps<RecordType>): ReactNode {
    return isUnset(value) ? (
        undefinedValue
    ) : (
        <ResourceLink to={portfolioUrl(record.portfolio.id)}>
            {value}
        </ResourceLink>
    )
}

export function portfolioBudgetRenderer<
    RecordType extends PortfolioGroupBy & ProfileGroupBy,
>({
    cellRenderProps: { record, readonly },
    dataIndex,
}: RenderProps<RecordType>): ReactNode {
    return (
        <PortfolioBudgetField
            dataIndex={dataIndex}
            readOnly={readonly}
            record={record}
        />
    )
}

export function portfolioStartDateRenderer<
    RecordType extends PortfolioGroupBy,
>({
    cellRenderProps: { record, readonly },
    dataIndex,
}: RenderProps<RecordType>): ReactNode {
    return (
        <PortfolioStartDateField
            dataIndex={dataIndex}
            readOnly={readonly}
            record={record}
        />
    )
}

export function portfolioEndDateRenderer<RecordType extends PortfolioGroupBy>({
    cellRenderProps: { record, readonly },
    dataIndex,
}: RenderProps<RecordType>): ReactNode {
    return (
        <PortfolioEndDateField
            dataIndex={dataIndex}
            readOnly={readonly}
            record={record}
        />
    )
}

export function portfolioLabelIdRenderer<
    RecordType extends PortfolioGroupBy & {
        portfolio__label: Label
    },
>({
    value,
    record,
    undefinedValue = UNDEFINED_VALUE,
}: CellRenderProps<RecordType>): ReactNode {
    return isUnset(value) ? (
        undefinedValue
    ) : (
        <ResourceLink
            to={{
                pathname: labelUrl(value),
                search: '?tab=portfolios',
            }}
        >
            {record.portfolio__label.name}
        </ResourceLink>
    )
}

export function removePortfolioFromLabelRenderer<
    RecordType extends PortfolioGroupBy,
>({ record, readonly, reloadData }: CellRenderProps<RecordType>): ReactElement {
    return (
        <RemoveFromLabelField
            record={record}
            readOnly={readonly}
            reloadData={reloadData}
        />
    )
}
