import { handleActions } from 'redux-actions'

import { dspOrdersTableColumnsConfig } from 'configuration/tables'
import { DATES, LABELS } from 'const/filters'
import { FILTER_SETTINGS, LOCAL_FILTERS } from 'const/reducerKeys'
import { serializeColumns } from 'helpers/tables'
import { defaultTab, getDefaultTable } from 'reducers/ui/defaults'

const initialState = {
    ...defaultTab,
    [LOCAL_FILTERS]: {
        [LABELS]: [],
    },
    [FILTER_SETTINGS]: {
        anchored: [DATES],
        order: [LABELS],
        displayState: {
            [LABELS]: true,
        },
    },
    table: getDefaultTable(serializeColumns(dspOrdersTableColumnsConfig), {
        sorter: {
            field: 'total_cost__sum',
            order: 'descend',
        },
    }),
}

export default handleActions({}, initialState)
