import {
    amazon_choice__pct,
    amazon_choice_count,
    best_seller__pct,
    best_seller_count,
    brand_amazon_choice__pct,
    brand_amazon_choice_count,
    brand_best_seller__pct,
    brand_best_seller_count,
    brand_count,
    brand_count__pct,
    brand_fold_weight__pct,
    brand_fold_weight__sum,
    brand_organic__pct,
    brand_organic_count,
    brand_rank_weight__pct,
    brand_rank_weight__sum,
    brand_sponsored__pct,
    brand_sponsored_brands__pct,
    brand_sponsored_brands_count,
    brand_sponsored_count,
    brand_sponsored_products__pct,
    brand_sponsored_products_count,
    brand_video_count,
    cost__sum,
    count,
    count__pct,
    estimated_cost_of_all_sponsored,
    estimated_search_volume,
    fold_weight__pct,
    fold_weight__sum,
    impressions__sum,
    local_rank__avg,
    organic_count,
    rank__avg,
    rank_weight__pct,
    rank_weight__sum,
    review_star__avg,
    sponsored_brands_count,
    sponsored_count,
    sponsored_products_count,
    video_count,
} from 'configuration/fieldCreators/metrics/sov'
import { productLabelId } from 'configuration/fieldCreators/productMetadata'
import {
    amazonChoice,
    bestSeller,
    layout,
    resultType,
    searchTimeDay,
    searchTimeMonth,
    searchTimeWeek,
    searchTimeWeekDay,
    searchTimeYear,
    sovKeywordCountryCode,
    sovKeywordId,
    sovKeywordLabelId,
    sovKeywordLanguageCode,
    sovKeywordPlatform,
} from 'configuration/fieldCreators/sov'

import { makeMapping } from './helpers'

export const SOV_METRICS_COLUMNS_PAGES = [
    count(),
    count__pct(),
    rank_weight__pct(),
    fold_weight__pct(),
    brand_count(),
    brand_count__pct({ isVisible: true }),
    brand_rank_weight__pct({ isVisible: true }),
    brand_fold_weight__pct({ isVisible: true }),
    estimated_search_volume(),
    rank__avg(),
    local_rank__avg(),
    review_star__avg(),
    amazon_choice_count(),
    amazon_choice__pct(),
    best_seller_count(),
    best_seller__pct(),
    brand_amazon_choice_count(),
    brand_amazon_choice__pct(),
    brand_best_seller_count(),
    brand_best_seller__pct(),
    sponsored_products_count(),
    sponsored_brands_count(),
    sponsored_count(),
    organic_count(),
    brand_sponsored_products_count(),
    brand_sponsored_brands_count(),
    brand_sponsored_count(),
    brand_organic_count(),
    brand_sponsored_products__pct(),
    brand_sponsored_brands__pct(),
    brand_sponsored__pct(),
    brand_organic__pct(),
    cost__sum(),
    impressions__sum(),
    estimated_cost_of_all_sponsored(),
]

const SOV_BRAND_METRICS_COLUMNS = [
    brand_count({ isVisible: true }),
    brand_count__pct({ isVisible: true }),
    brand_rank_weight__pct({ isVisible: true }),
    brand_fold_weight__pct({ isVisible: true }),
    brand_amazon_choice__pct({ isVisible: true }),
    brand_best_seller__pct({ isVisible: true }),
    brand_rank_weight__sum({ isVisible: true }),
    brand_fold_weight__sum({ isVisible: true }),
    brand_sponsored_products_count({ isVisible: true }),
    brand_sponsored_brands_count({ isVisible: true }),
    brand_sponsored_count({ isVisible: true }),
    brand_organic_count({ isVisible: true }),
    brand_sponsored_products__pct({ isVisible: true }),
    brand_sponsored_brands__pct({ isVisible: true }),
    brand_sponsored__pct({ isVisible: true }),
    brand_organic__pct({ isVisible: true }),
    estimated_cost_of_all_sponsored({ isVisible: true }),
]

const SOV_METRICS_COLUMNS = [
    count({ isVisible: true }),
    count__pct({ isVisible: true }),
    rank_weight__pct({ isVisible: true }),
    fold_weight__pct({ isVisible: true }),
    rank_weight__sum({ isVisible: true }),
    fold_weight__sum({ isVisible: true }),
    estimated_search_volume({ isVisible: true }),
    ...SOV_BRAND_METRICS_COLUMNS,
    rank__avg({ isVisible: true }),
    local_rank__avg({ isVisible: true }),
    review_star__avg({ isVisible: true }),
    amazon_choice_count({ isVisible: true }),
    amazon_choice__pct({ isVisible: true }),
    best_seller_count({ isVisible: true }),
    best_seller__pct({ isVisible: true }),
    brand_amazon_choice_count({ isVisible: true }),
    brand_best_seller_count({ isVisible: true }),
    sponsored_products_count({ isVisible: true }),
    sponsored_brands_count({ isVisible: true }),
    sponsored_count({ isVisible: true }),
    organic_count({ isVisible: true }),
    cost__sum({ isVisible: true }),
    impressions__sum({ isVisible: true }),
    brand_video_count({ isVisible: true }),
    video_count({ isVisible: true }),
]

export const SOV_METRICS_CONFIG = makeMapping(SOV_METRICS_COLUMNS)
export const SOV_BRAND_METRICS_CONFIG = makeMapping(SOV_BRAND_METRICS_COLUMNS)

export const SPECIAL_SOV_GROUP_BY_COLUMNS = [
    sovKeywordId({ dataIndex: ['keyword', 'id'] }),

    sovKeywordPlatform({
        dataIndex: ['keyword__platform'],
    }),
    sovKeywordCountryCode({
        dataIndex: ['keyword__country_code'],
    }),
    sovKeywordLanguageCode({
        dataIndex: ['keyword__language_code'],
    }),
    sovKeywordLabelId({
        dataIndex: ['keyword__label_id'],
    }),
    resultType({ dataIndex: ['result_type'] }),
    productLabelId({
        dataIndex: ['product__label_id'],
    }),
    amazonChoice({ dataIndex: ['amazon_choice'] }),
    bestSeller({ dataIndex: ['best_seller'] }),
    layout({ dataIndex: ['layout'] }),
    searchTimeWeekDay({
        dataIndex: ['search_time_week_day'],
    }),
    searchTimeYear({
        dataIndex: ['search_time_year'],
    }),
    searchTimeMonth({
        dataIndex: ['search_time_month'],
    }),
    searchTimeWeek({
        dataIndex: ['search_time_week'],
    }),
    searchTimeDay({
        dataIndex: ['search_time_day'],
    }),
]

export const SOV_TIME_AGGREGATE_COLUMNS = [
    searchTimeDay({
        dataIndex: ['search_time_day'],
    }),
    searchTimeWeek({
        dataIndex: ['search_time_week'],
    }),
    searchTimeMonth({
        dataIndex: ['search_time_month'],
    }),
]
