import { memo, ReactElement } from 'react'

import isEqual from 'lodash/isEqual'

import {
    CAMPAIGN_BUDGET_MIN,
    CAMPAIGN_BUDGET_MAX,
    CAMPAIGN_BUDGET_STEP,
    CAMPAIGN_BUDGET_PRECISION,
} from 'const/campaigns'
import { updatePortfolio } from 'services/cerebroApi/orgScope/resourceApi'
import { PortfolioGroupBy, ProfileGroupBy } from 'types'

import { BudgetField } from '../SharedFields/BudgetField'

interface Props<RecordType> {
    dataIndex: string[]
    readOnly: boolean
    record: RecordType
}

function PortfolioBudgetField<
    RecordType extends PortfolioGroupBy & ProfileGroupBy,
>({
    dataIndex = ['portfolio', 'budget'],
    readOnly = false,
    record,
}: Props<RecordType>): ReactElement {
    const getIsDisabled = (): boolean => {
        return !record.portfolio.user_can_manage
    }

    return (
        <BudgetField
            budgetMax={CAMPAIGN_BUDGET_MAX}
            budgetMin={CAMPAIGN_BUDGET_MIN}
            budgetPrecision={CAMPAIGN_BUDGET_PRECISION}
            budgetStep={CAMPAIGN_BUDGET_STEP}
            currencyCode={record.profile.currency_code}
            disabled={getIsDisabled()}
            fieldPath={dataIndex}
            readOnly={readOnly}
            record={record}
            updateRequestApi={updatePortfolio}
            updateRequestData={record.portfolio.id}
            updateRequestSuccessMesg="Successfully updated portfolio budget"
        />
    )
}

export default memo(
    PortfolioBudgetField,
    isEqual
) as typeof PortfolioBudgetField
