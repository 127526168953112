import { call } from 'redux-saga/effects'

import { fetchOrganizationsSuccess } from 'actions/orgs'
import { cerebroApiSaga } from 'sagas/common'
import { getUserOrganizations } from 'services/cerebroApi/orgScope/resourceApi'

export function* fetchOrganizationsSaga() {
    return yield call(
        cerebroApiSaga,
        fetchOrganizationsSuccess,
        getUserOrganizations,
        { limit: 500 }
    )
}

export function* fetchOrganizationsWorker() {
    yield call(fetchOrganizationsSaga)
}
