import fileDownload from 'js-file-download'

import message from 'utilities/message'
import moment from 'utilities/moment'

export const SYNC_CSV_RECORD_LIMIT = 500

const downloadFile = (data, filename) => fileDownload(data, filename)

const downloadCsvSuccessMessage = (recordCount) => {
    let msg = 'CSV download completed successfully.'
    if (parseInt(recordCount, 10) === SYNC_CSV_RECORD_LIMIT) {
        msg += ` This download has been limited to the first ${SYNC_CSV_RECORD_LIMIT} records.`
    }
    message.success(msg)
}

export const downloadCsv = ({ data, headers }, filename_identifier) => {
    downloadFile(
        data,
        `downstream-${filename_identifier}-${moment().format('YYYY-MM-DD')}.csv`
    )
    downloadCsvSuccessMessage(headers['x-dsi-csv-record-count'])
}
