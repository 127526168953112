import noop from 'lodash/noop'

const createReactotron = () => {
    /* eslint-disable @typescript-eslint/no-var-requires,global-require */
    const Reactotron = require('reactotron-react-js').default
    const { reactotronRedux } = require('reactotron-redux')
    const sagaPlugin = require('reactotron-redux-saga')

    return Reactotron.configure({
        name: 'Forge',
    })
        .use(reactotronRedux())
        .use(sagaPlugin())
        .connect()
}

const mockReactotron = {
    display: noop,
    createSagaMonitor: noop,
}

const reactotron =
    process.env.NODE_ENV === 'development' ? createReactotron() : mockReactotron

export default reactotron
