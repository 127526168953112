import { createAction } from 'redux-actions'

import { curryActionForPath } from 'helpers/curry'
import { serializeColumns } from 'helpers/tables'
import { Field } from 'types'

import {
    downloadDataFailure,
    downloadDataRequest,
    downloadDataSuccess,
    fetchDataFailure,
    fetchDataRequest,
    fetchDataSuccess,
    setDataLoading,
    updateSorter,
} from './data'

// Update Table Pagination
export const updateTablePagination = createAction('UPDATE_TABLE_PAGINATION')
export const makeUpdateTablePagination = curryActionForPath(
    updateTablePagination
)

// Update Table Sorter
export const makeUpdateTableSorter = curryActionForPath(updateSorter)

// Update Period Delta Columns
export const updateTablePeriodDeltaVisibility = createAction(
    'UPDATE_TABLE_PERIOD_DELTA_VISIBILITY'
)
export const makeUpdateTablePeriodDeltaVisibility = curryActionForPath(
    updateTablePeriodDeltaVisibility
)
export const updateTablePeriodDeltaType = createAction(
    'UPDATE_TABLE_PERIOD_DELTA_TYPE'
)
export const makeUpdateTablePeriodDeltaType = curryActionForPath(
    updateTablePeriodDeltaType
)
export const updateTablePeriodDeltaDateRange = createAction(
    'UPDATE_TABLE_PERIOD_DELTA_DATE_RANGE'
)
export const makeUpdateTablePeriodDeltaDateRange = curryActionForPath(
    updateTablePeriodDeltaDateRange
)

// Total Row
export const updateTableTotalRowVisibility = createAction(
    'UPDATE_TABLE_TOTAL_ROW_VISIBILITY'
)
export const makeUpdateTableTotalRowVisibility = curryActionForPath(
    updateTableTotalRowVisibility
)

// Update Table Column Configuration
const curryActionForColumns =
    (action: any) => (path: string[] | string) => (columns: Field<any>[]) =>
        action({
            path,
            columns: serializeColumns(columns),
        })
export const updateTableColumnsConfig = createAction(
    'UPDATE_TABLE_COLUMNS_CONFIG'
)
export const makeUpdateTableColumnsConfig = curryActionForColumns(
    updateTableColumnsConfig
)

// Table Loading State (used to explicitly set table loading state)
export const makeSetTableLoading = curryActionForPath(setDataLoading)

// Table Fetching
export const makeFetchTableRequest = curryActionForPath(fetchDataRequest)
export const makeFetchTableSuccess = curryActionForPath(fetchDataSuccess)
export const makeFetchTableFailure = curryActionForPath(fetchDataFailure)

// Update Table Records
export const updateTableRecord = createAction('UPDATE_TABLE_RECORD')
export const makeUpdateTableRecord = curryActionForPath(updateTableRecord)

// Download Table
export const makeDownloadTableRequest = curryActionForPath(downloadDataRequest)
export const makeDownloadTableSuccess = curryActionForPath(downloadDataSuccess)
export const makeDownloadTableFailure = curryActionForPath(downloadDataFailure)
