import { call, put, select } from 'redux-saga/effects'

import {
    makeFetchTableSuccess,
    makeDownloadTableSuccess,
    makeFetchTableRequest,
} from 'actions/ui/shared'
import { labelSovKeywordsTableColumnsConfig } from 'configuration/tables'
import { DATES } from 'const/filters'
import { LABEL_PAGE } from 'const/pages'
import { formatFilters } from 'helpers/filters/sovSearchResults'
import { generateReportNotification } from 'helpers/notifications'
import {
    formatPagination,
    formatSorter,
    formatMetrics,
    formatColumns,
    formatPeriodDeltaDateRange,
} from 'helpers/params'
import { cerebroApiSaga } from 'sagas/common'
import uiSagaRegistry from 'sagas/ui/registry'
import {
    selectResourceParamsOfPage,
    selectVisibleCombinedFilters,
    selectTableSettings,
    selectVisibleMetricsOfTable,
    selectResourceOfPage,
    selectVisibleColumnsOfTable,
} from 'selectors/ui'
import {
    getSovFactAggregates,
    getSovFactAggregatesExport,
} from 'services/cerebroApi/orgScope/sovSearchResultsApi'

const TAB_PATH = [LABEL_PAGE, 'sovKeywords']
const TABLE_PATH = [...TAB_PATH, 'table']

function* fetchTableSaga(noCount) {
    const { labelId } = yield select(selectResourceParamsOfPage, LABEL_PAGE)
    const filters = yield select(selectVisibleCombinedFilters, TAB_PATH)
    const {
        pagination,
        sorter,
        showPeriodDeltas,
        periodDeltaType,
        periodDeltaDateRange,
    } = yield select(selectTableSettings, TABLE_PATH)
    const metrics = yield select(
        selectVisibleMetricsOfTable,
        TABLE_PATH,
        labelSovKeywordsTableColumnsConfig
    )
    const params = {
        ...formatFilters(filters),
        ...formatPagination(pagination),
        ...formatSorter(sorter),
        ...formatMetrics(metrics, showPeriodDeltas),
        ...formatPeriodDeltaDateRange(
            showPeriodDeltas,
            periodDeltaType,
            periodDeltaDateRange,
            filters[DATES],
            'search_time'
        ),
        group_by: 'keyword_id',
        keyword__labels_id_and_in: labelId,
    }

    yield call(
        cerebroApiSaga,
        makeFetchTableSuccess(TABLE_PATH),
        getSovFactAggregates,
        params,
        { headers: { noCount } }
    )
}

function* downloadTableSaga() {
    const label = yield select(selectResourceOfPage, LABEL_PAGE)
    const filters = yield select(selectVisibleCombinedFilters, TAB_PATH)
    const { sorter, showPeriodDeltas, periodDeltaType, periodDeltaDateRange } =
        yield select(selectTableSettings, TABLE_PATH)
    const columns = yield select(
        selectVisibleColumnsOfTable,
        TABLE_PATH,
        labelSovKeywordsTableColumnsConfig
    )
    const params = {
        ...formatFilters(filters),
        ...formatSorter(sorter),
        ...formatColumns(columns, showPeriodDeltas),
        ...formatPeriodDeltaDateRange(
            showPeriodDeltas,
            periodDeltaType,
            periodDeltaDateRange,
            filters[DATES],
            'search_time'
        ),
        group_by: 'keyword_id',
        labels_id_and_in: label.id,
    }
    const reportName = `SOV Keyword Report - ${label.name}`

    yield call(
        cerebroApiSaga,
        makeDownloadTableSuccess(TABLE_PATH),
        getSovFactAggregatesExport,
        {
            ...params,
            async_download_name: reportName,
        }
    )

    generateReportNotification(reportName)
}

function* fetchTabDataSaga() {
    yield put(makeFetchTableRequest(TABLE_PATH)())
}

// Register Sagas
uiSagaRegistry.registerSagas(TAB_PATH, {
    fetchTabDataSaga,
})

uiSagaRegistry.registerSagas(TABLE_PATH, {
    fetchTableSaga,
    downloadTableSaga,
})
