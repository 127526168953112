import { call, select, put } from 'redux-saga/effects'

import {
    makeFetchTableRequest,
    makeFetchTableSuccess,
    makeDownloadTableSuccess,
} from 'actions/ui/shared'
import { dspAdvertisersTableColumnsConfig } from 'configuration/tables'
import { DATES } from 'const/filters'
import { DSP_SUMMARY_PAGE } from 'const/pages'
import { formatFilters } from 'helpers/filters/dspFacts'
import { generateReportNotification } from 'helpers/notifications'
import {
    formatSorter,
    formatMetrics,
    formatPagination,
    formatCurrency,
    formatColumns,
    formatPeriodDeltaDateRange,
} from 'helpers/params'
import { cerebroApiSaga } from 'sagas/common'
import uiSagaRegistry from 'sagas/ui/registry'
import {
    selectTableSettings,
    selectVisibleMetricsOfTable,
    selectVisibleColumnsOfTable,
    selectCurrencyCode,
    selectVisibleCombinedFilters,
} from 'selectors/ui'
import {
    getDspCampaignFacts,
    getDspCampaignFactsExport,
} from 'services/cerebroApi/orgScope/dspApi'

const TAB_PATH = [DSP_SUMMARY_PAGE, 'advertisers']
const TABLE_PATH = [...TAB_PATH, 'table']
const GROUP_BY = 'advertiser_id,profile_id'

function* fetchTableSaga(noCount) {
    const filters = yield select(selectVisibleCombinedFilters, TAB_PATH)
    const currency = yield select(selectCurrencyCode)

    const {
        pagination,
        sorter,
        showPeriodDeltas,
        periodDeltaType,
        periodDeltaDateRange,
    } = yield select(selectTableSettings, TABLE_PATH)
    const metrics = yield select(
        selectVisibleMetricsOfTable,
        TABLE_PATH,
        dspAdvertisersTableColumnsConfig
    )

    const params = {
        ...formatPagination(pagination),
        ...formatSorter(sorter),
        ...formatFilters(filters),
        ...formatMetrics(metrics, showPeriodDeltas),
        ...formatCurrency(currency),
        ...formatPeriodDeltaDateRange(
            showPeriodDeltas,
            periodDeltaType,
            periodDeltaDateRange,
            filters[DATES]
        ),
        group_by: GROUP_BY,
    }

    yield call(
        cerebroApiSaga,
        makeFetchTableSuccess(TABLE_PATH),
        getDspCampaignFacts,
        params,
        { headers: { noCount } }
    )
}

function* downloadTableSaga() {
    const filters = yield select(selectVisibleCombinedFilters, TAB_PATH)
    const { sorter, showPeriodDeltas, periodDeltaType, periodDeltaDateRange } =
        yield select(selectTableSettings, TABLE_PATH)
    const columns = yield select(
        selectVisibleColumnsOfTable,
        TABLE_PATH,
        dspAdvertisersTableColumnsConfig
    )
    const currency = yield select(selectCurrencyCode)
    const reportName = 'All DSP Advertisers Report'
    const params = {
        ...formatFilters(filters),
        ...formatSorter(sorter),
        ...formatColumns(columns, showPeriodDeltas),
        ...formatCurrency(currency),
        ...formatPeriodDeltaDateRange(
            showPeriodDeltas,
            periodDeltaType,
            periodDeltaDateRange,
            filters[DATES]
        ),
        group_by: GROUP_BY,
        async_download_name: reportName,
    }

    yield call(
        cerebroApiSaga,
        makeDownloadTableSuccess(TABLE_PATH),
        getDspCampaignFactsExport,
        params
    )

    generateReportNotification(reportName)
}

function* fetchTabDataSaga() {
    yield put(makeFetchTableRequest(TABLE_PATH)())
}

// Register Sagas
uiSagaRegistry.registerSagas(TAB_PATH, {
    fetchTabDataSaga,
})

uiSagaRegistry.registerSagas(TABLE_PATH, {
    fetchTableSaga,
    downloadTableSaga,
})
