import { SVGAttributes, ReactElement } from 'react'

type Props = SVGAttributes<SVGElement>

function SvgPriceIcon(props: Props): ReactElement {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M16.75 3H10.7503C10.6828 3 10.6203 3.0275 10.5728 3.0725L2.07312 11.5725C1.97563 11.67 1.97563 11.83 2.07312 11.9275L8.07287 17.9275C8.12287 17.975 8.18537 18 8.25036 18C8.31536 18 8.37786 17.975 8.42786 17.9275L16.9275 9.4275C16.9725 9.38 17 9.3175 17 9.25V3.25C17 3.1125 16.8875 3 16.75 3ZM14.0001 7.25C13.3102 7.25 12.7502 6.69 12.7502 6C12.7502 5.31 13.3102 4.75 14.0001 4.75C14.6901 4.75 15.2501 5.31 15.2501 6C15.2501 6.69 14.6901 7.25 14.0001 7.25Z" />
        </svg>
    )
}

export default SvgPriceIcon
