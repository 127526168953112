import { Translation } from 'react-i18next'

const translations = {
    net_received_inventory_amount: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:vendorCentral.net_received_amount.name',
                        'Net Received Amount'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:vendorCentral.net_received_amount.shortName',
                        'Net Received Amount'
                    )
                }
            </Translation>
        ),
        helpText: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:vendorCentral.net_received_amount.helpText',
                        'Net amount received by Amazon after subtracting the amount returned to the supplier in the selected time frame.'
                    )
                }
            </Translation>
        ),
    },
    net_received_inventory_units: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:vendorCentral.net_received_units.name',
                        'Net Received Units'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:vendorCentral.net_received_units.shortName',
                        'Net Received Units'
                    )
                }
            </Translation>
        ),
        helpText: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:vendorCentral.net_received_units.helpText',
                        'Net units received by Amazon after subtracting the units returned to the supplier in the selected time frame.'
                    )
                }
            </Translation>
        ),
    },
    open_purchase_order_units: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:vendorCentral.open_purchase_order_units.name',
                        'Open Purchase Order Quantity'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:vendorCentral.open_purchase_order_units.shortName',
                        'Open Purchase Order Quantity'
                    )
                }
            </Translation>
        ),
        helpText: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:vendorCentral.open_purchase_order_units.helpText',
                        "Number of units included in the vendor's open purchase orders. For example, confirmed purchase order quantities that are not yet received."
                    )
                }
            </Translation>
        ),
    },
    unfilled_customer_ordered_units: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:vendorCentral.unfilled_customer_ordered_units.name',
                        'Unfilled Customer Ordered Units'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:vendorCentral.unfilled_customer_ordered_units.shortName',
                        'Unfilled Customer Ordered Units'
                    )
                }
            </Translation>
        ),
        helpText: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:vendorCentral.unfilled_customer_ordered_units.helpText',
                        'Number of units ordered (or pre-ordered), but have not shipped.'
                    )
                }
            </Translation>
        ),
    },
    sellable_on_hand_inventory_cost: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:vendorCentral.sellable_on_hand_inventory_cost.name',
                        'Sellable On Hand Inventory Amount'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:vendorCentral.sellable_on_hand_inventory_cost.shortName',
                        'Sellable On Hand Inventory Amount'
                    )
                }
            </Translation>
        ),
        helpText: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:vendorCentral.sellable_on_hand_inventory_cost.helpText',
                        'The cost of inventory in Amazon fulfilment centres that is in sellable condition on the last day of the selected time frame.'
                    )
                }
            </Translation>
        ),
    },
    sellable_on_hand_inventory_units: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:vendorCentral.sellable_on_hand_inventory_units.name',
                        'Sellable On Hand Units'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:vendorCentral.sellable_on_hand_inventory_units.shortName',
                        'Sellable On Hand Units'
                    )
                }
            </Translation>
        ),
        helpText: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:vendorCentral.sellable_on_hand_inventory_units.helpText',
                        'Number of units in Amazon fulfilment centres that is in sellable condition on the last day of the selected time frame.'
                    )
                }
            </Translation>
        ),
    },
    unsellable_on_hand_inventory_cost: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:vendorCentral.unsellable_on_hand_inventory_cost.name',
                        'Unsellable On Hand Inventory Amount'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:vendorCentral.unsellable_on_hand_inventory_cost.shortName',
                        'Unsellable On Hand Inventory Amount'
                    )
                }
            </Translation>
        ),
        helpText: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:vendorCentral.unsellable_on_hand_inventory_cost.helpText',
                        'The cost of inventory in Amazon fulfilment centres that is in unsellable condition on the last day of the selected time frame.'
                    )
                }
            </Translation>
        ),
    },
    unsellable_on_hand_inventory_units: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:vendorCentral.unsellable_on_hand_inventory_units.name',
                        'Unsellable On Hand Inventory Units'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:vendorCentral.unsellable_on_hand_inventory_units.shortName',
                        'Unsellable On Hand Inventory Units'
                    )
                }
            </Translation>
        ),
        helpText: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:vendorCentral.unsellable_on_hand_inventory_units.helpText',
                        'Number of units in Amazon fulfilment centres that is in unsellable condition on the last day of the selected time frame.'
                    )
                }
            </Translation>
        ),
    },
    // Check for error
    aged_90_plus_days_sellable_inventory_cost: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:vendorCentral.aged_90_plus_days_sellable_inventory_cost.name',
                        'Aged 90+ Days Sellable Inventory'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:vendorCentral.aged_90_plus_days_sellable_inventory_cost.shortName',
                        'Aged 90+ Days Sellable Inventory'
                    )
                }
            </Translation>
        ),
        helpText: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:vendorCentral.aged_90_plus_days_sellable_inventory_cost.helpText',
                        'Cost of inventory that is at least 90 days old and in sellable condition on the last day of the selected time frame.'
                    )
                }
            </Translation>
        ),
    },
    aged_90_plus_days_sellable_inventory_units: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:vendorCentral.aged_90_plus_days_sellable_inventory_units.name',
                        'Aged 90+ Days Sellable Units'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:vendorCentral.aged_90_plus_days_sellable_inventory_units.shortName',
                        'Aged 90+ Days Sellable Units'
                    )
                }
            </Translation>
        ),
        helpText: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:vendorCentral.aged_90_plus_days_sellable_inventory_units.helpText',
                        'Number of units that are at least 90 days old and in sellable condition on the last day of the selected time frame.'
                    )
                }
            </Translation>
        ),
    },
    unhealthy_inventory_cost: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:vendorCentral.unhealthy_inventory_cost.name',
                        'Unhealthy Inventory Amount'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:vendorCentral.unhealthy_inventory_cost.shortName',
                        'Unhealthy Inventory Amount'
                    )
                }
            </Translation>
        ),
        helpText: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:vendorCentral.unhealthy_inventory_cost.helpText',
                        'Excess cost of inventory on the last day of the selected time frame based on forecasted demand.'
                    )
                }
            </Translation>
        ),
    },
    unhealthy_inventory_units: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:vendorCentral.unhealthy_inventory_units.name',
                        'Unhealthy Inventory Units'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:vendorCentral.unhealthy_inventory_units.shortName',
                        'Unhealthy Inventory Units'
                    )
                }
            </Translation>
        ),
        helpText: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:vendorCentral.unhealthy_inventory_units.helpText',
                        'Excess units of inventory on the last day of the selected time frame based on forecasted demand.'
                    )
                }
            </Translation>
        ),
    },
    average_vendor_lead_time_days: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:vendorCentral.average_vendor_lead_time_days.name',
                        'Overall Vendor Lead Time (Days)'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:vendorCentral.average_vendor_lead_time_days.shortName',
                        'Overall Vendor Lead Time (Days)'
                    )
                }
            </Translation>
        ),
        helpText: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:vendorCentral.average_vendor_lead_time_days.helpText',
                        'Time (in days) from when the vendor receives a purchase order from Amazon, to when inventory is received in a fulfilment centre. Selecting a date range greater than one day will display an average of daily averages which may not match exactly with the Vendor Central console.'
                    )
                }
            </Translation>
        ),
    },
    procurable_product_out_of_stock_rate: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:vendorCentral.procurable_product_out_of_stock_rate.name',
                        'Procurable Product OOS'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:vendorCentral.procurable_product_out_of_stock_rate.shortName',
                        'Procurable Product OOS'
                    )
                }
            </Translation>
        ),
        helpText: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:vendorCentral.procurable_product_out_of_stock_rate.helpText',
                        'Out of stock (OOS) rate on all products that are procurable. Procurability refers to whether Amazon is able to obtain an ASIN from a vendor without a purchase order delay. Procurable Product OOS = OOS Glance Views (GVs) on Procurable ASINS / Total GVs. Selecting a date range greater than one day will display an average of daily rates which may not match exactly with the Vendor Central console.'
                    )
                }
            </Translation>
        ),
    },
    sourceable_product_out_of_stock_rate: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:vendorCentral.sourceable_product_out_of_stock_rate.name',
                        'Sourceable Product OOS'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:vendorCentral.sourceable_product_out_of_stock_rate.shortName',
                        'Sourceable Product OOS'
                    )
                }
            </Translation>
        ),
        helpText: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:vendorCentral.sourceable_product_out_of_stock_rate.helpText',
                        'An expansion of Procurable Product OOS that includes all products where Amazon believes a source of supply may exist in the OOS calculation. Selecting a date range greater than one day will display an average of daily rates which may not match exactly with the Vendor Central console.'
                    )
                }
            </Translation>
        ),
    },
    receive_fill_rate: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:vendorCentral.receive_fill_rate.name',
                        'Receive Fill Rate'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:vendorCentral.receive_fill_rate.shortName',
                        'Receive Fill Rate'
                    )
                }
            </Translation>
        ),
        helpText: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:vendorCentral.receive_fill_rate.helpText',
                        'Purchase order units received by Amazon in comparison to purchase order units confirmed by the vendor. The fill rate here applies only to procurable ASINs. Procurability refers to whether Amazon is able to obtain an ASIN from a vendor without a purchase order delay. Fill Rate = received quantity / confirmed quantity. Selecting a date range greater than one day will display an average of daily rates which may not match exactly with the Vendor Central console.'
                    )
                }
            </Translation>
        ),
    },
    vendor_confirmation_rate: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:vendorCentral.vendor_confirmation_rate.name',
                        'Vendor Confirmation Rate'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:vendorCentral.vendor_confirmation_rate.shortName',
                        'Vendor Confirmation Rate'
                    )
                }
            </Translation>
        ),
        helpText: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:vendorCentral.vendor_confirmation_rate.helpText',
                        'How many units vendors confirm out of the units Amazon asks for, accounting for procurable ASINs only. Procurability refers to whether Amazon is able to obtain an ASIN from a vendor without a purchase order delay. Vendor confirmation rate = Quantity confirmed / quantity submitted. Selecting a date range greater than one day will display an average of daily rates which may not match exactly with the Vendor Central console.'
                    )
                }
            </Translation>
        ),
    },
    uft: {
        name: (
            <Translation>
                {(t) => t('metrics:vendorCentral.uft.name', 'UFT')}
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) =>
                    t('metrics:vendorCentral.uft.shortName', 'Ultra Fast Track')
                }
            </Translation>
        ),
        helpText: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:vendorCentral.uft.helpText',
                        'Ultra Fast Track (UFT) is the percentage of time that an ASIN can be shipped to the customer from a fulfilment centre, versus the selection Amazon intended to carry.'
                    )
                }
            </Translation>
        ),
    },
    sell_through_rate: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:vendorCentral.sell_through_rate.name',
                        'Sell Through Rate'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:vendorCentral.sell_through_rate.shortName',
                        'Sell Through Rate'
                    )
                }
            </Translation>
        ),
        helpText: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:vendorCentral.sell_through_rate.helpText',
                        'Calculated as the number of units shipped in comparison to the number of units at Amazon at the start of the period, plus any units received during the same time period. Sell Through Rate = (Shipped units - customer returns) / (On hand units + received units). Selecting a date range greater than one day will display an average of daily rates which may not match exactly with the Vendor Central console.'
                    )
                }
            </Translation>
        ),
    },
}

export default translations
