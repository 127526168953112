import { ReactElement, useState } from 'react'

import { Alert, Button, Typography } from 'antd'
import { AxiosResponse } from 'axios'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { object, string } from 'yup'

import { FormikInput } from 'components/formik'
import { useUserContext } from 'context/UserContext'
import { useCerebroApiRequest } from 'hooks'
import { getMfaSettingsAccessToken } from 'services/cerebroApi/noScope/resourceApi'

import styles from './styles.scss'

interface AccessTokenFormProps {
    onAccessTokenAcquired: (accessToken: string) => void
}

const AccessTokenForm = (props: AccessTokenFormProps): ReactElement => {
    const { t } = useTranslation('account')
    const { currentUser } = useUserContext()
    const makeCerebroApiRequest = useCerebroApiRequest()
    const { onAccessTokenAcquired } = props
    const validationSchema = object().shape({
        password: string().required().label(t('account:password', 'Password')),
    })
    const [authFailed, setAuthFailed] = useState(false)

    async function getAccessToken(password: string): Promise<void> {
        if (currentUser?.email) {
            await makeCerebroApiRequest({
                request: getMfaSettingsAccessToken(currentUser.email, password),
                onRequestSuccess: (
                    response: AxiosResponse<{ access_token: string }>
                ) => {
                    onAccessTokenAcquired(response.data.access_token)
                },
                onRequestFailure: () => {
                    setAuthFailed(true)
                },
                suppressErrorAlerts: true,
            })
        }
    }

    return (
        <div>
            <h1>
                {t('account:AccessTokenForm.header', 'Add your phone number')}
            </h1>
            <Typography>
                {t(
                    'account:AccessTokenForm.description',
                    'For added security, please set up two-factor authentication.'
                )}
            </Typography>
            <div style={{ marginTop: 16 }}>
                <Alert
                    message={t(
                        'account:AccessTokenForm.password.explanation',
                        'As we are making security changes to your account, we need to verify your password first.'
                    )}
                    style={{ marginBottom: 16 }}
                />
                <Typography>
                    {t(
                        'account:AccessTokenForm.password.label',
                        'Please enter the password for {{ email }}',
                        {
                            email: currentUser?.email,
                        }
                    )}
                </Typography>

                <Formik
                    initialValues={{ password: '' }}
                    validationSchema={validationSchema}
                    onSubmit={async (values) => {
                        setAuthFailed(false)
                        await getAccessToken(values.password)
                    }}
                >
                    {({ isSubmitting }) => (
                        <Form>
                            <div className="mb-2">
                                <Field
                                    className={styles.input_box}
                                    component={FormikInput}
                                    name="password"
                                    size="large"
                                    id="password"
                                    autoComplete="off"
                                    type="password"
                                    placeholder="password"
                                />
                                <ErrorMessage
                                    name="password"
                                    component="div"
                                    className="fg-control-text is-error"
                                />
                            </div>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={isSubmitting}
                                size="large"
                            >
                                {t('common:continue', 'Continue')}
                            </Button>
                            {authFailed && (
                                <div className="mt-3">
                                    <Alert
                                        message={t(
                                            'account:AccessTokenForm.password.invalid',
                                            'Invalid password'
                                        )}
                                        type="error"
                                    />
                                </div>
                            )}
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default AccessTokenForm
