import flow from 'lodash/fp/flow'
import mapValues from 'lodash/fp/mapValues'
import set from 'lodash/fp/set'
import unset from 'lodash/fp/unset'
import update from 'lodash/fp/update'
import get from 'lodash/get'
import has from 'lodash/has'
import isArray from 'lodash/isArray'
import pick from 'lodash/pick'
import { validate as isUuid } from 'uuid'

import {
    fetchFilterSettingsSuccess,
    fetchFiltersSuccess,
    resetFilters,
    resetLocalFilters,
    updateFilter,
    updateFilterSettings,
    updateLocalFilter,
} from 'actions/ui/shared/filter'
import { DATES } from 'const/filters'
import { DASHBOARD_PAGE, HOME_PAGE } from 'const/pages'
import {
    FILTER_SETTINGS,
    FILTER_UPDATED_AT,
    FILTERS,
    LOCAL_FILTERS,
} from 'const/reducerKeys'
import { getCurrentTimestamp } from 'helpers/utils'
import { Tab } from 'types/dashboards'

import { defaultDatesFilter } from '../defaults'
import { getPristineUiStateClone } from '../ui'

export default {
    // Filters
    [fetchFiltersSuccess.toString()](
        state: any,
        action: ReturnType<typeof fetchFiltersSuccess>
    ) {
        const {
            path,
            data: { filters },
        } = action.payload

        return flow(
            set([...path, FILTERS], filters),
            set([...path, FILTER_UPDATED_AT], getCurrentTimestamp())
        )(state)
    },
    [updateLocalFilter.toString()](
        state: any,
        action: ReturnType<typeof updateLocalFilter>
    ) {
        const {
            path,
            data: { key, value },
        } = action.payload

        const updateFn =
            value === undefined
                ? unset([...path, LOCAL_FILTERS, key])
                : set([...path, LOCAL_FILTERS, key], value)

        let newState = flow(
            updateFn,
            set([...path, FILTER_UPDATED_AT], getCurrentTimestamp())
        )(state)

        const page = path[0]
        if (page === DASHBOARD_PAGE || page === HOME_PAGE) {
            newState = set([page, 'isDirty'], true, newState)
        }

        if (has(newState, [...path, 'tabs'])) {
            return update(
                [...path, 'tabs'],
                mapValues((tab: Partial<Tab>) => ({
                    ...tab,
                    loaded: false,
                }))
            )(newState)
        }

        return newState
    },
    [updateFilter.toString()](
        state: any,
        action: ReturnType<typeof updateFilter>
    ) {
        const {
            path,
            data: { key, value },
        } = action.payload

        const newState = flow(
            set([...path, FILTERS, key], value),
            set([...path, FILTER_UPDATED_AT], getCurrentTimestamp())
        )(state)

        if (has(newState, [...path, 'tabs'])) {
            return update(
                [...path, 'tabs'],
                mapValues((tab: Partial<Tab>) => ({
                    ...tab,
                    loaded: false,
                }))
            )(newState)
        }

        return newState
    },
    [resetFilters.toString()](
        state: any,
        action: ReturnType<typeof resetFilters>
    ) {
        const { path } = action.payload
        const defaultUiStateClone = getPristineUiStateClone()
        const pageDefaultState = get(defaultUiStateClone, path)

        if (pageDefaultState) {
            const newState = flow(
                set([...path, FILTERS], pageDefaultState[FILTERS]),
                set([...path, LOCAL_FILTERS], pageDefaultState[LOCAL_FILTERS]),
                set(
                    [...path, FILTER_SETTINGS],
                    pageDefaultState[FILTER_SETTINGS]
                ),
                set([...path, FILTER_UPDATED_AT], getCurrentTimestamp())
            )(state)

            if (has(newState, [...path, 'tabs'])) {
                return update(
                    [...path, 'tabs'],
                    mapValues((tab: Partial<Tab>) => ({
                        ...tab,
                        loaded: false,
                    }))
                )(newState)
            }

            return newState
        }

        return state
    },
    [resetLocalFilters.toString()](
        state: any,
        action: ReturnType<typeof resetLocalFilters>
    ) {
        const { path } = action.payload
        const defaultUiStateClone = getPristineUiStateClone()
        const pageDefaultState = get(defaultUiStateClone, path)

        if (pageDefaultState) {
            return set(
                [...path, LOCAL_FILTERS],
                pageDefaultState[LOCAL_FILTERS],
                state
            )
        }

        // If path refers to a specific tab
        const pathTail = path.slice(-2)
        if (pathTail[0] === 'tabs' && isUuid(pathTail[1])) {
            const page = path[0]
            return flow(
                set([page, 'isDirty'], true),
                set([...path, LOCAL_FILTERS], { [DATES]: defaultDatesFilter })
            )(state)
        }

        return state
    },

    // Filter Settings
    [updateFilterSettings.toString()](
        state: any,
        action: ReturnType<typeof updateFilterSettings>
    ) {
        const {
            path,
            data: { anchored, order, displayState },
        } = action.payload

        return update(
            [...path, FILTER_SETTINGS],
            (filterSettings) => ({
                // Absorb anchored only when array is provided
                anchored: isArray(anchored)
                    ? anchored
                    : filterSettings.anchored,

                // Absorb order only when array is provided
                order: isArray(order) ? order : filterSettings.order,

                // We *MERGE* display state to update
                // the display state of filters given only
                displayState: {
                    ...filterSettings.displayState,
                    ...displayState,
                },
            }),
            state
        )
    },
    [fetchFilterSettingsSuccess.toString()](
        state: any,
        action: ReturnType<typeof fetchFilterSettingsSuccess>
    ) {
        const { path, data: filterSettings } = action.payload

        return set(
            [...path, FILTER_SETTINGS],
            pick(filterSettings, ['order', 'anchored', 'displayState']),
            state
        )
    },
}
