import { handleActions } from 'redux-actions'

import {
    CAMPAIGN_NAME,
    DATES,
    CAMPAIGN_BUDGET,
    CAMPAIGN_STATES,
    CAMPAIGN_TARGETING_TYPES,
    AGGREGATION,
    FACT_TYPES,
    LABELS,
    METRIC_FILTERS,
} from 'const/filters'
import { AD_ACCOUNT_PAGE } from 'const/pages'
import { LOCAL_FILTERS, FILTER_SETTINGS } from 'const/reducerKeys'

import {
    defaultResourcePageTabs,
    defaultResourcePageWidgets,
    defaultResourcePage,
} from '../defaults'

const defaultState = {
    [LOCAL_FILTERS]: {
        [CAMPAIGN_NAME]: null,
        [FACT_TYPES]: [],
        [CAMPAIGN_BUDGET]: [],
        [CAMPAIGN_STATES]: [],
        [CAMPAIGN_TARGETING_TYPES]: [],
        [LABELS]: [],
        [METRIC_FILTERS]: [],
    },
    [FILTER_SETTINGS]: {
        anchored: [CAMPAIGN_NAME, DATES, AGGREGATION, FACT_TYPES],
        order: [
            CAMPAIGN_STATES,
            CAMPAIGN_BUDGET,
            CAMPAIGN_TARGETING_TYPES,
            LABELS,
            METRIC_FILTERS,
        ],
        displayState: {
            [CAMPAIGN_STATES]: true,
            [CAMPAIGN_BUDGET]: true,
            [CAMPAIGN_TARGETING_TYPES]: true,
            [LABELS]: true,
            [METRIC_FILTERS]: true,
        },
    },

    ...defaultResourcePage,
    tabs: defaultResourcePageTabs,
    widgets: defaultResourcePageWidgets(AD_ACCOUNT_PAGE),
}

export default handleActions({}, defaultState)
