import { ReactElement } from 'react'

import { useAppConfig } from 'appConfig'
import { UNDEFINED_VALUE } from 'const/formatting'

interface Props {
    email: any
}

export default function UserEmail({ email }: Props): ReactElement {
    const appConfig = useAppConfig()
    if (email === 'operations+automation@downstreamimpact.com') {
        return <span>{appConfig.productTitle}</span>
    }
    return email ?? UNDEFINED_VALUE
}
