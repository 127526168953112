import { SVGAttributes, ReactElement } from 'react'

type Props = SVGAttributes<SVGElement>

function SvgDownstreamLogoIcon(props: Props): ReactElement {
    return (
        <svg viewBox="0 0 40 40" width="1em" height="1em" {...props}>
            <path d="M33.347 34.839a7.151 7.151 0 01-3.265.571 10.872 10.872 0 01-8.482-4.26h.2c4.7-.175 7.425-2.352 9.594-6.787.03-.076.061-.152.09-.229a12.9 12.9 0 00.807-4.376A11.371 11.371 0 0021.066 8.361a11.748 11.748 0 00-11.241 8.817 14.186 14.186 0 014.263-1.415c-.151.094-.493.142-1.89 2.592l-.079.136c-.308.554-.657 1.339-.538 1.075a18.745 18.745 0 00-.711 1.953l-.033.116q-.063.229-.118.462l-.029.127c-.037.16-.071.321-.1.483 0 .015-.005.03-.008.045a14.25 14.25 0 002.577 11.185 12.808 12.808 0 01-1.844-6.64 12.975 12.975 0 01.107-1.637l.024.036A13.125 13.125 0 0121.709 14.71 13.113 13.113 0 0028 37.5a.183.183 0 01.064.02 13.042 13.042 0 001.3.153A20 20 0 1140 20c0 5.916-2.433 12.025-6.653 14.839z" />
        </svg>
    )
}

export default SvgDownstreamLogoIcon
