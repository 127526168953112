import { handleActions } from 'redux-actions'

import { DASHBOARD_NAME } from 'const/filters'
import { FILTER_SETTINGS, LOCAL_FILTERS } from 'const/reducerKeys'
import { defaultPage } from 'reducers/ui/defaults'

const defaultState = {
    [LOCAL_FILTERS]: {
        [DASHBOARD_NAME]: null,
    },
    [FILTER_SETTINGS]: {
        anchored: [DASHBOARD_NAME],
        order: [],
        displayState: {},
    },
    ...defaultPage,
}

export default handleActions({}, defaultState)
