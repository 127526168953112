import { ReactElement } from 'react'

import classNames from 'classnames'

import { ColumnConfig } from './localTypes'
import styles from './styles.scss'

interface Props {
    columnsConfig: ColumnConfig[]
}

export default function CreateKeywordFormHeader({
    columnsConfig,
}: Props): ReactElement {
    return (
        <div className="d-flex align-items-start">
            <div
                style={{ flex: 1 }}
                className="mt-2 mr-1 pl-2 fg-control-label"
            >
                Keyword
            </div>

            {columnsConfig.map(({ width, name, dataKey }) => {
                return (
                    <div
                        className={classNames('mt-2 mr-1 fg-control-label')}
                        style={{
                            width,
                        }}
                        key={dataKey}
                    >
                        {name}
                    </div>
                )
            })}

            <div
                className={classNames('mt-2 mr-1 ', styles['remove-column'])}
            />
        </div>
    )
}
