import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'
import isUndefined from 'lodash/isUndefined'
import union from 'lodash/union'
import xorBy from 'lodash/xorBy'
import { renderToString } from 'react-dom/server'

import { ALL_IDS } from 'const/reducerKeys'

/**
 * Customizer function used in conjunction with lodash mergeWith
 * to handle the use case of merging two arrays.
 *
 * Lodash merge by default always replaces objValue with srcValue,
 * but we have a special case in our entities ALL_IDS property
 * where we want the union of the two arrays returned from merge.
 *
 * @param {*} objValue The destination value
 * @param {*} srcValue The source value
 * @param {*} key The key of the values being merged
 */
export const mergeArray = (objValue, srcValue, key) => {
    if (isArray(objValue)) {
        if (key === ALL_IDS) {
            return union(objValue, srcValue)
        }
        return srcValue
    }

    return undefined
}

/**
 * Checks if value is unset, that is, null or undefined
 *
 * @param {unknown} value
 * @returns {boolean}
 */
export const isUnset = (value) => isUndefined(value) || isNull(value)

/**
 * Renders something that may not be a string into a string
 *
 * @param {any} maybeElement
 * @returns {string}
 */
export const elementToString = (maybeElement) =>
    typeof maybeElement === 'string'
        ? maybeElement
        : renderToString(maybeElement)

/**
 * Compare 2 arrays by iteratee
 *
 * @param {any[]} values1 Array
 * @param {any[]} values2 Array
 * @param {Function|string} iteratee Function to be used to compare elements
 * @returns {boolean} - TRUE if 2 arrays are the same
 */
export const isArrayEqualBy = (values1, values2, iteratee) =>
    isEmpty(xorBy(values1, values2, iteratee))

export const addCache = (func) => {
    func.cache = true
}
