import { ReactElement, ReactNode } from 'react'

import { InfoCircleOutlined } from '@ant-design/icons'
import i18n from 'i18next'
import { Translation } from 'react-i18next'

import { AutomationTypeContainer } from 'components/AutomationType'
import { adGroupIdRenderer } from 'components/FieldRenderers/Renderers/adGroup'
import {
    campaignIdRenderer,
    campaignLabelIdRenderer,
} from 'components/FieldRenderers/Renderers/campaign'
import {
    portfolioIdRenderer,
    portfolioLabelIdRenderer,
} from 'components/FieldRenderers/Renderers/portfolio'
import { ToolTip } from 'components/ToolTip'
import { adGroupId } from 'configuration/fieldCreators/adGroup'
import {
    campaignCapabilityId,
    campaignId,
    campaignLabelId,
    campaignState,
} from 'configuration/fieldCreators/campaign'
import {
    attributed_orders_new_to_brand_14_day__sum,
    attributed_sales_14_day__sum,
    attributed_sales_new_to_brand_14_day__sum,
    attributed_units_sold_14_day__sum,
} from 'configuration/fieldCreators/metrics/ams'
import {
    portfolioId,
    portfolioLabelId,
} from 'configuration/fieldCreators/portfolio'
import {
    attributed_orders_14_day__sum,
    attributed_orders_new_to_brand_percentage_14_day__avg,
    attributed_sales_new_to_brand_percentage_14_day_avg,
    attributed_units_ordered_new_to_brand_percentage_14_day__avg,
    attributed_units_sold_new_to_brand_14_day__sum,
    attributionType,
    productId,
    product_category,
} from 'configuration/fieldCreators/sbPurchasedProduct'
import { AMS_CHART_FORMATTERS } from 'helpers/charts'
import { isUnset } from 'helpers/utilities'
import { ChartFormatters } from 'types'

import { makeMapping } from './helpers'

const LegacyCampaignName = (): string =>
    i18n.t(
        'table:sb_purchased_products.fields.legacyCampaign.title',
        'Legacy Sponsored Brand Campaign'
    )

interface LegacyCampaignFieldProps {
    title?: ReactNode
    description?: ReactNode
}

const LegacyCampaignField = ({
    title,
    description,
}: LegacyCampaignFieldProps): ReactElement => (
    <span>
        {title || (
            <Translation>
                {(t) =>
                    t(
                        'table:sb_purchased_products.fields.legacyCampaign.title',
                        'Legacy Sponsored Brand Campaign'
                    )
                }
            </Translation>
        )}{' '}
        <ToolTip
            title={
                description || (
                    <Translation>
                        {(t) =>
                            t(
                                'table:sb_purchased_products.fields.legacyCampaign.description',
                                "Legacy Sponsored Brand Campaigns (campaigns created before October 24, 2022) can't be identified individually."
                            )
                        }
                    </Translation>
                )
            }
        >
            <InfoCircleOutlined />
        </ToolTip>
    </span>
)

export const SPECIAL_SB_PURCHASED_PRODUCTS_GROUP_BY_COLUMNS = [
    productId(),
    attributionType(),
    product_category(),
    adGroupId({
        dataIndex: ['ad_group', 'id'],
        renderOptions: {
            render: (props) =>
                adGroupIdRenderer<any>({
                    ...props,
                    undefinedValue: <LegacyCampaignField />,
                }),
        },
    }),
    campaignId({
        dataIndex: ['campaign', 'id'],
        renderOptions: {
            render: (props) =>
                campaignIdRenderer<any>({
                    ...props,
                    undefinedValue: <LegacyCampaignField />,
                }),
        },
    }),
    campaignState({
        dataIndex: ['campaign__state'],
        renderOptions: {
            render: (props) =>
                campaignIdRenderer<any>({
                    ...props,
                    undefinedValue: <LegacyCampaignField />,
                }),
        },
    }),
    campaignLabelId({
        dataIndex: ['campaign__label_id'],
        renderOptions: {
            render: (props) =>
                campaignLabelIdRenderer<any>({
                    ...props,
                    undefinedValue: (
                        <LegacyCampaignField
                            description={
                                <Translation>
                                    {(t) =>
                                        t(
                                            'table:sb_purchased_products.fields.legacyCampaign.campaignLabel.description',
                                            "Includes Campaigns with no labels and Legacy Sponsored Brand Campaigns (campaigns created before October 24, 2022) that can't be identified individually."
                                        )
                                    }
                                </Translation>
                            }
                            title={
                                <Translation>
                                    {(t) =>
                                        t(
                                            'table:sb_purchased_products.fields.legacyCampaign.notIndentifiedLabel.title',
                                            'Unlabeled / Not Identifiable'
                                        )
                                    }
                                </Translation>
                            }
                        />
                    ),
                }),
        },
    }),
    campaignCapabilityId({
        dataIndex: ['automation_capability_id'],
        renderOptions: {
            render: ({ value }) =>
                isUnset(value) ? (
                    <LegacyCampaignField
                        description={
                            <Translation>
                                {(t) =>
                                    t(
                                        'table:sb_purchased_products.fields.legacyCampaign.automation.description',
                                        "Includes Campaigns with no automation enabled and Legacy Sponsored Brand Campaigns (campaigns created before October 24, 2022) that can't be identified individually."
                                    )
                                }
                            </Translation>
                        }
                        title={
                            <Translation>
                                {(t) =>
                                    t(
                                        'table:sb_purchased_products.fields.legacyCampaign.notIndentifiedAutomation.title',
                                        'No Automation / Not Identifiable'
                                    )
                                }
                            </Translation>
                        }
                    />
                ) : (
                    <AutomationTypeContainer capabilityId={value} />
                ),
        },
    }),
    portfolioId({
        dataIndex: ['portfolio', 'id'],
        renderOptions: {
            render: (props) =>
                portfolioIdRenderer<any>({
                    ...props,
                    undefinedValue: <LegacyCampaignField />,
                }),
        },
    }),
    portfolioLabelId({
        dataIndex: ['portfolio__label_id'],
        renderOptions: {
            render: (props) =>
                portfolioLabelIdRenderer<any>({
                    ...props,
                    undefinedValue: (
                        <LegacyCampaignField
                            description={
                                <Translation>
                                    {(t) =>
                                        t(
                                            'table:sb_purchased_products.fields.legacyCampaign.portfolioLabel.description',
                                            "Includes Portfolios with no labels and Legacy Sponsored Brand Campaigns (campaigns created before October 24, 2022) that can't be identified individually."
                                        )
                                    }
                                </Translation>
                            }
                            title={
                                <Translation>
                                    {(t) =>
                                        t(
                                            'table:sb_purchased_products.fields.legacyCampaign.notIndentifiedLabel.title',
                                            'Unlabeled / Not Identifiable'
                                        )
                                    }
                                </Translation>
                            }
                        />
                    ),
                }),
        },
    }),
]

export const SB_PURCHASED_PRODUCTS_GROUP_BY_CONFIG: ChartFormatters = {
    ...AMS_CHART_FORMATTERS,
    ad_group_id: {
        displayName: (record) => {
            if (!record?.ad_group) {
                return LegacyCampaignName()
            }
            return (
                AMS_CHART_FORMATTERS.ad_group_id?.displayName?.(record) ??
                record.ad_group.name
            )
        },
    },
    campaign_id: {
        displayName: (record) => {
            if (!record?.campaign) {
                return LegacyCampaignName()
            }
            return (
                AMS_CHART_FORMATTERS.campaign_id?.displayName?.(record) ??
                record.campaign.name
            )
        },
    },
}

const SB_PURCHASED_PRODUCTS_METRICS_COLUMNS = [
    attributed_sales_14_day__sum({ isVisible: true }),
    attributed_sales_new_to_brand_14_day__sum({ isVisible: true }),
    attributed_units_sold_14_day__sum({ isVisible: true }),
    attributed_orders_new_to_brand_14_day__sum({ isVisible: true }),
    attributed_orders_14_day__sum({ isVisible: true }),
    attributed_units_sold_new_to_brand_14_day__sum({
        isVisible: true,
    }),
    attributed_sales_new_to_brand_percentage_14_day_avg({ isVisible: true }),
    attributed_orders_new_to_brand_percentage_14_day__avg({ isVisible: true }),
    attributed_units_ordered_new_to_brand_percentage_14_day__avg({
        isVisible: true,
    }),
]

export const SB_PURCHASED_PRODUCTS_METRICS_CONFIG = makeMapping(
    SB_PURCHASED_PRODUCTS_METRICS_COLUMNS
)
