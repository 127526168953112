import { COUNTRY_CODES } from 'const/codes'

export const AD_GROUP_BID_MIN = 0.02
export const AD_GROUP_BID_MAX = 1000000000
export const AD_GROUP_BID_STEP = 0.1
export const AD_GROUP_BID_PRECISION = 2

export const AMAZON_AD_GROUP_SP_BID_LIMITS = {
    [COUNTRY_CODES.AE]: {
        min: 0.24,
        max: 3670,
    },
    [COUNTRY_CODES.AU]: {
        min: 0.1,
        max: 1410,
    },
    [COUNTRY_CODES.CA]: {
        min: 0.02,
        max: 1000,
    },
    [COUNTRY_CODES.DE]: {
        min: 0.02,
        max: 1000,
    },
    [COUNTRY_CODES.ES]: {
        min: 0.02,
        max: 1000,
    },
    [COUNTRY_CODES.FR]: {
        min: 0.02,
        max: 1000,
    },
    [COUNTRY_CODES.IN]: {
        min: 1,
        max: 5000,
    },
    [COUNTRY_CODES.IT]: {
        min: 0.02,
        max: 1000,
    },
    [COUNTRY_CODES.JP]: {
        min: 2,
        max: 100000,
    },
    [COUNTRY_CODES.MX]: {
        min: 0.1,
        max: 20000,
    },
    [COUNTRY_CODES.NL]: {
        min: 0.02,
        max: 1000,
    },
    [COUNTRY_CODES.UK]: {
        min: 0.02,
        max: 1000,
    },
    [COUNTRY_CODES.US]: {
        min: 0.02,
        max: 1000,
    },
}
