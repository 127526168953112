import {
    metadataId,
    productLabelId,
} from 'configuration/fieldCreators/productMetadata'

export const SPECIAL_PRODUCT_METADATA_COLUMNS = [
    metadataId({
        dataIndex: ['metadata_id'],
    }),
    productLabelId({
        dataIndex: ['product__label_id'],
    }),
]
