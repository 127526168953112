import {
    makeCountMetricFieldFunc,
    makeCurrencyMetricFieldFunc,
    makeMetricFieldFunc,
} from './localUtils'

export const revenue__sum = makeCurrencyMetricFieldFunc({
    name: 'Estimated Revenue',
    shortName: 'Est. Revenue',
    deltas: ['change', 'percentage_change'],
    id: 'revenue__sum',
})

export const revenue_1p__sum = makeCurrencyMetricFieldFunc({
    name: 'Estimated Revenue 1p',
    shortName: 'Est. Revenue 1p',
    deltas: ['change', 'percentage_change'],
    id: 'revenue_1p__sum',
})

export const revenue_3p__sum = makeCurrencyMetricFieldFunc({
    name: 'Estimated Revenue 3p',
    shortName: 'Est. Revenue 3p',
    deltas: ['change', 'percentage_change'],
    id: 'revenue_3p__sum',
})

export const units__sum = makeCountMetricFieldFunc({
    name: 'Estimated Units Ordered',
    shortName: 'Est. Units',
    deltas: ['change', 'percentage_change'],
    id: 'units__sum',
})

export const units_1p__sum = makeCountMetricFieldFunc({
    name: 'Estimated Units Ordered 1p',
    shortName: 'Est. Units 1p',
    deltas: ['change', 'percentage_change'],
    id: 'units_1p__sum',
})

export const units_3p__sum = makeCountMetricFieldFunc({
    name: 'Estimated Units Ordered 3p',
    shortName: 'Est. Units 3p',
    deltas: ['change', 'percentage_change'],
    id: 'units_3p__sum',
})

export const price__avg = makeCurrencyMetricFieldFunc({
    name: 'Average Price',
    shortName: 'Avg Price',
    deltas: ['change', 'percentage_change'],
    id: 'price__avg',
})

export const price__max = makeCurrencyMetricFieldFunc({
    name: 'Maximum Price',
    shortName: 'Max Price',
    deltas: ['change', 'percentage_change'],
    id: 'price__max',
})

export const price__min = makeCurrencyMetricFieldFunc({
    name: 'Minimum Price',
    shortName: 'Min Price',
    deltas: ['change', 'percentage_change'],
    id: 'price__min',
})

export const sales__sum = makeCurrencyMetricFieldFunc({
    name: 'Total Sales',
    shortName: 'Sales',
    deltas: ['change', 'percentage_change'],
    id: 'sales__sum',
})

export const sales_price__avg = makeCurrencyMetricFieldFunc({
    name: 'Estimated Average Sales Price',
    shortName: 'Est. Sales Price',
    deltas: ['change', 'percentage_change'],
    id: 'sales_price__avg',
})

export const sales_price_1p__avg = makeCurrencyMetricFieldFunc({
    name: 'Estimated Average 1p Sales Price',
    shortName: 'Est. 1p Sales Price',
    deltas: ['change', 'percentage_change'],
    id: 'sales_price_1p__avg',
})

export const sales_price_3p__avg = makeCurrencyMetricFieldFunc({
    name: 'Estimated Average 3p Sales Price',
    shortName: 'Est. 3p Sales Price',
    deltas: ['change', 'percentage_change'],
    id: 'sales_price_3p__avg',
})

export const review_count__avg = makeCountMetricFieldFunc({
    name: 'Average Reviews',
    shortName: 'Avg Reviews',
    deltas: ['change', 'percentage_change'],
    id: 'review_count__avg',
})

export const review_count__max = makeCountMetricFieldFunc({
    name: 'Maximum Reviews',
    shortName: 'Max Reviews',
    deltas: ['change', 'percentage_change'],
    id: 'review_count__max',
})

export const review_count__min = makeCountMetricFieldFunc({
    name: 'Minimum Reviews',
    shortName: 'Min Reviews',
    deltas: ['change', 'percentage_change'],
    id: 'review_count__min',
})

export const category_rank__avg = makeCountMetricFieldFunc({
    name: 'Average Category Rank',
    shortName: 'Avg Category Rank',
    isInverse: true,
    isRatio: true,
    deltas: ['change', 'percentage_change'],
    id: 'category_rank__avg',
})
export const subcategory_rank__avg = makeCountMetricFieldFunc({
    name: 'Average Subcategory Rank',
    shortName: 'Avg Subcategory Rank',
    isInverse: true,
    isRatio: true,
    deltas: ['change', 'percentage_change'],
    id: 'subcategory_rank__avg',
})

export const asin_count__sum = makeCountMetricFieldFunc({
    name: 'Number of ASINs',
    shortName: 'ASINs',
    deltas: ['change', 'percentage_change'],
    id: 'asin_count__sum',
})

export const brand_count__sum = makeCountMetricFieldFunc({
    name: 'Number of Brands',
    shortName: 'Brands',
    deltas: ['change', 'percentage_change'],
    id: 'brand_count__sum',
})

export const category_count__sum = makeCountMetricFieldFunc({
    name: 'Number of Categories',
    shortName: 'Categories',
    deltas: ['change', 'percentage_change'],
    id: 'category_count__sum',
})

export const sub_category_count__sum = makeCountMetricFieldFunc({
    name: 'Number of Subcategories',
    shortName: 'Subcategories',
    deltas: ['change', 'percentage_change'],
    id: 'sub_category_count__sum',
})

export const variant_count__max = makeCountMetricFieldFunc({
    name: 'Number of Variants',
    shortName: 'Variants',
    id: 'variant_count__max',
})

export const price__last_value = makeCurrencyMetricFieldFunc({
    name: 'Last Price',
    shortName: 'Last Price',
    id: 'price__last_value',
})

export const report_date__min = makeMetricFieldFunc({
    name: 'Report Date Min',
    shortName: 'Report Date Min',
    id: 'report_date__min',
    format: 'date',
    shortFormat: 'date',
})

export const report_date__max = makeMetricFieldFunc({
    name: 'Report Date Max',
    shortName: 'Report Date Max',
    id: 'report_date__max',
    format: 'yyyy-MM-DD',
    shortFormat: 'yyyy-MM-DD',
})
