import {
    makeCountMetricFieldFunc,
    makeCurrencyMetricFieldFunc,
    makePercentageMetricFieldFunc,
} from './localUtils'
import translations from './translations/ams'

export const ctr = makePercentageMetricFieldFunc({
    name: translations.ctr.name,
    shortName: translations.ctr.shortName,
    isRatio: true,
    deltas: ['change'],
    id: 'ctr',
})

export const roas = makeCurrencyMetricFieldFunc({
    name: translations.roas.name,
    shortName: translations.roas.name,
    isRatio: true,
    deltas: ['change', 'percentage_change'],
    id: 'roas',
})

export const roas_same_sku = makeCurrencyMetricFieldFunc({
    name: translations.roas_same_sku.name,
    shortName: translations.roas_same_sku.name,
    isRatio: true,
    deltas: ['change', 'percentage_change'],
    id: 'roas_same_sku',
})

export const roasp = makePercentageMetricFieldFunc({
    name: translations.roasp.name,
    shortName: translations.roasp.name,
    isRatio: true,
    deltas: ['change'],
    id: 'roasp',
})

export const acos = makePercentageMetricFieldFunc({
    name: translations.acos.name,
    shortName: translations.acos.name,
    isInverse: true,
    isRatio: true,
    deltas: ['change'],
    id: 'acos',
})

export const spc = makeCurrencyMetricFieldFunc({
    name: translations.spc.name,
    shortName: translations.spc.shortName,
    isRatio: true,
    deltas: ['change', 'percentage_change'],
    id: 'spc',
})

export const cpc = makeCurrencyMetricFieldFunc({
    name: translations.cpc.name,
    shortName: translations.cpc.shortName,
    isInverse: true,
    isRatio: true,
    deltas: ['change', 'percentage_change'],
    id: 'cpc',
})

export const cpa = makeCurrencyMetricFieldFunc({
    name: translations.cpa.name,
    shortName: translations.cpa.shortName,
    isInverse: true,
    isRatio: true,
    deltas: ['change', 'percentage_change'],
    id: 'cpa',
})

export const combined_cpa = makeCurrencyMetricFieldFunc({
    name: translations.combined_cpa.name,
    shortName: translations.combined_cpa.shortName,
    isInverse: true,
    isRatio: true,
    deltas: ['change', 'percentage_change'],
    id: 'combined_cpa',
})

export const cpm = makeCurrencyMetricFieldFunc({
    name: translations.cpm.name,
    shortName: translations.cpm.shortName,
    isInverse: true,
    isRatio: true,
    deltas: ['change', 'percentage_change'],
    id: 'cpm',
})

export const spm = makeCurrencyMetricFieldFunc({
    name: translations.spm.name,
    shortName: translations.spm.shortName,
    isRatio: true,
    deltas: ['change', 'percentage_change'],
    id: 'spm',
})

export const conversion_rate_clicks = makePercentageMetricFieldFunc({
    name: translations.conversion_rate_clicks.name,
    shortName: translations.conversion_rate_clicks.shortName,
    isRatio: true,
    deltas: ['change'],
    id: 'conversion_rate_clicks',
})

export const combined_conversion_rate_clicks = makePercentageMetricFieldFunc({
    name: translations.combined_conversion_rate_clicks.name,
    shortName: translations.combined_conversion_rate_clicks.shortName,
    isRatio: true,
    deltas: ['change'],
    id: 'combined_conversion_rate_clicks',
})

export const conversion_rate_impressions = makePercentageMetricFieldFunc({
    name: translations.conversion_rate_impressions.name,
    shortName: translations.conversion_rate_impressions.shortName,
    deltas: ['change'],
    id: 'conversion_rate_impressions',
})

export const combined_conversion_rate_impressions =
    makePercentageMetricFieldFunc({
        name: translations.combined_conversion_rate_impressions.name,
        shortName: translations.combined_conversion_rate_impressions.shortName,
        deltas: ['change'],
        id: 'combined_conversion_rate_impressions',
    })

export const aov = makeCurrencyMetricFieldFunc({
    name: translations.aov.name,
    shortName: translations.aov.shortName,
    isRatio: true,
    deltas: ['change', 'percentage_change'],
    id: 'aov',
})

export const combined_aov = makeCurrencyMetricFieldFunc({
    name: translations.combined_aov.name,
    shortName: translations.combined_aov.shortName,
    localDefinition: translations.combined_aov.helpText,
    isRatio: true,
    deltas: ['change', 'percentage_change'],
    id: 'combined_aov',
})

export const clicks__sum = makeCountMetricFieldFunc({
    name: translations.clicks__sum.name,
    shortName: translations.clicks__sum.name,
    deltas: ['change', 'percentage_change'],
    id: 'clicks__sum',
})

export const cost__sum = makeCurrencyMetricFieldFunc({
    name: translations.cost__sum.name,
    shortName: translations.cost__sum.name,
    isInverse: true,
    deltas: ['change', 'percentage_change'],
    id: 'cost__sum',
})

export const impressions__sum = makeCountMetricFieldFunc({
    name: translations.impressions__sum.name,
    shortName: translations.impressions__sum.name,
    deltas: ['change', 'percentage_change'],
    id: 'impressions__sum',
})

export const attributed_conversions_1_day__sum = makeCountMetricFieldFunc({
    name: translations.attributed_conversions__sum.name(1),
    shortName: translations.attributed_conversions__sum.shortName(1),
    deltas: ['change', 'percentage_change'],
    id: 'attributed_conversions_1_day__sum',
})

export const attributed_conversions_1_day_same_sku__sum =
    makeCountMetricFieldFunc({
        name: translations.attributed_conversions_same_sku__sum.name(1),
        shortName:
            translations.attributed_conversions_same_sku__sum.shortName(1),
        deltas: ['change', 'percentage_change'],
        id: 'attributed_conversions_1_day_same_sku__sum',
    })

export const attributed_conversions_7_day__sum = makeCountMetricFieldFunc({
    name: translations.attributed_conversions__sum.name(7),
    shortName: translations.attributed_conversions__sum.shortName(7),
    deltas: ['change', 'percentage_change'],
    id: 'attributed_conversions_7_day__sum',
})

export const attributed_conversions_7_day_same_sku__sum =
    makeCountMetricFieldFunc({
        name: translations.attributed_conversions_same_sku__sum.name(7),
        shortName:
            translations.attributed_conversions_same_sku__sum.shortName(7),
        deltas: ['change', 'percentage_change'],
        id: 'attributed_conversions_7_day_same_sku__sum',
    })

export const attributed_conversions_14_day__sum = makeCountMetricFieldFunc({
    name: translations.attributed_conversions__sum.name(14),
    shortName: translations.attributed_conversions__sum.shortName(14),
    deltas: ['change', 'percentage_change'],
    id: 'attributed_conversions_14_day__sum',
})

export const combined_conversions_14_day__sum = makeCountMetricFieldFunc({
    name: translations.combined_conversions_14_day__sum.name(),
    shortName: translations.combined_conversions_14_day__sum.shortName(),
    localDefinition: translations.combined_conversions_14_day__sum.helpText(),
    deltas: ['change', 'percentage_change'],
    id: 'combined_conversions_14_day__sum',
})

export const attributed_conversions_14_day_same_sku__sum =
    makeCountMetricFieldFunc({
        name: translations.attributed_conversions_same_sku__sum.name(14),
        shortName:
            translations.attributed_conversions_same_sku__sum.shortName(14),
        deltas: ['change', 'percentage_change'],
        id: 'attributed_conversions_14_day_same_sku__sum',
    })

export const attributed_conversions_30_day__sum = makeCountMetricFieldFunc({
    name: translations.attributed_conversions__sum.name(30),
    shortName: translations.attributed_conversions__sum.shortName(30),
    deltas: ['change', 'percentage_change'],
    id: 'attributed_conversions_30_day__sum',
})

export const attributed_conversions_30_day_same_sku__sum =
    makeCountMetricFieldFunc({
        name: translations.attributed_conversions_same_sku__sum.name(30),
        shortName:
            translations.attributed_conversions_same_sku__sum.shortName(30),
        deltas: ['change', 'percentage_change'],
        id: 'attributed_conversions_30_day_same_sku__sum',
    })

export const attributed_sales_1_day__sum = makeCurrencyMetricFieldFunc({
    name: translations.attributed_sales__sum.name(1),
    shortName: translations.attributed_sales__sum.shortName(1),
    deltas: ['change', 'percentage_change'],
    id: 'attributed_sales_1_day__sum',
})

export const attributed_sales_1_day_same_sku__sum = makeCurrencyMetricFieldFunc(
    {
        name: translations.attributed_sales_same_sku__sum.name(1),
        shortName: translations.attributed_sales_same_sku__sum.shortName(1),
        deltas: ['change', 'percentage_change'],
        id: 'attributed_sales_1_day_same_sku__sum',
    }
)

export const attributed_sales_7_day__sum = makeCurrencyMetricFieldFunc({
    name: translations.attributed_sales__sum.name(7),
    shortName: translations.attributed_sales__sum.shortName(7),
    deltas: ['change', 'percentage_change'],
    id: 'attributed_sales_7_day__sum',
})

export const attributed_sales_7_day_same_sku__sum = makeCurrencyMetricFieldFunc(
    {
        name: translations.attributed_sales_same_sku__sum.name(7),
        shortName: translations.attributed_sales_same_sku__sum.shortName(7),
        deltas: ['change', 'percentage_change'],
        id: 'attributed_sales_7_day_same_sku__sum',
    }
)

export const attributed_sales_14_day__sum = makeCurrencyMetricFieldFunc({
    name: translations.attributed_sales__sum.name(14),
    shortName: translations.attributed_sales__sum.shortName(14),
    deltas: ['change', 'percentage_change'],
    id: 'attributed_sales_14_day__sum',
})

export const amazon_sales = makeCurrencyMetricFieldFunc({
    name: translations.amazon_sales.name,
    shortName: translations.amazon_sales.name,
    deltas: ['change', 'percentage_change'],
    id: 'amazon_sales',
})

export const attributed_sales_14_day_same_sku__sum =
    makeCurrencyMetricFieldFunc({
        name: translations.attributed_sales_same_sku__sum.name(14),
        shortName: translations.attributed_sales_same_sku__sum.shortName(14),
        deltas: ['change', 'percentage_change'],
        id: 'attributed_sales_14_day_same_sku__sum',
    })

export const attributed_sales_30_day__sum = makeCurrencyMetricFieldFunc({
    name: translations.attributed_sales__sum.name(30),
    shortName: translations.attributed_sales__sum.shortName(30),
    deltas: ['change', 'percentage_change'],
    id: 'attributed_sales_30_day__sum',
})

export const attributed_sales_30_day_same_sku__sum =
    makeCurrencyMetricFieldFunc({
        name: translations.attributed_sales_same_sku__sum.name(30),
        shortName: translations.attributed_sales_same_sku__sum.shortName(30),
        deltas: ['change', 'percentage_change'],
        id: 'attributed_sales_30_day_same_sku__sum',
    })

export const attributed_units_ordered_1_day__sum = makeCountMetricFieldFunc({
    name: translations.attributed_units_ordered__sum.name(1),
    shortName: translations.attributed_units_ordered__sum.shortName(1),
    deltas: ['change', 'percentage_change'],
    id: 'attributed_units_ordered_1_day__sum',
})

export const attributed_units_ordered_1_day_same_sku__sum =
    makeCountMetricFieldFunc({
        name: translations.attributed_units_ordered_same_sku__sum.name(1),
        shortName:
            translations.attributed_units_ordered_same_sku__sum.shortName(1),
        deltas: ['change', 'percentage_change'],
        id: 'attributed_units_ordered_1_day_same_sku__sum',
    })

export const attributed_units_ordered_7_day__sum = makeCountMetricFieldFunc({
    name: translations.attributed_units_ordered__sum.name(7),
    shortName: translations.attributed_units_ordered__sum.shortName(7),
    deltas: ['change', 'percentage_change'],
    id: 'attributed_units_ordered_7_day__sum',
})

export const attributed_units_ordered_7_day_same_sku__sum =
    makeCountMetricFieldFunc({
        name: translations.attributed_units_ordered_same_sku__sum.name(7),
        shortName:
            translations.attributed_units_ordered_same_sku__sum.shortName(7),
        deltas: ['change', 'percentage_change'],
        id: 'attributed_units_ordered_7_day_same_sku__sum',
    })

export const attributed_units_ordered_14_day__sum = makeCountMetricFieldFunc({
    name: translations.attributed_units_ordered__sum.name(14),
    shortName: translations.attributed_units_ordered__sum.shortName(14),
    deltas: ['change', 'percentage_change'],
    id: 'attributed_units_ordered_14_day__sum',
})

export const attributed_units_ordered_14_day_same_sku__sum =
    makeCountMetricFieldFunc({
        name: translations.attributed_units_ordered_same_sku__sum.name(14),
        shortName:
            translations.attributed_units_ordered_same_sku__sum.shortName(14),
        deltas: ['change', 'percentage_change'],
        id: 'attributed_units_ordered_14_day_same_sku__sum',
    })

export const attributed_units_ordered_30_day__sum = makeCountMetricFieldFunc({
    name: translations.attributed_units_ordered__sum.name(30),
    shortName: translations.attributed_units_ordered__sum.shortName(30),
    deltas: ['change', 'percentage_change'],
    id: 'attributed_units_ordered_30_day__sum',
})

export const attributed_units_ordered_30_day_same_sku__sum =
    makeCountMetricFieldFunc({
        name: translations.attributed_units_ordered_same_sku__sum.name(30),
        shortName:
            translations.attributed_units_ordered_same_sku__sum.shortName(30),
        deltas: ['change', 'percentage_change'],
        id: 'attributed_units_ordered_30_day_same_sku__sum',
    })

export const attributed_units_sold_14_day__sum = makeCountMetricFieldFunc({
    name: translations.attributed_units_sold_14_day__sum.name,
    shortName: translations.attributed_units_sold_14_day__sum.shortName,
    deltas: ['change', 'percentage_change'],
    id: 'attributed_units_sold_14_day__sum',
})

export const attributed_orders_new_to_brand_14_day__sum =
    makeCountMetricFieldFunc({
        name: translations.attributed_orders_new_to_brand_14_day__sum.name,
        shortName: translations.attributed_orders_new_to_brand_14_day__sum.name,
        deltas: ['change', 'percentage_change'],
        id: 'attributed_orders_new_to_brand_14_day__sum',
    })

export const attributed_orders_new_to_brand_percentage_14_day =
    makePercentageMetricFieldFunc({
        name: translations.attributed_orders_new_to_brand_percentage_14_day
            .name,
        shortName:
            translations.attributed_orders_new_to_brand_percentage_14_day.name,
        deltas: ['change'],
        id: 'attributed_orders_new_to_brand_percentage_14_day',
    })

export const attributed_order_rate_new_to_brand_14_day =
    makePercentageMetricFieldFunc({
        name: translations.attributed_order_rate_new_to_brand_14_day.name,
        shortName: translations.attributed_order_rate_new_to_brand_14_day.name,
        isRatio: true,
        deltas: ['change'],
        id: 'attributed_order_rate_new_to_brand_14_day',
    })

export const attributed_sales_new_to_brand_14_day__sum =
    makeCurrencyMetricFieldFunc({
        name: translations.attributed_sales_new_to_brand_14_day__sum.name,
        shortName: translations.attributed_sales_new_to_brand_14_day__sum.name,
        deltas: ['change', 'percentage_change'],
        id: 'attributed_sales_new_to_brand_14_day__sum',
    })

export const attributed_sales_new_to_brand_percentage_14_day =
    makePercentageMetricFieldFunc({
        name: translations.attributed_sales_new_to_brand_percentage_14_day.name,
        shortName:
            translations.attributed_sales_new_to_brand_percentage_14_day.name,
        deltas: ['change'],
        id: 'attributed_sales_new_to_brand_percentage_14_day',
    })

export const attributed_units_ordered_new_to_brand_14_day__sum =
    makeCountMetricFieldFunc({
        name: translations.attributed_units_ordered_new_to_brand_14_day__sum
            .name,
        shortName:
            translations.attributed_units_ordered_new_to_brand_14_day__sum.name,
        deltas: ['change', 'percentage_change'],
        id: 'attributed_units_ordered_new_to_brand_14_day__sum',
    })

export const attributed_units_ordered_new_to_brand_percentage_14_day =
    makePercentageMetricFieldFunc({
        name: translations
            .attributed_units_ordered_new_to_brand_percentage_14_day.name,
        shortName:
            translations.attributed_units_ordered_new_to_brand_percentage_14_day
                .name,
        deltas: ['change'],
        id: 'attributed_units_ordered_new_to_brand_percentage_14_day',
    })

export const attributed_detail_page_views_14_day__sum =
    makeCountMetricFieldFunc({
        name: translations.attributed_detail_page_views_14_day__sum.name,
        shortName:
            translations.attributed_detail_page_views_14_day__sum.shortName,
        deltas: ['change', 'percentage_change'],
        id: 'attributed_detail_page_views_14_day__sum',
    })

export const attributed_detail_page_views_clicks_14_day__sum =
    makeCountMetricFieldFunc({
        name: translations.attributed_detail_page_views_clicks_14_day__sum.name,
        shortName:
            translations.attributed_detail_page_views_clicks_14_day__sum
                .shortName,
        deltas: ['change', 'percentage_change'],
        id: 'attributed_detail_page_views_clicks_14_day__sum',
    })

export const attributed_sales_14_day_same_sku_rate =
    makePercentageMetricFieldFunc({
        name: translations.attributed_sales_14_day_same_sku_rate.name,
        shortName: translations.attributed_sales_14_day_same_sku_rate.shortName,
        deltas: ['change'],
        id: 'attributed_sales_14_day_same_sku_rate',
    })

export const spend = makeCurrencyMetricFieldFunc({
    name: translations.spend.name,
    shortName: translations.spend.shortName,
    isInverse: true,
    deltas: [],
    id: 'spend',
})

export const spend__daily_avg = makeCurrencyMetricFieldFunc({
    name: translations.spend__daily_avg.name,
    shortName: translations.spend__daily_avg.shortName,
    isInverse: true,
    isRatio: true,
    deltas: [],
    id: 'spend__daily_avg',
})

export const spend__t3_daily_avg = makeCurrencyMetricFieldFunc({
    name: translations.spend__t3_daily_avg.name,
    shortName: translations.spend__t3_daily_avg.shortName,
    isInverse: true,
    isRatio: true,
    deltas: [],
    id: 'spend__t3_daily_avg',
})

export const spend__t7_daily_avg = makeCurrencyMetricFieldFunc({
    name: translations.spend__t7_daily_avg.name,
    shortName: translations.spend__t7_daily_avg.shortName,
    isInverse: true,
    isRatio: true,
    deltas: [],
    id: 'spend__t7_daily_avg',
})

export const spend__daily_avg_forecast = makeCurrencyMetricFieldFunc({
    name: translations.spend__daily_avg_forecast.name,
    shortName: translations.spend__daily_avg_forecast.shortName,
    isInverse: true,
    isRatio: true,
    deltas: [],
    id: 'spend__daily_avg_forecast',
})

export const spend__t3_daily_avg_forecast = makeCurrencyMetricFieldFunc({
    name: translations.spend__t3_daily_avg_forecast.name,
    shortName: translations.spend__t3_daily_avg_forecast.shortName,
    isInverse: true,
    isRatio: true,
    deltas: [],
    id: 'spend__t3_daily_avg_forecast',
})

export const spend__t7_daily_avg_forecast = makeCurrencyMetricFieldFunc({
    name: translations.spend__t7_daily_avg_forecast.name,
    shortName: translations.spend__t7_daily_avg_forecast.shortName,
    isInverse: true,
    isRatio: true,
    deltas: [],
    id: 'spend__t7_daily_avg_forecast',
})

export const spend__daily_avg_forecast_difference_from_goal =
    makeCurrencyMetricFieldFunc({
        name: translations.spend__daily_avg_forecast_difference_from_goal.name,
        shortName:
            translations.spend__daily_avg_forecast_difference_from_goal
                .shortName,
        isInverse: true,
        isRatio: true,
        deltas: [],
        id: 'spend__daily_avg_forecast_difference_from_goal',
    })

export const spend__t3_daily_avg_forecast_difference_from_goal =
    makeCurrencyMetricFieldFunc({
        name: translations.spend__t3_daily_avg_forecast_difference_from_goal
            .name,
        shortName:
            translations.spend__t3_daily_avg_forecast_difference_from_goal
                .shortName,
        isInverse: true,
        isRatio: true,
        deltas: [],
        id: 'spend__t3_daily_avg_forecast_difference_from_goal',
    })

export const spend__t7_daily_avg_forecast_difference_from_goal =
    makeCurrencyMetricFieldFunc({
        name: translations.spend__t7_daily_avg_forecast_difference_from_goal
            .name,
        shortName:
            translations.spend__t7_daily_avg_forecast_difference_from_goal
                .shortName,
        isInverse: true,
        isRatio: true,
        deltas: [],
        id: 'spend__t7_daily_avg_forecast_difference_from_goal',
    })

export const spend__daily_avg_forecast_pct_difference_from_goal =
    makePercentageMetricFieldFunc({
        name: translations.spend__daily_avg_forecast_pct_difference_from_goal
            .name,
        shortName:
            translations.spend__daily_avg_forecast_pct_difference_from_goal
                .shortName,
        isRatio: true,
        deltas: [],
        id: 'spend__daily_avg_forecast_pct_difference_from_goal',
    })

export const spend__t3_daily_avg_forecast_pct_difference_from_goal =
    makePercentageMetricFieldFunc({
        name: translations.spend__t3_daily_avg_forecast_pct_difference_from_goal
            .name,
        shortName:
            translations.spend__t3_daily_avg_forecast_pct_difference_from_goal
                .shortName,
        isRatio: true,
        deltas: [],
        id: 'spend__t3_daily_avg_forecast_pct_difference_from_goal',
    })

export const spend__t7_daily_avg_forecast_pct_difference_from_goal =
    makePercentageMetricFieldFunc({
        name: translations.spend__t7_daily_avg_forecast_pct_difference_from_goal
            .name,
        shortName:
            translations.spend__t7_daily_avg_forecast_pct_difference_from_goal
                .shortName,
        isRatio: true,
        deltas: [],
        id: 'spend__t7_daily_avg_forecast_pct_difference_from_goal',
    })

export const spend__difference_from_goal = makeCurrencyMetricFieldFunc({
    name: translations.spend__difference_from_goal.name,
    shortName: translations.spend__difference_from_goal.shortName,
    isInverse: true,
    deltas: [],
    id: 'spend__difference_from_goal',
})

export const spend__pct_difference_from_goal = makePercentageMetricFieldFunc({
    name: translations.spend__pct_difference_from_goal.name,
    shortName: translations.spend__pct_difference_from_goal.shortName,
    deltas: [],
    id: 'spend__pct_difference_from_goal',
})

export const spend__difference_from_goal_per_day = makeCurrencyMetricFieldFunc({
    name: translations.spend__difference_from_goal_per_day.name,
    shortName: translations.spend__difference_from_goal_per_day.shortName,
    isInverse: true,
    deltas: [],
    id: 'spend__difference_from_goal_per_day',
})

export const spend__goal = makeCurrencyMetricFieldFunc({
    name: translations.spend__goal.name,
    shortName: translations.spend__goal.shortName,
    isInverse: true,
    deltas: [],
    id: 'spend__goal',
})

export const view_impressions__sum = makeCountMetricFieldFunc({
    name: translations.view_impressions__sum.name,
    shortName: translations.view_impressions__sum.shortName,
    deltas: ['change', 'percentage_change'],
    id: 'view_impressions__sum',
})

export const combined_impressions__sum = makeCountMetricFieldFunc({
    name: translations.combined_impressions__sum.name,
    shortName: translations.combined_impressions__sum.shortName,
    localDefinition: translations.combined_impressions__sum.helpText,
    deltas: ['change', 'percentage_change'],
    id: 'combined_impressions__sum',
})

export const view_attributed_conversions_14_day__sum = makeCountMetricFieldFunc(
    {
        name: translations.view_attributed_conversions_14_day__sum.name,
        shortName:
            translations.view_attributed_conversions_14_day__sum.shortName,
        deltas: ['change', 'percentage_change'],
        id: 'view_attributed_conversions_14_day__sum',
    }
)

export const view_attributed_units_ordered_14_day__sum =
    makeCountMetricFieldFunc({
        name: translations.view_attributed_units_ordered_14_day__sum.name,
        shortName:
            translations.view_attributed_units_ordered_14_day__sum.shortName,
        deltas: ['change', 'percentage_change'],
        id: 'view_attributed_units_ordered_14_day__sum',
    })

export const view_attributed_sales_14_day__sum = makeCurrencyMetricFieldFunc({
    name: translations.view_attributed_sales_14_day__sum.name,
    shortName: translations.view_attributed_sales_14_day__sum.shortName,
    deltas: ['change', 'percentage_change'],
    id: 'view_attributed_sales_14_day__sum',
})

export const combined_attributed_sales_14_day__sum =
    makeCurrencyMetricFieldFunc({
        name: translations.combined_attributed_sales_14_day__sum.name,
        shortName: translations.combined_attributed_sales_14_day__sum.shortName,
        localDefinition:
            translations.combined_attributed_sales_14_day__sum.helpText,
        deltas: ['change', 'percentage_change'],
        id: 'combined_attributed_sales_14_day__sum',
    })

export const cac = makeCurrencyMetricFieldFunc({
    name: translations.cac.name,
    shortName: translations.cac.shortName,
    localDefinition: translations.cac.helpText,
    isInverse: true,
    isRatio: true,
    deltas: ['change'],
    id: 'cac',
})

export const attributed_branded_searches_14_day__sum = makeCountMetricFieldFunc(
    {
        name: translations.attributed_branded_searches_14_day__sum.name,
        shortName:
            translations.attributed_branded_searches_14_day__sum.shortName,
        deltas: ['change', 'percentage_change'],
        id: 'attributed_branded_searches_14_day__sum',
    }
)

export const view_attributed_detail_page_view_14_day__sum =
    makeCountMetricFieldFunc({
        name: translations.view_attributed_detail_page_view_14_day__sum.name,
        shortName:
            translations.view_attributed_detail_page_view_14_day__sum.shortName,
        deltas: ['change', 'percentage_change'],
        id: 'view_attributed_detail_page_view_14_day__sum',
    })

export const view_attributed_orders_new_to_brand_14_day__sum =
    makeCountMetricFieldFunc({
        name: translations.view_attributed_orders_new_to_brand_14_day__sum.name,
        shortName:
            translations.view_attributed_orders_new_to_brand_14_day__sum
                .shortName,
        deltas: ['change', 'percentage_change'],
        id: 'view_attributed_orders_new_to_brand_14_day__sum',
    })

export const view_attributed_sales_new_to_brand_14_day__sum =
    makeCurrencyMetricFieldFunc({
        name: translations.view_attributed_sales_new_to_brand_14_day__sum.name,
        shortName:
            translations.view_attributed_sales_new_to_brand_14_day__sum
                .shortName,
        deltas: ['change', 'percentage_change'],
        id: 'view_attributed_sales_new_to_brand_14_day__sum',
    })

export const view_attributed_units_ordered_new_to_brand_14_day__sum =
    makeCountMetricFieldFunc({
        name: translations
            .view_attributed_units_ordered_new_to_brand_14_day__sum.name,
        shortName:
            translations.view_attributed_units_ordered_new_to_brand_14_day__sum
                .shortName,
        deltas: ['change', 'percentage_change'],
        id: 'view_attributed_units_ordered_new_to_brand_14_day__sum',
    })

export const view_attributed_branded_searches_14_day__sum =
    makeCountMetricFieldFunc({
        name: translations.view_attributed_branded_searches_14_day__sum.name,
        shortName:
            translations.view_attributed_branded_searches_14_day__sum.shortName,
        deltas: ['change', 'percentage_change'],
        id: 'view_attributed_branded_searches_14_day__sum',
    })

export const video_complete_views__sum = makeCountMetricFieldFunc({
    name: translations.video_complete_views__sum.name,
    shortName: translations.video_complete_views__sum.shortName,
    deltas: ['change', 'percentage_change'],
    id: 'video_complete_views__sum',
})

export const video_first_quartile_views__sum = makeCountMetricFieldFunc({
    name: translations.video_first_quartile_views__sum.name,
    shortName: translations.video_first_quartile_views__sum.shortName,
    deltas: ['change', 'percentage_change'],
    id: 'video_first_quartile_views__sum',
})

export const video_midpoint_views__sum = makeCountMetricFieldFunc({
    name: translations.video_midpoint_views__sum.name,
    shortName: translations.video_midpoint_views__sum.shortName,
    deltas: ['change', 'percentage_change'],
    id: 'video_midpoint_views__sum',
})

export const video_third_quartile_views__sum = makeCountMetricFieldFunc({
    name: translations.video_third_quartile_views__sum.name,
    shortName: translations.video_third_quartile_views__sum.shortName,
    deltas: ['change', 'percentage_change'],
    id: 'video_third_quartile_views__sum',
})

export const video_unmutes__sum = makeCountMetricFieldFunc({
    name: translations.video_unmutes__sum.name,
    shortName: translations.video_unmutes__sum.shortName,
    deltas: ['change', 'percentage_change'],
    id: 'video_unmutes__sum',
})
