import { combineChildrenReducers } from 'utilities/reducers'

import pageReducer from './page'
import parentProductsReducer from './parentProducts'
import productAdsReducer from './productAds'
import productsReducer from './products'
import skuCogsBulkEditReducer from './skuCogsBulkEdit'
import skuProductsReducer from './skuProducts'

export default combineChildrenReducers(pageReducer, {
    parentProducts: parentProductsReducer,
    productAds: productAdsReducer,
    products: productsReducer,
    skuCogsBulkEdit: skuCogsBulkEditReducer,
    skuProducts: skuProductsReducer,
})
