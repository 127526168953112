import { ReactElement } from 'react'

import { Select, Spin } from 'antd'

import { SEARCH_RESULTS_PER_QUERY } from 'configuration/typeahead'
import { formatBrandName } from 'helpers/formatting'
import { useTypeahead } from 'hooks'
import { getPortfolios } from 'services/cerebroApi/orgScope/resourceApi'
import { Portfolio, TypeaheadOption } from 'types'

const optionFormatter = (portfolio: Portfolio): TypeaheadOption => ({
    value: portfolio.id,
    label: portfolio.name,
    metadata: portfolio.profile
        ? formatBrandName(portfolio.profile)
        : undefined,
})

interface Props {
    value?: string
    onChange: (value: string) => void
    profileId?: string
    defaultOptions?: Portfolio[]
}

const PortfolioSearchSelect = ({
    value,
    onChange,
    profileId,
    defaultOptions = [],
}: Props): ReactElement => {
    const [options, loading, onSearch, resetLoadingState] =
        useTypeahead<Portfolio>({
            apiSearchFunc: (query) => {
                const params: { [name: string]: number | string } = {
                    limit: SEARCH_RESULTS_PER_QUERY,
                    name__similar: query,
                }

                if (profileId) {
                    params.profile_id = profileId
                }

                return getPortfolios(params, { headers: { noCount: true } })
            },
            optionFormatter,
            defaultOptions,
        })

    return (
        <Select
            filterOption={false}
            optionLabelProp="title"
            placeholder="Search Portfolios..."
            loading={loading}
            notFoundContent={loading ? <Spin size="small" /> : null}
            onChange={onChange}
            onSearch={onSearch}
            onSelect={resetLoadingState}
            showSearch
            style={{ width: '100%' }}
            value={value}
            allowClear
        >
            {options.map((option) => (
                <Select.Option
                    key={option.value}
                    value={option.value}
                    title={option.label}
                >
                    <b>{option.label}</b>{' '}
                    {option.metadata && <i>({option.metadata})</i>}
                </Select.Option>
            ))}
        </Select>
    )
}

export default PortfolioSearchSelect
