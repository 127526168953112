import { SVGAttributes, ReactElement } from 'react'

type Props = SVGAttributes<SVGElement>

const SvgPlayButtonIcon = (props: Props): ReactElement => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.0001 2.47916C5.84644 2.47916 2.47925 5.84635 2.47925 9.99999C2.47925 14.1536 5.84644 17.5208 10.0001 17.5208C14.1537 17.5208 17.5209 14.1536 17.5209 9.99999C17.5209 5.84635 14.1537 2.47916 10.0001 2.47916ZM0.854248 9.99999C0.854248 4.94889 4.94898 0.854156 10.0001 0.854156C15.0512 0.854156 19.1459 4.94889 19.1459 9.99999C19.1459 15.0511 15.0512 19.1458 10.0001 19.1458C4.94898 19.1458 0.854248 15.0511 0.854248 9.99999ZM9.36005 6.21192C9.36782 6.21691 9.37556 6.22189 9.38329 6.22686L13.3171 8.75575C13.3242 8.76031 13.3314 8.7649 13.3385 8.76951C13.4647 8.85056 13.6015 8.93845 13.7103 9.0233C13.8256 9.11322 14.0057 9.27045 14.1118 9.52157C14.241 9.82743 14.241 10.1726 14.1118 10.4784C14.0057 10.7295 13.8256 10.8868 13.7103 10.9767C13.6015 11.0615 13.4647 11.1494 13.3385 11.2305L9.36006 13.7881C9.2133 13.8825 9.06009 13.981 8.92536 14.0511C8.78984 14.1217 8.54656 14.2343 8.24585 14.2128C7.89021 14.1874 7.56313 14.0089 7.34945 13.7234C7.16877 13.4821 7.13198 13.2165 7.11803 13.0644C7.10416 12.9132 7.10421 12.731 7.10424 12.5565C7.10425 12.5473 7.10425 12.5381 7.10425 12.5289V7.4711C7.10425 7.46192 7.10425 7.45271 7.10424 7.44348C7.10421 7.26898 7.10416 7.08682 7.11803 6.93558C7.13198 6.78343 7.16877 6.51787 7.34945 6.27653C7.56313 5.99111 7.89021 5.81254 8.24585 5.78714C8.54656 5.76566 8.78983 5.87832 8.92536 5.94886C9.06009 6.01899 9.2133 6.11753 9.36005 6.21192ZM8.72925 7.73822V12.2618L12.2476 9.99999L8.72925 7.73822Z"
                fill="currentColor"
            />
        </svg>
    )
}

export default SvgPlayButtonIcon
