import { call, put, select } from 'redux-saga/effects'

import {
    makeFetchTableSuccess,
    makeFetchTableRequest,
    makeDownloadTableSuccess,
} from 'actions/ui/shared'
import { labelBrandsTableColumnsConfig } from 'configuration/tables'
import { DATES } from 'const/filters'
import { LABEL_PAGE } from 'const/pages'
import { formatFilters } from 'helpers/filters/campaignFacts'
import { generateReportNotification } from 'helpers/notifications'
import {
    formatPagination,
    formatSorter,
    formatMetrics,
    formatCurrency,
    formatColumns,
    formatPeriodDeltaDateRange,
} from 'helpers/params'
import { cerebroApiSaga } from 'sagas/common'
import uiSagaRegistry from 'sagas/ui/registry'
import {
    selectResourceParamsOfPage,
    selectCurrencyCode,
    selectVisibleCombinedFilters,
    selectTableSettings,
    selectVisibleMetricsOfTable,
    selectResourceOfPage,
    selectVisibleColumnsOfTable,
} from 'selectors/ui'
import {
    getProfileFacts,
    getProfileFactsExport,
} from 'services/cerebroApi/orgScope/profileFactsApi'

const TAB_PATH = [LABEL_PAGE, 'brands']
const TABLE_PATH = [...TAB_PATH, 'table']

function* fetchTableSaga(noCount) {
    const { labelId } = yield select(selectResourceParamsOfPage, LABEL_PAGE)
    const currency = yield select(selectCurrencyCode)
    const filters = yield select(selectVisibleCombinedFilters, TAB_PATH)

    const {
        pagination,
        sorter,
        showPeriodDeltas,
        periodDeltaType,
        periodDeltaDateRange,
    } = yield select(selectTableSettings, TABLE_PATH)
    const metrics = yield select(
        selectVisibleMetricsOfTable,
        TABLE_PATH,
        labelBrandsTableColumnsConfig
    )

    const params = {
        ...formatPagination(pagination),
        ...formatSorter(sorter),
        ...formatFilters(filters),
        ...formatMetrics(metrics, showPeriodDeltas),
        ...formatCurrency(currency),
        ...formatPeriodDeltaDateRange(
            showPeriodDeltas,
            periodDeltaType,
            periodDeltaDateRange,
            filters[DATES]
        ),
        group_by: 'profile_id',
        profile__labels_id_and_in: labelId,
    }

    yield call(
        cerebroApiSaga,
        makeFetchTableSuccess(TABLE_PATH),
        getProfileFacts,
        params,
        {
            headers: { noCount },
        }
    )
}

function* downloadTableSaga() {
    const label = yield select(selectResourceOfPage, LABEL_PAGE)
    const filters = yield select(selectVisibleCombinedFilters, TAB_PATH)
    const { sorter, showPeriodDeltas, periodDeltaType, periodDeltaDateRange } =
        yield select(selectTableSettings, TABLE_PATH)
    const columns = yield select(
        selectVisibleColumnsOfTable,
        TABLE_PATH,
        labelBrandsTableColumnsConfig
    )
    const currency = yield select(selectCurrencyCode)
    const reportName = `Brand Report - ${label.name}`
    const params = {
        ...formatFilters(filters),
        ...formatSorter(sorter),
        ...formatColumns(columns, showPeriodDeltas),
        ...formatCurrency(currency),
        ...formatPeriodDeltaDateRange(
            showPeriodDeltas,
            periodDeltaType,
            periodDeltaDateRange,
            filters[DATES]
        ),
        group_by: 'profile_id',
        labels_id_and_in: label.id,
        async_download_name: reportName,
    }
    yield call(
        cerebroApiSaga,
        makeDownloadTableSuccess(TABLE_PATH),
        getProfileFactsExport,
        params
    )

    generateReportNotification(reportName)
}

function* fetchTabDataSaga() {
    yield put(makeFetchTableRequest(TABLE_PATH)())
}

// Register Sagas
uiSagaRegistry.registerSagas(TAB_PATH, {
    fetchTabDataSaga,
})

uiSagaRegistry.registerSagas(TABLE_PATH, {
    fetchTableSaga,
    downloadTableSaga,
})
