import { ReactElement } from 'react'

import { Drawer, List } from 'antd'
import classNames from 'classnames'
import { useTranslation, Trans } from 'react-i18next'
import { Link } from 'react-router-dom'

import { toggleReportsAppDrawer } from 'actions/ui/app'
import { REPORTS_SUMMARY_PAGE } from 'const/pages'
import { formatCerebroDateTime } from 'helpers/formatting'
import { getPath } from 'helpers/pages'
import { renderReportActions, renderReportDateRange } from 'helpers/reports'
import { useAction } from 'hooks'
import { useAnalytics } from 'services/analytics'
import { Report } from 'types'

import styles from './styles.scss'
import { CloseIcon } from '../../Icons'

interface ReportsDrawerProps {
    newReports: Report[]
    drawerReports: Report[]
    drawerReportsLoading: boolean
    downloadReport: (payload: { reportId: string }) => void
    visible: boolean
    onClose: () => void
}

const ReportsDrawer = ({
    newReports,
    drawerReports,
    drawerReportsLoading,
    downloadReport,
    visible,
    onClose,
}: ReportsDrawerProps): ReactElement => {
    const { t } = useTranslation(['common', 'navigation'])
    const toggleDrawer = useAction(toggleReportsAppDrawer)
    const closeMe = (): void => {
        toggleDrawer(false)
    }
    const { trackCtaClick } = useAnalytics()

    const onDownload = (payload: { reportId: string }): void => {
        trackCtaClick({
            destination: 'Same Page',
            text: 'Download Report',
            type: 'Button',
            location: 'Reports Drawer',
            extraProps: {
                reportId: payload.reportId,
            },
        })
        downloadReport(payload)
    }

    return (
        <Drawer
            title={
                <span>
                    {t('common:reports')} (
                    <Link
                        type="link"
                        onClick={closeMe}
                        to={getPath(REPORTS_SUMMARY_PAGE)}
                    >
                        {t('common:viewAll')}
                    </Link>
                    )
                </span>
            }
            placement="right"
            onClose={onClose}
            closeIcon={<CloseIcon />}
            open={visible}
            width={460}
            rootClassName={classNames(['fixed-header', styles.drawer])}
        >
            <List
                dataSource={drawerReports}
                loading={drawerReportsLoading}
                locale={{
                    emptyText: t(
                        'navigation:TopNav.ReportsDrawer.emptyText',
                        'No reports to download.'
                    ),
                }}
                renderItem={(report) => (
                    <List.Item
                        actions={[
                            renderReportActions(
                                report,
                                newReports,
                                onDownload,
                                t
                            ),
                        ]}
                    >
                        <List.Item.Meta
                            avatar={
                                <span className="fg-default-tag">
                                    {report.type}
                                </span>
                            }
                            title={<strong>{report.name}</strong>}
                            className={styles['drawer-list-item']}
                            description={
                                <>
                                    <p>
                                        <Trans i18nKey="navigation:TopNav.ReportsDrawer.dateRange">
                                            <strong>Date Range:</strong>{' '}
                                            {{
                                                dateRange:
                                                    renderReportDateRange(
                                                        report
                                                    ),
                                            }}
                                        </Trans>
                                    </p>
                                    <p>
                                        <Trans i18nKey="navigation:TopNav.ReportsDrawer.createdDate">
                                            <strong>Created Date:</strong>{' '}
                                            {{
                                                createdDate:
                                                    formatCerebroDateTime(
                                                        report.created_date
                                                    ),
                                            }}
                                        </Trans>
                                    </p>
                                </>
                            }
                        />
                    </List.Item>
                )}
            />
        </Drawer>
    )
}
export default ReportsDrawer
