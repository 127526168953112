import { memo, ReactElement } from 'react'

import isEqual from 'lodash/isEqual'

import {
    TARGET_BID_MIN,
    TARGET_BID_MAX,
    TARGET_BID_STEP,
    TARGET_BID_PRECISION,
} from 'const/nonKeywordTargets'
import { updateTarget } from 'services/cerebroApi/orgScope/resourceApi'
import { NonKeywordTargetFact, ProfileGroupBy } from 'types'

import { BidField } from '../../SharedFields/BidField'

type TargetRecord = NonKeywordTargetFact<ProfileGroupBy>

interface Props<RecordType> {
    dataIndex: string[]
    readOnly: boolean
    record: RecordType
}

function TargetBidField<RecordType extends TargetRecord>({
    dataIndex = ['target', 'base_bid'],
    readOnly = false,
    record,
}: Props<RecordType>): ReactElement {
    const fieldName = 'bid' // field to update
    const resourceId = record.target_id
    const currencyCode = record.profile.currency_code ?? 'USD'

    const getIsDisabled = (): boolean => {
        const userCanManageTarget = record.target.user_can_manage
        return !userCanManageTarget
    }

    return (
        <BidField
            bidMax={TARGET_BID_MAX}
            bidMin={TARGET_BID_MIN}
            bidPrecision={TARGET_BID_PRECISION}
            bidStep={TARGET_BID_STEP}
            currencyCode={currencyCode}
            disabled={getIsDisabled()}
            fieldName={fieldName}
            fieldPath={dataIndex}
            readOnly={readOnly}
            record={record}
            updateRequestApi={updateTarget}
            updateRequestData={resourceId}
            updateRequestSuccessMesg="Successfully updated target bid"
        />
    )
}

export default memo(TargetBidField, isEqual) as typeof TargetBidField
