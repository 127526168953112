import { handleActions } from 'redux-actions'

import { keywordSearchTermsSummaryTableColumnsConfig } from 'configuration/tables'
import {
    DATES,
    COUNTRIES,
    REGIONS,
    BRANDS,
    CAMPAIGNS,
    CAMPAIGN_STATES,
    CAMPAIGN_TARGETING_TYPES,
    KEYWORDS,
    KEYWORD_MATCH_TYPES,
    FACT_TYPES,
    METRIC_FILTERS,
    KEYWORD_STATES,
    PORTFOLIOS,
    LABELS,
    ADVANCED_TEXT,
    SEARCH_TERM_NAME,
} from 'const/filters'
import { FILTER_SETTINGS, FILTERS } from 'const/reducerKeys'
import { serializeColumns } from 'helpers/tables'
import { defaultTab, getDefaultTable } from 'reducers/ui/defaults'

const initialState = {
    ...defaultTab,
    [FILTERS]: {
        [SEARCH_TERM_NAME]: null,
        [METRIC_FILTERS]: [
            {
                metric: 'impressions__sum',
                operator: 'gt',
                value: 0,
            },
        ],
        [FACT_TYPES]: [],
        [REGIONS]: [],
        [COUNTRIES]: [],
        [BRANDS]: [],
        [PORTFOLIOS]: [],
        [CAMPAIGNS]: [],
        [CAMPAIGN_STATES]: [],
        [CAMPAIGN_TARGETING_TYPES]: [],
        [KEYWORDS]: [],
        [KEYWORD_MATCH_TYPES]: [],
        [KEYWORD_STATES]: [],
        [LABELS]: [],
        [ADVANCED_TEXT]: [],
    },
    [FILTER_SETTINGS]: {
        anchored: [SEARCH_TERM_NAME, DATES, FACT_TYPES],
        order: [
            METRIC_FILTERS,
            LABELS,
            REGIONS,
            COUNTRIES,
            BRANDS,
            PORTFOLIOS,
            CAMPAIGNS,
            CAMPAIGN_STATES,
            CAMPAIGN_TARGETING_TYPES,
            KEYWORDS,
            KEYWORD_MATCH_TYPES,
            KEYWORD_STATES,
            ADVANCED_TEXT,
        ],
        displayState: {
            [METRIC_FILTERS]: true,
            [LABELS]: true,
            [REGIONS]: true,
            [COUNTRIES]: true,
            [BRANDS]: true,
            [PORTFOLIOS]: true,
            [CAMPAIGNS]: true,
            [CAMPAIGN_STATES]: true,
            [CAMPAIGN_TARGETING_TYPES]: true,
            [KEYWORDS]: true,
            [KEYWORD_MATCH_TYPES]: true,
            [KEYWORD_STATES]: true,
            [ADVANCED_TEXT]: true,
        },
    },
    table: getDefaultTable(
        serializeColumns(keywordSearchTermsSummaryTableColumnsConfig),
        {
            showTotalRow: true,
        }
    ),
}

export default handleActions({}, initialState)
