import { IntegrationActionsField } from 'components/EditableFields'
import { IntegrationDescriptionCell } from 'components/FieldRenderers/IntegrationDescriptionCell'
import {
    deleteIntegration,
    integrationAccountId,
    integrationAccountTypeList,
    integrationActiveAccountCount,
    integrationAutoSelectNewAccounts,
    integrationClientId,
    integrationCountry,
    integrationCountryList,
    integrationCreatedBy,
    integrationCreatedDate,
    integrationDescription,
    integrationStatus,
    integrationTotalAccountCount,
    integrationType,
} from 'configuration/fieldCreators/integration'
import {
    advertiserId,
    advertiserIntegrationAlias,
    advertiserIntegrationType,
    advertiserType,
} from 'configuration/fieldCreators/walmartAdvertiser'
import {
    updateVCIntegration,
    updateSellerCentralIntegration,
} from 'services/cerebroApi/orgScope/resourceApi'
import {
    updateWmMarketplaceIntegration,
    updateWalmartAdvertisers,
} from 'services/cerebroApi/orgScope/walmartApi'
import { Field, Integration, SellingPartnerIntegration } from 'types'

export const aaIntegrationsTableColumnsConfig: Field<Integration>[] = [
    integrationDescription({
        renderOptions: {
            render: ({ record, reloadData }) => {
                return (
                    <IntegrationDescriptionCell
                        integration={record}
                        reloadData={reloadData}
                    />
                )
            },
        },
    }),
    integrationActiveAccountCount(),
    integrationTotalAccountCount(),
    integrationAutoSelectNewAccounts(),
    integrationAccountTypeList(),
    integrationCountryList(),
    integrationCreatedBy(),
    integrationCreatedDate(),
    deleteIntegration({
        renderOptions: {
            render: (props) => {
                return <IntegrationActionsField {...props} />
            },
        },
    }),
]

export const integrationsTableColumnsConfig: Field<Integration>[] = [
    integrationDescription({}, 'vendor_central'),
    integrationStatus(),
    integrationCreatedDate(),
    deleteIntegration({
        renderOptions: {
            render: (props) => {
                return (
                    <IntegrationActionsField
                        updateRequestApi={updateVCIntegration}
                        {...props}
                    />
                )
            },
        },
    }),
]

export const spIntegrationsTableColumnsConfig: Field<SellingPartnerIntegration>[] =
    [
        integrationDescription({}, 'selling_partner'),
        integrationType(),
        integrationAccountId(),
        integrationCountry(),
        integrationCreatedDate(),
        deleteIntegration({
            renderOptions: {
                render: (props) => {
                    return (
                        <IntegrationActionsField
                            updateRequestApi={updateSellerCentralIntegration}
                            {...props}
                        />
                    )
                },
            },
            fixed: 'right',
            width: 160,
            minWidth: 160,
        }),
    ]

export const wmMarketplaceIntegrationsTableColumnsConfig: Field<Integration>[] =
    [
        integrationDescription({}, 'walmart_marketplace'),
        integrationClientId(),
        integrationStatus(),
        integrationCreatedDate(),
        deleteIntegration({
            renderOptions: {
                render: (props) => {
                    return (
                        <IntegrationActionsField
                            updateRequestApi={updateWmMarketplaceIntegration}
                            {...props}
                        />
                    )
                },
            },
        }),
    ]

export const wmAdvertisingIntegrationsTableColumnsConfig: Field<Integration>[] =
    [
        advertiserIntegrationAlias({}, 'walmart_advertising'),
        advertiserIntegrationType(),
        advertiserId(),
        advertiserType(),
        integrationCreatedDate(),
        deleteIntegration({
            renderOptions: {
                render: (props) => {
                    return (
                        <IntegrationActionsField
                            updateRequestApi={updateWalmartAdvertisers}
                            {...props}
                        />
                    )
                },
            },
        }),
    ]
