import flow from 'lodash/fp/flow'
import set from 'lodash/fp/set'
import { handleActions } from 'redux-actions'

import {
    fetchCapabilitiesSuccess,
    fetchTasksSuccess,
    fetchAutomationProfilesSuccess,
    fetchAutomationProfilesFailure,
} from 'actions/ui/campaignPage/automation'
import { defaultPage } from 'reducers/ui/defaults'

const defaultState = {
    ...defaultPage,
    loading: false,
    saving: false,
    capabilities: [], // Automation capabilities
    tasks: [], // Automation tasks
    automationProfiles: {
        error: null,
        automationProfiles: [],
    },
    newAutomationProfile: {
        loading: false,
        error: null,
    },
}

export default handleActions(
    {
        [fetchCapabilitiesSuccess](state, action) {
            const { results } = action.payload

            return set('capabilities', results)(state)
        },

        [fetchTasksSuccess](state, action) {
            const { results } = action.payload

            return flow(set('tasks', results), set('saving', false))(state)
        },

        [fetchAutomationProfilesSuccess](state, action) {
            const { results } = action.payload

            return flow(
                set(['automationProfiles', 'automationProfiles'], results),
                set(['automationProfiles', 'error'], null)
            )(state)
        },

        [fetchAutomationProfilesFailure](state, action) {
            const error = action.payload

            return set(['automationProfiles', 'error'], error)(state)
        },
    },
    defaultState
)
