import { useEffect } from 'react'

import { datadogRum } from '@datadog/browser-rum'
import { useRouteMatch } from 'react-router-dom'
import { StringParam, useQueryParam } from 'use-query-params'

import { DatadogService } from 'types'

const useRumManualViewTracking = (
    service?: DatadogService,
    name?: string
): void => {
    const [tabId] = useQueryParam('tab', StringParam)
    const { path } = useRouteMatch()

    // by default, breakout tabs as separate Datadog Views
    const defaultName = tabId ? `${path}/tab/${tabId}` : path

    const effectiveName = name || defaultName

    useEffect(() => {
        if (service) {
            datadogRum.startView({
                name: effectiveName,
                service,
                version: VERSION,
            })
        }
    }, [service, effectiveName])
}

export default useRumManualViewTracking
