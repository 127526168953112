import { ReactElement, ReactNode } from 'react'

import { Typography } from 'antd'

type Props = {
    children: ReactNode
}

export default function PageHeaderTitle({ children }: Props): ReactElement {
    return (
        <Typography.Title
            level={1}
            style={{
                fontWeight: 400,
                color: 'rgba(0, 0, 0, 0.95)',
                letterSpacing: '-0.005em',
                lineHeight: 1.3,
                marginBottom: 16,
                marginTop: -8,
            }}
        >
            {children}
        </Typography.Title>
    )
}
