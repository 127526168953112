import {
    Key,
    memo,
    ReactElement,
    ReactNode,
    useEffect,
    useMemo,
    useState,
} from 'react'

import { Button, Skeleton } from 'antd'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'
import { MenuInfo } from 'rc-menu/lib/interface'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { makeUpdateFilter, makeUpdateLocalFilter } from 'actions/ui/shared'
import { ContentCard } from 'components/ContentCard'
import { Empty } from 'components/Empty'
import { MoreIcon } from 'components/Icons'
import { InfoRow } from 'components/InfoRow'
import { InfoRowStyle } from 'components/InfoRow/InfoRow'
import { makeMapping } from 'configuration/dataSources/helpers'
import { DATA_SOURCES, getMetadataKey } from 'configuration/widgets'
import { DASHBOARD_ACTIONS } from 'const/dashboards'
import { DATES, RANGE_LAG } from 'const/filters'
import { DASHBOARD_PAGE, HOME_PAGE } from 'const/pages'
import { CATEGORY_CHART, METRIC, TABLE, TEXT, TIME_SERIES } from 'const/widgets'
import { usePageContext } from 'context/PageContext'
import {
    useGetDataSourceGroupBysColumns,
    useGetDataSourceMetricsColumns,
    useGetDataSources,
} from 'features/MetricsGlossary/queries'
import { userHasCustomerServicePermissions } from 'helpers/featurePermissions'
import {
    formatFilterHierarchy,
    getFiltersHierarchy,
} from 'helpers/filters/dashboards'
import { hasExportFunction } from 'helpers/widgets'
import {
    useMonitorLoadTime,
    useUiDomainValue,
    useUserHasPermissions,
} from 'hooks'
import {
    selectLocalFilters,
    selectVisibleCombinedFilters,
    selectVisibleFilters,
    selectWidgetTab,
} from 'selectors/ui'
import {
    CurrencyCode,
    DashboardAction,
    DashboardTab,
    DatadogActionName,
    DateRangeFilter,
    FiltersState,
    RootReduxState,
    Widget as IWidget,
    WidgetFilterMap,
    FilterValue,
} from 'types'
import { ActionsDropdown } from 'views/DashboardPage/Actions'

import CategoryChartWidget from './CategoryChartWidget/CategoryChartWidget'
import MetricWidget from './MetricWidget/MetricWidget'
import {
    useGetWidgetDataQuery,
    useGetWidgetPriorPeriodDataQuery,
} from './queries'
import styles from './styles.scss'
import TableWidget from './TableWidget/TableWidget'
import TextWidget from './TextWidget/TextWidget'
import TimeSeriesWidget from './TimeSeriesWidget/TimeSeriesWidget'
import WidgetConfigExplanation from './WidgetConfigExplanation'

// Set to longer period for now.
// Sales calls often preload dashboards and leave them open for a while.
// We may be able to reduce this if we more manually control caching for dashboards
// to avoid invalidating cache for dashboard tabs that are unmounted while the dashboard is still mounted.
const WIDGET_CACHE_TIME = 1000 * 60 * 60 * 4

function useFilterConfiguration(
    pageName: string,
    widget: IWidget
): WidgetFilterMap {
    const path = [pageName]
    const pageFilters = useSelector<RootReduxState, Partial<FiltersState>>(
        (state) => selectVisibleFilters(state, path)
    )
    const pageLocalFilters = useSelector<RootReduxState, Partial<FiltersState>>(
        (state) => selectLocalFilters(state, path)
    )
    const tab = useSelector<
        RootReduxState,
        | {
              [key: string]: any
              widgets: IWidget[]
          }
        | undefined
    >((state) => selectWidgetTab(state, pageName, widget.id))

    const filters: FiltersState = useSelector<RootReduxState, FiltersState>(
        (state) =>
            selectVisibleCombinedFilters(state, [pageName, 'tabs', tab?.id])
    )
    const dispatch = useDispatch()

    // Cleaning up old page filters since they aren't needed anymore
    // After DOW-10221, the correct filters now come from tabs
    if (pageName === DASHBOARD_PAGE || pageName === HOME_PAGE) {
        if (!isEmpty(pageFilters?.[DATES])) {
            const updateFilter = makeUpdateFilter(path)
            dispatch(updateFilter({ key: DATES, value: [] }))
        }

        if (!isEmpty(pageLocalFilters?.[DATES])) {
            const updateFilter = makeUpdateLocalFilter(path)
            dispatch(updateFilter({ key: DATES, value: [] }))
        }
    }

    // Something is sketchy with these types
    // FiltersState != WidgetFilterMap but this code uses them interchangeably
    const filterMap = filters as WidgetFilterMap

    const { result: hierarchy } = getFiltersHierarchy(
        filterMap,
        widget.filters,
        widget.data_source
    )

    Object.keys(hierarchy).forEach((filter) => {
        filterMap[filter] = hierarchy[filter].values
    })

    return filterMap
}

interface Props {
    widget: IWidget
    widgetTab?: DashboardTab
    otherTabs?: DashboardTab[]
    className?: string
    isExport?: boolean
    hasPermission: boolean
    showFiltersDisplay?: boolean
    dateRange?: DateRangeFilter
    rangeLag?: FilterValue
    onEdit?: (widget: IWidget) => void
    onDelete?: (widget: IWidget) => void
    onClone?: (widget: IWidget, tabId?: Key) => void
    onExport?: (widget: IWidget) => void
    onCloneToDashboard?: (widget: IWidget) => void
}

function Widget({
    widget,
    widgetTab,
    otherTabs = [],
    hasPermission,
    dateRange,
    rangeLag,
    onEdit = noop,
    onDelete = noop,
    onClone = noop,
    onExport = noop,
    onCloneToDashboard = noop,
    className = '',
    isExport = false,
    showFiltersDisplay = false,
}: Props): ReactElement {
    const { t } = useTranslation(['dashboards', 'common'])
    const [pageNumber, setPageNumber] = useState<number>(1)
    const { pageName } = usePageContext()
    const pageCurrencyCode = useUiDomainValue<CurrencyCode>([
        pageName,
        'currencyCode',
    ])
    const appCurrencyCode = useUiDomainValue<CurrencyCode>([
        'app',
        'currencyCode',
    ])
    const currencyCode = pageCurrencyCode || appCurrencyCode
    const filterConfiguration = useFilterConfiguration(pageName, widget)

    const {
        data: widgetDataResponse,
        isLoading: isLoadingWidgetData,
        isRefetching: isRefetchingWidgetData,
        isError: isWidgetDataError,
        error: widgetDataError,
        refetch: refetchWidgetData,
    } = useGetWidgetDataQuery(
        pageName,
        widget,
        filterConfiguration,
        currencyCode,
        widget.type !== TABLE,
        widget.type === TABLE ? pageNumber : undefined,
        widget.type === TABLE ? widget.pagination?.pageSize ?? 10 : undefined,
        {
            cacheTime: WIDGET_CACHE_TIME,
        }
    )
    const widgetData = widgetDataResponse?.data.results ?? []
    const totalCount = widgetDataResponse?.data.count

    const {
        data: widgetPriorPeriodDataResponse,
        isLoading: isLoadingPriorPeriodWidgetData,
        refetch: refetchPriorPeriodWidgetData,
    } = useGetWidgetPriorPeriodDataQuery(
        pageName,
        widget,
        filterConfiguration,
        currencyCode,
        widget.type !== TABLE,
        widget.type === TABLE ? pageNumber : undefined,
        widget.type === TABLE ? widget.pagination?.pageSize ?? 10 : undefined,
        {
            enabled:
                widget.type === TIME_SERIES && !!widget.include_change_metrics,
            cacheTime: WIDGET_CACHE_TIME,
        }
    )

    useMonitorLoadTime({
        actionName: DatadogActionName.LOAD_WIDGET,
        loading: isLoadingWidgetData,
        widgetContext: {
            type: widget.type,
            name: widget.name,
            data_source: widget.data_source,
            metric_count: widget.metrics.length,
            group_by_count: widget.group_by.length,
            sort_by: {
                field: widget.sorter?.field,
                order: widget.sorter?.order,
            },
            pagination: widget.pagination,
        },
    })

    const refetchData = (): void => {
        refetchWidgetData()
        if (widget.type === TIME_SERIES && widget.include_change_metrics) {
            refetchPriorPeriodWidgetData()
        }
    }

    const widgetPriorPeriodData =
        widgetPriorPeriodDataResponse?.data.results ?? []

    const isSteveMode = useUserHasPermissions(userHasCustomerServicePermissions)

    // Load data sources and metrics for the widget
    // TODO move data sources loading to the parent component
    const { data: dataSourceData, isLoading: isLoadingDataSources } =
        useGetDataSources({
            includeEndOfLife: true,
        })
    const dataSource = (dataSourceData?.data.results ?? []).find(
        (d) => d.metadata_key === getMetadataKey(widget.data_source)
    )
    const { data: metricsColumnsData, isLoading: isLoadingMetrics } =
        useGetDataSourceMetricsColumns(dataSource?.slug)

    const dataSourceConfiguration = DATA_SOURCES[widget.data_source]
    const { data: groupByColumnsData, isLoading: isLoadingGroupBys } =
        useGetDataSourceGroupBysColumns(
            dataSource?.slug,
            dataSourceConfiguration
        )

    const metricsColumns = metricsColumnsData ?? []

    const groupByColumns = groupByColumnsData ?? []

    const metricsConfig = useMemo(() => {
        return metricsColumnsData ? makeMapping(metricsColumnsData) : {}
    }, [metricsColumnsData])

    const { result: hierarchy } = getFiltersHierarchy(
        widgetTab?.localFilters || {},
        widget.filters,
        widget.data_source
    )

    // Always use the date range override if present
    hierarchy[DATES] = {
        ...hierarchy[DATES],
        values: dateRange ?? hierarchy[DATES]?.values,
    }
    hierarchy[RANGE_LAG] = {
        ...hierarchy[RANGE_LAG],
        values: rangeLag ?? hierarchy[RANGE_LAG]?.values,
    }
    const filters: WidgetFilterMap = Object.fromEntries(
        Object.entries(hierarchy).map(([filter, result]) => [
            filter,
            result.values,
        ])
    )
    useEffect(() => {
        if (widget.new) {
            // Scroll in next render cycle
            setTimeout(() => {
                const element = document.getElementById(`widget__${widget.id}`)
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth', block: 'end' })
                }
            }, 500) // seems to not work in Chrome without a decent delay
        }
    }, [widget.new, widget.id])

    function renderLoading(): ReactNode {
        // we could extend this to have different loading animations per chart type

        return <Skeleton active />
    }

    function renderWidget(): ReactNode {
        if (isWidgetDataError && !isLoadingWidgetData) {
            // This is not a perfect way to detect a response size issue,
            // as a 502 can be caused by other things, but it's a decent assumption
            // since 502s for other reasons are very rare
            const isTooMuchDataError =
                widgetDataError && widgetDataError?.response?.status === 502

            return (
                <div>
                    {isTooMuchDataError && (
                        <div className="text-center">
                            <Empty
                                className="d-flex flex-column align-items-center justify-content-center"
                                description={t(
                                    'dashboards:widget.loadingError.tooMuchData.header',
                                    'Dataset Too Large'
                                )}
                            >
                                <Trans i18nKey="dashboards:widget.loadingError.tooMuchData.description">
                                    We appreciate your thorough input, but it
                                    seems the response is too large for our
                                    system to handle efficiently.
                                    <br />
                                    Try reducing the date range, adjusting the
                                    group bys, slimming down metrics, or adding
                                    some data filters.
                                </Trans>
                            </Empty>
                        </div>
                    )}
                    {!isTooMuchDataError && (
                        <div className="text-center">
                            {t(
                                'dashboards:widget.loadingError.general',
                                'There was an error loading this widget.'
                            )}
                            <br />
                            <Button
                                type="text"
                                onClick={() => refetchData()}
                                className={styles['retry-btn']}
                            >
                                {t('dashboards:widget.retry', 'Retry')}
                            </Button>
                        </div>
                    )}
                    <WidgetConfigExplanation widget={widget} />
                </div>
            )
        }

        if (widget.type === TEXT) {
            return <TextWidget widget={widget} />
        }

        if (
            widgetData?.length === 0 &&
            !isLoadingWidgetData &&
            !isLoadingPriorPeriodWidgetData
        ) {
            return <WidgetConfigExplanation widget={widget} fallbackToNoData />
        }

        if (isLoadingDataSources || isLoadingMetrics || isLoadingGroupBys) {
            return <Skeleton active />
        }

        if (widget.type === METRIC) {
            return (
                <MetricWidget
                    widget={widget}
                    widgetData={widgetData}
                    metricsConfig={metricsConfig}
                />
            )
        }

        if (widget.type === TABLE) {
            return (
                <TableWidget
                    widget={widget}
                    widgetData={widgetData}
                    totalCount={totalCount}
                    pageNumber={pageNumber}
                    onPageNumberChange={(page) => setPageNumber(page)}
                    isLoading={isLoadingWidgetData || isRefetchingWidgetData}
                    filters={filters}
                    dataSource={dataSource}
                    metricsColumns={metricsColumns}
                    groupByColumns={groupByColumns}
                />
            )
        }

        if (widget.type === TIME_SERIES) {
            return (
                <TimeSeriesWidget
                    widget={widget}
                    widgetData={widgetData}
                    widgetPriorPeriodData={widgetPriorPeriodData}
                    filters={filters}
                    metricsConfig={metricsConfig}
                />
            )
        }

        if (widget.type === CATEGORY_CHART) {
            return (
                <CategoryChartWidget
                    widget={widget}
                    widgetData={widgetData}
                    metricsConfig={metricsConfig}
                />
            )
        }

        return null
    }

    function renderCloneSubActions(): DashboardAction[] {
        const subActions = []

        subActions.push({
            label: t('dashboards:Widget.actions.onThisTab', 'On this tab'),
            type: DASHBOARD_ACTIONS.cloneWidgetOnThisTab,
        })

        if (!isEmpty(otherTabs)) {
            subActions.push({
                label: t(
                    'dashboards:Widget.actions.toAnotherTab',
                    'To another tab'
                ),
                type: DASHBOARD_ACTIONS.cloneWidgetToOtherTab,
                subActions: otherTabs.map((tab) => ({
                    label: tab.name,
                    somethingElse: tab.id,
                    type: tab.id,
                })),
            })
        }

        subActions.push({
            label: t(
                'dashboards:Widget.actions.toAnotherDashboard',
                'To another dashboard'
            ),
            type: DASHBOARD_ACTIONS.cloneWidgetToOtherDashboard,
        })

        return subActions
    }

    const acceptedActions = [
        DASHBOARD_ACTIONS.editWidget,
        DASHBOARD_ACTIONS.exportToCsv,
        DASHBOARD_ACTIONS.cloneWidgetOnThisTab,
        DASHBOARD_ACTIONS.cloneWidgetToOtherTab,
        DASHBOARD_ACTIONS.cloneWidgetToOtherDashboard,
        DASHBOARD_ACTIONS.deleteWidget,
        DASHBOARD_ACTIONS.reloadWidget,
    ]

    const handleActionSelected = (e: MenuInfo): void => {
        const actionKey = acceptedActions.includes(e.keyPath[0].toString())
            ? e.keyPath[0]
            : e.keyPath[1]

        switch (actionKey) {
            case DASHBOARD_ACTIONS.editWidget:
                onEdit(widget)
                break

            case DASHBOARD_ACTIONS.exportToCsv:
                onExport(widget)
                break

            case DASHBOARD_ACTIONS.cloneWidgetOnThisTab:
                onClone(widget)
                break

            case DASHBOARD_ACTIONS.cloneWidgetToOtherTab:
                onClone(widget, e.keyPath[0])
                break

            case DASHBOARD_ACTIONS.cloneWidgetToOtherDashboard:
                onCloneToDashboard(widget)
                break

            case DASHBOARD_ACTIONS.deleteWidget:
                onDelete(widget)
                break

            case DASHBOARD_ACTIONS.reloadWidget:
                refetchWidgetData()
                break

            default:
                break
        }
    }

    const hasExportSupport = hasExportFunction(widget)

    function renderActions(): ReactElement[] {
        const actions: DashboardAction[] = []

        if (hasPermission) {
            actions.push(
                {
                    label: t('common:edit', 'Edit'),
                    type: DASHBOARD_ACTIONS.editWidget,
                },
                {
                    label: t('common:clone', 'Clone'),
                    type: DASHBOARD_ACTIONS.openCloneSubmenu,
                    subActions: renderCloneSubActions(),
                }
            )

            actions.push({
                label: t('common:delete', 'Delete'),
                type: DASHBOARD_ACTIONS.deleteWidget,
            })
        }

        if (widget.type !== TEXT && hasExportSupport) {
            actions.push({
                label: t(
                    'dashboards:Widget.actions.exportCSV',
                    'Export to CSV'
                ),
                type: DASHBOARD_ACTIONS.exportToCsv,
            })
        }

        if (isSteveMode) {
            actions.push({
                label: t('dashboards:Widget.actions.refresh', 'Reload'),
                type: DASHBOARD_ACTIONS.reloadWidget,
            })
        }

        return actions.length
            ? [
                  <ActionsDropdown
                      actions={actions}
                      onSelectAction={handleActionSelected}
                      btn={<MoreIcon className={styles['more-icon']} />}
                  />,
              ]
            : []
    }

    function renderFiltersInfo(): ReactNode {
        // If show_filter_row is undefined, widget-level date range filters will still be shown
        // (previously, there was a small footer on the widget showing the applied date range)
        // For widgets created or configured after DOW-10221, this flag will always have a value
        if (
            !isEmpty(widget.filters[DATES]) &&
            widget.show_filter_row === undefined
        ) {
            widget.show_filter_row = true
        }

        if (
            !showFiltersDisplay ||
            widget.type === TEXT ||
            !widget.show_filter_row
        ) {
            return null
        }

        return (
            <InfoRow
                items={formatFilterHierarchy(
                    hierarchy,
                    'This filter overrides the tab-level one.',
                    t
                )}
                styleType={
                    isExport
                        ? InfoRowStyle.PDF_EXPORT
                        : InfoRowStyle.WIDGET_DISPLAY
                }
                onEdit={() => onEdit(widget)}
                showEditWidgetButton={hasPermission}
            />
        )
    }

    const isLoadingInitialData = isLoadingWidgetData || isRefetchingWidgetData
    const showLoading = isLoadingInitialData && widget.type !== TABLE
    return (
        <ContentCard
            id={`widget__${widget.id}`}
            title={widget.name}
            subTitle={widget.description}
            display={renderFiltersInfo()}
            actions={renderActions()}
            showActionsOnHover
            className={classNames(className, {
                [styles['is-editable']]: hasPermission,
            })}
        >
            {showLoading ? renderLoading() : renderWidget()}
        </ContentCard>
    )
}

export default memo(Widget)
