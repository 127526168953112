import { DATES } from 'const/filters'
import { formatDateFromFilter } from 'helpers/utils'
import { AsinUsageParams, FiltersState } from 'types'

export const formatFilters = (filters: FiltersState): AsinUsageParams => {
    const params: AsinUsageParams = {}

    const dates = filters[DATES]
    const { start, end } = formatDateFromFilter(dates)

    if (!!start && !!end) {
        params.report_date_min = start
        params.report_date_max = end
    }

    return params
}
