import { ReactElement } from 'react'

import {
    productTitleRenderer,
    productPriceRenderer,
    productAsinRenderer,
    parentProductTitleRenderer,
    parentProductPriceRenderer,
    parentProductAsinRenderer,
    campaignProductTitleRenderer,
} from 'components/FieldRenderers'
import { productCogsRenderer } from 'components/FieldRenderers/Renderers/productCogs'
import { AMS_METRICS_COLUMNS_PAGES } from 'configuration/dataSources/ams'
import { brandCountryCode } from 'configuration/fieldCreators/brand'
import { labels } from 'configuration/fieldCreators/labels'
import {
    gross_profit_14_day_same_sku,
    gross_profit_1_day_same_sku,
    gross_profit_30_day_same_sku,
    gross_profit_7_day_same_sku,
    gross_profit_margin_14_day_same_sku,
    gross_profit_margin_1_day_same_sku,
    gross_profit_margin_30_day_same_sku,
    gross_profit_margin_7_day_same_sku,
} from 'configuration/fieldCreators/metrics/productAds'
import {
    productAsin,
    productMetaDataNumberOfItems,
    productMetaDataPrice,
    productMetaDataTitle,
    productSku,
    removeProductFromLabel,
    skuCogs,
    skuMiscCogs,
    skuRawCogs,
    skuSupplierCogs,
} from 'configuration/fieldCreators/product'
import { metadataBrand } from 'configuration/fieldCreators/productMetadata'
import {
    ProductFactRecord,
    ParentProductGroupBy,
    Brand,
    Field,
    ProductAdFact,
    ProductSkuFactRecord,
} from 'types'

export type ParentProductFactRecord = ProductAdFact<
    ParentProductGroupBy & {
        profile__country_code: Brand['country_code']
        children?: ReactElement[]
    }
>

export const productsSummaryProductsTableColumnsConfig: Field<ProductFactRecord>[] =
    [
        productMetaDataTitle({
            fixed: 'left',
            dataIndex: ['metadata', 'title'],
            renderOptions: {
                render: (cellRenderProps) =>
                    productTitleRenderer({ cellRenderProps }),
            },
        }),
        productMetaDataPrice({
            dataIndex: ['metadata', 'price'],
            renderOptions: {
                render: (cellRenderProps) =>
                    productPriceRenderer({ cellRenderProps }),
            },
        }),
        productMetaDataNumberOfItems({
            dataIndex: ['metadata', 'number_of_items'],
        }),
        productAsin({
            dataIndex: ['product_ad__asin'],
            renderOptions: {
                render: (cellRenderProps) =>
                    productAsinRenderer({ cellRenderProps }),
            },
        }),
        labels({ dataIndex: ['metadata', 'labels'] }),
        ...(AMS_METRICS_COLUMNS_PAGES as Field<ProductFactRecord>[]),
    ]

export const productsSummaryParentProductsTableColumnsConfig: Field<ParentProductFactRecord>[] =
    [
        productMetaDataTitle({
            fixed: 'left',
            dataIndex: ['primary_metadata', 'title'],
            sorter: false,
            renderOptions: {
                render: (cellRenderProps) =>
                    parentProductTitleRenderer({
                        cellRenderProps,
                    }),
            },
        }),
        productMetaDataPrice({
            dataIndex: ['primary_metadata', 'price'],
            sorter: false,
            renderOptions: {
                render: (cellRenderProps) =>
                    parentProductPriceRenderer({
                        cellRenderProps,
                    }),
            },
        }),
        productMetaDataNumberOfItems({
            dataIndex: ['primary_metadata', 'number_of_items'],
            sorter: false,
        }),
        productAsin({
            dataIndex: ['primary_asin'],
            renderOptions: {
                render: (cellRenderProps) =>
                    parentProductAsinRenderer({
                        cellRenderProps,
                    }),
            },
        }),
        labels({
            dataIndex: ['primary_metadata', 'labels'],
            sorter: false,
        }),
        brandCountryCode({ dataIndex: ['profile__country_code'] }),
        metadataBrand({
            dataIndex: ['primary_metadata', 'brand'],
            sorter: false,
        }),
        ...(AMS_METRICS_COLUMNS_PAGES as Field<ParentProductFactRecord>[]),
    ]

export const labelProductsTableColumnsConfig: Field<ProductFactRecord>[] = [
    productMetaDataTitle({
        fixed: 'left',
        dataIndex: ['metadata', 'title'],
        renderOptions: {
            render: (cellRenderProps) =>
                productTitleRenderer({ cellRenderProps }),
        },
    }),
    productMetaDataPrice({
        dataIndex: ['metadata', 'price'],
        renderOptions: {
            render: (cellRenderProps) =>
                productPriceRenderer({ cellRenderProps }),
        },
    }),
    productMetaDataNumberOfItems({
        dataIndex: ['metadata', 'number_of_items'],
    }),
    metadataBrand({
        dataIndex: ['metadata', 'brand'],
    }),
    productAsin({
        dataIndex: ['product_ad__asin'],
        renderOptions: {
            render: (cellRenderProps) =>
                productAsinRenderer({ cellRenderProps }),
        },
    }),
    brandCountryCode({ dataIndex: ['profile__country_code'] }),
    labels({ dataIndex: ['metadata', 'labels'] }),
    ...(AMS_METRICS_COLUMNS_PAGES as Field<ProductFactRecord>[]),
    removeProductFromLabel(),
]

export const campaignProductsTableColumnsConfig: Field<ProductFactRecord>[] = [
    productMetaDataTitle({
        fixed: 'left',
        dataIndex: ['metadata', 'title'],
        renderOptions: {
            render: (cellRenderProps) =>
                campaignProductTitleRenderer({ cellRenderProps }),
        },
    }),
    productMetaDataPrice({
        dataIndex: ['metadata', 'price'],
        renderOptions: {
            render: (cellRenderProps) =>
                productPriceRenderer({ cellRenderProps }),
        },
    }),
    productMetaDataNumberOfItems({
        dataIndex: ['metadata', 'number_of_items'],
    }),
    productAsin({
        dataIndex: ['metadata', 'asin'],
        renderOptions: {
            render: (cellRenderProps) =>
                productAsinRenderer({ cellRenderProps }),
        },
    }),
    labels({ dataIndex: ['metadata', 'labels'] }),
    ...(AMS_METRICS_COLUMNS_PAGES as Field<ProductFactRecord>[]),
]

export const skuProductsTableColumnsConfig: Field<ProductSkuFactRecord>[] = [
    productMetaDataTitle({
        fixed: 'left',
        dataIndex: ['metadata', 'title'],
        renderOptions: {
            render: (cellRenderProps) =>
                productTitleRenderer({ cellRenderProps }),
        },
    }),
    productMetaDataPrice({
        dataIndex: ['metadata', 'price'],
        renderOptions: {
            render: (cellRenderProps) =>
                productPriceRenderer({ cellRenderProps }),
        },
    }),
    productMetaDataNumberOfItems({
        dataIndex: ['metadata', 'number_of_items'],
    }),
    productAsin({
        dataIndex: ['product_ad__asin'],
        renderOptions: {
            render: (cellRenderProps) =>
                productAsinRenderer({ cellRenderProps }),
        },
    }),
    brandCountryCode({ dataIndex: ['profile__country_code'] }),
    productSku({
        dataIndex: ['product_ad__sku'],
    }),
    skuCogs({
        renderOptions: {
            render: (cellRenderProps) =>
                productCogsRenderer({
                    cellRenderProps,
                    dataIndex: ['total_cogs'],
                }),
        },
    }),
    skuRawCogs({
        renderOptions: {
            render: (cellRenderProps) =>
                productCogsRenderer({
                    cellRenderProps,
                    dataIndex: ['raw_cogs'],
                }),
        },
        isVisible: false,
    }),
    skuSupplierCogs({
        renderOptions: {
            render: (cellRenderProps) =>
                productCogsRenderer({
                    cellRenderProps,
                    dataIndex: ['supplier_cogs'],
                }),
        },
        isVisible: false,
    }),
    skuMiscCogs({
        renderOptions: {
            render: (cellRenderProps) =>
                productCogsRenderer({
                    cellRenderProps,
                    dataIndex: ['misc_cogs'],
                }),
        },
        isVisible: false,
    }),
    gross_profit_1_day_same_sku() as Field<ProductSkuFactRecord>,
    gross_profit_7_day_same_sku() as Field<ProductSkuFactRecord>,
    gross_profit_14_day_same_sku() as Field<ProductSkuFactRecord>,
    gross_profit_30_day_same_sku() as Field<ProductSkuFactRecord>,
    gross_profit_margin_1_day_same_sku() as Field<ProductSkuFactRecord>,
    gross_profit_margin_7_day_same_sku() as Field<ProductSkuFactRecord>,
    gross_profit_margin_14_day_same_sku() as Field<ProductSkuFactRecord>,
    gross_profit_margin_30_day_same_sku() as Field<ProductSkuFactRecord>,
    ...(AMS_METRICS_COLUMNS_PAGES as Field<ProductSkuFactRecord>[]),
]

export const adAccountProductsTableColumnsConfig: Field<ProductFactRecord>[] = [
    ...campaignProductsTableColumnsConfig,
]

export const portfolioProductsTableColumnsConfig: Field<ProductFactRecord>[] = [
    ...campaignProductsTableColumnsConfig,
]
