import { ReactElement } from 'react'

import Icon, { QuestionCircleOutlined } from '@ant-design/icons'
import { Divider, Progress, Tooltip } from 'antd'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

import { AUTOMATION_ICONS, AUTOMATION_KEYS } from 'const/automations'
import { automationProfileUrl } from 'helpers/urls'

import { FullBudgetConfiguration } from './helpers'
import styles from './styles.scss'

interface Props {
    configuration: FullBudgetConfiguration
}

const BudgetStatusTooltip = ({ configuration }: Props): ReactElement => (
    <div className={styles.tooltip}>
        <span>Status:</span>{' '}
        <span style={{ color: configuration.colors?.text }}>
            {configuration.text.title}
        </span>
        <Progress
            className={styles['progress-bar']}
            percent={configuration.budget.basePercentageSpent}
            trailColor="#D7D8D9"
            strokeWidth={3}
            strokeColor={configuration.colors?.progress}
            showInfo={false}
        />
        <div className={styles['spend-info']}>
            <span>
                {configuration.budget.spent} / {configuration.budget.base}
            </span>
            <span>{configuration.budget.basePercentageSpent}% spent</span>
            {configuration.budget.basePercentageSpent > 100 && (
                <Tooltip
                    title={
                        <>
                            <p>
                                On any given day you could spend less than your
                                daily budget, or up to 25% more than your
                                average daily budget.
                            </p>
                            <p className="mb-0">
                                The daily budget amount is averaged over the
                                course of a calendar month.
                            </p>
                        </>
                    }
                >
                    <QuestionCircleOutlined className={styles.icon} />
                </Tooltip>
            )}
        </div>
        {configuration.abcTask?.enabled && (
            <div className={styles['abc-details']}>
                <Divider className={styles.divider} />
                <div className="d-flex align-items-center">
                    <Icon
                        className={styles.icon}
                        component={
                            AUTOMATION_ICONS[
                                AUTOMATION_KEYS.advanced_budget_control
                            ]
                        }
                    />
                    <div className="d-flex flex-column">
                        <span>Advanced Budget Control is Enabled</span>
                        <Link
                            to={automationProfileUrl(
                                configuration.abcTask.automation_profile?.id ??
                                    ''
                            )}
                        >
                            <span>
                                {configuration.abcTask.automation_profile?.name}
                            </span>
                        </Link>
                    </div>
                </div>
                <Divider className={styles.divider} />
                <span style={{ marginBottom: '8px' }}>
                    <span
                        className={classNames({
                            [styles['under-budget']]:
                                configuration.budget.activePercentageSpent <
                                100,
                            [styles['over-active-budget']]:
                                configuration.budget.activePercentageSpent >=
                                100,
                        })}
                    >
                        {configuration.budget.activePercentageSpent}%
                    </span>{' '}
                    spent of active budget ({configuration.budget.active})
                </span>
                <span>
                    <span
                        className={classNames({
                            [styles['under-budget']]:
                                configuration.budget.basePercentageSpent < 100,
                            [styles['over-base-budget']]:
                                configuration.budget.basePercentageSpent >= 100,
                        })}
                    >
                        {configuration.budget.basePercentageSpent}%
                    </span>{' '}
                    spent of total daily budget* ({configuration.budget.base})
                </span>
                <span className={styles.observation}>
                    *factors in Advanced Budget Control adjustments
                </span>
            </div>
        )}
        <div className={styles['updated-at']}>
            <span>as of {configuration.budget.updateTime}</span>
        </div>
    </div>
)

export default BudgetStatusTooltip
