import { captureException } from '@sentry/browser'
import produce from 'immer'
import compact from 'lodash/compact'
import flatMap from 'lodash/flatMap'
import flow from 'lodash/fp/flow'
import set from 'lodash/fp/set'
import unset from 'lodash/fp/unset'
import { all, call, put, select } from 'redux-saga/effects'

import {
    changeLayout,
    exportToPdfSuccess,
    fetchScheduledExportsSuccess,
    saveDashboardFailure,
    saveDashboardSuccess,
    shareDashboardFailure,
    shareDashboardSuccess,
} from 'actions/ui/dashboardPage'
import { makeFetchDashboardSuccess } from 'actions/ui/shared/dashboard'
import { DASHBOARD_PAGE } from 'const/pages'
import { userHasCustomerServicePermissions } from 'helpers/featurePermissions'
import { generateReportNotification } from 'helpers/notifications'
import { removeUIStateFromTabsAndWidgets } from 'helpers/ui/dashboardPage'
import { cerebroApiSaga } from 'sagas/common'
import { fetchOrganizationMembersSaga } from 'sagas/orgs/members'
import uiSagaRegistry from 'sagas/ui/registry'
import {
    selectDomainValue as selectAuthDomainValue,
    selectUserFeaturePermissions,
} from 'selectors/auth'
import {
    selectDashboardCurrencyCode,
    selectDashboardOfPage,
    selectDashboardParamsOfPage,
    selectDomainValue as selectUiDomainValue,
} from 'selectors/ui'
import { selectDenormalizedTabs } from 'selectors/ui/dashboard'
import {
    createScheduledExport,
    deleteScheduledExport,
    exportDashboardToPdf,
    getDashboard,
    getScheduledExports,
    updateDashboard,
    updateScheduledExport,
} from 'services/cerebroApi/orgScope/resourceApi'
import history from 'utilities/history'

function* fetchDashboardSaga() {
    const { dashboardId } = yield select(
        selectDashboardParamsOfPage,
        DASHBOARD_PAGE
    )

    yield call(
        cerebroApiSaga,
        makeFetchDashboardSuccess(DASHBOARD_PAGE),
        getDashboard,
        dashboardId
    )
}

function* fetchScheduledExports() {
    const { dashboardId } = yield select(
        selectDashboardParamsOfPage,
        DASHBOARD_PAGE
    )

    yield call(
        cerebroApiSaga,
        fetchScheduledExportsSuccess,
        getScheduledExports,
        {
            dashboard: dashboardId,
            state: 'enabled',
            limit: 1,
            'schedule_type!': 'one_time',
        }
    )
}

/**
 * Cancel dashboard changes
 */
export function* cancelDashboardChangesWorker() {
    const dashboard = yield select(selectDashboardOfPage, DASHBOARD_PAGE)

    // Reset the layout before refreshing the dashboard
    // so an extra layout change is not dispatched after
    // it renders
    const layout = compact(
        flatMap(dashboard.config.tabs, (tab) =>
            tab.widgets.map((widget) => widget.layout)
        )
    )

    if (layout.length > 0) {
        yield put(changeLayout(layout))
    }

    yield put(makeFetchDashboardSuccess(DASHBOARD_PAGE)(dashboard))
}

export function* saveDashboardWorker() {
    try {
        const { dashboardId } = yield select(
            selectDashboardParamsOfPage,
            DASHBOARD_PAGE
        )
        const { name, description, config } = yield select(
            selectDashboardOfPage,
            DASHBOARD_PAGE
        )
        const currencyCode = yield select(selectUiDomainValue, [
            DASHBOARD_PAGE,
            'currencyCode',
        ])
        const tabs = yield select(selectDenormalizedTabs, DASHBOARD_PAGE)

        const updatedTabs = removeUIStateFromTabsAndWidgets(tabs)

        const data = {
            name,
            description,
            config: flow(
                set('currency_code', currencyCode),
                set('tabs', updatedTabs),
                unset('filters')
            )(config),
        }

        yield call(
            cerebroApiSaga,
            saveDashboardSuccess,
            updateDashboard,
            dashboardId,
            data
        )
    } catch (error) {
        yield put(saveDashboardFailure(error))
    }
}

export function* shareDashboardWorker(action) {
    try {
        const { dashboardId } = yield select(
            selectDashboardParamsOfPage,
            DASHBOARD_PAGE
        )

        const { data, callback } = action.payload

        yield call(
            cerebroApiSaga,
            shareDashboardSuccess,
            updateDashboard,
            dashboardId,
            data
        )

        yield call(fetchDashboardSaga)
        callback()
    } catch (error) {
        yield put(shareDashboardFailure(error))
    }
}

export function* cloneWidgetWorker(action) {
    const { tabId } = action.payload
    const { dashboardId } = yield select(
        selectDashboardParamsOfPage,
        DASHBOARD_PAGE
    )
    history.push(`/dashboards/${dashboardId}?tab=${tabId}`)
}

export function* exportToPdfWorker() {
    const dashboard = yield select(selectDashboardOfPage, DASHBOARD_PAGE)

    const currencyCode = yield select(selectDashboardCurrencyCode)

    const params = {
        async_download_name: dashboard.name,
        currency_code: currencyCode,
        date_range: 'dashboard_date',
    }

    try {
        const response = yield call(
            cerebroApiSaga,
            exportToPdfSuccess,
            exportDashboardToPdf,
            dashboard.id,
            params
        )
        if (response.status === 200) {
            generateReportNotification(dashboard.name)
        }
    } catch (error) {
        yield call(captureException, error)
    }
}

export function* createScheduledExportWorker(action) {
    const { data, callback } = action.payload

    const dashboard = yield select(selectDashboardOfPage, DASHBOARD_PAGE)

    const params = {
        async_download_name: dashboard.name,
        currency_code: data.currency_code,
        date_range: data.date_range,
    }

    const hasCustomerServicePermissions = userHasCustomerServicePermissions(
        yield select(selectUserFeaturePermissions)
    )

    if (hasCustomerServicePermissions) {
        params.org_group_id = yield select(selectAuthDomainValue, [
            'organizationGroupId',
        ])
    }

    const requestBody = produce(data, (draft) => {
        delete draft.date_range
        delete draft.currency_code
        draft.dashboard_id = dashboard.id
    })

    try {
        yield call(
            cerebroApiSaga,
            null,
            createScheduledExport,
            requestBody,
            params
        )
        yield call(fetchScheduledExports)
        callback()
    } catch (error) {
        yield call(captureException, error)
    }
}

export function* updateScheduledExportWorker(action) {
    const { data, callback } = action.payload

    const params = {
        date_range: data.date_range,
        currency_code: data.currency_code,
    }

    try {
        yield call(
            cerebroApiSaga,
            null,
            updateScheduledExport,
            data.id,
            produce(data, (draft) => {
                delete draft.id
                delete draft.date_range
                delete draft.currency_code
            }),
            params
        )
        yield call(fetchScheduledExports)
        callback()
    } catch (error) {
        yield call(captureException, error)
    }
}

export function* deleteScheduledExportWorker(action) {
    const { id, callback } = action.payload

    try {
        yield call(cerebroApiSaga, null, deleteScheduledExport, id)
        yield call(fetchScheduledExports)
        callback()
    } catch (error) {
        yield call(captureException, error)
    }
}

/**
 * Mounts the Dashboard Page and fetches data
 */
function* mountPageSaga() {
    yield call(fetchDashboardSaga)

    const dashboard = yield select(selectDashboardOfPage, DASHBOARD_PAGE)

    if (dashboard) {
        yield all([
            call(
                fetchOrganizationMembersSaga,
                yield select(selectAuthDomainValue, ['organizationId'])
            ),
            call(fetchScheduledExports),
        ])
    }
}

uiSagaRegistry.registerSagas([DASHBOARD_PAGE], {
    mountPageSaga,
})
