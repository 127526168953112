import { combineChildrenReducers } from 'utilities/reducers'

import adGroupsReducer from './adGroups'
import adItemsReducer from './adItems'
import keywordsReducer from './keywords'
import pageReducer from './page'

export default combineChildrenReducers(pageReducer, {
    adGroups: adGroupsReducer,
    adItems: adItemsReducer,
    keywords: keywordsReducer,
})
