import { memo, ReactElement, useState } from 'react'

import { DeleteOutlined } from '@ant-design/icons'
import { Popconfirm, type PopconfirmProps } from 'antd'
import { type TooltipPlacement } from 'antd/es/tooltip'
import { AxiosResponse } from 'axios'
import isEqual from 'lodash/isEqual'
import noop from 'lodash/noop'
import { useTranslation } from 'react-i18next'

import { IconButton } from 'components/Buttons'
import { useCerebroApiRequest } from 'hooks'
import message from 'utilities/message'

interface Props {
    disabled: boolean
    okText?: PopconfirmProps['okText']
    title?: PopconfirmProps['title']
    danger?: boolean
    icon?: ReactElement
    updateRequestApi: (...args: any) => Promise<AxiosResponse>
    updateRequestCallback?: () => void
    updateRequestData: any
    updateRequestParams?: any
    updateRequestSuccessMesg: string | ReactElement
    tooltipTitle?: string | ReactElement
    tooltipPlacement?: TooltipPlacement
}

function DeleteField<RecordType>({
    disabled = false,
    okText = 'Delete',
    title = 'Are you sure you want to delete this record',
    danger = true,
    icon = <DeleteOutlined />,
    updateRequestApi,
    updateRequestCallback = noop,
    updateRequestData,
    updateRequestParams = {},
    updateRequestSuccessMesg,
    tooltipTitle,
    tooltipPlacement,
}: Props): ReactElement {
    const { t } = useTranslation('common')
    const [loading, setLoading] = useState(false)
    const makeCerebroApiRequest = useCerebroApiRequest()

    const onConfirm = async (): Promise<void> => {
        setLoading(true)

        await makeCerebroApiRequest<RecordType>({
            request: updateRequestApi(updateRequestData, updateRequestParams),
            onRequestSuccess: () => {
                message.success(updateRequestSuccessMesg)
                updateRequestCallback()
            },
        })

        setLoading(false)
    }

    return (
        <Popconfirm
            disabled={disabled}
            placement="topRight"
            title={title}
            onConfirm={onConfirm}
            okText={okText}
            okButtonProps={{
                danger: true,
            }}
            okType="primary"
            cancelText={t('common:cancel', 'Cancel')}
        >
            <IconButton
                danger={danger}
                disabled={disabled}
                icon={icon}
                loading={loading}
                type="link"
                tooltipTitle={tooltipTitle}
                tooltipPlacement={tooltipPlacement}
            />
        </Popconfirm>
    )
}

export default memo(DeleteField, isEqual) as typeof DeleteField
