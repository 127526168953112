import { useMemo } from 'react'

interface PendoHookValue {
    pendo?: pendo.Pendo
}

const usePendo = (): PendoHookValue => {
    const windowWithPendo = window as typeof window & {
        pendo?: pendo.Pendo
    }

    return useMemo(
        () => ({
            pendo: windowWithPendo.pendo,
        }),
        [windowWithPendo.pendo]
    )
}

export default usePendo
