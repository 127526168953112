import { ReactElement, useEffect } from 'react'

import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom'

import { AUTH_PAGE } from 'const/pages'
import { getPath } from 'helpers/pages'
import { useAuthDomainValue } from 'hooks'
import { sendSegmentPageEvent } from 'services/segment'

interface LocationState {
    prevPath?: string
    prevPathFromDropdown?: string
}

function PrivateRoute(props: RouteProps): ReactElement {
    const location = useLocation<LocationState>() || {}
    const { pathname, search, state } = location
    const signedIn = useAuthDomainValue('signedIn')
    const prevPathLink = localStorage.getItem('prevPath')
    useEffect(() => {
        const prevPath = state?.prevPath || prevPathLink

        if (signedIn && pathname && prevPath) {
            sendSegmentPageEvent(pathname, prevPath)
            if (prevPathLink) {
                localStorage.removeItem('prevPath')
            }
        } else if (signedIn && pathname) {
            sendSegmentPageEvent(pathname)
        }
    }, [signedIn, pathname, search, state, prevPathLink])

    // redirect to auth page if the user is not signed in
    if (!signedIn) {
        return (
            <Redirect
                to={{
                    pathname: getPath(AUTH_PAGE),
                    state: { from: location },
                }}
            />
        )
    }

    return <Route {...props} />
}

export default PrivateRoute
