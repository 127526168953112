import { ReactElement } from 'react'

import styles from './styles.scss'
import { LoadingIndicator } from '../LoadingIndicator'

const RouteLoadingPlaceholder = (): ReactElement => {
    return (
        <div className={styles.background}>
            <LoadingIndicator size="small" />
        </div>
    )
}

export default RouteLoadingPlaceholder
