import { ReactElement, useEffect } from 'react'

import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import ReactGridLayout, { WidthProvider } from 'react-grid-layout'
import { RouteComponentProps, useRouteMatch } from 'react-router-dom'
import { StringParam, useQueryParam } from 'use-query-params'

import { InfoIcon, WhitelabelLogoIcon } from 'components/Icons'
import { LoadingIndicator } from 'components/LoadingIndicator'
import { Widget } from 'components/Widgets'
import { DATES } from 'const/filters'
import { DASHBOARD_PRINT_PAGE } from 'const/pages'
import { PageContextProvider } from 'context/PageContext'
import { UserOverrideContextProvider } from 'context/UserOverrideContext'
import { getDateRange } from 'helpers/dateRange'
import { getOrganizationTheme } from 'helpers/organizationTheme'
import {
    useDenormalizedTabs,
    useMountingState,
    useMountPage,
    useRumManualViewTracking,
    useUiDomainValue,
} from 'hooks'
import usePendo from 'hooks/usePendo'
import CobaltLogo from 'images/logo/CobaltLogo'
import { defaultDatesFilter } from 'reducers/ui/defaults'
import {
    Dashboard,
    DashboardTab,
    DatadogService,
    DateRangeFilter,
    Organization,
} from 'types'
import moment from 'utilities/moment'

import { useDateRangeQueryParam } from './localHooks'
import styles from './styles.scss'

type Props = RouteComponentProps<{ dashboardId: string }>

const GridLayout = WidthProvider(ReactGridLayout)

function DashboardPrintPage({
    match: {
        params: { dashboardId },
    },
}: Props): ReactElement {
    const { path } = useRouteMatch()
    const { pendo } = usePendo()
    useEffect(() => {
        try {
            pendo?.setGuidesDisabled(true)
            pendo?.stopGuides()
        } catch {
            // ignore - can error if pendo is not loaded
        }
    }, [pendo])

    useRumManualViewTracking(DatadogService.DASHBOARDS, path)

    const [userId] = useQueryParam('user_id', StringParam)
    const [organizationId] = useQueryParam('org_id', StringParam)
    const [organizationGroupId] = useQueryParam('org_group_id', StringParam)
    const [currencyCode] = useQueryParam('currency_code', StringParam)
    const [dateRange] = useDateRangeQueryParam()

    useMountPage(DASHBOARD_PRINT_PAGE, {
        dashboardId,
        userId,
        orgId: organizationId,
        orgGroupId: organizationGroupId,
        dateRange,
        currencyCode,
    })

    const mounting = useMountingState(DASHBOARD_PRINT_PAGE)

    const organization = useUiDomainValue<Organization>([
        DASHBOARD_PRINT_PAGE,
        'organization',
    ])

    const dashboard = useUiDomainValue<Dashboard>([
        DASHBOARD_PRINT_PAGE,
        'dashboard',
    ])

    const tabs = useDenormalizedTabs(DASHBOARD_PRINT_PAGE)

    // Remove horizontal scroll bar on table widgets
    useEffect(() => {
        if (!mounting) {
            setTimeout(() => {
                const tables = document.querySelectorAll('.ant-table-body')
                tables.forEach((table) =>
                    table.setAttribute('style', 'overflow-x: hidden')
                )
            }, 100)
        }
    }, [mounting])

    if (mounting || !dashboard || isEmpty(tabs)) {
        return <LoadingIndicator size="small" />
    }

    const theme = getOrganizationTheme(organization)
    const whiteLabelLogoUrl = theme?.logo_url_print

    const renderTab = (tab: DashboardTab, index: number): ReactElement => {
        const tabDateRange =
            (tab.localFilters?.[DATES] as DateRangeFilter) ?? defaultDatesFilter

        const showFilterConflictIcon = dateRange && dateRange !== tabDateRange

        return (
            <div key={tab.id} className={styles['tab-section']}>
                <div
                    className={classNames(styles['tab-header'], {
                        'first-tab-header': index === 0,
                    })}
                >
                    <h2>{tab.name}</h2>
                    <h2 className={styles['date-range']}>
                        {getDateRange(
                            dateRange ?? tabDateRange,
                            'MMMM D, YYYY'
                        )}
                        {showFilterConflictIcon && (
                            <InfoIcon className={styles['info-icon']} />
                        )}
                    </h2>
                </div>
                <div className={styles['tab-content']}>
                    <GridLayout
                        layout={tab.widgets.map((w) => w.layout)}
                        rowHeight={30}
                        isDraggable={false}
                        isResizable={false}
                    >
                        {tab.widgets.map((widget) => (
                            <div key={widget.id}>
                                <Widget
                                    className={classNames(styles.widget, {
                                        [styles.table]: widget.type === 'table',
                                    })}
                                    widget={widget}
                                    widgetTab={tab}
                                    isExport
                                    hasPermission={false}
                                    showFiltersDisplay
                                    dateRange={dateRange}
                                />
                            </div>
                        ))}
                    </GridLayout>
                </div>
            </div>
        )
    }

    return (
        <PageContextProvider pageName={DASHBOARD_PRINT_PAGE}>
            <UserOverrideContextProvider
                userOverride={{
                    userId,
                    organizationId,
                    organizationGroupId,
                }}
            >
                <div id="dashboard-content" className={styles.container}>
                    <header>
                        <div>
                            <h1>{dashboard.name}</h1>
                        </div>
                        {whiteLabelLogoUrl ? (
                            <WhitelabelLogoIcon
                                src={whiteLabelLogoUrl}
                                alt={organization.name}
                                className={styles['org-logo']}
                            />
                        ) : (
                            <div className={styles['org-name']}>
                                {organization.name}
                            </div>
                        )}
                        <div className="fg-text-light mb-3">
                            {dashboard.description}
                        </div>
                        <div className={styles.metadata}>
                            <span>Created by:</span>
                            {dashboard.created_by}
                        </div>
                        <div className={styles.metadata}>
                            <span>Exported:</span>
                            {moment().format('MMM D, YYYY')}
                        </div>
                    </header>
                    {tabs.map(renderTab)}
                    <footer className="d-flex justify-content-between">
                        <div>
                            <div>{dashboard.name}</div>
                            <div>{getDateRange(dateRange, 'MMMM D, YYYY')}</div>
                        </div>

                        <div className={styles['ds-icon']}>
                            <div className={styles.powered}>Powered By</div>
                            <CobaltLogo style={{ width: 130 }} />
                        </div>
                    </footer>
                </div>
            </UserOverrideContextProvider>
        </PageContextProvider>
    )
}

export default DashboardPrintPage
