import { ReactElement, useRef, useEffect } from 'react'

import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import CreateAdItemFormAllRow from './CreateAdItemFormAllRow'
import CreateAdItemFormHeader from './CreateAdItemFormHeader'
import CreateAdItemFormRow from './CreateAdItemFormRow'
import { BaseAdItem, ColumnConfig } from './localTypes'
import styles from './styles.scss'

interface Props<AdItemField> {
    adItems: AdItemField[]
    remove: (index: number) => void
    columnsConfig: ColumnConfig[]
}

function CreateAdItemForm<AdItemField extends BaseAdItem>({
    adItems = [],
    remove,
    columnsConfig,
}: Props<AdItemField>): ReactElement {
    const { t } = useTranslation('table')

    const scrollToBottomRef = useRef<null | HTMLDivElement>(null)

    // Scroll to bottom of list when adding new items
    useEffect(() => {
        scrollToBottomRef.current?.scrollIntoView({ behavior: 'smooth' })
    }, [adItems])

    return (
        <div className="d-flex flex-column mt-2 flex-shrink-0">
            <div className="fg-font-size-medium fg-text-primary fg-font-weight-500 mb-2">
                {`${t(
                    'table:fields.walmartAdItem.creation.addedContentMsg',
                    'Added Items'
                )} (${adItems.length})`}
            </div>
            {adItems.length > 0 ? (
                <div className={styles['ad-item-table-container']}>
                    <div className={styles.sticky}>
                        <CreateAdItemFormHeader columnsConfig={columnsConfig} />
                        <CreateAdItemFormAllRow<AdItemField>
                            adItems={adItems}
                            columnsConfig={columnsConfig}
                        />
                    </div>
                    {adItems.map((adItem, idx) => (
                        <CreateAdItemFormRow<AdItemField>
                            key={idx}
                            adItem={adItem}
                            adItemIndex={idx}
                            remove={remove}
                            columnsConfig={columnsConfig}
                        />
                    ))}
                    <div ref={scrollToBottomRef} />
                </div>
            ) : (
                <div className={classNames('p-3', styles['empty-table-text'])}>
                    {t(
                        'table:fields.walmartAdItem.creation.noContentMsg',
                        'You haven’t added any Items yet.'
                    )}
                </div>
            )}
        </div>
    )
}

export default CreateAdItemForm
