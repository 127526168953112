import { TFunction } from 'i18next'

import { COUNTRY_CODES } from 'const/codes'

export const getCountryNames = (t: TFunction): any => ({
    [COUNTRY_CODES.AE]: t(
        'common:countries.unitedArabEmirates.name',
        'United Arab Emirates'
    ),
    [COUNTRY_CODES.AU]: t('common:countries.australia.name', 'Australia'),
    [COUNTRY_CODES.BR]: t('common:countries.brazil.name', 'Brazil'),
    [COUNTRY_CODES.CA]: t('common:countries.canada.name', 'Canada'),
    [COUNTRY_CODES.DE]: t('common:countries.germany.name', 'Germany'),
    [COUNTRY_CODES.ES]: t('common:countries.spain.name', 'Spain'),
    [COUNTRY_CODES.FR]: t('common:countries.france.name', 'France'),
    [COUNTRY_CODES.IN]: t('common:countries.india.name', 'India'),
    [COUNTRY_CODES.IT]: t('common:countries.italy.name', 'Italy'),
    [COUNTRY_CODES.JP]: t('common:countries.japan.name', 'Japan'),
    [COUNTRY_CODES.MX]: t('common:countries.mexico.name', 'Mexico'),
    [COUNTRY_CODES.NL]: t('common:countries.netherlands.name', 'Netherlands'),
    [COUNTRY_CODES.PL]: t('common:countries.poland.name', 'Poland'),
    [COUNTRY_CODES.SA]: t('common:countries.saudiArabia.name', 'Saudi Arabia'),
    [COUNTRY_CODES.SG]: t('common:countries.singapore.name', 'Singapore'),
    [COUNTRY_CODES.SE]: t('common:countries.sweden.name', 'Sweden'),
    [COUNTRY_CODES.TR]: t('common:countries.turkey.name', 'Turkey'),
    [COUNTRY_CODES.UK]: t(
        'common:countries.unitedKingdom.name',
        'United Kingdom'
    ),
    [COUNTRY_CODES.US]: t(
        'common:countries.unitedStates.name',
        'United States'
    ),
})
