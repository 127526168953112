import { ReactNode } from 'react'

import { Translation } from 'react-i18next'
import { generatePath } from 'react-router-dom'

import { ResourceLink } from 'components/Links/ResourceLink'
import { UNDEFINED_VALUE } from 'const/formatting'
import { AD_GROUP_PAGE, CAMPAIGN_PAGE } from 'const/pages'
import { isASIN } from 'helpers/asin'
import { getPath } from 'helpers/pages'
import { isUnset } from 'helpers/utilities'
import { QueryGroupBy, CellRenderProps } from 'types'

export function isQueryUsedAsNegativeKeywordRenderer<
    RecordType extends QueryGroupBy,
>({
    record,
    undefinedValue = UNDEFINED_VALUE,
}: CellRenderProps<RecordType>): ReactNode {
    const {
        campaign,
        ad_group,
        negative_keyword_id: negativeKeywordId,
        campaign_negative_keyword_id: campaignNegativeKeywordId,
    } = record

    let campaignNegativeKeywordDescription
    let adGroupNegativeKeywordDescription
    let numberOfNegativeKeywords = 0

    if (!isUnset(campaignNegativeKeywordId) && !isUnset(campaign)) {
        campaignNegativeKeywordDescription = (
            <span>
                <ResourceLink
                    to={`${generatePath(getPath(CAMPAIGN_PAGE), {
                        campaignId: campaign.id,
                    })}?tab=negativeKeywords`}
                >
                    Campaign
                </ResourceLink>
            </span>
        )
        numberOfNegativeKeywords += 1
    }

    if (!isUnset(negativeKeywordId) && !isUnset(ad_group)) {
        adGroupNegativeKeywordDescription = (
            <span>
                <ResourceLink
                    to={`${generatePath(getPath(AD_GROUP_PAGE), {
                        campaignId: ad_group.campaign_id,
                        adGroupId: ad_group.id,
                    })}?tab=negativeKeywords`}
                >
                    Ad Group
                </ResourceLink>
            </span>
        )
        numberOfNegativeKeywords += 1
    }

    if (numberOfNegativeKeywords === 0) {
        return isASIN(record.query) ? (
            undefinedValue
        ) : (
            <Translation>{(t) => t('common:no', 'No')}</Translation>
        )
    }

    return (
        <div>
            <Translation>{(t) => t('common:yes', 'Yes')}</Translation>
            {', '} {campaignNegativeKeywordDescription}
            {numberOfNegativeKeywords === 2 && ' & '}
            {adGroupNegativeKeywordDescription}{' '}
            <Translation>{(t) => t('common:negative', 'Negative')}</Translation>
        </div>
    )
}
