import { combineChildrenReducers } from 'utilities/reducers'

import manageAlertsReducer from './manageAlerts'
import pageReducer from './page'
import triggeredAlertsReducer from './triggeredAlerts'

export default combineChildrenReducers(pageReducer, {
    triggeredAlerts: triggeredAlertsReducer,
    manageAlerts: manageAlertsReducer,
})
