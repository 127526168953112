import { combineChildrenReducers } from 'utilities/reducers'

// Reducer that handles page level data, that is, parent reducer

// Children reducers
import lineItemsReducer from './lineItems'
import pageReducer from './page'

export default combineChildrenReducers(pageReducer, {
    lineItems: lineItemsReducer,
})
