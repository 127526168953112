import { handleActions } from 'redux-actions'

import { campaignKeywordSearchTermsTableColumnsConfig } from 'configuration/tables'
import {
    DATES,
    KEYWORD_MATCH_TYPES,
    METRIC_FILTERS,
    KEYWORDS,
    SEARCH_TERM_NAME,
} from 'const/filters'
import { FILTER_SETTINGS, LOCAL_FILTERS } from 'const/reducerKeys'
import { serializeColumns } from 'helpers/tables'
import { defaultTab, getDefaultTable } from 'reducers/ui/defaults'

const initialState = {
    ...defaultTab,
    [LOCAL_FILTERS]: {
        [SEARCH_TERM_NAME]: null,
        [KEYWORDS]: [],
        [KEYWORD_MATCH_TYPES]: [],
        [METRIC_FILTERS]: [],
    },
    [FILTER_SETTINGS]: {
        anchored: [SEARCH_TERM_NAME, DATES],
        order: [KEYWORDS, KEYWORD_MATCH_TYPES, METRIC_FILTERS],
        displayState: {
            [KEYWORD_MATCH_TYPES]: true,
            [METRIC_FILTERS]: true,
            [KEYWORDS]: true,
        },
    },
    table: getDefaultTable(
        serializeColumns(campaignKeywordSearchTermsTableColumnsConfig),
        {
            showTotalRow: true,
        }
    ),
}

export default handleActions({}, initialState)
