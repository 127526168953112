import { ReactElement } from 'react'

import { AUTOMATIONS } from 'const/automations'
import { AutomationCapabilityId } from 'types'

import CapabilityIcon from '../AutomationCapabilityIcon'

interface Props {
    capabilityId: AutomationCapabilityId
}

export default ({ capabilityId }: Props): ReactElement => (
    <div className="d-flex align-items-center">
        <CapabilityIcon capabilityId={capabilityId} />
        {AUTOMATIONS[capabilityId].name}
    </div>
)
