import Cookies from 'js-cookie'

const churnzeroCookieKey = 'cz_contact'

export const registerPendoUser = (user, org) => {
    const { username, email, first_name, last_name } = user

    // Hide the resource center whenever the user clicks outside it
    // See: https://github.com/pendo-io/snippets/blob/master/resourceCenter/dismissRCWhenClickOutsideTargetElementRC/dismissRCWhenClickOutsideTargetElementRC.js
    const events = {
        events: {
            guidesLoaded: () => {
                const hideResourceCenterOffClick = (e) => {
                    const tgt = e.target
                    const path = e.composedPath()
                    let resourceCenterDescendant = false
                    for (let i = 0; i < path.length; i += 1) {
                        if (path[i].id === 'pendo-resource-center-container') {
                            resourceCenterDescendant = true
                        }
                    }
                    if (
                        window.pendo.dom('#pendo-resource-center-container')
                            .length &&
                        !resourceCenterDescendant &&
                        !window.pendo
                            .dom(tgt)
                            .closest(
                                window.pendo.BuildingBlocks.BuildingBlockResourceCenter.getResourceCenter()
                                    .steps[0].elementPathRule
                            ).length
                    ) {
                        window.pendo.BuildingBlocks.BuildingBlockResourceCenter.dismissResourceCenter()
                    }
                }

                window.pendo.attachEvent(
                    document,
                    'click',
                    hideResourceCenterOffClick
                )
            },
        },
    }

    const visitor = {
        visitor: {
            id: username,
            email,
            first_name,
            last_name,
            domain: window.location.host,
            languageBrowser: window.navigator?.language,
        },
    }

    const account = () => {
        if (!org) {
            return {}
        }

        return {
            account: {
                id: `${STAGE}-${org.id}`,
                name: org.name,
                billing_entity_id: `${STAGE}-${
                    org?.agency ? org.agency.id : org.id
                }`,
                billing_entity_name: org?.agency ? org.agency.name : org.name,
            },
        }
    }

    const pendoInfo = {
        ...events,
        ...visitor,
        ...account(),
    }

    if (window.pendo?.visitorId === username) {
        window.pendo.identify(pendoInfo)
        return
    }

    window.pendo.initialize(pendoInfo)
}

export const registerFullstoryUser = (user) => {
    const { username: id, email, first_name, last_name } = user

    window.FS?.identify(id, {
        email,
        first_name,
        last_name,
    })
}

export const stopChurnZero = () => {
    if (!window.ChurnZero) {
        return
    }
    if (Cookies.get(churnzeroCookieKey)) {
        Cookies.remove(churnzeroCookieKey)
    }
    window.ChurnZero.push(['stop'])
}

const getOnlyDomain = (domain, prefix = '.') => {
    const domainParts = domain.split('.')
    while (domainParts.length > 2) {
        domainParts.shift()
    }
    return `${prefix}${domainParts.join('.')}`
}

export const registerChurnZero = (orgSalesforceId, userGilId) => {
    if (!orgSalesforceId || !userGilId || !window.ChurnZero) {
        stopChurnZero()
        return
    }

    window.ChurnZero.push(['setContact', orgSalesforceId, userGilId])

    if (Cookies.get(churnzeroCookieKey)) {
        Cookies.remove(churnzeroCookieKey)
    }
    Cookies.set(
        churnzeroCookieKey,
        {
            salesforceId: orgSalesforceId,
            gilId: userGilId,
        },
        { domain: getOnlyDomain(window.location.host) }
    )
}
