import { Select, InputNumber } from 'antd'
import { Field } from 'formik'
import { TFunction } from 'i18next'
import map from 'lodash/map'
import pick from 'lodash/pick'

import { FormikSelect, FormikInputNumber } from 'components/formik'
import {
    DISABLED,
    ENABLED,
    WALMART_AD_ITEM_STATE_FIELD_OPTIONS,
} from 'const/resourceStates'
import {
    WALMART_AD_ITEM_BID_MIN,
    WALMART_AD_ITEM_BID_PRECISION,
    WALMART_AD_ITEM_BID_STEP,
    MANUAL_TARGETING,
} from 'const/walmart'
import { getMetricValueFormatter } from 'helpers/formatters'
import { parseCurrency } from 'helpers/inputNumber'
import { WalmartCampaign } from 'types'

import { WalmartAdGroupSelectField, WalmartAdGroupSelectFormikField } from '..'
import { ColumnConfig } from '../../CreateKeywordModal/localTypes'

const getBasicWalmartAdItemCreationColumnsConfig = (
    t: TFunction,
    campaign: WalmartCampaign
): ColumnConfig[] => {
    const columns: ColumnConfig[] = [
        {
            name: 'State',
            dataKey: 'state',
            width: '12%',
            renderField: (fieldName) => (
                <Field
                    name={fieldName}
                    component={FormikSelect}
                    placeholder={t(
                        'table:fields.walmartAdItem.state.selectionText',
                        'Select State'
                    )}
                >
                    {map(
                        pick(WALMART_AD_ITEM_STATE_FIELD_OPTIONS, [
                            ENABLED,
                            DISABLED,
                        ]),
                        (label, value) => (
                            <Select.Option key={value} value={value}>
                                {label}
                            </Select.Option>
                        )
                    )}
                </Field>
            ),
            renderBulkField: (initialValue, handleChange) => (
                <Select
                    value={initialValue}
                    placeholder={t(
                        'table:fields.walmartAdItem.state.selectionText',
                        'Select State'
                    )}
                    onChange={handleChange}
                >
                    {map(
                        pick(WALMART_AD_ITEM_STATE_FIELD_OPTIONS, [
                            ENABLED,
                            DISABLED,
                        ]),
                        (label, value) => (
                            <Select.Option key={value} value={value}>
                                {label}
                            </Select.Option>
                        )
                    )}
                </Select>
            ),
        },
    ]

    const bidColumn: ColumnConfig = {
        name: 'Bid (CPC)',
        dataKey: 'bid',
        width: '11%',
        renderField: (fieldName) => (
            <Field
                name={fieldName}
                component={FormikInputNumber}
                formatter={getMetricValueFormatter('currencyAmount')}
                parser={parseCurrency}
                min={WALMART_AD_ITEM_BID_MIN}
                step={WALMART_AD_ITEM_BID_STEP}
                precision={WALMART_AD_ITEM_BID_PRECISION}
            />
        ),
        renderBulkField: (initialValue, handleChange) => (
            <InputNumber
                onChange={handleChange}
                value={initialValue}
                formatter={getMetricValueFormatter('currencyAmount')}
                parser={parseCurrency}
                min={WALMART_AD_ITEM_BID_MIN}
                step={WALMART_AD_ITEM_BID_STEP}
                precision={WALMART_AD_ITEM_BID_PRECISION}
            />
        ),
    }

    return campaign.targeting_type === MANUAL_TARGETING
        ? columns
        : [bidColumn, ...columns]
}

export const getWalmartAdGroupAdItemCreationColumnsConfig = (
    t: TFunction,
    campaign: WalmartCampaign
): ColumnConfig[] => getBasicWalmartAdItemCreationColumnsConfig(t, campaign)

export const getWalmartCampaignAdItemCreationColumnsConfig = (
    t: TFunction,
    campaign: WalmartCampaign
): ColumnConfig[] => {
    const columns = getBasicWalmartAdItemCreationColumnsConfig(t, campaign)

    return [
        {
            name: 'Ad Group',
            dataKey: 'adGroupId',
            width: '20%',

            renderField: (fieldName) => (
                <WalmartAdGroupSelectFormikField
                    name={fieldName}
                    campaign={campaign}
                />
            ),
            renderBulkField: (initialValue, handleChange) => {
                return (
                    <WalmartAdGroupSelectField
                        selectedAdGroup={initialValue}
                        campaign={campaign}
                        onChange={handleChange}
                    />
                )
            },
        },
        ...columns,
    ]
}
