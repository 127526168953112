import { ReactElement, ReactNode } from 'react'

import classNames from 'classnames'

import styles from './styles.scss'

interface Props {
    errorText?: ReactNode
    style?: 'error' | 'warning' | 'info'
}

const BulkEditError = ({
    errorText = null,
    style = 'error',
}: Props): ReactElement => (
    <div className={classNames('pt-1', styles[style])}>{errorText}</div>
)

export default BulkEditError
