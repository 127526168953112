import { handleActions } from 'redux-actions'

import { LOCAL_FILTERS, FILTER_SETTINGS } from 'const/reducerKeys'
import { defaultResourcePage } from 'reducers/ui/defaults'

const defaultState = {
    ...defaultResourcePage,
    [LOCAL_FILTERS]: {},
    [FILTER_SETTINGS]: {
        anchored: [],
        order: [],
        displayState: {},
    },
}

export default handleActions({}, defaultState)
