import get from 'lodash/get'

import {
    reportDateDay,
    reportDateMonth,
    reportDateMonthName,
    reportDateQuarter,
    reportDateQuarterNumber,
    reportDateWeek,
    reportDateWeekDay,
    reportDateYear,
} from 'configuration/fieldCreators/date'
import { count } from 'configuration/fieldCreators/metrics/asinUsage'
import { organizationId } from 'configuration/fieldCreators/orgFacts'
import { segmentId } from 'configuration/fieldCreators/segments'
import { DATES, SEGMENTS } from 'const/filters'
import { DATE_GROUP_BY_FORMATTERS } from 'helpers/charts'
import { ChartFormatters } from 'types'

import { makeMapping } from './helpers'

const ASIN_USAGE_METRICS_COLUMNS = [count({ isVisible: true })]

export const ASIN_USAGE_METRICS_CONFIG = makeMapping(ASIN_USAGE_METRICS_COLUMNS)

export const SPECIAL_ASIN_USAGE_GROUP_BY_COLUMNS = [
    segmentId({ dataIndex: ['segment', 'id'] }),
    organizationId({
        dataIndex: ['organization_id'],
    }),
    // This datasource deviates from the norm by using aliases for some of these columns
    // Take care changing these without validating changes against existing config selections
    reportDateDay({ dataIndex: ['day'] }),
    reportDateWeek({ dataIndex: ['week'] }),
    reportDateMonth({ dataIndex: ['month'] }),
    reportDateYear({ dataIndex: ['year'] }),
    reportDateWeekDay({ dataIndex: ['week_day'] }),
    reportDateMonthName({
        dataIndex: ['month_name'],
    }),
    reportDateQuarter({ dataIndex: ['quarter'] }),
    reportDateQuarterNumber({
        dataIndex: ['quarter_number'],
    }),
]

export const ASIN_USAGE_GROUP_BY_CONFIG: ChartFormatters = {
    segment_id: {
        displayName: (record) => get(record, ['segment', 'name'], 'No Segment'),
    },
    organization_id: {
        displayName: (record) =>
            record.organization?.name || 'Unknown Organization',
    },
    ...DATE_GROUP_BY_FORMATTERS,
}

export const ASIN_USAGE_FACTS_FILTER = [DATES, SEGMENTS]
