import { ReactElement } from 'react'

import { DeleteOutlined } from '@ant-design/icons'
import classNames from 'classnames'
import { ErrorMessage } from 'formik'

import { IconButton } from 'components/Buttons'

import { ColumnConfig, BaseKeyword } from './localTypes'
import styles from './styles.scss'

interface Props<KeywordField> {
    keyword: KeywordField
    keywordIndex: number
    remove: (index: number) => void
    columnsConfig: ColumnConfig[]
}

function CreateKeywordFormRow<KeywordField extends BaseKeyword>({
    keyword,
    keywordIndex,
    remove,
    columnsConfig,
}: Props<KeywordField>): ReactElement {
    return (
        <div
            className={`d-flex align-items-start mt-2 pb-2 ${styles['keyword-row']}`}
        >
            <div
                style={{ flex: 1 }}
                className={`mr-1 flex-1 ${styles['text-field']}`}
            >
                {keyword.text}
            </div>
            {columnsConfig.map(({ dataKey, width, renderField }) => {
                const fieldName = `keywords.${keywordIndex}.${dataKey}`
                return (
                    <div
                        className={classNames(
                            'd-flex flex-column align-items-center mr-1',
                            styles.field
                        )}
                        style={{
                            width,
                        }}
                        key={dataKey}
                    >
                        {renderField(fieldName)}
                        <ErrorMessage
                            name={fieldName}
                            component="div"
                            className="fg-control-text is-error"
                        />
                    </div>
                )
            })}

            <div
                className={classNames(
                    'd-flex flex-column align-items-center mr-1',
                    styles.field,
                    styles['remove-column']
                )}
            >
                <IconButton
                    icon={<DeleteOutlined />}
                    danger
                    type="link"
                    onClick={() => {
                        remove(keywordIndex)
                    }}
                />
            </div>
        </div>
    )
}

export default CreateKeywordFormRow
