import { Link, generatePath } from 'react-router-dom'

import { atAGlanceKeywordRenderer } from 'components/FieldRenderers/Renderers/atAGlance'
import { keywordBidRenderer } from 'components/FieldRenderers/Renderers/keyword'
import { adGroupName } from 'configuration/fieldCreators/adGroup'
import {
    brandCountryCode,
    brandCurrencyCode,
    brandId,
    brandRegion,
    brandTimezone,
} from 'configuration/fieldCreators/brand'
import {
    campaignBudget,
    campaignEndDate,
    campaignName,
    campaignStartDate,
    campaignState,
    campaignTargetingType,
    campaignType,
} from 'configuration/fieldCreators/campaign'
import {
    archiveKeyword,
    keywordActiveBid,
    keywordBid,
    keywordCreatedDate,
    keywordId,
    keywordMatchType,
    keywordState,
    keywordSuggestedBid,
} from 'configuration/fieldCreators/keyword'
import { UNDEFINED_VALUE } from 'const/formatting'
import { CAMPAIGN_PAGE } from 'const/pages'
import { getPath } from 'helpers/pages'
import { adGroupUrl } from 'helpers/urls'
import { isUnset } from 'helpers/utilities'
import {
    ProfileGroupBy,
    AdGroupGroupBy,
    Field,
    CampaignGroupBy,
    KeywordFact,
} from 'types'

import { AMS_METRICS_COLUMNS_PAGES } from '../dataSources/ams'

export type KeywordFactRecord = KeywordFact<
    ProfileGroupBy & AdGroupGroupBy & CampaignGroupBy
>

export const keywordsTableColumnsConfig: Field<KeywordFactRecord>[] = [
    keywordId({
        fixed: 'left',
        renderOptions: {
            render: (props) => atAGlanceKeywordRenderer(props),
        },
    }),
    keywordMatchType(),
    keywordState(),
    keywordSuggestedBid(),
    keywordBid({
        renderOptions: {
            render: (cellRenderProps) =>
                keywordBidRenderer({
                    cellRenderProps,
                    dataIndex: ['keyword', 'base_bid'],
                }),
        },
    }),
    keywordActiveBid(),
    keywordCreatedDate(),
    adGroupName({
        renderOptions: {
            render: ({ value, record }) => {
                if (isUnset(value)) {
                    return UNDEFINED_VALUE
                }
                const adGroupId = record.ad_group_id
                const campaignId = record.keyword.campaign.id
                const link = adGroupUrl(campaignId, adGroupId)

                return <Link to={link}>{value}</Link>
            },
        },
    }),
    ...(AMS_METRICS_COLUMNS_PAGES as Field<KeywordFactRecord>[]),
    archiveKeyword({ fixed: 'right' }),
]

export const keywordsSummaryTableColumnsConfig: Field<KeywordFactRecord>[] = [
    keywordId({
        fixed: 'left',
        renderOptions: {
            render: atAGlanceKeywordRenderer,
        },
    }),
    keywordMatchType(),
    keywordState(),
    keywordSuggestedBid({ isVisible: false }),
    keywordBid({
        renderOptions: {
            render: (cellRenderProps) =>
                keywordBidRenderer({
                    cellRenderProps,
                    dataIndex: ['keyword', 'base_bid'],
                }),
        },
    }),
    keywordActiveBid(),
    keywordCreatedDate(),
    adGroupName({
        renderOptions: {
            render: ({ record }) => {
                const adGroupId = record.keyword.ad_group_id
                const campaignId = record.keyword.campaign.id
                const link = adGroupUrl(campaignId, adGroupId)

                return <Link to={link}>{record.keyword.ad_group.name}</Link>
            },
        },
    }),
    brandId(),
    brandCountryCode(),
    brandCurrencyCode({ isVisible: false }),
    brandRegion(),
    brandTimezone({ isVisible: false }),
    campaignName({
        dataIndex: ['keyword', 'campaign', 'name'],
        renderOptions: {
            render: ({ value, record }) => (
                <Link
                    to={generatePath(getPath(CAMPAIGN_PAGE), {
                        campaignId: record.keyword.campaign.id,
                    })}
                >
                    {value}
                </Link>
            ),
        },
    }),
    campaignType({
        dataIndex: ['keyword', 'campaign', 'campaign_type'],
    }),
    campaignTargetingType({
        dataIndex: ['keyword', 'campaign', 'targeting_type'],
    }),
    campaignState({
        dataIndex: ['keyword', 'campaign', 'state'],
    }),
    campaignBudget({
        isVisible: false,
        dataIndex: ['keyword', 'campaign', 'budget'],
    }),
    campaignStartDate({
        isVisible: false,
        dataIndex: ['keyword', 'campaign', 'start_date'],
    }),
    campaignEndDate({
        isVisible: false,
        dataIndex: ['keyword', 'campaign', 'end_date'],
    }),
    ...(AMS_METRICS_COLUMNS_PAGES as Field<KeywordFactRecord>[]),
    archiveKeyword({ fixed: 'right' }),
]
