import { Select } from 'antd'

import { ConditionalWrapper } from 'components/ConditionalWrapper'
import { ToolTip } from 'components/ToolTip'

import { FormikWithCustomHandlersPropTypes } from '../propTypes'
import withCustomHandlers from '../withCustomHandlers'

const FormikSelect = ({
    field: { value },
    form,
    handleChange,
    handleBlur,
    handleClick,
    tooltipPlacement = 'bottom',
    tooltipTitle,
    ...props
}) => {
    return (
        <ConditionalWrapper
            condition={Boolean(tooltipTitle)}
            wrapper={(children) => (
                <ToolTip
                    title={tooltipTitle}
                    arrow={{ pointAtCenter: true }}
                    placement={tooltipPlacement}
                >
                    {children}
                </ToolTip>
            )}
        >
            <Select
                value={value}
                onChange={handleChange}
                onBlur={handleBlur}
                {...props}
            />
        </ConditionalWrapper>
    )
}

FormikSelect.propTypes = FormikWithCustomHandlersPropTypes

export default withCustomHandlers(FormikSelect)
