import {
    ReactElement,
    ComponentClass,
    JSXElementConstructor,
    LazyExoticComponent,
} from 'react'

import startsWith from 'lodash/startsWith'
import queryString from 'query-string'
import {
    Route,
    Redirect,
    useLocation,
    RouteProps,
    RouteComponentProps,
} from 'react-router-dom'

import {
    changeOrganizationGroupRequest,
    changeOrganizationRequest,
} from 'actions/auth'
import { ErrorBoundary } from 'components/ErrorBoundary'
import { ORGANIZATION_PAGE } from 'const/pages'
import { PageContextProvider } from 'context/PageContext'
import { getPath } from 'helpers/pages'
import {
    useAction,
    useCurrentOrganization,
    useCurrentOrganizationGroup,
} from 'hooks'
import { OrganizationLockedPage } from 'views/OrganizationLockedPage'

interface Props<C = any> extends RouteProps {
    component:
        | LazyExoticComponent<ComponentClass<C & RouteComponentProps<any>>>
        | JSXElementConstructor<C & RouteComponentProps<any>>
        | ComponentClass<C & RouteComponentProps<any>>
    pageName: string
    restricted?: boolean
}

function PageRoute<C>({
    component: PageComponent,
    pageName,
    restricted = false,
    ...rest
}: Props<C>): ReactElement {
    const location = useLocation()
    const organization = useCurrentOrganization()
    const organizationGroup = useCurrentOrganizationGroup()
    const changeOrganization = useAction(changeOrganizationRequest)
    const changeOrganizationGroup = useAction(changeOrganizationGroupRequest)
    const params: {
        orgId?: string
        orgGroupId?: string
        redirect?: string
        filters?: any
        resources?: any
        tab?: string
    } = queryString.parse(location.search)

    if (
        params.orgId ||
        params.orgGroupId ||
        params.filters ||
        params.redirect ||
        params.resources
    ) {
        try {
            if (params.filters) {
                params.filters = JSON.parse(params.filters)
            } else {
                params.filters = {}
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error('Error parsing filters', e)
            params.filters = {}
        }
        try {
            if (params.resources) {
                params.resources = JSON.parse(params.resources)
            } else {
                params.resources = {}
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error('Error parsing resources', e)
            params.resources = {}
        }
        if (params.orgGroupId || organizationGroup) {
            changeOrganizationGroup({
                ...params,
                organizationGroupId: params.orgGroupId ?? organizationGroup?.id,
                organizationId: params.orgId ?? organization?.id,
            })
        } else {
            changeOrganization({
                ...params,
                organizationId: params.orgId ?? organization?.id,
            })
        }
    }

    if (
        organization &&
        organization.locked &&
        !startsWith(location.pathname, getPath(ORGANIZATION_PAGE))
    ) {
        return <OrganizationLockedPage organization={organization} />
    }
    return (
        <Route
            {...rest}
            path={getPath(pageName)}
            render={(props) => (
                <ErrorBoundary key={location.pathname}>
                    <PageContextProvider pageName={pageName}>
                        {restricted ? (
                            <Redirect to="/" />
                        ) : (
                            <PageComponent
                                {...(props as any)}
                                pageName={pageName}
                            />
                        )}
                    </PageContextProvider>
                </ErrorBoundary>
            )}
        />
    )
}

export default PageRoute
