import { startCase } from 'lodash'
import first from 'lodash/first'
import get from 'lodash/get'
import keys from 'lodash/keys'
import lowerCase from 'lodash/lowerCase'

import { UNDEFINED_VALUE } from 'const/formatting'
import { ChangelogRecord } from 'types'

import { formatCurrency } from './formatting'

const formatCurrencyChange = (value: any, changelog: ChangelogRecord): any =>
    formatCurrency(value, {
        decimal: true,
        currencyCode: changelog.currency_code,
    })

const formatConfig = (value: string, changelog: ChangelogRecord): string => {
    const formatFilter = (filter: string, filterValue: any): any => {
        let formattedValue = JSON.stringify(filterValue)
        switch (filter) {
            case 'marketplaces':
                formattedValue = filterValue.length ? filterValue[0] : ''
                break
            case 'price': {
                const minPrice = filterValue.min ?? '0'
                if (filterValue.max) {
                    formattedValue = `${minPrice} - ${filterValue.max}`
                } else {
                    formattedValue = `> ${minPrice}`
                }
                break
            }
            case 'asins':
                if (filterValue.exclude) {
                    formattedValue = 'exclude '
                    if (filterValue.exclude.length < 10) {
                        formattedValue += `[${filterValue.exclude.join(', ')}]`
                    } else {
                        formattedValue += `${filterValue.exclude.length} asins`
                    }
                }
                if (filterValue.include) {
                    formattedValue += ' include '
                    if (filterValue.include.length < 10) {
                        formattedValue += `[${filterValue.include.join(', ')}]`
                    } else {
                        formattedValue += `${filterValue.include.length} asins`
                    }
                }
                break
            case 'brands':
            case 'categories':
                formattedValue = Object.values(filterValue)
                    .map((brand: any) => brand.label)
                    .join(', ')
                break
            default:
                break
        }
        return `${startCase(filter)}: ${formattedValue}`
    }
    if (changelog?.object_type === 'segment' && value) {
        try {
            const newConfig = JSON.parse(value.replace(/'/g, '"'))
            let filters = ''
            Object.keys(newConfig.filters).forEach((element: any) => {
                filters = `${filters} ${formatFilter(
                    element,
                    newConfig.filters[element]
                )}; `
            })
            return filters
        } catch (e) {
            console.error(e)
            // DO NOTHING AND SHOW THE NEW VALUE AS IS.
        }
    }
    return value
}

const formatToggleInt = (value: any): string => {
    if (value === 1) {
        return 'On'
    }
    return 'Off'
}

export const CHANGE_TERM_FORMATTERS = {
    bid: formatCurrencyChange,
    budget: formatCurrencyChange,
    base_budget: formatCurrencyChange,
    base_bid: formatCurrencyChange,
    config: formatConfig,
    enabled_int: formatToggleInt,
}

/**
 * Format change term
 *
 * @param {object} changelog - Changelog object
 * @param {string} changeType - Change type
 */
const formatChangeTerm = (
    changelog: ChangelogRecord,
    fieldChanged: string
): string => {
    const { changes } = changelog
    const { new: newValue } = changes[fieldChanged] ?? {}

    const formatter: (value: any, changelog: ChangelogRecord) => any = get(
        CHANGE_TERM_FORMATTERS,
        fieldChanged,
        (value: any): any => value
    )

    const changeTypeFormatted = lowerCase(fieldChanged)
    const newValueFormatted = formatter(newValue, changelog)

    return `${changeTypeFormatted} to ${newValueFormatted}`
}

export const formatChangeDescription = (
    changelog: ChangelogRecord
): string | null => {
    const {
        change_type: historyType,
        object_type: objectType,
        changes,
    } = changelog
    const resourceTypeLabel = lowerCase(objectType)

    if (historyType === '+') {
        return `Created new ${resourceTypeLabel}`
    }

    // Detect change type
    const firstFieldChanged = first(keys(changes))
    if (!firstFieldChanged) {
        if (historyType === '-') {
            return `Deleted ${resourceTypeLabel}`
        }
        if (historyType === '~') {
            return `Updated ${resourceTypeLabel}`
        }
        return UNDEFINED_VALUE
    }

    const changeTerm = formatChangeTerm(changelog, firstFieldChanged)

    return `Set ${resourceTypeLabel} ${changeTerm}`
}
