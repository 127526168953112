import { invalidateCache } from 'actions/cache'
import { CEREBRO_ACCESS_TOKEN } from 'const/localStorage'
import { shouldInvalidateCache } from 'helpers/cache'
import { createCerebroAxiosInstance } from 'helpers/cerebroApi'
import { userHasCustomerServicePermissions } from 'helpers/featurePermissions'
import reactotron from 'reactotron'
import { selectDomainValue, selectUserFeaturePermissions } from 'selectors/auth'
import storeRegistry from 'utilities/storeRegistry'

export const axiosInstanceOrganizations = createCerebroAxiosInstance()
axiosInstanceOrganizations.interceptors.request.use((config) => {
    const accessToken = localStorage.getItem(CEREBRO_ACCESS_TOKEN)

    const state = storeRegistry.getState()

    const isImpersonating = selectDomainValue(state, ['userIsImpersonating'])
    const isCustomerService = userHasCustomerServicePermissions(
        selectUserFeaturePermissions(state)
    )

    const organizationId = selectDomainValue(state, ['organizationId'])
    const organizationGroupId = selectDomainValue(state, [
        'organizationGroupId',
    ])

    if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`
    }

    if (organizationGroupId && isImpersonating && isCustomerService) {
        config.headers['x-dsi-org-group-id'] = organizationGroupId
    } else if (organizationId) {
        config.headers['x-dsi-org-id'] = organizationId
    }

    if (shouldInvalidateCache(config)) {
        storeRegistry.dispatch(invalidateCache())
    }

    reactotron.display({
        name: 'API_REQUEST',
        preview: `${config.url} ${JSON.stringify({
            isImpersonating,
            isCustomerService,
            organizationId,
            organizationGroupId,
        })}`,
        value: config,
    })

    return config
})
