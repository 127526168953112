import { memo, ReactElement } from 'react'

import isEqual from 'lodash/isEqual'
import { useTranslation } from 'react-i18next'

import { userHasOrgGroupAdminPermissions } from 'helpers/featurePermissions'
import { useUserHasPermissions } from 'hooks'
import { deleteOrganizationGroup } from 'services/cerebroApi/orgScope/resourceApi'
import { OrganizationGroup } from 'types'

import { DeleteField } from '../SharedFields/DeleteField'

interface Props<RecordType> {
    readOnly: boolean
    record: RecordType
    reloadData: () => void
}

function DeleteOrganizationGroupField<RecordType extends OrganizationGroup>({
    readOnly = false,
    record,
    reloadData,
}: Props<RecordType>): ReactElement {
    const hasPermission = useUserHasPermissions(userHasOrgGroupAdminPermissions)
    const { t } = useTranslation(['common', 'table'])
    return (
        <DeleteField
            disabled={!hasPermission || readOnly}
            okText={t('common:delete', 'Delete')}
            title={t(
                'table:fields.deleteOrganizationGroup.confirmTxt',
                'Are you sure you want to delete this Group?'
            )}
            updateRequestApi={deleteOrganizationGroup}
            updateRequestCallback={reloadData}
            updateRequestData={record.id}
            updateRequestSuccessMesg={t(
                'table:fields.deleteOrganizationGroup.successMsg',
                'Group successfully deleted'
            )}
        />
    )
}

export default memo(
    DeleteOrganizationGroupField,
    isEqual
) as typeof DeleteOrganizationGroupField
