import { ReactElement } from 'react'

import { WarningFilled } from '@ant-design/icons'
import { Alert } from 'antd'

import { setUserIsImpersonating } from 'actions/auth'
import { ToolTip } from 'components/ToolTip'
import { userHasCustomerServicePermissions } from 'helpers/featurePermissions'
import { useAction, useAuthDomainValue, useUserHasPermissions } from 'hooks'

import styles from './styles.scss'

function ImpersonationWarningBanner(): ReactElement | null {
    const isSteveMode = useUserHasPermissions(userHasCustomerServicePermissions)
    const isImpersonating = useAuthDomainValue('userIsImpersonating')
    const setIsImpersonating = useAction<boolean>(setUserIsImpersonating)

    if (!isSteveMode || !isImpersonating) {
        return null
    }

    return (
        <Alert
            type="error"
            banner
            closable
            showIcon={false}
            closeIcon={
                <ToolTip title="Exit Impersonation Mode" placement="left">
                    ✕
                </ToolTip>
            }
            message={
                <>
                    <WarningFilled />
                    <span className={styles['impersonation-banner-text']}>
                        You are in Impersonation Mode
                    </span>
                </>
            }
            className={styles['impersonation-mode-banner']}
            onClose={() => setIsImpersonating(false)}
        />
    )
}

export default ImpersonationWarningBanner
