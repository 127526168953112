import { placementMultiplierRenderer } from 'components/FieldRenderers/Renderers/placement'
import { placementText, placementMultiplier } from 'configuration/fieldCreators'
import { Field, CampaignPlacementFact } from 'types'

import { AMS_METRICS_COLUMNS_PAGES } from '../dataSources/ams'

export const placementsTableColumnsConfig: Field<CampaignPlacementFact>[] = [
    placementText({ fixed: 'left', sorter: true }),
    placementMultiplier({
        renderOptions: {
            render: (cellRenderProps) =>
                placementMultiplierRenderer({
                    cellRenderProps,
                }),
        },
    }),
    ...(AMS_METRICS_COLUMNS_PAGES as Field<CampaignPlacementFact>[]),
]
