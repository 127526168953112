import produce from 'immer'
import cloneDeep from 'lodash/cloneDeep'
import { handleActions } from 'redux-actions'

import {
    FetchOrganizationGroupBrandsOptionsAction,
    FetchOrganizationGroupDspAdvertisersOptionsAction,
    FetchOrganizationGroupIntegrationOptionsAction,
    FetchOrganizationGroupPendingInvitationAction,
    fetchOrganizationGroupBrandsSuccess,
    fetchOrganizationGroupDspAdvertiserOptionsSuccess,
    fetchOrganizationGroupIntegrationsSuccess,
    fetchOrganizationGroupPendingInvitationsSuccess,
} from 'actions/ui/organizationGroupPage'
import { defaultPage } from 'reducers/ui/defaults'

const defaultState = {
    ...defaultPage,

    brands: [],
    integrations: [],
    dspAdvertiserOptions: [],
    pendingInvitations: [],
}

type OrganizationGroupPageState = typeof defaultState

export default handleActions(
    {
        // data for form inputs
        [fetchOrganizationGroupBrandsSuccess.toString()](
            state: OrganizationGroupPageState,
            action: FetchOrganizationGroupBrandsOptionsAction
        ) {
            return produce(state, (draft) => {
                draft.brands = action.payload.results
            })
        },
        [fetchOrganizationGroupIntegrationsSuccess.toString()](
            state: OrganizationGroupPageState,
            action: FetchOrganizationGroupIntegrationOptionsAction
        ) {
            return produce(state, (draft) => {
                draft.integrations = action.payload.results
            })
        },
        [fetchOrganizationGroupDspAdvertiserOptionsSuccess.toString()](
            state: OrganizationGroupPageState,
            action: FetchOrganizationGroupDspAdvertisersOptionsAction
        ) {
            return produce(state, (draft) => {
                draft.dspAdvertiserOptions = action.payload.results
            })
        },
        [fetchOrganizationGroupPendingInvitationsSuccess.toString()](
            state: OrganizationGroupPageState,
            action: FetchOrganizationGroupPendingInvitationAction
        ) {
            return produce(state, (draft) => {
                draft.pendingInvitations = action.payload.results
            })
        },
    },
    cloneDeep(defaultState)
)
