import { ReactNode, ReactElement, CSSProperties } from 'react'

import classNames from 'classnames'
import { Draggable, DraggingStyle, NotDraggingStyle } from 'react-beautiful-dnd'

import styles from './styles.scss'

const draggingStyles = {
    boxShadow: '0 3px 10px rgba(0,0,0,.12)',
    border: '3px solid #fff',
    transition: 'all .15s',
}

const getItemStyle = (
    isDragging: boolean,
    draggableStyle?: DraggingStyle | NotDraggingStyle
): CSSProperties => ({
    ...(isDragging ? draggingStyles : {}),
    ...draggableStyle,
})

interface Props {
    children: ReactNode
    id: string
    index: number
    className?: string | undefined
    isDragDisabled?: boolean
}

const DraggableListItem = ({
    children,
    id,
    index,
    className = undefined,
    isDragDisabled = false,
}: Props): ReactElement => (
    <Draggable draggableId={id} index={index} isDragDisabled={isDragDisabled}>
        {(provided, snapshot) => (
            <div
                className={classNames(styles.item, className)}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={getItemStyle(
                    snapshot.isDragging,
                    provided.draggableProps.style
                )}
            >
                {children}
            </div>
        )}
    </Draggable>
)

export default DraggableListItem
