import { ReactNode, ReactElement } from 'react'

import { Droppable } from 'react-beautiful-dnd'

interface Props {
    droppableId: string
    children: ReactNode
}

const DroppableList = ({ children, droppableId }: Props): ReactElement => (
    <Droppable droppableId={droppableId}>
        {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
                {children}
                {provided.placeholder}
            </div>
        )}
    </Droppable>
)

export default DroppableList
