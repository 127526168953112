import isNull from 'lodash/isNull'

class StoreRegistry {
    constructor() {
        this._store = null
    }

    registerStore(store) {
        this._store = store
    }

    getState() {
        return isNull(this._store) ? null : this._store.getState()
    }

    dispatch(action) {
        return isNull(this._store) ? null : this._store.dispatch(action)
    }
}

export default new StoreRegistry()
