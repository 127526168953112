import { memo, ReactElement } from 'react'

import isEqual from 'lodash/isEqual'

import { userHasManageLabelsPermissions } from 'helpers/featurePermissions'
import { useUserHasPermissions } from 'hooks'
import { updateLabel } from 'services/cerebroApi/orgScope/resourceApi'
import { Label } from 'types'

import { TextInputField } from '../SharedFields'

interface Props<RecordType> {
    dataIndex: string[]
    readOnly: boolean
    record: RecordType
}

function LabelDescriptionField<RecordType extends Label>({
    dataIndex = ['description'],
    readOnly = false,
    record,
}: Props<RecordType>): ReactElement {
    const hasPermission = useUserHasPermissions(userHasManageLabelsPermissions)

    return (
        <TextInputField
            disabled={!hasPermission || readOnly}
            fieldName="description"
            fieldPath={dataIndex}
            readOnly={readOnly}
            record={record}
            updateRequestApi={updateLabel}
            updateRequestData={record.id}
            updateRequestSuccessMesg="Successfully updated label description"
        />
    )
}

export default memo(
    LabelDescriptionField,
    isEqual
) as typeof LabelDescriptionField
