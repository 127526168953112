import cloneDeep from 'lodash/cloneDeep'
import set from 'lodash/fp/set'
import get from 'lodash/get'
import { Action, combineActions, handleActions } from 'redux-actions'

import {
    changeOrganizationGroupRequest,
    changeOrganizationRequest,
    signOutSuccess,
} from 'actions/auth'
import {
    AsyncReducerHydratedPayload,
    asyncReducerHydrated,
} from 'actions/ui/app'
import { AGGREGATION, DATES } from 'const/filters'
import { APP } from 'const/pages'
import { FILTERS, FILTER_SETTINGS } from 'const/reducerKeys'
import { deepPick } from 'utilities/reducers'

import bidReducer from './bid'
import dashboardReducer from './dashboard'
import dataManagement from './dataManagement'
import filterReducer from './filter'
import labelReducer from './label'
import pageReducer from './page'
import resourceReducer from './resource'
import tabReducer from './tab'
import tableReducer from './table'
import {
    getDefaultState,
    getPristineUiStateClone,
    updatePristineUiStateClone,
} from '../ui'

const combinedActions = combineActions(
    changeOrganizationRequest,
    changeOrganizationGroupRequest
)

const resetFilters = (targetFilters: any, sourceFilters: any): void => {
    if (targetFilters && sourceFilters) {
        Object.keys(targetFilters).forEach((filterKey) => {
            if (filterKey in sourceFilters) {
                targetFilters[filterKey] = sourceFilters[filterKey]
            } else if (
                filterKey !== DATES &&
                filterKey !== AGGREGATION &&
                !!targetFilters[filterKey]
            ) {
                // we need dates, so it's the only one we don't reset
                if (Array.isArray(targetFilters[filterKey])) {
                    targetFilters[filterKey] = []
                } else if (typeof targetFilters[filterKey] === 'object') {
                    targetFilters[filterKey] = {}
                } else if (typeof targetFilters[filterKey] === 'string') {
                    targetFilters[filterKey] = ''
                } else {
                    targetFilters[filterKey] = undefined
                }
            }
        })
    }
}

const makeDesiredFiltersVisible = (
    targetFilterSettings: any,
    sourceFilters: any
): void => {
    if (
        targetFilterSettings &&
        targetFilterSettings.displayState &&
        sourceFilters
    ) {
        Object.keys(sourceFilters).forEach((filterKey) => {
            if (filterKey in targetFilterSettings.displayState) {
                targetFilterSettings.displayState[filterKey] = true
            }
        })
    }
}

export default handleActions(
    {
        // reset all state except settings that are persisted across sessions
        [signOutSuccess.toString()](state) {
            return deepPick(
                state,
                ['tableSettings', FILTERS, FILTER_SETTINGS],
                cloneDeep(getDefaultState())
            )
        },
        // on org change, don't reset filters since we could be redirecting to the correct org
        [combinedActions.toString()](
            state,
            action: ReturnType<typeof changeOrganizationRequest>
        ) {
            const { filters, redirect, tab } = action.payload

            const copy: any = JSON.parse(
                JSON.stringify(
                    deepPick(
                        state,
                        ['tableSettings', FILTERS, FILTER_SETTINGS, 'hydrated'],
                        cloneDeep(getDefaultState())
                    )
                )
            )
            if (APP in copy) {
                resetFilters(copy[APP].filters, filters)
                makeDesiredFiltersVisible(copy[APP].filtersSettings, filters)
            }
            if (redirect && redirect in copy) {
                resetFilters(copy[redirect]?.localFilters, filters)
                resetFilters(copy[redirect]?.filters, filters)
                makeDesiredFiltersVisible(
                    copy[redirect].filtersSettings,
                    filters
                )
                if (tab && tab in copy[redirect]) {
                    resetFilters(copy[redirect][tab]?.filters, filters)
                    resetFilters(copy[redirect][tab]?.localFilters, filters)
                    makeDesiredFiltersVisible(
                        copy[redirect][tab].filtersSettings,
                        filters
                    )
                }
                if ('tabs' in copy[redirect] && tab in copy[redirect].tabs) {
                    resetFilters(copy[redirect].tabs[tab]?.filters, filters)
                    resetFilters(
                        copy[redirect].tabs[tab]?.localFilters,
                        filters
                    )
                    makeDesiredFiltersVisible(
                        copy[redirect].tabs[tab].filtersSettings,
                        filters
                    )
                }
            }

            return copy
        },

        // when async reducers have been persisted and added

        [asyncReducerHydrated.toString()](
            state,
            action: Action<AsyncReducerHydratedPayload>
        ) {
            const { key } = action.payload

            // update clean UI state with the new reducer state
            // so we can use for resetting
            const defaultUiStateClone = getPristineUiStateClone()
            const pageDefaultState = get(defaultUiStateClone, [key])
            if (!pageDefaultState) {
                updatePristineUiStateClone(state, [key])
            }

            return set([key, 'hydrated'], true, state)
        },

        ...pageReducer,
        ...filterReducer,
        ...tabReducer,
        ...dashboardReducer,
        ...resourceReducer,
        ...dataManagement,
        ...tableReducer,
        ...bidReducer,
        ...labelReducer,
    },
    {}
)
