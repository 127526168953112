import { useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import { CustomerModuleKey } from 'const/customerModules'
import { DataSourceIntegrationKey } from 'const/dataSourceIntegrations'
import { WidgetDataSourceKey } from 'const/dataSources'

interface WidgetDataSourceInfo {
    key: WidgetDataSourceKey
    name: string
    moduleKey: CustomerModuleKey
    integrationKey?: DataSourceIntegrationKey
}

export function useWidgetDataSourceInfo(): Record<
    WidgetDataSourceKey,
    WidgetDataSourceInfo
> {
    const { t } = useTranslation()

    const widgetDataSourceInfo: Record<
        WidgetDataSourceKey,
        WidgetDataSourceInfo
    > = useMemo(
        () => ({
            '': {
                key: '',
                name: t('common:data.sources.text', 'Text'),
                moduleKey: 'retail_insights',
            },
            org_facts: {
                key: 'org_facts',
                name: t(
                    'common:data.sources.org_facts',
                    'Total Organization Metrics'
                ),
                moduleKey: 'retail_insights',
            },
            campaign_facts: {
                key: 'campaign_facts',
                name: t(
                    'common:data.sources.campaign_facts',
                    'Advertising Campaigns'
                ),
                moduleKey: 'advertising_intelligence',
            },
            keyword_facts: {
                key: 'keyword_facts',
                name: t(
                    'common:data.sources.keyword_facts',
                    'Advertising Keywords'
                ),
                moduleKey: 'advertising_intelligence',
            },
            product_ad_facts: {
                key: 'product_ad_facts',
                name: t(
                    'common:data.sources.product_ad_facts',
                    'Advertising Product Ads'
                ),
                moduleKey: 'advertising_intelligence',
            },
            sb_purchased_product_facts: {
                key: 'sb_purchased_product_facts',
                name: t(
                    'common:data.sources.sb_purchased_product_facts',
                    'Sponsored Brands Purchased Products'
                ),
                moduleKey: 'advertising_intelligence',
            },
            target_facts: {
                key: 'target_facts',
                name: t(
                    'common:data.sources.target_facts',
                    'Advertising Targets'
                ),
                moduleKey: 'advertising_intelligence',
            },
            ams_stream_facts: {
                key: 'ams_stream_facts',
                name: t(
                    'common:data.sources.ams_stream_facts',
                    'Amazon Marketing Stream Data'
                ),
                moduleKey: 'advertising_intelligence',
            },
            wm_advertising_campaign_facts: {
                key: 'wm_advertising_campaign_facts',
                name: t(
                    'common:data.sources.wm_advertising_campaign_facts',
                    'Walmart Campaigns'
                ),
                moduleKey: 'advertising_intelligence',
            },
            wm_advertising_placement_facts: {
                key: 'wm_advertising_placement_facts',
                name: t(
                    'common:data.sources.wm_advertising_placement_facts',
                    'Walmart Placement'
                ),
                moduleKey: 'advertising_intelligence',
            },
            wm_advertising_platform_facts: {
                key: 'wm_advertising_platform_facts',
                name: t(
                    'common:data.sources.wm_advertising_platform_facts',
                    'Walmart Platform'
                ),
                moduleKey: 'advertising_intelligence',
            },
            wm_advertising_keyword_facts: {
                key: 'wm_advertising_keyword_facts',
                name: t(
                    'common:data.sources.wm_advertising_keyword_facts',
                    'Walmart Ad Keywords'
                ),
                moduleKey: 'advertising_intelligence',
            },
            ad_group_facts: {
                key: 'ad_group_facts',
                name: t('common:data.sources.ad_group_facts', 'Ad Groups'),
                moduleKey: 'retail_insights', // This data source is not selectable in the UI
            },
            campaign_placement_facts: {
                key: 'campaign_placement_facts',
                name: t(
                    'common:data.sources.campaign_placement_facts',
                    'Campaign Placements'
                ),
                moduleKey: 'retail_insights', // This data source is not selectable in the UI
            },
            keyword_query_facts: {
                key: 'keyword_query_facts',
                name: t(
                    'common:data.sources.keyword_query_facts',
                    'Advertising Keywords'
                ),
                moduleKey: 'advertising_intelligence',
            },
            target_query_facts: {
                key: 'target_query_facts',
                name: t('common:data.sources.target_query_facts', 'Targets'),
                moduleKey: 'retail_insights', // This data source is not selectable in the UI
            },
            dsp_campaign_facts: {
                key: 'dsp_campaign_facts',
                name: t(
                    'common:data.sources.dsp_campaign_facts',
                    'DSP Campaigns'
                ),
                moduleKey: 'advertising_intelligence',
            },
            dsp_inventory_facts: {
                key: 'dsp_inventory_facts',
                name: t(
                    'common:data.sources.dsp_inventory_facts',
                    'DSP Inventory'
                ),
                moduleKey: 'advertising_intelligence',
            },
            dsp_audience_facts: {
                key: 'dsp_audience_facts',
                name: t(
                    'common:data.sources.dsp_audience_facts',
                    'DSP Audience'
                ),
                moduleKey: 'advertising_intelligence',
            },
            sov_search_results: {
                key: 'sov_search_results',
                name: t(
                    'common:data.sources.sov_search_results',
                    'Shelf Intelligence'
                ),
                moduleKey: 'shelf_intelligence',
            },
            asin_sales_estimate_facts: {
                key: 'asin_sales_estimate_facts',
                name: t(
                    'common:data.sources.asin_sales_estimate_facts',
                    'Sales Estimates'
                ),
                moduleKey: 'internal_only',
            },
            segment_facts: {
                key: 'segment_facts',
                name: t('common:data.sources.segment_facts', 'Segments'),
                moduleKey: 'market_intelligence',
            },
            sc_sales_and_traffic: {
                key: 'sc_sales_and_traffic',
                name: t(
                    'common:data.sources.sc_sales_and_traffic',
                    'Seller Central - Sales and Traffic'
                ),
                moduleKey: 'retail_insights',
            },
            sc_all_orders: {
                key: 'sc_all_orders',
                name: t(
                    'common:data.sources.sc_all_orders',
                    'Seller Central - All Orders'
                ),
                moduleKey: 'retail_insights',
            },
            vc_api_sales_diagnostic: {
                key: 'vc_api_sales_diagnostic',
                name: t(
                    'common:data.sources.vc_api_sales_diagnostic',
                    'Vendor Central - Sales Diagnostic Manufacturer'
                ),
                moduleKey: 'retail_insights',
            },
            vc_api_sales_diagnostic_sourcing: {
                key: 'vc_api_sales_diagnostic_sourcing',
                name: t(
                    'common:data.sources.vc_api_sales_diagnostic_sourcing',
                    'Vendor Central - Sales Diagnostic Sourcing'
                ),
                moduleKey: 'retail_insights',
            },
            vc_api_inventory_health: {
                key: 'vc_api_inventory_health',
                name: t(
                    'common:data.sources.vc_api_inventory_health',
                    'Vendor Central - Inventory Health'
                ),
                moduleKey: 'retail_insights',
            },
            vc_api_inventory_manufacturing: {
                key: 'vc_api_inventory_manufacturing',
                name: t(
                    'common:data.sources.vc_api_inventory_manufacturing',
                    'Vendor Central - Inventory Manufacturing'
                ),
                moduleKey: 'retail_insights',
            },
            vc_api_inventory_sourcing: {
                key: 'vc_api_inventory_sourcing',
                name: t(
                    'common:data.sources.vc_api_inventory_sourcing',
                    'Vendor Central - Inventory Sourcing'
                ),
                moduleKey: 'retail_insights',
            },
            wm_advertising_item_facts: {
                key: 'wm_advertising_item_facts',
                name: t(
                    'common:data.sources.wm_advertising_item_facts',
                    'Walmart Ad Items'
                ),
                moduleKey: 'advertising_intelligence',
            },
            wm_marketplace_order_facts: {
                key: 'wm_marketplace_order_facts',
                name: t(
                    'common:data.sources.wm_marketplace_order_facts',
                    'Walmart Marketplace'
                ),
                moduleKey: 'retail_insights',
            },
            vc_sales_diagnostic: {
                key: 'vc_sales_diagnostic',
                name: t(
                    'common:data.sources.vc_sales_diagnostic',
                    'Vendor Central - Manufacturer Sales Diagnostic Data (Legacy Integration)'
                ),
                moduleKey: 'retail_insights',
            },
            vc_source_sales_diagnostic: {
                key: 'vc_source_sales_diagnostic',
                name: t(
                    'common:data.sources.vc_source_sales_diagnostic',
                    'Vendor Central - Sourcing Sales Diagnostic Data (Legacy Integration)'
                ),
                moduleKey: 'retail_insights',
            },
            vc_inventory_health: {
                key: 'vc_inventory_health',
                name: t(
                    'common:data.sources.vc_inventory_health',
                    'Vendor Central - Inventory Health (Legacy Integration)'
                ),
                moduleKey: 'retail_insights',
            },
            vc_traffic_diagnostic: {
                key: 'vc_traffic_diagnostic',
                name: t(
                    'common:data.sources.vc_traffic_diagnostic',
                    'Vendor Central - Traffic Diagnostic (Legacy Integration)'
                ),
                moduleKey: 'retail_insights',
            },
            changelog: {
                key: 'changelog',
                name: t('common:data.sources.changelog', 'Changelog'),
                moduleKey: 'retail_insights',
            },
            asin_usage: {
                key: 'asin_usage',
                name: t('common:data.sources.asin_usage', 'ASIN Usage'),
                moduleKey: 'internal_only',
            },
        }),
        [t]
    )

    return widgetDataSourceInfo
}
