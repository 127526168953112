import { handleActions } from 'redux-actions'

import { keywordsTableColumnsConfig } from 'configuration/tables'
import {
    KEYWORD_TEXT,
    METRIC_FILTERS,
    DATES,
    KEYWORD_MATCH_TYPES,
    KEYWORD_STATES,
} from 'const/filters'
import { FILTER_SETTINGS, LOCAL_FILTERS } from 'const/reducerKeys'
import { ENABLED, PAUSED, KEYWORD_STATE_LABELS } from 'const/resourceStates'
import { serializeColumns } from 'helpers/tables'
import { defaultTab, getDefaultTable } from 'reducers/ui/defaults'

const initialState = {
    ...defaultTab,
    [LOCAL_FILTERS]: {
        [KEYWORD_TEXT]: null,
        [METRIC_FILTERS]: [],
        [KEYWORD_MATCH_TYPES]: [],
        [KEYWORD_STATES]: [
            {
                value: ENABLED,
                label: KEYWORD_STATE_LABELS[ENABLED],
            },
            {
                value: PAUSED,
                label: KEYWORD_STATE_LABELS[PAUSED],
            },
        ],
    },
    [FILTER_SETTINGS]: {
        anchored: [KEYWORD_TEXT, DATES],
        order: [METRIC_FILTERS, KEYWORD_MATCH_TYPES, KEYWORD_STATES],
        displayState: {
            [METRIC_FILTERS]: true,
            [KEYWORD_MATCH_TYPES]: false,
            [KEYWORD_STATES]: true,
        },
    },
    table: getDefaultTable(serializeColumns(keywordsTableColumnsConfig)),
}

export default handleActions({}, initialState)
