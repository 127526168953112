import {
    ADD_LABELS,
    DECREASE_BIDS,
    DECREASE_BIDS_BY_VALUE,
    DECREASE_BUDGETS,
    DECREASE_BUDGETS_BY_VALUE,
    DO_NOTHING,
    ENABLE_PRODUCT_ADS_BASED_ON_ASIN,
    ENABLE_RESOURCE,
    INCREASE_BIDS,
    INCREASE_BIDS_BY_VALUE,
    INCREASE_BUDGETS,
    INCREASE_BUDGETS_BY_VALUE,
    PAUSE_PRODUCT_ADS_BASED_ON_ASIN,
    PAUSE_RESOURCE,
    REMOVE_LABELS,
    REPLACE_LABELS,
    SET_BIDS,
    SET_BUDGETS,
} from 'const/rulebooks'
import { Action } from 'types'

export const BUDGET_ACTIONS: Action[] = [
    {
        label: 'Decrease budgets (%)',
        value: DECREASE_BUDGETS,
    },
    {
        label: 'Decrease budgets (value)',
        value: DECREASE_BUDGETS_BY_VALUE,
    },
    {
        label: 'Increase budgets (%)',
        value: INCREASE_BUDGETS,
    },
    {
        label: 'Increase budgets (value)',
        value: INCREASE_BUDGETS_BY_VALUE,
    },
    {
        label: 'Set budgets',
        value: SET_BUDGETS,
    },
]

export const BUDGET_CAP_ACTIONS: Action[] = [
    {
        label: 'Decrease budget caps (%)',
        value: DECREASE_BUDGETS,
    },
    {
        label: 'Decrease budget caps (value)',
        value: DECREASE_BUDGETS_BY_VALUE,
    },
    {
        label: 'Increase budget caps (%)',
        value: INCREASE_BUDGETS,
    },
    {
        label: 'Increase budget caps (value)',
        value: INCREASE_BUDGETS_BY_VALUE,
    },
    {
        label: 'Set budget caps',
        value: SET_BUDGETS,
    },
]

export const LABEL_ACTIONS: Action[] = [
    {
        label: 'Add Labels',
        value: ADD_LABELS,
    },
    {
        label: 'Remove Labels',
        value: REMOVE_LABELS,
    },
    {
        label: 'Clear and Replace Labels',
        value: REPLACE_LABELS,
    },
]

export const BID_ACTIONS: Action[] = [
    {
        label: 'Decrease bids (%)',
        value: DECREASE_BIDS,
    },
    {
        label: 'Decrease bids (value)',
        value: DECREASE_BIDS_BY_VALUE,
    },
    {
        label: 'Increase bids (%)',
        value: INCREASE_BIDS,
    },
    {
        label: 'Increase bids (value)',
        value: INCREASE_BIDS_BY_VALUE,
    },
    {
        label: 'Set bids',
        value: SET_BIDS,
    },
]

export const PAUSE_ENABLE_RESOURCE_ACTIONS: Action[] = [
    {
        label: 'Pause',
        value: PAUSE_RESOURCE,
    },
    {
        label: 'Enable',
        value: ENABLE_RESOURCE,
    },
]

export const PRODUCT_ADS_ACTIONS: Action[] = [
    {
        label: 'Pause',
        value: PAUSE_PRODUCT_ADS_BASED_ON_ASIN,
    },
    {
        label: 'Enable',
        value: ENABLE_PRODUCT_ADS_BASED_ON_ASIN,
    },
]

export const DO_NOTHING_ACTIONS: Action[] = [
    {
        label: 'Do nothing',
        value: DO_NOTHING,
    },
]
