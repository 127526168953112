import { ReactElement } from 'react'

import Icon from '@ant-design/icons'

import BuildingIconSvg from './BuildingIconSvg'
import { IconProps } from '../localTypes'

const BuildingIcon = (props: IconProps): ReactElement => (
    <Icon component={BuildingIconSvg} {...props} />
)

export default BuildingIcon
