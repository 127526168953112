import { SetAsHomeField } from 'components/EditableFields'
import { UNDEFINED_VALUE } from 'const/formatting'
import { isUnset } from 'helpers/utilities'
import { Field } from 'types'

import { createActionField, createField } from './createField'
import { FieldCreatorOptions } from './localTypes'

export function setAsHomePage<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['id']
    return createActionField({
        ...options,
        id: 'set_as_homepage',
        isResizeable: false,
        isVisible: true,
        dataIndex,
        minWidth: 55,
        renderOptions: options.renderOptions ?? {
            render: ({ value }) => {
                if (isUnset(value)) {
                    return <span>{UNDEFINED_VALUE}</span>
                }

                return <SetAsHomeField value={value} />
            },
        },
        align: 'center',
        sorter: options.sorter ?? false,
    })
}

export function dashboardName<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['name']
    return createField({
        ...options,
        id: 'dashboard_name',
        name: 'Dashboard Name',
        shortName: 'Name',
        minWidth: 50,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: ({ value }) => value ?? UNDEFINED_VALUE,
        },
        width: 200,
    })
}

export function dashboardDescription<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['description']
    return createField({
        ...options,
        id: 'dashboard_description',
        name: 'Dashboard Description',
        shortName: 'Description',
        minWidth: 50,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: ({ value }) => value ?? UNDEFINED_VALUE,
        },
        width: 200,
    })
}

export function dashboardSharing<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['dashboard_sharing']
    return createField({
        ...options,
        id: 'dashboard_sharing',
        name: 'Dashboard Sharing',
        shortName: 'Sharing',
        minWidth: 50,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: ({ value }) => value ?? UNDEFINED_VALUE,
        },
        width: 200,
        sorter: false,
    })
}

export function deleteDashboard<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createActionField({
        ...options,
        id: 'delete_dashboard',
        dataIndex: [],
        isResizeable: false,
        minWidth: 68,
        isVisible: true,
        renderOptions: options.renderOptions ?? {
            render: ({ value }) => value ?? UNDEFINED_VALUE,
        },
        sorter: false,
        fixed: 'right',
        align: 'center',
    })
}
