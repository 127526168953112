import { ReactNode } from 'react'

import { WALMART_BUDGET_TYPES } from 'const/walmart'
import { WalmartAdvertiser, WalmartCampaignBudgetType } from 'types'

import { formatDate } from './dates'
import { titleCase } from './formatting'

export const formatBudgetType = (
    budgetType: WalmartCampaignBudgetType
): ReactNode => {
    return WALMART_BUDGET_TYPES[budgetType].label
}

const NUMBER_REGEX = /^-?\d+$/
// Walmart Item IDs are documented as a string of numbers
// that is equal or less than 9 digits
export const isWalmartItemId = (value: string): boolean => {
    return NUMBER_REGEX.test(value) && value.length <= 9
}

export const formatWalmartResourceStateField = (
    record: any & { advertiser?: WalmartAdvertiser },
    resourceKey: 'ad_item' | 'ad_group' | 'keyword' | 'campaign'
): string => {
    const state = titleCase(record[resourceKey].state)

    return record.advertiser?.read_only
        ? `${state} (as of ${formatDate(
              record[resourceKey].updated_at
          )} Snapshot)`
        : state
}
