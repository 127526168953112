import { PRODUCT_ASINS } from 'const/filters'
import { formatFilters as formatCampaignFactsFilters } from 'helpers/filters/campaignFacts'
import { isNonEmptyArray } from 'helpers/typeGuard'
import { FiltersState, SBPurchasedProductFactsParams } from 'types'

export const formatFilters = (
    filters: FiltersState,
    hasHourlyData = false
): SBPurchasedProductFactsParams => {
    const params = formatCampaignFactsFilters(filters, hasHourlyData)

    const asins = filters[PRODUCT_ASINS]
    if (isNonEmptyArray(asins)) {
        params.purchased_asin__in = asins.map((item) => item.value).join()
    }

    return params
}
