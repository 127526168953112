import { SVGAttributes, ReactElement } from 'react'

type Props = SVGAttributes<SVGElement>

function SvgHomeIcon(props: Props): ReactElement {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M16.333 14.067v-4.74c0-.855 0-1.282-.109-1.676a3.001 3.001 0 00-.47-.969c-.241-.33-.576-.594-1.246-1.123l-1.534-1.21c-1.062-.84-1.594-1.26-2.181-1.42a3 3 0 00-1.585 0c-.588.16-1.12.58-2.182 1.42l-1.534 1.21c-.67.529-1.005.793-1.246 1.123a3 3 0 00-.47.97c-.11.393-.11.82-.11 1.674v4.74c0 .56 0 .84.11 1.055a1 1 0 00.437.437c.214.109.494.109 1.054.109H6.4c.56 0 .84 0 1.054-.11a1 1 0 00.437-.436C8 14.907 8 14.627 8 14.067v-1.734a2 2 0 014 0v1.734c0 .56 0 .84.109 1.054a1 1 0 00.437.437c.214.109.494.109 1.054.109h1.133c.56 0 .84 0 1.054-.11a1 1 0 00.437-.436c.11-.214.11-.494.11-1.054z" />
        </svg>
    )
}

export default SvgHomeIcon
