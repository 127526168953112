import { ReactElement, ReactNode } from 'react'

import { Modal, type ModalProps } from 'antd'

interface Props extends ModalProps {
    children: ReactNode
}

function CreateResourceModal({
    children,
    cancelText = 'Cancel',
    okText = 'Save',
    ...rest
}: Props): ReactElement {
    return (
        <Modal cancelText={cancelText} okText={okText} {...rest}>
            {children}
        </Modal>
    )
}

export default CreateResourceModal
