import { FunctionComponent, SVGAttributes } from 'react'

import { Translation } from 'react-i18next'

import { getProductTitleOutsideThemeContext } from 'appConfig'
import { ReactComponent as AdvancedBudgetControlSvg } from 'images/automation/advanced_budget_control.svg'
import { ReactComponent as ASINHarvestingSvg } from 'images/automation/asin_harvesting.svg'
import { ReactComponent as BudgetMaximizationSvg } from 'images/automation/budget_maximization.svg'
import { ReactComponent as KeywordHarvestingSvg } from 'images/automation/keyword_harvesting.svg'
import { ReactComponent as RoiOptimizationSvg } from 'images/automation/roi_optimization.svg'
import { ReactComponent as SovTargetingSvg } from 'images/automation/sov_targeting.svg'
import { ReactComponent as TimePartingSvg } from 'images/automation/time_parting.svg'

export const NO_AUTOMATION_OPTION = {
    value: 'false',
    label: 'No Automation Tasks',
}

export const AUTOMATIONS = {
    time_parting: {
        id: 'time_parting',
        name: (
            <Translation>
                {(t) => t('automations:dayparting.name', 'Dayparting')}
            </Translation>
        ),
        description: (
            <Translation>
                {(t) =>
                    t(
                        'automations:dayparting.description',
                        'Control your spend with bid multipliers for each day of the week or each hour of the day. After enabling dayparting on a campaign, we recommend managing the campaign in {{ productTitle }} rather than the Amazon Console for best results.',
                        { productTitle: getProductTitleOutsideThemeContext() }
                    )
                }
            </Translation>
        ),
    },
    roi_optimization: {
        id: 'roi_optimization',
        name: (
            <Translation>
                {(t) =>
                    t('automations:roiOptimization.name', 'ROI Optimization')
                }
            </Translation>
        ),
        description: (
            <Translation>
                {(t) =>
                    t(
                        'automations:roiOptimization.description',
                        'Let our algorithm help with your campaign ROI. {{ productTitle }}’s machine learning engine will work to improve this campaign’s ACoS / RoAS by actively changing bids and gathering data on unexplored keywords in a campaign. Set a maximum acceptable ACoS or minimum acceptable RoAS and {{ productTitle }} will target this benchmark and work to continuously improve beyond your goals. Your campaign ad spend may need to be reduced to reach your maximum ACoS / minimum RoAS.',
                        { productTitle: getProductTitleOutsideThemeContext() }
                    )
                }
            </Translation>
        ),
    },
    budget_pacing: {
        id: 'budget_pacing',
        name: (
            <Translation>
                {(t) =>
                    t(
                        'automations:budgetMaximization.name',
                        'Budget Maximization'
                    )
                }
            </Translation>
        ),
        description: (
            <Translation>
                {(t) =>
                    t(
                        'automations:budgetMaximization.description',
                        'Maximize your sales with a budget constraint. {{ productTitle }} will automatically update your campaign to spend a desired budget quota within a specific time window, and achieve the best ROI possible with the budget. You must choose a time window of at least 30 days.',
                        { productTitle: getProductTitleOutsideThemeContext() }
                    )
                }
            </Translation>
        ),
    },
    sov_targeting: {
        id: 'sov_targeting',
        name: (
            <Translation>
                {(t) => t('automations:sovTargeting.name', 'Shelf Planning')}
            </Translation>
        ),
        description: (
            <Translation>
                {(t) =>
                    t(
                        'automations:sovTargeting.description',
                        '{{ productTitle }} will automatically adjust your campaigns to achieve and maintain a specific share of voice (SOV) for a single search term. Your SOV target may not be achievable with your daily budget and maintaining SOV may come at the expense of efficient ROI. The keyword for the automation will be added to Shelf Intelligence and will count against the cap if the term is not already being tracked in your account. For best results, use this automation on a manual campaign with only one keyword.',
                        { productTitle: getProductTitleOutsideThemeContext() }
                    )
                }
            </Translation>
        ),
    },
    keyword_harvesting: {
        id: 'keyword_harvesting',
        name: (
            <Translation>
                {(t) =>
                    t(
                        'automations:keywordHarvesting.name',
                        'Keyword Harvesting'
                    )
                }
            </Translation>
        ),
        description: (
            <Translation>
                {(t) =>
                    t(
                        'automations:keywordHarvesting.description',
                        '{{ productTitle }} will automatically find customer search terms that drive sales and add them to this campaign as exact match. For best results, we recommend using Keyword Harvesting automation in parallel with either ACoS or RoAS Optimization.',
                        { productTitle: getProductTitleOutsideThemeContext() }
                    )
                }
            </Translation>
        ),
    },
    asin_harvesting: {
        id: 'asin_harvesting',
        name: (
            <Translation>
                {(t) => t('automations:asinHarvesting.name', 'ASIN Harvesting')}
            </Translation>
        ),
        description: (
            <Translation>
                {(t) =>
                    t(
                        'automations:asinHarvesting.description',
                        'ASIN Harvesting allows you to automatically identify the highest performing Product Target ASINs from selected ad groups, and place them into a new ad group of your choosing. Based on metric thresholds you provide, {{ productTitle }} will copy ASINs (from your selected sources) into your target ad group.',
                        { productTitle: getProductTitleOutsideThemeContext() }
                    )
                }
            </Translation>
        ),
    },
    advanced_budget_control: {
        id: 'advanced_budget_control',
        name: (
            <Translation>
                {(t) =>
                    t(
                        'automations:advancedBudgetControl.name',
                        'Advanced Budget Control'
                    )
                }
            </Translation>
        ),
        description: (
            <Translation>
                {(t) =>
                    t(
                        'automations:advancedBudgetControl.description',
                        'Save time managing your budget with custom budget multipliers for each day of the week. Distribute your budget evenly by the hour to ensure your ad is being served all day.'
                    )
                }
            </Translation>
        ),
    },
}

export const AUTOMATION_KEYS = {
    time_parting: 'time_parting',
    roi_optimization: 'roi_optimization',
    budget_pacing: 'budget_pacing',
    sov_targeting: 'sov_targeting',
    keyword_harvesting: 'keyword_harvesting',
    asin_harvesting: 'asin_harvesting',
    advanced_budget_control: 'advanced_budget_control',
}

export const AUTOMATIONS_ORDER = [
    'time_parting',
    'roi_optimization',
    'budget_pacing',
    'sov_targeting',
    'keyword_harvesting',
    'asin_harvesting',
    'advanced_budget_control',
]

interface AutomationIcons {
    [id: string]: FunctionComponent<SVGAttributes<SVGElement>>
}

export const AUTOMATION_ICONS: AutomationIcons = {
    time_parting: TimePartingSvg,
    roi_optimization: RoiOptimizationSvg,
    budget_pacing: BudgetMaximizationSvg,
    sov_targeting: SovTargetingSvg,
    keyword_harvesting: KeywordHarvestingSvg,
    asin_harvesting: ASINHarvestingSvg,
    advanced_budget_control: AdvancedBudgetControlSvg,
}
