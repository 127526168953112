import filter from 'lodash/filter'
import { Translation } from 'react-i18next'

import { AutomationTypeContainer } from 'components/AutomationType'
import { budgetStatusSorter } from 'components/FieldRenderers/BudgetStatusCell/helpers'
import {
    campaignIdRenderer,
    campaignNameRenderer,
    campaignStateRenderer,
    campaignEndDateRenderer,
    campaignLabelIdRenderer,
    archiveCampaignRenderer,
    removeCampaignFromPortfolioRenderer,
    removeCampaignFromLabelRenderer,
    campaignAutomatedBiddingRenderer,
    campaignBidMultiplierRenderer,
    campaignBudgetStatusRenderer,
} from 'components/FieldRenderers/Renderers/campaign'
import { AD_FORMAT_LABELS } from 'const/adFormats'
import { FACT_TYPE_LABELS } from 'const/factTypes'
import {
    PLACEMENT_OTHER,
    PLACEMENT_PRODUCT_PAGE,
    PLACEMENT_TOP,
} from 'const/placements'
import { TARGETING_TYPE_LABELS } from 'const/targetingTypes'
import { isUnset } from 'helpers/utilities'
import {
    CampaignBudgetStatusGroupBy,
    CampaignGroupBy,
    CampaignPlacementGroupBy,
    Field,
} from 'types'

import {
    createActionField,
    createDateField,
    createField,
    createLabelLookupField,
} from './createField'
import { FieldCreatorOptions } from './localTypes'

export function campaignId<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['campaign', 'id']
    const name = (
        <Translation>{(t) => t('common:campaign', 'Campaign')}</Translation>
    )
    return createField({
        ...options,
        id: 'campaign_id',
        name,
        shortName: name,
        dataIndex,
        minWidth: 200,
        width: 300,
        columnTitle: name,
        isTotalSupported: options.isTotalSupported ?? true,
        renderOptions: options.renderOptions ?? {
            render: (props) => campaignIdRenderer<any>(props),
        },
    })
}

export function campaignName<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['campaign', 'name']
    return createField({
        ...options,
        id: 'campaign_name',
        name: 'Campaign Name',
        shortName: 'Campaign',
        isResizeable: true,
        minWidth: 200,
        width: 300,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: (props) => campaignNameRenderer<any>(props),
        },
        columnTitle: 'Campaign Name',
    })
}

export function campaignType<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['campaign', 'campaign_type']
    const name = (
        <Translation>
            {(t) => t('table:fields.campaignType.name', 'Campaign Type')}
        </Translation>
    )
    return createLabelLookupField({
        ...options,
        id: 'campaign__campaign_type',
        name,
        shortName: name,
        minWidth: 120,
        width: 150,
        dataIndex,
        labels: FACT_TYPE_LABELS,
    })
}

export function campaignAdFormat<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['campaign', 'ad_format']
    const name = (
        <Translation>
            {(t) =>
                t('table:fields.campaignAdFormat.name', 'Campaign Ad Format')
            }
        </Translation>
    )
    const shortName = (
        <Translation>
            {(t) => t('table:fields.campaignAdFormat.shortName', 'Ad Format')}
        </Translation>
    )
    return createLabelLookupField({
        ...options,
        id: 'campaign__ad_format',
        name,
        shortName,
        isVisible: options.isVisible ?? false,
        isResizeable: true,
        minWidth: 80,
        width: 120,
        dataIndex,
        labels: AD_FORMAT_LABELS,
    })
}

export function campaignTargetingType<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['campaign', 'targeting_type']
    const name = (
        <Translation>
            {(t) =>
                t(
                    'table:fields.campaignTargetingType.name',
                    'Campaign Targeting Type'
                )
            }
        </Translation>
    )
    const shortName = (
        <Translation>
            {(t) =>
                t(
                    'table:fields.campaignTargetingType.shortName',
                    'Targeting Type'
                )
            }
        </Translation>
    )
    return createLabelLookupField({
        ...options,
        id: 'campaign__targeting_type',
        name,
        shortName,
        isResizeable: true,
        minWidth: 120,
        width: 150,
        dataIndex,
        labels: TARGETING_TYPE_LABELS,
    })
}

export function campaignState<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['campaign', 'state']
    const name = (
        <Translation>
            {(t) => t('table:fields.campaignState.name', 'Campaign State')}
        </Translation>
    )
    const shortName = (
        <Translation>
            {(t) => t('table:fields.campaignState.shortName', 'State')}
        </Translation>
    )
    return createField({
        ...options,
        id: 'campaign__state',
        name,
        shortName,
        minWidth: 85,
        width: 150,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: (cellRenderProps) =>
                campaignStateRenderer<any>({ cellRenderProps, dataIndex }),
        },
    })
}

export function campaignAutomatedBidding<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['campaign', 'bid_optimization']
    const name = (
        <Translation>
            {(t) =>
                t(
                    'table:fields.campaignAutomatedBidding.name',
                    'Automated Bidding'
                )
            }
        </Translation>
    )
    return createField({
        ...options,
        id: 'campaign__bid_optimization',
        name,
        shortName: name,
        minWidth: 85,
        width: 150,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: (props) => campaignAutomatedBiddingRenderer<any>(props),
        },
        sorter: false,
    })
}

export function campaignBudget<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['campaign', 'base_budget']
    const name = (
        <Translation>
            {(t) => t('table:fields.campaignBudget.name', 'Base Budget')}
        </Translation>
    )
    const shortName = (
        <Translation>
            {(t) => t('table:fields.campaignBudget.shortName', 'Budget')}
        </Translation>
    )
    return createField({
        ...options,
        id: 'campaign_budget',
        name,
        shortName,
        minWidth: 100,
        width: 120,
        dataIndex,
        align: 'right',
    })
}

export function campaignActiveBudget<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['campaign', 'active_budget']
    const helpText =
        'This is a snapshot of the current max budget during automation.'
    const name = (
        <Translation>
            {(t) =>
                t('table:fields.campaignActiveBudget.name', 'Active Budget')
            }
        </Translation>
    )
    return createField({
        ...options,
        id: 'campaign_active_budget',
        name,
        shortName: name,
        minWidth: 120,
        dataIndex,
        localDefinition: helpText,
        align: 'right',
    })
}

export function campaignBudgetStatus<
    RecordType extends CampaignGroupBy & CampaignBudgetStatusGroupBy,
>(options: FieldCreatorOptions<RecordType> = {}): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['budget_percentage']
    const name = (
        <Translation>
            {(t) =>
                t('table:fields.campaignBudgetStatus.name', 'Budget Status')
            }
        </Translation>
    )
    return createField({
        ...options,
        id: 'campaign__budget_status',
        name,
        shortName: name,
        isResizeable: false,
        minWidth: 120,
        width: 200,
        isVisible: options.isVisible ?? false,
        dataIndex,
        renderOptions: {
            render: (renderProps) =>
                campaignBudgetStatusRenderer({
                    cellRenderProps: renderProps,
                    dataIndex: ['budget_percentage'],
                }),
        },
        columnTitle: name,
        sorter: options.sorter ?? budgetStatusSorter,
        readonly: true,
    })
}

export function campaignCreatedDate<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['campaign', 'created_date']
    const name = (
        <Translation>
            {(t) => t('common:createdDate', 'Created Date')}
        </Translation>
    )
    return createDateField({
        ...options,
        id: 'campaign_created_date',
        name,
        shortName: 'Created Date',
        dataIndex,
        minWidth: 80,
        width: 110,
        isVisible: options.isVisible ?? false,
    })
}

export function campaignStartDate<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['campaign', 'start_date']
    const name = (
        <Translation>
            {(t) => t('table:fields.campaignStartDate.name', 'Start Date')}
        </Translation>
    )
    return createDateField({
        ...options,
        id: 'campaign_start_date',
        name,
        shortName: name,
        dataIndex,
        minWidth: 80,
        width: 110,
    })
}

export function campaignEndDate<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['campaign', 'end_date']
    const name = (
        <Translation>
            {(t) => t('table:fields.campaignEndDate.name', 'End Date')}
        </Translation>
    )
    return createField({
        ...options,
        id: 'campaign_end_date',
        name,
        shortName: name,
        minWidth: 80,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: (cellRenderProps) =>
                campaignEndDateRenderer<any>({ cellRenderProps, dataIndex }),
        },
        columnTitle: name,
        width: 110,
    })
}

export function campaignLabelId<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['campaign__label_id']
    return createField({
        ...options,
        id: 'campaign__label_id',
        name: 'Campaign Label',
        shortName: 'Campaign Label',
        minWidth: 50,
        width: 110,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: (props) => campaignLabelIdRenderer<any>(props),
        },
    })
}

export function campaignCapabilityId<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['automation_capability_id']
    return createField({
        ...options,
        id: 'automation_capability_id',
        name: 'Automation Type',
        shortName: 'Automation',
        minWidth: 80,
        width: 110,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: ({ value }) =>
                isUnset(value) ? (
                    'No Automation'
                ) : (
                    <AutomationTypeContainer capabilityId={value} />
                ),
        },
    })
}

export function campaignAutomationTasks<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['campaign', 'automation_tasks']
    const name = (
        <Translation>
            {(t) =>
                t('table:fields.campaignAutomationTasks.name', 'Automation')
            }
        </Translation>
    )
    return createField({
        ...options,
        id: 'campaign_automation_tasks',
        name,
        shortName: name,
        minWidth: 150,
        width: 200,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: ({ value }) => {
                const enabledAutomations = filter(
                    value,
                    (task) => task.enabled && task.capability
                )
                return (
                    <AutomationTypeContainer
                        capabilities={enabledAutomations.map(
                            (automation) => automation.capability
                        )}
                        empty={
                            <Translation>
                                {(t) =>
                                    t(
                                        'table:fields.campaignAutomationTasks.empty',
                                        'No Automation Tasks'
                                    )
                                }
                            </Translation>
                        }
                    />
                )
            },
        },
        sorter: options.sorter ?? false,
        columnTitle: name,
    })
}

export function archiveCampaign<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['campaign', 'id']
    return createField({
        ...options,
        id: 'archive_campaign',
        name: 'Archive',
        shortName: 'Archive',
        isResizeable: false,
        minWidth: 68,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: (cellRenderProps) =>
                archiveCampaignRenderer<any>({ cellRenderProps, dataIndex }),
        },
        sorter: false,
        fixed: 'right',
        align: 'center',
    })
}

export function removeCampaignFromPortfolio<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['campaign', 'id']
    return createActionField({
        ...options,
        id: 'remove_from_portfolio',
        dataIndex,
        isResizeable: false,
        minWidth: 68,
        renderOptions: options.renderOptions ?? {
            render: (cellRenderProps) =>
                removeCampaignFromPortfolioRenderer<any>({
                    cellRenderProps,
                    dataIndex,
                }),
        },
        sorter: false,
        fixed: 'right',
        align: 'center',
    })
}

export function removeCampaignFromLabel<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['campaign', 'id']
    return createActionField({
        ...options,
        id: 'remove_from_label',
        dataIndex,
        isResizeable: false,
        minWidth: 68,
        renderOptions: options.renderOptions ?? {
            render: (cellRenderProps) =>
                removeCampaignFromLabelRenderer<any>({
                    cellRenderProps,
                    dataIndex,
                }),
        },
        sorter: false,
        fixed: 'right',
        align: 'center',
    })
}

export function campaignBidMultiplierTopOfSearch<
    RecordType extends CampaignPlacementGroupBy,
>(options: FieldCreatorOptions<RecordType> = {}): Field<RecordType> {
    const dataIndex = ['campaign', 'bid_adjustments', 'placementTop']
    const name = (
        <Translation>
            {(t) =>
                t(
                    'table:fields.campaignBidMultiplierTopOfSearch.name',
                    'Bid Multiplier (Top of Search)'
                )
            }
        </Translation>
    )
    const helpText = (
        <Translation>
            {(t) =>
                t(
                    'table:fields.campaignBidMultiplierTopOfSearch.helpText',
                    'Bid adjustment for the Top of Search on the first page of results.\n\nThis field can only be adjusted for Sponsored Products campaigns.\n\nYou may increase bids for Top of Search by up to 900%.\n\nExample: A 100% increase on a $0.75 bid will become $1.50.'
                )
            }
        </Translation>
    )
    return createField({
        ...options,
        id: 'campaign__campaign_bid_multiplier_top_of_search',
        name,
        dataIndex,
        shortName: name,
        minWidth: 120,
        width: 150,
        renderOptions: {
            render: (renderProps) =>
                campaignBidMultiplierRenderer({
                    cellRenderProps: renderProps,
                    type: PLACEMENT_TOP,
                    dataIndex,
                }),
        },
        localDefinition: helpText,
        align: 'right',
    })
}

export function campaignBidMultiplierDetail<
    RecordType extends CampaignPlacementGroupBy,
>(options: FieldCreatorOptions<RecordType> = {}): Field<RecordType> {
    const dataIndex = ['campaign', 'bid_adjustments', 'placementProductPage']
    const name = (
        <Translation>
            {(t) =>
                t(
                    'table:fields.campaignBidMultiplierDetail.name',
                    'Bid Multiplier (Detail Page)'
                )
            }
        </Translation>
    )
    const helpText = (
        <Translation>
            {(t) =>
                t(
                    'table:fields.campaignBidMultiplierDetail.helpText',
                    'Bid adjustment for Product Detail pages.\n\nThis field can only be adjusted for Sponsored Products campaigns.\n\nYou may increase bids for Detail Page by up to 900%.\n\nExample: A 100% increase on a $0.75 bid will become $1.50.'
                )
            }
        </Translation>
    )
    return createField({
        ...options,
        id: 'campaign__campaign_bid_multiplier_detail',
        name,
        shortName: name,
        minWidth: 120,
        dataIndex,
        renderOptions: {
            render: (renderProps) =>
                campaignBidMultiplierRenderer({
                    cellRenderProps: renderProps,
                    type: PLACEMENT_PRODUCT_PAGE,
                    dataIndex,
                }),
        },
        localDefinition: helpText,
        width: 150,
        align: 'right',
    })
}

export function campaignBidMultiplierOther<
    RecordType extends CampaignPlacementGroupBy,
>(options: FieldCreatorOptions<RecordType> = {}): Field<RecordType> {
    const dataIndex = ['campaign', 'bid_adjustments', 'bidMultiplier']

    const name = (
        <Translation>
            {(t) =>
                t(
                    'table:fields.campaignBidMultiplierOther.name',
                    'Bid Multiplier (Other)'
                )
            }
        </Translation>
    )
    const helpText = (
        <Translation>
            {(t) =>
                t(
                    'table:fields.campaignBidMultiplierOther.helpText',
                    'For Sponsored Brands campaigns only — bid adjustment for placements other than Top of Search.\n\nTo enable this field, Automated bidding must be toggled off.\n\nYou may increase or decrease bids by up to 99% Example: A 40% decrease on a $5.00 bid will become $3.00.'
                )
            }
        </Translation>
    )
    return createField({
        ...options,
        id: 'campaign__campaign_bid_multiplier_other',
        name,
        shortName: name,
        minWidth: 120,
        width: 150,
        dataIndex,
        renderOptions: {
            render: (renderProps) =>
                campaignBidMultiplierRenderer({
                    cellRenderProps: renderProps,
                    type: PLACEMENT_OTHER,
                    dataIndex,
                }),
        },
        localDefinition: helpText,
        align: 'right',
    })
}
