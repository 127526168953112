import { combineChildrenReducers } from 'utilities/reducers'

// Reducer that handles page level data, that is, parent reducer

// Children reducers
import childOrgsReducer from './childOrgs'
import groupsReducer from './groups'
import integrationsReducer from './integrations'
import invitationsReducer from './invitations'
import membersReducer from './members'
import pageReducer from './page'
import scIntegrationsReducer from './scIntegrations'
import vcIntegrationsReducer from './vcIntegrations'
import wmAdvertisingIntegrationsReducer from './wmAdvertisingIntegrations'
import wmMarketplaceIntegrationsReducer from './wmMarketplaceIntegrations'

export default combineChildrenReducers(pageReducer, {
    groups: groupsReducer,
    members: membersReducer,
    invitations: invitationsReducer,
    integrations: integrationsReducer,
    vcIntegrations: vcIntegrationsReducer,
    scIntegrations: scIntegrationsReducer,
    wmMarketplaceIntegrations: wmMarketplaceIntegrationsReducer,
    wmAdvertisingIntegrations: wmAdvertisingIntegrationsReducer,
    childOrgs: childOrgsReducer,
})
