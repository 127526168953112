import { call, select, put } from 'redux-saga/effects'

import {
    makeFetchTableRequest,
    makeFetchTableSuccess,
    makeDownloadTableSuccess,
} from 'actions/ui/shared'
import { adAccountProductsTableColumnsConfig } from 'configuration/tables'
import { DATES } from 'const/filters'
import { AD_ACCOUNT_PAGE } from 'const/pages'
import { formatFilters } from 'helpers/filters/productAdFacts'
import { generateReportNotification } from 'helpers/notifications'
import {
    formatPagination,
    formatCurrency,
    formatColumns,
    formatSorter,
    formatMetrics,
    formatPeriodDeltaDateRange,
} from 'helpers/params'
import { cerebroApiSaga } from 'sagas/common'
import uiSagaRegistry from 'sagas/ui/registry'
import {
    selectTableSettings,
    selectVisibleCombinedFilters,
    selectVisibleMetricsOfTable,
    selectVisibleColumnsOfTable,
    selectCurrencyCode,
    selectResourceOfPage,
    selectResourceParamsOfPage,
} from 'selectors/ui'
import {
    getProductAdFactsExport,
    getProductAdFacts,
} from 'services/cerebroApi/orgScope/productAdFactsApi'

const TAB_PATH = [AD_ACCOUNT_PAGE, 'products']
const TABLE_PATH = [...TAB_PATH, 'table']

function* fetchTableSaga(noCount) {
    const { adAccountId } = yield select(
        selectResourceParamsOfPage,
        AD_ACCOUNT_PAGE
    )

    const filters = yield select(selectVisibleCombinedFilters, TAB_PATH)

    const {
        pagination,
        sorter,
        showPeriodDeltas,
        periodDeltaType,
        periodDeltaDateRange,
    } = yield select(selectTableSettings, TABLE_PATH)

    const metrics = yield select(
        selectVisibleMetricsOfTable,
        TABLE_PATH,
        adAccountProductsTableColumnsConfig
    )
    const currency = yield select(selectCurrencyCode)
    const params = {
        ...formatPagination(pagination),
        ...formatSorter(sorter),
        ...formatFilters(filters),
        ...formatMetrics(metrics, showPeriodDeltas),
        ...formatCurrency(currency),
        ...formatPeriodDeltaDateRange(
            showPeriodDeltas,
            periodDeltaType,
            periodDeltaDateRange,
            filters[DATES]
        ),
        profile_id: adAccountId,
        group_by: 'metadata_id',
    }

    yield call(
        cerebroApiSaga,
        makeFetchTableSuccess(TABLE_PATH),
        getProductAdFacts,
        params,
        {
            headers: { noCount },
        }
    )
}

function* downloadTableSaga(path) {
    const adAccount = yield select(selectResourceOfPage, AD_ACCOUNT_PAGE)

    const filters = yield select(selectVisibleCombinedFilters, TAB_PATH)
    const { sorter, showPeriodDeltas, periodDeltaType, periodDeltaDateRange } =
        yield select(selectTableSettings, TABLE_PATH)
    const columns = yield select(
        selectVisibleColumnsOfTable,
        path,
        adAccountProductsTableColumnsConfig
    )
    const currency = yield select(selectCurrencyCode)
    const reportName = `Ad Account Products Report - ${adAccount.brand_name}`
    const params = {
        ...formatSorter(sorter),
        ...formatFilters(filters),
        ...formatColumns(columns, showPeriodDeltas),
        ...formatCurrency(currency),
        ...formatPeriodDeltaDateRange(
            showPeriodDeltas,
            periodDeltaType,
            periodDeltaDateRange,
            filters[DATES]
        ),
        profile: adAccount.id,
        group_by: 'metadata_id',
        async_download_name: reportName,
    }

    yield call(
        cerebroApiSaga,
        makeDownloadTableSuccess(path),
        getProductAdFactsExport,
        params
    )

    generateReportNotification(reportName)
}

function* fetchTabDataSaga() {
    yield put(makeFetchTableRequest(TABLE_PATH)())
}

// Register Sagas
uiSagaRegistry.registerSagas(TAB_PATH, {
    fetchTabDataSaga,
})

uiSagaRegistry.registerSagas(TABLE_PATH, {
    fetchTableSaga,
    downloadTableSaga,
})
