import { memo, ReactElement } from 'react'

import isEqual from 'lodash/isEqual'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Action } from 'redux-actions'

import { downloadReport } from 'actions/ui/app'
import { renderReportActions } from 'helpers/reports'
import { selectDomainValue as selectUiDomainValue } from 'selectors/ui'
import { Report, RootReduxState } from 'types'

interface Props {
    record: Report
}

function DownloadField({ record }: Props): ReactElement | null {
    const { t } = useTranslation('common')
    const newReports = useSelector((state: RootReduxState) =>
        selectUiDomainValue(state, ['app', 'newReports'])
    )

    const dispatch = useDispatch()
    const dispatchDownloadReport = (payload: {
        reportId: string
    }): Action<{ reportId: string }> => dispatch(downloadReport(payload))

    return renderReportActions(record, newReports, dispatchDownloadReport, t)
}

export default memo(DownloadField, isEqual) as typeof DownloadField
