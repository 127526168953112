import account from './account.json'
import asyncUpdates from './asyncUpdates.json'
import auth from './auth.json'
import automations from './automations.json'
import campaigns from './campaigns.json'
import common from './common.json'
import dashboards from './dashboards.json'
import filters from './filters.json'
import metrics from './metrics.json'
import navigation from './navigation.json'
import table from './table.json'

export default {
    account,
    auth,
    asyncUpdates,
    automations,
    campaigns,
    common,
    dashboards,
    filters,
    metrics,
    navigation,
    table,
}
