import { combineChildrenReducers } from 'utilities/reducers'

// Reducer that handles page level data, that is, parent reducer

// Tab reducers
import brandsReducer from './brands'
import campaignsReducer from './campaigns'
import dspLineItemsReducer from './dspLineItems'
import dspOrdersReducer from './dspOrders'
import pageReducer from './page'
import portfoliosReducer from './portfolios'
import productsReducer from './products'
import sovKeywordsReducer from './sovKeywords'

export default combineChildrenReducers(pageReducer, {
    brands: brandsReducer,
    campaigns: campaignsReducer,
    products: productsReducer,
    sovKeywords: sovKeywordsReducer,
    dspOrders: dspOrdersReducer,
    dspLineItems: dspLineItemsReducer,
    portfolios: portfoliosReducer,
})
