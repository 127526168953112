import { ReactElement } from 'react'

import { QuestionCircleOutlined } from '@ant-design/icons'
import isArray from 'lodash/isArray'
import isUndefined from 'lodash/isUndefined'
import moment, { isMoment, Moment, unitOfTime } from 'moment-timezone'
import { Translation } from 'react-i18next'

import { ToolTip } from 'components/ToolTip'
import {
    DateRangeFilter,
    PeriodDeltaType,
    PresetRange,
    PresetRangeMap,
} from 'types'

import { formatCerebroDate, formatJSONDateTime } from './formatting'
import { isPresetDateRange } from './typeGuard'

export function calculateFromDateWithLag(lag?: number): string | undefined {
    if (!lag) {
        return undefined
    }

    const fromDate = moment().utc().subtract(lag, 'hours').startOf('day')
    return fromDate.format()
}

function daysAgo(days: number, fromDate?: string): Moment {
    return moment(fromDate).subtract(days, 'days').startOf('day')
}

export function dayRange(
    days: number,
    fromDate?: string,
    includeFromDay?: boolean,
    withLag?: boolean
): [Moment, Moment] {
    return [
        daysAgo(withLag ? days - 1 : days, fromDate),
        includeFromDay ? moment(fromDate).startOf('day') : daysAgo(1, fromDate),
    ]
}

function monthRange(months: number, fromDate?: string): Moment[] {
    return [
        moment(fromDate)
            .subtract(months, 'months')
            .startOf('month')
            .startOf('day'),
        moment(fromDate).subtract(1, 'month').endOf('month').startOf('day'),
    ]
}

function calendarRange(
    amount: number,
    unit: unitOfTime.DurationConstructor,
    fromDate?: string
): Moment[] {
    return [
        moment(fromDate).subtract(amount, unit).startOf(unit).startOf('day'),
        moment(fromDate)
            .subtract(Math.min(amount, 1), unit)
            .endOf(unit)
            .startOf('day'),
    ]
}

const getName = (name: ReactElement, subTitle?: string): ReactElement => {
    return (
        <>
            {name}
            {subTitle && (
                <span
                    style={{
                        display: 'block',
                        fontStyle: 'italic',
                        fontWeight: 500,
                        fontSize: '13px',
                        color: 'rgba(121, 124, 126, 0.60)',
                        marginTop: '-2px',
                    }}
                >
                    {subTitle}
                </span>
            )}
        </>
    )
}

const isLagInHour = (lagInHours: number | undefined | []): boolean => {
    if (
        lagInHours === undefined ||
        (isArray(lagInHours) && lagInHours.length === 0) ||
        lagInHours === 0
    ) {
        return false
    }
    return true
}

export const presetRanges = (
    fromDate?: string,
    lagInHours?: number
): PresetRangeMap => ({
    today: {
        name: getName(
            <>
                <Translation>
                    {(t) =>
                        t('filters:DateRangeFilter.presetRanges.today', 'Today')
                    }
                </Translation>
                <ToolTip
                    title="Today may contain incomplete data"
                    placement="bottom"
                >
                    <QuestionCircleOutlined style={{ marginLeft: '5px' }} />
                </ToolTip>
            </>
        ),
        range: [moment(fromDate).startOf('day'), moment(fromDate).endOf('day')],
    },
    yesterday: {
        name: getName(
            <Translation>
                {(t) =>
                    t(
                        'filters:DateRangeFilter.presetRanges.yesterday',
                        'Yesterday'
                    )
                }
            </Translation>
        ),
        range: dayRange(1, fromDate),
        isRecursive: true,
    },
    this_week: {
        name: getName(
            <Translation>
                {(t) =>
                    t(
                        'filters:DateRangeFilter.presetRanges.thisWeek',
                        'This Week'
                    )
                }
            </Translation>
        ),
        range: calendarRange(0, 'weeks', fromDate),
    },
    last_7_days: {
        name: getName(
            <Translation>
                {(t) =>
                    t(
                        'filters:DateRangeFilter.presetRanges.last7Days',
                        'Last 7 Days'
                    )
                }
            </Translation>
        ),
        range: dayRange(
            7,
            fromDate,
            isLagInHour(lagInHours),
            isLagInHour(lagInHours)
        ),
        isRecursive: true,
    },
    last_week: {
        name: getName(
            <Translation>
                {(t) =>
                    t(
                        'filters:DateRangeFilter.presetRanges.lastWeek',
                        'Last Week'
                    )
                }
            </Translation>
        ),
        range: calendarRange(1, 'week', fromDate),
        isRecursive: true,
    },
    last_2_weeks: {
        name: getName(
            <Translation>
                {(t) =>
                    t(
                        'filters:DateRangeFilter.presetRanges.last2Weeks',
                        'Last 2 Weeks'
                    )
                }
            </Translation>
        ),
        range: calendarRange(2, 'weeks', fromDate),
        isRecursive: true,
    },
    last_14_days: {
        name: getName(
            <Translation>
                {(t) =>
                    t(
                        'filters:DateRangeFilter.presetRanges.last14Days',
                        'Last 14 Days'
                    )
                }
            </Translation>
        ),
        range: dayRange(
            14,
            fromDate,
            isLagInHour(lagInHours),
            isLagInHour(lagInHours)
        ),
        isRecursive: true,
    },
    this_month: {
        name: getName(
            <Translation>
                {(t) =>
                    t(
                        'filters:DateRangeFilter.presetRanges.thisMonth',
                        'This Month'
                    )
                }
            </Translation>
        ),
        range: calendarRange(0, 'months', fromDate),
    },
    last_28_days: {
        name: getName(
            <Translation>
                {(t) =>
                    t(
                        'filters:DateRangeFilter.presetRanges.last28Days',
                        'Last 28 Days'
                    )
                }
            </Translation>
        ),
        range: dayRange(
            28,
            fromDate,
            isLagInHour(lagInHours),
            isLagInHour(lagInHours)
        ),
        isRecursive: true,
    },
    last_30_days: {
        name: getName(
            <Translation>
                {(t) =>
                    t(
                        'filters:DateRangeFilter.presetRanges.last30Days',
                        'Last 30 Days'
                    )
                }
            </Translation>
        ),
        range: dayRange(
            30,
            fromDate,
            isLagInHour(lagInHours),
            isLagInHour(lagInHours)
        ),
        isRecursive: true,
    },
    last_month: {
        name: getName(
            <Translation>
                {(t) =>
                    t(
                        'filters:DateRangeFilter.presetRanges.lastMonth',
                        'Last Month'
                    )
                }
            </Translation>
        ),
        range: calendarRange(1, 'month', fromDate),
        isRecursive: true,
    },
    this_quarter: {
        name: getName(
            <Translation>
                {(t) =>
                    t(
                        'filters:DateRangeFilter.presetRanges.thisQuarter',
                        'This Quarter'
                    )
                }
            </Translation>
        ),
        range: calendarRange(0, 'quarters', fromDate),
    },
    last_90_days: {
        name: getName(
            <Translation>
                {(t) =>
                    t(
                        'filters:DateRangeFilter.presetRanges.last90Days',
                        'Last 90 Days'
                    )
                }
            </Translation>
        ),
        range: dayRange(
            90,
            fromDate,
            isLagInHour(lagInHours),
            isLagInHour(lagInHours)
        ),
        isRecursive: true,
    },
    last_quarter: {
        name: getName(
            <Translation>
                {(t) =>
                    t(
                        'filters:DateRangeFilter.presetRanges.lastQuarter',
                        'Last Quarter'
                    )
                }
            </Translation>
        ),
        range: calendarRange(1, 'quarter', fromDate),
        isRecursive: true,
    },
    last_180_days: {
        name: getName(
            <Translation>
                {(t) =>
                    t(
                        'filters:DateRangeFilter.presetRanges.last180Days',
                        'Last 180 Days'
                    )
                }
            </Translation>
        ),
        range: dayRange(
            180,
            fromDate,
            isLagInHour(lagInHours),
            isLagInHour(lagInHours)
        ),
        isRecursive: true,
    },
    this_year: {
        name: getName(
            <Translation>
                {(t) =>
                    t(
                        'filters:DateRangeFilter.presetRanges.thisYear',
                        'Year {{year}}',
                        { year: moment(fromDate).year() }
                    )
                }
            </Translation>
        ),
        range: calendarRange(0, 'years', fromDate),
    },
    last_365_days: {
        name: getName(
            <Translation>
                {(t) =>
                    t(
                        'filters:DateRangeFilter.presetRanges.last365Days',
                        'Last 365 Days'
                    )
                }
            </Translation>
        ),
        range: dayRange(
            365,
            fromDate,
            isLagInHour(lagInHours),
            isLagInHour(lagInHours)
        ),
        isRecursive: true,
    },
    last_730_days: {
        name: getName(
            <Translation>
                {(t) =>
                    t(
                        'filters:DateRangeFilter.presetRanges.last730Days',
                        'Last 2 Years'
                    )
                }
            </Translation>
        ),
        range: dayRange(
            730,
            fromDate,
            isLagInHour(lagInHours),
            isLagInHour(lagInHours)
        ),
        isRecursive: true,
    },
    last_12_months: {
        name: getName(
            <Translation>
                {(t) =>
                    t(
                        'filters:DateRangeFilter.presetRanges.last12Months',
                        'Last 12 Months'
                    )
                }
            </Translation>
        ),
        range: monthRange(12, fromDate),
        isRecursive: true,
    },
    last_year: {
        name: getName(
            <Translation>
                {(t) =>
                    t(
                        'filters:DateRangeFilter.presetRanges.previousFullYear',
                        'Year {{year}}',
                        {
                            year: moment(fromDate).subtract(1, 'year').year(),
                        }
                    )
                }
            </Translation>
        ),
        range: calendarRange(1, 'year', fromDate),
        isRecursive: true,
    },
    last_2_years: {
        name: getName(
            <Translation>
                {(t) =>
                    t(
                        'filters:DateRangeFilter.presetRanges.previousFull2Years',
                        'Years {{year1}}–{{year2}}',
                        {
                            year1: moment(fromDate).subtract(2, 'years').year(),
                            year2: moment(fromDate).subtract(1, 'year').year(),
                        }
                    )
                }
            </Translation>
        ),
        range: calendarRange(2, 'years', fromDate),
        isRecursive: true,
    },
    last_7_days_to_date: {
        name: getName(
            <Translation>
                {(t) =>
                    t(
                        'filters:DateRangeFilter.presetRanges.last7DaysToDate',
                        'Last 7 Days | Today Included'
                    )
                }
            </Translation>
        ),
        range: dayRange(7, fromDate, true),
    },
    last_14_days_to_date: {
        name: getName(
            <Translation>
                {(t) =>
                    t(
                        'filters:DateRangeFilter.presetRanges.last14DaysToDate',
                        'Last 14 Days | Today Included'
                    )
                }
            </Translation>
        ),
        range: dayRange(14, fromDate, true),
    },
    last_28_days_to_date: {
        name: getName(
            <Translation>
                {(t) =>
                    t(
                        'filters:DateRangeFilter.presetRanges.last28DaysToDate',
                        'Last 28 Days | Today Included'
                    )
                }
            </Translation>
        ),
        range: dayRange(28, fromDate, true),
    },
    last_30_days_to_date: {
        name: getName(
            <Translation>
                {(t) =>
                    t(
                        'filters:DateRangeFilter.presetRanges.last30DaysToDate',
                        'Last 30 Days | Today Included'
                    )
                }
            </Translation>
        ),
        range: dayRange(30, fromDate, true),
    },
    last_90_days_to_date: {
        name: getName(
            <Translation>
                {(t) =>
                    t(
                        'filters:DateRangeFilter.presetRanges.last90DaysToDate',
                        'Last 90 Days | Today Included'
                    )
                }
            </Translation>
        ),
        range: dayRange(90, fromDate, true),
    },
    last_180_days_to_date: {
        name: getName(
            <Translation>
                {(t) =>
                    t(
                        'filters:DateRangeFilter.presetRanges.last180DaysToDate',
                        'Last 180 Days | Today Included'
                    )
                }
            </Translation>
        ),
        range: dayRange(180, fromDate, true),
    },
    month_to_date: {
        name: getName(
            <Translation>
                {(t) =>
                    t(
                        'filters:DateRangeFilter.presetRanges.monthToDate',
                        'Month-to-Date'
                    )
                }
            </Translation>
        ),
        range: [
            moment(fromDate).startOf('month'),
            moment(fromDate).startOf('day'),
        ],
        priorPeriodUnit: 'month',
    },
    quarter_to_date: {
        name: getName(
            <Translation>
                {(t) =>
                    t(
                        'filters:DateRangeFilter.presetRanges.quarterToDate',
                        'Quarter-to-Date'
                    )
                }
            </Translation>
        ),
        range: [
            moment(fromDate).startOf('quarter'),
            moment(fromDate).startOf('day'),
        ],
        priorPeriodUnit: 'quarter',
    },
    year_to_date: {
        name: getName(
            <Translation>
                {(t) =>
                    t(
                        'filters:DateRangeFilter.presetRanges.yearToDate',
                        'Year-to-Date'
                    )
                }
            </Translation>
        ),
        range: [
            moment(fromDate).startOf('year'),
            moment(fromDate).startOf('day'),
        ],
        priorPeriodUnit: 'year',
    },
})

function parallelPriorRange(
    dateRangeFilter: PresetRange,
    currentRangestart: Moment,
    currentRangeEnd: Moment,
    hasHourlyData = false
): string[] {
    const presetRange = presetRanges()[dateRangeFilter]
    const fromDate = presetRange.priorPeriodUnit
        ? currentRangeEnd
        : currentRangestart
    const unit = presetRange.priorPeriodUnit ?? 'day'
    const lastPeriodEnd = presetRange.isRecursive
        ? fromDate
        : fromDate.subtract(1, unit)
    const [min, max] = presetRanges(lastPeriodEnd.format('YYYY-MM-DD'))[
        dateRangeFilter
    ].range
    const formatter = hasHourlyData ? formatJSONDateTime : formatCerebroDate
    return [formatter(min), formatter(max)]
}

export const CONDENSED_PRESET_RANGE_ORDER = [
    'this_week',
    'last_week',
    'this_month',
    'last_month',
    'last_7_days',
    'last_14_days',
    'last_30_days',
    'this_year',
]

export function isParallelPriorPeriod(
    dateRangeFilter: DateRangeFilter
): boolean {
    return (
        isPresetDateRange(dateRangeFilter) &&
        Boolean(presetRanges()[dateRangeFilter].priorPeriodUnit)
    )
}

export function getDateRange(
    dateRange: DateRangeFilter | Moment[] | undefined,
    format: string,
    fromDate?: string,
    withoutPreset?: boolean,
    lagInHours?: number
): string | null | ReactElement {
    if (isUndefined(dateRange)) {
        return null
    }

    let range: string[]

    if (isArray(dateRange)) {
        range = dateRange
            .map((date: string | Moment) =>
                isMoment(date) ? date : moment(date, 'YYYY-MM-DD')
            )
            .map((momentDate: Moment) => momentDate.format(format))

        return range ? `${range[0]} – ${range[1]}` : null
    }

    const presetRange = presetRanges(fromDate, lagInHours)[dateRange]
    range = presetRange.range.map((date) => date.format(format))

    return (
        <>
            {withoutPreset ? '' : presetRange.name} ({range[0]} – {range[1]})
        </>
    )
}

export function getDateRangeArray(
    dateRange: DateRangeFilter,
    hasHourlyData?: boolean,
    rangeLag?: number
): Moment[] {
    const range = isArray(dateRange)
        ? dateRange.map((date: string | Moment) =>
              isMoment(date) ? date : moment(date)
          )
        : presetRanges(calculateFromDateWithLag(rangeLag), rangeLag)[dateRange]
              .range

    return hasHourlyData
        ? [range[0].startOf('day'), range[1].endOf('day')]
        : range
}

export function getPriorPeriodDateRange(
    min: string | Moment,
    max: string | Moment,
    periodDeltaType: PeriodDeltaType | undefined,
    dateRangeFilter: DateRangeFilter,
    hasHourlyData = false
): string[] {
    const minMoment = (isMoment(min) ? min : moment(min)).startOf('day')
    const maxMomentTmp = isMoment(max) ? max : moment(max)
    const maxMoment = hasHourlyData
        ? maxMomentTmp.endOf('day')
        : maxMomentTmp.startOf('day')

    if (periodDeltaType === 'prior_year') {
        return [
            formatCerebroDate(minMoment.subtract(1, 'year')),
            formatCerebroDate(maxMoment.subtract(1, 'year')),
        ]
    }

    if (
        periodDeltaType === 'prior_period' &&
        isPresetDateRange(dateRangeFilter)
    ) {
        return parallelPriorRange(
            dateRangeFilter,
            minMoment,
            maxMoment,
            hasHourlyData
        )
    }

    // prior period
    const diffInDays = maxMoment.diff(minMoment, 'days') + 1
    const formatter = hasHourlyData ? formatJSONDateTime : formatCerebroDate
    return [
        formatter(minMoment.subtract(diffInDays, 'days')),
        formatter(maxMoment.subtract(diffInDays, 'days')),
    ]
}

export function getFormattedDateRange(
    dateRange: DateRangeFilter | Moment[],
    fromDate?: string,
    withoutPreset?: boolean,
    lagInHours?: number
): string | null | ReactElement {
    const range: Moment[] =
        isArray(dateRange) && isMoment(dateRange[0])
            ? (dateRange as Moment[])
            : getDateRangeArray(dateRange as DateRangeFilter, false)

    const year1 = range[0].year()
    const year2 = range[1].year()
    const currentYear = moment().year()

    const format = year1 === year2 && year1 === currentYear ? 'M/D' : 'M/D/YY'

    return getDateRange(dateRange, format, fromDate, withoutPreset, lagInHours)
}

export const getDateRangeInDays = (
    rangeStart: string | Moment,
    rangeEnd: string | Moment
): number => {
    const startMoment = isMoment(rangeStart) ? rangeStart : moment(rangeStart)
    const endMoment = isMoment(rangeEnd) ? rangeEnd : moment(rangeEnd)

    return endMoment.diff(startMoment, 'days')
}

export const calculateSegmentLag = (): number => {
    const today = moment().utc().startOf('day')
    const nbOfDaysSinceLastSaturday = (today.day() + 1) % 7

    return nbOfDaysSinceLastSaturday * 24
}

export const twoSaturdaysAgoInHours = (): number => {
    const today = moment().utc().startOf('day')
    const nbOfDaysSinceLastSaturday = (today.day() + 1) % 7

    return (nbOfDaysSinceLastSaturday + 7) * 24
}

/**
 * Segments are refreshed every Monday and use data through the
 * previous Saturday. If this schedule changes, this function will
 * need to be updated to reflect the new schedule.
 */
export const calculateSegmentLagUsingLastRefresh = (
    last_refresh_at?: string
): number => {
    const today = moment().utc().startOf('day')
    const lastRefresh = moment(last_refresh_at).utc().startOf('day')
    const daysSinceLastRefresh = today.diff(lastRefresh, 'days')
    const daysSinceLastSaturday = (lastRefresh.day() + 1) % 7

    return (daysSinceLastRefresh + daysSinceLastSaturday) * 24
}
