import {
    advertiserIdRenderer,
    lineItemIdRenderer,
    lineItemLabelIdRenderer,
    orderIdRenderer,
    orderLabelIdRenderer,
} from 'components/FieldRenderers/Renderers'
import { brandId } from 'configuration/fieldCreators/brand'
import {
    advertiserId,
    lineItemId,
    lineItemLabelId,
    lineItemType,
    orderId,
    orderLabelId,
} from 'configuration/fieldCreators/dsp'
import { ctr, impressions__sum } from 'configuration/fieldCreators/metrics/ams'
import {
    amazon_audience_fee__sum,
    amazon_platform_fee__sum,
    atc_14_day__sum,
    atl_14_day__sum,
    brand_search_14_day__sum,
    click_throughs__sum,
    dpv_14_day__sum,
    dpvr_14_day,
    ecpdpv_14_day,
    ecpm,
    ecpvc,
    homepage_visit_14_day__sum,
    homepage_visit_clicks_14_day__sum,
    homepage_visit_cvr_14_day,
    homepage_visit_views_14_day__sum,
    new_subscribe_and_save_14_day__sum,
    new_to_brand_product_sales_14_day__sum,
    new_to_brand_purchases_14_day__sum,
    new_to_brand_purchases_clicks_14_day__sum,
    new_to_brand_purchases_views_14_day__sum,
    new_to_brand_units_sold_14_day__sum,
    percent_of_purchases_new_to_brand_14_day,
    purchase_rate_14_day,
    purchases_14_day__sum,
    purchases_clicks_14_day__sum,
    purchases_views_14_day__sum,
    roas_14_day,
    sales_14_day__sum,
    supply_cost__sum,
    thank_you_page_14_day__sum,
    thank_you_page_clicks_14_day__sum,
    thank_you_page_cvr_14_day,
    thank_you_page_views_14_day__sum,
    total_add_to_cart_14_day__sum,
    total_add_to_list_14_day__sum,
    total_cost__sum,
    total_detail_page_views_14_day__sum,
    total_dpvr_14_day,
    total_ecpdpv_14_day,
    total_new_to_brand_product_sales_14_day__sum,
    total_new_to_brand_purchases_14_day__sum,
    total_new_to_brand_purchases_clicks_14_day__sum,
    total_new_to_brand_purchases_views_14_day__sum,
    total_new_to_brand_units_sold_14_day__sum,
    total_purchase_rate_14_day,
    total_purchases_14_day__sum,
    total_purchases_clicks_14_day__sum,
    total_purchases_views_14_day__sum,
    total_roas_14_day,
    total_sales_14_day__sum,
    total_subscribe_and_save_subscriptions_14_day__sum,
    total_units_sold_14_day__sum,
    units_sold_14_day__sum,
    video_complete__sum,
    video_completion_rate,
    video_midpoint__sum,
    video_start__sum,
    video_third_quartile__sum,
    viewable_impressions__sum,
} from 'configuration/fieldCreators/metrics/dsp'
import { UNDEFINED_VALUE } from 'const/formatting'
import { titleCase } from 'helpers/formatting'
import {
    DspAdvertiserGroupBy,
    DspLineItemGroupBy,
    DspLineItemLabelGroupBy,
    DspOrderGroupBy,
    DspOrderLabelGroupBy,
} from 'types'

import { makeMapping } from './helpers'

const DSP_METRICS_COLUMNS = [
    total_cost__sum({ isVisible: true }),
    supply_cost__sum({ isVisible: true }),
    impressions__sum({ isVisible: true }),
    click_throughs__sum({ isVisible: true }),

    // purchases
    purchases_14_day__sum({ isVisible: true }),

    total_purchases_14_day__sum({ isVisible: true }),

    new_to_brand_purchases_14_day__sum({ isVisible: true }),

    total_new_to_brand_purchases_14_day__sum({ isVisible: true }),

    // units sold / sales
    units_sold_14_day__sum({ isVisible: true }),
    sales_14_day__sum({ isVisible: true }),
    total_units_sold_14_day__sum({ isVisible: true }),
    total_sales_14_day__sum({ isVisible: true }),
    new_to_brand_units_sold_14_day__sum({ isVisible: true }),
    new_to_brand_product_sales_14_day__sum({ isVisible: true }),
    total_new_to_brand_units_sold_14_day__sum({ isVisible: true }),
    total_new_to_brand_product_sales_14_day__sum({
        isVisible: true,
    }),
    atc_14_day__sum({ isVisible: true }),
    atl_14_day__sum({ isVisible: true }),
    new_subscribe_and_save_14_day__sum({ isVisible: true }),
    video_start__sum({ isVisible: true }),
    video_midpoint__sum({ isVisible: true }),
    video_third_quartile__sum({ isVisible: true }),
    video_complete__sum({ isVisible: true }),
    viewable_impressions__sum({ isVisible: true }),
    amazon_audience_fee__sum({ isVisible: true }),
    amazon_platform_fee__sum({ isVisible: true }),
    total_detail_page_views_14_day__sum({ isVisible: true }),
    thank_you_page_14_day__sum({ isVisible: true }),
    homepage_visit_14_day__sum({ isVisible: true }),
    total_subscribe_and_save_subscriptions_14_day__sum({
        isVisible: true,
    }),
    total_add_to_cart_14_day__sum({ isVisible: true }),
    total_add_to_list_14_day__sum({ isVisible: true }),
    // Ratio
    ctr({ isVisible: true }),
    ecpm({ isVisible: true }),
    dpv_14_day__sum({ isVisible: true }),
    dpvr_14_day({ isVisible: true }),
    ecpdpv_14_day({ isVisible: true }),
    total_dpvr_14_day({ isVisible: true }),
    total_ecpdpv_14_day({ isVisible: true }),
    purchase_rate_14_day({ isVisible: true }),
    total_purchase_rate_14_day({ isVisible: true }),
    percent_of_purchases_new_to_brand_14_day({ isVisible: true }),
    roas_14_day({ isVisible: true }),
    total_roas_14_day({ isVisible: true }),
    homepage_visit_cvr_14_day({ isVisible: true }),
    thank_you_page_cvr_14_day({ isVisible: true }),
    video_completion_rate({ isVisible: true }),
    ecpvc({ isVisible: true }),
    // Deprecated
    homepage_visit_views_14_day__sum({ isVisible: true }),
    homepage_visit_clicks_14_day__sum({ isVisible: true }),
    thank_you_page_clicks_14_day__sum({ isVisible: true }),
    thank_you_page_views_14_day__sum({ isVisible: true }),
    purchases_views_14_day__sum({ isVisible: true }),
    purchases_clicks_14_day__sum({ isVisible: true }),
    total_purchases_views_14_day__sum({ isVisible: true }),
    total_purchases_clicks_14_day__sum({ isVisible: true }),
    new_to_brand_purchases_views_14_day__sum({ isVisible: true }),
    new_to_brand_purchases_clicks_14_day__sum({ isVisible: true }),
    total_new_to_brand_purchases_views_14_day__sum({
        isVisible: true,
    }),
    total_new_to_brand_purchases_clicks_14_day__sum({
        isVisible: true,
    }),
    brand_search_14_day__sum({ isVisible: true }),
]

export const DSP_METRICS_COLUMNS_PAGES = [
    total_cost__sum({ isVisible: true }),
    supply_cost__sum({ isVisible: true }),
    impressions__sum({ isVisible: true }),
    click_throughs__sum({ isVisible: true }),

    // purchases
    purchases_14_day__sum({ isVisible: true }),
    total_purchases_14_day__sum(),
    new_to_brand_purchases_14_day__sum(),
    total_new_to_brand_purchases_14_day__sum(),

    // units sold / sales
    units_sold_14_day__sum(),
    sales_14_day__sum(),
    total_units_sold_14_day__sum(),
    total_sales_14_day__sum(),
    new_to_brand_units_sold_14_day__sum(),
    new_to_brand_product_sales_14_day__sum(),
    total_new_to_brand_units_sold_14_day__sum(),
    total_new_to_brand_product_sales_14_day__sum(),
    atc_14_day__sum(),
    atl_14_day__sum(),
    new_subscribe_and_save_14_day__sum(),
    video_start__sum(),
    video_midpoint__sum(),
    video_third_quartile__sum(),
    video_complete__sum(),
    viewable_impressions__sum(),
    amazon_audience_fee__sum(),
    amazon_platform_fee__sum(),
    total_detail_page_views_14_day__sum(),
    thank_you_page_14_day__sum(),
    homepage_visit_14_day__sum(),
    total_subscribe_and_save_subscriptions_14_day__sum(),
    total_add_to_cart_14_day__sum(),
    total_add_to_list_14_day__sum(),

    // Ratios
    ctr(),
    ecpm(),
    dpvr_14_day(),
    ecpdpv_14_day(),
    total_dpvr_14_day(),
    total_ecpdpv_14_day(),
    purchase_rate_14_day(),
    total_purchase_rate_14_day(),
    percent_of_purchases_new_to_brand_14_day(),
    roas_14_day(),
    total_roas_14_day(),
    homepage_visit_cvr_14_day(),
    thank_you_page_cvr_14_day(),
    video_completion_rate(),
    ecpvc(),

    // Deprecated
    purchases_views_14_day__sum(),
    purchases_clicks_14_day__sum(),
    total_purchases_views_14_day__sum(),
    total_purchases_clicks_14_day__sum(),
    new_to_brand_purchases_views_14_day__sum(),
    new_to_brand_purchases_clicks_14_day__sum(),
    total_new_to_brand_purchases_views_14_day__sum(),
    total_new_to_brand_purchases_clicks_14_day__sum(),
    homepage_visit_views_14_day__sum(),
    homepage_visit_clicks_14_day__sum(),
    thank_you_page_clicks_14_day__sum(),
    thank_you_page_views_14_day__sum(),
    brand_search_14_day__sum(),
]

export const DSP_METRICS_CONFIG = makeMapping(DSP_METRICS_COLUMNS)

const SPECIAL_DSP_GROUP_BY_COLUMNS = [
    brandId(),
    advertiserId<DspAdvertiserGroupBy>({
        renderOptions: { render: advertiserIdRenderer },
    }),
    orderId<DspOrderGroupBy>({
        renderOptions: { render: orderIdRenderer },
    }),

    orderLabelId<DspOrderLabelGroupBy>({
        renderOptions: { render: orderLabelIdRenderer },
    }),
    lineItemId<DspLineItemGroupBy>({
        renderOptions: { render: lineItemIdRenderer },
    }),
    lineItemType({
        dataIndex: ['line_item__line_item_type'],
        renderOptions: {
            render: ({ value }) => (value ? titleCase(value) : UNDEFINED_VALUE),
        },
    }),
    lineItemLabelId<DspLineItemLabelGroupBy>({
        renderOptions: { render: lineItemLabelIdRenderer },
    }),
]

export const SPECIAL_DSP_CAMPAIGN_GROUP_BY_COLUMNS = [
    ...SPECIAL_DSP_GROUP_BY_COLUMNS,
]

export const SPECIAL_DSP_INVENTORY_GROUP_BY_COLUMNS = [
    ...SPECIAL_DSP_GROUP_BY_COLUMNS,
]

export const SPECIAL_DSP_AUDIENCE_GROUP_BY_COLUMNS = [
    ...SPECIAL_DSP_GROUP_BY_COLUMNS,
]
