import { memo, ReactElement } from 'react'

import isEqual from 'lodash/isEqual'

import { useResource } from 'components/EditableFields/localHooks'
import { updateCampaign } from 'services/cerebroApi/orgScope/resourceApi'
import { CampaignGroupBy } from 'types'

import { DeleteField } from '../../SharedFields/DeleteField'

interface Props<RecordType> {
    dataIndex: string[]
    readOnly: boolean
    record: RecordType
    reloadData: () => void
}

function RemoveFromPortfolioField<RecordType extends CampaignGroupBy>({
    dataIndex = ['campaign'],
    readOnly = false,
    record,
    reloadData,
}: Props<RecordType>): ReactElement {
    const { getFromResource, resourceId } = useResource<RecordType>(
        record,
        dataIndex,
        'campaign'
    )

    const getIsDisabled = (): boolean => {
        const userCanManageCampaign = getFromResource(['user_can_manage'], true)
        return !userCanManageCampaign || readOnly
    }

    return (
        <DeleteField
            disabled={getIsDisabled()}
            okText="Remove"
            title="Are you sure you want to remove this Campaign?"
            updateRequestApi={updateCampaign}
            updateRequestCallback={reloadData}
            updateRequestData={resourceId}
            updateRequestParams={{ portfolio_id: null }}
            updateRequestSuccessMesg="Campaign successfully removed"
        />
    )
}

export default memo(
    RemoveFromPortfolioField,
    isEqual
) as typeof RemoveFromPortfolioField
