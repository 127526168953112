import { combineChildrenReducers } from 'utilities/reducers'

import changeSetsReducer from './changeSets'
import pageReducer from './page'
import rulesReducer from './rules'

export default combineChildrenReducers(pageReducer, {
    changeSets: changeSetsReducer,
    rules: rulesReducer,
})
