import { ReactElement } from 'react'

import Icon from '@ant-design/icons'

import ReportIconSvg from './ReportIconSvg'
import { IconProps } from '../localTypes'

const ReportIcon = (props: IconProps): ReactElement => (
    <Icon component={ReportIconSvg} {...props} />
)

export default ReportIcon
