import { ReactElement } from 'react'

import classNames from 'classnames'

import styles from './styles.scss'

export default function CreateAdGroupNegativeKeywordFormheader(): ReactElement {
    return (
        <div className="d-flex align-items-start">
            <div style={{ flex: 1 }} className="mt-2 mr-1 fg-control-label">
                Keyword
            </div>
            <div
                className={classNames(
                    'mt-2 mr-1 fg-control-label',
                    styles['header-label'],
                    styles['match-type-column']
                )}
                style={{
                    textAlign: 'center',
                }}
            >
                Match Type
            </div>

            <div
                className={classNames(
                    'mt-2 mr-1 fg-control-label',
                    styles['header-label'],
                    styles['remove-column']
                )}
                style={{
                    textAlign: 'center',
                }}
            >
                Remove
            </div>
        </div>
    )
}
