import { call, select, put } from 'redux-saga/effects'

import { makeFetchTableRequest, makeFetchTableSuccess } from 'actions/ui/shared'
import { DASHBOARDS_SUMMARY_PAGE } from 'const/pages'
import { formatFilters } from 'helpers/filters/dashboards'
import { formatSorter, formatPagination } from 'helpers/params'
import { cerebroApiSaga } from 'sagas/common'
import uiSagaRegistry from 'sagas/ui/registry'
import { selectVisibleCombinedFilters, selectTableSettings } from 'selectors/ui'
import { getDashboards } from 'services/cerebroApi/orgScope/resourceApi'

const TAB_PATH = [DASHBOARDS_SUMMARY_PAGE, 'allDashboards']
const TABLE_PATH = [...TAB_PATH, 'table']

function* fetchTableSaga(noCount) {
    const filters = yield select(selectVisibleCombinedFilters, TAB_PATH)
    const { pagination, sorter } = yield select(selectTableSettings, TABLE_PATH)
    const params = {
        ...formatFilters(filters),
        ...formatSorter(sorter),
        ...formatPagination(pagination),
    }
    yield call(
        cerebroApiSaga,
        makeFetchTableSuccess(TABLE_PATH),
        getDashboards,
        params,
        {
            headers: { noCount },
        }
    )
}

function* fetchTabDataSaga() {
    yield put(makeFetchTableRequest(TABLE_PATH)())
}

// Register Sagas
uiSagaRegistry.registerSagas(TAB_PATH, {
    fetchTabDataSaga,
})

uiSagaRegistry.registerSagas(TABLE_PATH, {
    fetchTableSaga,
})
