import { ReactElement } from 'react'

import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import pluralize from 'pluralize'
import { TFunction } from 'react-i18next'

import { InfoItem } from 'components/InfoRow/InfoRow'
import { DATA_SOURCES } from 'configuration/widgets'
import { WidgetDataSourceKey } from 'const/dataSources'
import {
    ADVANCED_AUTOMATIONS,
    ADVANCED_PRODUCT_TITLE,
    ADVANCED_TEXT,
    COUNTRIES,
    DASHBOARD_NAME,
    DATES,
    GREATER_THAN_OR_EQUAL,
    HierarchicalFilterState,
    LABELS,
    LESS_THAN_OR_EQUAL,
    RANGE_LAG,
    RANK_BETTER_THAN_OR_EQUAL,
    RANK_WORSE_THAN_OR_EQUAL,
    RANK_WORSE_THAN,
    RESOURCE_NAME_BY_FILTER_KEY,
    SEARCH_TERM,
    SOV_COUNTRIES,
    SOV_RANK,
} from 'const/filters'
import {
    calculateFromDateWithLag,
    getFormattedDateRange,
} from 'helpers/dateRange'
import { getBooleanFilterLabel } from 'helpers/filters'
import { isLabelValue, isMetricValue } from 'helpers/typeGuard'
import { defaultDatesFilter } from 'reducers/ui/defaults'
import {
    DashboardParams,
    DateRangeFilter,
    FilterHierarchy,
    FilterOption,
    FiltersHierarchy,
    FiltersState,
    FilterValue,
    WidgetFilterMap,
} from 'types'

export const formatFilters = (filters: FiltersState): DashboardParams => {
    const params: DashboardParams = {}

    if (!isEmpty(filters[DASHBOARD_NAME])) {
        params.name__icontains = filters[DASHBOARD_NAME]
    }

    return params
}

export const getFiltersHierarchy = (
    tabLevelFilters: WidgetFilterMap,
    widgetLevelFilters: WidgetFilterMap,
    widgetDataSource: WidgetDataSourceKey
): FiltersHierarchy => {
    // Parameter is cloned to avoid side effects.
    // We always consider the date range filter even when it is not explicitly in the state.
    const tabFilters = cloneDeep(tabLevelFilters)
    tabFilters[DATES] = tabFilters[DATES] || defaultDatesFilter
    if (widgetDataSource === 'sov_search_results' && tabFilters[COUNTRIES]) {
        tabFilters[SOV_COUNTRIES] = tabFilters[COUNTRIES]
        delete tabFilters[COUNTRIES]
    }

    const hierarchy = {
        tabLevelFilters: {},
        widgetLevelFilters: {},
        result: {},
    } as FiltersHierarchy

    const applicableFilters = DATA_SOURCES[widgetDataSource]?.filters
    const {
        APPLIED,
        APPLIED_BY_TAB,
        APPLIED_BY_WIDGET,
        NOT_APPLICABLE,
        OVERRIDDEN_BY_WIDGET,
        OVERRIDES_TAB_VALUES,
    } = HierarchicalFilterState

    Object.keys(tabFilters).forEach((filterKey) => {
        if (
            tabFilters[filterKey]?.length ||
            (filterKey === RANGE_LAG && Number.isInteger(tabFilters.rangeLag))
        ) {
            hierarchy.tabLevelFilters[filterKey] = {
                state: NOT_APPLICABLE,
                values: tabFilters[filterKey],
            }

            if (applicableFilters?.includes(filterKey)) {
                hierarchy.tabLevelFilters[filterKey].state = APPLIED
                hierarchy.result[filterKey] = {
                    state: APPLIED_BY_TAB,
                    values: tabFilters[filterKey],
                }
            }
        }
    })

    Object.keys(widgetLevelFilters).forEach((filterKey) => {
        if (
            widgetLevelFilters[filterKey]?.length ||
            (filterKey === RANGE_LAG && widgetLevelFilters[DATES]?.length)
        ) {
            hierarchy.widgetLevelFilters[filterKey] = {
                state: APPLIED,
                values: widgetLevelFilters[filterKey],
            }
            hierarchy.result[filterKey] = {
                state: APPLIED_BY_WIDGET,
                values: widgetLevelFilters[filterKey],
            }

            if (hierarchy.tabLevelFilters[filterKey]) {
                hierarchy.tabLevelFilters[filterKey].state =
                    OVERRIDDEN_BY_WIDGET
                hierarchy.widgetLevelFilters[filterKey].state =
                    OVERRIDES_TAB_VALUES
                hierarchy.result[filterKey].state = OVERRIDDEN_BY_WIDGET
            }
        }
    })

    return hierarchy
}

export const formatFilterLabel = (
    key: string,
    values: FilterValue,
    t: TFunction,
    rangeLag?: number
): string | ReactElement => {
    const resourceName = RESOURCE_NAME_BY_FILTER_KEY[key] || key

    if (key === DATES) {
        const formattedDateRange = getFormattedDateRange(
            values as DateRangeFilter,
            calculateFromDateWithLag(rangeLag),
            false,
            rangeLag
        )

        return formattedDateRange ?? ''
    }

    if (key === SEARCH_TERM) {
        return `${resourceName}: ${(values[0] as FilterOption).value}`
    }

    if (
        [
            ADVANCED_AUTOMATIONS,
            ADVANCED_PRODUCT_TITLE,
            ADVANCED_TEXT,
            LABELS,
        ].includes(key)
    ) {
        return getBooleanFilterLabel(
            JSON.parse((values[0] as FilterOption).value as string),
            null,
            resourceName,
            'Rules',
            key !== LABELS
        )
    }

    if (values.length === 1) {
        if (key === SOV_RANK) {
            const rankFilter = values[0] as FilterOption
            let operator = ''
            if (rankFilter.operator) {
                if (
                    [
                        GREATER_THAN_OR_EQUAL,
                        RANK_WORSE_THAN_OR_EQUAL,
                        RANK_WORSE_THAN,
                    ].includes(rankFilter.operator)
                ) {
                    operator = t(
                        'filters:NumberRangeControl.operatorLabels.rankWorseThan',
                        'Not in the top'
                    )
                } else if (
                    [LESS_THAN_OR_EQUAL, RANK_BETTER_THAN_OR_EQUAL].includes(
                        rankFilter.operator
                    )
                ) {
                    operator = t(
                        'filters:NumberRangeControl.operatorLabels.rankBetterThan',
                        'In the top'
                    )
                }
            }
            return `${resourceName}: ${operator} ${rankFilter.value}`
        }

        let theValue: unknown = values[0]
        if (isLabelValue(values[0])) {
            theValue = values[0].label
        } else if (isMetricValue(values[0])) {
            theValue = values[0].value
        }

        return `${resourceName}: ${theValue}`
    }

    if (values.length > 1) {
        return `${pluralize(resourceName)}: ${values.length}`
    }

    return resourceName
}

export const formatFilterHierarchy = (
    hierarchy: FilterHierarchy,
    overrideText: string,
    t: TFunction,
    notApplicableText?: string
): InfoItem[] => {
    const filterKeys = Object.keys(hierarchy)

    // The date range filter is supposed to always be the first one shown
    const dateIndex = filterKeys.indexOf(DATES)
    if (dateIndex > 0) {
        const dateItem = filterKeys.splice(dateIndex, 1)[0]
        filterKeys.unshift(dateItem)
    }

    const rangeLagIdx = filterKeys.indexOf(RANGE_LAG)
    let rangeLag = 0
    if (rangeLagIdx > 0) {
        filterKeys.splice(rangeLagIdx, 1)
        rangeLag = parseInt(hierarchy.rangeLag.values as string, 10)
    }

    return filterKeys.map((key) => {
        const filter = hierarchy[key]

        let hint
        if (filter.state === HierarchicalFilterState.OVERRIDDEN_BY_WIDGET) {
            hint = overrideText
        } else if (filter.state === HierarchicalFilterState.NOT_APPLICABLE) {
            hint = notApplicableText
        }

        return {
            state: filter.state,
            label: formatFilterLabel(
                key,
                filter.values,
                t,
                key === DATES && rangeLag ? rangeLag : undefined
            ),
            hint,
        }
    })
}
