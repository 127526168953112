import { handleActions } from 'redux-actions'

import { defaultTab, getDefaultList } from 'reducers/ui/defaults'

const initialState = {
    ...defaultTab,
    list: getDefaultList({
        sorter: {
            field: 'created_at',
            order: 'descend',
        },
    }),
}

export default handleActions({}, initialState)
