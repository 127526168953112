import { combineChildrenReducers } from 'utilities/reducers'

// Reducer that handles page level data, that is, parent reducer

// Children reducers
import ordersReducer from './orders'
import pageReducer from './page'

export default combineChildrenReducers(pageReducer, {
    orders: ordersReducer,
})
