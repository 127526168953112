import { all, takeLatest } from 'redux-saga/effects'

import {
    changeCurrencyCode,
    createBrandGroupRequest,
    deleteBrandGroupRequest,
    dismissUserSurveyRequest,
    fetchCustomEventsRequest,
    fetchOrganizationLabelsRequest,
    fetchReportsForDrawerRequest,
    fetchTriggeredAlertsForDrawerRequest,
    fetchUpdatesForDrawerRequest,
    mountAppRequest,
    setHiddenAutomationCapabilities,
    setHomeDashboardId,
    updateBrandGroupRequest,
} from 'actions/ui/app'

import {
    changeCurrencyCodeWorker,
    createBrandGroupWorker,
    deleteBrandGroupWorker,
    fetchCustomEventsSaga,
    fetchOrganizationLabelsSaga,
    fetchReportsForDrawerRequestWorker,
    fetchTriggeredAlertsForDrawerRequestWorker,
    fetchUpdatesForDrawerRequestWorker,
    mountAppWorker,
    setFeedbackSurveyLastReadDateRequestWorker,
    setHiddenAutomationCapabilitiesWorker,
    setHomeDashboardIdWorker,
    updateBrandGroupWorker,
} from './workers'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export default function* appWatcher() {
    yield all([
        takeLatest(mountAppRequest.toString(), mountAppWorker),

        takeLatest(changeCurrencyCode.toString(), changeCurrencyCodeWorker),
        takeLatest(setHomeDashboardId.toString(), setHomeDashboardIdWorker),
        takeLatest(
            fetchReportsForDrawerRequest.toString(),
            fetchReportsForDrawerRequestWorker
        ),
        takeLatest(
            fetchUpdatesForDrawerRequest.toString(),
            fetchUpdatesForDrawerRequestWorker
        ),
        takeLatest(
            fetchTriggeredAlertsForDrawerRequest.toString(),
            fetchTriggeredAlertsForDrawerRequestWorker
        ),
        takeLatest(
            setHiddenAutomationCapabilities.toString(),
            setHiddenAutomationCapabilitiesWorker
        ),

        takeLatest(createBrandGroupRequest.toString(), createBrandGroupWorker),
        takeLatest(updateBrandGroupRequest.toString(), updateBrandGroupWorker),
        takeLatest(deleteBrandGroupRequest.toString(), deleteBrandGroupWorker),

        takeLatest(fetchCustomEventsRequest.toString(), fetchCustomEventsSaga),
        takeLatest(
            fetchOrganizationLabelsRequest.toString(),
            fetchOrganizationLabelsSaga
        ),

        takeLatest(
            dismissUserSurveyRequest.toString(),
            setFeedbackSurveyLastReadDateRequestWorker
        ),
    ])
}
