import { memo, ReactElement } from 'react'

import isEqual from 'lodash/isEqual'
import pick from 'lodash/pick'

import { useResource } from 'components/EditableFields/localHooks'
import { KEYWORD_STATES, PAUSED, ENABLED } from 'const/resourceStates'
import { updateKeyword } from 'services/cerebroApi/orgScope/resourceApi'
import { KeywordFact } from 'types'

import { StateField } from '../../SharedFields/StateField'

type KeywordRecord = KeywordFact

interface Props<RecordType> {
    dataIndex: string[]
    readOnly: boolean
    record: RecordType
}

function KeywordStateField<RecordType extends KeywordRecord>({
    dataIndex = ['keyword', 'state'],
    readOnly = false,
    record,
}: Props<RecordType>): ReactElement {
    const { fieldName, getFromResource, resourceId } = useResource(
        record,
        dataIndex,
        'keyword'
    )

    const AVAILABLE_OPTIONS = pick(KEYWORD_STATES, [PAUSED, ENABLED])

    const getIsDisabled = (): boolean => {
        const userCanManageKeyword = getFromResource(['user_can_manage'], true)
        return !userCanManageKeyword
    }

    return (
        <StateField
            disabled={getIsDisabled()}
            fieldName={fieldName}
            fieldPath={dataIndex}
            formatFieldValue={(value) => {
                const state = value[fieldName]
                return KEYWORD_STATES[state]
            }}
            options={AVAILABLE_OPTIONS}
            placeholder="Keyword State"
            readOnly={readOnly}
            record={record}
            updateRequestApi={updateKeyword}
            updateRequestData={resourceId}
            updateRequestSuccessMesg="Successfully updated keyword state"
        />
    )
}

export default memo(KeywordStateField, isEqual) as typeof KeywordStateField
