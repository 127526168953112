import { call, select, put } from 'redux-saga/effects'

import { makeFetchTableSuccess, makeFetchTableRequest } from 'actions/ui/shared'
import { ORGANIZATION_PAGE } from 'const/pages'
import { formatPagination, formatSorter } from 'helpers/params'
import { cerebroApiSaga } from 'sagas/common'
import uiSagaRegistry from 'sagas/ui/registry'
import { selectDomainValue as selectAuthDomainValue } from 'selectors/auth'
import { selectTableSettings } from 'selectors/ui'
import { getOrganizationInvitations } from 'services/cerebroApi/orgScope/resourceApi'

const TAB_PATH = [ORGANIZATION_PAGE, 'invitations']
const TABLE_PATH = [...TAB_PATH, 'table']

function* fetchTableSaga() {
    const organizationId = yield select(selectAuthDomainValue, 'organizationId')
    const { pagination, sorter } = yield select(selectTableSettings, TABLE_PATH)
    const params = {
        ...formatPagination(pagination),
        ...formatSorter(sorter),
        'state!': 'revoked',
    }

    yield call(
        cerebroApiSaga,
        makeFetchTableSuccess(TABLE_PATH),
        getOrganizationInvitations,
        organizationId,
        params
    )
}

function* fetchTabDataSaga() {
    yield put(makeFetchTableRequest(TABLE_PATH)())
}

// Register Sagas
uiSagaRegistry.registerSagas(TAB_PATH, {
    fetchTabDataSaga,
})

uiSagaRegistry.registerSagas(TABLE_PATH, {
    fetchTableSaga,
})
