import { ReactElement } from 'react'

import { Translation } from 'react-i18next'

import {
    AutomationIcon,
    DashboardIcon,
    HomeIcon,
    KeywordIcon,
    LabelIcon,
    ScheduleIcon,
    SegmentsIcon,
    ShelfIntelligenceIcon,
} from 'components/Icons'
import {
    AD_ACCOUNTS_SUMMARY_PAGE,
    AUTOMATION_PAGE,
    CAMPAIGNS_SUMMARY_PAGE,
    DASHBOARDS_SUMMARY_PAGE,
    DSP_SUMMARY_PAGE,
    EVENTS_SUMMARY_PAGE,
    HOME_PAGE,
    KEYWORD_RESEARCH_PAGE,
    LABELS_SUMMARY_PAGE,
    PORTFOLIOS_SUMMARY_PAGE,
    PRODUCTS_SUMMARY_PAGE,
    SEGMENTS_SUMMARY_PAGE,
    SOV_KEYWORDS_SUMMARY_PAGE,
    TARGETING_SUMMARY_PAGE,
    WALMART_CAMPAIGNS_SUMMARY_PAGE,
    WALMART_ITEMS_SUMMARY_PAGE,
    WALMART_KEYWORDS_SUMMARY_PAGE,
} from 'const/pages'
import {
    userHasAmazonAdvertisingPermissions,
    userHasDashboardsOnlyPermissions,
    userHasReadOrManageSegmentPermissions,
    userHasSovReadPermissions,
    userHasWalmartAdvertisingReadPermissions,
} from 'helpers/featurePermissions'
import { getPath } from 'helpers/pages'
import { dashboardsUrl } from 'helpers/urls'
import { UserPermission } from 'types'

import { SHARED_WITH_ME } from './dashboards'

interface SubMenuNavItem {
    page: string
    getPath(featurePermissions?: UserPermission[]): string
    title: string | ReactElement
}
interface NavItem extends SubMenuNavItem {
    icon: ReactElement
    visible(featurePermissions?: UserPermission[]): boolean
}

export const TOP_LEVEL_ITEMS: NavItem[] = [
    {
        page: HOME_PAGE,
        getPath: () => getPath(HOME_PAGE),
        title: (
            <Translation>
                {(t) => t('navigation:SideMenu.home', 'Home')}
            </Translation>
        ),
        icon: <HomeIcon />,
        visible: (featurePermissions: UserPermission[]) =>
            !userHasDashboardsOnlyPermissions(featurePermissions),
    },
    {
        page: DASHBOARDS_SUMMARY_PAGE,
        getPath: (featurePermissions: UserPermission[]) =>
            userHasDashboardsOnlyPermissions(featurePermissions)
                ? dashboardsUrl(SHARED_WITH_ME)
                : getPath(DASHBOARDS_SUMMARY_PAGE),
        title: (
            <Translation>
                {(t) => t('common:dashboards', 'Dashboards')}
            </Translation>
        ),
        icon: <DashboardIcon />,
        visible: () => true,
    },
    {
        page: AUTOMATION_PAGE,
        getPath: () => getPath(AUTOMATION_PAGE),
        title: (
            <Translation>
                {(t) => t('common:automation', 'Automation')}
            </Translation>
        ),
        icon: <AutomationIcon />,
        visible: (featurePermissions: UserPermission[]) =>
            !userHasDashboardsOnlyPermissions(featurePermissions) &&
            (userHasAmazonAdvertisingPermissions(featurePermissions) ||
                userHasWalmartAdvertisingReadPermissions(featurePermissions)),
    },
    {
        page: SOV_KEYWORDS_SUMMARY_PAGE,
        getPath: () => getPath(SOV_KEYWORDS_SUMMARY_PAGE),
        title: (
            <Translation>
                {(t) =>
                    t(
                        'navigation:SideMenu.shelfIntelligence',
                        'Shelf Intelligence'
                    )
                }
            </Translation>
        ),
        icon: <ShelfIntelligenceIcon />,
        visible: (featurePermissions: UserPermission[]) =>
            !userHasDashboardsOnlyPermissions(featurePermissions) &&
            userHasSovReadPermissions(featurePermissions),
    },
    {
        page: KEYWORD_RESEARCH_PAGE,
        getPath: () => getPath(KEYWORD_RESEARCH_PAGE),
        title: (
            <Translation>
                {(t) =>
                    t('navigation:SideMenu.keywordResearch', 'Keyword Research')
                }
            </Translation>
        ),
        icon: <KeywordIcon />,
        visible: (featurePermissions: UserPermission[]) =>
            !userHasDashboardsOnlyPermissions(featurePermissions) &&
            userHasSovReadPermissions(featurePermissions),
    },
    {
        page: SEGMENTS_SUMMARY_PAGE,
        getPath: () => getPath(SEGMENTS_SUMMARY_PAGE),
        title: (
            <Translation>{(t) => t('common:segments', 'Segments')}</Translation>
        ),
        icon: <SegmentsIcon />,
        visible: (featurePermissions: UserPermission[]) =>
            userHasReadOrManageSegmentPermissions(featurePermissions),
    },
    {
        page: LABELS_SUMMARY_PAGE,
        getPath: () => getPath(LABELS_SUMMARY_PAGE),
        title: <Translation>{(t) => t('common:labels', 'Labels')}</Translation>,
        icon: <LabelIcon />,
        visible: (featurePermissions: UserPermission[]) =>
            !userHasDashboardsOnlyPermissions(featurePermissions),
    },
    {
        page: EVENTS_SUMMARY_PAGE,
        getPath: () => getPath(EVENTS_SUMMARY_PAGE),
        title: <Translation>{(t) => t('common:events', 'Events')}</Translation>,
        icon: <ScheduleIcon />,
        visible: (featurePermissions: UserPermission[]) =>
            !userHasDashboardsOnlyPermissions(featurePermissions),
    },
]

export const AMAZON_ITEMS: SubMenuNavItem[] = [
    {
        page: AD_ACCOUNTS_SUMMARY_PAGE,
        getPath: () => getPath(AD_ACCOUNTS_SUMMARY_PAGE),
        title: (
            <Translation>
                {(t) => t('common:advertisingAccounts', 'Advertising Accounts')}
            </Translation>
        ),
    },
    {
        page: PORTFOLIOS_SUMMARY_PAGE,
        getPath: () => getPath(PORTFOLIOS_SUMMARY_PAGE),
        title: (
            <Translation>
                {(t) => t('common:portfolios', 'Portfolios')}
            </Translation>
        ),
    },
    {
        page: CAMPAIGNS_SUMMARY_PAGE,
        getPath: () => getPath(CAMPAIGNS_SUMMARY_PAGE),
        title: (
            <Translation>
                {(t) => t('common:campaigns', 'Campaigns')}
            </Translation>
        ),
    },
    {
        page: PRODUCTS_SUMMARY_PAGE,
        getPath: () => getPath(PRODUCTS_SUMMARY_PAGE),
        title: (
            <Translation>
                {(t) => t('common:advertisedProducts', 'Advertised Products')}
            </Translation>
        ),
    },
    {
        page: TARGETING_SUMMARY_PAGE,
        getPath: () => getPath(TARGETING_SUMMARY_PAGE),
        title: (
            <Translation>
                {(t) => t('common:targeting', 'Targeting')}
            </Translation>
        ),
    },
    {
        page: DSP_SUMMARY_PAGE,
        getPath: () => getPath(DSP_SUMMARY_PAGE),
        title: <Translation>{(t) => t('common:dsp', 'DSP')}</Translation>,
    },
]

export const WALMART_ITEMS: SubMenuNavItem[] = [
    {
        page: WALMART_CAMPAIGNS_SUMMARY_PAGE,
        getPath: () => getPath(WALMART_CAMPAIGNS_SUMMARY_PAGE),
        title: (
            <Translation>
                {(t) => t('common:campaigns', 'Campaigns')}
            </Translation>
        ),
    },
    {
        page: WALMART_ITEMS_SUMMARY_PAGE,
        getPath: () => getPath(WALMART_ITEMS_SUMMARY_PAGE),
        title: <Translation>{(t) => t('common:items', 'Items')}</Translation>,
    },
    {
        page: WALMART_KEYWORDS_SUMMARY_PAGE,
        getPath: () => getPath(WALMART_KEYWORDS_SUMMARY_PAGE),
        title: (
            <Translation>{(t) => t('common:keywords', 'Keywords')}</Translation>
        ),
    },
]
