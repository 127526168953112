import { call, select, put } from 'redux-saga/effects'

import {
    makeFetchTableRequest,
    makeFetchTableSuccess,
    makeDownloadTableSuccess,
} from 'actions/ui/shared'
import { brandsSummaryTableColumnsConfig } from 'configuration/tables'
import { DATES } from 'const/filters'
import { AD_ACCOUNTS_SUMMARY_PAGE } from 'const/pages'
import { formatFilters } from 'helpers/filters/profileFacts'
import { generateReportNotification } from 'helpers/notifications'
import {
    formatSorter,
    formatMetrics,
    formatPagination,
    formatCurrency,
    formatColumns,
    formatPeriodDeltaDateRange,
} from 'helpers/params'
import { cerebroApiSaga } from 'sagas/common'
import uiSagaRegistry from 'sagas/ui/registry'
import {
    selectVisibleCombinedFilters,
    selectVisibleMetricsOfTable,
    selectVisibleColumnsOfTable,
    selectCurrencyCode,
    selectTableSettings,
} from 'selectors/ui'
import {
    getProfileFacts,
    getProfileFactsExport,
} from 'services/cerebroApi/orgScope/profileFactsApi'

const TABLE_PATH = [AD_ACCOUNTS_SUMMARY_PAGE, 'table']

function* fetchTableSaga(noCount) {
    const filters = yield select(selectVisibleCombinedFilters, [
        AD_ACCOUNTS_SUMMARY_PAGE,
    ])
    const {
        pagination,
        sorter,
        showPeriodDeltas,
        periodDeltaType,
        periodDeltaDateRange,
        showTotalRow,
    } = yield select(selectTableSettings, TABLE_PATH)
    const metrics = yield select(
        selectVisibleMetricsOfTable,
        TABLE_PATH,
        brandsSummaryTableColumnsConfig
    )
    const currency = yield select(selectCurrencyCode)
    const params = {
        ...formatFilters(filters),
        ...formatSorter(sorter),
        ...formatPagination(pagination),
        ...formatMetrics(metrics, showPeriodDeltas, showTotalRow),
        ...formatCurrency(currency),
        ...formatPeriodDeltaDateRange(
            showPeriodDeltas,
            periodDeltaType,
            periodDeltaDateRange,
            filters[DATES]
        ),
        group_by: 'profile_id',
    }
    yield call(
        cerebroApiSaga,
        makeFetchTableSuccess(TABLE_PATH),
        getProfileFacts,
        params,
        {
            headers: { noCount },
        }
    )
}

function* downloadTableSaga() {
    const filters = yield select(selectVisibleCombinedFilters, [
        AD_ACCOUNTS_SUMMARY_PAGE,
    ])
    const { sorter, showPeriodDeltas, periodDeltaType, periodDeltaDateRange } =
        yield select(selectTableSettings, TABLE_PATH)
    const columns = yield select(
        selectVisibleColumnsOfTable,
        TABLE_PATH,
        brandsSummaryTableColumnsConfig
    )
    const currency = yield select(selectCurrencyCode)
    const reportName = 'All Ad Accounts Report'
    const params = {
        ...formatFilters(filters),
        ...formatSorter(sorter),
        ...formatColumns(columns, showPeriodDeltas),
        ...formatCurrency(currency),
        ...formatPeriodDeltaDateRange(
            showPeriodDeltas,
            periodDeltaType,
            periodDeltaDateRange,
            filters[DATES]
        ),
        group_by: 'profile_id',
        async_download_name: reportName,
    }
    yield call(
        cerebroApiSaga,
        makeDownloadTableSuccess(TABLE_PATH),
        getProfileFactsExport,
        params
    )

    generateReportNotification(reportName)
}

function* fetchPageDataSaga() {
    yield put(makeFetchTableRequest(TABLE_PATH)())
}

/**
 * Mounts the Brands Summary Page and fetches data
 */
function* mountPageSaga() {
    yield call(fetchPageDataSaga)
}

uiSagaRegistry.registerSagas([AD_ACCOUNTS_SUMMARY_PAGE], {
    mountPageSaga,
    fetchPageDataSaga,
})
uiSagaRegistry.registerSagas(TABLE_PATH, {
    fetchTableSaga,
    downloadTableSaga,
})
