import {
    negativeKeywordIdRenderer,
    archiveCampaignNegativeKeywordRenderer,
    archiveAdGroupNegativeKeywordRenderer,
} from 'components/FieldRenderers'
import {
    archiveNegativeKeyword,
    negativeKeywordId,
    negativeKeywordMatchType,
    negativeKeywordState,
} from 'configuration/fieldCreators/negativeKeyword'
import { NegativeKeyword, Field } from 'types'

export const campaignNegativeKeywordsTableColumnsConfig: Field<NegativeKeyword>[] =
    [
        negativeKeywordId({
            fixed: 'left',
            sorter: true,
            renderOptions: {
                render: (props) => negativeKeywordIdRenderer(props),
            },
        }),
        negativeKeywordMatchType({ sorter: true }),
        negativeKeywordState(),
        archiveNegativeKeyword({
            renderOptions: {
                render: (props) =>
                    archiveCampaignNegativeKeywordRenderer(props),
            },
        }),
    ]

export const adGroupNegativeKeywordsTableColumnsConfig: Field<NegativeKeyword>[] =
    [
        negativeKeywordId({
            fixed: 'left',
            sorter: true,
            renderOptions: {
                render: (props) => negativeKeywordIdRenderer(props),
            },
        }),
        negativeKeywordMatchType({ sorter: true }),
        negativeKeywordState(),
        archiveNegativeKeyword({
            renderOptions: {
                render: (props) => archiveAdGroupNegativeKeywordRenderer(props),
            },
        }),
    ]
