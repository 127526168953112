import { ReactElement } from 'react'

import {
    ArchiveProductAdField,
    ProductAdStateField,
} from 'components/EditableFields/ProductAdFields'
import { ProductTitleCell } from 'components/FieldRenderers/ProductTitleCell'
import { AsinLink } from 'components/Links'
import { UNDEFINED_VALUE } from 'const/formatting'
import { formatCurrency } from 'helpers/formatting'
import { isUnset } from 'helpers/utilities'
import { ProductAdGroupBy, CellRenderProps } from 'types'

type RenderProps<RecordType> = {
    cellRenderProps: CellRenderProps<RecordType>
    dataIndex?: string[]
}

export function productAdTitleRenderer<RecordType extends ProductAdGroupBy>({
    cellRenderProps: { value, record },
}: RenderProps<RecordType>): ReactElement {
    const product_ad_asin = record.product_ad.asin
    const product_ad_id = record.product_ad.id
    const product_img = record.product_ad.product_metadata?.small_image_url
    const campaign_id = record.product_ad.campaign?.id
    const profile_country_code = record.product_ad.profile.country_code

    return (
        <ProductTitleCell
            value={value}
            campaignId={campaign_id}
            productImg={product_img}
            productAdId={product_ad_id}
            productAsin={product_ad_asin}
            profileCountryCode={profile_country_code}
        />
    )
}

export function productAdPriceRenderer<RecordType extends ProductAdGroupBy>({
    cellRenderProps: { value, record },
}: RenderProps<RecordType>): ReactElement {
    if (isUnset(value)) {
        return <span>{UNDEFINED_VALUE}</span>
    }

    const currencyCode = record.product_ad.product_metadata?.currency_code ?? ''
    return formatCurrency(value, {
        decimal: true,
        currencyCode,
    })
}

export function productAdAsinRenderer<RecordType extends ProductAdGroupBy>({
    cellRenderProps: { value, record },
}: RenderProps<RecordType>): ReactElement {
    if (isUnset(value)) {
        return <span>{UNDEFINED_VALUE}</span>
    }

    const countryCode = record.product_ad.profile.country_code
    return <AsinLink asin={value} countryCode={countryCode} />
}

export function productAdStateRenderer<RecordType extends ProductAdGroupBy>({
    cellRenderProps: { value, record, readonly },
    dataIndex = ['product_ad', 'state'],
}: RenderProps<RecordType>): ReactElement {
    if (isUnset(value)) {
        return <span>{UNDEFINED_VALUE}</span>
    }

    return (
        <ProductAdStateField
            dataIndex={dataIndex}
            readOnly={readonly}
            record={record}
        />
    )
}

export function archiveProductAdRenderer<RecordType extends ProductAdGroupBy>({
    cellRenderProps: { record, readonly, reloadData },
    dataIndex = ['product_ad'],
}: RenderProps<RecordType>): ReactElement {
    return (
        <ArchiveProductAdField
            dataIndex={dataIndex}
            record={record}
            readOnly={readonly}
            reloadData={reloadData}
        />
    )
}
