import { DATES, AD_GROUPS, AD_GROUP_STATES } from 'const/filters'
import { isNonEmptyArray } from 'helpers/typeGuard'
import { formatDateFromFilter } from 'helpers/utils'
import { FiltersState, AdGroupFactsParams } from 'types'

export const formatFilters = (filters: FiltersState): AdGroupFactsParams => {
    const params: AdGroupFactsParams = {}

    const dates = filters[DATES]
    const { start, end } = formatDateFromFilter(dates)
    params.report_date_min = start
    params.report_date_max = end

    const adGroups = filters[AD_GROUPS]
    if (isNonEmptyArray(adGroups)) {
        params.ad_group__in = adGroups.map((item) => item.value).join()
    }

    const adGroupStates = filters[AD_GROUP_STATES]
    if (isNonEmptyArray(adGroupStates)) {
        params.ad_group__state__in = adGroupStates
            .map((item) => item.value)
            .join()
    }

    return params
}
