import produce from 'immer'
import get from 'lodash/get'
import set from 'lodash/set'

import {
    updateTableColumnsConfig,
    updateTablePagination,
    updateTablePeriodDeltaDateRange,
    updateTablePeriodDeltaType,
    updateTablePeriodDeltaVisibility,
    updateTableRecord,
    updateTableTotalRowVisibility,
} from 'actions/ui/shared'

export default {
    // Columns Config
    [updateTableColumnsConfig](state, action) {
        const { path, columns } = action.payload
        return produce(state, (draft) => {
            set(draft, [...path, 'tableSettings', 'columns'], columns)
        })
    },

    // Pagination
    [updateTablePagination](state, action) {
        const { path, data: pagination } = action.payload
        const currentPagination = get(
            state,
            [...path, 'tableSettings', 'pagination'],
            {}
        )
        return produce(state, (draft) => {
            set(draft, [...path, 'tableSettings', 'pagination'], {
                ...currentPagination,
                ...pagination,
            })
        })
    },

    // Period Delta Visibility
    [updateTablePeriodDeltaVisibility](state, action) {
        const { path, data: isVisible } = action.payload
        return produce(state, (draft) => {
            set(
                draft,
                [...path, 'tableSettings', 'showPeriodDeltas'],
                isVisible
            )
        })
    },
    [updateTablePeriodDeltaType](state, action) {
        const { path, data: periodDeltaType } = action.payload
        return produce(state, (draft) => {
            set(
                draft,
                [...path, 'tableSettings', 'periodDeltaType'],
                periodDeltaType
            )
        })
    },
    [updateTableTotalRowVisibility](state, action) {
        const { path, data: isVisible } = action.payload
        return produce(state, (draft) => {
            set(draft, [...path, 'tableSettings', 'showTotalRow'], isVisible)
        })
    },
    [updateTablePeriodDeltaDateRange](state, action) {
        const { path, data: range } = action.payload
        return produce(state, (draft) => {
            set(
                draft,
                [...path, 'tableSettings', 'periodDeltaDateRange'],
                range
            )
        })
    },

    // Update Record
    [updateTableRecord](state, action) {
        const {
            path,
            data: { rowIndex, record },
        } = action.payload
        return produce(state, (draft) => {
            set(draft, [...path, 'data', rowIndex], record)
        })
    },
}
