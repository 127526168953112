import {
    CAMPAIGN_LABELS,
    PORTFOLIO_LABELS,
    PROFILE_LABELS,
    SOV_KEYWORD_LABELS,
    RESOURCE_NAME_MAPPING,
    PRODUCT_LABELS,
} from 'const/resourceTypes'

export const CAMPAIGN_LABEL_CONFIG = {
    defaultResourceType: CAMPAIGN_LABELS,
    resourceTypeOptions: [
        { label: RESOURCE_NAME_MAPPING[PROFILE_LABELS], value: PROFILE_LABELS },
        {
            label: RESOURCE_NAME_MAPPING[PORTFOLIO_LABELS],
            value: PORTFOLIO_LABELS,
        },
        {
            label: RESOURCE_NAME_MAPPING[CAMPAIGN_LABELS],
            value: CAMPAIGN_LABELS,
        },
    ],
}

export const PORTFOLIO_LABEL_CONFIG = {
    defaultResourceType: PORTFOLIO_LABELS,
    resourceTypeOptions: [
        { label: RESOURCE_NAME_MAPPING[PROFILE_LABELS], value: PROFILE_LABELS },
        {
            label: RESOURCE_NAME_MAPPING[PORTFOLIO_LABELS],
            value: PORTFOLIO_LABELS,
        },
    ],
}

export const PRODUCT_LABEL_CONFIG = {
    defaultResourceType: PRODUCT_LABELS,
    resourceTypeOptions: [
        { label: RESOURCE_NAME_MAPPING[PRODUCT_LABELS], value: PRODUCT_LABELS },
    ],
}

export const SOV_LABEL_CONFIG = {
    defaultResourceType: SOV_KEYWORD_LABELS,
    resourceTypeOptions: [
        {
            label: RESOURCE_NAME_MAPPING[SOV_KEYWORD_LABELS],
            value: SOV_KEYWORD_LABELS,
        },
    ],
}
