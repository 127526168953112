import { ReactElement } from 'react'

import styles from './styles.scss'

interface Props {
    rangeStart: string | number
    rangeEnd: string | number
    actionButtonVisible?: boolean
}

export default ({
    rangeStart,
    rangeEnd,
    actionButtonVisible = true,
}: Props): ReactElement => {
    return (
        <div>
            <span
                className={
                    actionButtonVisible
                        ? ''
                        : styles['text-not-visible-action-button']
                }
            >
                {rangeStart}
            </span>
            &ndash;
            <span
                className={
                    actionButtonVisible
                        ? ''
                        : styles['text-not-visible-action-button']
                }
            >
                {rangeEnd}
            </span>
        </div>
    )
}
