import { ReactElement } from 'react'

import { WalmartIcon } from 'components/Icons'
import { walmartItemUrl } from 'helpers/urls'

interface Props {
    itemId: string
}

function WalmartItemLink({ itemId }: Props): ReactElement {
    return (
        <a
            href={walmartItemUrl(itemId)}
            target="_blank"
            rel="noopener noreferrer"
        >
            {itemId} <WalmartIcon />
        </a>
    )
}

export default WalmartItemLink
