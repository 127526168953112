import { BulkUpdateState } from 'types'

export const PENDING_STATES = [
    BulkUpdateState.DRY_RUN_PENDING,
    BulkUpdateState.GENERATING,
    BulkUpdateState.IN_QUEUE,
    BulkUpdateState.IN_PROGRESS,
]

export const ERROR_STATES = [BulkUpdateState.TIMEOUT, BulkUpdateState.FAILURE]

export const COMPLETE_STATES = [
    BulkUpdateState.DRY_RUN,
    BulkUpdateState.COMPLETE,
    BulkUpdateState.PARTIAL_FAILURE,
]
