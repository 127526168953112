import { memo, ReactElement } from 'react'

import isEqual from 'lodash/isEqual'
import { useTranslation } from 'react-i18next'

import {
    LINE_ITEM_BUDGET_MIN,
    LINE_ITEM_BUDGET_MAX,
    LINE_ITEM_BUDGET_STEP,
    LINE_ITEM_BUDGET_PRECISION,
} from 'const/lineItems'
import { updateDspLineItem } from 'services/cerebroApi/orgScope/dspApi'
import { DspLineItemGroupBy, CurrencyCode } from 'types'

import { BudgetField } from '../../SharedFields/BudgetField'

interface Props<RecordType> {
    dataIndex: string[]
    readOnly: boolean
    record: RecordType
}

function LineItemBudgetField<RecordType extends DspLineItemGroupBy>({
    dataIndex,
    readOnly = false,
    record,
}: Props<RecordType>): ReactElement {
    const { t } = useTranslation('table')

    const { line_item_id: lineItemId, line_item: lineItem } = record
    const currencyCode: CurrencyCode = lineItem.currency_code as CurrencyCode

    return (
        <BudgetField
            budgetMax={LINE_ITEM_BUDGET_MAX}
            budgetMin={LINE_ITEM_BUDGET_MIN}
            budgetPrecision={LINE_ITEM_BUDGET_PRECISION}
            budgetStep={LINE_ITEM_BUDGET_STEP}
            currencyCode={currencyCode}
            disabled={!lineItem.user_can_manage}
            fieldName="budget"
            fieldPath={dataIndex}
            readOnly={readOnly}
            record={record}
            updateRequestApi={updateDspLineItem}
            updateRequestData={lineItemId}
            updateRequestSuccessMesg={t(
                'table:fields.lineItemBudget.successMsg',
                'Successfully updated line item budget'
            )}
        />
    )
}

export default memo(LineItemBudgetField, isEqual) as typeof LineItemBudgetField
