import get from 'lodash/get'
import isNull from 'lodash/isNull'
import createCachedSelector from 're-reselect'
import { createSelector } from 'reselect'

import { AuthState, RootReduxState } from 'types'
import moment from 'utilities/moment'

const selectAuth = (state: RootReduxState): AuthState => state.auth

export const selectDomainValue = createCachedSelector(
    selectAuth,
    (_state: RootReduxState, path: string | string[]) => path,
    (domainState, path) => get(domainState, path)
)((_state, path) => JSON.stringify(path))

export const selectHasRecentIntegration = createSelector(
    selectAuth,
    (authState) => {
        const { latestOrganizationIntegration } = authState

        if (isNull(latestOrganizationIntegration)) {
            return false
        }

        const now = moment()
        const thresholdTime = moment
            .utc(latestOrganizationIntegration)
            .add(24, 'hours')

        return now.isBefore(thresholdTime)
    }
)

export const selectHasNoIntegrations = createSelector(
    selectAuth,
    (authState) => {
        const { latestOrganizationIntegration } = authState

        return isNull(latestOrganizationIntegration)
    }
)

export const selectProfileFeaturePermissions = createCachedSelector(
    selectAuth,
    (_state: RootReduxState, profileId: string) => profileId,
    (authState, profileId) =>
        get(authState, ['profileFeaturePermissions', profileId], [])
)((_state, profileId) => profileId)

export const selectOrgKey = (state: RootReduxState): string => {
    const orgId = selectDomainValue(state, ['organizationId'])
    const orgGroupId = selectDomainValue(state, ['organizationGroupId'])

    return orgGroupId || orgId
}

export const selectUserOrganizationFeaturePermissions = createSelector(
    selectAuth,
    (authState) => authState.featurePermissions
)

export const selectUserFeaturePermissions = createSelector(
    selectAuth,
    (authState) => authState.userFeaturePermissions
)

export const selectUserSettings = createSelector(
    selectAuth,
    (authState) => authState
)
