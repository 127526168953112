import { useSelector, shallowEqual } from 'react-redux'

import {
    selectDomainValue as selectUiDomainValue,
    selectResourceOfPage,
    selectDashboardOfPage,
    selectFilters,
    selectVisibleCombinedFilters,
    selectResourceParamsOfPage,
    selectVisibleMetricsOfTable,
    selectVisibleColumnsOfTable,
} from 'selectors/ui'
import { PageFilters, RootReduxState, Field, FieldSerialized } from 'types'

export function useUiDomainValue<T>(path: string | string[]): T {
    return useSelector<RootReduxState, T>(
        (state) => selectUiDomainValue(state, path),
        shallowEqual
    )
}

export function useResourceParamsOfPage<T>(page: string): T {
    return useSelector<RootReduxState, T>(
        (state) => selectResourceParamsOfPage(state, page),
        shallowEqual
    )
}

export function useResourceOfPage<T>(page: string): T | null {
    return useSelector<RootReduxState, T>(
        (state) => selectResourceOfPage(state, page),
        shallowEqual
    )
}

export function useDashboardOfPage<T>(page: string): T {
    return useSelector<RootReduxState, T>(
        (state) => selectDashboardOfPage(state, page),
        shallowEqual
    )
}

export function usePageFilters(page: string): PageFilters {
    return useSelector<RootReduxState, PageFilters>(
        (state) => selectFilters(state, [page]),
        shallowEqual
    )
}

export function useVisibleCombinedFilters(path: string[]): PageFilters {
    return useSelector<RootReduxState, PageFilters>(
        (state) => selectVisibleCombinedFilters(state, path),
        shallowEqual
    )
}

export function useVisibleMetricsOfTable<T>(
    path: string[],
    columnsConfig: FieldSerialized[]
): Field<T>[] {
    return useSelector<RootReduxState, Field<T>[]>(
        (state) => selectVisibleMetricsOfTable(state, path, columnsConfig),
        shallowEqual
    )
}

export function useVisibleColumnsOfTable<T>(
    path: string[],
    columnsConfig: FieldSerialized[]
): Field<T>[] {
    return useSelector<RootReduxState, Field<T>[]>(
        (state) => selectVisibleColumnsOfTable(state, path, columnsConfig),
        shallowEqual
    )
}
