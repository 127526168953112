import { memo, ReactElement } from 'react'

import isEqual from 'lodash/isEqual'
import pick from 'lodash/pick'

import { useResource } from 'components/EditableFields/localHooks'
import { SOV_KEYWORD_STATES, PAUSED, ENABLED } from 'const/resourceStates'
import { updateSovKeyword } from 'services/cerebroApi/orgScope/resourceApi'
import { SovFact, SovKeyword } from 'types'

import { StateField } from '../../SharedFields/StateField'

type SovRecord = SovFact | SovKeyword

interface Props<RecordType> {
    dataIndex: string[]
    readOnly: boolean
    record: RecordType
}

function SovStateField<RecordType extends SovRecord>({
    dataIndex,
    readOnly,
    record,
}: Props<RecordType>): ReactElement {
    const { getFromResource, resourceId } = useResource(
        record,
        dataIndex,
        'keyword'
    )

    const AVAILABLE_OPTIONS = pick(SOV_KEYWORD_STATES, [PAUSED, ENABLED])

    const getIsDisabled = (): boolean => {
        const userCanManageCampaign = getFromResource(['user_can_manage'], true)
        return !userCanManageCampaign
    }

    return (
        <StateField
            disabled={getIsDisabled()}
            fieldPath={dataIndex}
            formatFieldValue={(value) => {
                const { state } = value
                return SOV_KEYWORD_STATES[state]
            }}
            options={AVAILABLE_OPTIONS}
            placeholder="Keyword State"
            readOnly={readOnly}
            record={record}
            updateRequestApi={updateSovKeyword}
            updateRequestData={resourceId}
            updateRequestSuccessMesg={
                <span>
                    Successfully updated keyword state for{' '}
                    <strong>{getFromResource(['text'])}</strong>
                </span>
            }
        />
    )
}

export default memo(SovStateField, isEqual) as typeof SovStateField
