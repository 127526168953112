import { ReactElement } from 'react'

import { createdDateRenderer } from 'components/ResourceDetailsV2/Renderers/common'
import { formatDay } from 'helpers/formatting'
import { Alert } from 'types'

export function alertCreatedAtRenderer(alert: Alert): ReactElement {
    const date = formatDay(alert.created_at)
    return createdDateRenderer(`Created: ${date}`)
}
