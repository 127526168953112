import { type TablePaginationConfig } from 'antd'
import { type PaginationConfig } from 'antd/es/pagination'

import { PAGE_SIZE_OPTIONS } from 'configuration/pagination'

import { formatNumber } from './formatting'

export const getPaginationOptions = (name = 'Rows'): PaginationConfig => ({
    pageSizeOptions: PAGE_SIZE_OPTIONS,
    showSizeChanger: true,
    showTotal: (total, range) =>
        `${formatNumber(range[0])} - ${formatNumber(
            range[1]
        )} of ${formatNumber(total)} Total ${name}`,
    position: 'bottom',
})

export type PaginationSize = 'xs' | 'sm' | 'default'
interface ResponsiveTablePaginationOptions {
    name?: string
    paginationSize?: PaginationSize
}

export const getResponsiveTablePaginationOptions = (
    config: ResponsiveTablePaginationOptions
): TablePaginationConfig => {
    const { name = 'Rows', paginationSize = 'default' } = config

    return {
        pageSizeOptions: PAGE_SIZE_OPTIONS,
        showSizeChanger: true,
        showTotal: (total, range) => {
            const prefix = `${formatNumber(range[0])} - ${formatNumber(
                range[1]
            )} of `
            const suffix = `${formatNumber(total)} Total ${name}`
            return (paginationSize === 'default' ? prefix : '') + suffix
        },
        position: ['bottomRight'],
        size: 'small',
    }
}
