import merge from 'deepmerge'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import uniqWith from 'lodash/uniqWith'

import { presetRanges } from 'helpers/dateRange'
import { formatCerebroDate } from 'helpers/formatting'
import {
    isDefined,
    isNonEmptyArray,
    isPresetDateRange,
} from 'helpers/typeGuard'
import { DateRangeFilter } from 'types'

export const extractValue = (
    text: string,
    startAfter: string,
    stopBefore: string
): string => {
    if (!text.includes(startAfter)) {
        return ''
    }

    const startIndex = text.indexOf(startAfter) + startAfter.length

    let endIndex =
        stopBefore.length !== 0
            ? text.indexOf(stopBefore, startIndex)
            : text.length

    if (endIndex < 0) {
        endIndex = text.length
    }

    return text.slice(startIndex, endIndex)
}

export const formatDateFromFilter = (
    dates?: DateRangeFilter,
    fromDate?: string,
    lagInHours?: number
): { start?: string; end?: string } => {
    if (!isDefined(dates)) {
        return {}
    }

    let start
    let end

    if (isPresetDateRange(dates)) {
        const { range } = presetRanges(fromDate, lagInHours)[dates]
        start = range[0]
        end = range[1]
    } else if (isNonEmptyArray(dates)) {
        start = dates[0]
        end = dates[1]
    }

    return { start: formatCerebroDate(start), end: formatCerebroDate(end) }
}

export const mergeAndDedupeObjects = <T extends Record<string, any>>(
    objects: T[],
    ignoredKeys: string[] = []
): T => {
    const mergedObject = merge.all(objects) as T
    const dedupedObject = {} as T

    Object.keys(mergedObject).forEach((key) => {
        const filterKey = key as keyof T
        const filterValue = mergedObject[key]

        if (
            Array.isArray(filterValue) &&
            filterValue.length > 1 &&
            !ignoredKeys.includes(key)
        ) {
            dedupedObject[filterKey] = uniqWith(
                filterValue,
                isEqual
            ) as T[keyof T]
        } else {
            dedupedObject[filterKey] = filterValue
        }
    })

    return dedupedObject
}

export const getNonEmptyKeyCount = (
    key: string,
    objectCollection: { [key: string]: unknown }[]
): number =>
    objectCollection.reduce(
        (count, obj) => (isEmpty(obj[key]) ? count : count + 1),
        0
    )

export const getCurrentTimestamp = (): number => new Date().getTime()

/**
 * Returns the user's OS or null if it's not possible to determine any
 * @see adapted from https://stackoverflow.com/questions/38241480/detect-macos-ios-windows-android-and-linux-os-with-js
 */
export const getOS = (): string | null => {
    const { userAgent, platform } = window.navigator
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
    const iosPlatforms = ['iPhone', 'iPad', 'iPod']
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']

    if (macosPlatforms.indexOf(platform) !== -1) {
        return 'Mac OS'
    }
    if (iosPlatforms.indexOf(platform) !== -1) {
        return 'iOS'
    }
    if (windowsPlatforms.indexOf(platform) !== -1) {
        return 'Windows'
    }
    if (/Android/.test(userAgent)) {
        return 'Android'
    }
    if (/Linux/.test(platform)) {
        return 'Linux'
    }

    return null
}
