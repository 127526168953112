import { all, takeLatest } from 'redux-saga/effects'

import {
    fetchCapabilitiesRequest,
    fetchTasksRequest,
    fetchAutomationProfilesRequest,
} from 'actions/ui/campaignPage/automation'

import {
    fetchCapabilitiesWorker,
    fetchTasksWorker,
    fetchAutomationProfilesWorker,
} from './workers'

export default function* automationTabWatcher() {
    yield all([
        // Capabilities
        takeLatest(
            fetchCapabilitiesRequest.toString(),
            fetchCapabilitiesWorker
        ),

        // Tasks
        takeLatest(fetchTasksRequest.toString(), fetchTasksWorker),

        // Automation profiles
        takeLatest(
            fetchAutomationProfilesRequest.toString(),
            fetchAutomationProfilesWorker
        ),
    ])
}
