import { ReactElement, useState } from 'react'

import { type TooltipProps } from 'antd'
import { Translation } from 'react-i18next'

import { ConditionalWrapper } from 'components/ConditionalWrapper'
import { ImageNotFoundIcon } from 'components/Icons'
import { ToolTip } from 'components/ToolTip'

import styles from './styles.scss'

interface Props {
    imageUrl: string | null
    alt: string
    tooltipTitle?: string | ReactElement<typeof Translation>
    tooltipPlacement?: TooltipProps['placement']
}

export default function DetailImage({
    imageUrl,
    alt,
    tooltipTitle,
    tooltipPlacement = 'top',
}: Props): ReactElement {
    const [failedToLoad, setFailedToLoad] = useState(false)
    const image =
        imageUrl && !failedToLoad ? (
            <img
                src={imageUrl}
                alt={alt}
                className={styles['details-img']}
                onError={() => setFailedToLoad(true)}
            />
        ) : (
            <ImageNotFoundIcon className={styles['details-img']} />
        )

    return (
        <ConditionalWrapper
            condition={Boolean(tooltipTitle)}
            wrapper={(children) => (
                <ToolTip
                    title={tooltipTitle}
                    arrow={{ pointAtCenter: true }}
                    placement={tooltipPlacement}
                >
                    {children}
                </ToolTip>
            )}
        >
            {image}
        </ConditionalWrapper>
    )
}
