import i18n from 'i18next'
import get from 'lodash/get'
import has from 'lodash/has'

import { axiosInstance } from './client'

const errorInfo = (errorName) => {
    return get(
        {
            ParamValidationError: i18n.t(
                'auth:errors.ParamValidationError',
                'Invalid parameter.'
            ),
            InvalidParameterException: i18n.t(
                'auth:errors.InvalidParameterException',
                'Invalid parameter.'
            ),
            TooManyFailedAttemptsException: i18n.t(
                'auth:errors.TooManyFailedAttemptsException',
                'Too many failed attempts.'
            ),
            ExpiredCodeException: i18n.t(
                'auth:errors.ExpiredCodeException',
                'Code has expired.'
            ),
            CodeMismatchException: i18n.t(
                'auth:errors.CodeMismatchException',
                'Code is incorrect.'
            ),
            UsernameExistsException: i18n.t(
                'auth:errors.UsernameExistsException',
                'User already exists.'
            ),
            UserNotFoundException: i18n.t(
                'auth:errors.UserNotFoundException',
                'User not found.'
            ),
            InvalidPasswordException: i18n.t(
                'auth:errors.InvalidPasswordException',
                'Provided password cannot be used for security reasons.'
            ),
            NotAuthorizedException: i18n.t(
                'auth:errors.NotAuthorizedException',
                'Invalid credentials were provided.'
            ),
            LimitExceededException: i18n.t(
                'auth:errors.LimitExceededException',
                'Attempt limit exceeded, please try again later.'
            ),
        },
        errorName
    )
}

const handleAuthResponse = (response) => {
    if (
        (response.status === 401 &&
            has(response, ['data', 'code']) &&
            response.data.code === 'SessionExpired') ||
        (response.status === 302 &&
            has(response, ['data', 'code']) &&
            response.data.code === 'SmsChallenge')
    ) {
        return
    }
    if (response.status !== 200) {
        const defaultErr = i18n.t('auth:errors.UnknownError', 'Unknown error.')
        let errMsg = ''

        if (has(response, ['data', 'error_description'])) {
            errMsg = get(response, ['data', 'error_description'])
        } else if (has(response, ['data', 'error'])) {
            errMsg = get(response, ['data', 'error'])
        } else if (has(response, 'data')) {
            errMsg = errorInfo(response.data)
        }

        throw new Error(errMsg ?? defaultErr)
    }
}

export const login = async (username, password) => {
    const response = await axiosInstance.post(
        `/oauth/token/`,
        {
            grant_type: 'password',
            username,
            password,
        },
        {
            auth: {
                username: CEREBRO_OAUTH_CLIENT_ID,
                password: CEREBRO_OAUTH_CLIENT_SECRET,
            },
        }
    )
    handleAuthResponse(response)
    return response
}

export const loginMfa = async (email, password, tokens, code) => {
    const response = await axiosInstance.post(
        `/oauth/token/`,
        {
            grant_type: 'password',
            username: email,
            password,
            tokens,
            code,
        },
        {
            headers: {
                'Content-Type': 'application/json',
            },
            auth: {
                username: CEREBRO_OAUTH_CLIENT_ID,
                password: CEREBRO_OAUTH_CLIENT_SECRET,
            },
        }
    )
    handleAuthResponse(response)
    return response
}

export const signUp = async (userData) => {
    const response = await axiosInstance.post(`/api/auth/sign_up/`, {
        email: userData.username,
        password: userData.password,
        name: userData.attributes.name ?? '',
        token: userData.token,
    })
    handleAuthResponse(response)
    return response
}

export const checkUserNotConfirmed = async (email) => {
    const response = await axiosInstance.post(`/api/auth/authenticate/`, {
        email,
        password: 'dummyPassword',
    })

    try {
        handleAuthResponse(response)
    } catch (e) {
        if (e.message === 'UserNotConfirmedException') {
            return true
        }
    }
    return false
}

export const resendSignUpCode = async (email) => {
    const response = await axiosInstance.post(
        `/api/auth/resend_confirmation_code/`,
        {
            email,
        }
    )
    handleAuthResponse(response)
}

export const confirmSignUp = async (email, code) => {
    const response = await axiosInstance.post(`/api/auth/confirm_sign_up/`, {
        email,
        code,
    })
    handleAuthResponse(response)
}

export const sendResetEmail = async (email) => {
    const response = await axiosInstance.post(
        `/api/auth/request_reset_password/`,
        {
            email,
        }
    )
    handleAuthResponse(response)
}

export const resetPassword = async (email, code, password) => {
    const response = await axiosInstance.post(
        `/api/auth/submit_reset_password/`,
        {
            email,
            code,
            password,
        }
    )
    handleAuthResponse(response)
}
