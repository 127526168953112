import { SVGAttributes, ReactElement } from 'react'

type Props = SVGAttributes<SVGElement>

function SvgScheduleIcon(props: Props): ReactElement {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M5.048 9.727c0-.2.17-.363.38-.363h1.905c.21 0 .381.162.381.363v1.091c0 .201-.17.364-.38.364H5.428a.373.373 0 01-.381-.364v-1.09zM5.429 12.09c-.21 0-.381.164-.381.364v1.091c0 .201.17.364.38.364h1.905c.21 0 .381-.163.381-.364v-1.09c0-.201-.17-.364-.38-.364H5.428zM8.667 9.727c0-.2.17-.363.38-.363h1.905c.21 0 .381.162.381.363v1.091c0 .201-.17.364-.38.364H9.047a.373.373 0 01-.381-.364v-1.09zM9.048 12.09a.373.373 0 00-.381.364v1.091c0 .201.17.364.38.364h1.905c.21 0 .381-.163.381-.364v-1.09c0-.201-.17-.364-.38-.364H9.047zM12.286 9.727c0-.2.17-.363.38-.363h1.905c.21 0 .381.162.381.363v1.091c0 .201-.17.364-.38.364h-1.905a.373.373 0 01-.381-.364v-1.09zM12.667 12.09a.373.373 0 00-.381.364v1.091c0 .201.17.364.38.364h1.905c.21 0 .381-.163.381-.364v-1.09c0-.201-.17-.364-.38-.364h-1.905z" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.286 4.636h-.572v-1.09A.559.559 0 0015.143 3a.559.559 0 00-.572.545v2.182a.559.559 0 01-.571.546.559.559 0 01-.571-.546v-1.09h-2.286V3.544A.559.559 0 0010.57 3a.559.559 0 00-.571.545v2.182a.559.559 0 01-.571.546.559.559 0 01-.572-.546v-1.09H6.571V3.544A.559.559 0 006 3a.559.559 0 00-.571.545v2.182a.559.559 0 01-.572.546.559.559 0 01-.571-.546v-1.09h-.572C2.77 4.636 2 5.37 2 6.272v9.09C2 16.267 2.769 17 3.714 17h12.572C17.23 17 18 16.266 18 15.364V6.273c0-.903-.769-1.637-1.714-1.637zM3.714 15.364V7.909h12.572v7.455H3.714z"
            />
        </svg>
    )
}

export default SvgScheduleIcon
