import { ReactNode } from 'react'

import { DeleteNegativeKeywordField } from 'components/EditableFields'
import { UNDEFINED_VALUE } from 'const/formatting'
import { isUnset } from 'helpers/utilities'
import {
    deleteNegativeKeyword,
    deleteCampaignNegativeKeyword,
} from 'services/cerebroApi/orgScope/resourceApi'
import { NegativeKeyword, CellRenderProps } from 'types'

export function negativeKeywordIdRenderer<RecordType extends NegativeKeyword>({
    record,
    undefinedValue = UNDEFINED_VALUE,
}: CellRenderProps<RecordType>): ReactNode {
    return record.text ?? undefinedValue
}

export function archiveCampaignNegativeKeywordRenderer<
    RecordType extends NegativeKeyword,
>({
    record,
    readonly,
    reloadData,
    undefinedValue = UNDEFINED_VALUE,
}: CellRenderProps<RecordType>): ReactNode {
    return isUnset(record.id) ? (
        undefinedValue
    ) : (
        <DeleteNegativeKeywordField
            apiFunc={deleteCampaignNegativeKeyword}
            readOnly={readonly}
            record={record}
            reloadData={reloadData}
        />
    )
}

export function archiveAdGroupNegativeKeywordRenderer<
    RecordType extends NegativeKeyword,
>({
    record,
    readonly,
    reloadData,
    undefinedValue = UNDEFINED_VALUE,
}: CellRenderProps<RecordType>): ReactNode {
    return isUnset(record.id) ? (
        undefinedValue
    ) : (
        <DeleteNegativeKeywordField
            apiFunc={deleteNegativeKeyword}
            readOnly={readonly}
            record={record}
            reloadData={reloadData}
        />
    )
}
