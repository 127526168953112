// modified based on https://github.com/rt2zz/redux-persist/blob/master/src/stateReconciler/autoMergeLevel2.js
import isArray from 'lodash/isArray'
import isEqual from 'lodash/isEqual'
import isPlainObject from 'lodash/isPlainObject'
import mergeWith from 'lodash/mergeWith'

import { GenericObject } from 'types'

function getColumnIdMinimumObject(columns: GenericObject[]): any[] {
    return columns.map((each) => ({
        id: each.id,
        isVisible: each.isVisible,
    }))
}

function customizer(
    objValue: any,
    srcValue: any,
    key: string
): string[] | GenericObject | undefined {
    if (key === 'columns' && isArray(objValue)) {
        // We want the localStorage to keep the order and visibility of the columns as selected by the user.
        if (
            !isEqual(
                getColumnIdMinimumObject(objValue),
                getColumnIdMinimumObject(srcValue)
            )
        ) {
            const newColumns: any[] = []
            const storageColumnMap = srcValue.reduce(
                (acc: { [columnId: string]: any }, column: any) => {
                    acc[column.id] = column
                    return acc
                },
                {}
            )
            const defaultStateColumnMap = objValue.reduce(
                (acc: { [columnId: string]: any }, column: any) => {
                    acc[column.id] = column
                    return acc
                },
                {}
            )

            // Add columns that are in storage and default state
            Object.keys(storageColumnMap).forEach((columnId) => {
                if (defaultStateColumnMap[columnId]) {
                    newColumns.push(storageColumnMap[columnId])
                }
            })

            const prevColumnMap: { [key: string]: string | null } = {}
            let prevColumnId: string | null = null

            // Iterate over default state to create the map of previous columns
            Object.keys(defaultStateColumnMap).forEach((columnId) => {
                if (!storageColumnMap[columnId]) {
                    if (prevColumnId !== null) {
                        prevColumnMap[columnId] = prevColumnId
                    }
                }
                prevColumnId = columnId
            })

            // Use prevColumnMap to insert newly added columns after their previous columns
            Object.keys(prevColumnMap).forEach((columnId) => {
                prevColumnId = prevColumnMap[columnId]
                const prevIndex = newColumns.findIndex(
                    (column) => column.id === prevColumnId
                )
                if (prevIndex !== -1) {
                    newColumns.splice(
                        prevIndex + 1,
                        0,
                        defaultStateColumnMap[columnId]
                    )
                } else {
                    newColumns.push(defaultStateColumnMap[columnId])
                }
            })

            return newColumns
        }
    }

    if (key === 'pagination') {
        return mergeWith(objValue, { pageSize: srcValue.pageSize })
    }

    /**
     * Here we are always taking the sorter value from localStorage
     * otherwise lodash/mergeWith will always combine the default and localStorage
     * arrays rather than take the most recent change over the default.
     */
    if (key === 'sorter') {
        return srcValue
    }

    return undefined
}

export default function autoMerge(
    inboundState: GenericObject,
    originalState: GenericObject,
    reducedState: GenericObject,
    { debug }: GenericObject
): GenericObject {
    const newState = JSON.parse(JSON.stringify(reducedState))
    // only rehydrate if inboundState exists and is an object
    if (inboundState && typeof inboundState === 'object') {
        Object.keys(inboundState).forEach((key) => {
            // ignore _persist data
            if (key === '_persist') {
                return
            }
            // if reducer modifies substate, skip auto rehydration
            if (originalState[key] !== reducedState[key]) {
                if (process.env.NODE_ENV !== 'production' && debug) {
                    /* eslint-disable no-console */
                    console.log(
                        'redux-persist/stateReconciler: sub state for key `%s` modified.',
                        key
                    )
                }
                /* eslint-enable no-console */
                // return
            } else if (debug) {
                /* eslint-disable no-console */
                console.log('originalState === reducedState `%s`', key)
                /* eslint-enable no-console */
            }

            if (isPlainObject(reducedState[key])) {
                newState[key] = mergeWith(
                    newState[key],
                    inboundState[key],
                    customizer
                )
                if (debug) {
                    /* eslint-disable no-console */
                    console.log(
                        'redux-persist/stateReconciler: `autoMerge` recconciled key `%s` as object',
                        key
                    )
                    /* eslint-enable no-console */
                }
                return
            }
            // otherwise hard set
            newState[key] = inboundState[key]
        })
    }

    if (
        process.env.NODE_ENV !== 'production' &&
        debug &&
        inboundState &&
        typeof inboundState === 'object'
    ) {
        /* eslint-disable no-console */
        console.log(
            `redux-persist/stateReconciler: rehydrated keys '${Object.keys(
                inboundState
            ).join(', ')}'`
        )
    }
    /* eslint-enable no-console */

    return newState
}
