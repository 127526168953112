import { ReactNode } from 'react'

import { CellRenderProps, OrganizationFact } from 'types'

export function organizationNameRenderer<RecordType extends OrganizationFact>({
    record,
    value,
}: CellRenderProps<RecordType>): ReactNode {
    return record.organization
        ? `${record.organization.name} (${record.organization.id})`
        : value
}
