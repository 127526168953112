import { ReactNode } from 'react'

import {
    WalmartKeywordBidField,
    WalmartKeywordStateField,
} from 'components/EditableFields'
import { CellRenderProps, WalmartKeywordGroupBy } from 'types'

type RenderProps = {
    record: WalmartKeywordGroupBy
    dataIndex: string[]
}

export function walmartKeywordBidRenderer<
    RecordType extends WalmartKeywordGroupBy,
>({ record }: CellRenderProps<RecordType>): ReactNode {
    return <WalmartKeywordBidField keyword={record.keyword} />
}

export function walmartKeywordStateRenderer({
    record,
    dataIndex,
}: RenderProps): ReactNode {
    return <WalmartKeywordStateField dataIndex={dataIndex} record={record} />
}
