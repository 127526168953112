import pluralize from 'pluralize'

import { getProductTitleOutsideThemeContext } from 'appConfig'

export const DASHBOARD_ACTIONS = {
    configureTabs: 'CONFIGURE_TABS',
    cloneDashboard: 'CLONE_DASHBOARD',
    exportToPdf: 'EXPORT_TO_PDF',
    exportToCsv: 'EXPORT_TO_CSV',
    cloneWidgetOnThisTab: 'CLONE_WIDGET_ON_THIS_TAB',
    cloneWidgetToOtherTab: 'CLONE_WIDGET_TO_OTHER_TAB',
    cloneWidgetToOtherDashboard: 'CLONE_WIDGET_TO_OTHER_DASHBOARD',
    openCloneSubmenu: 'OPEN_CLONE_SUBMENU',
    deleteWidget: 'DELETE_WIDGET',
    setAsHomepage: 'SET_AS_HOMEPAGE',
    editWidget: 'EDIT_WIDGET',
    shareViaEmail: 'SHARE_VIA_EMAIL',
    manageRecurringShare: 'MANAGE_RECURRING_SHARE',
    setDashboardCurrency: 'SET_DASHBOARD_CURRENCY',
    reloadWidget: 'RELOAD_WIDGET',
    showDashboardInfo: 'SHOW_DASHBOARD_INFO',
}

export const DASHBOARD_WIDGET_LIMIT = {
    WARNING_LIMIT: 90,
    WARNING_MESSAGE(count: number) {
        return (
            <p>
                This dashboard contains <strong>{count} widgets</strong> of{' '}
                <strong>{this.HARD_LIMIT} total allowed widgets</strong>.
            </p>
        )
    },
    HARD_LIMIT: 100,
    HARD_MESSAGE(count: number) {
        return (
            <>
                <p>
                    This dashboard contains <strong>{count} widgets</strong> of{' '}
                    <strong>{this.HARD_LIMIT} total allowed widgets</strong>.
                </p>
                <p>
                    <strong>
                        Remove{' '}
                        {pluralize('widget', count - this.HARD_LIMIT, true)}
                    </strong>{' '}
                    to save this dashboard.
                </p>
            </>
        )
    },
}

export const PERFORMANCE_TAB = 'performance'
export const ROI_TAB = 'roi'
export const REACH_TAB = 'reach'
export const CONVERSIONS_TAB = 'conversions'
export const CAMPAIGNS_TAB = 'campaigns'
export const PRODUCTS_TAB = 'products'

export const DASHBOARD_PDF_WIDTH = 1063

export const ALL_DASHBOARDS = 'allDashboards'
const MY_DASHBOARDS = 'myDashboards'
export const SHARED_WITH_ME = 'sharedWithMe'
const MANAGED_BY_DOWNSTREAM = 'managedByDownstream'

export const DASHBOARD_ACCESS_KEYS = [
    ALL_DASHBOARDS,
    MY_DASHBOARDS,
    SHARED_WITH_ME,
    MANAGED_BY_DOWNSTREAM,
]

export const DASHBOARD_ACCESS_LABELS = {
    [ALL_DASHBOARDS]: 'All Dashboards',
    [MY_DASHBOARDS]: 'Created by Me',
    [SHARED_WITH_ME]: 'Shared With Me',
    [MANAGED_BY_DOWNSTREAM]: `Managed by ${getProductTitleOutsideThemeContext()}`,
}

export const Y_BOTTOM_OF_PAGE = 999999999
