import { datadogRum } from '@datadog/browser-rum'
import { captureException } from '@sentry/browser'
import i18n from 'i18next'
import get from 'lodash/get'
import hash from 'object-hash'
import { Action, ActionFunctionAny } from 'redux-actions'
import {
    CallEffect,
    PutEffect,
    SelectEffect,
    call,
    put,
    select,
} from 'redux-saga/effects'
import { parse, stringify } from 'zipson'

import { signOutRequest } from 'actions/auth'
import { updateCache } from 'actions/cache'
import { ContactUsLink } from 'components/Links'
import { selectDomainValue as selectAuthDomainValue } from 'selectors/auth'
import { selectCache } from 'selectors/cache'
import message from 'utilities/message'

interface CacheParams {
    organizationId: string | null
    organizationGroupId: string | null
    apiParams: any
}

const WHITE_LISTED_ERROR_MESSAGES = [
    'There is already a matching download running. Please wait for it to end before starting a new one',
]

const shouldUseCache = (apiFunc: any): boolean => apiFunc.cache === true

const formatErrorMessage = (data: any): string => {
    // Stringify the error so that we can display to the user
    if (typeof data === 'string') {
        return data
    }
    if (Array.isArray(data)) {
        return data.map(formatErrorMessage).join(', ')
    }
    return JSON.stringify(data)
}

function* constructCacheParams(
    apiParams: any
): Generator<SelectEffect, string, string | null> {
    const cacheParams: CacheParams = {
        organizationId: yield select(selectAuthDomainValue, 'organizationId'),
        organizationGroupId: yield select(
            selectAuthDomainValue,
            'organizationGroupId'
        ),
        apiParams,
    }
    return hash(cacheParams)
}

export default function* cerebroApiSaga(
    successActionFunc:
        | ActionFunctionAny<Action<any>>
        | ((...args: any[]) => any)
        | null,
    apiFunc: any,
    ...apiParams: any[]
): Generator<
    | SelectEffect
    | Generator<SelectEffect, string, string | null>
    | PutEffect<Action<any>>
    | CallEffect<any>,
    any,
    any
> {
    let response: any
    let error: any

    // cache management
    const funcName = apiFunc.name

    let cacheParams: string | null = null
    if (shouldUseCache(apiFunc)) {
        cacheParams = yield constructCacheParams(apiParams)
        const cachedData: any = yield select(selectCache, [
            funcName,
            cacheParams,
        ])
        if (cachedData !== undefined) {
            const parsedData = parse(cachedData)
            if (successActionFunc) {
                yield put(successActionFunc(parsedData))
            }
            return { status: 200, data: parsedData }
        }
    }

    // request from server
    try {
        response = yield call(apiFunc, ...apiParams)
        const { status, data } = response

        if (status >= 200 && status < 300) {
            if (successActionFunc) {
                yield put(successActionFunc(data))
            }

            if (shouldUseCache(apiFunc)) {
                yield put(
                    updateCache({
                        funcName,
                        params: cacheParams,
                        data: stringify(data, { fullPrecisionFloats: true }),
                    })
                )
            }
        } else if (status === 400) {
            error = new Error(formatErrorMessage(data))
        } else if (status === 401) {
            const sessionExpiredMessage = i18n.t(
                'common:auth.sessionExpired',
                'Your session has expired. Please log in.'
            )
            message.warning({
                content: sessionExpiredMessage,
                key: 'sessionExpired',
            })
            // sign out the user if their tokens are expired
            yield put(signOutRequest())
        } else if (status === 403) {
            const notAuthorizedMessage = i18n.t(
                'common:auth.notAuthorized',
                'Not authorized.'
            )
            error = new Error(notAuthorizedMessage)
        } else if (status === 404 || status === 429) {
            // Do nothing if api is throttled or resource not found
        } else {
            const requestFailedStatusMessage = i18n.t(
                'common:auth.requestFailed.status',
                'Request failed (status code {{ status }}).',
                { status }
            )
            error = new Error(requestFailedStatusMessage)
        }
    } catch (err) {
        // handle 5XX errors
        error = err
    }

    if (error) {
        const errorMessage = get(error, 'message')
        const errorStatus =
            get(response, 'status') || get(error, ['response', 'status'])

        if (errorStatus === 500) {
            // special error message for 500 errors
            message.error(
                <>
                    Something went wrong and we&apos;ve already been notified.
                    Please try again or{' '}
                    <ContactUsLink link>contact us</ContactUsLink> if this issue
                    continues.
                </>
            )
        } else if (errorMessage && errorStatus >= 400) {
            // display all other errors to the user
            message.error(errorMessage)
        }

        if (
            errorStatus !== 400 ||
            !WHITE_LISTED_ERROR_MESSAGES.includes(errorMessage)
        ) {
            console.error({ error, errorStatus })
            captureException(error)
            datadogRum.addError(error)
            throw error
        }
    }

    return response
}
