import { ReactElement, ReactNode } from 'react'

import { Space, Select, Typography } from 'antd'
import { AxiosResponse } from 'axios'
import { Field } from 'formik'
import get from 'lodash/get'
import isNumber from 'lodash/isNumber'
import { Translation } from 'react-i18next'
import { number, object } from 'yup'

import { FormikInputNumber, FormikSelect } from 'components/formik'
import { getMetricValueFormatter } from 'helpers/formatters'
import { isUnset } from 'helpers/utilities'
import { useCerebroApiRequest } from 'hooks'
import {
    MultiplierAdjustmentType,
    MULTIPLIER_ADJUSTMENT_TYPES,
} from 'types/components/fields'
import message from 'utilities/message'

import { SingleValueField } from '../SingleValueField'

const { Option } = Select
const { Text } = Typography

interface Props<RecordType> {
    multiplierMax: number
    multiplierMin: number
    multiplierPrecision: number
    multiplierStep: number
    disabled?: boolean
    fieldName?: string
    fieldPath: string[]
    readOnly?: boolean
    record: RecordType
    serializeFieldValues?: (values: { [fieldName: string]: number }) => object
    updateRequestApi: (...args: any) => Promise<AxiosResponse<any>>
    updateRequestData: any
    updateRequestSuccessMesg: string
    onUpdateRequestSuccess?: (values: { [fieldName: string]: number }) => void
    getButtonClassName?: (values: {
        [fieldName: string]: number
    }) => string | null
    formatFieldValue?: (values: { [fieldName: string]: number }) => ReactNode
    adjustmentType: MULTIPLIER_ADJUSTMENT_TYPES
}

function MultiplierField<RecordType>({
    multiplierMax,
    multiplierMin,
    multiplierPrecision,
    multiplierStep,
    disabled = false,
    fieldName = 'multiplier',
    fieldPath = [],
    readOnly = false,
    record,
    serializeFieldValues = (values) => values,
    updateRequestApi,
    updateRequestData,
    updateRequestSuccessMesg,
    onUpdateRequestSuccess,
    getButtonClassName,
    formatFieldValue,
    adjustmentType = MULTIPLIER_ADJUSTMENT_TYPES.BOTH,
}: Props<RecordType>): ReactElement {
    const makeCerebroApiRequest = useCerebroApiRequest()
    const validationSchema = object().shape({
        [fieldName]: number()
            .min(multiplierMin, 'Must be above the minimum multiplier.')
            .max(multiplierMax, 'Must be below the maximum multiplier.')
            .required(),
    })
    const valueChange = (
        newValue: number,
        setFieldValue: any,
        values: { [fieldName: string]: number | MultiplierAdjustmentType }
    ): void => {
        const adjustmentTypeField = 'adjustmentType'
        const currentType = values[adjustmentTypeField]
        if (isUnset(newValue)) {
            return
        }

        if (!isNumber(newValue)) {
            return
        }

        setFieldValue(fieldName, newValue)

        if (
            newValue < 0 &&
            currentType !== MULTIPLIER_ADJUSTMENT_TYPES.DECREASE
        ) {
            setFieldValue(
                adjustmentTypeField,
                MULTIPLIER_ADJUSTMENT_TYPES.DECREASE
            )
        }
        if (
            newValue >= 0 &&
            currentType !== MULTIPLIER_ADJUSTMENT_TYPES.INCREASE
        ) {
            setFieldValue(
                adjustmentTypeField,
                MULTIPLIER_ADJUSTMENT_TYPES.INCREASE
            )
        }
    }
    const adjustmentTypeChange = (
        newType: MultiplierAdjustmentType,
        setFieldValue: any,
        values: { [fieldName: string]: number | MultiplierAdjustmentType }
    ): void => {
        const value = values[fieldName]
        if (isUnset(value)) {
            return
        }

        if (!isNumber(value)) {
            return
        }

        const multiplier =
            newType === MULTIPLIER_ADJUSTMENT_TYPES.INCREASE ? 1 : -1

        const newValue = Math.abs(value) * multiplier

        setFieldValue(fieldName, newValue)
    }

    const adjustmentTypeRender = (
        isSubmitting: boolean,
        setFieldValue: any,
        values: {
            [fieldName: string]: number
        }
    ): ReactNode => {
        const increaseByTranslation = (
            <Translation>
                {(t) => t('common:increaseBy', 'Increase by')}
            </Translation>
        )

        if (adjustmentType === MULTIPLIER_ADJUSTMENT_TYPES.INCREASE) {
            return <Text>{increaseByTranslation}:</Text>
        }

        const decreaseByTranslation = (
            <Translation>
                {(t) => t('common:decreaseBy', 'Decrease by')}
            </Translation>
        )

        if (adjustmentType === MULTIPLIER_ADJUSTMENT_TYPES.DECREASE) {
            return <Text>{decreaseByTranslation}:</Text>
        }

        return (
            <Field
                name="adjustmentType"
                defaultValue={MULTIPLIER_ADJUSTMENT_TYPES.INCREASE}
                component={FormikSelect}
                style={{ width: 250 }}
                disabled={isSubmitting}
                handleChange={(value: MultiplierAdjustmentType) =>
                    adjustmentTypeChange(
                        value as MultiplierAdjustmentType,
                        setFieldValue,
                        values
                    )
                }
            >
                <Option value={MULTIPLIER_ADJUSTMENT_TYPES.INCREASE}>
                    {increaseByTranslation}
                </Option>
                <Option value={MULTIPLIER_ADJUSTMENT_TYPES.DECREASE}>
                    {decreaseByTranslation}
                </Option>
            </Field>
        )
    }
    const initialValue = get(record, fieldPath)
    const initalAdjustmentType =
        initialValue < 0
            ? MULTIPLIER_ADJUSTMENT_TYPES.DECREASE
            : MULTIPLIER_ADJUSTMENT_TYPES.INCREASE

    const initialValues = {
        [fieldName]: get(record, fieldPath),
        adjustmentType: initalAdjustmentType,
    }
    return (
        <SingleValueField
            disabled={disabled}
            fieldName={fieldName}
            formatFieldValue={(values) => formatFieldValue?.(values)}
            initialValues={initialValues}
            onSave={(values) =>
                makeCerebroApiRequest({
                    request: updateRequestApi(
                        updateRequestData,
                        serializeFieldValues(values)
                    ),
                    onRequestSuccess: () => {
                        message.success(updateRequestSuccessMesg)
                        onUpdateRequestSuccess?.(values)
                    },
                })
            }
            readOnly={readOnly}
            validationSchema={validationSchema}
            getButtonClassName={getButtonClassName}
        >
            {({ isSubmitting, setFieldValue, values }) => (
                <Space>
                    {adjustmentTypeRender(isSubmitting, setFieldValue, values)}
                    <Field
                        component={FormikInputNumber}
                        disabled={isSubmitting}
                        formatter={getMetricValueFormatter('percentage')}
                        max={multiplierMax}
                        min={multiplierMin}
                        name={fieldName}
                        placeholder="Multiplier"
                        precision={multiplierPrecision}
                        step={multiplierStep}
                        handleChange={(newValue: number) =>
                            valueChange(newValue, setFieldValue, values)
                        }
                    />
                </Space>
            )}
        </SingleValueField>
    )
}

export default MultiplierField
