import { call, select } from 'redux-saga/effects'

import { makeFetchResourceSuccess } from 'actions/ui/shared/resource'
import { DSP_ORDER_PAGE } from 'const/pages'
import { cerebroApiSaga } from 'sagas/common'
import uiSagaRegistry from 'sagas/ui/registry'
import { selectResourceParamsOfPage } from 'selectors/ui'
import { getDspOrder } from 'services/cerebroApi/orgScope/dspApi'

function* fetchOrderSaga() {
    const { orderId } = yield select(selectResourceParamsOfPage, DSP_ORDER_PAGE)

    yield call(
        cerebroApiSaga,
        makeFetchResourceSuccess(DSP_ORDER_PAGE),
        getDspOrder,
        orderId
    )
}

/**
 * Mounts the DSP Order Page and fetches data
 */
function* mountPageSaga() {
    yield call(fetchOrderSaga)
}

uiSagaRegistry.registerSagas([DSP_ORDER_PAGE], {
    mountPageSaga,
})
