import { curryActionForPath } from 'helpers/curry'

import {
    downloadDataFailure,
    downloadDataRequest,
    downloadDataSuccess,
    fetchDataRequest,
    fetchDataSuccess,
    updateSorter,
} from './data'

export const makeUpdateListSorter = curryActionForPath(updateSorter)

export const makeFetchListRequest = curryActionForPath(fetchDataRequest)
export const makeFetchListSuccess = curryActionForPath(fetchDataSuccess)
export const makeDownloadListRequest = curryActionForPath(downloadDataRequest)
export const makeDownloadListSuccess = curryActionForPath(downloadDataSuccess)
export const makeDownloadListFailure = curryActionForPath(downloadDataFailure)
