import { memo, ReactElement } from 'react'

import isEqual from 'lodash/isEqual'

import { useResource } from 'components/EditableFields/localHooks'
import { ARCHIVED } from 'const/resourceStates'
import { updateTarget } from 'services/cerebroApi/orgScope/resourceApi'
import { NonKeywordTargetFact } from 'types'

import { DeleteField } from '../../SharedFields/DeleteField'

type TargetRecord = NonKeywordTargetFact

interface Props<RecordType> {
    dataIndex: string[]
    readOnly: boolean
    record: RecordType
    reloadData: () => void
}

function ArchiveTargetField<RecordType extends TargetRecord>({
    dataIndex = ['target'],
    readOnly,
    record,
    reloadData,
}: Props<RecordType>): ReactElement {
    const { getFromResource, resourceId } = useResource<RecordType>(
        record,
        dataIndex,
        'target'
    )

    const getIsDisabled = (): boolean => {
        const userCanManageTarget = getFromResource(['user_can_manage'], true)
        return !userCanManageTarget || readOnly
    }

    return (
        <DeleteField
            disabled={getIsDisabled()}
            okText="Archive"
            title="Are you sure you want to archive this target?"
            updateRequestApi={updateTarget}
            updateRequestCallback={reloadData}
            updateRequestData={resourceId}
            updateRequestParams={{ state: ARCHIVED }}
            updateRequestSuccessMesg="Target successfully archived"
        />
    )
}

export default memo(ArchiveTargetField, isEqual) as typeof ArchiveTargetField
