import { ReactElement } from 'react'

import { Popover, PopoverProps } from 'antd'
import classNames from 'classnames'

import styles from './styles.scss'

const CustomPopover = ({
    children,
    className,
    ...rest
}: PopoverProps): ReactElement => (
    <div className={classNames(styles['popover-wrapper'], className)}>
        <Popover {...rest}>{children}</Popover>
    </div>
)

export default CustomPopover
