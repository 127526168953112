import { handleActions } from 'redux-actions'

import { portfolioCampaignsTableColumnsConfig } from 'configuration/tables'
import { serializeColumns } from 'helpers/tables'
import { defaultTab, getDefaultTable } from 'reducers/ui/defaults'

const initialState = {
    ...defaultTab,
    table: getDefaultTable(
        serializeColumns(portfolioCampaignsTableColumnsConfig)
    ),
}

export default handleActions({}, initialState)
