import { ReactElement } from 'react'

import { Link } from 'react-router-dom'

import { UNDEFINED_VALUE } from 'const/formatting'
import { titleCase } from 'helpers/formatting'
import { isUnset } from 'helpers/utilities'
import { AsyncUpdate } from 'types'

interface Props {
    update: AsyncUpdate
}

const AsyncUpdateSourceType = ({ update }: Props): ReactElement => {
    const { source_type } = update
    if (isUnset(source_type)) {
        return <span>{UNDEFINED_VALUE}</span>
    }
    if (source_type === 'csv') {
        return <span>CSV</span>
    }
    if (source_type === 'rulebook' && update.change_set?.rulebook?.id) {
        return (
            <Link to={`/rulebooks/${update.change_set.rulebook.id}`}>
                Rulebook: {update.change_set.rulebook.name}
            </Link>
        )
    }
    return <span>{titleCase(source_type)}</span>
}

export default AsyncUpdateSourceType
