import mergeWith from 'lodash/fp/mergeWith'
import { normalize } from 'normalizr'
import { combineActions, handleActions } from 'redux-actions'

import {
    createAuthPageOrganizationSuccess,
    setUserIsImpersonating,
    signOutSuccess,
} from 'actions/auth'
import {
    fetchOrganizationGroupMembersSuccess,
    fetchOrganizationGroupsSuccess,
    fetchOrganizationGroupSuccess,
    fetchOrganizationMembersSuccess,
    fetchOrganizationsSuccess,
    fetchOrganizationSuccess,
} from 'actions/orgs'
import { mergeArray } from 'helpers/utilities'
import {
    organizationGroupListSchema,
    organizationGroupSchema,
    organizationListSchema,
    organizationSchema,
    userListSchema,
} from 'schemas'

const defaultState = {
    organizations: {},
    groups: {},
    users: {},
}

export default handleActions(
    {
        // organizations
        [fetchOrganizationsSuccess](state, action) {
            const {
                entities: { organizations, users },
            } = normalize(action.payload.results, organizationListSchema)

            return mergeWith(mergeArray, state, {
                organizations,
                users,
            })
        },
        [combineActions(
            fetchOrganizationSuccess,
            createAuthPageOrganizationSuccess
        )](state, action) {
            const {
                entities: { organizations, users },
            } = normalize(action.payload, organizationSchema)

            return mergeWith(mergeArray, state, {
                organizations,
                users,
            })
        },

        // groups
        [fetchOrganizationGroupSuccess](state, action) {
            const {
                entities: { groups, organizations, users },
            } = normalize(action.payload, organizationGroupSchema)

            return mergeWith(mergeArray, state, {
                groups,
                organizations,
                users,
            })
        },
        [fetchOrganizationGroupsSuccess](state, action) {
            const {
                entities: { groups, users, organizations },
            } = normalize(action.payload.results, organizationGroupListSchema)

            return mergeWith(mergeArray, state, {
                groups,
                organizations,
                users,
            })
        },

        // members
        [fetchOrganizationMembersSuccess](state, action) {
            const { members, organizationId } = action.payload
            if (members) {
                const {
                    entities: { users },
                    result,
                } = normalize(members, userListSchema)

                return mergeWith(mergeArray, state, {
                    users,
                    organizations: {
                        [organizationId]: {
                            members: result,
                        },
                    },
                })
            }
            return state
        },
        [fetchOrganizationGroupMembersSuccess](state, action) {
            const { members, organizationGroupId } = action.payload
            const {
                entities: { users },
                result,
            } = normalize(members, userListSchema)

            return mergeWith(mergeArray, state, {
                users,
                groups: {
                    [organizationGroupId]: {
                        members: result,
                    },
                },
            })
        },
        // Impersonation Mode, reset org state
        [setUserIsImpersonating]() {
            return defaultState
        },

        // sign out
        [signOutSuccess]() {
            return defaultState
        },
    },
    defaultState
)
