import { memo, ReactElement, useState, SyntheticEvent } from 'react'

import classNames from 'classnames'
import isEqual from 'lodash/isEqual'
import { CellType } from 'rc-table/lib/interface'
import { Resizable } from 'react-resizable'

import styles from './styles.scss'
import { OnHeaderCellProps } from '../../localTypes'

type Props<RecordType> = CellType<RecordType> &
    OnHeaderCellProps<RecordType> & {
        onClick: (e: SyntheticEvent) => void
    }

function HeaderCell<T extends object>({
    isResizeable,
    width,
    onResize,
    onResizeStop,
    className,
    onClick,
    ...restProps
}: Props<T>): ReactElement {
    const [allowClick, setAllowClick] = useState(true)

    const handleClick = (e: SyntheticEvent): void => {
        if (allowClick && onClick) {
            onClick(e)
        }
    }

    const th = (
        <th
            className={classNames(className, styles['header-cell'])}
            onClick={handleClick}
            {...restProps}
        />
    )

    if (isResizeable) {
        return (
            <Resizable
                width={width ?? 0}
                height={0}
                onResize={onResize}
                onResizeStart={() => {
                    setAllowClick(false)
                }}
                onResizeStop={(e, data) => {
                    onResizeStop(e, data)
                    setTimeout(() => {
                        setAllowClick(true)
                    })
                }}
                draggableOpts={{ enableUserSelectHack: false }}
            >
                {th}
            </Resizable>
        )
    }

    return th
}

export default memo(HeaderCell, isEqual)
