import { ReactElement } from 'react'

import classNames from 'classnames'
import { Link, generatePath } from 'react-router-dom'

import { getProductTitleOutsideThemeContext } from 'appConfig'
import {
    DashboardDescriptionField,
    DeleteDashboardField,
} from 'components/EditableFields'
import { DownstreamLogoIcon, BuildingIcon } from 'components/Icons'
import { ToolTip } from 'components/ToolTip'
import { UNDEFINED_VALUE } from 'const/formatting'
import { DASHBOARD_PAGE } from 'const/pages'
import { getPath } from 'helpers/pages'
import { isUnset } from 'helpers/utilities'
import { Dashboard, CellRenderProps } from 'types'
import styles from 'views/DashboardsSummaryPage/styles.scss'

type RenderProps<RecordType> = {
    cellRenderProps: CellRenderProps<RecordType>
    dataIndex?: string[]
}

export function dashboardNameRenderer<RecordType extends Dashboard>({
    cellRenderProps: { value, record },
}: RenderProps<RecordType>): ReactElement {
    if (isUnset(value)) {
        return <span>{UNDEFINED_VALUE}</span>
    }

    return (
        <Link
            to={{
                pathname: generatePath(getPath(DASHBOARD_PAGE), {
                    dashboardId: record.id,
                }),
                search: `?tab=${record.config.tabs[0].id}`,
            }}
        >
            <div className="d-flex align-items-center">
                {record.global_access && (
                    <DownstreamLogoIcon
                        className={classNames('mr-2', styles.logo)}
                    />
                )}
                {record.agency_access && !record.global_access && (
                    <ToolTip
                        arrow={{ pointAtCenter: true }}
                        placement="top"
                        title="Available through the Agency"
                    >
                        <BuildingIcon
                            className={classNames('mr-2', styles.logo)}
                        />
                    </ToolTip>
                )}
                <div>{value}</div>
            </div>
        </Link>
    )
}

export function dashboardDescriptionRenderer<RecordType extends Dashboard>({
    cellRenderProps: { record, readonly },
    dataIndex = ['description'],
}: RenderProps<RecordType>): ReactElement {
    return (
        <DashboardDescriptionField
            dataIndex={dataIndex}
            record={record}
            readOnly={readonly}
        />
    )
}

export function dashboardSharingRenderer<RecordType extends Dashboard>({
    cellRenderProps: { record },
}: RenderProps<RecordType>): ReactElement {
    const productTitle = getProductTitleOutsideThemeContext()

    if (record.global_access) {
        return (
            <span>
                <strong>Managed by {productTitle}</strong>
            </span>
        )
    }
    if (record.agency_access) {
        return (
            <span>
                <strong>Managed by Agency</strong>
            </span>
        )
    }
    if (record.org_wide_permission === 'read') {
        return (
            <span>
                <strong>Everyone in Organization</strong> can{' '}
                <strong>View</strong>
            </span>
        )
    }
    if (record.org_wide_permission === 'write') {
        return (
            <span>
                <strong>Everyone in Organization</strong> can{' '}
                <strong>Edit</strong>
            </span>
        )
    }
    const readUserCount = (record.read_users ?? []).length
    const writeUserCount = (record.write_users ?? []).length
    if (readUserCount > 0 && writeUserCount > 0) {
        return (
            <span>
                <strong>Custom Permissions</strong> (
                {(record.read_users ?? []).length} Read and{' '}
                {(record.write_users ?? []).length} Write)
            </span>
        )
    }
    if (readUserCount > 0) {
        return (
            <span>
                <strong>Custom Permissions</strong> (
                {(record.read_users ?? []).length} Read)
            </span>
        )
    }
    if (writeUserCount > 0) {
        return (
            <span>
                <strong>Custom Permissions</strong> (
                {(record.write_users ?? []).length} Write)
            </span>
        )
    }
    return (
        <span>
            <strong>Private</strong> (Only Me)
        </span>
    )
}

export function deleteDashboardRenderer<RecordType extends Dashboard>({
    cellRenderProps: { record, readonly, reloadData },
}: RenderProps<RecordType>): ReactElement {
    return (
        <DeleteDashboardField
            record={record}
            readOnly={readonly}
            reloadData={reloadData}
        />
    )
}
