import { QuestionCircleOutlined } from '@ant-design/icons'
import { Translation } from 'react-i18next'

import { AsinLink } from 'components/Links'
import { ToolTip } from 'components/ToolTip'
import { UNDEFINED_VALUE } from 'const/formatting'
import { isUnset } from 'helpers/utilities'
import { CellRenderProps, Field } from 'types'

import { createField, createTitleField } from './createField'
import { FieldCreatorOptions } from './localTypes'
import {
    makeCountMetricFieldFunc,
    makePercentageMetricFieldFunc,
} from './metrics/localUtils'
import translations from './metrics/translations/ams'

export function attributionType<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['attribution_type']
    return createTitleField({
        ...options,
        id: 'attribution_type',
        name: 'Attribution Type',
        shortName: 'Attribution Type',
        minWidth: 50,
        dataIndex,
        width: 100,
    })
}

export function productId<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createField({
        ...options,
        id: options.id ?? 'product_id',
        name: 'ASIN',
        shortName: 'ASIN',
        minWidth: 80,
        dataIndex: options.dataIndex ?? ['product_id'],
        renderOptions: {
            render: ({ value }: CellRenderProps<any>) => {
                if (isUnset(value)) {
                    return <span>{UNDEFINED_VALUE}</span>
                }
                const [countryCode, asin] = value.split('_')
                return <AsinLink asin={asin} countryCode={countryCode} />
            },
        },
        width: 100,
    })
}

export function product_category<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['product_category']
    return createField({
        ...options,
        id: 'product_category',
        name: 'Product Category',
        shortName: 'Category',
        minWidth: 100,
        dataIndex,
        columnTitle: (
            <>
                Product Category{' '}
                <ToolTip title="The number next to the category represents the category ID">
                    <QuestionCircleOutlined />
                </ToolTip>
            </>
        ),
        width: 150,
    })
}

export const attributed_orders_14_day__sum = makeCountMetricFieldFunc({
    deltas: ['change', 'percentage_change'],
    id: 'attributed_orders_14_day__sum',
    name: (
        <Translation>
            {(t) =>
                t(
                    'metrics:attributed_orders_14_day__sum.name',
                    'Attributed Orders (14 Day)'
                )
            }
        </Translation>
    ),
    shortName: (
        <Translation>
            {(t) =>
                t(
                    'metrics:attributed_orders_14_day__sum.shortName',
                    'Orders (14 Day)'
                )
            }
        </Translation>
    ),
    localDefinition: (
        <Translation>
            {(t) =>
                t(
                    'metrics:attributed_orders_14_day__sum.helpText',
                    'Number of orders within the last 14 days.'
                )
            }
        </Translation>
    ),
})

export const attributed_units_sold_new_to_brand_14_day__sum =
    makeCountMetricFieldFunc({
        deltas: ['change', 'percentage_change'],
        id: 'attributed_units_sold_new_to_brand_14_day__sum',
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:attributed_units_sold_new_to_brand_14_day__sum.name',
                        'New-To-Brand (NTB) Units Sold (14 Day)'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:attributed_units_sold_new_to_brand_14_day__sum.shortName',
                        'NTB Units Sold (14 Day)'
                    )
                }
            </Translation>
        ),
        localDefinition: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:attributed_units_sold_new_to_brand_14_day__sum.helpText',
                        'Total number of attributed units ordered as part of new-to-brand sales occurring within 14 days of an ad click. Not available for book vendors.'
                    )
                }
            </Translation>
        ),
    })

export const attributed_sales_new_to_brand_percentage_14_day_avg =
    makePercentageMetricFieldFunc({
        name: translations.attributed_sales_new_to_brand_percentage_14_day.name,
        shortName:
            translations.attributed_sales_new_to_brand_percentage_14_day.name,
        type: 'percentage_as_is',
        deltas: ['change'],
        id: 'attributed_sales_new_to_brand_percentage_14_day__avg',
    })

export const attributed_orders_new_to_brand_percentage_14_day__avg =
    makePercentageMetricFieldFunc({
        name: translations.attributed_orders_new_to_brand_percentage_14_day
            .name,
        shortName:
            translations.attributed_orders_new_to_brand_percentage_14_day.name,
        type: 'percentage_as_is',
        deltas: ['change'],
        id: 'attributed_orders_new_to_brand_percentage_14_day__avg',
    })

export const attributed_units_ordered_new_to_brand_percentage_14_day__avg =
    makePercentageMetricFieldFunc({
        name: translations
            .attributed_units_ordered_new_to_brand_percentage_14_day.name,
        shortName:
            translations.attributed_units_ordered_new_to_brand_percentage_14_day
                .name,
        type: 'percentage_as_is',
        deltas: ['change'],
        id: 'attributed_units_ordered_new_to_brand_percentage_14_day__avg',
    })
