import { call, select } from 'redux-saga/effects'

import {
    makeFetchResourceSuccess,
    makeUpdateResourceSuccess,
} from 'actions/ui/shared/resource'
import { KEYWORD_PAGE } from 'const/pages'
import { cerebroApiSaga } from 'sagas/common'
import uiSagaRegistry from 'sagas/ui/registry'
import { selectResourceParamsOfPage } from 'selectors/ui'
import {
    getKeyword,
    updateKeyword,
} from 'services/cerebroApi/orgScope/resourceApi'

function* updateKeywordSaga({ keywordId }, params) {
    yield call(
        cerebroApiSaga,
        makeUpdateResourceSuccess(KEYWORD_PAGE),
        updateKeyword,
        keywordId,
        params
    )
}

function* fetchKeywordSaga() {
    const { keywordId } = yield select(selectResourceParamsOfPage, KEYWORD_PAGE)

    yield call(
        cerebroApiSaga,
        makeFetchResourceSuccess(KEYWORD_PAGE),
        getKeyword,
        keywordId
    )
}

/**
 * Mounts the Ad Group Page and fetches data
 */
function* mountPageSaga() {
    yield call(fetchKeywordSaga)
}

uiSagaRegistry.registerSagas([KEYWORD_PAGE], {
    mountPageSaga,
    updateResourceSaga: updateKeywordSaga,
})
