import { Translation } from 'react-i18next'

import {
    walmartAdGroupStateRenderer,
    wmAdGroupNameRenderer,
} from 'components/FieldRenderers/Renderers'
import { Field, WalmartAdGroupGroupBy } from 'types'

import { createField } from './createField'
import { FieldCreatorOptions } from './localTypes'

export function adGroupName<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['name']
    const name = (
        <Translation>{(t) => t('common:adGroup', 'Ad Group')}</Translation>
    )
    return createField({
        ...options,
        id: 'walmart_ad_group_name',
        name,
        shortName: name,
        minWidth: 200,
        isTotalSupported: options.isTotalSupported ?? true,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: (props) => wmAdGroupNameRenderer<any>(props),
        },
        width: 300,
    })
}

export function adGroupState(
    options: FieldCreatorOptions<WalmartAdGroupGroupBy> = {}
): Field<WalmartAdGroupGroupBy> {
    const dataIndex = options.dataIndex ?? ['state']
    const name = (
        <Translation>
            {(t) => t('table:fields.adGroupState.name', 'Ad Group State')}
        </Translation>
    )
    const shortName = (
        <Translation>{(t) => t('common:state', 'State')}</Translation>
    )
    return createField({
        ...options,
        id: 'walmart_ad_group_state',
        name,
        shortName,
        minWidth: 85,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: ({ record }) =>
                walmartAdGroupStateRenderer({
                    record,
                    dataIndex,
                }),
        },
        width: 150,
    })
}
