import { Organization, OrganizationTheme } from 'types'

export const getOrganizationTheme = (
    organization?: Organization
): OrganizationTheme | null => {
    if (organization) {
        if (organization.agency && typeof organization.agency === 'object') {
            return organization.agency.theme
        }
        return organization.theme
    }
    return null
}
