// create a react compoment
import { ReactElement } from 'react'

import { Redirect } from 'react-router-dom'

import { SIGN_UP_MFA_SETUP_PAGE } from 'const/pages'
import { useUserContext } from 'context/UserContext'
import { getPath } from 'helpers/pages'

function MfaCheck(): ReactElement | null {
    const userContext = useUserContext()

    const needsToSetUpMfa = userContext.userMfaSettings?.needsToSetUpMfa

    const setupPath = getPath(SIGN_UP_MFA_SETUP_PAGE)

    if (needsToSetUpMfa) {
        return <Redirect to={setupPath} />
    }

    return null
}

export default MfaCheck
