import { ReactElement } from 'react'

import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import styles from './styles.scss'

const propTypes = {
    size: PropTypes.oneOf(['x_small', 'small', 'medium', 'large']),
    style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    fullContent: PropTypes.bool,
}

const defaultProps = {
    size: 'large',
    style: null,
    fullContent: true,
}

type Size = 'x_small' | 'small' | 'medium' | 'large'

const SIZE_MAP: Record<Size, number> = {
    x_small: 20,
    small: 50,
    medium: 80,
    large: 100,
}

interface LoadingIndicatorProps {
    size: Size
    style?: React.CSSProperties
    fullContent?: boolean
}

const LoadingIndicator = (props: LoadingIndicatorProps): ReactElement => {
    const { size, style, fullContent } = props
    return (
        <div
            className={classNames(styles['spin-wrapper'], {
                [styles['full-content']]: fullContent,
            })}
            style={style}
        >
            <Spin
                indicator={
                    <LoadingOutlined
                        spin
                        style={{ fontSize: SIZE_MAP[size] }}
                    />
                }
            />
        </div>
    )
}

LoadingIndicator.propTypes = propTypes
LoadingIndicator.defaultProps = defaultProps

export default LoadingIndicator
