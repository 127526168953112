import { ReactElement } from 'react'

import classNames from 'classnames'
import { useFormikContext } from 'formik'
import every from 'lodash/every'
import { useTranslation } from 'react-i18next'

import { ColumnConfig, FormValues } from './localTypes'
import styles from './styles.scss'

interface Props<AdItemField> {
    adItems: AdItemField[]
    columnsConfig: ColumnConfig[]
}

function CreateAdItemFormAllRow<AdItemField extends Record<string, any>>({
    adItems = [],
    columnsConfig,
}: Props<AdItemField>): ReactElement {
    const { t } = useTranslation('common')
    const { setValues } = useFormikContext<FormValues<AdItemField>>()

    const getInitialValue = (dataKey: string): string | undefined => {
        const adItem: Record<string, any> = adItems[0]

        return every(adItems, [dataKey, adItem[dataKey]])
            ? adItem[dataKey]
            : undefined
    }

    const handleChange = (val: string | number, field: string): void => {
        setValues({
            adItems: adItems.map((adItem) => ({
                ...adItem,
                ...{ [field]: val },
            })),
        })
    }

    return (
        <div
            className={classNames(
                'd-flex align-items-start',
                styles['bulk-edit-row']
            )}
        >
            <div
                style={{ flex: 1 }}
                className={`mr-1 flex-1 fg-text-light ${styles['text-field']}`}
            >
                <em>
                    {t('common:applyChangesInBulk', 'Apply changes in bulk')}:
                </em>
            </div>

            {columnsConfig.map(({ renderBulkField, dataKey, width }) => {
                const initialValue = getInitialValue(dataKey)

                return (
                    <div
                        className={classNames(
                            'd-flex flex-column align-items-center mr-1',
                            styles.field
                        )}
                        style={{
                            width,
                        }}
                        key={dataKey}
                    >
                        {renderBulkField(initialValue, (val) => {
                            handleChange(val, dataKey)
                        })}
                    </div>
                )
            })}

            <div
                className={classNames(
                    'd-flex flex-column align-items-center mr-1',
                    styles.field,
                    styles['remove-column']
                )}
            />
        </div>
    )
}

export default CreateAdItemFormAllRow
