import {
    acos,
    amazon_sales,
    aov,
    attributed_conversions_14_day__sum,
    attributed_conversions_14_day_same_sku__sum,
    attributed_conversions_1_day__sum,
    attributed_conversions_1_day_same_sku__sum,
    attributed_conversions_30_day__sum,
    attributed_conversions_30_day_same_sku__sum,
    attributed_conversions_7_day__sum,
    attributed_conversions_7_day_same_sku__sum,
    attributed_sales_14_day__sum,
    attributed_sales_14_day_same_sku__sum,
    attributed_sales_14_day_same_sku_rate,
    attributed_sales_1_day__sum,
    attributed_sales_1_day_same_sku__sum,
    attributed_sales_30_day__sum,
    attributed_sales_30_day_same_sku__sum,
    attributed_sales_7_day__sum,
    attributed_sales_7_day_same_sku__sum,
    attributed_units_ordered_14_day__sum,
    attributed_units_ordered_14_day_same_sku__sum,
    attributed_units_ordered_1_day__sum,
    attributed_units_ordered_1_day_same_sku__sum,
    attributed_units_ordered_30_day__sum,
    attributed_units_ordered_30_day_same_sku__sum,
    attributed_units_ordered_7_day__sum,
    attributed_units_ordered_7_day_same_sku__sum,
    clicks__sum,
    conversion_rate_clicks,
    conversion_rate_impressions,
    cost__sum,
    cpa,
    cpc,
    cpm,
    ctr,
    impressions__sum,
    roas,
    roas_same_sku,
    roasp,
    spc,
    spend,
    spend__daily_avg,
    spend__daily_avg_forecast,
    spend__daily_avg_forecast_difference_from_goal,
    spend__daily_avg_forecast_pct_difference_from_goal,
    spend__difference_from_goal,
    spend__difference_from_goal_per_day,
    spend__goal,
    spend__pct_difference_from_goal,
    spend__t3_daily_avg,
    spend__t3_daily_avg_forecast,
    spend__t3_daily_avg_forecast_difference_from_goal,
    spend__t3_daily_avg_forecast_pct_difference_from_goal,
    spend__t7_daily_avg,
    spend__t7_daily_avg_forecast,
    spend__t7_daily_avg_forecast_difference_from_goal,
    spend__t7_daily_avg_forecast_pct_difference_from_goal,
    spm,
} from 'configuration/fieldCreators/metrics/ams'

import { makeMapping } from './helpers'

export const TARGET_QUERY_SUPPORTED_METRICS_COLUMNS_PAGES = [
    clicks__sum({ isVisible: true }),
    impressions__sum(),
    cost__sum({ isVisible: true }),
    roas(),
    roas_same_sku(),
    roasp(),
    acos({ isVisible: true }),
    spc(),
    cpc(),
    cpa(),
    cpm(),
    spm(),
    aov(),
    ctr(),
    conversion_rate_clicks(),
    conversion_rate_impressions(),
    attributed_conversions_14_day__sum(),
    attributed_conversions_14_day_same_sku__sum(),
    attributed_sales_14_day__sum({ isVisible: true }),
    amazon_sales(),
    attributed_sales_14_day_same_sku__sum(),
    attributed_units_ordered_14_day__sum(),
    attributed_units_ordered_14_day_same_sku__sum(),
    attributed_sales_14_day_same_sku_rate(),
    spend(),
    spend__difference_from_goal(),
    spend__pct_difference_from_goal(),
    spend__difference_from_goal_per_day(),
    spend__goal(),
    spend__daily_avg(),
    spend__daily_avg_forecast(),
    spend__daily_avg_forecast_difference_from_goal(),
    spend__daily_avg_forecast_pct_difference_from_goal(),
    spend__t3_daily_avg(),
    spend__t3_daily_avg_forecast(),
    spend__t3_daily_avg_forecast_difference_from_goal(),
    spend__t3_daily_avg_forecast_pct_difference_from_goal(),
    spend__t7_daily_avg(),
    spend__t7_daily_avg_forecast(),
    spend__t7_daily_avg_forecast_difference_from_goal(),
    spend__t7_daily_avg_forecast_pct_difference_from_goal(),
    attributed_conversions_1_day__sum(),
    attributed_conversions_1_day_same_sku__sum(),
    attributed_conversions_7_day__sum(),
    attributed_conversions_7_day_same_sku__sum(),
    attributed_conversions_30_day__sum(),
    attributed_conversions_30_day_same_sku__sum(),
    attributed_units_ordered_1_day__sum(),
    attributed_units_ordered_1_day_same_sku__sum(),
    attributed_units_ordered_7_day__sum(),
    attributed_units_ordered_7_day_same_sku__sum(),
    attributed_units_ordered_30_day__sum(),
    attributed_units_ordered_30_day_same_sku__sum(),
    attributed_sales_1_day__sum(),
    attributed_sales_1_day_same_sku__sum(),
    attributed_sales_7_day__sum(),
    attributed_sales_7_day_same_sku__sum(),
    attributed_sales_30_day__sum(),
    attributed_sales_30_day_same_sku__sum(),
]

export const TARGET_QUERY_SUPPORTED_AMS_METRICS_CONFIG = makeMapping(
    TARGET_QUERY_SUPPORTED_METRICS_COLUMNS_PAGES
)
