const ACCESS_TOKEN_DEFAULT_TTL_SECONDS = 3590

export interface AccessToken {
    value: string
    ttl: number
}

export function createStorableAccessToken(
    value: string,
    ttl_seconds: number = ACCESS_TOKEN_DEFAULT_TTL_SECONDS
): AccessToken {
    const currentTimestamp = new Date().getTime()
    const ttlEpochTime = currentTimestamp + ttl_seconds * 1000
    return {
        value,
        ttl: ttlEpochTime,
    }
}
