import { createAction } from 'redux-actions'

import { curryActionForPath } from 'helpers/curry'

// Fetch bid recommendations
export const fetchSPBidRecommendationsSuccess = createAction(
    'FETCH_SP_BID_RECOMMENDATIONS_SUCCESS'
)
export const fetchSDBidRecommendationsSuccess = createAction(
    'FETCH_SD_BID_RECOMMENDATIONS_SUCCESS'
)
export const fetchSBBidRecommendationsSuccess = createAction(
    'FETCH_SB_BID_RECOMMENDATIONS_SUCCESS'
)
const fetchBidRecommendationsFailure = createAction(
    'FETCH_BID_RECOMMENDATIONS_FAILURE'
)

export const makeFetchSPBidRecommendationsSuccess = curryActionForPath(
    fetchSPBidRecommendationsSuccess
)
export const makeFetchSDBidRecommendationsSuccess = curryActionForPath(
    fetchSDBidRecommendationsSuccess
)
export const makeFetchSBBidRecommendationsSuccess = curryActionForPath(
    fetchSBBidRecommendationsSuccess
)
export const makeFetchBidRecommendationsFailure = curryActionForPath(
    fetchBidRecommendationsFailure
)

// Apply suggested bid
export const applySuggestedBidRequest = createAction(
    'APPLY_SUGGESTED_BID_REQUEST'
)
export const applySuggestedBidSuccess = createAction(
    'APPLY_SUGGESTED_BID_SUCCESS'
)
export const applySuggestedBidFailure = createAction(
    'APPLY_SUGGESTED_BID_FAILURE'
)

export const makeApplySuggestedBidSuccess = curryActionForPath(
    applySuggestedBidSuccess
)
export const makeApplySuggestedBidFailure = curryActionForPath(
    applySuggestedBidFailure
)
