import classNames from 'classnames'
import { getIn } from 'formik'

import MomentDatePicker from 'components/MomentDatePicker'

import { FormikWithCustomHandlersPropTypes } from '../propTypes'
import withCustomHandlers from '../withCustomHandlers'

const FormikDatePicker = ({
    field: { value, name },
    form, // eslint-disable-line
    handleChange,
    handleBlur,
    handleClick, // eslint-disable-line
    minDate,
    maxDate,
    ...props
}) => (
    <MomentDatePicker
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        disabledDate={(current) =>
            current &&
            ((minDate && current.isBefore(minDate, 'day')) ||
                (maxDate && current.isAfter(maxDate, 'day')))
        }
        className={classNames({
            'fg-control-error':
                getIn(form.errors, name) && getIn(form.touched, name),
        })}
        {...props}
    />
)

FormikDatePicker.propTypes = FormikWithCustomHandlersPropTypes

export default withCustomHandlers(FormikDatePicker)
