import isEmpty from 'lodash/isEmpty'

import { LABEL_TEXT } from 'const/filters'

export const formatFilters = (filters) => {
    const params = {}

    if (!isEmpty(filters[LABEL_TEXT])) {
        params.name__icontains = filters[LABEL_TEXT]
    }

    return params
}
