import { handleActions } from 'redux-actions'

import { campaignTargetSearchTermsTableColumnsConfig } from 'configuration/tables'
import { DATES, METRIC_FILTERS, TARGET_SEARCH_TEXT } from 'const/filters'
import { FILTER_SETTINGS, LOCAL_FILTERS } from 'const/reducerKeys'
import { serializeColumns } from 'helpers/tables'
import { defaultTab, getDefaultTable } from 'reducers/ui/defaults'

const initialState = {
    ...defaultTab,
    [LOCAL_FILTERS]: {
        [TARGET_SEARCH_TEXT]: null,
        [METRIC_FILTERS]: [],
    },
    [FILTER_SETTINGS]: {
        anchored: [TARGET_SEARCH_TEXT, DATES],
        order: [METRIC_FILTERS],
        displayState: {
            [METRIC_FILTERS]: true,
        },
    },
    table: getDefaultTable(
        serializeColumns(campaignTargetSearchTermsTableColumnsConfig),
        {
            showTotalRow: true,
        }
    ),
}

export default handleActions({}, initialState)
