import { makeCountMetricFieldFunc } from './localUtils'

export const count = makeCountMetricFieldFunc({
    name: 'Change Count',
    shortName: 'Changes',
    deltas: [],
    id: 'count',
})

export const estimated_seconds_saved = makeCountMetricFieldFunc({
    name: 'Estimated Time Saved (Seconds)',
    shortName: 'Seconds Saved',
    precise: true,
    deltas: [],
    id: 'estimated_seconds_saved',
    localDefinition:
        'Seconds Saved metric is calculated based on the total number of automated operation and the average time needed to complete each operation manually.',
})

export const estimated_minutes_saved = makeCountMetricFieldFunc({
    name: 'Estimated Time Saved (Minutes)',
    shortName: 'Minutes Saved',
    precise: true,
    deltas: [],
    id: 'estimated_minutes_saved',
    localDefinition:
        'Minutes Saved metric is calculated based on the total number of automated operation and the average time needed to complete each operation manually.',
})

export const estimated_hours_saved = makeCountMetricFieldFunc({
    name: 'Estimated Time Saved (Hours)',
    shortName: 'Hours Saved',
    precise: true,
    deltas: [],
    id: 'estimated_hours_saved',
    localDefinition:
        'Hours Saved metric is calculated based on the total number of automated operation and the average time needed to complete each operation manually.',
})

export const estimated_days_saved = makeCountMetricFieldFunc({
    name: 'Estimated Time Saved (Days)',
    shortName: 'Days Saved',
    precise: true,
    deltas: [],
    id: 'estimated_days_saved',
    localDefinition:
        'Days Saved metric is calculated based on the total number of automated operation and the average time needed to complete each operation manually.',
})
