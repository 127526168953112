import { handleActions } from 'redux-actions'

import { LOCAL_FILTERS, FILTER_SETTINGS } from 'const/reducerKeys'

import { defaultPage } from '../defaults'

const defaultState = {
    [LOCAL_FILTERS]: {},
    [FILTER_SETTINGS]: {},

    ...defaultPage,
}

export default handleActions({}, defaultState)
