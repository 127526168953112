/* eslint-disable import/no-import-module-exports */
import { composeWithDevTools } from '@redux-devtools/extension'
import { captureException } from '@sentry/browser'
import {
    applyMiddleware,
    legacy_createStore as createStore,
    Reducer,
} from 'redux'
import { Persistor, persistStore } from 'redux-persist'
import createSagaMiddleware from 'redux-saga'

import { asyncReducerHydrated } from 'actions/ui/app'
import { DynamicReducerKey, DynamicReducerOptions } from 'reducers/ui/ui'

import { sentryMiddleware } from './middlewares'
import reactotron from './reactotron'
import { buildRootReducer, injectReducer as injectUiReducer } from './reducers'
import SagaManager from './sagas/SagaManager'
import storeRegistry from './utilities/storeRegistry'

const sagaMonitor = reactotron.createSagaMonitor?.()

// create saga middleware
const sagaMiddleware = createSagaMiddleware({
    onError: (error: Error, errorInfo: any) => {
        captureException(error, errorInfo)
    },
    ...(sagaMonitor ? { sagaMonitor } : {}),
})

// create redux store with redux tools in development and beta stages
const store = createStore(
    buildRootReducer(),
    {},
    composeWithDevTools({ trace: true })(
        ...[
            applyMiddleware(sagaMiddleware, sentryMiddleware),
            ...(sagaMonitor ? [(reactotron as any).createEnhancer()] : []),
        ]
    )
)

// redux persist
let persistor = persistStore(store)

let persistStorePromise: ((persistor: Persistor) => any) | null = null

const injectReducerToStore = (
    key: DynamicReducerKey,
    reducer: Reducer<any, any>,
    options: DynamicReducerOptions = {}
): void => {
    injectUiReducer(key, reducer, options)
    store.replaceReducer(buildRootReducer())
    persistor = persistStore(store, null, () => {
        store.dispatch(asyncReducerHydrated({ key }))
    })
    if (persistStorePromise) {
        persistStorePromise(persistor)
    }
}

// run sagas
SagaManager.startSagas(sagaMiddleware)

// register store for access outside of components while avoid circular references
storeRegistry.registerStore(store)

if (module.hot) {
    module.hot.accept('./reducers', () => {
        // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require
        const newReducers = require('./reducers')
        store.replaceReducer(newReducers.buildRootReducer())
    })

    module.hot.accept('./sagas/SagaManager', () => {
        SagaManager.cancelSagas(store)
        // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require
        const newSagaManager = require('./sagas/SagaManager')
        newSagaManager.default.startSagas(sagaMiddleware)
    })
}

const getPersistor = (): Persistor => persistor

const subscribeToPersistor = (
    callback: (persistor: Persistor) => any
): void => {
    persistStorePromise = callback
}
const unsubscribeFromPersistor = (): void => {
    persistStorePromise = null
}

export {
    store,
    getPersistor,
    injectReducerToStore,
    subscribeToPersistor,
    unsubscribeFromPersistor,
}
