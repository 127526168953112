import { call, put, select } from 'redux-saga/effects'

import { makeFetchTableRequest, makeFetchTableSuccess } from 'actions/ui/shared'
import { CAMPAIGN_PAGE } from 'const/pages'
import { formatFilters } from 'helpers/filters/changelog'
import { cerebroApiSaga } from 'sagas/common'
import uiSagaRegistry from 'sagas/ui/registry'
import {
    selectResourceParamsOfPage,
    selectVisibleCombinedFilters,
} from 'selectors/ui'
import { getChangelog } from 'services/cerebroApi/orgScope/resourceApi'

const TAB_PATH = [CAMPAIGN_PAGE, 'budget']
const HISTORY_CHART_PATH = [...TAB_PATH, 'history']

/**
 * Fetch table data
 */
function* fetchHistoryChartSaga() {
    const { campaignId } = yield select(
        selectResourceParamsOfPage,
        CAMPAIGN_PAGE
    )
    const filters = yield select(selectVisibleCombinedFilters, TAB_PATH)

    const params = {
        ...formatFilters(filters),
        limit: 10000,
        offset: 0,
        ordering: 'date',
        object_type: 'campaign',
        object_id: campaignId,
        changed_fields_in: 'base_budget,budget',
    }

    yield call(
        cerebroApiSaga,
        makeFetchTableSuccess(HISTORY_CHART_PATH),
        getChangelog,
        params,
        { headers: { noCount: true } }
    )
}

/**
 * Fetch all data for tab
 */
function* fetchTabDataSaga() {
    yield put(makeFetchTableRequest(HISTORY_CHART_PATH)())
}

uiSagaRegistry.registerSagas(TAB_PATH, {
    fetchTabDataSaga,
})

uiSagaRegistry.registerSagas(HISTORY_CHART_PATH, {
    fetchTableSaga: fetchHistoryChartSaga,
})
