import { Translation } from 'react-i18next'

import {
    adGroupIdRenderer,
    adGroupNameRenderer,
    adGroupStateRenderer,
    adGroupActiveBidRenderer,
    archiveAdGroupRenderer,
} from 'components/FieldRenderers/Renderers/adGroup'
import { Field } from 'types'

import { createActionField, createDateField, createField } from './createField'
import { FieldCreatorOptions } from './localTypes'

export function adGroupId<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createField({
        ...options,
        id: 'ad_group_id',
        name: 'Ad Group',
        dataIndex: options.dataIndex ?? ['ad_group', 'id'],
        shortName: 'Ad Group',
        minWidth: 80,
        renderOptions: options.renderOptions ?? {
            render: (props) => adGroupIdRenderer<any>(props),
        },
        width: 300,
    })
}

export function adGroupName<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createField({
        ...options,
        id: 'ad_group_name',
        name: 'Ad Group Name',
        shortName: 'Name',
        dataIndex: options.dataIndex ?? ['ad_group', 'name'],
        minWidth: 90,
        renderOptions: options.renderOptions ?? {
            render: (props) => adGroupNameRenderer<any>(props),
        },
        width: 160,
    })
}

export function adGroupState<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['ad_group', 'state']
    return createField({
        ...options,
        id: 'ad_group__state',
        name: 'Ad Group State',
        shortName: 'State',
        minWidth: 80,
        dataIndex: options.dataIndex ?? ['ad_group', 'state'],
        renderOptions: options.renderOptions ?? {
            render: (cellRenderProps) =>
                adGroupStateRenderer<any>({ cellRenderProps, dataIndex }),
        },
        columnTitle: 'Ad Group State',
        width: 150,
    })
}

export function adGroupBid<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createField({
        ...options,
        id: 'ad_group__default_bid',
        name: 'Ad Group Default Bid',
        shortName: 'Default Bid',
        dataIndex: options.dataIndex ?? ['ad_group', 'base_default_bid'],
        minWidth: 80,
        width: 100,
        sorter: options.sorter ?? false,
    })
}

export function adGroupActiveBid<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createField({
        ...options,
        id: 'ad_group__active_bid',
        name: 'Ad Group Active Default Bid',
        shortName: 'Active Default Bid',
        dataIndex: options.dataIndex ?? ['ad_group', 'active_default_bid'],
        renderOptions: options.renderOptions ?? {
            render: (props) => adGroupActiveBidRenderer<any>(props),
        },
        minWidth: 80,
        width: 100,
        align: 'right',
        sorter: options.sorter ?? false,
    })
}

export function adGroupCreatedDate<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const name = (
        <Translation>
            {(t) => t('common:createdDate', 'Created Date')}
        </Translation>
    )
    return createDateField({
        ...options,
        id: 'ad_group_created_date',
        name,
        shortName: 'Created Date',
        dataIndex: options.dataIndex ?? ['ad_group', 'created_date'],
        minWidth: 80,
        isVisible: options.isVisible ?? false,
        width: 110,
    })
}

export function archiveAdGroup<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['ad_group', 'id']
    return createActionField({
        ...options,
        id: 'archive_ad_group',
        dataIndex,
        minWidth: 68,
        isVisible: true,
        renderOptions: options.renderOptions ?? {
            render: (cellRenderProps) =>
                archiveAdGroupRenderer<any>({ cellRenderProps, dataIndex }),
        },
        width: 68,
        sorter: false,
        fixed: 'right',
        align: 'center',
    })
}
