import { CurrencyMap } from 'types'

export const defaultCurrencyCode = 'USD'

export const CURRENCIES: CurrencyMap = {
    AED: {
        name: 'United Arab Emirates Dirham',
        symbol: 'د.إ',
        color: '#199AD1',
    },
    AUD: { name: 'Australian Dollar', symbol: 'A$', color: '#BF2D85' },
    BRL: { name: 'Brazilian Real', symbol: 'R$', color: '#D27200' },
    CAD: { name: 'Canadian Dollar', symbol: 'C$', color: '#9ABD6B' },
    CHF: { name: 'Swiss Franc', symbol: '₣', color: '#9AA28B' },
    EUR: { name: 'Euro', symbol: '€', color: '#36A2B5' },
    GBP: { name: 'British Pound Sterling', symbol: '£', color: '#403CA1' },
    SEK: { name: 'Swedish Krona', symbol: 'Kr', color: '#25747E' },
    INR: { name: 'Indian Rupee', symbol: '₹', color: '#BB5640' },
    JPY: { name: 'Japanese Yen', symbol: '¥', color: '#C39F40' },
    MXN: { name: 'Mexican Peso', symbol: 'M$', color: '#C8444C' },
    PLN: { name: 'Polish złoty', symbol: 'zł', color: '#996CC7' },
    SAR: { name: 'Saudi Riyal', symbol: 'SR', color: '#761510' },
    SGD: { name: 'Singapore Dollar', symbol: 'S$', color: '#994C87' },
    USD: { name: 'United States Dollar', symbol: '$', color: '#21A67C' },
    TRY: { name: 'Turkish Lira', symbol: '₺', color: '#436EEE' },
}
