import { ReactElement } from 'react'

import { ScheduleIcon, AutomationIcon } from 'components/Icons'
import { StatusIndicator } from 'components/StatusIndicator'
import { formatDay } from 'helpers/formatting'
import { Rulebook } from 'types'

import { createdDateRenderer } from './common'
import { DetailWrapper, DetailIcon, DetailText } from './shared'

export function rulebookStatusRenderer(rulebook: Rulebook): ReactElement {
    return <StatusIndicator enabled={Boolean(rulebook.active_version_id)} />
}

export function rulebookScheduleRenderer(rulebook: Rulebook): ReactElement {
    let text = ''
    if (rulebook.schedule_type === 'daily') {
        text =
            rulebook.schedule === 1
                ? 'Daily'
                : `Every ${rulebook.schedule} days`
    } else if (rulebook.schedule_type === 'weekly') {
        text =
            rulebook.schedule === 1
                ? 'Weekly'
                : `Every ${rulebook.schedule} weeks`
    }

    return (
        <DetailWrapper
            leftContent={<DetailIcon icon={ScheduleIcon} />}
            rightContent={<DetailText text={text} />}
        />
    )
}

export function rulebookActionPolicyRenderer(rulebook: Rulebook): ReactElement {
    const text =
        rulebook.action_policy === 'apply_immediately' ? 'Auto-run' : 'Manual'
    return (
        <DetailWrapper
            leftContent={<DetailIcon icon={AutomationIcon} />}
            rightContent={<DetailText text={text} />}
        />
    )
}

export function rulebookCreatedAtRenderer(rulebook: Rulebook): ReactElement {
    const date = formatDay(rulebook.created_at)
    return createdDateRenderer(`Created: ${date}`)
}
