import { Translation } from 'react-i18next'

import {
    keywordStateRenderer,
    keywordSuggestedBidRenderer,
    keywordActiveBidRenderer,
    archiveKeywordRenderer,
    keywordIdRenderer,
} from 'components/FieldRenderers/Renderers/keyword'
import { Field } from 'types'

import {
    createActionField,
    createDateField,
    createField,
    createTitleField,
} from './createField'
import { FieldCreatorOptions } from './localTypes'

export function keywordId<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['keyword', 'id']
    const name = (
        <Translation>{(t) => t('common:keyword', 'Keyword')}</Translation>
    )
    return createField({
        ...options,
        id: 'keyword_id',
        name,
        shortName: name,
        isTotalSupported: options.isTotalSupported ?? true,
        minWidth: 100,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: (props) => keywordIdRenderer<any>(props),
        },
        width: 200,
    })
}

export function keywordMatchType<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['keyword', 'match_type']
    const name = (
        <Translation>
            {(t) =>
                t('table:fields.keywordMatchType.name', 'Keyword Match Type')
            }
        </Translation>
    )
    const shortName = (
        <Translation>
            {(t) => t('table:fields.keywordMatchType.shortName', 'Match Type')}
        </Translation>
    )

    return createTitleField({
        ...options,
        id: 'keyword__match_type',
        name,
        shortName,
        dataIndex,
        minWidth: 75,
        width: 110,
    })
}

export function keywordState<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['keyword', 'state']
    const name = (
        <Translation>
            {(t) => t('table:fields.keywordState.name', 'Keyword State')}
        </Translation>
    )
    const shortName = (
        <Translation>
            {(t) => t('table:fields.keywordState.shortName', 'State')}
        </Translation>
    )

    return createField({
        ...options,
        id: 'keyword__state',
        name,
        shortName,
        dataIndex,
        minWidth: 75,
        renderOptions: options.renderOptions ?? {
            render: (cellRenderProps) =>
                keywordStateRenderer<any>({ cellRenderProps, dataIndex }),
        },
        width: 100,
    })
}

export function keywordBid<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['keyword', 'base_bid']
    const name = (
        <Translation>
            {(t) => t('table:fields.keywordBid.name', 'Keyword Bid')}
        </Translation>
    )
    const shortName = (
        <Translation>
            {(t) => t('table:fields.keywordBid.shortName', 'Bid')}
        </Translation>
    )

    return createField({
        ...options,
        id: 'keyword_bid',
        name,
        shortName,
        minWidth: 75,
        dataIndex,
        width: 100,
        align: 'right',
    })
}

export function keywordSuggestedBid<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['keyword', 'suggestedBid']
    const helpText =
        'Suggested bid and bid range are calculated from a group of winning bids for ads that are similar to yours'
    return createField({
        ...options,
        id: 'keyword_suggested_bid',
        name: 'Suggested Bid',
        shortName: 'Suggested Bid',
        minWidth: 100,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: (cellRenderProps) =>
                keywordSuggestedBidRenderer<any>({
                    cellRenderProps,
                    dataIndex,
                }),
        },
        localDefinition: helpText,
        width: 175,
        align: 'right',
        sorter: options.sorter ?? false,
    })
}

export function keywordActiveBid<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['keyword', 'active_bid']
    return createField({
        ...options,
        id: 'keyword_active_bid',
        name: 'Active Bid',
        shortName: 'Active Bid',
        minWidth: 75,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: (props) => keywordActiveBidRenderer<any>(props),
        },
        width: 100,
        align: 'right',
    })
}

export function keywordCreatedDate<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['keyword', 'created_date']
    const name = (
        <Translation>
            {(t) => t('common:createdDate', 'Created Date')}
        </Translation>
    )
    return createDateField({
        ...options,
        id: 'keyword_created_date',
        name,
        shortName: name,
        dataIndex,
        minWidth: 80,
        isVisible: options.isVisible ?? false,
        width: 110,
    })
}

export function archiveKeyword<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['keyword']
    return createActionField({
        ...options,
        id: 'archive_keyword',
        isResizeable: false,
        minWidth: 68,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: (cellRenderProps) =>
                archiveKeywordRenderer<any>({ cellRenderProps, dataIndex }),
        },
        align: 'center',
        sorter: false,
        fixed: options.fixed ?? 'right',
    })
}
