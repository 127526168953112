import { useCallback, useEffect, useState } from 'react'

import { useFormikContext } from 'formik'

import { useCerebroApiRequest } from 'hooks'
import { getWalmartAdGroups } from 'services/cerebroApi/orgScope/walmartApi'
import {
    CerebroPaginatedResponse,
    WalmartAdGroup,
    WalmartCampaign,
} from 'types'

import { Option } from './localTypes'

export const useFetchWalmartAdGroups = (
    campaign: WalmartCampaign,
    name?: string,
    isFormikField = false
): [boolean, Option[]] => {
    const makeCerebroApiRequest = useCerebroApiRequest()
    const { setFieldTouched } = useFormikContext()

    const [isFetching, setFetching] = useState(false)
    const [options, setOptions] = useState<Option[]>([]) // TODO: When ad groups on campaign, add campaign.ad_groups[0] for initialValue

    const fetchWalmartAdGroups = useCallback(
        async (campaignId: string): Promise<void> => {
            await makeCerebroApiRequest<
                WalmartAdGroup,
                CerebroPaginatedResponse<WalmartAdGroup>
            >({
                request: getWalmartAdGroups({
                    campaign_id: campaignId,
                    'state!': 'archived',
                }),
                onRequestSuccess: ({ data }) => {
                    const fetchedOptions: Option[] = data.results.map(
                        ({ id, name: adGroupName }) => ({
                            value: id,
                            label: adGroupName,
                        })
                    )

                    setOptions(fetchedOptions)
                    setFetching(false)
                },
            })
        },
        [makeCerebroApiRequest]
    )

    useEffect(() => {
        if (isFormikField && name) {
            setFieldTouched(name, false)
        }

        setOptions([])
        setFetching(true)
        fetchWalmartAdGroups(campaign.id)
    }, [
        campaign.id,
        name,
        setFieldTouched,
        isFormikField,
        fetchWalmartAdGroups,
    ])

    return [isFetching, options]
}
