import { ReactElement } from 'react'

import { List, Popover } from 'antd'
import classNames from 'classnames'
import compact from 'lodash/compact'

import { AutomationCapability } from 'types'

import classes from './styles.scss'
import CapabilityElement from '../AutomationCapabilityElement'
import CapabilityIcon from '../AutomationCapabilityIcon'

interface Props {
    capabilities: AutomationCapability[]
}

export default ({ capabilities }: Props): ReactElement => {
    const renderGroupedIcons = (): ReactElement => {
        const capabilitiesPreview = compact(
            capabilities.map((capability, idx) => idx < 3 && capability)
        )

        return (
            <div
                className={classNames(
                    'd-flex align-items-center',
                    classes['grouped-capabilities--wrapper']
                )}
            >
                <div
                    style={{
                        width: `${capabilitiesPreview.length * 20}px`,
                    }}
                    className={classNames(
                        'position-relative',
                        classes['capability-group-icon--wrapper']
                    )}
                >
                    {capabilitiesPreview.map((capability, idx) => (
                        <CapabilityIcon
                            key={`${capability.id}-${idx}`}
                            capabilityId={capability.id}
                            className={classes['capability-group-icon']}
                        />
                    ))}
                </div>
                <span>{capabilities.length} Automations</span>
            </div>
        )
    }

    return (
        <Popover
            content={
                <List
                    size="small"
                    split={false}
                    dataSource={capabilities}
                    renderItem={(capability) => (
                        <List.Item>
                            <CapabilityElement capabilityId={capability.id} />
                        </List.Item>
                    )}
                />
            }
        >
            {renderGroupedIcons()}
        </Popover>
    )
}
