import { ReactNode } from 'react'

import {
    MemberTypeField,
    RemoveMemberFromOrganizationField,
} from 'components/EditableFields'
import { CellRenderProps, Member, User } from 'types'

type RenderProps<RecordType> = {
    cellRenderProps: CellRenderProps<RecordType>
    dataIndex: string[]
}

export function removeFromOrganizationRenderer<RecordType extends User>({
    record,
    reloadData,
    readonly,
}: CellRenderProps<RecordType>): ReactNode {
    return (
        <RemoveMemberFromOrganizationField
            record={record}
            readOnly={readonly}
            reloadData={reloadData}
        />
    )
}

export function memberTypeRenderer<RecordType extends Member>({
    cellRenderProps: { readonly, record },
    dataIndex,
}: RenderProps<RecordType>): ReactNode {
    return (
        <MemberTypeField
            dataIndex={dataIndex}
            record={record}
            readOnly={readonly}
        />
    )
}
