import produce from 'immer'
import { handleActions } from 'redux-actions'

import {
    FetchBrandOptionsAction,
    FetchDspAdvertiserOptionsAction,
    FetchIntegrationOptionsAction,
    fetchBrandOptionsSuccess,
    fetchDspAdvertiserOptionsSuccess,
    fetchIntegrationOptionsSuccess,
} from 'actions/orgs'
import { defaultResourcePage } from 'reducers/ui/defaults'

const defaultState = {
    ...defaultResourcePage,

    brandOptions: [],
    integrationOptions: [],
    dspAdvertiserOptions: [],
}

type OrganizationPageState = typeof defaultState

export default handleActions(
    {
        [fetchBrandOptionsSuccess.toString()](
            state: OrganizationPageState,
            action: FetchBrandOptionsAction
        ) {
            return produce(state, (draft) => {
                draft.brandOptions = action.payload.results
            })
        },
        [fetchIntegrationOptionsSuccess.toString()](
            state: OrganizationPageState,
            action: FetchIntegrationOptionsAction
        ) {
            return produce(state, (draft) => {
                draft.integrationOptions = action.payload.results
            })
        },
        [fetchDspAdvertiserOptionsSuccess.toString()](
            state: OrganizationPageState,
            action: FetchDspAdvertiserOptionsAction
        ) {
            return produce(state, (draft) => {
                draft.dspAdvertiserOptions = action.payload.results
            })
        },
    },
    defaultState
)
