import { memo, ReactElement } from 'react'

import isEqual from 'lodash/isEqual'

import { updatePortfolio } from 'services/cerebroApi/orgScope/resourceApi'
import { PortfolioGroupBy } from 'types'

import { DateField } from '../SharedFields/DateField'

interface Props<RecordType> {
    dataIndex: string[]
    readOnly: boolean
    record: RecordType
}

function PortfolioEndDateField<RecordType extends PortfolioGroupBy>({
    dataIndex = ['portfolio', 'end_date'],
    readOnly = false,
    record,
}: Props<RecordType>): ReactElement {
    const getIsDisabled = (): boolean => {
        return !record.portfolio.user_can_manage
    }

    return (
        <DateField
            disabled={getIsDisabled()}
            fieldName="end_date"
            fieldPath={dataIndex}
            placeholder="End Date"
            readOnly={readOnly}
            record={record}
            serializeFieldValues={({ end_date }) => {
                return { end_date: end_date?.format('YYYY-MM-DD') }
            }}
            updateRequestApi={updatePortfolio}
            updateRequestData={record.portfolio.id}
            updateRequestSuccessMesg="Successfully updated portfolio end date"
        />
    )
}

export default memo(
    PortfolioEndDateField,
    isEqual
) as typeof PortfolioEndDateField
