import { addCache } from 'helpers/utilities'
import {
    CerebroApiOptions,
    BannerRequestBody,
    CerebroResourceResponse,
    CerebroPaginatedResponse,
    WalmartAdGroup,
    WalmartPlacementInclusion,
    WalmartPlacementMultiplier,
    WalmartPlatformMultiplier,
    WalmartCampaign,
    WalmartAdItem,
    WalmartKeyword,
    Report,
    AsyncUpdate,
    WalmartAdvertiser,
    BulkUpdatePayload,
    WalmartUpdateResponse,
    WalmartUpdatePayload,
} from 'types'

import { axiosInstanceOrganizations } from './client'
import { getCerebroHeaders } from './headers'

export const getWalmartMarketplaceIntegrations = (
    params: any,
    options: CerebroApiOptions = {}
): Promise<CerebroPaginatedResponse<any>> =>
    axiosInstanceOrganizations.get(
        '/api/walmart_marketplace_api_integrations/',
        {
            params,
            headers: getCerebroHeaders(options.headers),
        }
    )
addCache(getWalmartMarketplaceIntegrations)

export const createWmMarketplaceIntegration = (
    data: any
): Promise<CerebroResourceResponse<any>> =>
    axiosInstanceOrganizations.post(
        '/api/walmart_marketplace_api_integrations/',
        data
    )

export const updateWmMarketplaceIntegration = (
    integrationId: string,
    data: any
): Promise<CerebroResourceResponse<any>> =>
    axiosInstanceOrganizations.patch(
        `/api/walmart_marketplace_api_integration/${integrationId}/`,
        data
    )

export const getWmMarketplaceOrderFacts = (
    params: BannerRequestBody,
    options: CerebroApiOptions = {}
): Promise<CerebroPaginatedResponse<any>> =>
    axiosInstanceOrganizations.post(
        '/api/walmart_marketplace_order_facts/aggregate/banner/',
        params,
        {
            headers: getCerebroHeaders(options.headers),
        }
    )
addCache(getWmMarketplaceOrderFacts)

export const getWmMarketplaceOrderFactsExport = (
    params: BannerRequestBody
): Promise<CerebroResourceResponse<Report>> =>
    axiosInstanceOrganizations.post(
        '/api/walmart_marketplace_order_facts/aggregate/banner/export/',
        params
    )

export const getWmAdvertisingItemFacts = (
    params: BannerRequestBody,
    options: CerebroApiOptions = {}
): Promise<CerebroPaginatedResponse<any>> =>
    axiosInstanceOrganizations.post(
        '/api/walmart_advertising_item_facts/aggregate/banner/',
        params,
        {
            headers: getCerebroHeaders(options.headers),
        }
    )
addCache(getWmAdvertisingItemFacts)

export const getWmAdvertisingItemFactsExport = (
    params: BannerRequestBody
): Promise<CerebroResourceResponse<Report>> =>
    axiosInstanceOrganizations.post(
        '/api/walmart_advertising_item_facts/aggregate/banner/export/',
        params
    )

export const getWmAdvertisingKeywordFacts = (
    params: BannerRequestBody,
    options: CerebroApiOptions = {}
): Promise<CerebroPaginatedResponse<any>> =>
    axiosInstanceOrganizations.post(
        '/api/walmart_advertising_keyword_facts/aggregate/banner/',

        params,
        {
            headers: getCerebroHeaders(options.headers),
        }
    )
addCache(getWmAdvertisingItemFacts)

export const getWmAdvertisingKeywordFactsExport = (
    params: BannerRequestBody
): Promise<CerebroResourceResponse<Report>> =>
    axiosInstanceOrganizations.post(
        '/api/walmart_advertising_keyword_facts/aggregate/banner/export/',
        params
    )

export const getWmAdvertisingCampaignFacts = (
    params: BannerRequestBody,
    options: CerebroApiOptions = {}
): Promise<CerebroPaginatedResponse<any>> =>
    axiosInstanceOrganizations.post(
        '/api/walmart_advertising_campaign_facts/aggregate/banner/',
        params,
        {
            headers: getCerebroHeaders(options.headers),
        }
    )
addCache(getWmAdvertisingItemFacts)

export const getWmAdvertisingCampaignFactsExport = (
    params: BannerRequestBody
): Promise<CerebroResourceResponse<Report>> =>
    axiosInstanceOrganizations.post(
        '/api/walmart_advertising_campaign_facts/aggregate/banner/export/',
        params
    )

export const getWmAdvertisingPlacementFacts = (
    params: BannerRequestBody,
    options: CerebroApiOptions = {}
): Promise<CerebroPaginatedResponse<any>> =>
    axiosInstanceOrganizations.post(
        '/api/walmart_advertising_placement_facts/aggregate/banner/',
        params,
        {
            headers: getCerebroHeaders(options.headers),
        }
    )
addCache(getWmAdvertisingPlacementFacts)

export const getWmAdvertisingPlacementFactsExport = (
    params: BannerRequestBody
): Promise<CerebroResourceResponse<Report>> =>
    axiosInstanceOrganizations.post(
        '/api/walmart_advertising_placement_facts/aggregate/banner/export/',
        params
    )

export const getWmAdvertisingPlatformFacts = (
    params: BannerRequestBody,
    options: CerebroApiOptions = {}
): Promise<CerebroPaginatedResponse<any>> =>
    axiosInstanceOrganizations.post(
        '/api/walmart_advertising_platform_facts/aggregate/banner/',
        params,
        {
            headers: getCerebroHeaders(options.headers),
        }
    )
addCache(getWmAdvertisingPlatformFacts)

export const getWmAdvertisingPlatformFactsExport = (
    params: BannerRequestBody
): Promise<CerebroResourceResponse<Report>> =>
    axiosInstanceOrganizations.post(
        '/api/walmart_advertising_platform_facts/aggregate/banner/export/',
        params
    )

export const getWmAdvertisingAdGroupFacts = (
    params: BannerRequestBody,
    options: CerebroApiOptions = {}
): Promise<CerebroPaginatedResponse<any>> =>
    axiosInstanceOrganizations.post(
        '/api/walmart_advertising_ad_group_facts/aggregate/banner/',
        params,
        {
            headers: getCerebroHeaders(options.headers),
        }
    )
addCache(getWmAdvertisingItemFacts)

export const getWalmartAdGroup = (
    wmAdGroupId: string,
    options: CerebroApiOptions = {}
): Promise<CerebroResourceResponse<WalmartAdGroup>> =>
    axiosInstanceOrganizations.get(`/api/walmart_ad_group/${wmAdGroupId}`, {
        headers: getCerebroHeaders(options.headers),
    })

export const getWalmartCampaign = (
    wmCampaignId: string,
    options: CerebroApiOptions = {}
): Promise<CerebroResourceResponse<WalmartCampaign>> =>
    axiosInstanceOrganizations.get(`/api/walmart_campaign/${wmCampaignId}`, {
        headers: getCerebroHeaders(options.headers),
    })

export const updateWalmartCampaign = (
    wmCampaignId: string,
    data: any
): Promise<CerebroResourceResponse<WalmartCampaign>> =>
    axiosInstanceOrganizations.patch(
        `/api/walmart_campaign/${wmCampaignId}/`,
        data
    )

export const updateWalmartCampaigns = (
    data: any,
    params: any = {}
): Promise<CerebroPaginatedResponse<WalmartCampaign>> =>
    axiosInstanceOrganizations.patch('/api/walmart_campaigns/', data, {
        params,
    })

export const cloneWalmartCampaigns = (
    data: any,
    params: any
): Promise<CerebroResourceResponse<WalmartCampaign>> =>
    axiosInstanceOrganizations.patch('/api/walmart_campaigns/mutate/', data, {
        params,
    })

export const asyncUpdateWalmartCampaignFacts = (
    data: any,
    params: BannerRequestBody,
    options: CerebroApiOptions = {}
): Promise<CerebroResourceResponse<AsyncUpdate>> =>
    axiosInstanceOrganizations.patch(
        '/api/walmart_advertising_campaign_facts/aggregate/banner/mutate/',
        data,
        {
            params: { ...params, metrics: null },
            headers: getCerebroHeaders(options.headers),
        }
    )

export const getWalmartCampaigns = (
    params: any,
    options: CerebroApiOptions = {}
): Promise<CerebroPaginatedResponse<WalmartCampaign>> =>
    axiosInstanceOrganizations.get('/api/walmart_campaigns/', {
        params,
        headers: getCerebroHeaders(options.headers),
    })

export const getWalmartKeywords = (
    params: any,
    options: CerebroApiOptions = {}
): Promise<CerebroPaginatedResponse<WalmartKeyword>> =>
    axiosInstanceOrganizations.get('/api/walmart_keywords/', {
        params,
        headers: getCerebroHeaders(options.headers),
    })

export const createWalmartKeyword = (
    data: WalmartUpdatePayload<WalmartKeyword>
): Promise<CerebroResourceResponse<WalmartUpdateResponse<WalmartKeyword>>> =>
    axiosInstanceOrganizations.post(`/api/walmart_keywords/`, data)

export const updateWalmartKeyword = (
    keywordId: string,
    data: any
): Promise<CerebroResourceResponse<WalmartKeyword>> =>
    axiosInstanceOrganizations.patch(`/api/walmart_keyword/${keywordId}/`, data)

export const updateWalmartKeywords = (
    data: any,
    params = {}
): Promise<CerebroResourceResponse<WalmartKeyword[]>> =>
    axiosInstanceOrganizations.patch('/api/walmart_keywords/', data, {
        params,
    })

export const asyncUpdateWalmartKeywordFacts = (
    data: any,
    params: BannerRequestBody,
    options: CerebroApiOptions = {}
): Promise<CerebroResourceResponse<AsyncUpdate>> =>
    axiosInstanceOrganizations.patch(
        '/api/walmart_advertising_keyword_facts/aggregate/banner/mutate/',
        data,
        {
            params: { ...params, metrics: null },
            headers: getCerebroHeaders(options.headers),
        }
    )

export const getWalmartAdGroups = (
    params: any,
    options: CerebroApiOptions = {}
): Promise<CerebroPaginatedResponse<WalmartAdGroup>> =>
    axiosInstanceOrganizations.get('/api/walmart_ad_groups/', {
        params,
        headers: getCerebroHeaders(options.headers),
    })

export const getWalmartAdItems = (
    params: any,
    options: CerebroApiOptions = {}
): Promise<CerebroPaginatedResponse<WalmartAdItem>> =>
    axiosInstanceOrganizations.get('/api/walmart_ad_items/', {
        params,
        headers: getCerebroHeaders(options.headers),
    })

export const createWalmartAdItem = (
    data: WalmartUpdatePayload<WalmartAdItem>
): Promise<CerebroResourceResponse<WalmartUpdateResponse<WalmartAdItem>>> =>
    axiosInstanceOrganizations.post(`/api/walmart_ad_items/`, data)

export const updateWalmartAdItem = (
    wmAdItemId: string,
    data: any
): Promise<CerebroResourceResponse<WalmartAdItem>> =>
    axiosInstanceOrganizations.patch(
        `/api/walmart_ad_item/${wmAdItemId}/`,
        data
    )

export const updateWalmartAdItems = (
    data: any,
    params = {}
): Promise<CerebroResourceResponse<WalmartAdItem[]>> =>
    axiosInstanceOrganizations.patch('/api/walmart_ad_items/', data, {
        params,
    })

export const asyncUpdateWalmartItemFacts = (
    data: any,
    params: BannerRequestBody,
    options: CerebroApiOptions = {}
): Promise<CerebroResourceResponse<AsyncUpdate>> =>
    axiosInstanceOrganizations.patch(
        '/api/walmart_advertising_item_facts/aggregate/banner/mutate/',
        data,
        {
            params: { ...params, metrics: null },
            headers: getCerebroHeaders(options.headers),
        }
    )

export const createWalmartAdGroup = (
    data: WalmartAdGroup
): Promise<CerebroResourceResponse<WalmartAdGroup>> =>
    axiosInstanceOrganizations.post('/api/walmart_ad_groups/', data)

export const createWalmartCampaign = (
    data: WalmartUpdatePayload<WalmartCampaign>
): Promise<CerebroResourceResponse<WalmartCampaign>> =>
    axiosInstanceOrganizations.post('/api/walmart_campaigns/', data)

export const getWalmartAdvertisers = (
    params: any = {},
    options: CerebroApiOptions = {}
): Promise<CerebroPaginatedResponse<WalmartAdvertiser>> =>
    axiosInstanceOrganizations.get('/api/walmart_advertisers/', {
        params,
        headers: getCerebroHeaders(options.headers),
    })
addCache(getWalmartAdvertisers)

export const updateWalmartAdvertisers = (
    integrationId: string,
    data: BulkUpdatePayload
): Promise<CerebroResourceResponse<WalmartAdvertiser[]>> =>
    axiosInstanceOrganizations.patch(
        `/api/walmart_advertiser/${integrationId}/`,
        data
    )

export const updateWalmartAdGroup = (
    adGroupId: string,
    data: any
): Promise<CerebroResourceResponse<WalmartAdGroup>> =>
    axiosInstanceOrganizations.patch(
        `/api/walmart_ad_group/${adGroupId}/`,
        data
    )

export const updateWalmartAdGroups = (
    data: any,
    params = {}
): Promise<CerebroResourceResponse<WalmartAdGroup[]>> =>
    axiosInstanceOrganizations.patch('/api/walmart_ad_groups/', data, {
        params,
    })

export const asyncUpdateWalmartAdGroupFacts = (
    data: any,
    params: BannerRequestBody,
    options: CerebroApiOptions = {}
): Promise<CerebroResourceResponse<AsyncUpdate>> =>
    axiosInstanceOrganizations.patch(
        '/api/walmart_advertising_ad_group_facts/aggregate/banner/mutate/',
        data,
        {
            params: { ...params, metrics: null },
            headers: getCerebroHeaders(options.headers),
        }
    )

export const createWalmartPlatformMultiplier = (
    data: WalmartUpdatePayload<WalmartPlatformMultiplier>
): Promise<CerebroResourceResponse<WalmartPlatformMultiplier>> =>
    axiosInstanceOrganizations.post(`/api/walmart_platform_multipliers/`, data)

export const updateWalmartPlatformMultiplier = (
    platformMultiplierId: string,
    data: any
): Promise<CerebroResourceResponse<WalmartPlatformMultiplier>> =>
    axiosInstanceOrganizations.patch(
        `/api/walmart_platform_multiplier/${platformMultiplierId}/`,
        data
    )

export const createWalmartPlacementMultiplier = (
    data: WalmartUpdatePayload<WalmartPlacementMultiplier>
): Promise<CerebroResourceResponse<WalmartPlacementMultiplier>> =>
    axiosInstanceOrganizations.post(`/api/walmart_placement_multipliers/`, data)

export const updateWalmartPlacementMultiplier = (
    placementMultiplierId: string,
    data: any
): Promise<CerebroResourceResponse<WalmartPlacementMultiplier>> =>
    axiosInstanceOrganizations.patch(
        `/api/walmart_placement_multiplier/${placementMultiplierId}/`,
        data
    )

export const createWalmartPlacementInclusion = (
    data: WalmartUpdatePayload<WalmartPlacementInclusion>
): Promise<
    CerebroResourceResponse<WalmartUpdateResponse<WalmartPlacementInclusion>>
> => axiosInstanceOrganizations.post(`/api/walmart_placement_inclusions/`, data)

export const updateWalmartPlacementInclusion = (
    placementInclusionId: string,
    data: any
): Promise<CerebroResourceResponse<WalmartPlacementInclusion>> =>
    axiosInstanceOrganizations.patch(
        `/api/walmart_placement_inclusion/${placementInclusionId}/`,
        data
    )

export const getWalmartSuggestedKeywords = (
    adGroup: WalmartAdGroup,
    options: CerebroApiOptions = {}
): Promise<any> =>
    axiosInstanceOrganizations.get(
        `/api/wma/?advertiser_id=${adGroup.advertiser_id}&endpoint=/api/v1/keyword_suggestions&adGroupId=${adGroup.walmart_resource_id}`,
        {
            headers: getCerebroHeaders(options.headers),
        }
    )

export const searchWalmartItemsCatalog = (
    advertiserId: string,
    data: any
): Promise<any> =>
    axiosInstanceOrganizations.post(
        `/api/wma/?advertiser_id=${advertiserId}&endpoint=/api/v1/itemSearch`,
        {
            ...data,
            advertiserId,
        }
    )

export const getWalmartCampaignStats = (
    advertiserId: string,
    campaignId: string,
    options: CerebroApiOptions = {}
): Promise<any> =>
    axiosInstanceOrganizations.get(
        `/api/wma/?advertiser_id=${advertiserId}&endpoint=/api/v1/stats&campaignId=${campaignId}`,
        {
            headers: getCerebroHeaders(options.headers),
        }
    )
