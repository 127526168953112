import { addCache } from 'helpers/utilities'
import {
    BannerRequestBody,
    BulkUpdatePayload,
    CerebroApiOptions,
    CerebroPaginatedResponse,
    CerebroResourceResponse,
    Report,
    UpdatePayload,
} from 'types'

import { axiosInstanceOrganizations } from './client'
import { getCerebroHeaders } from './headers'

export const getDspCampaignFacts = (
    params: BannerRequestBody,
    options: CerebroApiOptions = {}
): Promise<CerebroPaginatedResponse<any>> =>
    axiosInstanceOrganizations.post(
        '/api/dsp_campaign_facts/aggregate/banner/',

        params,
        {
            headers: getCerebroHeaders(options.headers),
        }
    )
addCache(getDspCampaignFacts)

export const getDspCampaignFactsExport = (
    params: BannerRequestBody
): Promise<CerebroResourceResponse<Report>> =>
    axiosInstanceOrganizations.post(
        '/api/dsp_campaign_facts/aggregate/banner/export/',
        params
    )

export const asyncUpdateDspFacts = (
    data: BulkUpdatePayload,
    params: BannerRequestBody,
    options: CerebroApiOptions = {}
): Promise<CerebroResourceResponse<any>> =>
    axiosInstanceOrganizations.patch(
        '/api/dsp_campaign_facts/aggregate/banner/mutate/',
        data,
        {
            params: { ...params, metrics: null },
            headers: getCerebroHeaders(options.headers),
        }
    )

export const getDspInventoryFacts = (
    params: BannerRequestBody,
    options: CerebroApiOptions = {}
): Promise<CerebroPaginatedResponse<any>> =>
    axiosInstanceOrganizations.post(
        '/api/dsp_inventory_facts/aggregate/banner/',
        params,
        {
            headers: getCerebroHeaders(options.headers),
        }
    )
addCache(getDspInventoryFacts)

export const getDspInventoryFactsExport = (
    params: BannerRequestBody
): Promise<CerebroResourceResponse<Report>> =>
    axiosInstanceOrganizations.post(
        '/api/dsp_inventory_facts/aggregate/banner/export/',
        params
    )

export const getDspAudienceFacts = (
    params: BannerRequestBody,
    options: CerebroApiOptions = {}
): Promise<CerebroPaginatedResponse<any>> =>
    axiosInstanceOrganizations.post(
        '/api/dsp_audience_facts/aggregate/banner/',
        params,
        {
            headers: getCerebroHeaders(options.headers),
        }
    )
addCache(getDspAudienceFacts)

export const getDspAudienceFactsExport = (
    params: BannerRequestBody
): Promise<CerebroResourceResponse<Report>> =>
    axiosInstanceOrganizations.post(
        '/api/dsp_audience_facts/aggregate/banner/export/',
        params
    )

export const getDspAdvertiser = (
    advertiserId: string
): Promise<CerebroResourceResponse<any>> =>
    axiosInstanceOrganizations.get(`/api/dsp_advertiser/${advertiserId}/`)

export const getDspAdvertisers = (
    params = {}
): Promise<CerebroResourceResponse<any>> =>
    axiosInstanceOrganizations.get('/api/dsp_advertisers/', { params })
addCache(getDspAdvertisers)

export const getDspOrder = (
    orderId: string
): Promise<CerebroResourceResponse<any>> =>
    axiosInstanceOrganizations.get(`/api/dsp_order/${orderId}/`)

export const getDspOrders = (
    params = {}
): Promise<CerebroResourceResponse<any>> =>
    axiosInstanceOrganizations.get('/api/dsp_orders/', { params })

export const updateDspOrder = (
    orderId: string,
    data: any
): Promise<CerebroResourceResponse<any>> =>
    axiosInstanceOrganizations.patch(`/api/dsp_order/${orderId}/`, data)

export const updateDspOrders = (
    data: UpdatePayload,
    params = {}
): Promise<CerebroResourceResponse<any>> =>
    axiosInstanceOrganizations.patch('/api/dsp_orders/', data, { params })

export const getDspLineItem = (
    lineItemId: string
): Promise<CerebroResourceResponse<any>> =>
    axiosInstanceOrganizations.get(`/api/dsp_line_item/${lineItemId}/`)

export const updateDspLineItem = (
    lineItemId: string,
    data: any
): Promise<CerebroResourceResponse<any>> =>
    axiosInstanceOrganizations.patch(`/api/dsp_line_item/${lineItemId}/`, data)

export const getDspLineItems = (
    params = {}
): Promise<CerebroResourceResponse<any>> =>
    axiosInstanceOrganizations.get('/api/dsp_line_items/', { params })

export const updateDspLineItems = (
    data: UpdatePayload,
    params: any = {}
): Promise<CerebroResourceResponse<any>> =>
    axiosInstanceOrganizations.patch('/api/dsp_line_items/', data, { params })
