/**
 * Recommendation on organizing sagas in parallel
 *
 * There are 2 ways to start saga effects in parallel:
 *
 * A)
 * const rootWatcher = function * () {
 *   yield takeLatest(A, Asaga);
 *   yield takeLatest(B, Bsaga);
 * }
 *
 * B)
 * const rootWatcher = function * () {
 *   yield [ takeLatest(A, Asaga), takeLatest(B, Bsaga) ]
 * }
 *
 * There is no real difference between those two snippets.
 * Subtle technicality is that first one "asks" redux-saga runtime 2x times to do something (starting a watcher in this case)
 * while the latter asks only once - holding both in a 'batch'.
 *
 * Though, yielding arrays is deprecated.
 *
 * So the recommended way is:
 *
 * const rootWatcher = function * () {
 *   yield all([ takeLatest(A, Asaga), takeLatest(B, Bsaga) ])
 * }
 *
 * @see https://github.com/redux-saga/redux-saga/issues/1329
 */

import { all } from 'redux-saga/effects'

import authWatcher from './auth'
import orgsWatcher from './orgs'
import uiWatcher from './ui'

export default function* rootWatcher() {
    yield all([authWatcher(), uiWatcher(), orgsWatcher()])
}
