import { call, select } from 'redux-saga/effects'

import { makeFetchResourceSuccess } from 'actions/ui/shared/resource'
import { DSP_LINE_ITEM_PAGE } from 'const/pages'
import { cerebroApiSaga } from 'sagas/common'
import uiSagaRegistry from 'sagas/ui/registry'
import { selectResourceParamsOfPage } from 'selectors/ui'
import { getDspLineItem } from 'services/cerebroApi/orgScope/dspApi'

function* fetchLineItemSaga() {
    const { lineItemId } = yield select(
        selectResourceParamsOfPage,
        DSP_LINE_ITEM_PAGE
    )

    yield call(
        cerebroApiSaga,
        makeFetchResourceSuccess(DSP_LINE_ITEM_PAGE),
        getDspLineItem,
        lineItemId
    )
}

/**
 * Mounts the Ad Group Page and fetches data
 */
function* mountPageSaga() {
    yield call(fetchLineItemSaga)
}

uiSagaRegistry.registerSagas([DSP_LINE_ITEM_PAGE], {
    mountPageSaga,
})
