import axios, { AxiosInstance } from 'axios'

export const createCerebroAxiosInstance = (): AxiosInstance =>
    axios.create({
        baseURL: CEREBRO_API_ENDPOINT,
        validateStatus: (status) => status >= 200 && status < 500,
        headers: {
            'Content-Type': 'application/json',
        },
    })
