import { ReactElement, SVGAttributes } from 'react'

type Props = SVGAttributes<SVGElement>

function LockedLockIconSvg(props: Props): ReactElement {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M12.693 5.664V4.422a2.48 2.48 0 00-.764-1.782 2.651 2.651 0 00-1.842-.739h-.17c-.691 0-1.354.266-1.843.739a2.48 2.48 0 00-.763 1.782v1.242c-.656.05-1.312.119-1.966.204v-1.44c0-1.173.482-2.298 1.338-3.128A4.659 4.659 0 019.913 0h.17a4.659 4.659 0 013.23 1.299 4.357 4.357 0 011.34 3.125v1.44a39.07 39.07 0 00-1.96-.2zm-8.77 1.98a33.774 33.774 0 0112.152 0l.091.017c.235.043.447.164.599.341a.97.97 0 01.235.633v6.77a.97.97 0 01-.235.632 1.03 1.03 0 01-.599.342l-.622.113A30.807 30.807 0 0110 17c-1.86 0-3.716-.168-5.543-.502l-.622-.114a1.03 1.03 0 01-.599-.341A.97.97 0 013 15.41V8.64a.97.97 0 01.235-.632c.153-.178.364-.299.599-.342l.09-.021z" />
        </svg>
    )
}

export default LockedLockIconSvg
