import { handleActions } from 'redux-actions'

import { campaignNegativeKeywordsTableColumnsConfig } from 'configuration/tables'
import { NEGATIVE_KEYWORD_TEXT } from 'const/filters'
import { LOCAL_FILTERS, FILTER_SETTINGS } from 'const/reducerKeys'
import { serializeColumns } from 'helpers/tables'
import { defaultTab, getDefaultTable } from 'reducers/ui/defaults'

const initialState = {
    ...defaultTab,
    [LOCAL_FILTERS]: {
        [NEGATIVE_KEYWORD_TEXT]: null,
    },
    [FILTER_SETTINGS]: {
        anchored: [NEGATIVE_KEYWORD_TEXT],
    },
    table: getDefaultTable(
        serializeColumns(campaignNegativeKeywordsTableColumnsConfig)
    ),
}

export default handleActions({}, initialState)
