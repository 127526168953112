import {
    createContext,
    ReactElement,
    ReactNode,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react'

import { useSelector } from 'react-redux'

import { CustomerModuleKey } from 'const/customerModules'
import { useCurrentOrganizationGroup } from 'hooks'
import {
    CustomerModuleInfo,
    useCustomerModules,
} from 'hooks/useCustomerModules'
import { selectCurrentOrganization } from 'selectors/orgs'
import { getOrganizationAccountStatus } from 'services/cerebroApi/noScope/resourceApi'
import {
    CerebroResourceResponse,
    OrgAccountStatus,
    Organization,
    RootReduxState,
} from 'types'

import useCerebroApiRequest from '../hooks/useCerebroApiRequest'

interface OrgContext {
    orgId?: string
    orgGroupId?: string
    orgAccountStatus?: OrgAccountStatus & {
        // eventually this could be completely calculated on the server
        customerModules: Record<CustomerModuleKey, CustomerModuleInfo>
    }
}

const OrgContextInstance = createContext<OrgContext>({
    orgId: undefined,
    orgGroupId: undefined,
})

export const useOrgContext = (): OrgContext => useContext(OrgContextInstance)

export function OrgContextProvider(props: {
    children: ReactNode
}): ReactElement {
    const { children } = props
    const [orgAccountStatus, setOrgAccountStatus] = useState<OrgAccountStatus>()
    const makeCerebroApiRequest = useCerebroApiRequest()
    const currentOrg = useSelector<RootReduxState, Organization | undefined>(
        (state) => selectCurrentOrganization(state)
    )
    const currentOrganizationGroup = useCurrentOrganizationGroup()
    const customerModuleInfo = useCustomerModules()

    const orgId = currentOrg?.id
    const orgGroupId = currentOrganizationGroup?.id

    useEffect(() => {
        async function fetchOrgAccountStatus(): Promise<void> {
            if (orgId) {
                await makeCerebroApiRequest({
                    request: getOrganizationAccountStatus(orgId),
                    onRequestSuccess: (
                        response: CerebroResourceResponse<OrgAccountStatus>
                    ) => {
                        setOrgAccountStatus(response.data)
                    },
                })
            }
        }
        if (orgId) {
            setOrgAccountStatus(undefined)
            fetchOrgAccountStatus()
        }
    }, [orgId, makeCerebroApiRequest])

    const orgContext = useMemo(() => {
        return {
            orgId,
            orgGroupId,
            orgAccountStatus: orgAccountStatus
                ? {
                      ...orgAccountStatus,
                      customerModules: customerModuleInfo,
                  }
                : undefined,
        }
    }, [orgId, orgGroupId, orgAccountStatus, customerModuleInfo])

    return (
        <OrgContextInstance.Provider value={orgContext}>
            {children}
        </OrgContextInstance.Provider>
    )
}
