import { ReactElement } from 'react'

import Icon from '@ant-design/icons'
import classnames from 'classnames'

import { AUTOMATION_ICONS } from 'const/automations'
import { AutomationCapabilityId } from 'types'

interface Props {
    capabilityId: AutomationCapabilityId
    className?: string
}

export default ({ capabilityId, className }: Props): ReactElement => (
    <Icon
        component={AUTOMATION_ICONS[capabilityId] as any}
        className={classnames('mr-1', className)}
        style={{ fontSize: '1.2rem' }}
    />
)
