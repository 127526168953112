import flow from 'lodash/fp/flow'
import set from 'lodash/fp/set'
import update from 'lodash/fp/update'
import pick from 'lodash/pick'

import {
    // Mount
    mountPageRequest,
    mountPageSuccess,
    mountPageFailure,
    unmountPageRequest,

    // Fetch data
    fetchPageDataRequest,
    fetchPageDataSuccess,
    fetchPageDataFailure,
} from 'actions/ui/shared/page'

export default {
    [mountPageRequest](state, { payload: { pageName, data } }) {
        return flow(
            set([pageName, 'mounting'], true),
            update([pageName], (pageState) => ({
                ...pageState,
                ...pick(data, ['resourceParams', 'dashboardParams']),
            }))
        )(state)
    },
    [mountPageSuccess](state, { payload: { pageName } }) {
        return set([pageName, 'mounting'], false)(state)
    },
    [unmountPageRequest](state, { payload: { pageName } }) {
        return flow(set([pageName, 'mounting'], true))(state)
    },
    [mountPageFailure](
        state,
        {
            payload: {
                pageName,
                data: { message },
            },
        }
    ) {
        return flow(
            set([pageName, 'mounting'], false),
            set([pageName, 'error'], message)
        )(state)
    },

    // Fetch data
    [fetchPageDataRequest](state, { payload: { pageName } }) {
        return set([pageName, 'loading'], true)(state)
    },
    [fetchPageDataSuccess](state, { payload: { pageName } }) {
        return set([pageName, 'loading'], false)(state)
    },
    [fetchPageDataFailure](
        state,
        {
            payload: {
                pageName,
                data: { message },
            },
        }
    ) {
        return flow(
            set([pageName, 'loading'], false),
            set([pageName, 'error'], message)
        )(state)
    },
}
