import { handleActions } from 'redux-actions'

import { dspAdvertisersTableColumnsConfig } from 'configuration/tables'
import { DATES, BRANDS, DSP_ADVERTISERS } from 'const/filters'
import { FILTERS, FILTER_SETTINGS } from 'const/reducerKeys'
import { serializeColumns } from 'helpers/tables'
import { defaultTab, getDefaultTable } from 'reducers/ui/defaults'

const initialState = {
    ...defaultTab,
    [FILTERS]: {
        [BRANDS]: [],
        [DSP_ADVERTISERS]: [],
    },
    [FILTER_SETTINGS]: {
        anchored: [DATES],
        order: [BRANDS, DSP_ADVERTISERS],
        displayState: {
            [BRANDS]: true,
            [DSP_ADVERTISERS]: true,
        },
    },
    table: getDefaultTable(serializeColumns(dspAdvertisersTableColumnsConfig), {
        sorter: {
            field: 'total_cost__sum',
            order: 'descend',
        },
    }),
}

export default handleActions({}, initialState)
