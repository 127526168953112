import { ReactElement } from 'react'

import { fetchCustomEventsRequest } from 'actions/ui/app'
import { useAction } from 'hooks'
import { deleteCustomEvent } from 'services/cerebroApi/orgScope/customEventsApi'
import { CustomEvent } from 'types'

import { DeleteField } from '../SharedFields/DeleteField'

interface Props {
    readOnly?: boolean
    record: CustomEvent
    reloadData: () => void
}

function DeleteCustomEventField({
    readOnly = false,
    record,
    reloadData,
}: Props): ReactElement {
    const fetchCustomEvents = useAction(fetchCustomEventsRequest)
    return (
        <DeleteField
            disabled={readOnly}
            okText="Delete"
            title="Are you sure you want to delete this Custom Event?"
            updateRequestApi={deleteCustomEvent}
            updateRequestCallback={() => {
                reloadData()
                // refresh app-level state
                fetchCustomEvents()
            }}
            updateRequestData={record.id}
            updateRequestSuccessMesg="Custom Event successfully deleted"
        />
    )
}

export default DeleteCustomEventField
