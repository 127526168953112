import { useEffect, useState, useCallback } from 'react'

import { ProductAdFactRecord } from 'configuration/tables'
import { SELLER_BRAND_TYPE } from 'const/brands'
import { Campaign } from 'types'

export const useValidator = (
    campaign: Campaign,
    products: ProductAdFactRecord[]
): [(incomingAsins: string[]) => boolean, string] => {
    const [itemsToAdd, setAsinsToAdd] = useState<string[]>([])
    const [errorMsg, setErrorMsg] = useState('')
    const [isValid, setIsValid] = useState(true)
    const MIN_ASIN_LENGTH = 10
    const isSellerBrandType = campaign?.profile.type === SELLER_BRAND_TYPE

    const clearErrors = (): void => {
        setErrorMsg('')
        setIsValid(true)
    }

    useEffect(() => {
        const hasDuplicates =
            itemsToAdd.filter((item, index, array) =>
                array.includes(item, index + 1)
            ).length > 0

        if (hasDuplicates) {
            setErrorMsg(
                'An ASIN you are trying to add has already been entered'
            )
            setIsValid(false)
        } else {
            itemsToAdd.forEach((item) => {
                const index = products.findIndex(
                    (product) => product.product_ad.asin === item
                )

                if (item.length < MIN_ASIN_LENGTH) {
                    setErrorMsg(
                        `ASIN must be at least ${MIN_ASIN_LENGTH} characters long`
                    )
                    setIsValid(false)
                } else if (index > -1) {
                    setErrorMsg(
                        'An ASIN you are trying to add has already been added'
                    )
                    setIsValid(false)
                } else {
                    clearErrors()
                }
            })
        }
    }, [itemsToAdd, products])

    const skuValidator = (): boolean => true
    const asinValidator = useCallback(
        (incomingAsins: string[]): boolean => {
            setAsinsToAdd(incomingAsins)
            return isValid
        },
        [isValid]
    )

    const skuErrorMsg = ''
    const asinErrorMsg = errorMsg

    const validator = isSellerBrandType ? skuValidator : asinValidator
    const errorMsgs = isSellerBrandType ? skuErrorMsg : asinErrorMsg

    return [validator, errorMsgs]
}
