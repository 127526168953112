import { combineChildrenReducers } from 'utilities/reducers'

import keywordsReducer from './keywords'
import keywordSearchTermsReducer from './keywordSearchTerms'
import pageReducer from './page'
import productTargetsReducer from './productTargets'
import productTargetSearchTermsReducer from './productTargetSearchTerms'

export default combineChildrenReducers(pageReducer, {
    keywords: keywordsReducer,
    keywordSearchTerms: keywordSearchTermsReducer,
    productTargets: productTargetsReducer,
    productTargetSearchTerms: productTargetSearchTermsReducer,
})
