import React, { useEffect } from 'react'

import RouteLoadingPlaceholder from '../RouteLoadingPlaceholder/RouteLoadingPlaceholder'

const propTypes = {}
const defaultProps = {}

const ReloadPage = (): React.ReactElement => {
    useEffect(() => {
        window.location.reload()
    })
    return <RouteLoadingPlaceholder />
}

ReloadPage.propTypes = propTypes
ReloadPage.defaultProps = defaultProps

export default ReloadPage
