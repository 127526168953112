import produce from 'immer'
import set from 'lodash/fp/set'
import includes from 'lodash/includes'
import intersection from 'lodash/intersection'
import isEmpty from 'lodash/isEmpty'

import {
    AMS_TIME_AGGREGATE_FIELDS,
    CHANGELOG_TIME_AGGREGATE_FIELDS,
    DATA_SOURCES,
    SOV_TIME_AGGREGATE_FIELDS,
} from 'configuration/widgets'
import { Y_BOTTOM_OF_PAGE } from 'const/dashboards'
import { WidgetDataSourceKey } from 'const/dataSources'
import { AGGREGATION, DATES, SEARCH_TERM } from 'const/filters'
import { CATEGORY_CHART, TABLE, TIME_SERIES } from 'const/widgets'
import { deserializeDatesFilter } from 'helpers/filters'
import { transformToString } from 'helpers/widgets'
import {
    defaultDashboardTabState,
    defaultWidgetState,
} from 'reducers/ui/defaults'
import {
    BannerRequestBody,
    CerebroApiOptions,
    CerebroPaginatedResponse,
    CerebroResourceResponse,
    DashboardTab,
    DataSourceDescription,
    Field,
    FiltersState,
    ModifiedWidget,
    Widget,
    WidgetFilterMap,
} from 'types'
import { Report } from 'types/resources'

export function formatAutoFilters(widget: Widget): any {
    const widgetGroupBy = transformToString(widget.group_by)
    const widgetMetrics = transformToString(widget.metrics)
    const params: any = {}

    if (widget.auto_filter) {
        params.auto_filter = widget.auto_filter
        params.auto_filter_limit = widget.auto_filter_limit
        params.auto_filter_direction = widget.auto_filter_direction
        params.auto_filter_dimension =
            widget.type === TIME_SERIES ? widgetGroupBy[1] : widgetGroupBy[0]
        params.auto_filter_metric = widgetMetrics[0]
        params.auto_filter_include_others =
            widget.auto_filter_include_others ?? false

        if (widgetMetrics.length === 1) {
            params[`${widgetMetrics[0]}__gte`] = 0 // filter out nulls
        }

        // For category charts we don't want the limit to override auto_filter_limit
        if (widget.type === CATEGORY_CHART) {
            params.limit = undefined
        }
    }

    return params
}

export function formatGoals(widget: Widget): {
    [metric: string]: string | number
} {
    if (widget.goals) {
        return widget.goals.reduce((agg: any, goal) => {
            agg[goal.metric] = goal.value
            return agg
        }, {})
    }
    return {}
}

export const isGroupedByDate = (groupBy: string[]): boolean =>
    intersection(groupBy, SOV_TIME_AGGREGATE_FIELDS).length > 0 ||
    intersection(groupBy, AMS_TIME_AGGREGATE_FIELDS).length > 0 ||
    intersection(groupBy, CHANGELOG_TIME_AGGREGATE_FIELDS).length > 0

export function formatWidgetGroupBy(
    widget: Widget | ModifiedWidget,
    pageFilters: FiltersState,
    format = 'string'
): any {
    const widgetGroupBy = transformToString(widget.group_by)
    const widgetMetrics = transformToString(widget.metrics)
    const params: any = {}
    if (widget.type === TIME_SERIES && !isEmpty(pageFilters[AGGREGATION])) {
        const key = DATA_SOURCES[widget.data_source]?.datePrefix
        // override the first group_by element with page-level aggregate filter value
        params.group_by = set(
            ['0'],
            `${key}_${pageFilters[AGGREGATION]}`
        )(widgetGroupBy)
        params.ordering = `${key}_${pageFilters[AGGREGATION]}`
    } else if (widgetGroupBy.length > 0) {
        // just let them be as they come, so we respect formatting settings like the width
        params.group_by = widget.group_by
    }

    // If grouped by a date field add a metric filter of gte 0 to filter out nulls
    if (isGroupedByDate(widgetGroupBy) && widgetMetrics.length === 1) {
        params[`${widgetMetrics[0]}__gte`] = 0
    }

    // String format used for url query params, array format used for rendering chart
    if (params.group_by && format === 'string') {
        params.group_by = widgetGroupBy.join()
    }

    return params
}

/**
    This is where we abstract "campaign placement facts", "keyword query facts"
    and "target query facts" from the user. Based on combinations of
    data_source/group_by/filters we can swap in the correct apiFunc.
 */
function getWidgetConfig(
    filters: WidgetFilterMap,
    dataSource: WidgetDataSourceKey,
    groupBy: string[]
): DataSourceDescription | undefined {
    if (dataSource === 'campaign_facts' && includes(groupBy, 'placement')) {
        return DATA_SOURCES.campaign_placement_facts
    }

    if (
        dataSource === 'keyword_facts' &&
        (includes(groupBy, 'query') || !isEmpty(filters[SEARCH_TERM]))
    ) {
        return DATA_SOURCES.keyword_query_facts
    }

    if (
        dataSource === 'target_facts' &&
        (includes(groupBy, 'query') || !isEmpty(filters[SEARCH_TERM]))
    ) {
        return DATA_SOURCES.target_query_facts
    }

    return DATA_SOURCES[dataSource]
}

export function getWidgetApiFunc({
    filters,
    data_source,
    group_by,
}: Pick<Widget | ModifiedWidget, 'filters' | 'data_source' | 'group_by'>): (
    params: BannerRequestBody,
    options?: CerebroApiOptions
) => Promise<CerebroPaginatedResponse<any> | null> {
    const widgetGroupBy = transformToString(group_by)

    const widgetConfig = getWidgetConfig(filters, data_source, widgetGroupBy)
    return widgetConfig?.apiFunc ?? (() => Promise.resolve(null))
}

export function getWidgetExportApiFunc({
    filters,
    data_source,
    group_by,
}: Widget): (
    params: any,
    options?: {}
) => Promise<CerebroResourceResponse<Report> | null> {
    const widgetGroupBy = transformToString(group_by)

    const widgetConfig = getWidgetConfig(filters, data_source, widgetGroupBy)
    return widgetConfig?.exportApiFunc ?? (() => Promise.resolve(null))
}

export function getWidgetMetrics({
    metrics,
    data_source,
    include_change_metrics = false,
    type,
    show_totals = false,
    group_by,
}: Widget | ModifiedWidget): string[] {
    const dataSourceConfig = DATA_SOURCES[data_source]
    const metricDependencies: Field<any>[] = []

    if (dataSourceConfig?.metricDependencies) {
        const groupByIds: string[] = group_by.map((group) => {
            return typeof group === 'string' ? group : group?.id ?? '--'
        })
        const metricIds: string[] = metrics.map((metric) => {
            return typeof metric === 'string' ? metric : metric?.id ?? '--'
        })

        groupByIds.forEach((id) => {
            const dependentMetrics = dataSourceConfig?.metricDependencies?.[id]
            dependentMetrics?.forEach((metric) => {
                if (!metricIds.includes(metric?.id)) {
                    metricDependencies.push(metric)
                }
            })
        })
    }

    const allRequiredMetrics = [...metrics, ...metricDependencies]

    const widgetMetrics: string[] = []

    allRequiredMetrics.forEach((metric) => {
        if (!metric) {
            return
        }

        const key = typeof metric === 'string' ? metric : metric.id
        widgetMetrics.push(key)

        const isSegmentDateMetric =
            data_source === 'segment_facts' &&
            ['report_date__min', 'report_date__max'].includes(key)

        if (type === TABLE && show_totals) {
            widgetMetrics.push(`${key}__total`)

            if (include_change_metrics && !isSegmentDateMetric) {
                widgetMetrics.push(`${key}__total__last_period`)
                widgetMetrics.push(`${key}__total__change`)
                widgetMetrics.push(`${key}__total__percentage_change`)
            }
        }

        if (
            include_change_metrics &&
            type !== TIME_SERIES &&
            !isSegmentDateMetric
        ) {
            widgetMetrics.push(`${key}__last_period`)
            widgetMetrics.push(`${key}__change`)
            widgetMetrics.push(`${key}__percentage_change`)
        }
    })

    return widgetMetrics
}

export function removeUIStateFromWidget(widget: Widget): Widget {
    return produce(widget, (draft: any) => {
        Object.keys(defaultWidgetState).forEach((key) => {
            delete draft[key]
        })
        if (!isEmpty(draft?.filters?.[DATES])) {
            draft.filters[DATES] = deserializeDatesFilter(draft.filters[DATES])
        }

        if (
            draft?.filters?.rangeLag &&
            (Number.isInteger(draft?.filters?.rangeLag) ||
                !isEmpty(draft?.filters?.rangeLag))
        ) {
            draft.filters.rangeLag = deserializeDatesFilter(
                draft.filters.rangeLag
            )
        }

        if (widget.layout.y === Infinity) {
            draft.layout.y = Y_BOTTOM_OF_PAGE
        }
    })
}

export function removeUIStateFromTabsAndWidgets(
    tabs: DashboardTab[]
): DashboardTab[] {
    return tabs.map((tab) => ({
        ...produce(tab, (draft: any) => {
            Object.keys(defaultDashboardTabState).forEach((key) => {
                delete draft[key]
            })

            if (!isEmpty(draft?.localFilters?.[DATES])) {
                draft.localFilters[DATES] = deserializeDatesFilter(
                    draft.localFilters[DATES]
                )
            }

            if (
                Number.isInteger(draft?.localFilters?.rangeLag) ||
                !isEmpty(draft?.localFilters?.rangeLag)
            ) {
                draft.localFilters.rangeLag = deserializeDatesFilter(
                    draft.localFilters.rangeLag.toString()
                )
            }
        }),
        widgets: tab.widgets.map(removeUIStateFromWidget),
    }))
}

export function getNewYPosition(widgets: Widget[]): number {
    const max = widgets
        .map((w) => w.layout)
        .reduce((prev, current) => (prev.y > current.y ? prev : current))

    return max.y + max.h
}
