import { ReactElement } from 'react'

import Icon from '@ant-design/icons'

import LocationPinIconSvg from './LocationPinIconSvg'
import { IconProps } from '../localTypes'

const LocationPinIcon = (props: IconProps): ReactElement => (
    <Icon component={LocationPinIconSvg} {...props} />
)

export default LocationPinIcon
