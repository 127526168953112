import { handleActions } from 'redux-actions'

import { walmartAdItemsSummaryTableColumnsConfig } from 'configuration/tables'
import { DATES, AD_ITEM_STATES, CAMPAIGNS, AD_ACCOUNTS } from 'const/filters'
import { FILTERS, FILTER_SETTINGS } from 'const/reducerKeys'
import { serializeColumns } from 'helpers/tables'
import { getDefaultTable, defaultTab } from 'reducers/ui/defaults'

const defaultState = {
    ...defaultTab,
    [FILTERS]: {
        [AD_ITEM_STATES]: [],
        [CAMPAIGNS]: [],
        [AD_ACCOUNTS]: [],
    },
    [FILTER_SETTINGS]: {
        anchored: [DATES, AD_ITEM_STATES, CAMPAIGNS],
        order: [AD_ACCOUNTS],
        displayState: {
            [AD_ACCOUNTS]: true,
        },
    },
    table: getDefaultTable(
        serializeColumns(walmartAdItemsSummaryTableColumnsConfig),
        { showTotalRow: true }
    ),
}

export default handleActions({}, defaultState)
