import { combineChildrenReducers } from 'utilities/reducers'

// Reducer that handles page level data, that is, parent reducer

// Tab reducers
import automationsReducer from './automations'
import pageReducer from './page'
import profilesReducer from './profiles'
import rulebooksReducer from './rulebooks'

export default combineChildrenReducers(pageReducer, {
    automations: automationsReducer,
    profiles: profilesReducer,
    rulebooks: rulebooksReducer,
})
