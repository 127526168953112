import { Button } from 'antd'
import noop from 'lodash/noop'
import PropTypes from 'prop-types'

import { PlusIcon } from '../Icons'

const propTypes = {
    icon: PropTypes.node,
    onClick: PropTypes.func,
    loading: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
}
const defaultProps = {
    icon: <PlusIcon />,
    onClick: noop,
    loading: false,
    children: null,
    className: '',
}

const CreateResourceButton = ({ children, ...props }) => (
    <Button type="primary" {...props}>
        {children}
    </Button>
)

CreateResourceButton.propTypes = propTypes
CreateResourceButton.defaultProps = defaultProps

export default CreateResourceButton
