import { all, call, put, select } from 'redux-saga/effects'

import {
    fetchBrandOptionsSuccess,
    fetchDspAdvertiserOptionsSuccess,
    fetchIntegrationOptionsSuccess,
    fetchOrganizationSuccess,
} from 'actions/orgs'
import { makeFetchResourceSuccess } from 'actions/ui/shared'
import { ORGANIZATION_PAGE } from 'const/pages'
import { userHasOrgAdminPermissions } from 'helpers/featurePermissions'
import { cerebroApiSaga } from 'sagas/common'
import { fetchOrganizationGroupsSaga } from 'sagas/orgs/groups/workers'
import { fetchOrganizationMembersSaga } from 'sagas/orgs/members'
import uiSagaRegistry from 'sagas/ui/registry'
import {
    selectDomainValue as selectAuthDomainValue,
    selectUserOrganizationFeaturePermissions,
} from 'selectors/auth'
import { getDspAdvertisers } from 'services/cerebroApi/orgScope/dspApi'
import {
    getAdAccounts,
    getOrganization,
    getOrganizationIntegrations,
} from 'services/cerebroApi/orgScope/resourceApi'

const PAGE_PATH = [ORGANIZATION_PAGE]

function* fetchBrandOptionsSaga(): any {
    yield call(cerebroApiSaga, fetchBrandOptionsSuccess, getAdAccounts, {
        limit: 1000,
    })
}

function* fetchDspAdvertiserOptionsSaga(): any {
    const userPermissions = yield select(
        selectUserOrganizationFeaturePermissions
    )

    if (userHasOrgAdminPermissions(userPermissions)) {
        yield call(
            cerebroApiSaga,
            fetchDspAdvertiserOptionsSuccess,
            getDspAdvertisers
        )
    }
}

function* fetchIntegrationOptionsSaga(organizationId: number | string): any {
    const userPermissions = yield select(
        selectUserOrganizationFeaturePermissions
    )

    if (userHasOrgAdminPermissions(userPermissions)) {
        yield call(
            cerebroApiSaga,
            fetchIntegrationOptionsSuccess,
            getOrganizationIntegrations,
            organizationId
        )
    }
}

function* fetchOrganizationSaga(organizationId: number | string): any {
    const response = yield call(
        cerebroApiSaga,
        null,
        getOrganization,
        organizationId
    )
    const organization = response.data

    yield all([
        put(fetchOrganizationSuccess(organization)),
        put(makeFetchResourceSuccess(ORGANIZATION_PAGE)(organization)),
    ])
}

function* fetchPageDataSaga(): any {
    const organizationId = yield select(selectAuthDomainValue, 'organizationId')

    yield all([
        // Organization
        call(fetchOrganizationSaga, organizationId),
        call(fetchOrganizationGroupsSaga, organizationId),
        call(fetchOrganizationMembersSaga, organizationId),
        // Options
        call(fetchBrandOptionsSaga),
        call(fetchIntegrationOptionsSaga, organizationId),
        call(fetchDspAdvertiserOptionsSaga),
    ])
}

/**
 * Mounts page
 */
function* mountPageSaga(): any {
    yield call(fetchPageDataSaga)
}

uiSagaRegistry.registerSagas(PAGE_PATH, {
    mountPageSaga,
    fetchPageDataSaga,
})
