import { Translation } from 'react-i18next'

import { getProductTitleOutsideThemeContext } from 'appConfig'
import IntegrationAliasField from 'components/EditableFields/IntegrationFields/IntegrationAliasField'
import { walmartAdvertiserNameRenderer } from 'components/FieldRenderers'
import { UNDEFINED_VALUE } from 'const/formatting'
import { IntegrationType } from 'const/integrations'
import { WALMART_ADVERTISER_TYPES } from 'const/walmart'
import { Field, Integration, WalmartAdvertiser } from 'types'

import { createField, createLabelLookupField } from './createField'
import { FieldCreatorOptions } from './localTypes'

export function advertiserType<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['type']
    const name = (
        <Translation>
            {(t) => t('table:fields.accountType.name', 'Ad Account Type')}
        </Translation>
    )

    return createLabelLookupField({
        ...options,
        id: 'advertiser_account_type',
        name,
        shortName: name,
        minWidth: 100,
        isTotalSupported: options.isTotalSupported ?? true,
        dataIndex,
        labels: WALMART_ADVERTISER_TYPES,
        width: 200,
    })
}

export function advertiserAccountName<
    RecordType extends { advertiser?: WalmartAdvertiser },
>(options: FieldCreatorOptions<RecordType> = {}): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['alias']
    const name = (
        <Translation>
            {(t) =>
                t('table:fields.advertisingAccountName.name', 'Ad Account Name')
            }
        </Translation>
    )

    return createField({
        ...options,
        id: 'advertiser_account_name',
        name,
        shortName: name,
        minWidth: 200,
        isTotalSupported: options.isTotalSupported ?? true,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: ({ record }) =>
                record.advertiser
                    ? walmartAdvertiserNameRenderer(record.advertiser)
                    : UNDEFINED_VALUE,
        },
        width: 250,
    })
}

export function advertiserIntegrationAlias<RecordType extends Integration>(
    options: FieldCreatorOptions<RecordType> = {},
    integrationKey: IntegrationType | undefined = undefined
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['alias']
    const name = (
        <Translation>
            {(t) =>
                t('table:fields.advertisingAccountName.name', 'Ad Account Name')
            }
        </Translation>
    )

    return createField({
        ...options,
        id: 'advertiser_account_name',
        name,
        shortName: name,
        minWidth: 200,
        isTotalSupported: options.isTotalSupported ?? true,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: ({ value, record, reloadData }) =>
                integrationKey ? (
                    <IntegrationAliasField
                        record={record}
                        value={value}
                        reloadData={reloadData}
                        integrationKey={integrationKey}
                    />
                ) : (
                    value
                ),
        },
        width: 250,
    })
}

export function advertiserId<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['advertiser_id']
    const name = (
        <Translation>
            {(t) => t('table:fields.advertiserId.name', 'Advertiser ID')}
        </Translation>
    )

    return createField({
        ...options,
        id: 'advertiser_id',
        name,
        shortName: name,
        minWidth: 100,
        isTotalSupported: options.isTotalSupported ?? true,
        dataIndex,
        emptyValue: '',
        width: 200,
    })
}

export function advertiserIntegrationType<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['read_only']
    const name = (
        <Translation>
            {(t) =>
                t(
                    'table:fields.integrationType.name',
                    'Ad Account Integration Type'
                )
            }
        </Translation>
    )

    return createField({
        ...options,
        id: 'advertiser_integration_type',
        name,
        shortName: name,
        minWidth: 100,
        isTotalSupported: options.isTotalSupported ?? true,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: ({ value }) =>
                value ? (
                    <Translation>
                        {(t) =>
                            t(
                                'table:fields.integrationType.options.legacyDataImport',
                                'Legacy Data Import'
                            )
                        }
                    </Translation>
                ) : (
                    <Translation>
                        {(t) =>
                            t(
                                'table:fields.integrationType.options.newIntegration',
                                '{{ productTitle }} Ad Integration',
                                {
                                    productTitle:
                                        getProductTitleOutsideThemeContext(),
                                }
                            )
                        }
                    </Translation>
                ),
        },
        width: 200,
    })
}
