import type { ReactElement } from 'react'

import { flagComponents } from 'images/flags'
import { CountryCode } from 'types'

interface FlagProps {
    country: CountryCode
}

const Flag = (props: FlagProps): ReactElement => {
    const { country } = props
    const FlagImageComponent = flagComponents[country]

    return (
        <span className="fg-flag-wrapper" data-testid={country}>
            {FlagImageComponent}
        </span>
    )
}

export default Flag
