import { combineChildrenReducers } from 'utilities/reducers'

import bidHistoryReducer from './bidHistory'
import pageReducer from './page'
import searchTermsReducer from './searchTerms'

export default combineChildrenReducers(pageReducer, {
    bidHistory: bidHistoryReducer,
    searchTerms: searchTermsReducer,
})
