import { createAction } from 'redux-actions'

import { curryActionForPage } from 'helpers/curry'

// Dashboard
export const fetchDashboardSuccess = createAction('FETCH_DASHBOARD_SUCCESS')
export const makeFetchDashboardSuccess = curryActionForPage(
    fetchDashboardSuccess
)

// Update Widget Sorter
export const updateWidgetSorter = createAction('UPDATE_WIDGET_SORTER')
export const makeUpdateWidgetSorter = curryActionForPage(updateWidgetSorter)

// Update Widget Pagination
export const updateWidgetPagination = createAction('UPDATE_WIDGET_PAGINATION')
export const makeUpdateWidgetPagination = curryActionForPage(
    updateWidgetPagination
)

// Update Widget Table Settings
export const updateWidgetTableColumns = createAction(
    'UPDATE_WIDGET_TABLE_COLUMNS'
)
export const makeUpdateWidgetTableColumns = curryActionForPage(
    updateWidgetTableColumns
)

// Export widget to csv
export const exportWidgetToCsvRequest = createAction(
    'EXPORT_WIDGET_TO_CSV_REQUEST'
)
export const exportWidgetToCsvSuccess = createAction(
    'EXPORT_WIDGET_TO_CSV_SUCCESS'
)

export const makeExportWidgetToCsvRequest = curryActionForPage(
    exportWidgetToCsvRequest
)
export const makeExportWidgetToCsvSuccess = curryActionForPage(
    exportWidgetToCsvSuccess
)
