import { forwardRef, memo, ReactChild, ReactElement, Ref } from 'react'

import { LoadingOutlined } from '@ant-design/icons'
import { Divider, Input, type InputProps } from 'antd'
import Downshift, {
    ControllerStateAndHelpers,
    DownshiftState,
    StateChangeOptions,
} from 'downshift'
import xorBy from 'lodash/xorBy'

import { ColoredTag } from 'components/ColoredTag'
import { TypeaheadOption } from 'types'

import styles from './styles.scss'
import TypeaheadMenu from './TypeaheadMenu'
import { TypeaheadMenuProps } from './types'

interface Props {
    loading: boolean
    options?: TypeaheadOption[]
    values: TypeaheadOption[]
    color?: string
    placeholder: string

    renderMenu?: (props: TypeaheadMenuProps) => ReactElement
    renderTagContent?: (props: TypeaheadOption) => ReactChild
    onChangeInput(inputValue: string): void
    onChange: (values: TypeaheadOption[]) => void
}

const downshiftStateReducer = (
    state: DownshiftState<TypeaheadOption>,
    changes: StateChangeOptions<TypeaheadOption>
): Partial<StateChangeOptions<TypeaheadOption>> => {
    // This prevents the menu from being closed and prevents auto complete
    // of the input when the user selects an item with a keyboard or mouse
    switch (changes.type) {
        case Downshift.stateChangeTypes.keyDownEnter:
        case Downshift.stateChangeTypes.mouseUp:
        case Downshift.stateChangeTypes.clickItem:
            return {
                ...changes,
                isOpen: state.isOpen,
                highlightedIndex: state.highlightedIndex,
                inputValue: state.inputValue,
            }
        default:
            return changes
    }
}

const defaultRenderMenu = (props: TypeaheadMenuProps): ReactElement => (
    <TypeaheadMenu {...props} />
)

const defaultRenderTagContent = (option: TypeaheadOption): ReactChild =>
    option.label

const Typeahead = (
    {
        loading,
        options = [],
        values,
        color,
        placeholder,
        renderMenu = defaultRenderMenu,
        renderTagContent = defaultRenderTagContent,
        onChangeInput,
        onChange,
    }: Props,
    ref: Ref<HTMLElement>
): ReactElement => {
    const handleToggleOption = (option: TypeaheadOption): void => {
        onChange(xorBy(values, [option], 'value'))
    }

    const syncDownshiftState = ({
        type,
        selectedItem,
        inputValue,
    }: StateChangeOptions<TypeaheadOption>): void => {
        // Toggle items from state when enter key is pressed
        if (type === Downshift.stateChangeTypes.keyDownEnter && selectedItem) {
            handleToggleOption(selectedItem)
        }

        if (type === Downshift.stateChangeTypes.changeInput && inputValue) {
            onChangeInput(inputValue)
        }
    }

    return (
        <Downshift
            defaultIsOpen
            selectedItem={null}
            itemToString={(item) => (item ? item.label : '')}
            stateReducer={downshiftStateReducer}
            onStateChange={syncDownshiftState}
        >
            {(downshift: ControllerStateAndHelpers<TypeaheadOption>) => (
                <div className={styles.typeahead}>
                    <div className="mb-2">
                        <Input
                            {...(downshift.getInputProps({
                                ref,
                                placeholder,
                                suffix: loading ? (
                                    <LoadingOutlined />
                                ) : (
                                    <span />
                                ),
                            }) as unknown as InputProps)}
                        />
                    </div>

                    {renderMenu({
                        downshift,
                        options,
                        values,
                        onToggleOption: handleToggleOption,
                    })}

                    {values.length > 0 && (
                        <div className={styles['selected-options']}>
                            <Divider
                                className={styles['selected-options-divider']}
                            />

                            {values.map((option) => (
                                <ColoredTag
                                    key={option?.value?.toString()}
                                    className={styles.tag}
                                    color={color}
                                    closable
                                    onClose={() => handleToggleOption(option)}
                                    onClick={() => handleToggleOption(option)}
                                >
                                    {renderTagContent(option)}
                                </ColoredTag>
                            ))}
                        </div>
                    )}
                </div>
            )}
        </Downshift>
    )
}

export default memo(forwardRef<HTMLElement, Props>(Typeahead))
