import { ReactNode } from 'react'

import { generatePath } from 'react-router-dom'

import { ResourceLink } from 'components/Links/ResourceLink'
import { UNDEFINED_VALUE } from 'const/formatting'
import { WALMART_CAMPAIGN_PAGE } from 'const/pages'
import { getPath } from 'helpers/pages'
import { isUnset } from 'helpers/utilities'
import { formatWalmartResourceStateField } from 'helpers/walmart'
import { CellRenderProps, WalmartCampaignGroupBy } from 'types'

export function wmCampaignNameRenderer<
    RecordType extends WalmartCampaignGroupBy,
>({
    record,
    value,
    undefinedValue = UNDEFINED_VALUE,
}: CellRenderProps<RecordType>): ReactNode {
    return isUnset(value) ? (
        undefinedValue
    ) : (
        <ResourceLink
            to={generatePath(getPath(WALMART_CAMPAIGN_PAGE), {
                wmCampaignId: record.campaign.id,
            })}
        >
            {value}
        </ResourceLink>
    )
}

export function wmCampaignStateRenderer<
    RecordType extends WalmartCampaignGroupBy,
>({
    record,
    value,
    undefinedValue = UNDEFINED_VALUE,
}: CellRenderProps<RecordType>): ReactNode {
    if (isUnset(value)) {
        return undefinedValue
    }

    return formatWalmartResourceStateField(record, 'campaign')
}
