import { ReactElement, ReactNode } from 'react'

import styles from './styles.scss'

interface Props {
    leftContent?: ReactNode
    rightContent?: ReactNode
}
export default function DetailWrapper({
    leftContent,
    rightContent,
}: Props): ReactElement {
    return (
        <div className={styles['details-wrapper']}>
            {leftContent && (
                <div className={styles['details-wrapper-left']}>
                    {leftContent}
                </div>
            )}
            {rightContent && <div>{rightContent}</div>}
        </div>
    )
}
