import { combineChildrenReducers } from 'utilities/reducers'

// Reducer that handles page level data, that is, parent reducer
import campaignsReducer from './campaigns'
import pageReducer from './page'
import productsReducer from './products'

// Children reducers

export default combineChildrenReducers(pageReducer, {
    campaigns: campaignsReducer,
    products: productsReducer,
})
