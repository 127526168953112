import { ReactElement } from 'react'

import { Alert } from 'antd'
import isEmpty from 'lodash/isEmpty'
import { Trans, useTranslation } from 'react-i18next'

import { useAppConfig } from 'appConfig'
import { ContactUsLink } from 'components/Links'
import { formatDate } from 'helpers/dates'
import { Organization } from 'types'
import moment from 'utilities/moment'

const DAYS_BEFORE = 5 // Number of days to show banner before expiry

interface Props {
    organization?: Organization
}

function TrialNotificationBanner({ organization }: Props): ReactElement | null {
    const { t } = useTranslation('account')
    const { productTitle } = useAppConfig()

    if (!organization || isEmpty(organization)) {
        return null
    }

    const { locked, trial_expiration_date: trialExpirationDate } = organization

    if (locked || !trialExpirationDate) {
        return null
    }

    const trialExpirationMoment = moment(trialExpirationDate)

    // Show banner only DAYS_BEFORE days before trial expiration date
    if (trialExpirationMoment.clone().subtract(DAYS_BEFORE, 'day').isAfter()) {
        return null
    }

    const formattedDate = formatDate(trialExpirationDate, 'day')
    const message = trialExpirationMoment.isSameOrAfter()
        ? t(
              'account:Banners.TrialNotificationBanner.trialEndsMessage',
              'We hope you are enjoying {{ productTitle }}. Your service ends on {{ date }}.',
              {
                  date: formattedDate,
                  productTitle,
              }
          )
        : t(
              'account:Banners.TrialNotificationBanner.trialEndedMessage',
              'We hope you are enjoying {{ productTitle }}. Your service ended on {{ date }}.',
              {
                  date: formattedDate,
                  productTitle,
              }
          )

    const messageDiv = (
        <Trans i18nKey="account:Banners.TrialNotificationBanner.message">
            {{ message }} To continue using {{ productTitle }}{' '}
            <ContactUsLink link contactUsPage="/group/3/create/10">
                contact us
            </ContactUsLink>
            .
        </Trans>
    )

    return <Alert banner type="warning" message={messageDiv} />
}

export default TrialNotificationBanner
