import { useCallback } from 'react'

import get from 'lodash/get'
import last from 'lodash/last'
import lastIndexOf from 'lodash/lastIndexOf'

interface UseResourceReturnValues {
    fieldName: string
    getFromResource: (path: string[], defaultValue?: any) => any
    resourceId: string
}

export const useResource = <RecordType>(
    record: RecordType,
    dataIndex: string[],
    resourceName: string
): UseResourceReturnValues => {
    const fieldName = last(dataIndex) as string
    const resourceIndex = lastIndexOf(dataIndex, resourceName)
    const resourcePath = dataIndex.slice(0, resourceIndex + 1)

    const getFromResource = useCallback(
        (path: string[], defaultValue?: any): any =>
            get(record, [...resourcePath, ...path], defaultValue),
        [record, resourcePath]
    )

    const resourceId = getFromResource(['id'])

    return { fieldName, getFromResource, resourceId }
}
