import { Checkbox } from 'antd'

import { FormikWithCustomHandlersPropTypes } from '../propTypes'
import withCustomHandlers from '../withCustomHandlers'

const FormikCheckbox = ({
    field: { value },
    form, // eslint-disable-line
    handleChange,
    handleBlur,
    handleClick, // eslint-disable-line
    ...props
}) => (
    <Checkbox
        checked={value}
        onChange={(e) => handleChange(e.target.checked)}
        {...props}
    />
)

FormikCheckbox.propTypes = FormikWithCustomHandlersPropTypes

export default withCustomHandlers(FormikCheckbox)
