import { Input } from 'antd'
import classNames from 'classnames'
import { getIn } from 'formik'

import { FormikWithCustomHandlersPropTypes } from '../propTypes'
import withCustomHandlers from '../withCustomHandlers'

const FormikInput = ({
    field: { value, name },
    form,
    handleChange,
    handleBlur,
    handleClick,
    ...props
}) => (
    <Input
        value={value}
        onChange={(e) => handleChange(e.target.value)}
        onBlur={handleBlur}
        className={classNames({
            'fg-control-error':
                getIn(form.errors, name) && getIn(form.touched, name),
        })}
        {...props}
    />
)

FormikInput.propTypes = FormikWithCustomHandlersPropTypes

export default withCustomHandlers(FormikInput)
