import { ReactElement, useState, useEffect, useCallback } from 'react'

import { Select } from 'antd'
import { Field, useFormikContext } from 'formik'

import { FormikSelect } from 'components/formik'
import { SelectOptionContent } from 'components/SelectOptionContent'
import { SPONSORED_PRODUCT, FACT_TYPE_LABELS } from 'const/factTypes'
import { useCerebroApiRequest } from 'hooks'
import { getCampaign } from 'services/cerebroApi/orgScope/resourceApi'
import { Campaign, CerebroResourceResponse } from 'types'

import { KEYWORD_TYPE, FormValues } from './shared'

interface OptionsWithMetadata {
    value: string
    disabled: boolean
    metadata?: string
}

const getOptionContentForCampaign = (
    campaign: Campaign | null
): OptionsWithMetadata[] => {
    if (!campaign) {
        return []
    }
    if (campaign.profile.type === 'vendor') {
        return [
            {
                value: KEYWORD_TYPE.CAMPAIGN_NEGATIVE,
                disabled: true,
                metadata: 'Not supported for Vendor Campaigns',
            },
            {
                value: KEYWORD_TYPE.AD_GROUP_NEGATIVE,
                disabled: false,
            },
        ]
    }
    // catch all in-case other campaign types are added to Forge
    if (campaign.campaign_type !== SPONSORED_PRODUCT) {
        return [
            {
                value: KEYWORD_TYPE.CAMPAIGN_NEGATIVE,
                disabled: true,
                metadata: `Not supported for ${
                    FACT_TYPE_LABELS[campaign.campaign_type] ??
                    campaign.campaign_type
                } Campaigns`,
            },
            {
                value: KEYWORD_TYPE.AD_GROUP_NEGATIVE,
                disabled: false,
            },
        ]
    }
    return [
        {
            value: KEYWORD_TYPE.CAMPAIGN_NEGATIVE,
            disabled: false,
        },
        {
            value: KEYWORD_TYPE.AD_GROUP_NEGATIVE,
            disabled: false,
        },
    ]
}

const KeywordTypeSelectField = (): ReactElement => {
    const { setFieldValue, values } = useFormikContext<FormValues>()
    const [selectedCampaign, setSelectedCampaign] = useState<Campaign | null>(
        null
    )
    const [loading, setLoading] = useState<boolean>(false)

    const makeCerebroApiRequest = useCerebroApiRequest()

    const hydrateForm = useCallback(async (): Promise<void> => {
        if (values.campaignId) {
            setLoading(true)
            await makeCerebroApiRequest<
                Campaign,
                CerebroResourceResponse<Campaign>
            >({
                request: getCampaign(values.campaignId),
                onRequestSuccess: ({ data: campaign }) => {
                    setSelectedCampaign(campaign)
                    setLoading(false)
                },
            })
        }
    }, [makeCerebroApiRequest, values.campaignId])

    useEffect(() => {
        setFieldValue('keywordType', '')
        hydrateForm()
    }, [hydrateForm, setFieldValue])

    return (
        <Field
            name="keywordType"
            component={FormikSelect}
            placeholder="Select Negative Keyword Type"
            style={{ width: 250 }}
            loading={loading}
            disabled={!values.campaignId || loading}
        >
            {getOptionContentForCampaign(selectedCampaign).map(
                ({ value, disabled, metadata }, idx) => (
                    <Select.Option key={idx} value={value} disabled={disabled}>
                        <SelectOptionContent
                            label={value}
                            metadata={metadata}
                        />
                    </Select.Option>
                )
            )}
        </Field>
    )
}

export default KeywordTypeSelectField
