import { connect } from 'react-redux'

import { mountAppRequest, toggleSidebarCollapsed } from 'actions/ui/app'
import {
    selectHasRecentIntegration,
    selectHasNoIntegrations,
    selectUserOrganizationFeaturePermissions,
} from 'selectors/auth'
import { selectCurrentOrganization } from 'selectors/orgs'
import {
    selectGlobalNotification,
    selectIsSidebarCollapsed,
    selectDomainValue,
} from 'selectors/ui'
import { RootReduxState } from 'types'

import AppLayout, { AppLayoutProps } from './AppLayout'

const mapStateToProps = (
    state: RootReduxState
): Pick<
    AppLayoutProps,
    | 'hasRecentIntegration'
    | 'hasNoIntegrations'
    | 'currentOrganization'
    | 'featurePermissions'
    | 'globalNotification'
    | 'isSidebarCollapsed'
    | 'mounting'
> => ({
    hasRecentIntegration: selectHasRecentIntegration(state),
    hasNoIntegrations: selectHasNoIntegrations(state),
    currentOrganization: selectCurrentOrganization(state),
    featurePermissions: selectUserOrganizationFeaturePermissions(state),
    globalNotification: selectGlobalNotification(state),
    isSidebarCollapsed: selectIsSidebarCollapsed(state),
    mounting: selectDomainValue(state, ['app', 'mounting']),
})

const mapDispatchToProps: Pick<
    AppLayoutProps,
    'mountAppRequest' | 'toggleSidebarCollapsed'
> = {
    mountAppRequest,
    toggleSidebarCollapsed,
}

export default connect(mapStateToProps, mapDispatchToProps)(AppLayout)
