import { ReactElement } from 'react'

import { Translation } from 'react-i18next'

import { removeProductFromLabelRenderer } from 'components/FieldRenderers/Renderers/product'
import { productCogsRenderer } from 'components/FieldRenderers/Renderers/productCogs'
import { Field } from 'types'

import {
    createActionField,
    createField,
    createNumberField,
} from './createField'
import { FieldCreatorOptions } from './localTypes'

export function productMetaDataTitle<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['product_metadata', 'title']
    return createField({
        ...options,
        id: 'product_metadata__title',
        name: 'Product Title',
        shortName: 'Product Title',
        minWidth: 80,
        isTotalSupported: options.isTotalSupported ?? true,
        dataIndex,
        width: 300,
    })
}

export function productMetaDataPrice<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['product_metadata', 'price']
    return createField({
        ...options,
        id: 'product__metadata_price',
        name: 'List Price',
        shortName: 'List Price',
        minWidth: 60,
        dataIndex,
        width: 100,
    })
}

function getCogsField<RecordType>(
    options: FieldCreatorOptions<RecordType>,
    id: string,
    dataIndex: string[],
    name: ReactElement,
    shortName: ReactElement,
    helpText: ReactElement
): Field<RecordType> {
    return createField({
        ...options,
        id,
        name,
        shortName,
        localDefinition: helpText,
        minWidth: 120,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: (props) =>
                productCogsRenderer<any>({ cellRenderProps: props, dataIndex }),
        },
    })
}

export function skuCogs<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['total_cogs']
    const name = (
        <Translation>
            {(t) => t('table:fields.cogs.name', 'Total Cost of Goods Sold')}
        </Translation>
    )
    const shortName = (
        <Translation>
            {(t) => t('table:fields.cogs.shortName', 'Total COGS')}
        </Translation>
    )
    const helpText = (
        <Translation>
            {(t) =>
                t(
                    'table:fields.cogs.helpText',
                    'Most recent COGS during selected date range.'
                )
            }
        </Translation>
    )

    return getCogsField(
        options,
        'sku__total_cogs',
        dataIndex,
        name,
        shortName,
        helpText
    )
}

export function skuRawCogs<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['raw_cogs']
    const name = (
        <Translation>
            {(t) => t('table:fields.cogs.raw.name', 'Cost of Goods Sold')}
        </Translation>
    )
    const shortName = (
        <Translation>
            {(t) => t('table:fields.cogs.raw.shortName', 'COGS')}
        </Translation>
    )
    const helpText = (
        <Translation>
            {(t) =>
                t(
                    'table:fields.cogs.helpText',
                    'Most recent COGS during selected date range.'
                )
            }
        </Translation>
    )
    return getCogsField(
        options,
        'sku__raw_cogs',
        dataIndex,
        name,
        shortName,
        helpText
    )
}

export function skuMiscCogs<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['misc_cogs']
    const name = (
        <Translation>
            {(t) =>
                t(
                    'table:fields.cogs.misc.name',
                    'Miscellaneous Cost of Goods Sold'
                )
            }
        </Translation>
    )
    const shortName = (
        <Translation>
            {(t) => t('table:fields.cogs.misc.shortName', 'Misc COGS')}
        </Translation>
    )
    const helpText = (
        <Translation>
            {(t) =>
                t(
                    'table:fields.cogs.helpText',
                    'Most recent COGS during selected date range.'
                )
            }
        </Translation>
    )
    return getCogsField(
        options,
        'sku__misc_cogs',
        dataIndex,
        name,
        shortName,
        helpText
    )
}

export function skuSupplierCogs<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['supplier_cogs']
    const name = (
        <Translation>
            {(t) =>
                t(
                    'table:fields.cogs.supplier.name',
                    'Supplier Cost of Goods Sold'
                )
            }
        </Translation>
    )
    const shortName = (
        <Translation>
            {(t) => t('table:fields.cogs.supplier.shortName', 'Supplier COGS')}
        </Translation>
    )
    const helpText = (
        <Translation>
            {(t) =>
                t(
                    'table:fields.cogs.helpText',
                    'Most recent COGS during selected date range.'
                )
            }
        </Translation>
    )
    return getCogsField(
        options,
        'sku__supplier_cogs',
        dataIndex,
        name,
        shortName,
        helpText
    )
}

export function productMetaDataNumberOfItems<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? [
        'product_metadata',
        'number_of_items',
    ]
    return createNumberField({
        ...options,
        id: 'product_metadata__number_of_items',
        name: 'Product Pack Size',
        shortName: 'Pack Size',
        minWidth: 60,
        dataIndex,
        width: 100,
    })
}

export function productAdId<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['product_ad_id']
    return createField({
        ...options,
        id: 'product_ad_id',
        name: 'Product Ad',
        shortName: 'Product Ad',
        minWidth: 80,
        dataIndex,
        width: 300,
        sorter: options.sorter ?? false,
    })
}

export function productAsin<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['asin']
    return createField({
        ...options,
        id: 'product_ad__asin',
        name: 'ASIN',
        shortName: 'ASIN',
        minWidth: 50,
        dataIndex,
        width: 100,
    })
}

export function productSku<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['sku']
    return createField({
        ...options,
        id: 'product_ad__sku',
        name: 'SKU',
        shortName: 'SKU',
        minWidth: 80,
        dataIndex,
        width: 120,
    })
}

export function productAdState<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['product_ad', 'state']
    return createField({
        ...options,
        id: 'product_ad__state',
        name: 'Product Ad State',
        shortName: 'State',
        minWidth: 70,
        dataIndex,
        width: 100,
    })
}

export function archiveProductAd<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createActionField({
        ...options,
        id: 'archive_product_ad',
        dataIndex: [],
        isResizeable: false,
        minWidth: 68,
        align: 'center',
        sorter: false,
        fixed: 'right',
    })
}

export function removeProductFromLabel<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createActionField({
        ...options,
        id: 'remove_product_from_label',
        dataIndex: [],
        isResizeable: false,
        minWidth: 68,
        renderOptions: options.renderOptions ?? {
            render: (props) => removeProductFromLabelRenderer<any>(props),
        },
        sorter: false,
        fixed: 'right',
        align: 'center',
    })
}
