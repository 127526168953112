import { ReactElement } from 'react'

import { Tooltip, type TooltipProps } from 'antd'

import './styles.scss'

const CustomToolTip = ({
    children = null,
    ...rest
}: TooltipProps): ReactElement => <Tooltip {...rest}>{children}</Tooltip>

export default CustomToolTip
