import { ReactElement, useEffect, useState } from 'react'

import { Button, notification, Typography } from 'antd'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Redirect, useHistory } from 'react-router-dom'

import { signOutRequest } from 'actions/auth'
import { COGNITO_ACCESS_TOKEN } from 'const/localStorage'
import { AUTH_PAGE } from 'const/pages'
import { useUserContext } from 'context/UserContext'
import { getPath } from 'helpers/pages'
import CobaltLogo from 'images/logo/CobaltLogo'
import { useAnalytics } from 'services/analytics'
import { AccessToken, createStorableAccessToken } from 'types/auth'

import AccessTokenForm from './Forms/AccessTokenForm'
import AddPhoneNumberForm from './Forms/AddPhoneNumberForm'
import VerifyPhoneNumberForm from './Forms/VerifyPhoneNumberForm'
import styles from './styles.scss'

type SetUpPhoneNumberPageStep = 'accessToken' | 'addPhoneNumber' | 'verify'

const SetUpPhoneNumberPage = (): ReactElement => {
    const { t } = useTranslation('account')
    const [accessToken, setAccessToken] = useState<AccessToken>()
    const history = useHistory()
    const dispatch = useDispatch()
    const [validPhoneNumber, setValidPhoneNumber] = useState<string>()
    const { setMfaConfigured, userMfaSettings } = useUserContext()

    const now = new Date().getTime()
    const haveValidToken = accessToken?.value && accessToken?.ttl >= now
    const { trackEvent } = useAnalytics()

    let currentStep: SetUpPhoneNumberPageStep = 'verify'
    if (!haveValidToken) {
        currentStep = 'accessToken'
    } else if (!validPhoneNumber) {
        currentStep = 'addPhoneNumber'
    } else {
        currentStep = 'verify'
    }

    useEffect(() => {
        if (currentStep === 'addPhoneNumber') {
            trackEvent('MFA - Add Phone Number Started', {
                text: 'MFA - Add Phone Number Started',
            })
        } else if (currentStep === 'verify') {
            trackEvent('MFA - Verify Phone Number Started', {
                text: 'MFA - Verify Phone Number Started',
            })
        }
    }, [currentStep])

    const updateAccessToken = (newAccessToken: string): void => {
        const token = createStorableAccessToken(newAccessToken)

        localStorage.setItem(COGNITO_ACCESS_TOKEN, JSON.stringify(token))
        setAccessToken(token)
    }

    useEffect(() => {
        const storedData = localStorage.getItem(COGNITO_ACCESS_TOKEN)
        if (storedData) {
            const token = JSON.parse(storedData) as AccessToken
            if (token.ttl >= new Date().getTime()) {
                setAccessToken(token)
            }
        }
    }, [])

    const logOut = (): void => {
        history.push(getPath(AUTH_PAGE))
        dispatch(signOutRequest())
    }

    function setMfaConfigurationComplete(): void {
        setMfaConfigured()
        notification.config({
            top: 10,
            duration: 5,
            placement: 'top',
        })
        notification.success({
            message: t(
                'account:SetUpPhoneNumberPage.successMsg',
                'Your phone number has been successfully verified! Two-factor authentication is now active.'
            ),
            style: {
                width: 'auto',
                fontSize: 14,
            },
        })
    }

    if (userMfaSettings?.needsToSetUpMfa === false) {
        return <Redirect to="/" />
    }

    return (
        <div className={styles.container}>
            <div className={styles.logo}>
                <CobaltLogo style={{ height: 48 }} />
            </div>
            <div className={classNames(styles['form-container'], styles.form)}>
                {currentStep === 'accessToken' && (
                    <AccessTokenForm
                        onAccessTokenAcquired={updateAccessToken}
                    />
                )}
                {currentStep === 'addPhoneNumber' && accessToken && (
                    <AddPhoneNumberForm
                        accessToken={accessToken.value}
                        onPhoneNumberUpdated={(phoneNumber) =>
                            setValidPhoneNumber(phoneNumber)
                        }
                    />
                )}
                {currentStep === 'verify' &&
                    accessToken &&
                    validPhoneNumber && (
                        <VerifyPhoneNumberForm
                            accessToken={accessToken.value}
                            phoneNumberToVerify={validPhoneNumber}
                            onPhoneNumberVerified={() =>
                                setMfaConfigurationComplete()
                            }
                            onEditPhoneNumberRequested={() =>
                                setValidPhoneNumber(undefined)
                            }
                        />
                    )}
                <div className={classNames(styles.footer, styles.compressed)}>
                    <Typography>
                        {t(
                            'mfa.setUpPhoneNumber.changeAccounts',
                            'Need to change accounts?'
                        )}{' '}
                        <Button
                            type="link"
                            onClick={logOut}
                            style={{ paddingLeft: 0 }}
                        >
                            {t('common:logOut', 'Log Out')}
                        </Button>
                    </Typography>
                </div>
            </div>
        </div>
    )
}

export default SetUpPhoneNumberPage
