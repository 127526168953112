import { memo, ReactElement } from 'react'

import isEqual from 'lodash/isEqual'
import noop from 'lodash/noop'

import { useResource } from 'components/EditableFields/localHooks'
import { updateKeyword } from 'services/cerebroApi/orgScope/resourceApi'
import { KeywordFact, SuggestedBid } from 'types'

import { SuggestedBidField } from '../../SharedFields/SuggestedBidField'

type KeywordRecord = KeywordFact

interface SerializedSuggestedBidParams {
    id: string
    bid: number
}

interface Props<RecordType> {
    dataIndex: string[]
    readOnly: boolean
    record: RecordType
    reloadData: () => void
    rowIndex: number
    updateRecord: (data: { rowIndex: number; record: RecordType }) => void
    value: SuggestedBid
    actionButtonVisible?: boolean
}

function KeywordSuggestedBidField<RecordType extends KeywordRecord>({
    dataIndex = ['keyword', 'suggestedBid'],
    readOnly = false,
    record,
    reloadData = noop,
    rowIndex,
    updateRecord = noop,
    value,
    actionButtonVisible = true,
}: Props<RecordType>): ReactElement {
    const { getFromResource, resourceId } = useResource<RecordType>(
        record,
        dataIndex,
        'keyword'
    )

    const currencyCode = getFromResource(['profile', 'currency_code'])

    const getIsDisabled = (): boolean => {
        const userCanManageKeyword = getFromResource(['user_can_manage'], true)
        return !userCanManageKeyword
    }

    const serializeFieldValues = (params: {
        bid: number
    }): SerializedSuggestedBidParams => ({
        ...params,
        id: resourceId,
    })

    return (
        <SuggestedBidField
            currencyCode={currencyCode}
            disabled={getIsDisabled()}
            fieldName="keyword"
            readOnly={readOnly}
            record={record}
            reloadData={reloadData}
            rowIndex={rowIndex}
            serializeFieldValues={serializeFieldValues}
            updateRecord={updateRecord}
            updateRequestApi={updateKeyword}
            updateRequestData={resourceId}
            updateRequestSuccessMesg="Successfully updated keyword bid"
            value={value}
            actionButtonVisible={actionButtonVisible}
        />
    )
}

// This is a workaround for a typescript bug where components with generics are
// not well supported with React.memo
// @see https://github.com/DefinitelyTyped/DefinitelyTyped/issues/37087#issuecomment-542793243
export default memo(
    KeywordSuggestedBidField,
    isEqual
) as typeof KeywordSuggestedBidField
