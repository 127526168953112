import { Translation } from 'react-i18next'

import { WalmartAdvertiserType } from 'types'

export const MAX_CAMPAIGN_NAME_LENGTH = 265
export const MAX_AD_GROUP_NAME_LENGTH = 255

export const WALMART_AD_ITEM_BID_MIN = 0.2
export const WALMART_AD_ITEM_BID_MAX = 1000000000
export const WALMART_AD_ITEM_BID_STEP = 0.1
export const WALMART_AD_ITEM_BID_PRECISION = 2

export const WALMART_KEYWORD_BID_MIN = 0.3
export const WALMART_KEYWORD_BID_MAX = 1000000000
export const WALMART_KEYWORD_BID_STEP = 0.1
export const WALMART_KEYWORD_BID_PRECISION = 2

export const AUTO_TARGETING = 'auto'
export const MANUAL_TARGETING = 'manual'

type AdvertiserTypeLabels = {
    [index in WalmartAdvertiserType]: string
}

type CampaignMinBudgets = {
    [index in WalmartAdvertiserType]: {
        total: number
        daily: number
    }
}

export const WALMART_ADVERTISER_TYPES: AdvertiserTypeLabels = {
    seller: 'Seller',
    supplier: 'Supplier',
}

export const WALMART_CAMPAIGN_MIN_BUDGETS: CampaignMinBudgets = {
    seller: {
        total: 50,
        daily: 10,
    },
    supplier: {
        total: 100,
        daily: 50,
    },
}

export const WALMART_BUDGET_TYPES = {
    both: {
        label: (
            <Translation>
                {(t) =>
                    t(
                        'table:fields.campaignBudgetType.options.both',
                        'Total & Daily'
                    )
                }
            </Translation>
        ),
        value: 'both',
    },
    total: {
        label: (
            <Translation>
                {(t) =>
                    t('table:fields.campaignBudgetType.options.total', 'Total')
                }
            </Translation>
        ),
        value: 'total',
    },
    daily: {
        label: (
            <Translation>
                {(t) =>
                    t('table:fields.campaignBudgetType.options.daily', 'Daily')
                }
            </Translation>
        ),
        value: 'daily',
    },
    old: {
        label: (
            <Translation>
                {(t) =>
                    t('table:fields.campaignBudgetType.options.old', 'Legacy')
                }
            </Translation>
        ),
        value: 'old',
    },
}

export const NEW_WM_AD_INTEGRATION_HELP_LINK =
    'https://downstreamjs.zendesk.com/hc/en-us/sections/4413222150167-Walmart'

export const WM_ADS_INTEGRATION_ONBOARDING_GUIDE =
    'https://downstreamjs.zendesk.com/hc/en-us/articles/4421085665559-Walmart-Advertising-Integration-Onboarding-Guide'

export const NEW_WM_AD_INTEGRATION_FORM_LINK =
    'https://downstream-developer.s3.us-west-2.amazonaws.com/common/WMCOnboardingForm20240722.docx'
