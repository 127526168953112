import { ReactElement } from 'react'

import { Trans } from 'react-i18next'

import { RankingCategory } from 'types'

import styles from './styles.scss'

interface Props {
    bestRankingCategory: string
    otherRankings: RankingCategory[]
    bestRankingCategoryRank?: number
}

const RankingCategoriesCell = ({
    bestRankingCategory,
    otherRankings,
    bestRankingCategoryRank,
}: Props): ReactElement => {
    return (
        <div className={styles.cell}>
            <div className={styles.root}>
                {bestRankingCategoryRank ? (
                    <Trans i18nKey="table:SegmentsTable.fields.ranking">
                        #{{ rank: bestRankingCategoryRank }} in{' '}
                        {{ name: bestRankingCategory }}
                    </Trans>
                ) : (
                    <Trans i18nKey="table:SegmentsTable.fields.bestRankingCategory.withoutRank">
                        Ranked in: {{ bestRankingCategory }}
                    </Trans>
                )}
            </div>
            <div>
                {otherRankings.map((ranking) => (
                    <div key={ranking.id} className={styles.rank}>
                        <Trans i18nKey="table:SegmentsTable.fields.ranking">
                            #{{ rank: ranking.rank }} in{' '}
                            {{ name: ranking.name }}
                        </Trans>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default RankingCategoriesCell
