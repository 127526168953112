export const VIDEO = 'video'
export const PRODUCT_COLLECTION = 'productCollection'

type AdFormatLabels = {
    [index: string]: string
}

export const AD_FORMAT_LABELS: AdFormatLabels = {
    [VIDEO]: 'Video',
    [PRODUCT_COLLECTION]: 'Product Collection',
}
