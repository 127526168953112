import { ReactElement, CSSProperties } from 'react'

import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { BRAND_COLORS } from 'const/colors'

import styles from './styles.scss'

interface Props {
    enabled: boolean
    text?: string
    className?: CSSProperties
}

export default function StatusIndicator({
    enabled,
    text,
    className,
}: Props): ReactElement {
    const { t } = useTranslation('common')
    const style = {
        color: enabled ? BRAND_COLORS.PRIMARY : '#989A9E',
    }

    let title
    if (text) {
        title = text
    } else {
        title = enabled
            ? t('common:enabled', 'Enabled')
            : t('common:notEnabled', 'Not Enabled')
    }

    return (
        <div
            className={classNames(
                'd-flex align-items-center',
                className as Record<string, any>
            )}
        >
            <div className={styles.badge} style={style} />
            <div className={classNames('ml-2', styles.label)} style={style}>
                {title}
            </div>
        </div>
    )
}
