import { ReactElement } from 'react'

import {
    AutoComplete,
    Input,
    type InputProps,
    type AutoCompleteProps,
} from 'antd'
import classNames from 'classnames'
import { getIn, FieldProps } from 'formik'

import { HandlerProps } from '../localTypes'
import withCustomHandlers from '../withCustomHandlers'

type Props<V, FormValues> = HandlerProps &
    Omit<AutoCompleteProps, 'placeholder'> &
    FieldProps<V, FormValues> &
    Pick<InputProps, 'placeholder'>

function FormikAutoComplete<V extends string, FormValues>({
    field: { value, name },
    form,
    handleChange,
    handleBlur,
    handleClick,
    options,
    placeholder,
    ...props
}: Props<V, FormValues>): ReactElement {
    const { onSelect } = props
    return (
        <AutoComplete
            options={options}
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            onClick={handleClick}
            onSelect={onSelect}
            onInputKeyDown={(e) => {
                if (e.key === 'Enter' && e.currentTarget.value && onSelect) {
                    onSelect(e.currentTarget.value, {
                        value: e.currentTarget.value,
                        label: e.currentTarget.value,
                    })
                }
            }}
            className={classNames({
                'fg-control-error':
                    getIn(form.errors, name) && getIn(form.touched, name),
            })}
            {...props}
        >
            <Input placeholder={placeholder} />
        </AutoComplete>
    )
}

export default withCustomHandlers(FormikAutoComplete)
