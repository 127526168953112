import { ReactElement } from 'react'

import classNames from 'classnames'

import { Organization, UserPermission } from 'types'
import styles from 'views/AppLayout/SideMenu/styles.scss'

import BottomNavigation from './BottomNavigation'
import Branding from './Branding'
import TopNavigation from './TopNavigation'

interface Props {
    currentOrganization?: Organization
    isSidebarCollapsed: boolean
    featurePermissions: UserPermission[]
    hasNoIntegrations: boolean
}

const SideMenu = ({
    currentOrganization,
    isSidebarCollapsed,
    featurePermissions,
    hasNoIntegrations,
}: Props): ReactElement => {
    return (
        <div className={styles['sider-wrapper']}>
            <div>
                <Branding
                    currentOrganization={currentOrganization}
                    isSidebarCollapsed={isSidebarCollapsed}
                />
                <TopNavigation
                    currentOrganization={currentOrganization}
                    featurePermissions={featurePermissions}
                    hasNoIntegrations={hasNoIntegrations}
                />
            </div>
            <div className={styles['bottom-menu']}>
                <div
                    className={classNames(styles.divider, styles['full-width'])}
                />
                <BottomNavigation />
            </div>
        </div>
    )
}

export default SideMenu
