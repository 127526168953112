import { memo, ReactElement } from 'react'

import isEqual from 'lodash/isEqual'

import {
    AD_GROUP_BID_MIN,
    AD_GROUP_BID_MAX,
    AD_GROUP_BID_STEP,
    AD_GROUP_BID_PRECISION,
} from 'const/adGroup'
import { updateAdGroup } from 'services/cerebroApi/orgScope/resourceApi'
import { AdGroupGroupBy } from 'types'

import { BidField } from '../../SharedFields/BidField'

interface Props<RecordType> {
    dataIndex: string[]
    readOnly: boolean
    record: RecordType
}

function AdGroupBidField<RecordType extends AdGroupGroupBy>({
    dataIndex = ['ad_group', 'base_default_bid'],
    readOnly = false,
    record,
}: Props<RecordType>): ReactElement {
    const fieldName = 'default_bid' // field to update
    const resourceId = record.ad_group.id
    const currencyCode = record.ad_group.profile.currency_code

    const getIsDisabled = (): boolean => {
        const userCanManageAdGroup = record.ad_group.user_can_manage
        return !userCanManageAdGroup
    }

    return (
        <BidField
            bidMax={AD_GROUP_BID_MAX}
            bidMin={AD_GROUP_BID_MIN}
            bidPrecision={AD_GROUP_BID_PRECISION}
            bidStep={AD_GROUP_BID_STEP}
            currencyCode={currencyCode}
            disabled={getIsDisabled()}
            fieldName={fieldName}
            fieldPath={dataIndex}
            readOnly={readOnly}
            record={record}
            updateRequestApi={updateAdGroup}
            updateRequestData={resourceId}
            updateRequestSuccessMesg="Successfully updated ad group bid"
        />
    )
}

export default memo(AdGroupBidField, isEqual)
