import { Translation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { deleteOrganizationGroupRenderer } from 'components/FieldRenderers'
import { USER_GROUP_TYPE_NAMES } from 'const/organizations'
import {
    renderDspResources,
    renderResources,
    renderSovResources,
} from 'helpers/organizations'
import { organizationGroupUrl } from 'helpers/urls'
import { Field, OrganizationGroup } from 'types'

import {
    createActionField,
    createField,
    createNumberField,
} from './createField'
import { FieldCreatorOptions } from './localTypes'

export function organizationGroupName<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['name']
    const name = (
        <Translation>
            {(t) => t('table:fields.organizationGroupName.name', 'Group Name')}
        </Translation>
    )
    return createField({
        ...options,
        id: 'organization_group_name',
        name,
        shortName: name,
        minWidth: 200,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: ({ value, record }) => (
                <Link to={organizationGroupUrl(record as OrganizationGroup)}>
                    {value}
                </Link>
            ),
        },
        width: 300,
    })
}

export function organizationGroupAdsDataAccess<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['data_access']
    const name = (
        <Translation>
            {(t) =>
                t(
                    'table:fields.organizationGroupDataAccess.name',
                    'Advertising Data Access'
                )
            }
        </Translation>
    )
    return createField({
        ...options,
        id: 'organization_group_data_access',
        name,
        shortName: name,
        minWidth: 200,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: ({ record }) => renderResources<any>(record),
        },
        width: 300,
    })
}

export function organizationGroupDspDataAccess<
    RecordType extends OrganizationGroup,
>(options: FieldCreatorOptions<RecordType> = {}): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['dsp_data_access']
    const name = (
        <Translation>
            {(t) =>
                t(
                    'table:fields.organizationGroupDSPDataAccess.name',
                    'DSP Data Access'
                )
            }
        </Translation>
    )
    return createField({
        ...options,
        id: 'organization_group_dsp_data_access',
        name,
        shortName: name,
        minWidth: 200,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: ({ record }) => renderDspResources(record),
        },
        width: 300,
    })
}

export function organizationGroupSovDataAccess<
    RecordType extends OrganizationGroup,
>(options: FieldCreatorOptions<RecordType> = {}): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['sov_data_access']
    const name = (
        <Translation>
            {(t) =>
                t(
                    'table:fields.organizationGroupSovDataAccess.name',
                    'Shelf Intelligence Data Access'
                )
            }
        </Translation>
    )
    return createField({
        ...options,
        id: 'organization_group_sov_data_access',
        name,
        shortName: name,
        minWidth: 200,
        isVisible: options.isVisible ?? false,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: ({ record }) => renderSovResources(record),
        },
        width: 300,
    })
}

export function organizationGroupType<RecordType extends OrganizationGroup>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createField({
        ...options,
        id: 'organization_group_type',
        name: 'Group Type',
        shortName: 'Group Type',
        minWidth: 150,
        dataIndex: [],
        renderOptions: {
            render: ({ record }) => {
                if (record.is_read_only) {
                    return USER_GROUP_TYPE_NAMES.read_only
                }
                if (record.is_dashboard_only) {
                    return USER_GROUP_TYPE_NAMES.dashboard_only
                }
                return USER_GROUP_TYPE_NAMES.standard
            },
        },
    })
}

export function organizationGroupMemberCount<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['member_count']
    const name = (
        <Translation>
            {(t) =>
                t('table:fields.organizationGroupMemberCount.name', 'Members')
            }
        </Translation>
    )
    return createNumberField({
        ...options,
        id: 'organization_group_member_count',
        name,
        shortName: name,
        minWidth: 100,
        dataIndex,
        width: 100,
        sorter: options.sorter ?? false,
        align: 'center',
    })
}

export function deleteOrganizationGroup<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createActionField({
        ...options,
        id: 'delete_organization_group',
        dataIndex: [],
        isResizeable: false,
        minWidth: 68,
        renderOptions: options.renderOptions ?? {
            render: (props) => deleteOrganizationGroupRenderer<any>(props),
        },
        sorter: false,
        fixed: 'right',
        align: 'center',
    })
}
