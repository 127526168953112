import { createAction } from 'redux-actions'

export interface CacheEntry {
    funcName: string
    params: string | null
    data: any
}

export const updateCache = createAction<CacheEntry>('UPDATE_CACHE')

export const invalidateCache = createAction<void>('INVALIDATE_CACHE')
