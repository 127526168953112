import { ReactElement } from 'react'

import { Menu } from 'antd'
import classNames from 'classnames'
import { useSelector } from 'react-redux'

import { CurrencyIcon } from 'components/Icons'
import { TextButton } from 'components/TextButton'
import { useCurrentOrganization, useCurrentOrganizationGroup } from 'hooks'
import { selectIsSidebarCollapsed } from 'selectors/ui'
import { RootReduxState } from 'types'

import { getOrgGroupTitle } from './helpers'
import { CurrencyMenuItem, OrganizationMenuItem } from './MenuItems'
import OrganizationButton from './OrganizationButton'
import styles from './styles.scss'

const BottomNavigation = (): ReactElement => {
    const isSidebarCollapsed = useSelector((state: RootReduxState) =>
        selectIsSidebarCollapsed(state)
    )

    const currentOrganization = useCurrentOrganization()
    const currentOrganizationGroup = useCurrentOrganizationGroup()

    return (
        <Menu
            mode="inline"
            theme="dark"
            style={{ marginBottom: 10 }}
            className={classNames(styles['sider-menu'], {
                [styles['sidebar-open']]: !isSidebarCollapsed,
            })}
            selectable={false}
        >
            <CurrencyMenuItem key="currency">
                {({ toggleModal, currencyCode }) => (
                    <TextButton icon={<CurrencyIcon />} onClick={toggleModal}>
                        {!isSidebarCollapsed && <span>{currencyCode}</span>}
                    </TextButton>
                )}
            </CurrencyMenuItem>
            {currentOrganization && (
                <OrganizationMenuItem
                    key="organization"
                    currentOrganization={currentOrganization}
                    currentOrganizationGroup={currentOrganizationGroup}
                >
                    {({ isSteveMode, toggleModal }) =>
                        isSteveMode ? (
                            <OrganizationButton
                                isSidebarCollapsed={isSidebarCollapsed}
                                toggleModal={toggleModal}
                                title={getOrgGroupTitle({
                                    organization: currentOrganization,
                                    organizationGroup: currentOrganizationGroup,
                                })}
                            />
                        ) : (
                            <OrganizationButton
                                isSidebarCollapsed={isSidebarCollapsed}
                                toggleModal={toggleModal}
                                title={currentOrganization?.name}
                            />
                        )
                    }
                </OrganizationMenuItem>
            )}
        </Menu>
    )
}

export default BottomNavigation
