import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'

import { DATES } from 'const/filters'
import { formatCerebroDate } from 'helpers/formatting'

import { presetRanges } from '../dateRange'

export const formatFilters = (filters) => {
    const params = {}

    if (!isEmpty(filters[DATES])) {
        if (isArray(filters[DATES])) {
            params.report_date_min = formatCerebroDate(filters[DATES][0])
            params.report_date_max = formatCerebroDate(filters[DATES][1])
        } else {
            const { range } = presetRanges()[filters[DATES]]
            params.report_date_min = formatCerebroDate(range[0])
            params.report_date_max = formatCerebroDate(range[1])
        }
    }

    return params
}
