import PropTypes from 'prop-types'
import { CirclePicker } from 'react-color'

import { DEFAULT_COLOR_PICKER_PROPS } from 'const/colors'

const propTypes = {
    field: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
}

function FormikColorPicker({
    field: { name, value },
    form: { setFieldValue, setFieldTouched },
    ...props
}) {
    const handleChangeComplete = (color) => {
        setFieldValue(name, color.hex)
        setFieldTouched(name, true)
    }

    return (
        <CirclePicker
            color={value}
            onChangeComplete={handleChangeComplete}
            {...DEFAULT_COLOR_PICKER_PROPS}
            {...props}
        />
    )
}

FormikColorPicker.propTypes = propTypes

export default FormikColorPicker
