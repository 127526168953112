import { ReactElement, SVGAttributes } from 'react'

type Props = SVGAttributes<SVGElement>

function BookIconSvg(props: Props): ReactElement {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M5.4465 4.66667C4.484 4.66667 4.484 3.33333 5.4465 3.33333H17V2H5.1C3.9401 2 3 2.89533 3 4V16C3 17.1047 3.9401 18 5.1 18H17V4.66667H5.4465V4.66667Z" />
        </svg>
    )
}

export default BookIconSvg
