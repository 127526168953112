import find from 'lodash/find'
import { call, select, put } from 'redux-saga/effects'

import {
    makeDownloadTableSuccess,
    makeFetchTableRequest,
    makeFetchTableSuccess,
} from 'actions/ui/shared'
import { campaignTargetsTableColumnsConfig } from 'configuration/tables'
import { DATES } from 'const/filters'
import { CAMPAIGN_PAGE } from 'const/pages'
import { formatFilters } from 'helpers/filters/targetFacts'
import { generateReportNotification } from 'helpers/notifications'
import {
    formatPagination,
    formatSorter,
    formatMetrics,
    formatCurrency,
    formatPeriodDeltaDateRange,
    formatColumns,
} from 'helpers/params'
import { cerebroApiSaga } from 'sagas/common'
import uiSagaRegistry from 'sagas/ui/registry'
import { fetchTargetBidRecommendationsSaga } from 'sagas/ui/shared/bid/workers'
import {
    selectTableSettings,
    selectVisibleCombinedFilters,
    selectVisibleMetricsOfTable,
    selectCurrencyCode,
    selectResourceOfPage,
    selectResourceParamsOfPage,
    selectVisibleColumnsOfTable,
} from 'selectors/ui'
import { updateTarget } from 'services/cerebroApi/orgScope/resourceApi'
import {
    getTargetFacts,
    getTargetFactsExport,
} from 'services/cerebroApi/orgScope/targetFactsApi'

const TAB_PATH = [CAMPAIGN_PAGE, 'targets']
const TABLE_PATH = [...TAB_PATH, 'table']

function* fetchTableSaga(noCount) {
    const { campaignId } = yield select(
        selectResourceParamsOfPage,
        CAMPAIGN_PAGE
    )

    const filters = yield select(selectVisibleCombinedFilters, TAB_PATH)
    const {
        pagination,
        sorter,
        showPeriodDeltas,
        periodDeltaType,
        periodDeltaDateRange,
    } = yield select(selectTableSettings, TABLE_PATH)
    const metrics = yield select(
        selectVisibleMetricsOfTable,
        TABLE_PATH,
        campaignTargetsTableColumnsConfig
    )
    const currency = yield select(selectCurrencyCode)

    const params = {
        ...formatPagination(pagination),
        ...formatSorter(sorter),
        ...formatFilters(filters),
        ...formatMetrics(metrics, showPeriodDeltas),
        ...formatCurrency(currency),
        ...formatPeriodDeltaDateRange(
            showPeriodDeltas,
            periodDeltaType,
            periodDeltaDateRange,
            filters[DATES]
        ),
        campaign: campaignId,
        group_by:
            'target_id,resolved_expression,profile_id,ad_group_id,campaign_id',
    }

    const response = yield call(cerebroApiSaga, null, getTargetFacts, params, {
        headers: { noCount },
    })

    if (response) {
        yield put(makeFetchTableSuccess(TABLE_PATH)(response.data))

        const columns = yield select(
            selectVisibleColumnsOfTable,
            TABLE_PATH,
            campaignTargetsTableColumnsConfig
        )

        if (find(columns, { id: 'target_suggested_bid' })) {
            yield call(
                fetchTargetBidRecommendationsSaga,
                TABLE_PATH,
                response.data.results
            )
        }
    }
}

function* downloadTableSaga() {
    const campaign = yield select(selectResourceOfPage, CAMPAIGN_PAGE)
    const filters = yield select(selectVisibleCombinedFilters, TAB_PATH)
    const { sorter, showPeriodDeltas, periodDeltaType, periodDeltaDateRange } =
        yield select(selectTableSettings, TABLE_PATH)
    const columns = yield select(
        selectVisibleColumnsOfTable,
        TABLE_PATH,
        campaignTargetsTableColumnsConfig
    )

    const currency = yield select(selectCurrencyCode)
    const reportName = `Target Report - ${campaign.name} `
    const params = {
        ...formatFilters(filters),
        ...formatSorter(sorter),
        ...formatColumns(columns, showPeriodDeltas),
        ...formatCurrency(currency),
        ...formatPeriodDeltaDateRange(
            showPeriodDeltas,
            periodDeltaType,
            periodDeltaDateRange,
            filters[DATES]
        ),
        campaign: campaign.id,
        group_by: 'target_id',
        async_download_name: reportName,
    }

    yield call(
        cerebroApiSaga,
        makeDownloadTableSuccess(TABLE_PATH),
        getTargetFactsExport,
        params
    )

    generateReportNotification(reportName)
}

function* fetchTabDataSaga() {
    yield put(makeFetchTableRequest(TABLE_PATH)())
}

function* updateTargetSaga({ targetId, data }) {
    const campaign = yield select(selectResourceOfPage, CAMPAIGN_PAGE)
    const { targeting_type } = campaign

    yield call(cerebroApiSaga, null, updateTarget, targetId, {
        ...data,
        expression_type: targeting_type,
    })
}

// Register Sagas
uiSagaRegistry.registerSagas(TAB_PATH, {
    fetchTabDataSaga,
})

uiSagaRegistry.registerSagas(TABLE_PATH, {
    fetchTableSaga,
    updateTableRecordSaga: updateTargetSaga,
    downloadTableSaga,
})
