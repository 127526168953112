import React from 'react'

import { Link, LinkProps } from 'react-router-dom'

interface TrackedLinkProps extends LinkProps {
    from?: string
}

const TrackedLink: React.FC<TrackedLinkProps> = ({ from, ...rest }) => {
    return (
        <Link
            onClick={() => localStorage.setItem('prevPath', from || '')}
            {...rest}
        />
    )
}

export default TrackedLink
