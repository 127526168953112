import { handleActions } from 'redux-actions'

import { adGroupsTableColumnsConfig } from 'configuration/tables'
import { DATES, AD_GROUPS, AD_GROUP_STATES } from 'const/filters'
import { FILTER_SETTINGS, LOCAL_FILTERS } from 'const/reducerKeys'
import { serializeColumns } from 'helpers/tables'
import { defaultTab, getDefaultTable } from 'reducers/ui/defaults'

const initialState = {
    ...defaultTab,
    [LOCAL_FILTERS]: {
        [AD_GROUPS]: [],
        [AD_GROUP_STATES]: [],
    },
    [FILTER_SETTINGS]: {
        anchored: [DATES, AD_GROUPS, AD_GROUP_STATES],
        order: [],
        displayState: {},
    },
    table: getDefaultTable(serializeColumns(adGroupsTableColumnsConfig)),
}

export default handleActions({}, initialState)
