import { createAction } from 'redux-actions'

import {
    curryPayloadActionForPath,
    curryEmptyPayloadActionForPath,
    PathActionEmptyPayload,
    PathActionPayload,
} from 'helpers/curry'

// Mounting
export const mountTabRequest = createAction<
    PathActionPayload<{
        active: boolean
        mounting: boolean
    }>
>('MOUNT_TAB_REQUEST')

export const mountTabSuccess =
    createAction<PathActionEmptyPayload>('MOUNT_TAB_SUCCESS')

export const mountTabFailure =
    createAction<PathActionPayload<Error>>('MOUNT_TAB_FAILURE')

export type MountTabRequestAction = ReturnType<typeof mountTabRequest>
export type MountTabSuccessAction = ReturnType<typeof mountTabSuccess>
export type MountTabFailureAction = ReturnType<typeof mountTabFailure>

export const makeMountTabRequest = curryPayloadActionForPath(mountTabRequest)
export const makeMountTabSuccess =
    curryEmptyPayloadActionForPath(mountTabSuccess)
export const makeMountTabFailure = curryPayloadActionForPath(mountTabFailure)

// Fetch data
export const fetchTabDataRequest = createAction<PathActionEmptyPayload>(
    'FETCH_TAB_DATA_REQUEST'
)

export const fetchTabDataSuccess = createAction<PathActionEmptyPayload>(
    'FETCH_TAB_DATA_SUCCESS'
)
export const fetchTabDataFailure = createAction<PathActionPayload<Error>>(
    'FETCH_TAB_DATA_FAILURE'
)

export type FetchTabDataRequestAction = ReturnType<typeof fetchTabDataRequest>
export type FetchTabDataSuccessAction = ReturnType<typeof fetchTabDataSuccess>
export type FetchTabDataFailureAction = ReturnType<typeof fetchTabDataFailure>

export const makeFetchTabDataRequest =
    curryEmptyPayloadActionForPath(fetchTabDataRequest)
export const makeFetchTabDataSuccess =
    curryEmptyPayloadActionForPath(fetchTabDataSuccess)
export const makeFetchTabDataFailure =
    curryPayloadActionForPath(fetchTabDataFailure)
