import { memo, ReactElement } from 'react'

import isEqual from 'lodash/isEqual'
import pick from 'lodash/pick'
import { useTranslation } from 'react-i18next'

import { useResource } from 'components/EditableFields/localHooks'
import { CAMPAIGN_STATES, PAUSED, ENABLED } from 'const/resourceStates'
import { updateCampaign } from 'services/cerebroApi/orgScope/resourceApi'
import { CampaignGroupBy } from 'types'

import { StateField } from '../../SharedFields/StateField'

type CampaignRecord = CampaignGroupBy

interface Props<RecordType> {
    dataIndex: string[]
    readOnly: boolean
    record: RecordType
}

function CampaignStateField<RecordType extends CampaignRecord>({
    dataIndex = ['campaign', 'state'],
    readOnly = false,
    record,
}: Props<RecordType>): ReactElement {
    const { t } = useTranslation(['campaigns'])
    const { fieldName, getFromResource, resourceId } = useResource(
        record,
        dataIndex,
        'campaign'
    )

    const AVAILABLE_OPTIONS = pick(CAMPAIGN_STATES, [PAUSED, ENABLED])

    const getIsDisabled = (): boolean => {
        const userCanManageCampaign = getFromResource(['user_can_manage'], true)
        return !userCanManageCampaign
    }

    return (
        <StateField
            disabled={getIsDisabled()}
            fieldName={fieldName}
            fieldPath={dataIndex}
            formatFieldValue={(value) => {
                const state = value[fieldName]
                return CAMPAIGN_STATES[state]
            }}
            options={AVAILABLE_OPTIONS}
            placeholder={t('campaigns:campaignState', 'Campaign State')}
            readOnly={readOnly}
            record={record}
            updateRequestApi={updateCampaign}
            updateRequestData={resourceId}
            updateRequestSuccessMesg={t(
                'campaigns:successfulRequestMsg.updateState',
                'Successfully updated campaign state'
            )}
        />
    )
}

export default memo(CampaignStateField, isEqual) as typeof CampaignStateField
