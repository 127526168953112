import { all, takeLatest } from 'redux-saga/effects'

import { addLabelsRequest, removeLabelRequest } from 'actions/ui/shared/label'

import { addLabelsWorker, removeLabelWorker } from './workers'

export default function* labelWatcher() {
    yield all([
        takeLatest(addLabelsRequest.toString(), addLabelsWorker),
        takeLatest(removeLabelRequest.toString(), removeLabelWorker),
    ])
}
