import {
    acos,
    ad_revenue_14_days__sum,
    ad_revenue_30_days__sum,
    ad_revenue_3_days__sum,
    ad_spend__sum,
    advertised_sku_sales_14_days__sum,
    advertised_sku_sales_30_days__sum,
    advertised_sku_sales_3_days__sum,
    advertised_sku_units_14_days__sum,
    advertised_sku_units_30_days__sum,
    advertised_sku_units_3_days__sum,
    attributed_orders_14_days__sum,
    attributed_orders_30_days__sum,
    attributed_orders_3_days__sum,
    brand_view_revenue_14_days__sum,
    brand_view_revenue_30_days__sum,
    brand_view_revenue_3_days__sum,
    cpc,
    cpm,
    ctr,
    ntb_orders_14_days__sum,
    ntb_orders_30_days__sum,
    ntb_orders_3_days__sum,
    ntb_revenue_14_days__sum,
    ntb_revenue_30_days__sum,
    ntb_revenue_3_days__sum,
    ntb_units_14_days__sum,
    ntb_units_30_days__sum,
    ntb_units_3_days__sum,
    num_ads_clicks__sum,
    num_ads_shown__sum,
    other_sku_sales_14_days__sum,
    other_sku_sales_30_days__sum,
    other_sku_sales_3_days__sum,
    other_sku_units_14_days__sum,
    other_sku_units_30_days__sum,
    other_sku_units_3_days__sum,
    roas,
    roasp,
    spc,
    spm,
    units_sold_14_days__sum,
    units_sold_30_days__sum,
    units_sold_3_days__sum,
    view_revenue_14_days__sum,
    view_revenue_30_days__sum,
    view_revenue_3_days__sum,
} from 'configuration/fieldCreators/metrics/walmartAdvertising'
import {
    adGroupId,
    advertiserIntegrationType,
    advertiserType,
    itemImage,
    platform,
} from 'configuration/fieldCreators/walmartAdvertising'
import { Field } from 'types'

import { makeMapping } from './helpers'

const WM_ADVERTISING_METRICS_COLUMNS = [
    ad_spend__sum({ isVisible: true }),
    num_ads_shown__sum({ isVisible: true }),
    num_ads_clicks__sum({ isVisible: true }),
    ad_revenue_3_days__sum({ isVisible: true }),
    ad_revenue_14_days__sum({ isVisible: true }),
    ad_revenue_30_days__sum({ isVisible: true }),
    ctr({ isVisible: true }),
    acos({ isVisible: true }),
    roas({ isVisible: true }),
    roasp({ isVisible: true }),
    spc({ isVisible: true }),
    cpc({ isVisible: true }),
    cpm({ isVisible: true }),
    spm({ isVisible: true }),
    // v2
    advertised_sku_sales_3_days__sum({ isVisible: true }),
    advertised_sku_sales_14_days__sum({ isVisible: true }),
    advertised_sku_sales_30_days__sum({ isVisible: true }),
    advertised_sku_units_3_days__sum({ isVisible: true }),
    advertised_sku_units_14_days__sum({ isVisible: true }),
    advertised_sku_units_30_days__sum({ isVisible: true }),
    attributed_orders_3_days__sum({ isVisible: true }),
    attributed_orders_14_days__sum({ isVisible: true }),
    attributed_orders_30_days__sum({ isVisible: true }),
    units_sold_3_days__sum({ isVisible: true }),
    units_sold_14_days__sum({ isVisible: true }),
    units_sold_30_days__sum({ isVisible: true }),
    ntb_orders_3_days__sum({ isVisible: true }),
    ntb_orders_14_days__sum({ isVisible: true }),
    ntb_orders_30_days__sum({ isVisible: true }),
    ntb_revenue_3_days__sum({ isVisible: true }),
    ntb_revenue_14_days__sum({ isVisible: true }),
    ntb_revenue_30_days__sum({ isVisible: true }),
    ntb_units_3_days__sum({ isVisible: true }),
    ntb_units_14_days__sum({ isVisible: true }),
    ntb_units_30_days__sum({ isVisible: true }),
    other_sku_sales_3_days__sum({ isVisible: true }),
    other_sku_sales_14_days__sum({ isVisible: true }),
    other_sku_sales_30_days__sum({ isVisible: true }),
    other_sku_units_3_days__sum({ isVisible: true }),
    other_sku_units_14_days__sum({ isVisible: true }),
    other_sku_units_30_days__sum({ isVisible: true }),
]

const WM_ADVERTISING_VIEW_METRICS_COLUMNS = [
    view_revenue_3_days__sum({ isVisible: true }),
    view_revenue_14_days__sum({ isVisible: true }),
    view_revenue_30_days__sum({ isVisible: true }),
    brand_view_revenue_3_days__sum({ isVisible: true }),
    brand_view_revenue_14_days__sum({ isVisible: true }),
    brand_view_revenue_30_days__sum({ isVisible: true }),
]

export const WM_ADVERTISING_METRICS_CONFIG = makeMapping([
    ...WM_ADVERTISING_METRICS_COLUMNS,
    ...WM_ADVERTISING_VIEW_METRICS_COLUMNS,
])

export const WM_ADVERTISING_METRICS_TABLE_COLUMNS = [
    ad_spend__sum({ isVisible: true }),
    num_ads_shown__sum({ isVisible: true }),
    num_ads_clicks__sum({ isVisible: true }),
    units_sold_14_days__sum({ isVisible: true }),
    ad_revenue_14_days__sum({ isVisible: true }),
    ctr({ isVisible: false }),
    acos({ isVisible: false }),
    roas({ isVisible: true }),
    roasp({ isVisible: false }),
    spc({ isVisible: false }),
    cpc({ isVisible: true }),
    cpm({ isVisible: false }),
    spm({ isVisible: false }),
    // v2
    advertised_sku_sales_3_days__sum({ isVisible: false }),
    advertised_sku_sales_14_days__sum({
        isVisible: false,
    }),
    advertised_sku_sales_30_days__sum({
        isVisible: false,
    }),
    advertised_sku_units_3_days__sum({ isVisible: false }),
    advertised_sku_units_14_days__sum({
        isVisible: false,
    }),
    advertised_sku_units_30_days__sum({
        isVisible: false,
    }),
    attributed_orders_3_days__sum({ isVisible: false }),
    attributed_orders_14_days__sum({ isVisible: false }),
    attributed_orders_30_days__sum({ isVisible: false }),
    units_sold_3_days__sum({ isVisible: false }),
    units_sold_30_days__sum({ isVisible: false }),
    ad_revenue_3_days__sum({ isVisible: false }),
    ad_revenue_30_days__sum({ isVisible: false }),
    ntb_orders_3_days__sum({ isVisible: false }),
    ntb_orders_14_days__sum({ isVisible: false }),
    ntb_orders_30_days__sum({ isVisible: false }),
    ntb_revenue_3_days__sum({ isVisible: false }),
    ntb_revenue_14_days__sum({ isVisible: false }),
    ntb_revenue_30_days__sum({ isVisible: false }),
    ntb_units_3_days__sum({ isVisible: false }),
    ntb_units_14_days__sum({ isVisible: false }),
    ntb_units_30_days__sum({ isVisible: false }),
    other_sku_sales_3_days__sum({ isVisible: false }),
    other_sku_sales_14_days__sum({ isVisible: false }),
    other_sku_sales_30_days__sum({ isVisible: false }),
    other_sku_units_3_days__sum({ isVisible: false }),
    other_sku_units_14_days__sum({ isVisible: false }),
    other_sku_units_30_days__sum({ isVisible: false }),
]

const SPECIAL_WM_ADVERTISING_FIRST_COMMON_GROUP_BY_COLUMNS: Field<any>[] = []

const SPECIAL_WM_ADVERSITING_ADVERTISER_GROUP_BY_COLUMNS = [
    advertiserType(),
    advertiserIntegrationType(),
]

const SPECIAL_WM_ADVERTISING_LAST_COMMON_GROUP_BY_COLUMNS = [
    ...SPECIAL_WM_ADVERSITING_ADVERTISER_GROUP_BY_COLUMNS,
    adGroupId(),
]

export const SPECIAL_WM_ADVERTISING_CAMPAIGN_GROUP_BY_COLUMNS = [
    ...SPECIAL_WM_ADVERTISING_FIRST_COMMON_GROUP_BY_COLUMNS,
    ...SPECIAL_WM_ADVERSITING_ADVERTISER_GROUP_BY_COLUMNS,
]

export const SPECIAL_WM_ADVERTISING_ITEM_GROUP_BY_COLUMNS = [
    ...SPECIAL_WM_ADVERTISING_FIRST_COMMON_GROUP_BY_COLUMNS,
    itemImage(),
    ...SPECIAL_WM_ADVERTISING_LAST_COMMON_GROUP_BY_COLUMNS,
]

export const SPECIAL_WM_ADVERTISING_KEYWORD_GROUP_BY_COLUMNS = [
    ...SPECIAL_WM_ADVERTISING_FIRST_COMMON_GROUP_BY_COLUMNS,
    ...SPECIAL_WM_ADVERTISING_LAST_COMMON_GROUP_BY_COLUMNS,
]

export const SPECIAL_WM_ADVERTISING_PLACEMENT_GROUP_BY_COLUMNS = [
    ...SPECIAL_WM_ADVERTISING_FIRST_COMMON_GROUP_BY_COLUMNS,
    adGroupId(),
    ...SPECIAL_WM_ADVERSITING_ADVERTISER_GROUP_BY_COLUMNS,
]

export const SPECIAL_WM_ADVERTISING_PLATFORM_GROUP_BY_COLUMNS = [
    ...SPECIAL_WM_ADVERTISING_FIRST_COMMON_GROUP_BY_COLUMNS,
    platform(),
    adGroupId(),
    ...SPECIAL_WM_ADVERSITING_ADVERTISER_GROUP_BY_COLUMNS,
]
