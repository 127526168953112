import { axiosInstanceOrganizations } from 'services/cerebroApi/orgScope/client'
import { CerebroResourceResponse, Report } from 'types'
import { ProductCogs } from 'types/resources/productCogs'

export const getProductSkuCogs = (
    sku: string
): Promise<CerebroResourceResponse<ProductCogs>> =>
    axiosInstanceOrganizations.get(`/api/product_skus/${sku}/cogs/`)

export const putProductSkuCogs = (
    sku: string,
    data: Pick<ProductCogs, 'cogs'>
): Promise<CerebroResourceResponse<ProductCogs>> =>
    axiosInstanceOrganizations.put(`/api/product_skus/${sku}/cogs/`, data)

export interface ProductSkuCogsTemplateFilter {
    asin__in?: string
    marketplace__in?: string
    sku__in?: string
    complex_label_filter?: string
}

export const getProductCogsExportAsyncExport = (
    params: ProductSkuCogsTemplateFilter
): Promise<CerebroResourceResponse<Report>> =>
    axiosInstanceOrganizations.get(`/api/product_cogs/export/async/`, {
        params,
    })
