import isEmpty from 'lodash/isEmpty'

import {
    ADVANCED_TEXT,
    BRANDS,
    CAMPAIGN_STATES,
    CAMPAIGN_TARGETING_TYPES,
    CAMPAIGNS,
    COUNTRIES,
    DATES,
    FACT_TYPES,
    KEYWORD_MATCH_TYPES,
    KEYWORD_STATES,
    KEYWORDS,
    LABELS,
    METRIC_FILTERS,
    PORTFOLIOS,
    REGIONS,
    SEARCH_TERM_NAME,
} from 'const/filters'
import { formatBooleanFilter } from 'helpers/filters'
import { isNonEmptyArray } from 'helpers/typeGuard'
import { formatDateFromFilter } from 'helpers/utils'
import { FiltersState, KeywordFactsParams } from 'types'

import { formatMetricFiltersForParams } from './metrics'

export const formatFilters = (filters: FiltersState): KeywordFactsParams => {
    let params: KeywordFactsParams = {}

    const dates = filters[DATES]
    const { start, end } = formatDateFromFilter(dates)
    params.report_date_min = start
    params.report_date_max = end

    if (!isEmpty(filters[SEARCH_TERM_NAME])) {
        params.query__icontains = filters[SEARCH_TERM_NAME]
    }

    const regions = filters[REGIONS]
    if (isNonEmptyArray(regions)) {
        params.profile__region__in = regions.map((item) => item.value).join()
    }

    const countries = filters[COUNTRIES]
    if (isNonEmptyArray(countries)) {
        params.profile__country_code__in = countries
            .map((item) => item.value)
            .join()
    }

    const brands = filters[BRANDS]
    if (isNonEmptyArray(brands)) {
        params.profile__in = brands.map((item) => item.value).join()
    }

    const portfolios = filters[PORTFOLIOS]
    if (isNonEmptyArray(portfolios)) {
        params.campaign__portfolio__id__in = portfolios
            .map((item) => item.value)
            .join()
    }

    const campaigns = filters[CAMPAIGNS]
    if (isNonEmptyArray(campaigns)) {
        params.campaign__in = campaigns.map((item) => item.value).join()
    }

    const campaignStates = filters[CAMPAIGN_STATES]
    if (isNonEmptyArray(campaignStates)) {
        params.campaign__state__in = campaignStates
            .map((item) => item.value)
            .join()
    }

    const campaignTargetingTypes = filters[CAMPAIGN_TARGETING_TYPES]
    if (isNonEmptyArray(campaignTargetingTypes)) {
        params.campaign__targeting_type__in = campaignTargetingTypes
            .map((item) => item.value)
            .join()
    }

    const keywords = filters[KEYWORDS]
    if (isNonEmptyArray(keywords)) {
        params.keyword__in = keywords.map((item) => item.value).join()
    }

    const keywordMatchTypes = filters[KEYWORD_MATCH_TYPES]
    if (isNonEmptyArray(keywordMatchTypes)) {
        params.keyword__match_type__in = keywordMatchTypes
            .map((item) => item.value)
            .join()
    }

    const keywordStates = filters[KEYWORD_STATES]
    if (isNonEmptyArray(keywordStates)) {
        params.keyword__state__in = keywordStates
            .map((item) => item.value)
            .join()
    }

    const metricFilters = filters[METRIC_FILTERS]
    if (isNonEmptyArray(metricFilters)) {
        params = { ...params, ...formatMetricFiltersForParams(metricFilters) }
    }

    const labels = filters[LABELS]
    if (isNonEmptyArray(labels)) {
        params.complex_label_filter = formatBooleanFilter(labels)
    }

    const advancedText = filters[ADVANCED_TEXT]
    if (isNonEmptyArray(advancedText)) {
        params.complex_text_filter = formatBooleanFilter(advancedText)
    }

    const factTypes = filters[FACT_TYPES]
    if (isNonEmptyArray(factTypes)) {
        params.campaign__campaign_type__in = factTypes
            .map((item) => item.value)
            .join()
    }

    return params
}
