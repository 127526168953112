import { useCallback } from 'react'

import { datadogRum } from '@datadog/browser-rum'
import { captureException } from '@sentry/browser'
import { AxiosError } from 'axios'
import i18n from 'i18next'
import get from 'lodash/get'
import isString from 'lodash/isString'
import noop from 'lodash/noop'
import { useDispatch } from 'react-redux'

import { signOutRequest } from 'actions/auth'
import {
    CerebroResponse,
    MakeCerebroApiRequestArgs,
    MakeCerebroApiRequestFunc,
} from 'types'
import displayMessage from 'utilities/message'

interface CerebroApiRequestOptions {
    throwOnError?: boolean
    treat429AsError?: boolean
}

const useCerebroApiRequest = (
    options?: CerebroApiRequestOptions
): MakeCerebroApiRequestFunc => {
    const { throwOnError = false, treat429AsError = false } = options ?? {}
    const dispatch = useDispatch()

    return useCallback(
        async <T, CR extends CerebroResponse<T> = CerebroResponse<T>>({
            request,
            onRequestSuccess = noop,
            onRequestFailure = noop,
            suppressErrorAlerts = false,
        }: MakeCerebroApiRequestArgs<CR>): Promise<CR | null> => {
            let showErrorMessage = !suppressErrorAlerts
            const requestFailedGenericMessage = i18n.t(
                'common:auth.requestFailed.generic',
                'Request failed'
            )
            let error: Error = new Error(requestFailedGenericMessage)

            const handleError = (): void => {
                captureException(error)
                datadogRum.addError(error)
                if (showErrorMessage) {
                    displayMessage.error(error.message)
                }
            }

            try {
                const response = await request
                if (!response) {
                    onRequestFailure({
                        status: 404,
                        data: 'Request returned falsy',
                    } as CR)
                    handleError()
                    return null
                }
                const { status, data } = response

                if (status >= 200 && status < 300) {
                    onRequestSuccess(response)
                    return response
                }

                if (status === 401) {
                    // 401 means the user is not authenticated
                    // Since we are handling this and it's expected
                    // we don't need to show an error message
                    showErrorMessage = false
                    const sessionExpiredMessage = i18n.t(
                        'common:auth.sessionExpired',
                        'Your session has expired. Please log in.'
                    )
                    displayMessage.warning({
                        content: sessionExpiredMessage,
                        key: 'sessionExpired',
                    })
                    // This is a bit of a nuclear option.
                    // It might be a good improvement for this to check if the user is actually signed in,
                    // but the sign out does a lot of cleanup so it may be safest to leave as is for now.
                    dispatch(signOutRequest())
                } else if (status === 403) {
                    const notAuthorizedMessage = i18n.t(
                        'common:auth.notAuthorized',
                        'Not authorized.'
                    )
                    error = new Error(notAuthorizedMessage)
                } else if (status === 404) {
                    // Do nothing if resource not found
                } else if (status === 429) {
                    if (treat429AsError) {
                        error = new AxiosError('Too many requests', '429')
                    }
                    // Default do nothing if api is throttled or resource not found
                } else {
                    // Cerebro error is typically the first element in an array
                    const cerebroError = get(data, ['0'])

                    // try to extract the message from the Cerebro error
                    let errorMessage
                    if (get(cerebroError, ['description'])) {
                        errorMessage = get(cerebroError, ['description'])
                    } else if (isString(cerebroError)) {
                        errorMessage = cerebroError
                    }

                    const requestFailedStatusMessage = i18n.t(
                        'common:auth.requestFailed.status',
                        'Request failed (status code {{ status }}).',
                        { status }
                    )
                    error = new Error(
                        errorMessage ?? requestFailedStatusMessage
                    )
                }

                onRequestFailure(response)
            } catch (err: any) {
                error = err
                onRequestFailure(err.response)
            }

            /**
             * Since cerebro errors can be handled in the try / catch
             * as either a status > 300 or an error we return early if
             * the response was successful meaning we will never get to
             * this point unless there was an error.
             */
            handleError()

            if (throwOnError) {
                throw error
            }

            return null
        },
        [dispatch, throwOnError, treat429AsError]
    )
}

export default useCerebroApiRequest
