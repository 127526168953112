import { memo, ReactElement } from 'react'

import isEqual from 'lodash/isEqual'
import pick from 'lodash/pick'

import { useResource } from 'components/EditableFields/localHooks'
import { PRODUCT_AD_STATES, PAUSED, ENABLED } from 'const/resourceStates'
import { updateProductAd } from 'services/cerebroApi/orgScope/resourceApi'
import { ProductAdFact } from 'types'

import { StateField } from '../../SharedFields/StateField'

type ProductAdRecord = ProductAdFact

interface Props<RecordType> {
    dataIndex: string[]
    readOnly: boolean
    record: RecordType
}

function ProductAdStateField<RecordType extends ProductAdRecord>({
    dataIndex = ['product_ad', 'state'],
    readOnly = false,
    record,
}: Props<RecordType>): ReactElement {
    const { fieldName, getFromResource, resourceId } = useResource(
        record,
        dataIndex,
        'product_ad'
    )

    const AVAILABLE_OPTIONS = pick(PRODUCT_AD_STATES, [PAUSED, ENABLED])

    const getIsDisabled = (): boolean => {
        const userCanManageProductAd = getFromResource(
            ['user_can_manage'],
            true
        )
        return !userCanManageProductAd
    }

    return (
        <StateField
            disabled={getIsDisabled()}
            fieldName={fieldName}
            fieldPath={dataIndex}
            formatFieldValue={(value) => {
                const state = value[fieldName]
                return PRODUCT_AD_STATES[state]
            }}
            options={AVAILABLE_OPTIONS}
            placeholder="Product Ad State"
            readOnly={readOnly}
            record={record}
            updateRequestApi={updateProductAd}
            updateRequestData={resourceId}
            updateRequestSuccessMesg="Successfully updated product ad state"
        />
    )
}

export default memo(ProductAdStateField, isEqual) as typeof ProductAdStateField
