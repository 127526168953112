import { EQUALS } from 'const/filters'
import { MetricFilter } from 'types'

interface MetricParams {
    // required b/c of dynamic assignment
    [key: string]: string | number | undefined
}

export const formatMetricFiltersForParams = (
    metricFilters: MetricFilter[]
): MetricParams => {
    const metricParams: MetricParams = {}

    metricFilters.forEach(({ metric, operator, value }) => {
        if (metric) {
            let key = metric
            if (operator && operator !== EQUALS) {
                key += `__${operator}`
            }
            metricParams[key] = value
        }
    })

    return metricParams
}
