import { INTERNAL_DOMAINS } from 'const/internalDomains'
import { isString } from 'helpers/typeGuard'

interface Domain {
    domain?: string
    isInternal?: boolean | null
}

export const isInternalDomain = (domain: string | null): boolean | null => {
    if (isString(domain)) {
        return INTERNAL_DOMAINS.some((internalDomain) =>
            internalDomain.test(domain)
        )
    }
    return null
}

export const extractDomainFromEmail = (
    email?: string | null | number
): Domain => {
    if (email && isString(email) && email.includes('@')) {
        const [, domain] = email.split('@')
        return { domain, isInternal: isInternalDomain(domain) }
    }
    return {}
}
