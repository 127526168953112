import {
    reportActions,
    reportCreatedDate,
    reportDateRange,
    reportName,
} from 'configuration/fieldCreators/reports'
import { Report, Field } from 'types'

export const reportsSummaryTableColumnsConfig: Field<Report>[] = [
    reportName(),
    reportDateRange(),
    reportCreatedDate(),
    reportActions(),
]
