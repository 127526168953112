import { handleActions } from 'redux-actions'

import { DATES } from 'const/filters'
import { DSP_ADVERTISER_PAGE } from 'const/pages'
import { LOCAL_FILTERS, FILTER_SETTINGS } from 'const/reducerKeys'
import {
    defaultResourcePage,
    defaultDspResourcePageTabs,
    defaultDspResourcePageWidgets,
} from 'reducers/ui/defaults'

const defaultState = {
    [LOCAL_FILTERS]: {},
    [FILTER_SETTINGS]: {
        anchored: [DATES],
        order: [],
        displayState: {},
    },
    ...defaultResourcePage,
    tabs: defaultDspResourcePageTabs,
    widgets: defaultDspResourcePageWidgets(DSP_ADVERTISER_PAGE),
}

export default handleActions({}, defaultState)
