import {
    date,
    dateDay,
    dateMonth,
    dateQuarter,
    dateWeek,
    dateYear,
    reportDateDay,
    reportDateDayHour,
    reportDateHour,
    reportDateMonth,
    reportDateMonthName,
    reportDateQuarter,
    reportDateQuarterNumber,
    reportDateWeek,
    reportDateWeekDay,
    reportDateYear,
} from 'configuration/fieldCreators/date'

export const REPORT_DATE_TIME_COLUMNS = [reportDateDayHour()]

export const REPORT_DATE_COLUMNS = [
    reportDateDay(),
    reportDateWeek(),
    reportDateMonth(),
    reportDateYear(),
    reportDateWeekDay(),
    reportDateMonthName(),
    reportDateQuarter(),
    reportDateQuarterNumber(),
]

/**
 * Report date columns for week and upper grains
 */
export const REPORT_DATE_WEEKLY_GRAIN_COLUMNS = [
    reportDateWeek(),
    reportDateMonth(),
    reportDateYear(),
    reportDateMonthName(),
    reportDateQuarter(),
    reportDateQuarterNumber(),
]

export const REPORT_DATE_AGGREGATE_COLUMNS = [
    reportDateDay(),
    reportDateWeek(),
    reportDateMonth(),
    reportDateYear(),
    reportDateQuarter(),
]

export const REPORT_DATE_TIME_AGGREGATE_COLUMNS = [
    reportDateHour(),
    reportDateDay(),
    reportDateWeek(),
    reportDateMonth(),
    reportDateYear(),
    reportDateQuarter(),
]

export const DATE_TIME_AGGREGATE_COLUMNS = [
    date(),
    dateDay(),
    dateWeek(),
    dateMonth(),
    dateYear(),
    dateQuarter(),
]
