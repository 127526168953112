import { SVGAttributes, ReactElement } from 'react'

type Props = SVGAttributes<SVGElement>

function SvgInfoIcon(props: Props): ReactElement {
    return (
        <svg
            viewBox="0 0 12 12"
            width="12"
            height="12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <rect width="12" height="12" rx="6" fill="currentColor" />
            <path
                d="M5.99996 3.96745C6.52916 3.96745 6.95756 3.53905 6.95756 3.00985C6.95756 2.48065 6.52916 2.05225 5.99996 2.05225C5.48336 2.05225 5.04236 2.48065 5.04236 3.00985C5.04236 3.53905 5.48336 3.96745 5.99996 3.96745ZM5.20616 9.94785H6.80636V4.86205H5.20616V9.94785Z"
                fill="white"
            />
        </svg>
    )
}

export default SvgInfoIcon
