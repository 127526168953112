import { SVGAttributes, ReactElement } from 'react'

type Props = SVGAttributes<SVGElement>

function PaintBrushIconSvg(props: Props): ReactElement {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M18.9151 5.90962L9.08458 0.23394C8.93011 0.144752 8.73267 0.194831 8.64584 0.345222L6.11039 4.73675C6.00543 4.91855 5.92219 5.1071 5.85816 5.29936L16.1417 11.2366C16.2761 11.0849 16.3977 10.9185 16.5027 10.7367L19.0381 6.34522C19.125 6.19483 19.0724 6.00043 18.9151 5.90962Z" />
            <path d="M6.98824 9.41239L8.17962 10.1002L5.51044 14.7234C5.05149 15.5183 5.33735 16.5458 6.14791 17.0137C6.95817 17.4815 7.9909 17.2154 8.44985 16.4205L11.119 11.7973L12.0171 12.3158C13.2161 13.008 14.6597 12.8906 15.696 12.134L5.30371 6.13403C5.1675 7.41026 5.78926 8.72015 6.98824 9.41239ZM7.4458 15.4445C7.35386 15.6037 7.14787 15.6569 6.98541 15.5631C6.82296 15.4693 6.76604 15.2643 6.85798 15.1051L7.19093 14.5284C7.28287 14.3692 7.48886 14.316 7.65132 14.4097C7.81377 14.5035 7.87069 14.7085 7.77875 14.8678L7.4458 15.4445Z" />
        </svg>
    )
}

export default PaintBrushIconSvg
