import { memo, ReactElement } from 'react'

import isEqual from 'lodash/isEqual'
import pick from 'lodash/pick'
import { useTranslation } from 'react-i18next'

import { KEYWORD_STATES, PAUSED, ENABLED } from 'const/resourceStates'
import { userHasWalmartAdvertisingWritePermissions } from 'helpers/featurePermissions'
import { formatWalmartResourceStateField } from 'helpers/walmart'
import { useUserHasPermissions } from 'hooks'
import { updateWalmartKeyword } from 'services/cerebroApi/orgScope/walmartApi'
import { WalmartKeywordGroupBy } from 'types'

import { StateField } from '../../SharedFields/StateField'

interface Props {
    dataIndex: string[]
    record: WalmartKeywordGroupBy
}

function WalmartKeywordStateField({
    dataIndex = ['keyword', 'state'],
    record,
}: Props): ReactElement {
    const { t } = useTranslation('table')
    const hasWalmartPermissions = useUserHasPermissions(
        userHasWalmartAdvertisingWritePermissions
    )

    const resourceId = record.keyword_id

    const AVAILABLE_OPTIONS = pick(KEYWORD_STATES, [PAUSED, ENABLED])

    return (
        <StateField
            disabled={
                !record.keyword.user_can_manage || record.advertiser?.read_only
            }
            fieldName="state"
            fieldPath={dataIndex}
            formatFieldValue={() =>
                formatWalmartResourceStateField(record, 'keyword')
            }
            options={AVAILABLE_OPTIONS}
            placeholder="Keyword State"
            readOnly={!record.keyword.user_can_manage || !hasWalmartPermissions}
            record={record}
            updateRequestApi={updateWalmartKeyword}
            updateRequestData={resourceId}
            updateRequestSuccessMesg={t(
                'table:fields.walmartKeyword.update.stateUpdateSuccessMsg',
                'Successfully updated keyword state'
            )}
        />
    )
}

export default memo(
    WalmartKeywordStateField,
    isEqual
) as typeof WalmartKeywordStateField
