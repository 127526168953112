import { handleActions } from 'redux-actions'

import { campaignChangelogTableColumnsConfig } from 'configuration/tables'
import { DATES, AUTOMATIONS, RESOURCE_TYPES } from 'const/filters'
import { FILTER_SETTINGS, LOCAL_FILTERS } from 'const/reducerKeys'
import { serializeColumns } from 'helpers/tables'
import { defaultTab, getDefaultTable } from 'reducers/ui/defaults'

const initialState = {
    ...defaultTab,
    [LOCAL_FILTERS]: {
        [AUTOMATIONS]: [],
        [RESOURCE_TYPES]: [],
    },
    [FILTER_SETTINGS]: {
        anchored: [DATES],
        order: [AUTOMATIONS, RESOURCE_TYPES],
        displayState: {
            [AUTOMATIONS]: true,
            [RESOURCE_TYPES]: true,
        },
    },
    table: getDefaultTable(
        serializeColumns(campaignChangelogTableColumnsConfig),
        {
            sorter: {
                field: ['date'],
                order: 'descend',
            },
        }
    ),
}

export default handleActions({}, initialState)
