import { ReactElement } from 'react'

import { TFunction } from 'i18next'
import { Translation } from 'react-i18next'

import { Flag } from 'components/Flag'
import { BrandIcon, LocationPinIcon } from 'components/Icons'
import { AdAccountLink } from 'components/Links'
import { TextDividerVertical } from 'components/TextDividerVertical'
import { getCountryNames } from 'helpers/countries'
import { getRegionNames } from 'helpers/regions'
import { Brand, CountryCode } from 'types'

import { DetailWrapper, DetailIcon, DetailText } from './shared'

export function adAccountRenderer(adAccount: Brand): ReactElement {
    const text = <AdAccountLink adAccount={adAccount} />
    return (
        <DetailWrapper
            leftContent={
                <DetailIcon
                    icon={BrandIcon}
                    tooltipTitle={
                        <Translation>
                            {(t) =>
                                t(
                                    'common:advertisingAccount',
                                    'Advertising Account'
                                )
                            }
                        </Translation>
                    }
                />
            }
            rightContent={<DetailText text={text} />}
        />
    )
}

export function adAccountRegionCountryRenderer(
    adAccount: Brand,
    t: TFunction
): ReactElement {
    const regions = getRegionNames(t)
    const expandedRegion =
        adAccount.region in regions
            ? regions[adAccount.region]
            : adAccount.region

    const tooltip = (
        <>
            {t('common:region', 'Region')}
            <TextDividerVertical />
            {t('common:country', 'Country')}
        </>
    )

    const text = (
        <span className="d-flex align-items-center">
            {expandedRegion}
            <TextDividerVertical />
            <span className="fg-flag-container">
                <Flag country={adAccount.country_code} />
                <div>{adAccount.country_code}</div>
            </span>
        </span>
    )

    return (
        <DetailWrapper
            leftContent={
                <DetailIcon icon={LocationPinIcon} tooltipTitle={tooltip} />
            }
            rightContent={<DetailText text={text} />}
        />
    )
}

export function adAccountNameMarketplaceRenderer(
    name: string,
    countryCode: CountryCode,
    t: TFunction
): ReactElement {
    const tooltip = (
        <>
            {t('common:advertisingAccount', 'Advertising Account')}
            <TextDividerVertical />
            {t('common:marketplace', 'Marketplace')}
        </>
    )

    const countries = getCountryNames(t)

    const text = (
        <span className="d-flex align-items-center">
            {name}
            <TextDividerVertical />
            <span className="fg-flag-container">
                <Flag country={countryCode} />
                <div>{countries[countryCode]}</div>
            </span>
        </span>
    )

    return (
        <DetailWrapper
            leftContent={<DetailIcon icon={BrandIcon} tooltipTitle={tooltip} />}
            rightContent={<DetailText text={text} />}
        />
    )
}
