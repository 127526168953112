import { handleActions } from 'redux-actions'

import { campaignProductAdsTableColumnsConfig } from 'configuration/tables'
import { DATES, PRODUCT_TITLE } from 'const/filters'
import { LOCAL_FILTERS, FILTER_SETTINGS } from 'const/reducerKeys'
import { serializeColumns } from 'helpers/tables'
import { defaultTab, getDefaultTable } from 'reducers/ui/defaults'

const initialState = {
    ...defaultTab,
    [LOCAL_FILTERS]: {
        [PRODUCT_TITLE]: null,
    },
    [FILTER_SETTINGS]: {
        anchored: [PRODUCT_TITLE, DATES],
    },
    table: getDefaultTable(
        serializeColumns(campaignProductAdsTableColumnsConfig)
    ),
}

export default handleActions({}, initialState)
