import { ReactElement, ReactNode } from 'react'

import {
    WalmartAdItemStateField,
    WalmartAdItemBidField,
} from 'components/EditableFields'
import { CellRenderProps, WalmartAdItemGroupBy } from 'types'

import { WalmartItemTitleCell } from '../WalmartItemTitleCell'

type TitleRenderProps<RecordType> = {
    cellRenderProps: CellRenderProps<RecordType>
    dataIndex?: string[]
}

type StateRenderProps = {
    record: WalmartAdItemGroupBy
    dataIndex: string[]
}

export function wmAdItemBidRenderer<RecordType extends WalmartAdItemGroupBy>({
    record,
}: CellRenderProps<RecordType>): ReactNode {
    return (
        <WalmartAdItemBidField
            adItem={record.ad_item}
            campaign={record.campaign}
        />
    )
}

export function wmAdItemStateRenderer({
    record,
    dataIndex,
}: StateRenderProps): ReactNode {
    return <WalmartAdItemStateField dataIndex={dataIndex} record={record} />
}

export function wmItemTitleRenderer<RecordType extends WalmartAdItemGroupBy>({
    cellRenderProps: { value, record },
}: TitleRenderProps<RecordType>): ReactElement {
    return (
        <WalmartItemTitleCell
            value={value}
            productImg={record.item_metadata?.image_url}
        />
    )
}
