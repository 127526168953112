import get from 'lodash/get'

import { GROUPING_COLORS } from 'configuration/charts'
import {
    asin_count__sum,
    brand_count__sum,
    category_count__sum,
    category_rank__avg,
    price__avg,
    price__last_value,
    price__max,
    price__min,
    report_date__max,
    report_date__min,
    revenue_1p__sum,
    revenue_3p__sum,
    revenue__sum,
    review_count__avg,
    review_count__max,
    review_count__min,
    sales_price_1p__avg,
    sales_price_3p__avg,
    sales_price__avg,
    sub_category_count__sum,
    subcategory_rank__avg,
    units_1p__sum,
    units_3p__sum,
    units__sum,
    variant_count__max,
} from 'configuration/fieldCreators/metrics/asinSalesEstimateFacts'
import { productId, segmentId } from 'configuration/fieldCreators/segments'
import {
    ADVANCED_PRODUCT_TITLE,
    COUNTRIES,
    DATES,
    METRIC_FILTERS,
    SEGMENTS,
    SOV_ASINS,
    SOV_METADATA_BRANDS,
} from 'const/filters'
import { DATE_GROUP_BY_FORMATTERS } from 'helpers/charts'
import { propToColor } from 'helpers/colors'
import { formatProductName } from 'helpers/formatting'
import { ChartFormatters, Field, FieldMapping, Segment } from 'types'

import { makeMapping } from './helpers'

export const SPECIAL_SEGMENT_FACTS_GROUP_BY_COLUMNS: Field<Segment>[] = [
    productId({
        dataIndex: ['product', 'id'],
    }),
    segmentId({ isVisible: true, dataIndex: ['segment', 'id'] }),
]

export const SEGMENT_FACTS_GROUP_BY_CONFIG: ChartFormatters = {
    vendor_seller: {
        color: (record) =>
            get(GROUPING_COLORS, ['vendor_seller', record.vendor_seller]),
    },
    product_id: {
        displayName: (record) => formatProductName(record, 'product'),
        color: (record) => propToColor(record, 'product_id'),
    },
    ...DATE_GROUP_BY_FORMATTERS,
    segment_id: {
        displayName: (record) => get(record, ['segment', 'name'], 'No Segment'),
    },
}

const SEGMENT_FACTS_METRICS_COLUMNS: Field<Segment>[] = [
    revenue__sum({ isVisible: true }),
    revenue_1p__sum({ isVisible: true }),
    revenue_3p__sum({ isVisible: true }),
    units__sum({ isVisible: true }),
    units_1p__sum({ isVisible: true }),
    units_3p__sum({ isVisible: true }),
    price__avg({ isVisible: true }),
    price__max({ isVisible: true }),
    price__min({ isVisible: true }),
    sales_price__avg({ isVisible: true }),
    sales_price_1p__avg({ isVisible: true }),
    sales_price_3p__avg({ isVisible: true }),
    review_count__avg({ isVisible: true }),
    review_count__max({ isVisible: true }),
    review_count__min({ isVisible: true }),
    category_rank__avg({ isVisible: true }),
    subcategory_rank__avg({ isVisible: true }),
    asin_count__sum({ isVisible: true }),
    brand_count__sum({ isVisible: true }),
    category_count__sum({ isVisible: true }),
    sub_category_count__sum({ isVisible: true }),
    variant_count__max({ excludeLastPeriod: true }),
    price__last_value({ isVisible: true }),
    report_date__min({ isVisible: true }),
    report_date__max({ isVisible: true }),
] as Field<Segment>[]

export const SEGMENT_FACTS_METRICS_CONFIG: FieldMapping = makeMapping(
    SEGMENT_FACTS_METRICS_COLUMNS
)

export const SEGMENT_FACTS_FILTER = [
    DATES,
    COUNTRIES,
    SEGMENTS,
    ADVANCED_PRODUCT_TITLE,
    SOV_METADATA_BRANDS,
    SOV_ASINS,
    METRIC_FILTERS,
]
