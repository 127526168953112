import { createAction } from 'redux-actions'

const NAMESPACE = 'ORGANIZATION_GROUP_PAGE'

// data for form inputs
export const fetchOrganizationGroupBrandsSuccess = createAction(
    `${NAMESPACE}/FETCH_BRAND_SUCCESS`
)

export type FetchOrganizationGroupBrandsOptionsAction = ReturnType<
    typeof fetchOrganizationGroupBrandsSuccess
>

export const fetchOrganizationGroupIntegrationsSuccess = createAction(
    `${NAMESPACE}/FETCH_INTEGRATIONS_SUCCESS`
)

export type FetchOrganizationGroupIntegrationOptionsAction = ReturnType<
    typeof fetchOrganizationGroupIntegrationsSuccess
>

export const fetchOrganizationGroupDspAdvertiserOptionsSuccess = createAction(
    `${NAMESPACE}/FETCH_DSP_ADVERTISER_OPTIONS_SUCCESS`
)

export type FetchOrganizationGroupDspAdvertisersOptionsAction = ReturnType<
    typeof fetchOrganizationGroupDspAdvertiserOptionsSuccess
>

export const fetchOrganizationGroupPendingInvitationsRequest = createAction(
    `${NAMESPACE}/FETCH_PENDING_INVITATIONS_REQUEST`
)
export const fetchOrganizationGroupPendingInvitationsSuccess = createAction(
    `${NAMESPACE}/FETCH_PENDING_INVITATIONS_SUCCESS`
)

export type FetchOrganizationGroupPendingInvitationAction = ReturnType<
    typeof fetchOrganizationGroupPendingInvitationsSuccess
>
