import { memo, ReactElement } from 'react'

import isEqual from 'lodash/isEqual'

import { useResource } from 'components/EditableFields/localHooks'
import { ARCHIVED } from 'const/resourceStates'
import { updateAdGroup } from 'services/cerebroApi/orgScope/resourceApi'
import { AdGroupGroupBy } from 'types'

import { DeleteField } from '../../SharedFields/DeleteField'

interface Props<RecordType> {
    dataIndex: string[]
    readOnly: boolean
    record: RecordType
    reloadData: () => void
}

function ArchiveAdGroupField<RecordType extends AdGroupGroupBy>({
    dataIndex = ['ad_group'],
    readOnly = false,
    record,
    reloadData,
}: Props<RecordType>): ReactElement {
    const { getFromResource, resourceId } = useResource<RecordType>(
        record,
        dataIndex,
        'ad_group'
    )

    const getIsDisabled = (): boolean => {
        const userCanManageAdGroup = getFromResource(['user_can_manage'], true)
        return !userCanManageAdGroup || readOnly
    }

    return (
        <DeleteField
            disabled={getIsDisabled()}
            okText="Archive"
            title="Are you sure you want to archive this Ad Group?"
            updateRequestApi={updateAdGroup}
            updateRequestCallback={reloadData}
            updateRequestData={resourceId}
            updateRequestParams={{ state: ARCHIVED }}
            updateRequestSuccessMesg="Ad Group successfully archived"
        />
    )
}

export default memo(ArchiveAdGroupField, isEqual) as typeof ArchiveAdGroupField
