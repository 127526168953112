import { ReactElement } from 'react'

import { Alert, Button, Checkbox, Divider, Modal } from 'antd'
import { Field, Formik, FieldProps } from 'formik'
import map from 'lodash/map'

import { CreateOrganizationModal } from 'components/CreateResourceModal'
import { PlusIcon } from 'components/Icons'
import { OrganizationGroupSearchSelect } from 'components/SearchSelect'
import { useModal } from 'hooks'
import {
    OrganizationGroup,
    Organization,
    AutomationCapabilitiesMap,
} from 'types'

import { ImpersonationButtonToggle } from './ImpersonationButtonToggle'

interface Props {
    // modal callback
    modalVisible: boolean
    closeModal: () => void

    // select org group values/options
    currentOrganizationId?: string
    currentOrganizationGroupId?: string
    currentOrganization?: Organization
    currentOrganizationGroup?: OrganizationGroup

    // checkbox hidden automations values/options
    automationCapabilities: AutomationCapabilitiesMap
    hiddenAutomationCapabilities: string[]

    // action callbacks
    handleChangeOrganizationGroup: ({
        organizationId,
        organizationGroupId,
    }: {
        organizationId: string
        organizationGroupId: string
    }) => void
    handleChangeHiddenAutomationCapabilities: (
        hiddenAutomations: string[]
    ) => void
}

const SteveModeModal = ({
    modalVisible,
    closeModal,
    currentOrganizationId = '',
    currentOrganizationGroupId = '',
    currentOrganization,
    currentOrganizationGroup,
    automationCapabilities,
    hiddenAutomationCapabilities,
    handleChangeOrganizationGroup,
    handleChangeHiddenAutomationCapabilities,
}: Props): ReactElement => {
    const {
        isModalVisible: isCreateOrgModalVisible,
        toggleModalVisible: toggleCreateOrgModal,
    } = useModal()

    const serializeOrgGroup = (orgId: string, orgGroupId: string): string =>
        JSON.stringify([orgId, orgGroupId])

    const deserializeOrgGroup = (serializedValue: string): string[] =>
        JSON.parse(serializedValue)

    return (
        <>
            <Formik
                initialValues={{
                    orgGroup: serializeOrgGroup(
                        currentOrganizationId,
                        currentOrganizationGroupId
                    ),
                    hiddenAutomations: hiddenAutomationCapabilities,
                }}
                onSubmit={({ orgGroup, hiddenAutomations }) => {
                    const [organizationId, organizationGroupId] =
                        deserializeOrgGroup(orgGroup)
                    handleChangeOrganizationGroup({
                        organizationId,
                        organizationGroupId,
                    })
                    handleChangeHiddenAutomationCapabilities(hiddenAutomations)
                    closeModal()
                }}
            >
                {({ setFieldValue, handleSubmit }) => (
                    <Modal
                        title="Impersonation Mode Settings"
                        open={modalVisible}
                        onCancel={closeModal}
                        onOk={() => handleSubmit()}
                    >
                        <>
                            <Alert
                                message={
                                    <>
                                        While operating in Impersonation Mode,
                                        you are accessing real customer data in
                                        production. Changes made to{' '}
                                        <b>anything</b> in Impersonation Mode
                                        will be <b>live in production</b>. Use
                                        extreme caution when making changes.{' '}
                                        <ImpersonationButtonToggle />.
                                    </>
                                }
                                type="error"
                                className="mb-3"
                            />
                            <h3>Organization Group to Assume</h3>
                            <Field name="orgGroup">
                                {({
                                    field,
                                    form: {
                                        values: { orgGroup },
                                    },
                                }: FieldProps) => (
                                    <OrganizationGroupSearchSelect
                                        className="mt-2"
                                        onChange={(id) => {
                                            setFieldValue(field.name, id)
                                        }}
                                        value={orgGroup}
                                        prefetchRecordLimit={100} // prefetch more records for steve-mode modal
                                        defaultOptions={
                                            currentOrganizationGroup &&
                                            currentOrganization
                                                ? [
                                                      {
                                                          ...currentOrganizationGroup,
                                                          // merge in `organization`
                                                          // because it is not persisted
                                                          // with organization groups in redux
                                                          organization:
                                                              currentOrganization,
                                                      },
                                                  ]
                                                : []
                                        }
                                    />
                                )}
                            </Field>
                            <Button
                                type="link"
                                icon={<PlusIcon />}
                                className="pl-0 mt-3"
                                onClick={() => {
                                    closeModal()
                                    toggleCreateOrgModal()
                                }}
                            >
                                Create New Organization
                            </Button>
                            <Divider className="mt-3" />
                            <h3>Hidden Automation Capabilities</h3>
                            <Field name="hiddenAutomations">
                                {({
                                    field,
                                    form: {
                                        values: { hiddenAutomations },
                                    },
                                }: FieldProps) => (
                                    <Checkbox.Group
                                        options={map(
                                            automationCapabilities,
                                            (capability, capabilityId) => ({
                                                label: capability.name,
                                                value: capabilityId,
                                            })
                                        )}
                                        defaultValue={hiddenAutomations}
                                        onChange={(selectedOptions) => {
                                            setFieldValue(
                                                field.name,
                                                selectedOptions
                                            )
                                        }}
                                    />
                                )}
                            </Field>
                        </>
                    </Modal>
                )}
            </Formik>
            <CreateOrganizationModal
                visible={isCreateOrgModalVisible}
                closeModal={toggleCreateOrgModal}
                isSteveMode
            />
        </>
    )
}

export default SteveModeModal
