import { ReactElement } from 'react'

import { Button } from 'antd'

import { ConditionalWrapper } from 'components/ConditionalWrapper'
import { ToolTip } from 'components/ToolTip'

import { type IconButtonProps } from './localTypes'

const IconButton = ({
    tooltipPlacement = 'top',
    tooltipTitle,
    ...btnProps
}: IconButtonProps): ReactElement => (
    <ConditionalWrapper
        condition={Boolean(tooltipTitle)}
        wrapper={(children) => (
            <ToolTip
                title={tooltipTitle}
                arrow={{ pointAtCenter: true }}
                placement={tooltipPlacement}
            >
                {children}
            </ToolTip>
        )}
    >
        <Button shape="circle" {...btnProps} />
    </ConditionalWrapper>
)

export default IconButton
