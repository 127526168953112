import { Link, generatePath } from 'react-router-dom'

import {
    targetActiveBidRenderer,
    targetBidRenderer,
} from 'components/FieldRenderers/Renderers/target'
import { adGroupName } from 'configuration/fieldCreators/adGroup'
import {
    brandCountryCode,
    brandCurrencyCode,
    brandId,
    brandRegion,
    brandTimezone,
} from 'configuration/fieldCreators/brand'
import {
    campaignBudget,
    campaignEndDate,
    campaignName,
    campaignStartDate,
    campaignState,
    campaignTargetingType,
    campaignType,
} from 'configuration/fieldCreators/campaign'
import {
    archiveTarget,
    expressionDescription,
    targetActiveBid,
    targetAmazonResourceId,
    targetBid,
    targetExpressions,
    targetState,
    targetSuggestedBid,
} from 'configuration/fieldCreators/target'
import { CAMPAIGN_PAGE } from 'const/pages'
import { getPath } from 'helpers/pages'
import {
    ProfileGroupBy,
    AdGroupGroupBy,
    CampaignGroupBy,
    Field,
    NonKeywordTargetFact,
} from 'types'

import { TARGET_SUPPORTED_AMS_METRICS_COLUMNS_PAGES } from '../dataSources/ams'

export type TargetFactRecord = NonKeywordTargetFact<
    ProfileGroupBy & AdGroupGroupBy & CampaignGroupBy
>

export const campaignTargetsTableColumnsConfig: Field<TargetFactRecord>[] = [
    targetExpressions({ fixed: 'left' }),
    targetAmazonResourceId(),
    targetState(),
    targetSuggestedBid(),
    targetBid({
        renderOptions: {
            render: (cellRenderProps) =>
                targetBidRenderer({
                    cellRenderProps,
                    dataIndex: ['target', 'base_bid'],
                }),
        },
    }),
    targetActiveBid({
        renderOptions: {
            render: (cellRenderProps) =>
                targetActiveBidRenderer({
                    cellRenderProps,
                    dataIndex: ['target', 'active_bid'],
                }),
        },
    }),
    adGroupName({
        sorter: false,
    }),
    ...(TARGET_SUPPORTED_AMS_METRICS_COLUMNS_PAGES as Field<TargetFactRecord>[]),
    archiveTarget(),
]

export const adGroupTargetsTableColumnsConfig: Field<TargetFactRecord>[] = [
    targetExpressions({ fixed: 'left' }),
    targetAmazonResourceId(),
    targetState(),
    targetSuggestedBid(),
    targetBid({
        renderOptions: {
            render: (cellRenderProps) =>
                targetBidRenderer({
                    cellRenderProps,
                    dataIndex: ['target', 'base_bid'],
                }),
        },
    }),
    targetActiveBid({
        renderOptions: {
            render: (cellRenderProps) =>
                targetActiveBidRenderer({
                    cellRenderProps,
                    dataIndex: ['target', 'active_bid'],
                }),
        },
    }),
    ...(TARGET_SUPPORTED_AMS_METRICS_COLUMNS_PAGES as Field<TargetFactRecord>[]),
    archiveTarget(),
]

export const targetsSummaryTableColumnsConfig: Field<TargetFactRecord>[] = [
    expressionDescription({ fixed: 'left' }),
    targetAmazonResourceId(),
    targetState(),
    targetSuggestedBid(),
    targetBid({
        renderOptions: {
            render: (cellRenderProps) =>
                targetBidRenderer({
                    cellRenderProps,
                    dataIndex: ['target', 'base_bid'],
                }),
        },
    }),
    targetActiveBid({
        renderOptions: {
            render: (cellRenderProps) =>
                targetActiveBidRenderer({
                    cellRenderProps,
                    dataIndex: ['target', 'active_bid'],
                }),
        },
    }),
    adGroupName({
        sorter: false,
    }),
    brandId(),
    brandCountryCode(),
    brandCurrencyCode({ isVisible: false }),
    brandRegion(),
    brandTimezone({ isVisible: false }),
    campaignName({
        dataIndex: ['campaign', 'name'],
        renderOptions: {
            render: ({ value, record }) => (
                <Link
                    to={generatePath(getPath(CAMPAIGN_PAGE), {
                        campaignId: record.campaign.id,
                    })}
                >
                    {value}
                </Link>
            ),
        },
    }),
    campaignType({
        dataIndex: ['campaign', 'campaign_type'],
    }),
    campaignTargetingType({
        dataIndex: ['campaign', 'targeting_type'],
    }),
    campaignState({
        dataIndex: ['campaign', 'state'],
    }),
    campaignBudget({
        isVisible: false,
        dataIndex: ['campaign', 'budget'],
    }),
    campaignStartDate({
        isVisible: false,
        dataIndex: ['campaign', 'start_date'],
    }),
    campaignEndDate({
        isVisible: false,
        dataIndex: ['campaign', 'end_date'],
    }),
    ...(TARGET_SUPPORTED_AMS_METRICS_COLUMNS_PAGES as Field<TargetFactRecord>[]),
    archiveTarget(),
]
