import {
    createContext,
    ReactElement,
    ReactNode,
    useContext,
    useMemo,
} from 'react'

interface UserOverrideContextProps {
    userOverride?: {
        userId?: string
        organizationId?: string
        organizationGroupId?: string
    }
    children: ReactNode
}

interface UserOverrideContext {
    userOverride?: {
        userId?: string
        organizationId?: string
        organizationGroupId?: string
    }
}

const UserOverrideContextInstance = createContext<UserOverrideContext>({
    userOverride: undefined,
})

export const useUserOverrideContext = (): UserOverrideContext =>
    useContext(UserOverrideContextInstance)

export function UserOverrideContextProvider(
    props: UserOverrideContextProps
): ReactElement {
    const { children, userOverride } = props
    const userId = userOverride?.userId
    const organizationId = userOverride?.organizationId
    const organizationGroupId = userOverride?.organizationGroupId

    const userOverrideContext = useMemo(() => {
        const hasOverride = userId || organizationId || organizationGroupId
        return {
            userOverride: hasOverride
                ? {
                      userId,
                      organizationId,
                      organizationGroupId,
                  }
                : undefined,
        }
    }, [organizationGroupId, organizationId, userId])

    return (
        <UserOverrideContextInstance.Provider value={userOverrideContext}>
            {children}
        </UserOverrideContextInstance.Provider>
    )
}
