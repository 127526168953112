import { all, call, select } from 'redux-saga/effects'

import {
    makeFetchResourceSuccess,
    makeUpdateResourceSuccess,
} from 'actions/ui/shared/resource'
import { AD_ACCOUNT_PAGE } from 'const/pages'
import { cerebroApiSaga } from 'sagas/common'
import uiSagaRegistry from 'sagas/ui/registry'
import { selectResourceParamsOfPage } from 'selectors/ui'
import {
    getAdAccount,
    updateAdAccount,
} from 'services/cerebroApi/orgScope/resourceApi'

function* fetchBrandSaga() {
    const { adAccountId } = yield select(
        selectResourceParamsOfPage,
        AD_ACCOUNT_PAGE
    )

    yield call(
        cerebroApiSaga,
        makeFetchResourceSuccess(AD_ACCOUNT_PAGE),
        getAdAccount,
        adAccountId
    )
}

function* updateBrandSaga({ adAccountId }, data) {
    yield call(
        cerebroApiSaga,
        makeUpdateResourceSuccess(AD_ACCOUNT_PAGE),
        updateAdAccount,
        adAccountId,
        data
    )
}

/**
 * Mounts the Brand Page and fetches data
 */
function* mountPageSaga() {
    yield all([
        // Load brand
        call(fetchBrandSaga),
    ])
}

uiSagaRegistry.registerSagas([AD_ACCOUNT_PAGE], {
    mountPageSaga,
    updateResourceSaga: updateBrandSaga,
    addLabelsSaga: updateBrandSaga,
    removeLabelSaga: updateBrandSaga,
})
