import produce from 'immer'
import cloneDeep from 'lodash/cloneDeep'
import set from 'lodash/fp/set'
import { Action, ReducerMap, handleActions } from 'redux-actions'

import { updateCache, invalidateCache, CacheEntry } from 'actions/cache'

export const MAXIMUM_CACHE_COUNT = 1000

type CacheStateEntry = Record<string, Record<string, any>>

interface CacheState {
    order: string[]
    entry: CacheStateEntry
}

const defaultState: CacheState = {
    order: [],
    entry: {} as CacheStateEntry,
}

export default handleActions(
    {
        [updateCache.toString()](
            state: CacheState,
            action: Action<CacheEntry>
        ): CacheState {
            const { funcName, params, data } = action.payload
            if (params) {
                const nxtState = set(['entry', funcName, params], data, state)

                nxtState.order.push(`${funcName}.${params}`)
                if (nxtState.order.length > MAXIMUM_CACHE_COUNT) {
                    const item = nxtState.order.shift()
                    nxtState.entry = produce(nxtState.entry, (draft) => {
                        if (item) {
                            delete (draft as any)[item]
                        }
                    })
                }

                return nxtState
            }
            return state
        },

        [invalidateCache.toString()](state: CacheState): CacheState {
            return {
                ...state,
                order: [],
                entry: {},
            }
        },
    } as ReducerMap<CacheState, CacheEntry>,
    cloneDeep(defaultState)
)
