import { handleActions } from 'redux-actions'

import { customEventsTableColumnsConfig } from 'configuration/tables/customEventsTable'
import { RESOURCE_TYPES, DATES, CUSTOM_EVENT_DESCRIPTION } from 'const/filters'
import { FILTER_SETTINGS, FILTERS } from 'const/reducerKeys'
import { serializeColumns } from 'helpers/tables'
import { defaultTab, getDefaultTable } from 'reducers/ui/defaults'

const initialState = {
    ...defaultTab,
    [FILTERS]: {
        [CUSTOM_EVENT_DESCRIPTION]: null,
        [RESOURCE_TYPES]: [],
    },
    [FILTER_SETTINGS]: {
        anchored: [CUSTOM_EVENT_DESCRIPTION, DATES],
        order: [RESOURCE_TYPES],
        displayState: {
            [RESOURCE_TYPES]: true,
        },
    },
    table: getDefaultTable(serializeColumns(customEventsTableColumnsConfig), {
        sorter: {
            field: 'created_at',
            order: 'descend',
        },
    }),
}

export default handleActions({}, initialState)
