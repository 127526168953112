import { ReactElement, ReactNode } from 'react'

import { Modal } from 'antd'
import noop from 'lodash/noop'
import { Translation } from 'react-i18next'

import InviteMembersList from 'components/InviteMembersList/InviteMembersList'
import { SOV_READ, SOV_WRITE } from 'const/featurePermissions'
import {
    RESOURCE_TYPE_LABELS,
    RESOURCE_TYPE_REGIONS,
    RESOURCE_TYPE_COUNTRIES,
    RESOURCE_TYPE_BRANDS,
    RESOURCE_TYPE_INTEGRATIONS,
    RESOURCE_TYPE_ALL,
    RESOURCE_TYPE_DSP_ADVERTISERS,
    NO_ACCESS,
} from 'const/organizations'
import { inviteMember } from 'services/cerebroApi/orgScope/resourceApi'
import { Member, OrganizationGroup, UserGroupType } from 'types'
import message from 'utilities/message'

import { formatBrandName } from './formatting'

export function partitionNewAndExistingUsers(
    members: Partial<Member>[]
): string[][] {
    const newUsers: string[] = []
    const existingUsers: string[] = []

    members.forEach((member) => {
        if (!member.email) {
            return
        }
        if (member.id) {
            existingUsers.push(member.id)
        } else {
            newUsers.push(member.email)
        }
    })

    return [newUsers, existingUsers]
}

export async function confirmSendInvitations(
    emails: string[],
    orgName: string,
    orgId: string,
    orgGroupId: string,
    groupType: UserGroupType,
    callback: () => void = noop
): Promise<void> {
    Modal.confirm({
        title: (
            <Translation>
                {(t) =>
                    t(
                        'account:sendInvites.title',
                        '{{count}} email address does not belong to a user in the {{orgName}} organization',
                        { count: emails.length, orgName }
                    )
                }
            </Translation>
        ),
        content: <InviteMembersList emails={emails} groupType={groupType} />,
        okText: (
            <Translation>
                {(t) =>
                    t('account:sendInvites.okText', 'Send an Invitation', {
                        count: emails.length,
                    })
                }
            </Translation>
        ),
        async onOk() {
            const response = await Promise.all(
                emails.map((email) =>
                    inviteMember({
                        user_email: email.trim().toLowerCase(),
                        organization_id: orgId,
                        organization_group_ids: [orgGroupId],
                        member_type: groupType,
                    })
                )
            )
            if (response.some((r) => r.status >= 300)) {
                const firstErrorResponse = response.find(
                    (r) => r.status !== 200
                )
                if (firstErrorResponse) {
                    const { data } = firstErrorResponse
                    const cerebroError: string | undefined = data?.[0]
                    message.error(
                        cerebroError ?? (
                            <Translation>
                                {(t) =>
                                    t(
                                        'account:sendInvites.errorMsg',
                                        'Failed to send invitation',
                                        { count: emails.length }
                                    )
                                }
                            </Translation>
                        )
                    )
                    return
                }
                return
            }
            message.success(
                <Translation>
                    {(t) =>
                        t(
                            'account:sendInvites.successMsg',
                            'Invitation successfully sent!',
                            { count: emails.length }
                        )
                    }
                </Translation>
            )
            callback()
        },
        icon: null,
        width: 500,
    })
}

const renderResourceCell = (label: string, content: string): ReactElement => (
    <div>
        <span style={{ fontWeight: 500 }}>{label}: </span>
        {content}
    </div>
)

export const renderResources = <T extends OrganizationGroup>(
    group: T
): ReactElement => {
    if (group.regions.length > 0) {
        return renderResourceCell(
            RESOURCE_TYPE_LABELS[RESOURCE_TYPE_REGIONS],
            group[RESOURCE_TYPE_REGIONS].join(', ')
        )
    }

    if (group.countries.length > 0) {
        return renderResourceCell(
            RESOURCE_TYPE_LABELS[RESOURCE_TYPE_COUNTRIES],
            group[RESOURCE_TYPE_COUNTRIES].join(', ')
        )
    }

    if (group.brands.length > 0) {
        return renderResourceCell(
            RESOURCE_TYPE_LABELS[RESOURCE_TYPE_BRANDS],
            group[RESOURCE_TYPE_BRANDS].map((brand) =>
                formatBrandName(brand)
            ).join(', ')
        )
    }

    if (group.integrations.length > 0) {
        return renderResourceCell(
            RESOURCE_TYPE_LABELS[RESOURCE_TYPE_INTEGRATIONS],
            group[RESOURCE_TYPE_INTEGRATIONS].map(
                (integration) => integration.alias
            ).join(', ')
        )
    }

    return <div>{RESOURCE_TYPE_LABELS[RESOURCE_TYPE_ALL]}</div>
}

export const renderSovResources = (group: OrganizationGroup): ReactNode => {
    if (
        !group.permissions.includes(SOV_READ) &&
        !group.permissions.includes(SOV_WRITE)
    ) {
        return RESOURCE_TYPE_LABELS[NO_ACCESS]
    }
    if (group.sov_regions.length > 0) {
        return renderResourceCell(
            RESOURCE_TYPE_LABELS[RESOURCE_TYPE_REGIONS],
            group.sov_regions.join(', ')
        )
    }

    if (group.sov_countries.length > 0) {
        return renderResourceCell(
            RESOURCE_TYPE_LABELS[RESOURCE_TYPE_COUNTRIES],
            group.sov_countries.join(', ')
        )
    }

    return <div>{RESOURCE_TYPE_LABELS[RESOURCE_TYPE_ALL]}</div>
}

export const renderDspResources = (group: OrganizationGroup): ReactElement => {
    if (group.dsp_advertisers.length > 0) {
        return renderResourceCell(
            RESOURCE_TYPE_LABELS[RESOURCE_TYPE_DSP_ADVERTISERS],
            group.dsp_advertisers
                .map((advertiser) => advertiser.name)
                .join(', ')
        )
    }

    return <div>{RESOURCE_TYPE_LABELS[RESOURCE_TYPE_ALL]}</div>
}
