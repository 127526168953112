import { memo, ReactElement, useMemo } from 'react'

import { isEqual } from 'lodash'
import { useTranslation } from 'react-i18next'

import { useResource } from 'components/EditableFields/localHooks'
import { StateField } from 'components/EditableFields/SharedFields'
import { UNDEFINED_VALUE } from 'const/formatting'
import { DSP_DELIVERY_ACTIVATION_STATES } from 'const/resourceStates'
import { titleCase } from 'helpers/formatting'
import {
    updateDspLineItem,
    updateDspOrder,
} from 'services/cerebroApi/orgScope/dspApi'
import { DspLineItemGroupBy, DspOrderGroupBy } from 'types'

type DspRecord = DspLineItemGroupBy | DspOrderGroupBy

interface Props<RecordType> {
    dataIndex: string[]
    readOnly: boolean
    record: RecordType
    resourceName: 'line_item' | 'order'
}

function DeliveryActivationStatusField<RecordType extends DspRecord>({
    dataIndex,
    readOnly = false,
    record,
    resourceName,
}: Props<RecordType>): ReactElement {
    const { t } = useTranslation()
    const { fieldName, getFromResource, resourceId } = useResource(
        record,
        dataIndex,
        resourceName
    )

    const apiMethod = useMemo(() => {
        if (resourceName === 'line_item') {
            return updateDspLineItem
        }
        return updateDspOrder
    }, [resourceName])

    const getIsDisabled = (): boolean => {
        const userCanManage = getFromResource(['user_can_manage'], true)
        return !userCanManage
    }

    return (
        <StateField
            disabled={getIsDisabled()}
            fieldName={fieldName}
            fieldPath={dataIndex}
            options={DSP_DELIVERY_ACTIVATION_STATES}
            placeholder={t(
                'campaigns:dsp.DeliveryActivationStatus.placeholder',
                'Delivery Activation Status'
            )}
            formatFieldValue={(value) => {
                return value[fieldName]
                    ? titleCase(value[fieldName])
                    : UNDEFINED_VALUE
            }}
            readOnly={readOnly}
            record={record}
            updateRequestApi={apiMethod}
            updateRequestData={resourceId}
            updateRequestSuccessMesg={t(
                'campaigns:dsp.DeliveryActivationStatus.updateState.success',
                'Successfully updated delivery activation state'
            )}
        />
    )
}

export default memo(
    DeliveryActivationStatusField,
    isEqual
) as typeof DeliveryActivationStatusField
