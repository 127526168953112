import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import isObjectLike from 'lodash/isObjectLike'
import { Moment } from 'moment'

import { DATA_SOURCES } from 'configuration/widgets'
import {
    KEYWORDS,
    CAMPAIGNS,
    PRODUCT_ASINS,
    COUNTRIES,
    PRODUCT_ADS,
    BRANDS,
    PORTFOLIOS,
    PARENT_ASINS,
    AD_GROUPS,
    DSP_ADVERTISERS,
    DSP_ORDERS,
    DSP_LINE_ITEMS,
    SEGMENTS,
} from 'const/filters'
import {
    KEYWORD_PAGE,
    CAMPAIGN_PAGE,
    PRODUCT_PAGE,
    PRODUCT_AD_PAGE,
    AD_ACCOUNT_PAGE,
    PORTFOLIO_PAGE,
    AD_GROUP_PAGE,
    DSP_ADVERTISER_PAGE,
    DSP_ORDER_PAGE,
    DSP_LINE_ITEM_PAGE,
    SEGMENT_PAGE,
} from 'const/pages'
import {
    REPORT_DATE_HOUR_ID,
    REPORT_DATE_DAY_ID,
    MAX_DAYS_RANGE_FOR_HOURLY_DATA,
} from 'const/widgets'
import { getDateRangeInDays } from 'helpers/dateRange'
import { Widget } from 'types'

interface FilterOverrides {
    mergeWithFilters?: boolean
    filters: Partial<Record<string, any>>
}

export function getFilterOverridesForPage(
    pageName: string,
    resource: any
): FilterOverrides {
    const { id } = resource
    if (pageName === KEYWORD_PAGE) {
        return {
            filters: {
                [KEYWORDS]: [{ value: id }],
            },
        }
    }

    if (pageName === CAMPAIGN_PAGE) {
        return {
            filters: {
                [CAMPAIGNS]: [{ value: id }],
            },
        }
    }

    if (pageName === PRODUCT_AD_PAGE) {
        return {
            filters: {
                [PRODUCT_ADS]: [{ value: id }],
            },
        }
    }

    if (pageName === AD_ACCOUNT_PAGE) {
        return {
            filters: {
                [BRANDS]: [{ value: id }],
            },
        }
    }

    if (pageName === PRODUCT_PAGE) {
        const { asin, marketplace, is_parent } = resource
        if (is_parent) {
            return {
                filters: {
                    [PARENT_ASINS]: [{ value: asin }],
                    [COUNTRIES]: [{ value: marketplace }],
                },
            }
        }
        return {
            filters: {
                [PRODUCT_ASINS]: [{ value: asin }],
                [COUNTRIES]: [{ value: marketplace }],
            },
        }
    }

    if (pageName === PORTFOLIO_PAGE) {
        return {
            filters: {
                [PORTFOLIOS]: [{ value: id }],
            },
        }
    }

    if (pageName === AD_GROUP_PAGE) {
        return {
            filters: {
                [AD_GROUPS]: [{ value: id }],
            },
        }
    }

    if (pageName === DSP_ADVERTISER_PAGE) {
        return {
            filters: {
                [DSP_ADVERTISERS]: [{ value: id }],
            },
        }
    }

    if (pageName === DSP_ORDER_PAGE) {
        return {
            filters: {
                [DSP_ORDERS]: [{ value: id }],
            },
        }
    }

    if (pageName === DSP_LINE_ITEM_PAGE) {
        return {
            filters: {
                [DSP_LINE_ITEMS]: [{ value: id }],
            },
        }
    }

    if (pageName === SEGMENT_PAGE) {
        return {
            mergeWithFilters: true,
            filters: {
                [SEGMENTS]: [{ value: id }],
            },
        }
    }

    return { filters: {} }
}

export function transformToString(config: any): string[] {
    if (!isEmpty(config) && isObjectLike(config[0])) {
        return config
            .filter((each: any) => !isNil(each))
            .map((each: any) => each.id)
    }

    return config
}

export function hasExportFunction(widget: Widget): boolean {
    const dataSource = DATA_SOURCES[widget.data_source]
    return !!dataSource?.exportApiFunc
}

export const isWidgetHourlyAgg = (widget: Widget): boolean => {
    const widgetGroupBy = transformToString(widget.group_by)
    return !!widgetGroupBy?.length && widgetGroupBy[0] === REPORT_DATE_HOUR_ID
}

/**
 * takes a widget and a dates range. If the widget is supposed to be hourly aggregated but the date range is too big, then it zooms out the widget to work on a daily aggregation.
 *
 * @param widget The original widget (won't be changed)
 * @param dateRange string[] | moment[]
 *
 * @returns an Object
 * {
 *    widget: the new widget (possibly zoomed out),
 *   isZoomedOut: a boolean saying whether the widget was zoomed out or not.
 * }
 */
export const zoomOut = (
    widget: Widget,
    dateRange?: string[] | Moment[]
): { isZoomedOut: boolean; widget: Widget } => {
    const dateRangeInDays =
        dateRange?.length === 2
            ? getDateRangeInDays(dateRange[0], dateRange[1])
            : 0
    const hasHourlyData = isWidgetHourlyAgg(widget)
    const tempWidget = cloneDeep(widget)
    let isZoomedOut = false
    if (hasHourlyData && dateRangeInDays > MAX_DAYS_RANGE_FOR_HOURLY_DATA) {
        tempWidget.sorter = { ...widget.sorter, field: REPORT_DATE_DAY_ID }
        tempWidget.group_by = widget.group_by.map((grouped, idx) =>
            idx === 0 ? { ...grouped, id: REPORT_DATE_DAY_ID } : grouped
        )
        tempWidget.order_by = REPORT_DATE_DAY_ID
        isZoomedOut = true
    }
    return {
        isZoomedOut,
        widget: tempWidget,
    }
}
