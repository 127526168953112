import { ReactElement } from 'react'

import { Flex } from 'antd'
import pluralize from 'pluralize'

import {
    formatBulkUpdateType,
    isCSVUpdateAction,
    isNumberAction,
} from 'helpers/bulkUpdate'
import { titleCase, formatNumber } from 'helpers/formatting'
import { AsyncUpdate, BulkUpdateNumberAction } from 'types'

const descriptionPartsForNumberAction = (
    action: BulkUpdateNumberAction,
    numberValue: number
): {
    verb: string
    value: string
    preposition: string
} => {
    let verb
    let value
    let preposition
    switch (action) {
        case 'set':
            verb = 'Setting'
            value = formatNumber(numberValue, '0.00')
            preposition = 'to'
            break
        case 'decrease':
            verb = 'Decreasing'
            value = formatNumber(numberValue, '0.00')
            preposition = 'by'
            break
        case 'decrease_by_pct':
            verb = 'Decreasing'
            value = formatNumber(numberValue, '0.0%')
            preposition = 'by'
            break
        case 'increase':
            verb = 'Increasing'
            value = formatNumber(numberValue, '0.00')
            preposition = 'by'
            break
        case 'increase_by_pct':
            verb = 'Increasing'
            value = formatNumber(numberValue, '0.0%')
            preposition = 'by'
            break
        default:
            verb = 'Updating'
            value = formatNumber(numberValue, '0.00')
            preposition = 'to'
    }
    return {
        verb,
        value,
        preposition,
    }
}

const formatCSVUpdateDescription = (update: AsyncUpdate): ReactElement => (
    <Flex vertical style={{ lineHeight: '16px' }}>
        {update.name ? (
            <span style={{ fontSize: '12.6px' }}>{update.name}</span>
        ) : null}
        <span style={{ fontSize: 12 }}>
            Updating {pluralize(formatBulkUpdateType(update))} with CSV file
        </span>
    </Flex>
)

interface Props {
    update: AsyncUpdate
}

const AsyncUpdateDescription = ({ update }: Props): ReactElement => {
    const resourceAndCount = `${formatNumber(update.count)} ${pluralize(
        formatBulkUpdateType(update),
        update.count
    )}`

    if (update.actions?.bid) {
        if (
            update.actions.bid.params &&
            isNumberAction(update.actions.bid.action)
        ) {
            const { value, preposition, verb } =
                descriptionPartsForNumberAction(
                    update.actions.bid.action,
                    update.actions.bid.params.value
                )
            return (
                <span>
                    {verb} Bid of {resourceAndCount} {preposition} {value}
                </span>
            )
        }
        if (isCSVUpdateAction(update.actions.bid.action)) {
            return formatCSVUpdateDescription(update)
        }
    }

    if (update.actions?.budget) {
        if (
            update.actions.budget.params &&
            isNumberAction(update.actions.budget.action)
        ) {
            const { value, preposition, verb } =
                descriptionPartsForNumberAction(
                    update.actions.budget.action,
                    update.actions.budget.params.value
                )
            return (
                <span>
                    {verb} Budget of {resourceAndCount} {preposition} {value}
                </span>
            )
        }
        if (isCSVUpdateAction(update.actions.budget.action)) {
            return formatCSVUpdateDescription(update)
        }
    }

    if (update.actions?.labels) {
        if (update.actions.labels.params) {
            let actionVerb
            switch (update.actions.labels.action) {
                case 'add':
                    actionVerb = 'Adding'
                    break
                case 'remove':
                    actionVerb = 'Removing'
                    break
                case 'set':
                    actionVerb = 'Replacing'
                    break
                default:
                    actionVerb = 'Updating'
            }
            return (
                <span>
                    {actionVerb} Labels for {resourceAndCount}
                </span>
            )
        }
        if (isCSVUpdateAction(update.actions.labels.action)) {
            return formatCSVUpdateDescription(update)
        }
    }

    if (update.actions?.state) {
        if (update.actions.state.params) {
            return (
                <span>
                    Updating State of {resourceAndCount} to{' '}
                    {titleCase(update.actions.state.params.value)}
                </span>
            )
        }
        if (isCSVUpdateAction(update.actions.state.action)) {
            return formatCSVUpdateDescription(update)
        }
    }

    return <span>Bulk Update of {pluralize(formatBulkUpdateType(update))}</span>
}

export default AsyncUpdateDescription
