import flow from 'lodash/fp/flow'
import set from 'lodash/fp/set'
import get from 'lodash/get'
import { handleActions } from 'redux-actions'

import {
    // Fetch Triggered Alerts
    fetchTriggeredAlertsSuccess,
    fetchTriggeredAlertsFailure,

    // Fetch Alert Subscriptions
    fetchAlertSubscriptionsSuccess,
    fetchAlertSubscriptionsFailure,
} from 'actions/ui/alertPage'

import { defaultResourcePage } from '../defaults'

const defaultState = {
    ...defaultResourcePage,
    triggeredAlerts: {
        loading: true,
        error: false,
        count: null,
        next: null,
        previous: null,
        results: [],
    },
    subscriptions: [],
}

export default handleActions(
    {
        [fetchTriggeredAlertsSuccess](state, action) {
            const results = get(action, ['payload', 'results'], [])
            const count = get(action, ['payload', 'count'], 0)
            const next = get(action, ['payload', 'next'], null)
            const previous = get(action, ['payload', 'previous'], [])

            return flow(
                set(['triggeredAlerts', 'loading'], false),
                set(['triggeredAlerts', 'results'], results),
                set(['triggeredAlerts', 'count'], count),
                set(['triggeredAlerts', 'next'], next),
                set(['triggeredAlerts', 'previous'], previous)
            )(state)
        },
        [fetchTriggeredAlertsFailure](state, action) {
            return flow(
                set(['triggeredAlerts', 'loading'], false),
                set(['triggeredAlerts', 'error'], action.payload)
            )(state)
        },

        [fetchAlertSubscriptionsSuccess](state, action) {
            return set('subscriptions', action.payload.results)(state)
        },
        [fetchAlertSubscriptionsFailure](state, action) {
            return set('error', action.payload)(state)
        },
    },
    defaultState
)
