import { ReactElement, useState } from 'react'

import { CountryCode } from 'types'

import { CogsProductMetadata } from './localTypes'
import styles from './styles.scss'
import { Flag } from '../Flag'
import { AmazonIcon, BrandIcon, ImageNotFoundIcon, LabelIcon } from '../Icons'
import { AsinLink } from '../Links'

interface ProductCogsProductsProps {
    sku: string
    products: CogsProductMetadata[]
    selectedProductId?: string
}

const ProductCogsProducts = (
    props: ProductCogsProductsProps
): ReactElement | null => {
    const { sku, products = [], selectedProductId } = props
    const [imageOk, setImageOk] = useState<boolean>(true)

    const product = products.find((p) => p.id === selectedProductId)

    return product ? (
        <div className={styles.container}>
            <div className={styles['image-outer']}>
                <div className={styles.image}>
                    {product.small_image_url && imageOk ? (
                        <img
                            src={product.small_image_url}
                            alt={product.title ?? '[product image]'}
                            onError={() => setImageOk(false)}
                        />
                    ) : (
                        <ImageNotFoundIcon
                            className={styles['missing-img-icon']}
                        />
                    )}
                </div>
            </div>
            <div className={styles.text}>
                <div className={styles.title}>{product.title}</div>
                <div className={styles.metadata}>
                    <div className={styles.sku}>
                        <LabelIcon />
                        <span>{sku}</span>
                    </div>
                    <div className={styles.marketplaces}>
                        <BrandIcon />
                        {products.length > 1 ? (
                            <span className={styles.label}>
                                {products.length} countries
                            </span>
                        ) : null}
                        {products.map((p) => (
                            <Flag
                                key={p.id}
                                country={p.marketplace as CountryCode}
                            />
                        ))}
                    </div>
                    <div className={styles.asin}>
                        <AmazonIcon />
                        <span className={styles.label}>Related ASIN: </span>
                        <AsinLink
                            asin={product.asin}
                            countryCode={product.marketplace}
                            showAmazonIcon={false}
                        />
                    </div>
                </div>
            </div>
        </div>
    ) : null
}

export default ProductCogsProducts
