import get from 'lodash/get'
import { select, put, call } from 'redux-saga/effects'

import { makeFetchDashboardSuccess } from 'actions/ui/shared/dashboard'
import { FALLBACK_DASHBOARD_USER } from 'const/applicationUsers'
import { HOME_PAGE } from 'const/pages'
import { cerebroApiSaga } from 'sagas/common'
import uiSagaRegistry from 'sagas/ui/registry'
import { selectHasNoIntegrations } from 'selectors/auth'
import { selectDomainValue } from 'selectors/ui'
import {
    getDashboard,
    getDashboards,
} from 'services/cerebroApi/orgScope/resourceApi'

function* fetchFallbackDashboardSaga() {
    const res = yield call(cerebroApiSaga, null, getDashboards, {
        ordering: 'created_at',
        limit: 1,
        created_by__email: FALLBACK_DASHBOARD_USER,
    })

    return get(res, ['data', 'results', 0], null)
}

function* fetchDashboardSaga() {
    const dashboardId = yield select(selectDomainValue, [
        'app',
        'homeDashboardId',
    ])
    const hasNoIntegrations = yield select(selectHasNoIntegrations)

    let dashboard
    if (dashboardId) {
        const res = yield call(cerebroApiSaga, null, getDashboard, dashboardId)
        if (res.status >= 400 && !hasNoIntegrations) {
            dashboard = yield call(fetchFallbackDashboardSaga)
        } else {
            dashboard = res.data
        }
    } else if (!hasNoIntegrations) {
        dashboard = yield call(fetchFallbackDashboardSaga)
    }

    if (dashboard) {
        yield put(makeFetchDashboardSuccess(HOME_PAGE)(dashboard))
    }
}

function* fetchPageDataSaga() {
    yield call(fetchDashboardSaga)
}

/**
 * Mounts the Home Page and fetches data
 */
function* mountPageSaga() {
    yield call(fetchPageDataSaga)
}

uiSagaRegistry.registerSagas([HOME_PAGE], {
    mountPageSaga,
})
