import { handleActions } from 'redux-actions'

import { labelSovKeywordsTableColumnsConfig } from 'configuration/tables'
import {
    SEARCH_TERM_NAME,
    SOV_FOLDS,
    SOV_RESULT_TYPES,
    SOV_PLATFORMS,
    SOV_COUNTRIES,
    SOV_KEYWORDS,
    SOV_LANGUAGES,
    SOV_STATES,
    SOV_METADATA_BRANDS,
    DATES,
} from 'const/filters'
import { LOCAL_FILTERS, FILTERS, FILTER_SETTINGS } from 'const/reducerKeys'
import { serializeColumns } from 'helpers/tables'
import { defaultTab, getDefaultTable } from 'reducers/ui/defaults'

const initialState = {
    ...defaultTab,
    [LOCAL_FILTERS]: {
        [SEARCH_TERM_NAME]: null,
    },
    [FILTERS]: {
        [SOV_FOLDS]: [],
        [SOV_RESULT_TYPES]: [],
        [SOV_PLATFORMS]: [],
        [SOV_COUNTRIES]: [],
        [SOV_KEYWORDS]: [],
        [SOV_LANGUAGES]: [],
        [SOV_STATES]: [],
        [SOV_METADATA_BRANDS]: [],
    },
    [FILTER_SETTINGS]: {
        anchored: [SEARCH_TERM_NAME, DATES],
        order: [
            SOV_METADATA_BRANDS,
            SOV_KEYWORDS,
            SOV_FOLDS,
            SOV_RESULT_TYPES,
            SOV_PLATFORMS,
            SOV_COUNTRIES,
            SOV_LANGUAGES,
            SOV_STATES,
        ],
        displayState: {
            [SOV_METADATA_BRANDS]: true,
            [SOV_KEYWORDS]: true,
            [SOV_FOLDS]: true,
            [SOV_RESULT_TYPES]: true,
            [SOV_PLATFORMS]: true,
            [SOV_COUNTRIES]: true,
            [SOV_LANGUAGES]: false,
            [SOV_STATES]: false,
        },
    },
    table: getDefaultTable(
        serializeColumns(labelSovKeywordsTableColumnsConfig),
        {
            sorter: {
                field: ['keyword', 'text'],
                order: 'ascend',
            },
        }
    ),
}

export default handleActions({}, initialState)
