import { all } from 'redux-saga/effects'

import './products'
import './productAds'
import './skuProducts'
import './skuCogsBulkEdit'

import parentProductsTabWatcher from './parentProducts'

export default function* productsSummaryPageWatcher() {
    yield all([parentProductsTabWatcher()])
}
