import isNil from 'lodash/isNil'
import moment from 'moment-timezone'

import { DAY_FORMAT, HOUR_TIMEZONE_FORMAT } from 'const/formatting'
import { formatCurrency, titleCase } from 'helpers/formatting'
import { getAbcIfEnabled } from 'helpers/timeParting'
import {
    ABCAutomationTask,
    Campaign,
    CampaignBudgetStatusGroupBy,
    CampaignGroupBy,
} from 'types'

export interface BudgetConfiguration {
    text: {
        title: string
        tooltip?: string
    }
    abcTask?: ABCAutomationTask
    budget?: {
        base: string
        active: string
        spent: string
        updateTime: string
        basePercentageSpent: number
        activePercentageSpent: number
    }
    colors?: {
        text: string
        progress: string
    }
}

export type FullBudgetConfiguration = BudgetConfiguration & {
    budget: NonNullable<BudgetConfiguration['budget']>
}

// TODO: some of this logic should be on the back-end,
// as in returning null for records that do not respect certain conditions
export const budgetStatusSorter = (
    a: CampaignGroupBy & CampaignBudgetStatusGroupBy,
    b: CampaignGroupBy & CampaignBudgetStatusGroupBy
): number => {
    if (a.campaign.state !== 'enabled') {
        return -1
    }
    if (b.campaign.state !== 'enabled') {
        return 1
    }

    const aToday = moment().tz(a.campaign.profile.timezone).startOf('day')
    const aUpdate = moment.tz(a.budget_updated_at, a.campaign.profile.timezone)
    if (aUpdate.isBefore(aToday)) {
        return -1
    }

    const bToday = moment().tz(b.campaign.profile.timezone).startOf('day')
    const bUpdate = moment.tz(b.budget_updated_at, b.campaign.profile.timezone)
    if (bUpdate.isBefore(bToday)) {
        return 1
    }

    return (a.budget_percentage || 0) > (b.budget_percentage || 0) ? 1 : -1
}

export const getBudgetConfiguration = (
    campaign: Campaign,
    historicalBudget: number | null,
    budgetPercentage: number | null,
    budgetUpdateTime: string | null
): BudgetConfiguration => {
    if (campaign.state !== 'enabled') {
        return {
            text: {
                title: titleCase(`Campaign ${campaign.state}`),
            },
        }
    }

    const today = moment().tz(campaign.profile.timezone).startOf('day')
    const updateTime = moment.tz(budgetUpdateTime, campaign.profile.timezone)

    if (
        isNil(historicalBudget) ||
        isNil(budgetPercentage) ||
        updateTime.isBefore(today)
    ) {
        return {
            text: {
                title: 'No budget updates yet',
                tooltip:
                    'Budget Status typically updates after ~5% of budget has been spent.',
            },
        }
    }

    const spent = historicalBudget * budgetPercentage
    const basePercentageSpent = (spent / campaign.base_budget) * 100
    const activePercentageSpent = (spent / campaign.active_budget) * 100

    const formatterOptions = {
        decimal: true,
        currencyCode: campaign.profile.currency_code,
    }

    const budgetState = {
        base: formatCurrency(campaign.base_budget, formatterOptions),
        active: formatCurrency(campaign.active_budget, formatterOptions),
        spent: formatCurrency(spent, formatterOptions),
        updateTime: moment
            .tz(budgetUpdateTime, campaign.profile.timezone)
            .calendar({
                sameDay: `${HOUR_TIMEZONE_FORMAT} [today]`,
                nextDay: `${HOUR_TIMEZONE_FORMAT} [tomorrow]`,
                nextWeek: `${HOUR_TIMEZONE_FORMAT} dddd`,
                lastDay: `${HOUR_TIMEZONE_FORMAT} [yesterday]`,
                lastWeek: `${HOUR_TIMEZONE_FORMAT} [last] dddd`,
                sameElse: `${HOUR_TIMEZONE_FORMAT} ${DAY_FORMAT}`,
            }),
        basePercentageSpent:
            basePercentageSpent > 0 && basePercentageSpent < 1
                ? 1
                : Math.round(basePercentageSpent),
        activePercentageSpent:
            activePercentageSpent > 0 && activePercentageSpent < 1
                ? 1
                : Math.round(activePercentageSpent),
    }

    const abcTask = getAbcIfEnabled(campaign.automation_tasks, campaign.state)
    const baseState = abcTask ? { abcTask } : {}

    if (spent < campaign.active_budget) {
        return {
            ...baseState,
            text: {
                title: 'Delivering',
            },
            budget: {
                ...budgetState,
            },
            colors: {
                text: '#0ba55f',
                progress: '#068467',
            },
        }
    }

    if (spent < campaign.base_budget && abcTask && abcTask.enabled) {
        return {
            ...baseState,
            text: {
                title: 'Hit Hourly Budget Cap',
            },
            budget: {
                ...budgetState,
            },
            colors: {
                text: '#ad8419',
                progress: '#d2b95e',
            },
        }
    }

    return {
        ...baseState,
        text: {
            title: 'Out of Budget',
        },
        budget: {
            ...budgetState,
        },
        colors: {
            text: '#c45500',
            progress: '#db894b',
        },
    }
}
