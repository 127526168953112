import { ReactElement, useMemo } from 'react'

import escapeRegExp from 'lodash/escapeRegExp'
import uniq from 'lodash/uniq'

import { TypeaheadMenuItemProps } from './types'

/**
 * Underline matches found in option string
 *
 * @param item
 * @param query
 */
const TypeaheadMenuItem = ({
    item,
    query,
    highlightQuery = true,
}: TypeaheadMenuItemProps): ReactElement => {
    const annotatedLabel = useMemo(
        () =>
            highlightQuery && query
                ? uniq(
                      item.label.match(new RegExp(escapeRegExp(query), 'gi')) ||
                          []
                  ).reduce(
                      (label, match) =>
                          label.replace(
                              new RegExp(escapeRegExp(match), 'g'),
                              `<u>${match}</u>`
                          ),
                      item.label
                  )
                : item.label,
        [highlightQuery, query, item]
    )

    return (
        <span>
            <b
                dangerouslySetInnerHTML={{
                    __html: annotatedLabel,
                }}
            />

            {item.metadata && (
                <span>
                    {' '}
                    (<em>{item.metadata}</em>)
                </span>
            )}
        </span>
    )
}

export default TypeaheadMenuItem
