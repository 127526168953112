import { memo, ReactElement } from 'react'

import isEqual from 'lodash/isEqual'

import { userHasDashboardsOnlyPermissions } from 'helpers/featurePermissions'
import { useUserHasPermissions } from 'hooks'
import { updateDashboard } from 'services/cerebroApi/orgScope/resourceApi'
import { Dashboard } from 'types'

import { TextAreaField } from '../../SharedFields'

interface Props<RecordType> {
    dataIndex: string[]
    readOnly: boolean
    record: RecordType
}

function DashboardDescriptionField<RecordType extends Dashboard>({
    dataIndex = ['description'],
    readOnly = false,
    record,
}: Props<RecordType>): ReactElement {
    const dashboardOnlyExperience = useUserHasPermissions(
        userHasDashboardsOnlyPermissions
    )

    const hasPermission = record.user_can_edit && !dashboardOnlyExperience

    return (
        <TextAreaField
            disabled={!hasPermission || readOnly}
            fieldName="description"
            fieldPath={dataIndex}
            readOnly={readOnly}
            record={record}
            updateRequestApi={updateDashboard}
            updateRequestData={record.id}
            updateRequestSuccessMesg="Successfully updated dashboard description"
        />
    )
}

export default memo(
    DashboardDescriptionField,
    isEqual
) as typeof DashboardDescriptionField
