import { sendCtaClickEvent, sendTrackEvent, TrackEvent } from './segment'

interface Analytics {
    trackEvent: (eventName: string, event: TrackEvent) => void
    trackCtaClick: (event: TrackEvent) => void
}

export const useAnalytics = (): Analytics => {
    return {
        trackEvent: sendTrackEvent,
        trackCtaClick: sendCtaClickEvent,
    }
}
