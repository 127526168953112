import find from 'lodash/find'

import {
    AMAZON_ADVERTISING,
    ASSUME_ANY_ORGANIZATION_GROUP,
    ASSUME_ANY_USER,
    DASHBOARD_TEMPLATE_FEATURE,
    DASHBOARDS_ONLY,
    MANAGE_AMS,
    MANAGE_AUTOMATION,
    MANAGE_COGS,
    MANAGE_LABELS,
    MANAGE_RULEBOOKS,
    MANAGE_SEGMENTS,
    ORGANIZATION_ADMIN,
    ORGANIZATION_GROUP_ADMIN,
    READ_SEGMENTS,
    SELLER_CENTRAL_READ,
    SOV_RAW_DATA,
    SOV_READ,
    SOV_WRITE,
    USE_SEGMENT_MODEL_VERSION,
    VENDOR_CENTRAL_READ,
    WALMART_ADVERTISING_READ,
    WALMART_ADVERTISING_WRITE,
    WALMART_MARKETPLACE_READ,
    WALMART_MARKETPLACE_WRITE,
} from 'const/featurePermissions'
import { FeaturePermissionCodename, UserPermission } from 'types'

export const userHasPermissions = (
    userFeaturePermissions: UserPermission[],
    permission: FeaturePermissionCodename
): boolean => {
    const permissionMeta = find(userFeaturePermissions, [
        'codename',
        permission,
    ])

    if (permissionMeta) {
        return (
            permissionMeta.granted_to_user ||
            permissionMeta.granted_to_organization_group
        )
    }

    return false
}

export const userHasSovReadPermissions = (
    userFeaturePermissions: UserPermission[]
): boolean => userHasPermissions(userFeaturePermissions, SOV_READ)

export const userHasSovWritePermissions = (
    userFeaturePermissions: UserPermission[]
): boolean => userHasPermissions(userFeaturePermissions, SOV_WRITE)

export const userHasSovRawDataPermissions = (
    userFeaturePermissions: UserPermission[]
): boolean => userHasPermissions(userFeaturePermissions, SOV_RAW_DATA)

export const userHasCustomerServicePermissions = (
    userFeaturePermissions: UserPermission[]
): boolean =>
    userHasPermissions(userFeaturePermissions, ASSUME_ANY_ORGANIZATION_GROUP)

export const userHasOrgAdminPermissions = (
    userFeaturePermissions: UserPermission[]
): boolean => userHasPermissions(userFeaturePermissions, ORGANIZATION_ADMIN)

export const userHasOrgGroupAdminPermissions = (
    userFeaturePermissions: UserPermission[]
): boolean =>
    userHasPermissions(userFeaturePermissions, ORGANIZATION_GROUP_ADMIN)

export const userHasManageLabelsPermissions = (
    userFeaturePermissions: UserPermission[]
): boolean => userHasPermissions(userFeaturePermissions, MANAGE_LABELS)

export const userHasManageAutomationPermissions = (
    userFeaturePermissions: UserPermission[]
): boolean => userHasPermissions(userFeaturePermissions, MANAGE_AUTOMATION)

export const userHasManageAmsPermissions = (
    userFeaturePermissions: UserPermission[]
): boolean => userHasPermissions(userFeaturePermissions, MANAGE_AMS)

export const userHasVendorCentralReadPermissions = (
    userFeaturePermissions: UserPermission[]
): boolean => userHasPermissions(userFeaturePermissions, VENDOR_CENTRAL_READ)

export const userHasSellerCentralReadPermissions = (
    userFeaturePermissions: UserPermission[]
): boolean => userHasPermissions(userFeaturePermissions, SELLER_CENTRAL_READ)

export const userHasAssumeAnyUserPermissions = (
    userFeaturePermissions: UserPermission[]
): boolean => userHasPermissions(userFeaturePermissions, ASSUME_ANY_USER)

export const userHasManageRulebooksPermissions = (
    userFeaturePermissions: UserPermission[]
): boolean => userHasPermissions(userFeaturePermissions, MANAGE_RULEBOOKS)

export const userHasDashboardsOnlyPermissions = (
    userFeaturePermissions: UserPermission[]
): boolean => userHasPermissions(userFeaturePermissions, DASHBOARDS_ONLY)

export const userHasAmazonAdvertisingPermissions = (
    userFeaturePermissions: UserPermission[]
): boolean => userHasPermissions(userFeaturePermissions, AMAZON_ADVERTISING)

export const userHasWalmartMarketplaceReadPermissions = (
    userFeaturePermissions: UserPermission[]
): boolean =>
    userHasPermissions(userFeaturePermissions, WALMART_MARKETPLACE_READ)

export const userHasWalmartMarketplaceWritePermissions = (
    userFeaturePermissions: UserPermission[]
): boolean =>
    userHasPermissions(userFeaturePermissions, WALMART_MARKETPLACE_WRITE)

export const userHasWalmartAdvertisingReadPermissions = (
    userFeaturePermissions: UserPermission[]
): boolean =>
    userHasPermissions(userFeaturePermissions, WALMART_ADVERTISING_READ)

export const userHasWalmartAdvertisingWritePermissions = (
    userFeaturePermissions: UserPermission[]
): boolean =>
    userHasPermissions(userFeaturePermissions, WALMART_ADVERTISING_WRITE)

export const userHasReadOrManageSegmentPermissions = (
    userFeaturePermissions: UserPermission[]
): boolean => {
    return (
        userHasPermissions(userFeaturePermissions, READ_SEGMENTS) ||
        userHasPermissions(userFeaturePermissions, MANAGE_SEGMENTS)
    )
}

export const userHasManageSegmentPermissions = (
    userFeaturePermissions: UserPermission[]
): boolean => userHasPermissions(userFeaturePermissions, MANAGE_SEGMENTS)

export const userHasManageProductCogsPermissions = (
    userFeaturePermissions: UserPermission[]
): boolean => userHasPermissions(userFeaturePermissions, MANAGE_COGS)

export const userHasDashboardTemplateFeaturePermissions = (
    userFeaturePermissions: UserPermission[]
): boolean =>
    userHasPermissions(userFeaturePermissions, DASHBOARD_TEMPLATE_FEATURE)

export const userHasManageSegmentVersions = (
    userFeaturePermissions: UserPermission[]
): boolean =>
    userHasPermissions(userFeaturePermissions, USE_SEGMENT_MODEL_VERSION)
