import { SVGAttributes, ReactElement } from 'react'

type Props = SVGAttributes<SVGElement>

function SvgArrowIcon(props: Props): ReactElement {
    return (
        <svg
            width="18"
            height="12"
            viewBox="0 0 18 12"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.03033 11.0303C5.73744 11.3232 5.26256 11.3232 4.96967 11.0303L0.469669 6.53033C0.176777 6.23744 0.176777 5.76256 0.469669 5.46967L4.96967 0.96967C5.26256 0.676777 5.73744 0.676777 6.03033 0.96967C6.32322 1.26256 6.32322 1.73744 6.03033 2.03033L2.81066 5.25L17 5.25C17.4142 5.25 17.75 5.58579 17.75 6C17.75 6.41421 17.4142 6.75 17 6.75L2.81066 6.75L6.03033 9.96967C6.32322 10.2626 6.32322 10.7374 6.03033 11.0303Z"
            />
        </svg>
    )
}

export default SvgArrowIcon
