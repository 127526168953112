import { handleActions } from 'redux-actions'

import {
    fetchChangesFailure,
    fetchChangesRequest,
    fetchChangesSuccess,
    resetChangesTable,
} from 'actions/ui/rulebookPage'
import { changeSetsTableColumnsConfig } from 'configuration/tables/changeSets'
import { CHANGE_SET_STATES, DATES } from 'const/filters'
import { FILTER_SETTINGS, LOCAL_FILTERS } from 'const/reducerKeys'
import { serializeColumns } from 'helpers/tables'
import { defaultPage, getDefaultTable } from 'reducers/ui/defaults'

const initialState = {
    ...defaultPage,
    [LOCAL_FILTERS]: {
        [DATES]: 'last_14_days_to_date',
        [CHANGE_SET_STATES]: [],
    },
    [FILTER_SETTINGS]: {
        anchored: [DATES, CHANGE_SET_STATES],
        order: [DATES, CHANGE_SET_STATES],
        displayState: {
            [CHANGE_SET_STATES]: true,
        },
    },
    table: getDefaultTable(serializeColumns(changeSetsTableColumnsConfig), {
        sorter: {
            field: 'updated_at',
            order: 'descend',
        },
    }),
}

export default handleActions(
    {
        [fetchChangesRequest.toString()](state, action) {
            return {
                ...state,
                table: {
                    ...state.table,
                    data: ((state.table.data as any[]) || []).map((record) => {
                        if (record.id === (action.payload as any).id) {
                            return {
                                ...record,
                                changes: [],
                            }
                        }
                        return record
                    }),
                },
            } as any
        },
        [fetchChangesSuccess.toString()](state, action) {
            return {
                ...state,
                table: {
                    ...state.table,
                    data: ((state.table.data as any[]) || []).map((record) => {
                        if (record.id === (action.payload as any).record.id) {
                            return {
                                ...record,
                                changes: (action.payload as any).changes,
                            }
                        }
                        return record
                    }),
                },
            } as any
        },
        [fetchChangesFailure.toString()](state, action) {
            return {
                ...state,
                table: {
                    ...state.table,
                    data: ((state.table.data as any[]) || []).map((record) => {
                        if (record.id === (action.payload as any).id) {
                            return {
                                ...record,
                                changes: [],
                            }
                        }
                        return record
                    }),
                },
            } as any
        },
        [resetChangesTable.toString()](state) {
            return {
                ...state,
                table: {
                    ...state.table,
                    data: initialState.table.data,
                    loading: false,
                    downloading: false,
                    error: null,
                    tableSettings: {
                        ...initialState.table.tableSettings,
                        pagination: initialState.table.tableSettings.pagination,
                    },
                },
            } as any
        },
    },
    initialState
)
