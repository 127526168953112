import { ReactElement, useEffect } from 'react'

import { Select } from 'antd'
import { Field, useFormikContext } from 'formik'

import { FormikSelect } from 'components/formik'
import { MATCH_TYPE_OPTIONS } from 'const/matchTypes'

import { FormValues } from './shared'

const MatchTypeSelectField = (): ReactElement => {
    const { values, setFieldValue } = useFormikContext<FormValues>()

    useEffect(() => {
        setFieldValue('matchType', '')
    }, [values.campaignId, setFieldValue])

    return (
        <Field
            name="matchType"
            component={FormikSelect}
            placeholder="Select Match Type"
            style={{ width: 250 }}
        >
            {MATCH_TYPE_OPTIONS.map(({ value, label }) => (
                <Select.Option key={value} value={value}>
                    {label}
                </Select.Option>
            ))}
        </Field>
    )
}

export default MatchTypeSelectField
