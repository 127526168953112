import { axiosInstanceOrganizations } from 'services/cerebroApi/orgScope/client'

// Alert Configurations
export const getAlert = (alertId) =>
    axiosInstanceOrganizations.get(`/api/alert_configuration/${alertId}/`)

export const updateAlert = (alertId, data) =>
    axiosInstanceOrganizations.patch(
        `/api/alert_configuration/${alertId}/`,
        data
    )

export const deleteAlert = (alertId) =>
    axiosInstanceOrganizations.delete(`/api/alert_configuration/${alertId}/`)

export const getAlerts = () =>
    axiosInstanceOrganizations.get('/api/alert_configurations/')

export const createAlert = (data) =>
    axiosInstanceOrganizations.post('/api/alert_configurations/', data)

// Alert Subscriptions
export const createAlertSubscription = (data) =>
    axiosInstanceOrganizations.post('/api/alert_subscriptions/', data)

export const deleteAlertSubscription = (id) =>
    axiosInstanceOrganizations.delete(`/api/alert_subscription/${id}/`)

export const getAlertSubscriptions = (params) =>
    axiosInstanceOrganizations.get('/api/alert_subscriptions/', { params })

// Triggered Alerts
export const getTriggeredAlert = (alertId) =>
    axiosInstanceOrganizations.get(`/api/triggered_alert/${alertId}/`)

export const getTriggeredAlerts = (params) =>
    axiosInstanceOrganizations.get('/api/triggered_alerts/', { params })

export const updateTriggeredAlert = (alertId, data) =>
    axiosInstanceOrganizations.patch(`/api/triggered_alert/${alertId}/`, data)
