import { handleActions } from 'redux-actions'

import { placementsTableColumnsConfig } from 'configuration/tables'
import { DATES } from 'const/filters'
import { FILTER_SETTINGS } from 'const/reducerKeys'
import { serializeColumns } from 'helpers/tables'
import { defaultTab, getDefaultTable } from 'reducers/ui/defaults'

const initialState = {
    ...defaultTab,
    [FILTER_SETTINGS]: {
        anchored: [DATES],
        order: [],
        displayState: {},
    },
    table: getDefaultTable(serializeColumns(placementsTableColumnsConfig)),
}

export default handleActions({}, initialState)
