import { useState, SetStateAction, Dispatch, Key } from 'react'

import { useCerebroApiRequest } from 'hooks'
import { getIntegration } from 'services/cerebroApi/orgScope/resourceApi'
import { IntegrationProfile, Integration, CerebroResourceResponse } from 'types'

interface IntegrationProfilesState {
    profiles: IntegrationProfile[]
    loadProfiles: () => Promise<void>
    loading: boolean
    selectedRowKeys: Key[]
    setSelectedRowKeys: Dispatch<SetStateAction<Key[]>>
}

const useIntegrationProfilesTable = (
    integrationId?: number
): IntegrationProfilesState => {
    const [profiles, setProfiles] = useState<IntegrationProfile[]>([])
    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])
    const [loading, setLoading] = useState(false)

    const makeCerebroApiRequest = useCerebroApiRequest()
    const loadProfiles = async (): Promise<void> => {
        setLoading(true)
        await makeCerebroApiRequest<
            Integration,
            CerebroResourceResponse<Integration>
        >({
            request: getIntegration(integrationId),
            onRequestSuccess: (response) => {
                const integrationProfiles = [
                    ...(response.data.profiles ?? []),
                ].sort((a, b) => {
                    const aName = (
                        a.profile.ams_brand_name ??
                        a.profile.brand_name ??
                        ''
                    ).toLowerCase()
                    const bName = (
                        b.profile.ams_brand_name ??
                        a.profile.brand_name ??
                        ''
                    ).toLowerCase()

                    if (aName < bName) {
                        return -1
                    }
                    if (aName > bName) {
                        return 1
                    }
                    if (aName === bName) {
                        if (a.profile.country_code < b.profile.country_code) {
                            return -1
                        }
                        if (a.profile.country_code > b.profile.country_code) {
                            return 1
                        }
                    }
                    return 0
                })
                setProfiles(integrationProfiles)
                setSelectedRowKeys(
                    integrationProfiles
                        .filter(
                            (profile) => profile.selected_status === 'active'
                        )
                        .map((profile) => profile.profile.id)
                )
            },
        })
        setLoading(false)
    }

    return {
        profiles,
        loadProfiles,
        loading,
        selectedRowKeys,
        setSelectedRowKeys,
    }
}

export default useIntegrationProfilesTable
