import { Organization, OrganizationGroup } from 'types'

type GroupTitleOptions = {
    organization?: Organization
    organizationGroup?: OrganizationGroup
}

export const getOrgGroupTitle = ({
    organization,
    organizationGroup,
}: GroupTitleOptions): string =>
    organization?.name && organizationGroup?.name
        ? `${organization?.name} | ${organizationGroup?.name}`
        : 'Loading...'
