import { handleActions } from 'redux-actions'

import { campaignProductsTableColumnsConfig } from 'configuration/tables'
import {
    DATES,
    LABELS,
    PRODUCT_ASINS,
    PRODUCT_TITLE,
    PRODUCT_TITLES,
} from 'const/filters'
import { LOCAL_FILTERS, FILTER_SETTINGS } from 'const/reducerKeys'
import { serializeColumns } from 'helpers/tables'
import { defaultTab, getDefaultTable } from 'reducers/ui/defaults'

const initialState = {
    ...defaultTab,
    [LOCAL_FILTERS]: {
        [PRODUCT_TITLE]: null,
        [PRODUCT_ASINS]: [],
        [PRODUCT_TITLES]: [],
        [LABELS]: [],
    },
    [FILTER_SETTINGS]: {
        anchored: [PRODUCT_TITLE, DATES],
        order: [PRODUCT_ASINS, PRODUCT_TITLES, LABELS],
        displayState: {
            [PRODUCT_ASINS]: true,
            [PRODUCT_TITLES]: true,
            [LABELS]: true,
        },
    },
    table: getDefaultTable(
        serializeColumns(campaignProductsTableColumnsConfig)
    ),
}

export default handleActions({}, initialState)
