import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'

import {
    DATES,
    REGIONS,
    COUNTRIES,
    AUTOMATIONS,
    CAMPAIGNS,
    BRANDS,
    RESOURCE_TYPES,
    LABELS,
    PORTFOLIOS,
    CAMPAIGN_NAME,
} from 'const/filters'
import { CAMPAIGN_LABELS } from 'const/resourceTypes'
import { formatCerebroDate } from 'helpers/formatting'

import { presetRanges } from '../dateRange'
import { formatBooleanFilter } from '../filters'

export const formatFilters = (filters, useProfileDates = true) => {
    const params = {}

    if (!isEmpty(filters[DATES])) {
        let min
        let max
        if (isArray(filters[DATES])) {
            min = formatCerebroDate(filters[DATES][0])
            max = formatCerebroDate(filters[DATES][1])
        } else {
            const { range } = presetRanges()[filters[DATES]]
            min = formatCerebroDate(range[0])
            max = formatCerebroDate(range[1])
        }
        params[useProfileDates ? 'profile_date_min' : 'date_min'] = min
        params[useProfileDates ? 'profile_date_max' : 'date_max'] = max
    }

    if (!isEmpty(filters[REGIONS])) {
        params.region__in = filters[REGIONS].map((item) => item.value).join()
    }

    if (!isEmpty(filters[COUNTRIES])) {
        params.country_code__in = filters[COUNTRIES].map(
            (item) => item.value
        ).join()
    }

    if (!isEmpty(filters[CAMPAIGNS])) {
        params.campaign__id__in = filters[CAMPAIGNS].map(
            (item) => item.value
        ).join()
    }

    if (!isEmpty(filters[AUTOMATIONS])) {
        params.automation_capability__in = filters[AUTOMATIONS].map(
            (item) => item.value
        ).join()
    }

    if (!isEmpty(filters[BRANDS])) {
        params.profile_id__in = filters[BRANDS].map((item) => item.value).join()
    }

    if (!isEmpty(filters[RESOURCE_TYPES])) {
        params.object_type__in = filters[RESOURCE_TYPES].map(
            (item) => item.value
        ).join()
    }

    if (!isEmpty(filters[LABELS])) {
        params.complex_label_filter = formatBooleanFilter(
            filters[LABELS],
            CAMPAIGN_LABELS
        )
    }

    if (!isEmpty(filters[PORTFOLIOS])) {
        params.campaign__portfolio__id__in = filters[PORTFOLIOS].map(
            (item) => item.value
        ).join()
    }

    if (!isEmpty(filters[CAMPAIGN_NAME])) {
        params.campaign__name__icontains = filters[CAMPAIGN_NAME]
    }

    return params
}
