import { ReactNode, ReactElement } from 'react'

import { Tooltip } from 'antd'
import { type TooltipPropsWithTitle } from 'antd/es/tooltip'

interface Props extends TooltipPropsWithTitle {
    disabled: boolean
    children: ReactElement
    title: ReactNode | string
}

const ToolTipDisabledElement = ({
    disabled = false,
    children,
    ...tooltipProps
}: Props): ReactElement =>
    disabled ? (
        <Tooltip {...tooltipProps}>
            <span style={{ width: '100%' }}>{children}</span>
        </Tooltip>
    ) : (
        children
    )

export default ToolTipDisabledElement
