import { SVGAttributes, ReactElement } from 'react'

type Props = SVGAttributes<SVGElement>

function SvgPlusIcon(props: Props): ReactElement {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 12 12"
            width="1em"
            height="1em"
            {...props}
        >
            <path d="M10.25 6.5H6.5v3.75a.75.75 0 01-1.5 0V6.5H1.25a.75.75 0 110-1.5H5V1.25a.75.75 0 011.5 0V5h3.75a.75.75 0 010 1.5z" />
        </svg>
    )
}

export default SvgPlusIcon
