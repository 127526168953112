import { SVGAttributes, ReactElement } from 'react'

type Props = SVGAttributes<SVGElement>

function SvgLabelIcon(props: Props): ReactElement {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.25 4.55v4.912c0 .344.137.675.38.919l6.95 6.95a1.3 1.3 0 001.84 0l4.91-4.912a1.3 1.3 0 000-1.838l-6.95-6.95a1.3 1.3 0 00-.918-.381H4.55a1.3 1.3 0 00-1.3 1.3zm4.5 4.7a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
            />
        </svg>
    )
}

export default SvgLabelIcon
