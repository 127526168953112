import { ReactElement, SVGAttributes } from 'react'

export default function ImageNotFoundIconSvg(
    props: SVGAttributes<SVGElement>
): ReactElement {
    return (
        <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M6.07243 11.817C5.97022 11.0454 6.51092 10.336 7.27967 10.2323L28.1666 7.41916C28.9356 7.31559 29.6421 7.85713 29.7443 8.6287L30.6181 15.2194L28.449 14.8929L27.8409 10.3071C27.7898 9.9213 27.4364 9.65051 27.0519 9.70229L8.95002 12.1404C8.56547 12.1922 8.29529 12.5469 8.34639 12.9327L9.50375 21.6626L8.38391 26.828L8.04655 26.7062L6.07243 11.817Z"
                fill="#C6C6C6"
            />
            <path
                d="M22.1709 23.6944C21.9046 23.9125 21.5077 23.842 21.3326 23.5457L19.1546 19.8594C18.9773 19.5594 18.5736 19.4917 18.3081 19.7175L11.8441 25.2166C11.4791 25.5271 11.6423 26.1236 12.1139 26.2026L26.7278 28.6527C27.2043 28.7326 27.5531 28.2116 27.299 27.7995L24.2332 22.8276C24.0548 22.5383 23.6634 22.4726 23.4004 22.6879L22.1709 23.6944Z"
                fill="#C6C6C6"
            />
            <path
                d="M28.7165 20.231C28.5878 20.9988 27.8632 21.5173 27.0982 21.3891C26.3331 21.2608 25.8173 20.5343 25.946 19.7665C26.0748 18.9986 26.7993 18.4801 27.5644 18.6084C28.3294 18.7366 28.8453 19.4631 28.7165 20.231Z"
                fill="#C6C6C6"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.7573 10.9553C10.9923 10.827 10.2677 11.3455 10.139 12.1134L7.34178 28.7974C7.21304 29.5653 7.72893 30.2918 8.49393 30.42L29.2726 33.9037C30.0376 34.0319 30.7622 33.5134 30.8909 32.7456L33.6881 16.0615C33.8168 15.2936 33.301 14.5672 32.536 14.4389L11.7573 10.9553ZM12.7929 13.273C12.4104 13.2089 12.0481 13.4681 11.9837 13.8521L9.65274 27.7555C9.58838 28.1394 9.84632 28.5026 10.2288 28.5668L28.237 31.5859C28.6195 31.6501 28.9818 31.3908 29.0462 31.0069L31.3771 17.1035C31.4415 16.7195 31.1836 16.3563 30.8011 16.2922L12.7929 13.273Z"
                fill="#C6C6C6"
            />
        </svg>
    )
}
