import isEmpty from 'lodash/isEmpty'

import { NEGATIVE_KEYWORD_TEXT } from 'const/filters'
import { FiltersState, KeywordFactsParams } from 'types'

export const formatFilters = (filters: FiltersState): KeywordFactsParams => {
    const params: KeywordFactsParams = {}

    if (!isEmpty(filters[NEGATIVE_KEYWORD_TEXT])) {
        params.text__icontains = filters[NEGATIVE_KEYWORD_TEXT]
    }

    return params
}
