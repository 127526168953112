import { call, select, put } from 'redux-saga/effects'

import {
    makeFetchTableSuccess,
    makeFetchTableRequest,
    makeDownloadTableSuccess,
} from 'actions/ui/shared'
import { keywordQueryFactTableColumnsConfig } from 'configuration/tables'
import { DATES } from 'const/filters'
import { KEYWORD_PAGE } from 'const/pages'
import { formatFilters } from 'helpers/filters/keywordQueryFacts'
import { generateReportNotification } from 'helpers/notifications'
import {
    formatPagination,
    formatSorter,
    formatMetrics,
    formatCurrency,
    formatColumns,
    formatPeriodDeltaDateRange,
} from 'helpers/params'
import { cerebroApiSaga } from 'sagas/common'
import uiSagaRegistry from 'sagas/ui/registry'
import {
    selectVisibleCombinedFilters,
    selectCurrencyCode,
    selectResourceParamsOfPage,
    selectResourceOfPage,
    selectTableSettings,
    selectVisibleMetricsOfTable,
    selectVisibleColumnsOfTable,
} from 'selectors/ui'
import {
    getKeywordQueryFacts,
    getKeywordQueryFactsExport,
} from 'services/cerebroApi/orgScope/keywordQueryFactsApi'

const TAB_PATH = [KEYWORD_PAGE, 'searchTerms']
const TABLE_PATH = [...TAB_PATH, 'table']

function* fetchTableSaga(noCount) {
    const { keywordId } = yield select(selectResourceParamsOfPage, KEYWORD_PAGE)
    const filters = yield select(selectVisibleCombinedFilters, TAB_PATH)
    const {
        pagination,
        sorter,
        showPeriodDeltas,
        periodDeltaType,
        periodDeltaDateRange,
    } = yield select(selectTableSettings, TABLE_PATH)
    const metrics = yield select(
        selectVisibleMetricsOfTable,
        TABLE_PATH,
        keywordQueryFactTableColumnsConfig
    )
    const currency = yield select(selectCurrencyCode)

    const params = {
        ...formatPagination(pagination),
        ...formatSorter(sorter),
        ...formatFilters(filters),
        ...formatMetrics(metrics, showPeriodDeltas),
        ...formatCurrency(currency),
        ...formatPeriodDeltaDateRange(
            showPeriodDeltas,
            periodDeltaType,
            periodDeltaDateRange,
            filters[DATES]
        ),
        keyword: keywordId,
        group_by: 'query',
    }

    yield call(
        cerebroApiSaga,
        makeFetchTableSuccess(TABLE_PATH),
        getKeywordQueryFacts,
        params,
        { headers: { noCount } }
    )
}

function* downloadTableSaga() {
    const keyword = yield select(selectResourceOfPage, KEYWORD_PAGE)
    const filters = yield select(selectVisibleCombinedFilters, TAB_PATH)
    const { sorter, showPeriodDeltas, periodDeltaType, periodDeltaDateRange } =
        yield select(selectTableSettings, TABLE_PATH)
    const currency = yield select(selectCurrencyCode)
    const columns = yield select(
        selectVisibleColumnsOfTable,
        TABLE_PATH,
        keywordQueryFactTableColumnsConfig
    )
    const reportName = `Search Term Report - ${keyword.text}`

    const params = {
        ...formatSorter(sorter),
        ...formatFilters(filters),
        ...formatCurrency(currency),
        ...formatColumns(columns, showPeriodDeltas),
        ...formatPeriodDeltaDateRange(
            showPeriodDeltas,
            periodDeltaType,
            periodDeltaDateRange,
            filters[DATES]
        ),
        keyword: keyword.id,
        group_by: 'query',
        async_download_name: reportName,
    }

    yield call(
        cerebroApiSaga,
        makeDownloadTableSuccess(TABLE_PATH),
        getKeywordQueryFactsExport,
        params
    )

    generateReportNotification(reportName)
}

function* fetchTabDataSaga() {
    yield put(makeFetchTableRequest(TABLE_PATH)())
}

// Register Sagas
uiSagaRegistry.registerSagas(TAB_PATH, {
    fetchTabDataSaga,
})

uiSagaRegistry.registerSagas(TABLE_PATH, {
    fetchTableSaga,
    downloadTableSaga,
})
