import {
    organizationInvitationEmailRenderer,
    organizationInvitationStatusRenderer,
} from 'components/FieldRenderers'
import { titleCase } from 'helpers/formatting'
import { Field } from 'types'

import { createCerebroDateTimeField, createField } from './createField'
import { FieldCreatorOptions } from './localTypes'

export function organizationInvitationEmail<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['user_email']
    return createField({
        ...options,
        id: 'organization_invitation_email',
        name: 'Email Address',
        shortName: 'Email Address',
        minWidth: 200,
        dataIndex,
        renderOptions: {
            render: (props) => organizationInvitationEmailRenderer<any>(props),
        },
        width: 300,
    })
}

export function organizationInvitationMemberType<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['member_type']
    return createField({
        ...options,
        id: 'organization_invitation_member_type',
        name: 'Member Type',
        shortName: 'Member Type',
        minWidth: 150,
        dataIndex,
        renderOptions: {
            render: ({ value }) => titleCase(value),
        },
    })
}

export function organizationInvitationStatus<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['state']
    return createField({
        ...options,
        id: 'organization_invitation_status',
        name: 'Status',
        shortName: 'Status',
        minWidth: 150,
        dataIndex,
        renderOptions: {
            render: (props) => organizationInvitationStatusRenderer<any>(props),
        },
        width: 300,
    })
}

export function organizationInvitationCreatedDate<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['created_date']
    return createCerebroDateTimeField({
        ...options,
        id: 'organization_invitation_created_date',
        dataIndex,
        name: 'Invite Date',
        shortName: 'Invite Date',
        minWidth: 150,
    })
}

export function organizationInvitationExpirationDate<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['expiration_date']
    return createCerebroDateTimeField({
        ...options,
        id: 'organization_invitation_expiration_date',
        dataIndex,
        name: 'Expiration Date',
        shortName: 'Expiration Date',
        minWidth: 150,
    })
}

export function organizationInvitationInviter<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['inviter', 'email']
    return createField({
        ...options,
        id: 'organization_invitation_inviter',
        dataIndex,
        name: 'Invited By',
        shortName: 'Invited By',
        minWidth: 200,
        width: 300,
    })
}
