import { ReactElement, ReactNode } from 'react'

import { Menu, type MenuItemProps } from 'antd'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { changeCurrencyCode } from 'actions/ui/app'
import { useModal, useAction } from 'hooks'
import { selectCurrencyCode, selectIsSidebarCollapsed } from 'selectors/ui'
import { RootReduxState } from 'types'

import CurrencyModal from './CurrencyModal'

type CurrencyCode = string | null | undefined

type RenderProps = {
    toggleModal: () => void
    currencyCode: string
}

// Menu.Item is passed props from Menu
// see https://github.com/react-component/menu/issues/142#issuecomment-413041068
interface Props extends Omit<MenuItemProps, 'children'> {
    children: (renderProps: RenderProps) => ReactNode
}

function CurrencyMenuItem({
    children,
    ...menuItemProps
}: Props): ReactElement | null {
    const { t } = useTranslation('navigation')
    const isSidebarCollapsed = useSelector((state: RootReduxState) =>
        selectIsSidebarCollapsed(state)
    )

    const currencyCode = useSelector<RootReduxState, CurrencyCode>((state) =>
        selectCurrencyCode(state)
    )

    const { isModalVisible, toggleModalVisible } = useModal()

    const dispatchChangeCurrencyCode = useAction<{ currencyCode: string }>(
        changeCurrencyCode
    )

    return currencyCode ? (
        <>
            <Menu.Item
                {...menuItemProps}
                key="currency"
                title={
                    isSidebarCollapsed
                        ? t('navigation:SideMenu.currency', 'Change Currency')
                        : null
                }
            >
                {children({ toggleModal: toggleModalVisible, currencyCode })}
            </Menu.Item>
            <CurrencyModal
                modalVisible={isModalVisible}
                closeModal={toggleModalVisible}
                handleChangeCurrencyCode={(nextCurrencyCode) => {
                    if (nextCurrencyCode) {
                        dispatchChangeCurrencyCode({
                            currencyCode: nextCurrencyCode,
                        })
                    }
                }}
                currencyCode={currencyCode}
            />
        </>
    ) : null
}

export default CurrencyMenuItem
