import { Translation } from 'react-i18next'

import {
    wmCampaignNameRenderer,
    wmCampaignStateRenderer,
} from 'components/FieldRenderers/Renderers'
import { FACT_TYPE_LABELS } from 'const/factTypes'
import { UNDEFINED_VALUE } from 'const/formatting'
import { titleCase } from 'helpers/formatting'
import { isUnset } from 'helpers/utilities'
import { formatBudgetType } from 'helpers/walmart'
import { Field } from 'types'

import {
    createCurrencyField,
    createDateField,
    createField,
    createLabelLookupField,
    createTitleField,
} from './createField'
import { FieldCreatorOptions } from './localTypes'

export function campaignName<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['name']
    const name = (
        <Translation>{(t) => t('common:campaign', 'Campaign')}</Translation>
    )
    return createField({
        ...options,
        id: 'campaign_name',
        name,
        shortName: name,
        minWidth: 200,
        isTotalSupported: options.isTotalSupported ?? true,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: (props) => wmCampaignNameRenderer<any>(props),
        },
        width: 300,
    })
}

export function campaignType<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['campaign_type']
    const name = (
        <Translation>
            {(t) => t('table:fields.campaignType.name', 'Campaign Type')}
        </Translation>
    )
    return createLabelLookupField({
        ...options,
        id: 'campaign_type',
        name,
        shortName: name,
        minWidth: 120,
        dataIndex,
        labels: FACT_TYPE_LABELS,
        width: 150,
    })
}

export function campaignTargetingType<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['targeting_type']

    const name = (
        <Translation>
            {(t) =>
                t(
                    'table:fields.campaignTargetingType.name',
                    'Campaign Targeting Type'
                )
            }
        </Translation>
    )
    const shortName = (
        <Translation>
            {(t) =>
                t(
                    'table:fields.campaignTargetingType.shortName',
                    'Targeting Type'
                )
            }
        </Translation>
    )

    return createTitleField({
        ...options,
        id: 'targeting_type',
        name,
        shortName,
        minWidth: 120,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: ({ value }) =>
                isUnset(value) ? UNDEFINED_VALUE : titleCase(value),
        },
        width: 150,
    })
}

export function campaignState<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['state']
    const name = (
        <Translation>
            {(t) => t('table:fields.campaignState.name', 'Campaign State')}
        </Translation>
    )
    const shortName = (
        <Translation>
            {(t) => t('table:fields.campaignState.shortName', 'State')}
        </Translation>
    )
    return createField({
        ...options,
        id: 'campaign_state',
        name,
        shortName,
        minWidth: 85,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: (props) => wmCampaignStateRenderer<any>(props),
        },
        width: 150,
    })
}

export function campaignStartDate<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['start_date']
    const name = (
        <Translation>
            {(t) => t('table:fields.campaignStartDate.name', 'Start Date')}
        </Translation>
    )
    return createDateField({
        id: 'campaign_start_date',
        name,
        shortName: name,
        dataIndex,
        minWidth: 80,
        width: 110,
    })
}

export function campaignEndDate<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['end_date']
    const name = (
        <Translation>
            {(t) => t('table:fields.campaignEndDate.name', 'End Date')}
        </Translation>
    )
    return createDateField({
        ...options,
        id: 'campaign_end_date',
        name,
        shortName: name,
        dataIndex,
        minWidth: 80,
        width: 110,
    })
}

export function campaignCreationDate<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['created_at']
    const name = (
        <Translation>
            {(t) =>
                t('table:fields.campaignCreationDate.name', 'Creation Date')
            }
        </Translation>
    )
    return createDateField({
        ...options,
        id: 'campaign_creation_date',
        name,
        shortName: name,
        dataIndex,
        minWidth: 80,
        width: 110,
    })
}

export function campaignBudgetType<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['budget_type']
    const name = (
        <Translation>
            {(t) => t('table:fields.campaignBudgetType.name', 'Budget Type')}
        </Translation>
    )

    return createField({
        ...options,
        id: 'campaign_budget_type',
        name,
        shortName: name,
        minWidth: 100,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: ({ value }) => formatBudgetType(value),
        },
        width: 120,
    })
}

export function campaignTotalBudget<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['total_budget']
    const name = (
        <Translation>
            {(t) => t('table:fields.campaignTotalBudget.name', 'Total Budget')}
        </Translation>
    )
    return createCurrencyField({
        ...options,
        id: 'campaign_total_budget',
        name,
        shortName: name,
        minWidth: 100,
        dataIndex,
        currencyCode: 'USD',
        width: 120,
        align: 'right',
    })
}

export function campaignDailyBudget<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['daily_budget']
    const name = (
        <Translation>
            {(t) => t('table:fields.campaignDailyBudget.name', 'Daily Budget')}
        </Translation>
    )
    return createCurrencyField({
        ...options,
        id: 'campaign_daily_budget',
        name,
        shortName: name,
        minWidth: 100,
        dataIndex,
        currencyCode: 'USD',
        align: 'right',
        width: 120,
    })
}
