import { call, select } from 'redux-saga/effects'

import {
    fetchOrganizationGroupsSuccess,
    fetchOrganizationGroupSuccess,
} from 'actions/orgs'
import { cerebroApiSaga } from 'sagas/common'
import { selectOrganizationGroup } from 'selectors/orgs'
import {
    getOrganizationGroup,
    getOrganizationGroups,
} from 'services/cerebroApi/orgScope/resourceApi'

export function* fetchOrganizationGroupSaga(organizationGroupId) {
    const group = yield select(selectOrganizationGroup, organizationGroupId)

    if (!group) {
        yield call(
            cerebroApiSaga,
            fetchOrganizationGroupSuccess,
            getOrganizationGroup,
            organizationGroupId
        )
    }
}

export function* fetchOrganizationGroupsSaga(organizationId) {
    yield call(
        cerebroApiSaga,
        fetchOrganizationGroupsSuccess,
        getOrganizationGroups,
        organizationId
    )
}

export function* fetchOrganizationGroupsWorker({
    payload: { organizationId },
}) {
    yield call(fetchOrganizationGroupsSaga, organizationId)
}
