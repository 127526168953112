import toInteger from 'lodash/toInteger'
import { Moment } from 'moment-timezone'

import { HOURLY_MULTIPLIER_BLOCKS } from 'configuration/timeParting'
import { AUTOMATION_KEYS } from 'const/automations'
import { ENABLED } from 'const/resourceStates'
import {
    AutomationProfile,
    HourlyMultiplierBlock,
    ABCAutomationProfile,
    TimePartingAutomationProfile,
    AutomationTask,
    ABCAutomationTask,
    CampaignState,
} from 'types'
import moment from 'utilities/moment'

interface DayIndex {
    Mo: number
    Tu: number
    We: number
    Th: number
    Fr: number
    Sa: number
    Su: number
    [id: string]: number
}

const DAY_INDEX: DayIndex = {
    Mo: 0,
    Tu: 1,
    We: 2,
    Th: 3,
    Fr: 4,
    Sa: 5,
    Su: 6,
}

type Multipliers = {
    daily_multiplier?: number[]
    hourly_multiplier?: number[]
}

interface EffectiveMultiplier {
    effectiveTimeFormatted?: string
    timezone?: string
    effectiveHourlyMultiplier?: number
    effectiveDailyMultiplier?: number
    multiplier?: number
}

/**
 * Give Time Parting multipliers and an associated timezone, return an object
 * with information about the "active" multiplier.
 *
 * @param multipliers the multipliers that are applied to the campaign
 * @param timezone the timezone of the multipliers
 * @param currentTimeUTC a moment object that represents the current time in UTC
 * @returns {*} object with information about the effect hour and multiplier
 */
export const getEffectiveMultiplier = (
    multipliers: Multipliers,
    timezone: string,
    currentTimeUTC: Moment
): EffectiveMultiplier => {
    if (!multipliers) {
        return {}
    }

    const { daily_multiplier, hourly_multiplier } = multipliers

    if (daily_multiplier && hourly_multiplier && timezone) {
        const effectiveTime = moment.tz(currentTimeUTC, timezone)
        const hourIndex = toInteger(effectiveTime.format('HH'))
        const dayIndex = DAY_INDEX[effectiveTime.format('dd')]

        return {
            effectiveTimeFormatted: effectiveTime
                .clone()
                .startOf('hour')
                .format('dddd, h:mm A'),
            timezone,
            effectiveHourlyMultiplier: hourly_multiplier[hourIndex],
            effectiveDailyMultiplier: daily_multiplier[dayIndex],
            multiplier:
                hourly_multiplier[hourIndex] * daily_multiplier[dayIndex],
        }
    }

    return {}
}

export const getChildLabelIndex = (childLabel: string): number => {
    const allChildLabels = HOURLY_MULTIPLIER_BLOCKS.reduce<string[]>(
        (accumulator, { childLabels }) => {
            accumulator.push(...childLabels)
            return accumulator
        },
        []
    )

    return allChildLabels.findIndex((element) => childLabel === element)
}

export const getHourlyMultiplierBlockFromIndex = (
    parentBlockIndex: number
): HourlyMultiplierBlock => {
    return HOURLY_MULTIPLIER_BLOCKS[parentBlockIndex]
}

export const isArrayUniform = (array: any[]): boolean => {
    if (array.length) {
        return array.every((val, _idx, arr) => val === arr[0])
    }
    return false
}

export const deriveParentHourlyMultipliers = (
    hourlyMultipliers: number[]
): (number | null)[] =>
    HOURLY_MULTIPLIER_BLOCKS.map(({ start, end }) => {
        const hourlyMultiplierSlice = hourlyMultipliers.slice(start, end)
        return isArrayUniform(hourlyMultiplierSlice)
            ? hourlyMultiplierSlice[0]
            : null
    })

export const average = (array: number[]): number =>
    array.reduce((acc, current) => acc + current, 0) / array.length

export function isAbcProfile(
    profile: AutomationProfile
): profile is ABCAutomationProfile {
    return (
        (profile as ABCAutomationProfile).capability ===
        'advanced_budget_control'
    )
}

export function isTimePartingProfile(
    profile: AutomationProfile
): profile is ABCAutomationProfile {
    return (
        (profile as TimePartingAutomationProfile).capability === 'time_parting'
    )
}

export const isHourlyEnabled = (profile: AutomationProfile): boolean =>
    (isAbcProfile(profile) && profile.params.hourly_enabled) ||
    isTimePartingProfile(profile)

export const getAbcIfEnabled = (
    automationTasks: AutomationTask[],
    campaignState: CampaignState
): ABCAutomationTask | null => {
    if (campaignState !== ENABLED) {
        return null
    }

    const abcTaskIndex = automationTasks.findIndex(
        (task) => task.capability.id === AUTOMATION_KEYS.advanced_budget_control
    )

    return abcTaskIndex === -1
        ? null
        : (automationTasks[abcTaskIndex] as ABCAutomationTask)
}
