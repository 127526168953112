import { ReactNode } from 'react'

import { AsinLink } from 'components/Links'
import { ResearchKeywordLink } from 'components/Links/ResearchKeywordLink/ResearchKeywordLink'
import { isASIN, formatASIN } from 'helpers/asin'
import { CampaignGroupBy, CellRenderProps } from 'types'

import styles from './styles.scss'

export function searchTermQueryRenderer<RecordType extends CampaignGroupBy>({
    value,
    record,
}: CellRenderProps<RecordType>): ReactNode {
    return isASIN(value) ? (
        <AsinLink
            asin={formatASIN(value)}
            countryCode={record.campaign.profile.country_code}
        />
    ) : (
        <div className={styles.field}>
            <span className={styles.text}>{value}</span>
            <ResearchKeywordLink
                countryCode={record.campaign.profile.country_code}
                keyword={value}
            />
        </div>
    )
}
