import {
    advertiserAccountName,
    advertiserId,
    advertiserIntegrationType,
    advertiserType,
} from 'configuration/fieldCreators/walmartAdvertiser'
import {
    campaignBudgetType,
    campaignCreationDate,
    campaignDailyBudget,
    campaignEndDate,
    campaignName,
    campaignStartDate,
    campaignState,
    campaignTargetingType,
    campaignTotalBudget,
    campaignType,
} from 'configuration/fieldCreators/walmartCampaign'
import { Field, WalmartCampaignGroupBy } from 'types'

import { WM_ADVERTISING_METRICS_TABLE_COLUMNS } from '../dataSources/walmartAdvertising'

export const walmartCampaignsSummaryTableColumnsConfig: Field<WalmartCampaignGroupBy>[] =
    [
        campaignName({
            fixed: true,
            dataIndex: ['campaign', 'name'],
        }),
        advertiserAccountName({
            alternateId: 'advertiser_alias',
            dataIndex: ['advertiser', 'alias'],
        }),
        campaignType({
            dataIndex: ['campaign', 'campaign_type'],
        }),
        campaignTargetingType({
            alternateId: 'campaign_targeting_type',
            dataIndex: ['campaign', 'targeting_type'],
        }),
        campaignState({
            dataIndex: ['campaign', 'state'],
        }),
        campaignStartDate({
            dataIndex: ['campaign', 'start_date'],
        }),
        campaignEndDate({
            dataIndex: ['campaign', 'end_date'],
        }),
        campaignTotalBudget({
            dataIndex: ['campaign', 'total_budget'],
        }),
        campaignDailyBudget({
            dataIndex: ['campaign', 'daily_budget'],
        }),
        campaignBudgetType({
            dataIndex: ['campaign', 'budget_type'],
        }),
        campaignCreationDate({
            dataIndex: ['campaign', 'created_at'],
        }),
        advertiserType({
            isVisible: false,
            dataIndex: ['advertiser', 'type'],
        }),
        advertiserId({
            isVisible: false,
            dataIndex: ['advertiser', 'advertiser_id'],
        }),
        advertiserIntegrationType({
            isVisible: false,
            dataIndex: ['advertiser', 'read_only'],
        }),
        ...(WM_ADVERTISING_METRICS_TABLE_COLUMNS as Field<WalmartCampaignGroupBy>[]),
    ]
