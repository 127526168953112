import { RumEvent, RumEventDomainContext } from '@datadog/browser-rum'

import { ignoreErrors } from 'configuration/monitoring'
import { isRegEx } from 'helpers/typeGuard'

interface BeforeSendProps {
    event: RumEvent
    context: RumEventDomainContext
}

/**
 * Evaluates if an error should be suppressed from browser monitoring tools (e.g., Datadog)
 */
export const shouldSuppressError = ({
    event,
    context: _context,
}: BeforeSendProps): boolean => {
    if (event.type === 'error') {
        return ignoreErrors.some((matchCriteria) => {
            if (isRegEx(matchCriteria)) {
                return matchCriteria.test(event.error.message)
            }
            return event.error.message.includes(matchCriteria)
        })
    }
    return false
}
