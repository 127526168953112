import { ReactElement } from 'react'

import { Translation } from 'react-i18next'

interface Props {
    text: string | ReactElement<typeof Translation>
    type?: 'default' | 'active' | 'primary' | 'warning' | 'success'
}
export default function DetailTag({
    text,
    type = 'default',
}: Props): ReactElement {
    return <div className={`fg-${type}-tag`}>{text}</div>
}
