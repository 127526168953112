import { useCallback, useEffect, useState } from 'react'

import { useFormikContext } from 'formik'

import { useCerebroApiRequest } from 'hooks'
import { getAdGroups } from 'services/cerebroApi/orgScope/resourceApi'
import { AdGroup, CerebroPaginatedResponse } from 'types'

import { AdGroupSelectFieldProps } from '../localTypes'

interface Option {
    value: string
    label: string
    metadata: string
    disabled: boolean
}

type AdGroupOptions = [boolean, Option[]]

interface Props {
    name: AdGroupSelectFieldProps['name']
    campaignId: AdGroupSelectFieldProps['campaignId']
    isOptionDisabled: Required<AdGroupSelectFieldProps>['isOptionDisabled']
    optionMetadata: Required<AdGroupSelectFieldProps>['optionMetadata']
}

const useAdGroupOptions = ({
    name,
    campaignId,
    isOptionDisabled,
    optionMetadata,
}: Props): AdGroupOptions => {
    const makeCerebroApiRequest = useCerebroApiRequest()

    const [isFetching, setFetching] = useState(false)

    const [adGroups, setAdGroups] = useState<AdGroup[]>([])

    const { setFieldTouched, setFieldValue } = useFormikContext()

    const fetchAdGroups = useCallback(async (): Promise<void> => {
        if (campaignId) {
            setFetching(true)
            await makeCerebroApiRequest<
                AdGroup,
                CerebroPaginatedResponse<AdGroup>
            >({
                request: getAdGroups({
                    campaign: campaignId,
                    'state!': 'archived',
                }),
                onRequestSuccess: ({ data }) => {
                    setAdGroups(data.results)
                    setFetching(false)
                },
            })
        }
    }, [makeCerebroApiRequest, campaignId])

    useEffect(() => {
        setFieldValue(name, '')
        setFieldTouched(name, false)
        setAdGroups([])
        fetchAdGroups()
    }, [name, setFieldTouched, setFieldValue, fetchAdGroups])

    const options: Option[] = adGroups.map((adGroup) => {
        const { id, name: adGroupName } = adGroup
        return {
            value: id,
            label: adGroupName,
            metadata: optionMetadata(adGroup),
            disabled: isOptionDisabled(adGroup),
        }
    })

    return [isFetching, options]
}

export default useAdGroupOptions
