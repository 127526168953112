import { ReactElement } from 'react'

import { ExclamationCircleOutlined } from '@ant-design/icons'

import styles from './styles.scss'

interface Props {
    level?: 'danger' | 'warning'
}

export default function WarningIcon({
    level = 'warning',
}: Props): ReactElement {
    const colorClass = level === 'danger' ? styles.danger : styles.warn
    return (
        <ExclamationCircleOutlined
            className={`${colorClass} ${styles['warning-icon']}`}
        />
    )
}
