import { SVGAttributes, ReactElement } from 'react'

type Props = SVGAttributes<SVGElement>

function SvgCheckIcon(props: Props): ReactElement {
    return (
        <svg
            viewBox="0 0 56 56"
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            fill="currentColor"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M52.22 10.877a2.84 2.84 0 01-.094 4.006l-29.57 28.333a2.815 2.815 0 01-3.897 0L3.874 29.05a2.84 2.84 0 01-.093-4.005 2.815 2.815 0 013.99-.093L20.609 37.25l27.62-26.466a2.815 2.815 0 013.991.093z"
            />
        </svg>
    )
}

export default SvgCheckIcon
