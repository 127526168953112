import {
    ad_contribution_14_day_ordered_revenue,
    ad_contribution_14_day_same_sku_ordered_revenue,
    ad_contribution_14_day_same_sku_shipped_cogs,
    ad_contribution_14_day_same_sku_shipped_revenue,
    ad_contribution_14_day_shipped_cogs,
    ad_contribution_14_day_shipped_revenue,
    advertising_cost_of_ordered_revenue,
    advertising_cost_of_shipped_cogs,
    advertising_cost_of_shipped_revenue,
    age_90_sellable_inventory__sum,
    age_90_sellable_units__sum,
    aged_90_plus_days_sellable_inventory__last_value,
    aged_90_plus_days_sellable_inventory_cost__last_value,
    aged_90_plus_days_sellable_inventory_units__last_value,
    aged_90_plus_days_sellable_units__last_value,
    average_sales_price__avg,
    average_unit_price_ordered_revenue__avg,
    average_unit_price_shipped_revenue__avg,
    average_vendor_lead_time_days,
    conversion_percent,
    conversion_rate_glances,
    conversion_rate_shipped_glances,
    cost__sum,
    customer_returns__sum,
    deprecated_customer_returns__sum,
    fast_track_gv,
    free_replacements__sum,
    glance_view__sum,
    glance_views__sum,
    lbb__avg,
    manufacturer_advertising_cost_of_total_sales,
    manufacturer_total_advertising_cost_of_sales,
    net_ppm__sum,
    net_received_amount__sum,
    net_received_units__sum,
    net_received_value__sum,
    open_purchase_order_quantity__last_value,
    open_purchase_order_quantity__sum,
    open_purchase_order_units__last_value,
    ordered_revenue__sum,
    ordered_units__sum,
    organic_sales_14_day_ordered_revenue__sum,
    organic_sales_14_day_same_sku_ordered_revenue__sum,
    organic_sales_14_day_same_sku_shipped_cogs__sum,
    organic_sales_14_day_same_sku_shipped_revenue__sum,
    organic_sales_14_day_shipped_cogs__sum,
    organic_sales_14_day_shipped_revenue__sum,
    organic_sales_1_day_same_sku_ordered_revenue__sum,
    organic_sales_1_day_same_sku_shipped_revenue__sum,
    percentage_of_total_gv,
    procurable_product_out_of_stock_rate,
    receive_fill_rate,
    rep_oos__avg,
    rep_oos_of_total__avg,
    sell_through_rate,
    sellable_on_hand_inventory__last_value,
    sellable_on_hand_inventory__sum,
    sellable_on_hand_inventory_cost__last_value,
    sellable_on_hand_inventory_units__last_value,
    sellable_on_hand_units__last_value,
    sellable_on_hand_units__sum,
    shipped_cogs__sum,
    shipped_revenue__sum,
    shipped_units__sum,
    sourceable_product_out_of_stock_rate,
    sourcing_advertising_cost_of_total_sales,
    sourcing_total_advertising_cost_of_sales,
    sub_category_sales_rank__avg,
    unfilled_customer_ordered_units__last_value,
    unhealthy_inventory__last_value,
    unhealthy_inventory__sum,
    unhealthy_units__last_value,
    unhealthy_units__sum,
    unsellable_on_hand_inventory__last_value,
    unsellable_on_hand_inventory__sum,
    unsellable_on_hand_inventory_cost__last_value,
    unsellable_on_hand_inventory_units__last_value,
    unsellable_on_hand_units__last_value,
    unsellable_on_hand_units__sum,
    vendor_confirmation_rate,
} from 'configuration/fieldCreators/metrics/vendorCentral'
import { productTitle } from 'configuration/fieldCreators/vendorCentral'

import { AMS_WITH_SD_METRICS_COLUMNS_NO_PACING } from './ams'
import { makeMapping } from './helpers'

const VC_COMMON_METRICS_COLUMNS = [
    average_sales_price__avg({ isVisible: true }),
    shipped_units__sum({ isVisible: true }),
    deprecated_customer_returns__sum({ isVisible: true }),
    free_replacements__sum({ isVisible: true }),
    shipped_cogs__sum({ isVisible: true }),
    shipped_revenue__sum({ isVisible: true }),
    cost__sum({ isVisible: true }),
    advertising_cost_of_shipped_cogs({ isVisible: true }),
    advertising_cost_of_shipped_revenue({ isVisible: true }),
]

const VC_SOURCE_METRICS_COLUMNS = [
    ...VC_COMMON_METRICS_COLUMNS,
    sourcing_total_advertising_cost_of_sales({ isVisible: false }),
    sourcing_advertising_cost_of_total_sales({ isVisible: false }),
]

const VC_MANUFACTURER_METRICS_COLUMNS = [
    ...VC_COMMON_METRICS_COLUMNS,
    advertising_cost_of_ordered_revenue({ isVisible: true }),
    ordered_revenue__sum({ isVisible: true }),
    glance_view__sum({ isVisible: true }),
    ordered_units__sum({ isVisible: true }),
    sub_category_sales_rank__avg({ isVisible: true }),
    rep_oos__avg({ isVisible: true }),
    rep_oos_of_total__avg({ isVisible: true }),
    lbb__avg({ isVisible: true }),
    manufacturer_total_advertising_cost_of_sales({
        isVisible: false,
    }),
    manufacturer_advertising_cost_of_total_sales({
        isVisible: false,
    }),
    ad_contribution_14_day_shipped_revenue({ isVisible: true }),
    ad_contribution_14_day_same_sku_shipped_revenue({
        isVisible: true,
    }),
    organic_sales_14_day_shipped_revenue__sum({ isVisible: true }),
    organic_sales_1_day_same_sku_shipped_revenue__sum({
        isVisible: true,
    }),
    organic_sales_14_day_same_sku_shipped_revenue__sum({
        isVisible: true,
    }),
    ad_contribution_14_day_shipped_cogs({ isVisible: true }),
    ad_contribution_14_day_same_sku_shipped_cogs({ isVisible: true }),
    organic_sales_14_day_shipped_cogs__sum({ isVisible: true }),
    organic_sales_14_day_same_sku_shipped_cogs__sum({
        isVisible: true,
    }),
    ad_contribution_14_day_ordered_revenue({ isVisible: true }),
    ad_contribution_14_day_same_sku_ordered_revenue({
        isVisible: true,
    }),
    organic_sales_14_day_ordered_revenue__sum({ isVisible: true }),
    organic_sales_1_day_same_sku_ordered_revenue__sum({
        isVisible: true,
    }),
    organic_sales_14_day_same_sku_ordered_revenue__sum({
        isVisible: true,
    }),
]

const VC_API_SALES_DIAGNOSTIC_METRICS_COLUMNS = [
    ordered_units__sum({ isVisible: true }),
    ordered_revenue__sum({ isVisible: true }),
    average_unit_price_ordered_revenue__avg({ isVisible: true }),
    shipped_units__sum({ isVisible: true }),
    shipped_revenue__sum({ isVisible: true }),
    average_unit_price_shipped_revenue__avg({ isVisible: true }),
    shipped_cogs__sum({ isVisible: true }),
    customer_returns__sum({ isVisible: true }),
    glance_views__sum({ isVisible: true }),
    conversion_rate_glances({ isVisible: true }),
    conversion_rate_shipped_glances({ isVisible: true }),
    cost__sum({ isVisible: true }),
    advertising_cost_of_shipped_revenue({ isVisible: true }),
    ad_contribution_14_day_shipped_revenue({ isVisible: true }),
    ad_contribution_14_day_same_sku_shipped_revenue({
        isVisible: true,
    }),
    organic_sales_14_day_shipped_revenue__sum({ isVisible: true }),
    organic_sales_1_day_same_sku_shipped_revenue__sum({
        isVisible: true,
    }),
    organic_sales_14_day_same_sku_shipped_revenue__sum({
        isVisible: true,
    }),
    advertising_cost_of_ordered_revenue({ isVisible: true }),
    ad_contribution_14_day_ordered_revenue({ isVisible: true }),
    ad_contribution_14_day_same_sku_ordered_revenue({
        isVisible: true,
    }),
    organic_sales_14_day_ordered_revenue__sum({ isVisible: true }),
    organic_sales_1_day_same_sku_ordered_revenue__sum({
        isVisible: true,
    }),
    organic_sales_14_day_same_sku_ordered_revenue__sum({
        isVisible: true,
    }),
    advertising_cost_of_shipped_cogs({ isVisible: true }),
    net_ppm__sum({ isVisible: true }),
    ...AMS_WITH_SD_METRICS_COLUMNS_NO_PACING,
]

const VC_API_SALES_DIAGNOSTIC_SOURCING_METRICS_COLUMNS = [
    shipped_units__sum({ isVisible: true }),
    shipped_revenue__sum({ isVisible: true }),
    average_unit_price_shipped_revenue__avg({ isVisible: true }),
    shipped_cogs__sum({ isVisible: true }),
    customer_returns__sum({ isVisible: true }),
    glance_views__sum({ isVisible: true }),
    conversion_rate_shipped_glances({ isVisible: true }),
    net_ppm__sum({ isVisible: true }),
    cost__sum({ isVisible: true }),
    advertising_cost_of_shipped_revenue({ isVisible: true }),
    ad_contribution_14_day_shipped_revenue({ isVisible: true }),
    ad_contribution_14_day_same_sku_shipped_revenue({
        isVisible: true,
    }),
    organic_sales_14_day_shipped_revenue__sum({ isVisible: true }),
    organic_sales_1_day_same_sku_shipped_revenue__sum({
        isVisible: true,
    }),
    organic_sales_14_day_same_sku_shipped_revenue__sum({
        isVisible: true,
    }),
    advertising_cost_of_shipped_cogs({ isVisible: true }),
    ...AMS_WITH_SD_METRICS_COLUMNS_NO_PACING,
]

const VC_TRAFFIC_DIAGNOSTIC_METRICS_COLUMNS = [
    glance_view__sum({ isVisible: true }),
    fast_track_gv({ isVisible: true }),
    conversion_percent({ isVisible: true }),
    percentage_of_total_gv({ isVisible: true }),
]

const VC_INVENTORY_HEALTH_COMMON_METRICS_COLUMNS = [
    net_received_units__sum({ isVisible: true }),
    open_purchase_order_quantity__sum({ isVisible: true }),
    sellable_on_hand_units__sum({ isVisible: true }),
    unsellable_on_hand_units__sum({ isVisible: true }),
    age_90_sellable_units__sum({ isVisible: true }),
    unhealthy_units__sum({ isVisible: true }),
    sellable_on_hand_inventory__sum({ isVisible: true }),
    unsellable_on_hand_inventory__sum({ isVisible: true }),
    age_90_sellable_inventory__sum({ isVisible: true }),
    unhealthy_inventory__sum({ isVisible: true }),
    sell_through_rate({ isVisible: true }),
]

const VC_INVENTORY_HEALTH_LEGACY_METRICS_COLUMNS = [
    ...VC_INVENTORY_HEALTH_COMMON_METRICS_COLUMNS,
    net_received_value__sum({ isVisible: true }),
]

const VC_INVENTORY_HEALTH_METRICS_COLUMNS = [
    ...VC_INVENTORY_HEALTH_COMMON_METRICS_COLUMNS,
    net_received_amount__sum({ isVisible: true }),
]

const VC_API_INVENTORY_HEALTH_METRICS_COLUMNS = [
    net_received_units__sum({ isVisible: true }),
    net_received_amount__sum({ isVisible: true }),
    open_purchase_order_quantity__last_value({ isVisible: true }),
    sellable_on_hand_inventory__last_value({ isVisible: true }),
    sellable_on_hand_units__last_value({ isVisible: true }),
    unsellable_on_hand_inventory__last_value({ isVisible: true }),
    unsellable_on_hand_units__last_value({ isVisible: true }),
    aged_90_plus_days_sellable_inventory__last_value({
        isVisible: true,
    }),
    aged_90_plus_days_sellable_units__last_value({ isVisible: true }),
    unhealthy_inventory__last_value({ isVisible: true }),
    unhealthy_units__last_value({ isVisible: true }),
    ...AMS_WITH_SD_METRICS_COLUMNS_NO_PACING,
]

const VC_API_INVENTORY_SOURCING_METRICS_COLUMNS = [
    open_purchase_order_units__last_value({ isVisible: true }),
    sellable_on_hand_inventory_cost__last_value({ isVisible: true }),
    sellable_on_hand_inventory_units__last_value({ isVisible: true }),
    unsellable_on_hand_inventory_cost__last_value({
        isVisible: true,
    }),
    unsellable_on_hand_inventory_units__last_value({
        isVisible: true,
    }),
    aged_90_plus_days_sellable_inventory_cost__last_value({
        isVisible: true,
    }),
    aged_90_plus_days_sellable_inventory_units__last_value({
        isVisible: true,
    }),
    average_vendor_lead_time_days({ isVisible: true }),
    receive_fill_rate({ isVisible: true }),
    vendor_confirmation_rate({ isVisible: true }),
]

const VC_API_INVENTORY_MANUFACTURING_METRICS_COLUMNS = [
    ...VC_API_INVENTORY_SOURCING_METRICS_COLUMNS,
    unfilled_customer_ordered_units__last_value({ isVisible: true }),
    procurable_product_out_of_stock_rate({ isVisible: true }),
    sourceable_product_out_of_stock_rate({
        isVisible: true,
        releaseDate: '2024-01-10',
    }),
]

export const VC_API_INVENTORY_MANUFACTURING_METRICS_CONFIG = makeMapping(
    VC_API_INVENTORY_MANUFACTURING_METRICS_COLUMNS
)
export const VC_API_INVENTORY_SOURCING_METRICS_CONFIG = makeMapping(
    VC_API_INVENTORY_SOURCING_METRICS_COLUMNS
)

export const VC_SOURCE_METRICS_CONFIG = makeMapping(VC_SOURCE_METRICS_COLUMNS)
export const VC_MANUFACTURER_METRICS_CONFIG = makeMapping(
    VC_MANUFACTURER_METRICS_COLUMNS
)
export const VC_TRAFFIC_DIAGNOSTIC_METRICS_CONFIG = makeMapping(
    VC_TRAFFIC_DIAGNOSTIC_METRICS_COLUMNS
)
export const VC_INVENTORY_HEALTH_LEGACY_METRICS_CONFIG = makeMapping(
    VC_INVENTORY_HEALTH_LEGACY_METRICS_COLUMNS
)
export const VC_INVENTORY_HEALTH_METRICS_CONFIG = makeMapping(
    VC_INVENTORY_HEALTH_METRICS_COLUMNS
)
export const VC_API_SALES_DIAGNOSTIC_METRICS_CONFIG = makeMapping(
    VC_API_SALES_DIAGNOSTIC_METRICS_COLUMNS
)
export const VC_API_SALES_DIAGNOSTIC_SOURCING_METRICS_CONFIG = makeMapping(
    VC_API_SALES_DIAGNOSTIC_SOURCING_METRICS_COLUMNS
)
export const VC_API_INVENTORY_HEALTH_METRICS_CONFIG = makeMapping(
    VC_API_INVENTORY_HEALTH_METRICS_COLUMNS
)

export const SPECIAL_VC_GROUP_BY_COLUMNS = [
    // This does not have any special rendering
    // BUT it uses a group by alias for the ID
    // so it is included in the special group by columns
    // to ensure the ID used here is included in the group by column list
    productTitle(),
]

export const SPECIAL_VC_API_GROUP_BY_COLUMNS = [
    // no VC_API_GROUP_BY_COLUMNS do special rendering
]
