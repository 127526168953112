import { QuestionCircleOutlined } from '@ant-design/icons'
import { Translation } from 'react-i18next'

import { ToolTip } from 'components/ToolTip'
import { UNDEFINED_VALUE } from 'const/formatting'
import { titleCase } from 'helpers/formatting'
import { Field } from 'types'

import { createField, createNumberField } from './createField'
import { FieldCreatorOptions } from './localTypes'

export function organizationName<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['name']
    const name = (
        <Translation>
            {(t) => t('table:fields.organization.name.name', 'Name')}
        </Translation>
    )
    return createField({
        ...options,
        id: 'name',
        dataIndex,
        name,
        shortName: name,
        minWidth: 175,
        fixed: options.fixed ?? true,
    })
}

export function ownerFullName<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['owner']
    const name = (
        <Translation>
            {(t) => t('table:fields.organization.owner.name', 'Org Owner')}
        </Translation>
    )
    return createField({
        ...options,
        id: 'owner',
        dataIndex,
        name,
        shortName: name,
        minWidth: 128,
        renderOptions: {
            render: ({ value }) => {
                if (value?.first_name || value?.last_name) {
                    return titleCase(`${value.first_name} ${value.last_name}`)
                }
                return value?.email ?? value?.username ?? UNDEFINED_VALUE
            },
        },
    })
}

export function groupCount<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['group_count']
    const name = (
        <Translation>
            {(t) => t('table:fields.organization.group_count.name', 'Groups')}
        </Translation>
    )
    return createNumberField({
        ...options,
        id: 'group_count',
        dataIndex,
        name,
        shortName: name,
        minWidth: 108,
    })
}

export function memberCount<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['member_count']
    const name = (
        <Translation>
            {(t) => t('table:fields.organization.member_count.name', 'Members')}
        </Translation>
    )
    return createNumberField({
        ...options,
        id: 'member_count',
        dataIndex,
        name,
        shortName: name,
        minWidth: 108,
    })
}

export function sovCapCount<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['sov_keyword_cap']
    const name = (
        <Translation>
            {(t) =>
                t(
                    'table:fields.organization.sov_keyword_cap.name',
                    'SOV Keyword Cap'
                )
            }
        </Translation>
    )
    return createNumberField({
        ...options,
        id: 'sov_keyword_cap',
        dataIndex,
        name,
        shortName: name,
        minWidth: 147,
    })
}

export function sovEnabledCount<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['sov_keyword_used']
    const name = (
        <Translation>
            {(t) =>
                t(
                    'table:fields.organization.sov_keyword_used.name',
                    'SOV Keywords Enabled'
                )
            }
        </Translation>
    )
    return createNumberField({
        ...options,
        id: 'sov_keyword_used',
        dataIndex,
        name,
        shortName: name,
        minWidth: 171,
    })
}

export function adAcountsCount<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['ad_account_count']
    const name = (
        <Translation>
            {(t) =>
                t(
                    'table:fields.organization.ad_account_count.name',
                    'Ad Accounts'
                )
            }
        </Translation>
    )
    return createNumberField({
        ...options,
        id: 'ad_account_count',
        dataIndex,
        name,
        shortName: name,
        minWidth: 112,
    })
}

export function SPIntegrationsCount<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['seller_partner_integration_count']
    const name = (
        <Translation>
            {(t) =>
                t(
                    'table:fields.organization.sp_integration_count.name',
                    'Seller Partner Integrations'
                )
            }
        </Translation>
    )
    return createNumberField({
        ...options,
        id: 'seller_partner_integration_count',
        dataIndex,
        name,
        shortName: name,
        minWidth: 212,
        columnTitle: (
            <>
                {name}
                <ToolTip
                    title={
                        <Translation>
                            {(t) =>
                                t(
                                    'table:fields.organization.sp_integration_count.title_tooltip',
                                    'There could be duplicate seller partner integrations from different ad accounts'
                                )
                            }
                        </Translation>
                    }
                >
                    <QuestionCircleOutlined style={{ paddingLeft: 8 }} />
                </ToolTip>
            </>
        ),
    })
}

export function walmartMarketplaceIntegrationCount<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? [
        'walmart_marketplace_integration_count',
    ]
    const name = (
        <Translation>
            {(t) =>
                t(
                    'table:fields.organization.walmart_marketplace_integration_count.name',
                    'Walmart Marketplace Integrations'
                )
            }
        </Translation>
    )
    return createNumberField({
        ...options,
        id: 'walmart_marketplace_integration_count',
        dataIndex,
        name,
        shortName: name,
        minWidth: 190,
    })
}

export function walmartAdIntegrationCount<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['walmart_advertiser_count']
    const name = (
        <Translation>
            {(t) =>
                t(
                    'table:fields.organization.walmart_advertiser_count.name',
                    'Walmart Advertising Integrations'
                )
            }
        </Translation>
    )
    return createNumberField({
        ...options,
        id: 'walmart_advertiser_count',
        dataIndex,
        name,
        shortName: name,
        minWidth: 246,
    })
}
