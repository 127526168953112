import { ReactElement } from 'react'

import { titleCase } from 'helpers/formatting'
import { DspLineItem } from 'types'

import DetailTag from './shared/DetailTag'
import { DetailText } from './shared/DetailText'
import { DetailWrapper } from './shared/DetailWrapper'

export function lineItemTypeRenderer(lineItem: DspLineItem): ReactElement {
    const text = titleCase(lineItem.line_item_type)
    return (
        <DetailWrapper
            leftContent={<DetailTag text="Line Item Type" />}
            rightContent={<DetailText text={text} />}
        />
    )
}
