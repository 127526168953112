import { SVGAttributes, ReactElement } from 'react'

type Props = SVGAttributes<SVGElement>

function SvgOrganizationIcon(props: Props): ReactElement {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M15.779 16.833V2.189L4.242 1V16.833H3V18H17V16.833H15.779ZM12.242 6.261H14.052V8.086H12.242V6.261ZM12.242 9.423H14.052V11.248H12.242V9.423ZM12.242 12.585H14.052V14.411H12.242V12.585ZM9.105 6.262H10.915V8.087H9.105V6.262ZM9.105 9.424H10.915V11.249H9.105V9.424ZM5.968 6.262H7.78V8.087H5.97L5.968 6.262ZM5.968 9.424H7.78V11.249H5.97L5.968 9.424ZM5.968 12.586H7.78V14.412H5.97L5.968 12.586ZM9.105 12.586H10.915V15.834H9.105V12.586Z" />
        </svg>
    )
}

export default SvgOrganizationIcon
