import { ReactElement, FunctionComponent } from 'react'

import { type TooltipProps } from 'antd'
import { Translation } from 'react-i18next'

import { ConditionalWrapper } from 'components/ConditionalWrapper'
import { IconProps } from 'components/Icons/localTypes'
import { ToolTip } from 'components/ToolTip'

interface Props {
    icon: FunctionComponent<IconProps>
    tooltipTitle?: string | ReactElement<typeof Translation>
    tooltipPlacement?: TooltipProps['placement']
}
export default function DetailIcon({
    icon: Icon,
    tooltipTitle,
    tooltipPlacement = 'top',
}: Props): ReactElement {
    return (
        <ConditionalWrapper
            condition={Boolean(tooltipTitle)}
            wrapper={(children) => (
                <ToolTip
                    title={tooltipTitle}
                    arrow={{ pointAtCenter: true }}
                    placement={tooltipPlacement}
                >
                    {children}
                </ToolTip>
            )}
        >
            <Icon
                // using inline styles to take precedence over ant styles
                style={{
                    color: '#d6d9dc',
                    fontSize: '20px',
                }}
            />
        </ConditionalWrapper>
    )
}
