import { Translation } from 'react-i18next'

import UserEmail from 'components/FieldRenderers/Renderers/UserEmail'
import { Field } from 'types'

import { createField } from './createField'
import { FieldCreatorOptions } from './localTypes'

export function userId<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['user', 'email']
    const name = (
        <Translation>{(t) => t('common:createdBy', 'Created By')}</Translation>
    )

    return createField({
        ...options,
        id: 'user_id',
        name,
        shortName: name,
        minWidth: 100,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: ({ value }) => <UserEmail email={value} />,
        },
        width: 200,
    })
}
