import forEach from 'lodash/fp/forEach'
import get from 'lodash/fp/get'
import set from 'lodash/set'

type ForEachWithConvert = {
    convert: (args: { cap: boolean }) => any
}

const forEachWithKey = (forEach as unknown as ForEachWithConvert).convert({
    cap: false,
})

class SagasRegistry {
    _sagasMap: object

    constructor() {
        this._sagasMap = {}
    }

    /**
     * Registers saga
     *
     * @param {string[]} path - the path of saga domain in redux store
     * @param {object} sagas - a map of saga names to sagas
     *
     * @returns {void}
     */
    registerSagas(path: string[], sagas: object): void {
        forEachWithKey((value: unknown, saga: string) => {
            return set(this._sagasMap, [...path, saga], value)
        }, sagas)
    }

    /**
     * Look up saga
     *
     * @param {string[]} path path of saga domain in redux store
     * @param {string} type Saga type
     *
     * @returns {function|function[]} saga found
     */
    lookupSaga(
        path: string[],
        type: string
    ): ((...args: unknown[]) => unknown) | undefined {
        return get([...path, type], this._sagasMap)
    }
}

export default new SagasRegistry()
