import { ReactElement } from 'react'

import { Radio, type RadioProps } from 'antd'
import { FieldProps } from 'formik'

import { HandlerProps } from '../localTypes'
import withCustomHandlers from '../withCustomHandlers'

type Props<V, FormValues> = FieldProps<V, FormValues> &
    HandlerProps &
    RadioProps

const FormikRadioGroup = <V, FormValues>({
    field,
    form,
    handleChange,
    handleBlur,
    handleClick,
    ...props
}: Props<V, FormValues>): ReactElement => (
    <Radio.Group
        name={field.name}
        value={field.value}
        checked={field.checked}
        onChange={field.onChange}
        {...props}
    />
)

export default withCustomHandlers(FormikRadioGroup)
