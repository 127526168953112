import { SVGAttributes, ReactElement } from 'react'

type Props = SVGAttributes<SVGElement>

function SvgCloseIcon(props: Props): ReactElement {
    return (
        <svg
            viewBox="0 0 56 56"
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            fill="currentColor"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.829 9.829a2.83 2.83 0 014.002 0L28 23.999l14.168-14.17a2.83 2.83 0 114.002 4.002L32.002 28l14.17 14.17a2.83 2.83 0 11-4.003 4.001L28 32.001l-14.17 14.17A2.83 2.83 0 119.83 42.17L23.999 28 9.828 13.83a2.83 2.83 0 010-4.001z"
            />
        </svg>
    )
}

export default SvgCloseIcon
