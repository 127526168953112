import { ReactElement, ReactNode } from 'react'

import {
    LoadingOutlined,
    DownloadOutlined,
    CloseCircleOutlined,
} from '@ant-design/icons'
import { Badge } from 'antd'
import { TFunction } from 'i18next'

import { IconButton } from 'components/Buttons'
import { DAY_FORMAT } from 'const/formatting'
import { PENDING_STATES, ERROR_STATES } from 'const/reports'
import { getDateRange, presetRanges } from 'helpers/dateRange'
import { titleCase } from 'helpers/formatting'
import { Report } from 'types'

import { isPresetDateRange } from './typeGuard'

export const isReportPending = (report: Report): boolean =>
    PENDING_STATES.indexOf(report.state) > -1

const isReportErrored = (report: Report): boolean =>
    ERROR_STATES.indexOf(report.state) > -1

const isReportComplete = (report: Report): boolean =>
    report.state === 'complete'

export const renderReportDateRange = <T extends Report>(
    record: T
): ReactNode => {
    if (record.request_params) {
        const {
            report_date_min,
            report_date_max,
            search_time_min,
            search_time_max,
            date_range,
        } = record.request_params

        if (report_date_min && report_date_max) {
            return getDateRange([report_date_min, report_date_max], DAY_FORMAT)
        }

        if (search_time_min && search_time_max) {
            return getDateRange([search_time_min, search_time_max], DAY_FORMAT)
        }

        if (date_range) {
            if (date_range === 'dashboard_date') {
                return 'Dashboard Date Range(s)'
            }

            if (isPresetDateRange(date_range) && presetRanges()[date_range]) {
                return getDateRange(date_range, DAY_FORMAT, record.created_date)
            }

            return getDateRange(date_range.split(','), DAY_FORMAT)
        }
    }

    return null
}

export const renderReportActions = (
    record: Report,
    newReports: Report[],
    downloadReport: (payload: { reportId: string }) => void,
    t: TFunction
): ReactElement | null => {
    let button = null

    if (isReportPending(record)) {
        button = (
            <IconButton
                icon={<LoadingOutlined />}
                tooltipTitle={titleCase(record.state)}
            />
        )
    }

    if (isReportComplete(record)) {
        button = (
            <IconButton
                icon={<DownloadOutlined />}
                href={record.download_url}
                onClick={() => downloadReport({ reportId: record.id })}
            />
        )
    }

    if (isReportErrored(record)) {
        button = (
            <IconButton
                icon={<CloseCircleOutlined />}
                tooltipTitle={t('common:reportFailed', 'Report Failed')}
                danger
                type="link"
            />
        )
    }

    // Add a badge to new reports
    if (newReports.some((report) => report.id === record.id)) {
        return <Badge dot>{button}</Badge>
    }

    return button
}
