import { memo, ReactElement } from 'react'

import { Alert, Button, Divider, Modal, Tooltip } from 'antd'
import { AxiosResponse } from 'axios'
import isEqual from 'lodash/isEqual'
import { Trans, useTranslation } from 'react-i18next'

import { userHasOrgAdminPermissions } from 'helpers/featurePermissions'
import { formatCerebroDateTime, titleCase } from 'helpers/formatting'
import { useCerebroApiRequest, useModal, useUserHasPermissions } from 'hooks'
import {
    initiateSellerCentralIntegrationUpdate,
    updateIntegration,
} from 'services/cerebroApi/orgScope/resourceApi'
import { Integration, SellingPartnerIntegration } from 'types'
import message from 'utilities/message'
import SCIntegrationUpdateInviteForm from 'views/OrganizationPage/Tables/SCIntegrationsTable/SCIntegrationUpdateInviteForm'

import { DeleteField } from '../SharedFields/DeleteField'

interface Props<RecordType> {
    readonly: boolean
    record: RecordType
    reloadData: () => void
    updateRequestApi?: (...args: any) => Promise<AxiosResponse>
}

function IntegrationActionsField<
    RecordType extends Integration | SellingPartnerIntegration,
>({
    readonly = false,
    record,
    reloadData,
    updateRequestApi = updateIntegration,
}: Props<RecordType>): ReactElement {
    const hasPermission = useUserHasPermissions(userHasOrgAdminPermissions)
    const { t } = useTranslation(['common', 'table'])
    const makeCerebroApiRequest = useCerebroApiRequest()

    const integrationId = record.id

    const isSellingPartnerIntegration = !!Object.hasOwn(
        record,
        'seller_central_account'
    )

    const integrationType = isSellingPartnerIntegration
        ? titleCase(
              (record as SellingPartnerIntegration)?.seller_central_account
                  .integration_type
          )
        : undefined

    const {
        isModalVisible: updateModalVisible,
        toggleModalVisible: toggleUpdateModalVisible,
        onModalCancel: onUpdateModalCancel,
    } = useModal()

    const onUpdateRedirectRequested = async (): Promise<void> => {
        await makeCerebroApiRequest({
            request: initiateSellerCentralIntegrationUpdate(integrationId),
            onRequestSuccess: (response) => {
                message.success(
                    t(
                        'account:SCIntegrationForm.successMsg',
                        'Redirecting to {{integrationType}}...',
                        {
                            integrationType,
                        }
                    )
                )
                window.location.href = response.data.lwa_uri
                toggleUpdateModalVisible()
            },
        })
    }

    return (
        <>
            {isSellingPartnerIntegration && (
                <>
                    <Tooltip
                        title={
                            (record as SellingPartnerIntegration)
                                .authentication_updated_at
                                ? t(
                                      'account:SCIntegrationUpdate.reauthorizedDate',
                                      'Reauthorized on {{ lastAuthorized }}',
                                      {
                                          lastAuthorized: formatCerebroDateTime(
                                              (
                                                  record as SellingPartnerIntegration
                                              ).authentication_updated_at ??
                                                  record.created_at
                                          ),
                                      }
                                  )
                                : t(
                                      'account:SCIntegrationUpdate.authorizedDate',
                                      'Authorized when created on {{ lastAuthorized }}',
                                      {
                                          lastAuthorized: formatCerebroDateTime(
                                              (
                                                  record as SellingPartnerIntegration
                                              ).authentication_updated_at ??
                                                  record.created_at
                                          ),
                                      }
                                  )
                        }
                    >
                        <Button
                            onClick={toggleUpdateModalVisible}
                            style={{ marginRight: 4 }}
                        >
                            Reauthorize
                        </Button>
                    </Tooltip>
                    <Modal
                        title={t(
                            'account:SCIntegrationUpdateForm.title',
                            'Reauthorize Selling Partner Integration'
                        )}
                        footer={
                            <div>
                                <Button
                                    onClick={() => {
                                        onUpdateModalCancel()
                                    }}
                                >
                                    {t('common:cancel', 'Cancel')}
                                </Button>
                            </div>
                        }
                        onCancel={onUpdateModalCancel}
                        open={updateModalVisible}
                    >
                        <div>
                            <Trans i18nKey="account:SCIntegrationUpdateForm.info">
                                <p>
                                    Reauthorizing this integration will update
                                    permissions and ensure that the integration
                                    has access to the latest features.
                                </p>
                            </Trans>
                        </div>
                        <div style={{ marginBottom: 16 }}>
                            <h3>
                                {t(
                                    'account:SCIntegrationUpdateForm.redirect.header',
                                    'Reauthorize with my Account Now'
                                )}
                            </h3>
                            <Trans i18nKey="account:SCIntegrationUpdateForm.redirect.info">
                                <p>
                                    On clicking the button below, you will be
                                    redirected to the {{ integrationType }}{' '}
                                    console where you will authenticate using
                                    your{' '}
                                    <strong>Primary Account Manager</strong>{' '}
                                    credentials.
                                </p>
                            </Trans>
                            <Alert
                                type="warning"
                                message={
                                    <Trans i18nKey="account:SCIntegrationUpdateForm.redirect.warn">
                                        Please sign out of your{' '}
                                        {{ integrationType }}{' '}
                                        <strong>{integrationType}</strong>{' '}
                                        account first, and then click the button
                                        below to reauthorize.
                                    </Trans>
                                }
                            />
                        </div>
                        <Button
                            type="primary"
                            ghost
                            onClick={onUpdateRedirectRequested}
                        >
                            {t(
                                'account:SCIntegrationUpdateForm.redirect.button',
                                'Reauthorize using My Account'
                            )}
                        </Button>
                        <Divider />
                        <h3>
                            {t(
                                'account:SCIntegrationUpdateForm.email.button',
                                'Reauthorize with an Email Link'
                            )}
                        </h3>
                        <SCIntegrationUpdateInviteForm
                            integration={record as SellingPartnerIntegration}
                        />
                    </Modal>
                </>
            )}
            <DeleteField
                disabled={!hasPermission || readonly}
                okText={t('common:delete', 'Delete')}
                title={t(
                    'table:fields.deleteIntegration.confirmTxt',
                    'Are you sure you want to delete this Integration?'
                )}
                updateRequestApi={updateRequestApi}
                updateRequestData={record.id}
                updateRequestParams={{ active: false }}
                updateRequestCallback={reloadData}
                updateRequestSuccessMesg={t(
                    'table:fields.deleteIntegration.successMsg',
                    'Integration successfully deleted'
                )}
            />
        </>
    )
}

export default memo(
    IntegrationActionsField,
    isEqual
) as typeof IntegrationActionsField
