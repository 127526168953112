import { UNDEFINED_VALUE } from 'const/formatting'
import { DateAggregation, formatDate } from 'helpers/dates'
import {
    formatCerebroDate,
    formatCerebroDateTime,
    formatCurrency,
    formatNumber,
    titleCase,
} from 'helpers/formatting'
import { isUnset } from 'helpers/utilities'
import { Field } from 'types'

import { FieldCreatorSetupOptions } from './localTypes'

export function createField<RecordType>(
    options: FieldCreatorSetupOptions<RecordType>
): Field<RecordType> {
    return {
        ...options,
        shortName: options.shortName ?? options.name,
        alternateId: options.alternateId ?? options.id,
        isResizeable: options.isResizeable ?? true,
        minWidth: options.minWidth ?? 50,
        isVisible: options.isVisible ?? true,
        renderOptions: {
            ...options.renderOptions,
            render: options.renderOptions?.render
                ? options.renderOptions.render
                : ({ value }) => value ?? UNDEFINED_VALUE,
        },
        localDefinition: options.localDefinition ?? '',
        antTableColumnOptions: {
            title: options.columnTitle ?? options.shortName ?? options.name,
            dataIndex: options.dataIndex,
            width: options.width ?? options.minWidth ?? 100,
            sorter: options.sorter ?? true,
            fixed: options.fixed ?? false,
            align: options.align ?? 'left',
            readonly: options.readonly ?? false,
        },
    }
}

export function createDateField<RecordType>(
    options: FieldCreatorSetupOptions<RecordType> & {
        dateFormat?: string
        dateAggregation?: DateAggregation | null
    }
): Field<RecordType> {
    return createField({
        minWidth: options.minWidth ?? 80,
        width: options.width ?? 110,
        ...options,
        renderOptions: {
            ...options.renderOptions,
            render: options.renderOptions?.render
                ? options.renderOptions.render
                : ({ value }) =>
                      isUnset(value)
                          ? options.emptyValue ?? UNDEFINED_VALUE
                          : formatDate(
                                value,
                                options.dateAggregation ?? null,
                                options.dateFormat
                            ),
        },
    })
}

export function createCerebroDateField<RecordType>(
    options: FieldCreatorSetupOptions<RecordType>
): Field<RecordType> {
    return createField({
        minWidth: options.minWidth ?? 80,
        width: options.width ?? 110,
        ...options,
        renderOptions: {
            ...options.renderOptions,
            render: options.renderOptions?.render
                ? options.renderOptions.render
                : ({ value }) => <span>{formatCerebroDate(value)}</span>,
        },
    })
}

export function createCerebroDateTimeField<RecordType>(
    options: FieldCreatorSetupOptions<RecordType>
): Field<RecordType> {
    return createField({
        minWidth: options.minWidth ?? 150,
        width: options.width ?? 180,
        ...options,
        renderOptions: {
            ...options.renderOptions,
            render: options.renderOptions?.render
                ? options.renderOptions.render
                : ({ value }) => <span>{formatCerebroDateTime(value)}</span>,
        },
    })
}

export function createNumberField<RecordType>(
    options: FieldCreatorSetupOptions<RecordType> & {
        numberFormat?: string
        showUnsetAsNumber?: boolean
    }
): Field<RecordType> {
    return createField({
        minWidth: options.minWidth ?? 100,
        width: options.width ?? 120,
        align: 'right',
        ...options,
        renderOptions: {
            ...options.renderOptions,
            render: options.renderOptions?.render
                ? options.renderOptions.render
                : ({ value }) => (
                      <span>
                          {formatNumber(
                              value,
                              options.numberFormat ?? '0,0',
                              options.showUnsetAsNumber
                          )}
                      </span>
                  ),
        },
    })
}

function createPercentageField<RecordType>(
    options: FieldCreatorSetupOptions<RecordType> & {
        numberFormat?: string
        showUnsetAsNumber?: boolean
    }
): Field<RecordType> {
    return createField({
        align: 'right',
        ...options,
        renderOptions: {
            ...options.renderOptions,
            render: options.renderOptions?.render
                ? options.renderOptions.render
                : ({ value }) => (
                      <span>
                          {formatNumber(
                              value,
                              options.numberFormat ?? '0,0',
                              options.showUnsetAsNumber
                          )}
                          %
                      </span>
                  ),
        },
    })
}

export function createCurrencyField<RecordType>(
    options: FieldCreatorSetupOptions<RecordType> & { currencyCode?: string }
): Field<RecordType> {
    return createField({
        minWidth: options.minWidth ?? 100,
        width: options.width ?? 120,
        align: 'right',
        ...options,
        renderOptions: {
            ...options.renderOptions,
            render: options.renderOptions?.render
                ? options.renderOptions.render
                : ({ value, record }) => {
                      const currencyCode =
                          (record as any)?.currency_code ??
                          options.currencyCode ??
                          ''
                      return (
                          <span>
                              {isUnset(value)
                                  ? options.emptyValue ?? UNDEFINED_VALUE
                                  : formatCurrency(value, {
                                        decimal: true,
                                        currencyCode,
                                    })}
                          </span>
                      )
                  },
        },
    })
}

export function createTitleField<RecordType>(
    options: FieldCreatorSetupOptions<RecordType>
): Field<RecordType> {
    return createField({
        minWidth: options.minWidth ?? 120,
        width: options.width ?? 150,
        ...options,
        renderOptions: {
            ...options.renderOptions,
            render: options.renderOptions?.render
                ? options.renderOptions.render
                : ({ value }) =>
                      isUnset(value)
                          ? options.emptyValue ?? UNDEFINED_VALUE
                          : titleCase(value),
        },
    })
}

export function createActionField<RecordType>(
    options: Omit<FieldCreatorSetupOptions<RecordType>, 'name' | 'shortName'>
): Field<RecordType> {
    return createField({
        ...options,
        name: '',
        shortName: '',
        renderOptions: {
            ...options.renderOptions,
            render: options.renderOptions?.render
                ? options.renderOptions.render
                : ({ value }) =>
                      isUnset(value)
                          ? options.emptyValue ?? UNDEFINED_VALUE
                          : titleCase(value),
        },
    })
}

export function createLabelLookupField<RecordType>(
    options: FieldCreatorSetupOptions<RecordType> & {
        labels: Record<string, string>
    }
): Field<RecordType> {
    return createField({
        minWidth: options.minWidth ?? 120,
        width: options.width ?? 150,
        ...options,
        renderOptions: {
            ...options.renderOptions,
            render: options.renderOptions?.render
                ? options.renderOptions.render
                : ({ value }) => (
                      <span>
                          {isUnset(value)
                              ? options.emptyValue ?? UNDEFINED_VALUE
                              : options.labels[value] ??
                                options.emptyValue ??
                                UNDEFINED_VALUE}
                      </span>
                  ),
        },
    })
}

type FieldType =
    | 'string'
    | 'percentage'
    | 'date'
    | 'integer'
    | 'float'
    | 'currency'
    | 'datetime'
    | 'timestamp'

export function createFieldOfType<RecordType>(
    fieldType: FieldType,
    options: FieldCreatorSetupOptions<RecordType>
): Field<RecordType> {
    switch (fieldType) {
        case 'currency':
            return createCurrencyField(options)
        case 'date':
            return createDateField(options)
        case 'datetime':
        case 'timestamp':
            return createCerebroDateTimeField(options)
        case 'string':
            return createField(options)
        case 'integer':
            return createField(options)
        case 'float':
            return createNumberField(options)
        case 'percentage':
            return createPercentageField(options)
        default: {
            const exhaustiveCheck: never = fieldType
            throw new Error(
                `Unhandled createFieldOfType case: ${exhaustiveCheck}`
            )
        }
    }
}
