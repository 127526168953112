import { memo, ReactElement } from 'react'

import isEqual from 'lodash/isEqual'
import pick from 'lodash/pick'

import { useResource } from 'components/EditableFields/localHooks'
import { TARGET_STATES, PAUSED, ENABLED } from 'const/resourceStates'
import { updateTarget } from 'services/cerebroApi/orgScope/resourceApi'
import { NonKeywordTargetFact } from 'types'

import { StateField } from '../../SharedFields/StateField'

type TargetRecord = NonKeywordTargetFact

interface Props<RecordType> {
    dataIndex: string[]
    readOnly: boolean
    record: RecordType
}

function TargetStateField<RecordType extends TargetRecord>({
    dataIndex = ['target', 'state'],
    readOnly = false,
    record,
}: Props<RecordType>): ReactElement {
    const { fieldName, getFromResource, resourceId } = useResource(
        record,
        dataIndex,
        'target'
    )

    const AVAILABLE_OPTIONS = pick(TARGET_STATES, [PAUSED, ENABLED])

    const getIsDisabled = (): boolean => {
        const userCanManageTarget = getFromResource(['user_can_manage'], true)
        return !userCanManageTarget
    }

    return (
        <StateField
            disabled={getIsDisabled()}
            fieldName={fieldName}
            fieldPath={dataIndex}
            formatFieldValue={(value) => {
                const state = value[fieldName]
                return TARGET_STATES[state]
            }}
            options={AVAILABLE_OPTIONS}
            placeholder="Target State"
            readOnly={readOnly}
            record={record}
            updateRequestApi={updateTarget}
            updateRequestData={resourceId}
            updateRequestSuccessMesg="Successfully updated target state"
        />
    )
}

export default memo(TargetStateField, isEqual) as typeof TargetStateField
