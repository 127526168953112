import { ReactElement } from 'react'

import { Alert } from 'antd'
import { useSelector } from 'react-redux'

import { dismissUserSurveyRequest } from 'actions/ui/app'
import { IconButton } from 'components/Buttons'
import { CloseIcon } from 'components/Icons'
import { isUnset } from 'helpers/utilities'
import { useAction } from 'hooks'
import { selectDomainValue as selectAuthDomainValue } from 'selectors/auth'
import { selectDomainValue } from 'selectors/ui'
import { RootReduxState } from 'types'
import moment from 'utilities/moment'

import styles from './styles.scss'

const DISMISSAL_LENGTH_DAYS = 60 // Length of time the banner will be dismissed in days

function UserFeedbackBanner(): ReactElement | null {
    const dismissBanner = useAction(dismissUserSurveyRequest)
    const lastRead = useSelector((state: RootReduxState) =>
        selectDomainValue(state, ['app', 'userSurveyLastRead'])
    )
    const dateJoined = useSelector((state: RootReduxState) =>
        selectAuthDomainValue(state, ['date_joined'])
    )
    const today = moment()

    const shouldDisplayBanner = (): boolean => {
        const nextShowDate =
            lastRead && moment(lastRead).add(DISMISSAL_LENGTH_DAYS, 'days')
        const firstShowDate =
            dateJoined && moment(dateJoined).add(DISMISSAL_LENGTH_DAYS, 'days')

        const recentlyDismissed = nextShowDate && today.isBefore(nextShowDate)
        const newUser = firstShowDate && today.isBefore(firstShowDate)

        return !newUser && (isUnset(lastRead) || !recentlyDismissed)
    }

    const handleDismiss = (): void => {
        dismissBanner(today.toISOString())
    }

    const messageBody = (
        <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
                Your feedback is important to us. Please take a moment to share
                your experience.
                <a
                    className="ml-1"
                    id="fs-survey-link" // fs-survey-link is for Full Story to reference
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://downstreamimpact.typeform.com/to/Dhq8Kb"
                    onClick={handleDismiss}
                >
                    Take the Survey
                </a>
            </div>
            <IconButton
                icon={<CloseIcon />}
                onClick={handleDismiss}
                size="small"
                type="link"
            />
        </div>
    )

    return shouldDisplayBanner() ? (
        <Alert
            banner
            className={styles['info-alert']}
            message={messageBody}
            showIcon={false}
            type="info"
        />
    ) : null
}

export default UserFeedbackBanner
