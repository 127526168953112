import find from 'lodash/find'
import { call, select, put } from 'redux-saga/effects'

import {
    makeFetchTableRequest,
    makeFetchTableSuccess,
    makeDownloadTableSuccess,
} from 'actions/ui/shared'
import { targetsSummaryTableColumnsConfig } from 'configuration/tables'
import { DATES } from 'const/filters'
import { TARGETING_SUMMARY_PAGE } from 'const/pages'
import { formatFilters } from 'helpers/filters/targetFacts'
import { generateReportNotification } from 'helpers/notifications'
import {
    formatSorter,
    formatMetrics,
    formatPagination,
    formatCurrency,
    formatColumns,
    formatPeriodDeltaDateRange,
} from 'helpers/params'
import { cerebroApiSaga } from 'sagas/common'
import uiSagaRegistry from 'sagas/ui/registry'
import { fetchTargetBidRecommendationsSaga } from 'sagas/ui/shared/bid/workers'
import {
    selectTableSettings,
    selectVisibleMetricsOfTable,
    selectVisibleColumnsOfTable,
    selectCurrencyCode,
    selectVisibleCombinedFilters,
} from 'selectors/ui'
import {
    getTargetFacts,
    getTargetFactsExport,
} from 'services/cerebroApi/orgScope/targetFactsApi'

const TAB_PATH = [TARGETING_SUMMARY_PAGE, 'productTargets']
const TABLE_PATH = [...TAB_PATH, 'table']

function* fetchTableSaga(noCount) {
    const filters = yield select(selectVisibleCombinedFilters, TAB_PATH)
    const currency = yield select(selectCurrencyCode)

    const {
        pagination,
        sorter,
        showPeriodDeltas,
        periodDeltaType,
        periodDeltaDateRange,
        showTotalRow,
    } = yield select(selectTableSettings, TABLE_PATH)
    const metrics = yield select(
        selectVisibleMetricsOfTable,
        TABLE_PATH,
        targetsSummaryTableColumnsConfig
    )

    const params = {
        ...formatPagination(pagination),
        ...formatSorter(sorter),
        ...formatFilters(filters),
        ...formatMetrics(metrics, showPeriodDeltas, showTotalRow),
        ...formatCurrency(currency),
        ...formatPeriodDeltaDateRange(
            showPeriodDeltas,
            periodDeltaType,
            periodDeltaDateRange,
            filters[DATES]
        ),
        group_by:
            'target_id,resolved_expression,profile_id,ad_group_id,campaign_id',
    }

    const response = yield call(cerebroApiSaga, null, getTargetFacts, params, {
        headers: { noCount },
    })

    if (response && response.data && response.data.results) {
        yield put(makeFetchTableSuccess(TABLE_PATH)(response.data))

        const columns = yield select(
            selectVisibleColumnsOfTable,
            TABLE_PATH,
            targetsSummaryTableColumnsConfig
        )

        if (find(columns, { id: 'target_suggested_bid' })) {
            yield call(
                fetchTargetBidRecommendationsSaga,
                TABLE_PATH,
                response.data.results
            )
        }
    }
}

function* downloadTableSaga() {
    const filters = yield select(selectVisibleCombinedFilters, TAB_PATH)
    const { sorter, showPeriodDeltas, periodDeltaType, periodDeltaDateRange } =
        yield select(selectTableSettings, TABLE_PATH)
    const columns = yield select(
        selectVisibleColumnsOfTable,
        TABLE_PATH,
        targetsSummaryTableColumnsConfig
    )
    const currency = yield select(selectCurrencyCode)
    const reportName = 'All Product Targets Report'
    const params = {
        ...formatFilters(filters),
        ...formatSorter(sorter),
        ...formatColumns(columns, showPeriodDeltas),
        ...formatCurrency(currency),
        ...formatPeriodDeltaDateRange(
            showPeriodDeltas,
            periodDeltaType,
            periodDeltaDateRange,
            filters[DATES]
        ),
        group_by:
            'target_id,resolved_expression,profile_id,ad_group_id,campaign_id',
        async_download_name: reportName,
    }

    yield call(
        cerebroApiSaga,
        makeDownloadTableSuccess(TABLE_PATH),
        getTargetFactsExport,
        params
    )

    generateReportNotification(reportName)
}

function* fetchTabDataSaga() {
    yield put(makeFetchTableRequest(TABLE_PATH)())
}

// Register Sagas
uiSagaRegistry.registerSagas(TAB_PATH, {
    fetchTabDataSaga,
})

uiSagaRegistry.registerSagas(TABLE_PATH, {
    fetchTableSaga,
    downloadTableSaga,
})
