import { adGroupBidRenderer } from 'components/FieldRenderers/Renderers/adGroup'
import {
    adGroupActiveBid,
    adGroupBid,
    adGroupCreatedDate,
    adGroupId,
    adGroupState,
    archiveAdGroup,
} from 'configuration/fieldCreators/adGroup'
import { labels } from 'configuration/fieldCreators/labels'
import { CampaignGroupBy, ProfileGroupBy, Field, AdGroupFact } from 'types'

import { AMS_METRICS_COLUMNS_PAGES } from '../dataSources/ams'

export type AdGroupFactRecord = AdGroupFact<CampaignGroupBy & ProfileGroupBy>

export const adGroupsTableColumnsConfig: Field<AdGroupFactRecord>[] = [
    adGroupId({
        fixed: 'left',
    }),
    adGroupState(),
    adGroupBid({
        renderOptions: {
            render: (cellRenderProps) =>
                adGroupBidRenderer({
                    cellRenderProps,
                    dataIndex: ['ad_group', 'base_default_bid'],
                }),
        },
    }),
    adGroupActiveBid(),
    adGroupCreatedDate(),
    labels({ dataIndex: ['campaign', 'labels'] }),
    ...(AMS_METRICS_COLUMNS_PAGES as Field<AdGroupFactRecord>[]),
    archiveAdGroup({ fixed: 'right' }),
]
