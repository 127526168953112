import {
    deleteCustomEvent,
    description,
    notes,
    resource,
    resourceType,
} from 'configuration/fieldCreators/customEvent'
import { date, dateCreated } from 'configuration/fieldCreators/date'
import { Field, CustomEvent } from 'types'

export const customEventsTableColumnsConfig: Field<CustomEvent>[] = [
    date({ fixed: 'left' }),
    description(),
    resource(),
    resourceType(),
    notes(),
    dateCreated(),
    deleteCustomEvent(),
]
