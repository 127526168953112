import { memo, ReactElement } from 'react'

import isEqual from 'lodash/isEqual'

import { userHasManageAutomationPermissions } from 'helpers/featurePermissions'
import { useUserHasPermissions } from 'hooks'
import { deleteAutomationProfile } from 'services/cerebroApi/orgScope/resourceApi'
import { AutomationProfile } from 'types'

import { DeleteField } from '../SharedFields/DeleteField'

interface Props<RecordType> {
    readOnly: boolean
    record: RecordType
    reloadData: () => void
}

function AutomationDeleteProfileField<RecordType extends AutomationProfile>({
    readOnly = false,
    record,
    reloadData,
}: Props<RecordType>): ReactElement {
    const hasManageAutomationPermissions = useUserHasPermissions(
        userHasManageAutomationPermissions
    )

    const resourceId = record?.id
    const hasAssociatedTask = record?.automation_task_count !== 0

    const isDisabled =
        readOnly || hasAssociatedTask || !hasManageAutomationPermissions

    const getTooltipTitle = (): string | undefined => {
        if (!hasManageAutomationPermissions) {
            return 'Must have Edit Permissions to Delete'
        }

        if (hasAssociatedTask) {
            return 'Remove associated campaigns in order to delete this automation profile'
        }

        return undefined
    }

    return (
        <DeleteField
            disabled={isDisabled}
            okText="Delete"
            title="Are you sure you want to delete this automation profile?"
            updateRequestApi={deleteAutomationProfile}
            updateRequestCallback={reloadData}
            updateRequestData={resourceId}
            updateRequestSuccessMesg="Profile successfully deleted"
            tooltipTitle={getTooltipTitle()}
            tooltipPlacement="left"
        />
    )
}

export default memo(
    AutomationDeleteProfileField,
    isEqual
) as typeof AutomationDeleteProfileField
