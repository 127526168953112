import { SVGAttributes, ReactElement } from 'react'

type Props = SVGAttributes<SVGElement>

function CobaltLogoHorizontalCompressed(props: Props): ReactElement {
    const { fill } = props
    const fillColor = fill ?? '#000'

    return (
        <svg
            viewBox="0 0 166 25"
            fill="black"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g fill="none" fillRule="evenodd">
                <g fill={fillColor} transform="translate(50, 4)">
                    <path d="m7.6503 15.1756c-1.34692 0-2.56669-.2922-3.65949-.8767-1.08009-.5972-1.93136-1.4168-2.55399-2.4587-.609926-1.0419-.914847-2.21724-.914847-3.52607 0-1.30874.311269-2.48407.933897-3.52598.62262-1.04191 1.47398-1.85518 2.55399-2.43963 1.09279-.59723 2.31256-.89588 3.65948-.89588 1.09271 0 2.09018.19064 2.99236.57183.9021.38119 1.6645.9339 2.2871 1.65819l-1.6009 1.50573c-.9658-1.04191-2.15378-1.56287-3.56419-1.56287-.91485 0-1.73446.20325-2.45876.60984-.72421.3939-1.28969.94668-1.69628 1.65819-.40659.7116-.60993 1.51843-.60993 2.42058 0 .90216.20334 1.70907.60993 2.42057.40659.7116.97207 1.2707 1.69628 1.6773.7243.3939 1.54391.5908 2.45876.5908 1.41041 0 2.59839-.5273 3.56419-1.5819l1.6009 1.5248c-.6226.7242-1.3913 1.277-2.3062 1.6582-.90213.3812-1.8996.5717-2.9923.5717z" />
                    <path d="m28.4727 15.1756c-1.3597 0-2.5859-.2922-3.6786-.8767-1.0928-.5972-1.9505-1.4168-2.573-2.4587-.6226-1.0546-.934-2.22994-.934-3.52607 0-1.29604.3114-2.46502.934-3.50693.6225-1.05462 1.4802-1.87423 2.573-2.45868 1.0927-.59723 2.3189-.89588 3.6786-.89588 1.3595 0 2.5857.29865 3.6785.89588 1.0927.58445 1.9504 1.39772 2.573 2.43963.6227 1.04191.9339 2.21724.9339 3.52598 0 1.30883-.3112 2.48417-.9339 3.52607-.6226 1.0419-1.4803 1.8615-2.573 2.4587-1.0928.5845-2.319.8767-3.6785.8767zm0-2.1728c.8894 0 1.6899-.1969 2.4015-.5908.7115-.4066 1.2706-.9657 1.6772-1.6773.4066-.7242.61-1.53111.61-2.42057 0-.88945-.2034-1.68993-.61-2.40145-.4066-.72429-.9657-1.28342-1.6772-1.67732-.7116-.40659-1.5121-.60984-2.4015-.60984-.8895 0-1.6901.20325-2.4016.60984-.7116.3939-1.2706.95303-1.6772 1.67732-.4067.71152-.6099 1.512-.6099 2.40145 0 .88946.2032 1.69637.6099 2.42057.4066.7116.9656 1.2707 1.6772 1.6773.7115.3939 1.5121.5908 2.4016.5908z" />
                    <path d="m54.4536 8.40403c.737.24143 1.3215.64802 1.7535 1.21986.432.55901.648 1.25791.648 2.09651 0 1.1563-.4447 2.0521-1.3341 2.6874-.8895.6226-2.1856.934-3.8882.934h-6.6328v-13.3418h6.2516c1.5755 0 2.789.31127 3.6404.9339.8513.60992 1.277 1.45494 1.277 2.53494 0 .66072-.1525 1.24525-.4575 1.75351-.3049.50826-.7243.90216-1.2579 1.18168zm-6.9759-4.45996v3.67852h3.507c.8641 0 1.5248-.15246 1.9823-.45738.4701-.3177.7051-.77517.7051-1.37231 0-.60993-.235-1.0674-.7051-1.37232-.4575-.31762-1.1182-.47651-1.9823-.47651zm4.0026 9.45363c1.9187 0 2.878-.6417 2.878-1.9251 0-1.2833-.9593-1.92499-2.878-1.92499h-4.0026v3.85009z" />
                    <path d="m75.8307 11.8975h-6.6711l-1.3151 3.0876h-2.554l6.0038-13.34179h2.4396l6.0232 13.34179h-2.5922zm-.8195-1.94419-2.5162-5.83228-2.4967 5.83228z" />
                    <path d="m88.6504 1.64331h2.4778v11.24519h6.9764v2.0966h-9.4542z" />
                    <path d="m108.717 3.73984h-4.422v-2.09653h11.321v2.09653h-4.421v11.24526h-2.478z" />
                    <path d="m28.3674 5.30054c1.1132.28495 2.0095 1.59286 2.0095 3.06083 0 1.32495-.7193 2.55553-1.651 3.02563 1.6703-.2668 2.8073-1.83811 2.5396-3.50925-.2304-1.43965-1.4423-2.51815-2.8993-2.57721z" />
                </g>
                <path
                    fill={fillColor}
                    d="M15.018 0v1.587c-1.342 1.177-2.1 2.874-2.075 4.649v.05c0 1.874.681 3.303 2.07 4.351v1.899l-2.768 3.285c-.247.291-.216.72.066.973.322.282.66.546 1.01.792-.105.118-.212.237-.327.35C11.638 19.286 9.706 20 7.427 20 4.392 20 2.02 18.867.365 16.643l-.365-.5.456-.429 3.295-3.088.551-.509.487.572c.87 1.041 1.517 1.442 2.327 1.442.343 0 1.396 0 1.396-1.857V0h6.506zm5.788.391c3.144 0 5.597.832 7.601 2.43l-2.56 3.572c-1.69-1.173-3.533-1.804-5.172-1.804-1.245.01-1.852.531-1.852 1.182v.05c0 .832.627 1.203 3.188 1.773 4.25.912 6.88 2.275 6.88 5.692v.048c0 3.737-2.984 5.952-7.467 5.952-2.812 0-5.526-.759-7.668-2.256-.342-.238-.67-.495-.982-.77l2.165-2.557.686-.81c1.821 1.433 3.855 2.194 5.99 2.194 1.377 0 2.116-.471 2.116-1.251v-.052c0-.761-.607-1.171-3.116-1.753-2.328-.528-4.342-1.128-5.597-2.31-.863-.81-1.366-1.898-1.366-3.428v-.05c-.023-1.349.464-2.657 1.366-3.667C16.24 1.219 18.234.39 20.806.39z"
                    transform="translate(0, 3)"
                />
                <path
                    stroke={fillColor}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.111"
                    d="M37 0.556L37 25"
                    opacity=".3"
                    transform="translate(3)"
                />
            </g>
        </svg>
    )
}

export default CobaltLogoHorizontalCompressed
