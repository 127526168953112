export const KEYWORD_TYPE = {
    CAMPAIGN_NEGATIVE: 'Campaign Negative',
    AD_GROUP_NEGATIVE: 'Ad Group Negative',
}

export interface FormValues {
    searchTerm: string
    keywordType: string
    campaignId: string
    adGroupId: string
    matchType: string
}
