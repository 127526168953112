import moment from 'moment'

import { variant_count__max } from 'configuration/fieldCreators/metrics/asinSalesEstimateFacts'
import widgetsTranslation from 'configuration/widgetsTranslation'
import { WidgetDataSourceKey } from 'const/dataSources'
import {
    AD_ACCOUNT_TYPES,
    AD_ACCOUNTS,
    ADVANCED_AUTOMATIONS,
    ADVANCED_TEXT,
    AUTOMATIONS,
    BRANDS,
    CAMPAIGN_AD_FORMATS,
    CAMPAIGN_STATES,
    CAMPAIGN_TARGETING_TYPES,
    CAMPAIGNS,
    COUNTRIES,
    DATES,
    DSP_ADVERTISERS,
    DSP_AUDIENCE_ID,
    DSP_AUDIENCE_NAME,
    DSP_CREATIVE_ID,
    DSP_CREATIVE_NAME,
    DSP_CREATIVE_TYPE,
    DSP_LINE_ITEMS,
    DSP_ORDERS,
    FACT_TYPES,
    KEYWORD_MATCH_TYPES,
    KEYWORD_STATES,
    KEYWORDS,
    LABELS,
    METRIC_FILTERS,
    ORGANIZATIONS,
    PORTFOLIOS,
    PRODUCT_AD_STATES,
    PRODUCT_ADS,
    PRODUCT_ASINS,
    PRODUCT_NUMBER_OF_ITEMS,
    PRODUCT_TITLES,
    RANGE_LAG,
    REGIONS,
    RESOURCE_TYPES,
    SC_ASINS,
    SC_ACCOUNTS,
    SC_PRODUCT_TITLES,
    SEARCH_TERM,
    SOV_ASINS,
    SOV_BRANDS,
    SOV_COUNTRIES,
    SOV_FOLDS,
    SOV_KEYWORDS,
    SOV_LANGUAGES,
    SOV_LAYOUT,
    SOV_METADATA_BRANDS,
    SOV_PLATFORMS,
    SOV_RANK,
    SOV_RESULT_TYPES,
    SOV_STATES,
    TARGET_STATES,
    VC_INTEGRATIONS,
    VC_PROGRAMS,
} from 'const/filters'
import {
    AD_ACCOUNT_PAGE,
    AD_GROUP_PAGE,
    CAMPAIGN_PAGE,
    CAMPAIGN_PRODUCT_AD_PAGE,
    DASHBOARD_PAGE,
    DASHBOARD_PRINT_PAGE,
    DSP_ADVERTISER_PAGE,
    DSP_LINE_ITEM_PAGE,
    DSP_ORDER_PAGE,
    HOME_PAGE,
    KEYWORD_PAGE,
    PORTFOLIO_PAGE,
    PRODUCT_AD_PAGE,
    PRODUCT_PAGE,
    SEGMENT_PAGE,
} from 'const/pages'
import {
    CATEGORY_CHART,
    MAX_DAYS_RANGE_FOR_HOURLY_DATA,
    METRIC,
    REPORT_DATE_DAY_ID,
    REPORT_DATE_HOUR_ID,
    TABLE,
    TEXT,
    TIME_SERIES,
} from 'const/widgets'
import {
    AMS_CHART_FORMATTERS,
    CHANGELOG_CHART_FORMATTERS,
    DSP_CHART_FORMATTERS,
    ORG_FACT_CHART_FORMATTERS,
    SC_CHART_FORMATTERS,
    SOV_CHART_FORMATTERS,
    VC_CHART_FORMATTERS,
    WM_ADVERTISING_FORMATTERS,
    WM_MARKETPLACE_FORMATTERS,
} from 'helpers/charts'
import { calculateSegmentLag } from 'helpers/dateRange'
import {
    userHasAmazonAdvertisingPermissions,
    userHasCustomerServicePermissions,
    userHasReadOrManageSegmentPermissions,
    userHasSellerCentralReadPermissions,
    userHasSovReadPermissions,
    userHasVendorCentralReadPermissions,
    userHasWalmartAdvertisingReadPermissions,
    userHasWalmartMarketplaceReadPermissions,
} from 'helpers/featurePermissions'
import { formatFilters as formatAdGroupFactsFilters } from 'helpers/filters/adGroupFacts'
import { formatFilters as formatAsinUsageFilters } from 'helpers/filters/asinUsage'
import { formatFilters as formatCampaignFactsFilters } from 'helpers/filters/campaignFacts'
import { formatFilters as formatChangelogFilters } from 'helpers/filters/changelog'
import { formatFilters as formatDspFactsFilters } from 'helpers/filters/dspFacts'
import { formatFilters as formatKeywordFactsFilters } from 'helpers/filters/keywordFacts'
import { formatFilters as formatOrgFactsFilters } from 'helpers/filters/orgFacts'
import { formatFilters as formatProductAdFactsFilters } from 'helpers/filters/productAdFacts'
import { formatFilters as formatSBPurchasedProductsFactsFilters } from 'helpers/filters/sbPurchasedProductsFacts'
import { formatFilters as formatSegmentFactsFilters } from 'helpers/filters/segmentFacts'
import { formatFilters as formatScFactsFilters } from 'helpers/filters/sellerCentralFacts'
import { formatFilters as formatSovSearchResultsFilters } from 'helpers/filters/sovSearchResults'
import { formatFilters as formatTargetFactsFilters } from 'helpers/filters/targetFacts'
import { formatFilters as formatVcApiSalesDiagnosticFactsFilters } from 'helpers/filters/vendorCentralApiSalesDiagnosticFacts'
import { formatFilters as formatVcFactsFilters } from 'helpers/filters/vendorCentralFacts'
import { formatFilters as formatWmAdvertisingFactsFilters } from 'helpers/filters/wmAdvertisingFacts'
import { formatFilters as formatWmMarketplaceFactsFilters } from 'helpers/filters/wmMarketplaceFacts'
import { formatDateFromFilter } from 'helpers/utils'
import { ReactComponent as LineChartWidgetSvg } from 'images/dashboards/line_chart_widget.svg'
import { ReactComponent as MetricWidgetSvg } from 'images/dashboards/metric_widget.svg'
import { ReactComponent as PieChartWidgetSvg } from 'images/dashboards/pie_chart_widget.svg'
import { ReactComponent as TableWidgetSvg } from 'images/dashboards/table_widget.svg'
import { ReactComponent as TextWidgetSvg } from 'images/dashboards/text_widget.svg'
import {
    getAdGroupFacts,
    getAdGroupFactsExport,
} from 'services/cerebroApi/orgScope/adGroupFactsApi'
import {
    getAmsStreamFacts,
    getAmsStreamFactsExport,
} from 'services/cerebroApi/orgScope/amsStreamFactsApi'
import {
    getAsinUsageSummary,
    getAsinUsageSummaryExport,
} from 'services/cerebroApi/orgScope/asinUsageFactsApi'
import {
    getCampaignFacts,
    getCampaignFactsExport,
} from 'services/cerebroApi/orgScope/campaignFactsApi'
import {
    getCampaignPlacementFacts,
    getCampaignPlacementFactsExport,
} from 'services/cerebroApi/orgScope/campaignPlacementFactsApi'
import {
    getChangelogSummary,
    getChangelogSummaryExport,
} from 'services/cerebroApi/orgScope/changelogFactsApi'
import {
    getDspAudienceFacts,
    getDspAudienceFactsExport,
    getDspCampaignFacts,
    getDspCampaignFactsExport,
    getDspInventoryFacts,
    getDspInventoryFactsExport,
} from 'services/cerebroApi/orgScope/dspApi'
import {
    getKeywordFacts,
    getKeywordFactsExport,
} from 'services/cerebroApi/orgScope/keywordFactsApi'
import {
    getKeywordQueryFacts,
    getKeywordQueryFactsExport,
} from 'services/cerebroApi/orgScope/keywordQueryFactsApi'
import {
    getOrgFacts,
    getOrgFactsExport,
} from 'services/cerebroApi/orgScope/orgFactsApi'
import {
    getProductAdFacts,
    getProductAdFactsExport,
} from 'services/cerebroApi/orgScope/productAdFactsApi'
import {
    getSBPurchasedProductsFacts,
    getSBPurchasedProductsFactsExport,
} from 'services/cerebroApi/orgScope/sbPurchasedProductsFactsApi'
import {
    getSegmentFacts,
    getSegmentFactsExport,
} from 'services/cerebroApi/orgScope/segmentApi'
import {
    getScOrderV2Metrics,
    getScOrderV2MetricsExport,
    getScSalesAndTrafficData,
    getScSalesAndTrafficDataExport,
} from 'services/cerebroApi/orgScope/sellerCentralApi'
import {
    getSovFactAggregates,
    getSovFactAggregatesExport,
} from 'services/cerebroApi/orgScope/sovSearchResultsApi'
import {
    getTargetFacts,
    getTargetFactsExport,
} from 'services/cerebroApi/orgScope/targetFactsApi'
import {
    getTargetQueryFacts,
    getTargetQueryFactsExport,
} from 'services/cerebroApi/orgScope/targetQueryFactsApi'
import {
    getVcApiInventoryHealthFacts,
    getVcApiInventoryHealthFactsExport,
    getVcApiInventoryManufacturingMetrics,
    getVcApiInventoryManufacturingMetricsExport,
    getVcApiInventorySourcingMetrics,
    getVcApiInventorySourcingMetricsExport,
    getVcApiSalesDiagnosticManufacturerMetrics,
    getVcApiSalesDiagnosticManufacturerMetricsExport,
    getVcApiSalesDiagnosticSourcingMetrics,
    getVcApiSalesDiagnosticSourcingMetricsExport,
    getVcInventoryHealthFactAggregates,
    getVcInventoryHealthFactAggregatesExport,
    getVcManufacturerFactAggregates,
    getVcManufacturerFactAggregatesExport,
    getVcSourceFactAggregates,
    getVcSourceFactAggregatesExport,
    getVcTrafficDiagnosticFactAggregates,
    getVcTrafficDiagnosticFactAggregatesExport,
} from 'services/cerebroApi/orgScope/vendorCentralApi'
import {
    getWmAdvertisingCampaignFacts,
    getWmAdvertisingCampaignFactsExport,
    getWmAdvertisingItemFacts,
    getWmAdvertisingItemFactsExport,
    getWmAdvertisingKeywordFacts,
    getWmAdvertisingKeywordFactsExport,
    getWmAdvertisingPlacementFacts,
    getWmAdvertisingPlacementFactsExport,
    getWmAdvertisingPlatformFacts,
    getWmAdvertisingPlatformFactsExport,
    getWmMarketplaceOrderFacts,
    getWmMarketplaceOrderFactsExport,
} from 'services/cerebroApi/orgScope/walmartApi'
import {
    DataSourceMetadataKey,
    DataSources,
    FiltersState,
    UserPermission,
    WidgetType,
} from 'types'

import {
    AMS_GOAL_OPTIONS,
    AMS_METRICS_CONFIG,
    AMS_WITH_SD_METRICS_CONFIG,
    CAMPAIGN_PLACEMENT_GROUP_BY_PLACEHOLDER_COLUMNS,
    PRODUCT_AD_METRICS_CONFIG,
    QUERY_GROUP_BY_COLUMNS,
    SPECIAL_AD_GROUP_GROUP_BY_COLUMNS,
    SPECIAL_BRAND_GROUP_BY_COLUMNS,
    SPECIAL_CAMPAIGN_GROUP_BY_COLUMNS,
    SPECIAL_KEYWORD_GROUP_BY_COLUMNS,
    SPECIAL_PORTFOLIO_GROUP_BY_COLUMNS,
    SPECIAL_PRODUCT_AD_GROUP_BY_COLUMNS,
    SPECIAL_TARGET_GROUP_BY_COLUMNS,
    STREAM_SUPPORTED_AMS_METRICS_CONFIG,
    TARGET_SUPPORTED_AMS_METRICS_CONFIG,
} from './dataSources/ams'
import {
    ASIN_USAGE_FACTS_FILTER,
    ASIN_USAGE_GROUP_BY_CONFIG,
    ASIN_USAGE_METRICS_CONFIG,
    SPECIAL_ASIN_USAGE_GROUP_BY_COLUMNS,
} from './dataSources/asinUsage'
import {
    CHANGELOG_METRICS_CONFIG,
    SPECIAL_CHANGELOG_GROUP_BY_COLUMNS,
} from './dataSources/changelog'
import {
    DATE_TIME_AGGREGATE_COLUMNS,
    REPORT_DATE_AGGREGATE_COLUMNS,
    REPORT_DATE_COLUMNS,
    REPORT_DATE_TIME_AGGREGATE_COLUMNS,
    REPORT_DATE_TIME_COLUMNS,
    REPORT_DATE_WEEKLY_GRAIN_COLUMNS,
} from './dataSources/date'
import {
    DSP_METRICS_CONFIG,
    SPECIAL_DSP_AUDIENCE_GROUP_BY_COLUMNS,
    SPECIAL_DSP_CAMPAIGN_GROUP_BY_COLUMNS,
    SPECIAL_DSP_INVENTORY_GROUP_BY_COLUMNS,
} from './dataSources/dsp'
import {
    ORG_FACT_METRICS_CONFIG,
    SPECIAL_ORG_FACT_GROUP_BY_COLUMNS,
} from './dataSources/orgs'
import { SPECIAL_PRODUCT_METADATA_COLUMNS } from './dataSources/productMedatdata'
import {
    SB_PURCHASED_PRODUCTS_GROUP_BY_CONFIG,
    SB_PURCHASED_PRODUCTS_METRICS_CONFIG,
    SPECIAL_SB_PURCHASED_PRODUCTS_GROUP_BY_COLUMNS,
} from './dataSources/sbPurchasedProductFacts'
import {
    SEGMENT_FACTS_FILTER,
    SEGMENT_FACTS_GROUP_BY_CONFIG,
    SEGMENT_FACTS_METRICS_CONFIG,
    SPECIAL_SEGMENT_FACTS_GROUP_BY_COLUMNS,
} from './dataSources/segment'
import {
    SC_METRICS_AND_METADATA_CONFIG,
    SC_SALES_AND_TRAFFIC_CONFIG,
    SPECIAL_SC_GROUP_BY_COLUMNS,
} from './dataSources/sellerCentral'
import {
    SOV_METRICS_CONFIG,
    SOV_TIME_AGGREGATE_COLUMNS,
    SPECIAL_SOV_GROUP_BY_COLUMNS,
} from './dataSources/sov'
import {
    SPECIAL_VC_API_GROUP_BY_COLUMNS,
    SPECIAL_VC_GROUP_BY_COLUMNS,
    VC_API_INVENTORY_HEALTH_METRICS_CONFIG,
    VC_API_INVENTORY_MANUFACTURING_METRICS_CONFIG,
    VC_API_INVENTORY_SOURCING_METRICS_CONFIG,
    VC_API_SALES_DIAGNOSTIC_METRICS_CONFIG,
    VC_API_SALES_DIAGNOSTIC_SOURCING_METRICS_CONFIG,
    VC_INVENTORY_HEALTH_LEGACY_METRICS_CONFIG,
    VC_MANUFACTURER_METRICS_CONFIG,
    VC_SOURCE_METRICS_CONFIG,
    VC_TRAFFIC_DIAGNOSTIC_METRICS_CONFIG,
} from './dataSources/vendorCentral'
import {
    SPECIAL_WM_ADVERTISING_CAMPAIGN_GROUP_BY_COLUMNS,
    SPECIAL_WM_ADVERTISING_ITEM_GROUP_BY_COLUMNS,
    SPECIAL_WM_ADVERTISING_KEYWORD_GROUP_BY_COLUMNS,
    SPECIAL_WM_ADVERTISING_PLACEMENT_GROUP_BY_COLUMNS,
    SPECIAL_WM_ADVERTISING_PLATFORM_GROUP_BY_COLUMNS,
    WM_ADVERTISING_METRICS_CONFIG,
} from './dataSources/walmartAdvertising'
import {
    SPECIAL_WM_MARKETPLACE_GROUP_BY_COLUMNS,
    WM_MARKETPLACE_METRICS_CONFIG,
} from './dataSources/walmartMarketplace'

const returnsTrue = (): boolean => true

const returnsFalse = (): boolean => false

export interface WidgetTypeEntry {
    name: string
    svgComponent: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
}

export const WIDGET_TYPES: Record<WidgetType, WidgetTypeEntry> = {
    [TIME_SERIES]: {
        name: 'Time Series Chart',
        svgComponent: LineChartWidgetSvg,
    },
    [CATEGORY_CHART]: {
        name: 'Categorical Chart',
        svgComponent: PieChartWidgetSvg,
    },
    [TABLE]: {
        name: 'Table',
        svgComponent: TableWidgetSvg,
    },
    [METRIC]: {
        name: 'Metric',
        svgComponent: MetricWidgetSvg,
    },
    [TEXT]: {
        name: 'Custom Text',
        svgComponent: TextWidgetSvg,
    },
}

/**
 * AMS fact fields that should be displayed as options
 * for X-Axis in timeseries charts
 */
export const AMS_TIME_AGGREGATE_FIELDS = [
    'report_date_year',
    'report_date_month',
    'report_date_week',
    REPORT_DATE_DAY_ID,
    REPORT_DATE_HOUR_ID,
]

/**
 * SOV fact fields that should be displayed as options
 * for X-Axis in timeseries charts
 */
export const SOV_TIME_AGGREGATE_FIELDS = [
    'search_time_month',
    'search_time_week',
    'search_time_day',
    'search_time',
]

/**
 * SOV fact fields that should be displayed as options
 * for X-Axis in timeseries charts
 */
export const CHANGELOG_TIME_AGGREGATE_FIELDS = [
    'date_month',
    'date_week',
    'date_day',
    'date',
]

export const DATA_SOURCES: DataSources = {
    '': undefined, // Text widget data source
    campaign_facts: {
        name: widgetsTranslation.campaign_facts.name,
        filters: [
            DATES,
            REGIONS,
            COUNTRIES,
            FACT_TYPES,
            LABELS,
            BRANDS,
            PORTFOLIOS,
            CAMPAIGNS,
            CAMPAIGN_STATES,
            CAMPAIGN_TARGETING_TYPES,
            CAMPAIGN_AD_FORMATS,
            ADVANCED_AUTOMATIONS,
            METRIC_FILTERS,
            RANGE_LAG,
        ],
        apiFunc: getCampaignFacts,
        exportApiFunc: getCampaignFactsExport,
        formatFiltersFunc: formatCampaignFactsFilters,
        specialGroupByColumnRenderers: [
            ...SPECIAL_CAMPAIGN_GROUP_BY_COLUMNS,
            ...SPECIAL_BRAND_GROUP_BY_COLUMNS,
            ...SPECIAL_PORTFOLIO_GROUP_BY_COLUMNS,
            ...REPORT_DATE_COLUMNS,
        ],
        specialGroupByColumnPlaceholders: [
            ...CAMPAIGN_PLACEMENT_GROUP_BY_PLACEHOLDER_COLUMNS,
        ],
        groupByConfig: AMS_CHART_FORMATTERS,
        metricsConfig: AMS_WITH_SD_METRICS_CONFIG,
        goalOptions: AMS_GOAL_OPTIONS,
        datePrefix: 'report_date',
        timeAggregateFields: REPORT_DATE_AGGREGATE_COLUMNS,
        displayed: userHasAmazonAdvertisingPermissions,
    },
    // We only need API functions defined here
    campaign_placement_facts: {
        apiFunc: getCampaignPlacementFacts,
        exportApiFunc: getCampaignPlacementFactsExport,
        displayed: returnsFalse,
        formatFiltersFunc: formatCampaignFactsFilters,
    },
    ad_group_facts: {
        apiFunc: getAdGroupFacts,
        exportApiFunc: getAdGroupFactsExport,
        formatFiltersFunc: formatAdGroupFactsFilters,
        metricsConfig: AMS_WITH_SD_METRICS_CONFIG,
        datePrefix: 'report_date',
        displayed: returnsFalse,
    },
    keyword_facts: {
        name: widgetsTranslation.keyword_facts.name,
        filters: [
            SEARCH_TERM,
            DATES,
            REGIONS,
            COUNTRIES,
            FACT_TYPES,
            LABELS,
            BRANDS,
            PORTFOLIOS,
            CAMPAIGNS,
            CAMPAIGN_STATES,
            CAMPAIGN_TARGETING_TYPES,
            ADVANCED_TEXT,
            KEYWORDS,
            KEYWORD_MATCH_TYPES,
            KEYWORD_STATES,
            METRIC_FILTERS,
            RANGE_LAG,
        ],
        apiFunc: getKeywordFacts,
        exportApiFunc: getKeywordFactsExport,
        formatFiltersFunc: formatKeywordFactsFilters,
        specialGroupByColumnRenderers: [
            ...SPECIAL_KEYWORD_GROUP_BY_COLUMNS,
            ...SPECIAL_AD_GROUP_GROUP_BY_COLUMNS,
            ...SPECIAL_CAMPAIGN_GROUP_BY_COLUMNS,
            ...SPECIAL_BRAND_GROUP_BY_COLUMNS,
            ...SPECIAL_PORTFOLIO_GROUP_BY_COLUMNS,
            ...REPORT_DATE_COLUMNS,
            ...QUERY_GROUP_BY_COLUMNS,
        ],
        specialGroupByColumnPlaceholders: [...QUERY_GROUP_BY_COLUMNS],
        groupByConfig: AMS_CHART_FORMATTERS,
        metricsConfig: AMS_METRICS_CONFIG,
        goalOptions: AMS_GOAL_OPTIONS,
        datePrefix: 'report_date',
        timeAggregateFields: REPORT_DATE_AGGREGATE_COLUMNS,
        displayed: userHasAmazonAdvertisingPermissions,
    },
    // We only need API functions defined here
    keyword_query_facts: {
        apiFunc: getKeywordQueryFacts,
        exportApiFunc: getKeywordQueryFactsExport,
        displayed: returnsFalse,
        formatFiltersFunc: formatKeywordFactsFilters,
    },
    product_ad_facts: {
        name: widgetsTranslation.product_ad_facts.name,
        filters: [
            DATES,
            REGIONS,
            COUNTRIES,
            FACT_TYPES,
            LABELS,
            BRANDS,
            PORTFOLIOS,
            CAMPAIGNS,
            CAMPAIGN_STATES,
            CAMPAIGN_TARGETING_TYPES,
            PRODUCT_ADS,
            PRODUCT_ASINS,
            PRODUCT_AD_STATES,
            PRODUCT_TITLES,
            PRODUCT_NUMBER_OF_ITEMS,
            METRIC_FILTERS,
            RANGE_LAG,
        ],
        apiFunc: getProductAdFacts,
        exportApiFunc: getProductAdFactsExport,
        formatFiltersFunc: formatProductAdFactsFilters,
        specialGroupByColumnRenderers: [
            ...SPECIAL_PRODUCT_AD_GROUP_BY_COLUMNS,
            ...SPECIAL_PRODUCT_METADATA_COLUMNS,
            ...SPECIAL_AD_GROUP_GROUP_BY_COLUMNS,
            ...SPECIAL_CAMPAIGN_GROUP_BY_COLUMNS,
            ...SPECIAL_BRAND_GROUP_BY_COLUMNS,
            ...SPECIAL_PORTFOLIO_GROUP_BY_COLUMNS,
            ...REPORT_DATE_COLUMNS,
        ],
        groupByConfig: AMS_CHART_FORMATTERS,
        metricsConfig: PRODUCT_AD_METRICS_CONFIG,
        goalOptions: AMS_GOAL_OPTIONS,
        datePrefix: 'report_date',
        timeAggregateFields: REPORT_DATE_AGGREGATE_COLUMNS,
        displayed: userHasAmazonAdvertisingPermissions,
        metricCategoryOrder: ['Advertising', 'Advertising + COGS', 'COGS'],
    },
    target_facts: {
        name: widgetsTranslation.target_facts.name,
        filters: [
            SEARCH_TERM,
            DATES,
            REGIONS,
            COUNTRIES,
            FACT_TYPES,
            LABELS,
            BRANDS,
            PORTFOLIOS,
            CAMPAIGNS,
            CAMPAIGN_STATES,
            CAMPAIGN_TARGETING_TYPES,
            TARGET_STATES,
            METRIC_FILTERS,
            RANGE_LAG,
        ],
        apiFunc: getTargetFacts,
        exportApiFunc: getTargetFactsExport,
        formatFiltersFunc: formatTargetFactsFilters,
        specialGroupByColumnRenderers: [
            ...SPECIAL_TARGET_GROUP_BY_COLUMNS,
            ...SPECIAL_AD_GROUP_GROUP_BY_COLUMNS,
            ...SPECIAL_CAMPAIGN_GROUP_BY_COLUMNS,
            ...SPECIAL_BRAND_GROUP_BY_COLUMNS,
            ...SPECIAL_PORTFOLIO_GROUP_BY_COLUMNS,
            ...REPORT_DATE_COLUMNS,
            ...QUERY_GROUP_BY_COLUMNS,
        ],
        specialGroupByColumnPlaceholders: [...QUERY_GROUP_BY_COLUMNS],
        groupByConfig: AMS_CHART_FORMATTERS,
        metricsConfig: TARGET_SUPPORTED_AMS_METRICS_CONFIG,
        goalOptions: AMS_GOAL_OPTIONS,
        datePrefix: 'report_date',
        timeAggregateFields: REPORT_DATE_AGGREGATE_COLUMNS,
        displayed: userHasAmazonAdvertisingPermissions,
    },
    // We only need API functions defined here
    target_query_facts: {
        apiFunc: getTargetQueryFacts,
        exportApiFunc: getTargetQueryFactsExport,
        displayed: returnsFalse,
        formatFiltersFunc: formatTargetFactsFilters,
    },
    sov_search_results: {
        name: widgetsTranslation.sov_search_results.name,
        filters: [
            DATES,
            LABELS,
            COUNTRIES,
            SOV_METADATA_BRANDS,
            SOV_BRANDS,
            SOV_KEYWORDS,
            SOV_FOLDS,
            SOV_RANK,
            SOV_RESULT_TYPES,
            SOV_PLATFORMS,
            SOV_COUNTRIES,
            SOV_LANGUAGES,
            SOV_STATES,
            SOV_ASINS,
            PRODUCT_TITLES,
            PRODUCT_NUMBER_OF_ITEMS,
            SOV_LAYOUT,
            RANGE_LAG,
        ],
        apiFunc: getSovFactAggregates,
        exportApiFunc: getSovFactAggregatesExport,
        formatFiltersFunc: formatSovSearchResultsFilters,
        specialGroupByColumnRenderers: [...SPECIAL_SOV_GROUP_BY_COLUMNS],
        groupByConfig: SOV_CHART_FORMATTERS,
        metricsConfig: SOV_METRICS_CONFIG,
        datePrefix: 'search_time',
        timeAggregateFields: SOV_TIME_AGGREGATE_COLUMNS,
        displayed: userHasSovReadPermissions,
        metricCategoryOrder: [
            'Shelf Intelligence',
            'Advertising + Shelf Intelligence',
            'Advertising',
        ],
    },
    vc_sales_diagnostic: {
        name: widgetsTranslation.vc_sales_diagnostic.name,
        filters: [
            DATES,
            COUNTRIES,
            PRODUCT_ASINS,
            PRODUCT_TITLES,
            LABELS,
            VC_INTEGRATIONS,
            METRIC_FILTERS,
            RANGE_LAG,
        ],
        apiFunc: getVcManufacturerFactAggregates,
        exportApiFunc: getVcManufacturerFactAggregatesExport,
        formatFiltersFunc: formatVcFactsFilters,
        specialGroupByColumnRenderers: [
            ...SPECIAL_PRODUCT_METADATA_COLUMNS,
            ...SPECIAL_VC_GROUP_BY_COLUMNS,
            ...REPORT_DATE_COLUMNS,
        ],
        groupByConfig: VC_CHART_FORMATTERS,
        metricsConfig: VC_MANUFACTURER_METRICS_CONFIG,
        datePrefix: 'report_date',
        timeAggregateFields: REPORT_DATE_AGGREGATE_COLUMNS,
        displayed: userHasVendorCentralReadPermissions,
        lag: 72,
    },
    vc_source_sales_diagnostic: {
        name: widgetsTranslation.vc_source_sales_diagnostic.name,
        filters: [
            DATES,
            COUNTRIES,
            PRODUCT_ASINS,
            PRODUCT_TITLES,
            LABELS,
            VC_INTEGRATIONS,
            METRIC_FILTERS,
            RANGE_LAG,
        ],
        apiFunc: getVcSourceFactAggregates,
        exportApiFunc: getVcSourceFactAggregatesExport,
        formatFiltersFunc: formatVcFactsFilters,
        specialGroupByColumnRenderers: [
            ...SPECIAL_PRODUCT_METADATA_COLUMNS,
            ...SPECIAL_VC_GROUP_BY_COLUMNS,
            ...REPORT_DATE_COLUMNS,
        ],
        groupByConfig: VC_CHART_FORMATTERS,
        metricsConfig: VC_SOURCE_METRICS_CONFIG,
        datePrefix: 'report_date',
        timeAggregateFields: REPORT_DATE_AGGREGATE_COLUMNS,
        displayed: userHasVendorCentralReadPermissions,
        lag: 72,
    },
    vc_traffic_diagnostic: {
        name: widgetsTranslation.vc_traffic_diagnostic.name,
        filters: [
            DATES,
            COUNTRIES,
            PRODUCT_ASINS,
            PRODUCT_TITLES,
            LABELS,
            VC_INTEGRATIONS,
            METRIC_FILTERS,
            RANGE_LAG,
        ],
        apiFunc: getVcTrafficDiagnosticFactAggregates,
        exportApiFunc: getVcTrafficDiagnosticFactAggregatesExport,
        formatFiltersFunc: formatVcFactsFilters,
        specialGroupByColumnRenderers: [
            ...SPECIAL_PRODUCT_METADATA_COLUMNS,
            ...SPECIAL_VC_GROUP_BY_COLUMNS,
            ...REPORT_DATE_COLUMNS,
        ],
        groupByConfig: VC_CHART_FORMATTERS,
        metricsConfig: VC_TRAFFIC_DIAGNOSTIC_METRICS_CONFIG,
        datePrefix: 'report_date',
        timeAggregateFields: REPORT_DATE_AGGREGATE_COLUMNS,
        displayed: userHasVendorCentralReadPermissions,
        lag: 72,
    },
    vc_inventory_health: {
        name: widgetsTranslation.vc_inventory_health.name,
        filters: [
            DATES,
            COUNTRIES,
            PRODUCT_ASINS,
            PRODUCT_TITLES,
            LABELS,
            VC_INTEGRATIONS,
            METRIC_FILTERS,
            RANGE_LAG,
        ],
        apiFunc: getVcInventoryHealthFactAggregates,
        exportApiFunc: getVcInventoryHealthFactAggregatesExport,
        formatFiltersFunc: formatVcFactsFilters,
        specialGroupByColumnRenderers: [
            ...SPECIAL_PRODUCT_METADATA_COLUMNS,
            ...SPECIAL_VC_GROUP_BY_COLUMNS,
            ...REPORT_DATE_COLUMNS,
        ],
        groupByConfig: VC_CHART_FORMATTERS,
        metricsConfig: VC_INVENTORY_HEALTH_LEGACY_METRICS_CONFIG,
        datePrefix: 'report_date',
        timeAggregateFields: REPORT_DATE_AGGREGATE_COLUMNS,
        displayed: userHasVendorCentralReadPermissions,
        lag: 72,
    },
    sc_sales_and_traffic: {
        name: widgetsTranslation.sc_sales_and_traffic.name,
        filters: [
            DATES,
            REGIONS,
            COUNTRIES,
            AD_ACCOUNT_TYPES,
            SC_ACCOUNTS,
            SC_ASINS,
            SC_PRODUCT_TITLES,
            METRIC_FILTERS,
            LABELS,
            RANGE_LAG,
        ],
        apiFunc: getScSalesAndTrafficData,
        exportApiFunc: getScSalesAndTrafficDataExport,
        formatFiltersFunc: formatScFactsFilters,
        specialGroupByColumnRenderers: [
            ...SPECIAL_PRODUCT_METADATA_COLUMNS,
            ...SPECIAL_SC_GROUP_BY_COLUMNS,
            ...REPORT_DATE_COLUMNS,
        ],
        groupByConfig: SC_CHART_FORMATTERS,
        metricsConfig: SC_SALES_AND_TRAFFIC_CONFIG,
        datePrefix: 'report_date',
        timeAggregateFields: REPORT_DATE_AGGREGATE_COLUMNS,
        displayed: userHasSellerCentralReadPermissions,
        lag: 48,
        metricCategoryOrder: ['Retail', 'Advertising + Retail', 'Advertising'],
    },
    sc_all_orders: {
        name: widgetsTranslation.sc_all_orders.name,
        filters: [
            DATES,
            REGIONS,
            COUNTRIES,
            AD_ACCOUNT_TYPES,
            SC_ACCOUNTS,
            SC_ASINS,
            SC_PRODUCT_TITLES,
            METRIC_FILTERS,
            LABELS,
            RANGE_LAG,
        ],
        apiFunc: getScOrderV2Metrics,
        exportApiFunc: getScOrderV2MetricsExport,
        formatFiltersFunc: formatScFactsFilters,
        specialGroupByColumnRenderers: [
            ...SPECIAL_PRODUCT_METADATA_COLUMNS,
            ...SPECIAL_SC_GROUP_BY_COLUMNS,
            ...REPORT_DATE_COLUMNS,
        ],
        groupByConfig: SC_CHART_FORMATTERS,
        timeAggregateFields: REPORT_DATE_AGGREGATE_COLUMNS,
        displayed: userHasSellerCentralReadPermissions,
        metricsConfig: SC_METRICS_AND_METADATA_CONFIG,
        lag: 48,
        metricCategoryOrder: ['Retail', 'Advertising'],
    },
    vc_api_sales_diagnostic: {
        name: widgetsTranslation.vc_api_sales_diagnostic.name,
        filters: [
            DATES,
            COUNTRIES,
            AD_ACCOUNT_TYPES,
            SC_ACCOUNTS,
            PRODUCT_ASINS,
            PRODUCT_TITLES,
            METRIC_FILTERS,
            LABELS,
            VC_PROGRAMS,
            RANGE_LAG,
        ],
        apiFunc: getVcApiSalesDiagnosticManufacturerMetrics,
        exportApiFunc: getVcApiSalesDiagnosticManufacturerMetricsExport,
        formatFiltersFunc: formatVcApiSalesDiagnosticFactsFilters,
        specialGroupByColumnRenderers: [
            ...SPECIAL_PRODUCT_METADATA_COLUMNS,
            ...SPECIAL_VC_API_GROUP_BY_COLUMNS,
            ...REPORT_DATE_COLUMNS,
        ],
        groupByConfig: SC_CHART_FORMATTERS,
        metricsConfig: VC_API_SALES_DIAGNOSTIC_METRICS_CONFIG,
        datePrefix: 'report_date',
        timeAggregateFields: REPORT_DATE_AGGREGATE_COLUMNS,
        displayed: userHasSellerCentralReadPermissions,
        lag: 72,
        metricCategoryOrder: ['Retail', 'Advertising + Retail', 'Advertising'],
    },
    vc_api_sales_diagnostic_sourcing: {
        name: widgetsTranslation.vc_api_sales_diagnostic_sourcing.name,
        filters: [
            DATES,
            COUNTRIES,
            AD_ACCOUNT_TYPES,
            SC_ACCOUNTS,
            PRODUCT_ASINS,
            PRODUCT_TITLES,
            METRIC_FILTERS,
            LABELS,
            VC_PROGRAMS,
            RANGE_LAG,
        ],
        apiFunc: getVcApiSalesDiagnosticSourcingMetrics,
        exportApiFunc: getVcApiSalesDiagnosticSourcingMetricsExport,
        formatFiltersFunc: formatVcApiSalesDiagnosticFactsFilters,
        specialGroupByColumnRenderers: [
            ...SPECIAL_PRODUCT_METADATA_COLUMNS,
            ...SPECIAL_VC_API_GROUP_BY_COLUMNS,
            ...REPORT_DATE_COLUMNS,
        ],
        groupByConfig: SC_CHART_FORMATTERS,
        metricsConfig: VC_API_SALES_DIAGNOSTIC_SOURCING_METRICS_CONFIG,
        datePrefix: 'report_date',
        timeAggregateFields: REPORT_DATE_AGGREGATE_COLUMNS,
        displayed: userHasSellerCentralReadPermissions,
        lag: 72,
        metricCategoryOrder: ['Retail', 'Advertising + Retail', 'Advertising'],
    },
    vc_api_inventory_health: {
        name: widgetsTranslation.vc_api_inventory_health.name,
        filters: [
            DATES,
            COUNTRIES,
            AD_ACCOUNT_TYPES,
            SC_ACCOUNTS,
            PRODUCT_ASINS,
            PRODUCT_TITLES,
            METRIC_FILTERS,
            LABELS,
            VC_PROGRAMS,
            RANGE_LAG,
        ],
        apiFunc: getVcApiInventoryHealthFacts,
        exportApiFunc: getVcApiInventoryHealthFactsExport,
        formatFiltersFunc: formatVcApiSalesDiagnosticFactsFilters,
        specialGroupByColumnRenderers: [
            ...SPECIAL_PRODUCT_METADATA_COLUMNS,
            ...SPECIAL_VC_API_GROUP_BY_COLUMNS,
            ...REPORT_DATE_COLUMNS,
        ],
        groupByConfig: SC_CHART_FORMATTERS,
        metricsConfig: VC_API_INVENTORY_HEALTH_METRICS_CONFIG,
        datePrefix: 'report_date',
        timeAggregateFields: REPORT_DATE_AGGREGATE_COLUMNS,
        displayed: userHasSellerCentralReadPermissions,
        lag: 72,
    },
    vc_api_inventory_manufacturing: {
        name: widgetsTranslation.vc_api_inventory_manufacturing.name,
        filters: [
            DATES,
            COUNTRIES,
            SC_ACCOUNTS,
            PRODUCT_ASINS,
            PRODUCT_TITLES,
            METRIC_FILTERS,
            LABELS,
            RANGE_LAG,
        ],
        apiFunc: getVcApiInventoryManufacturingMetrics,
        exportApiFunc: getVcApiInventoryManufacturingMetricsExport,
        formatFiltersFunc: formatVcApiSalesDiagnosticFactsFilters,
        specialGroupByColumnRenderers: [
            ...SPECIAL_PRODUCT_METADATA_COLUMNS,
            ...SPECIAL_VC_API_GROUP_BY_COLUMNS,
            ...REPORT_DATE_COLUMNS,
        ],
        groupByConfig: SC_CHART_FORMATTERS,
        metricsConfig: VC_API_INVENTORY_MANUFACTURING_METRICS_CONFIG,
        datePrefix: 'report_date',
        timeAggregateFields: REPORT_DATE_AGGREGATE_COLUMNS,
        displayed: userHasSellerCentralReadPermissions,
        lag: 72,
    },
    vc_api_inventory_sourcing: {
        name: widgetsTranslation.vc_api_inventory_sourcing.name,
        filters: [
            DATES,
            COUNTRIES,
            SC_ACCOUNTS,
            PRODUCT_ASINS,
            PRODUCT_TITLES,
            METRIC_FILTERS,
            LABELS,
            RANGE_LAG,
        ],
        apiFunc: getVcApiInventorySourcingMetrics,
        exportApiFunc: getVcApiInventorySourcingMetricsExport,
        formatFiltersFunc: formatVcApiSalesDiagnosticFactsFilters,
        specialGroupByColumnRenderers: [
            ...SPECIAL_PRODUCT_METADATA_COLUMNS,
            ...SPECIAL_VC_API_GROUP_BY_COLUMNS,
            ...REPORT_DATE_COLUMNS,
        ],
        groupByConfig: SC_CHART_FORMATTERS,
        metricsConfig: VC_API_INVENTORY_SOURCING_METRICS_CONFIG,
        datePrefix: 'report_date',
        timeAggregateFields: REPORT_DATE_AGGREGATE_COLUMNS,
        displayed: userHasSellerCentralReadPermissions,
        lag: 72,
    },
    dsp_campaign_facts: {
        name: widgetsTranslation.dsp_campaign_facts.name,
        filters: [
            DATES,
            BRANDS,
            DSP_ADVERTISERS,
            DSP_CREATIVE_NAME,
            DSP_CREATIVE_ID,
            DSP_CREATIVE_TYPE,
            DSP_ORDERS,
            DSP_LINE_ITEMS,
            LABELS,
            RANGE_LAG,
        ],
        apiFunc: getDspCampaignFacts,
        exportApiFunc: getDspCampaignFactsExport,
        formatFiltersFunc: formatDspFactsFilters,
        specialGroupByColumnRenderers: [
            ...SPECIAL_DSP_CAMPAIGN_GROUP_BY_COLUMNS,
            ...REPORT_DATE_COLUMNS,
        ],
        groupByConfig: DSP_CHART_FORMATTERS,
        metricsConfig: DSP_METRICS_CONFIG,
        datePrefix: 'report_date',
        timeAggregateFields: REPORT_DATE_AGGREGATE_COLUMNS,
        displayed: userHasAmazonAdvertisingPermissions,
    },
    dsp_inventory_facts: {
        name: widgetsTranslation.dsp_inventory_facts.name,
        filters: [
            DATES,
            BRANDS,
            DSP_ADVERTISERS,
            DSP_ORDERS,
            DSP_LINE_ITEMS,
            LABELS,
            RANGE_LAG,
        ],
        apiFunc: getDspInventoryFacts,
        exportApiFunc: getDspInventoryFactsExport,
        formatFiltersFunc: formatDspFactsFilters,
        specialGroupByColumnRenderers: [
            ...SPECIAL_DSP_INVENTORY_GROUP_BY_COLUMNS,
            ...REPORT_DATE_COLUMNS,
        ],
        groupByConfig: DSP_CHART_FORMATTERS,
        metricsConfig: DSP_METRICS_CONFIG,
        datePrefix: 'report_date',
        timeAggregateFields: REPORT_DATE_AGGREGATE_COLUMNS,
        displayed: userHasAmazonAdvertisingPermissions,
    },
    dsp_audience_facts: {
        name: widgetsTranslation.dsp_audience_facts.name,
        filters: [
            DATES,
            BRANDS,
            DSP_AUDIENCE_NAME,
            DSP_AUDIENCE_ID,
            DSP_ADVERTISERS,
            DSP_ORDERS,
            DSP_LINE_ITEMS,
            LABELS,
            RANGE_LAG,
        ],
        apiFunc: getDspAudienceFacts,
        exportApiFunc: getDspAudienceFactsExport,
        formatFiltersFunc: formatDspFactsFilters,
        specialGroupByColumnRenderers: [
            ...SPECIAL_DSP_AUDIENCE_GROUP_BY_COLUMNS,
            ...REPORT_DATE_COLUMNS,
        ],
        groupByConfig: DSP_CHART_FORMATTERS,
        datePrefix: 'report_date',
        timeAggregateFields: REPORT_DATE_AGGREGATE_COLUMNS,
        displayed: userHasAmazonAdvertisingPermissions,
    },
    changelog: {
        name: widgetsTranslation.changelog.name,
        filters: [
            DATES,
            REGIONS,
            COUNTRIES,
            BRANDS,
            CAMPAIGNS,
            RESOURCE_TYPES,
            AUTOMATIONS,
        ],
        apiFunc: getChangelogSummary,
        exportApiFunc: getChangelogSummaryExport,
        formatFiltersFunc: formatChangelogFilters,
        specialGroupByColumnRenderers: [
            ...SPECIAL_CHANGELOG_GROUP_BY_COLUMNS,
            ...DATE_TIME_AGGREGATE_COLUMNS,
        ],
        groupByConfig: CHANGELOG_CHART_FORMATTERS,
        metricsConfig: CHANGELOG_METRICS_CONFIG,
        datePrefix: 'date',
        timeAggregateFields: DATE_TIME_AGGREGATE_COLUMNS,
        displayed: returnsTrue,
    },
    asin_usage: {
        name: widgetsTranslation.asin_usage.name,
        filters: ASIN_USAGE_FACTS_FILTER,
        apiFunc: getAsinUsageSummary,
        exportApiFunc: getAsinUsageSummaryExport,
        formatFiltersFunc: formatAsinUsageFilters,
        specialGroupByColumnRenderers: [...SPECIAL_ASIN_USAGE_GROUP_BY_COLUMNS],
        groupByConfig: ASIN_USAGE_GROUP_BY_CONFIG,
        metricsConfig: ASIN_USAGE_METRICS_CONFIG,
        datePrefix: '',
        timeAggregateFields: REPORT_DATE_COLUMNS,
        displayed: userHasCustomerServicePermissions,
    },
    wm_marketplace_order_facts: {
        name: widgetsTranslation.wm_marketplace_order_facts.name,
        filters: [DATES, METRIC_FILTERS, AD_ACCOUNTS],
        apiFunc: getWmMarketplaceOrderFacts,
        exportApiFunc: getWmMarketplaceOrderFactsExport,
        formatFiltersFunc: formatWmMarketplaceFactsFilters,
        specialGroupByColumnRenderers: [
            ...SPECIAL_WM_MARKETPLACE_GROUP_BY_COLUMNS,
            ...REPORT_DATE_COLUMNS,
        ],
        groupByConfig: WM_MARKETPLACE_FORMATTERS,
        metricsConfig: WM_MARKETPLACE_METRICS_CONFIG,
        datePrefix: 'report_date',
        timeAggregateFields: REPORT_DATE_AGGREGATE_COLUMNS,
        displayed: userHasWalmartMarketplaceReadPermissions,
    },
    wm_advertising_campaign_facts: {
        name: widgetsTranslation.wm_advertising_campaign_facts.name,
        filters: [DATES, METRIC_FILTERS, AD_ACCOUNTS],
        apiFunc: getWmAdvertisingCampaignFacts,
        exportApiFunc: getWmAdvertisingCampaignFactsExport,
        formatFiltersFunc: formatWmAdvertisingFactsFilters,
        specialGroupByColumnRenderers: [
            ...SPECIAL_WM_ADVERTISING_CAMPAIGN_GROUP_BY_COLUMNS,
            ...REPORT_DATE_COLUMNS,
        ],
        groupByConfig: WM_ADVERTISING_FORMATTERS,
        metricsConfig: WM_ADVERTISING_METRICS_CONFIG,
        datePrefix: 'report_date',
        timeAggregateFields: REPORT_DATE_AGGREGATE_COLUMNS,
        displayed: userHasWalmartAdvertisingReadPermissions,
    },
    wm_advertising_placement_facts: {
        name: widgetsTranslation.wm_advertising_placement_facts.name,
        filters: [DATES, METRIC_FILTERS, AD_ACCOUNTS],
        apiFunc: getWmAdvertisingPlacementFacts,
        exportApiFunc: getWmAdvertisingPlacementFactsExport,
        formatFiltersFunc: formatWmAdvertisingFactsFilters,
        specialGroupByColumnRenderers: [
            ...SPECIAL_WM_ADVERTISING_PLACEMENT_GROUP_BY_COLUMNS,
            ...REPORT_DATE_COLUMNS,
        ],
        groupByConfig: WM_ADVERTISING_FORMATTERS,
        metricsConfig: WM_ADVERTISING_METRICS_CONFIG,
        datePrefix: 'report_date',
        timeAggregateFields: REPORT_DATE_AGGREGATE_COLUMNS,
        displayed: userHasWalmartAdvertisingReadPermissions,
    },
    wm_advertising_platform_facts: {
        name: widgetsTranslation.wm_advertising_platform_facts.name,
        filters: [DATES, METRIC_FILTERS, AD_ACCOUNTS],
        apiFunc: getWmAdvertisingPlatformFacts,
        exportApiFunc: getWmAdvertisingPlatformFactsExport,
        formatFiltersFunc: formatWmAdvertisingFactsFilters,
        specialGroupByColumnRenderers: [
            ...SPECIAL_WM_ADVERTISING_PLATFORM_GROUP_BY_COLUMNS,
            ...REPORT_DATE_COLUMNS,
        ],
        groupByConfig: WM_ADVERTISING_FORMATTERS,
        metricsConfig: WM_ADVERTISING_METRICS_CONFIG,
        datePrefix: 'report_date',
        timeAggregateFields: REPORT_DATE_AGGREGATE_COLUMNS,
        displayed: userHasWalmartAdvertisingReadPermissions,
    },
    wm_advertising_item_facts: {
        name: widgetsTranslation.wm_advertising_item_facts.name,
        filters: [DATES, METRIC_FILTERS, AD_ACCOUNTS, CAMPAIGNS],
        apiFunc: getWmAdvertisingItemFacts,
        exportApiFunc: getWmAdvertisingItemFactsExport,
        formatFiltersFunc: formatWmAdvertisingFactsFilters,
        specialGroupByColumnRenderers: [
            ...SPECIAL_WM_ADVERTISING_ITEM_GROUP_BY_COLUMNS,
            ...REPORT_DATE_COLUMNS,
        ],
        groupByConfig: WM_ADVERTISING_FORMATTERS,
        metricsConfig: WM_ADVERTISING_METRICS_CONFIG,
        datePrefix: 'report_date',
        timeAggregateFields: REPORT_DATE_AGGREGATE_COLUMNS,
        displayed: userHasWalmartAdvertisingReadPermissions,
    },
    wm_advertising_keyword_facts: {
        name: widgetsTranslation.wm_advertising_keyword_facts.name,
        filters: [DATES, METRIC_FILTERS, AD_ACCOUNTS],
        apiFunc: getWmAdvertisingKeywordFacts,
        exportApiFunc: getWmAdvertisingKeywordFactsExport,
        formatFiltersFunc: formatWmAdvertisingFactsFilters,
        specialGroupByColumnRenderers: [
            ...SPECIAL_WM_ADVERTISING_KEYWORD_GROUP_BY_COLUMNS,
            ...REPORT_DATE_COLUMNS,
        ],
        groupByConfig: WM_ADVERTISING_FORMATTERS,
        metricsConfig: WM_ADVERTISING_METRICS_CONFIG,
        datePrefix: 'report_date',
        timeAggregateFields: REPORT_DATE_AGGREGATE_COLUMNS,
        displayed: userHasWalmartAdvertisingReadPermissions,
    },
    ams_stream_facts: {
        name: widgetsTranslation.ams_stream_facts.name,
        filters: [
            DATES,
            REGIONS,
            COUNTRIES,
            FACT_TYPES,
            LABELS,
            BRANDS,
            PORTFOLIOS,
            CAMPAIGNS,
            CAMPAIGN_STATES,
            CAMPAIGN_TARGETING_TYPES,
            CAMPAIGN_AD_FORMATS,
            ADVANCED_AUTOMATIONS,
            METRIC_FILTERS,
            RANGE_LAG,
        ],
        apiFunc: getAmsStreamFacts,
        exportApiFunc: getAmsStreamFactsExport,
        formatFiltersFunc: formatCampaignFactsFilters,
        specialGroupByColumnRenderers: [
            ...REPORT_DATE_COLUMNS,
            ...REPORT_DATE_TIME_COLUMNS,
            ...SPECIAL_TARGET_GROUP_BY_COLUMNS,
            ...SPECIAL_CAMPAIGN_GROUP_BY_COLUMNS,
            ...SPECIAL_PORTFOLIO_GROUP_BY_COLUMNS,
            ...SPECIAL_KEYWORD_GROUP_BY_COLUMNS,
            ...SPECIAL_BRAND_GROUP_BY_COLUMNS,
        ],
        groupByConfig: {
            ...AMS_CHART_FORMATTERS,
        },
        metricsConfig: STREAM_SUPPORTED_AMS_METRICS_CONFIG,
        datePrefix: 'report_date',
        timeAggregateFields: REPORT_DATE_TIME_AGGREGATE_COLUMNS,
        displayed: userHasAmazonAdvertisingPermissions,
    },
    org_facts: {
        name: widgetsTranslation.org_facts.name,
        filters: [
            DATES,
            COUNTRIES,
            BRANDS,
            ORGANIZATIONS,
            SC_ACCOUNTS,
            DSP_ADVERTISERS,
            METRIC_FILTERS,
            RANGE_LAG,
        ],
        apiFunc: getOrgFacts,
        exportApiFunc: getOrgFactsExport,
        formatFiltersFunc: formatOrgFactsFilters,
        specialGroupByColumnRenderers: [
            ...SPECIAL_ORG_FACT_GROUP_BY_COLUMNS,
            ...REPORT_DATE_COLUMNS,
        ],
        groupByConfig: ORG_FACT_CHART_FORMATTERS,
        metricsConfig: ORG_FACT_METRICS_CONFIG,
        datePrefix: 'report_date',
        timeAggregateFields: REPORT_DATE_AGGREGATE_COLUMNS,
        displayed: (featurePermissions: UserPermission[]) =>
            userHasAmazonAdvertisingPermissions(featurePermissions) &&
            userHasSellerCentralReadPermissions(featurePermissions),
        metricCategoryOrder: ['Retail', 'Advertising + Retail', 'Advertising'],
    },
    segment_facts: {
        name: widgetsTranslation.segment_facts.name,
        filters: [...SEGMENT_FACTS_FILTER, RANGE_LAG],
        apiFunc: getSegmentFacts,
        exportApiFunc: getSegmentFactsExport,
        formatFiltersFunc: formatSegmentFactsFilters,
        specialGroupByColumnRenderers: [
            ...SPECIAL_SEGMENT_FACTS_GROUP_BY_COLUMNS,
            ...REPORT_DATE_WEEKLY_GRAIN_COLUMNS,
        ],
        metricDependencies: {
            product_id: [
                variant_count__max({
                    excludeLastPeriod: true,
                }),
            ],
        },
        groupByConfig: SEGMENT_FACTS_GROUP_BY_CONFIG,
        metricsConfig: SEGMENT_FACTS_METRICS_CONFIG,
        timeAggregateFields: REPORT_DATE_AGGREGATE_COLUMNS,
        displayed: userHasReadOrManageSegmentPermissions,
        datePrefix: 'report_date',
        lag: calculateSegmentLag(),
    },
    sb_purchased_product_facts: {
        name: widgetsTranslation.sb_purchased_product_facts.name,
        filters: [
            DATES,
            REGIONS,
            COUNTRIES,
            LABELS,
            BRANDS,
            PORTFOLIOS,
            CAMPAIGNS,
            CAMPAIGN_STATES,
            CAMPAIGN_TARGETING_TYPES,
            PRODUCT_ASINS,
            METRIC_FILTERS,
            RANGE_LAG,
        ],
        apiFunc: getSBPurchasedProductsFacts,
        exportApiFunc: getSBPurchasedProductsFactsExport,
        formatFiltersFunc: formatSBPurchasedProductsFactsFilters,
        specialGroupByColumnRenderers: [
            ...SPECIAL_SB_PURCHASED_PRODUCTS_GROUP_BY_COLUMNS,
            ...SPECIAL_BRAND_GROUP_BY_COLUMNS,
            ...REPORT_DATE_COLUMNS,
        ],
        groupByConfig: SB_PURCHASED_PRODUCTS_GROUP_BY_CONFIG,
        metricsConfig: SB_PURCHASED_PRODUCTS_METRICS_CONFIG,
        datePrefix: 'report_date',
        timeAggregateFields: REPORT_DATE_AGGREGATE_COLUMNS,
        displayed: userHasAmazonAdvertisingPermissions,
    },
}

interface DashboardPageParams {
    [key: string]: string
}

const formatDashboardPageFilters = (
    filters: FiltersState,
    datePrefix = 'report_date',
    hasHourlyData = false
): DashboardPageParams => {
    const params: DashboardPageParams = {}

    const dates = filters[DATES]
    let { start, end } = formatDateFromFilter(dates)

    if (!!start && !!end) {
        const dateRangeInDays = moment(end).diff(moment(start), 'days')
        if (hasHourlyData && dateRangeInDays < MAX_DAYS_RANGE_FOR_HOURLY_DATA) {
            start = `${start}T00:00`
            end = `${end}T23:59`
        }

        params[`${datePrefix}_min`] = start
        params[`${datePrefix}_max`] = end
    }
    return params
}

export const PAGE_DASHBOARD_CONFIG: {
    [key: string]: {
        dataSource?: WidgetDataSourceKey
        formatPageFiltersFunc:
            | ((filters: FiltersState) => any)
            | ((filters: FiltersState, hasHourlyData?: boolean) => any)
            | ((
                  filters: FiltersState,
                  datePrefix?: string,
                  hasHourlyData?: boolean
              ) => any)
    }
} = {
    [KEYWORD_PAGE]: {
        dataSource: 'keyword_facts',
        formatPageFiltersFunc: formatKeywordFactsFilters,
    },
    [CAMPAIGN_PAGE]: {
        dataSource: 'campaign_facts',
        formatPageFiltersFunc: formatCampaignFactsFilters,
    },
    [CAMPAIGN_PRODUCT_AD_PAGE]: {
        dataSource: 'product_ad_facts',
        formatPageFiltersFunc: formatProductAdFactsFilters,
    },
    [PRODUCT_PAGE]: {
        dataSource: 'product_ad_facts',
        formatPageFiltersFunc: formatProductAdFactsFilters,
    },
    [PRODUCT_AD_PAGE]: {
        dataSource: 'product_ad_facts',
        formatPageFiltersFunc: formatProductAdFactsFilters,
    },
    [AD_ACCOUNT_PAGE]: {
        dataSource: 'campaign_facts',
        formatPageFiltersFunc: formatCampaignFactsFilters,
    },
    [HOME_PAGE]: {
        formatPageFiltersFunc: formatDashboardPageFilters,
    },
    [DASHBOARD_PAGE]: {
        formatPageFiltersFunc: formatDashboardPageFilters,
    },
    [DASHBOARD_PRINT_PAGE]: {
        formatPageFiltersFunc: formatDashboardPageFilters,
    },
    [PORTFOLIO_PAGE]: {
        dataSource: 'campaign_facts',
        formatPageFiltersFunc: formatCampaignFactsFilters,
    },
    [AD_GROUP_PAGE]: {
        dataSource: 'ad_group_facts',
        formatPageFiltersFunc: formatAdGroupFactsFilters,
    },
    [DSP_ADVERTISER_PAGE]: {
        dataSource: 'dsp_campaign_facts',
        formatPageFiltersFunc: formatDspFactsFilters,
    },
    [DSP_ORDER_PAGE]: {
        dataSource: 'dsp_campaign_facts',
        formatPageFiltersFunc: formatDspFactsFilters,
    },
    [DSP_LINE_ITEM_PAGE]: {
        dataSource: 'dsp_campaign_facts',
        formatPageFiltersFunc: formatDspFactsFilters,
    },
    [SEGMENT_PAGE]: {
        dataSource: 'segment_facts',
        formatPageFiltersFunc: formatSegmentFactsFilters,
    },
}

const DATA_SOURCE_METADATA_KEY_TO_WIDGET_DATASOURCE_MAP: Record<
    DataSourceMetadataKey,
    WidgetDataSourceKey
> = {
    ad_group_facts: 'ad_group_facts',
    // organization
    org_facts: 'org_facts',
    // amazon ads
    ams_stream_facts: 'ams_stream_facts',
    campaign_facts: 'campaign_facts',
    keyword_facts: 'keyword_facts',
    product_ad_facts: 'product_ad_facts',
    target_facts: 'target_facts',
    sb_purchased_product_facts: 'sb_purchased_product_facts',
    // dsp
    dsp_campaign_facts: 'dsp_campaign_facts',
    dsp_inventory_facts: 'dsp_inventory_facts',
    dsp_audience_facts: 'dsp_audience_facts',
    // market intelligence
    sov: 'sov_search_results',
    segment_asin_sales_estimate_segmentizer_facts: 'segment_facts',
    // sc sp-api
    seller_central_sales_and_traffic_facts: 'sc_sales_and_traffic',
    seller_central_order_facts: 'sc_all_orders',
    // vc sp-api
    vendor_central_selling_partner_api_inventory_health_facts:
        'vc_api_inventory_health',
    vendor_central_selling_partner_api_inventory_manufacturing_facts:
        'vc_api_inventory_manufacturing',
    vendor_central_selling_partner_api_inventory_sourcing_facts:
        'vc_api_inventory_sourcing',
    vendor_central_selling_partner_api_sales_diagnostic_facts:
        'vc_api_sales_diagnostic',
    vendor_central_selling_partner_api_sales_diagnostic_sourcing_facts:
        'vc_api_sales_diagnostic_sourcing',
    // vc legacy (scraped)
    vendor_central_manufacturer_sales_diagnostic_facts: 'vc_sales_diagnostic',
    vendor_central_source_sales_diagnostic_facts: 'vc_source_sales_diagnostic',
    vendor_central_traffic_diagnostic_facts: 'vc_traffic_diagnostic',
    vendor_central_inventory_health_facts: 'vc_inventory_health',
    // walmart
    walmart_advertising_campaign_facts: 'wm_advertising_campaign_facts',
    walmart_advertising_item_facts: 'wm_advertising_item_facts',
    walmart_advertising_keyword_facts: 'wm_advertising_keyword_facts',
    walmart_advertising_placement_facts: 'wm_advertising_placement_facts',
    walmart_advertising_platform_facts: 'wm_advertising_platform_facts',
    walmart_marketplace_order_facts: 'wm_marketplace_order_facts',
    // cobalt
    asin_usage_facts: 'asin_usage',
    changelog: 'changelog',
}

const DATA_SOURCE_WIDGET_TO_METADATA_KEY_MAP = Object.keys(
    DATA_SOURCE_METADATA_KEY_TO_WIDGET_DATASOURCE_MAP
).reduce(
    (acc, key) => {
        const widgetDataSourceKey =
            DATA_SOURCE_METADATA_KEY_TO_WIDGET_DATASOURCE_MAP[
                key as DataSourceMetadataKey
            ]
        if (widgetDataSourceKey) {
            acc[widgetDataSourceKey] = key as DataSourceMetadataKey
        }
        return acc
    },
    {} as Record<WidgetDataSourceKey, DataSourceMetadataKey>
)

export function getMetadataKey(
    dataSourceKey: WidgetDataSourceKey
): DataSourceMetadataKey | undefined {
    if (dataSourceKey !== '' && dataSourceKey) {
        return DATA_SOURCE_WIDGET_TO_METADATA_KEY_MAP[dataSourceKey]
    }
    return undefined
}

export function getWidgetDataSourceKey(
    metadataKey: string
): WidgetDataSourceKey | undefined {
    return DATA_SOURCE_METADATA_KEY_TO_WIDGET_DATASOURCE_MAP[
        metadataKey as DataSourceMetadataKey
    ]
}
