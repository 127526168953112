import { ReactElement } from 'react'

import { Button } from 'antd'
import { Formik, Field, ErrorMessage } from 'formik'
import noop from 'lodash/noop'
import { object, string } from 'yup'

import { fetchOrganizationLabelsRequest } from 'actions/ui/app'
import { CreateResourceButton } from 'components/Buttons'
import { FormikInput, FormikColorPicker } from 'components/formik'
import { OptionalFormLabel } from 'components/OptionalFormLabel'
import { useCerebroApiRequest, useAction } from 'hooks'
import { createLabel } from 'services/cerebroApi/orgScope/resourceApi'
import { CerebroResourceResponse, Label } from 'types'
import message from 'utilities/message'

import CreateResourceModal from '../CreateResourceModal/CreateResourceModal'

interface Props {
    isModalVisible: boolean
    closeModal: () => void
    onSuccess?: (res: CerebroResourceResponse<Label>) => void
}

interface FormValues {
    name: string
    description?: string
    color: string
}

const validationSchema = object().shape({
    name: string()
        .matches(/^[^,]+$/, {
            excludeEmptyString: true,
            message: 'Commas are not allowed in Label Names',
        })
        .required()
        .label('Name'),
    color: string().required().label('Color'),
})

function CreateLabelModal({
    isModalVisible,
    closeModal,
    onSuccess = noop,
}: Props): ReactElement {
    const makeCerebroApiRequest = useCerebroApiRequest()
    const refreshLabels = useAction(fetchOrganizationLabelsRequest)

    return (
        <Formik
            enableReinitialize
            initialValues={{
                name: '',
                description: '',
                color: '',
            }}
            validationSchema={validationSchema}
            onSubmit={async (values: FormValues, { resetForm }) => {
                await makeCerebroApiRequest({
                    request: createLabel(values),
                    onRequestSuccess: (response) => {
                        message.success('Successfully created label')
                        resetForm()
                        closeModal()
                        onSuccess(response)
                        refreshLabels()
                    },
                })
            }}
        >
            {({ isSubmitting, handleSubmit, resetForm }) => (
                <CreateResourceModal
                    title="Add New Label"
                    width={700}
                    open={isModalVisible}
                    footer={[
                        <Button
                            key={1}
                            onClick={() => {
                                resetForm()
                                closeModal()
                            }}
                            disabled={isSubmitting}
                        >
                            Cancel
                        </Button>,
                        <CreateResourceButton
                            disabled={isSubmitting}
                            loading={isSubmitting}
                            key={2}
                            onClick={() => handleSubmit()}
                        >
                            Add Label
                        </CreateResourceButton>,
                    ]}
                    onCancel={() => {
                        resetForm()
                        closeModal()
                    }}
                >
                    <div className="d-flex">
                        <div className="flex-grow-1">
                            <div className="fg-form-control">
                                <div className="fg-control-label">
                                    Label Name:
                                </div>
                                <Field name="name" component={FormikInput} />
                                <ErrorMessage
                                    name="name"
                                    component="div"
                                    className="fg-control-text is-error"
                                />
                            </div>

                            <div className="fg-form-control">
                                <div className="fg-control-label">
                                    <OptionalFormLabel fieldName="Description" />
                                </div>
                                <Field
                                    name="description"
                                    component={FormikInput}
                                />
                            </div>
                        </div>

                        <div className="ml-3">
                            <Field name="color" component={FormikColorPicker} />
                            <ErrorMessage
                                name="color"
                                component="div"
                                className="fg-control-text is-error"
                            />
                        </div>
                    </div>
                </CreateResourceModal>
            )}
        </Formik>
    )
}

export default CreateLabelModal
