import {
    makeCountMetricFieldFunc,
    makeCurrencyMetricFieldFunc,
    makePercentageMetricFieldFunc,
} from './localUtils'

const defaultCountMetricOptions = {
    deltas: ['change', 'percentage_change'],
}

const defaultCurrencyAmountMetricOptions = {
    deltas: ['change', 'percentage_change'],
}

const defaultPercentageMetricOptions = {
    deltas: ['change'],
}

export const units_ordered__sum = makeCountMetricFieldFunc({
    ...defaultCountMetricOptions,
    name: 'Units Ordered',
    shortName: 'Units Ordered',
    id: 'units_ordered__sum',
})

export const units_ordered_b2b__sum = makeCountMetricFieldFunc({
    ...defaultCountMetricOptions,
    name: 'Units Ordered B2B',
    shortName: 'Units Ordered B2B',
    id: 'units_ordered_b2b__sum',
})

export const total_order_items__sum = makeCountMetricFieldFunc({
    name: 'Total Order Items',
    shortName: 'Total Order Items',
    ...defaultCountMetricOptions,
    id: 'total_order_items__sum',
})

export const total_order_items_b2b__sum = makeCountMetricFieldFunc({
    ...defaultCountMetricOptions,
    name: 'Total Order Items B2B',
    shortName: 'Total Order Items B2B',
    id: 'total_order_items_b2b__sum',
})

export const browser_sessions__sum = makeCountMetricFieldFunc({
    ...defaultCountMetricOptions,
    name: 'Browser Sessions',
    shortName: 'Browser Sessions',
    id: 'browser_sessions__sum',
})

export const mobile_app_sessions__sum = makeCountMetricFieldFunc({
    ...defaultCountMetricOptions,
    name: 'Mobile App Sessions',
    shortName: 'Mobile App Sessions',
    id: 'mobile_app_sessions__sum',
})

export const sessions__sum = makeCountMetricFieldFunc({
    ...defaultCountMetricOptions,
    name: 'Sessions',
    shortName: 'Sessions',
    id: 'sessions__sum',
})

export const browser_page_views__sum = makeCountMetricFieldFunc({
    ...defaultCountMetricOptions,
    name: 'Browser Page Views',
    shortName: 'Browser Page Views',
    id: 'browser_page_views__sum',
})

export const mobile_app_page_views__sum = makeCountMetricFieldFunc({
    ...defaultCountMetricOptions,
    name: 'Mobile App Page Views',
    shortName: 'Mobile App Page Views',
    id: 'mobile_app_page_views__sum',
})

export const page_views__sum = makeCountMetricFieldFunc({
    ...defaultCountMetricOptions,
    name: 'Page Views',
    shortName: 'Page Views',
    id: 'page_views__sum',
})

export const buy_box_wins__sum = makeCountMetricFieldFunc({
    ...defaultCountMetricOptions,
    name: 'Buy Box Wins (Featured Offers)',
    shortName: 'Buy Box Wins',
    localDefinition:
        'Number of times in which the product was the featured offer (buy box winner).',
    id: 'buy_box_wins__sum',
})

export const buy_box_win_percentage = makePercentageMetricFieldFunc({
    ...defaultPercentageMetricOptions,
    name: 'Buy Box Percentage (Featured Offer)',
    shortName: 'Buy Box %',
    isRatio: true,
    localDefinition:
        'Percentage of page views in which the product was the featured offer (buy box winner).',
    id: 'buy_box_win_percentage',
})

export const ordered_product_sales__sum = makeCurrencyMetricFieldFunc({
    ...defaultCurrencyAmountMetricOptions,
    name: 'Ordered Product Sales',
    shortName: 'Ordered Product Sales',
    id: 'ordered_product_sales__sum',
})

export const ordered_product_sales_b2b__sum = makeCurrencyMetricFieldFunc({
    ...defaultCurrencyAmountMetricOptions,
    name: 'Ordered Product Sales B2B',
    shortName: 'Ordered Product Sales B2B',
    id: 'ordered_product_sales_b2b__sum',
})

export const order_count__sum = makeCountMetricFieldFunc({
    ...defaultCountMetricOptions,
    name: 'Order Count',
    shortName: 'Order Count',
    id: 'order_count__sum',
})

export const total_sales__sum = makeCurrencyMetricFieldFunc({
    ...defaultCurrencyAmountMetricOptions,
    name: 'Total Sales',
    shortName: 'Total Sales',
    id: 'total_sales__sum',
})

export const total_advertising_cost_of_sales = makePercentageMetricFieldFunc({
    ...defaultPercentageMetricOptions,
    name: 'Total Advertising Cost of Sales',
    shortName: 'TACoS',
    isInverse: true,
    id: 'total_advertising_cost_of_sales',
})

export const total_aov = makeCurrencyMetricFieldFunc({
    ...defaultCurrencyAmountMetricOptions,
    name: 'Total Average Order Value',
    shortName: 'Total AOV',
    localDefinition: 'Total Sales / Order Count',
    isRatio: true,
    id: 'total_aov',
})

export const total_aov_order_items = makeCurrencyMetricFieldFunc({
    ...defaultCurrencyAmountMetricOptions,
    name: 'Total Average Order Item Value',
    shortName: 'Total AOV (Order Items)',
    localDefinition: 'Total Sales / Order Items',
    isRatio: true,
    id: 'total_aov_order_items',
})

export const total_average_ordered_unit_value = makeCurrencyMetricFieldFunc({
    ...defaultCurrencyAmountMetricOptions,
    name: 'Average Selling Price',
    shortName: 'Average Selling Price',
    localDefinition: 'Total Sales / Units Ordered',
    isRatio: true,
    id: 'total_average_ordered_unit_value',
})

export const total_aov_shipped_order_items = makeCurrencyMetricFieldFunc({
    ...defaultCurrencyAmountMetricOptions,
    name: 'Total Average Order Item Value (Shipped)',
    shortName: 'Total AOV (Order Item Shipped)',
    localDefinition: 'Total Sales / Order Items (Shipped)',
    isRatio: true,
    id: 'total_aov_shipped_order_items',
})

export const total_average_ordered_unit_value_shipped =
    makeCurrencyMetricFieldFunc({
        ...defaultCurrencyAmountMetricOptions,
        name: 'Average Selling Price (Shipped)',
        shortName: 'Average Selling Price (Shipped)',
        localDefinition: 'Total Sales / Units Ordered (Shipped)',
        isRatio: true,
        id: 'total_average_ordered_unit_value_shipped',
    })

export const total_aov_b2b_order_items = makeCurrencyMetricFieldFunc({
    ...defaultCurrencyAmountMetricOptions,
    name: 'Total Average B2B Order Items Value',
    shortName: 'Total AOV (B2B Order Items)',
    localDefinition: 'Total Sales B2B / Order Items B2B',
    isRatio: true,
    id: 'total_aov_b2b_order_items',
})

export const total_average_ordered_unit_value_b2b = makeCurrencyMetricFieldFunc(
    {
        ...defaultCurrencyAmountMetricOptions,
        name: 'Total Average B2B Units Ordered Value',
        shortName: 'Total AOV (B2B Units Ordered)',
        localDefinition: 'Total Sales B2B / Units Ordered B2B',
        isRatio: true,
        id: 'total_average_ordered_unit_value_b2b',
    }
)

const base_total_cpa_currency = {
    ...defaultCurrencyAmountMetricOptions,
    name: 'Total Cost per Acquisition',
    shortName: 'Total CPA',
    helpText: 'Cost / Order Count',
    isInverse: true,
    isRatio: true,
    id: 'total_cpa',
}

export const total_cpa = makeCurrencyMetricFieldFunc({
    ...base_total_cpa_currency,
})

export const total_cpa_order_items = makeCurrencyMetricFieldFunc({
    ...base_total_cpa_currency,
    name: 'Total Cost per Acquisition (Order Items)',
    shortName: 'Total CPA (Order Items)',
    localDefinition: 'Cost / Total Order Items',
    id: 'total_cpa_order_items',
})

export const total_cpa_units = makeCurrencyMetricFieldFunc({
    ...base_total_cpa_currency,
    name: 'Total Cost per Acquisition (Units Ordered)',
    shortName: 'Total CPA (Units Ordered)',
    localDefinition: 'Cost / Units Ordered',
    id: 'total_cpa_units',
})

export const conversion_rate_sessions = makePercentageMetricFieldFunc({
    ...defaultPercentageMetricOptions,
    name: 'Conversion Rate (Sessions)',
    shortName: 'CVR (Sessions)',
    localDefinition: 'Order Count / Sessions',
    isRatio: true,
    id: 'total_cvr',
})

export const total_cvr_order_items = makePercentageMetricFieldFunc({
    ...defaultPercentageMetricOptions,
    name: 'Order Item Session Percentage',
    shortName: 'Order Item Session Percentage',
    localDefinition: 'Order Items / Sessions',
    isRatio: true,
    id: 'total_cvr_order_items',
})

export const total_cvr_units = makePercentageMetricFieldFunc({
    ...defaultPercentageMetricOptions,
    name: 'Unit Session Percentage',
    shortName: 'Unit Session Percentage',
    localDefinition: 'Units Ordered / Sessions',
    isRatio: true,
    id: 'total_cvr_units',
})

export const cost__sum = makeCurrencyMetricFieldFunc({
    ...defaultCurrencyAmountMetricOptions,
    name: 'Cost',
    shortName: 'Advertising Cost',
    localDefinition:
        'The total cost value of clicks generated by related product ads.',
    isInverse: true,
    id: 'cost__sum',
})

export const attributed_sales_14_day__sum = makeCurrencyMetricFieldFunc({
    ...defaultCurrencyAmountMetricOptions,
    name: 'Attributed Sales (14 Day)',
    shortName: 'Ad Sales (14 Day)',
    localDefinition:
        'The aggregate value of sales generated whenever a shopper purchases any of your brand’s products within 14 days of clicking on your ad. Note: Sales are attributed to the last ad clicked to avoid double-counting sales and may take up to 72 hours to display after a purchase has been made. Sales value is based on sales price to the end customer, not to Amazon.',
    id: 'attributed_sales_14_day__sum',
})

export const attributed_sales_14_day_same_sku__sum =
    makeCurrencyMetricFieldFunc({
        ...defaultCurrencyAmountMetricOptions,
        name: 'Attributed Sales (14 Day Same SKU)',
        shortName: 'Ad Sales (14 Day Same SKU)',
        localDefinition:
            'The aggregate value of sales generated whenever a shopper purchases the advertised product within 14 days of clicking on your ad. Note: Sales are attributed to the last ad clicked to avoid double-counting sales and may take up to 72 hours to display after a purchase has been made. Sales value is based on sales price to the end customer, not to Amazon.',
        id: 'attributed_sales_14_day_same_sku__sum',
    })

export const total_roas = makeCurrencyMetricFieldFunc({
    ...defaultCurrencyAmountMetricOptions,
    name: 'TRoAS ($)',
    shortName: 'TRoAS ($)',
    localDefinition:
        'The average revenue earned for each unit spent on advertising. TRoAS is calculated by dividing total sales by total cost of associated ads. Total Sales is as reported by Seller Central.',
    id: 'total_roas',
})

export const ad_contribution_1_day = makePercentageMetricFieldFunc({
    ...defaultPercentageMetricOptions,
    name: 'Ad Contribution (1 Day)',
    shortName: 'Ad Contribution (1 Day)',
    id: 'ad_contribution_1_day',
    localDefinition:
        'Percentage of sales that are attributed to advertising, within a 1 day window',
})

export const ad_contribution_1_day_same_sku = makePercentageMetricFieldFunc({
    ...defaultPercentageMetricOptions,
    name: 'Ad Contribution Same Sku (1 Day)',
    shortName: 'Ad Contribution Same Sku (1 Day)',
    id: 'ad_contribution_1_day_same_sku',
    localDefinition:
        'Percentage of sales that are attributed to advertising, within a 1 day window',
})

export const ad_contribution_14_day = makePercentageMetricFieldFunc({
    ...defaultPercentageMetricOptions,
    name: 'Ad Contribution (14 Day)',
    shortName: 'Ad Contribution (14 Day)',
    id: 'ad_contribution_14_day',
    localDefinition:
        'Percentage of sales that are attributed to advertising, within a 14 day window',
})

export const ad_contribution_14_day_same_sku = makePercentageMetricFieldFunc({
    ...defaultPercentageMetricOptions,
    name: 'Ad Contribution Same Sku (14 Day)',
    shortName: 'Ad Contribution Same Sku (14 Day)',
    id: 'ad_contribution_14_day_same_sku',
    localDefinition:
        'Percentage of sales that are attributed to advertising, within a 14 day window',
})

export const organic_sales_1_day__sum = makeCurrencyMetricFieldFunc({
    ...defaultCurrencyAmountMetricOptions,
    name: 'Organic Sales (1 Day) ($)',
    shortName: 'Organic Sales (1 Day) ($)',
    localDefinition:
        'The difference between total sales and advertising 1-day attributed sales.',
    id: 'organic_sales_1_day__sum',
})

export const organic_sales_1_day_same_sku__sum = makeCurrencyMetricFieldFunc({
    ...defaultCurrencyAmountMetricOptions,
    name: 'Organic Sales Same Sku (1 Day) ($)',
    shortName: 'Organic Sales Same Sku (1 Day) ($)',
    localDefinition:
        'The difference between total sales and advertising 1-day attributed sales of the same sku.',
    id: 'organic_sales_1_day_same_sku__sum',
})

export const organic_sales_14_day__sum = makeCurrencyMetricFieldFunc({
    ...defaultCurrencyAmountMetricOptions,
    name: 'Organic Sales (14 Day) ($)',
    shortName: 'Organic Sales (14 Day) ($)',
    localDefinition:
        'The difference between total sales and advertising 14-day attributed sales.',
    id: 'organic_sales_14_day__sum',
})

export const organic_sales_14_day_same_sku__sum = makeCurrencyMetricFieldFunc({
    ...defaultCurrencyAmountMetricOptions,
    name: 'Organic Sales Same Sku (14 Day) ($)',
    shortName: 'Organic Sales Same Sku (14 Day) ($)',
    localDefinition:
        'The difference between total sales and advertising 14-day attributed sales of the same sku.',
    id: 'organic_sales_14_day_same_sku__sum',
})

export const item_price__sum = makeCurrencyMetricFieldFunc({
    ...defaultCurrencyAmountMetricOptions,
    name: 'Item Price',
    shortName: 'Item Price',
    localDefinition: "Summation of 'Item Price'",
    id: 'item_price__sum',
})

export const item_promotion_discount__sum = makeCurrencyMetricFieldFunc({
    ...defaultCurrencyAmountMetricOptions,
    name: 'Item Promotion Discount',
    shortName: 'Item Promotion Discount',
    localDefinition: "Summation of 'Item Promotion Discount'",
    id: 'item_promotion_discount__sum',
})

export const item_tax__sum = makeCurrencyMetricFieldFunc({
    ...defaultCurrencyAmountMetricOptions,
    name: 'Item Tax',
    shortName: 'Item Tax',
    localDefinition: "Summation of 'Item Tax'",
    id: 'item_tax__sum',
})

export const ship_promotion_discount__sum = makeCurrencyMetricFieldFunc({
    ...defaultCurrencyAmountMetricOptions,
    name: 'Ship Promotion Discount',
    shortName: 'Ship Promotion Discount',
    localDefinition: "Summation of 'Ship Promotion Discount'",
    id: 'ship_promotion_discount__sum',
})

export const shipping_price__sum = makeCurrencyMetricFieldFunc({
    ...defaultCurrencyAmountMetricOptions,
    name: 'Shipping Price',
    shortName: 'Shipping Price',
    localDefinition: "Summation of 'Shipping Price'",
    id: 'shipping_price__sum',
})

export const shipping_tax__sum = makeCurrencyMetricFieldFunc({
    ...defaultCurrencyAmountMetricOptions,
    name: 'Shipping Tax',
    shortName: 'Shipping Tax',
    localDefinition: "Summation of 'Shipping Tax'",
    id: 'shipping_tax__sum',
})

export const gift_wrap_price__sum = makeCurrencyMetricFieldFunc({
    ...defaultCurrencyAmountMetricOptions,
    name: 'Gift Wrap Price',
    shortName: 'Gift Wrap Price',
    localDefinition: "Summation of 'Gift Wrap Price'",
    id: 'gift_wrap_price__sum',
})

export const gift_wrap_tax__sum = makeCurrencyMetricFieldFunc({
    ...defaultCurrencyAmountMetricOptions,
    name: 'Gift Wrap Tax',
    shortName: 'Gift Wrap Tax',
    localDefinition: "Summation of 'Gift Wrap Tax'",
    id: 'gift_wrap_tax__sum',
})

export const vat_exclusive_item_price__sum = makeCurrencyMetricFieldFunc({
    ...defaultCurrencyAmountMetricOptions,
    name: 'Vat Exclusive Item Price',
    shortName: 'Vat Exclusive Item Price',
    localDefinition: "Summation of 'Vat Exclusive Item Price'",
    id: 'vat_exclusive_item_price__sum',
})

export const vat_exclusive_giftwrap_price__sum = makeCurrencyMetricFieldFunc({
    ...defaultCurrencyAmountMetricOptions,
    name: 'Vat Exclusive Giftwrap Price',
    shortName: 'Vat Exclusive Giftwrap Price',
    localDefinition: "Summation of 'Vat Exclusive Giftwrap Price'",
    id: 'vat_exclusive_giftwrap_price__sum',
})

export const vat_exclusive_shipping_price__sum = makeCurrencyMetricFieldFunc({
    ...defaultCurrencyAmountMetricOptions,
    name: 'Vat Exclusive Shipping Price',
    shortName: 'Vat Exclusive Shipping Price',
    localDefinition: "Summation of 'Vat Exclusive Shipping Price'",
    id: 'vat_exclusive_shipping_price__sum',
})

export const quantity__sum = makeCountMetricFieldFunc({
    ...defaultCountMetricOptions,
    name: 'Quantity',
    shortName: 'Quantity',
    localDefinition: "Summation of 'Order Quantity'",
    id: 'quantity__sum',
})
