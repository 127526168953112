import { handleActions } from 'redux-actions'

import { labelDspOrdersTableColumnsConfig } from 'configuration/tables'
import { DATES, BRANDS, DSP_ADVERTISERS, DSP_ORDERS } from 'const/filters'
import { LOCAL_FILTERS, FILTER_SETTINGS } from 'const/reducerKeys'
import { serializeColumns } from 'helpers/tables'
import { defaultTab, getDefaultTable } from 'reducers/ui/defaults'

const initialState = {
    ...defaultTab,
    [LOCAL_FILTERS]: {
        [BRANDS]: [],
        [DSP_ADVERTISERS]: [],
        [DSP_ORDERS]: [],
    },
    [FILTER_SETTINGS]: {
        anchored: [DATES],
        order: [BRANDS, DSP_ADVERTISERS, DSP_ORDERS],
        displayState: {
            [BRANDS]: true,
            [DSP_ADVERTISERS]: true,
            [DSP_ORDERS]: true,
        },
    },
    table: getDefaultTable(serializeColumns(labelDspOrdersTableColumnsConfig), {
        sorter: {
            field: 'total_cost__sum',
            order: 'descend',
        },
    }),
}

export default handleActions({}, initialState)
