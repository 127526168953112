import { labels } from 'configuration/fieldCreators/labels'
import {
    amazon_choice__pct,
    best_seller__pct,
    review_star__avg,
} from 'configuration/fieldCreators/metrics/sov'
import {
    removeSovKeywordFromLabel,
    sovKeywordCountryCode,
    sovKeywordCreatedAt,
    sovKeywordId,
    sovKeywordLanguageCode,
    sovKeywordPlatform,
    sovKeywordState,
} from 'configuration/fieldCreators/sov'
import { Field, SovFact } from 'types'

export const labelSovKeywordsTableColumnsConfig: Field<SovFact>[] = [
    sovKeywordId(),
    sovKeywordPlatform({ dataIndex: ['keyword', 'platform'] }),
    sovKeywordCountryCode({ dataIndex: ['keyword', 'country_code'] }),
    sovKeywordLanguageCode({
        dataIndex: ['keyword', 'language_code'],
    }),
    sovKeywordState({ dataIndex: ['keyword', 'state'] }),
    labels({ dataIndex: ['keyword', 'labels'] }),
    sovKeywordCreatedAt({ dataIndex: ['keyword', 'created_at'] }),
    review_star__avg({
        isVisible: true,
    }) as Field<SovFact>,
    amazon_choice__pct({ isVisible: true }) as Field<SovFact>,
    best_seller__pct({ isVisible: true }) as Field<SovFact>,
    removeSovKeywordFromLabel(),
]
