import { AlertConfig } from './alert'
import { User } from './organization'

interface ActionParams {
    percentage_change: number
    change: number
    set: number
    min_value?: number | null
    max_value?: number | null
    labels?: string | null
}

interface Action {
    action_type:
        | 'increase_bids'
        | 'decrease_bids'
        | 'increase_bids_by_value'
        | 'decrease_bids_by_value'
        | 'set_bids'
        | 'increase_budgets'
        | 'decrease_budgets'
        | 'increase_budgets_by_value'
        | 'decrease_budgets_by_value'
        | 'set_budgets'
        | 'pause_resource'
        | 'enable_resource'
        | 'do_nothing'
        | 'add_labels'
        | 'remove_labels'
        | 'replace_labels'
    params: ActionParams | null
}

export type RuleAction = Action

export interface RuleCondition {
    condition_type: 'cerebro_alert'
    alert_configuration: AlertConfig
}

export interface Rule {
    id: number | string
    name?: string
    condition: RuleCondition
    action: RuleAction
}

export interface RulebookConfig {
    rules: Rule[]
}

export interface RulebookVersion {
    id: string
    config: RulebookConfig
    created_by: User['id']
    created_at: string
    updated_at: string
    config_hash: string
}

export interface Rulebook {
    id: string
    name: string
    description: string | null
    organization_id: string
    active_version: RulebookVersion
    active_version_id: string | null
    created_by: User['id']
    created_at: string
    updated_at: string
    action_policy: 'dry_run' | 'apply_immediately'
    rule_conflict_policy: 'use_first'
    notification_policy: string | null
    schedule_type: string | null
    schedule_super_type: string | null
    schedule: number | null
    schedule_detail: string | null
    organization: number
    changed_by: number
}

export enum RulebookChangeState {
    GENERATING = 'generating',
    DRY_RUN = 'dry_run',
    IN_QUEUE = 'in_queue', // sent into cerebro SQS queue
    IN_PROGRESS = 'in_progress', // currently being processed
    COMPLETE = 'complete', // done creating async updates. But they are still running
    FAILURE = 'failure', // nothing succeeded
    DRAFT = 'draft', // draft run
    PARTIALLY_FAILED = 'partially_failed',
    PARTIALLY_COMPLETED = 'partially_completed',
    COMPLETED_WITH_CHANGES = 'completed_with_changes',
    COMPLETED_WITHOUT_CHANGES = 'completed_without_changes',
}

export enum RulebookChangeStateFilterOptions {
    RUNNING,
    CHANGES_PENDING,
    CHANGES_MADE,
    FAILURE,
    NO_CHANGES,
}

// Returned with Rulebook Version Cerebro Requests
interface RulebookParams {
    rulebook: string
    rulebook_id: string
}

export type RulebookVersionReturnType = RulebookVersion & RulebookParams
