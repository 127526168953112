import { ReactElement } from 'react'

import { Flex, Typography } from 'antd'
import { TooltipPlacement } from 'antd/es/tooltip'
import { useTranslation } from 'react-i18next'

import { ExternalLinkIcon } from 'components/Icons'
import TrackedLink from 'components/Links/TrackedLink'
import MarkdownRenderer from 'components/MarkdownRenderer/MarkdownRenderer'
import {
    TableColumnTooltipsMetadata,
    TooltipComponentType,
} from 'configuration/fieldCreators/localTypes'
import { METRICS_GLOSSARY_PAGE } from 'const/pages'
import { getPath } from 'helpers/pages'

import FieldTooltip, { FieldTooltipProps } from './FieldTooltip'
import { MetricFormula } from './MetricFormulaList'
import styles from './styles.scss'
import { DataSource, DataSourceMetric } from '../types'

export type MetricTooltipAlertType = 'disallowed'

interface MetricTooltipProps {
    metric: DataSourceMetric
    dataSource: DataSource
    children: ReactElement | string
    placement?: TooltipPlacement
    alertMessage?: ReactElement | string
    alertType?: MetricTooltipAlertType
    zIndex?: number
    customTooltip?: TooltipComponentType
    metadata?: TableColumnTooltipsMetadata
}

export default function MetricTooltip({
    metric,
    dataSource,
    children,
    placement,
    alertMessage,
    alertType,
    zIndex,
    metadata,
    customTooltip = FieldTooltip,
}: MetricTooltipProps): ReactElement {
    const { t } = useTranslation()
    const name = t('common:glossary', 'Glossary')

    const hasFormula = !!metric.metadata_formula
    const metricPath = `${getPath(METRICS_GLOSSARY_PAGE)}?group=${
        dataSource.data_source_group.slug
    }&dataSource=${dataSource.slug}&metric=${metric.slug}`

    const canLinkToGlossary = dataSource.is_selectable_in_dashboards

    const title = (
        <Flex align="center" gap={32}>
            <Flex flex="1">
                <span
                    style={{
                        fontSize: '13px',
                        lineHeight: '1.4',
                    }}
                >
                    {metric.display_name}
                </span>
            </Flex>
            {canLinkToGlossary && (
                <Flex>
                    <TrackedLink
                        target="_blank"
                        to={metricPath}
                        from="MetricTooltip"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '2px',
                            fontSize: '12.6px',
                        }}
                    >
                        {name}
                        <ExternalLinkIcon />
                    </TrackedLink>
                </Flex>
            )}
        </Flex>
    )

    const body = (
        <>
            {metric.definition && (
                <Typography.Title
                    level={5}
                    style={{
                        fontWeight: '400',
                        color: 'rgba(0, 0, 0, 0.75)',
                        margin: '4px 0 0',
                        fontSize: '12.6px',
                        lineHeight: '1.4',
                    }}
                >
                    <MarkdownRenderer markdown={metric.definition} />
                </Typography.Title>
            )}
            {hasFormula && (
                <div className={styles['tooltip-formula-wrapper']}>
                    <MetricFormula metric={metric} />
                </div>
            )}
        </>
    )

    const TooltipType = customTooltip as React.ComponentType<FieldTooltipProps>

    return (
        <TooltipType
            title={title}
            body={body}
            placement={placement}
            zIndex={zIndex}
            alertMessage={alertMessage}
            alertType={alertType}
            metadata={metadata}
        >
            <div>{children ?? '-'}</div>
        </TooltipType>
    )
}
