import {
    brandCountryCode,
    brandCurrencyCode,
    brandEntityId,
    brandId,
    brandRegion,
    brandTimezone,
    brandType,
} from 'configuration/fieldCreators/brand'
import { labels } from 'configuration/fieldCreators/labels'
import {
    portfolioBudget,
    portfolioEndDate,
    portfolioId,
    portfolioStartDate,
    removePortfolioFromLabel,
} from 'configuration/fieldCreators/portfolio'
import { ProfileGroupBy, Field, PortfolioFact } from 'types'

import {
    AMS_METRICS_COLUMNS_PAGES,
    AMS_PACING_GOAL_COLUMNS,
} from '../dataSources/ams'

export type PortfolioFactRecord = PortfolioFact<ProfileGroupBy>

export const portfoliosSummaryTableColumnsConfig: Field<PortfolioFactRecord>[] =
    [
        portfolioId({ fixed: 'left' }),
        portfolioBudget(),
        portfolioStartDate(),
        portfolioEndDate(),
        labels({ dataIndex: ['portfolio', 'labels'] }),
        brandId(),
        brandCountryCode(),
        brandCurrencyCode(),
        brandRegion(),
        brandTimezone(),
        brandType(),
        brandEntityId(),
        ...(AMS_METRICS_COLUMNS_PAGES as Field<PortfolioFactRecord>[]),
        ...(AMS_PACING_GOAL_COLUMNS as Field<PortfolioFactRecord>[]),
    ]

export const labelPortfoliosTableColumnsConfig: Field<PortfolioFactRecord>[] = [
    ...portfoliosSummaryTableColumnsConfig,
    removePortfolioFromLabel(),
]
