import { ReactElement } from 'react'

import Icon from '@ant-design/icons'

import ImageNotFoundIconSvg from './ImageNotFoundIconSvg'
import { IconProps } from '../localTypes'

const ImageNotFoundIcon = (props: IconProps): ReactElement => (
    <Icon component={ImageNotFoundIconSvg} {...props} />
)

export default ImageNotFoundIcon
