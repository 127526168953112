import { ReactElement, CSSProperties } from 'react'

import { Select, Spin } from 'antd'

import { SelectOptionContent } from 'components/SelectOptionContent'
import {
    SEARCH_RESULTS_PER_QUERY,
    PREFETCH_RESULTS_PER_QUERY,
} from 'configuration/typeahead'
import { useTypeahead } from 'hooks'
import { getAdGroups } from 'services/cerebroApi/orgScope/resourceApi'
import { AdGroup } from 'types'

interface Props {
    value?: string
    onChange: (value: string) => void
    campaignId: string
    disabled?: boolean
    isOptionDisabled?: (option: AdGroup) => boolean
    optionMetadata?: (option: AdGroup) => string
    style?: CSSProperties
    placeholder?: string
    extraAPIParams?: object
}

const CampaignAdGroupSearchSelect = ({
    campaignId,
    value,
    onChange,
    disabled = false,
    isOptionDisabled = () => false,
    optionMetadata = () => '',
    style = { width: '100%' },
    placeholder = 'Search Ad Groups...',
    extraAPIParams = {},
}: Props): ReactElement => {
    const extrasStr = JSON.stringify(extraAPIParams)
    const [options, loading, onSearch, resetLoadingState] =
        useTypeahead<AdGroup>({
            apiSearchFunc: (query) =>
                getAdGroups(
                    {
                        limit: SEARCH_RESULTS_PER_QUERY,
                        campaign: campaignId,
                        name__similar: query,
                        'state!': 'archived',
                        ...extraAPIParams,
                    },
                    { headers: { noCount: true } }
                ),
            optionFormatter: (adGroup) => ({
                value: adGroup.id,
                label: adGroup.name,
                disabled: isOptionDisabled(adGroup),
                metadata: optionMetadata(adGroup),
            }),
            prefetchQueryConfig: {
                queryKey: ['adGroups', campaignId, extrasStr],
                queryFn: () => {
                    if (disabled) {
                        return null
                    }

                    return getAdGroups(
                        {
                            limit: PREFETCH_RESULTS_PER_QUERY,
                            campaign: campaignId,
                            'state!': 'archived',
                            ordering: 'name',
                            ...JSON.parse(extrasStr),
                        },
                        { headers: { noCount: true } }
                    )
                },
            },
        })

    return (
        <Select
            filterOption
            optionFilterProp="title"
            optionLabelProp="title"
            placeholder={placeholder}
            loading={loading}
            notFoundContent={loading ? <Spin size="small" /> : null}
            onChange={onChange}
            onSearch={onSearch}
            onSelect={resetLoadingState}
            showSearch
            value={value}
            disabled={disabled}
            style={style}
        >
            {options.map((option, idx) => (
                <Select.Option
                    key={idx}
                    value={option.value}
                    title={option.label}
                    disabled={option.disabled}
                >
                    <SelectOptionContent
                        label={option.label}
                        metadata={option.metadata}
                    />
                </Select.Option>
            ))}
        </Select>
    )
}

export default CampaignAdGroupSearchSelect
