import { addCache } from 'helpers/utilities'
import {
    BannerRequestBody,
    CerebroApiOptions,
    CerebroPaginatedResponse,
    CerebroResourceResponse,
    ProductMetadata,
    Report,
    SovKeywordResearch,
    SovKeywordResearchCategories,
} from 'types'

import { axiosInstanceOrganizations } from './client'
import { getCerebroHeaders } from './headers'

export const getSovFactAggregates = (
    params: BannerRequestBody,
    options: CerebroApiOptions = {}
): Promise<CerebroPaginatedResponse<any>> =>
    axiosInstanceOrganizations.post(
        '/api/sov/search_results/facts/aggregate/banner/',
        params,
        {
            headers: getCerebroHeaders(options.headers),
        }
    )
addCache(getSovFactAggregates)

export const getSovFactAggregatesExport = (
    params: BannerRequestBody
): Promise<CerebroResourceResponse<Report>> =>
    axiosInstanceOrganizations.post(
        '/api/sov/search_results/facts/aggregate/banner/export/',
        params
    )

export interface KeywordResearchResponse {
    count: number
    next: string | null
    previous: string | null
    results: SovKeywordResearch[]
    top_asins: string[]
    product_metadata: ProductMetadata[]
}

export interface KeywordResearchCategoriesResponse {
    count: number
    results: SovKeywordResearchCategories[]
}
