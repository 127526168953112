import { memo, ReactElement } from 'react'

import isEqual from 'lodash/isEqual'
import noop from 'lodash/noop'

import { NegativeKeyword } from 'types'

import { DeleteField } from '../../SharedFields/DeleteField'

type KeywordRecord = NegativeKeyword

interface Props<RecordType> {
    apiFunc: () => Promise<any>
    readOnly: boolean
    record: RecordType
    reloadData: () => void
}

function DeleteNegativeKeywordField<RecordType extends KeywordRecord>({
    apiFunc = noop as () => Promise<any>,
    readOnly,
    record,
    reloadData,
}: Props<RecordType>): ReactElement {
    const resourceId = record.id
    const getIsDisabled = (): boolean => {
        const userCanManageKeyword = record.user_can_manage
        return !userCanManageKeyword || readOnly
    }

    return (
        <DeleteField
            disabled={getIsDisabled()}
            okText="Delete"
            title="Are you sure you want to delete this Negative Keyword?"
            updateRequestApi={apiFunc}
            updateRequestCallback={reloadData}
            updateRequestData={resourceId}
            updateRequestSuccessMesg="Negative Keyword successfully deleted"
        />
    )
}

export default memo(
    DeleteNegativeKeywordField,
    isEqual
) as typeof DeleteNegativeKeywordField
