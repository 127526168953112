import { Translation } from 'react-i18next'

import {
    wmAdItemStateRenderer,
    wmAdItemBidRenderer,
    wmItemTitleRenderer,
} from 'components/FieldRenderers/Renderers'
import { WalmartItemLink } from 'components/Links'
import { Field, WalmartAdItemGroupBy } from 'types'

import { createField } from './createField'
import { FieldCreatorOptions } from './localTypes'

export function adItemId<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['id']
    const name = (
        <Translation>
            {(t) => t('table:fields.adItem.name', 'Ad Item Id')}
        </Translation>
    )

    return createField({
        ...options,
        id: 'ad_item',
        name,
        shortName: name,
        minWidth: 80,
        dataIndex,
        width: 300,
        sorter: options.sorter ?? false,
    })
}

export function adItemState<RecordType extends WalmartAdItemGroupBy>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['state']
    const name = (
        <Translation>
            {(t) => t('table:fields.adItemState.name', 'Ad Item State')}
        </Translation>
    )
    const shortName = (
        <Translation>
            {(t) => t('table:fields.adItemState.shortName', 'State')}
        </Translation>
    )

    return createField({
        ...options,
        id: 'ad_item_state',
        name,
        shortName,
        minWidth: 70,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: ({ record }) =>
                wmAdItemStateRenderer({ record, dataIndex }),
        },
        width: 100,
    })
}

export function adItemBid<RecordType extends WalmartAdItemGroupBy>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['bid']
    const name = (
        <Translation>
            {(t) => t('table:fields.adItemBid.name', 'Ad Item Bid')}
        </Translation>
    )
    const shortName = (
        <Translation>
            {(t) => t('table:fields.adItemBid.shortName', 'Bid')}
        </Translation>
    )

    return createField({
        ...options,
        id: 'ad_item_bid',
        name,
        shortName,
        dataIndex,
        minWidth: 75,
        renderOptions: options.renderOptions ?? {
            render: (props) => wmAdItemBidRenderer(props),
        },
        width: 100,
        align: 'right',
    })
}

export function itemId<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['item_id']
    const name = (
        <Translation>
            {(t) => t('table:fields.item.name', 'Item Id')}
        </Translation>
    )
    return createField({
        ...options,
        id: 'item_id',
        name,
        shortName: name,
        minWidth: 100,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: ({ value }) => <WalmartItemLink itemId={value} />,
        },
        width: 200,
    })
}

export function itemMetaDataTitle<RecordType extends WalmartAdItemGroupBy>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['name']
    const name = (
        <Translation>{(t) => t('common:itemTitle', 'Item Title')}</Translation>
    )
    return createField({
        ...options,
        id: 'walmart_item_title',
        name,
        shortName: name,
        minWidth: 200,
        isTotalSupported: options.isTotalSupported ?? true,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: (cellRenderProps) =>
                wmItemTitleRenderer({
                    cellRenderProps,
                }),
        },
        width: 300,
    })
}
