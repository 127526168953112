import { captureException } from '@sentry/browser'
import { message } from 'antd'
import { isEmpty } from 'lodash'
import { SagaIterator } from 'redux-saga'
import {
    call,
    cancel,
    delay,
    put,
    race,
    select,
    spawn,
    take,
    takeEvery,
} from 'redux-saga/effects'

import {
    fetchChangesFailure,
    fetchChangesRequest,
    fetchChangesSuccess,
} from 'actions/ui/rulebookPage'
import {
    makeFetchTabDataRequest,
    makeFetchTableFailure,
    makeFetchTableSuccess,
    makeIsUnmountPageAction,
    makeSetTableLoading,
} from 'actions/ui/shared'
import { CHANGE_SET_STATES, DATES } from 'const/filters'
import { RULEBOOK_PAGE } from 'const/pages'
import {
    BACK_OFF_RATE,
    POLL_INTERVAL,
    POLL_INTERVAL_MAX,
    POLL_TIMEOUT,
} from 'const/polling'
import { isSomeUpdatesPending } from 'helpers/bulkUpdate'
import { getDateRangeArray } from 'helpers/dateRange'
import { formatJSONDateTime } from 'helpers/formatting'
import { formatPagination, formatSorter } from 'helpers/params'
import { isSomeChangeSetsPending } from 'helpers/rulebookChangeSet'
import { isNonEmptyArray } from 'helpers/typeGuard'
import { cerebroApiSaga } from 'sagas/common'
import uiSagaRegistry from 'sagas/ui/registry'
import {
    selectDomainValue as selectUiDomainValue,
    selectResourceParamsOfPage,
    selectTableSettings,
    selectVisibleCombinedFilters,
} from 'selectors/ui'
import { getRulebookChangeSets } from 'services/cerebroApi/orgScope/rulebooksApi'
import { getAsyncUpdates } from 'services/cerebroApi/orgScope/updatesApi'
import {
    ChangeSet,
    RulebookChangeState,
    RulebookChangeStateFilterOptions,
} from 'types'

const TAB_PATH = [RULEBOOK_PAGE, 'changeSets']
const TABLE_PATH = [...TAB_PATH, 'table']

interface Action {
    type: string
    payload: any
}

const formatChangeSetFilters = (
    filters: Record<string, any>
): Record<string, any> => {
    const params: any = {}

    if (!isEmpty(filters[DATES])) {
        const range = getDateRangeArray(filters[DATES], true)

        params.updated_at_min = formatJSONDateTime(range[0])
        params.updated_at_max = formatJSONDateTime(range[1])
    }

    if (isNonEmptyArray(filters[CHANGE_SET_STATES])) {
        const filtered_states = filters[CHANGE_SET_STATES]
        let filter_by_count = true
        const desired_states = filtered_states.flatMap((state: any) => {
            switch (state?.value) {
                case RulebookChangeStateFilterOptions.RUNNING:
                    filter_by_count = false
                    return [
                        RulebookChangeState.GENERATING,
                        RulebookChangeState.IN_QUEUE,
                        RulebookChangeState.IN_PROGRESS,
                        RulebookChangeState.DRAFT,
                        RulebookChangeState.COMPLETE,
                    ]
                case RulebookChangeStateFilterOptions.CHANGES_PENDING:
                    return [
                        RulebookChangeState.COMPLETE,
                        RulebookChangeState.DRY_RUN,
                        RulebookChangeState.PARTIALLY_COMPLETED,
                        RulebookChangeState.PARTIALLY_FAILED,
                    ]
                case RulebookChangeStateFilterOptions.CHANGES_MADE:
                    return [
                        RulebookChangeState.COMPLETED_WITH_CHANGES,
                        RulebookChangeState.PARTIALLY_COMPLETED,
                    ]
                case RulebookChangeStateFilterOptions.FAILURE:
                    filter_by_count = false
                    return [
                        RulebookChangeState.PARTIALLY_FAILED,
                        RulebookChangeState.FAILURE,
                    ]
                case RulebookChangeStateFilterOptions.NO_CHANGES:
                    filter_by_count = false
                    return [RulebookChangeState.COMPLETED_WITHOUT_CHANGES]
                default:
                    return []
            }
        })
        params.state__in = desired_states.join()
        if (filter_by_count) {
            params.change_count__gt = 0
        }
    }

    return params
}

const isUnmountPageAction = makeIsUnmountPageAction(RULEBOOK_PAGE)

function* fetchTableSaga(): any {
    const currentChangesets: ChangeSet[] = yield select(selectUiDomainValue, [
        ...TABLE_PATH,
        'data',
    ]) ?? []
    const tableSettings = yield select(selectTableSettings, TABLE_PATH)
    const filters = yield select(selectVisibleCombinedFilters, TAB_PATH)
    const resourceParams = yield select(
        selectResourceParamsOfPage,
        RULEBOOK_PAGE
    )

    const { rulebookId } = resourceParams
    const { pagination, sorter } = tableSettings

    const params = {
        ...formatPagination(pagination),
        ...formatSorter(sorter),
        ...formatChangeSetFilters(filters),
        rulebook: rulebookId,
    }

    const response = yield call(
        cerebroApiSaga,
        null,
        getRulebookChangeSets,
        params
    )

    if (response) {
        const changeSets = response.data.results ?? []
        changeSets.forEach((changeset: ChangeSet) => {
            const currentChangeset = currentChangesets.find(
                (cChangeset) => cChangeset.id === changeset.id
            )
            changeset.changes = currentChangeset?.changes
        })
        yield put(makeFetchTableSuccess(TABLE_PATH)(response.data))
        return response.data.results
    }
    return null
}

function* fetchChangesWorker(action: Action): any {
    const record = action.payload
    const params = {
        change_set: record.id,
    }
    const response = yield call(cerebroApiSaga, null, getAsyncUpdates, params)
    if (response.status === 200) {
        const changes =
            response.data.count > 0 ? [...response.data.results] : []

        yield put(
            fetchChangesSuccess({
                record,
                changes,
            })
        )
        return changes
    }
    yield put(
        fetchChangesFailure({
            record,
        })
    )

    return null
}

function* pollChanges(action: Action): any {
    let delayInterval = POLL_INTERVAL
    while (true) {
        try {
            const results = yield call(fetchChangesWorker, action)
            if (results && !isSomeUpdatesPending(results)) {
                yield call(fetchTableSaga)
                break
            }
        } catch {
            yield put(fetchChangesFailure(action.payload))
            break
        }
        yield delay(Math.min(delayInterval, POLL_INTERVAL_MAX))
        delayInterval += delayInterval * BACK_OFF_RATE
    }
}

function* startPollingChanges(action: Action): any {
    const record = action.payload
    const changeSets: ChangeSet[] = yield select(selectUiDomainValue, [
        ...TABLE_PATH,
        'data',
    ]) ?? []
    const polledChageset = changeSets.find(
        (changeset) => changeset.id === record.id
    )

    const changes = polledChageset?.changes ?? []

    let hasTimedOut = false
    if (isSomeUpdatesPending(changes)) {
        const raceResponse = yield race({
            response: call(pollChanges, action),
            timedOut: delay(POLL_TIMEOUT),
            unmount: take(isUnmountPageAction),
        })
        const { timedOut } = raceResponse
        hasTimedOut = timedOut
    }

    if (hasTimedOut) {
        const error = {
            message:
                'This page has stopped checking the status of pending rulebook changes. Refresh the page to see the current status.',
        }

        yield call(captureException, error)
        const closeMessage = message.warning(error.message, 0)

        // wait until the user navigates away from the page to remove the message
        yield take(isUnmountPageAction)
        closeMessage()
    }
}

function* fetchChangesAndPoll(action: Action): any {
    yield call(fetchChangesWorker, action)
    yield spawn(startPollingChanges, action)
}

function* checkUploadStatusSaga(): any {
    let delayInterval = POLL_INTERVAL
    while (true) {
        try {
            const results = yield call(fetchTableSaga)
            if (results && !isSomeChangeSetsPending(results)) {
                break
            }
        } catch (error) {
            yield put(makeFetchTableFailure(TABLE_PATH)(error))
            break
        }
        yield delay(Math.min(delayInterval, POLL_INTERVAL_MAX))
        delayInterval += delayInterval * BACK_OFF_RATE
    }
}

function* startPollingSaga(): any {
    const response = yield select(selectUiDomainValue, TABLE_PATH)
    const { data } = response

    // start polling if there are pending reports
    let hasTimedOut = false
    let hasPolled = false
    if (isSomeChangeSetsPending(data)) {
        const raceResponse = yield race({
            response: call(checkUploadStatusSaga),
            timedOut: delay(POLL_TIMEOUT),
            unmount: take(isUnmountPageAction),
        })
        const { timedOut } = raceResponse
        hasTimedOut = timedOut
        hasPolled = true
    }

    if (hasTimedOut) {
        const error = {
            message:
                'This page has stopped checking the status of pending rulebook changes. Refresh the page to see the current status.',
        }

        yield call(captureException, error)
        const closeMessage = message.warning(error.message, 0)

        // wait until the user navigates away from the page to remove the message
        yield take(isUnmountPageAction)
        closeMessage()
    } else if (hasPolled) {
        // since there is a freshly finished dry_run, we should fetch async_updates.
        yield put(makeFetchTabDataRequest([RULEBOOK_PAGE, 'changes'])())
    }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* fetchTabDataSaga() {
    // calling the saga (instead of putting an action) because it's necessary to
    // start polling AFTER initial table data has been fetched
    yield put(makeSetTableLoading(TABLE_PATH)(true))
    yield call(fetchTableSaga)
    yield put(makeSetTableLoading(TABLE_PATH)(false))

    // use spawn to start polling in 'detached' fork mode
    // see https://redux-saga.js.org/docs/advanced/ForkModel.html
    yield spawn(startPollingSaga)
}

function* fetchChangesSaga(): SagaIterator {
    const changesRequestWatcher = yield takeEvery(
        fetchChangesRequest.toString(),
        fetchChangesAndPoll
    )
    yield take(isUnmountPageAction)
    yield cancel(changesRequestWatcher)
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* mountTabSaga(): any {
    yield spawn(fetchChangesSaga)
}

// Register Sagas
uiSagaRegistry.registerSagas(TAB_PATH, {
    mountTabSaga,
    fetchTabDataSaga,
})

uiSagaRegistry.registerSagas(TABLE_PATH, {
    fetchTableSaga,
})
