import UserEmail from 'components/FieldRenderers/Renderers/UserEmail'
import { Flag } from 'components/Flag'
import { REGION_LABELS, COUNTRY_LABELS } from 'const/codes'
import { UNDEFINED_VALUE } from 'const/formatting'
import { isUnset } from 'helpers/utilities'
import { ChangelogRecord, Field } from 'types'

import {
    createDateField,
    createField,
    createLabelLookupField,
    createTitleField,
} from './createField'
import { FieldCreatorOptions } from './localTypes'

export function objectName<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createField({
        ...options,
        id: 'object_name',
        name: 'Resource Name',
        shortName: 'Resource Name',
        dataIndex: options.dataIndex ?? ['object_name'],
        minWidth: 100,
    })
}

export function objectType<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['object_type']
    return createTitleField({
        ...options,
        id: 'object_type',
        name: 'Resource Type',
        shortName: 'Resource Type',
        dataIndex,
        minWidth: 100,
    })
}

export function changes<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['object_id']
    return createField({
        ...options,
        id: 'changes',
        name: 'Change Description',
        shortName: 'Change Description',
        dataIndex,
        minWidth: 100,
        width: 200,
        sorter: options.sorter ?? false,
    })
}

export function changeReason<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['change_reason']
    return createField({
        ...options,
        id: 'change_reason',
        name: 'Change Reason',
        shortName: 'Change Reason',
        dataIndex,
        minWidth: 100,
    })
}

export function changeDate<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['date']
    return createDateField({
        ...options,
        id: 'date',
        name: 'Change Date',
        shortName: 'Change Date',
        dataIndex,
        minWidth: 100,
        dateFormat: 'M/DD/YYYY h:mm:ss A',
    })
}

export function userEmail<RecordType extends ChangelogRecord>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['user', 'email']
    return createField({
        ...options,
        id: 'userEmail',
        name: 'User',
        shortName: 'User',
        dataIndex,
        minWidth: 100,
        renderOptions: options.renderOptions ?? {
            render: ({ value }) => <UserEmail email={value} />,
        },
    })
}

export function region<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['region']
    return createLabelLookupField({
        ...options,
        id: 'region',
        name: 'Region',
        shortName: 'Region',
        dataIndex,
        minWidth: 100,
        labels: REGION_LABELS,
    })
}

export function countryCode<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['country_code']
    return createField({
        ...options,
        id: 'country_code',
        name: 'Country',
        shortName: 'Country',
        dataIndex,
        minWidth: 100,
        renderOptions: options.renderOptions ?? {
            render: ({ value }) =>
                isUnset(value) ? (
                    UNDEFINED_VALUE
                ) : (
                    <span className="fg-flag-container">
                        <Flag country={value} />
                        {COUNTRY_LABELS[value]}
                    </span>
                ),
        },
    })
}
