import { useEffect, useState } from 'react'

import axios from 'axios'

interface AppOfflineStatus {
    isOffline: boolean
    customOfflineMessage?: string
}

const defaultAppOfflineStatus: AppOfflineStatus = {
    isOffline: false,
    customOfflineMessage: undefined,
}

function useAppOfflineStatus(): AppOfflineStatus {
    const [status, setStatus] = useState<AppOfflineStatus>(
        defaultAppOfflineStatus
    )

    useEffect(() => {
        async function getMaintenanceOverride(): Promise<void> {
            try {
                const response = await axios(`${ASSET_PATH}_app_offline.json`)
                setStatus(response?.data ?? defaultAppOfflineStatus)
            } catch {
                setStatus(defaultAppOfflineStatus)
            }
        }
        getMaintenanceOverride()
    }, [])

    return status
}

export default useAppOfflineStatus
