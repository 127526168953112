import { ReactElement } from 'react'

import { get } from 'lodash'

import ProductCogsModal from 'components/ProductCogs/ProductCogsModal'
import { CellRenderProps, ProductSkuFactRecord } from 'types'

type RenderProps<RecordType> = {
    cellRenderProps: CellRenderProps<RecordType>
    dataIndex: string[]
}

export function productCogsRenderer<RecordType extends ProductSkuFactRecord>({
    cellRenderProps: { record, reloadData },
    dataIndex,
}: RenderProps<RecordType>): ReactElement {
    const valueToShow = get(record, dataIndex)
    return (
        <ProductCogsModal
            sku={record.product_ad__sku ?? undefined}
            cogsValue={valueToShow ?? undefined}
            currencyCode={record.metadata?.currency_code ?? undefined}
            onChange={reloadData}
        />
    )
}
