import orderBy from 'lodash/orderBy'
import { createSelector } from 'reselect'

import { RootReduxState, Label, LabelFilterOption } from 'types'

import { selectDomainValue } from './ui'

const selectLabels = (state: RootReduxState): Label[] =>
    selectDomainValue(state, ['app', 'organizationLabels'])

export const selectLabelsOrdered = createSelector(selectLabels, (labels) =>
    orderBy(labels, (label) => label.name.toLowerCase())
)

export const selectLabelsForFilters = createSelector(
    selectLabelsOrdered,
    (labels): LabelFilterOption[] =>
        labels.map((label) => ({
            label: label.name,
            value: label.id,
        }))
)
