import { HourlyMultiplierBlock } from 'types'

export const DAILY_ALL_ONE = Array(7).fill(1)

export const HOURLY_ALL_ONE = Array(24).fill(1)

export const DEFAULT_TIME_PARTING_HOURLY_MULTIPLIERS = HOURLY_ALL_ONE

export const DEFAULT_ABC_HOURLY_MULTIPLIERS = [
    0.12, 0.12, 0.12, 0.25, 0.25, 0.25, 0.37, 0.37, 0.37, 0.5, 0.5, 0.5, 0.62,
    0.62, 0.62, 0.75, 0.75, 0.75, 0.87, 0.87, 0.87, 1, 1, 1,
]

export const HOURLY_MULTIPLIER_BLOCKS: HourlyMultiplierBlock[] = [
    {
        start: 0,
        end: 3,
        parentLabel: '12-3am',
        childLabels: ['12-1am', '1-2am', '2-3am'],
    },
    {
        start: 3,
        end: 6,
        parentLabel: '3-6am',
        childLabels: ['3-4am', '4-5am', '5-6am'],
    },
    {
        start: 6,
        end: 9,
        parentLabel: '6-9am',
        childLabels: ['6-7am', '7-8am', '8-9am'],
    },
    {
        start: 9,
        end: 12,
        parentLabel: '9-12pm',
        childLabels: ['9-10am', '10-11am', '11am-12pm'],
    },
    {
        start: 12,
        end: 15,
        parentLabel: '12-3pm',
        childLabels: ['12-1pm', '1-2pm', '2-3pm'],
    },
    {
        start: 15,
        end: 18,
        parentLabel: '3-6pm',
        childLabels: ['3-4pm', '4-5pm', '5-6pm'],
    },
    {
        start: 18,
        end: 21,
        parentLabel: '6-9pm',
        childLabels: ['6-7pm', '7-8pm', '8-9pm'],
    },
    {
        start: 21,
        end: 24,
        parentLabel: '9-12am',
        childLabels: ['9-10pm', '10-11pm', '11pm-12am'],
    },
]
