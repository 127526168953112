import { memo, ReactElement } from 'react'

import isEqual from 'lodash/isEqual'

import { useResource } from 'components/EditableFields/localHooks'
import { ARCHIVED } from 'const/resourceStates'
import { updateProductAd } from 'services/cerebroApi/orgScope/resourceApi'
import { ProductAdFact } from 'types'

import { DeleteField } from '../../SharedFields/DeleteField'

type ProductAdRecord = ProductAdFact

interface Props<RecordType> {
    dataIndex: string[]
    record: RecordType
    readOnly: boolean
    reloadData: () => void
}

function ArchiveProductAdField<RecordType extends ProductAdRecord>({
    dataIndex = ['product_ad'],
    record,
    readOnly,
    reloadData,
}: Props<RecordType>): ReactElement {
    const { getFromResource, resourceId } = useResource<RecordType>(
        record,
        dataIndex,
        'product_ad'
    )

    const getIsDisabled = (): boolean => {
        const userCanManageProductAd = getFromResource(
            ['user_can_manage'],
            true
        )
        return !userCanManageProductAd || readOnly
    }

    return (
        <DeleteField
            disabled={getIsDisabled()}
            okText="Archive"
            title="Are you sure you want to archive this Product Ad?"
            updateRequestApi={updateProductAd}
            updateRequestCallback={reloadData}
            updateRequestData={resourceId}
            updateRequestParams={{ state: ARCHIVED }}
            updateRequestSuccessMesg="Product Ad successfully archived"
        />
    )
}

export default memo(
    ArchiveProductAdField,
    isEqual
) as typeof ArchiveProductAdField
