import { AxiosRequestConfig } from 'axios'

import { CACHED_METHODS } from 'const/services'

export const shouldInvalidateCache = (config: AxiosRequestConfig): boolean => {
    /**
     * if method is undefined invalidate
     */
    if (config.method === undefined) {
        return true
    }

    /**
     *  banner aggregation might happen with a post request
     * (mostly because we're breaking the max url size of get requests).
     *  However, we don't want to invalidate the cache because it won't change data.
     */
    if (config.url?.includes('/aggregate/banner/')) {
        return false
    }

    /**
     * if method is not 'get' or 'GET' invalidate
     */
    return !CACHED_METHODS.includes(config.method)
}
