import { handleActions } from 'redux-actions'

import { targetsSummaryTableColumnsConfig } from 'configuration/tables'
import {
    METRIC_FILTERS,
    TARGET_STATES,
    AD_GROUPS,
    AD_GROUP_STATES,
    DATES,
    REGIONS,
    COUNTRIES,
    BRANDS,
    PORTFOLIOS,
    CAMPAIGNS,
    CAMPAIGN_STATES,
    CAMPAIGN_TARGETING_TYPES,
    FACT_TYPES,
    TARGET_TEXT,
    LABELS,
} from 'const/filters'
import { FILTER_SETTINGS, FILTERS } from 'const/reducerKeys'
import { serializeColumns } from 'helpers/tables'
import { defaultTab, getDefaultTable } from 'reducers/ui/defaults'

const initialState = {
    ...defaultTab,
    [FILTERS]: {
        [TARGET_TEXT]: null,
        [METRIC_FILTERS]: [],
        [FACT_TYPES]: [],
        [REGIONS]: [],
        [COUNTRIES]: [],
        [BRANDS]: [],
        [PORTFOLIOS]: [],
        [CAMPAIGNS]: [],
        [CAMPAIGN_STATES]: [],
        [CAMPAIGN_TARGETING_TYPES]: [],
        [TARGET_STATES]: [],
        [AD_GROUPS]: [],
        [AD_GROUP_STATES]: [],
        [LABELS]: [],
    },
    [FILTER_SETTINGS]: {
        anchored: [TARGET_TEXT, DATES, FACT_TYPES],
        order: [
            METRIC_FILTERS,
            LABELS,
            REGIONS,
            COUNTRIES,
            BRANDS,
            PORTFOLIOS,
            CAMPAIGNS,
            CAMPAIGN_STATES,
            CAMPAIGN_TARGETING_TYPES,
            TARGET_STATES,
            AD_GROUPS,
            AD_GROUP_STATES,
        ],
        displayState: {
            [METRIC_FILTERS]: true,
            [LABELS]: true,
            [REGIONS]: true,
            [COUNTRIES]: true,
            [BRANDS]: true,
            [PORTFOLIOS]: false,
            [CAMPAIGNS]: true,
            [CAMPAIGN_STATES]: true,
            [CAMPAIGN_TARGETING_TYPES]: true,
            [TARGET_STATES]: true,
            [AD_GROUPS]: true,
            [AD_GROUP_STATES]: true,
        },
    },
    table: getDefaultTable(serializeColumns(targetsSummaryTableColumnsConfig), {
        showTotalRow: true,
    }),
}

export default handleActions({}, initialState)
