import { SVGAttributes, ReactElement } from 'react'

type Props = SVGAttributes<SVGElement>

function SvgDashboardIcon(props: Props): ReactElement {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <rect x={2} y={2} width={7} height={5} rx={1} />
            <rect x={2} y={9} width={7} height={8} rx={1} />
            <rect x={11} y={12} width={7} height={5} rx={1} />
            <rect x={11} y={2} width={7} height={8} rx={1} />
        </svg>
    )
}

export default SvgDashboardIcon
