import { ReactElement } from 'react'

import { ProductTitleCell } from 'components/FieldRenderers/ProductTitleCell'
import { AsinLink } from 'components/Links'
import { UNDEFINED_VALUE } from 'const/formatting'
import { formatCurrency } from 'helpers/formatting'
import { isUnset } from 'helpers/utilities'
import { ParentProductGroupBy, CellRenderProps, Brand } from 'types'

type RenderProps<RecordType> = {
    cellRenderProps: CellRenderProps<RecordType>
    dataIndex?: string[]
}

export function parentProductTitleRenderer<
    RecordType extends ParentProductGroupBy & {
        profile__country_code: Brand['country_code']
    },
>({
    cellRenderProps: { value, record },
}: RenderProps<RecordType>): ReactElement {
    const product_ad_asin = record.primary_asin
    const product_ad_id = record.primary_metadata_id
    const product_img = record.primary_metadata?.small_image_url
    const profile_country_code = record.profile__country_code

    return (
        <ProductTitleCell
            value={value}
            productImg={product_img}
            productAdId={product_ad_id}
            productAsin={product_ad_asin}
            profileCountryCode={profile_country_code}
        />
    )
}

export function parentProductPriceRenderer<
    RecordType extends ParentProductGroupBy,
>({
    cellRenderProps: { value, record },
}: RenderProps<RecordType>): ReactElement {
    if (isUnset(value)) {
        return <span>{UNDEFINED_VALUE}</span>
    }

    const currencyCode = record.primary_metadata.currency_code ?? ''
    return formatCurrency(value, {
        decimal: true,
        currencyCode,
    })
}

export function parentProductAsinRenderer<
    RecordType extends ParentProductGroupBy & {
        profile__country_code: Brand['country_code']
    },
>({
    cellRenderProps: { value, record },
}: RenderProps<RecordType>): ReactElement {
    if (isUnset(value)) {
        return <span>{UNDEFINED_VALUE}</span>
    }

    const countryCode = record.profile__country_code
    return <AsinLink asin={value} countryCode={countryCode} />
}
