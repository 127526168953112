import { SVGAttributes, ReactElement } from 'react'

import Icon from '@ant-design/icons'

import { IconProps } from '../localTypes'

type Props = SVGAttributes<SVGElement>

function SvgCheckFilledIcon(props: Props): ReactElement {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <circle cx="10" cy="10" r="10" style={{ stroke: 'transparent' }} />
            <path
                d="M14.167 7.5L8.61144 13.3333L5.83366 10.4167"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

interface CheckFilledIconProps extends IconProps {
    stroke?: string
    fill?: string
}

export const CheckFilledIcon = (props: CheckFilledIconProps): ReactElement => {
    const {
        stroke = '#5AC282',
        fill = '#E8FDF0',
        style: propsStyle = {},
    } = props
    const style = {
        stroke,
        fill,
        color: fill,
        ...propsStyle,
    }
    return <Icon component={SvgCheckFilledIcon} style={style} />
}
