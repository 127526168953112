import { SVGAttributes, ReactElement } from 'react'

type Props = SVGAttributes<SVGElement>

function SvgSegmentsIcon(props: Props): ReactElement {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M10 4.5C10 4.22386 9.77539 3.99814 9.49993 4.01759C5.86733 4.27399 3 7.30215 3 11C3 14.866 6.13401 18 10 18C13.6979 18 16.726 15.1327 16.9824 11.5001C17.0019 11.2246 16.7761 11 16.5 11H10.5C10.2239 11 10 10.7761 10 10.5V4.5Z" />
            <path d="M18.5 9C18.7761 9 19.0019 8.77539 18.9824 8.49993C18.7377 5.0325 15.9675 2.26234 12.5001 2.01759C12.2246 1.99814 12 2.22386 12 2.5V8.5C12 8.77614 12.2239 9 12.5 9H18.5Z" />
        </svg>
    )
}

export default SvgSegmentsIcon
