import { handleActions } from 'redux-actions'

import { RULEBOOK_NAME, RULEBOOK_STATES } from 'const/filters'
import { FILTER_SETTINGS, LOCAL_FILTERS } from 'const/reducerKeys'
import { defaultTab, getDefaultList } from 'reducers/ui/defaults'

const initialState = {
    ...defaultTab,
    [LOCAL_FILTERS]: {
        [RULEBOOK_NAME]: null,
        [RULEBOOK_STATES]: [],
    },
    [FILTER_SETTINGS]: {
        anchored: [RULEBOOK_NAME],
        order: [RULEBOOK_STATES],
        displayState: {
            [RULEBOOK_STATES]: true,
        },
    },
    list: getDefaultList({
        sorter: {
            field: 'updated_at',
            order: 'descend',
        },
    }),
}

export default handleActions({}, initialState)
