import {
    automationCapabilityIdRenderer,
    changelogChangesRenderer,
    changelogObjectNameRenderer,
} from 'components/FieldRenderers'
import { automationCapabilityId } from 'configuration/fieldCreators/automation'
import {
    brandCountryCode,
    brandId,
    brandRegion,
} from 'configuration/fieldCreators/brand'
import { campaignId } from 'configuration/fieldCreators/campaign'
import {
    changeDate,
    changeReason,
    changes,
    objectName,
    objectType,
    userEmail,
} from 'configuration/fieldCreators/changelog'
import { ChangelogRecord, Field } from 'types'

export const campaignChangelogTableColumnsConfig: Field<ChangelogRecord>[] = [
    objectName({
        fixed: 'left',
        sorter: true,
        renderOptions: {
            render: (cellRenderProps) =>
                changelogObjectNameRenderer({
                    cellRenderProps,
                }),
        },
    }),
    objectType(),
    automationCapabilityId({
        dataIndex: ['automation_capability_id'],
        renderOptions: {
            render: (cellRenderProps) =>
                automationCapabilityIdRenderer({
                    cellRenderProps,
                }),
        },
    }),
    changes({
        dataIndex: ['changes'],
        renderOptions: {
            render: (cellRenderProps) =>
                changelogChangesRenderer({
                    cellRenderProps,
                }),
        },
    }),
    changeReason(),
    userEmail(),
    changeDate(),
]

export const eventsChangelogTableColumnsConfig: Field<ChangelogRecord>[] = [
    ...campaignChangelogTableColumnsConfig,
    campaignId(),
    brandId(),
    brandRegion({
        dataIndex: ['profile', 'region'],
    }),
    brandCountryCode({
        dataIndex: ['profile', 'country_code'],
    }),
]

export const segmentChangelogTableColumnsConfig: Field<ChangelogRecord>[] = [
    changes({
        renderOptions: {
            render: (cellRenderProps) =>
                changelogChangesRenderer({
                    cellRenderProps,
                }),
        },
        dataIndex: ['changes'],
    }),
    userEmail(),
    changeDate(),
]
