import { all, takeLatest } from 'redux-saga/effects'

import { fetchOrganizationGroupsRequest } from 'actions/orgs'

import { fetchOrganizationGroupsWorker } from './workers'

export default function* organizationGroupsWatcher() {
    yield all([
        takeLatest(
            fetchOrganizationGroupsRequest.toString(),
            fetchOrganizationGroupsWorker
        ),
    ])
}
