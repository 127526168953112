import flow from 'lodash/fp/flow'
import set from 'lodash/fp/set'
import update from 'lodash/fp/update'
import get from 'lodash/get'
import has from 'lodash/has'
import isEmpty from 'lodash/isEmpty'
import mapValues from 'lodash/mapValues'
import { normalize } from 'normalizr'

import { changeCurrencyCode } from 'actions/ui/app'
import {
    fetchDashboardSuccess,
    updateWidgetPagination,
    updateWidgetSorter,
    updateWidgetTableColumns,
} from 'actions/ui/shared/dashboard'
import { DATES } from 'const/filters'
import { DASHBOARD_PAGE, HOME_PAGE } from 'const/pages'
import { FILTERS } from 'const/reducerKeys'
import { serializeDatesFilter } from 'helpers/filters'
import { getCurrentPage } from 'helpers/pages'
import { tabListSchema } from 'schemas'

import {
    defaultDashboardTabState,
    defaultDatesFilter,
    defaultPaginationAndSorter,
    defaultWidgetLayout,
    defaultWidgetState,
} from '../defaults'

export default {
    [fetchDashboardSuccess](state, action) {
        const { pageName, data: dashboard } = action.payload
        const {
            entities: { tabs, widgets = {} },
        } = normalize(get(dashboard, ['config', 'tabs'], []), tabListSchema)

        // If we have dashboard filters stored in config, serialize them;
        // Use the defaults from page reducer otherwise
        let filters = get(dashboard, ['config', FILTERS])
        if (filters) {
            filters = {
                ...filters,
                [DATES]: serializeDatesFilter(filters[DATES]),
            }
        } else {
            filters = get(state, [pageName, FILTERS])
        }

        return flow(
            set([pageName, 'dashboard'], dashboard),
            set(
                [pageName, 'currencyCode'],
                dashboard.config?.currency_code || null
            ),
            set(
                [pageName, 'tabs'],
                mapValues(tabs, (tab) => {
                    const processedTab = {
                        ...defaultDashboardTabState,
                        ...tab,
                    }

                    if (pageName === DASHBOARD_PAGE || pageName === HOME_PAGE) {
                        let dateRangeFilter = defaultDatesFilter
                        let rangeLag
                        if (!isEmpty(tab?.localFilters?.[DATES])) {
                            dateRangeFilter = serializeDatesFilter(
                                tab.localFilters[DATES]
                            )
                        } else if (!isEmpty((filters || {})[DATES])) {
                            dateRangeFilter = filters[DATES]
                        }

                        if (!isEmpty(tab?.localFilters?.rangeLag)) {
                            rangeLag = parseInt(
                                tab.localFilters.rangeLag[0]?.value ?? 0,
                                10
                            )
                        }

                        processedTab.localFilters = {
                            ...processedTab.localFilters,
                            [DATES]: dateRangeFilter,
                            rangeLag,
                        }
                    }

                    return processedTab
                })
            ),
            set(
                [pageName, 'widgets'],
                // set default ui state on each widget
                mapValues(widgets, (widget) => ({
                    ...widget,
                    data_source:
                        // DOW-10796 backwards compatibility:
                        // Convert legacy Stream widgets to the new unified data source
                        // As users save their dashboards, widgets will be in the correct state automatically
                        widget.data_source === 'ams_stream_traffic_facts' ||
                        widget.data_source === 'ams_stream_conversion_facts'
                            ? 'ams_stream_facts'
                            : widget.data_source,
                    filters: {
                        ...widget.filters,
                        ...(!isEmpty(widget?.filters?.[DATES]) && {
                            [DATES]: serializeDatesFilter(
                                widget.filters[DATES]
                            ),
                        }),
                        ...(!isEmpty(widget?.filters?.rangeLag) && {
                            rangeLag: parseInt(
                                widget.filters.rangeLag[0]?.value ?? 0,
                                10
                            ),
                        }),
                    },
                    ...defaultWidgetState,
                    ...defaultPaginationAndSorter(widget),
                    ...defaultWidgetLayout(widget),
                }))
            ),
            set([pageName, FILTERS], filters),
            set([pageName, 'isDirty'], false)
        )(state)
    },

    // update pagination
    [updateWidgetPagination](state, action) {
        const {
            pageName,
            data: { widgetId, pagination },
        } = action.payload

        return update(
            [pageName, 'widgets', widgetId, 'pagination'],
            (prevPagination) => ({ ...prevPagination, ...pagination })
        )(state)
    },

    // update sorter
    [updateWidgetSorter](state, action) {
        const {
            pageName,
            data: { widgetId, sorter },
        } = action.payload

        return set([pageName, 'widgets', widgetId, 'sorter'], sorter)(state)
    },

    // update table settings
    [updateWidgetTableColumns](state, action) {
        const {
            pageName,
            data: { widgetId, metrics, group_by },
        } = action.payload

        return flow(
            set([pageName, 'widgets', widgetId, 'metrics'], metrics),
            set([pageName, 'widgets', widgetId, 'group_by'], group_by)
        )(state)
    },

    [changeCurrencyCode](state) {
        const { page } = getCurrentPage()
        if (has(state, [page, 'tabs'])) {
            return update([page, 'tabs'], (tabs) =>
                mapValues(tabs, (tab) => ({
                    ...tab,
                    loaded: false,
                }))
            )(state)
        }
        return state
    },
}
