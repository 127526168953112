/**
 * 'DatadogService' is used to separate Datadog RUM metrics for analysis by Forge domain.
 */
export enum DatadogService {
    // general services
    AUTH = 'AUTH',
    AUTOMATION = 'AUTOMATION',
    DASHBOARDS = 'DASHBOARDS',
    SYSTEM = 'SYSTEM', // includes profile, alerts, updates, labels, events, and reports

    // amazon services
    AMAZON_ADVERTISING = 'AMAZON_ADVERTISING',

    // walmart services
    WALMART_ADVERTISING = 'WALMART_ADVERTISING',

    // market intelligence
    SEGMENTS = 'SEGMENTS',
    SHARE_OF_VOICE = 'SHARE_OF_VOICE',

    // misc
    DEPRECATED = 'DEPRECATED',
}

export enum DatadogActionName {
    LOAD_WIDGET = 'load-widget',
    LOAD_TABLE = 'load-table',
    LOAD_TYPEAHEAD = 'load-typeahead',
}
