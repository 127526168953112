import {
    advertiserIdRenderer,
    orderIdRenderer,
    lineItemIdRenderer,
    orderBudgetRenderer,
    lineItemBudgetRenderer,
    lineItemBidRenderer,
    lineItemTypeRenderer,
} from 'components/FieldRenderers/Renderers'
import { brandId } from 'configuration/fieldCreators/brand'
import {
    advertiserId,
    lineItemBid,
    lineItemBudget,
    lineItemDeliveryActivationStatus,
    lineItemDeliveryStatus,
    lineItemId,
    lineItemType,
    orderBudget,
    orderDeliveryActivationStatus,
    orderDeliveryStatus,
    orderId,
    removeLineItemFromLabel,
    removeOrderFromLabel,
} from 'configuration/fieldCreators/dsp'
import { labels } from 'configuration/fieldCreators/labels'
import {
    DspAdvertiserGroupBy,
    DspCampaignFact,
    DspLineItemGroupBy,
    DspOrderGroupBy,
    Field,
} from 'types'

import { DSP_METRICS_COLUMNS_PAGES } from '../dataSources/dsp'

export type DspCampaignFactRecord = DspCampaignFact<
    DspAdvertiserGroupBy & DspOrderGroupBy & DspLineItemGroupBy
>

export const dspAdvertisersTableColumnsConfig: Field<DspCampaignFactRecord>[] =
    [
        advertiserId<DspCampaignFactRecord>({
            renderOptions: { render: advertiserIdRenderer },
        }),
        brandId(),
        ...(DSP_METRICS_COLUMNS_PAGES as Field<DspCampaignFactRecord>[]),
    ]

export const dspOrdersTableColumnsConfig: Field<
    DspCampaignFact<DspOrderGroupBy>
>[] = [
    orderId<DspCampaignFact<DspOrderGroupBy>>({
        renderOptions: { render: orderIdRenderer },
    }),
    orderBudget<DspCampaignFact<DspOrderGroupBy>>({
        renderOptions: { render: orderBudgetRenderer },
    }),
    orderDeliveryActivationStatus<DspCampaignFact<DspOrderGroupBy>>(),
    orderDeliveryStatus<DspCampaignFact<DspOrderGroupBy>>(),
    advertiserId<DspCampaignFact<DspOrderGroupBy>>({
        renderOptions: { render: advertiserIdRenderer },
    }),
    brandId(),
    labels({ dataIndex: ['order', 'labels'] }),
    ...(DSP_METRICS_COLUMNS_PAGES as Field<DspCampaignFact<DspOrderGroupBy>>[]),
]

export const dspLineItemsTableColumnsConfig: Field<
    DspCampaignFact<DspLineItemGroupBy>
>[] = [
    lineItemId<DspCampaignFact<DspLineItemGroupBy>>({
        renderOptions: { render: lineItemIdRenderer },
    }),
    lineItemType<DspCampaignFact<DspLineItemGroupBy>>({
        renderOptions: { render: lineItemTypeRenderer },
    }),
    lineItemBudget<DspCampaignFact<DspLineItemGroupBy>>({
        renderOptions: {
            render: (cellRenderProps) =>
                lineItemBudgetRenderer({
                    cellRenderProps,
                    dataIndex: ['line_item', 'budget'],
                }),
        },
    }),
    lineItemDeliveryActivationStatus<DspCampaignFact<DspLineItemGroupBy>>(),
    lineItemDeliveryStatus<DspCampaignFact<DspLineItemGroupBy>>(),
    lineItemBid<DspCampaignFact<DspLineItemGroupBy>>({
        renderOptions: {
            render: (cellRenderProps) =>
                lineItemBidRenderer({
                    cellRenderProps,
                    dataIndex: ['line_item', 'bid'],
                }),
        },
    }),
    orderId<DspCampaignFact<DspLineItemGroupBy>>({
        renderOptions: { render: orderIdRenderer },
    }),
    orderBudget<DspCampaignFact<DspLineItemGroupBy>>({
        renderOptions: { render: orderBudgetRenderer },
    }),
    advertiserId<DspCampaignFact<DspLineItemGroupBy>>({
        renderOptions: { render: advertiserIdRenderer },
    }),
    brandId(),
    labels({ dataIndex: ['line_item', 'labels'] }),
    ...(DSP_METRICS_COLUMNS_PAGES as Field<
        DspCampaignFact<DspLineItemGroupBy>
    >[]),
]

export const labelDspLineItemsTableColumnsConfig: Field<
    DspCampaignFact<DspLineItemGroupBy>
>[] = [...dspLineItemsTableColumnsConfig, removeLineItemFromLabel()]

export const labelDspOrdersTableColumnsConfig: Field<
    DspCampaignFact<DspOrderGroupBy>
>[] = [...dspOrdersTableColumnsConfig, removeOrderFromLabel()]
