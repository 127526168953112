import { memo, ReactElement, useState } from 'react'

import { Button } from 'antd'
import { AxiosResponse } from 'axios'
import isEqual from 'lodash/isEqual'

import { FormattedRange } from 'components/FormattedRange'
import { formatCurrency } from 'helpers/formatting'
import { useCerebroApiRequest } from 'hooks'
import { CurrencyCode, SuggestedBid } from 'types'
import message from 'utilities/message'

interface Props<RecordType> {
    currencyCode: CurrencyCode
    disabled?: boolean
    fieldName: keyof RecordType
    readOnly?: boolean
    record: RecordType
    reloadData: () => void
    rowIndex: number
    serializeFieldValues?: (params: { bid: number }) => object
    updateRecord: (data: { rowIndex: number; record: RecordType }) => void
    updateRequestApi: (...args: any) => Promise<AxiosResponse<any>>
    updateRequestData: any
    updateRequestSuccessMesg: string
    value: SuggestedBid
    actionButtonVisible?: boolean
}

function SuggestedBidField<RecordType>({
    currencyCode,
    disabled = false,
    fieldName,
    readOnly = false,
    record,
    reloadData,
    rowIndex,
    serializeFieldValues = (values) => values,
    updateRecord,
    updateRequestApi,
    updateRequestData,
    updateRequestSuccessMesg,
    value,
    actionButtonVisible = true,
}: Props<RecordType>): ReactElement {
    const [loading, setLoading] = useState(false)
    const makeCerebroApiRequest = useCerebroApiRequest()

    const { suggested, rangeStart, rangeEnd } = value

    const optimisticallyUpdateColumns = (data: object): void => {
        const newData = {
            ...record,
            [fieldName]: {
                ...record[fieldName],
                ...data,
            },
        }

        updateRecord({
            rowIndex,
            record: newData,
        })
    }

    const handleApplySuggestedBid = async (): Promise<void> => {
        setLoading(true)
        const params = { bid: suggested }

        await makeCerebroApiRequest({
            request: updateRequestApi(
                updateRequestData,
                serializeFieldValues(params)
            ),
            onRequestSuccess: (response) => {
                message.success(updateRequestSuccessMesg)

                if (response.data) {
                    optimisticallyUpdateColumns(response.data)
                } else {
                    reloadData()
                }
            },
        })

        setLoading(false)
    }

    const suggestedBid = formatCurrency(suggested, {
        decimal: true,
        currencyCode,
    })

    const start = formatCurrency(rangeStart, {
        decimal: true,
        currencyCode,
    })

    const end = formatCurrency(rangeEnd, {
        decimal: true,
        currencyCode,
    })

    if (readOnly) {
        return <div>{suggestedBid}</div>
    }

    return (
        <div
            style={{
                width: '100%',
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'center',
            }}
        >
            <div
                style={
                    actionButtonVisible
                        ? { marginBottom: '0.5em' }
                        : { marginBottom: '0' }
                }
            >
                <span>{suggestedBid}</span>
                {actionButtonVisible && (
                    <Button
                        disabled={disabled}
                        loading={loading}
                        onClick={() => handleApplySuggestedBid()}
                        size="small"
                        style={{ marginLeft: '0.6em' }}
                    >
                        Apply
                    </Button>
                )}
            </div>
            <FormattedRange
                rangeStart={start}
                rangeEnd={end}
                actionButtonVisible={actionButtonVisible}
            />
        </div>
    )
}

export default memo(SuggestedBidField, isEqual) as typeof SuggestedBidField
