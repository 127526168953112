import set from 'lodash/fp/set'
import update from 'lodash/fp/update'

import {
    fetchBrandGroupsSuccess,
    createBrandGroupSuccess,
    updateBrandGroupSuccess,
    deleteBrandGroupSuccess,
} from 'actions/ui/app'

export default {
    [fetchBrandGroupsSuccess](state, action) {
        return set(['brandGroups'], action.payload.results, state)
    },

    [createBrandGroupSuccess](state, action) {
        const newBrandGroup = action.payload

        return update(['brandGroups'], (brandGroups) =>
            brandGroups.concat(newBrandGroup)
        )(state)
    },

    [updateBrandGroupSuccess](state, action) {
        const updatedBrandGroup = action.payload

        return update(['brandGroups'], (brandGroups) =>
            brandGroups.map((brandGroup) =>
                brandGroup.id === updatedBrandGroup.id
                    ? updatedBrandGroup
                    : brandGroup
            )
        )(state)
    },

    [deleteBrandGroupSuccess](state, action) {
        const deletedBrandGroupId = action.payload

        return update(['brandGroups'], (brandGroups) =>
            brandGroups.filter(
                (brandGroup) => brandGroup.id !== deletedBrandGroupId
            )
        )(state)
    },
}
