import { ReactElement } from 'react'

import Icon from '@ant-design/icons'

import CampaignIconSvg from './CampaignIconSvg'
import { IconProps } from '../localTypes'

const CampaignIcon = (props: IconProps): ReactElement => (
    <Icon component={CampaignIconSvg} {...props} />
)

export default CampaignIcon
