import { addCache } from 'helpers/utilities'
import {
    CerebroApiOptions,
    BannerRequestBody,
    CerebroResourceResponse,
    CerebroPaginatedResponse,
    Report,
} from 'types'

import { axiosInstanceOrganizations } from './client'
import { getCerebroHeaders } from './headers'

export const getProductAdFacts = (
    params: BannerRequestBody,
    options: CerebroApiOptions = {}
): Promise<CerebroPaginatedResponse<any>> =>
    axiosInstanceOrganizations.post(
        `/api/product_ad_facts/aggregate/banner/`,
        params,
        {
            headers: getCerebroHeaders(options.headers),
        }
    )
addCache(getProductAdFacts)

export const getProductAdWithFacts = (
    params: BannerRequestBody,
    options: CerebroApiOptions = {}
): Promise<CerebroPaginatedResponse<any>> =>
    axiosInstanceOrganizations.post(
        `/api/product_ad_only_facts/aggregate/banner/`,
        params,
        {
            headers: getCerebroHeaders(options.headers),
        }
    )
addCache(getProductAdWithFacts)

export const asyncUpdateProductAdFacts = (
    data: any,
    params: BannerRequestBody,
    options: CerebroApiOptions = {}
): Promise<CerebroResourceResponse<any>> =>
    axiosInstanceOrganizations.patch(
        `/api/product_ad_facts/aggregate/banner/mutate/`,
        data,
        {
            params: { ...params, metrics: null },
            headers: getCerebroHeaders(options.headers),
        }
    )

export const getProductAdFactsExport = (
    params: BannerRequestBody
): Promise<CerebroResourceResponse<Report>> =>
    axiosInstanceOrganizations.post(
        `/api/product_ad_facts/aggregate/banner/export/`,
        params
    )
