import { CheckCircleOutlined } from '@ant-design/icons'
import { Flex, Tooltip } from 'antd'
import { Translation } from 'react-i18next'

import IntegrationAliasField from 'components/EditableFields/IntegrationFields/IntegrationAliasField'
import { Flag } from 'components/Flag'
import { COUNTRY_LABELS } from 'const/codes'
import { UNDEFINED_VALUE } from 'const/formatting'
import { IntegrationType } from 'const/integrations'
import { formatCerebroDateTime, titleCase } from 'helpers/formatting'
import { isUnset } from 'helpers/utilities'
import {
    CountryCode,
    Field,
    Integration,
    SellingPartnerIntegration,
} from 'types'

import {
    createActionField,
    createCerebroDateTimeField,
    createField,
    createNumberField,
    createTitleField,
} from './createField'
import { FieldCreatorOptions } from './localTypes'

export function integrationDescription<
    RecordType extends Integration | SellingPartnerIntegration,
>(
    options: FieldCreatorOptions<RecordType> = {},
    integrationKey: IntegrationType | undefined = undefined
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['alias']
    const name = (
        <Translation>
            {(t) => t('table:fields.integrationDescription.name', 'Name')}
        </Translation>
    )
    return createField({
        ...options,
        id: 'integration_description',
        name,
        shortName: name,
        minWidth: 200,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: ({ value, record, reloadData }) =>
                integrationKey ? (
                    <IntegrationAliasField
                        record={record}
                        value={value}
                        reloadData={reloadData}
                        integrationKey={integrationKey}
                    />
                ) : (
                    value
                ),
        },
        width: 300,
    })
}

export function integrationType<
    RecordType extends Integration | SellingPartnerIntegration,
>(options: FieldCreatorOptions<RecordType> = {}): Field<RecordType> {
    const dataIndex = options.dataIndex ?? [
        'seller_central_account',
        'integration_type',
    ]

    return createTitleField({
        ...options,
        id: 'integration_type',
        name: 'Integration Type',
        shortName: 'Integration Type',
        isResizeable: true,
        minWidth: 200,
        isVisible: true,
        dataIndex,
        emptyValue: '',
        width: 200,
    })
}

export function integrationAccountId<
    RecordType extends SellingPartnerIntegration,
>(options: FieldCreatorOptions<RecordType> = {}): Field<RecordType> {
    const dataIndex = options.dataIndex ?? [
        'seller_central_account',
        'selling_partner_id',
    ]

    return createField({
        ...options,
        id: 'integration_account_id',
        name: 'Selling Partner ID',
        shortName: 'Selling Partner ID',
        minWidth: 200,
        dataIndex,
        emptyValue: '',
        localDefinition:
            'Identifier for the integrated account. Merchant Token in Seller Central or Vendor Account ID in Vendor Central.',
        width: 200,
    })
}

export function integrationCountry<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['seller_central_account', 'country']

    return createField({
        ...options,
        id: 'integration_country',
        name: 'Country',
        shortName: 'Country',
        minWidth: 100,
        dataIndex,
        renderOptions: {
            render: ({ value }) =>
                isUnset(value) ? (
                    UNDEFINED_VALUE
                ) : (
                    <span className="fg-flag-container">
                        <Flag country={value} />
                        {COUNTRY_LABELS[value]}
                    </span>
                ),
        },
        width: 150,
    })
}

export function integrationStatus<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['active']

    return createField({
        ...options,
        id: 'integration_status',
        name: 'Status',
        shortName: 'Status',
        minWidth: 100,
        dataIndex,
        renderOptions: {
            render: ({ value }) => (value ? 'Active' : 'Inactive'),
        },
        width: 200,
    })
}

export function integrationAutoSelectNewAccounts<
    RecordType extends Integration,
>(options: FieldCreatorOptions<RecordType> = {}): Field<RecordType> {
    const name = (
        <Translation>
            {(t) =>
                t(
                    'table:fields.integrationAutoSelectNewAccounts',
                    'Auto-select New Accounts'
                )
            }
        </Translation>
    )
    return createField({
        ...options,
        id: 'integration_auto_select_new_accounts',
        name,
        shortName: name,
        dataIndex: [],
        minWidth: 100,
        renderOptions: {
            render: ({ record }) => {
                return (
                    <span>
                        {record.auto_select_new_accounts ? (
                            <CheckCircleOutlined
                                style={{
                                    color: '#5561f6',
                                    fontSize: 16,
                                }}
                            />
                        ) : null}
                    </span>
                )
            },
        },
        align: 'center',
        sorter: options.sorter ?? false,
    })
}

export function integrationTotalAccountCount<RecordType extends Integration>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const name = (
        <Translation>
            {(t) =>
                t('table:fields.integrationTotalAccountCount', 'Total Accounts')
            }
        </Translation>
    )
    return createNumberField({
        ...options,
        id: 'integration_total_account_count',
        name,
        dataIndex: options.dataIndex ?? ['total_profile_count'],
        shortName: name,
        minWidth: 100,
        sorter: options.sorter ?? false,
        align: 'right',
    })
}

export function integrationActiveAccountCount<RecordType extends Integration>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const name = (
        <Translation>
            {(t) =>
                t('table:fields.integrationAccountCount', 'Active Accounts')
            }
        </Translation>
    )
    return createNumberField({
        ...options,
        id: 'integration_active_account_count',
        name,
        shortName: name,
        dataIndex: options.dataIndex ?? ['active_profile_count'],
        minWidth: 100,
        sorter: options.sorter ?? false,
        align: 'right',
    })
}

export function integrationAccountTypeList<RecordType extends Integration>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const name = (
        <Translation>
            {(t) =>
                t(
                    'table:fields.integrationAccountTypeList.name',
                    'Account Types'
                )
            }
        </Translation>
    )
    return createField({
        ...options,
        id: 'integration_account_types',
        dataIndex: [],
        name,
        shortName: name,
        minWidth: 180,
        renderOptions: {
            render: ({ record }) => {
                return (
                    <div>
                        {(record.active_profile_types ?? [])
                            .map((t: string) => titleCase(t))
                            .join(', ')}
                    </div>
                )
            },
        },
        sorter: options.sorter ?? false,
    })
}

export function integrationCountryList<RecordType extends Integration>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const name = (
        <Translation>
            {(t) => t('table:fields.integrationCountryList.name', 'Countries')}
        </Translation>
    )
    return createField({
        ...options,
        id: 'integration_countries_list',
        dataIndex: [],
        name,
        shortName: name,
        minWidth: 240,
        renderOptions: {
            render: ({ record }) => {
                const allCountryCodes = record.active_profile_countries ?? []
                const countryCodes = new Set<CountryCode>(allCountryCodes)
                return (
                    <div>
                        {[...countryCodes].map((country_code) => {
                            return (
                                <span
                                    key={country_code}
                                    style={{
                                        display: 'inline-block',
                                        margin: '0 8px 8px 0',
                                    }}
                                >
                                    <Tooltip
                                        title={`${COUNTRY_LABELS[country_code]} (${country_code})`}
                                    >
                                        <Flex gap={4}>
                                            <Flag country={country_code} />
                                        </Flex>
                                    </Tooltip>
                                </span>
                            )
                        })}
                    </div>
                )
            },
        },
        sorter: options.sorter ?? false,
    })
}

export function integrationCreatedBy<RecordType extends Integration>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['created_by']
    const name = (
        <Translation>
            {(t) => t('table:fields.integrationCreatedBy.name', 'Created By')}
        </Translation>
    )
    return createField({
        ...options,
        id: 'integration_created_by',
        name,
        shortName: name,
        isResizeable: true,
        minWidth: 160,
        isVisible: true,
        dataIndex,
    })
}

export function integrationCreatedDate<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['created_at']
    const name = (
        <Translation>
            {(t) =>
                t('table:fields.integrationCreatedDate.name', 'Created Date')
            }
        </Translation>
    )
    return createCerebroDateTimeField({
        ...options,
        id: 'integration_created_date',
        name,
        shortName: name,
        minWidth: 180,
        dataIndex,
        renderOptions: {
            render: ({ value }) => formatCerebroDateTime(value),
        },
    })
}

export function deleteIntegration<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createActionField({
        ...options,
        id: 'delete_integration',
        dataIndex: [],
        isResizeable: false,
        minWidth: options.minWidth ?? 68,
        isVisible: true,
        sorter: false,
        fixed: 'right',
        align: 'center',
    })
}

export function integrationClientId<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['api_key_client_id']
    const name = (
        <Translation>
            {(t) => t('table:fields.integrationClientId.name', 'Client ID')}
        </Translation>
    )
    return createField({
        ...options,
        id: 'integration_client_id',
        name,
        shortName: name,
        minWidth: 200,
        dataIndex,
        width: 300,
    })
}
