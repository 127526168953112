import { ReactElement } from 'react'

import { Translation } from 'react-i18next'

import { ColorCircle } from 'components/ColorCircle'
import { PaintBrushIcon } from 'components/Icons'
import { Label } from 'types'

import { DetailWrapper, DetailIcon } from './shared'

export function labelColorRenderer(label: Label): ReactElement {
    return (
        <DetailWrapper
            leftContent={
                <DetailIcon
                    icon={PaintBrushIcon}
                    tooltipTitle={
                        <Translation>
                            {(t) => t('common:color', 'Color')}
                        </Translation>
                    }
                />
            }
            rightContent={<ColorCircle color={label.color} />}
        />
    )
}
