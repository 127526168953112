import { CSSProperties, ReactElement } from 'react'

import classNames from 'classnames'
import noop from 'lodash/noop'
import darken from 'polished/lib/color/darken'
import saturate from 'polished/lib/color/saturate'
import transparentize from 'polished/lib/color/transparentize'

import { IconButton } from 'components/Buttons'
import { CloseIcon } from 'components/Icons'
import { ResourceLink } from 'components/Links/ResourceLink'
import { labelUrl } from 'helpers/urls'
import { Label } from 'types'

import styles from './styles.scss'

interface Props {
    isClosable?: boolean
    label: Label
    onClose?: () => void
    className?: string
    style?: CSSProperties
}

export default function LabelElement({
    className,
    isClosable = false,
    label,
    onClose = noop,
    style,
}: Props): ReactElement {
    const color = saturate(0.22, darken(0.17, label.color))
    const backgroundColor = transparentize(0.87, label.color)
    return (
        <div
            className={classNames(className, styles['label-tag'])}
            style={{
                ...style,
                backgroundColor,
            }}
        >
            <ResourceLink
                to={labelUrl(label.id)}
                style={{ textDecoration: 'none' }}
            >
                <div
                    className={styles['label-link']}
                    style={{
                        color,
                    }}
                >
                    {label.name}
                </div>
            </ResourceLink>
            {isClosable && (
                <IconButton
                    className={styles['close-btn']}
                    size="small"
                    icon={
                        <CloseIcon
                            style={{
                                fontSize: 12,
                            }}
                        />
                    }
                    tooltipTitle="Remove label"
                    onClick={onClose}
                    style={{
                        color,
                    }}
                />
            )}
        </div>
    )
}
