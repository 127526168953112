import { ReactElement } from 'react'

import isEmpty from 'lodash/isEmpty'
import queryString from 'query-string'
import { Link } from 'react-router-dom'

import { UNDEFINED_VALUE } from 'const/formatting'
import { userHasDashboardsOnlyPermissions } from 'helpers/featurePermissions'
import { campaignUrl } from 'helpers/urls'
import { useUserHasPermissions } from 'hooks'
import { Campaign } from 'types'

interface Props {
    campaign?: Campaign | null
    queryParams?: {}
}

const CampaignLink = ({
    campaign = null,
    queryParams = {},
}: Props): ReactElement => {
    const dashboardOnlyExperience = useUserHasPermissions(
        userHasDashboardsOnlyPermissions
    )

    if (!campaign || isEmpty(campaign)) {
        return <span>{UNDEFINED_VALUE}</span>
    }

    const pathname = campaignUrl(campaign.id)
    const search = queryString.stringify(queryParams)

    return dashboardOnlyExperience ? (
        <span>{campaign.name}</span>
    ) : (
        <Link
            to={{ pathname, search }}
            title={campaign.name}
            className="campaign-link"
        >
            {campaign.name}
        </Link>
    )
}

export default CampaignLink
