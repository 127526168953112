import { ThemeConfig } from 'antd'
import color from 'color'

const black = '#000'
const white = '#fff'
const primary = '#5561F6'
const linkHoverColor = '#1823A5'
const disabledColor = color(black).alpha(0.25).string()
const disabledBgColor = '#f5f5f5'
const headerBgColor = '#F8F8F9'
const tableBorderColor = '#EDEDED'
const tableCellBgColor = white
const containerBorderColor = 'rgba(0, 0, 0, 0.12)'

export const antdTheme: ThemeConfig = {
    token: {
        colorPrimary: primary,
        colorTextHeading: color(black).alpha(0.85).string(),
        colorLink: primary,
        colorLinkHover: linkHoverColor,
        colorText: color(black).alpha(0.65).string(),
        fontFamily: "proxima-nova, 'Noto Sans', 'Noto Sans JP', sans-serif",
        colorTextDisabled: disabledColor,
        colorBgContainerDisabled: disabledBgColor,
        colorBorderSecondary: 'rgba(5, 5, 5, 0.1)', // default is rgba(5, 5, 5, 0.06)
        borderRadius: 4,
        colorBgLayout: white,
        fontSizeHeading1: 27,
        fontSizeHeading2: 21,
        fontSizeHeading3: 19,
        fontSizeHeading4: 17,
        fontSizeHeading5: 15,
    },
    components: {
        Card: {
            padding: 24,
            lineHeight: 1.5715,
            headerHeight: 48,
            colorBorder: '#b3b2b3',
            colorBorderSecondary: containerBorderColor,
            borderRadiusLG: 6,
            boxShadowTertiary:
                '0 1px 1px -1px rgba(0, 0, 0, 0.1), 0 2px 1px -1px rgba(0, 0, 0, 0.02), 0 2px 2px -2px rgba(0, 0, 0, 0.04);',
        },
        Tooltip: {
            colorBgBase: '#30343c',
        },
        Button: {
            colorTextDisabled: disabledColor,
            colorBgContainerDisabled: disabledBgColor,
            fontSizeSM: 12.6,
            colorLinkHover: primary,
            borderRadius: 5,
        },
        Table: {
            fontSize: 12.6,
            padding: 0,
            cellPaddingInlineSM: 10,
            cellPaddingBlockSM: 8,
            headerSplitColor: 'transparent',
            headerBg: headerBgColor,
            borderColor: tableBorderColor,
            selectionColumnWidth: 44,
            bodySortBg: tableCellBgColor,
            headerSortActiveBg: headerBgColor,
        },
        Layout: {
            headerBg: '#1e3050',
            siderBg: '#1e3050',
        },
        Menu: {
            colorBgBase: 'none',
            subMenuItemBg: 'none',
            darkSubMenuItemBg: 'none',
        },
        Pagination: {
            itemSize: 28,
            borderRadius: 4,
            marginXS: 6,
        },
        Steps: {
            descriptionMaxWidth: 180,
        },
    },
}
