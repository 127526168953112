import { SELLER_BRAND_TYPE, VENDOR_BRAND_TYPE } from 'const/brands'
import {
    COUNTRY_CODES,
    LANGUAGE_CODES,
    PLATFORM_CODES,
    REGION_CODES,
} from 'const/codes'
import {
    HEADLINE_SEARCH,
    SPONSORED_DISPLAY,
    SPONSORED_PRODUCT,
} from 'const/factTypes'
import { BROAD, EXACT, PHRASE } from 'const/matchTypes'
import { ARCHIVED, ENABLED, PAUSED } from 'const/resourceStates'
import { HEADLINE, ORGANIC, SPONSORED, VIDEO } from 'const/resultTypes'
import { HYBRID, SELLER, VENDOR } from 'const/segments'
import { AUTO, MANUAL } from 'const/targetingTypes'

// List of 24 colors in a gradient from navy to orange to green back to navy
const HOURS_OF_DAY_COLORS = [
    '#000080',
    '#2b1b6b',
    '#402960',
    '#553755',
    '#6a454b',
    '#805240',
    '#bf7c20',
    '#ea970b',
    '#ffa500',
    '#eaa705',
    '#d4aa0a',
    '#bfac0f',
    '#95b01a',
    '#6ab524',
    '#40b92e',
    '#15be39',
    '#00c03e',
    '#00b044',
    '#00a049',
    '#008054',
    '#00705a',
    '#005064',
    '#00406a',
    '#002075',
]

export const GROUPING_COLORS = {
    region: {
        [REGION_CODES.NA]: '#336ced',
        [REGION_CODES.JP]: '#b56dff',
        [REGION_CODES.EU]: '#f4292a',
    },
    country: {
        [COUNTRY_CODES.AU]: '#d3027d',
        [COUNTRY_CODES.CA]: '#664CCE',
        [COUNTRY_CODES.FR]: '#155696',
        [COUNTRY_CODES.DE]: '#06C2CE',
        [COUNTRY_CODES.IN]: '#26B99E',
        [COUNTRY_CODES.IT]: '#1DAA00',
        [COUNTRY_CODES.JP]: '#4F7019',
        [COUNTRY_CODES.MX]: '#BA66C8',
        [COUNTRY_CODES.PL]: '#6B2477',
        [COUNTRY_CODES.ES]: '#D8A420',
        [COUNTRY_CODES.UK]: '#DE6836',
        [COUNTRY_CODES.US]: '#0799FC',
    },
    language: {
        [LANGUAGE_CODES.CES]: '#E96C53',
        [LANGUAGE_CODES.ENG]: '#425593',
        [LANGUAGE_CODES.FRA]: '#239DD1',
        [LANGUAGE_CODES.GER]: '#373b3b',
        [LANGUAGE_CODES.ITA]: '#E87159',
        [LANGUAGE_CODES.JPN]: '#8D929A',
        [LANGUAGE_CODES.NLD]: '#306f84',
        [LANGUAGE_CODES.POL]: '#3f8e86',
        [LANGUAGE_CODES.SPA]: '#d1687f',
        [LANGUAGE_CODES.TUR]: '#832a44',
        [LANGUAGE_CODES.ZHO]: '#A79116',
    },
    state: {
        [ENABLED]: '#39A765',
        [PAUSED]: '#7E8EA9',
        [ARCHIVED]: '#ea645e',
    },
    profile__type: {
        [VENDOR_BRAND_TYPE]: '#22A3B4',
        [SELLER_BRAND_TYPE]: '#F06678',
    },
    campaign__targeting_type: {
        [AUTO]: '#379bc3',
        [MANUAL]: '#e77613',
    },
    campaign__campaign_type: {
        [SPONSORED_PRODUCT]: '#C29B00',
        [HEADLINE_SEARCH]: '#f43c70',
        [SPONSORED_DISPLAY]: '#15A881',
    },
    keyword__match_type: {
        [EXACT]: '#46A09E',
        [PHRASE]: '#cf453e',
        [BROAD]: '#D17E0A',
    },
    keyword__platform: {
        [PLATFORM_CODES.DESKTOP]: '#17A185',
        [PLATFORM_CODES.MOBILE]: '#BC990B',
    },
    result_type: {
        [ORGANIC]: '#11A786',
        [SPONSORED]: '#009DD1',
        [HEADLINE]: '#C18906',
        [VIDEO]: '#D9534F',
    },
    vendor_seller: {
        [VENDOR]: '#2C11D4',
        [SELLER]: '#2B8CFF',
        [HYBRID]: '#4CBA9F',
    },
    report_date_hour_of_day: HOURS_OF_DAY_COLORS.reduce(
        (acc, color, index) => ({
            ...acc,
            [index]: color,
        }),
        {}
    ),
}

export const AVAILABLE_SYMBOLS = [
    'circle',
    'square',
    'diamond',
    'triangle',
    'triangle-down',
]

export const Y_AXIS_COLORS = ['#5C55E5', '#434348']
export const Y_AXIS_COLORS_BOTH = ['#5C55E5', '#D59700']
export const Y_AXIS_PRIOR_PERIOD_COLUMN_COLORS = ['#DFDDFF', '#DFDFDF']
export const DEFAULT_AXIS_COLORS_WHEN_MULTIPLE = [
    Y_AXIS_COLORS[0],
    '#D59700',
    Y_AXIS_COLORS[1],
    '#BE4965',
]

export const DEFAULT_SERIES = {
    marker: {
        symbol: 'circle',
        lineColor: undefined,
        lineWidth: 1,
        states: {
            hover: {
                lineWidthPlus: 0,
                radius: 5,
                animation: {
                    duration: 150,
                },
            },
        },
    },
    lineWidth: 2,
    states: {
        hover: {
            lineWidth: 2,
            halo: {
                size: 0,
            },
        },
    },
    animation: false,
}

export const DEFAULT_TITLE = {
    text: undefined,
    floating: true,
    style: {
        height: 0,
    },
}

export const DEFAULT_TOOLTIP = {
    backgroundColor: undefined,
    borderWidth: 0,
    shadow: false,
    useHTML: true,
}
