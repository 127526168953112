import { handleActions } from 'redux-actions'

import { keywordsTableColumnsConfig } from 'configuration/tables'
import {
    DATES,
    KEYWORD_TEXT,
    KEYWORD_MATCH_TYPES,
    KEYWORD_STATES,
    METRIC_FILTERS,
    AD_GROUPS,
    AD_GROUP_STATES,
} from 'const/filters'
import { FILTER_SETTINGS, LOCAL_FILTERS } from 'const/reducerKeys'
import { serializeColumns } from 'helpers/tables'
import { defaultTab, getDefaultTable } from 'reducers/ui/defaults'

const initialState = {
    ...defaultTab,
    [LOCAL_FILTERS]: {
        [KEYWORD_TEXT]: null,
        [AD_GROUPS]: [],
        [AD_GROUP_STATES]: [],
        [KEYWORD_MATCH_TYPES]: [],
        [KEYWORD_STATES]: [],
        [METRIC_FILTERS]: [],
    },
    [FILTER_SETTINGS]: {
        anchored: [KEYWORD_TEXT, DATES, AD_GROUPS, AD_GROUP_STATES],
        order: [KEYWORD_MATCH_TYPES, KEYWORD_STATES, METRIC_FILTERS],
        displayState: {
            [KEYWORD_MATCH_TYPES]: true,
            [KEYWORD_STATES]: true,
            [METRIC_FILTERS]: true,
        },
    },
    table: getDefaultTable(serializeColumns(keywordsTableColumnsConfig)),
}

export default handleActions({}, initialState)
