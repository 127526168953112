import { CountryCode } from 'types'

export const REGION_CODES = {
    JP: 'JP',
    EU: 'EU',
    NA: 'NA',
}

export const REGION_LABELS = {
    [REGION_CODES.EU]: 'Europe',
    [REGION_CODES.JP]: 'APAC',
    [REGION_CODES.NA]: 'North America',
}

export const COUNTRY_CODES: { [key: string]: CountryCode } = {
    AE: 'AE',
    AU: 'AU',
    BE: 'BE',
    BR: 'BR',
    CA: 'CA',
    DE: 'DE',
    ES: 'ES',
    FR: 'FR',
    IN: 'IN',
    IT: 'IT',
    JP: 'JP',
    MX: 'MX',
    NL: 'NL',
    PL: 'PL',
    SA: 'SA',
    SG: 'SG',
    SE: 'SE',
    TR: 'TR',
    UK: 'UK',
    US: 'US',
}

export const SP_API_COUNTRY_CODES = [
    COUNTRY_CODES.AE,
    COUNTRY_CODES.AU,
    COUNTRY_CODES.BR,
    COUNTRY_CODES.CA,
    COUNTRY_CODES.DE,
    COUNTRY_CODES.ES,
    COUNTRY_CODES.FR,
    COUNTRY_CODES.IN,
    COUNTRY_CODES.IT,
    COUNTRY_CODES.JP,
    COUNTRY_CODES.MX,
    COUNTRY_CODES.NL,
    // COUNTRY_CODES.SA, # Saudi Arabia is not supported
    COUNTRY_CODES.SG,
    COUNTRY_CODES.SE,
    COUNTRY_CODES.TR,
    COUNTRY_CODES.UK,
    COUNTRY_CODES.US,
]

export const COUNTRY_LABELS = {
    [COUNTRY_CODES.AE]: 'United Arab Emirates',
    [COUNTRY_CODES.AU]: 'Australia',
    [COUNTRY_CODES.BE]: 'Belgium',
    [COUNTRY_CODES.BR]: 'Brazil',
    [COUNTRY_CODES.CA]: 'Canada',
    [COUNTRY_CODES.ES]: 'Spain',
    [COUNTRY_CODES.DE]: 'Germany',
    [COUNTRY_CODES.FR]: 'France',
    [COUNTRY_CODES.IN]: 'India',
    [COUNTRY_CODES.IT]: 'Italy',
    [COUNTRY_CODES.JP]: 'Japan',
    [COUNTRY_CODES.MX]: 'Mexico',
    [COUNTRY_CODES.NL]: 'Netherlands',
    [COUNTRY_CODES.PL]: 'Poland',
    [COUNTRY_CODES.SA]: 'Saudi Arabia',
    [COUNTRY_CODES.SG]: 'Singapore',
    [COUNTRY_CODES.SE]: 'Sweden',
    [COUNTRY_CODES.TR]: 'Turkey',
    [COUNTRY_CODES.UK]: 'United Kingdom',
    [COUNTRY_CODES.US]: 'United States',
}

export const COUNTRY_CURRENCIES = {
    [COUNTRY_CODES.AE]: 'AED',
    [COUNTRY_CODES.AU]: 'AUD',
    [COUNTRY_CODES.BE]: 'EUR',
    [COUNTRY_CODES.BR]: 'BRL',
    [COUNTRY_CODES.CA]: 'CAD',
    [COUNTRY_CODES.ES]: 'EUR',
    [COUNTRY_CODES.DE]: 'EUR',
    [COUNTRY_CODES.FR]: 'EUR',
    [COUNTRY_CODES.IN]: 'INR',
    [COUNTRY_CODES.IT]: 'EUR',
    [COUNTRY_CODES.JP]: 'JPY',
    [COUNTRY_CODES.MX]: 'MXN',
    [COUNTRY_CODES.NL]: 'EUR',
    [COUNTRY_CODES.PL]: 'PLN',
    [COUNTRY_CODES.SA]: 'SAR',
    [COUNTRY_CODES.SE]: 'SEK',
    [COUNTRY_CODES.SG]: 'SGD',
    [COUNTRY_CODES.TR]: 'TRY',
    [COUNTRY_CODES.UK]: 'GBP',
    [COUNTRY_CODES.US]: 'USD',
}

type LanguageCodes = {
    [index: string]: string
}

export const LANGUAGE_CODES: LanguageCodes = {
    ARA: 'ARA',
    CES: 'CES',
    ENG: 'ENG',
    FRA: 'FRA',
    GER: 'GER',
    ITA: 'ITA',
    JPN: 'JPN',
    NLD: 'NLD',
    POL: 'POL',
    POR: 'POR',
    SPA: 'SPA',
    SWE: 'SWE',
    TUR: 'TUR',
    ZHO: 'ZHO',
}

export const LANGUAGE_LABELS = {
    [LANGUAGE_CODES.ARA]: 'Arabic',
    [LANGUAGE_CODES.CES]: 'Czech',
    [LANGUAGE_CODES.ENG]: 'English',
    [LANGUAGE_CODES.FRA]: 'French',
    [LANGUAGE_CODES.GER]: 'German',
    [LANGUAGE_CODES.ITA]: 'Italian',
    [LANGUAGE_CODES.JPN]: 'Japanese',
    [LANGUAGE_CODES.NLD]: 'Dutch',
    [LANGUAGE_CODES.POL]: 'Polish',
    [LANGUAGE_CODES.POR]: 'Portuguese',
    [LANGUAGE_CODES.SPA]: 'Spanish',
    [LANGUAGE_CODES.SWE]: 'Swedish',
    [LANGUAGE_CODES.TUR]: 'Turkish',
    [LANGUAGE_CODES.ZHO]: 'Chinese',
}

export const COUNTRY_LANGUAGE_MAP = {
    AE: 'ARA',
    AU: 'ENG',
    BE: 'FRA',
    BR: 'POR',
    CA: 'ENG',
    DE: 'GER',
    ES: 'SPA',
    FR: 'FRA',
    IN: 'ENG',
    IT: 'ITA',
    JP: 'JPN',
    MX: 'SPA',
    NL: 'NLD',
    TR: 'TUR',
    SG: 'ENG',
    UK: 'ENG',
    US: 'ENG',
}

export const PLATFORM_CODES = {
    BOTH: 'desktop,mobile',
    DESKTOP: 'desktop',
    MOBILE: 'mobile',
}

export const PLATFORM_LABELS = {
    [PLATFORM_CODES.BOTH]: 'Both',
    [PLATFORM_CODES.DESKTOP]: 'Desktop',
    [PLATFORM_CODES.MOBILE]: 'Mobile',
}
