import get from 'lodash/get'
import values from 'lodash/values'
import createCachedSelector from 're-reselect'
import { createSelector } from 'reselect'

import {
    RootReduxState,
    OrganizationsState,
    OrganizationGroupsState,
    UsersState,
} from 'types'

const getOrganizations = (state: RootReduxState): OrganizationsState =>
    state.orgs.organizations

const getOrganizationGroups = (
    state: RootReduxState
): OrganizationGroupsState => state.orgs.groups

const getUsers = (state: RootReduxState): UsersState => state.orgs.users

// organizations
export const selectOrganizations = createSelector(getOrganizations, (orgs) =>
    values(orgs)
)

export const selectOrganization = createCachedSelector(
    getOrganizations,
    (_state: RootReduxState, orgId: string) => orgId,
    (orgs, orgId) => get(orgs, [orgId], undefined)
)((_state, orgId) => orgId)

export const selectCurrentOrganization = createSelector(
    (state: RootReduxState) => get(state, ['auth', 'organizationId']) ?? '',
    getOrganizations,
    (orgId, orgs) => get(orgs, [orgId], undefined)
)

export const selectCurrentOrganizationGroup = createSelector(
    (state: RootReduxState) =>
        get(state, ['auth', 'organizationGroupId']) ?? '',
    getOrganizationGroups,
    (orgGroupId, orgGroups) => get(orgGroups, [orgGroupId])
)

// org groups
export const selectOrganizationGroup = createCachedSelector(
    getOrganizationGroups,
    (_state: RootReduxState, groupId: string | undefined) => groupId,
    (groups, groupId) => (groupId ? get(groups, [groupId]) : null)
)((_state, groupId) => groupId)

export const selectOrganizationGroups = createCachedSelector(
    getOrganizationGroups,
    (_state: RootReduxState, orgId: string) => orgId,
    (groups, orgId) =>
        values(groups).filter((group) => group.organization_id === orgId)
)((_state, orgId) => orgId)

// org members
export const selectOrganizationMembers = createCachedSelector(
    getUsers,
    selectOrganization,
    (users, org) =>
        org && org.members ? org.members.map((userId) => users[userId]) : []
)((_state, orgId) => orgId)

export const selectOrganizationGroupMembers = createCachedSelector(
    getUsers,
    selectOrganizationGroup,
    (users, group) =>
        group && group.members
            ? group.members.map((userId) => users[userId])
            : []
)((_state, groupId) => groupId)

// owner
export const selectOrganizationOwner = createCachedSelector(
    getUsers,
    selectOrganization,
    (users, org) => (org ? users[org.owner] : undefined)
)((_state, orgId) => orgId)

export const selectOrganizationPermittedGeos = createSelector(
    selectCurrentOrganization,
    (_state: RootReduxState, geos: readonly string[]) => geos,
    (org, geos) => {
        return org?.permit_all_geos
            ? geos
            : geos.filter(
                  (geo) =>
                      org?.permitted_geos.map((g) => g.id).includes(geo) ??
                      false
              )
    }
)
