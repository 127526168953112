import { memo, useEffect, useState } from 'react'

import Highcharts from 'highcharts'
import highchartsMore from 'highcharts/highcharts-more'
import annotations from 'highcharts/modules/annotations'
import HighchartsReact from 'highcharts-react-official'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import { useTranslation } from 'react-i18next'
import { ReactElement } from 'react-markdown/lib/react-markdown'
import { useSelector } from 'react-redux'

import { CreateCustomEventModal } from 'components/CreateResourceModal'
import { DEFAULT_SERIES, DEFAULT_TITLE } from 'configuration/charts'
import { DATES } from 'const/filters'
import { MONTH_DAY_FORMAT, MONTH_DAY_HOUR_FORMAT } from 'const/formatting'
import {
    REPORT_DATE_DAY_ID,
    REPORT_DATE_HOUR_ID,
    REPORT_DATE_WEEK_ID,
    SEARCH_TIME_HOUR_ID,
    SEARCH_TIME_WEEK_ID,
    SPLINE_AND_COLUMN,
} from 'const/widgets'
import { DEFAULT_TIME_SERIES_X_AXIS } from 'helpers/chart'
import {
    getDateRangeArray,
    isParallelPriorPeriod,
    presetRanges,
} from 'helpers/dateRange'
import { userHasDashboardsOnlyPermissions } from 'helpers/featurePermissions'
import { elementToString } from 'helpers/utilities'
import { transformToString } from 'helpers/widgets'
import {
    useCustomEventsForWidget,
    useModal,
    useUserHasPermissions,
} from 'hooks'
import { ReactComponent as CustomEventIcon } from 'images/annotation-icon-charts.svg'
import {
    selectAutomationCapabilitiesMap,
    selectBrandGroupsMap,
} from 'selectors/ui'
import {
    DataWidgetConfig,
    DateRangeFilter,
    FieldMapping,
    MetricType,
    PresetRange,
    PriorPeriodConfig,
    WidgetFilterMap,
} from 'types'
import moment from 'utilities/moment'

import { getChartType, legend, PointType, renderTooltip } from './helpers'
import { AxisDrivenSeries } from './helpers/axisDrivenSeries'
import { MetricsDrivenSeries } from './helpers/metricsDrivenSeries'

// Register the Highcharts annotations module
annotations(Highcharts)
highchartsMore(Highcharts)

interface TimeSeriesWidgetProps {
    widget: DataWidgetConfig
    widgetData: any[]
    widgetPriorPeriodData: any[]
    filters: WidgetFilterMap
    metricsConfig: FieldMapping
    chartHeight?: string | number
    showLegend?: boolean
    showYAxesTitles?: boolean
}

const TimeSeriesWidget = ({
    widget,
    widgetData,
    widgetPriorPeriodData,
    filters,
    metricsConfig,
    chartHeight,
    showLegend = true,
    showYAxesTitles = true,
}: TimeSeriesWidgetProps): ReactElement => {
    // We don't want to change the original widget
    const modifiedWidget = cloneDeep(widget)
    const { t } = useTranslation('dashboards')

    const { isModalVisible, toggleModalVisible, onModalCancel } = useModal()

    const [newEventDate, setNewEventDate] = useState<moment.Moment | null>(null)

    const dashboardOnlyExperience = useUserHasPermissions(
        userHasDashboardsOnlyPermissions
    )

    // Remove global function on unmount
    // Used for tooltip markdown onclick event
    useEffect(() => {
        return () => {
            if ((window as any).openAddNewEventModal) {
                ;(window as any).openAddNewEventModal = undefined
            }
        }
    }, [])

    const automationCapabilitiesMap = useSelector(
        selectAutomationCapabilitiesMap
    )

    const brandGroupsMap = useSelector(selectBrandGroupsMap)

    const widgetGroupBy = transformToString(widget.group_by)

    let isDataHourlyAggregated = [
        REPORT_DATE_HOUR_ID,
        SEARCH_TIME_HOUR_ID,
    ].includes(widgetGroupBy[0])
    const isDataWeeklyAggregated = [
        REPORT_DATE_WEEK_ID,
        SEARCH_TIME_WEEK_ID,
    ].includes(widgetGroupBy[0])

    let customDateFormat = MONTH_DAY_FORMAT
    if (isDataHourlyAggregated) {
        customDateFormat = MONTH_DAY_HOUR_FORMAT
    }

    if (isDataHourlyAggregated && widget.isZoomedOut) {
        isDataHourlyAggregated = false
        modifiedWidget.sorter = {
            ...modifiedWidget.sorter,
            field: REPORT_DATE_DAY_ID,
        }
        modifiedWidget.group_by = modifiedWidget.group_by.map((grouped, idx) =>
            idx === 0 ? { ...grouped, id: REPORT_DATE_DAY_ID } : grouped
        )
        modifiedWidget.order_by = REPORT_DATE_DAY_ID
    }

    const modifiedWidgetGroupBy = transformToString(modifiedWidget.group_by)
    const customEvents = useCustomEventsForWidget(filters)

    const priorPeriodConfig: PriorPeriodConfig = {
        diff: 1,
        unit: 'year',
        format: 'YYYY',
        hasData:
            !!modifiedWidget.include_change_metrics &&
            (widgetPriorPeriodData.length ?? 0) > 0,
    }

    // DOW-10016 backwards compatibility:
    //  If the widget has metrics_by_axis.total_metrics we can assume we are dealing with a new widget configuration that allows multiple y-axes at each side.
    //  Otherwise, we proceed with the older kind of configuration.
    const seriesHelper = modifiedWidget.metrics_by_axis?.total_metrics
        ? new AxisDrivenSeries(
              modifiedWidget,
              widgetData,
              widgetPriorPeriodData,
              priorPeriodConfig,
              automationCapabilitiesMap,
              brandGroupsMap,
              metricsConfig
          )
        : new MetricsDrivenSeries(
              modifiedWidget,
              widgetData,
              widgetPriorPeriodData,
              priorPeriodConfig,
              automationCapabilitiesMap,
              brandGroupsMap,
              metricsConfig
          )

    const dateRangeFilter = filters[DATES] as DateRangeFilter

    const dateRange = getDateRangeArray(
        dateRangeFilter,
        isDataHourlyAggregated,
        filters.rangeLag !== undefined ? Number(filters.rangeLag) : undefined
    )

    if (modifiedWidget.period_delta_type === 'prior_period') {
        const isParallel = isParallelPriorPeriod(dateRangeFilter)

        priorPeriodConfig.diff = isParallel
            ? 1
            : dateRange[1].diff(dateRange[0], 'days') + 1

        priorPeriodConfig.unit = isParallel
            ? presetRanges()[dateRangeFilter as PresetRange].priorPeriodUnit!
            : 'days'

        priorPeriodConfig.format = customDateFormat
    }

    const getMetricFormat = (point: PointType): string => {
        const { metric } = point.series.options as any
        return metricsConfig[metric]?.metricOptions?.format ?? ''
    }

    const getMetricType = (point: PointType): MetricType => {
        const { metric } = point.series.options as any
        return metricsConfig[metric]?.metricOptions?.type ?? 'count'
    }

    // Store function globally so tooltip can call it
    ;(window as any).openAddNewEventModal = (
        date: string | number | undefined
    ) => {
        setNewEventDate(moment(date))
        toggleModalVisible()
    }

    // Show custom events for day-aggregated charts
    const showCustomEvents = (): boolean =>
        modifiedWidget.show_annotations !== false &&
        modifiedWidgetGroupBy[0]?.includes('day')

    const series = seriesHelper.getSeries()
    const xAxis = {
        ...DEFAULT_TIME_SERIES_X_AXIS,
        min: dateRange[0].valueOf(),
        maxPadding: 0,
    }
    const yAxis = seriesHelper.getYAxes(showYAxesTitles)

    // As of highcharts v9, most attributes are blocked & need to be explicitly allowed
    Highcharts.AST.allowedAttributes.push(
        'data-reactroot',
        'onclick', // for tooltip add event btn
        'view-box', // for svg icon, highcharts issue#16229
        'xmlns' // for svg icon, highcharts issue#16229
    )

    const showPercentsInTooltips =
        modifiedWidget.stacked && modifiedWidget.stacked_type === 'percent'

    return (
        <>
            <div className="fg-chart-wrap line-chart-widget">
                <HighchartsReact
                    immutable
                    highcharts={Highcharts}
                    options={{
                        annotations: showCustomEvents()
                            ? [
                                  {
                                      labels: customEvents.map((event) => ({
                                          point: {
                                              x: moment(event.date).valueOf(),
                                              y: 0,
                                              xAxis: 0,
                                          },
                                          backgroundColor: 'transparent',
                                          borderColor: 'transparent',
                                          padding: 0,
                                          useHTML: true,
                                          formatter() {
                                              return elementToString(
                                                  <CustomEventIcon />
                                              )
                                          },
                                          y: -9,
                                      })),
                                      draggable: '',
                                  },
                              ]
                            : [],
                        chart: {
                            marginTop: 50,
                            height: chartHeight,
                            spacing: [0, 6, 10, 0],
                        },
                        legend: showLegend
                            ? seriesHelper.getLegendOptions(legend)
                            : { enabled: false },
                        plotOptions: {
                            areaspline: {
                                connectNulls: true,
                                lineWidth: 1,
                                marker: {
                                    enabled: false,
                                    lineColor: '#ffffff',
                                },
                            },
                            area: {
                                connectNulls: true,
                                lineWidth: 1,
                                marker: {
                                    enabled: false,
                                    lineColor: '#ffffff',
                                },
                            },
                            column: {
                                maxPointWidth: 60,
                            },
                            series: {
                                ...DEFAULT_SERIES,
                                stacking: modifiedWidget.stacked
                                    ? modifiedWidget.stacked_type ?? 'normal'
                                    : undefined,
                            },
                            spline: {
                                ...(getChartType(modifiedWidget) ===
                                SPLINE_AND_COLUMN
                                    ? {
                                          marker: {
                                              lineColor: null,
                                              lineWidth: null,
                                          },
                                      }
                                    : {}),
                            },
                            line: {
                                marker: {
                                    enabled: false,
                                },
                            },
                        },
                        series,
                        title: DEFAULT_TITLE,
                        tooltip: renderTooltip(
                            t,
                            getMetricFormat,
                            showCustomEvents() ? customEvents : [],
                            priorPeriodConfig,
                            !dashboardOnlyExperience && showCustomEvents(),
                            getMetricType,
                            customDateFormat,
                            isDataWeeklyAggregated,
                            showPercentsInTooltips
                        ),
                        xAxis,
                        yAxis,
                    }}
                />
            </div>
            <CreateCustomEventModal
                isModalVisible={isModalVisible}
                closeModal={onModalCancel}
                eventDate={newEventDate}
            />
        </>
    )
}

export default memo(TimeSeriesWidget, isEqual)
