import { SVGAttributes, ReactElement } from 'react'

type Props = SVGAttributes<SVGElement>

function SvgTimezoneIcon(props: Props): ReactElement {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M12.2999 12.2345L9.5534 10.4556L9.54892 10.4511C9.43353 10.3357 9.36308 10.1756 9.36308 10V5.19231C9.36308 4.84056 9.64825 4.55538 10 4.55538C10.3518 4.55538 10.6369 4.84056 10.6369 5.19231V9.72646L13.1867 11.329L13.1915 11.3338C13.4402 11.5825 13.4402 11.9812 13.1915 12.2299C12.9443 12.4771 12.5489 12.4786 12.2999 12.2345Z" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM16.72 10C16.72 13.7114 13.7114 16.72 10 16.72C6.28865 16.72 3.28 13.7114 3.28 10C3.28 6.28865 6.28865 3.28 10 3.28C13.7114 3.28 16.72 6.28865 16.72 10Z"
            />
        </svg>
    )
}

export default SvgTimezoneIcon
