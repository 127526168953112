import produce from 'immer'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import isString from 'lodash/isString'
import isUndefined from 'lodash/isUndefined'
import moment, { Moment } from 'moment-timezone'

import {
    Sorter,
    Field,
    ChildField,
    Pagination,
    CurrencyCode,
    PeriodDeltaType,
    DateRangeFilter,
} from 'types'

import {
    calculateFromDateWithLag,
    getPriorPeriodDateRange,
    presetRanges,
} from './dateRange'

const idNameMapping: { [id: string]: string } = {
    keyword__id: 'keyword__text',
    ad_group__id: 'ad_group__name',
    campaign__id: 'campaign__name',
    portfolio__id: 'portfolio__name',
    profile__id: 'profile__brand_name',
    advertiser__id: 'advertiser__name',
    order__id: 'order__name',
    line_item__id: 'line_item__name',
    campaign__label_id: 'campaign__label_name',
    keyword__label_id: 'keyword__label_name',
    porfolio__label_id: 'porfolio__label_name',
    product__label_id: 'product__label_name',
    profile__label_id: 'profile__label_name',
    order__label_id: 'order__label_name',
    line_item__label_id: 'line_item__label_name',
    // this is for sov management endpoint
    id: 'text',
}

const getMetricNameFromField = <T>(field: Field<T> | ChildField<T>): string => {
    if (isString(field.dataIndex)) {
        return field.dataIndex
    }
    if (isArray(field.dataIndex)) {
        return String(field.dataIndex.slice(-1))
    }
    throw new Error(
        `Unrecognized dataIndex format for field ${JSON.stringify(field)}`
    )
}

const getCerebroFormattedField = <T>(
    field: Field<T> | ChildField<T>
): string => {
    if (isString(field.dataIndex)) {
        return field.dataIndex
    }
    if (isArray(field.dataIndex)) {
        return field.dataIndex.join('__')
    }
    throw new Error('Unrecognized dataIndex format!')
}

type SorterParams = Sorter & { filterPositiveValues?: boolean }

interface SorterFormatted {
    [key: string]: string | number
}

interface MetricsFormatted {
    metrics?: string
}

interface ColumnsFormatted {
    columns?: string
}

interface PaginationFormatted {
    limit?: number
    offset?: number
}

interface CurrencyFormatted {
    currency?: CurrencyCode
}

export interface SignupData {
    name: string
    token?: string
    phone?: string
    email: string
    password: string
    terms_of_service: boolean
}

interface SignupDataFormatted {
    username: string
    token?: string
    password: string
    attributes: {
        email: string
        name: string
        'custom:terms_of_service': '1' | '0'
        phone_number?: string
    }
}

/**
 * Convert sorter into query params using Cerebro's ordering conventions
 */
export const formatSorter = (sorter?: SorterParams): SorterFormatted => {
    const { field, order, filterPositiveValues } = sorter ?? {}
    const params: SorterFormatted = {}

    if (!field || !order) {
        return params
    }

    let formattedField =
        typeof field === 'string' || typeof field === 'number'
            ? `${field}`.replace(/\./g, '__')
            : field.join('__')

    // replace xx__id with xx__name in "order by"
    if (formattedField in idNameMapping) {
        formattedField = idNameMapping[formattedField]
    }

    return produce(params, (draft) => {
        // set the ordering according to Cerebro conventions
        draft.ordering =
            order === 'descend' ? `-${formattedField}` : formattedField

        // Filter only positive values if `filterPositiveValues` is true
        if (filterPositiveValues) {
            draft[`${formattedField}__gt`] = 0
        }
    })
}

/**
 * Convert array of metric names into query params using Cerebro's
 * metrics parameter conventions
 */
export const formatStringMetrics = (metrics: string[]): MetricsFormatted =>
    isEmpty(metrics) ? {} : { metrics: metrics.join() }

/**
 * Convert metrics into query params using Cerebro's metrics parameter conventions
 */
export const formatMetrics = <T>(
    metrics: Field<T>[],
    showPeriodDeltas: boolean,
    showTotalRow?: boolean,
    fieldsToIgnore?: string[]
): MetricsFormatted => {
    if (isEmpty(metrics)) {
        return {}
    }

    return {
        metrics: metrics
            .reduce((accumulator: string[], metric: Field<T>): string[] => {
                const metricName = getMetricNameFromField(metric)
                accumulator.push(metricName)

                if (showTotalRow && metric.isTotalSupported) {
                    accumulator.push(`${metricName}__total`)

                    if (showPeriodDeltas) {
                        metric.childrenFields?.forEach((child) => {
                            const splitName = child.dataIndex
                                ?.slice(-1)[0]
                                .split('__')
                            if (!splitName) {
                                return
                            }

                            const [periodName, ...mainName] = [
                                splitName.pop(),
                                ...splitName,
                            ]
                            accumulator.push(
                                `${mainName.join('__')}__total__${periodName}`
                            )
                        })
                    }
                }

                if (showPeriodDeltas) {
                    metric.childrenFields?.forEach((child) =>
                        accumulator.push(getMetricNameFromField(child))
                    )
                }

                return accumulator
            }, [])
            .filter((metric) => !fieldsToIgnore?.includes(metric))
            .join(','),
    }
}

/**
 * Converts pagination into pagination query parameters according to Cerebro's conventions
 */
export const formatPagination = (
    pagination?: Pagination
): PaginationFormatted =>
    pagination?.pageSize && pagination?.current
        ? {
              limit: pagination.pageSize,
              offset: (pagination.current - 1) * pagination.pageSize,
          }
        : {}

/**
 * Formats currency codes into query parameters according to Cerebro's conventions
 */
export const formatCurrency = (currency?: CurrencyCode): CurrencyFormatted =>
    currency ? { currency } : {}

/**
 * Formats Fields into "columns" query parameters according to Cerebro's conventions
 */
export const formatColumns = <T>(
    columns: Field<T>[],
    showPeriodDeltas: boolean
): ColumnsFormatted => {
    if (isEmpty(columns)) {
        return {}
    }

    const columnNames = columns.reduce(
        (accumulator: string[], col: Field<T>) => {
            if (!isUndefined(col.dataIndex)) {
                accumulator.push(getCerebroFormattedField(col))
                if (showPeriodDeltas && !isEmpty(col.childrenFields)) {
                    col.childrenFields?.forEach((child) => {
                        if (child.metricOptions?.isDelta) {
                            accumulator.push(getCerebroFormattedField(child))
                        }
                    })
                }
            }
            return accumulator
        },
        []
    )

    // add resource name to columns if id is in the columns
    // it is OK even if we have duplicate column name/text in the request
    const extraColumnNames: string[] = []
    columnNames.forEach((columnName) => {
        if (columnName in idNameMapping) {
            extraColumnNames.push(idNameMapping[columnName])
        }
    })
    const finalColumnNames = extraColumnNames.concat(columnNames)

    return {
        columns: finalColumnNames.join(','),
    }
}

/**
 * Format sign up form values for Cognito API
 */
export const formatSignUpData = ({
    name,
    token,
    phone,
    email,
    password,
    terms_of_service,
}: SignupData): SignupDataFormatted => {
    let phoneFormatted
    if (phone) {
        const phoneParsed = parsePhoneNumberFromString(phone, 'US')
        if (phoneParsed && phoneParsed.isValid()) {
            phoneFormatted = phoneParsed.format('E.164')
        }
    }
    return {
        username: email, // using email as username, per Cognito settings
        token,
        password,
        attributes: {
            email,
            name,
            'custom:terms_of_service': terms_of_service
                ? ('1' as const)
                : ('0' as const),
            ...(phoneFormatted ? { phone_number: phoneFormatted } : {}),
        },
    }
}

function generatePreviousPeriodParams(
    datePrefix: string,
    min: string,
    max: string
): object {
    return {
        [`previous_period_${datePrefix}_min`]: min,
        [`previous_period_${datePrefix}_max`]: max,
    }
}

export function formatPeriodDeltaDateRange(
    showPeriodDeltas: boolean,
    periodDeltaType: PeriodDeltaType | undefined,
    periodDeltaDateRange: string[],
    dateRangeFilter: DateRangeFilter,
    datePrefix = 'report_date',
    rangeLag = 0
): object {
    if (showPeriodDeltas) {
        if (periodDeltaType === 'custom') {
            return generatePreviousPeriodParams(
                datePrefix,
                periodDeltaDateRange[0],
                periodDeltaDateRange[1]
            )
        }
        const fromDate = calculateFromDateWithLag(rangeLag)

        const range: Moment[] = isArray(dateRangeFilter)
            ? [moment(dateRangeFilter[0]), moment(dateRangeFilter[1])]
            : presetRanges(fromDate, rangeLag)[dateRangeFilter].range

        const priorRange = getPriorPeriodDateRange(
            range[0],
            range[1],
            periodDeltaType,
            dateRangeFilter
        )

        return generatePreviousPeriodParams(
            datePrefix,
            priorRange[0],
            priorRange[1]
        )
    }

    return {}
}
