import { memo, ReactElement } from 'react'

import { CloseOutlined } from '@ant-design/icons'
import isEqual from 'lodash/isEqual'
import { useTranslation } from 'react-i18next'

import { updateAutomationTask } from 'services/cerebroApi/orgScope/resourceApi'
import { AutomationTask } from 'types'

import { DeleteField } from '../SharedFields/DeleteField'

interface Props<RecordType> {
    readOnly: boolean
    record: RecordType
    reloadData: () => void
}

function AutomationDetachProfileField<RecordType extends AutomationTask>({
    readOnly = false,
    record,
    reloadData,
}: Props<RecordType>): ReactElement {
    const { t } = useTranslation(['common', 'automations'])
    const resourceId = record?.id

    return (
        <DeleteField
            disabled={readOnly}
            okText={t('common:remove', 'Remove')}
            title={t(
                'automations:automationProfile.campaignRemoval.warning',
                'Remove this campaign from the Automation Profile? This will disable the automation for this campaign.'
            )}
            icon={<CloseOutlined />}
            danger={false}
            updateRequestApi={updateAutomationTask}
            updateRequestCallback={reloadData}
            updateRequestData={resourceId}
            // always disable automation if profile is null
            updateRequestParams={{ automation_profile: null, enabled: false }}
            updateRequestSuccessMesg={t(
                'automations:automationProfile.campaignRemoval.successMessage',
                'Campaign removed.'
            )}
        />
    )
}

export default memo(
    AutomationDetachProfileField,
    isEqual
) as typeof AutomationDetachProfileField
