export const UNDEFINED_VALUE = '-'
export const OTHER = 'Others'
export const UNDEFINED_GROUP_BY_VALUE = 'N/A'

export const HOUR_FORMAT = 'MM/DD/YYYY HH:mm'
export const HOUR_TIMEZONE_FORMAT = 'h:mma z'
export const DAY_FORMAT = 'MM/DD/YYYY'
export const MONTH_FORMAT = 'MMM YYYY'
export const YEAR_FORMAT = 'YYYY'
export const MONTH_DAY_FORMAT = 'MMM DD'
export const MONTH_DAY_HOUR_FORMAT = 'MMM DD - ha'

export const UNDEFINED_BRAND = 'Unknown Brand'
