export const MANUAL = 'manual'
export const AUTO = 'auto'

type TargetTypeLabels = {
    [index: string]: string
}

export const TARGETING_TYPE_LABELS: TargetTypeLabels = {
    [MANUAL]: 'Manual',
    [AUTO]: 'Auto',
}

// Audience targeting types
const VIEWS = 'views'
const AUDIENCE = 'audience'
const PURCHASES = 'purchases'

export const AUDIENCE_TARGETING_TYPES = [VIEWS, AUDIENCE, PURCHASES]

export const ASIN_SAME_AS = 'asinSameAs'
