import { handleActions } from 'redux-actions'

import { enabledAutomationsTableColumnsConfig } from 'configuration/tables'
import {
    AUTOMATIONS,
    AUTOMATION_PROFILES,
    CAMPAIGN_NAME,
    REGIONS,
    COUNTRIES,
    BRANDS,
} from 'const/filters'
import { FILTER_SETTINGS, LOCAL_FILTERS } from 'const/reducerKeys'
import { serializeColumns } from 'helpers/tables'
import { defaultTab, getDefaultTable } from 'reducers/ui/defaults'

const initialState = {
    ...defaultTab,
    [LOCAL_FILTERS]: {
        [CAMPAIGN_NAME]: null,
        [AUTOMATIONS]: [],
        [AUTOMATION_PROFILES]: [],
        [REGIONS]: [],
        [COUNTRIES]: [],
        [BRANDS]: [],
    },
    [FILTER_SETTINGS]: {
        anchored: [CAMPAIGN_NAME],
        order: [AUTOMATIONS],
        displayState: {
            [AUTOMATIONS]: true,
            [AUTOMATION_PROFILES]: true,
            [REGIONS]: true,
            [COUNTRIES]: true,
            [BRANDS]: true,
        },
    },
    table: getDefaultTable(
        serializeColumns(enabledAutomationsTableColumnsConfig),
        {
            sorter: {
                field: ['start_date'],
                order: 'descend',
            },
        }
    ),
}

export default handleActions({}, initialState)
