export const ALL_FACT_TYPES = 'all'
export const SPONSORED_PRODUCT = 'sponsoredProducts'
export const HEADLINE_SEARCH = 'headlineSearch'
export const SPONSORED_DISPLAY = 'sponsoredDisplay'

export const FACT_TYPE_KEYS = [
    SPONSORED_PRODUCT,
    HEADLINE_SEARCH,
    SPONSORED_DISPLAY,
]

type FactTypeLabels = {
    [index: string]: string
}

export const FACT_TYPE_LABELS: FactTypeLabels = {
    [SPONSORED_PRODUCT]: 'Sponsored Products',
    [HEADLINE_SEARCH]: 'Sponsored Brands',
    [SPONSORED_DISPLAY]: 'Sponsored Display',
}
