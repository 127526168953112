import { ReactElement } from 'react'

import queryString from 'query-string'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { Link, useHistory } from 'react-router-dom'

import { useAppConfig } from 'appConfig'
import { AUTH_PAGE } from 'const/pages'
import { getPath } from 'helpers/pages'
import message from 'utilities/message'

import { CreateAccountForm } from './Forms'
import { useInvitationQuery } from './queries'
import styles from './styles.scss'

const InvitationSignUpPage = (): ReactElement | null => {
    const location = useLocation()
    const history = useHistory()
    const { t } = useTranslation('account')
    const search = queryString.parse(location.search)
    const { token }: { token?: string } = search

    const { productTitle } = useAppConfig()
    const { data: invitation, isFetching, isError } = useInvitationQuery(token)
    let redirectToLogIn = false

    if (isFetching) {
        return null
    }

    if (!invitation || isError) {
        redirectToLogIn = true
        message.error(
            <span>
                {t(
                    'account:invitation.error.notFound',
                    'Invalid invitation link'
                )}
            </span>
        )
    } else if (invitation?.state === 'accepted') {
        redirectToLogIn = true
        message.success(
            <span>
                {t(
                    'account:invitation.error.alreadyAccepted',
                    'Invitation already accepted, you can log in now.'
                )}
            </span>
        )
    } else if (invitation?.state !== 'pending') {
        return (
            <div>
                <div className={styles.title}>
                    <span className={styles.subtitle}>
                        {t(
                            'account:invitation.error.invalidState',
                            'The invitation is no longer valid. Please contact the organization administrator to request a new one.'
                        )}
                    </span>
                </div>
                <div className={styles.footer}>
                    <Trans i18nKey="account:CreateAccountForm.logInLink">
                        Already have a {{ productTitle }} account?{' '}
                        <Link to={getPath(AUTH_PAGE)}>
                            <span>Log In</span>
                        </Link>
                    </Trans>
                </div>
            </div>
        )
    }

    if (redirectToLogIn) {
        history.push(getPath(AUTH_PAGE))
        return null
    }

    return <CreateAccountForm invitation={invitation} token={token} />
}

export default InvitationSignUpPage
