import { memo, ReactElement, ReactNode } from 'react'

import { Select } from 'antd'
import { AxiosResponse } from 'axios'
import { Field } from 'formik'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import { object, string } from 'yup'

import { FormikSelect } from 'components/formik'
import { ARCHIVED, ENABLED, PAUSED } from 'const/resourceStates'
import { useCerebroApiRequest } from 'hooks'
import { CerebroResponse, ResourceStates } from 'types'
import message from 'utilities/message'

import { SingleValueField } from '../SingleValueField'

interface Props<RecordType> {
    disabled?: boolean
    fieldName?: string
    fieldPath: string[]
    disableSubmit?: boolean
    formatFieldValue: (values: {
        [fieldName: string]: ResourceStates
    }) => ReactElement | string
    options: {}
    placeholder: string
    readOnly: boolean
    record: RecordType
    label?: string
    serializeFieldValues?: (values: {
        [fieldName: string]: ResourceStates
    }) => object
    updateRequestApi: (...args: any) => Promise<AxiosResponse>
    updateRequestData: any
    updateRequestSuccessMesg: string | ReactElement
    doBeforeSave?: () => void
    extraContent?: ReactNode
}

function StateField<RecordType>({
    disabled = false,
    fieldName = 'state',
    fieldPath = [],
    disableSubmit = false,
    formatFieldValue = (values) => values[fieldName],
    options = {
        [PAUSED]: 'Paused',
        [ENABLED]: 'Enabled',
        [ARCHIVED]: 'Archived',
    },
    placeholder = 'Select a State',
    readOnly,
    record,
    label,
    serializeFieldValues = (values) => values,
    updateRequestApi,
    updateRequestData,
    updateRequestSuccessMesg,
    doBeforeSave,
    extraContent,
}: Props<RecordType>): ReactElement {
    const makeCerebroApiRequest = useCerebroApiRequest()
    const validationSchema = object().shape({
        [fieldName]: string().required(),
    })

    return (
        <SingleValueField
            disabled={disabled}
            disableSubmit={disableSubmit}
            fieldName={fieldName}
            formatFieldValue={formatFieldValue}
            initialValues={{
                [fieldName]: get(record, fieldPath) as ResourceStates,
            }}
            onSave={(values) => {
                doBeforeSave?.()
                return makeCerebroApiRequest<
                    RecordType,
                    CerebroResponse<RecordType>
                >({
                    request: updateRequestApi(
                        updateRequestData,
                        serializeFieldValues(values)
                    ),
                    onRequestSuccess: () => {
                        message.success(updateRequestSuccessMesg)
                    },
                })
            }}
            readOnly={readOnly}
            validationSchema={validationSchema}
            extraContent={extraContent}
        >
            {({ isSubmitting }) => (
                <>
                    {label && <span className="mr-2">{label}</span>}
                    <Field
                        component={FormikSelect}
                        placeholder={placeholder}
                        name={fieldName}
                        optionFilterProp="children"
                        disabled={isSubmitting}
                        style={{ width: '120px' }}
                        popupMatchSelectWidth={false}
                    >
                        {Object.entries(options).map(([value, fieldLabel]) => (
                            <Select.Option key={value} value={value}>
                                {fieldLabel}
                            </Select.Option>
                        ))}
                    </Field>
                </>
            )}
        </SingleValueField>
    )
}

export default memo(StateField, isEqual) as typeof StateField
