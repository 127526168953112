import { SovKeywordResearch } from 'types'

import {
    makeCountMetricFieldFunc,
    makeCurrencyMetricFieldFunc,
    makePercentageMetricFieldFunc,
    makeRankMetricFieldFunc,
} from './localUtils'

export const count = makeCountMetricFieldFunc({
    name: 'All Search Results Count',
    shortName: 'All Count',
    deltas: ['change', 'percentage_change'],
    id: 'count',
})

export const count__pct = makePercentageMetricFieldFunc({
    name: 'SOV % - Non Weighted',
    shortName: 'SOV %',
    deltas: ['change'],
    id: 'count__pct',
    isTotalSupported: false,
})

export const rank_weight__pct = makePercentageMetricFieldFunc({
    name: 'SOV % - Position Weighted',
    shortName: 'SOV % - PW',
    deltas: ['change'],
    id: 'rank_weight__pct',
    isTotalSupported: false,
})

export const fold_weight__pct = makePercentageMetricFieldFunc({
    name: 'SOV % - Fold/Area Weighted',
    shortName: 'SOV % - FAW',
    deltas: ['change'],
    id: 'fold_weight__pct',
    isTotalSupported: false,
})

export const rank_weight__sum = makeCountMetricFieldFunc({
    name: 'SOV - Position Weighted Raw',
    shortName: 'SOV - PW',
    deltas: ['change', 'percentage_change'],
    id: 'rank_weight__sum',
})

export const fold_weight__sum = makeCountMetricFieldFunc({
    name: 'SOV - Fold/Area Weighted Raw',
    shortName: 'SOV - FAW',
    deltas: ['change', 'percentage_change'],
    id: 'fold_weight__sum',
})

export const brand_count = makeCountMetricFieldFunc({
    name: 'Brand Search Results Count',
    shortName: 'Brand Count',
    deltas: ['change', 'percentage_change'],
    id: 'brand_count',
})

export const brand_count__pct = makePercentageMetricFieldFunc({
    name: 'Brand SOV % - Non Weighted',
    shortName: 'Brand SOV %',
    deltas: ['change'],
    id: 'brand_count__pct',
})

export const brand_rank_weight__pct = makePercentageMetricFieldFunc({
    name: 'Brand SOV % - Position Weighted',
    shortName: 'Brand SOV % - PW',
    deltas: ['change'],
    id: 'brand_rank_weight__pct',
})

export const brand_fold_weight__pct = makePercentageMetricFieldFunc({
    name: 'Brand SOV % - Fold/Area Weighted',
    shortName: 'Brand SOV % - FAW',
    deltas: ['change'],
    id: 'brand_fold_weight__pct',
})

export const brand_rank_weight__sum = makeCountMetricFieldFunc({
    name: 'Brand SOV - Position Weighted Raw',
    shortName: 'Brand SOV - PW',
    deltas: ['change', 'percentage_change'],
    id: 'brand_rank_weight__sum',
})

export const brand_fold_weight__sum = makeCountMetricFieldFunc({
    name: 'Brand SOV - Fold/Area Weighted Raw',
    shortName: 'Brand SOV - FAW',
    deltas: ['change', 'percentage_change'],
    id: 'brand_fold_weight__sum',
})

export const estimated_search_volume = makeCountMetricFieldFunc({
    name: 'Estimated Search Volume',
    shortName: 'Est. Searches',
    deltas: ['change', 'percentage_change'],
    id: 'estimated_search_volume',
})

export const result_count = makeCountMetricFieldFunc({
    name: 'Estimated Search Results',
    shortName: 'Est. Search Results',
    deltas: ['change', 'percentage_change'],
    id: 'result_count',
})

export const monthly_trend = makePercentageMetricFieldFunc<SovKeywordResearch>({
    name: '30-Day Trend',
    shortName: '30-Day Trend',
    deltas: ['change'],
    id: 'monthly_trend',
    type: 'percentage_as_is',
})

export const quarterly_trend =
    makePercentageMetricFieldFunc<SovKeywordResearch>({
        name: '90-Day Trend',
        shortName: '90-Day Trend',
        deltas: ['change'],
        id: 'quarterly_trend',
        type: 'percentage_as_is',
    })

export const rank__avg = makeRankMetricFieldFunc({
    name: 'Average Rank Overall',
    shortName: 'Avg Rank',
    deltas: ['change', 'percentage_change'],
    id: 'rank__avg',
    isInverse: true,
})

export const local_rank__avg = makeRankMetricFieldFunc({
    name: 'Average Rank for Result Type',
    shortName: 'Avg Rank - RT',
    deltas: ['change', 'percentage_change'],
    id: 'local_rank__avg',
    isInverse: true,
})

export const review_star__avg = makeCountMetricFieldFunc({
    name: 'Average Review Stars',
    shortName: 'Avg Review',
    format: '0.00',
    shortFormat: '0.00',
    deltas: ['change', 'percentage_change'],
    id: 'review_star__avg',
})

export const amazon_choice_count = makeCountMetricFieldFunc({
    name: 'Amazon Choice Count',
    shortName: 'Amazon Choices',
    shortFormat: '0,0',
    deltas: ['change', 'percentage_change'],
    id: 'amazon_choice_count',
})

export const amazon_choice__pct = makePercentageMetricFieldFunc({
    name: 'Amazon Choice %',
    shortName: 'Amazon Choice %',
    deltas: ['change'],
    id: 'amazon_choice__pct',
    isTotalSupported: false,
})

export const best_seller_count = makeCountMetricFieldFunc({
    name: 'Best Seller Count',
    shortName: 'Best Seller',
    shortFormat: '0,0',
    deltas: ['change', 'percentage_change'],
    id: 'best_seller_count',
})

export const best_seller__pct = makePercentageMetricFieldFunc({
    name: 'Best Seller %',
    shortName: 'Best Seller %',
    deltas: ['change'],
    isTotalSupported: false,
    id: 'best_seller__pct',
})

export const brand_amazon_choice_count = makeCountMetricFieldFunc({
    name: 'Brand Amazon Choice Count',
    shortName: 'Brand Amazon Choices',
    shortFormat: '0,0',
    deltas: ['change', 'percentage_change'],
    id: 'brand_amazon_choice_count',
})

export const brand_amazon_choice__pct = makePercentageMetricFieldFunc({
    name: 'Brand Amazon Choice %',
    shortName: 'Brand Amazon Choice %',
    deltas: ['change'],
    id: 'brand_amazon_choice__pct',
    isTotalSupported: false,
})

export const brand_best_seller_count = makeCountMetricFieldFunc({
    name: 'Brand Best Seller Count',
    shortName: 'Brand Best Seller',
    shortFormat: '0,0',
    deltas: ['change', 'percentage_change'],
    id: 'brand_best_seller_count',
})

export const brand_best_seller__pct = makePercentageMetricFieldFunc({
    name: 'Brand Best Seller %',
    shortName: 'Brand Best Seller %',
    deltas: ['change'],
    id: 'brand_best_seller__pct',
    isTotalSupported: false,
})

export const sponsored_products_count = makeCountMetricFieldFunc({
    name: 'Sponsored Products Result Count',
    shortName: 'Sponsored Products Count',
    shortFormat: '0,0',
    deltas: ['change', 'percentage_change'],
    id: 'sponsored_products_count',
})

export const sponsored_brands_count = makeCountMetricFieldFunc({
    name: 'Sponsored Brands Result Count',
    shortName: 'Sponsored Brands Count',
    shortFormat: '0,0',
    deltas: ['change', 'percentage_change'],
    id: 'sponsored_brands_count',
})

export const sponsored_count = makeCountMetricFieldFunc({
    name: 'Sponsored Result Count',
    shortName: 'Sponsored Count',
    shortFormat: '0,0',
    deltas: ['change', 'percentage_change'],
    id: 'sponsored_count',
})

export const brand_video_count = makeCountMetricFieldFunc({
    name: 'Brand Video Result Count',
    shortName: 'Brand Video Count',
    shortFormat: '0,0',
    deltas: ['change', 'percentage_change'],
    id: 'brand_video_count',
})

export const video_count = makeCountMetricFieldFunc({
    name: 'Video Result Count',
    shortName: 'Video Count',
    shortFormat: '0,0',
    deltas: ['change', 'percentage_change'],
    id: 'video_count',
})

export const organic_count = makeCountMetricFieldFunc({
    name: 'Organic Result Count',
    shortName: 'Organic Count',
    shortFormat: '0,0',
    deltas: ['change', 'percentage_change'],
    id: 'organic_count',
})

export const brand_sponsored_products_count = makeCountMetricFieldFunc({
    name: 'Brand Sponsored Products Result Count',
    shortName: 'Brand Sponsored Products Count',
    shortFormat: '0,0',
    deltas: ['change', 'percentage_change'],
    id: 'brand_sponsored_products_count',
})

export const brand_sponsored_brands_count = makeCountMetricFieldFunc({
    name: 'Brand Sponsored Brands Result Count',
    shortName: 'Brand Sponsored Brands Count',
    shortFormat: '0,0',
    deltas: ['change', 'percentage_change'],
    id: 'brand_sponsored_brands_count',
})

export const brand_sponsored_count = makeCountMetricFieldFunc({
    name: 'Brand Sponsored Result Count',
    shortName: 'Brand Sponsored Count',
    shortFormat: '0,0',
    deltas: ['change', 'percentage_change'],
    id: 'brand_sponsored_count',
})

export const brand_organic_count = makeCountMetricFieldFunc({
    name: 'Brand Organic Result Count',
    shortName: 'Brand Organic Count',
    shortFormat: '0,0',
    deltas: ['change', 'percentage_change'],
    id: 'brand_organic_count',
})

export const brand_sponsored_products__pct = makePercentageMetricFieldFunc({
    name: 'Brand Sponsored Products %',
    shortName: 'Brand Sponsored Products %',
    deltas: ['change'],
    id: 'brand_sponsored_products__pct',
})

export const brand_sponsored_brands__pct = makePercentageMetricFieldFunc({
    name: 'Brand Sponsored Brands %',
    shortName: 'Brand Sponsored Brands %',
    deltas: ['change'],
    id: 'brand_sponsored_brands__pct',
})

export const brand_sponsored__pct = makePercentageMetricFieldFunc({
    name: 'Brand Sponsored %',
    shortName: 'Brand Sponsored %',
    deltas: ['change'],
    id: 'brand_sponsored__pct',
})

export const brand_organic__pct = makePercentageMetricFieldFunc({
    name: 'Brand Organic %',
    shortName: 'Brand Organic %',
    deltas: ['change'],
    id: 'brand_organic__pct',
})

export const cost__sum = makeCurrencyMetricFieldFunc({
    name: 'Cost',
    shortName: 'Advertising Spend',
    isInverse: true,
    deltas: ['change', 'percentage_change'],
    id: 'cost__sum',
})

export const impressions__sum = makeCountMetricFieldFunc({
    name: 'Impressions',
    shortName: 'Advertising Impressions',
    shortFormat: '0,0',
    isInverse: true,
    deltas: ['change', 'percentage_change'],
    id: 'impressions__sum',
})

export const estimated_cost_of_all_sponsored = makeCurrencyMetricFieldFunc({
    name: 'Estimated Cost of All Sponsored',
    shortName: 'Estimated Cost of All Sponsored',
    isInverse: true,
    deltas: ['change', 'percentage_change'],
    id: 'estimated_cost_of_all_sponsored',
})
