import { Children, cloneElement, memo, ReactElement } from 'react'

import classNames from 'classnames'

import { BodySummaryCellWithMemo } from './BodyCell'
import styles from './styles.scss'

function RowWrapper(props: any): ReactElement {
    const {
        children,
        resizing,
        record,
        rowIndex,
        showTotalRow,
        className,
        ...otherProps
    } = props

    return (
        <>
            {showTotalRow && rowIndex === 0 ? (
                <tr
                    {...otherProps}
                    className={classNames(className, styles['total-row'])}
                >
                    {Children.map(children, (child) =>
                        cloneElement(
                            child,
                            {
                                component: BodySummaryCellWithMemo,
                            },
                            null
                        )
                    )}
                </tr>
            ) : null}
            <tr {...otherProps} className={className}>
                {children}
            </tr>
        </>
    )
}

function areEqual(_: any, nextProps: any): boolean {
    return nextProps.resizing
}

const BodyWrapperWithMemo = memo(RowWrapper, areEqual)
export default BodyWrapperWithMemo
