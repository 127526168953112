import { ReactElement, ReactNode } from 'react'

import DeliveryActivationStatusField from 'components/EditableFields/DspCommonFields/DeliveryActivationStatusField/DeliveryActivationStatusField'
import LineItemBidField from 'components/EditableFields/DspLineItemFields/LineItemBidField/LineItemBidField'
import LineItemBudgetField from 'components/EditableFields/DspLineItemFields/LineItemBudgetField/LineItemBudgetField'
import { RemoveFromLabelField as RemoveLineItemFromLabelField } from 'components/EditableFields/DspLineItemFields/RemoveFromLabelField/RemoveFromLabelField'
import { RemoveFromLabelField as RemoveOrderFromLabelField } from 'components/EditableFields/DspOrderFields/RemoveFromLabelField/RemoveFromLabelField'
import { Labels } from 'components/Labels'
import { ResourceLink } from 'components/Links/ResourceLink'
import { UNDEFINED_VALUE } from 'const/formatting'
import { formatCurrency, titleCase } from 'helpers/formatting'
import { advertiserUrl, lineItemUrl, orderUrl } from 'helpers/urls'
import {
    CellRenderProps,
    DspOrderGroupBy,
    DspLineItemGroupBy,
    DspOrderLabelGroupBy,
    DspLineItemLabelGroupBy,
    DspAdvertiser,
    DspOrder,
} from 'types'

type RenderPropsWithDataIndex<RecordType> = {
    cellRenderProps: CellRenderProps<RecordType>
    dataIndex: string[]
}

export function advertiserIdRenderer<
    RecordType extends { advertiser: DspAdvertiser },
>({
    record,
    value,
    undefinedValue = UNDEFINED_VALUE,
}: CellRenderProps<RecordType>): ReactNode {
    return value ? (
        <ResourceLink to={advertiserUrl(value)}>
            {record.advertiser.name}
        </ResourceLink>
    ) : (
        undefinedValue
    )
}

export function orderIdRenderer<RecordType extends { order: DspOrder }>({
    record,
    value,
    undefinedValue = UNDEFINED_VALUE,
}: CellRenderProps<RecordType>): ReactNode {
    return value ? (
        <ResourceLink to={orderUrl(value)}>{record.order.name}</ResourceLink>
    ) : (
        undefinedValue
    )
}

export function orderBudgetRenderer<RecordType extends { order: DspOrder }>({
    record,
    value,
    undefinedValue = UNDEFINED_VALUE,
}: CellRenderProps<RecordType>): ReactNode {
    return value ? (
        <span>
            {formatCurrency(value, {
                decimal: true,
                currencyCode: record.order.currency_code,
            })}
        </span>
    ) : (
        undefinedValue
    )
}

export function orderLabelIdRenderer<RecordType extends DspOrderLabelGroupBy>({
    value,
    record,
    undefinedValue = UNDEFINED_VALUE,
}: CellRenderProps<RecordType>): ReactNode {
    return value ? <Labels labels={[record.order__label]} /> : undefinedValue
}

export function lineItemIdRenderer<RecordType extends DspLineItemGroupBy>({
    record,
    value,
    undefinedValue = UNDEFINED_VALUE,
}: CellRenderProps<RecordType>): ReactNode {
    return value ? (
        <ResourceLink to={lineItemUrl(value)}>
            {record.line_item.name}
        </ResourceLink>
    ) : (
        undefinedValue
    )
}

export function lineItemTypeRenderer<RecordType extends DspLineItemGroupBy>({
    record,
    value,
    undefinedValue = UNDEFINED_VALUE,
}: CellRenderProps<RecordType>): ReactNode {
    return value ? (
        <span>{titleCase(record.line_item.line_item_type)}</span>
    ) : (
        undefinedValue
    )
}

export function lineItemBudgetRenderer<RecordType extends DspLineItemGroupBy>({
    cellRenderProps: { readonly, record },
    dataIndex,
}: RenderPropsWithDataIndex<RecordType>): ReactNode {
    return (
        <LineItemBudgetField
            dataIndex={dataIndex}
            readOnly={readonly}
            record={record}
        />
    )
}

export function lineItemBidRenderer<RecordType extends DspLineItemGroupBy>({
    cellRenderProps: { readonly, record },
    dataIndex,
}: RenderPropsWithDataIndex<RecordType>): ReactNode {
    return (
        <LineItemBidField
            dataIndex={dataIndex}
            readOnly={readonly}
            record={record}
        />
    )
}

export function lineItemLabelIdRenderer<
    RecordType extends DspLineItemLabelGroupBy,
>({
    value,
    record,
    undefinedValue = UNDEFINED_VALUE,
}: CellRenderProps<RecordType>): ReactNode {
    return value ? (
        <Labels labels={[record.line_item__label]} />
    ) : (
        undefinedValue
    )
}

export function removeLineItemFromLabelRenderer<
    RecordType extends DspLineItemGroupBy,
>({ record, readonly, reloadData }: CellRenderProps<RecordType>): ReactElement {
    return (
        <RemoveLineItemFromLabelField
            record={record}
            readOnly={readonly}
            reloadData={reloadData}
        />
    )
}

export function removeOrderFromLabelRenderer<
    RecordType extends DspOrderGroupBy,
>({ record, readonly, reloadData }: CellRenderProps<RecordType>): ReactElement {
    return (
        <RemoveOrderFromLabelField
            record={record}
            readOnly={readonly}
            reloadData={reloadData}
        />
    )
}

interface DeliveryActivationStatusRendererProps<RecordType> {
    cellRenderProps: CellRenderProps<RecordType>
    dataIndex: string[]
    resourceName: 'line_item' | 'order'
}

export function deliveryActivationStatusRenderer<
    RecordType extends DspOrderGroupBy | DspLineItemGroupBy,
>({
    cellRenderProps: { readonly, record },
    dataIndex,
    resourceName,
}: DeliveryActivationStatusRendererProps<RecordType>): ReactNode {
    return (
        <DeliveryActivationStatusField
            dataIndex={dataIndex}
            readOnly={readonly}
            record={record}
            resourceName={resourceName}
        />
    )
}
