import { handleActions } from 'redux-actions'

import { walmartCampaignKeywordsTabTableColumnsConfig } from 'configuration/tables'
import {
    DATES,
    KEYWORD_TEXT,
    KEYWORD_MATCH_TYPES,
    KEYWORD_STATES,
    AD_GROUPS,
} from 'const/filters'
import { LOCAL_FILTERS, FILTER_SETTINGS } from 'const/reducerKeys'
import { serializeColumns } from 'helpers/tables'
import { defaultTab, getDefaultTable } from 'reducers/ui/defaults'

const defaultState = {
    ...defaultTab,
    [LOCAL_FILTERS]: {
        [KEYWORD_TEXT]: null,
        [KEYWORD_MATCH_TYPES]: [],
        [KEYWORD_STATES]: [],
        [AD_GROUPS]: [],
    },
    [FILTER_SETTINGS]: {
        anchored: [KEYWORD_TEXT, DATES],
        order: [KEYWORD_STATES, KEYWORD_MATCH_TYPES, AD_GROUPS],
        displayState: {
            [KEYWORD_MATCH_TYPES]: true,
            [KEYWORD_STATES]: true,
            [AD_GROUPS]: true,
        },
    },
    table: getDefaultTable(
        serializeColumns(walmartCampaignKeywordsTabTableColumnsConfig),
        { showTotalRow: true }
    ),
}

export default handleActions({}, defaultState)
