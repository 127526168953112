import { addCache } from 'helpers/utilities'
import {
    CerebroApiOptions,
    BannerRequestBody,
    CerebroResourceResponse,
    CerebroPaginatedResponse,
    Report,
} from 'types'

import { axiosInstanceOrganizations } from './client'
import { getCerebroHeaders } from './headers'

/**
 * Gets relevant facts for VC Data, grouped and filtered
 * by specified parameters
 */
export const getVcManufacturerFactAggregates = (
    params: BannerRequestBody,
    options: CerebroApiOptions = {}
): Promise<CerebroPaginatedResponse<any>> =>
    axiosInstanceOrganizations.post(
        '/api/vendor_central_manufacturer_sales_diagnostic_facts/aggregate/banner/',
        params,
        {
            headers: getCerebroHeaders(options.headers),
        }
    )
addCache(getVcManufacturerFactAggregates)

/**
 * Get a CSV export for VC Data, grouped and filtered
 * by specified parameters
 */
export const getVcManufacturerFactAggregatesExport = (
    params: BannerRequestBody
): Promise<CerebroResourceResponse<Report>> =>
    axiosInstanceOrganizations.post(
        '/api/vendor_central_manufacturer_sales_diagnostic_facts/aggregate/banner/export/',
        params
    )

/**
 * Gets relevant facts for VC Sourcing Data, grouped and filtered
 * by specified parameters
 */
export const getVcSourceFactAggregates = (
    params: BannerRequestBody,
    options: CerebroApiOptions = {}
): Promise<CerebroPaginatedResponse<any>> =>
    axiosInstanceOrganizations.post(
        '/api/vendor_central_source_sales_diagnostic_facts/aggregate/banner/',
        params,
        {
            headers: getCerebroHeaders(options.headers),
        }
    )
addCache(getVcSourceFactAggregates)

/**
 * Get a CSV export for VC Sourcing Data, grouped and filtered
 * by specified parameters
 */
export const getVcSourceFactAggregatesExport = (
    params: BannerRequestBody
): Promise<CerebroResourceResponse<Report>> =>
    axiosInstanceOrganizations.post(
        '/api/vendor_central_source_sales_diagnostic_facts/aggregate/banner/export/',
        params
    )

/**
 * Gets relevant facts for VC Traffic Diagnostic, grouped and filtered
 * by specified parameters
 */
export const getVcTrafficDiagnosticFactAggregates = (
    params: BannerRequestBody,
    options: CerebroApiOptions = {}
): Promise<CerebroPaginatedResponse<any>> =>
    axiosInstanceOrganizations.post(
        '/api/vendor_central_traffic_diagnostic_facts/aggregate/banner/',
        params,
        {
            headers: getCerebroHeaders(options.headers),
        }
    )
addCache(getVcTrafficDiagnosticFactAggregates)

/**
 * Get a CSV export for VC Traffic Diagnostic Data, grouped and filtered
 * by specified parameters
 */
export const getVcTrafficDiagnosticFactAggregatesExport = (
    params: BannerRequestBody
): Promise<CerebroResourceResponse<Report>> =>
    axiosInstanceOrganizations.post(
        '/api/vendor_central_traffic_diagnostic_facts/aggregate/banner/export/',
        params
    )

/**
 * Gets relevant facts for VC Traffic Diagnostic, grouped and filtered
 * by specified parameters
 */
export const getVcInventoryHealthFactAggregates = (
    params: BannerRequestBody,
    options: CerebroApiOptions = {}
): Promise<CerebroPaginatedResponse<any>> =>
    axiosInstanceOrganizations.post(
        '/api/vendor_central_inventory_health_facts/aggregate/banner/',
        params,
        {
            headers: getCerebroHeaders(options.headers),
        }
    )
addCache(getVcInventoryHealthFactAggregates)

/**
 * Get a CSV export for VC Traffic Diagnostic Data, grouped and filtered
 * by specified parameters
 */
export const getVcInventoryHealthFactAggregatesExport = (
    params: BannerRequestBody
): Promise<CerebroResourceResponse<Report>> =>
    axiosInstanceOrganizations.post(
        '/api/vendor_central_inventory_health_facts/aggregate/banner/export/',
        params
    )

/**
 * Gets relevant facts for VC API Sales Diagnostic Data, grouped and filtered
 * by specified parameters
 */
export const getVcApiSalesDiagnosticManufacturerMetrics = (
    params: BannerRequestBody,
    options: CerebroApiOptions = {}
): Promise<CerebroPaginatedResponse<any>> =>
    axiosInstanceOrganizations.post(
        '/api/vendor_central_selling_partner_api_sales_diagnostic_facts/aggregate/banner/',
        params,
        {
            headers: getCerebroHeaders(options.headers),
        }
    )
addCache(getVcApiSalesDiagnosticManufacturerMetrics)

/**
 * Get a CSV export for VC API Sales Diagnostic Data, grouped and filtered
 * by specified parameters
 */
export const getVcApiSalesDiagnosticManufacturerMetricsExport = (
    params: BannerRequestBody
): Promise<CerebroResourceResponse<Report>> =>
    axiosInstanceOrganizations.post(
        '/api/vendor_central_selling_partner_api_sales_diagnostic_facts/aggregate/banner/export/',
        params
    )

/**
 * Gets relevant facts for VC API Inventory Manufacturing Data, grouped and filtered
 * by specified parameters
 */
export const getVcApiInventoryManufacturingMetrics = (
    params: BannerRequestBody,
    options: CerebroApiOptions = {}
): Promise<CerebroPaginatedResponse<any>> =>
    axiosInstanceOrganizations.post(
        '/api/vendor_central_selling_partner_api_inventory_manufacturing_facts/aggregate/banner/',
        params,
        {
            headers: getCerebroHeaders(options.headers),
        }
    )
addCache(getVcApiInventoryManufacturingMetrics)

/**
 * Get a CSV export for VC API Inventory Manufacturing Data, grouped and filtered
 * by specified parameters
 */
export const getVcApiInventoryManufacturingMetricsExport = (
    params: BannerRequestBody
): Promise<CerebroResourceResponse<Report>> =>
    axiosInstanceOrganizations.post(
        '/api/vendor_central_selling_partner_api_inventory_manufacturing_facts/aggregate/banner/export/',
        params
    )

/**
 * Gets relevant facts for VC API Inventory Sourcing Data, grouped and filtered
 * by specified parameters
 */
export const getVcApiInventorySourcingMetrics = (
    params: BannerRequestBody,
    options: CerebroApiOptions = {}
): Promise<CerebroPaginatedResponse<any>> =>
    axiosInstanceOrganizations.post(
        '/api/vendor_central_selling_partner_api_inventory_sourcing_facts/aggregate/banner/',
        params,
        {
            headers: getCerebroHeaders(options.headers),
        }
    )
addCache(getVcApiInventoryManufacturingMetrics)

/**
 * Get a CSV export for VC API Inventory Sourcing Data, grouped and filtered
 * by specified parameters
 */
export const getVcApiInventorySourcingMetricsExport = (
    params: BannerRequestBody
): Promise<CerebroResourceResponse<Report>> =>
    axiosInstanceOrganizations.post(
        '/api/vendor_central_selling_partner_api_inventory_sourcing_facts/aggregate/banner/export/',
        params
    )

/**
 * Gets relevant facts for VC API Sales Diagnostic Data, grouped and filtered
 * by specified parameters
 */
export const getVcApiSalesDiagnosticSourcingMetrics = (
    params: BannerRequestBody,
    options: CerebroApiOptions = {}
): Promise<CerebroPaginatedResponse<any>> =>
    axiosInstanceOrganizations.post(
        '/api/vendor_central_selling_partner_api_sales_diagnostic_sourcing_facts/aggregate/banner/',
        params,
        {
            headers: getCerebroHeaders(options.headers),
        }
    )
addCache(getVcApiSalesDiagnosticSourcingMetrics)

/**
 * Get a CSV export for VC API Sales Diagnostic Data, grouped and filtered
 * by specified parameters
 */
export const getVcApiSalesDiagnosticSourcingMetricsExport = (
    params: BannerRequestBody
): Promise<CerebroResourceResponse<Report>> =>
    axiosInstanceOrganizations.post(
        '/api/vendor_central_selling_partner_api_sales_diagnostic_sourcing_facts/aggregate/banner/export/',
        params
    )

/**
 * Gets relevant facts for VC API Inventory Health Data, grouped and filtered
 * by specified parameters
 */
export const getVcApiInventoryHealthFacts = (
    params: BannerRequestBody,
    options: CerebroApiOptions = {}
): Promise<CerebroPaginatedResponse<any>> =>
    axiosInstanceOrganizations.post(
        '/api/vendor_central_selling_partner_api_inventory_health_facts/aggregate/banner/',
        params,
        {
            headers: getCerebroHeaders(options.headers),
        }
    )
addCache(getVcApiInventoryHealthFacts)

/**
 * Get a CSV export for VC API Inventory Health Data, grouped and filtered
 * by specified parameters
 */
export const getVcApiInventoryHealthFactsExport = (
    params: BannerRequestBody
): Promise<CerebroResourceResponse<Report>> =>
    axiosInstanceOrganizations.post(
        '/api/vendor_central_selling_partner_api_inventory_health_facts/aggregate/banner/export/',
        params
    )
