import {
    deleteLabel,
    labelColor,
    labelCreatedDate,
    labelDescription,
    labelName,
} from 'configuration/fieldCreators/labels'
import { Label, Field } from 'types'

export const labelsSummaryTableColumnsConfig: Field<Label>[] = [
    labelName({ fixed: true }),
    labelDescription(),
    labelColor(),
    labelCreatedDate(),
    deleteLabel(),
]
