export function mapTargetExpressionTypeToBidRecommendationExpressionType(
    expressionType: string
): string | undefined {
    switch (expressionType) {
        // auto targets
        case 'queryHighRelMatches':
            return 'CLOSE_MATCH'
        case 'queryBroadRelMatches':
            return 'LOOSE_MATCH'
        case 'asinSubstituteRelated':
            return 'SUBSTITUTES'
        case 'asinAccessoryRelated':
            return 'COMPLEMENTS'
        // manual targets
        case 'asinSameAs':
            return 'PAT_ASIN'
        case 'asinCategorySameAs':
            return 'PAT_CATEGORY'
        case 'keywordGroupSameAs':
            return 'KEYWORD_GROUP'

        default:
            return undefined
    }
}

export function convertProductAudienceExpressionToBidRecommendationExpression(
    mappedValues: Record<string, string>
): string {
    const vals = []
    const category = mappedValues.asinCategorySameAs
    if (category) {
        vals.push(`category="${category}"`)
    }

    // Age
    if (mappedValues.asinAgeRangeSameAs) {
        vals.push(`age-range="${mappedValues.asinAgeRangeSameAs}"`)
    }

    // Brand
    if (mappedValues.asinBrandSameAs) {
        vals.push(`brand="${mappedValues.asinBrandSameAs}"`)
    }

    // Genre
    if (mappedValues.asinGenreSameAs) {
        vals.push(`genre="${mappedValues.asinGenreSameAs}"`)
    }

    // Reviews
    if (mappedValues.asinReviewRatingBetween) {
        vals.push(`review-rating="${mappedValues.asinReviewRatingBetween}"`)
    }
    if (
        mappedValues.asinReviewRatingLessThan !== undefined &&
        mappedValues.asinReviewRatingGreaterThan !== undefined
    ) {
        vals.push(
            `review-rating="${mappedValues.asinReviewRatingGreaterThan}"-${mappedValues.asinReviewRatingLessThan}"`
        )
    } else if (mappedValues.asinReviewRatingLessThan !== undefined) {
        vals.push(
            `review-rating-less-than="${mappedValues.asinReviewRatingLessThan}"`
        )
    } else if (mappedValues.asinReviewRatingGreaterThan !== undefined) {
        vals.push(
            `review-rating-greater-than="${mappedValues.asinReviewRatingGreaterThan}"`
        )
    }

    // Price
    if (mappedValues.asinPriceBetween) {
        vals.push(`price="${mappedValues.asinPriceBetween}"`)
    }
    if (
        mappedValues.asinPriceLessThan !== undefined &&
        mappedValues.asinPriceGreaterThan !== undefined
    ) {
        vals.push(
            `price="${mappedValues.asinPriceGreaterThan}"-${mappedValues.asinPriceLessThan}"`
        )
    } else if (mappedValues.asinPriceLessThan !== undefined) {
        vals.push(`price-less-than="${mappedValues.asinPriceLessThan}"`)
    } else if (mappedValues.asinPriceGreaterThan !== undefined) {
        vals.push(`price-greater-than="${mappedValues.asinPriceGreaterThan}"`)
    }

    // Shipping
    if (mappedValues.asinIsPrimeShippingEligible !== undefined) {
        vals.push(
            `prime-shipping-eligible="${mappedValues.asinIsPrimeShippingEligible}"`
        )
    }

    return vals.join(' ')
}

export function mapBidRecommendationExpressionTypeToTargetExpressionType(
    recommendationType: string
): string | undefined {
    switch (recommendationType) {
        // auto targets
        case 'CLOSE_MATCH':
            return 'queryHighRelMatches'
        case 'LOOSE_MATCH':
            return 'queryBroadRelMatches'
        case 'SUBSTITUTES':
            return 'asinSubstituteRelated'
        case 'COMPLEMENTS':
            return 'asinAccessoryRelated'
        // manual targets
        case 'PAT_ASIN':
            return 'asinSameAs'
        case 'PAT_CATEGORY':
            return 'asinCategorySameAs'
        default:
            return undefined
    }
}

export function mapTargetExpressionsToBidRecommendationExpression(
    targetExpressions: { type: string; value: string }[]
): { type: string | undefined; value: string } {
    if (targetExpressions.length === 1) {
        const first_expression = targetExpressions[0]
        const translatedExpressionType =
            mapTargetExpressionTypeToBidRecommendationExpressionType(
                first_expression.type
            )

        return {
            type: translatedExpressionType,
            value: first_expression.value,
        }
    }
    const mapped_values: Record<string, string> = {}
    targetExpressions.forEach((expression) => {
        mapped_values[expression.type] = expression.value
    })

    return {
        type: 'PAT_CATEGORY_REFINEMENT',
        value: convertProductAudienceExpressionToBidRecommendationExpression(
            mapped_values
        ),
    }
}
