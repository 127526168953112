import { ReactElement } from 'react'

import { Button } from 'antd'
import { useHistory } from 'react-router'

import { useCerebroApiRequest } from 'hooks'
import { updateTriggeredAlert } from 'services/cerebroApi/orgScope/alertsApi'
import { TriggeredAlert } from 'types'

interface Props {
    triggeredAlert: TriggeredAlert
}

function AlertLink({ triggeredAlert }: Props): ReactElement {
    const history = useHistory()
    const makeCerebroApiRequest = useCerebroApiRequest()
    const handleDismissTriggeredAlert = async (
        triggeredAlertId: string,
        alertConfigurationId: string
    ): Promise<void> => {
        await makeCerebroApiRequest({
            request: updateTriggeredAlert(triggeredAlertId, {
                status: 'read',
            }),
            onRequestSuccess: () => {
                history.push(`/alerts/${alertConfigurationId}`)
            },
        })
    }

    return (
        <Button
            type="link"
            onClick={() => {
                handleDismissTriggeredAlert(
                    triggeredAlert.id,
                    triggeredAlert.alert_configuration_id
                )
            }}
            className="px-0"
        >
            {triggeredAlert.alert_configuration.name}
        </Button>
    )
}

export default AlertLink
