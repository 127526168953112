import {
    organizationInvitationCreatedDate,
    organizationInvitationEmail,
    organizationInvitationExpirationDate,
    organizationInvitationInviter,
    organizationInvitationMemberType,
    organizationInvitationStatus,
} from 'configuration/fieldCreators/organizationInvitation'
import { Field, OrganizationInvitation } from 'types'

export const organizationInvitationTableColumnsConfig: Field<OrganizationInvitation>[] =
    [
        organizationInvitationEmail({
            fixed: true,
        }),
        organizationInvitationMemberType(),
        organizationInvitationStatus(),
        organizationInvitationCreatedDate(),
        organizationInvitationExpirationDate(),
        organizationInvitationInviter(),
    ]
