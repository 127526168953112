import { all, takeLatest, takeEvery } from 'redux-saga/effects'

import {
    saveDashboardRequest,
    cancelDashboardChanges,
    exportToPdfRequest,
    createScheduledExportRequest,
    updateScheduledExportRequest,
    deleteScheduledExportRequest,
    shareDashboardRequest,
    cloneWidget,
} from 'actions/ui/dashboardPage'

import {
    saveDashboardWorker,
    cancelDashboardChangesWorker,
    exportToPdfWorker,
    createScheduledExportWorker,
    updateScheduledExportWorker,
    deleteScheduledExportWorker,
    shareDashboardWorker,
    cloneWidgetWorker,
} from './workers'

export default function* dashboardPageWatcher() {
    yield all([
        takeLatest(saveDashboardRequest.toString(), saveDashboardWorker),
        takeLatest(
            cancelDashboardChanges.toString(),
            cancelDashboardChangesWorker
        ),
        takeEvery(cloneWidget.toString(), cloneWidgetWorker),
        takeEvery(exportToPdfRequest.toString(), exportToPdfWorker),
        takeLatest(
            createScheduledExportRequest.toString(),
            createScheduledExportWorker
        ),
        takeLatest(
            updateScheduledExportRequest.toString(),
            updateScheduledExportWorker
        ),
        takeLatest(
            deleteScheduledExportRequest.toString(),
            deleteScheduledExportWorker
        ),
        takeLatest(shareDashboardRequest.toString(), shareDashboardWorker),
    ])
}
