import { ReactElement } from 'react'

import Icon from '@ant-design/icons'

import RelationshipIconSvg from './RelationshipIconSvg'
import { IconProps } from '../localTypes'

const RelationshipIcon = (props: IconProps): ReactElement => (
    <Icon component={RelationshipIconSvg} {...props} />
)

export default RelationshipIcon
