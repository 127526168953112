import { ReactElement, ReactNode } from 'react'

import { Card } from 'antd'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    children: ReactNode
}

const BasicContainer = ({ className, children }: Props): ReactElement => (
    <Card
        bodyStyle={{ padding: 0 }}
        bordered={false}
        size="small"
        className={className}
    >
        {children}
    </Card>
)

export default BasicContainer
