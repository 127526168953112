import { call, put, select } from 'redux-saga/effects'

import {
    makeDownloadTableSuccess,
    makeFetchTableRequest,
    makeFetchTableSuccess,
} from 'actions/ui/shared'
import { campaignsSummaryTableColumnsConfig } from 'configuration/tables'
import { DATES } from 'const/filters'
import { CAMPAIGNS_SUMMARY_PAGE } from 'const/pages'
import { formatFilters } from 'helpers/filters/campaignFacts'
import { generateReportNotification } from 'helpers/notifications'
import {
    formatColumns,
    formatCurrency,
    formatMetrics,
    formatPagination,
    formatPeriodDeltaDateRange,
    formatSorter,
} from 'helpers/params'
import { cerebroApiSaga } from 'sagas/common'
import uiSagaRegistry from 'sagas/ui/registry'
import {
    selectCurrencyCode,
    selectTableSettings,
    selectVisibleColumnsOfTable,
    selectVisibleCombinedFilters,
    selectVisibleMetricsOfTable,
} from 'selectors/ui'
import {
    getCampaignFacts,
    getCampaignFactsExport,
} from 'services/cerebroApi/orgScope/campaignFactsApi'

const TABLE_PATH = [CAMPAIGNS_SUMMARY_PAGE, 'table']

function* fetchTableSaga(noCount) {
    const currency = yield select(selectCurrencyCode)
    const filters = yield select(selectVisibleCombinedFilters, [
        CAMPAIGNS_SUMMARY_PAGE,
    ])

    const {
        columns,
        pagination,
        sorter,
        showPeriodDeltas,
        periodDeltaType,
        periodDeltaDateRange,
        showTotalRow,
    } = yield select(selectTableSettings, TABLE_PATH)

    const metrics = yield select(
        selectVisibleMetricsOfTable,
        TABLE_PATH,
        campaignsSummaryTableColumnsConfig
    )

    // Stream fields aren't automatically returned by Banner
    // If the Budget Status column is visible, we manually query for them
    let group_by_stream_fields = ''
    if (
        columns.find(
            (column) =>
                column.isVisible && column.id === 'campaign__budget_status'
        )
    ) {
        group_by_stream_fields =
            ',budget_percentage,budget_updated_at,historical_budget'
    }

    const params = {
        ...formatPagination(pagination),
        ...formatSorter(sorter),
        ...formatFilters(filters),
        ...formatMetrics(metrics, showPeriodDeltas, showTotalRow),
        ...formatCurrency(currency),
        ...formatPeriodDeltaDateRange(
            showPeriodDeltas,
            periodDeltaType,
            periodDeltaDateRange,
            filters[DATES]
        ),
        group_by: `campaign_id,profile_id,portfolio_id${group_by_stream_fields}`,
    }

    yield call(
        cerebroApiSaga,
        makeFetchTableSuccess(TABLE_PATH),
        getCampaignFacts,
        params,
        {
            headers: { noCount },
        }
    )
}

function* downloadTableSaga() {
    const filters = yield select(selectVisibleCombinedFilters, [
        CAMPAIGNS_SUMMARY_PAGE,
    ])
    const { sorter, showPeriodDeltas, periodDeltaType, periodDeltaDateRange } =
        yield select(selectTableSettings, TABLE_PATH)
    const columns = yield select(
        selectVisibleColumnsOfTable,
        TABLE_PATH,
        campaignsSummaryTableColumnsConfig
    )
    const currency = yield select(selectCurrencyCode)
    const reportName = 'All Campaigns Report'
    const params = {
        ...formatFilters(filters),
        ...formatSorter(sorter),
        ...formatColumns(columns, showPeriodDeltas),
        ...formatCurrency(currency),
        ...formatPeriodDeltaDateRange(
            showPeriodDeltas,
            periodDeltaType,
            periodDeltaDateRange,
            filters[DATES]
        ),
        group_by: 'campaign_id',
        async_download_name: reportName,
    }
    yield call(
        cerebroApiSaga,
        makeDownloadTableSuccess(TABLE_PATH),
        getCampaignFactsExport,
        params
    )

    generateReportNotification(reportName)
}

function* fetchPageDataSaga() {
    yield put(makeFetchTableRequest(TABLE_PATH)())
}

/**
 * Mounts the Campaigns Summary page and fetches data
 */
function* mountPageSaga() {
    yield call(fetchPageDataSaga)
}

uiSagaRegistry.registerSagas([CAMPAIGNS_SUMMARY_PAGE], {
    mountPageSaga,
    fetchPageDataSaga,
})
uiSagaRegistry.registerSagas(TABLE_PATH, {
    fetchTableSaga,
    downloadTableSaga,
})
