import { connect } from 'react-redux'

import { selectAutomationCapabilitiesMap } from 'selectors/ui/page'

import Automation from './Automation'

const mapStateToProps = (state) => ({
    capabilitiesMap: selectAutomationCapabilitiesMap(state),
})

export default connect(mapStateToProps)(Automation)
