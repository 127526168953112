import { ReactElement } from 'react'

import Icon from '@ant-design/icons'

import SimpleRightArrowIconSvg from './SimpleRightArrowIconSvg'
import { IconProps } from '../localTypes'

const SimpleRightArrowIcon = (props: IconProps): ReactElement => (
    <Icon component={SimpleRightArrowIconSvg} {...props} />
)

export default SimpleRightArrowIcon
