import { memo, ReactElement } from 'react'

import isEqual from 'lodash/isEqual'
import pick from 'lodash/pick'

import { useResource } from 'components/EditableFields/localHooks'
import { CAMPAIGN_STATES, PAUSED, ENABLED } from 'const/resourceStates'
import { updateAdGroup } from 'services/cerebroApi/orgScope/resourceApi'
import { AdGroupGroupBy } from 'types'

import { StateField } from '../../SharedFields/StateField'

type AdGroupRecord = AdGroupGroupBy

interface Props<RecordType> {
    dataIndex: string[]
    readOnly: boolean
    record: RecordType
}

function AdGroupStateField<RecordType extends AdGroupRecord>({
    dataIndex = ['ad_group', 'state'],
    readOnly = false,
    record,
}: Props<RecordType>): ReactElement {
    const { fieldName, getFromResource, resourceId } = useResource(
        record,
        dataIndex,
        'ad_group'
    )

    const AVAILABLE_OPTIONS = pick(CAMPAIGN_STATES, [PAUSED, ENABLED])

    const getIsDisabled = (): boolean => {
        const userCanManageAdGroup = getFromResource(['user_can_manage'], true)
        return !userCanManageAdGroup
    }

    return (
        <StateField
            disabled={getIsDisabled()}
            fieldName={fieldName}
            fieldPath={dataIndex}
            formatFieldValue={(value) => {
                const state = value[fieldName]
                return CAMPAIGN_STATES[state]
            }}
            options={AVAILABLE_OPTIONS}
            placeholder="Ad Group State"
            readOnly={readOnly}
            record={record}
            updateRequestApi={updateAdGroup}
            updateRequestData={resourceId}
            updateRequestSuccessMesg="Successfully updated ad group state"
        />
    )
}

export default memo(AdGroupStateField, isEqual) as typeof AdGroupStateField
