import { ReactElement, ReactNode } from 'react'

import { Form, useFormikContext } from 'formik'

import { BulkEditError } from './BulkEditError'

interface Props {
    children: ReactNode
    errorMessage?: ReactElement | null
    validationError?: ReactElement | string | null
    validationErrorStyle?: 'error' | 'warning' | 'info'
}

const BulkUpdateAction = ({
    children,
    errorMessage,
    validationError,
    validationErrorStyle = 'error',
}: Props): ReactElement => {
    const { status, dirty } = useFormikContext()

    const getErrorText = (): ReactNode => {
        if (status) {
            return status
        }

        if (errorMessage) {
            return errorMessage
        }

        // only show validation errors after the form has been touched
        if (validationError && !!dirty) {
            return validationError
        }

        return null
    }

    return (
        <Form>
            <div className="d-flex flex-column">
                <div className="d-flex align-items-start">{children}</div>
                <BulkEditError
                    errorText={getErrorText()}
                    style={validationErrorStyle}
                />
            </div>
        </Form>
    )
}

export default BulkUpdateAction
