import { Translation } from 'react-i18next'

const translations = {
    raw_cogs_weighted: {
        name: (
            <Translation>
                {(t): string =>
                    t(
                        'metrics:productAds.raw_cogs_weighted.name ',
                        'Cost Of Goods Sold (COGS)'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t): string =>
                    t('metrics:productAds.raw_cogs_weighted.shortName ', 'COGS')
                }
            </Translation>
        ),
        helpText: (
            <Translation>
                {(t): string =>
                    t(
                        'metrics:productAds.raw_cogs_weighted.helpText ',
                        'Cost Of Goods Sold (COGS)'
                    )
                }
            </Translation>
        ),
    },
    supplier_cogs_weighted: {
        name: (
            <Translation>
                {(t): string =>
                    t(
                        'metrics:productAds.supplier_cogs_weighted.name ',
                        'Supplier COGS'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t): string =>
                    t(
                        'metrics:productAds.supplier_cogs_weighted.shortName ',
                        'Supplier COGS'
                    )
                }
            </Translation>
        ),
        helpText: (
            <Translation>
                {(t): string =>
                    t(
                        'metrics:productAds.supplier_cogs_weighted.helpText ',
                        'Supplier COGS'
                    )
                }
            </Translation>
        ),
    },
    misc_cogs_weighted: {
        name: (
            <Translation>
                {(t): string =>
                    t(
                        'metrics:productAds.misc_cogs_weighted.name ',
                        'Misc COGS'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t): string =>
                    t(
                        'metrics:productAds.misc_cogs_weighted.shortName ',
                        'Misc COGS'
                    )
                }
            </Translation>
        ),
        helpText: (
            <Translation>
                {(t): string =>
                    t(
                        'metrics:productAds.misc_cogs_weighted.helpText ',
                        'Misc COGS'
                    )
                }
            </Translation>
        ),
    },
    total_cogs_weighted: {
        name: (
            <Translation>
                {(t): string =>
                    t(
                        'metrics:productAds.total_cogs_weighted.name ',
                        'Total COGS'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t): string =>
                    t(
                        'metrics:productAds.total_cogs_weighted.shortName ',
                        'Total COGS'
                    )
                }
            </Translation>
        ),
        helpText: (
            <Translation>
                {(t): string =>
                    t(
                        'metrics:productAds.total_cogs_weighted.helpText ',
                        'Total COGS'
                    )
                }
            </Translation>
        ),
    },
    gross_profit_1_day_same_sku: {
        name: (
            <Translation>
                {(t): string =>
                    t(
                        'metrics:productAds.gross_profit_1_day_same_sku.name ',
                        'Gross Profit (1 Day)'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t): string =>
                    t(
                        'metrics:productAds.gross_profit_1_day_same_sku.shortName ',
                        'Gross Profit (1 Day)'
                    )
                }
            </Translation>
        ),
        helpText: (
            <Translation>
                {(t): string =>
                    t(
                        'metrics:productAds.gross_profit_1_day_same_sku.helpText ',
                        'Gross Profit (1 Day)'
                    )
                }
            </Translation>
        ),
    },
    gross_profit_7_day_same_sku: {
        name: (
            <Translation>
                {(t): string =>
                    t(
                        'metrics:productAds.gross_profit_7_day_same_sku.name ',
                        'Gross Profit (7 Day)'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t): string =>
                    t(
                        'metrics:productAds.gross_profit_7_day_same_sku.shortName ',
                        'Gross Profit (7 Day)'
                    )
                }
            </Translation>
        ),
        helpText: (
            <Translation>
                {(t): string =>
                    t(
                        'metrics:productAds.gross_profit_7_day_same_sku.helpText ',
                        'Gross Profit (7 Day)'
                    )
                }
            </Translation>
        ),
    },
    gross_profit_14_day_same_sku: {
        name: (
            <Translation>
                {(t): string =>
                    t(
                        'metrics:productAds.gross_profit_14_day_same_sku.name ',
                        'Gross Profit (14 Day)'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t): string =>
                    t(
                        'metrics:productAds.gross_profit_14_day_same_sku.shortName ',
                        'Gross Profit (14 Day)'
                    )
                }
            </Translation>
        ),
        helpText: (
            <Translation>
                {(t): string =>
                    t(
                        'metrics:productAds.gross_profit_14_day_same_sku.helpText ',
                        'Gross Profit (14 Day)'
                    )
                }
            </Translation>
        ),
    },
    gross_profit_30_day_same_sku: {
        name: (
            <Translation>
                {(t): string =>
                    t(
                        'metrics:productAds.gross_profit_30_day_same_sku.name ',
                        'Gross Profit (30 Day)'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t): string =>
                    t(
                        'metrics:productAds.gross_profit_30_day_same_sku.shortName ',
                        'Gross Profit (30 Day)'
                    )
                }
            </Translation>
        ),
        helpText: (
            <Translation>
                {(t): string =>
                    t(
                        'metrics:productAds.gross_profit_30_day_same_sku.helpText ',
                        'Gross Profit (30 Day)'
                    )
                }
            </Translation>
        ),
    },
    gross_profit_margin_1_day_same_sku: {
        name: (
            <Translation>
                {(t): string =>
                    t(
                        'metrics:productAds.gross_profit_margin_1_day_same_sku.name ',
                        'Gross Profit Margin (1 Day)'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t): string =>
                    t(
                        'metrics:productAds.gross_profit_margin_1_day_same_sku.shortName ',
                        'Gross Profit Margin (1 Day)'
                    )
                }
            </Translation>
        ),
        helpText: (
            <Translation>
                {(t): string =>
                    t(
                        'metrics:productAds.gross_profit_margin_1_day_same_sku.helpText ',
                        'Gross Profit Margin (1 Day)'
                    )
                }
            </Translation>
        ),
    },
    gross_profit_margin_7_day_same_sku: {
        name: (
            <Translation>
                {(t): string =>
                    t(
                        'metrics:productAds.gross_profit_margin_7_day_same_sku.name ',
                        'Gross Profit Margin (7 Day)'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t): string =>
                    t(
                        'metrics:productAds.gross_profit_margin_7_day_same_sku.shortName ',
                        'Gross Profit Margin (7 Day)'
                    )
                }
            </Translation>
        ),
        helpText: (
            <Translation>
                {(t): string =>
                    t(
                        'metrics:productAds.gross_profit_margin_7_day_same_sku.helpText ',
                        'Gross Profit Margin (7 Day)'
                    )
                }
            </Translation>
        ),
    },
    gross_profit_margin_14_day_same_sku: {
        name: (
            <Translation>
                {(t): string =>
                    t(
                        'metrics:productAds.gross_profit_margin_14_day_same_sku.name ',
                        'Gross Profit Margin (14 Day)'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t): string =>
                    t(
                        'metrics:productAds.gross_profit_margin_14_day_same_sku.shortName ',
                        'Gross Profit Margin (14 Day)'
                    )
                }
            </Translation>
        ),
        helpText: (
            <Translation>
                {(t): string =>
                    t(
                        'metrics:productAds.gross_profit_margin_14_day_same_sku.helpText ',
                        'Gross Profit Margin (14 Day)'
                    )
                }
            </Translation>
        ),
    },
    gross_profit_margin_30_day_same_sku: {
        name: (
            <Translation>
                {(t): string =>
                    t(
                        'metrics:productAds.gross_profit_margin_30_day_same_sku.name ',
                        'Gross Profit Margin (30 Day)'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t): string =>
                    t(
                        'metrics:productAds.gross_profit_margin_30_day_same_sku.shortName ',
                        'Gross Profit Margin (30 Day)'
                    )
                }
            </Translation>
        ),
        helpText: (
            <Translation>
                {(t): string =>
                    t(
                        'metrics:productAds.gross_profit_margin_30_day_same_sku.helpText ',
                        'Gross Profit Margin (30 Day)'
                    )
                }
            </Translation>
        ),
    },
}

export default translations
