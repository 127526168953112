import { ReactElement } from 'react'

import Icon from '@ant-design/icons'

import BellIconSvg from './BellIconSvg'
import { IconProps } from '../localTypes'

const BellIcon = (props: IconProps): ReactElement => (
    <Icon component={BellIconSvg} {...props} />
)

export default BellIcon
