import { ReactElement } from 'react'

import Icon from '@ant-design/icons'

import SimpleLeftArrowIconSvg from './SimpleLeftArrowIconSvg'
import { IconProps } from '../localTypes'

const SimpleLeftArrowIcon = (props: IconProps): ReactElement => (
    <Icon component={SimpleLeftArrowIconSvg} {...props} />
)

export default SimpleLeftArrowIcon
