import isEqual from 'lodash/isEqual'
import { useSelector, shallowEqual } from 'react-redux'

import {
    selectDomainValue as selectAuthDomainValue,
    selectUserOrganizationFeaturePermissions,
} from 'selectors/auth'
import { RootReduxState, UserPermission } from 'types'

export function useAuthDomainValue<T>(path: string | string[]): T {
    return useSelector<RootReduxState, T>(
        (state) => selectAuthDomainValue(state, path),
        isEqual
    )
}

export function useUserHasPermissions(
    hasPermissionsFunc: (permissions: UserPermission[]) => boolean
): boolean {
    return hasPermissionsFunc(
        useSelector<RootReduxState, UserPermission[]>(
            (state) => selectUserOrganizationFeaturePermissions(state),
            shallowEqual
        )
    )
}
