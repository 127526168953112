import { combineChildrenReducers } from 'utilities/reducers'

import childProductsReducer from './childProducts'
import pageReducer from './page'
import productAdsReducer from './productAds'

export default combineChildrenReducers(pageReducer, {
    childProducts: childProductsReducer,
    productAds: productAdsReducer,
})
