import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'

import { UNDEFINED_VALUE } from 'const/formatting'
import { formatAdAccountName } from 'helpers/formatting'
import { adAccountUrl } from 'helpers/urls'

import { ResourceLink } from './ResourceLink'

const AdAccountLink = ({ adAccount, appendCountry = true }) => {
    if (!adAccount || isEmpty(adAccount)) {
        return <span>{UNDEFINED_VALUE}</span>
    }
    return (
        <ResourceLink to={adAccountUrl(adAccount.id)}>
            {formatAdAccountName(adAccount, { appendCountry })}
        </ResourceLink>
    )
}

AdAccountLink.propTypes = {
    adAccount: PropTypes.object,
}

AdAccountLink.defaultProps = {
    adAccount: null,
}

export default AdAccountLink
