import { ReactElement } from 'react'

import { Row, Col } from 'antd'
import classNames from 'classnames'
import map from 'lodash/map'
import noop from 'lodash/noop'

import { WIDGET_TYPES, WidgetTypeEntry } from 'configuration/widgets'
import { WidgetType } from 'types'

import styles from './styles.scss'

interface WidgetTypesProps {
    selectedType: string | null
    handleWidgetTypeClick: (key: WidgetType) => void
}

function WidgetTypes({
    selectedType = null,
    handleWidgetTypeClick,
}: WidgetTypesProps): ReactElement {
    function renderWidgetType(
        widgetType: WidgetTypeEntry,
        key: WidgetType
    ): ReactElement {
        const { name, svgComponent: SvgComponent } = widgetType
        return (
            <Col key={key}>
                <div
                    className={classNames({
                        [styles['widget-type-container']]: true,
                        [styles['widget-type-container-active']]:
                            selectedType === key,
                    })}
                    onClick={() => handleWidgetTypeClick(key)}
                    role="button"
                    tabIndex={-1}
                    onKeyPress={noop}
                >
                    <SvgComponent className={styles['widget-icon']} />
                    <div className={styles['widget-title']}>{name}</div>
                </div>
            </Col>
        )
    }

    return (
        <Row justify="center" style={{ marginTop: '40px' }} gutter={16}>
            {map(WIDGET_TYPES, renderWidgetType)}
        </Row>
    )
}

export default WidgetTypes
