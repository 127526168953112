import { ReactElement, ReactNode } from 'react'

import { useTranslation } from 'react-i18next'

import {
    AsyncUpdateAction,
    AsyncUpdateDescription,
    AsyncUpdateSourceType,
    AsyncUpdateStatus,
} from 'components/AsyncUpdates'
import { UNDEFINED_VALUE } from 'const/formatting'
import { getTranslatedBulkUpdateLabel } from 'helpers/bulkUpdate'
import { formatNumber } from 'helpers/formatting'
import { isUnset } from 'helpers/utilities'
import { AsyncUpdate, CellRenderProps } from 'types'

type Props<RecordType> = CellRenderProps<RecordType>

export function asyncUpdateStatusRenderer<RecordType extends AsyncUpdate>({
    record,
}: Props<RecordType>): ReactElement {
    return <AsyncUpdateStatus update={record} />
}

export function asyncUpdateActionRenderer<RecordType extends AsyncUpdate>({
    record,
    reloadData,
}: Props<RecordType>): ReactElement {
    return <AsyncUpdateAction update={record} reloadData={reloadData} />
}

export function asyncUpdateDescriptionRenderer<RecordType extends AsyncUpdate>({
    record,
}: Props<RecordType>): ReactElement {
    return <AsyncUpdateDescription update={record} />
}

const BulkUpdateLabel = ({
    recordType,
    count = 1,
}: {
    recordType: string
    count?: number
}): ReactElement => {
    const { t } = useTranslation()

    return (
        <span className="fg-default-tag">
            {getTranslatedBulkUpdateLabel(recordType, count, t)}
        </span>
    )
}

export function asyncUpdateTypeRenderer<RecordType extends AsyncUpdate>({
    record,
}: Props<RecordType>): ReactElement {
    return <BulkUpdateLabel recordType={record.type} />
}

export function asyncUpdateSourceTypeRenderer<RecordType extends AsyncUpdate>({
    record,
}: Props<RecordType>): ReactElement {
    return <AsyncUpdateSourceType update={record} />
}

export function asyncUpdateCountRenderer<RecordType extends AsyncUpdate>({
    record,
}: Props<RecordType>): ReactNode {
    if (isUnset(record.count) || record.count === 0) {
        return UNDEFINED_VALUE
    }

    return formatNumber(record.count)
}
