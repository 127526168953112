import { ReactElement } from 'react'

import { useTranslation } from 'react-i18next'

import { formatNumber } from 'helpers/formatting'

interface Props {
    count: number
}

export const TotalRowsCountCell = ({ count }: Props): ReactElement => {
    const { t } = useTranslation('table')

    return (
        <div>
            {t('table:fields.totalRows', 'Total: {{total}} Rows', {
                total: formatNumber(count),
            })}
        </div>
    )
}
