import { ReactElement } from 'react'

import Icon from '@ant-design/icons'

import InfoIconSvg from './InfoIconSvg'
import { IconProps } from '../localTypes'

const InfoIcon = (props: IconProps): ReactElement => (
    <Icon component={InfoIconSvg} {...props} />
)

export default InfoIcon
