import { generatePath } from 'react-router'

import { ResourceLink } from 'components/Links/ResourceLink'
import { adGroupName } from 'configuration/fieldCreators/walmartAdGroup'
import {
    advertiserAccountName,
    advertiserId,
    advertiserIntegrationType,
    advertiserType,
} from 'configuration/fieldCreators/walmartAdvertiser'
import {
    campaignName,
    campaignTargetingType,
} from 'configuration/fieldCreators/walmartCampaign'
import {
    adItemBid,
    adItemId,
    adItemState,
    itemId,
    itemMetaDataTitle,
} from 'configuration/fieldCreators/walmartItem'
import { WALMART_AD_GROUP_PAGE, WALMART_CAMPAIGN_PAGE } from 'const/pages'
import { getPath } from 'helpers/pages'
import { Field, WalmartAdItemGroupBy } from 'types'

import { WM_ADVERTISING_METRICS_TABLE_COLUMNS } from '../dataSources/walmartAdvertising'

export const walmartAdItemsSummaryTableColumnsConfig: Field<WalmartAdItemGroupBy>[] =
    [
        itemMetaDataTitle({
            alternateId: 'item_name',
            fixed: true,
            dataIndex: ['item_metadata', 'name'],
        }),
        adItemState({
            dataIndex: ['ad_item', 'state'],
        }),
        adItemBid({ dataIndex: ['ad_item', 'bid'] }),
        itemId({ dataIndex: ['ad_item', 'item_id'] }),
        campaignName({
            dataIndex: ['campaign', 'name'],
            renderOptions: {
                render: ({ record, value }) => (
                    <ResourceLink
                        to={generatePath(getPath(WALMART_CAMPAIGN_PAGE), {
                            wmCampaignId: record.campaign.id,
                        })}
                    >
                        {value}
                    </ResourceLink>
                ),
            },
        }),
        advertiserAccountName({
            alternateId: 'advertiser_alias',
            dataIndex: ['advertiser', 'alias'],
        }),
        campaignTargetingType({
            alternateId: 'campaign_targeting_type',
            dataIndex: ['campaign', 'targeting_type'],
        }),
        adGroupName({
            alternateId: 'ad_group_name',
            dataIndex: ['ad_group', 'name'],
            renderOptions: {
                render: ({ record, value }) => (
                    <ResourceLink
                        to={generatePath(getPath(WALMART_AD_GROUP_PAGE), {
                            wmAdGroupId: record.ad_group.id,
                            wmCampaignId: record.campaign.id,
                        })}
                    >
                        {value}
                    </ResourceLink>
                ),
            },
        }),
        adItemId({
            alternateId: 'ad_item_id',
            dataIndex: ['ad_item', 'id'],
        }),
        advertiserType({
            isVisible: false,
            dataIndex: ['advertiser', 'type'],
        }),
        advertiserId({
            isVisible: false,
            dataIndex: ['advertiser', 'advertiser_id'],
        }),
        advertiserIntegrationType({
            isVisible: false,
            dataIndex: ['advertiser', 'read_only'],
        }),
        ...(WM_ADVERTISING_METRICS_TABLE_COLUMNS as Field<WalmartAdItemGroupBy>[]),
    ]

export const walmartCampaignAdItemsTabTableColumnsConfig: Field<WalmartAdItemGroupBy>[] =
    [
        itemMetaDataTitle({
            fixed: true,
            dataIndex: ['item_metadata', 'name'],
        }),
        adItemId({ dataIndex: ['ad_item', 'id'] }),
        adItemState({
            dataIndex: ['ad_item', 'state'],
        }),
        adItemBid({ dataIndex: ['ad_item', 'bid'] }),
        itemId({ dataIndex: ['ad_item', 'item_id'] }),
        adGroupName({
            dataIndex: ['ad_group', 'name'],
            renderOptions: {
                render: ({ record, value }) => (
                    <ResourceLink
                        to={generatePath(getPath(WALMART_AD_GROUP_PAGE), {
                            wmAdGroupId: record.ad_group.id,
                            wmCampaignId: record.campaign.id,
                        })}
                    >
                        {value}
                    </ResourceLink>
                ),
            },
        }),
        adItemId({
            dataIndex: ['ad_item', 'id'],
        }),
        ...(WM_ADVERTISING_METRICS_TABLE_COLUMNS as Field<WalmartAdItemGroupBy>[]),
    ]

export const walmartAdGroupAdItemsTabTableColumnsConfig: Field<WalmartAdItemGroupBy>[] =
    [
        itemMetaDataTitle({
            fixed: true,
            dataIndex: ['item_metadata', 'name'],
        }),
        itemId({ dataIndex: ['ad_item', 'item_id'] }),
        adItemState({
            dataIndex: ['ad_item', 'state'],
        }),
        adItemBid({ dataIndex: ['ad_item', 'bid'] }),
        adItemId({
            dataIndex: ['ad_item', 'id'],
        }),
        ...(WM_ADVERTISING_METRICS_TABLE_COLUMNS as Field<WalmartAdItemGroupBy>[]),
    ]
