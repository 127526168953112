import { createAction } from 'redux-actions'

import { curryActionForPath } from 'helpers/curry'

export const setDataLoading = createAction('SET_DATA_LOADING')
export type SetDataLoadintAction = ReturnType<typeof setDataLoading>

export const fetchDataRequest = createAction('FETCH_DATA_REQUEST')
export type FetchDataRequestAction = ReturnType<typeof fetchDataRequest>

export const fetchDataSuccess = createAction('FETCH_DATA_SUCCESS')
export type FetchDataSuccessAction = ReturnType<typeof fetchDataSuccess>

export const fetchDataFailure = createAction('FETCH_DATA_FAILURE')
export type FetchDataFailureAction = ReturnType<typeof fetchDataFailure>

export const updateSorter = createAction('UPDATE_SORTER')
export type UpdateSorterAction = ReturnType<typeof updateSorter>

export const downloadDataRequest = createAction('DOWNLOAD_DATA_REQUEST')
export type DownloadDataRequestAction = ReturnType<typeof downloadDataRequest>

export const downloadDataSuccess = createAction('DOWNLOAD_DATA_SUCCESS')
export type DownloadDataSuccessAction = ReturnType<typeof downloadDataSuccess>

export const downloadDataFailure = createAction('DOWNLOAD_DATA_FAILURE')
export type DownloadDataFailureAction = ReturnType<typeof downloadDataFailure>

export const makeFetchDataFailure = curryActionForPath(fetchDataFailure)
export const makeDownloadDataFailure = curryActionForPath(downloadDataFailure)
