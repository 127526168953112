import produce from 'immer'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import set from 'lodash/set'

import {
    FetchDataRequestAction,
    FetchDataSuccessAction,
    SetDataLoadintAction,
    fetchDataRequest,
    fetchDataSuccess,
    fetchDataFailure,
    setDataLoading,
    updateSorter,
    FetchDataFailureAction,
    UpdateSorterAction,
    downloadDataRequest,
    DownloadDataRequestAction,
    downloadDataSuccess,
    DownloadDataSuccessAction,
    downloadDataFailure,
    DownloadDataFailureAction,
} from 'actions/ui/shared'
import {
    FieldPropertiesSerializable,
    Pagination,
    PeriodDeltaType,
    Sorter,
} from 'types'

interface DataState {
    loading: boolean
    error: string | null
    downloading: boolean
    data: any[]
    [att: string]: any
}

export interface TableState extends DataState {
    tableSettings: {
        columns: FieldPropertiesSerializable[]
        sorter: Sorter
        pagination: Pagination
        showPeriodDeltas: boolean
        periodDeltaType: PeriodDeltaType
        showTotalRow: boolean
        periodDeltaDateRange: string[]
    }
}

export interface ListState extends DataState {
    listSettings: {
        sorter: Sorter
    }
}

interface State {
    [path: string]: TableState | ListState
}

const isTableState = (state: DataState): state is TableState =>
    get(state, 'tableSettings') !== undefined

const getSettingsName = (state: State, path: string[]): string =>
    isTableState(get(state, path)) ? 'tableSettings' : 'listSettings'

export default {
    // Set Loading State Directly
    [setDataLoading.toString()](
        state: State,
        { payload: { path, data: loading } }: SetDataLoadintAction
    ) {
        return produce(state, (draft) => {
            set(draft, [...path, 'loading'], loading)
        })
    },

    // Data Fetching
    [fetchDataRequest.toString()](
        state: State,
        { payload: { path } }: FetchDataRequestAction
    ) {
        return produce(state, (draft) => {
            set(draft, [...path, 'loading'], true)
        })
    },
    [fetchDataSuccess.toString()](
        state: State,
        action: FetchDataSuccessAction
    ) {
        const {
            path,
            data: { results, count, unrestricted_count },
        } = action.payload
        return produce(state, (draft) => {
            set(draft, [...path, 'loading'], false)
            set(draft, [...path, 'error'], null)
            set(draft, [...path, 'data'], results)
            if (isTableState(get(draft, path))) {
                if (!isNil(count)) {
                    set(
                        draft,
                        [...path, 'tableSettings', 'pagination', 'total'],
                        count
                    )
                }
                if (!isNil(unrestricted_count)) {
                    set(
                        draft,
                        [
                            ...path,
                            'tableSettings',
                            'pagination',
                            'unrestricted_total',
                        ],
                        unrestricted_count
                    )
                }
            }
        })
    },
    [fetchDataFailure.toString()](
        state: State,
        {
            payload: {
                path,
                data: { message },
            },
        }: FetchDataFailureAction
    ) {
        return produce(state, (draft) => {
            set(draft, [...path, 'loading'], false)
            set(draft, [...path, 'error'], message)
        })
    },

    // Sorter
    [updateSorter.toString()](state: State, action: UpdateSorterAction) {
        const { path, data: sorter } = action.payload
        const settingsName = getSettingsName(state, path)
        return produce(state, (draft) => {
            set(draft, [...path, settingsName, 'sorter'], sorter)
        })
    },

    // Downloads
    [downloadDataRequest.toString()](
        state: State,
        { payload: { path } }: DownloadDataRequestAction
    ) {
        return produce(state, (draft) => {
            set(draft, [...path, 'downloading'], true)
        })
    },
    [downloadDataSuccess.toString()](
        state: State,
        { payload: { path } }: DownloadDataSuccessAction
    ) {
        return produce(state, (draft) => {
            set(draft, [...path, 'downloading'], false)
        })
    },
    [downloadDataFailure.toString()](
        state: State,
        {
            payload: {
                path,
                data: { message },
            },
        }: DownloadDataFailureAction
    ) {
        return produce(state, (draft) => {
            set(draft, [...path, 'downloading'], false)
            set(draft, [...path, 'error'], message)
        })
    },
}
