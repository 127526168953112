import { call, put, select } from 'redux-saga/effects'

import { makeFetchTableRequest, makeFetchTableSuccess } from 'actions/ui/shared'
import { CAMPAIGN_PAGE } from 'const/pages'
import { ENABLED } from 'const/resourceStates'
import { formatFilters } from 'helpers/filters/negativeKeyword'
import { formatSorter, formatPagination } from 'helpers/params'
import { cerebroApiSaga } from 'sagas/common'
import uiSagaRegistry from 'sagas/ui/registry'
import {
    selectResourceOfPage,
    selectTableSettings,
    selectVisibleCombinedFilters,
} from 'selectors/ui'
import { getCampaignNegativeKeywords } from 'services/cerebroApi/orgScope/resourceApi'

const TAB_PATH = [CAMPAIGN_PAGE, 'negativeKeywords']
const TABLE_PATH = [...TAB_PATH, 'table']

function* fetchTableSaga(noCount) {
    const campaign = yield select(selectResourceOfPage, CAMPAIGN_PAGE)
    const { pagination, sorter } = yield select(selectTableSettings, TABLE_PATH)
    const filters = yield select(selectVisibleCombinedFilters, TAB_PATH)
    const params = {
        ...formatPagination(pagination),
        ...formatSorter(sorter),
        ...formatFilters(filters),
        state: ENABLED,
        campaign: campaign.id,
    }

    const response = yield call(
        cerebroApiSaga,
        null,
        getCampaignNegativeKeywords,
        params,
        {
            headers: { noCount },
        }
    )

    if (response) {
        yield put(makeFetchTableSuccess(TABLE_PATH)(response.data))
    }
}

function* fetchTabDataSaga() {
    yield put(makeFetchTableRequest(TABLE_PATH)())
}

// Register Sagas
uiSagaRegistry.registerSagas(TAB_PATH, {
    fetchTabDataSaga,
})

uiSagaRegistry.registerSagas(TABLE_PATH, {
    fetchTableSaga,
})
