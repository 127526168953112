import { ReactElement } from 'react'

import { AmazonOutlined } from '@ant-design/icons'

import { sellerUrl } from 'helpers/urls'
import { CountryCode } from 'types'

interface Props {
    sellerId: string
    countryCode: CountryCode
    showAmazonIcon?: boolean
}

function AsinLink({
    sellerId,
    countryCode,
    showAmazonIcon = true,
}: Props): ReactElement | null {
    return sellerId ? (
        <a
            href={sellerUrl(sellerId, countryCode)}
            target="_blank"
            rel="noopener noreferrer"
        >
            {sellerId} {showAmazonIcon && <AmazonOutlined />}
        </a>
    ) : null
}

export default AsinLink
