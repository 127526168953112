import {
    ReactElement,
    ComponentClass,
    JSXElementConstructor,
    LazyExoticComponent,
    useEffect,
    useState,
} from 'react'

import { useSelector } from 'react-redux'
import { RouteComponentProps, RouteProps } from 'react-router-dom'

import { LoadingIndicator } from 'components/LoadingIndicator'
import { DynamicModule } from 'dynamicModules/types'
import { dynamicReducerKeys } from 'reducers/ui/ui'
import { selectDomainValue as selectUiDomainValue } from 'selectors/ui'
import { RootReduxState } from 'types'

interface InnerProps extends RouteProps {
    pageName?: string
}

// Track which modules have been initialized already
const initCache: Record<string, boolean> = {}
const hydrateCache: Record<string, boolean> = {}

function useDynamicModuleInit(
    pageName: string,
    module: DynamicModule
): boolean {
    const [isInitialized, setIsInitialized] = useState(
        initCache[pageName] ?? false
    )
    const [isHydrated, setIsHydrated] = useState(
        hydrateCache[pageName] ?? false
    )

    const requiresDynamicReducer = (
        dynamicReducerKeys as readonly string[]
    ).includes(pageName)
    const isReducerHydrated = useSelector((state: RootReduxState) =>
        selectUiDomainValue(state, [pageName, 'hydrated'])
    )
    useEffect(() => {
        if (!initCache[pageName]) {
            module.init()
            setIsInitialized(true)
            initCache[pageName] = true
        }
    }, [pageName, module])

    useEffect(() => {
        if (isReducerHydrated) {
            setIsHydrated(true)
            hydrateCache[pageName] = true
        }
    }, [pageName, isReducerHydrated, module])

    return isInitialized && (!requiresDynamicReducer || isHydrated)
}

function DynamicModuleInitializer({
    pageName,
    module,
    children,
}: {
    pageName: string
    module: DynamicModule
    children: ReactElement
}): ReactElement {
    const isInitialized = useDynamicModuleInit(pageName, module)

    return isInitialized ? children : <LoadingIndicator size="small" />
}

export default function DynamicPageRoute<C>(
    module: DynamicModule
):
    | LazyExoticComponent<ComponentClass<C & RouteComponentProps<any>>>
    | JSXElementConstructor<C & RouteComponentProps<any>>
    | ComponentClass<C & RouteComponentProps<any>> {
    return ({ pageName = '', ...props }: InnerProps): ReactElement => {
        return (
            <DynamicModuleInitializer module={module} pageName={pageName}>
                <module.page {...(props as any)} />
            </DynamicModuleInitializer>
        )
    }
}
