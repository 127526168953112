import { call, select } from 'redux-saga/effects'

import { makeFetchResourceSuccess } from 'actions/ui/shared'
import { WALMART_CAMPAIGN_PAGE } from 'const/pages'
import { cerebroApiSaga } from 'sagas/common'
import uiSagaRegistry from 'sagas/ui/registry'
import { selectResourceParamsOfPage } from 'selectors/ui'
import { getWalmartCampaign } from 'services/cerebroApi/orgScope/walmartApi'

function* fetchWalmartCampaignSaga() {
    const { wmCampaignId } = yield select(
        selectResourceParamsOfPage,
        WALMART_CAMPAIGN_PAGE
    )

    yield call(
        cerebroApiSaga,
        makeFetchResourceSuccess(WALMART_CAMPAIGN_PAGE),
        getWalmartCampaign,
        wmCampaignId
    )
}

/**
 * Mounts the Walmart Campaign Page
 */
function* mountPageSaga() {
    yield call(fetchWalmartCampaignSaga)
}

uiSagaRegistry.registerSagas([WALMART_CAMPAIGN_PAGE], {
    mountPageSaga,
})
