import { Translation } from 'react-i18next'

import { isQueryUsedAsNegativeKeywordRenderer } from 'components/FieldRenderers/Renderers/isQueryUsedAsNegativeKeyword/isQueryUsedAsNegativeKeyword'
import { Field } from 'types'

import { createField } from './createField'
import { FieldCreatorOptions } from './localTypes'

export function isQueryUsedAsNegativeKeyword<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const name = (
        <Translation>
            {(t) =>
                t(
                    'metrics:ams.targeting.isQueryUsedAsNegativeKeyword.name',
                    'Negative?'
                )
            }
        </Translation>
    )
    return createField({
        ...options,
        id: 'negative_keyword_presence',
        name,
        shortName: name,
        dataIndex: options.dataIndex || ['negative_keyword_presence'],
        minWidth: 250,
        width: 300,
        columnTitle: name,
        isTotalSupported: false,
        renderOptions: options.renderOptions ?? {
            render: (props) => isQueryUsedAsNegativeKeywordRenderer<any>(props),
        },
    })
}
