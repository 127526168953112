import { SVGAttributes, ReactElement } from 'react'

type Props = SVGAttributes<SVGElement>

function SvgTrendDownIcon(props: Props): ReactElement {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M1.1616 5.32823C1.44055 5.04928 1.89281 5.04928 2.17175 5.32823L6.22895 9.38542C6.40229 9.55876 6.50231 9.658 6.58119 9.72496C6.61238 9.75144 6.63135 9.76492 6.64043 9.77091C6.65771 9.77482 6.67565 9.77482 6.69293 9.77091C6.70201 9.76492 6.72098 9.75144 6.75217 9.72496C6.83105 9.658 6.93107 9.55876 7.10441 9.38542L9.40245 7.08738C9.5528 6.937 9.69446 6.79531 9.82332 6.68592C9.96339 6.56701 10.1346 6.44493 10.3551 6.37328C10.6659 6.27228 11.0008 6.27228 11.3116 6.37328C11.5321 6.44493 11.7033 6.56701 11.8434 6.68592C11.9722 6.79532 12.1139 6.93702 12.2643 7.08741L17.6191 12.4422V8.3333C17.6191 7.93882 17.9389 7.61902 18.3333 7.61902C18.7278 7.61902 19.0476 7.93882 19.0476 8.3333V14.1666C19.0476 14.5611 18.7278 14.8809 18.3333 14.8809H12.5C12.1055 14.8809 11.7857 14.5611 11.7857 14.1666C11.7857 13.7721 12.1055 13.4524 12.5 13.4524H16.6089L11.2711 8.11452C11.0977 7.94118 10.9977 7.84194 10.9188 7.77498C10.8876 7.7485 10.8687 7.73502 10.8596 7.72903C10.8423 7.72512 10.8244 7.72512 10.8071 7.72903C10.798 7.73502 10.779 7.7485 10.7479 7.77498C10.669 7.84194 10.569 7.94118 10.3956 8.11452L8.09761 10.4125C7.94725 10.5629 7.80557 10.7046 7.67671 10.814C7.53663 10.9329 7.36545 11.055 7.14492 11.1267C6.83409 11.2277 6.49927 11.2277 6.18844 11.1267C5.96791 11.055 5.79672 10.9329 5.65665 10.814C5.52779 10.7046 5.38612 10.5629 5.23576 10.4125L1.1616 6.33838C0.882656 6.05943 0.882656 5.60717 1.1616 5.32823Z" />
        </svg>
    )
}

export default SvgTrendDownIcon
