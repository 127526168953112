import { memo, ReactElement } from 'react'

import { Field } from 'formik'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import { object, string } from 'yup'

import { ColorCircle } from 'components/ColorCircle'
import { FormikColorPicker } from 'components/formik'
import { COLOR_PICKER_OPTIONS } from 'const/colors'

import { SingleValueField } from '../SingleValueField'

interface Props<RecordType> {
    fieldPath: string[]
    record: RecordType
    fieldName?: string
    disabled?: boolean
    required?: boolean
    readOnly?: boolean
    onSave: (values: any) => Promise<{ status: number } | null>
    colors?: string[]
    useApplyAndCancelButtons?: boolean
    currentColor?: string
}

function ColorPickerField<RecordType>({
    fieldPath,
    record,
    fieldName = 'color',
    disabled = false,
    required = false,
    readOnly = false,
    onSave,
    colors,
    useApplyAndCancelButtons = true,
    currentColor,
}: Props<RecordType>): ReactElement {
    const validationSchema = object().shape({
        [fieldName]: required ? string().required() : string(),
    })

    return (
        <SingleValueField
            disabled={disabled}
            fieldName={fieldName}
            formatFieldValue={(values) => (
                <ColorCircle color={currentColor ?? values[fieldName]} />
            )}
            initialValues={{ [fieldName]: get(record, fieldPath) }}
            onSave={onSave}
            readOnly={readOnly}
            validationSchema={validationSchema}
            useApplyAndCancelButtons={useApplyAndCancelButtons}
        >
            {({ isSubmitting, closeForm, resetForm }) => (
                <Field
                    component={FormikColorPicker}
                    disabled={isSubmitting}
                    name={fieldName}
                    colors={colors ?? COLOR_PICKER_OPTIONS}
                    onChange={(val: any) => {
                        if (
                            !useApplyAndCancelButtons &&
                            closeForm &&
                            resetForm
                        ) {
                            onSave({ color: val.hex })
                            closeForm()
                            resetForm()
                        }
                    }}
                />
            )}
        </SingleValueField>
    )
}

export default memo(ColorPickerField, isEqual) as typeof ColorPickerField
