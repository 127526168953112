import { ReactElement } from 'react'

import { ResearchKeywordLink } from 'components/Links/ResearchKeywordLink/ResearchKeywordLink'
import { ResourceLink } from 'components/Links/ResourceLink'
import { UNDEFINED_VALUE } from 'const/formatting'
import { keywordUrl } from 'helpers/urls'
import { Keyword } from 'types'

import styles from './styles.scss'

interface KeyworedIdCellProps {
    keyword?: Keyword
}

export function KeywordIdCell({ keyword }: KeyworedIdCellProps): ReactElement {
    if (!keyword) {
        return <span>{UNDEFINED_VALUE}</span>
    }
    return (
        <div className={styles.field}>
            <ResourceLink
                className={styles['keyword-link']}
                to={keywordUrl(keyword.id)}
            >
                {keyword.text}
            </ResourceLink>
            {keyword.campaign?.targeting_type !== 'auto' && (
                <ResearchKeywordLink
                    countryCode={keyword.profile.country_code}
                    keyword={keyword.text}
                />
            )}
        </div>
    )
}
