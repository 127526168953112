import { ReactElement } from 'react'

import { fetchCustomEventsRequest } from 'actions/ui/app'
import { useAction } from 'hooks'
import { updateCustomEvent } from 'services/cerebroApi/orgScope/customEventsApi'
import { CustomEvent } from 'types'

import { TextAreaField } from '../SharedFields'

interface Props {
    readOnly?: boolean
    record: CustomEvent
}

function CustomEventDescriptionField({
    readOnly = false,
    record,
}: Props): ReactElement {
    const fetchCustomEvents = useAction(fetchCustomEventsRequest)

    return (
        <TextAreaField
            disabled={readOnly}
            fieldName="notes"
            fieldPath={['notes']}
            readOnly={readOnly}
            record={record}
            updateRequestApi={updateCustomEvent}
            updateRequestData={record.id}
            updateRequestSuccessMesg="Successfully updated custom event notes"
            updateRequestCallback={fetchCustomEvents}
        />
    )
}

export default CustomEventDescriptionField
