import { SVGAttributes, ReactElement } from 'react'

type Props = SVGAttributes<SVGElement>

function SvgShelfIntelligenceIcon(props: Props): ReactElement {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M.933 3.333a.9.9 0 01.9-.9H3.5a.9.9 0 01.868.664l.513 1.88 12.011.792a.9.9 0 01.841.898v5a.9.9 0 01-.83.897l-10.834.833a.9.9 0 01-.937-.66L3.314 6.07l-.501-1.837h-.98a.9.9 0 01-.9-.9zm6.773 3.949a.9.9 0 00-1.746.436l.834 3.334a.9.9 0 001.746-.437l-.834-3.333zm2.75-.465a.9.9 0 011.018.764l.417 2.917a.9.9 0 11-1.782.254l-.417-2.916a.9.9 0 01.764-1.019zm4.777 1.1a.9.9 0 00-1.8 0v2.5a.9.9 0 001.8 0v-2.5zm-5.9 7.916a1.667 1.667 0 11-3.333 0 1.667 1.667 0 013.333 0zm5 1.667a1.667 1.667 0 100-3.333 1.667 1.667 0 000 3.333z"
            />
        </svg>
    )
}

export default SvgShelfIntelligenceIcon
