import { ReactElement, Key, Dispatch, SetStateAction } from 'react'

import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Table, type TableProps, Tooltip } from 'antd'
import { toUpper } from 'lodash'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Flag } from 'components/Flag'
import { formatBrandName, titleCase } from 'helpers/formatting'
import { CountryCode, IntegrationProfile } from 'types'

interface Props {
    profiles: IntegrationProfile[]
    loading: boolean
    selectedRowKeys: Key[]
    setSelectedRowKeys: Dispatch<SetStateAction<Key[]>>
    scroll?: TableProps<IntegrationProfile>['scroll']
}

const IntegrationProfilesTable = ({
    profiles,
    loading,
    selectedRowKeys,
    setSelectedRowKeys,
    scroll = { y: 500 },
}: Props): ReactElement => {
    const { t } = useTranslation(['common', 'table'])

    return (
        <Table
            size="small"
            pagination={false}
            loading={loading}
            rowKey={(record) => record.profile.id}
            rowSelection={{
                selectedRowKeys,
                onChange: setSelectedRowKeys,
                columnWidth: 38,
            }}
            scroll={scroll}
            dataSource={profiles}
            columns={[
                {
                    title: t(
                        'common:advertisingAccount',
                        'Advertising Account'
                    ),
                    dataIndex: ['profile', 'brand_name'],
                    key: 'brand',
                    render: (_, record) => (
                        <span>
                            <Link
                                to={`/advertising_accounts/${record.profile.id}`}
                                target="_blank"
                            >
                                {formatBrandName(record.profile, {
                                    appendCountry: false,
                                })}
                            </Link>
                            {record.status === 'inactive' && (
                                <span>
                                    <Tooltip
                                        title={t(
                                            'table:IntegrationProfilesTable.inactiveTooltipTitle',
                                            'The integration has lost access to this Advertising Account.'
                                        )}
                                        placement="right"
                                    >
                                        <ExclamationCircleOutlined
                                            style={{
                                                color: '#ff4d4f',
                                                marginLeft: '5px',
                                            }}
                                        />
                                    </Tooltip>
                                </span>
                            )}
                        </span>
                    ),
                },
                {
                    title: t('common:brand_id', 'Brand ID'),
                    dataIndex: ['profile', 'brand_entity_id'],
                    key: 'brand_entity_id',
                    width: 185,
                },
                {
                    title: t('common:country', 'Country'),
                    dataIndex: ['profile', 'country_code'],
                    key: 'country_code',
                    render: (_, record) => (
                        <span
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 4,
                            }}
                        >
                            <Flag
                                country={
                                    toUpper(
                                        record.profile.country_code
                                    ) as CountryCode
                                }
                            />{' '}
                            {record.profile.country_code}
                        </span>
                    ),
                    width: 80,
                },
                {
                    title: t('common:type', 'Type'),
                    dataIndex: ['profile', 'type'],
                    key: 'type',
                    render: (text) => titleCase(text),
                    width: 80,
                },
            ]}
        />
    )
}

export default IntegrationProfilesTable
