import { memo, ReactElement } from 'react'

import { Popconfirm } from 'antd'
import isEqual from 'lodash/isEqual'
import { useSelector, useDispatch } from 'react-redux'

import { setHomeDashboardId } from 'actions/ui/app'
import { HomeDashboardIcon } from 'components/Icons'
import { UNDEFINED_VALUE } from 'const/formatting'
import { isUnset } from 'helpers/utilities'
import { selectDomainValue as selectUiDomainValue } from 'selectors/ui'
import { RootReduxState } from 'types'

interface Props {
    value: string
}

function SetAsHomeField({ value }: Props): ReactElement {
    const dispatch = useDispatch()

    const homeDashboardId = useSelector((state: RootReduxState) =>
        selectUiDomainValue(state, ['app', 'homeDashboardId'])
    )

    if (isUnset(value)) {
        return <span>{UNDEFINED_VALUE}</span>
    }

    const onConfirm = (): void => {
        dispatch(setHomeDashboardId({ dashboardId: value }))
    }

    return value === homeDashboardId ? (
        <HomeDashboardIcon active />
    ) : (
        <Popconfirm
            title="Do you want to set this as your Home Dashboard?"
            onConfirm={onConfirm}
            placement="right"
        >
            <HomeDashboardIcon active={false} />
        </Popconfirm>
    )
}

export default memo(SetAsHomeField, isEqual) as typeof SetAsHomeField
