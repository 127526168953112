import { ReactElement } from 'react'

import classNames from 'classnames'

import CreateCampaignNegativeKeywordFormAllRow from './CreateCampaignNegativeKeywordFormAllRow'
import CreateCampaignNegativeKeywordFormHeader from './CreateCampaignNegativeKeywordFormHeader'
import CreateCampaignNegativeKeywordFormRow from './CreateCampaignNegativeKeywordFormRow'
import { KeywordField } from './localTypes'
import styles from './styles.scss'

interface Props {
    keywords: KeywordField[]
    remove: (index: number) => void
    setFieldValue: (name: string, values: any) => void
}

const CreateCampaignNegativeKeywordForm = ({
    keywords = [],
    remove,
    setFieldValue,
}: Props): ReactElement => {
    return (
        <div className="d-flex flex-column mt-2 flex-shrink-0">
            {keywords.length > 0 ? (
                <div>
                    <CreateCampaignNegativeKeywordFormHeader />
                    <CreateCampaignNegativeKeywordFormAllRow
                        keywords={keywords}
                        setFieldValue={setFieldValue}
                    />
                    {keywords.map((keyword, idx) => (
                        <CreateCampaignNegativeKeywordFormRow
                            key={idx}
                            keyword={keyword}
                            keywordIndex={idx}
                            remove={remove}
                        />
                    ))}
                </div>
            ) : (
                <div
                    className={classNames(
                        'mt-2 p-3',
                        styles['empty-table-text']
                    )}
                >
                    To get started, add some negative keywords in the field
                    above
                </div>
            )}
        </div>
    )
}

export default CreateCampaignNegativeKeywordForm
