import { memo, ReactElement } from 'react'

import isEqual from 'lodash/isEqual'

import { userHasManageLabelsPermissions } from 'helpers/featurePermissions'
import { useUserHasPermissions, useCerebroApiRequest } from 'hooks'
import { updateLabel } from 'services/cerebroApi/orgScope/resourceApi'
import { Label } from 'types'
import message from 'utilities/message'

import { ColorPickerField } from '../SharedFields/ColorPickerField'

interface Props<RecordType> {
    dataIndex?: string[]
    readOnly?: boolean
    record: RecordType
}

function LabelColorField<RecordType extends Label>({
    dataIndex = ['color'],
    readOnly = false,
    record,
}: Props<RecordType>): ReactElement {
    const hasPermission = useUserHasPermissions(userHasManageLabelsPermissions)
    const makeCerebroApiRequest = useCerebroApiRequest()

    return (
        <ColorPickerField
            fieldPath={dataIndex}
            record={record}
            disabled={!hasPermission || readOnly}
            onSave={(values) =>
                makeCerebroApiRequest({
                    request: updateLabel(record.id, values),
                    onRequestSuccess: () => {
                        message.success('Successfully updated label color')
                    },
                })
            }
        />
    )
}

export default memo(LabelColorField, isEqual) as typeof LabelColorField
