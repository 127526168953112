import { createAction } from 'redux-actions'

const NAMESPACE = 'PRODUCTS_SUMMARY_PAGE'

// fetch children
export const fetchChildrenRequest = createAction(
    `${NAMESPACE}/FETCH_CHILDREN_REQUEST`
)
export const fetchChildrenSuccess = createAction(
    `${NAMESPACE}/FETCH_CHILDREN_SUCCESS`
)
