import { ReactElement, useCallback, CSSProperties } from 'react'

import { Select, Spin } from 'antd'

import { SelectOptionContent } from 'components/SelectOptionContent'
import {
    SEARCH_RESULTS_PER_QUERY,
    PREFETCH_RESULTS_PER_QUERY,
} from 'configuration/typeahead'
import { resolvedExpressionToString } from 'helpers/targeting'
import { useTypeahead } from 'hooks'
import { getTargets } from 'services/cerebroApi/orgScope/resourceApi'
import { NonKeywordTarget } from 'types'

interface Props {
    value?: string
    onChange: (value: string) => void
    campaignId: string
    disabled?: boolean
    isOptionDisabled?: (option: NonKeywordTarget) => boolean
    optionMetadata?: (option: NonKeywordTarget) => string
    style?: CSSProperties
    placeholder?: string
    formatLabel?: (target: NonKeywordTarget) => string
}

const CampaignTargetSearchSelect = ({
    campaignId,
    value,
    onChange,
    disabled = false,
    isOptionDisabled = () => false,
    optionMetadata = () => '',
    style = { width: '100%' },
    placeholder = 'Search Targets...',
    formatLabel = ({ expressions }: NonKeywordTarget) =>
        resolvedExpressionToString(expressions[0]),
}: Props): ReactElement => {
    const [options, loading, onSearch, resetLoadingState] =
        useTypeahead<NonKeywordTarget>({
            apiSearchFunc: (query) =>
                getTargets(
                    {
                        limit: SEARCH_RESULTS_PER_QUERY,
                        campaign: campaignId,
                        name__similar: query,
                        'state!': 'archived',
                    },
                    { headers: { noCount: true } }
                ),
            optionFormatter: (target) => ({
                value: target.id,
                label: formatLabel(target),
                disabled: isOptionDisabled(target),
                metadata: optionMetadata(target),
            }),
            prefetchApiFunc: useCallback(
                () =>
                    getTargets(
                        {
                            limit: PREFETCH_RESULTS_PER_QUERY,
                            campaign: campaignId,
                            'state!': 'archived',
                            ordering: 'name',
                        },
                        { headers: { noCount: true } }
                    ),
                [campaignId]
            ),
        })

    return (
        <Select
            filterOption
            optionFilterProp="title"
            optionLabelProp="title"
            placeholder={placeholder}
            loading={loading}
            notFoundContent={loading ? <Spin size="small" /> : null}
            onChange={onChange}
            onSearch={onSearch}
            onSelect={resetLoadingState}
            showSearch
            value={value}
            disabled={disabled}
            style={style}
        >
            {options.map((option, idx) => (
                <Select.Option
                    key={idx}
                    value={option.value}
                    title={option.label}
                    disabled={option.disabled}
                >
                    <SelectOptionContent
                        label={option.label}
                        metadata={option.metadata}
                    />
                </Select.Option>
            ))}
        </Select>
    )
}

export default CampaignTargetSearchSelect
