import { ReactElement, Dispatch, SetStateAction } from 'react'

import { FormikHelpers } from 'formik'
import uniq from 'lodash/uniq'
import pluralize from 'pluralize'
import { useTranslation } from 'react-i18next'
import { array, number, object, string } from 'yup'

import { BROAD, EXACT, PHRASE } from 'const/matchTypes'
import { ENABLED } from 'const/resourceStates'
import { WALMART_KEYWORD_BID_MAX, WALMART_KEYWORD_BID_MIN } from 'const/walmart'
import { useCerebroApiRequest } from 'hooks'
import {
    createWalmartKeyword,
    getWalmartSuggestedKeywords,
} from 'services/cerebroApi/orgScope/walmartApi'
import { WalmartAdGroup, WalmartKeyword, WalmartResourceState } from 'types'
import displayMessage from 'utilities/message'

import { FormValues, KeywordField } from './localTypes'
import { CreateKeywordModal } from '../CreateKeywordModal'
import { getWalmartAdGroupKeywordCreationColumnsConfig } from '../Shared/CreateKeywordModalColumnsConfigs'

interface Props {
    adGroup: WalmartAdGroup
    isModalVisible: boolean
    onModalCancel: () => void
    toggleModalVisible: () => void
    reloadData: () => void
}

const CreateWalmartAdGroupKeywordsModal = ({
    adGroup,
    isModalVisible,
    onModalCancel,
    reloadData,
    toggleModalVisible,
}: Props): ReactElement => {
    const { t } = useTranslation()

    const makeCerebroApiRequest = useCerebroApiRequest()

    const closeModal = (callback: any): void => {
        callback()
        toggleModalVisible()
        reloadData()
    }

    const submitForm = async (
        { keywords }: FormValues,
        { resetForm }: FormikHelpers<FormValues>
    ): Promise<void> => {
        const keywordItems: Partial<WalmartKeyword>[] = keywords.map(
            (keyword) =>
                ({
                    keyword_text: keyword.text,
                    bid: keyword.bid,
                    match_type: keyword.matchType,
                    state: keyword.state as WalmartResourceState,
                    ad_group_id: adGroup.id,
                    advertiser_id: adGroup.advertiser_id,
                    campaign_id: adGroup.campaign_id,
                }) as WalmartKeyword
        )
        await makeCerebroApiRequest({
            request: createWalmartKeyword({
                items: keywordItems,
            }),
            onRequestSuccess: (response) => {
                if (response.status === 207) {
                    const updated =
                        'successful_updates' in response.data
                            ? response.data.successful_updates
                            : [response.data]
                    displayMessage.success(
                        t(
                            'table:fields.walmartKeyword.creation.partialSuccessMsg',
                            'Successfully created {{successfulCount}} out of {{keywordQuantity}} on Walmart ad group "{{adGroupName}}"',
                            {
                                successfulCount: updated.length,
                                keywordQuantity: pluralize(
                                    t('common:keyword', 'Keyword'),
                                    keywords.length,
                                    true
                                ),
                                adGroupName: adGroup.name,
                            }
                        )
                    )
                } else {
                    displayMessage.success(
                        t(
                            'table:fields.walmartKeyword.creation.successMsg',
                            'Successfully created {{keywordQuantity}} on Walmart ad group "{{adGroupName}}"',
                            {
                                keywordQuantity: pluralize(
                                    t('common:keyword', 'Keyword'),
                                    keywords.length,
                                    true
                                ),
                                adGroupName: adGroup.name,
                            }
                        )
                    )
                }

                closeModal(resetForm)
            },
        })
    }

    const initialValues: FormValues = { keywords: [] }

    const validationSchema = object().shape({
        keywords: array()
            .of(
                object().shape({
                    // static values
                    text: string().required(),

                    // form values
                    matchType: string()
                        .label('Match Type')
                        .matches(new RegExp(`${EXACT}|${PHRASE}|${BROAD}`))
                        .required(),
                    bid: number()
                        .label('Keyword Bid')
                        .max(WALMART_KEYWORD_BID_MAX)
                        .min(WALMART_KEYWORD_BID_MIN)
                        .nullable()
                        .required(),
                })
            )
            .min(1)
            .required(),
    })

    const serializeKeywordField = (keyword: string): KeywordField => {
        return {
            text: keyword,
            state: ENABLED,
            matchType: EXACT,
            bid: WALMART_KEYWORD_BID_MIN,
        }
    }

    const fetchSuggestedKeywords = async (
        setSuggestedKeywords: Dispatch<SetStateAction<string[]>>,
        setEmptyStateMsg: Dispatch<SetStateAction<string>>
    ): Promise<void> => {
        await makeCerebroApiRequest({
            request: getWalmartSuggestedKeywords(adGroup),
            onRequestSuccess: (response) => {
                setSuggestedKeywords(
                    uniq(
                        response.data.map(
                            ({ query }: { query: string }) => query
                        )
                    )
                )
            },
            onRequestFailure: (response) => {
                if (
                    response?.data?.error?.includes('does not have any items')
                ) {
                    setEmptyStateMsg(
                        'Add an item to your Ad Group to show suggested Keywords.'
                    )
                }
            },
        })
    }

    return (
        <CreateKeywordModal<KeywordField>
            isModalVisible={isModalVisible}
            onModalCancel={onModalCancel}
            toggleModalVisible={toggleModalVisible}
            columnsConfig={getWalmartAdGroupKeywordCreationColumnsConfig(t)}
            initialValues={initialValues}
            validationSchema={validationSchema}
            submitForm={submitForm}
            serializeKeywordField={serializeKeywordField}
            fetchSuggestedKeywords={fetchSuggestedKeywords}
        />
    )
}

export default CreateWalmartAdGroupKeywordsModal
