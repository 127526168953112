import { ReactElement } from 'react'

import { QuestionCircleOutlined } from '@ant-design/icons'
import { Button } from 'antd'

import { setUserIsImpersonating } from 'actions/auth'
import { ToolTip } from 'components/ToolTip'
import { useAction, useAuthDomainValue } from 'hooks'

export const ImpersonationButtonToggle = (): ReactElement | null => {
    const isImpersonating = useAuthDomainValue('userIsImpersonating')
    const setIsImpersonating = useAction<boolean>(setUserIsImpersonating)

    const buttonText = `${
        isImpersonating ? 'Exit' : 'Enter'
    } Impersonation Mode`

    return (
        <>
            <Button
                type="link"
                onClick={() => setIsImpersonating(!isImpersonating)}
                className="p-0"
                style={{ height: 'auto' }}
            >
                {buttonText}
            </Button>
            {!isImpersonating && (
                <ToolTip
                    placement="top"
                    title={
                        <>
                            Also known as Steve Mode, Impersonation Mode allows
                            you to access any organization and view customer
                            data. Changes made to <b>anything</b> in
                            Impersonation Mode will be <b>live in production</b>
                            .
                            <br />
                            Use extreme caution when making changes.
                        </>
                    }
                >
                    <QuestionCircleOutlined className="pl-1" />
                </ToolTip>
            )}
        </>
    )
}
