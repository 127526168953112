import { ReactElement } from 'react'

import Icon from '@ant-design/icons'

import { ReactComponent as ArrowRightIcon } from 'images/arrow-right.svg'

import { IconButton, IconButtonProps } from './IconButton'

function ArrowRightButton(props: IconButtonProps): ReactElement {
    return (
        <IconButton
            icon={<Icon component={ArrowRightIcon} />}
            size="small"
            {...props}
        />
    )
}

export default ArrowRightButton
