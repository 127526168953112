import { ReactElement } from 'react'

interface Props {
    src: string
    alt: string
    className?: string
}

const WhitelabelLogoIcon = ({ src, alt, className }: Props): ReactElement => (
    <img src={src} alt={alt} className={className} />
)

export default WhitelabelLogoIcon
