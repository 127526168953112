import { ReactElement } from 'react'

import { Alert } from 'antd'

interface Props {
    hasRecentIntegration: boolean
}

const RecentIntegrationBanner = ({
    hasRecentIntegration,
}: Props): ReactElement | null =>
    hasRecentIntegration ? (
        <Alert
            banner
            type="warning"
            message="You have recently added a new Amazon Advertising integration.
                         Performance data can take up to 24 hours to become available in your account."
        />
    ) : null

export default RecentIntegrationBanner
