import { ReactElement } from 'react'

import Icon from '@ant-design/icons'

import ShelfIntelligenceIconSvg from './ShelfIntelligenceIconSvg'
import { IconProps } from '../localTypes'

const ShelfIntelligenceIcon = (props: IconProps): ReactElement => (
    <Icon component={ShelfIntelligenceIconSvg} {...props} />
)

export default ShelfIntelligenceIcon
