import { ReactElement } from 'react'

import { Formik, Field, ErrorMessage, Form } from 'formik'
import { useTranslation, Trans } from 'react-i18next'
import { object, string } from 'yup'

import { resetPasswordRequest } from 'actions/auth'
import CodeInput from 'components/CodeInput/CodeInput'
import { FormikInput } from 'components/formik'
import { ToolTip } from 'components/ToolTip'
import { MIN_PW_SIZE } from 'const/auth'
import { useAuthDomainValue, useAction } from 'hooks'

import { AuthError, SubmitButton } from '../Shared'
import styles from '../styles.scss'

const ResetPasswordForm = (): ReactElement => {
    const resetPassword = useAction(resetPasswordRequest)
    const email = useAuthDomainValue('email')
    const { t } = useTranslation('account')

    return (
        <>
            <div className={styles.title}>
                <h1>
                    {t(
                        'account:ResetPasswordForm.title',
                        'Reset your password'
                    )}
                </h1>
                <div className={styles.subtitle}>
                    <Trans i18nKey="account:ResetPasswordForm.subTitle">
                        Enter the 6-digit code that was sent to you
                        <br /> and your new password.
                    </Trans>
                </div>
            </div>

            <Formik
                initialValues={{ code: '', newPassword: '' }}
                onSubmit={(values) => resetPassword({ email, ...values })}
                validationSchema={object().shape({
                    code: string()
                        .required()
                        .label(t('account:code', '6-Digit Code')),
                    newPassword: string()
                        .min(MIN_PW_SIZE)
                        .required()
                        .label(
                            t(
                                'account:ResetPasswordForm.newPassword',
                                'New Password'
                            )
                        ),
                })}
            >
                {({ setFieldValue }) => (
                    <Form>
                        <div className="mb-2">
                            <CodeInput
                                length={6}
                                onChange={(code) => setFieldValue('code', code)}
                            />
                            <ErrorMessage
                                name="code"
                                component="div"
                                className="fg-control-text is-error"
                            />
                        </div>
                        <div style={{ marginTop: 32 }}>
                            <ToolTip
                                placement="right"
                                title={t(
                                    'account:PasswordField.securityTooltip',
                                    'We check passwords against common and compromised passwords to keep your account secure.'
                                )}
                            >
                                <Field
                                    component={FormikInput}
                                    placeholder={t(
                                        'account:ResetPasswordForm.newPassword',
                                        'New Password'
                                    )}
                                    name="newPassword"
                                    type="password"
                                    size="large"
                                />
                            </ToolTip>
                            <ErrorMessage
                                name="newPassword"
                                component="div"
                                className="fg-control-text is-error"
                            />
                        </div>
                        <SubmitButton>
                            {t(
                                'account:ResetPasswordForm.submitBtnText',
                                'Reset Password'
                            )}
                        </SubmitButton>
                    </Form>
                )}
            </Formik>

            <AuthError />
        </>
    )
}

export default ResetPasswordForm
