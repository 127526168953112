import {
    createRef,
    ReactElement,
    RefObject,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react'

import { Input, InputRef } from 'antd'

interface CodeInputProps {
    length: number
    onChange: (code: string) => void
    autoFocus?: boolean
}

const CodeInput = (props: CodeInputProps): ReactElement => {
    const { length, onChange, autoFocus = true } = props
    const [workingCodeValue, setWorkingCodeValue] = useState<string[]>([])
    const inputRefs = useRef<RefObject<InputRef>[]>([])
    inputRefs.current = [...Array(length).keys()].map(
        (_, i) => inputRefs.current[i] ?? createRef()
    )

    const setFocused = useCallback(
        (index: number, e?: React.SyntheticEvent<HTMLInputElement>): void => {
            if (index >= 0 && index < length) {
                inputRefs.current[index]?.current?.focus()
            }
            e?.preventDefault()
        },
        [length]
    )

    const handlePaste = useCallback(
        (e: React.ClipboardEvent<HTMLInputElement>) => {
            const value = e.clipboardData.getData('text')
            const newCode = value
                .split('')
                .filter((char) => !Number.isNaN(+char))
            if (newCode.length <= length) {
                setWorkingCodeValue(newCode)
                onChange(newCode.join(''))
            }
        },
        [length, onChange]
    )

    const handleKeyDown = useCallback(
        (index: number) => (e: React.KeyboardEvent<HTMLInputElement>) => {
            const { key } = e
            const hasValue = !!workingCodeValue[index]
            if (key === 'Backspace') {
                if (hasValue) {
                    setWorkingCodeValue((previous) => {
                        const newValue = [...previous]
                        newValue[index] = ''
                        onChange(newValue.join(''))
                        return newValue
                    })
                } else if (index > 0) {
                    setWorkingCodeValue((previous) => {
                        const newValue = [...previous]
                        newValue[index - 1] = ''
                        onChange(newValue.join(''))
                        return newValue
                    })
                    setFocused(index - 1, e)
                }
            } else if (key === 'ArrowLeft') {
                if (e.target instanceof HTMLInputElement) {
                    if (e.target.selectionStart === 0) {
                        setFocused(index - 1, e)
                    }
                }
            } else if (key === 'ArrowRight') {
                if (e.target instanceof HTMLInputElement) {
                    if (e.target.selectionStart === e.target.value.length) {
                        setFocused(index + 1, e)
                    }
                }
            } else if (!Number.isNaN(+key)) {
                setWorkingCodeValue((previous) => {
                    const newValue = [...previous]
                    newValue[index] = key
                    onChange(newValue.join(''))
                    return newValue
                })
                setFocused(index + 1, e)
            }
        },
        [workingCodeValue, setFocused, onChange]
    )

    useEffect(() => {
        setFocused(0)
    }, [setFocused])

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                gap: 8,
            }}
        >
            {Array.from({ length }).map((_, index) => (
                <div
                    key={index}
                    className="flex flex-row justify-center items-center"
                >
                    <Input
                        autoFocus={autoFocus && index === 0}
                        name="code"
                        inputMode="numeric"
                        ref={inputRefs.current[index]}
                        autoComplete="one-time-code"
                        type="text"
                        className="text-center"
                        style={{
                            width: 40,
                            fontSize: 24,
                        }}
                        size="large"
                        onPaste={handlePaste}
                        onPasteCapture={handlePaste}
                        onKeyDown={handleKeyDown(index)}
                        value={workingCodeValue[index] || ''}
                        maxLength={1}
                    />
                </div>
            ))}
        </div>
    )
}

export default CodeInput
