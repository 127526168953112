import { ReactNode } from 'react'

import {
    RemoveSovKeywordFromLabelField,
    SovStateField,
} from 'components/EditableFields/SovFields'
import { ResourceLink } from 'components/Links/ResourceLink'
import { UNDEFINED_VALUE } from 'const/formatting'
import { labelUrl, sovKeywordUrl } from 'helpers/urls'
import { isUnset } from 'helpers/utilities'
import { CellRenderProps, Label, SovFact, SovKeywordGroupBy } from 'types'

type RenderProps<RecordType> = {
    cellRenderProps: CellRenderProps<RecordType>
    dataIndex: string[]
}

export function sovKeywordIdRenderer<RecordType extends SovKeywordGroupBy>({
    value,
    record,
    undefinedValue = UNDEFINED_VALUE,
}: CellRenderProps<RecordType>): ReactNode {
    return isUnset(value) ? (
        undefinedValue
    ) : (
        <ResourceLink to={sovKeywordUrl(value)}>
            {record.keyword.text}
        </ResourceLink>
    )
}

export function sovKeywordStateRenderer<RecordType extends SovKeywordGroupBy>({
    cellRenderProps: { record, readonly },
    dataIndex,
}: RenderProps<RecordType>): ReactNode {
    return (
        <SovStateField
            record={record}
            readOnly={readonly}
            dataIndex={dataIndex}
        />
    )
}

export function sovKeywordLabelIdRenderer<
    RecordType extends SovKeywordGroupBy & {
        keyword__label: Label
    },
>({
    value,
    record,
    undefinedValue = UNDEFINED_VALUE,
}: CellRenderProps<RecordType>): ReactNode {
    if (isUnset(value)) {
        return undefinedValue
    }
    return (
        <ResourceLink
            to={{
                pathname: labelUrl(value),
                search: '?tab=sovKeywords',
            }}
        >
            {record.keyword__label?.name}
        </ResourceLink>
    )
}

export function removeSovKeywordFromLabelRenderer<RecordType extends SovFact>({
    cellRenderProps: { reloadData, readonly, record },
    dataIndex,
}: RenderProps<RecordType>): ReactNode {
    return (
        <RemoveSovKeywordFromLabelField
            dataIndex={dataIndex}
            record={record}
            readOnly={readonly}
            reloadData={reloadData}
        />
    )
}
