import { ReactNode } from 'react'

import isEmpty from 'lodash/isEmpty'

import {
    ArchiveTargetField,
    TargetBidField,
    TargetStateField,
    TargetSuggestedBidField,
} from 'components/EditableFields/TargetFields'
import { UNDEFINED_VALUE } from 'const/formatting'
import { resolvedExpressionToReactNode } from 'helpers/targeting'
import { isUnset } from 'helpers/utilities'
import {
    CellRenderProps,
    TargetGroupBy,
    NonKeywordTargetFact,
    ProfileGroupBy,
    Expression,
} from 'types'

import { ActiveBidCell } from '../ActiveBidCell'

type RenderProps<RecordType> = {
    cellRenderProps: CellRenderProps<RecordType>
    dataIndex: string[]
}

export function targetExpressionRenderer<RecordType extends TargetGroupBy>({
    value,
    record,
    undefinedValue = UNDEFINED_VALUE,
}: CellRenderProps<RecordType>): ReactNode {
    if (isUnset(value) || isEmpty(value)) {
        return undefinedValue
    }

    return (
        <div>
            {(value ?? []).map((v: Expression, i: number) => {
                return (
                    <div key={v.id ?? i}>
                        {resolvedExpressionToReactNode(v, record)}
                    </div>
                )
            })}
        </div>
    )
}

export function expressionDescriptionRenderer<
    RecordType extends TargetGroupBy,
>({ value, record }: CellRenderProps<RecordType>): ReactNode {
    const expressions = record.target?.expressions
    if (isUnset(expressions) || isEmpty(expressions)) {
        return value ?? UNDEFINED_VALUE
    }
    return (
        <div>
            {(expressions ?? []).map((v: Expression, i: number) => {
                return (
                    <div key={v.id ?? i}>
                        {resolvedExpressionToReactNode(v, record)}
                    </div>
                )
            })}
        </div>
    )
}

export function targetSuggestedBidRenderer<
    RecordType extends NonKeywordTargetFact<ProfileGroupBy>,
>({
    cellRenderProps: {
        record,
        readonly,
        reloadData,
        rowIndex,
        updateRecord,
        value,
    },
    dataIndex,
}: RenderProps<RecordType>): ReactNode {
    return (
        <TargetSuggestedBidField
            dataIndex={dataIndex}
            readOnly={readonly}
            record={record}
            reloadData={reloadData}
            rowIndex={rowIndex}
            updateRecord={updateRecord}
            value={value}
        />
    )
}

export function targetBidRenderer<
    RecordType extends NonKeywordTargetFact<ProfileGroupBy>,
>({
    cellRenderProps: { record, readonly },
    dataIndex,
}: RenderProps<RecordType>): ReactNode {
    return (
        <TargetBidField
            dataIndex={dataIndex}
            readOnly={readonly}
            record={record}
        />
    )
}

export function targetActiveBidRenderer<
    RecordType extends NonKeywordTargetFact<ProfileGroupBy>,
>({ cellRenderProps: { record, value } }: RenderProps<RecordType>): ReactNode {
    const { currency_code, timezone } = record.profile
    const { active_bid, base_bid, campaign } = record.target
    if (isUnset(value) || active_bid === null || base_bid === null) {
        return UNDEFINED_VALUE
    }
    return (
        <ActiveBidCell
            activeBid={active_bid}
            baseBid={base_bid}
            campaign={campaign}
            currencyCode={currency_code}
            timezone={timezone}
        />
    )
}

export function targetStateRenderer<RecordType extends NonKeywordTargetFact>({
    cellRenderProps: { record, readonly },
    dataIndex,
}: RenderProps<RecordType>): ReactNode {
    return (
        <TargetStateField
            dataIndex={dataIndex}
            readOnly={readonly}
            record={record}
        />
    )
}

export function archiveTargetRenderer<RecordType extends NonKeywordTargetFact>({
    cellRenderProps: { record, readonly, reloadData },
    dataIndex,
}: RenderProps<RecordType>): ReactNode {
    return (
        <ArchiveTargetField
            dataIndex={dataIndex}
            readOnly={readonly}
            record={record}
            reloadData={reloadData}
        />
    )
}
