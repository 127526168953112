import { all } from 'redux-saga/effects'

import './page'
import './groups'
import './members'
import './invitations'
import './vcIntegrations'
import './scIntegrations'
import './wmMarketplaceIntegrations'
import './wmAdvertisingIntegrations'
import './childOrgs'

import integrationsTabWatcher from './integrations'

export default function* organizationPageWatcher() {
    yield all([integrationsTabWatcher()])
}
