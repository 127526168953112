import { memo, ReactElement } from 'react'

import isEqual from 'lodash/isEqual'
import { useTranslation } from 'react-i18next'

import { useResource } from 'components/EditableFields/localHooks'
import { ARCHIVED } from 'const/resourceStates'
import { updateCampaign } from 'services/cerebroApi/orgScope/resourceApi'
import { CampaignGroupBy } from 'types'

import { DeleteField } from '../../SharedFields/DeleteField'

interface Props<RecordType> {
    dataIndex: string[]
    readOnly: boolean
    record: RecordType
    reloadData: () => void
}

function ArchiveCampaignField<RecordType extends CampaignGroupBy>({
    dataIndex = ['campaign'],
    readOnly = false,
    record,
    reloadData,
}: Props<RecordType>): ReactElement {
    const { t } = useTranslation(['table', 'common'])
    const { getFromResource, resourceId } = useResource<RecordType>(
        record,
        dataIndex,
        'campaign'
    )

    const getIsDisabled = (): boolean => {
        const userCanManageCampaign = getFromResource(['user_can_manage'], true)
        return !userCanManageCampaign || readOnly
    }

    return (
        <DeleteField
            disabled={getIsDisabled()}
            okText={t('common:archive', 'Archive')}
            title={t(
                'table:fields.archiveCampaign.confirmTxt',
                'Are you sure you want to archive this Campaign?'
            )}
            updateRequestApi={updateCampaign}
            updateRequestCallback={reloadData}
            updateRequestData={resourceId}
            updateRequestParams={{ state: ARCHIVED }}
            updateRequestSuccessMesg={t(
                'table:fields.archiveCampaign.successMsg',
                'Campaign successfully archived'
            )}
        />
    )
}

export default memo(
    ArchiveCampaignField,
    isEqual
) as typeof ArchiveCampaignField
