import { ReactElement } from 'react'

import { KeywordIcon } from 'components/Icons'
import { ENABLED } from 'const/resourceStates'
import { titleCase, formatCurrency } from 'helpers/formatting'
import { Keyword } from 'types'

import { DetailWrapper, DetailTag, DetailText, DetailIcon } from './shared'
import { StatusIndicator } from '../../StatusIndicator'

export function keywordStateRenderer(keyword: Keyword): ReactElement {
    return (
        <StatusIndicator
            enabled={keyword.state === ENABLED}
            text={titleCase(keyword.state)}
        />
    )
}

export function keywordActiveBidRenderer(keyword: Keyword): ReactElement {
    const bid = formatCurrency(keyword.active_bid, {
        decimal: true,
        currencyCode: keyword.profile.currency_code,
    })

    return (
        <DetailWrapper
            leftContent={<DetailTag text="Active Bid" type="active" />}
            rightContent={<DetailText text={bid} />}
        />
    )
}

export function keywordBidRenderer(keyword: Keyword): ReactElement {
    const bid = formatCurrency(keyword.base_bid, {
        decimal: true,
        currencyCode: keyword.profile.currency_code,
    })

    return (
        <DetailWrapper
            leftContent={<DetailTag text="Bid" />}
            rightContent={<DetailText text={bid} />}
        />
    )
}

export function keywordMatchTypeRenderer(keyword: Keyword): ReactElement {
    const text = `${titleCase(keyword.match_type)} Match`
    return (
        <DetailWrapper
            leftContent={<DetailIcon icon={KeywordIcon} />}
            rightContent={<DetailText text={text} />}
        />
    )
}
