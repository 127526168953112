import { createAction } from 'redux-actions'

const NAMESPACE = 'DASHBOARD_PRINT_PAGE'

// fetch dashboard
export const fetchDashboardSuccess = createAction(
    `${NAMESPACE}/FETCH_DASHBOARD_SUCCESS`
)

// fetch organization
export const fetchOrganizationSuccess = createAction(
    `${NAMESPACE}/FETCH_ORGANIZATION_SUCCESS`
)
