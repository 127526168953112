import { ReactElement } from 'react'

import { Tooltip } from 'antd'
import classNames from 'classnames'
import produce from 'immer'
import update from 'lodash/update'
import { useTranslation } from 'react-i18next'

import { ReactComponent as CircleArrowSvg } from 'images/circle-arrow.svg'
import { Field, ChildField } from 'types'

import styles from './styles.scss'

interface Props<RecordType> {
    updateColumns: (columns: Field<RecordType>[]) => void
    columns: Field<RecordType>[]
    parentId: string
    isCollapsed: boolean
}

function ToggleMetricDeltasTitle<RecordType>({
    updateColumns,
    columns,
    parentId,
    isCollapsed,
}: Props<RecordType>): ReactElement {
    const { t } = useTranslation('table')
    const parentIndex = columns.findIndex((col) => col.id === parentId)

    const toggleChildrenColumnsVisible = (): void => {
        updateColumns(
            produce(columns, (draft) => {
                update(
                    draft,
                    [parentIndex, 'childrenFields'],
                    (childCols: ChildField<RecordType>[]) =>
                        childCols.map((child) => ({
                            ...child,
                            ...{ isVisible: !child.isVisible },
                        }))
                )
            })
        )
    }

    return (
        <Tooltip
            title={
                isCollapsed
                    ? t(
                          'table:PeriodDeltas.tooltipTitle.show',
                          'Show Change Columns'
                      )
                    : t(
                          'table:PeriodDeltas.tooltipTitle.hide',
                          'Hide Change Columns'
                      )
            }
            arrow={{ pointAtCenter: true }}
            placement="top"
        >
            <CircleArrowSvg
                className={classNames(styles['toggle-icon'], {
                    [styles.collapsed]: isCollapsed,
                })}
                onClick={toggleChildrenColumnsVisible}
            />
        </Tooltip>
    )
}

export default ToggleMetricDeltasTitle
