import get from 'lodash/get'
import createCachedSelector from 're-reselect'

import { RootReduxState, CacheState } from 'types'

const selectStateCache = (state: RootReduxState): CacheState => state.cache

export const selectCache = createCachedSelector(
    selectStateCache,
    (_state: RootReduxState, path: string[]) => path,
    (cacheState, path) => {
        return get(cacheState, ['entry', ...path])
    }
)((_state, path) => JSON.stringify(path))
