import { DependencyList, useRef } from 'react'

import isEqual from 'lodash/isEqual'
import isObject from 'lodash/isObject'

function isPrimitive(val: any): boolean {
    return !isObject(val)
}

export function checkDeps(deps: DependencyList): void {
    if (!deps || !deps.length) {
        throw new Error(
            'useDeepCompareEffect should not be used with no dependencies. Use React.useEffect instead.'
        )
    }
    if (deps.every(isPrimitive)) {
        throw new Error(
            'useDeepCompareEffect should not be used with dependencies that are all primitive values. Use React.useEffect instead.'
        )
    }
}

export function useDeepCompareMemoize(value: unknown): any {
    const ref = useRef<any>()

    if (!isEqual(value, ref.current)) {
        ref.current = value
    }

    return ref.current
}

function getCssStyle(property: string): string {
    return window
        .getComputedStyle(document.body, null)
        .getPropertyValue(property)
}

function getCanvasFont(): string {
    const fontWeight = getCssStyle('font-weight')
    const fontSize = getCssStyle('font-size')
    const fontFamily = getCssStyle('font-family')

    return `${fontWeight} ${fontSize} ${fontFamily}`
}

/**
 * Dynamically computes the width a given text occupies
 * on the user screen by leveraging HTML5 and Canvas.
 * @param text the string you want to get the width of.
 * @return 0 if the string is empty or a Canvas could not
 * be created for any reason; the text width otherwise.
 */
export function useTextWidth(text: string): number {
    if (!text) {
        return 0
    }

    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    if (!context) {
        return 0
    }

    context.font = getCanvasFont()
    return context.measureText(text).width
}
