import { call } from 'redux-saga/effects'

import { reloadCurrentDataSaga } from '../../app/workers'

export function* resetFiltersWorker() {
    yield call(reloadCurrentDataSaga)
}

export function* updateLocalFilterWorker() {
    yield call(reloadCurrentDataSaga)
}

export function* updateFilterWorker() {
    // Reload
    yield call(reloadCurrentDataSaga)
}

export function* updateFilterSettingsWorker() {
    yield call(reloadCurrentDataSaga)
}
