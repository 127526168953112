import { dateCreated } from 'configuration/fieldCreators/date'
import {
    deleteOrganizationGroup,
    organizationGroupAdsDataAccess,
    organizationGroupDspDataAccess,
    organizationGroupMemberCount,
    organizationGroupName,
    organizationGroupSovDataAccess,
    organizationGroupType,
} from 'configuration/fieldCreators/organizationGroup'
import { Field, OrganizationGroup } from 'types'

export const organizationGroupTableColumnsConfig: Field<OrganizationGroup>[] = [
    organizationGroupName({ fixed: 'left' }),
    organizationGroupType(),
    organizationGroupAdsDataAccess(),
    organizationGroupDspDataAccess(),
    organizationGroupSovDataAccess(),
    organizationGroupMemberCount(),
    dateCreated({ dataIndex: ['created_date'] }),
    deleteOrganizationGroup(),
]
