import { handleActions } from 'redux-actions'

import { DATES } from 'const/filters'
import { LOCAL_FILTERS, FILTER_SETTINGS } from 'const/reducerKeys'
import { defaultPage } from 'reducers/ui/defaults'

const defaultState = {
    ...defaultPage,
    [LOCAL_FILTERS]: {},
    [FILTER_SETTINGS]: {
        anchored: [DATES],
        order: [],
        displayState: {},
    },
}

export default handleActions({}, defaultState)
