export const CEREBRO_ALERT = 'cerebro_alert'
export const INCREASE_BIDS = 'increase_bids'
export const DECREASE_BIDS = 'decrease_bids'
export const INCREASE_BIDS_BY_VALUE = 'increase_bids_by_value'
export const DECREASE_BIDS_BY_VALUE = 'decrease_bids_by_value'
export const SET_BIDS = 'set_bids'
export const INCREASE_BUDGETS = 'increase_budgets'
export const DECREASE_BUDGETS = 'decrease_budgets'
export const INCREASE_BUDGETS_BY_VALUE = 'increase_budgets_by_value'
export const DECREASE_BUDGETS_BY_VALUE = 'decrease_budgets_by_value'
export const SET_BUDGETS = 'set_budgets'
export const PAUSE_PRODUCT_ADS_BASED_ON_ASIN = 'pause_product_ads_based_on_asin'
export const PAUSE_RESOURCE = 'pause_resource'
export const ENABLE_PRODUCT_ADS_BASED_ON_ASIN =
    'enable_product_ads_based_on_asin'
export const ENABLE_RESOURCE = 'enable_resource'
export const DO_NOTHING = 'do_nothing'
export const ADD_LABELS = 'add_labels'
export const REMOVE_LABELS = 'remove_labels'
export const REPLACE_LABELS = 'replace_labels'
