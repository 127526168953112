import { memo, ReactElement } from 'react'

import isEqual from 'lodash/isEqual'

import { LABEL_PAGE } from 'const/pages'
import { userHasManageLabelsPermissions } from 'helpers/featurePermissions'
import { useUserHasPermissions, useResourceOfPage } from 'hooks'
import { updateProducts } from 'services/cerebroApi/orgScope/resourceApi'
import { Label, ProductFactRecord } from 'types'

import { DeleteField } from '../SharedFields/DeleteField'

interface Props<RecordType> {
    readOnly: boolean
    record: RecordType
    reloadData: () => void
}

function RemoveProductFromLabelField<RecordType extends ProductFactRecord>({
    readOnly = false,
    record,
    reloadData,
}: Props<RecordType>): ReactElement {
    const hasLabelPermissions = useUserHasPermissions(
        userHasManageLabelsPermissions
    )

    const label: Label | null = useResourceOfPage(LABEL_PAGE)

    const getIsDisabled = (): boolean => {
        return !hasLabelPermissions || readOnly
    }

    return (
        <DeleteField
            disabled={getIsDisabled()}
            okText="Remove"
            title={`Are you sure you want to remove this product from ${label?.name}?`}
            updateRequestApi={updateProducts}
            updateRequestCallback={reloadData}
            updateRequestData={{
                items: [
                    {
                        id: `${record.profile__country_code}_${record.product_ad__asin}`,
                        remove_label_ids: [label?.id],
                    },
                ],
            }}
            updateRequestSuccessMesg="Product successfully removed"
        />
    )
}

export default memo(
    RemoveProductFromLabelField,
    isEqual
) as typeof RemoveProductFromLabelField
