import { memo, ReactElement } from 'react'

import isEqual from 'lodash/isEqual'
import noop from 'lodash/noop'

import { useResource } from 'components/EditableFields/localHooks'
import { updateTarget } from 'services/cerebroApi/orgScope/resourceApi'
import { NonKeywordTargetFact, SuggestedBid, ProfileGroupBy } from 'types'

import { SuggestedBidField } from '../../SharedFields/SuggestedBidField'

type TargetRecord = NonKeywordTargetFact<ProfileGroupBy>

interface SerializedSuggestedBidParams {
    id: string
    bid: number
}

interface Props<RecordType> {
    dataIndex: string[]
    readOnly: boolean
    record: RecordType
    reloadData: () => void
    rowIndex: number
    updateRecord: (data: { rowIndex: number; record: RecordType }) => void
    value: SuggestedBid
}

function TargetSuggestedBidField<RecordType extends TargetRecord>({
    dataIndex = ['target', 'suggestedBid'],
    readOnly = false,
    reloadData = noop,
    rowIndex,
    updateRecord = noop,
    record,
    value,
}: Props<RecordType>): ReactElement {
    const { getFromResource, resourceId } = useResource<RecordType>(
        record,
        dataIndex,
        'target'
    )

    const currencyCode = record.profile.currency_code ?? 'USD'

    const getIsDisabled = (): boolean => {
        const userCanManageTarget = getFromResource(['user_can_manage'], true)
        return !userCanManageTarget
    }

    const serializeFieldValues = (params: {
        bid: number
    }): SerializedSuggestedBidParams => ({
        ...params,
        id: resourceId,
    })

    return (
        <SuggestedBidField
            currencyCode={currencyCode}
            disabled={getIsDisabled()}
            fieldName="target"
            readOnly={readOnly}
            record={record}
            reloadData={reloadData}
            rowIndex={rowIndex}
            serializeFieldValues={serializeFieldValues}
            updateRecord={updateRecord}
            updateRequestApi={updateTarget}
            updateRequestData={resourceId}
            updateRequestSuccessMesg="Successfully updated target bid"
            value={value}
        />
    )
}

export default memo(
    TargetSuggestedBidField,
    isEqual
) as typeof TargetSuggestedBidField
