import { ReactElement } from 'react'

import { Button } from 'antd'
import { Formik, FormikHelpers, FieldArray } from 'formik'
import isNull from 'lodash/isNull'
import { array, object, string } from 'yup'

import { ProductAdFactRecord } from 'configuration/tables'
import { SELLER_BRAND_TYPE } from 'const/brands'
import { PAUSED, ENABLED } from 'const/resourceStates'
import { useCerebroApiRequest } from 'hooks'
import { createProductAd } from 'services/cerebroApi/orgScope/resourceApi'
import { Campaign, ProductAd } from 'types'
import message from 'utilities/message'

import CreateProductAdForm from './CreateProductAdForm'
import { useValidator } from './localHooks'
import { FormValues, ProductAdField } from './localTypes'
import CreateResourceModal from '../CreateResourceModal/CreateResourceModal'
import { BulkAddTextArea, Item } from '../Shared'

interface Props {
    campaign: Campaign | null
    campaignProducts: ProductAdFactRecord[]
    isModalVisible: boolean
    onModalCancel: () => void
    toggleModalVisible: () => void
    reloadData: () => void
}

function CreateProductAdModal({
    campaign,
    campaignProducts = [],
    isModalVisible,
    onModalCancel,
    reloadData,
    toggleModalVisible,
}: Props): ReactElement {
    const makeCerebroApiRequest = useCerebroApiRequest()
    const [validator, errorMsg] = useValidator(
        campaign as Campaign,
        campaignProducts
    )
    const isSellerBrandType = campaign?.profile.type === SELLER_BRAND_TYPE

    const closeModal = (callback: any): void => {
        callback()
        toggleModalVisible()
        reloadData()
    }

    const submitForm = async (
        { products }: FormValues,
        { resetForm }: FormikHelpers<FormValues>
    ): Promise<void> => {
        const promises = products.map(async (product) =>
            makeCerebroApiRequest<ProductAd>({
                request: createProductAd({
                    ...(isSellerBrandType
                        ? { sku: product.asin }
                        : { asin: product.asin }),
                    ad_group_id: product.adGroupId,
                    state: product.state,
                    campaign_id: campaign?.id,
                }),
            })
        )

        const responses = await Promise.all(promises)

        if (responses.every((response) => response !== null)) {
            message.success(
                `Successfully created ${products.length} product ads on campaign "${campaign?.name}"`
            )
            closeModal(resetForm)
        } else if (responses.some((response) => !isNull(response))) {
            closeModal(resetForm)
        }
    }

    const initialValues: FormValues = { products: [] }

    const validationSchema = object().shape({
        products: array().of(
            object().shape({
                // static values
                asin: string().required(),

                // form values
                // form values
                adGroupId: string().label('Ad Group Target').required(),
                state: string()
                    .label('State')
                    .matches(new RegExp(`${PAUSED}|${ENABLED}`))
                    .required(),
            })
        ),
    })

    const deserializeFieldArrayValue = (value: Item): ProductAdField => {
        return {
            asin: value.product as string,
            adGroupId: campaign?.ad_groups[0]?.id ?? '',
            state: ENABLED,
        }
    }

    const handleFieldArrayPush = (
        value: Item,
        push: (obj: any) => void
    ): void => {
        const product = deserializeFieldArrayValue(value)
        push(product)
    }

    const bulkAddLabel = isSellerBrandType ? 'SKU' : 'ASIN'

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={submitForm}
            validationSchema={validationSchema}
        >
            {({
                isSubmitting,
                resetForm,
                handleSubmit,
                values,
                setFieldValue,
                dirty,
                isValid,
            }) => (
                <CreateResourceModal
                    open={isModalVisible}
                    title={<div>Add {bulkAddLabel}s</div>}
                    onCancel={onModalCancel}
                    width={900}
                    footer={[
                        <Button
                            key={1}
                            className="mr-2"
                            onClick={() => {
                                resetForm()
                                toggleModalVisible()
                            }}
                            disabled={isSubmitting}
                        >
                            Cancel
                        </Button>,
                        <Button
                            key={2}
                            type="primary"
                            onClick={() => handleSubmit()}
                            loading={isSubmitting}
                            disabled={!dirty || !isValid}
                        >
                            Create {bulkAddLabel}s
                        </Button>,
                    ]}
                >
                    <FieldArray
                        name="products"
                        render={(arrayHelpers) => (
                            <div className="d-flex flex-column">
                                <BulkAddTextArea
                                    errorMsg={errorMsg}
                                    label={`Product Ad ${bulkAddLabel}s`}
                                    loading={false}
                                    push={(item) => {
                                        handleFieldArrayPush(
                                            item,
                                            arrayHelpers.push
                                        )
                                    }}
                                    type="product"
                                    validator={validator}
                                />
                                <CreateProductAdForm
                                    campaign={campaign as Campaign}
                                    products={values.products}
                                    remove={arrayHelpers.remove}
                                    setFieldValue={setFieldValue}
                                />
                            </div>
                        )}
                    />
                </CreateResourceModal>
            )}
        </Formik>
    )
}

export default CreateProductAdModal
