import { ReactElement } from 'react'

import { Select } from 'antd'
import { Field } from 'formik'

import { FormikSelect } from 'components/formik'
import { WalmartCampaign } from 'types'

import { useFetchWalmartAdGroups } from './localHooks'

interface Props {
    name: string
    campaign: WalmartCampaign
}

const AdGroupSelectFormikField = ({ name, campaign }: Props): ReactElement => {
    const [isFetching, options] = useFetchWalmartAdGroups(campaign, name, true)

    return (
        <Field
            name={name}
            component={FormikSelect}
            placeholder="Select Target Ad Group"
            loading={isFetching}
            disabled={isFetching}
            style={{ width: '100%', maxWidth: 200 }}
            defaultActiveFirstOption
        >
            {options.map(({ value, label }) => (
                <Select.Option key={value} value={value}>
                    {label}
                </Select.Option>
            ))}
        </Field>
    )
}

export default AdGroupSelectFormikField
