import { createAction } from 'redux-actions'

const NAMESPACE = 'DASHBOARD_PAGE'

// export dashbaord
export const exportToPdfRequest = createAction(
    `${NAMESPACE}/EXPORT_TO_PDF_REQUEST`
)
export const exportToPdfSuccess = createAction(
    `${NAMESPACE}/EXPORT_TO_PDF_SUCCESS`
)

// add widget
export const addWidget = createAction(`${NAMESPACE}/ADD_WIDGET`)

// delete widget
export const deleteWidget = createAction(`${NAMESPACE}/DELETE_WIDGET`)

// update widget
export const updateWidget = createAction(`${NAMESPACE}/UPDATE_WIDGET`)

// clone widget
export const cloneWidget = createAction(`${NAMESPACE}/CLONE_WIDGET`)

// toggle widget form modal
export const toggleWidgetModal = createAction(
    `${NAMESPACE}/TOGGLE_WIDGET_MODAL`
)

// update tabs
export const updateTabs = createAction(`${NAMESPACE}/UPDATE_TABS`)

// save dashboard
export const saveDashboardRequest = createAction(
    `${NAMESPACE}/SAVE_DASHBOARD_REQUEST`
)
export const saveDashboardSuccess = createAction(
    `${NAMESPACE}/SAVE_DASHBOARD_SUCCESS`
)
export const saveDashboardFailure = createAction(
    `${NAMESPACE}/SAVE_DASHBOARD_FAILURE`
)

// cancel dashboard changes
export const cancelDashboardChanges = createAction(
    `${NAMESPACE}/CANCEL_DASHBOARD_CHANGES`
)

// handle dashboard field change
export const handleFieldsChange = createAction(
    `${NAMESPACE}/HANDLE_FIELDS_CHANGE`
)

// change layout
export const changeLayout = createAction(`${NAMESPACE}/CHANGE_LAYOUT`)

// scheduled exports
export const createScheduledExportRequest = createAction(
    `${NAMESPACE}/CREATE_SCHEDULED_EXPORT_REQUEST`
)
export const updateScheduledExportRequest = createAction(
    `${NAMESPACE}/UPDATE_SCHEDULED_EXPORT_REQUEST`
)
export const deleteScheduledExportRequest = createAction(
    `${NAMESPACE}/DELETE_SCHEDULED_EXPORT_REQUEST`
)
export const fetchScheduledExportsSuccess = createAction(
    `${NAMESPACE}/FETCH_SCHEDULED_EXPORTS_SUCCESS`
)

export const shareDashboardRequest = createAction(
    `${NAMESPACE}/SHARE_DASHBOARD_REQUEST`
)

export const shareDashboardSuccess = createAction(
    `${NAMESPACE}/SHARE_DASHBOARD_SUCCESS`
)

export const shareDashboardFailure = createAction(
    `${NAMESPACE}/SHARE_DASHBOARD_FAILURE`
)

// currency settings
export const changeDashboardCurrencyCode = createAction(
    `${NAMESPACE}/CHANGE_DASHBOARD_CURRENCY_CODE`
)
