import { memo, ReactElement } from 'react'

import isEqual from 'lodash/isEqual'
import { useTranslation } from 'react-i18next'

import {
    WALMART_KEYWORD_BID_MIN,
    WALMART_KEYWORD_BID_MAX,
    WALMART_KEYWORD_BID_STEP,
    WALMART_KEYWORD_BID_PRECISION,
} from 'const/walmart'
import { userHasWalmartAdvertisingWritePermissions } from 'helpers/featurePermissions'
import { useUserHasPermissions } from 'hooks'
import { updateWalmartKeyword } from 'services/cerebroApi/orgScope/walmartApi'
import { WalmartKeyword } from 'types'

import { BidField } from '../../SharedFields'

interface Props {
    keyword: WalmartKeyword
}

function WalmartKeywordBidField({ keyword }: Props): ReactElement {
    const { t } = useTranslation('table')
    const hasWalmartPermissions = useUserHasPermissions(
        userHasWalmartAdvertisingWritePermissions
    )

    return (
        <BidField<WalmartKeyword>
            bidMax={WALMART_KEYWORD_BID_MAX}
            bidMin={WALMART_KEYWORD_BID_MIN}
            bidPrecision={WALMART_KEYWORD_BID_PRECISION}
            bidStep={WALMART_KEYWORD_BID_STEP}
            currencyCode="USD"
            disabled={!keyword.user_can_manage || !hasWalmartPermissions}
            fieldName="bid"
            fieldPath={['bid']}
            readOnly={!keyword.user_can_manage}
            record={keyword}
            updateRequestApi={updateWalmartKeyword}
            updateRequestData={keyword.id}
            updateRequestSuccessMesg={t(
                'table:fields.walmartKeyword.update.bidUpdateSuccessMsg',
                'Successfully updated keyword bid'
            )}
        />
    )
}

export default memo(
    WalmartKeywordBidField,
    isEqual
) as typeof WalmartKeywordBidField
