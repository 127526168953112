import { connect } from 'react-redux'

import { makeUpdateFilter } from 'actions/ui/shared/filter'
import { DASHBOARD_PAGE } from 'const/pages'
import { selectDomainValue as selectAuthDomainValue } from 'selectors/auth'
import { selectOrganizationMembers } from 'selectors/orgs'
import { selectDashboardOfPage, selectDomainValue } from 'selectors/ui'

import ShareButton from './ShareButton'

const mapStateToProps = (state) => ({
    dashboard: selectDashboardOfPage(state, DASHBOARD_PAGE),
    orgMembers: selectOrganizationMembers(
        state,
        selectAuthDomainValue(state, ['organizationId'])
    ),
    userEmail: selectAuthDomainValue(state, ['email']),
    isDirty: selectDomainValue(state, [DASHBOARD_PAGE, 'isDirty']),
})

const mapDispatchToProps = {
    updateFilter: makeUpdateFilter([DASHBOARD_PAGE]),
}

export default connect(mapStateToProps, mapDispatchToProps)(ShareButton)
