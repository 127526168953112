import { ReactElement } from 'react'

import { Translation } from 'react-i18next'

import { CampaignIcon } from 'components/Icons'
import { StatusIndicator } from 'components/StatusIndicator'
import { ENABLED } from 'const/resourceStates'
import { formatCurrency, titleCase } from 'helpers/formatting'
import { AdGroup, CurrencyCode } from 'types'

import { DetailIcon, DetailTag, DetailText, DetailWrapper } from './shared'

export function adGroupStatusRenderer(adGroup: AdGroup): ReactElement {
    return (
        <StatusIndicator
            enabled={adGroup.state === ENABLED}
            text={titleCase(adGroup.state)}
        />
    )
}

export function adGroupCampaignNameRenderer(adGroup: AdGroup): ReactElement {
    return (
        <DetailWrapper
            leftContent={
                <DetailIcon
                    icon={CampaignIcon}
                    tooltipTitle={
                        <Translation>
                            {(t) =>
                                t('campaigns:campaignName', 'Campaign Name')
                            }
                        </Translation>
                    }
                />
            }
            rightContent={<DetailText text={adGroup.campaign.name} />}
        />
    )
}

export function adGroupBidRenderer(
    bid: number,
    currencyCode: CurrencyCode,
    label: string
): ReactElement {
    const text = formatCurrency(bid, {
        currencyCode,
        decimal: true,
    })
    return (
        <DetailWrapper
            leftContent={<DetailTag text={label} />}
            rightContent={<DetailText text={text} />}
        />
    )
}
