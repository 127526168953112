import { createAction } from 'redux-actions'

import { curryActionForPath } from 'helpers/curry'

// Filter Settings
export const fetchFilterSettingsSuccess = createAction(
    'FETCH_FILTER_SETTINGS_SUCCESS'
)
export const updateFilterSettings = createAction('UPDATE_FILTER_SETTINGS')

export const makeUpdateFilterSettings = curryActionForPath(updateFilterSettings)

// Filters
export const fetchFiltersSuccess = createAction('FETCH_FILTERS_SUCCESS')
export const updateLocalFilter = createAction('UPDATE_LOCAL_FILTER')
export const updateFilter = createAction('UPDATE_FILTER')
export const resetFilters = createAction('RESET_FILTERS')
export const resetLocalFilters = createAction('RESET_LOCAL_FILTERS')

export const makeUpdateLocalFilter = curryActionForPath(updateLocalFilter)
export const makeUpdateFilter = curryActionForPath(updateFilter)
export const makeResetFilters = curryActionForPath(resetFilters)
export const makeResetLocalFilters = curryActionForPath(resetLocalFilters)
