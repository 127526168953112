import { all, call, select, put } from 'redux-saga/effects'

import { fetchPortfoliosSuccess } from 'actions/ui/campaignPage/page'
import {
    makeFetchTableRequest,
    makeFetchResourceSuccess,
    makeUpdateResourceSuccess,
} from 'actions/ui/shared'
import { CAMPAIGN_PAGE } from 'const/pages'
import { cerebroApiSaga } from 'sagas/common'
import uiSagaRegistry from 'sagas/ui/registry'
import { selectResourceParamsOfPage, selectResourceOfPage } from 'selectors/ui'
import {
    getCampaign,
    getPortfolios,
    updateCampaign,
} from 'services/cerebroApi/orgScope/resourceApi'

import { fetchTasksSaga } from './automation/workers'

function* fetchPortfoliosSaga(campaign) {
    yield call(cerebroApiSaga, fetchPortfoliosSuccess, getPortfolios, {
        limit: 1000,
        profile: campaign.profile_id,
    })
}

function* fetchCampaignSaga() {
    const { campaignId } = yield select(
        selectResourceParamsOfPage,
        CAMPAIGN_PAGE
    )

    yield call(
        cerebroApiSaga,
        makeFetchResourceSuccess(CAMPAIGN_PAGE),
        getCampaign,
        campaignId
    )
}

function* updateCampaignSaga({ campaignId }, data) {
    yield call(
        cerebroApiSaga,
        makeUpdateResourceSuccess(CAMPAIGN_PAGE),
        updateCampaign,
        campaignId,
        data
    )

    // update keywords table when campaign is updated
    // required because dayparting updates impact table data
    yield put(makeFetchTableRequest([CAMPAIGN_PAGE, 'keywords', 'table'])())
}

/**
 * Mounts the Campaign Page and fetches data
 */
function* mountPageSaga() {
    yield call(fetchCampaignSaga)

    const campaign = yield select(selectResourceOfPage, CAMPAIGN_PAGE)

    if (campaign) {
        const { campaignId } = yield select(
            selectResourceParamsOfPage,
            CAMPAIGN_PAGE
        )
        yield all([
            call(fetchTasksSaga, campaignId),
            call(fetchPortfoliosSaga, campaign),
        ])
    }
}

uiSagaRegistry.registerSagas([CAMPAIGN_PAGE], {
    mountPageSaga,
    updateResourceSaga: updateCampaignSaga,
    addLabelsSaga: updateCampaignSaga,
    removeLabelSaga: updateCampaignSaga,
})
