import { combineChildrenReducers } from 'utilities/reducers'

import advertisersReducer from './advertisers'
import lineItemsReducer from './lineItems'
import ordersReducer from './orders'
import pageReducer from './page'

export default combineChildrenReducers(pageReducer, {
    advertisers: advertisersReducer,
    orders: ordersReducer,
    lineItems: lineItemsReducer,
})
