import { TFunction } from 'i18next'
import { Schema, number, object, string } from 'yup'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const getBaseSchema = (t: TFunction) =>
    object().shape({
        name: string().required(
            t(
                'campaigns:createAdGroup.nameRequiredWarning',
                'Ad group name is required'
            )
        ),
        default_bid: number().required(
            t(
                'campaigns:AmazonCampaignCreation.createAdGroup.defaultBidRequiredWarning',
                'Default Bid is required'
            )
        ),
    })

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getEditionShapeValidator = (t: TFunction) => getBaseSchema(t)

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getCreationShapeValidator = (t: TFunction) => {
    const baseSchema = getBaseSchema(t)

    return baseSchema.shape({
        name: (baseSchema.fields.name as Schema).clone().nullable(),
    })
}
