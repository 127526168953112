import { createAction } from 'redux-actions'

import { curryActionForPage } from 'helpers/curry'

// Add
export const addLabelsRequest = createAction('ADD_LABELS_REQUEST')
export const addLabelsSuccess = createAction('ADD_LABELS_SUCCESS')
export const addLabelsFailure = createAction('ADD_LABELS_FAILURE')

export const makeAddLabelsSuccess = curryActionForPage(addLabelsSuccess)
export const makeAddLabelsFailure = curryActionForPage(addLabelsFailure)

// Remove
export const removeLabelRequest = createAction('REMOVE_LABEL_REQUEST')
