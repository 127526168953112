import {
    DAY_FORMAT,
    HOUR_FORMAT,
    MONTH_FORMAT,
    UNDEFINED_VALUE,
    YEAR_FORMAT,
} from 'const/formatting'
import { formatJSONDateTime } from 'helpers/formatting'
import moment from 'utilities/moment'

export type DateAggregation =
    | 'hour'
    | 'day'
    | 'week'
    | 'month'
    | 'year'
    | 'quarter'

const formatByAggregation: Record<DateAggregation, string> = {
    hour: HOUR_FORMAT,
    day: DAY_FORMAT,
    week: DAY_FORMAT,
    month: MONTH_FORMAT,
    quarter: YEAR_FORMAT,
    year: YEAR_FORMAT,
}

export const formatDate = (
    value: moment.MomentInput,
    aggregation: DateAggregation | null = null,
    customFormat: string = DAY_FORMAT,
    formatAsWeek: boolean = false
): string => {
    if (!value) {
        return UNDEFINED_VALUE
    }

    const format = aggregation ? formatByAggregation[aggregation] : customFormat

    if (aggregation === 'week' || formatAsWeek) {
        const weekStart = moment(value)
        const weekEnd = weekStart.clone().add(6, 'days')
        return `${weekStart.format(format)} – ${weekEnd.format(format)}`
    }

    if (aggregation === 'quarter') {
        const quarter = Math.floor(moment(value).month() / 3) + 1
        return `Q${quarter} ${moment(value).format(format)}`
    }

    return moment(value).format(format)
}

export const PACIFIC_TIMEZONE = 'America/Los_Angeles'
export const UTC = 'UTC'

export const moveDateBetweenTimeZones = (
    value: string,
    originalTZ: string,
    targetTZ: string
): string =>
    formatJSONDateTime(moment.tz(value, originalTZ).clone().tz(targetTZ))
