import { all, takeEvery } from 'redux-saga/effects'

import { createAmazonAdvertisingIntegration } from 'actions/ui/organizationPage'

import { createIntegrationWorker } from './workers'

export default function* integrationsTabWatcher() {
    yield all([
        takeEvery(
            createAmazonAdvertisingIntegration.toString(),
            createIntegrationWorker
        ),
    ])
}
