import { handleActions } from 'redux-actions'

import { campaignsSummaryTableColumnsConfig } from 'configuration/tables'
import {
    ADVANCED_AUTOMATIONS,
    ADVANCED_TEXT,
    AUTOMATION_PROFILES,
    BID_MULTIPLIERS,
    BRANDS,
    CAMPAIGN_AD_FORMATS,
    CAMPAIGN_NAME,
    CAMPAIGN_START_DATE,
    CAMPAIGN_STATES,
    CAMPAIGN_TARGETING_TYPES,
    CAMPAIGNS,
    COUNTRIES,
    DATES,
    FACT_TYPES,
    LABELS,
    METRIC_FILTERS,
    PORTFOLIOS,
    REGIONS,
} from 'const/filters'
import { FILTER_SETTINGS, FILTERS } from 'const/reducerKeys'
import { serializeColumns } from 'helpers/tables'
import { getDefaultTable } from 'reducers/ui/defaults'

import { defaultPage } from '../defaults'

const defaultState = {
    ...defaultPage,
    [FILTERS]: {
        [CAMPAIGN_NAME]: null,
        [FACT_TYPES]: [],
        [REGIONS]: [],
        [COUNTRIES]: [],
        [BRANDS]: [],
        [PORTFOLIOS]: [],
        [CAMPAIGNS]: [],
        [CAMPAIGN_STATES]: [],
        [CAMPAIGN_START_DATE]: [],
        [CAMPAIGN_TARGETING_TYPES]: [],
        [CAMPAIGN_AD_FORMATS]: [],
        [ADVANCED_AUTOMATIONS]: [],
        [AUTOMATION_PROFILES]: [],
        [ADVANCED_TEXT]: [],
        [LABELS]: [],
        [METRIC_FILTERS]: [],
        [BID_MULTIPLIERS]: [],
    },
    [FILTER_SETTINGS]: {
        anchored: [CAMPAIGN_NAME, DATES, FACT_TYPES],
        order: [
            METRIC_FILTERS,
            REGIONS,
            COUNTRIES,
            BRANDS,
            PORTFOLIOS,
            CAMPAIGNS,
            CAMPAIGN_START_DATE,
            CAMPAIGN_STATES,
            CAMPAIGN_TARGETING_TYPES,
            CAMPAIGN_AD_FORMATS,
            ADVANCED_AUTOMATIONS,
            AUTOMATION_PROFILES,
            ADVANCED_TEXT,
            LABELS,
            BID_MULTIPLIERS,
        ],
        displayState: {
            [METRIC_FILTERS]: true,
            [REGIONS]: true,
            [COUNTRIES]: true,
            [BRANDS]: true,
            [PORTFOLIOS]: true,
            [CAMPAIGNS]: true,
            [CAMPAIGN_START_DATE]: true,
            [CAMPAIGN_STATES]: true,
            [CAMPAIGN_TARGETING_TYPES]: true,
            [ADVANCED_AUTOMATIONS]: true,
            [AUTOMATION_PROFILES]: true,
            [ADVANCED_TEXT]: true,
            [LABELS]: true,
            [BID_MULTIPLIERS]: true,
        },
    },
    table: getDefaultTable(
        serializeColumns(campaignsSummaryTableColumnsConfig),
        {
            showTotalRow: true,
        }
    ),
}

export default handleActions({}, defaultState)
