import { denormalize } from 'normalizr'
import createCachedSelector from 're-reselect'

import { tabSchema, tabListSchema } from 'schemas'
import { RootReduxState, PageTabs, PageWidgets, Widget } from 'types'

import { selectDomainValue } from './ui'

const selectTabs = (state: RootReduxState, pageName: string): PageTabs =>
    selectDomainValue(state, [pageName, 'tabs'])

const selectWidgets = (state: RootReduxState, pageName: string): PageWidgets =>
    selectDomainValue(state, [pageName, 'widgets'])

export const selectTabById = createCachedSelector(
    selectTabs,
    (_state, _pageName, tabId) => tabId,
    (tabs, tabId) => tabs[tabId]
)((_state, pageName, tabId) => `${pageName}-${tabId}`)

export const selectDenormalizedTabById = createCachedSelector(
    selectWidgets,
    selectTabById,
    (widgets, tab) => (tab ? denormalize(tab, tabSchema, { widgets }) : null)
)((_state, pageName, tabId) => `${pageName}-${tabId}`)

export const selectDenormalizedTabs = createCachedSelector(
    selectTabs,
    selectWidgets,
    (tabs, widgets) =>
        denormalize(Object.keys(tabs), tabListSchema, { tabs, widgets })
)((_state, pageName) => pageName)

export const selectCountOfWidgets = createCachedSelector(
    selectWidgets,
    (widgets) => (widgets ? Object.keys(widgets).length : 0)
)((_state, pageName) => pageName)

export const selectWidgetTab = (
    state: RootReduxState,
    pageName: string,
    widgetId: string
):
    | {
          widgets: Widget[]
          [key: string]: any
      }
    | undefined => {
    const tabs = selectTabs(state, pageName)
    if (!tabs) {
        return undefined
    }
    const tabId = Object.keys(tabs).find((key) => {
        return tabs[key].widgets.includes(widgetId)
    })

    return selectDenormalizedTabById(state, pageName, tabId)
}
