import {
    FOLD_AND_AREA_WEIGHTED,
    NON_WEIGHTED,
    POSITION_WEIGHTED,
    SovWeight,
} from 'const/sovWeights'

export const SOV_WEIGHTS_MAP: Record<
    SovWeight,
    { label: string; metric: string; pct_metric: string }
> = {
    [NON_WEIGHTED]: {
        label: 'Non Weighted',
        metric: `${NON_WEIGHTED}`,
        pct_metric: `${NON_WEIGHTED}__pct`,
    },
    [FOLD_AND_AREA_WEIGHTED]: {
        label: 'Fold and Area Weighted',
        metric: `${FOLD_AND_AREA_WEIGHTED}__sum`,
        pct_metric: `${FOLD_AND_AREA_WEIGHTED}__pct`,
    },
    [POSITION_WEIGHTED]: {
        label: 'Position Weighted',
        metric: `${POSITION_WEIGHTED}__sum`,
        pct_metric: `${POSITION_WEIGHTED}__pct`,
    },
}
