import { handleActions } from 'redux-actions'

import { campaignTargetsTableColumnsConfig } from 'configuration/tables'
import {
    DATES,
    TARGET_STATES,
    METRIC_FILTERS,
    AD_GROUPS,
    AD_GROUP_STATES,
} from 'const/filters'
import { FILTER_SETTINGS, LOCAL_FILTERS } from 'const/reducerKeys'
import { serializeColumns } from 'helpers/tables'
import { defaultTab, getDefaultTable } from 'reducers/ui/defaults'

const initialState = {
    ...defaultTab,
    [LOCAL_FILTERS]: {
        [AD_GROUPS]: [],
        [AD_GROUP_STATES]: [],
        [TARGET_STATES]: [],
        [METRIC_FILTERS]: [],
    },
    [FILTER_SETTINGS]: {
        anchored: [DATES, AD_GROUPS, AD_GROUP_STATES],
        order: [TARGET_STATES, METRIC_FILTERS],
        displayState: {
            [TARGET_STATES]: true,
            [METRIC_FILTERS]: true,
        },
    },
    table: getDefaultTable(serializeColumns(campaignTargetsTableColumnsConfig)),
}

export default handleActions({}, initialState)
