import { SVGAttributes, ReactElement } from 'react'

type Props = SVGAttributes<SVGElement>

function SvgTrendUpIcon(props: Props): ReactElement {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M1.1616 14.6718C1.44055 14.9507 1.89281 14.9507 2.17175 14.6718L6.22895 10.6146C6.40229 10.4412 6.50231 10.342 6.58119 10.275C6.61238 10.2486 6.63135 10.2351 6.64043 10.2291C6.65771 10.2252 6.67565 10.2252 6.69293 10.2291C6.70201 10.2351 6.72098 10.2486 6.75217 10.275C6.83105 10.342 6.93107 10.4412 7.10441 10.6146L9.40245 12.9126C9.5528 13.063 9.69446 13.2047 9.82332 13.3141C9.96339 13.433 10.1346 13.5551 10.3551 13.6267C10.6659 13.7277 11.0008 13.7277 11.3116 13.6267C11.5321 13.5551 11.7033 13.433 11.8434 13.3141C11.9722 13.2047 12.1139 13.063 12.2643 12.9126L17.6191 7.5578V11.6667C17.6191 12.0612 17.9389 12.381 18.3333 12.381C18.7278 12.381 19.0476 12.0612 19.0476 11.6667V5.83336C19.0476 5.43887 18.7278 5.11908 18.3333 5.11908H12.5C12.1055 5.11908 11.7857 5.43887 11.7857 5.83336C11.7857 6.22785 12.1055 6.54765 12.5 6.54765H16.6089L11.2711 11.8855C11.0977 12.0588 10.9977 12.1581 10.9188 12.225C10.8876 12.2515 10.8687 12.265 10.8596 12.271C10.8423 12.2749 10.8244 12.2749 10.8071 12.271C10.798 12.265 10.779 12.2515 10.7479 12.225C10.669 12.1581 10.569 12.0588 10.3956 11.8855L8.09761 9.58747C7.94725 9.43708 7.80557 9.29538 7.67671 9.18598C7.53663 9.06707 7.36545 8.94499 7.14492 8.87333C6.83409 8.77234 6.49927 8.77234 6.18844 8.87333C5.96791 8.94499 5.79672 9.06707 5.65665 9.18598C5.52779 9.29538 5.38612 9.43707 5.23576 9.58746L1.1616 13.6616C0.882656 13.9406 0.882656 14.3928 1.1616 14.6718Z" />
        </svg>
    )
}

export default SvgTrendUpIcon
