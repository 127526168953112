import { ReactElement } from 'react'

import { ToolTip } from 'components/ToolTip'
import { UNDEFINED_VALUE } from 'const/formatting'
import moment from 'utilities/moment'

import styles from './styles.scss'

interface Props {
    time: string | null
    format?: string
    highlighted?: boolean
}

export default function Timestamp({
    time,
    format = 'M/DD/YYYY hh:mm a',
    highlighted = false,
}: Props): ReactElement {
    if (!time) {
        return <span>{UNDEFINED_VALUE}</span>
    }
    return (
        <ToolTip title={moment(time).fromNow()}>
            <span className={highlighted ? styles.highlighted : undefined}>
                {moment(time).tz(moment.tz.guess()).format(format)}
            </span>
        </ToolTip>
    )
}
