/**
 * Checks if a string is an Amazon ASIN
 * @see https://stackoverflow.com/a/12827734/3112403
 */
const SINGLE_ASIN_REGEX = /^([Bb][\da-zA-Z]{9}|\d{9}(X|\d))$/
export const isASIN = (value: string): boolean => SINGLE_ASIN_REGEX.test(value)

/**
 * Formats an Amazon ASIN
 */
export const formatASIN = (value: string): string => value.toUpperCase()
