import { handleActions } from 'redux-actions'

import { DATES, AGGREGATION } from 'const/filters'
import { KEYWORD_PAGE } from 'const/pages'
import { LOCAL_FILTERS, FILTER_SETTINGS } from 'const/reducerKeys'

import {
    defaultResourcePageTabs,
    defaultResourcePageWidgets,
    defaultResourcePage,
} from '../defaults'

const defaultState = {
    [LOCAL_FILTERS]: {},
    [FILTER_SETTINGS]: {
        anchored: [DATES, AGGREGATION],
        order: [],
        displayState: {},
    },

    ...defaultResourcePage,

    tabs: defaultResourcePageTabs,
    widgets: defaultResourcePageWidgets(KEYWORD_PAGE),
}

export default handleActions({}, defaultState)
