import { shallowEqual, useSelector } from 'react-redux'

import { makeUpdateFilter } from 'actions/ui/shared/filter'
import { APP } from 'const/pages'
import { useAction } from 'hooks/redux/useAction'
import {
    selectIsPageContentDisabled,
    selectCurrencyCode,
    selectLabelsOrdered,
    selectFilters,
    selectLabelsForFilters,
} from 'selectors/ui'
import { selectCustomEventsForWidget } from 'selectors/ui/customEvents'
import {
    RootReduxState,
    CurrencyCode,
    Label,
    CustomEvent,
    PageFilters,
    AppFiltersState,
    LabelFilterOption,
} from 'types'

interface AppStateObject {
    currencyCode: CurrencyCode
    isPageContentDisabled: boolean
    organizationLabels: Label[]
    labelsForFilters: LabelFilterOption[]
}

export const useAppState = (): AppStateObject => ({
    currencyCode: useSelector(
        (state: RootReduxState) => selectCurrencyCode(state),
        shallowEqual
    ),
    isPageContentDisabled: useSelector(
        (state: RootReduxState) => selectIsPageContentDisabled(state),
        shallowEqual
    ),
    organizationLabels: useSelector(
        (state: RootReduxState) => selectLabelsOrdered(state),
        shallowEqual
    ),
    labelsForFilters:
        useSelector(
            (state: RootReduxState) => selectLabelsForFilters(state),
            shallowEqual
        ) ?? [],
})

export function useAppFilters(): AppFiltersState {
    return useSelector<RootReduxState, AppFiltersState>(
        (state) => selectFilters<AppFiltersState>(state, [APP]),
        shallowEqual
    )
}

export function useAppUpdateFilter(): any {
    return useAction(makeUpdateFilter([APP]))
}

export const useCustomEventsForWidget = (filters: PageFilters): CustomEvent[] =>
    useSelector<RootReduxState, CustomEvent[]>((state) =>
        selectCustomEventsForWidget(state, filters)
    )
