import { call, select, put } from 'redux-saga/effects'

import { makeFetchTableSuccess, makeFetchTableRequest } from 'actions/ui/shared'
import { LABELS_SUMMARY_PAGE } from 'const/pages'
import { formatFilters } from 'helpers/filters/label'
import { formatPagination, formatSorter } from 'helpers/params'
import { cerebroApiSaga } from 'sagas/common'
import uiSagaRegistry from 'sagas/ui/registry'
import { selectTableSettings, selectVisibleCombinedFilters } from 'selectors/ui'
import { getLabels } from 'services/cerebroApi/orgScope/resourceApi'

const TABLE_PATH = [LABELS_SUMMARY_PAGE, 'table']

function* fetchTableSaga() {
    const { pagination, sorter } = yield select(selectTableSettings, TABLE_PATH)
    const filters = yield select(selectVisibleCombinedFilters, [
        LABELS_SUMMARY_PAGE,
    ])

    yield call(cerebroApiSaga, makeFetchTableSuccess(TABLE_PATH), getLabels, {
        ...formatPagination(pagination),
        ...formatSorter(sorter),
        ...formatFilters(filters),
    })
}

function* fetchPageDataSaga() {
    yield put(makeFetchTableRequest(TABLE_PATH)())
}

function* mountPageSaga() {
    yield put(makeFetchTableRequest(TABLE_PATH)())
}

uiSagaRegistry.registerSagas([LABELS_SUMMARY_PAGE], {
    mountPageSaga,
    fetchPageDataSaga,
})

uiSagaRegistry.registerSagas(TABLE_PATH, {
    fetchTableSaga,
})
