import { handleActions } from 'redux-actions'

import { walmartKeywordsSummaryTableColumnsConfig } from 'configuration/tables'
import {
    DATES,
    CAMPAIGNS,
    KEYWORD_TEXT,
    KEYWORD_MATCH_TYPES,
    KEYWORD_STATES,
    AD_GROUPS,
    AD_ACCOUNTS,
} from 'const/filters'
import { FILTER_SETTINGS, FILTERS } from 'const/reducerKeys'
import { serializeColumns } from 'helpers/tables'
import { getDefaultTable } from 'reducers/ui/defaults'

import { defaultPage } from '../defaults'

const defaultState = {
    ...defaultPage,
    [FILTERS]: {
        [KEYWORD_TEXT]: null,
        [CAMPAIGNS]: [],
        [KEYWORD_MATCH_TYPES]: [],
        [KEYWORD_STATES]: [],
        [AD_GROUPS]: [],
        [AD_ACCOUNTS]: [],
    },
    [FILTER_SETTINGS]: {
        anchored: [KEYWORD_TEXT, DATES],
        order: [
            KEYWORD_STATES,
            KEYWORD_MATCH_TYPES,
            CAMPAIGNS,
            AD_GROUPS,
            AD_ACCOUNTS,
        ],
        displayState: {
            [CAMPAIGNS]: true,
            [KEYWORD_MATCH_TYPES]: true,
            [KEYWORD_STATES]: true,
            [AD_GROUPS]: true,
            [AD_ACCOUNTS]: true,
        },
    },
    table: getDefaultTable(
        serializeColumns(walmartKeywordsSummaryTableColumnsConfig),
        { showTotalRow: true }
    ),
}

export default handleActions({}, defaultState)
