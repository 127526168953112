import { Action, ActionFunction1 } from 'redux-actions'

import { getCurrentPage } from './pages'

type ActionCreator<Payload> = ActionFunction1<Payload, Action<Payload>>

export interface PathActionEmptyPayload {
    path: string[]
    options: any
}

export interface PathActionPayload<T> extends PathActionEmptyPayload {
    data: T
}

export const curryEmptyPayloadActionForPath =
    (actionCreator: ActionCreator<PathActionEmptyPayload>) =>
    (path: string[], options = {}) =>
    () =>
        actionCreator({
            path,
            options,
        })

/**
 * Currys action creator to inject path in payload
 */
export const curryPayloadActionForPath =
    <T>(actionCreator: ActionCreator<PathActionPayload<T>>) =>
    (path: string[], options = {}) =>
    (data: T) =>
        actionCreator({
            path,
            options,
            data,
        })

/**
 * Currys action creator to inject path in payload
 */
export const curryActionForPath =
    (actionCreator: any) =>
    (path?: string[] | string, options = {}) =>
    (data?: unknown) =>
        actionCreator({
            path,
            options,
            data,
        })

/**
 * Currys action creator to inject pageName in payload
 * If pageName is not provided, assumes current page
 */
export const curryActionForPage =
    (actionCreator: any) => (pageName?: string) => (data?: unknown) =>
        actionCreator({
            pageName: pageName || getCurrentPage().page,
            data,
        })

/**
 * Currys action creator to inject pageName and updatingResource state name in payload
 */
export const curryActionForResource =
    (actionCreator: any) =>
    (pageName?: string | string[], updatingResourceKey = 'updating') =>
    (data?: unknown) =>
        actionCreator({
            pageName,
            updatingResourceKey,
            data,
        })
