import get from 'lodash/get'
import createCachedSelector from 're-reselect'

import { RootReduxState, UiState, Path } from 'types'

const selectDomainState = (state: RootReduxState): UiState => state.ui

export const createPathCacheKey = (path: Path): string => {
    if (typeof path === 'string') {
        return path
    }
    return path.join()
}

export const selectDomainValue = createCachedSelector<
    RootReduxState,
    Path,
    UiState,
    any,
    any
>(
    selectDomainState,
    (_state, path: Path) => path,
    (domainState, path) => get(domainState, path)
)((_state, path) => JSON.stringify(path))

export const selectResourceParamsOfPage = (
    state: RootReduxState,
    pageName: string
): any => selectDomainValue(state, [pageName, 'resourceParams']) ?? {}

export const selectResourceOfPage = (
    state: RootReduxState,
    pageName: string
): any => selectDomainValue(state, [pageName, 'resource'])

export const selectDashboardParamsOfPage = (
    state: RootReduxState,
    pageName: string
): any => selectDomainValue(state, [pageName, 'dashboardParams'])

export const selectDashboardOfPage = (
    state: RootReduxState,
    pageName: string
): any => selectDomainValue(state, [pageName, 'dashboard'])
