import { call, select, put } from 'redux-saga/effects'

import { makeFetchTableRequest, makeFetchTableSuccess } from 'actions/ui/shared'
import { walmartCampaignKeywordsTabTableColumnsConfig } from 'configuration/tables'
import { DATES } from 'const/filters'
import { WALMART_CAMPAIGN_PAGE } from 'const/pages'
import { formatFilters } from 'helpers/filters/wmAdvertisingFacts'
import {
    formatSorter,
    formatPagination,
    formatCurrency,
    formatMetrics,
    formatPeriodDeltaDateRange,
} from 'helpers/params'
import { cerebroApiSaga } from 'sagas/common'
import uiSagaRegistry from 'sagas/ui/registry'
import {
    selectTableSettings,
    selectCurrencyCode,
    selectResourceParamsOfPage,
    selectVisibleMetricsOfTable,
    selectVisibleCombinedFilters,
} from 'selectors/ui'
import { getWmAdvertisingKeywordFacts } from 'services/cerebroApi/orgScope/walmartApi'

const TAB_PATH = [WALMART_CAMPAIGN_PAGE, 'keywords']
const TABLE_PATH = [...TAB_PATH, 'table']

function* fetchTableSaga(noCount) {
    const { wmCampaignId } = yield select(
        selectResourceParamsOfPage,
        WALMART_CAMPAIGN_PAGE
    )
    const currency = yield select(selectCurrencyCode)
    const {
        pagination,
        sorter,
        showPeriodDeltas,
        periodDeltaType,
        periodDeltaDateRange,
        showTotalRow,
    } = yield select(selectTableSettings, TABLE_PATH)
    const metrics = yield select(
        selectVisibleMetricsOfTable,
        TABLE_PATH,
        walmartCampaignKeywordsTabTableColumnsConfig
    )
    const filters = yield select(selectVisibleCombinedFilters, TAB_PATH)

    const params = {
        ...formatFilters(filters),
        ...formatPagination(pagination),
        ...formatSorter(sorter),
        ...formatCurrency(currency),
        ...formatMetrics(metrics, showPeriodDeltas, showTotalRow),
        ...formatPeriodDeltaDateRange(
            showPeriodDeltas,
            periodDeltaType,
            periodDeltaDateRange,
            filters[DATES]
        ),
        group_by: 'keyword_id,ad_group_id,campaign_id,advertiser_id',
        campaign_id: wmCampaignId,
    }

    yield call(
        cerebroApiSaga,
        makeFetchTableSuccess(TABLE_PATH),
        getWmAdvertisingKeywordFacts,
        params,
        { headers: { noCount } }
    )
}

function* fetchTabDataSaga() {
    yield put(makeFetchTableRequest(TABLE_PATH)())
}

// Register Sagas
uiSagaRegistry.registerSagas(TAB_PATH, {
    fetchTabDataSaga,
})

uiSagaRegistry.registerSagas(TABLE_PATH, {
    fetchTableSaga,
})
