export const KEYWORD_BID_MIN = 0.02
export const KEYWORD_BID_MAX = 1000000000
export const KEYWORD_BID_STEP = 0.1
export const KEYWORD_BID_PRECISION = 2
export const KEYWORD_BID_DEFAULT_AMOUNT_ADJUSTMENT = 0.2

// Bulk Update Bid Actions
export const SET = 'set'
export const INCREASE = 'increase'
export const DECREASE = 'decrease'
export const INCREASE_BY_PCT = 'increase_by_pct'
export const DECREASE_BY_PCT = 'decrease_by_pct'
export const APPLY_AMAZON_SUGGESTED_BID = 'amazon_suggested'
export const ABOVE_AMAZON_SUGGESTED_BID = 'above_amazon_suggested'
export const BELOW_AMAZON_SUGGESTED_BID = 'below_amazon_suggested'
