import { ReactElement } from 'react'

import Icon, { PlusOutlined } from '@ant-design/icons'
import { Badge, Button, Drawer, List, Avatar } from 'antd'
import classNames from 'classnames'
import orderBy from 'lodash/orderBy'
import { useTranslation, Trans } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'

import { ArrowRightButton } from 'components/Buttons'
import { AlertLink } from 'components/Links'
import { useCerebroApiRequest } from 'hooks'
import { ReactComponent as BellFilledIcon } from 'images/bell-filled.svg'
import { updateTriggeredAlert } from 'services/cerebroApi/orgScope/alertsApi'
import { TriggeredAlert } from 'types'
import moment from 'utilities/moment'

import styles from './styles.scss'
import { CloseIcon } from '../../Icons'

interface AlertsDrawerProps {
    triggeredAlerts: TriggeredAlert[]
    visible: boolean
    onClose: () => void
}

const AlertsDrawer = ({
    triggeredAlerts,
    visible,
    onClose,
}: AlertsDrawerProps): ReactElement => {
    const { t } = useTranslation(['common', 'navigation'])
    const history = useHistory()
    const makeCerebroApiRequest = useCerebroApiRequest()
    const handleDismissAlert = async (
        alertId: string,
        alertConfigurationId: string
    ): Promise<void> => {
        await makeCerebroApiRequest({
            request: updateTriggeredAlert(alertId, {
                status: 'read',
            }),
            onRequestSuccess: () => {
                history.push(`/alerts/${alertConfigurationId}`)
            },
        })
    }

    return (
        <Drawer
            title={
                <>
                    <span className="mr-2">
                        {t('common:triggeredAlerts', 'Triggered Alerts')}
                    </span>
                    <span>
                        (
                        <Link to="/alerts/?tab=triggeredAlerts">
                            {t('common:viewAll', 'View All')}
                        </Link>
                        )
                    </span>
                </>
            }
            placement="right"
            onClose={onClose}
            closeIcon={<CloseIcon />}
            open={visible}
            width={460}
            className={classNames(['fixed-header', styles.drawer])}
        >
            <List
                dataSource={orderBy(
                    triggeredAlerts,
                    ['triggered_at'],
                    ['desc']
                )}
                locale={{
                    emptyText: t(
                        'navigation:TopNav.AlertsDrawer.emptyText',
                        'No new alerts.'
                    ),
                }}
                footer={
                    <div className="d-flex justify-content-center">
                        <Button
                            type="primary"
                            onClick={() => {
                                history.push('/alert/new')
                            }}
                            icon={<PlusOutlined />}
                        >
                            {t(
                                'navigation:TopNav.AlertsDrawer.createAlertBtnText',
                                'Create New Alert'
                            )}
                        </Button>
                    </div>
                }
                renderItem={(alert) => (
                    <List.Item
                        actions={[
                            <ArrowRightButton
                                onClick={() => {
                                    handleDismissAlert(
                                        alert.id,
                                        alert.alert_configuration_id
                                    )
                                }}
                            />,
                        ]}
                    >
                        <List.Item.Meta
                            avatar={
                                <Badge dot={alert.status === 'unread'}>
                                    <Avatar
                                        style={{
                                            backgroundColor: 'transparent',
                                        }}
                                    >
                                        <Icon
                                            component={BellFilledIcon as any}
                                            style={{
                                                color: 'rgb(89,89,89)',
                                                fontSize: '16px',
                                            }}
                                        />
                                    </Avatar>
                                </Badge>
                            }
                            title={<AlertLink triggeredAlert={alert} />}
                            className={styles['drawer-list-item']}
                            description={
                                <p>
                                    <Trans i18nKey="navigation:TopNav.AlertsDrawer.triggeredOn">
                                        <strong>Triggered On:</strong>{' '}
                                        {{
                                            triggeredOn: moment(
                                                alert.triggered_at
                                            ).format('LLL'),
                                        }}
                                    </Trans>
                                </p>
                            }
                        />
                    </List.Item>
                )}
            />
        </Drawer>
    )
}

export default AlertsDrawer
