import { SVGAttributes, ReactElement } from 'react'

type Props = SVGAttributes<SVGElement>

function SvgAutomationIcon(props: Props): ReactElement {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.928 3.7c.468-.451 1.189-.453 1.72-.079l.136.095c.484.34 1.112.353 1.659.125.546-.227.994-.683 1.095-1.266l.028-.163c.112-.64.623-1.149 1.273-1.16a8.868 8.868 0 01.322 0c.65.011 1.161.52 1.273 1.16l.028.163c.101.583.549 1.04 1.095 1.266.547.228 1.175.216 1.66-.125l.135-.095c.531-.374 1.252-.372 1.72.079.078.074.154.15.228.228.451.468.453 1.189.079 1.72l-.095.136c-.34.484-.353 1.112-.125 1.659.227.546.683.994 1.266 1.095l.163.028c.64.112 1.149.623 1.16 1.273a9.555 9.555 0 010 .322c-.011.65-.52 1.161-1.16 1.273l-.163.028c-.583.101-1.04.549-1.267 1.095-.227.547-.215 1.175.126 1.66l.095.135c.374.531.372 1.252-.079 1.72a8.95 8.95 0 01-.228.228c-.468.451-1.189.453-1.72.079l-.136-.095c-.484-.34-1.112-.353-1.659-.125-.546.227-.994.683-1.095 1.266l-.028.163c-.112.64-.623 1.149-1.273 1.16a9.56 9.56 0 01-.322 0c-.65-.011-1.161-.52-1.273-1.16l-.028-.163c-.101-.583-.549-1.04-1.095-1.267-.547-.227-1.175-.215-1.66.126l-.135.095c-.531.374-1.252.372-1.72-.079a8.79 8.79 0 01-.228-.228c-.451-.468-.453-1.189-.079-1.72l.095-.136c.34-.484.353-1.112.125-1.659-.227-.546-.683-.994-1.266-1.095l-.163-.028c-.64-.112-1.149-.623-1.16-1.273a8.87 8.87 0 010-.322c.011-.65.52-1.161 1.16-1.273l.163-.028c.583-.101 1.04-.549 1.266-1.095.228-.547.216-1.175-.125-1.66l-.095-.135c-.374-.531-.372-1.252.079-1.72.074-.077.15-.154.228-.228zM15 10a5 5 0 11-10 0 5 5 0 0110 0z"
            />
            <path d="M12.588 9.683a.4.4 0 010 .634l-3.444 2.651a.4.4 0 01-.644-.317V7.35a.4.4 0 01.644-.317l3.444 2.651z" />
        </svg>
    )
}

export default SvgAutomationIcon
