import { SVGAttributes, ReactElement } from 'react'

type Props = SVGAttributes<SVGElement>

function SvgBrandIcon(props: Props): ReactElement {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M17.2853 15.1937L16.0531 4.56875C16.0531 4.54219 16.0279 4.48906 15.9776 4.48906H13.3371V3.90469C13.3371 1.56719 12.1049 0 10.244 0C8.38309 0 7.15086 1.56719 7.15086 3.90469V4.48906H4.63611C4.61096 4.48906 4.56066 4.51563 4.56066 4.56875L3.32844 15.1937C3.25299 15.6453 3.40388 16.1234 3.6805 16.4688C3.98227 16.8141 4.40978 17 4.83729 17H15.7765C16.204 17 16.6566 16.8141 16.9332 16.4688C17.2602 16.0969 17.3607 15.6453 17.2853 15.1937ZM12.2558 4.48906H8.25735V3.90469C8.25735 2.62969 8.7603 1.14219 10.244 1.14219C11.7277 1.14219 12.2307 2.62969 12.2307 3.90469V4.48906H12.2558Z" />
        </svg>
    )
}

export default SvgBrandIcon
