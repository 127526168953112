import { useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import { DataSourceIntegrationKey } from 'const/dataSourceIntegrations'

interface DataSourceIntegrationInfo {
    key: DataSourceIntegrationKey
    name: string
    url: string
}

export function useDataSourceIntegrationInfo(): Record<
    DataSourceIntegrationKey,
    DataSourceIntegrationInfo
> {
    const { t } = useTranslation()
    const integrationInfo: Record<
        DataSourceIntegrationKey,
        DataSourceIntegrationInfo
    > = useMemo(
        () => ({
            amazon_advertising: {
                key: 'amazon_advertising',
                name: t(
                    'common:data.integrations.amazon_advertising',
                    'Amazon Advertising'
                ),
                url: '/account?tab=integrations',
            },
            amazon_advertising_dsp: {
                key: 'amazon_advertising_dsp',
                name: t(
                    'common:data.integrations.amazon_advertising_dsp',
                    'Amazon Advertising Agency'
                ),
                url: '/account?tab=integrations',
            },
            seller_central: {
                key: 'seller_central',
                name: t(
                    'common:data.integrations.seller_central',
                    'Selling Partner'
                ),
                url: '/account?tab=scIntegrations',
            },
            vendor_central: {
                key: 'vendor_central',
                name: t(
                    'common:data.integrations.vendor_central',
                    'Selling Partner'
                ),
                url: '/account?tab=scIntegrations',
            },
            walmart_advertising: {
                key: 'walmart_advertising',
                name: t(
                    'common:data.integrations.walmart_advertising',
                    'Walmart Advertising'
                ),
                url: '?tab=wmAdvertisingIntegrations',
            },
        }),
        [t]
    )

    return integrationInfo
}
