import get from 'lodash/get'
import { call, put, select } from 'redux-saga/effects'

import { makeFetchTableRequest, makeFetchTableSuccess } from 'actions/ui/shared'
import { AD_GROUP_PAGE } from 'const/pages'
import { ENABLED } from 'const/resourceStates'
import { formatPagination, formatSorter } from 'helpers/params'
import { cerebroApiSaga } from 'sagas/common'
import uiSagaRegistry from 'sagas/ui/registry'
import { selectResourceOfPage, selectTableSettings } from 'selectors/ui'
import { getNegativeKeywords } from 'services/cerebroApi/orgScope/resourceApi'

const TAB_PATH = [AD_GROUP_PAGE, 'negativeKeywords']
const TABLE_PATH = [...TAB_PATH, 'table']

function* selectCampaignId() {
    const adGroup = yield select(selectResourceOfPage, AD_GROUP_PAGE)

    return get(adGroup, 'campaign_id')
}

/**
 * Fetch table data
 */
function* fetchTableSaga(noCount) {
    const campaignId = yield selectCampaignId()
    const { pagination, sorter } = yield select(selectTableSettings, TABLE_PATH)
    const params = {
        ...formatPagination(pagination),
        ...formatSorter(sorter),
        campaign: campaignId,
        state: ENABLED,
    }

    const response = yield call(
        cerebroApiSaga,
        null,
        getNegativeKeywords,
        params,
        { headers: { noCount } }
    )

    if (response) {
        yield put(makeFetchTableSuccess(TABLE_PATH)(response.data))
    }
}

function* fetchTabDataSaga() {
    yield put(makeFetchTableRequest(TABLE_PATH)())
}

// Register Sagas
uiSagaRegistry.registerSagas(TAB_PATH, {
    fetchTabDataSaga,
})

uiSagaRegistry.registerSagas(TABLE_PATH, {
    fetchTableSaga,
})
