import { useState, useCallback } from 'react'

interface ModalState {
    isModalVisible: boolean
    onModalCancel: () => void
    toggleModalVisible: () => void
}

const useModal = (): ModalState => {
    const [isModalVisible, setIsModalVisible] = useState(false)

    return {
        isModalVisible,
        onModalCancel: useCallback(() => setIsModalVisible(false), []),
        toggleModalVisible: useCallback(
            () => setIsModalVisible(!isModalVisible),
            [isModalVisible]
        ),
    }
}

export default useModal
