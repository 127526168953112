import { ReactElement, useEffect } from 'react'

import { ErrorMessage, Field, Form, Formik } from 'formik'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { boolean, object, string } from 'yup'

import { signUpRequest } from 'actions/auth'
import { useAppConfig } from 'appConfig'
import { FormikCheckbox, FormikInput } from 'components/formik'
import { ToolTip } from 'components/ToolTip'
import { MIN_PW_SIZE } from 'const/auth'
import { AUTH_PAGE } from 'const/pages'
import { getPath } from 'helpers/pages'
import { useAction } from 'hooks'
import { useAnalytics } from 'services/analytics'
import { OrganizationInvitation } from 'types'

import { AuthError, SubmitButton } from '../Shared'
import styles from '../styles.scss'

interface Props {
    invitation?: OrganizationInvitation
    token?: string
}

const CreateAccountForm = ({ invitation, token }: Props): ReactElement => {
    const signUp = useAction(signUpRequest)
    const { t } = useTranslation('account')
    const { productTitle } = useAppConfig()

    const { trackEvent, trackCtaClick } = useAnalytics()

    useEffect(() => {
        trackEvent('Sign Up - Sign Up Started', {
            text: 'Sign Up - Sign Up Started',
        })
    }, [])

    const validationSchema = object().shape({
        name: string().required().label(t('account:name', 'Name')),
        email: string()
            .email()
            .trim()
            .lowercase()
            .required()
            .label(t('account:email', 'Email')),
        password: string()
            .min(MIN_PW_SIZE)
            .required()
            .label(t('account:password', 'Password')),
        terms_of_service: boolean().oneOf(
            [true],
            t(
                'account:CreateAccountForm.terms_of_service.errorMsg',
                'Please agree to our terms of service'
            )
        ),
    })

    const renderTitle = (): ReactElement => {
        if (invitation) {
            return (
                <div className={styles.title}>
                    <h1>
                        {t(
                            'account:CreateAccountForm.invitedMember.title',
                            'Welcome to {{ productTitle }}!',
                            { productTitle }
                        )}
                    </h1>
                    <div className={styles.subtitle}>
                        <Trans i18nKey="account:CreateAccountForm.invitedMember.subTitle">
                            You&apos;ve been invited to join{' '}
                            <strong>
                                {{ orgName: invitation.organization.name }}
                            </strong>{' '}
                            by
                            <br />
                            {{ inviterEmail: invitation.inviter.email }}
                        </Trans>
                    </div>
                </div>
            )
        }
        return (
            <div className={styles.title}>
                <h1>{t('account:CreateAccountForm.title', 'Sign up')}</h1>
                <div className={styles.subtitle}>
                    <Trans i18nKey="account:CreateAccountForm.subTitle">
                        Create your account, then join or create
                        <br /> an organization
                    </Trans>
                </div>
            </div>
        )
    }

    return (
        <>
            {renderTitle()}

            <Formik
                initialValues={{
                    name: '',
                    email: invitation?.user_email || '',
                    password: '',
                    terms_of_service: false,
                }}
                onSubmit={(values) => {
                    trackCtaClick({
                        destination: 'Sign in - Login Page',
                        text: 'Sign Up',
                        type: 'button',
                        location: 'Sign Up Page',
                    })
                    signUp({ ...validationSchema.cast(values), token })
                }}
                validationSchema={validationSchema}
            >
                {() => (
                    <Form>
                        <div className="mb-2">
                            <Field
                                component={FormikInput}
                                placeholder={t('account:name', 'Name')}
                                name="name"
                                size="large"
                            />
                            <ErrorMessage
                                name="name"
                                component="div"
                                className="fg-control-text is-error"
                            />
                        </div>
                        <div className="mb-2">
                            <Field
                                component={FormikInput}
                                placeholder={t('account:email', 'Email')}
                                name="email"
                                size="large"
                                disabled={!!invitation}
                            />
                            <ErrorMessage
                                name="email"
                                component="div"
                                className="fg-control-text is-error"
                            />
                        </div>
                        <div className="mb-2">
                            <ToolTip
                                placement="right"
                                title={t(
                                    'account:PasswordField.securityTooltip',
                                    'We check passwords against common and compromised passwords to keep your account secure.'
                                )}
                            >
                                <Field
                                    component={FormikInput}
                                    placeholder={t(
                                        'account:password',
                                        'Password'
                                    )}
                                    name="password"
                                    type="password"
                                    size="large"
                                />
                            </ToolTip>
                            <ErrorMessage
                                name="password"
                                component="div"
                                className="fg-control-text is-error"
                            />
                        </div>
                        <div className="mt-4">
                            <Field
                                component={FormikCheckbox}
                                name="terms_of_service"
                            >
                                <Trans i18nKey="account:CreateAccountForm.termsOfServiceLink">
                                    I agree to {{ productTitle }}&apos;s{' '}
                                    <a
                                        href={TERMS_OF_SERVICE}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        Terms of Service
                                    </a>
                                    .
                                </Trans>
                            </Field>
                            <ErrorMessage
                                name="terms_of_service"
                                component="div"
                                className="fg-control-text is-error"
                            />
                        </div>
                        <SubmitButton>
                            {t(
                                'account:CreateAccountForm.submitBtnText',
                                'Sign Up'
                            )}
                        </SubmitButton>
                    </Form>
                )}
            </Formik>

            <AuthError />

            <div className={styles.footer}>
                <Trans i18nKey="account:CreateAccountForm.logInLink">
                    Already have a {{ productTitle }} account?{' '}
                    <Link to={getPath(AUTH_PAGE)}>
                        <span>Log In</span>
                    </Link>
                </Trans>
            </div>
        </>
    )
}

export default CreateAccountForm
