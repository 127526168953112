import { ReactElement } from 'react'

interface Props {
    condition: boolean
    wrapper: (children: ReactElement) => ReactElement
    children: ReactElement
}

function ConditionalWrapper({
    condition,
    wrapper,
    children,
}: Props): ReactElement {
    return condition ? wrapper(children) : children
}

export default ConditionalWrapper
