import { Reducer } from 'redux'

import sharedReducer from './shared'
import { buildUiReducer } from './ui'

// Pulled out of reduce-reducers as the older version does not have types
// and the newer version forces an upgrade of redux-actions, whose latest version does not work with Jest
function reduceReducers(...args: any[]): any {
    const initialState = typeof args[0] !== 'function' && args.shift()
    const reducers = args

    if (typeof initialState === 'undefined') {
        throw new TypeError(
            'The initial state may not be undefined. If you do not want to set a value for this reducer, you can use null instead of undefined.'
        )
    }

    // eslint-disable-next-line @typescript-eslint/no-shadow
    return (prevState: any, value: any, ...args: any[]) => {
        const prevStateIsUndefined = typeof prevState === 'undefined'
        const valueIsUndefined = typeof value === 'undefined'

        if (prevStateIsUndefined && valueIsUndefined && initialState) {
            return initialState
        }

        return reducers.reduce(
            (newState, reducer, index) => {
                if (typeof reducer === 'undefined') {
                    throw new TypeError(
                        `An undefined reducer was passed in at index ${index}`
                    )
                }

                return reducer(newState, value, ...args)
            },
            prevStateIsUndefined && !valueIsUndefined && initialState
                ? initialState
                : prevState
        )
    }
}

// Reducer that handles shared actions that applies to all pages

export const buildReducer = (): Reducer<any, any> => {
    const ui = buildUiReducer()
    const shared = sharedReducer as Reducer<any, any>
    return reduceReducers(ui, shared)
}
