import { handleActions } from 'redux-actions'

import { labelBrandsTableColumnsConfig } from 'configuration/tables'
import {
    FACT_TYPES,
    REGIONS,
    COUNTRIES,
    BRANDS,
    METRIC_FILTERS,
} from 'const/filters'
import { LOCAL_FILTERS, FILTER_SETTINGS, DATES } from 'const/reducerKeys'
import { serializeColumns } from 'helpers/tables'
import { defaultTab, getDefaultTable } from 'reducers/ui/defaults'

const initialState = {
    ...defaultTab,
    [LOCAL_FILTERS]: {
        [FACT_TYPES]: [],
        [REGIONS]: [],
        [COUNTRIES]: [],
        [BRANDS]: [],
        [METRIC_FILTERS]: [],
    },
    [FILTER_SETTINGS]: {
        anchored: [DATES, FACT_TYPES],
        order: [METRIC_FILTERS, REGIONS, COUNTRIES, BRANDS],
        displayState: {
            [METRIC_FILTERS]: true,
            [REGIONS]: true,
            [COUNTRIES]: true,
            [BRANDS]: true,
        },
    },
    table: getDefaultTable(serializeColumns(labelBrandsTableColumnsConfig)),
}

export default handleActions({}, initialState)
