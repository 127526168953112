import { axiosInstanceOrganizations } from './client'
import { getCerebroHeaders } from './headers'

export const getCustomEvents = (params, options = {}) =>
    axiosInstanceOrganizations.get('/api/custom_events/', {
        params,
        headers: getCerebroHeaders(options.headers),
    })

export const createCustomEvent = (data) =>
    axiosInstanceOrganizations.post('/api/custom_events/', data)

export const updateCustomEvent = (id, data) =>
    axiosInstanceOrganizations.patch(`/api/custom_event/${id}/`, data)

export const deleteCustomEvent = (id) =>
    axiosInstanceOrganizations.delete(`/api/custom_event/${id}/`)
