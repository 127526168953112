import { ReactElement, useEffect } from 'react'

import { ErrorMessage, Field, Form, Formik } from 'formik'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { object, string } from 'yup'

import { signInRequest } from 'actions/auth'
import { useAppConfig } from 'appConfig'
import { FormikInput } from 'components/formik'
import { AUTH_FORGOT_PASSWORD_PAGE } from 'const/pages'
import { getPath } from 'helpers/pages'
import { useAction } from 'hooks'
import { useAnalytics } from 'services/analytics'

import { AuthError, SubmitButton } from '../Shared'
import styles from '../styles.scss'

const LoginForm = (): ReactElement => {
    const signIn = useAction(signInRequest)
    const { t } = useTranslation('account')

    const validationSchema = object().shape({
        email: string()
            .email()
            .trim()
            .lowercase()
            .required()
            .label(t('account:email', 'Email')),
        password: string()
            .min(6)
            .required()
            .label(t('account:password', 'Password')),
    })

    const { isChinaBuild, productTitle } = useAppConfig()

    const { trackEvent, trackCtaClick } = useAnalytics()

    useEffect(() => {
        trackEvent('Login - Login Started', {
            text: 'Login - Login Started',
        })
    }, [])

    const demoLink = isChinaBuild
        ? 'https://www.junglescout.cn/downstream/'
        : 'https://www.downstreamimpact.com/demo/'

    return (
        <>
            <div className={styles.title}>
                <h1>{t('account:LoginForm.title', 'Log In')}</h1>
            </div>

            <Formik
                initialValues={{
                    email: '',
                    password: '',
                }}
                onSubmit={(values) => {
                    trackCtaClick({
                        destination: 'Signed in - Landing page',
                        text: 'Log In',
                        type: 'button',
                        location: 'Login - Login Page',
                    })
                    signIn(validationSchema.cast(values))
                }}
                validationSchema={validationSchema}
            >
                {() => (
                    <Form>
                        <div className="mb-2">
                            <Field
                                component={FormikInput}
                                placeholder={t('account:email', 'Email')}
                                name="email"
                                size="large"
                                id="email"
                            />
                            <ErrorMessage
                                name="email"
                                component="div"
                                className="fg-control-text is-error"
                            />
                        </div>
                        <div>
                            <Field
                                component={FormikInput}
                                placeholder={t('account:password', 'Password')}
                                name="password"
                                type="password"
                                size="large"
                                id="password"
                            />
                            <ErrorMessage
                                name="password"
                                component="div"
                                className="fg-control-text is-error"
                            />
                        </div>
                        <SubmitButton>
                            {t('account:LoginForm.submitBtnText', 'Log In')}
                        </SubmitButton>
                    </Form>
                )}
            </Formik>

            <div className="mt-3">
                <Link to={getPath(AUTH_FORGOT_PASSWORD_PAGE)}>
                    <span>
                        {t(
                            'account:LoginForm.forgotPasswordLink',
                            'Forgot password?'
                        )}
                    </span>
                </Link>
            </div>

            <AuthError />

            <div className={styles.footer}>
                <Trans i18nKey="account:LoginForm.requestDemoLink">
                    Don&apos;t have a {{ productTitle }} account?{' '}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={demoLink}
                    >
                        <span>Request a Demo</span>
                    </a>
                </Trans>
            </div>
        </>
    )
}

export default LoginForm
