import { call, select } from 'redux-saga/effects'

import {
    makeFetchResourceSuccess,
    makeUpdateResourceSuccess,
} from 'actions/ui/shared/resource'
import { AD_GROUP_PAGE } from 'const/pages'
import { cerebroApiSaga } from 'sagas/common'
import uiSagaRegistry from 'sagas/ui/registry'
import { selectResourceParamsOfPage } from 'selectors/ui'
import {
    getAdGroup,
    updateAdGroup,
} from 'services/cerebroApi/orgScope/resourceApi'

/**
 * Updates an ad group
 */
function* updateAdGroupSaga({ adGroupId }, params) {
    yield call(
        cerebroApiSaga,
        makeUpdateResourceSuccess(AD_GROUP_PAGE),
        updateAdGroup,
        adGroupId,
        params
    )
}

function* fetchAdGroupSaga() {
    const { adGroupId } = yield select(
        selectResourceParamsOfPage,
        AD_GROUP_PAGE
    )

    yield call(
        cerebroApiSaga,
        makeFetchResourceSuccess(AD_GROUP_PAGE),
        getAdGroup,
        adGroupId
    )
}

/**
 * Mounts the Ad Group Page and fetches data
 */
function* mountPageSaga() {
    yield call(fetchAdGroupSaga)
}

uiSagaRegistry.registerSagas([AD_GROUP_PAGE], {
    mountPageSaga,
    updateResourceSaga: updateAdGroupSaga,
})
