import { memo, ReactElement } from 'react'

import isEqual from 'lodash/isEqual'
import { useTranslation } from 'react-i18next'

import {
    CAMPAIGN_BUDGET_MIN,
    CAMPAIGN_BUDGET_MAX,
    CAMPAIGN_BUDGET_STEP,
    CAMPAIGN_BUDGET_PRECISION,
} from 'const/campaigns'
import { updateCampaign } from 'services/cerebroApi/orgScope/resourceApi'
import { CampaignGroupBy } from 'types'

import { BudgetField } from '../../SharedFields/BudgetField'

interface Props<RecordType> {
    dataIndex: string[]
    readOnly: boolean
    record: RecordType
}

function CampaignBudgetField<RecordType extends CampaignGroupBy>({
    dataIndex = ['campaign', 'base_budget'],
    readOnly = false,
    record,
}: Props<RecordType>): ReactElement {
    const fieldName = 'budget' // field to update
    const currencyCode = record.campaign.profile.currency_code
    const resourceId = record.campaign_id
    const { t } = useTranslation(['campaigns'])

    const getIsDisabled = (): boolean => {
        const userCanManageCampaign = record.campaign.user_can_manage
        return !userCanManageCampaign
    }

    return (
        <BudgetField
            budgetMax={CAMPAIGN_BUDGET_MAX}
            budgetMin={CAMPAIGN_BUDGET_MIN}
            budgetPrecision={CAMPAIGN_BUDGET_PRECISION}
            budgetStep={CAMPAIGN_BUDGET_STEP}
            currencyCode={currencyCode}
            disabled={getIsDisabled()}
            fieldName={fieldName}
            fieldPath={dataIndex}
            readOnly={readOnly}
            record={record}
            updateRequestApi={updateCampaign}
            updateRequestData={resourceId}
            updateRequestSuccessMesg={t(
                'campaigns:successfulRequestMsg.updateBudget',
                'Successfully updated campaign budget'
            )}
        />
    )
}

export default memo(CampaignBudgetField, isEqual) as typeof CampaignBudgetField
