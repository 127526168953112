import { handleActions } from 'redux-actions'

import { childOrganizationsTableColumnsConfig } from 'configuration/tables'
import { serializeColumns } from 'helpers/tables'
import { defaultTab, getDefaultTable } from 'reducers/ui/defaults'

const initialState = {
    ...defaultTab,
    table: getDefaultTable(
        serializeColumns(childOrganizationsTableColumnsConfig),
        {
            sorter: {
                field: 'name',
                order: 'ascend',
            },
        }
    ),
}

export default handleActions({}, initialState)
