import { ReactElement } from 'react'

import Icon from '@ant-design/icons'

import UploadIconSvg from './UploadIconSvg'
import { IconProps } from '../localTypes'

const UploadIcon = (props: IconProps): ReactElement => (
    <Icon component={UploadIconSvg} {...props} />
)

export default UploadIcon
