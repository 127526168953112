import { SVGAttributes, ReactElement } from 'react'

type Props = SVGAttributes<SVGElement>

function LocationPinIconSvg(props: Props): ReactElement {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="-1 0 20 20"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M9 2C5.68624 2 3 4.51585 3 7.61811C3.00146 7.85208 3.0186 8.08569 3.0513 8.31743C3.56426 12.7004 8.61015 16.7557 8.61015 16.7557C8.68451 16.8353 8.77248 16.9015 8.87005 16.9511L9.04104 17L9.21203 16.9511C9.31007 16.9006 9.39902 16.8346 9.47535 16.7557C9.47535 16.7557 14.4494 12.7004 14.9504 8.30732C14.9816 8.07884 14.9981 7.84865 15 7.61811C15 4.51585 12.3138 2 9 2ZM9 9.7683C7.70219 9.7683 6.6489 8.78252 6.6489 7.56756C6.6489 6.35261 7.70219 5.36682 9 5.36682C10.2978 5.36682 11.3511 6.35261 11.3511 7.56756C11.3511 8.78252 10.2978 9.7683 9 9.7683Z" />
        </svg>
    )
}

export default LocationPinIconSvg
