export const EXACT = 'exact'
export const BROAD = 'broad'
export const PHRASE = 'phrase'

export const BOTH_NEGATIVE_MATCH_TYPES = 'negativeExact,negativePhrase'
export const NEGATIVE_EXACT = 'negativeExact'
export const NEGATIVE_PHRASE = 'negativePhrase'

type MatchTypeOption = {
    value: string
    label: string
}

export const MATCH_TYPE_OPTIONS: MatchTypeOption[] = [
    { value: EXACT, label: 'Exact' },
    { value: BROAD, label: 'Broad' },
    { value: PHRASE, label: 'Phrase' },
]

export const NEGATIVE_MATCH_TYPE_OPTIONS: MatchTypeOption[] = [
    { value: NEGATIVE_EXACT, label: 'Negative Exact' },
    { value: NEGATIVE_PHRASE, label: 'Negative Phrase' },
]

export const NEGATIVE_MATCH_TYPE_OPTIONS_WITH_BOTH: MatchTypeOption[] = [
    ...NEGATIVE_MATCH_TYPE_OPTIONS,
    ...[{ value: BOTH_NEGATIVE_MATCH_TYPES, label: 'Both' }],
]
