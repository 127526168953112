import { RawAxiosRequestHeaders } from 'axios'

import { CerebroApiOptionsHeaders } from 'types'

export const getCerebroHeaders = (
    options: CerebroApiOptionsHeaders | RawAxiosRequestHeaders = {}
): RawAxiosRequestHeaders => {
    const headers: { [key: string]: any } = {}

    if (options.noCount) {
        headers['x-dsi-pagination-method'] = 'no_count'
    }

    if (options.userId) {
        headers['x-dsi-user-id'] = options.userId
    }

    if (options.orgId) {
        headers['x-dsi-org-id'] = options.orgId
    }

    if (options.orgGroupId) {
        headers['x-dsi-org-group-id'] = options.orgGroupId
    }

    return headers
}
