import isArray from 'lodash/isArray'
import { useQueryParam } from 'use-query-params'

import { PresetRange, DateRangeFilter } from 'types'

export default function useDateRangeQueryParam(): [
    DateRangeFilter | undefined,
    (
        newValue: DateRangeFilter,
        updateType?: 'replace' | 'push' | 'replaceIn' | 'pushIn'
    ) => void,
] {
    return useQueryParam<DateRangeFilter>('date_range', {
        encode: (value) => {
            if (isArray(value)) {
                return value.join(',')
            }
            return value
        },
        decode: (value) => {
            const str = isArray(value) ? value[0] : value
            const valueArr = str.split(',')
            if (valueArr.length === 1) {
                return str as PresetRange
            }
            return valueArr
        },
    })
}
