import { datadogRum } from '@datadog/browser-rum'
import { configureScope } from '@sentry/browser'
import { all, call } from 'redux-saga/effects'

import { extractDomainFromEmail } from 'helpers/email'
import {
    getIdentifyProps,
    sendSegmentTrackAndIdentifyEvents,
    sendTrackEvent,
} from 'services/segment'
import {
    registerChurnZero,
    registerFullstoryUser,
    registerPendoUser,
} from 'services/userTracking'
import { Organization, OrganizationGroup, User } from 'types'

interface ContextParams {
    id: string
    email: string
    organizationId?: string
    organizationName?: string
    organizationGroupId?: string
    organizationGroupName?: string
}

const setSentryUserContext = ({
    id,
    email,
    organizationId,
    organizationName,
    organizationGroupId,
    organizationGroupName,
}: ContextParams): void => {
    configureScope((scope) => {
        scope.setTags({
            organizationId,
            organizationName,
            organizationGroupId,
            organizationGroupName,
        })
        scope.setUser({
            id,
            email,
        })
    })
}

const setDatadogUserContext = ({
    id,
    email,
    organizationId,
    organizationName,
    organizationGroupId,
    organizationGroupName,
}: ContextParams): void => {
    // https://docs.datadoghq.com/real_user_monitoring/browser/modifying_data_and_context/?tab=npm#user-session
    datadogRum.setUser({
        id,
        email,
        organizationId,
        organizationName,
        organizationGroupId,
        organizationGroupName,
        ...extractDomainFromEmail(email),
    })
}

const unsetSentryUserContext = (): void => {
    configureScope((scope) => {
        scope.setTags({
            organizationId: null,
            organizationGroupId: null,
        })
        scope.setUser({})
    })
}

const unsetDatadogUserContext = (): void => {
    datadogRum.clearUser()
}

/**
 * Set user context for Sentry and Datadog
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function* setDebugUserContextSaga(
    id: string,
    email: string,
    organization: Organization | null,
    organizationGroup: OrganizationGroup | null
) {
    const context = {
        id,
        email,
        organizationId: organization?.id,
        organizationName: organization?.name,
        organizationGroupId: organizationGroup?.id,
        organizationGroupName: organizationGroup?.name,
    }

    yield call(setSentryUserContext, context)
    yield call(setDatadogUserContext, context)
}

/**
 * Unset the user context for Sentry and Datadog
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function* unsetDebugUserContextSaga() {
    yield all([call(unsetSentryUserContext), call(unsetDatadogUserContext)])
}

/**
 * Set third party tracking in ChurnZero, Pendo, Fullstory, and Segment.io
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function* setThirdPartyTrackingSaga(
    currentUser: User,
    organization: Organization | null,
    gilId?: string
) {
    let salesforceId = null
    if (organization) {
        salesforceId = organization.salesforce_id

        // Register current user in ChurnZero
        yield call(registerChurnZero, salesforceId, gilId)
    }

    // Register user in Pendo, Fullstory, and Segment.io
    yield all([
        call(registerPendoUser, currentUser, organization),
        call(registerFullstoryUser, currentUser),
        call(
            sendSegmentTrackAndIdentifyEvents,
            'Signed In',
            {},
            gilId ?? '',
            currentUser.id,
            getIdentifyProps(currentUser, gilId, salesforceId)
        ),
    ])
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function* sendTrackEventSaga(eventName: string) {
    yield call(sendTrackEvent, eventName, {})
}
