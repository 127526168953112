import { useQuery, UseQueryResult } from '@tanstack/react-query'

import { getInvitationByToken } from 'services/cerebroApi/orgScope/resourceApi'
import { OrganizationInvitation } from 'types'

export const useInvitationQuery = (
    token: string | undefined
): UseQueryResult<OrganizationInvitation, Error> => {
    return useQuery({
        enabled: !!token,
        queryKey: ['auth', 'invitation', token] as const,
        queryFn: async () => {
            const response = await getInvitationByToken(token)
            if (response.status === 404) {
                throw new Error('Invitation not found')
            }
            return response.data
        },
        refetchOnMount: false,
        retryOnMount: false,
        retry: false,
        cacheTime: 0,
    })
}
