import { ReactElement } from 'react'

import { type AutoCompleteProps, type InputProps } from 'antd'
import { FieldProps } from 'formik'

import { Suggestion, SuggestionManager } from 'hooks/useSuggestionList'

import { FormikAutoComplete } from '../AutoComplete'
import { HandlerProps } from '../localTypes'
import withCustomHandlers from '../withCustomHandlers'

interface Props<V, FormValues>
    extends HandlerProps,
        Omit<AutoCompleteProps, 'placeholder' | 'options'>,
        FieldProps<V, FormValues>,
        Pick<InputProps, 'placeholder'> {
    suggestionManager: SuggestionManager
    renderOptions?: (
        options: Suggestion[],
        removeOption: (option: string) => void
    ) => AutoCompleteProps['options']
}

function FormikStorageBackedAutoComplete<V extends string, FormValues>({
    suggestionManager,
    renderOptions = (options) =>
        options.map((opt) => ({
            value: opt.suggestion,
            label: opt.suggestion,
        })),
    ...props
}: Props<V, FormValues>): ReactElement {
    const { suggestions, removeSuggestion } = suggestionManager

    return (
        <FormikAutoComplete
            options={renderOptions(suggestions, removeSuggestion)}
            {...props}
        />
    )
}

export default withCustomHandlers(FormikStorageBackedAutoComplete)
