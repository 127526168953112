import { ReactElement } from 'react'

import { Tooltip } from 'antd'
import isNull from 'lodash/isNull'

import { UNDEFINED_VALUE } from 'const/formatting'
import { Label } from 'types'

import { LabelElement, LabelGroup } from './Label'

interface Props {
    labels: Label[] | null
    empty?: string
}

const Labels = ({
    labels = [],
    empty = UNDEFINED_VALUE,
}: Props): ReactElement => {
    const renderEmpty = (): ReactElement => (
        <div className="text-center text-muted">{empty}</div>
    )

    const renderSingleLabel = (label: Label): ReactElement => (
        <Tooltip key={label.id} title={label.description || label.name}>
            <LabelElement label={label} />
        </Tooltip>
    )

    if (isNull(labels)) {
        return renderEmpty()
    }

    switch (labels.length) {
        case 0:
            return renderEmpty()
        case 1:
            return renderSingleLabel(labels[0])
        default:
            return <LabelGroup labels={labels} />
    }
}

export default Labels
