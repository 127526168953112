import { axiosInstanceOrganizations } from 'services/cerebroApi/orgScope/client'
import {
    Rulebook,
    RulebookVersion,
    CerebroPaginatedResponse,
    CerebroResourceResponse,
    ChangeSet,
    RulebookVersionReturnType,
} from 'types'

export const getRulebooks = (
    params: object
): Promise<CerebroPaginatedResponse<Rulebook>> =>
    axiosInstanceOrganizations.get('/api/rulebooks/', { params })

export const createRulebook = (
    data: Partial<Rulebook>
): Promise<CerebroResourceResponse<Rulebook>> =>
    axiosInstanceOrganizations.post('/api/rulebooks/', data)

export const getRulebook = (
    id: string
): Promise<CerebroResourceResponse<Rulebook>> =>
    axiosInstanceOrganizations.get(`/api/rulebook/${id}/`)

export const updateRulebook = (
    id: string,
    data: Partial<Rulebook>
): Promise<CerebroResourceResponse<Rulebook>> =>
    axiosInstanceOrganizations.patch(`/api/rulebook/${id}/`, data)

export const deleteRulebook = (
    id: string
): Promise<CerebroResourceResponse<Rulebook>> =>
    axiosInstanceOrganizations.delete(`/api/rulebook/${id}/`)

export const createRulebookVersion = (data: {
    rulebook: string
    config: RulebookVersion['config']
}): Promise<CerebroResourceResponse<RulebookVersion>> =>
    axiosInstanceOrganizations.post(`/api/rulebook_versions/`, data)

export const createRulebookVersionDryRun = (
    version: string
): Promise<CerebroResourceResponse<RulebookVersion>> =>
    axiosInstanceOrganizations.post(`/api/rulebook_version/${version}/dry_run/`)

export const getRulebookVersions = (
    params: object
): Promise<CerebroPaginatedResponse<RulebookVersionReturnType>> =>
    axiosInstanceOrganizations.get('/api/rulebook_versions/', { params })

export const getRulebookChangeSets = (
    params: object
): Promise<CerebroPaginatedResponse<ChangeSet>> =>
    axiosInstanceOrganizations.get('/api/change_sets/', { params })
