import { call, put } from 'redux-saga/effects'

import {
    fetchOrganizationMembersSuccess,
    fetchOrganizationGroupMembersSuccess,
} from 'actions/orgs'
import { cerebroApiSaga } from 'sagas/common'
import {
    getOrganizationGroupMembers,
    getOrganizationMembers,
} from 'services/cerebroApi/orgScope/resourceApi'

export function* fetchOrganizationMembersSaga(organizationId) {
    const {
        data: { results: members },
    } = yield call(
        cerebroApiSaga,
        null,
        getOrganizationMembers,
        organizationId,
        { limit: 1000 }
    )

    yield put(fetchOrganizationMembersSuccess({ members, organizationId }))
}

export function* fetchOrganizationGroupMembersSaga(organizationGroupId) {
    const {
        data: { results: members },
    } = yield call(
        cerebroApiSaga,
        null,
        getOrganizationGroupMembers,
        organizationGroupId
    )
    yield put(
        fetchOrganizationGroupMembersSuccess({ members, organizationGroupId })
    )
}
