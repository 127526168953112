import { ReactElement } from 'react'

import { LoadingOutlined } from '@ant-design/icons'
import { Translation } from 'react-i18next'

import { CheckFilledIcon } from 'components/Icons'
import {
    isChangeSetDryRun,
    isChangeSetPending,
    wasChangeSetManuallyTriggered,
} from 'helpers/rulebookChangeSet'
import { CellRenderProps, ChangeSet, RulebookChangeState } from 'types'

import styles from './styles.scss'

interface StatesMapProps {
    translation: ReactElement<typeof Translation>
    icon?: ReactElement
    className?: string
}

const iconSize = 23

const statesMap: { [key: string]: StatesMapProps } = {
    failure: {
        translation: (
            <Translation>{(t) => t('common:failure', 'Failure')}</Translation>
        ),
        className: 'failure',
    },
    dryRun_scheduled_running: {
        icon: <LoadingOutlined spin style={{ fontSize: iconSize }} />,
        translation: (
            <Translation>
                {(t) =>
                    t(
                        'automations:rulebook.fields.activity.dryRun_scheduled_running',
                        'Scheduled Dry Run - Running'
                    )
                }
            </Translation>
        ),
    },
    dryRun_manual_running: {
        icon: <LoadingOutlined spin style={{ fontSize: iconSize }} />,
        translation: (
            <Translation>
                {(t) =>
                    t(
                        'automations:rulebook.fields.activity.dryRun_manual_running',
                        'Manual Dry Run - Running'
                    )
                }
            </Translation>
        ),
    },
    run_scheduled_running: {
        icon: <LoadingOutlined spin style={{ fontSize: iconSize }} />,
        translation: (
            <Translation>
                {(t) =>
                    t(
                        'automations:rulebook.fields.activity.run_scheduled_running',
                        'Scheduled Run - Running'
                    )
                }
            </Translation>
        ),
    },
    dryRun_manual_withChanges_allChangesDone: {
        icon: <CheckFilledIcon style={{ fontSize: iconSize }} />,
        className: 'success',
        translation: (
            <Translation>
                {(t) =>
                    t(
                        'automations:rulebook.fields.activity.dryRun_manual_withChanges_allChangesDone',
                        'Manual Run - Changes Accepted'
                    )
                }
            </Translation>
        ),
    },
    dryRun_manual_withChanges_noChangesDone: {
        className: 'neutral',
        translation: (
            <Translation>
                {(t) =>
                    t(
                        'automations:rulebook.fields.activity.dryRun_manual_withChanges_noChangesDone',
                        'Manual Dry Run - Changes Proposed'
                    )
                }
            </Translation>
        ),
    },
    dryRun_manual_noChanges: {
        translation: (
            <Translation>
                {(t) =>
                    t(
                        'automations:rulebook.fields.activity.dryRun_manual_noChanges',
                        'Manual Dry Run - No Changes Proposed'
                    )
                }
            </Translation>
        ),
    },
    dryRun_manual_withChanges_partiallyCompleted: {
        icon: <CheckFilledIcon style={{ fontSize: iconSize }} />,
        className: 'success',
        translation: (
            <Translation>
                {(t) =>
                    t(
                        'automations:rulebook.fields.activity.dryRun_manual_withChanges_partiallyCompleted',
                        'Manual Run - Some Changes Accepted'
                    )
                }
            </Translation>
        ),
    },
    dryRun_manual_withChanges_partiallyFailed: {
        className: 'failure',
        translation: (
            <Translation>
                {(t) =>
                    t(
                        'automations:rulebook.fields.activity.dryRun_manual_withChanges_partiallyFailed',
                        'Manual Dry Run - Some Changes Have Failed'
                    )
                }
            </Translation>
        ),
    },
    dryRun_scheduled_withChanges_noChangesDone: {
        className: 'automatic-changes-proposed',
        translation: (
            <Translation>
                {(t) =>
                    t(
                        'automations:rulebook.fields.activity.dryRun_scheduled_withChanges_noChangesDone',
                        'Scheduled Dry Run - Changes Proposed'
                    )
                }
            </Translation>
        ),
    },
    dryRun_scheduled_withChanges_allChangesDone: {
        icon: <CheckFilledIcon style={{ fontSize: iconSize }} />,
        className: 'success',
        translation: (
            <Translation>
                {(t) =>
                    t(
                        'automations:rulebook.fields.activity.dryRun_scheduled_withChanges_allChangesDone',
                        'Scheduled Dry Run - Changes Manually Accepted'
                    )
                }
            </Translation>
        ),
    },
    dryRun_scheduled_noChanges: {
        translation: (
            <Translation>
                {(t) =>
                    t(
                        'automations:rulebook.fields.activity.dryRun_scheduled_noChanges',
                        'Schedule Dry Run - No Changes Proposed'
                    )
                }
            </Translation>
        ),
    },
    dryRun_scheduled_withChanges_partiallyCompleted: {
        icon: <CheckFilledIcon style={{ fontSize: iconSize }} />,
        className: 'success',
        translation: (
            <Translation>
                {(t) =>
                    t(
                        'automations:rulebook.fields.activity.dryRun_scheduled_withChanges_partiallyCompleted',
                        'Scheduled Dry Run - Some Changes Manually Accepted'
                    )
                }
            </Translation>
        ),
    },
    dryRun_scheduled_withChanges_partiallyFailed: {
        className: 'failure',
        translation: (
            <Translation>
                {(t) =>
                    t(
                        'automations:rulebook.fields.activity.dryRun_scheduled_withChanges_partiallyFailed',
                        'Scheduled Dry Run - Some Changes Have Failed'
                    )
                }
            </Translation>
        ),
    },
    run_scheduled_noChanges: {
        translation: (
            <Translation>
                {(t) =>
                    t(
                        'automations:rulebook.fields.activity.run_scheduled_noChanges',
                        'Schedule Run - No Changes'
                    )
                }
            </Translation>
        ),
    },
    run_scheduled_withChanges_noChangesDone: {
        className: 'neutral',
        translation: (
            <Translation>
                {(t) =>
                    t(
                        'automations:rulebook.fields.activity.run_scheduled_withChanges_noChangesDone',
                        'Scheduled Run - Changes Proposed'
                    )
                }
            </Translation>
        ),
    },
    run_scheduled_withChanges_allChangesDone: {
        icon: <CheckFilledIcon style={{ fontSize: iconSize }} />,
        className: 'success',
        translation: (
            <Translation>
                {(t) =>
                    t(
                        'automations:rulebook.fields.activity.run_scheduled_withChanges_allChangesDone',
                        'Scheduled Run - Changes Made'
                    )
                }
            </Translation>
        ),
    },
    run_scheduled_withChanges_partiallyCompleted: {
        icon: <CheckFilledIcon style={{ fontSize: iconSize }} />,
        className: 'success',
        translation: (
            <Translation>
                {(t) =>
                    t(
                        'automations:rulebook.fields.activity.run_scheduled_withChanges_partiallyCompleted',
                        'Scheduled Run - Some Changes Made'
                    )
                }
            </Translation>
        ),
    },
    run_scheduled_withChanges_partiallyFailed: {
        className: 'failure',
        translation: (
            <Translation>
                {(t) =>
                    t(
                        'automations:rulebook.fields.activity.run_scheduled_withChanges_partiallyFailed',
                        'Scheduled Run - Some Changes Have Failed'
                    )
                }
            </Translation>
        ),
    },
}

export default function RulebookActivityCell({
    record,
}: CellRenderProps<ChangeSet>): ReactElement {
    const textKeyParts = []
    if (record.state === RulebookChangeState.FAILURE) {
        textKeyParts.push('failure')
    } else {
        const dryRun = isChangeSetDryRun(record) ? 'dryRun' : 'run'
        const scheduled = wasChangeSetManuallyTriggered(record)
            ? 'manual'
            : 'scheduled'
        textKeyParts.push(dryRun, scheduled)
        if (isChangeSetPending(record)) {
            textKeyParts.push('running')
        } else if (
            RulebookChangeState.COMPLETED_WITHOUT_CHANGES === record.state ||
            !record.change_count
        ) {
            textKeyParts.push('noChanges')
        } else {
            textKeyParts.push('withChanges')
            if (record.state === RulebookChangeState.DRY_RUN) {
                textKeyParts.push('noChangesDone')
            } else if (
                record.state === RulebookChangeState.PARTIALLY_COMPLETED
            ) {
                textKeyParts.push('partiallyCompleted')
            } else if (record.state === RulebookChangeState.PARTIALLY_FAILED) {
                textKeyParts.push('partiallyFailed')
            } else if (
                record.state === RulebookChangeState.COMPLETED_WITH_CHANGES
            ) {
                textKeyParts.push('allChangesDone')
            }
        }
    }

    const textKey = textKeyParts.join('_')
    if (statesMap[textKey]) {
        const stateDescriptor = statesMap[textKey]
        return (
            <span
                className={`${styles['state-wrapper']} ${
                    stateDescriptor.className
                        ? styles[stateDescriptor.className]
                        : ''
                }`}
            >
                {stateDescriptor.icon && (
                    <span className={styles.icon}>{stateDescriptor.icon}</span>
                )}
                {stateDescriptor.translation}
            </span>
        )
    }
    return <span>{record.state}</span>
}
