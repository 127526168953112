import { handleActions } from 'redux-actions'

import { labelCampaignsTableColumnsConfig } from 'configuration/tables'
import {
    CAMPAIGN_NAME,
    FACT_TYPES,
    REGIONS,
    COUNTRIES,
    BRANDS,
    PORTFOLIOS,
    CAMPAIGNS,
    CAMPAIGN_STATES,
    CAMPAIGN_TARGETING_TYPES,
    AUTOMATIONS,
    METRIC_FILTERS,
    DATES,
} from 'const/filters'
import { LOCAL_FILTERS, FILTER_SETTINGS } from 'const/reducerKeys'
import { serializeColumns } from 'helpers/tables'
import { defaultTab, getDefaultTable } from 'reducers/ui/defaults'

const initialState = {
    ...defaultTab,
    [LOCAL_FILTERS]: {
        [CAMPAIGN_NAME]: null,
        [FACT_TYPES]: [],
        [REGIONS]: [],
        [COUNTRIES]: [],
        [BRANDS]: [],
        [PORTFOLIOS]: [],
        [CAMPAIGNS]: [],
        [CAMPAIGN_STATES]: [],
        [CAMPAIGN_TARGETING_TYPES]: [],
        [AUTOMATIONS]: [],
        [METRIC_FILTERS]: [],
    },
    [FILTER_SETTINGS]: {
        anchored: [CAMPAIGN_NAME, DATES, FACT_TYPES],
        order: [
            METRIC_FILTERS,
            REGIONS,
            COUNTRIES,
            BRANDS,
            PORTFOLIOS,
            CAMPAIGNS,
            CAMPAIGN_STATES,
            CAMPAIGN_TARGETING_TYPES,
            AUTOMATIONS,
        ],
        displayState: {
            [METRIC_FILTERS]: true,
            [REGIONS]: true,
            [COUNTRIES]: true,
            [BRANDS]: true,
            [PORTFOLIOS]: true,
            [CAMPAIGNS]: true,
            [CAMPAIGN_STATES]: true,
            [CAMPAIGN_TARGETING_TYPES]: true,
            [AUTOMATIONS]: true,
        },
    },
    table: getDefaultTable(serializeColumns(labelCampaignsTableColumnsConfig)),
}

export default handleActions({}, initialState)
