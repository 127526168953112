import {
    average_rating__last_value,
    commission_from_sale__sum,
    commission_rate,
    gross_sales_revenue__sum,
    gross_shipping_revenue__sum,
    inventory_count__last_value,
    payable_to_partner__sum,
    price__last_value,
    refunded_retail_sales__sum,
    reviews_count__last_value,
    shipped_quantity__sum,
    total_tender__sum,
} from 'configuration/fieldCreators/metrics/walmartMarketplace'

import { makeMapping } from './helpers'

const WM_MARKETPLACE_METRICS_COLUMNS = [
    shipped_quantity__sum({ isVisible: true }),
    gross_sales_revenue__sum({ isVisible: true }),
    total_tender__sum({ isVisible: true }),
    payable_to_partner__sum({ isVisible: true }),
    commission_from_sale__sum({ isVisible: true }),
    commission_rate({ isVisible: true }),
    refunded_retail_sales__sum({ isVisible: true }),
    gross_shipping_revenue__sum({ isVisible: true }),
    price__last_value({ isVisible: true }),
    average_rating__last_value({ isVisible: true }),
    inventory_count__last_value({ isVisible: true }),
    reviews_count__last_value({ isVisible: true }),
]

export const WM_MARKETPLACE_METRICS_CONFIG = makeMapping(
    WM_MARKETPLACE_METRICS_COLUMNS
)

export const SPECIAL_WM_MARKETPLACE_GROUP_BY_COLUMNS = []
