import { call, put, select } from 'redux-saga/effects'

import {
    makeFetchTableRequest,
    makeFetchTableSuccess,
    makeDownloadTableSuccess,
} from 'actions/ui/shared'
import { skuProductsTableColumnsConfig } from 'configuration/tables'
import { DATES } from 'const/filters'
import { PRODUCTS_SUMMARY_PAGE } from 'const/pages'
import { userHasManageProductCogsPermissions } from 'helpers/featurePermissions'
import { formatFilters } from 'helpers/filters/productAdFacts'
import { generateReportNotification } from 'helpers/notifications'
import {
    formatPagination,
    formatCurrency,
    formatColumns,
    formatSorter,
    formatMetrics,
    formatPeriodDeltaDateRange,
} from 'helpers/params'
import { defaultDatesFilter } from 'reducers/ui/defaults'
import { cerebroApiSaga } from 'sagas/common'
import uiSagaRegistry from 'sagas/ui/registry'
import { selectUserOrganizationFeaturePermissions } from 'selectors/auth'
import {
    selectVisibleCombinedFilters,
    selectTableSettings,
    selectCurrencyCode,
    selectVisibleMetricsOfTable,
    selectVisibleColumnsOfTable,
} from 'selectors/ui'
import {
    getProductAdFactsExport,
    getProductAdFacts,
} from 'services/cerebroApi/orgScope/productAdFactsApi'
import {
    FiltersState,
    CurrencyCode,
    Field,
    ProductSkuFactRecord,
    Path,
    Table,
} from 'types'

const TAB_PATH = [PRODUCTS_SUMMARY_PAGE, 'skuProducts']
const TABLE_PATH = [...TAB_PATH, 'table']

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* fetchTableSaga(noCount: boolean) {
    const filters: FiltersState = yield select(
        selectVisibleCombinedFilters,
        TAB_PATH
    )
    const {
        pagination,
        sorter,
        showPeriodDeltas,
        periodDeltaType,
        periodDeltaDateRange,
        showTotalRow,
        columns,
    }: Table<any>['tableSettings'] = yield select(
        selectTableSettings,
        TABLE_PATH
    )
    const metrics: Field<ProductSkuFactRecord>[] = yield select(
        selectVisibleMetricsOfTable,
        TABLE_PATH,
        skuProductsTableColumnsConfig
    )

    let formattedMetrics = formatMetrics(
        metrics,
        showPeriodDeltas,
        showTotalRow
    )

    const hasProductCogsPermissions = userHasManageProductCogsPermissions(
        yield select(selectUserOrganizationFeaturePermissions)
    )

    // COGS is calculated as a metric in Banner but treated as a column in Forge
    // We need to manually query it if visible
    if (hasProductCogsPermissions) {
        columns.forEach((column) => {
            if (column.isVisible && column.id.startsWith('sku__')) {
                if (formattedMetrics.metrics) {
                    formattedMetrics = {
                        metrics: [
                            column.id.replace('sku__', ''),
                            formattedMetrics.metrics,
                        ].join(','),
                    }
                } else {
                    formattedMetrics = {
                        metrics: column.id.replace('sku__', ''),
                    }
                }
            }
        })
    }

    const currency: CurrencyCode = yield select(selectCurrencyCode)
    const params = {
        ...formatFilters(filters),
        ...formatPagination(pagination),
        ...formatSorter(sorter),
        ...formattedMetrics,
        ...formatCurrency(currency),
        ...formatPeriodDeltaDateRange(
            showPeriodDeltas,
            periodDeltaType,
            periodDeltaDateRange,
            filters[DATES] ?? defaultDatesFilter
        ),
        group_by:
            'product_ad__asin,product_ad__sku,profile__country_code,metadata_id',
    }
    yield call(
        cerebroApiSaga,
        makeFetchTableSuccess(TABLE_PATH),
        getProductAdFacts,
        params,
        {
            headers: { noCount },
        }
    )
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* downloadTableSaga(path: Path) {
    const filters: FiltersState = yield select(
        selectVisibleCombinedFilters,
        TAB_PATH
    )
    const { sorter, showPeriodDeltas, periodDeltaType, periodDeltaDateRange } =
        yield select(selectTableSettings, TABLE_PATH)
    const columns: Field<ProductSkuFactRecord>[] = yield select(
        selectVisibleColumnsOfTable,
        path,
        skuProductsTableColumnsConfig
    )
    const currency: CurrencyCode = yield select(selectCurrencyCode)
    const reportName = 'Products/SKUs Report'
    const params = {
        ...formatFilters(filters),
        ...formatSorter(sorter),
        ...formatColumns(columns, showPeriodDeltas),
        ...formatCurrency(currency),
        ...formatPeriodDeltaDateRange(
            showPeriodDeltas,
            periodDeltaType,
            periodDeltaDateRange,
            filters[DATES] ?? defaultDatesFilter
        ),
        group_by: 'product_ad__asin,product_ad__sku,profile__country_code',
        async_download_name: reportName,
    }
    yield call(
        cerebroApiSaga,
        makeDownloadTableSuccess(path),
        getProductAdFactsExport,
        params
    )

    generateReportNotification(reportName)
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* fetchTabDataSaga() {
    yield put(makeFetchTableRequest(TABLE_PATH)())
}

// Register Sagas
uiSagaRegistry.registerSagas(TAB_PATH, {
    fetchTabDataSaga,
})

uiSagaRegistry.registerSagas(TABLE_PATH, {
    fetchTableSaga,
    downloadTableSaga,
})
