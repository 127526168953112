import { all, call, put, select } from 'redux-saga/effects'

import { fetchUserOrganizationFeaturePermissionsRequest } from 'actions/auth'
import { fetchOrganizationGroupSuccess } from 'actions/orgs'
import {
    fetchOrganizationGroupBrandsSuccess,
    fetchOrganizationGroupDspAdvertiserOptionsSuccess,
    fetchOrganizationGroupIntegrationsSuccess,
    fetchOrganizationGroupPendingInvitationsSuccess,
} from 'actions/ui/organizationGroupPage'
import { ORGANIZATION_GROUP_PAGE } from 'const/pages'
import { userHasOrgAdminPermissions } from 'helpers/featurePermissions'
import { cerebroApiSaga } from 'sagas/common'
import { fetchOrganizationGroupSaga } from 'sagas/orgs/groups/workers'
import { fetchOrganizationGroupMembersSaga } from 'sagas/orgs/members'
import uiSagaRegistry from 'sagas/ui/registry'
import {
    selectDomainValue,
    selectUserOrganizationFeaturePermissions,
} from 'selectors/auth'
import { selectResourceParamsOfPage } from 'selectors/ui'
import { getDspAdvertisers } from 'services/cerebroApi/orgScope/dspApi'
import {
    getAdAccounts,
    getOrganizationIntegrations,
    getOrganizationInvitations,
    patchOrganizationGroup,
} from 'services/cerebroApi/orgScope/resourceApi'
import { OrganizationGroupRequest } from 'types'

const PAGE_PATH = [ORGANIZATION_GROUP_PAGE]

function* fetchBrandsSaga(): any {
    yield call(
        cerebroApiSaga,
        fetchOrganizationGroupBrandsSuccess,
        getAdAccounts,
        {
            limit: 1000,
        }
    )
}

function* fetchIntegrationsSaga(): any {
    const organizationId = yield select(selectDomainValue, 'organizationId')
    const permissions = yield select(selectUserOrganizationFeaturePermissions)

    if (userHasOrgAdminPermissions(permissions)) {
        yield call(
            cerebroApiSaga,
            fetchOrganizationGroupIntegrationsSuccess,
            getOrganizationIntegrations,
            organizationId
        )
    }
}

function* fetchDspAdvertiserOptionsSaga(): any {
    const userPermissions = yield select(
        selectUserOrganizationFeaturePermissions
    )

    if (userHasOrgAdminPermissions(userPermissions)) {
        yield call(
            cerebroApiSaga,
            fetchOrganizationGroupDspAdvertiserOptionsSuccess,
            getDspAdvertisers,
            {
                limit: 1000,
            }
        )
    }
}

export function* fetchPendingInvitationsWorker(): any {
    const { organizationGroupId } = yield select(
        selectResourceParamsOfPage,
        ORGANIZATION_GROUP_PAGE
    )
    const organizationId = yield select(selectDomainValue, 'organizationId')
    const permissions = yield select(selectUserOrganizationFeaturePermissions)

    if (userHasOrgAdminPermissions(permissions)) {
        const params = {
            state: 'pending',
            organization_groups__id: organizationGroupId,
        }

        yield call(
            cerebroApiSaga,
            fetchOrganizationGroupPendingInvitationsSuccess,
            getOrganizationInvitations,
            organizationId,
            params
        )
    }
}

function* updateOrganizationGroupSaga(
    { organizationGroupId }: { organizationGroupId: string | number },
    data: Partial<OrganizationGroupRequest>,
    successAction: any
): any {
    const { data: organizationGroup } = yield call(
        cerebroApiSaga,
        successAction,
        patchOrganizationGroup,
        organizationGroupId,
        data
    )

    yield put(fetchOrganizationGroupSuccess(organizationGroup))

    // refresh user feature permissions
    const organizationId = yield select(selectDomainValue, 'organizationId')
    yield put(
        fetchUserOrganizationFeaturePermissionsRequest({
            organizationId,
        })
    )
}

function* fetchPageDataSaga(): any {
    const { organizationGroupId } = yield select(
        selectResourceParamsOfPage,
        ORGANIZATION_GROUP_PAGE
    )

    yield all([
        call(fetchOrganizationGroupSaga, organizationGroupId),
        call(fetchOrganizationGroupMembersSaga, organizationGroupId),

        call(fetchBrandsSaga),
        call(fetchIntegrationsSaga),
        call(fetchDspAdvertiserOptionsSaga),
        call(fetchPendingInvitationsWorker),
    ])
}

function* mountPageSaga(): any {
    yield call(fetchPageDataSaga)
}

uiSagaRegistry.registerSagas(PAGE_PATH, {
    mountPageSaga,
    fetchPageDataSaga,
    updateResourceSaga: updateOrganizationGroupSaga,
})
