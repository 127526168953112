import { AutomationTypeContainer } from 'components/AutomationType'
import { capabilityIdRenderer } from 'components/FieldRenderers'
import { AutomationProfileLink } from 'components/Links'
import {
    automationCapabilityId,
    automationDeleteProfile,
    automationDetachProfile,
    automationEndDate,
    automationProfileCreatedDate,
    automationProfileHasOverrides,
    automationProfileName,
    automationProfileTaskCount,
    automationStartDate,
    automationState,
} from 'configuration/fieldCreators/automation'
import { campaignId, campaignState } from 'configuration/fieldCreators/campaign'
import { AutomationTask, AutomationProfile, Field } from 'types'

export const enabledAutomationsTableColumnsConfig: Field<AutomationTask>[] = [
    automationCapabilityId({
        fixed: 'left',
        renderOptions: {
            render: (cellRenderProps) =>
                capabilityIdRenderer({
                    cellRenderProps,
                }),
        },
    }),
    campaignId(),
    automationProfileName({
        dataIndex: ['automation_profile', 'name'],
        renderOptions: {
            render: ({ record }) => (
                <AutomationProfileLink
                    automationProfile={record.automation_profile}
                />
            ),
        },
    }),
    automationStartDate(),
    automationEndDate(),
]

export const automationProfilesTableColumnsConfig: Field<AutomationProfile>[] =
    [
        automationProfileName({
            fixed: 'left',
        }),
        automationCapabilityId({
            dataIndex: ['capability'],
            renderOptions: {
                render: ({ value }) => (
                    <AutomationTypeContainer capabilityId={value} />
                ),
            },
        }),
        automationProfileTaskCount(),
        automationProfileCreatedDate(),
        automationDeleteProfile(),
    ]

export const profileAutomationsTableColumnsConfig: Field<AutomationTask>[] = [
    campaignId(),
    campaignState({ readonly: true }),
    automationState(),
    automationStartDate(),
    automationEndDate(),
    automationProfileHasOverrides(),
    automationDetachProfile(),
]
