import { memo, ReactElement } from 'react'

import { AxiosResponse } from 'axios'
import { Field } from 'formik'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import noop from 'lodash/noop'
import { object, string } from 'yup'

import { FormikInput } from 'components/formik'
import { UNDEFINED_VALUE } from 'const/formatting'
import { isUnset } from 'helpers/utilities'
import { useCerebroApiRequest } from 'hooks'
import message from 'utilities/message'

import { SingleValueField } from '../SingleValueField'

interface Props<RecordType> {
    disabled?: boolean
    fieldName: string
    fieldPath: string[]
    readOnly?: boolean
    record: RecordType
    required?: boolean
    serializeFieldValues?: (values: { [fieldName: string]: string }) => object
    updateRequestApi: (...args: any) => Promise<AxiosResponse<any>>
    updateRequestData: any
    updateRequestSuccessMesg: string
    updateRequestCallback?: () => void
}

function TextInputField<RecordType>({
    disabled = false,
    fieldName,
    fieldPath = [],
    readOnly = false,
    record,
    required = false,
    serializeFieldValues = (values) => values,
    updateRequestApi,
    updateRequestData,
    updateRequestSuccessMesg,
    updateRequestCallback = noop,
}: Props<RecordType>): ReactElement {
    const makeCerebroApiRequest = useCerebroApiRequest()
    const validationSchema = object().shape({
        [fieldName]: required ? string().required() : string(),
    })

    return (
        <SingleValueField
            disabled={disabled}
            fieldName={fieldName}
            formatFieldValue={(values) =>
                isUnset(values[fieldName]) || values[fieldName] === ''
                    ? UNDEFINED_VALUE
                    : values[fieldName]
            }
            initialValues={{ [fieldName]: get(record, fieldPath) }}
            onSave={(values) =>
                makeCerebroApiRequest({
                    request: updateRequestApi(
                        updateRequestData,
                        serializeFieldValues(values)
                    ),
                    onRequestSuccess: () => {
                        message.success(updateRequestSuccessMesg)
                    },
                })
            }
            readOnly={readOnly}
            validationSchema={validationSchema}
            updateRequestCallback={updateRequestCallback}
        >
            {({ isSubmitting }) => (
                <Field
                    component={FormikInput}
                    disabled={isSubmitting}
                    name={fieldName}
                />
            )}
        </SingleValueField>
    )
}

export default memo(TextInputField, isEqual) as typeof TextInputField
