import { ReactElement } from 'react'

import Icon from '@ant-design/icons'

import TrendDownIconSvg from './TrendDownIconSvg'
import { IconProps } from '../localTypes'

const TrendDownIcon = (props: IconProps): ReactElement => (
    <Icon component={TrendDownIconSvg} {...props} />
)

export default TrendDownIcon
