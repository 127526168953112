import { call, select, put } from 'redux-saga/effects'

import { makeFetchTableRequest, makeFetchTableSuccess } from 'actions/ui/shared'
import { adGroupsTableColumnsConfig } from 'configuration/tables'
import { DATES } from 'const/filters'
import { CAMPAIGN_PAGE } from 'const/pages'
import { formatFilters } from 'helpers/filters/adGroupFacts'
import {
    formatSorter,
    formatMetrics,
    formatPagination,
    formatCurrency,
    formatPeriodDeltaDateRange,
} from 'helpers/params'
import { cerebroApiSaga } from 'sagas/common'
import uiSagaRegistry from 'sagas/ui/registry'
import {
    selectCurrencyCode,
    selectVisibleCombinedFilters,
    selectVisibleMetricsOfTable,
    selectResourceParamsOfPage,
    selectTableSettings,
} from 'selectors/ui'
import { getAdGroupFacts } from 'services/cerebroApi/orgScope/adGroupFactsApi'

const TAB_PATH = [CAMPAIGN_PAGE, 'adGroups']
const TABLE_PATH = [...TAB_PATH, 'table']

function* fetchTableSaga(noCount) {
    const { campaignId } = yield select(
        selectResourceParamsOfPage,
        CAMPAIGN_PAGE
    )

    const filters = yield select(selectVisibleCombinedFilters, TAB_PATH)

    const {
        pagination,
        sorter,
        showPeriodDeltas,
        periodDeltaType,
        periodDeltaDateRange,
    } = yield select(selectTableSettings, TABLE_PATH)

    const metrics = yield select(
        selectVisibleMetricsOfTable,
        TABLE_PATH,
        adGroupsTableColumnsConfig
    )

    const currency = yield select(selectCurrencyCode)

    const params = {
        ...formatPagination(pagination),
        ...formatSorter(sorter),
        ...formatFilters(filters),
        ...formatMetrics(metrics, showPeriodDeltas),
        ...formatCurrency(currency),
        ...formatPeriodDeltaDateRange(
            showPeriodDeltas,
            periodDeltaType,
            periodDeltaDateRange,
            filters[DATES]
        ),
        campaign: campaignId,
        group_by: 'ad_group_id,campaign_id,profile_id',
    }

    yield call(
        cerebroApiSaga,
        makeFetchTableSuccess(TABLE_PATH),
        getAdGroupFacts,
        params,
        {
            headers: { noCount },
        }
    )
}

function* fetchTabDataSaga() {
    yield put(makeFetchTableRequest(TABLE_PATH)())
}

// Register Sagas
uiSagaRegistry.registerSagas(TAB_PATH, {
    fetchTabDataSaga,
})

uiSagaRegistry.registerSagas(TABLE_PATH, {
    fetchTableSaga,
})
