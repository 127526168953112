import cloneDeep from 'lodash/cloneDeep'
import { handleActions } from 'redux-actions'

import { reportsSummaryTableColumnsConfig } from 'configuration/tables'
import { serializeColumns } from 'helpers/tables'
import { getDefaultTable } from 'reducers/ui/defaults'

const defaultState = {
    table: getDefaultTable(serializeColumns(reportsSummaryTableColumnsConfig), {
        sorter: {
            field: ['created_date'],
            order: 'descend',
        },
    }),
    error: null,
}

export default handleActions(
    {},
    cloneDeep(defaultState) // create clone, so the defaultState is not mutated
)
