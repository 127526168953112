import cloneDeep from 'lodash/cloneDeep'
import flow from 'lodash/fp/flow'
import set from 'lodash/fp/set'
import isEmpty from 'lodash/isEmpty'
import mapValues from 'lodash/mapValues'
import { normalize } from 'normalizr'
import { handleActions } from 'redux-actions'

import {
    // fetch dashboard
    fetchDashboardSuccess,
    fetchOrganizationSuccess,
} from 'actions/ui/dashboardPrintPage'
import { DATES } from 'const/filters'
import { serializeDatesFilter } from 'helpers/filters'
import {
    defaultWidgetState,
    defaultPaginationAndSorter,
    defaultWidgetLayout,
    defaultPage,
} from 'reducers/ui/defaults'
import { tabListSchema } from 'schemas'

const defaultState = {
    dashboard: null,
    organization: null,
    tabs: {},
    widgets: {},
    ...defaultPage,
}

export default handleActions(
    {
        // fetch dashboard
        [fetchDashboardSuccess](state, action) {
            const dashboard = action.payload
            const {
                entities: { tabs, widgets = {} },
            } = normalize(dashboard.config.tabs ?? [], tabListSchema)

            const legacyFilters = dashboard.config.filters

            const getTabFilters = (tab) => {
                const filters =
                    cloneDeep(tab.localFilters || legacyFilters) ?? {}
                if (!isEmpty(filters[DATES])) {
                    filters[DATES] = serializeDatesFilter(filters[DATES])
                }
                return filters
            }

            return flow(
                set('dashboard', dashboard),
                set('organizationId', dashboard.organization_id),
                set(
                    'tabs',
                    mapValues(tabs, (tab) => ({
                        ...tab,
                        localFilters: { ...getTabFilters(tab) },
                    }))
                ),
                set(
                    'widgets',
                    mapValues(widgets, (widget) => ({
                        ...widget,
                        filters: {
                            ...widget.filters,
                            ...(!isEmpty(widget.filters[DATES]) && {
                                [DATES]: serializeDatesFilter(
                                    widget.filters[DATES]
                                ),
                            }),
                        },
                        ...defaultWidgetState,
                        ...defaultPaginationAndSorter(widget),
                        ...defaultWidgetLayout(widget),
                    }))
                )
            )(state)
        },

        // fetch organization
        [fetchOrganizationSuccess](state, action) {
            const organization = action.payload
            return set(['organization'], organization, state)
        },
    },
    cloneDeep(defaultState) // create clone, so the defaultState is not mutated
)
