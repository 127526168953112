import { ReactElement } from 'react'

import { Select, Spin } from 'antd'

import { SelectOptionContent } from 'components/SelectOptionContent'
import { AUTO } from 'const/targetingTypes'
import { Campaign } from 'types'

import { useCampaignOptions } from './localHooks'
import { CampaignSearchSelectProps } from './localTypes'

interface OptionMetadata {
    disabled: boolean
    metadata: string
}

const getOptionMetadata = (campaign: Campaign): OptionMetadata => {
    if (campaign.targeting_type === AUTO) {
        return {
            disabled: true,
            metadata: 'Cannot add Keywords to Auto Campaigns',
        }
    }
    if (campaign.campaign_type === 'sponsoredDisplay') {
        return {
            disabled: true,
            metadata: 'Cannot add Keywords to a Sponsored Display Campaigns',
        }
    }
    // catch all in case other campaign types are introduced
    if (
        !['sponsoredProducts', 'headlineSearch'].includes(
            campaign.campaign_type
        )
    ) {
        return {
            disabled: true,
            metadata: `Cannot add Keywords to ${campaign.campaign_type} Campaign type`,
        }
    }
    return { disabled: false, metadata: '' }
}

const CampaignForSearchTermKeywordSearchSelect = ({
    profileId,
    value,
    onChange,
    className,
    style = { width: '100%' },
    defaultOptions,
    isOptionDisabled = (option) => getOptionMetadata(option).disabled,
    optionMetadata = (option) => getOptionMetadata(option).metadata,
}: CampaignSearchSelectProps): ReactElement => {
    const [options, loading, onSearch, resetLoadingState] = useCampaignOptions({
        profileId,
        isOptionDisabled,
        optionMetadata,
        defaultOptions,
    })
    return (
        <Select
            className={className}
            filterOption={false}
            placeholder="Select a Campaign..."
            loading={loading}
            notFoundContent={loading ? <Spin size="small" /> : null}
            onChange={onChange}
            onSearch={onSearch}
            onSelect={resetLoadingState}
            showSearch
            style={style}
            value={value}
        >
            {options.map((option, idx) => (
                <Select.Option
                    key={idx}
                    value={option.value}
                    title={option.label}
                    disabled={option.disabled}
                >
                    <SelectOptionContent
                        label={option.label}
                        metadata={option.metadata}
                    />
                </Select.Option>
            ))}
        </Select>
    )
}

export default CampaignForSearchTermKeywordSearchSelect
