import find from 'lodash/find'

import { negativeKeywordIdRenderer } from 'components/FieldRenderers'
import { UNDEFINED_VALUE } from 'const/formatting'
import { NEGATIVE_MATCH_TYPE_OPTIONS_WITH_BOTH } from 'const/matchTypes'
import { NEGATIVE_KEYWORD_STATES } from 'const/resourceStates'
import { isUnset } from 'helpers/utilities'
import { Field } from 'types'

import {
    createActionField,
    createField,
    createLabelLookupField,
} from './createField'
import { FieldCreatorOptions } from './localTypes'

export function negativeKeywordId<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['id']
    return createField({
        ...options,
        id: 'negative_keyword_id',
        name: 'Negative Keyword',
        shortName: 'Negative Keyword',
        minWidth: 80,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: (props) => negativeKeywordIdRenderer<any>(props),
        },
        width: 200,
        sorter: options.sorter ?? false,
    })
}

export function negativeKeywordMatchType<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['match_type']
    return createField({
        ...options,
        id: 'negative_keyword_match_type',
        name: 'Match Type',
        shortName: 'Match Type',
        minWidth: 70,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: ({ value }) =>
                isUnset(value)
                    ? UNDEFINED_VALUE
                    : find(NEGATIVE_MATCH_TYPE_OPTIONS_WITH_BOTH, [
                          'value',
                          value,
                      ])?.label,
        },
        width: 110,
        sorter: options.sorter ?? false,
    })
}

export function negativeKeywordState<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['state']
    return createLabelLookupField({
        ...options,
        id: 'negative_keyword_state',
        name: 'Negative Keyword State',
        shortName: 'State',
        minWidth: 100,
        dataIndex,
        labels: NEGATIVE_KEYWORD_STATES,
        sorter: options.sorter ?? false,
    })
}

export function archiveNegativeKeyword<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createActionField({
        ...options,
        id: 'archive_keyword',
        dataIndex: ['id'],
        isResizeable: false,
        minWidth: 68,
        align: 'center',
        sorter: false,
        fixed: 'right',
    })
}
