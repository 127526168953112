import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'

import {
    DATES,
    REGIONS,
    COUNTRIES,
    BRANDS,
    LABELS,
    METRIC_FILTERS,
    PORTFOLIOS,
    FACT_TYPES,
} from 'const/filters'
import { PORTFOLIO_LABELS } from 'const/resourceTypes'
import { formatCerebroDate } from 'helpers/formatting'

import { formatMetricFiltersForParams } from './metrics'
import { presetRanges } from '../dateRange'
import { formatBooleanFilter } from '../filters'

export const formatFilters = (filters) => {
    let params = {}

    if (!isEmpty(filters[DATES])) {
        if (isArray(filters[DATES])) {
            params.report_date_min = formatCerebroDate(filters[DATES][0])
            params.report_date_max = formatCerebroDate(filters[DATES][1])
        } else {
            const { range } = presetRanges()[filters[DATES]]
            params.report_date_min = formatCerebroDate(range[0])
            params.report_date_max = formatCerebroDate(range[1])
        }
    }

    if (!isEmpty(filters[REGIONS])) {
        params.profile__region__in = filters[REGIONS].map(
            (item) => item.value
        ).join()
    }

    if (!isEmpty(filters[COUNTRIES])) {
        params.profile__country_code__in = filters[COUNTRIES].map(
            (item) => item.value
        ).join()
    }

    if (!isEmpty(filters[BRANDS])) {
        params.profile_id__in = filters[BRANDS].map((item) => item.value).join()
    }

    if (!isEmpty(filters[PORTFOLIOS])) {
        params.portfolio_id__in = filters[PORTFOLIOS].map(
            (item) => item.value
        ).join()
    }

    if (!isEmpty(filters[LABELS])) {
        params.complex_label_filter = formatBooleanFilter(
            filters[LABELS],
            PORTFOLIO_LABELS
        )
    }

    const metricFilters = filters[METRIC_FILTERS]
    if (!isEmpty(metricFilters)) {
        params = { ...params, ...formatMetricFiltersForParams(metricFilters) }
    }

    if (!isEmpty(filters[FACT_TYPES])) {
        params.campaign__campaign_type__in = filters[FACT_TYPES].map(
            (item) => item.value
        ).join()
    }

    return params
}
