import { combineChildrenReducers } from 'utilities/reducers'

// Reducer that handles page level data, that is, parent reducer

// Children reducers
import keywordsReducer from './keywords'
import negativeKeywordsReducer from './negativeKeywords'
import pageReducer from './page'
import targetsReducer from './targets'

export default combineChildrenReducers(pageReducer, {
    keywords: keywordsReducer,
    negativeKeywords: negativeKeywordsReducer,
    targets: targetsReducer,
})
