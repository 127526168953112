import { all, takeLatest } from 'redux-saga/effects'

import { fetchOrganizationsRequest } from 'actions/orgs'

import { fetchOrganizationsWorker } from './workers'

export default function* organizationsWatcher() {
    yield all([
        takeLatest(
            fetchOrganizationsRequest.toString(),
            fetchOrganizationsWorker
        ),
    ])
}
