import {
    makeCountMetricFieldFunc,
    makeCurrencyMetricFieldFunc,
    makePercentageMetricFieldFunc,
} from './localUtils'

// Clicks
export const click_throughs__sum = makeCountMetricFieldFunc({
    name: 'Click-Throughs',
    shortName: 'Click-Throughs',
    deltas: ['change', 'percentage_change'],
    id: 'click_throughs__sum',
})

export const purchases_clicks_14_day__sum = makeCountMetricFieldFunc({
    name: 'Purchases Clicks',
    shortName: 'Purchases Clicks',
    deltas: ['change', 'percentage_change'],
    id: 'purchases_clicks_14_day__sum',
})

export const total_purchases_clicks_14_day__sum = makeCountMetricFieldFunc({
    name: 'Total Purchases Clicks',
    shortName: 'Total Purchases Clicks',
    deltas: ['change', 'percentage_change'],
    id: 'total_purchases_clicks_14_day__sum',
})

export const new_to_brand_purchases_clicks_14_day__sum =
    makeCountMetricFieldFunc({
        name: 'New-To-Brand Purchases Clicks',
        shortName: 'New-To-Brand (NTB) Clicks (14 Day)',
        deltas: ['change', 'percentage_change'],
        id: 'new_to_brand_purchases_clicks_14_day__sum',
    })

export const total_new_to_brand_purchases_clicks_14_day__sum =
    makeCountMetricFieldFunc({
        name: 'Total New-To-Brand Purchases Clicks',
        shortName: 'Total New-To-Brand (NTB) Clicks (14 Day)',
        deltas: ['change', 'percentage_change'],
        id: 'total_new_to_brand_purchases_clicks_14_day__sum',
    })

// Views
export const purchases_views_14_day__sum = makeCountMetricFieldFunc({
    name: 'Purchases Views',
    shortName: 'Purchases Views',
    deltas: ['change', 'percentage_change'],
    id: 'purchases_views_14_day__sum',
})

export const total_purchases_views_14_day__sum = makeCountMetricFieldFunc({
    name: 'Total Purchases Views',
    shortName: 'Total Purchases Views',
    deltas: ['change', 'percentage_change'],
    id: 'total_purchases_views_14_day__sum',
})

export const new_to_brand_purchases_views_14_day__sum =
    makeCountMetricFieldFunc({
        name: 'New-To-Brand Purchases Views',
        shortName: 'New-To-Brand Purchases Views',
        deltas: ['change', 'percentage_change'],
        id: 'new_to_brand_purchases_views_14_day__sum',
    })

export const total_new_to_brand_purchases_views_14_day__sum =
    makeCountMetricFieldFunc({
        name: 'Total New-To-Brand Purchases Views',
        shortName: 'Total New-To-Brand Purchases Views',
        deltas: ['change', 'percentage_change'],
        id: 'total_new_to_brand_purchases_views_14_day__sum',
    })

// Purchases
export const purchases_14_day__sum = makeCountMetricFieldFunc({
    name: 'Purchases',
    shortName: 'Purchases',
    deltas: ['change', 'percentage_change'],
    id: 'purchases_14_day__sum',
})

export const total_purchases_14_day__sum = makeCountMetricFieldFunc({
    name: 'Total Purchases',
    shortName: 'Total Purchases',
    deltas: ['change', 'percentage_change'],
    id: 'total_purchases_14_day__sum',
})

export const new_to_brand_purchases_14_day__sum = makeCountMetricFieldFunc({
    name: 'New-To-Brand Purchases',
    shortName: 'New-To-Brand Purchases',
    deltas: ['change', 'percentage_change'],
    id: 'new_to_brand_purchases_14_day__sum',
})

export const total_new_to_brand_purchases_14_day__sum =
    makeCountMetricFieldFunc({
        name: 'Total New-To-Brand Purchases',
        shortName: 'Total New-To-Brand Purchases',
        deltas: ['change', 'percentage_change'],
        id: 'total_new_to_brand_purchases_14_day__sum',
    })

// Units Sold
export const units_sold_14_day__sum = makeCountMetricFieldFunc({
    name: 'Units Sold',
    shortName: 'Units Sold',
    deltas: ['change', 'percentage_change'],
    id: 'units_sold_14_day__sum',
})

export const total_units_sold_14_day__sum = makeCountMetricFieldFunc({
    name: 'Total Units Sold',
    shortName: 'Total Units Sold',
    deltas: ['change', 'percentage_change'],
    id: 'total_units_sold_14_day__sum',
})

export const new_to_brand_units_sold_14_day__sum = makeCountMetricFieldFunc({
    name: 'New-To-Brand Units Sold',
    shortName: 'New-To-Brand Units Sold',
    deltas: ['change', 'percentage_change'],
    id: 'new_to_brand_units_sold_14_day__sum',
})

export const total_new_to_brand_units_sold_14_day__sum =
    makeCountMetricFieldFunc({
        name: 'Total New-To-Brand Units Sold',
        shortName: 'Total New-To-Brand Units Sold',
        deltas: ['change', 'percentage_change'],
        id: 'total_new_to_brand_units_sold_14_day__sum',
    })

// Sales
export const sales_14_day__sum = makeCurrencyMetricFieldFunc({
    name: 'Product Sales',
    shortName: 'Product Sales',
    deltas: ['change', 'percentage_change'],
    id: 'sales_14_day__sum',
})

export const total_sales_14_day__sum = makeCurrencyMetricFieldFunc({
    name: 'Total Product Sales',
    shortName: 'Total Product Sales',
    deltas: ['change', 'percentage_change'],
    id: 'total_sales_14_day__sum',
})

export const new_to_brand_product_sales_14_day__sum =
    makeCurrencyMetricFieldFunc({
        name: 'New-To-Brand Product Sales',
        shortName: 'New-To-Brand Product Sales',
        deltas: ['change', 'percentage_change'],
        id: 'new_to_brand_product_sales_14_day__sum',
    })

export const total_new_to_brand_product_sales_14_day__sum =
    makeCurrencyMetricFieldFunc({
        name: 'Total New-To-Brand Product Sales',
        shortName: 'Total New-To-Brand Product Sales',
        deltas: ['change', 'percentage_change'],
        id: 'total_new_to_brand_product_sales_14_day__sum',
    })

// Cost
export const total_cost__sum = makeCurrencyMetricFieldFunc({
    name: 'Total Cost',
    shortName: 'Total Cost',
    isInverse: true,
    deltas: ['change', 'percentage_change'],
    id: 'total_cost__sum',
})

export const supply_cost__sum = makeCurrencyMetricFieldFunc({
    name: 'Supply Cost',
    shortName: 'Supply Cost',
    isInverse: true,
    deltas: ['change', 'percentage_change'],
    id: 'supply_cost__sum',
})

export const amazon_audience_fee__sum = makeCurrencyMetricFieldFunc({
    name: 'Amazon Audience Fee',
    shortName: 'Amazon Audience Fee',
    isInverse: true,
    deltas: ['change', 'percentage_change'],
    id: 'amazon_audience_fee__sum',
})

export const amazon_platform_fee__sum = makeCurrencyMetricFieldFunc({
    name: 'Amazon Platform Fee',
    shortName: 'Amazon Platform Fee',
    isInverse: true,
    deltas: ['change', 'percentage_change'],
    id: 'amazon_platform_fee__sum',
})

export const atc_14_day__sum = makeCountMetricFieldFunc({
    name: 'Add To Cart (14 Day)',
    shortName: 'Add to Cart',
    deltas: ['change', 'percentage_change'],
    id: 'atc_14_day__sum',
})

export const atl_14_day__sum = makeCountMetricFieldFunc({
    name: 'Add To List (14 Day)',
    shortName: 'Add to List',
    deltas: ['change', 'percentage_change'],
    id: 'atl_14_day__sum',
})

export const total_detail_page_views_14_day__sum = makeCountMetricFieldFunc({
    name: 'Total Detail Page Views (14 Day)',
    shortName: 'TDPV',
    deltas: ['change', 'percentage_change'],
    id: 'total_detail_page_views_14_day__sum',
})

export const thank_you_page_14_day__sum = makeCountMetricFieldFunc({
    name: 'Thank You Page (14 Day)',
    shortName: 'Thank You',
    deltas: ['change', 'percentage_change'],
    id: 'thank_you_page_14_day__sum',
})

export const homepage_visit_14_day__sum = makeCountMetricFieldFunc({
    name: 'Homepage Page (14 Day)',
    shortName: 'Homepage',
    deltas: ['change', 'percentage_change'],
    id: 'homepage_visit_14_day__sum',
})

export const total_subscribe_and_save_subscriptions_14_day__sum =
    makeCountMetricFieldFunc({
        name: 'Total Subscribe and Save Subscriptions (14 Day)',
        shortName: 'Total Sub & Save',
        deltas: ['change', 'percentage_change'],
        id: 'total_subscribe_and_save_subscriptions_14_day__sum',
    })

export const total_add_to_cart_14_day__sum = makeCountMetricFieldFunc({
    name: 'Total Add to Cart (14 Day)',
    shortName: 'Total Add to Cart',
    deltas: ['change', 'percentage_change'],
    id: 'total_add_to_cart_14_day__sum',
})

export const total_add_to_list_14_day__sum = makeCountMetricFieldFunc({
    name: 'Total Add to List (14 Day)',
    shortName: 'Total Add to List',
    deltas: ['change', 'percentage_change'],
    id: 'total_add_to_list_14_day__sum',
})

export const ecpm = makeCurrencyMetricFieldFunc({
    name: 'Effective CPM',
    shortName: 'eCPM',
    deltas: ['change', 'percentage_change'],
    id: 'ecpm',
})

export const dpv_14_day__sum = makeCountMetricFieldFunc({
    name: 'Detail Page Views (14 Day)',
    shortName: 'DPV',
    deltas: ['change', 'percentage_change'],
    id: 'dpv_14_day__sum',
})

export const dpvr_14_day = makePercentageMetricFieldFunc({
    name: 'Detail Page View Rate',
    shortName: 'DPVR',
    isRatio: true,
    deltas: ['change'],
    id: 'dpvr_14_day',
})

export const ecpdpv_14_day = makeCurrencyMetricFieldFunc({
    name: 'Effective Cost per Detail Page View',
    shortName: 'eCPDPV',
    isRatio: true,
    deltas: ['change', 'percentage_change'],
    id: 'ecpdpv_14_day',
})

export const total_dpvr_14_day = makePercentageMetricFieldFunc({
    name: 'Total Detail Page View Rate',
    shortName: 'TDPVR',
    isRatio: true,
    deltas: ['change'],
    id: 'total_dpvr_14_day',
})

export const total_ecpdpv_14_day = makeCurrencyMetricFieldFunc({
    name: 'Effective Cost per Total Detail Page View',
    shortName: 'eCPTDPV',
    isRatio: true,
    deltas: ['change', 'percentage_change'],
    id: 'total_ecpdpv_14_day',
})

export const purchase_rate_14_day = makePercentageMetricFieldFunc({
    name: 'Purchase Rate',
    shortName: 'Purchase Rate',
    isRatio: true,
    deltas: ['change'],
    id: 'purchase_rate_14_day',
})

export const total_purchase_rate_14_day = makePercentageMetricFieldFunc({
    name: 'Total Purchase Rate',
    shortName: 'Total Purchase Rate',
    isRatio: true,
    deltas: ['change'],
    id: 'total_purchase_rate_14_day',
})

export const percent_of_purchases_new_to_brand_14_day =
    makePercentageMetricFieldFunc({
        name: 'Percent of Purchases New to Brand',
        shortName: '% Purchases NtB',
        deltas: ['change'],
        id: 'percent_of_purchases_new_to_brand_14_day',
    })

export const roas_14_day = makeCurrencyMetricFieldFunc({
    name: 'Return (14 days) on Ad Spend',
    shortName: 'RoAS',
    deltas: ['change', 'percentage_change'],
    id: 'roas_14_day',
})

export const total_roas_14_day = makeCurrencyMetricFieldFunc({
    name: 'Total Return (14 days) on Ad Spend',
    shortName: 'TRoAS',
    deltas: ['change', 'percentage_change'],
    id: 'total_roas_14_day',
})

export const homepage_visit_cvr_14_day = makePercentageMetricFieldFunc({
    name: 'Homepage Conversion Rate',
    shortName: 'Homepage CVR',
    isRatio: true,
    deltas: ['change'],
    id: 'homepage_visit_cvr_14_day',
})

export const thank_you_page_cvr_14_day = makePercentageMetricFieldFunc({
    name: 'Thank You Page Conversion Rate',
    shortName: 'Thank You CVR',
    isRatio: true,
    deltas: ['change'],
    id: 'thank_you_page_cvr_14_day',
})

export const video_completion_rate = makePercentageMetricFieldFunc({
    name: 'Video Completion Rate',
    shortName: '% Video Complete',
    deltas: ['change'],
    id: 'video_completion_rate',
})

export const ecpvc = makeCurrencyMetricFieldFunc({
    name: 'Effective Cost per Video Completion',
    shortName: 'eCPVC',
    isRatio: true,
    deltas: ['change', 'percentage_change'],
    id: 'ecpvc',
})

export const homepage_visit_views_14_day__sum = makeCountMetricFieldFunc({
    name: 'Homepage Visit Views (14 Day)',
    shortName: 'Homepage Visit Views',
    deltas: ['change', 'percentage_change'],
    id: 'homepage_visit_views_14_day__sum',
})
export const homepage_visit_clicks_14_day__sum = makeCountMetricFieldFunc({
    name: 'Homepage Visit Clicks (14 Day)',
    shortName: 'Homepage Visit Clicks',
    deltas: ['change', 'percentage_change'],
    id: 'homepage_visit_clicks_14_day__sum',
})
export const new_subscribe_and_save_14_day__sum = makeCountMetricFieldFunc({
    name: 'New Subscribe and Save (14 Day)',
    shortName: 'New Subscribe and Save',
    deltas: ['change', 'percentage_change'],
    id: 'new_subscribe_and_save_14_day__sum',
})
export const thank_you_page_clicks_14_day__sum = makeCountMetricFieldFunc({
    name: 'Thank You Page Clicks (14 Day)',
    shortName: 'Thank You Page Clicks',
    deltas: ['change', 'percentage_change'],
    id: 'thank_you_page_clicks_14_day__sum',
})
export const thank_you_page_views_14_day__sum = makeCountMetricFieldFunc({
    name: 'Thank You Page Views (14 Day)',
    shortName: 'Thank You Page Views',
    deltas: ['change', 'percentage_change'],
    id: 'thank_you_page_views_14_day__sum',
})
export const video_start__sum = makeCountMetricFieldFunc({
    name: 'Video Start',
    shortName: 'Video Start',
    deltas: ['change', 'percentage_change'],
    id: 'video_start__sum',
})
export const video_midpoint__sum = makeCountMetricFieldFunc({
    name: 'Video Midpoint',
    shortName: 'Video Midpoint',
    deltas: ['change', 'percentage_change'],
    id: 'video_midpoint__sum',
})
export const video_third_quartile__sum = makeCountMetricFieldFunc({
    name: 'Video Third-Quartile',
    shortName: 'Video Third-Quartile',
    deltas: ['change', 'percentage_change'],
    id: 'video_third_quartile__sum',
})
export const video_complete__sum = makeCountMetricFieldFunc({
    name: 'Video Complete',
    shortName: 'Video Complete',
    deltas: ['change', 'percentage_change'],
    id: 'video_complete__sum',
})
export const viewable_impressions__sum = makeCountMetricFieldFunc({
    name: 'Viewable Impressions',
    shortName: 'Viewable Impressions',
    deltas: ['change', 'percentage_change'],
    id: 'viewable_impressions__sum',
})
export const brand_search_14_day__sum = makeCountMetricFieldFunc({
    name: 'Branded Searches (14 Day)',
    shortName: 'Brand Search',
    deltas: ['change', 'percentage_change'],
    id: 'brand_search_14_day__sum',
})
