import { TFunction } from 'i18next'
import isArray from 'lodash/isArray'
import truncate from 'lodash/truncate'

import { BOOLEAN_OPERATOR_LABELS, NONE_SUFFIX } from 'const/filters'
import { RESOURCE_NAME_MAPPING } from 'const/resourceTypes'
import { isDefined, isNonEmptyArray, isString } from 'helpers/typeGuard'
import {
    BooleanFilter,
    BooleanLeafFilter,
    BooleanOrFilter,
    LabelMapping,
} from 'types'

const MAX_LENGTH = 30

export const truncateLabel = (label: string): string =>
    truncate(label, { length: MAX_LENGTH })

export const formatBooleanLabelLeafClause = (
    leafClause: BooleanLeafFilter,
    labelMapping: LabelMapping,
    t: TFunction,
    operatorMapping?: LabelMapping,
    resourceTypeMapping?: LabelMapping
): string => {
    const resourceMapping = resourceTypeMapping || RESOURCE_NAME_MAPPING

    const resourceTypeStr = isString(resourceMapping[leafClause.resourceType])
        ? `${resourceMapping[leafClause.resourceType]} `
        : ''

    if (leafClause.operator === NONE_SUFFIX) {
        return `${resourceTypeStr} has none`
    }

    const opMapping = operatorMapping || BOOLEAN_OPERATOR_LABELS(t)

    const leafValue = leafClause.value ?? []
    const leafLabels = isArray(leafValue)
        ? leafValue
              .map(
                  (labelId) => labelMapping[labelId] || `${labelId} (Not Found)`
              )
              .join(', ')
        : leafClause.value
    const operatorString =
        isDefined(opMapping[leafClause.operator]) &&
        isString(opMapping[leafClause.operator])
            ? `${(opMapping[leafClause.operator] || '').toLowerCase()}: `
            : ''
    return `${resourceTypeStr}${operatorString}${leafLabels}`
}

const formatBooleanLabelOrClause = (
    clause: BooleanOrFilter,
    labelMapping: LabelMapping,
    t: TFunction,
    operatorMapping?: LabelMapping,
    resourceTypeMapping?: LabelMapping
): string => {
    if (isNonEmptyArray(clause.or)) {
        if (clause.or.length === 1) {
            return formatBooleanLabelLeafClause(
                clause.or[0],
                labelMapping,
                t,
                operatorMapping,
                resourceTypeMapping
            )
        }
        if (clause.or.length > 1) {
            return clause.or
                .map(
                    (leafClause) =>
                        `(${formatBooleanLabelLeafClause(
                            leafClause,
                            labelMapping,
                            t,
                            operatorMapping,
                            resourceTypeMapping
                        )})`
                )
                .join(' or ')
        }
    }
    return 'Unspecified'
}

export const formatBooleanLabelFilter = (
    selectedValue: BooleanFilter,
    labelMapping: LabelMapping,
    t: TFunction,
    operatorMapping?: LabelMapping,
    resourceTypeMapping?: LabelMapping
): string => {
    if (isNonEmptyArray(selectedValue.and)) {
        if (selectedValue.and.length === 1) {
            return formatBooleanLabelOrClause(
                selectedValue.and[0],
                labelMapping,
                t,
                operatorMapping,
                resourceTypeMapping
            )
        }
        if (selectedValue.and.length > 1) {
            return selectedValue.and
                .map(
                    (orClause) =>
                        `(${formatBooleanLabelOrClause(
                            orClause,
                            labelMapping,
                            t,
                            operatorMapping,
                            resourceTypeMapping
                        )})`
                )
                .join(' and ')
        }
    }
    return 'Unspecified'
}
