import { captureException } from '@sentry/browser'
import { call, put, select } from 'redux-saga/effects'

import {
    makeUpdateResourceSuccess,
    makeUpdateResourceFailure,
} from 'actions/ui/shared/resource'
import uiSagaRegistry from 'sagas/ui/registry'
import { selectResourceParamsOfPage } from 'selectors/ui'

/**
 * Update Page Resource
 */
export function* updateResourceWorker(action) {
    const { pageName, updatingResourceKey, data } = action.payload

    try {
        const updateResourceSaga = uiSagaRegistry.lookupSaga(
            [pageName],
            'updateResourceSaga'
        )

        const resourceParams = yield select(
            selectResourceParamsOfPage,
            pageName
        )

        if (updateResourceSaga && resourceParams) {
            yield call(
                updateResourceSaga,
                resourceParams,
                data,
                makeUpdateResourceSuccess(pageName, updatingResourceKey)
            )
        }
    } catch (error) {
        yield call(captureException, error)
        yield put(
            makeUpdateResourceFailure(pageName, updatingResourceKey)(error)
        )
    }
}
