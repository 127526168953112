import { memo, ReactElement } from 'react'

import { Select } from 'antd'
import { Field } from 'formik'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import { object, string } from 'yup'

import { FormikSelect } from 'components/formik'
import { USER_GROUP_TYPE_NAMES } from 'const/organizations'
import { userHasOrgAdminPermissions } from 'helpers/featurePermissions'
import {
    useAuthDomainValue,
    useCerebroApiRequest,
    useUserHasPermissions,
} from 'hooks'
import { updateOrganizationMember } from 'services/cerebroApi/orgScope/resourceApi'
import { CerebroResponse, Member, UserGroupType } from 'types'
import message from 'utilities/message'

import { SingleValueField } from '../SharedFields'

interface Props<RecordType> {
    dataIndex: string[]
    readOnly: boolean
    record: RecordType
}

function MemberTypeField<RecordType extends Member>({
    dataIndex = ['member_type'],
    readOnly = false,
    record,
}: Props<RecordType>): ReactElement {
    const fieldName = 'member_type'
    const makeCerebroApiRequest = useCerebroApiRequest()
    const isOrgAdmin = useUserHasPermissions(userHasOrgAdminPermissions)
    const organizationId = useAuthDomainValue('organizationId')
    const validationSchema = object().shape({
        [fieldName]: string().required(),
    })
    const options = {
        standard: USER_GROUP_TYPE_NAMES.standard,
        read_only: USER_GROUP_TYPE_NAMES.read_only,
        dashboard_only: USER_GROUP_TYPE_NAMES.dashboard_only,
    }
    return (
        <SingleValueField
            fieldName={fieldName}
            formatFieldValue={(values) => {
                const memberType = values[fieldName]
                return USER_GROUP_TYPE_NAMES[memberType]
            }}
            initialValues={{
                [fieldName]: get(record, dataIndex) as UserGroupType,
            }}
            onSave={(values) => {
                return makeCerebroApiRequest<
                    RecordType,
                    CerebroResponse<RecordType>
                >({
                    request: updateOrganizationMember(
                        organizationId,
                        record.id,
                        values
                    ),
                    onRequestSuccess: () => {
                        message.success('Successfully updated member type')
                    },
                })
            }}
            readOnly={!isOrgAdmin || readOnly}
            validationSchema={validationSchema}
        >
            {({ isSubmitting }) => (
                <Field
                    component={FormikSelect}
                    placeholder="Member Type"
                    name={fieldName}
                    optionFilterProp="children"
                    disabled={isSubmitting}
                    popupMatchSelectWidth={false}
                >
                    {Object.entries(options).map(([value, fieldLabel]) => (
                        <Select.Option key={value} value={value}>
                            {fieldLabel}
                        </Select.Option>
                    ))}
                </Field>
            )}
        </SingleValueField>
    )
}

export default memo(MemberTypeField, isEqual) as typeof MemberTypeField
