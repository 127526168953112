import { handleActions } from 'redux-actions'

import { adGroupTargetsTableColumnsConfig } from 'configuration/tables'
import { TARGET_STATES, METRIC_FILTERS, DATES } from 'const/filters'
import { FILTER_SETTINGS, LOCAL_FILTERS } from 'const/reducerKeys'
import { serializeColumns } from 'helpers/tables'
import { defaultTab, getDefaultTable } from 'reducers/ui/defaults'

const initialState = {
    ...defaultTab,
    [LOCAL_FILTERS]: {
        [METRIC_FILTERS]: [],
        [TARGET_STATES]: [],
    },
    [FILTER_SETTINGS]: {
        anchored: [DATES],
        order: [METRIC_FILTERS, TARGET_STATES],
        displayState: {
            [METRIC_FILTERS]: true,
            [TARGET_STATES]: true,
        },
    },
    table: getDefaultTable(serializeColumns(adGroupTargetsTableColumnsConfig)),
}

export default handleActions({}, initialState)
