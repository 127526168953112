import { Translation } from 'react-i18next'

import {
    walmartKeywordBidRenderer,
    walmartKeywordStateRenderer,
} from 'components/FieldRenderers/Renderers'
import { Field, WalmartKeywordGroupBy } from 'types'

import { createField, createTitleField } from './createField'
import { FieldCreatorOptions } from './localTypes'

export function keywordText<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['keyword_text']
    const name = (
        <Translation>{(t) => t('common:keyword', 'Keyword')}</Translation>
    )
    return createField({
        ...options,
        id: 'keyword_text',
        name,
        shortName: name,
        minWidth: 200,
        isTotalSupported: options.isTotalSupported ?? true,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: ({ value }) => value,
        },
    })
}

export function keywordMatchType<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['match_type']
    const name = (
        <Translation>
            {(t) =>
                t('table:fields.keywordMatchType.name', 'Keyword Match Type')
            }
        </Translation>
    )
    const shortName = (
        <Translation>
            {(t) => t('table:fields.keywordMatchType.shortName', 'Match Type')}
        </Translation>
    )

    return createTitleField({
        ...options,
        id: 'keyword_match_type',
        name,
        shortName,
        dataIndex,
        minWidth: 75,
        width: 110,
    })
}

export function keywordState<RecordType extends WalmartKeywordGroupBy>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['state']
    const name = (
        <Translation>
            {(t) => t('table:fields.keywordState.name', 'Keyword State')}
        </Translation>
    )
    const shortName = (
        <Translation>
            {(t) => t('table:fields.keywordState.shortName', 'State')}
        </Translation>
    )

    return createField({
        ...options,
        id: 'keyword_state',
        name,
        shortName,
        dataIndex,
        minWidth: 75,
        renderOptions: options.renderOptions ?? {
            render: ({ record }) =>
                walmartKeywordStateRenderer({ record, dataIndex }),
        },
        width: 100,
    })
}

export function keywordBid<RecordType extends WalmartKeywordGroupBy>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['bid']
    const name = (
        <Translation>
            {(t) => t('table:fields.keywordBid.name', 'Keyword Bid')}
        </Translation>
    )
    const shortName = (
        <Translation>
            {(t) => t('table:fields.keywordBid.shortName', 'Bid')}
        </Translation>
    )

    return createField({
        ...options,
        id: 'keyword_bid',
        name,
        shortName,
        dataIndex,
        minWidth: 75,
        renderOptions: options.renderOptions ?? {
            render: (props) => walmartKeywordBidRenderer(props),
        },
        width: 100,
        align: 'right',
    })
}
