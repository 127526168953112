import { Translation } from 'react-i18next'

import UserEmail from 'components/FieldRenderers/Renderers/UserEmail'
import { RulebookActivityCell } from 'components/FieldRenderers/RulebookActivityCell'
import { Timestamp } from 'components/Timestamp'
import { ChangeSet, Field } from 'types'

import { createField, createNumberField } from './createField'
import { FieldCreatorOptions } from './localTypes'

export function changeSetUpdatedAtDate<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['updated_at']
    const name = (
        <Translation>
            {(t) => t('common:updatedDate', 'Updated Date')}
        </Translation>
    )
    return createField({
        ...options,
        id: 'updated_at',
        name,
        shortName: name,
        dataIndex,
        minWidth: 100,
        width: 200,
        renderOptions: options.renderOptions ?? {
            render: ({ value }) => <Timestamp time={value} />,
        },
        columnTitle: (
            <Translation>{(t) => t('common:date', 'Date')}</Translation>
        ),
        sorter: true,
    })
}

export function changeSetActivity(
    options: FieldCreatorOptions<ChangeSet> = {}
): Field<ChangeSet> {
    const dataIndex = options.dataIndex ?? ['description']
    const name = (
        <Translation>{(t) => t('common:activity', 'Activity')}</Translation>
    )
    return createField({
        ...options,
        id: 'activity',
        name,
        shortName: name,
        dataIndex,
        minWidth: 100,
        width: 200,
        renderOptions: options.renderOptions ?? {
            render: (props) => <RulebookActivityCell {...props} />,
        },
        sorter: false,
    })
}

export function changeSetCreatedBy<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['created_by']
    const name = (
        <Translation>{(t) => t('common:madeBy', 'Made By')}</Translation>
    )
    return createField({
        ...options,
        id: 'created_by',
        name,
        shortName: name,
        dataIndex,
        minWidth: 100,
        width: 200,
        renderOptions: options.renderOptions ?? {
            render: ({ value }) => <UserEmail email={value} />,
        },
        sorter: true,
    })
}

export function changeSetChangeCount<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['change_count']
    const name = (
        <Translation>{(t) => t('common:changes', 'Changes')}</Translation>
    )
    return createNumberField({
        ...options,
        id: 'change_count',
        name,
        shortName: name,
        dataIndex,
        minWidth: 100,
        width: 200,
        showUnsetAsNumber: false,
        sorter: false,
        columnTitle: (
            <Translation>{(t) => t('common:changes', 'Changes')}</Translation>
        ),
    })
}
