import { handleActions } from 'redux-actions'

import { DATES, AGGREGATION } from 'const/filters'
import { PRODUCT_AD_PAGE } from 'const/pages'
import { LOCAL_FILTERS, FILTER_SETTINGS } from 'const/reducerKeys'

import {
    defaultResourcePageTabs,
    defaultResourcePageWidgets,
    defaultResourcePage,
} from '../defaults'

const defaultState = {
    [LOCAL_FILTERS]: {},
    [FILTER_SETTINGS]: {
        order: [DATES, AGGREGATION],
        displayState: {
            [AGGREGATION]: true,
            [DATES]: true,
        },
    },

    ...defaultResourcePage,

    tabs: defaultResourcePageTabs,
    widgets: defaultResourcePageWidgets(PRODUCT_AD_PAGE),
}

export default handleActions({}, defaultState)
