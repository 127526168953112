import { ReactElement } from 'react'

import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { isKeyInOrgAccountStatusDataSources } from 'const/dataSources'
import { useOrgContext } from 'context/OrgContext'
import { userHasDashboardsOnlyPermissions } from 'helpers/featurePermissions'
import { useUserHasPermissions } from 'hooks'
import { useDataSourceIntegrationInfo } from 'hooks/useDataSourceIntegrationInfo'
import { useWidgetDataSourceInfo } from 'hooks/useWidgetDataSourceInfo'
import { Widget as IWidget } from 'types'

import styles from './styles.scss'
import { Empty } from '../Empty'

function WidgetConfigExplanation(props: {
    widget: IWidget
    fallbackToNoData?: boolean
}): ReactElement | null {
    const { widget, fallbackToNoData = false } = props
    const { t } = useTranslation()

    const orgContext = useOrgContext()

    const widgetDataSourceInfo = useWidgetDataSourceInfo()
    const dataSourceIntegrationInfo = useDataSourceIntegrationInfo()

    const dashboardOnlyExperience = useUserHasPermissions(
        userHasDashboardsOnlyPermissions
    )

    const dataSourceInfo = widgetDataSourceInfo[widget.data_source]
    const dataSourceModule = dataSourceInfo
        ? orgContext.orgAccountStatus?.customerModules[dataSourceInfo.moduleKey]
        : undefined

    const dataSourceContext = isKeyInOrgAccountStatusDataSources(
        widget.data_source
    )
        ? orgContext?.orgAccountStatus?.data_sources[widget.data_source]
        : undefined

    const integration =
        dataSourceContext && dataSourceContext.requires_integration
            ? orgContext.orgAccountStatus?.integrations[
                  dataSourceContext.integration_key
              ]
            : undefined

    const missingIntegration = integration && !integration.has_integration
    const missingIntegrationInfo =
        missingIntegration && dataSourceContext
            ? dataSourceIntegrationInfo[dataSourceContext.integration_key]
            : undefined

    const missingCustomerModule =
        dataSourceModule &&
        dataSourceModule.key !== 'internal_only' &&
        !dataSourceModule.user_has_access

    const isSegmentsAndMissingData =
        widget.data_source === 'segment_facts' &&
        dataSourceContext?.has_data === false

    if (
        !dashboardOnlyExperience &&
        dataSourceModule &&
        (missingCustomerModule ||
            missingIntegrationInfo ||
            isSegmentsAndMissingData)
    ) {
        return (
            <div className="text-center">
                <Empty
                    className="d-flex flex-column align-items-center justify-content-center"
                    description={
                        <div className={styles['widget-config-explanation']}>
                            <p>
                                {t(
                                    'dashboards:widget.dataSources.typeExplanation',
                                    'This widget is configured to show {{ dataSource }}.',
                                    {
                                        dataSource:
                                            dataSourceInfo?.name ??
                                            widget.data_source,
                                    }
                                )}

                                {missingCustomerModule && (
                                    <Trans i18nKey="dashboards:widget.module.missing">
                                        {' '}
                                        To see data for this widget, you need to
                                        purchase a{' '}
                                        <strong>
                                            {{
                                                customerModule:
                                                    dataSourceModule.name,
                                            }}
                                        </strong>{' '}
                                        module.
                                    </Trans>
                                )}
                                {!missingCustomerModule &&
                                    missingIntegrationInfo && (
                                        <Trans i18nKey="dashboards:widget.integration.missing">
                                            {' '}
                                            To see data for this widget, you
                                            need to{' '}
                                            <Link
                                                to={missingIntegrationInfo.url}
                                            >
                                                configure a{' '}
                                                <strong>
                                                    {{
                                                        integratioName:
                                                            missingIntegrationInfo.name,
                                                    }}
                                                </strong>{' '}
                                                integration
                                            </Link>
                                            .
                                        </Trans>
                                    )}
                                {!missingCustomerModule &&
                                    !missingIntegration &&
                                    isSegmentsAndMissingData && (
                                        <Trans i18nKey="dashboards:widget.dataSources.segments.missing">
                                            {' '}
                                            To see data for this widget, you
                                            need to{' '}
                                            <Link to="/segments">
                                                create at least one segment
                                            </Link>
                                            .
                                        </Trans>
                                    )}
                            </p>
                        </div>
                    }
                />
            </div>
        )
    }
    return fallbackToNoData ? (
        <Empty
            className="d-flex flex-column align-items-center justify-content-center"
            description="No data. Try adjusting your widget filters or group bys."
        />
    ) : null
}

export default WidgetConfigExplanation
