import { ReactElement } from 'react'

import { Translation } from 'react-i18next'

const translations = {
    ctr: {
        name: (
            <Translation>
                {(t) => t('metrics:ams.ctr.name', 'Click Through Rate')}
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) => t('metrics:ams.ctr.shortName', 'CTR')}
            </Translation>
        ),
    },
    roas: {
        name: (
            <Translation>
                {(t) => t('metrics:ams.roas.name', 'RoAS ($)')}
            </Translation>
        ),
    },
    roas_same_sku: {
        name: (
            <Translation>
                {(t) =>
                    t('metrics:ams.roas_same_sku.name', 'RoAS Same Sku ($)')
                }
            </Translation>
        ),
    },
    roasp: {
        name: (
            <Translation>
                {(t) => t('metrics:ams.roasp.name', 'RoAS (%)')}
            </Translation>
        ),
    },
    acos: {
        name: (
            <Translation>
                {(t) => t('metrics:ams.acos.name', 'ACoS')}
            </Translation>
        ),
    },
    spc: {
        name: (
            <Translation>
                {(t) => t('metrics:ams.spc.name', 'Sales per Click')}
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) => t('metrics:ams.spc.shortName', 'SPC')}
            </Translation>
        ),
    },
    cpc: {
        name: (
            <Translation>
                {(t) => t('metrics:ams.cpc.name', 'Cost per Click')}
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) => t('metrics:ams.cpc.shortName', 'CPC')}
            </Translation>
        ),
    },
    cpa: {
        name: (
            <Translation>
                {(t) => t('metrics:ams.cpa.name', 'Cost per Acquisition')}
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) => t('metrics:ams.cpa.shortName', 'CPA')}
            </Translation>
        ),
    },
    combined_cpa: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.combined_cpa.name',
                        'Combined Cost per Acquisition'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) => t('metrics:ams.combined_cpa.shortName', 'Combined CPA')}
            </Translation>
        ),
    },
    cpm: {
        name: (
            <Translation>
                {(t) => t('metrics:ams.cpm.name', 'Cost per Thousand')}
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) => t('metrics:ams.cpm.shortName', 'CPM')}
            </Translation>
        ),
    },
    spm: {
        name: (
            <Translation>
                {(t) => t('metrics:ams.spm.name', 'Sales per Thousand')}
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) => t('metrics:ams.spm.shortName', 'SPM')}
            </Translation>
        ),
    },
    conversion_rate_clicks: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.conversion_rate_clicks.name',
                        'Conversion Rate (Clicks)'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.conversion_rate_clicks.shortName',
                        'CVR (Clicks)'
                    )
                }
            </Translation>
        ),
    },
    combined_conversion_rate_clicks: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.combined_conversion_rate_clicks.name',
                        'Combined Conversion Rate (Clicks)'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.combined_conversion_rate_clicks.shortName',
                        'Combined CVR (Clicks)'
                    )
                }
            </Translation>
        ),
    },
    conversion_rate_impressions: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.conversion_rate_impressions.name',
                        'Conversion Rate (Impressions)'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.conversion_rate_impressions.shortName',
                        'CVR (Impressions)'
                    )
                }
            </Translation>
        ),
    },
    combined_conversion_rate_impressions: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.combined_conversion_rate_impressions.name',
                        'Combined Conversion Rate (Impressions)'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.combined_conversion_rate_impressions.shortName',
                        'Combined CVR (Impressions)'
                    )
                }
            </Translation>
        ),
    },
    aov: {
        name: (
            <Translation>
                {(t) => t('metrics:ams.aov.name', 'Average Order Value')}
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) => t('metrics:ams.aov.shortName', 'AOV')}
            </Translation>
        ),
    },
    combined_aov: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.combined_aov.name',
                        'Combined Average Order Value'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) => t('metrics:ams.combined_aov.shortName', 'Combined AOV')}
            </Translation>
        ),
        helpText: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.combined_aov.helpText',
                        'The average sales value of customer orders. Combined AOV is calculated by dividing total attributed sales (CPC + vCPM) by total attributed conversions (CPC + vCPM).'
                    )
                }
            </Translation>
        ),
    },
    clicks__sum: {
        name: (
            <Translation>
                {(t) => t('metrics:ams.clicks__sum.name', 'Clicks')}
            </Translation>
        ),
    },
    cost__sum: {
        name: (
            <Translation>
                {(t) => t('metrics:ams.cost__sum.name', 'Cost')}
            </Translation>
        ),
    },
    impressions__sum: {
        name: (
            <Translation>
                {(t) => t('metrics:ams.impressions__sum.name', 'Impressions')}
            </Translation>
        ),
    },
    attributed_conversions__sum: {
        name: (numDays: number): ReactElement => (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.attributed_conversions__sum.name',
                        'Attributed Conversions ({{numDays}} Day)',
                        { numDays }
                    )
                }
            </Translation>
        ),
        shortName: (numDays: number): ReactElement => (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.attributed_conversions__sum.shortName',
                        'Conversions ({{numDays}} Day)',
                        { numDays }
                    )
                }
            </Translation>
        ),
    },
    combined_conversions_14_day__sum: {
        name: (): ReactElement => (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.combined_conversions_14_day__sum.name',
                        'Combined Conversions (14 Day)'
                    )
                }
            </Translation>
        ),
        shortName: (): ReactElement => (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.combined_conversions_14_day__sum.shortName',
                        'Combined Conversions (14 Day)'
                    )
                }
            </Translation>
        ),
        helpText: (): ReactElement => (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.combined_conversions_14_day__sum.helpText',
                        'The number of times a shopper purchases (CPC or vCPM) any of your brand’s products within (14 day) of clicking on your ad. Note: If a shopper purchases multiple units, this will still count as one conversion.'
                    )
                }
            </Translation>
        ),
    },
    attributed_conversions_same_sku__sum: {
        name: (numDays: number): ReactElement => (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.attributed_conversions_same_sku__sum.name',
                        'Attributed Conversions ({{numDays}} Day Same SKU)',
                        { numDays }
                    )
                }
            </Translation>
        ),
        shortName: (numDays: number): ReactElement => (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.attributed_conversions_same_sku__sum.shortName',
                        'Conversions ({{numDays}} Day Same SKU)',
                        { numDays }
                    )
                }
            </Translation>
        ),
    },
    attributed_sales__sum: {
        name: (numDays: number): ReactElement => (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.attributed_sales__sum.name',
                        'Attributed Sales ({{numDays}} Day)',
                        { numDays }
                    )
                }
            </Translation>
        ),
        shortName: (numDays: number): ReactElement => (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.attributed_sales__sum.shortName',
                        'Sales ({{numDays}} Day)',
                        { numDays }
                    )
                }
            </Translation>
        ),
    },
    amazon_sales: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.amazon_sales.name',
                        'Attributed Sales (As Amazon Console Shows)'
                    )
                }
            </Translation>
        ),
    },
    attributed_sales_same_sku__sum: {
        name: (numDays: number): ReactElement => (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.attributed_sales_same_sku__sum.name',
                        'Attributed Sales ({{numDays}} Day Same SKU)',
                        { numDays }
                    )
                }
            </Translation>
        ),
        shortName: (numDays: number): ReactElement => (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.attributed_sales_same_sku__sum.shortName',
                        'Sales ({{numDays}} Day Same SKU)',
                        { numDays }
                    )
                }
            </Translation>
        ),
    },
    attributed_units_ordered__sum: {
        name: (numDays: number): ReactElement => (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.attributed_units_ordered__sum.name',
                        'Attributed Units Ordered ({{numDays}} Day)',
                        { numDays }
                    )
                }
            </Translation>
        ),
        shortName: (numDays: number): ReactElement => (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.attributed_units_ordered__sum.shortName',
                        'Units Ordered ({{numDays}} Day)',
                        { numDays }
                    )
                }
            </Translation>
        ),
    },
    attributed_units_ordered_same_sku__sum: {
        name: (numDays: number): ReactElement => (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.attributed_units_ordered_same_sku__sum.name',
                        'Attributed Units Ordered ({{numDays}} Day Same SKU)',
                        { numDays }
                    )
                }
            </Translation>
        ),
        shortName: (numDays: number): ReactElement => (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.attributed_units_ordered_same_sku__sum.shortName',
                        'Units Ordered ({{numDays}} Day Same SKU)',
                        { numDays }
                    )
                }
            </Translation>
        ),
    },
    attributed_units_sold_14_day__sum: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.attributed_units_sold_14_day__sum.name',
                        'Attributed Units Sold (14 day)'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.attributed_units_sold_14_day__sum.shortName',
                        'Units Sold (14 day)'
                    )
                }
            </Translation>
        ),
    },
    attributed_orders_new_to_brand_14_day__sum: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.attributed_orders_new_to_brand_14_day__sum.name',
                        'New-To-Brand (NTB) Orders'
                    )
                }
            </Translation>
        ),
    },
    attributed_orders_new_to_brand_percentage_14_day: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.attributed_orders_new_to_brand_percentage_14_day.name',
                        '% of Orders New-To-Brand (NTB)'
                    )
                }
            </Translation>
        ),
    },
    attributed_order_rate_new_to_brand_14_day: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.attributed_order_rate_new_to_brand_14_day.name',
                        'New-To-Brand (NTB) Order Rate'
                    )
                }
            </Translation>
        ),
    },
    attributed_sales_new_to_brand_14_day__sum: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.attributed_sales_new_to_brand_14_day__sum.name',
                        'New-To-Brand (NTB) Sales'
                    )
                }
            </Translation>
        ),
    },
    attributed_sales_new_to_brand_percentage_14_day: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.attributed_sales_new_to_brand_percentage_14_day.name',
                        '% of Sales New-To-Brand (NTB)'
                    )
                }
            </Translation>
        ),
    },
    attributed_units_ordered_new_to_brand_14_day__sum: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.attributed_units_ordered_new_to_brand_14_day__sum.name',
                        'New-To-Brand (NTB) Units Ordered'
                    )
                }
            </Translation>
        ),
    },
    attributed_units_ordered_new_to_brand_percentage_14_day: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.attributed_units_ordered_new_to_brand_percentage_14_day.name',
                        '% of Units Ordered New-To-Brand (NTB)'
                    )
                }
            </Translation>
        ),
    },
    attributed_detail_page_views_14_day__sum: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.attributed_detail_page_views_14_day__sum.name',
                        'Attributed Detail Page Views (14 day)'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.attributed_detail_page_views_14_day__sum.shortName',
                        'Detail Page Views (14 day)'
                    )
                }
            </Translation>
        ),
    },
    attributed_detail_page_views_clicks_14_day__sum: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.attributed_detail_page_views_clicks_14_day__sum.name',
                        'Attributed Detail Page View Conversions (14 day)'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.attributed_detail_page_views_clicks_14_day__sum.shortName',
                        'Detail Page View Conversions (14 day)'
                    )
                }
            </Translation>
        ),
    },
    attributed_sales_14_day_same_sku_rate: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.attributed_sales_14_day_same_sku_rate.name',
                        '% of Same Sku Attributed 14 Day Sales'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.attributed_sales_14_day_same_sku_rate.shortName',
                        '% of Same Sku Sales (14 Day)'
                    )
                }
            </Translation>
        ),
    },
    spend: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.spend.name',
                        'Pacing Spend through Yesterday'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) => t('metrics:ams.spend.shortName', 'Pacing Spend')}
            </Translation>
        ),
    },
    spend__daily_avg: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.spend__daily_avg.name',
                        'Spend Daily Average'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.spend__daily_avg.shortName',
                        'Spend Daily Avg'
                    )
                }
            </Translation>
        ),
    },
    spend__t3_daily_avg: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.spend__t3_daily_avg.name',
                        'Spend 3 Day Average'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.spend__t3_daily_avg.shortName',
                        'Spend T3 Avg'
                    )
                }
            </Translation>
        ),
    },
    spend__t7_daily_avg: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.spend__t7_daily_avg.name',
                        'Spend 7 Day Average'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.spend__t7_daily_avg.shortName',
                        'Spend T7 Avg'
                    )
                }
            </Translation>
        ),
    },
    spend__daily_avg_forecast: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.spend__daily_avg_forecast.name',
                        'Spend Daily Average Forecast'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.spend__daily_avg_forecast.shortName',
                        'Spend Forecast'
                    )
                }
            </Translation>
        ),
    },
    spend__t3_daily_avg_forecast: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.spend__t3_daily_avg_forecast.name',
                        'Spend 3 Day Average Forecast'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.spend__t3_daily_avg_forecast.shortName',
                        'Spend T3 Forecast'
                    )
                }
            </Translation>
        ),
    },
    spend__t7_daily_avg_forecast: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.spend__t7_daily_avg_forecast.name',
                        'Spend 7 Day Average Forecast'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.spend__t7_daily_avg_forecast.shortName',
                        'Spend T7 Forecast'
                    )
                }
            </Translation>
        ),
    },
    spend__daily_avg_forecast_difference_from_goal: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.spend__daily_avg_forecast_difference_from_goal.name',
                        'Spend Daily Average Forecast Difference from Goal'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.spend__daily_avg_forecast_difference_from_goal.shortName',
                        'Spend Forecast vs Goal'
                    )
                }
            </Translation>
        ),
    },
    spend__t3_daily_avg_forecast_difference_from_goal: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.spend__t3_daily_avg_forecast_difference_from_goal.name',
                        'Spend 3 Day Average Forecast Difference from Goal'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.spend__t3_daily_avg_forecast_difference_from_goal.shortName',
                        'Spend T3 Forecast vs Goal'
                    )
                }
            </Translation>
        ),
    },
    spend__t7_daily_avg_forecast_difference_from_goal: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.spend__t7_daily_avg_forecast_difference_from_goal.name',
                        'Spend 7 Day Average Forecast Difference from Goal'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.spend__t7_daily_avg_forecast_difference_from_goal.shortName',
                        'Spend T7 Forecast vs Goal'
                    )
                }
            </Translation>
        ),
    },
    spend__daily_avg_forecast_pct_difference_from_goal: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.spend__daily_avg_forecast_pct_difference_from_goal.name',
                        'Spend Daily Average Forecast Percentage Difference of Goal'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.spend__daily_avg_forecast_pct_difference_from_goal.shortName',
                        'Spend Forecast % of Goal'
                    )
                }
            </Translation>
        ),
    },
    spend__t3_daily_avg_forecast_pct_difference_from_goal: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.spend__t3_daily_avg_forecast_pct_difference_from_goal.name',
                        'Spend 3 Day Average Forecast Percentage Difference of Goal'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.spend__t3_daily_avg_forecast_pct_difference_from_goal.shortName',
                        'Spend T3 Forecast % of Goal'
                    )
                }
            </Translation>
        ),
    },
    spend__t7_daily_avg_forecast_pct_difference_from_goal: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.spend__t7_daily_avg_forecast_pct_difference_from_goal.name',
                        'Spend 7 Day Average Forecast Percentage Difference of Goal'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.spend__t7_daily_avg_forecast_pct_difference_from_goal.shortName',
                        'Spend T7 Forecast % of Goal'
                    )
                }
            </Translation>
        ),
    },
    spend__difference_from_goal: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.spend__difference_from_goal.name',
                        'Remaining Spend from Goal'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.spend__difference_from_goal.shortName',
                        'Spend vs Goal'
                    )
                }
            </Translation>
        ),
    },
    spend__pct_difference_from_goal: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.spend__pct_difference_from_goal.name',
                        'Spend Percentage of Goal'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.spend__pct_difference_from_goal.shortName',
                        'Spend % to Goal'
                    )
                }
            </Translation>
        ),
    },
    spend__difference_from_goal_per_day: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.spend__difference_from_goal_per_day.name',
                        'Remaining Spend from Goal per Day'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.spend__difference_from_goal_per_day.shortName',
                        'Daily Spend to Goal'
                    )
                }
            </Translation>
        ),
    },
    spend__goal: {
        name: (
            <Translation>
                {(t) => t('metrics:ams.spend__goal.name', 'Spend Goal')}
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) => t('metrics:ams.spend__goal.shortName', 'Spend Goal')}
            </Translation>
        ),
    },

    view_impressions__sum: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.view_impressions__sum.name',
                        'vCPM Impressions'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.view_impressions__sum.shortName',
                        'vCPM Impressions'
                    )
                }
            </Translation>
        ),
    },
    combined_impressions__sum: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.combined_impressions__sum.name',
                        'Combined Impressions'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.combined_impressions__sum.shortName',
                        'Combined Impressions'
                    )
                }
            </Translation>
        ),
        helpText: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.combined_impressions__sum.helpText',
                        'The total number of viewable impressions (CPC + vCPM).'
                    )
                }
            </Translation>
        ),
    },
    view_attributed_conversions_14_day__sum: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.view_attributed_conversions_14_day__sum.name',
                        'vCPM Conversions (14 day)'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.view_attributed_conversions_14_day__sum.shortName',
                        'vCPM Conversions (14 day)'
                    )
                }
            </Translation>
        ),
    },
    view_attributed_units_ordered_14_day__sum: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.view_attributed_units_ordered_14_day__sum.name',
                        'vCPM Units Ordered (14 day)'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.view_attributed_units_ordered_14_day__sum.shortName',
                        'vCPM Units Ordered (14 day)'
                    )
                }
            </Translation>
        ),
    },
    view_attributed_sales_14_day__sum: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.view_attributed_sales_14_day__sum.name',
                        'vCPM Sales (14 Day)'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.view_attributed_sales_14_day__sum.shortName',
                        'vCPM Sales (14 Day)'
                    )
                }
            </Translation>
        ),
    },
    combined_attributed_sales_14_day__sum: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.combined_attributed_sales_14_day__sum.name',
                        'Combined Sales (14 Day)'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.combined_attributed_sales_14_day__sum.shortName',
                        'Combined Sales (14 Day)'
                    )
                }
            </Translation>
        ),
        helpText: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.combined_attributed_sales_14_day__sum.helpText',
                        'The combined value of sales (CPC + vCPM) occurring within 14 days of an ad click or view.'
                    )
                }
            </Translation>
        ),
    },
    combined_acos: {
        name: (
            <Translation>
                {(t) =>
                    t('metrics:ams.combined_acos.name', 'vCPM Combined ACoS')
                }
            </Translation>
        ),
        helpText: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.combined_acos.helpText',
                        'Advertising cost as a percent of combined (CPC + vCPM) sales. ACoS is calculated by dividing advertising cost by attributed sales. Note: Amazon uses a default sales attribution based on a 14-day last click window. Revenue is based on sales price to end customer.'
                    )
                }
            </Translation>
        ),
    },
    combined_roas: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.combined_roas.name',
                        'vCPM Combined RoAS ($)'
                    )
                }
            </Translation>
        ),
        helpText: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.combined_roas.helpText',
                        'The average combined (CPC + vCPM) revenue earned for each unit spent on advertising. RoAS is calculated by dividing total attributed sales by total cost of the ad. Note: Amazon uses a default sales attribution based on a 14-day last click window. Revenue is based on sales price to end customer.'
                    )
                }
            </Translation>
        ),
    },
    cac: {
        name: (
            <Translation>
                {(t) => t('metrics:ams.cac.name', 'Customer Acquisition Cost')}
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) => t('metrics:ams.cac.shortName', 'CAC')}
            </Translation>
        ),
        helpText: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.cac.helpText',
                        'The customer acquisition cost is calculated by dividing advertising cost by attributed conversions.'
                    )
                }
            </Translation>
        ),
    },
    attributed_branded_searches_14_day__sum: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.attributed_branded_searches_14_day__sum.name',
                        'Attributed Branded Searches (14 Day)'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.attributed_branded_searches_14_day__sum.shortName',
                        'Branded Searches (14 Day)'
                    )
                }
            </Translation>
        ),
    },
    view_attributed_detail_page_view_14_day__sum: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.view_attributed_detail_page_view_14_day__sum.name',
                        'vCPM Detail Page Views (14 day)'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.view_attributed_detail_page_view_14_day__sum.shortName',
                        'Detail Page Views (14 day)'
                    )
                }
            </Translation>
        ),
    },
    view_attributed_orders_new_to_brand_14_day__sum: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.view_attributed_orders_new_to_brand_14_day__sum.name',
                        'vCPM New-To-Brand (NTB) Orders'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.view_attributed_orders_new_to_brand_14_day__sum.shortName',
                        'vCPM New-To-Brand (NTB) Orders'
                    )
                }
            </Translation>
        ),
    },
    view_attributed_sales_new_to_brand_14_day__sum: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.view_attributed_sales_new_to_brand_14_day__sum.name',
                        'vCPM New-To-Brand (NTB) Sales'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.view_attributed_sales_new_to_brand_14_day__sum.shortName',
                        'vCPM New-To-Brand (NTB) Sales'
                    )
                }
            </Translation>
        ),
    },
    view_attributed_units_ordered_new_to_brand_14_day__sum: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.view_attributed_units_ordered_new_to_brand_14_day__sum.name',
                        'vCPM New-To-Brand (NTB) Units Ordered'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.view_attributed_units_ordered_new_to_brand_14_day__sum.shortName',
                        'vCPM New-To-Brand (NTB) Units Ordered'
                    )
                }
            </Translation>
        ),
    },
    view_attributed_branded_searches_14_day__sum: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.view_attributed_branded_searches_14_day__sum.name',
                        'vCPM Branded Searches (14 Day)'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.view_attributed_branded_searches_14_day__sum.shortName',
                        'vCPM Branded Searches (14 Day)'
                    )
                }
            </Translation>
        ),
    },
    video_complete_views__sum: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.video_complete_views__sum.name',
                        'Video Complete Views'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.video_complete_views__sum.shortName',
                        'Video Complete Views'
                    )
                }
            </Translation>
        ),
    },
    video_first_quartile_views__sum: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.video_first_quartile_views__sum.name',
                        'Video First Quartile Views'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.video_first_quartile_views__sum.shortName',
                        'Video First Quartile Views'
                    )
                }
            </Translation>
        ),
    },
    video_midpoint_views__sum: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.video_midpoint_views__sum.name',
                        'Video Midpoint Views'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.video_midpoint_views__sum.shortName',
                        'Video Midpoint Views'
                    )
                }
            </Translation>
        ),
    },
    video_third_quartile_views__sum: {
        name: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.video_third_quartile_views__sum.name',
                        'Video Third Quartile Views'
                    )
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.video_third_quartile_views__sum.shortName',
                        'Video Third Quartile Views'
                    )
                }
            </Translation>
        ),
    },
    video_unmutes__sum: {
        name: (
            <Translation>
                {(t) =>
                    t('metrics:ams.video_unmutes__sum.name', 'Video Unmutes')
                }
            </Translation>
        ),
        shortName: (
            <Translation>
                {(t) =>
                    t(
                        'metrics:ams.video_unmutes__sum.shortName',
                        'Video Unmutes'
                    )
                }
            </Translation>
        ),
    },
}

export default translations
