import { invalidateCache } from 'actions/cache'
import { CEREBRO_ACCESS_TOKEN } from 'const/localStorage'
import { shouldInvalidateCache } from 'helpers/cache'
import { createCerebroAxiosInstance } from 'helpers/cerebroApi'
import reactotron from 'reactotron'
import storeRegistry from 'utilities/storeRegistry'

export const axiosInstance = createCerebroAxiosInstance()
axiosInstance.interceptors.request.use((config) => {
    const accessToken = localStorage.getItem(CEREBRO_ACCESS_TOKEN)

    if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`
    }

    if (shouldInvalidateCache(config)) {
        storeRegistry.dispatch(invalidateCache())
    }

    reactotron.display({
        name: 'API_REQUEST',
        preview: config.url,
        value: config,
    })

    return config
})
