import { ReactElement } from 'react'

import { Formik, ErrorMessage, Form } from 'formik'
import { useTranslation } from 'react-i18next'
import { object, string } from 'yup'

import { OrganizationInvitation } from 'types'

import { AuthError, SubmitButton } from '../Shared'
import styles from '../styles.scss'

interface FormValues {
    invitationId: string
}

interface Props {
    invitation: OrganizationInvitation
    onSubmit: (values: FormValues) => void
}

const JoinOrganizationForm = ({
    invitation,
    onSubmit,
}: Props): ReactElement => {
    const { t } = useTranslation('account')
    return (
        <>
            <div className={styles.title}>
                <div className={styles.subtitle}>
                    {t(
                        'account:JoinOrganizationForm.title',
                        'You have been invited by {{inviterEmail}} to join:',
                        { inviterEmail: invitation.inviter.email }
                    )}
                </div>
                <h1 className="mt-3">{invitation.organization.name}</h1>
            </div>

            <Formik
                initialValues={{ invitationId: invitation.id }}
                onSubmit={onSubmit}
                validationSchema={object().shape({
                    invitationId: string()
                        .required()
                        .label(
                            t(
                                'account:JoinOrganizationForm.invitation',
                                'Invitation'
                            )
                        ),
                })}
            >
                {() => (
                    <Form>
                        <SubmitButton className="mt-1">
                            {t(
                                'account:JoinOrganizationForm.submitBtnText',
                                'Join Organization'
                            )}
                        </SubmitButton>
                        <ErrorMessage
                            name="invitationId"
                            component="div"
                            className="fg-control-text is-error"
                        />
                    </Form>
                )}
            </Formik>

            <AuthError />
        </>
    )
}
export default JoinOrganizationForm
