import { ReactElement } from 'react'

import { CloseCircleOutlined } from '@ant-design/icons'
import { InputNumber } from 'antd'
import classNames from 'classnames'
import moment, { Moment } from 'moment'
import { useTranslation } from 'react-i18next'

import MomentDatePicker from 'components/MomentDatePicker'
import { DAY_FORMAT } from 'const/formatting'
import { formatDate } from 'helpers/dates'
import { formatDecimal } from 'helpers/inputNumber'
import { Currency } from 'types'
import { ProductCogsPeriod } from 'types/resources/productCogs'

import styles from './styles.scss'
import { IconButton } from '../Buttons'

type EditableProductCogsProperty = Pick<
    ProductCogsPeriod,
    | 'start_date'
    | 'cost_of_goods_per_unit'
    | 'supplier_shipping_cost_per_unit'
    | 'misc_cost_per_unit'
>

interface ProductCogsTableRowProps {
    cog: ProductCogsPeriod
    previousCog?: ProductCogsPeriod
    currency: Currency
    onDateChange: (value: Moment | null) => any
    onNumberChange: (
        prop: keyof EditableProductCogsProperty,
        value?: number | null
    ) => any
    errors: Record<keyof EditableProductCogsProperty, string>
    onRemoveRequested: () => any
}

const ErrorMessage = (props: { error?: string }): ReactElement | null => {
    const { error } = props
    return error ? (
        <div className={classNames(styles.fieldMessage, styles.isError)}>
            {error}
        </div>
    ) : null
}

const ProductCogsTableRow = (props: ProductCogsTableRowProps): ReactElement => {
    const {
        cog,
        previousCog,
        currency,
        onDateChange,
        onNumberChange,
        onRemoveRequested,
        errors,
    } = props

    const { t } = useTranslation('cogs')

    const getError = (
        key: keyof EditableProductCogsProperty
    ): string | undefined => {
        return errors[key]
    }

    let until = ''
    if (previousCog) {
        if (previousCog.start_date) {
            until = formatDate(moment(previousCog.start_date).add(-1, 'days'))
        } else {
            until = '?'
        }
    }
    const now = moment()
    const presentSuffix = t(
        'cogs:CogsManagement.edit.cogsRow.ongoingSuffix',
        '— Present'
    )
    const ongoing = now.isAfter(cog.start_date) ? presentSuffix : ''
    const formattedUntil = until ? `— ${until}` : ongoing

    return (
        <tr>
            <td className={styles.dateCol} aria-label="Start Date">
                <MomentDatePicker
                    value={cog.start_date ? moment(cog.start_date) : undefined}
                    format={DAY_FORMAT}
                    onChange={onDateChange}
                    className={classNames({
                        'fg-control-error': !!getError('start_date'),
                    })}
                />
                <ErrorMessage error={getError('start_date')} />
            </td>
            <td>
                <span className={styles.until}>{formattedUntil}</span>
            </td>
            <td aria-label="Cost of Goods per Unit">
                <InputNumber
                    addonBefore={currency.symbol}
                    decimalSeparator="."
                    precision={2}
                    min={0}
                    value={cog.cost_of_goods_per_unit}
                    onChange={(val?: number | null) =>
                        onNumberChange('cost_of_goods_per_unit', val)
                    }
                />
            </td>
            <td aria-label="Shipping Cost per Unit">
                <InputNumber
                    addonBefore={currency.symbol}
                    decimalSeparator="."
                    precision={2}
                    min={0}
                    value={cog.supplier_shipping_cost_per_unit}
                    onChange={(val: number | null) =>
                        onNumberChange('supplier_shipping_cost_per_unit', val)
                    }
                />
            </td>
            <td aria-label="Misc Cost per Unit">
                <InputNumber
                    addonBefore={currency.symbol}
                    decimalSeparator="."
                    precision={2}
                    min={0}
                    value={cog.misc_cost_per_unit}
                    onChange={(val: number | null) =>
                        onNumberChange('misc_cost_per_unit', val)
                    }
                />
            </td>
            <td className={styles.amountCell}>
                <span className={styles.total}>
                    {currency.symbol}
                    {formatDecimal(
                        (
                            Math.round(
                                (cog.cost_of_goods_per_unit +
                                    cog.supplier_shipping_cost_per_unit +
                                    cog.misc_cost_per_unit) *
                                    100
                            ) / 100
                        ).toFixed(2)
                    )}
                </span>
            </td>
            <td aria-label="Actions">
                <IconButton
                    icon={<CloseCircleOutlined />}
                    tooltipTitle={t(
                        'cogs:CogsManagement.edit.cogsRow.removeTooltip',
                        'Remove'
                    )}
                    danger
                    type="link"
                    onClick={onRemoveRequested}
                />
            </td>
        </tr>
    )
}

export default ProductCogsTableRow
