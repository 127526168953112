import {
    AD_ACCOUNT_PAGE,
    AD_ACCOUNTS_SUMMARY_PAGE,
    AD_GROUP_KEYWORD_PAGE,
    AD_GROUP_PAGE,
    ALERT_CREATION_PAGE,
    ALERT_PAGE,
    ALERTS_SUMMARY_PAGE,
    AMAZON_CAMPAIGN_CREATION_PAGE,
    AUTH_FORGOT_PASSWORD_PAGE,
    AUTH_INVITATION_SIGN_UP_PAGE,
    AUTH_ORGANIZATION_PAGE,
    AUTH_PAGE,
    AUTH_RESET_PASSWORD_PAGE,
    AUTH_SIGN_IN_MFA_PAGE,
    AUTH_SIGNUP_PAGE,
    AUTH_VERIFY_EMAIL_PAGE,
    AUTOMATION_PAGE,
    AUTOMATION_PROFILE_PAGE,
    BRAND_CAMPAIGN_KEYWORD_PAGE,
    BRAND_CAMPAIGN_PAGE,
    BRAND_CAMPAIGN_PRODUCT_AD_PAGE,
    BULK_UPDATES_PAGE,
    CAMPAIGN_KEYWORD_PAGE,
    CAMPAIGN_PAGE,
    CAMPAIGN_PRODUCT_AD_PAGE,
    CAMPAIGNS_SUMMARY_PAGE,
    DASHBOARD_PAGE,
    DASHBOARD_PRINT_PAGE,
    DASHBOARDS_SUMMARY_PAGE,
    DSP_ADVERTISER_PAGE,
    DSP_LINE_ITEM_PAGE,
    DSP_ORDER_PAGE,
    DSP_SUMMARY_PAGE,
    EVENTS_SUMMARY_PAGE,
    HOME_PAGE,
    INSIGHTS_PAGE,
    KEYWORD_PAGE,
    KEYWORD_RESEARCH_PAGE,
    LABEL_PAGE,
    LABELS_SUMMARY_PAGE,
    METRICS_DEBUGGER_PAGE,
    METRICS_GLOSSARY_PAGE,
    ORGANIZATION_GROUP_PAGE,
    ORGANIZATION_PAGE,
    PORTFOLIO_PAGE,
    PORTFOLIOS_SUMMARY_PAGE,
    PRODUCT_AD_PAGE,
    PRODUCT_PAGE,
    PRODUCT_PRODUCT_AD_PAGE,
    PRODUCTS_SUMMARY_PAGE,
    REPORTS_SUMMARY_PAGE,
    RULEBOOK_PAGE,
    SEGMENT_CREATION_PAGE,
    SEGMENT_EDIT_PAGE,
    SEGMENT_PAGE,
    SEGMENTS_SUMMARY_PAGE,
    SIGN_UP_MFA_SETUP_PAGE,
    SOV_KEYWORD_PAGE,
    SOV_KEYWORD_SEARCH_RESULT_PAGE,
    SOV_KEYWORDS_SUMMARY_PAGE,
    TARGETING_SUMMARY_PAGE,
    WALMART_AD_GROUP_PAGE,
    WALMART_CAMPAIGN_CREATION_PAGE,
    WALMART_CAMPAIGN_PAGE,
    WALMART_CAMPAIGNS_SUMMARY_PAGE,
    WALMART_ITEMS_SUMMARY_PAGE,
    WALMART_KEYWORDS_SUMMARY_PAGE,
} from 'const/pages'

export const PAGES_AND_PATHS = [
    {
        page: AD_ACCOUNTS_SUMMARY_PAGE,
        path: '/advertising_accounts',
    },
    {
        page: AD_ACCOUNT_PAGE,
        path: '/advertising_accounts/:adAccountId',
    },
    {
        page: AMAZON_CAMPAIGN_CREATION_PAGE,
        path: '/amazon/campaigns/create',
    },
    {
        page: BRAND_CAMPAIGN_PAGE,
        path: '/advertising_accounts/:brandId/campaigns/:campaignId',
    },
    {
        page: BRAND_CAMPAIGN_KEYWORD_PAGE,
        path: '/advertising_accounts/:brandId/campaigns/:campaignId/keywords/:keywordId',
    },
    {
        page: BRAND_CAMPAIGN_PRODUCT_AD_PAGE,
        path: '/advertising_accounts/:brandId/campaigns/:campaignId/productAds/:productAdId',
    },
    {
        page: BULK_UPDATES_PAGE,
        path: '/updates',
    },
    {
        page: CAMPAIGNS_SUMMARY_PAGE,
        path: '/campaigns',
    },
    {
        page: CAMPAIGN_PAGE,
        path: '/campaigns/:campaignId',
    },
    {
        page: CAMPAIGN_KEYWORD_PAGE,
        path: '/campaigns/:campaignId/keywords/:keywordId',
    },
    {
        page: CAMPAIGN_PRODUCT_AD_PAGE,
        path: '/campaigns/:campaignId/productAds/:productAdId',
    },
    {
        page: AD_GROUP_PAGE,
        path: '/campaigns/:campaignId/adGroups/:adGroupId',
    },
    {
        page: AD_GROUP_KEYWORD_PAGE,
        path: '/campaigns/:campaignId/adGroups/:adGroupId/keywords/:keywordId',
    },
    {
        page: DASHBOARDS_SUMMARY_PAGE,
        path: '/dashboards',
    },
    {
        page: DASHBOARD_PRINT_PAGE,
        path: '/dashboards/:dashboardId/print',
    },
    {
        page: DASHBOARD_PAGE,
        path: '/dashboards/:dashboardId',
    },
    {
        page: DSP_SUMMARY_PAGE,
        path: '/dsp',
    },
    {
        page: DSP_ADVERTISER_PAGE,
        path: '/dsp/advertisers/:advertiserId',
    },
    {
        page: DSP_ORDER_PAGE,
        path: '/dsp/orders/:orderId',
    },
    {
        page: DSP_LINE_ITEM_PAGE,
        path: '/dsp/lineItems/:lineItemId',
    },
    {
        page: PRODUCTS_SUMMARY_PAGE,
        path: '/products',
    },
    {
        page: PRODUCT_PAGE,
        path: '/products/:asin/:countryCode',
    },
    {
        page: PRODUCT_PRODUCT_AD_PAGE,
        path: '/products/:asin/:countryCode/productAds/:productAdId',
    },
    {
        page: TARGETING_SUMMARY_PAGE,
        path: '/targeting',
    },
    {
        page: KEYWORD_PAGE,
        path: '/targeting/keywords/:keywordId',
    },
    {
        page: KEYWORD_RESEARCH_PAGE,
        path: '/keyword-research',
    },
    {
        page: LABELS_SUMMARY_PAGE,
        path: '/labels',
    },
    {
        page: LABEL_PAGE,
        path: '/labels/:labelId',
    },
    {
        page: METRICS_DEBUGGER_PAGE,
        path: '/metric-debugger',
    },
    {
        page: METRICS_GLOSSARY_PAGE,
        path: '/metric-glossary',
    },
    {
        page: SEGMENTS_SUMMARY_PAGE,
        path: '/segments',
    },
    {
        page: SEGMENT_CREATION_PAGE,
        path: '/segments/create',
    },
    {
        page: SEGMENT_EDIT_PAGE,
        path: '/segments/:segmentId/edit',
    },
    {
        page: SEGMENT_PAGE,
        path: '/segments/:segmentId',
    },
    {
        page: SOV_KEYWORDS_SUMMARY_PAGE,
        path: '/sov',
    },
    {
        page: SOV_KEYWORD_PAGE,
        path: '/sov/:sovKeywordId',
    },
    {
        page: SOV_KEYWORD_SEARCH_RESULT_PAGE,
        path: '/sov/:sovKeywordId/search/:scheduledDate',
    },
    {
        page: AUTH_PAGE,
        path: '/auth',
    },
    {
        page: AUTH_SIGNUP_PAGE,
        path: '/auth/signup',
    },
    {
        page: AUTH_SIGN_IN_MFA_PAGE,
        path: '/auth/mfa',
    },
    {
        page: SIGN_UP_MFA_SETUP_PAGE,
        path: '/user/mfa/setup',
    },
    {
        page: AUTH_INVITATION_SIGN_UP_PAGE,
        path: '/auth/signup/invitation',
    },
    {
        page: AUTH_VERIFY_EMAIL_PAGE,
        path: '/auth/signup/verify',
    },
    {
        page: AUTH_ORGANIZATION_PAGE,
        path: '/auth/signup/organization',
    },
    {
        page: AUTH_FORGOT_PASSWORD_PAGE,
        path: '/auth/password/forgot',
    },
    {
        page: AUTH_RESET_PASSWORD_PAGE,
        path: '/auth/password/reset',
    },
    {
        page: ORGANIZATION_PAGE,
        path: '/account',
    },
    {
        page: ORGANIZATION_GROUP_PAGE,
        path: '/account/groups/:organizationGroupId',
    },
    {
        page: AUTOMATION_PAGE,
        path: '/automation',
    },
    {
        page: AUTOMATION_PROFILE_PAGE,
        path: '/automation/profiles/:profileId',
    },
    {
        page: REPORTS_SUMMARY_PAGE,
        path: '/reports',
    },
    {
        page: PORTFOLIOS_SUMMARY_PAGE,
        path: '/portfolios',
    },
    {
        page: PORTFOLIO_PAGE,
        path: '/portfolios/:portfolioId',
    },
    {
        page: EVENTS_SUMMARY_PAGE,
        path: '/events',
    },
    {
        page: INSIGHTS_PAGE,
        path: '/insights',
    },
    {
        page: ALERTS_SUMMARY_PAGE,
        path: '/alerts',
    },
    {
        page: ALERT_PAGE,
        path: '/alerts/:alertId',
    },
    {
        page: ALERT_CREATION_PAGE,
        path: '/alert/new',
    },
    {
        page: RULEBOOK_PAGE,
        path: '/rulebooks/:rulebookId',
    },
    // Walmart Pages
    {
        page: WALMART_AD_GROUP_PAGE,
        path: '/walmart/campaigns/:wmCampaignId/adGroups/:wmAdGroupId',
    },
    {
        page: WALMART_CAMPAIGNS_SUMMARY_PAGE,
        path: '/walmart/campaigns',
    },
    {
        page: WALMART_CAMPAIGN_CREATION_PAGE,
        path: '/walmart/campaigns/create',
    },
    {
        page: WALMART_CAMPAIGN_PAGE,
        path: '/walmart/campaigns/:wmCampaignId',
    },
    {
        page: WALMART_KEYWORDS_SUMMARY_PAGE,
        path: '/walmart/keywords',
    },
    {
        page: WALMART_ITEMS_SUMMARY_PAGE,
        path: '/walmart/items',
    },
    // Home Page should always be last in this list so that getPage
    // will work for highlighting nav items when in a nested route
    {
        page: HOME_PAGE,
        path: '/',
    },
]

export const PAGE_TO_REDUCER_KEY_MAP = {
    [AD_GROUP_KEYWORD_PAGE]: KEYWORD_PAGE,
    [BRAND_CAMPAIGN_PAGE]: CAMPAIGN_PAGE,
    [BRAND_CAMPAIGN_KEYWORD_PAGE]: KEYWORD_PAGE,
    [BRAND_CAMPAIGN_PRODUCT_AD_PAGE]: PRODUCT_AD_PAGE,
    [CAMPAIGN_KEYWORD_PAGE]: KEYWORD_PAGE,
    [CAMPAIGN_PRODUCT_AD_PAGE]: PRODUCT_AD_PAGE,
    [PRODUCT_PRODUCT_AD_PAGE]: PRODUCT_AD_PAGE,
}
