import { Component } from 'react'

import { datadogRum } from '@datadog/browser-rum'
import { withScope, captureException } from '@sentry/browser'
import { Extras } from '@sentry/types'
import PropTypes from 'prop-types'

import ErrorPage from './ErrorPage'
import ReloadPage from './ReloadPage'

function looksLikeNewVersionError(error: unknown): boolean {
    if (!(error instanceof Error)) {
        return false
    }
    const { name, message } = error
    return (
        (name === 'ChunkLoadError' &&
            message.includes('Loading chunk') &&
            message.includes('failed')) ||
        (name === 'SyntaxError' && message.includes('Unexpected token'))
    )
}

class ErrorBoundary extends Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
    }

    state = { error: null }

    componentDidCatch(error: unknown, errorInfo: unknown): void {
        this.setState({ error })
        datadogRum.addError(error)
        withScope((scope) => {
            scope.setExtras(errorInfo as Extras)
            captureException(error)
        })
    }

    render(): React.ReactNode {
        const { error } = this.state
        const { children } = this.props
        if (error) {
            if (looksLikeNewVersionError(error)) {
                return <ReloadPage />
            }

            return <ErrorPage />
        }
        // when there's not an error, render children untouched
        return children
    }
}

export default ErrorBoundary
