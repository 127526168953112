import { useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import { IntegrationIssueKey } from 'types'

interface IntegrationIssuesInfo {
    completeDescription: string
    shortDescription: string
}

export function useIntegrationIssuesInfo(): Record<
    IntegrationIssueKey,
    IntegrationIssuesInfo
> {
    const { t } = useTranslation()
    const integrationIssuesInfo: Record<
        IntegrationIssueKey,
        IntegrationIssuesInfo
    > = useMemo(
        () => ({
            default: {
                completeDescription: t(
                    'account:IntegrationIssues.missingIssueKey',
                    'Contact Cobalt 2.0 support.'
                ),
                shortDescription: t(
                    'account:IntegrationIssues.missingIssueKey',
                    'Contact Cobalt 2.0 support.'
                ),
            },
        }),
        [t]
    )

    return integrationIssuesInfo
}
