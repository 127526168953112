import { handleActions } from 'redux-actions'

import { labelsSummaryTableColumnsConfig } from 'configuration/tables/labelsTable'
import { LABEL_TEXT } from 'const/filters'
import { FILTER_SETTINGS, FILTERS } from 'const/reducerKeys'
import { serializeColumns } from 'helpers/tables'
import { defaultPage, getDefaultTable } from 'reducers/ui/defaults'

const defaultState = {
    [FILTERS]: {
        [LABEL_TEXT]: '',
    },
    [FILTER_SETTINGS]: {
        anchored: [LABEL_TEXT],
        order: [],
        displayState: {},
    },
    ...defaultPage,
    table: getDefaultTable(serializeColumns(labelsSummaryTableColumnsConfig), {
        sorter: {
            field: 'created_date',
            order: 'descend',
        },
    }),
}

export default handleActions({}, defaultState)
