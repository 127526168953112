import {
    ReactElement,
    ReactNode,
    createContext,
    useContext,
    useMemo,
} from 'react'

interface PageContext {
    pageName: string
}

const PageContextInstance = createContext<PageContext>({
    pageName: '',
})

export const usePageContext = (): PageContext => useContext(PageContextInstance)

export function PageContextProvider(props: {
    pageName: string
    children: ReactNode
}): ReactElement {
    const { pageName, children } = props
    const contextVal = useMemo(() => ({ pageName }), [pageName])

    return (
        <PageContextInstance.Provider value={contextVal}>
            {children}
        </PageContextInstance.Provider>
    )
}
