import { handleActions } from 'redux-actions'

import { organizationGroupTableColumnsConfig } from 'configuration/tables'
import { serializeColumns } from 'helpers/tables'
import { defaultTab, getDefaultTable } from 'reducers/ui/defaults'

const initialState = {
    ...defaultTab,
    table: getDefaultTable(
        serializeColumns(organizationGroupTableColumnsConfig),
        {
            sorter: {
                field: 'created_date',
                order: 'descend',
            },
        }
    ),
}

export default handleActions({}, initialState)
