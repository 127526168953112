import { automationCapabilityIdRenderer } from 'components/FieldRenderers/Renderers/automation'
import { automationCapabilityId } from 'configuration/fieldCreators/automation'
import { brandId } from 'configuration/fieldCreators/brand'
import { campaignId } from 'configuration/fieldCreators/campaign'
import { countryCode, region } from 'configuration/fieldCreators/changelog'
import {
    count,
    estimated_days_saved,
    estimated_hours_saved,
    estimated_minutes_saved,
    estimated_seconds_saved,
} from 'configuration/fieldCreators/metrics/changelog'
import { portfolioId } from 'configuration/fieldCreators/portfolio'
import { userId } from 'configuration/fieldCreators/user'

import { makeMapping } from './helpers'

const CHANGELOG_METRICS_COLUMNS = [
    count({ isVisible: true }),
    estimated_seconds_saved({ isVisible: true }),
    estimated_minutes_saved({ isVisible: true }),
    estimated_hours_saved({ isVisible: true }),
    estimated_days_saved({ isVisible: true }),
]

export const CHANGELOG_METRICS_CONFIG = makeMapping(CHANGELOG_METRICS_COLUMNS)

export const SPECIAL_CHANGELOG_GROUP_BY_COLUMNS = [
    automationCapabilityId({
        dataIndex: ['automation_capability_id'],
        renderOptions: {
            render: (cellRenderProps) =>
                automationCapabilityIdRenderer<any>({
                    cellRenderProps,
                }),
        },
    }),
    campaignId({
        dataIndex: ['campaign', 'id'],
    }),
    portfolioId({
        dataIndex: ['portfolio', 'id'],
    }),
    brandId({
        dataIndex: ['profile', 'id'],
    }),
    region({
        dataIndex: ['region'],
    }),
    countryCode({
        dataIndex: ['country_code'],
    }),
    userId({ dataIndex: ['user_id'] }),
]
