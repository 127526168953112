import {
    deliveryActivationStatusRenderer,
    removeLineItemFromLabelRenderer,
    removeOrderFromLabelRenderer,
} from 'components/FieldRenderers'
import { UNDEFINED_VALUE } from 'const/formatting'
import { titleCase } from 'helpers/formatting'
import { Field } from 'types'

import { createActionField, createField } from './createField'
import { FieldCreatorOptions } from './localTypes'

export function advertiserId<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['advertiser', 'id']
    return createField({
        ...options,
        id: 'advertiser_id',
        name: 'Advertiser',
        shortName: 'Advertiser',
        minWidth: 100,
        dataIndex,
        width: 200,
    })
}

export function orderId<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['order', 'id']
    return createField({
        ...options,
        id: 'order_id',
        name: 'Order',
        shortName: 'Order',
        minWidth: 100,
        dataIndex,
        width: 200,
    })
}

export function orderBudget<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['order', 'budget']
    return createField({
        ...options,
        id: 'order__budget',
        name: 'Order Budget',
        shortName: 'Order Budget',
        minWidth: 100,
        dataIndex,
        width: 200,
        align: 'right',
    })
}

export function orderDeliveryActivationStatus<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? [
        'order',
        'delivery_activation_status',
    ]
    return createField({
        ...options,
        id: 'order__delivery_activation_status',
        name: 'Order Delivery Activation Status',
        shortName: 'Delivery Activation Status',
        minWidth: 100,
        dataIndex,
        width: 200,
        renderOptions: {
            render: (cellRenderProps) => {
                if (!cellRenderProps.value) {
                    return UNDEFINED_VALUE
                }
                return deliveryActivationStatusRenderer<any>({
                    cellRenderProps,
                    dataIndex,
                    resourceName: 'order',
                })
            },
        },
    })
}

export function orderDeliveryStatus<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['order', 'delivery_status']
    return createField({
        ...options,
        id: 'order__delivery_status',
        name: 'Order Delivery Status',
        shortName: 'Delivery Status',
        minWidth: 100,
        dataIndex,
        width: 200,
        renderOptions: {
            render: ({ value }) => (value ? titleCase(value) : UNDEFINED_VALUE),
        },
    })
}

export function orderLabelId<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['order__label_id']
    return createField({
        ...options,
        id: 'order__label_id',
        name: 'Order Label',
        shortName: 'Order Label',
        minWidth: 50,
        dataIndex,
        width: 110,
    })
}

export function lineItemId<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['line_item', 'id']
    return createField({
        ...options,
        id: 'line_item_id',
        name: 'Line Item',
        shortName: 'Line Item',
        minWidth: 100,
        dataIndex,
        width: 200,
    })
}

export function lineItemType<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['line_item', 'line_item_type']
    return createField({
        ...options,
        id: 'line_item__line_item_type',
        name: 'Line Item Type',
        shortName: 'Type',
        minWidth: 100,
        dataIndex,
        width: 200,
    })
}

export function lineItemBudget<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['line_item', 'budget']
    return createField({
        ...options,
        id: 'line_item__budget',
        name: 'Line Item Budget',
        shortName: 'Budget',
        minWidth: 100,
        dataIndex,
        align: 'right',
        width: 200,
    })
}

export function lineItemDeliveryActivationStatus<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? [
        'line_item',
        'delivery_activation_status',
    ]
    return createField({
        ...options,
        id: 'line_item__delivery_activation_status',
        name: 'Line Item Delivery Activation Status',
        shortName: 'Delivery Activation Status',
        minWidth: 100,
        dataIndex,
        width: 200,
        renderOptions: {
            render: (cellRenderProps) => {
                if (!cellRenderProps.value) {
                    return UNDEFINED_VALUE
                }
                return deliveryActivationStatusRenderer<any>({
                    cellRenderProps,
                    dataIndex,
                    resourceName: 'line_item',
                })
            },
        },
    })
}

export function lineItemDeliveryStatus<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['line_item', 'delivery_status']
    return createField({
        ...options,
        id: 'line_item__delivery_status',
        name: 'Line Item Delivery Status',
        shortName: 'Delivery Status',
        minWidth: 100,
        dataIndex,
        width: 200,
        renderOptions: {
            render: ({ value }) => (value ? titleCase(value) : UNDEFINED_VALUE),
        },
    })
}

export function lineItemBid<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['line_item', 'bid']
    return createField({
        ...options,
        id: 'line_item__bid',
        name: 'Line Item Bid',
        shortName: 'Bid',
        minWidth: 100,
        dataIndex,
        width: 200,
        align: 'right',
    })
}

export function lineItemLabelId<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['line_item__label_id']
    return createField({
        ...options,
        id: 'line_item__label_id',
        name: 'Line Item Label',
        shortName: 'Label',
        minWidth: 50,
        dataIndex,
        width: 110,
    })
}

export function removeLineItemFromLabel<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createActionField({
        ...options,
        id: 'remove_line_item_from_label',
        dataIndex: [],
        isResizeable: false,
        minWidth: 68,
        renderOptions: options.renderOptions ?? {
            render: (props) => removeLineItemFromLabelRenderer<any>(props),
        },
        sorter: false,
        fixed: 'right',
        align: 'center',
    })
}

export function removeOrderFromLabel<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createActionField({
        ...options,
        id: 'remove_order_from_label',
        dataIndex: [],
        isResizeable: false,
        minWidth: 68,
        renderOptions: options.renderOptions ?? {
            render: (props) => removeOrderFromLabelRenderer<any>(props),
        },
        sorter: false,
        fixed: 'right',
        align: 'center',
    })
}
