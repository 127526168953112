import { handleActions } from 'redux-actions'

import { spIntegrationsTableColumnsConfig } from 'configuration/tables/integrationsTable'
import { serializeColumns } from 'helpers/tables'
import { defaultTab, getDefaultTable } from 'reducers/ui/defaults'

const initialState = {
    ...defaultTab,
    table: getDefaultTable(serializeColumns(spIntegrationsTableColumnsConfig), {
        sorter: {
            field: 'created_at',
            order: 'descend',
        },
    }),
}

export default handleActions({}, initialState)
