import { memo, ReactElement } from 'react'

import isEqual from 'lodash/isEqual'
import { useTranslation } from 'react-i18next'

import { DISABLED, ENABLED } from 'const/resourceStates'
import { userHasWalmartAdvertisingWritePermissions } from 'helpers/featurePermissions'
import { formatWalmartResourceStateField } from 'helpers/walmart'
import { useUserHasPermissions } from 'hooks'
import { updateWalmartAdGroup } from 'services/cerebroApi/orgScope/walmartApi'
import { WalmartAdGroupGroupBy } from 'types'

import { StateField } from '../../SharedFields/StateField'

interface Props {
    dataIndex: string[]
    record: WalmartAdGroupGroupBy
}

function WalmartAdGroupStateField({
    dataIndex = ['ad_group', 'state'],
    record,
}: Props): ReactElement {
    const { t } = useTranslation(['table', 'common'])
    const hasWalmartPermissions = useUserHasPermissions(
        userHasWalmartAdvertisingWritePermissions
    )

    const resourceId = record.ad_group_id

    const AVAILABLE_OPTIONS = {
        [ENABLED]: t('common:enabled', 'Enabled'),
        [DISABLED]: t('common:disabled', 'Disabled'),
    }

    return (
        <StateField
            disabled={
                !record.ad_group.user_can_manage || record.advertiser?.read_only
            }
            fieldName="state"
            fieldPath={dataIndex}
            formatFieldValue={() =>
                formatWalmartResourceStateField(record, 'ad_group')
            }
            options={AVAILABLE_OPTIONS}
            placeholder="Ad Group State"
            readOnly={
                !record.ad_group.user_can_manage || !hasWalmartPermissions
            }
            record={record}
            updateRequestApi={updateWalmartAdGroup}
            updateRequestData={resourceId}
            updateRequestSuccessMesg={t(
                'table:fields.walmartAdGroup.update.stateUpdateSuccessMsg',
                'Successfully updated Ad Group state'
            )}
        />
    )
}

export default memo(
    WalmartAdGroupStateField,
    isEqual
) as typeof WalmartAdGroupStateField
