import {
    campaignActiveBudgetRenderer,
    campaignBudgetRenderer,
} from 'components/FieldRenderers/Renderers/campaign'
import {
    brandCountryCode,
    brandCurrencyCode,
    brandEntityId,
    brandId,
    brandRegion,
    brandTimezone,
    brandType,
} from 'configuration/fieldCreators/brand'
import {
    archiveCampaign,
    campaignActiveBudget,
    campaignAdFormat,
    campaignAutomatedBidding,
    campaignAutomationTasks,
    campaignBidMultiplierDetail,
    campaignBidMultiplierOther,
    campaignBidMultiplierTopOfSearch,
    campaignBudget,
    campaignBudgetStatus,
    campaignCreatedDate,
    campaignEndDate,
    campaignId,
    campaignStartDate,
    campaignState,
    campaignTargetingType,
    campaignType,
    removeCampaignFromLabel,
    removeCampaignFromPortfolio,
} from 'configuration/fieldCreators/campaign'
import { labels } from 'configuration/fieldCreators/labels'
import { portfolioName } from 'configuration/fieldCreators/portfolio'
import {
    CampaignFact,
    ProfileGroupBy,
    PortfolioGroupBy,
    Field,
    SerializedPlacement,
    CampaignBudgetStatusGroupBy,
} from 'types'

import { AMS_METRICS_COLUMNS_PAGES } from '../dataSources/ams'

export type CampaignFactRecord = CampaignFact<
    CampaignBudgetStatusGroupBy &
        ProfileGroupBy &
        PortfolioGroupBy &
        SerializedPlacement
>

export const campaignsSummaryTableColumnsConfig: Field<CampaignFactRecord>[] = [
    campaignId({ fixed: 'left' }),
    campaignType(),
    campaignAdFormat(),
    campaignTargetingType(),
    campaignBudgetStatus(),
    campaignState(),
    campaignBudget({
        renderOptions: {
            render: (cellRenderProps) =>
                campaignBudgetRenderer({
                    cellRenderProps,
                    dataIndex: ['campaign', 'base_budget'],
                }),
        },
    }),
    campaignActiveBudget({
        renderOptions: {
            render: (cellRenderProps) =>
                campaignActiveBudgetRenderer({
                    cellRenderProps,
                    dataIndex: ['campaign', 'active_budget'],
                }),
        },
    }),
    campaignEndDate(),
    campaignStartDate(),
    campaignCreatedDate(),
    campaignAutomatedBidding(),
    campaignAutomationTasks(),
    brandId(),
    brandCountryCode(),
    brandCurrencyCode(),
    brandRegion(),
    brandTimezone(),
    brandType(),
    brandEntityId(),
    portfolioName(),
    labels({ dataIndex: ['campaign', 'labels'] }),
    ...(AMS_METRICS_COLUMNS_PAGES as Field<CampaignFactRecord>[]),
    campaignBidMultiplierTopOfSearch(),
    campaignBidMultiplierDetail(),
    campaignBidMultiplierOther(),
    archiveCampaign(),
]

export const brandCampaignsTableColumnsConfig: Field<CampaignFactRecord>[] = [
    campaignId({ fixed: 'left' }),
    campaignType(),
    campaignAdFormat(),
    campaignTargetingType(),
    campaignBidMultiplierTopOfSearch(),
    campaignBidMultiplierDetail(),
    campaignBidMultiplierOther(),
    campaignState(),
    campaignBudget({
        renderOptions: {
            render: (cellRenderProps) =>
                campaignBudgetRenderer({
                    cellRenderProps,
                    dataIndex: ['campaign', 'base_budget'],
                }),
        },
    }),
    campaignActiveBudget({
        renderOptions: {
            render: (cellRenderProps) =>
                campaignActiveBudgetRenderer({
                    cellRenderProps,
                    dataIndex: ['campaign', 'active_budget'],
                }),
        },
    }),
    campaignEndDate(),
    campaignStartDate(),
    campaignCreatedDate(),
    campaignAutomatedBidding(),
    campaignAutomationTasks(),
    portfolioName(),
    labels({ dataIndex: ['campaign', 'labels'] }),
    ...(AMS_METRICS_COLUMNS_PAGES as Field<CampaignFactRecord>[]),
    archiveCampaign(),
]

export const portfolioCampaignsTableColumnsConfig: Field<CampaignFactRecord>[] =
    [
        campaignId({ fixed: 'left' }),
        campaignType(),
        campaignAdFormat(),
        campaignTargetingType(),
        campaignBidMultiplierTopOfSearch(),
        campaignBidMultiplierDetail(),
        campaignBidMultiplierOther(),
        campaignState(),
        campaignBudget({
            renderOptions: {
                render: (cellRenderProps) =>
                    campaignBudgetRenderer({
                        cellRenderProps,
                        dataIndex: ['campaign', 'base_budget'],
                    }),
            },
        }),
        campaignActiveBudget({
            renderOptions: {
                render: (cellRenderProps) =>
                    campaignActiveBudgetRenderer({
                        cellRenderProps,
                        dataIndex: ['campaign', 'active_budget'],
                    }),
            },
        }),
        campaignEndDate(),
        campaignStartDate(),
        campaignCreatedDate(),
        campaignAutomatedBidding(),
        campaignAutomationTasks(),
        labels({ dataIndex: ['campaign', 'labels'] }),
        ...(AMS_METRICS_COLUMNS_PAGES as Field<CampaignFactRecord>[]),
        removeCampaignFromPortfolio(),
    ]

export const labelCampaignsTableColumnsConfig: Field<CampaignFactRecord>[] = [
    campaignId({ fixed: 'left' }),
    campaignType(),
    campaignAdFormat(),
    campaignTargetingType(),
    campaignBidMultiplierTopOfSearch(),
    campaignBidMultiplierDetail(),
    campaignBidMultiplierOther(),
    campaignState(),
    campaignBudget({
        renderOptions: {
            render: (cellRenderProps) =>
                campaignBudgetRenderer({
                    cellRenderProps,
                    dataIndex: ['campaign', 'base_budget'],
                }),
        },
    }),
    campaignActiveBudget({
        renderOptions: {
            render: (cellRenderProps) =>
                campaignActiveBudgetRenderer({
                    cellRenderProps,
                    dataIndex: ['campaign', 'active_budget'],
                }),
        },
    }),
    campaignEndDate(),
    campaignStartDate(),
    campaignCreatedDate(),
    campaignAutomatedBidding(),
    campaignAutomationTasks(),
    brandId(),
    brandCountryCode(),
    brandRegion(),
    portfolioName(),
    labels({ dataIndex: ['campaign', 'labels'] }),
    ...(AMS_METRICS_COLUMNS_PAGES as Field<CampaignFactRecord>[]),
    removeCampaignFromLabel(),
]
