import {
    productAdTitleRenderer,
    productAdPriceRenderer,
    productAdAsinRenderer,
    productAdStateRenderer,
    archiveProductAdRenderer,
} from 'components/FieldRenderers'
import { AMS_METRICS_COLUMNS_PAGES } from 'configuration/dataSources/ams'
import { adGroupName } from 'configuration/fieldCreators/adGroup'
import { campaignName } from 'configuration/fieldCreators/campaign'
import {
    archiveProductAd,
    productAdState,
    productAsin,
    productMetaDataNumberOfItems,
    productMetaDataPrice,
    productMetaDataTitle,
    productSku,
} from 'configuration/fieldCreators/product'
import {
    AdGroupGroupBy,
    CampaignGroupBy,
    ProfileGroupBy,
    Field,
    ProductAdFact,
} from 'types'

export type ProductAdFactRecord = ProductAdFact<
    AdGroupGroupBy & CampaignGroupBy & ProfileGroupBy
>

export const campaignProductAdsTableColumnsConfig: Field<ProductAdFactRecord>[] =
    [
        productMetaDataTitle({
            fixed: 'left',
            dataIndex: ['product_ad', 'product_metadata', 'title'],
            renderOptions: {
                render: (cellRenderProps) =>
                    productAdTitleRenderer({
                        cellRenderProps,
                    }),
            },
        }),
        productMetaDataPrice({
            dataIndex: ['product_ad', 'product_metadata', 'price'],
            renderOptions: {
                render: (cellRenderProps) =>
                    productAdPriceRenderer({
                        cellRenderProps,
                    }),
            },
        }),
        productAsin({
            dataIndex: ['product_ad', 'asin'],
            renderOptions: {
                render: (cellRenderProps) =>
                    productAdAsinRenderer({
                        cellRenderProps,
                    }),
            },
        }),
        productSku({
            dataIndex: ['product_ad', 'sku'],
        }),
        productAdState({
            renderOptions: {
                render: (cellRenderProps) =>
                    productAdStateRenderer({
                        cellRenderProps,
                    }),
            },
        }),
        adGroupName({
            dataIndex: ['product_ad', 'ad_group', 'name'],
        }),
        ...(AMS_METRICS_COLUMNS_PAGES as Field<ProductAdFactRecord>[]),
        archiveProductAd({
            renderOptions: {
                render: (cellRenderProps) =>
                    archiveProductAdRenderer({
                        cellRenderProps,
                    }),
            },
        }),
    ]

export const productsSummaryProductAdsTableColumnsConfig: Field<ProductAdFactRecord>[] =
    [
        productMetaDataTitle({
            fixed: 'left',
            dataIndex: ['product_ad', 'product_metadata', 'title'],
            renderOptions: {
                render: (cellRenderProps) =>
                    productAdTitleRenderer({
                        cellRenderProps,
                    }),
            },
        }),
        productMetaDataPrice({
            dataIndex: ['product_ad', 'product_metadata', 'price'],
            renderOptions: {
                render: (cellRenderProps) =>
                    productAdPriceRenderer({
                        cellRenderProps,
                    }),
            },
        }),
        productMetaDataNumberOfItems({
            dataIndex: ['product_ad', 'product_metadata', 'number_of_items'],
        }),
        productAsin({
            dataIndex: ['product_ad', 'asin'],
            renderOptions: {
                render: (cellRenderProps) =>
                    productAdAsinRenderer({
                        cellRenderProps,
                    }),
            },
        }),
        productSku({
            dataIndex: ['product_ad', 'sku'],
        }),
        productAdState({
            renderOptions: {
                render: (cellRenderProps) =>
                    productAdStateRenderer({
                        cellRenderProps,
                    }),
            },
        }),
        campaignName(),
        ...(AMS_METRICS_COLUMNS_PAGES as Field<ProductAdFactRecord>[]),
        archiveProductAd({
            renderOptions: {
                render: (cellRenderProps) =>
                    archiveProductAdRenderer({
                        cellRenderProps,
                    }),
            },
        }),
    ]
