import {
    dashboardDescriptionRenderer,
    dashboardNameRenderer,
    dashboardSharingRenderer,
    deleteDashboardRenderer,
} from 'components/FieldRenderers'
import {
    dashboardDescription,
    dashboardName,
    dashboardSharing,
    deleteDashboard,
    setAsHomePage,
} from 'configuration/fieldCreators/dashboard'
import { dateCreated } from 'configuration/fieldCreators/date'
import { userId } from 'configuration/fieldCreators/user'
import { Dashboard, Field } from 'types'

export const dashboardsTableColumnsConfig: Field<Dashboard>[] = [
    setAsHomePage({
        fixed: 'left',
    }),
    dashboardName({
        renderOptions: {
            render: (cellRenderProps) =>
                dashboardNameRenderer({
                    cellRenderProps,
                }),
        },
    }),
    dashboardDescription({
        renderOptions: {
            render: (cellRenderProps) =>
                dashboardDescriptionRenderer({
                    cellRenderProps,
                }),
        },
    }),
    userId({ dataIndex: ['created_by'] }),
    dateCreated(),
    dashboardSharing({
        renderOptions: {
            render: (cellRenderProps) =>
                dashboardSharingRenderer({ cellRenderProps }),
        },
    }),
    deleteDashboard({
        fixed: 'right',
        renderOptions: {
            render: (cellRenderProps) =>
                deleteDashboardRenderer({
                    cellRenderProps,
                }),
        },
    }),
]
