import isEmpty from 'lodash/isEmpty'
import { call, put, select } from 'redux-saga/effects'

import { makeFetchListRequest, makeFetchListSuccess } from 'actions/ui/shared'
import { RULEBOOK_NAME, RULEBOOK_STATES } from 'const/filters'
import { AUTOMATION_PAGE } from 'const/pages'
import { DISABLED, ENABLED } from 'const/rulebook'
import { formatSorter } from 'helpers/params'
import { isNonEmptyArray } from 'helpers/typeGuard'
import { cerebroApiSaga } from 'sagas/common'
import uiSagaRegistry from 'sagas/ui/registry'
import { selectListSettings, selectVisibleCombinedFilters } from 'selectors/ui'
import { getRulebooks } from 'services/cerebroApi/orgScope/rulebooksApi'

const TAB_PATH = [AUTOMATION_PAGE, 'rulebooks']
const LIST_PATH = [...TAB_PATH, 'list']

const formatRulebooksFilters = (
    filters: Record<string, any>
): Record<string, any> => {
    const params: any = {}

    if (!isEmpty(filters[RULEBOOK_NAME])) {
        params.name__icontains = filters[RULEBOOK_NAME]
    }

    if (isNonEmptyArray(filters[RULEBOOK_STATES])) {
        const desired_rulebook_states = filters[RULEBOOK_STATES]
        let search_enabled_rulebooks = false
        let search_disabled_rulebooks = false
        desired_rulebook_states.forEach((state: any) => {
            search_enabled_rulebooks =
                search_enabled_rulebooks || state.value === ENABLED
            search_disabled_rulebooks =
                search_disabled_rulebooks || state.value === DISABLED
        })
        if (search_enabled_rulebooks && !search_disabled_rulebooks) {
            params.active_version__isnull = false
        } else if (!search_enabled_rulebooks && search_disabled_rulebooks) {
            params.active_version__isnull = true
        }
    }

    return params
}

/**
 * Fetch table data
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* fetchSaga(): any {
    const filters = yield select(selectVisibleCombinedFilters, TAB_PATH)
    const { sorter } = yield select(selectListSettings, LIST_PATH)
    const params = {
        ...formatRulebooksFilters(filters),
        ...formatSorter(sorter),
    }
    yield call(
        cerebroApiSaga,
        makeFetchListSuccess(LIST_PATH),
        getRulebooks,
        params
    )
}

/**
 * Fetches all data for automations tab
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* fetchTabDataSaga() {
    yield put(makeFetchListRequest(LIST_PATH)())
}

// Register sagas
uiSagaRegistry.registerSagas(TAB_PATH, {
    fetchTabDataSaga,
})

uiSagaRegistry.registerSagas(LIST_PATH, {
    fetchDataSaga: fetchSaga,
})
