import * as React from 'react'

import PropTypes from 'prop-types'

const withCustomHandlers = (Component) => {
    class WrappedComponent extends React.Component {
        constructor(props) {
            super(props)

            this.handleChange = (value) => {
                const {
                    field: { name },
                    form: { setFieldValue },
                    parseLists,
                } = this.props

                let parsedValue = value
                if (parseLists && value.length) {
                    const head = value.slice(0, -1)
                    const tail = value
                        .slice(-1)[0]
                        .split(/[ ,;\n]+/)
                        .filter((v) => v)

                    parsedValue = [...new Set([...head, ...tail])]
                }

                setFieldValue(name, parsedValue)

                if (props.handlePostChange) {
                    props.handlePostChange(parsedValue)
                }
            }

            this.handleBlur = () => {
                const {
                    field: { name },
                    form: { setFieldTouched },
                } = this.props

                setFieldTouched(name, true)
            }

            this.handleClick = this.handleBlur
        }

        render() {
            const { parseLists, handlePostChange, ...rest } = this.props

            return (
                <Component
                    handleChange={this.handleChange}
                    handleClick={this.handleClick}
                    handleBlur={this.handleBlur}
                    {...rest}
                />
            )
        }
    }

    WrappedComponent.propTypes = {
        field: PropTypes.object.isRequired,
        form: PropTypes.shape({
            setFieldValue: PropTypes.func,
            setFieldTouched: PropTypes.func,
        }).isRequired,
        parseLists: PropTypes.bool,
    }

    WrappedComponent.defaultProps = {
        parseLists: false,
    }

    return WrappedComponent
}

export default withCustomHandlers
