import reduce from 'lodash/reduce'

import {
    PLACEMENT_PRODUCT_PAGE as PPG_TYPE,
    PLACEMENT_TOP as PT_TYPE,
    PLACEMENT_OTHER as PO_TYPE,
    PlacementProductPage,
    PlacementTopOfSearch,
    PlacementRestOfSearch,
} from 'types'

type PlacementCategories = PPG_TYPE | PT_TYPE | PO_TYPE

type PlacementKeys =
    | PlacementProductPage
    | PlacementTopOfSearch
    | PlacementRestOfSearch

export const SP_PLACEMENT_MULTIPLIER_MIN = 0.0
export const SP_PLACEMENT_MULTIPLIER_MAX = 900
export const SB_PLACEMENT_MULTIPLIER_MIN = -99
export const SB_PLACEMENT_MULTIPLIER_MAX = 99
export const PLACEMENT_MULTIPLIER_STEP = 1
export const SP_PLACEMENT_MULTIPLIER_PRECISION = 1
export const SB_PLACEMENT_MULTIPLIER_PRECISION = 2

export const PLACEMENT_PRODUCT_PAGE: PPG_TYPE = 'placementProductPage'
export const PLACEMENT_OTHER: PO_TYPE = 'placementOther'
export const PLACEMENT_TOP: PT_TYPE = 'placementTop'

const PRODUCT_PAGE: PlacementProductPage[] = ['Detail Page on-Amazon']

const TOP_OF_SEARCH: PlacementTopOfSearch[] = [
    'Top of Search on-Amazon',
    'Inizio della ricerca',
    'Oben in den Suchergebnissen',
    'En haut de la recherche',
    'Top de Búsqueda',
    'Top of Search',
    '検索のトップ',
]

const REST_OF_SEARCH: PlacementRestOfSearch[] = [
    'Other on-Amazon',
    'Other Placements',
    'Otros anuncios',
    'Andere Platzierungen',
    'Remarketing off-Amazon',
    'Altri posizionamenti',
    'Autres placements',
    'その他の広告枠',
]

const PLACEMENT_CATEGORIES: {
    [key in PlacementCategories]:
        | PlacementRestOfSearch[]
        | PlacementTopOfSearch[]
        | PlacementProductPage[]
} = {
    [PLACEMENT_PRODUCT_PAGE]: PRODUCT_PAGE,
    [PLACEMENT_OTHER]: REST_OF_SEARCH,
    [PLACEMENT_TOP]: TOP_OF_SEARCH,
}

export const PLACEMENT_TYPES: {
    [key in PlacementKeys]: PPG_TYPE | PO_TYPE | PT_TYPE
} = reduce(
    PLACEMENT_CATEGORIES,
    (accumCategories: any, categoryArray, categoryKey) => ({
        ...accumCategories,
        ...reduce(
            categoryArray,
            (accumPlacements, placementKey) => ({
                ...accumPlacements,
                [placementKey]: categoryKey,
            }),
            {}
        ),
    }),
    {}
)
