import { ReactElement } from 'react'

import { Checkbox } from 'antd'
import { type CheckboxChangeEvent } from 'antd/es/checkbox'
import { useTranslation } from 'react-i18next'

import { ConditionalWrapper } from 'components/ConditionalWrapper'
import { ToolTip } from 'components/ToolTip'
import { isMetricField } from 'helpers/tables'
import { Field } from 'types'

import styles from './styles.scss'

interface Props<T> {
    column: Field<T>
    displayStateDisabled: (column: Field<T>) => boolean
    disabledTooltip?: string | null
    onCheckboxChange: (event: CheckboxChangeEvent, column: Field<T>) => void
    isDraggable?: boolean
}

function Setting<T>({
    column,
    displayStateDisabled,
    disabledTooltip,
    onCheckboxChange,
    isDraggable = true,
}: Props<T>): ReactElement {
    const { t } = useTranslation('common')
    return (
        <>
            <div>
                <ConditionalWrapper
                    condition={displayStateDisabled(column)}
                    wrapper={(children) => (
                        <ToolTip title={disabledTooltip}>{children}</ToolTip>
                    )}
                >
                    <Checkbox
                        checked={Boolean(column.isVisible)}
                        onChange={(event) => onCheckboxChange(event, column)}
                        disabled={displayStateDisabled(column)}
                    >
                        {column.name}
                    </Checkbox>
                </ConditionalWrapper>
            </div>
            <div className="d-flex">
                {isMetricField(column) && (
                    <span className={styles.metric}>
                        {t('common:metric', 'Metric')}
                    </span>
                )}
                {isDraggable && (
                    <div className={styles['drag-handle']}>
                        <span />
                        <span />
                        <span />
                    </div>
                )}
            </div>
        </>
    )
}

export default Setting
