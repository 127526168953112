import { ReactElement, ReactNode, CSSProperties } from 'react'

import { Tooltip } from 'antd'
import classNames from 'classnames'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'

import { useAppConfig } from 'appConfig'
import {
    isUpdatePending,
    isUpdateFailed,
    isUpdateComplete,
    isUpdateDryRun,
} from 'helpers/bulkUpdate'
import { AsyncUpdate, BulkUpdateState } from 'types'

import styles from './styles.scss'

const getTitleAndContent = (
    update: AsyncUpdate,
    t: TFunction,
    productTitle: string
): { title: string; content: ReactElement } => {
    if (isUpdatePending(update)) {
        return {
            title: t(
                'asyncUpdates:AsyncUpdateStatus.pending.title',
                'Bulk update is still being processed.'
            ),
            content: (
                <span className={styles.pending}>
                    {t(
                        'asyncUpdates:AsyncUpdateStatus.pending.content',
                        'Pending...'
                    )}
                </span>
            ),
        }
    }

    if (isUpdateFailed(update)) {
        let title
        switch (update.state) {
            case BulkUpdateState.FAILURE:
                title = t(
                    'asyncUpdates:AsyncUpdateStatus.failed.title.systemError',
                    'System error occurred during bulk update.'
                )
                break
            case BulkUpdateState.TIMEOUT:
                title = t(
                    'asyncUpdates:AsyncUpdateStatus.failed.title.timeout',
                    'Bulk update timed out during processing.'
                )
                break
            default:
                title = t(
                    'asyncUpdates:AsyncUpdateStatus.failed.title.generic',
                    'Bulk update failed.'
                )
        }
        return {
            title,
            content: (
                <span className={styles.failure}>
                    {t(
                        'asyncUpdates:AsyncUpdateStatus.failed.content',
                        'Failure'
                    )}
                </span>
            ),
        }
    }

    if (isUpdateComplete(update)) {
        let title
        let extra: ReactNode = null
        switch (update.state) {
            case BulkUpdateState.DRY_RUN:
                title = t(
                    'asyncUpdates:AsyncUpdateStatus.complete.title.dryRun',
                    'Dry run successfully completed.'
                )
                break
            case BulkUpdateState.COMPLETE:
                title = t(
                    'asyncUpdates:AsyncUpdateStatus.complete.title.liveRun',
                    'All records successfully updated.'
                )
                break
            case BulkUpdateState.PARTIAL_FAILURE:
                title = t(
                    'asyncUpdates:AsyncUpdateStatus.complete.title.partialFailure',
                    'Some records were not updated.'
                )
                extra = (
                    <span className={classNames(styles.pending, 'ml-1')}>
                        (
                        {t(
                            'asyncUpdates:AsyncUpdateStatus.complete.extraContent',
                            'with conflicts'
                        )}
                        )
                    </span>
                )
                break
            default:
                title = t(
                    'asyncUpdates:AsyncUpdateStatus.complete.title.default',
                    'Bulk update complete.'
                )
        }
        return {
            title,
            content: (
                <span className={styles.complete}>
                    {t(
                        'asyncUpdates:AsyncUpdateStatus.complete.content',
                        'Complete'
                    )}
                    {extra}
                </span>
            ),
        }
    }

    return {
        title: t(
            'asyncUpdates:AsyncUpdateStatus.unknown.title',
            'Unknown status. Please contact {{ productTitle }}.',
            { productTitle }
        ),
        content: (
            <span className={styles.pending}>
                {t('asyncUpdates:AsyncUpdateStatus.unknown.content', 'Unknown')}
            </span>
        ),
    }
}

interface Props {
    update: AsyncUpdate
    style?: CSSProperties
}

const AsyncUpdateStatus = ({ update, style = {} }: Props): ReactElement => {
    const { t } = useTranslation('asyncUpdates')
    const { productTitle } = useAppConfig()

    const { title, content } = getTitleAndContent(update, t, productTitle)
    return (
        <Tooltip title={title}>
            <div className={styles.container} style={style}>
                <div>{content}</div>
                {isUpdateDryRun(update) && (
                    <div className="ml-2">
                        <span className="fg-warning-tag">
                            {t(
                                'asyncUpdates:AsyncUpdateStatus.dryRunTagText',
                                'Dry Run'
                            )}
                        </span>
                    </div>
                )}
            </div>
        </Tooltip>
    )
}

export default AsyncUpdateStatus
