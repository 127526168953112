import flow from 'lodash/fp/flow'
import set from 'lodash/fp/set'

import {
    addLabelsRequest,
    addLabelsSuccess,
    addLabelsFailure,
} from 'actions/ui/shared/label'

export default {
    [addLabelsRequest](state, { payload: { pageName } }) {
        return set([pageName, 'addingLabels'], true, state)
    },
    [addLabelsSuccess](state, { payload: { pageName } }) {
        return set([pageName, 'addingLabels'], false, state)
    },
    [addLabelsFailure](
        state,
        {
            payload: {
                pageName,
                data: { message },
            },
        }
    ) {
        return flow(
            set([pageName, 'addingLabels'], false),
            set([pageName, 'error'], message)
        )(state)
    },
}
