import { ReactElement } from 'react'

import { useTranslation } from 'react-i18next'

import { LABEL_PAGE } from 'const/pages'
import { userHasManageLabelsPermissions } from 'helpers/featurePermissions'
import { useUserHasPermissions, useResourceOfPage } from 'hooks'
import { updateDspOrder } from 'services/cerebroApi/orgScope/dspApi'
import { DspOrderGroupBy, Label } from 'types'

import { DeleteField } from '../../SharedFields/DeleteField'

interface Props<RecordType> {
    readOnly: boolean
    record: RecordType
    reloadData: () => void
}

export function RemoveFromLabelField<RecordType extends DspOrderGroupBy>({
    readOnly = false,
    record,
    reloadData,
}: Props<RecordType>): ReactElement {
    const { t } = useTranslation(['table', 'common'])
    const hasLabelPermissions = useUserHasPermissions(
        userHasManageLabelsPermissions
    )

    const label: Label | null = useResourceOfPage(LABEL_PAGE)

    const getIsDisabled = (): boolean => {
        return !hasLabelPermissions || readOnly
    }

    return (
        <DeleteField
            disabled={getIsDisabled()}
            okText={t('common:remove', 'Remove')}
            title={t(
                'table:fields.removeOrderFromLabel.confirmTxt',
                'Are you sure you want to remove this order from {{name}}?',
                {
                    name: label?.name,
                }
            )}
            updateRequestCallback={reloadData}
            updateRequestApi={updateDspOrder}
            updateRequestData={record.order.id}
            updateRequestParams={{
                remove_label_ids: [label?.id],
            }}
            updateRequestSuccessMesg={t(
                'table:fields.removeOrderFromLabel.successMsg',
                'Order successfully removed'
            )}
        />
    )
}
