import set from 'lodash/fp/set'
import { handleActions } from 'redux-actions'

import { fetchPortfoliosSuccess } from 'actions/ui/campaignPage/page'
import { DATES, AGGREGATION } from 'const/filters'
import { CAMPAIGN_PAGE } from 'const/pages'
import { LOCAL_FILTERS, FILTER_SETTINGS } from 'const/reducerKeys'

import {
    defaultResourcePageTabs,
    defaultResourcePageWidgets,
    defaultResourcePage,
} from '../defaults'

const defaultState = {
    [LOCAL_FILTERS]: {},
    [FILTER_SETTINGS]: {
        anchored: [DATES, AGGREGATION],
        order: [],
        displayState: {},
    },

    ...defaultResourcePage,
    portfolios: [],
    tabs: defaultResourcePageTabs,
    widgets: defaultResourcePageWidgets(CAMPAIGN_PAGE),
}

export default handleActions(
    {
        [fetchPortfoliosSuccess](state, action) {
            return set(['portfolios'], action.payload.results)(state)
        },
    },
    defaultState
)
