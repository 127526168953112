import { handleActions } from 'redux-actions'

import { DATES } from 'const/filters'
import { FILTER_SETTINGS, LOCAL_FILTERS } from 'const/reducerKeys'
import { defaultTab, defaultChart } from 'reducers/ui/defaults'

const initialState = {
    ...defaultTab,
    [LOCAL_FILTERS]: {},
    [FILTER_SETTINGS]: {
        anchored: [DATES],
        order: [],
        displayState: {},
    },
    chart: defaultChart,
}

export default handleActions({}, initialState)
