import { ReactElement } from 'react'

import classnames from 'classnames'

import styles from './styles.scss'

interface Props {
    color: string
    className?: string
}

export default ({ color, className, ...props }: Props): ReactElement => (
    <div
        className={classnames(styles['label-color'], className)}
        style={{
            background: color,
        }}
        {...props}
    />
)
