import cloneDeep from 'lodash/cloneDeep'
import { handleActions } from 'redux-actions'

import { DATES } from 'const/filters'
import { FILTERS, FILTER_SETTINGS } from 'const/reducerKeys'

import { defaultDashboardPage } from '../defaults'

const defaultState = {
    [FILTERS]: {
        [DATES]: [],
    },
    [FILTER_SETTINGS]: {
        anchored: [DATES],
        order: [],
        displayState: {},
    },

    ...defaultDashboardPage,
}

export default handleActions(
    {},
    cloneDeep(defaultState) // create clone, so the defaultState is not mutated
)
