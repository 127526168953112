import { SVGAttributes, ReactElement } from 'react'

type Props = SVGAttributes<SVGElement>

function SvgEditIcon(props: Props): ReactElement {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 14 14"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                xmlns="http://www.w3.org/2000/svg"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.839 4.85548C11.5537 5.14076 11.411 5.2834 11.2413 5.32153C11.1547 5.34098 11.0649 5.34098 10.9783 5.32153C10.8085 5.2834 10.6659 5.14076 10.3806 4.85548L9.50562 3.98048C9.22034 3.6952 9.0777 3.55255 9.03957 3.38281C9.02012 3.29622 9.02012 3.2064 9.03957 3.11981C9.0777 2.95006 9.22034 2.80742 9.50562 2.52214C9.79091 2.23686 9.93355 2.09422 10.1033 2.05609C10.1899 2.03664 10.2797 2.03664 10.3663 2.05609C10.536 2.09422 10.6787 2.23686 10.964 2.52214L11.839 3.39714C12.1242 3.68242 12.2669 3.82506 12.305 3.99481C12.3245 4.0814 12.3245 4.17122 12.305 4.25781C12.2669 4.42756 12.1242 4.5702 11.839 4.85548ZM4.43247 11.2682C3.72809 11.3563 3.3759 11.4003 3.16841 11.1928C2.96092 10.9853 3.00491 10.6331 3.09291 9.92875L3.18375 9.20158C3.20903 8.99917 3.22168 8.89797 3.26564 8.80806C3.30961 8.71816 3.38173 8.64604 3.52596 8.5018L7.3446 4.68317C7.7446 4.28317 7.9446 4.08317 8.19312 4.08317C8.44165 4.08317 8.64165 4.28317 9.04165 4.68317L9.67793 5.31945C10.0779 5.71945 10.2779 5.91945 10.2779 6.16798C10.2779 6.4165 10.0779 6.6165 9.67793 7.0165L5.85927 10.8352C5.71505 10.9794 5.64294 11.0515 5.55304 11.0955C5.46314 11.1394 5.36195 11.1521 5.15956 11.1774L4.43247 11.2682Z"
            />
        </svg>
    )
}

export default SvgEditIcon
