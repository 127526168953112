import { createAction } from 'redux-actions'

// fetch organization group
export const fetchOrganizationGroupSuccess = createAction(
    'FETCH_ORGANIZATION_GROUP_SUCCESS'
)

// fetch organization groups
export const fetchOrganizationGroupsRequest = createAction(
    'FETCH_ORGANIZATION_GROUPS_REQUEST'
)
export const fetchOrganizationGroupsSuccess = createAction(
    'FETCH_ORGANIZATION_GROUPS_SUCCESS'
)
