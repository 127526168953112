import { ReactElement } from 'react'

import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons'
import { Statistic } from 'antd'
import { type Formatter } from 'antd/es/statistic/utils'

import { DELTA_COLORS } from 'const/colors'

interface Props {
    value: number
    formatter: Formatter
    fontSize?: number
    inverse?: boolean
    className?: string
    affix?: 'prefix' | 'suffix'
}

const ChangeDelta = ({
    value,
    formatter,
    fontSize = 12.6,
    inverse = false,
    className,
    affix = 'prefix',
}: Props): ReactElement => {
    const colors = inverse
        ? [DELTA_COLORS.RED, DELTA_COLORS.GREEN]
        : [DELTA_COLORS.GREEN, DELTA_COLORS.RED]

    let affixContent
    let color
    if (value > 0) {
        affixContent = <ArrowUpOutlined />
        color = colors[0]
    } else if (value < 0) {
        affixContent = <ArrowDownOutlined />
        color = colors[1]
    }

    return (
        <Statistic
            className={className}
            value={Math.abs(value)}
            valueStyle={{ fontSize, color }}
            formatter={formatter}
            {...(affix === 'prefix' && { prefix: affixContent })}
            {...(affix === 'suffix' && { suffix: affixContent })}
        />
    )
}

export default ChangeDelta
