import { ReactElement } from 'react'

import Icon from '@ant-design/icons'

import CreateIconSvg from './CreateIconSvg'
import { IconProps } from '../localTypes'

const CreateIcon = (props: IconProps): ReactElement => (
    <Icon component={CreateIconSvg} {...props} />
)

export default CreateIcon
