import { axiosInstanceOrganizations } from 'services/cerebroApi/orgScope/client'

export const getAsyncUpdates = (params) =>
    axiosInstanceOrganizations.get(`/api/async_updates/`, { params })

export const getWalmartAsyncUpdatesViaSnapshot = (params) =>
    axiosInstanceOrganizations.get(`/api/walmart_async_upload_snapshot/`, {
        params,
    })

export const updateAsyncUpdate = (id, data) =>
    axiosInstanceOrganizations.patch(`/api/async_updates/${id}/`, data)

export const getAsyncUpdateViaCsv = (params) =>
    axiosInstanceOrganizations.get(`/api/async_update_via_csv/`, { params })

export const triggerAsyncUpdateViaCsv = (name, type, data) =>
    axiosInstanceOrganizations.patch(
        `/api/async_update_via_csv/?name=${name}&type=${type}`,
        data
    )

export const triggerWalmartAsyncUpdateViaSnapshot = (name, data) =>
    axiosInstanceOrganizations.patch(
        `/api/walmart_async_upload_snapshot/?name=${name}`,
        data
    )
