import { ReactNode } from 'react'

import { CellRenderProps, OrganizationInvitation } from 'types'

import { OrgInviteStatusCell } from '../OrgInviteStatusCell'

export function organizationInvitationEmailRenderer<
    RecordType extends OrganizationInvitation,
>({ record, value }: CellRenderProps<RecordType>): ReactNode {
    return value || record.user?.email
}

export function organizationInvitationStatusRenderer<
    RecordType extends OrganizationInvitation,
>({ record, reloadData }: CellRenderProps<RecordType>): ReactNode {
    return <OrgInviteStatusCell invite={record} reloadData={reloadData} />
}
