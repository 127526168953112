import { handleActions } from 'redux-actions'

import { profileAutomationsTableColumnsConfig } from 'configuration/tables'
import { CAMPAIGN_NAME, REGIONS, COUNTRIES, BRANDS } from 'const/filters'
import { FILTER_SETTINGS, LOCAL_FILTERS } from 'const/reducerKeys'
import { serializeColumns } from 'helpers/tables'
import { defaultTab, getDefaultTable } from 'reducers/ui/defaults'

const initialState = {
    ...defaultTab,
    [LOCAL_FILTERS]: {
        [CAMPAIGN_NAME]: null,
        [REGIONS]: [],
        [COUNTRIES]: [],
        [BRANDS]: [],
    },
    [FILTER_SETTINGS]: {
        anchored: [CAMPAIGN_NAME],
        order: [REGIONS, COUNTRIES, BRANDS],
        displayState: {
            [REGIONS]: true,
            [COUNTRIES]: true,
            [BRANDS]: true,
        },
    },
    table: getDefaultTable(
        serializeColumns(profileAutomationsTableColumnsConfig),
        {
            sorter: {
                field: ['start_date'],
                order: 'descend',
            },
        }
    ),
}

export default handleActions({}, initialState)
