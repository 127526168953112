import { ReactElement } from 'react'

import Icon from '@ant-design/icons'

import PlayButtonIconSvg from './PlayButtonIconSvg'
import { IconProps } from '../localTypes'

const PlayButtonIcon = (props: IconProps): ReactElement => (
    <Icon component={PlayButtonIconSvg} {...props} />
)

export default PlayButtonIcon
