import createCachedSelector from 're-reselect'

import { deserializeColumns, isMetricField } from 'helpers/tables'
import { RootReduxState, FieldSerialized, Field, Path, Table } from 'types'

import {
    selectDomainValue,
    createPathCacheKey as createTablePathCacheKey,
} from './ui'

const selectSerializedColumns = (
    state: RootReduxState,
    tablePath: Path
): FieldSerialized[] =>
    selectDomainValue(state, [...tablePath, 'tableSettings', 'columns'])

export const selectTableSettings = createCachedSelector<
    RootReduxState,
    Path,
    Table<any>,
    Table<any>['tableSettings']
>(
    (state, tablePath) => selectDomainValue(state, tablePath),
    (table) => table.tableSettings
)((_state, tablePath) => createTablePathCacheKey(tablePath))

export const selectTableColumnState = createCachedSelector<
    RootReduxState,
    Path,
    FieldSerialized[],
    Field<any>[],
    Field<any>[]
>(
    (state, tablePath) => selectSerializedColumns(state, tablePath),
    (_state, _tablePath, columnsConfiguration: Field<any>[]) =>
        columnsConfiguration,
    (serializedColumns, columnsConfiguration) => {
        return deserializeColumns(serializedColumns, columnsConfiguration)
    }
)((_state, tablePath) => createTablePathCacheKey(tablePath))

export const selectVisibleMetricsOfTable = createCachedSelector<
    RootReduxState,
    Path,
    FieldSerialized[],
    Field<any>[],
    Field<any>[]
>(
    (state, tablePath) => selectSerializedColumns(state, tablePath),
    (_state, _tablePath, columnsConfiguration: Field<any>[]) =>
        columnsConfiguration,
    (serializedColumns, columnsConfiguration) =>
        deserializeColumns(serializedColumns, columnsConfiguration).filter(
            (col) => isMetricField(col) && col.isVisible
        )
)((_state, tablePath) => createTablePathCacheKey(tablePath))

export const selectVisibleColumnsOfTable = createCachedSelector<
    RootReduxState,
    Path,
    FieldSerialized[],
    Field<any>[],
    Field<any>[]
>(
    (state, tablePath) => selectSerializedColumns(state, tablePath),
    (_state, _tablePath, columnsConfiguration: Field<any>[]) =>
        columnsConfiguration,
    (serializedColumns, columnsConfiguration) =>
        deserializeColumns(serializedColumns, columnsConfiguration).filter(
            (col) => col.isVisible
        )
)((_state, tablePath) => createTablePathCacheKey(tablePath))
