import { Tokens } from 'types/resources/auth'

export class SmsMfaChallengeError extends Error {
    email: string

    password: string

    tokens: Tokens

    constructor(
        message: string,
        email: string,
        password: string,
        tokens: Tokens
    ) {
        super(message)
        this.name = 'SmsMfaChallengeError'
        this.email = email
        this.password = password
        this.tokens = tokens
    }
}
