import { useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Action, ActionFunction1 } from 'redux-actions'

import useDeepCompareCallback from 'hooks/useDeepCompareCallback'

type ActionCreator<Payload> = ActionFunction1<Payload, Action<Payload>>

export function useAction<Payload>(
    action: ActionCreator<Payload>
): (payload?: Payload) => void {
    const dispatch = useDispatch()

    return useDeepCompareCallback(bindActionCreators(action, dispatch), [
        action,
        dispatch,
    ])
}
