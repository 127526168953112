import { useEffect, DependencyList } from 'react'

import { checkDeps, useDeepCompareMemoize } from 'helpers/hooks'

/**
 * This is a drop-in replacement for React.useEffect when dependencies
 * contain non primitive values and a deep comparison is required.
 *
 * @see https://github.com/kentcdodds/use-deep-compare-effect
 *
 * @param callback
 * @param dependencies
 */
function useDeepCompareEffect(
    callback: () => void,
    dependencies: DependencyList
): void {
    if (process.env.NODE_ENV !== 'production') {
        checkDeps(dependencies)
    }
    useEffect(callback, useDeepCompareMemoize(dependencies))
}

export default useDeepCompareEffect
