import {
    createContext,
    ReactElement,
    ReactNode,
    useContext,
    useMemo,
} from 'react'

interface AppConfig {
    isChinaBuild: boolean
    productTitle: string
}

function getProductTitle(): string {
    return 'Cobalt'
}

const AppConfigContextInstance = createContext<AppConfig>({
    isChinaBuild: !!IS_CHINA_BUILD,
    productTitle: getProductTitle(),
})

export const useAppConfig = (): AppConfig =>
    useContext(AppConfigContextInstance)

export function AppConfigProvider(props: {
    children: ReactNode
}): ReactElement {
    const { children } = props
    const productTitle = getProductTitle()

    const appConfig = useMemo(
        () => ({
            isChinaBuild: !!IS_CHINA_BUILD,
            productTitle,
        }),
        [productTitle]
    )

    return (
        <AppConfigContextInstance.Provider value={appConfig}>
            {children}
        </AppConfigContextInstance.Provider>
    )
}

// Ideally this wouldn't be exported and all productTitles should
// be read from the useTheme hook, but we have some functions throughout the
// app currently where we need to refactor before that's possible
export function getProductTitleOutsideThemeContext(): string {
    return getProductTitle()
}
