import { ReactElement } from 'react'

import { InputNumber, type InputNumberProps } from 'antd'
import classNames from 'classnames'
import { getIn, FieldProps } from 'formik'

import { HandlerProps } from '../localTypes'
import withCustomHandlers from '../withCustomHandlers'

type Props<V, FormValues> = HandlerProps &
    InputNumberProps &
    FieldProps<V, FormValues>

function FormikInputNumber<V extends number, FormValues>({
    field: { value, name },
    form,
    handleChange,
    handleBlur,
    handleClick,
    ...props
}: Props<V, FormValues>): ReactElement {
    return (
        <InputNumber
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            className={classNames({
                'fg-control-error':
                    getIn(form.errors, name) && getIn(form.touched, name),
            })}
            {...props}
        />
    )
}

export default withCustomHandlers(FormikInputNumber)
