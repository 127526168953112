import { COUNTRY_CODES } from './codes'

export const CAMPAIGN_BIDDING_ADJUSTMENT_PRECISION = 0
export const CAMPAIGN_BIDDING_ADJUSTMENT_MIN = 0
export const CAMPAIGN_BIDDING_ADJUSTMENT_MAX = 900

export const SB_CAMPAIGN_BIDDING_ADJUSTMENT_MIN = -99

// Default budget values
export const CAMPAIGN_BUDGET_MIN = 1
export const CAMPAIGN_BUDGET_MAX = 1000000
export const CAMPAIGN_BUDGET_STEP = 0.1
export const CAMPAIGN_BUDGET_PRECISION = 2

// Budget min/max is dependent on country and currency
// See helper: src/helpers/amazonCampaign -> getSPCampaignBudgetLimits
// Anything not in this list, defaults to min: 1, max: 1000000
export const AMAZON_CAMPAIGN_SP_BUDGET_LIMITS = {
    [COUNTRY_CODES.AE]: {
        min: 4,
        max: 3700000,
    },
    [COUNTRY_CODES.IN]: {
        min: 500,
        max: 21000000,
    },
    [COUNTRY_CODES.JP]: {
        min: 100,
        max: 21000000,
    },
    [COUNTRY_CODES.MX]: {
        min: 1,
        max: 21000000,
    },
    [COUNTRY_CODES.AU]: {
        min: 1.4,
        max: 1500000,
    },
}

export const COST_TYPE_LABELS = {
    cpc: 'CPC',
    vcpm: 'vCPM',
}
