import { Field } from 'types'

import { createField } from './createField'
import { FieldCreatorOptions } from './localTypes'

export function productTitle<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['product_title']
    return createField({
        ...options,
        id: 'product_title',
        name: 'Product Title',
        shortName: 'Product Title',
        minWidth: 80,
        dataIndex,
        width: 100,
    })
}
