import isEmpty from 'lodash/isEmpty'

import {
    AUTOMATIONS,
    AUTOMATION_PROFILES,
    CAMPAIGN_NAME,
    REGIONS,
    COUNTRIES,
    BRANDS,
    AUTOMATION_PROFILE_NAME,
} from 'const/filters'

/**
 * Format filters for automations tab
 *
 * @param {object} filters - Filters
 *
 * @returns {object}
 */
export const formatAutomationsFilters = (filters) => {
    const params = {}

    if (!isEmpty(filters[AUTOMATIONS])) {
        params.capability__id__in = filters[AUTOMATIONS].map(
            (item) => item.value
        ).join()
    }

    if (!isEmpty(filters[AUTOMATION_PROFILES])) {
        params.automation_profile__name__in = filters[AUTOMATION_PROFILES].map(
            (item) => item.label
        ).join()
    }

    if (!isEmpty(filters[CAMPAIGN_NAME])) {
        params.campaign__name__icontains = filters[CAMPAIGN_NAME]
    }

    if (!isEmpty(filters[AUTOMATION_PROFILE_NAME])) {
        params.automation_profile__name__icontains =
            filters[AUTOMATION_PROFILE_NAME]
    }

    if (!isEmpty(filters[REGIONS])) {
        params.campaign__profile__region__in = filters[REGIONS].map(
            (item) => item.value
        ).join()
    }

    if (!isEmpty(filters[COUNTRIES])) {
        params.campaign__profile__country_code__in = filters[COUNTRIES].map(
            (item) => item.value
        ).join()
    }

    if (!isEmpty(filters[BRANDS])) {
        params.campaign__profile__in = filters[BRANDS].map(
            (item) => item.value
        ).join()
    }

    return params
}

export const formatAutomationProfilesFilters = (filters) => {
    const params = {}

    if (!isEmpty(filters[AUTOMATIONS])) {
        params.capability__id__in = filters[AUTOMATIONS].map(
            (item) => item.value
        ).join()
    }

    if (!isEmpty(filters[AUTOMATION_PROFILE_NAME])) {
        params.name__icontains = filters[AUTOMATION_PROFILE_NAME]
    }

    return params
}
