import { handleActions } from 'redux-actions'

import { DATES } from 'const/filters'
import { FILTERS, FILTER_SETTINGS } from 'const/reducerKeys'

import { defaultPage } from '../defaults'

const defaultState = {
    ...defaultPage,
    [FILTERS]: {
        [DATES]: 'last_30_days_to_date',
    },
    [FILTER_SETTINGS]: {
        anchored: [DATES],
        order: [],
        displayState: {},
    },
}

export default handleActions({}, defaultState)
