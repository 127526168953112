import { memo, ReactElement } from 'react'

import isEqual from 'lodash/isEqual'

import { updateCampaign } from 'services/cerebroApi/orgScope/resourceApi'
import { CampaignGroupBy } from 'types'

import { ToggleField } from '../../SharedFields/ToggleField'

interface Props<RecordType> {
    record: RecordType
    readOnly?: boolean
}

function CampaignAutomatedBiddingField<RecordType extends CampaignGroupBy>({
    readOnly = false,
    record,
}: Props<RecordType>): ReactElement {
    return (
        <ToggleField
            disabled={readOnly || !record.campaign.user_can_manage}
            fieldName="bid_optimization"
            fieldPath={['campaign', 'bid_optimization']}
            record={record}
            updateRequestApi={updateCampaign}
            updateRequestData={record.campaign.id}
            updateRequestSuccessMesg="Campaign updated successfully."
        />
    )
}

export default memo(
    CampaignAutomatedBiddingField,
    isEqual
) as typeof CampaignAutomatedBiddingField
