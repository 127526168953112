import { memo, ReactElement } from 'react'

import isEqual from 'lodash/isEqual'

import { updateAutomationTask } from 'services/cerebroApi/orgScope/resourceApi'
import { AutomationTask } from 'types'

import { ToggleField } from '../SharedFields/ToggleField'

interface Props<RecordType> {
    dataIndex?: string[]
    record: RecordType
    readOnly?: boolean
}

function AutomationStateField<RecordType extends AutomationTask>({
    dataIndex = ['enabled'],
    readOnly = false,
    record,
}: Props<RecordType>): ReactElement {
    const resourceId = record?.id
    const fieldName = 'enabled'

    return (
        <ToggleField
            disabled={readOnly}
            fieldName={fieldName}
            fieldPath={dataIndex}
            record={record}
            updateRequestApi={updateAutomationTask}
            updateRequestData={resourceId}
            serializeFieldValues={(values) => values}
            updateRequestSuccessMesg="Automation updated successfully."
        />
    )
}

export default memo(
    AutomationStateField,
    isEqual
) as typeof AutomationStateField
