import { ReactNode } from 'react'

import {
    AdGroupBidField,
    AdGroupStateField,
    ArchiveAdGroupField,
} from 'components/EditableFields/AdGroupFields'
import { ResourceLink } from 'components/Links/ResourceLink'
import { UNDEFINED_VALUE } from 'const/formatting'
import { adGroupUrl } from 'helpers/urls'
import { isUnset } from 'helpers/utilities'
import {
    AdGroupGroupBy,
    CampaignGroupBy,
    CellRenderProps,
    ProfileGroupBy,
} from 'types'

import { ActiveBidCell } from '../ActiveBidCell'

type RenderProps<RecordType> = {
    cellRenderProps: CellRenderProps<RecordType>
    dataIndex: string[]
}

export function adGroupIdRenderer<RecordType extends AdGroupGroupBy>({
    record,
    value,
    undefinedValue = UNDEFINED_VALUE,
}: CellRenderProps<RecordType>): ReactNode {
    if (isUnset(value)) {
        return undefinedValue
    }

    const id = record.ad_group_id
    const campaignId = record.ad_group.campaign.id
    const link = adGroupUrl(campaignId, id)

    return <ResourceLink to={link}>{record.ad_group.name}</ResourceLink>
}

export function adGroupNameRenderer<
    RecordType extends AdGroupGroupBy & CampaignGroupBy,
>({
    record,
    value,
    undefinedValue = UNDEFINED_VALUE,
}: CellRenderProps<RecordType>): ReactNode {
    if (isUnset(value)) {
        return undefinedValue
    }

    const id = record.ad_group_id
    const campaignId = record.campaign?.id ?? record.ad_group.campaign.id
    const link = adGroupUrl(campaignId, id)

    return <ResourceLink to={link}>{value}</ResourceLink>
}

export function adGroupStateRenderer<
    RecordType extends AdGroupGroupBy & CampaignGroupBy,
>({
    cellRenderProps: { record, readonly },
    dataIndex,
}: RenderProps<RecordType>): ReactNode {
    return (
        <AdGroupStateField
            dataIndex={dataIndex}
            readOnly={readonly}
            record={record}
        />
    )
}

export function adGroupBidRenderer<
    RecordType extends AdGroupGroupBy & CampaignGroupBy,
>({
    cellRenderProps: { record, readonly },
    dataIndex,
}: RenderProps<RecordType>): ReactNode {
    return (
        <AdGroupBidField
            dataIndex={dataIndex}
            readOnly={readonly}
            record={record}
        />
    )
}

export function adGroupActiveBidRenderer<
    RecordType extends AdGroupGroupBy & CampaignGroupBy & ProfileGroupBy,
>({
    record,
    value,
    undefinedValue = UNDEFINED_VALUE,
}: CellRenderProps<RecordType>): ReactNode {
    if (isUnset(value)) {
        return undefinedValue
    }

    const { currency_code, timezone } = record.profile
    const { campaign } = record
    const { active_default_bid, base_default_bid } = record.ad_group

    return (
        <ActiveBidCell
            activeBid={active_default_bid}
            baseBid={base_default_bid}
            campaign={campaign}
            currencyCode={currency_code}
            timezone={timezone}
        />
    )
}

export function archiveAdGroupRenderer<
    RecordType extends AdGroupGroupBy & CampaignGroupBy & ProfileGroupBy,
>({
    cellRenderProps: { readonly, record, reloadData },
    dataIndex,
}: RenderProps<RecordType>): ReactNode {
    return (
        <ArchiveAdGroupField
            dataIndex={dataIndex}
            record={record}
            readOnly={readonly}
            reloadData={reloadData}
        />
    )
}
