import { ReactElement } from 'react'

import { Formik, Field, ErrorMessage, Form } from 'formik'
import { useTranslation, Trans } from 'react-i18next'
import { Link } from 'react-router-dom'
import { object, string } from 'yup'

import { sendResetEmailRequest } from 'actions/auth'
import { FormikInput } from 'components/formik'
import { AUTH_PAGE } from 'const/pages'
import { getPath } from 'helpers/pages'
import { useAction } from 'hooks'

import { AuthError, SubmitButton } from '../Shared'
import styles from '../styles.scss'

const ForgotPasswordForm = (): ReactElement => {
    const sendCode = useAction(sendResetEmailRequest)
    const { t } = useTranslation('account')

    const validationSchema = object().shape({
        email: string()
            .email()
            .trim()
            .lowercase()
            .required()
            .label(t('account:email', 'Email')),
    })

    return (
        <>
            <div className={styles.title}>
                <h1>
                    {t(
                        'account:ForgotPasswordForm.title',
                        'Reset your password'
                    )}
                </h1>
                <div className={styles.subtitle}>
                    <Trans i18nKey="account:ForgotPasswordForm.subTitle">
                        Enter the email address that you used to register
                        <br />
                        and we&apos;ll send you a 6-digit code to reset your
                        password.
                    </Trans>
                </div>
            </div>

            <Formik
                initialValues={{
                    email: '',
                }}
                onSubmit={(values) => sendCode(validationSchema.cast(values))}
                validationSchema={validationSchema}
            >
                {() => (
                    <Form>
                        <div>
                            <Field
                                component={FormikInput}
                                placeholder={t('account:email', 'Email')}
                                name="email"
                                size="large"
                            />
                            <ErrorMessage
                                name="email"
                                component="div"
                                className="fg-control-text is-error"
                            />
                        </div>
                        <SubmitButton>
                            {t(
                                'account:ForgotPasswordForm.submitBtnText',
                                'Send Code'
                            )}
                        </SubmitButton>
                    </Form>
                )}
            </Formik>

            <div className="mt-3">
                <Link to={getPath(AUTH_PAGE)}>
                    <span>
                        {t(
                            'account:ForgotPasswordForm.logInLink',
                            'Back to Log In'
                        )}
                    </span>
                </Link>
            </div>

            <AuthError />
        </>
    )
}

export default ForgotPasswordForm
