import { call, select, put } from 'redux-saga/effects'

import {
    makeFetchTableRequest,
    makeFetchTableSuccess,
    makeDownloadTableSuccess,
} from 'actions/ui/shared'
import { placementsTableColumnsConfig } from 'configuration/tables'
import { DATES } from 'const/filters'
import { CAMPAIGN_PAGE } from 'const/pages'
import { formatFilters } from 'helpers/filters/campaignPlacementFacts'
import { generateReportNotification } from 'helpers/notifications'
import {
    formatSorter,
    formatMetrics,
    formatPagination,
    formatColumns,
    formatCurrency,
    formatPeriodDeltaDateRange,
} from 'helpers/params'
import { serializePlacements } from 'helpers/placements'
import { cerebroApiSaga } from 'sagas/common'
import uiSagaRegistry from 'sagas/ui/registry'
import {
    selectTableSettings,
    selectVisibleCombinedFilters,
    selectVisibleMetricsOfTable,
    selectVisibleColumnsOfTable,
    selectCurrencyCode,
    selectResourceOfPage,
} from 'selectors/ui'
import {
    getCampaignPlacementFacts,

    // Export
    getCampaignPlacementFactsExport,
} from 'services/cerebroApi/orgScope/campaignPlacementFactsApi'

const TAB_PATH = [CAMPAIGN_PAGE, 'placements']
const TABLE_PATH = [...TAB_PATH, 'table']

function* fetchTableSaga(noCount) {
    const campaign = yield select(selectResourceOfPage, CAMPAIGN_PAGE)
    const filters = yield select(selectVisibleCombinedFilters, [CAMPAIGN_PAGE])
    const {
        pagination,
        sorter,
        showPeriodDeltas,
        periodDeltaType,
        periodDeltaDateRange,
    } = yield select(selectTableSettings, TABLE_PATH)
    const metrics = yield select(
        selectVisibleMetricsOfTable,
        TABLE_PATH,
        placementsTableColumnsConfig
    )
    const currency = yield select(selectCurrencyCode)
    const params = {
        ...formatPagination(pagination),
        ...formatSorter(sorter),
        ...formatFilters(filters),
        ...formatMetrics(metrics, showPeriodDeltas),
        ...formatCurrency(currency),
        ...formatPeriodDeltaDateRange(
            showPeriodDeltas,
            periodDeltaType,
            periodDeltaDateRange,
            filters[DATES]
        ),
        campaign: campaign.id,
        group_by: 'placement,campaign_id',
    }
    const response = yield call(
        cerebroApiSaga,
        null,
        getCampaignPlacementFacts,
        params,
        {
            headers: { noCount },
        }
    )

    if (response) {
        const results = serializePlacements(response.data.results, campaign)

        const data = {
            ...response.data,
            results,
        }

        yield put(makeFetchTableSuccess(TABLE_PATH)(data))
    }
}

function* downloadTableSaga(path) {
    const campaign = yield select(selectResourceOfPage, CAMPAIGN_PAGE)
    const filters = yield select(selectVisibleCombinedFilters, [CAMPAIGN_PAGE])
    const { sorter, showPeriodDeltas, periodDeltaType, periodDeltaDateRange } =
        yield select(selectTableSettings, TABLE_PATH)
    const columns = yield select(
        selectVisibleColumnsOfTable,
        path,
        placementsTableColumnsConfig
    )

    const currency = yield select(selectCurrencyCode)
    const reportName = `Placement Report - ${campaign.name} `
    const params = {
        ...formatFilters(filters),
        ...formatSorter(sorter),
        ...formatColumns(columns, showPeriodDeltas),
        ...formatCurrency(currency),
        ...formatPeriodDeltaDateRange(
            showPeriodDeltas,
            periodDeltaType,
            periodDeltaDateRange,
            filters[DATES]
        ),
        campaign: campaign.id,
        group_by: 'placement',
        async_download_name: reportName,
    }

    yield call(
        cerebroApiSaga,
        makeDownloadTableSuccess(path),
        getCampaignPlacementFactsExport,
        params
    )

    generateReportNotification(reportName)
}

function* fetchTabDataSaga() {
    yield put(makeFetchTableRequest(TABLE_PATH)())
}

// Register Sagas
uiSagaRegistry.registerSagas(TAB_PATH, {
    fetchTabDataSaga,
})

uiSagaRegistry.registerSagas(TABLE_PATH, {
    fetchTableSaga,
    downloadTableSaga,
})
