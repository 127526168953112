import { ReactElement, useState } from 'react'

import { Skeleton } from 'antd'
import isUndefined from 'lodash/isUndefined'
import { generatePath } from 'react-router-dom'

import { ImageNotFoundIcon } from 'components/Icons'
import { ResearchKeywordLink } from 'components/Links/ResearchKeywordLink/ResearchKeywordLink'
import { ResourceLink } from 'components/Links/ResourceLink'
import { UNDEFINED_VALUE } from 'const/formatting'
import { CAMPAIGN_PRODUCT_AD_PAGE } from 'const/pages'
import { getPath } from 'helpers/pages'
import { productUrl } from 'helpers/urls'
import { CellRenderProps, CountryCode, ProductAdGroupBy } from 'types'

import styles from './styles.scss'

type DefaultGroupBy = ProductAdGroupBy

interface Props<RecordType> extends Pick<CellRenderProps<RecordType>, 'value'> {
    productAsin: string
    productAdId: string
    productImg?: string | null
    campaignId?: string
    profileCountryCode: CountryCode
}

function ProductTitleCell<RecordType extends DefaultGroupBy = DefaultGroupBy>({
    value,
    productAsin,
    productAdId,
    productImg,
    campaignId,
    profileCountryCode,
}: Props<RecordType>): ReactElement {
    const isLoading = value === 'Loading...'
    const [isValidImg, setIsValidImg] = useState(true)

    const renderTitle = (text: string): ReactElement => {
        let link = null

        if (campaignId) {
            link = generatePath(getPath(CAMPAIGN_PRODUCT_AD_PAGE), {
                campaignId,
                productAdId,
            })
        } else if (productAsin && profileCountryCode) {
            link = productUrl(productAsin, profileCountryCode)
        }

        return (
            <div className={styles.field}>
                {link ? (
                    <ResourceLink className={styles['product-link']} to={link}>
                        {text}
                    </ResourceLink>
                ) : (
                    <span className={styles['product-link']}>{text}</span>
                )}
                {profileCountryCode && (
                    <ResearchKeywordLink
                        countryCode={profileCountryCode}
                        asin={productAsin}
                    />
                )}
            </div>
        )
    }

    // if metadataTitle is set we know we have a product_ad and
    // product_metadata so we can safely render a link with title
    // and image
    if (value && productImg && isValidImg) {
        return (
            <div className={styles.container}>
                {isLoading && (
                    <Skeleton
                        active
                        avatar={{
                            size: 'large',
                            shape: 'square',
                        }}
                        className="mr-2"
                        title={{
                            width: 140,
                        }}
                        paragraph={false}
                    />
                )}
                {!isLoading && (
                    <>
                        <div className={styles.image}>
                            <img
                                src={productImg}
                                alt={value}
                                onError={() => setIsValidImg(false)}
                            />
                        </div>
                        {renderTitle(value)}
                    </>
                )}
            </div>
        )
    }
    if (value) {
        return (
            <div className={styles.container}>
                <ImageNotFoundIcon className={styles['missing-img-icon']} />
                {renderTitle(value)}
            </div>
        )
    }

    // if product_ad is set and not empty we can safely render a link
    // with the missing img icon and asin
    if (!isUndefined(productAsin)) {
        return (
            <div className={styles.container}>
                <ImageNotFoundIcon className={styles['missing-img-icon']} />
                {renderTitle(productAsin)}
            </div>
        )
    }

    // unable to render a link to product ad page
    return <span>{UNDEFINED_VALUE}</span>
}

export default ProductTitleCell
