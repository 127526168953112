import isEmpty from 'lodash/isEmpty'
import { call, select, put } from 'redux-saga/effects'

import {
    makeDownloadTableFailure,
    makeDownloadTableSuccess,
} from 'actions/ui/shared'
import { COUNTRIES, LABELS, PRODUCT_ASINS, PRODUCT_SKUS } from 'const/filters'
import { PRODUCTS_SUMMARY_PAGE } from 'const/pages'
import { PRODUCT_LABELS } from 'const/resourceTypes'
import { formatBooleanFilter } from 'helpers/filters'
import { generateReportNotification } from 'helpers/notifications'
import { cerebroApiSaga } from 'sagas/common'
import uiSagaRegistry from 'sagas/ui/registry'
import { selectDomainValue as selectAuthDomainValue } from 'selectors/auth'
import { selectVisibleCombinedFilters } from 'selectors/ui'
import {
    getProductCogsExportAsyncExport,
    ProductSkuCogsTemplateFilter,
} from 'services/cerebroApi/orgScope/productCogsApi'
import { CountryCode, FilterOption, FiltersState, Report } from 'types'

const BULK_EDIT_PATH = [PRODUCTS_SUMMARY_PAGE, 'skuCogsBulkEdit']

const formatFilters = (filters: FiltersState): ProductSkuCogsTemplateFilter => {
    const params: ProductSkuCogsTemplateFilter = {}

    if (!isEmpty(filters[COUNTRIES])) {
        params.marketplace__in = (filters[COUNTRIES] ?? [])
            .map((item: FilterOption) => item.value as CountryCode)
            .join()
    }

    if (!isEmpty(filters[PRODUCT_ASINS])) {
        params.asin__in = (filters[PRODUCT_ASINS] ?? [])
            .map((item) => item.value)
            .join()
    }

    if (!isEmpty(filters[LABELS])) {
        params.complex_label_filter = formatBooleanFilter(
            filters[LABELS],
            PRODUCT_LABELS
        )
    }

    if (!isEmpty(filters[PRODUCT_SKUS])) {
        params.sku__in = (filters[PRODUCT_SKUS] ?? [])
            .map((item) => item.value)
            .join()
    }

    return params
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function* downloadTableSaga() {
    const organizationId: string = yield select(
        selectAuthDomainValue,
        'organizationId'
    )

    const filters: FiltersState = yield select(
        selectVisibleCombinedFilters,
        BULK_EDIT_PATH
    )

    const params = {
        ...formatFilters(filters),
        columns:
            'title,asin,marketplace,sku,start_date_char,cost_of_goods_per_unit,supplier_shipping_cost_per_unit,misc_cost_per_unit',
        async_download_name: `org-${organizationId}-product-cogs-bulk-template`,
    }

    try {
        yield call(
            cerebroApiSaga,
            (data: Report) => {
                generateReportNotification(data.name)
                return makeDownloadTableSuccess(BULK_EDIT_PATH)()
            },
            getProductCogsExportAsyncExport,
            params
        )
    } catch {
        yield put(makeDownloadTableFailure(BULK_EDIT_PATH)())
    }
}

// Register Sagas
uiSagaRegistry.registerSagas(BULK_EDIT_PATH, {
    downloadTableSaga,
})
