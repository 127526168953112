import { createAction } from 'redux-actions'

const NAMESPACE = 'ALERT_PAGE'

export const fetchTriggeredAlertsSuccess = createAction(
    `${NAMESPACE}/FETCH_TRIGGERED_ALERTS_SUCCESS`
)
export const fetchTriggeredAlertsFailure = createAction(
    `${NAMESPACE}/FETCH_TRIGGERED_ALERTS_FAILURE`
)

export const fetchAlertSubscriptionsSuccess = createAction(
    `${NAMESPACE}/FETCH_ALERT_SUBSCRIPTIONS_SUCCESS`
)
export const fetchAlertSubscriptionsFailure = createAction(
    `${NAMESPACE}/FETCH_ALERT_SUBSCRIPTIONS_FAILURE`
)
