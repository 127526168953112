import { ReactElement } from 'react'

import Icon from '@ant-design/icons'

import EditIconSvg from './EditIconSvg'
import { IconProps } from '../localTypes'

const EditIcon = (props: IconProps): ReactElement => (
    <Icon component={EditIconSvg} {...props} />
)

export default EditIcon
