import { Children, memo, ReactElement, ReactNode } from 'react'

import classNames from 'classnames'
import isEqual from 'lodash/isEqual'
import { CellType } from 'rc-table/lib/interface'

import { TotalRowsCountCell } from 'components/FieldRenderers/TotalRowsCountCell/TotalRowsCountCell'

import styles from './styles.scss'
import { OnCellProps } from '../../localTypes'

interface PaginatedRecord {
    results_count: number
}

type Props<RecordType> = CellType<RecordType> &
    OnCellProps<RecordType> & {
        isTotalCell: boolean
    }

/**
 * When Ant needs to render a component in a table cell (e.g., the expand/close
 * icon for tree data), the component to be rendered will be included as the
 * first element of two in the children prop of the BodyCell.
 *
 * This function returns the component that Ant expects to be rendered in the
 * table cell. If no extra component is expected to be rendered, it returns
 * null.
 */
function extractChildrenToAppend<RecordType>(
    children: CellType<RecordType>['children']
): ReactNode {
    let firstChild = null
    try {
        const childrenArray = Children.toArray(children)
        if (childrenArray.length === 2) {
            firstChild = childrenArray[0]
        }
    } catch (_e) {
        // do nothing if children cannot be iterated over
        // this occurs when children is an object
    }
    return firstChild
}

function BodyCell<T extends PaginatedRecord>({
    columns,
    value,
    record,
    rowIndex,
    renderOptions,
    metricOptions,
    priorPeriodOptions,
    children,
    reloadData,
    readonly,
    shortFormat,
    updateColumns,
    updateRecord,
    isTotalCell,
    isTotalSupported,
    colIndex,
    ...restProps
}: Props<T>): ReactElement {
    const contentProps = {
        columns,
        value,
        record,
        rowIndex,
        reloadData,
        readonly,
        shortFormat,
        updateColumns,
        updateRecord,
        isTotalCell,
    }

    // Render cells that have options for prior periods
    // These are rarely used, so they can be prioritized
    if (priorPeriodOptions) {
        return (
            <td {...restProps}>
                <div className={styles['cell-contents']}>
                    {priorPeriodOptions.render(contentProps)}
                </div>
            </td>
        )
    }

    if (isTotalCell) {
        // Only render total count if it's not a metric
        // If it's not the first column, render empty cell
        if (!metricOptions) {
            return (
                <td {...restProps}>
                    <div className={classNames(styles['cell-contents'])}>
                        {colIndex === 0 && (
                            <TotalRowsCountCell count={record.results_count} />
                        )}
                    </div>
                </td>
            )
        }

        // Render empty cell if total is not supported for this metric
        if (!isTotalSupported) {
            return (
                <td {...restProps}>
                    <div className={classNames(styles['cell-contents'])} />
                </td>
            )
        }
    }

    if (renderOptions) {
        const antChildrenToAppend = extractChildrenToAppend(children)

        if (antChildrenToAppend) {
            return (
                <td {...restProps}>
                    <div
                        className={classNames(
                            styles['cell-contents'],
                            styles['v-align-cell-contents']
                        )}
                    >
                        <div>{antChildrenToAppend}</div>
                        <div className={styles['v-align-cell-inner-content']}>
                            {renderOptions.render(contentProps)}
                        </div>
                    </div>
                </td>
            )
        }

        return (
            <td {...restProps}>
                <div className={styles['cell-contents']}>
                    {renderOptions.render(contentProps)}
                </div>
            </td>
        )
    }

    if (metricOptions) {
        return (
            <td {...restProps}>
                <div className={styles['cell-contents']}>
                    {metricOptions.render(contentProps)}
                </div>
            </td>
        )
    }

    return (
        <td {...restProps}>
            <div className={styles['cell-contents']}>{children}</div>
        </td>
    )
}

function BodySummaryCell<T extends PaginatedRecord>(
    props: Props<T>
): ReactElement {
    return <BodyCell {...props} isTotalCell />
}

const BodyCellWithMemo = memo(BodyCell, isEqual)
export const BodySummaryCellWithMemo = memo(BodySummaryCell, isEqual)
export default BodyCellWithMemo
