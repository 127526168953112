import { ReactElement } from 'react'

import { Translation } from 'react-i18next'

import { AutomationIcon } from 'components/Icons'
import { AUTOMATIONS } from 'const/automations'
import { AutomationProfile } from 'types'

import { DetailWrapper, DetailIcon, DetailText } from './shared'

export function automationTypeRenderer(
    profile: AutomationProfile
): ReactElement {
    return (
        <DetailWrapper
            leftContent={
                <DetailIcon
                    icon={AutomationIcon}
                    tooltipTitle={
                        <Translation>
                            {(t) =>
                                t(
                                    'automations:automationType.label',
                                    'Automation Type'
                                )
                            }
                        </Translation>
                    }
                />
            }
            rightContent={
                <DetailText text={AUTOMATIONS[profile.capability].name} />
            }
        />
    )
}
