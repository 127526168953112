import React from 'react'

import { Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'

import { ContactUsLink } from 'components/Links'
import CobaltLogo from 'images/logo/CobaltLogo'

import styles from './styles.scss'

const ErrorPage = (): React.ReactElement => {
    const { t } = useTranslation()
    return (
        <Row justify="center" className={styles.background}>
            <Col xl={8} style={{ marginTop: '3%', marginBottom: '3%' }}>
                <div className={styles['error-div']}>
                    <div className={styles.logo}>
                        <CobaltLogo style={{ height: 48 }} />
                    </div>

                    <h2>
                        {t(
                            'common:errors.genericHeader',
                            'Something Went Wrong.'
                        )}
                    </h2>

                    <p>
                        {t(
                            'common:errors.notified',
                            'If youʼre seeing this page, our team has already been notified that something has gone wrong.'
                        )}
                    </p>

                    <p>
                        {t(
                            'common:errors.retry',
                            'First try refreshing the page to resolve the issue. If the problem persists, click the link below to provide a bug report with more details. This will help us resolve the issue faster and will give you visibility into the steps weʼre taking to fix the problem.'
                        )}
                    </p>
                    <ContactUsLink contactUsPage="/group/3/create/14">
                        {t('common:errors.submitBug', 'Submit Bug Report')}
                    </ContactUsLink>
                </div>
            </Col>
        </Row>
    )
}
export default ErrorPage
