import has from 'lodash/has'
import { call, select, put } from 'redux-saga/effects'

import {
    makeFetchTableRequest,
    makeFetchTableSuccess,
    makeDownloadTableSuccess,
} from 'actions/ui/shared'
import { productsSummaryProductAdsTableColumnsConfig } from 'configuration/tables'
import { DATES } from 'const/filters'
import { PRODUCT_PAGE } from 'const/pages'
import { formatFilters } from 'helpers/filters/productAdFacts'
import { generateReportNotification } from 'helpers/notifications'
import {
    formatPagination,
    formatCurrency,
    formatColumns,
    formatSorter,
    formatMetrics,
    formatPeriodDeltaDateRange,
} from 'helpers/params'
import { cerebroApiSaga } from 'sagas/common'
import uiSagaRegistry from 'sagas/ui/registry'
import {
    selectResourceParamsOfPage,
    selectCurrencyCode,
    selectVisibleMetricsOfTable,
    selectVisibleColumnsOfTable,
    selectVisibleCombinedFilters,
    selectTableSettings,
} from 'selectors/ui'
import {
    getProductAdFacts,
    getProductAdFactsExport,
} from 'services/cerebroApi/orgScope/productAdFactsApi'

const TAB_PATH = [PRODUCT_PAGE, 'productAds']
const TABLE_PATH = [...TAB_PATH, 'table']

function* fetchTableSaga(noCount) {
    const { asin, countryCode } = yield select(
        selectResourceParamsOfPage,
        PRODUCT_PAGE
    )
    const currency = yield select(selectCurrencyCode)
    const filters = yield select(selectVisibleCombinedFilters, TAB_PATH)

    const {
        pagination,
        sorter,
        showPeriodDeltas,
        periodDeltaType,
        periodDeltaDateRange,
    } = yield select(selectTableSettings, TABLE_PATH)
    const metrics = yield select(
        selectVisibleMetricsOfTable,
        TABLE_PATH,
        productsSummaryProductAdsTableColumnsConfig
    )

    const params = {
        ...formatPagination(pagination),
        ...formatSorter(sorter),
        ...formatFilters(filters),
        ...formatMetrics(metrics, showPeriodDeltas),
        ...formatCurrency(currency),
        ...formatPeriodDeltaDateRange(
            showPeriodDeltas,
            periodDeltaType,
            periodDeltaDateRange,
            filters[DATES]
        ),
        product_ad__asin: asin,
        profile__country_code: countryCode,
        group_by: 'product_ad_id,campaign_id',
    }
    const response = yield call(
        cerebroApiSaga,
        null,
        getProductAdFacts,
        params,
        {
            headers: { noCount },
        }
    )
    if (has(response, ['data', 'results'])) {
        yield put(makeFetchTableSuccess(TABLE_PATH)(response.data))
    }
}

function* downloadTableSaga() {
    const { asin, countryCode } = yield select(
        selectResourceParamsOfPage,
        PRODUCT_PAGE
    )
    const filters = yield select(selectVisibleCombinedFilters, TAB_PATH)
    const { sorter, showPeriodDeltas, periodDeltaType, periodDeltaDateRange } =
        yield select(selectTableSettings, TABLE_PATH)
    const columns = yield select(
        selectVisibleColumnsOfTable,
        TABLE_PATH,
        productsSummaryProductAdsTableColumnsConfig
    )
    const currency = yield select(selectCurrencyCode)
    const reportName = `Product Ads Report - ${countryCode}_${asin}`
    const params = {
        ...formatFilters(filters),
        ...formatSorter(sorter),
        ...formatColumns(columns, showPeriodDeltas),
        ...formatCurrency(currency),
        ...formatPeriodDeltaDateRange(
            showPeriodDeltas,
            periodDeltaType,
            periodDeltaDateRange,
            filters[DATES]
        ),
        product_ad__asin: asin,
        profile__country_code: countryCode,
        group_by: 'product_ad_id',
        async_download_name: reportName,
    }
    yield call(
        cerebroApiSaga,
        makeDownloadTableSuccess(TABLE_PATH),
        getProductAdFactsExport,
        params
    )

    generateReportNotification(reportName)
}

function* fetchTabDataSaga() {
    yield put(makeFetchTableRequest(TABLE_PATH)())
}

// Register Sagas
uiSagaRegistry.registerSagas(TAB_PATH, {
    fetchTabDataSaga,
})

uiSagaRegistry.registerSagas(TABLE_PATH, {
    fetchTableSaga,
    downloadTableSaga,
})
