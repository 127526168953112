import isEqual from 'lodash/isEqual'
import { useSelector } from 'react-redux'

import {
    selectCurrentOrganization,
    selectCurrentOrganizationGroup,
    selectOrganizations,
} from 'selectors/orgs'
import { Organization, RootReduxState, OrganizationGroup } from 'types'

export function useCurrentOrganization(): Organization | undefined {
    return useSelector<RootReduxState, Organization | undefined>(
        selectCurrentOrganization,
        isEqual
    )
}

export function useCurrentOrganizationGroup(): OrganizationGroup {
    return useSelector<RootReduxState, OrganizationGroup>(
        selectCurrentOrganizationGroup,
        isEqual
    )
}

export function useOrganizations(): Organization[] {
    return useSelector<RootReduxState, Organization[]>(
        selectOrganizations,
        isEqual
    )
}
