import { ReactElement } from 'react'

import { ResourceLink } from 'components/Links/ResourceLink'
import { adGroupUrl } from 'helpers/urls'

import { SelectedSearchTerm } from '../localTypes'
import styles from '../styles.scss'

interface Props {
    selectedSearchTerm: SelectedSearchTerm
}

const AdGroupLabel = ({
    selectedSearchTerm: {
        record: { ad_group, campaign },
    },
}: Props): ReactElement => (
    <div className="d-flex flex-column">
        <div className={styles.label}>Source Ad Group</div>
        <div className={styles.value}>
            <ResourceLink to={adGroupUrl(campaign.id, ad_group.id)}>
                {ad_group.name}
            </ResourceLink>
        </div>
    </div>
)

export default AdGroupLabel
