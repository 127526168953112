import { memo, ReactElement } from 'react'

import isEqual from 'lodash/isEqual'

import { useResource } from 'components/EditableFields/localHooks'
import { ARCHIVED } from 'const/resourceStates'
import { updateKeyword } from 'services/cerebroApi/orgScope/resourceApi'
import { KeywordGroupBy } from 'types'

import { DeleteField } from '../../SharedFields/DeleteField'

interface Props<RecordType> {
    dataIndex: string[]
    readOnly: boolean
    record: RecordType
    reloadData: () => void
}

function ArchiveKeywordField<RecordType extends KeywordGroupBy>({
    dataIndex = ['keyword'],
    readOnly = false,
    record,
    reloadData,
}: Props<RecordType>): ReactElement {
    const { getFromResource, resourceId } = useResource<RecordType>(
        record,
        dataIndex,
        'keyword'
    )

    const getIsDisabled = (): boolean => {
        const userCanManageKeyword = getFromResource(['user_can_manage'], true)
        return !userCanManageKeyword || readOnly
    }

    return (
        <DeleteField
            disabled={getIsDisabled()}
            okText="Archive"
            title="Are you sure you want to archive this Keyword?"
            updateRequestApi={updateKeyword}
            updateRequestCallback={reloadData}
            updateRequestData={resourceId}
            updateRequestParams={{ state: ARCHIVED }}
            updateRequestSuccessMesg="Keyword successfully archived"
        />
    )
}

export default memo(ArchiveKeywordField, isEqual) as typeof ArchiveKeywordField
