import { HEADLINE_SEARCH } from 'const/factTypes'
import { PLACEMENT_TYPES, PLACEMENT_OTHER } from 'const/placements'
import {
    Campaign,
    CampaignType,
    CampaignPlacement,
    SerializedPlacement,
    BidAdjustmentType,
} from 'types'

import { isDefined } from './typeGuard'

export const placementImmutable = (
    type: BidAdjustmentType,
    campaignType: CampaignType
): boolean => {
    const isOther = type === PLACEMENT_OTHER
    const isSponsoredBrand = campaignType === HEADLINE_SEARCH

    return !isDefined(type) || isSponsoredBrand ? !isOther : isOther
}

export const placementApiField = (
    type: BidAdjustmentType,
    campaignType: CampaignType
): BidAdjustmentType => {
    const isSponsoredBrand = campaignType === HEADLINE_SEARCH
    return isSponsoredBrand ? 'bidMultiplier' : type
}

const placementMultiplier = (
    type: BidAdjustmentType,
    campaign: Campaign
): number | null => {
    if (placementImmutable(type, campaign.campaign_type)) {
        return null
    }

    return (
        campaign.bid_adjustments?.[
            placementApiField(type, campaign.campaign_type)
        ] ?? 0
    )
}

export const serializePlacements = (
    results: CampaignPlacement[],
    campaign: Campaign
): SerializedPlacement[] =>
    results.map((result) => {
        const type = PLACEMENT_TYPES[result.placement]
        const multiplier = placementMultiplier(type, campaign)

        return {
            ...result,
            multiplier,
            type,
        }
    })
