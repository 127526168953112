import { call, select, put } from 'redux-saga/effects'

import { makeFetchTableSuccess, makeFetchTableRequest } from 'actions/ui/shared'
import { EVENTS_SUMMARY_PAGE } from 'const/pages'
import { formatFilters } from 'helpers/filters/customEvents'
import { formatPagination, formatSorter } from 'helpers/params'
import { cerebroApiSaga } from 'sagas/common'
import uiSagaRegistry from 'sagas/ui/registry'
import { selectTableSettings, selectVisibleCombinedFilters } from 'selectors/ui'
import { getCustomEvents } from 'services/cerebroApi/orgScope/customEventsApi'

const TAB_PATH = [EVENTS_SUMMARY_PAGE, 'custom']
const TABLE_PATH = [...TAB_PATH, 'table']

function* fetchTableSaga(noCount) {
    const filters = yield select(selectVisibleCombinedFilters, TAB_PATH)
    const { pagination, sorter } = yield select(selectTableSettings, TABLE_PATH)

    const params = {
        ...formatFilters(filters),
        ...formatPagination(pagination),
        ...formatSorter(sorter),
    }

    yield call(
        cerebroApiSaga,
        makeFetchTableSuccess(TABLE_PATH),
        getCustomEvents,
        params,
        { headers: noCount }
    )
}

function* fetchTabDataSaga() {
    yield put(makeFetchTableRequest(TABLE_PATH)())
}

// Register Sagas
uiSagaRegistry.registerSagas(TAB_PATH, {
    fetchTabDataSaga,
})

uiSagaRegistry.registerSagas(TABLE_PATH, {
    fetchTableSaga,
})
