import { METRIC, TABLE, TIME_SERIES, TEXT, CATEGORY_CHART } from 'const/widgets'
import { Widget } from 'types'

interface LayoutSize {
    w: number
    h: number
}

export const getWidgetLayoutSize = (
    widget: Widget,
    w = 12,
    h = 16
): LayoutSize => {
    switch (widget.type) {
        case METRIC: {
            const metricRowHeight = 6
            const numWidgetsPerGridCell = 6
            const metricH =
                widget.metrics.length % numWidgetsPerGridCell === 0
                    ? metricRowHeight
                    : metricRowHeight +
                      Math.floor(
                          widget.metrics.length / numWidgetsPerGridCell
                      ) *
                          3
            return {
                w: Math.floor(2.5 * widget.metrics.length),
                h: metricH,
            }
        }
        case TABLE:
            return {
                w,
                h,
            }
        case TIME_SERIES:
        case CATEGORY_CHART:
            return {
                w,
                h: 14,
            }
        case TEXT:
            return {
                w: 6,
                h: 8,
            }
        default:
            return {
                w,
                h,
            }
    }
}
