import { memo, ReactElement } from 'react'

import isEqual from 'lodash/isEqual'

import { fetchOrganizationLabelsRequest } from 'actions/ui/app'
import { userHasManageLabelsPermissions } from 'helpers/featurePermissions'
import { useUserHasPermissions, useAction } from 'hooks'
import { deleteLabel } from 'services/cerebroApi/orgScope/resourceApi'
import { Label } from 'types'

import { DeleteField } from '../SharedFields/DeleteField'

interface Props<RecordType> {
    readOnly: boolean
    record: RecordType
    reloadData: () => void
}

function DeleteLabelField<RecordType extends Label>({
    readOnly = false,
    record,
    reloadData,
}: Props<RecordType>): ReactElement {
    const hasPermission = useUserHasPermissions(userHasManageLabelsPermissions)
    const refreshLabels = useAction(fetchOrganizationLabelsRequest)

    return (
        <DeleteField
            disabled={!hasPermission || readOnly}
            okText="Delete"
            title="Are you sure you want to delete this Label?"
            updateRequestApi={deleteLabel}
            updateRequestCallback={() => {
                // reload table
                reloadData()
                // refresh app-level labels
                refreshLabels()
            }}
            updateRequestData={record.id}
            updateRequestSuccessMesg="Label successfully deleted"
        />
    )
}

export default memo(DeleteLabelField, isEqual) as typeof DeleteLabelField
