import { ReactNode } from 'react'

import { generatePath } from 'react-router-dom'

import { PlacementMultiplierField } from 'components/EditableFields'
import {
    ArchiveCampaignField,
    CampaignAutomatedBiddingField,
    CampaignBudgetField,
    CampaignEndDateField,
    CampaignStateField,
    RemoveCampaignFromLabelField,
    RemoveFromPortfolioField,
} from 'components/EditableFields/CampaignFields'
import { ActiveBudgetCell } from 'components/FieldRenderers/ActiveBudgetCell'
import { BudgetStatusCell } from 'components/FieldRenderers/BudgetStatusCell'
import { getBudgetConfiguration } from 'components/FieldRenderers/BudgetStatusCell/helpers'
import { CampaignLink } from 'components/Links'
import { ResourceLink } from 'components/Links/ResourceLink'
import { UNDEFINED_VALUE } from 'const/formatting'
import { CAMPAIGN_PAGE } from 'const/pages'
import { getPath } from 'helpers/pages'
import { labelUrl } from 'helpers/urls'
import { isUnset } from 'helpers/utilities'
import {
    BidAdjustmentType,
    CampaignBudgetStatusGroupBy,
    CampaignGroupBy,
    CampaignLabelGroupBy,
    CampaignPlacementGroupBy,
    CellRenderProps,
} from 'types'

type RenderProps<RecordType> = {
    cellRenderProps: CellRenderProps<RecordType>
    dataIndex: string[]
}

type CampaignMultiplierRenderProps<RecordType> = {
    cellRenderProps: CellRenderProps<RecordType>
    dataIndex: string[]
    type: BidAdjustmentType
}

export function campaignIdRenderer<RecordType extends CampaignGroupBy>({
    record,
    value,
    undefinedValue = UNDEFINED_VALUE,
}: CellRenderProps<RecordType>): ReactNode {
    if (isUnset(value)) {
        return undefinedValue
    }

    return <CampaignLink campaign={record.campaign} />
}

export function campaignNameRenderer<RecordType extends CampaignGroupBy>({
    record,
    value,
    undefinedValue = UNDEFINED_VALUE,
}: CellRenderProps<RecordType>): ReactNode {
    return isUnset(value) ? (
        undefinedValue
    ) : (
        <ResourceLink
            to={generatePath(getPath(CAMPAIGN_PAGE), {
                campaignId: record.campaign.id,
            })}
        >
            {value}
        </ResourceLink>
    )
}

export function campaignStateRenderer<RecordType extends CampaignGroupBy>({
    cellRenderProps: { readonly, record },
    dataIndex,
}: RenderProps<RecordType>): ReactNode {
    return (
        <CampaignStateField
            dataIndex={dataIndex}
            readOnly={readonly}
            record={record}
        />
    )
}

export function campaignAutomatedBiddingRenderer<
    RecordType extends CampaignGroupBy,
>({
    record,
    readonly,
    undefinedValue = UNDEFINED_VALUE,
}: CellRenderProps<RecordType>): ReactNode {
    if (record.campaign.campaign_type === 'headlineSearch') {
        return (
            <CampaignAutomatedBiddingField
                record={record}
                readOnly={readonly}
            />
        )
    }
    return undefinedValue
}

export function campaignBudgetRenderer<RecordType extends CampaignGroupBy>({
    cellRenderProps: { readonly, record },
    dataIndex,
}: RenderProps<RecordType>): ReactNode {
    return (
        <CampaignBudgetField
            dataIndex={dataIndex}
            readOnly={readonly}
            record={record}
        />
    )
}

export function campaignActiveBudgetRenderer<
    RecordType extends CampaignGroupBy,
>({ cellRenderProps: { record } }: RenderProps<RecordType>): ReactNode {
    return (
        <ActiveBudgetCell
            automationTasks={record.campaign.automation_tasks}
            currencyCode={record.campaign.profile.currency_code}
            campaign={record.campaign}
            baseBudget={record.campaign.base_budget}
            activeBudget={record.campaign.active_budget}
        />
    )
}

export function campaignBudgetStatusRenderer<
    RecordType extends CampaignGroupBy & CampaignBudgetStatusGroupBy,
>({ cellRenderProps: { record } }: RenderProps<RecordType>): ReactNode {
    const configuration = getBudgetConfiguration(
        record.campaign,
        record.historical_budget,
        record.budget_percentage,
        record.budget_updated_at
    )

    return <BudgetStatusCell configuration={configuration} />
}

export function campaignEndDateRenderer<RecordType extends CampaignGroupBy>({
    cellRenderProps: { readonly, record },
    dataIndex,
}: RenderProps<RecordType>): ReactNode {
    return (
        <CampaignEndDateField
            dataIndex={dataIndex}
            readOnly={readonly}
            record={record}
        />
    )
}

export function campaignLabelIdRenderer<
    RecordType extends CampaignGroupBy & CampaignLabelGroupBy,
>({
    value,
    record,
    undefinedValue = UNDEFINED_VALUE,
}: CellRenderProps<RecordType>): ReactNode {
    if (isUnset(value)) {
        return undefinedValue
    }
    return (
        <ResourceLink
            to={{
                pathname: labelUrl(value),
                search: '?tab=campaigns',
            }}
        >
            {record.campaign__label.name}
        </ResourceLink>
    )
}

export function archiveCampaignRenderer<RecordType extends CampaignGroupBy>({
    cellRenderProps: { readonly, record, reloadData },
    dataIndex,
}: RenderProps<RecordType>): ReactNode {
    return (
        <ArchiveCampaignField
            dataIndex={dataIndex}
            record={record}
            readOnly={readonly}
            reloadData={reloadData}
        />
    )
}

export function removeCampaignFromPortfolioRenderer<
    RecordType extends CampaignGroupBy,
>({
    cellRenderProps: { readonly, record, reloadData },
    dataIndex,
}: RenderProps<RecordType>): ReactNode {
    return (
        <RemoveFromPortfolioField
            dataIndex={dataIndex}
            record={record}
            readOnly={readonly}
            reloadData={reloadData}
        />
    )
}

export function removeCampaignFromLabelRenderer<
    RecordType extends CampaignGroupBy,
>({
    cellRenderProps: { readonly, record, reloadData },
    dataIndex,
}: RenderProps<RecordType>): ReactNode {
    return (
        <RemoveCampaignFromLabelField
            dataIndex={dataIndex}
            record={record}
            readOnly={readonly}
            reloadData={reloadData}
        />
    )
}

export function campaignBidMultiplierRenderer<
    RecordType extends CampaignPlacementGroupBy,
>({
    cellRenderProps: { readonly, record, rowIndex, updateRecord },
    dataIndex,
    type,
}: CampaignMultiplierRenderProps<RecordType>): ReactNode {
    return (
        <PlacementMultiplierField
            dataIndex={dataIndex}
            record={record}
            readOnly={readonly}
            rowIndex={rowIndex}
            updateRecord={updateRecord}
            type={type}
        />
    )
}
