import { combineChildrenReducers } from 'utilities/reducers'

import adGroupsReducer from './adGroups'
import automationReducer from './automation'
import budgetReducer from './budget'
import changelogReducer from './changelog'
import keywordsReducer from './keywords'
import keywordSearchTermsReducer from './keywordSearchTerms'
import negativeKeywordsReducer from './negativeKeywords'
import pageReducer from './page'
import placementsReducer from './placements'
import productAdsReducer from './productAds'
import productsReducer from './products'
import targetsReducer from './targets'
import targetSearchTermsReducer from './targetSearchTerms'

export default combineChildrenReducers(pageReducer, {
    automation: automationReducer,
    changelog: changelogReducer,
    keywords: keywordsReducer,
    keywordSearchTerms: keywordSearchTermsReducer,
    negativeKeywords: negativeKeywordsReducer,
    productAds: productAdsReducer,
    products: productsReducer,
    placements: placementsReducer,
    adGroups: adGroupsReducer,
    targets: targetsReducer,
    targetSearchTerms: targetSearchTermsReducer,
    budget: budgetReducer,
})
