import { shallowEqual, useSelector } from 'react-redux'

import { selectDenormalizedTabs, selectDenormalizedTabById } from 'selectors/ui'
import { RootReduxState, DashboardTab } from 'types'

export function useDenormalizedTabs<T = DashboardTab[]>(page: string): T {
    return useSelector<RootReduxState, T>(
        (state) => selectDenormalizedTabs(state, page),
        shallowEqual
    )
}

export function useDenormalizedTabById<T = DashboardTab>(
    page: string,
    tabId?: string
): T | null {
    return useSelector<RootReduxState, T>(
        (state) => selectDenormalizedTabById(state, page, tabId),
        shallowEqual
    )
}
