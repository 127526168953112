import { handleActions } from 'redux-actions'

import { labelDspLineItemsTableColumnsConfig } from 'configuration/tables'
import {
    DATES,
    BRANDS,
    DSP_ADVERTISERS,
    DSP_ORDERS,
    DSP_LINE_ITEMS,
} from 'const/filters'
import { LOCAL_FILTERS, FILTER_SETTINGS } from 'const/reducerKeys'
import { serializeColumns } from 'helpers/tables'
import { defaultTab, getDefaultTable } from 'reducers/ui/defaults'

const initialState = {
    ...defaultTab,
    [LOCAL_FILTERS]: {
        [BRANDS]: [],
        [DSP_ADVERTISERS]: [],
        [DSP_ORDERS]: [],
        [DSP_LINE_ITEMS]: [],
    },
    [FILTER_SETTINGS]: {
        anchored: [DATES],
        order: [BRANDS, DSP_ADVERTISERS, DSP_ORDERS, DSP_LINE_ITEMS],
        displayState: {
            [BRANDS]: true,
            [DSP_ADVERTISERS]: true,
            [DSP_ORDERS]: true,
            [DSP_LINE_ITEMS]: true,
        },
    },
    table: getDefaultTable(
        serializeColumns(labelDspLineItemsTableColumnsConfig),
        {
            sorter: {
                field: 'total_cost__sum',
                order: 'descend',
            },
        }
    ),
}

export default handleActions({}, initialState)
