import {
    makeCountMetricFieldFunc,
    makeCurrencyMetricFieldFunc,
    makePercentageMetricFieldFunc,
} from './localUtils'

export const shipped_quantity__sum = makeCountMetricFieldFunc({
    name: 'Shipped Quantity',
    shortName: 'Shipped Quantity',
    deltas: ['change', 'percentage_change'],
    id: 'shipped_quantity__sum',
})

export const gross_sales_revenue__sum = makeCurrencyMetricFieldFunc({
    name: 'Gross Sales Revenue',
    shortName: 'Gross Sales Revenue',
    deltas: ['change', 'percentage_change'],
    id: 'gross_sales_revenue__sum',
})

export const total_tender__sum = makeCurrencyMetricFieldFunc({
    name: 'Total Tender',
    shortName: 'Total Tender',
    deltas: ['change', 'percentage_change'],
    id: 'total_tender__sum',
})

export const payable_to_partner__sum = makeCurrencyMetricFieldFunc({
    name: 'Payable To Partner',
    shortName: 'Payable To Partner',
    deltas: ['change', 'percentage_change'],
    id: 'payable_to_partner__sum',
})

export const commission_from_sale__sum = makeCurrencyMetricFieldFunc({
    name: 'Commission From Sale',
    shortName: 'Commission From Sale',
    deltas: ['change', 'percentage_change'],
    id: 'commission_from_sale__sum',
})

export const commission_rate = makePercentageMetricFieldFunc({
    name: 'Commission Rate',
    shortName: 'Commission Rate',
    localDefinition: 'Commissions / Gross Shipping Revenue',
    deltas: ['change'],
    id: 'commission_rate',
})

export const refunded_retail_sales__sum = makeCurrencyMetricFieldFunc({
    name: 'Refunded Retail Sales',
    shortName: 'Refunded Retail Sales',
    deltas: ['change', 'percentage_change'],
    id: 'refunded_retail_sales__sum',
})

export const gross_shipping_revenue__sum = makeCurrencyMetricFieldFunc({
    name: 'Gross Shipping Revenue',
    shortName: 'Gross Shipping Revenue',
    deltas: ['change', 'percentage_change'],
    id: 'gross_shipping_revenue__sum',
})

export const price__last_value = makeCurrencyMetricFieldFunc({
    name: 'Price',
    shortName: 'Price',
    deltas: ['change', 'percentage_change'],
    id: 'price__last_value',
})

export const average_rating__last_value = makeCountMetricFieldFunc({
    name: 'Average Rating',
    shortName: 'Average Rating',
    deltas: ['change', 'percentage_change'],
    id: 'average_rating__last_value',
})

export const inventory_count__last_value = makeCountMetricFieldFunc({
    name: 'Inventory Count',
    shortName: 'Inventory Count',
    deltas: ['change', 'percentage_change'],
    id: 'inventory_count__last_value',
})

export const reviews_count__last_value = makeCountMetricFieldFunc({
    name: 'Reviews',
    shortName: 'Reviews',
    deltas: ['change', 'percentage_change'],
    id: 'reviews_count__last_value',
})
