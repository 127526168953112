import { CSSProperties, ReactElement, ReactNode } from 'react'

import { Link, LinkProps } from 'react-router-dom'

import { userHasDashboardsOnlyPermissions } from 'helpers/featurePermissions'
import { useUserHasPermissions } from 'hooks'

interface Props {
    to: LinkProps['to']
    children: ReactNode
    className?: string | undefined
    style?: CSSProperties | undefined
}

export const ResourceLink = ({
    to,
    children,
    className = undefined,
    style = undefined,
}: Props): ReactElement => {
    const dashboardOnlyExperience = useUserHasPermissions(
        userHasDashboardsOnlyPermissions
    )

    return dashboardOnlyExperience ? (
        (children as ReactElement)
    ) : (
        <Link to={to} className={className} style={style}>
            {children}
        </Link>
    )
}
