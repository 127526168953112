import { ReactNode } from 'react'

import { generatePath } from 'react-router-dom'

import { WalmartAdGroupStateField } from 'components/EditableFields'
import { ResourceLink } from 'components/Links/ResourceLink'
import { UNDEFINED_VALUE } from 'const/formatting'
import { WALMART_AD_GROUP_PAGE } from 'const/pages'
import { getPath } from 'helpers/pages'
import { isUnset } from 'helpers/utilities'
import { CellRenderProps, WalmartAdGroupGroupBy } from 'types'

type RenderProps = {
    record: WalmartAdGroupGroupBy
    dataIndex: string[]
}

export function wmAdGroupNameRenderer<
    RecordType extends WalmartAdGroupGroupBy,
>({
    record,
    value,
    undefinedValue = UNDEFINED_VALUE,
}: CellRenderProps<RecordType>): ReactNode {
    return isUnset(value) ? (
        undefinedValue
    ) : (
        <ResourceLink
            to={generatePath(getPath(WALMART_AD_GROUP_PAGE), {
                wmCampaignId: record.ad_group.campaign_id,
                wmAdGroupId: record.ad_group.id,
            })}
        >
            {value}
        </ResourceLink>
    )
}

export function walmartAdGroupStateRenderer({
    record,
    dataIndex,
}: RenderProps): ReactNode {
    return <WalmartAdGroupStateField dataIndex={dataIndex} record={record} />
}
