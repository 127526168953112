import includes from 'lodash/includes'
import isObjectLike from 'lodash/isObjectLike'
import isUndefined from 'lodash/isUndefined'
import pick from 'lodash/pick'
import set from 'lodash/set'
import { combineReducers, Reducer, ReducersMapObject } from 'redux'

/**
 * Combine parent reducer and children reducers
 *
 * @param {function} parentReducer - Parent reducer
 * @param {object} childrenReducers - Children reducers map
 *
 * @returns {function} - Combined reducer
 */
export const combineChildrenReducers =
    (
        parentReducer: Reducer<any, any>,
        childrenReducers: ReducersMapObject<any, any>
    ): Reducer<any, any> =>
    (state, action) => {
        const nextParentState = parentReducer(state, action)

        // reducer combined by `combineReducers()` expects the state to have
        // only the keys that belongs to sub reducers.
        // So, it filters out data for children combined reducer only from the state
        //
        // @see https://github.com/reduxjs/redux/blob/master/src/combineReducers.js#L57
        const childrenReducer = combineReducers(childrenReducers)
        const childrenState = pick(
            nextParentState,
            Object.keys(childrenReducers)
        )
        const nextChildrenState = childrenReducer(childrenState, action)

        return {
            ...nextParentState,
            ...nextChildrenState,
        }
    }

export function deepPick(
    obj: any,
    keys: any,
    newObj = {},
    path: string[] = []
): object {
    if (isObjectLike(obj)) {
        Object.keys(obj).forEach((k) => {
            const value = obj[k]

            if (includes(keys, k)) {
                if (!isUndefined(value)) {
                    set(newObj, [...path, k], value)
                }
            } else if (
                ![
                    'widget',
                    'data',
                    'dashboard',
                    'alert_configuration',
                ].includes(k) &&
                isObjectLike(value)
            ) {
                path.push(k)
                deepPick(value, keys, newObj, path)
                path.pop()
            }
        })
    }

    return newObj
}

function mutableDeepOverride<T extends object, U extends object>(
    targetObj: T,
    sourceObj: U,
    key: string
): T {
    if (isObjectLike(sourceObj)) {
        Object.keys(sourceObj).forEach((k) => {
            if (k === key) {
                const value = (sourceObj as any)[k]
                set(targetObj, [k], value)
            } else if (
                isObjectLike((targetObj as any)[k]) &&
                isObjectLike((sourceObj as any)[k])
            ) {
                mutableDeepOverride(
                    (targetObj as any)[k],
                    (sourceObj as any)[k],
                    key
                )
            }
        })
    }

    return targetObj
}

export function deepOverride<T extends object, U extends object>(
    mainObj: T,
    sourceObj: U,
    key: string
): T {
    const newObj = { ...mainObj }
    return mutableDeepOverride(newObj, sourceObj, key)
}
