import { ReactElement } from 'react'

import classNames from 'classnames'

import styles from './styles.scss'

interface Props {
    type: string
}

export default function CreateProductAdFormheader({
    type,
}: Props): ReactElement {
    return (
        <div className="d-flex align-items-start">
            <div style={{ flex: 1 }} className="mt-2 mr-1 fg-control-label">
                {type}
            </div>

            <div
                className={classNames(
                    'mt-2 mr-1 fg-control-label',
                    styles['header-label'],
                    styles['ad-group-column']
                )}
                style={{
                    textAlign: 'center',
                }}
            >
                Target Ad Group
            </div>

            <div
                className={classNames(
                    'mt-2 mr-1 fg-control-label',
                    styles['header-label'],
                    styles['state-column']
                )}
                style={{
                    textAlign: 'center',
                }}
            >
                State
            </div>

            <div
                className={classNames(
                    'mt-2 mr-1 fg-control-label',
                    styles['header-label'],
                    styles['remove-column']
                )}
                style={{
                    textAlign: 'center',
                }}
            >
                Remove
            </div>
        </div>
    )
}
