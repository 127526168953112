import { ReactNode } from 'react'

import { DeleteOrganizationGroupField } from 'components/EditableFields'
import { CellRenderProps, OrganizationGroup } from 'types'

export function deleteOrganizationGroupRenderer<
    RecordType extends OrganizationGroup,
>({ record, readonly, reloadData }: CellRenderProps<RecordType>): ReactNode {
    return (
        <DeleteOrganizationGroupField
            record={record}
            readOnly={readonly}
            reloadData={reloadData}
        />
    )
}
