import { ReactElement, ReactNode } from 'react'

import { DownloadOutlined, SettingOutlined } from '@ant-design/icons'
import noop from 'lodash/noop'
import { useTranslation } from 'react-i18next'

import { IconButton } from 'components/Buttons'
import { useTableSettingsProps } from 'hooks/redux'
import { Field, PeriodDeltaType } from 'types'

import styles from './styles.scss'
import TableSettingsModal from '../TableSettingsModal/TableSettingsModal'

interface Props<RecordType> {
    title: ReactNode
    subtitle?: ReactNode
    tablePath: string[]
    downloadData?: () => void
    downloading?: boolean
    columns: Field<RecordType>[]
    showPeriodDeltas?: boolean
    periodDeltaType?: PeriodDeltaType
    periodDeltaDateRange?: string[]
    disablePriorPeriod?: boolean
    disabledPriorPeriodMessage?: string
    showTotalRow?: boolean
    showTableSettings?: boolean
    extraActions?: ReactNode[]
    alert?: ReactNode
}

function BasicTitle<RecordType>({
    title,
    subtitle,
    tablePath,
    downloadData = noop,
    downloading = false,
    showPeriodDeltas,
    periodDeltaType,
    periodDeltaDateRange,
    disablePriorPeriod,
    disabledPriorPeriodMessage,
    showTotalRow,
    showTableSettings = true,
    columns,
    extraActions = [],
    alert = undefined,
}: Props<RecordType>): ReactElement {
    const { t } = useTranslation('table')
    const {
        toggleModalVisibility,
        isSettingsModalVisible,
        handleModalCancel,
        handleModalOk,
    } = useTableSettingsProps(tablePath)

    const getActions = (): ReactNode[] => {
        let actions = extraActions
        if (downloadData !== noop) {
            actions = actions.concat(
                <IconButton
                    tooltipTitle={t(
                        'table:BasicTitle.downloadBtn.tooltipTitle',
                        'Download Report'
                    )}
                    tooltipPlacement="topRight"
                    onClick={() => downloadData()}
                    icon={<DownloadOutlined />}
                    type="link"
                    loading={downloading}
                />
            )
        }
        if (showTableSettings) {
            actions = actions.concat(
                <IconButton
                    tooltipTitle={t(
                        'table:BasicTitle.tableSettingsBtn.tooltipTitle',
                        'Adjust Table Settings'
                    )}
                    tooltipPlacement="topRight"
                    onClick={toggleModalVisibility}
                    icon={<SettingOutlined />}
                    type="link"
                />
            )
        }
        return actions
    }

    return (
        <>
            <div className={styles['title-container']}>
                <div className={styles.title}>
                    {title}
                    <span className={styles.subtitle}>{subtitle}</span>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                    {getActions().map((action, index) => (
                        <div key={index}>{action}</div>
                    ))}
                </div>
                <TableSettingsModal
                    droppableId="paginatedTableSettingsModal"
                    visible={isSettingsModalVisible}
                    handleCancel={handleModalCancel}
                    handleOk={handleModalOk}
                    columns={columns}
                    showPeriodDeltas={showPeriodDeltas}
                    periodDeltaType={periodDeltaType}
                    periodDeltaDateRange={periodDeltaDateRange}
                    showTotalRow={showTotalRow}
                    disablePriorPeriod={disablePriorPeriod}
                    disabledPriorPeriodMessage={disabledPriorPeriodMessage}
                />
            </div>
            {alert}
        </>
    )
}

export default BasicTitle
