import { all, takeLatest } from 'redux-saga/effects'

import { mountTabRequest, fetchTabDataRequest } from 'actions/ui/shared/tab'

import { fetchTabDataWorker, mountTabWorker } from './workers'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export default function* tabWatcher() {
    yield all([
        takeLatest(mountTabRequest.toString(), mountTabWorker),
        takeLatest(fetchTabDataRequest.toString(), fetchTabDataWorker),
    ])
}
