import { generatePath } from 'react-router-dom'

import { ResourceLink } from 'components/Links/ResourceLink'
import { adGroupName } from 'configuration/fieldCreators/walmartAdGroup'
import {
    advertiserAccountName,
    advertiserId,
    advertiserIntegrationType,
    advertiserType,
} from 'configuration/fieldCreators/walmartAdvertiser'
import { campaignName } from 'configuration/fieldCreators/walmartCampaign'
import {
    keywordBid,
    keywordMatchType,
    keywordState,
    keywordText,
} from 'configuration/fieldCreators/walmartKeyword'
import { WALMART_AD_GROUP_PAGE, WALMART_CAMPAIGN_PAGE } from 'const/pages'
import { getPath } from 'helpers/pages'
import { Field, WalmartKeywordGroupBy } from 'types'

import { WM_ADVERTISING_METRICS_TABLE_COLUMNS } from '../dataSources/walmartAdvertising'

export const walmartKeywordsSummaryTableColumnsConfig: Field<WalmartKeywordGroupBy>[] =
    [
        keywordText<WalmartKeywordGroupBy>({
            fixed: true,
            renderOptions: {
                render: ({ record }) => record.keyword.keyword_text,
            },
        }),
        keywordMatchType({
            dataIndex: ['keyword', 'match_type'],
        }),
        keywordState({
            dataIndex: ['keyword', 'state'],
        }),
        keywordBid({
            dataIndex: ['keyword', 'bid'],
        }),
        campaignName<WalmartKeywordGroupBy>({
            dataIndex: ['campaign', 'name'],
            renderOptions: {
                render: ({ record, value }) => (
                    <ResourceLink
                        to={generatePath(getPath(WALMART_CAMPAIGN_PAGE), {
                            wmCampaignId: record.campaign.id,
                        })}
                    >
                        {value}
                    </ResourceLink>
                ),
            },
        }),
        advertiserAccountName({
            alternateId: 'advertiser_alias',
            dataIndex: ['advertiser', 'alias'],
        }) as Field<WalmartKeywordGroupBy>,
        adGroupName<WalmartKeywordGroupBy>({
            alternateId: 'ad_group_name',
            dataIndex: ['ad_group', 'name'],
            renderOptions: {
                render: ({ record, value }) => (
                    <ResourceLink
                        to={generatePath(getPath(WALMART_AD_GROUP_PAGE), {
                            wmAdGroupId: record.ad_group.id,
                            wmCampaignId: record.campaign.id,
                        })}
                    >
                        {value}
                    </ResourceLink>
                ),
            },
        }),
        advertiserType({
            isVisible: false,
            dataIndex: ['advertiser', 'type'],
        }),
        advertiserId({
            isVisible: false,
        }),
        advertiserIntegrationType({
            isVisible: false,
            dataIndex: ['advertiser', 'read_only'],
        }),
        ...(WM_ADVERTISING_METRICS_TABLE_COLUMNS as Field<WalmartKeywordGroupBy>[]),
    ]

export const walmartCampaignKeywordsTabTableColumnsConfig: Field<WalmartKeywordGroupBy>[] =
    [
        keywordText<WalmartKeywordGroupBy>({
            fixed: true,
            renderOptions: {
                render: ({ record }) => record.keyword.keyword_text,
            },
        }),
        keywordMatchType({
            dataIndex: ['keyword', 'match_type'],
        }),
        keywordState({
            dataIndex: ['keyword', 'state'],
        }),
        keywordBid({
            dataIndex: ['keyword', 'bid'],
        }),
        adGroupName<WalmartKeywordGroupBy>({
            dataIndex: ['ad_group', 'name'],
            renderOptions: {
                render: ({ record, value }) => (
                    <ResourceLink
                        to={generatePath(getPath(WALMART_AD_GROUP_PAGE), {
                            wmAdGroupId: record.ad_group.id,
                            wmCampaignId: record.campaign.id,
                        })}
                    >
                        {value}
                    </ResourceLink>
                ),
            },
        }),
        ...(WM_ADVERTISING_METRICS_TABLE_COLUMNS as Field<WalmartKeywordGroupBy>[]),
    ]

export const walmartAdGroupKeywordsTabTableColumnsConfig: Field<WalmartKeywordGroupBy>[] =
    [
        keywordText<WalmartKeywordGroupBy>({
            fixed: true,
            renderOptions: {
                render: ({ record }) => record.keyword.keyword_text,
            },
        }),
        keywordMatchType({
            dataIndex: ['keyword', 'match_type'],
        }),
        keywordState({
            dataIndex: ['keyword', 'state'],
        }),
        keywordBid({
            dataIndex: ['keyword', 'bid'],
        }),
        ...(WM_ADVERTISING_METRICS_TABLE_COLUMNS as Field<WalmartKeywordGroupBy>[]),
    ]
