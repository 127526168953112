import { handleActions } from 'redux-actions'

import { dspLineItemsTableColumnsConfig } from 'configuration/tables'
import {
    DATES,
    BRANDS,
    DSP_ADVERTISERS,
    DSP_ORDERS,
    DSP_LINE_ITEMS,
    LABELS,
} from 'const/filters'
import { FILTERS, FILTER_SETTINGS } from 'const/reducerKeys'
import { serializeColumns } from 'helpers/tables'
import { defaultTab, getDefaultTable } from 'reducers/ui/defaults'

const initialState = {
    ...defaultTab,
    [FILTERS]: {
        [BRANDS]: [],
        [DSP_ADVERTISERS]: [],
        [DSP_ORDERS]: [],
        [DSP_LINE_ITEMS]: [],
        [LABELS]: [],
    },
    [FILTER_SETTINGS]: {
        anchored: [DATES],
        order: [BRANDS, DSP_ADVERTISERS, DSP_ORDERS, DSP_LINE_ITEMS, LABELS],
        displayState: {
            [BRANDS]: true,
            [DSP_ADVERTISERS]: true,
            [DSP_ORDERS]: true,
            [DSP_LINE_ITEMS]: true,
            [LABELS]: true,
        },
    },
    table: getDefaultTable(serializeColumns(dspLineItemsTableColumnsConfig), {
        sorter: {
            field: 'total_cost__sum',
            order: 'descend',
        },
    }),
}

export default handleActions({}, initialState)
