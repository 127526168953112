import has from 'lodash/has'
import { all, call, select, put } from 'redux-saga/effects'

import { makeFetchResourceSuccess } from 'actions/ui/shared/resource'
import { PRODUCT_PAGE } from 'const/pages'
import { cerebroApiSaga } from 'sagas/common'
import uiSagaRegistry from 'sagas/ui/registry'
import { selectResourceParamsOfPage, selectResourceOfPage } from 'selectors/ui'
import {
    getProductMetadata,
    updateProduct,
} from 'services/cerebroApi/orgScope/resourceApi'

function* fetchProductSaga() {
    const { asin, countryCode } = yield select(
        selectResourceParamsOfPage,
        PRODUCT_PAGE
    )

    const response = yield call(cerebroApiSaga, null, getProductMetadata, {
        asin,
        marketplace: countryCode,
    })

    if (has(response, ['data', 'results'])) {
        const product = response.data.results[0]
        if (product) {
            yield put(makeFetchResourceSuccess(PRODUCT_PAGE)(product))
        }
    }
}

function* updateProductSaga({ asin, countryCode }, data) {
    const {
        data: { labels },
        status,
    } = yield call(
        cerebroApiSaga,
        null,
        updateProduct,
        `${countryCode}_${asin}`,
        data
    )

    const product = yield select(selectResourceOfPage, PRODUCT_PAGE)

    if (status === 200 && product) {
        yield put(
            makeFetchResourceSuccess(PRODUCT_PAGE)({ ...product, labels })
        )
    }
}

/**
 * Mounts the Products Page and fetches data
 */
function* mountPageSaga() {
    yield all([
        // Load product
        call(fetchProductSaga),
    ])
}

uiSagaRegistry.registerSagas([PRODUCT_PAGE], {
    mountPageSaga,
    addLabelsSaga: updateProductSaga,
    removeLabelSaga: updateProductSaga,
})
