import { ReactElement } from 'react'

import { Trans, useTranslation } from 'react-i18next'

import { useAppConfig } from 'appConfig'
import { ContactUsLink } from 'components/Links'
import CobaltLogo from 'images/logo/CobaltLogo'

import styles from './styles.scss'

interface MaintenancePageProps {
    message?: string
}

const MaintenancePage = ({ message }: MaintenancePageProps): ReactElement => {
    const { t } = useTranslation()
    const { productTitle } = useAppConfig()
    return (
        <div className={styles.background}>
            <div className={styles.container}>
                <div className={styles.logo}>
                    <CobaltLogo style={{ height: 48 }} />
                </div>
                {message ? (
                    <p>{message}</p>
                ) : (
                    <p>
                        <Trans i18nKey="common:maintenance.defaultOfflineMessage">
                            We are currently offline for planned maintenance.{' '}
                            <ContactUsLink link>Let us know</ContactUsLink> if
                            you have questions or concerns.
                        </Trans>
                    </p>
                )}
                <p style={{ marginBottom: 0 }}>
                    {t(
                        'common:maintenance.valediction',
                        'Thanks for your patience,'
                    )}
                </p>
                <p>
                    {t(
                        'common:maintenance.team',
                        '{{ productTitle }} Engineering Team',
                        { productTitle }
                    )}
                </p>
            </div>
        </div>
    )
}

export default MaintenancePage
