import createCachedSelector from 're-reselect'

import { List, Path, RootReduxState } from 'types'

import { selectDomainValue, createPathCacheKey } from './ui'

export const selectListSettings = createCachedSelector<
    RootReduxState,
    Path,
    List<any>,
    List<any>['listSettings']
>(
    (state, listPath) => selectDomainValue(state, listPath),
    (list) => list.listSettings
)((_state, listPath) => createPathCacheKey(listPath))
