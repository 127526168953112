import { SVGAttributes, ReactElement } from 'react'

type Props = SVGAttributes<SVGElement>

function SvgLogOutIcon(props: Props): ReactElement {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M6.46679 1.70001L7.50001 1.70001C7.94184 1.70001 8.30001 2.05819 8.30001 2.50001C8.30001 2.94184 7.94184 3.30001 7.50001 3.30001H6.50001C5.78675 3.30001 5.29991 3.30064 4.92316 3.33142C4.55576 3.36143 4.36405 3.4161 4.22823 3.4853C3.90835 3.64829 3.64829 3.90835 3.4853 4.22823C3.4161 4.36404 3.36144 4.55576 3.33142 4.92316C3.30064 5.29991 3.30001 5.78675 3.30001 6.50001V13.5C3.30001 14.2133 3.30064 14.7001 3.33142 15.0769C3.36143 15.4443 3.4161 15.636 3.4853 15.7718C3.64829 16.0917 3.90835 16.3517 4.22823 16.5147C4.36404 16.5839 4.55576 16.6386 4.92316 16.6686C5.29991 16.6994 5.78675 16.7 6.50001 16.7H7.50001C7.94184 16.7 8.30001 17.0582 8.30001 17.5C8.30001 17.9418 7.94184 18.3 7.50001 18.3H6.46676C5.79494 18.3 5.24249 18.3 4.79287 18.2633C4.32652 18.2252 3.90081 18.1436 3.50184 17.9403C2.88091 17.624 2.37607 17.1191 2.05969 16.4982C1.85641 16.0992 1.77483 15.6735 1.73673 15.2072C1.69999 14.7575 1.7 14.2051 1.70001 13.5332V6.46679C1.7 5.79496 1.69999 5.2425 1.73673 4.79287C1.77483 4.32652 1.85641 3.90081 2.05969 3.50184C2.37607 2.88091 2.88091 2.37607 3.50184 2.05969C3.90081 1.85641 4.32652 1.77483 4.79287 1.73673C5.2425 1.69999 5.79496 1.7 6.46679 1.70001ZM12.7677 5.26766C13.0801 4.95524 13.5866 4.95524 13.899 5.26766L18.0657 9.43433C18.3781 9.74675 18.3781 10.2533 18.0657 10.5657L13.899 14.7324C13.5866 15.0448 13.0801 15.0448 12.7677 14.7324C12.4552 14.4199 12.4552 13.9134 12.7677 13.601L15.5686 10.8H7.50001C7.05819 10.8 6.70001 10.4418 6.70001 10C6.70001 9.55819 7.05819 9.20001 7.50001 9.20001H15.5686L12.7677 6.39903C12.4552 6.08661 12.4552 5.58008 12.7677 5.26766Z" />
        </svg>
    )
}

export default SvgLogOutIcon
