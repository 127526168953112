import isNull from 'lodash/isNull'
import createCachedSelector from 're-reselect'

import {
    BRANDS,
    CAMPAIGNS,
    PORTFOLIOS,
    KEYWORDS,
    SOV_KEYWORDS,
    PRODUCT_ASINS,
    COUNTRIES,
    PRODUCT_ADS,
    PARENT_ASINS,
    AD_GROUPS,
} from 'const/filters'
import { RootReduxState, CustomEvent, PageFilters } from 'types'

import { selectDomainValue } from './ui'

const selectCustomEvents = (state: RootReduxState): CustomEvent[] =>
    selectDomainValue(state, ['app', 'customEvents'])

export const selectCustomEventsForWidget = createCachedSelector(
    selectCustomEvents,
    (_state: RootReduxState, filters: PageFilters) => filters,
    (customEvents, filters) =>
        customEvents.filter((customEvent) => {
            // return all global events
            if (isNull(customEvent.resource)) {
                return true
            }

            if (customEvent.resource_type === 'profile') {
                if (filters[BRANDS] && filters[BRANDS].length === 1) {
                    return filters[BRANDS][0].value === customEvent.resource_id
                }
                return false
            }

            if (customEvent.resource_type === 'portfolio') {
                if (filters[PORTFOLIOS] && filters[PORTFOLIOS].length === 1) {
                    return (
                        filters[PORTFOLIOS][0].value === customEvent.resource_id
                    )
                }
                return false
            }

            if (customEvent.resource_type === 'campaign') {
                if (filters[CAMPAIGNS] && filters[CAMPAIGNS].length === 1) {
                    return (
                        filters[CAMPAIGNS][0].value === customEvent.resource_id
                    )
                }
                return false
            }

            if (customEvent.resource_type === 'ad_group') {
                if (filters[AD_GROUPS] && filters[AD_GROUPS].length === 1) {
                    return (
                        filters[AD_GROUPS][0].value === customEvent.resource_id
                    )
                }
                return false
            }

            if (customEvent.resource_type === 'keyword') {
                if (filters[KEYWORDS] && filters[KEYWORDS].length === 1) {
                    return (
                        filters[KEYWORDS][0].value === customEvent.resource_id
                    )
                }
                return false
            }

            if (customEvent.resource_type === 'sov_keyword') {
                if (
                    filters[SOV_KEYWORDS] &&
                    filters[SOV_KEYWORDS].length === 1
                ) {
                    return (
                        filters[SOV_KEYWORDS][0].value ===
                        customEvent.resource_id
                    )
                }
                return false
            }

            if (customEvent.resource_type === 'product_ad') {
                if (filters[PRODUCT_ADS] && filters[PRODUCT_ADS].length === 1) {
                    return (
                        filters[PRODUCT_ADS][0].value ===
                        customEvent.resource_id
                    )
                }
                return false
            }

            if (customEvent.resource_type === 'product') {
                // "Child Product"
                if (filters[PRODUCT_ASINS] && filters[COUNTRIES]) {
                    if (
                        filters[PRODUCT_ASINS].length === 1 &&
                        filters[COUNTRIES].length === 1
                    ) {
                        return (
                            `${filters[COUNTRIES][0].value}_${filters[PRODUCT_ASINS][0].value}` ===
                            customEvent.resource_id
                        )
                    }
                }

                // "Parent Product"
                if (filters[PARENT_ASINS] && filters[COUNTRIES]) {
                    if (
                        filters[PARENT_ASINS].length === 1 &&
                        filters[COUNTRIES].length === 1
                    ) {
                        return (
                            `${filters[COUNTRIES][0].value}_${filters[PARENT_ASINS][0].value}` ===
                            customEvent.resource_id
                        )
                    }
                }

                return false
            }

            return false
        })
)((_state, filters) => JSON.stringify(filters))
