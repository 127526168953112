import produce from 'immer'
import forOwn from 'lodash/forOwn'
import flow from 'lodash/fp/flow'
import set from 'lodash/fp/set'

import {
    fetchResourceSuccess,
    updateResourceRequest,
    updateResourceSuccess,
    updateResourceFailure,
} from 'actions/ui/shared/resource'
import { TABLE } from 'const/widgets'
import { getFilterOverridesForPage } from 'helpers/widgets'
import { defaultPaginationAndSorter } from 'reducers/ui/defaults'

export default {
    [fetchResourceSuccess](state, { payload: { pageName, data } }) {
        const filterOverrides = getFilterOverridesForPage(pageName, data)

        return produce(state, (draft) => {
            draft[pageName].resource = data
            forOwn(draft[pageName].widgets, (widget) => {
                widget.filters = filterOverrides.mergeWithFilters
                    ? { ...widget.filters, ...filterOverrides.filters }
                    : filterOverrides.filters
                if (widget.type === TABLE) {
                    const paginationAndSorterObj =
                        defaultPaginationAndSorter(widget)
                    widget.pagination = { ...paginationAndSorterObj.pagination }
                    widget.sorter = { ...paginationAndSorterObj.sorter }
                }
            })
        })
    },
    [updateResourceRequest](
        state,
        { payload: { pageName, updatingResourceKey } }
    ) {
        return set([pageName, updatingResourceKey], true, state)
    },
    [updateResourceSuccess](
        state,
        { payload: { pageName, updatingResourceKey, data } }
    ) {
        return flow(
            set([pageName, 'resource'], data),
            set([pageName, updatingResourceKey], false)
        )(state)
    },
    [updateResourceFailure](
        state,
        {
            payload: {
                pageName,
                updatingResourceKey,
                data: { message },
            },
        }
    ) {
        return flow(
            set([pageName, updatingResourceKey], false),
            set([pageName, 'error'], message)
        )(state)
    },
}
