import { SVGAttributes, ReactElement } from 'react'

type Props = SVGAttributes<SVGElement>

function SvgMoreIcon(props: Props): ReactElement {
    return (
        <svg
            viewBox="0 0 14 14"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            {...props}
        >
            <path d="M12.185 8.556a1.556 1.556 0 110-3.112 1.556 1.556 0 010 3.112zM7 8.556a1.556 1.556 0 110-3.112 1.556 1.556 0 010 3.112zM.26 7a1.556 1.556 0 103.11 0A1.556 1.556 0 00.26 7z" />
        </svg>
    )
}

export default SvgMoreIcon
