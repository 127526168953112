/**
 * This helper is for those widgets out there that define up to two metrics. Before DOW-10016 was implemented
 */
import { YAxisOptions } from 'highcharts'

import {
    Y_AXIS_COLORS,
    Y_AXIS_COLORS_BOTH,
    Y_AXIS_PRIOR_PERIOD_COLUMN_COLORS,
} from 'configuration/charts'
import { COLUMN, SPLINE_AND_COLUMN } from 'const/widgets'
import { transformToString } from 'helpers/widgets'
import { FieldMapping, PriorPeriodConfig, Widget } from 'types'

import {
    generateGroupedSeries,
    generateMetricSeries,
    getAxisByMetric,
    getChartType,
} from './utils'

export class MetricsDrivenSeries {
    private widget: Widget

    private widgetData: any[]

    private widgetPriorPeriodData: any[]

    private useSameAxis: boolean

    private widgetMetrics: string[]

    private widgetGroupBy: string[]

    private hasOneGrouping: boolean

    private priorPeriodConfig: PriorPeriodConfig

    private automationCapabilitiesMap: any

    private brandGroupsMap: any

    private metricsConfig: FieldMapping

    constructor(
        widget: Widget,
        widgetData: any[],
        widgetPriorPeriodData: any[],
        priorPeriodConfig: PriorPeriodConfig,
        automationCapabilitiesMap: any,
        brandGroupsMap: any,
        metricsConfig: FieldMapping
    ) {
        this.widget = widget
        this.widgetData = widgetData
        this.widgetPriorPeriodData = widgetPriorPeriodData
        this.useSameAxis = widget?.use_same_metric_axis || false
        this.widgetMetrics = transformToString(widget.metrics) ?? []
        this.widgetGroupBy = transformToString(widget.group_by) ?? []
        this.hasOneGrouping = this.widgetGroupBy.length === 1
        this.priorPeriodConfig = priorPeriodConfig
        this.automationCapabilitiesMap = automationCapabilitiesMap
        this.brandGroupsMap = brandGroupsMap
        this.metricsConfig = metricsConfig
    }

    getYAxes = (showTitle: boolean = true): YAxisOptions[] =>
        this.widgetMetrics.map((metric, idx) => {
            const color = (): string => {
                if (this.useSameAxis) {
                    return '#888'
                }
                if (this.hasOneGrouping) {
                    return Y_AXIS_COLORS[idx]
                }
                return ''
            }
            return getAxisByMetric({
                widget: this.widget,
                metric,
                useTitle: !this.useSameAxis && showTitle,
                color: color(),
                isRightAxis: idx === 1,
                metricsConfig: this.metricsConfig,
            })
        })

    getSeries = (): any[] => {
        const chartType = getChartType(this.widget)
        if (this.hasOneGrouping) {
            // Only time aggregate grouping selected so it's possible
            // to have 2 metrics selected (left/right Y-axis). Map through
            // selected metrics and build a series config object for each.
            const currentSeries = this.widgetMetrics.flatMap((metric, idx) =>
                generateMetricSeries({
                    widget: this.widget,
                    widgetData: this.widgetData,
                    widgetPriorPeriodData: this.widgetPriorPeriodData,
                    groupedBy: this.widgetGroupBy[0],
                    metric,
                    chartType,
                    color:
                        chartType === SPLINE_AND_COLUMN
                            ? Y_AXIS_COLORS_BOTH[idx]
                            : Y_AXIS_COLORS[idx],
                    yAxisIdx: this.useSameAxis ? 0 : idx,
                    seriesIdx: idx,
                    priorPeriodConfig: this.priorPeriodConfig,
                    priorPeriodColor:
                        chartType === COLUMN
                            ? Y_AXIS_PRIOR_PERIOD_COLUMN_COLORS[idx]
                            : Y_AXIS_COLORS[idx],
                    priorPeriodBorderColor: Y_AXIS_COLORS[idx],
                    metricsConfig: this.metricsConfig,
                })
            )
            return currentSeries
        }

        if (this.widgetGroupBy.length === 2) {
            return generateGroupedSeries(
                this.widgetGroupBy,
                this.widgetMetrics[0],
                this.widget.data_source,
                this.widgetData,
                this.automationCapabilitiesMap,
                this.brandGroupsMap,
                chartType,
                this.metricsConfig
            )
        }

        return []
    }

    // eslint-disable-next-line class-methods-use-this
    getLegendOptions = (legend: any): any => legend
}
