import { Switch } from 'antd'

import { ConditionalWrapper } from 'components/ConditionalWrapper'
import { ToolTip } from 'components/ToolTip'

import { FormikWithCustomHandlersPropTypes } from '../propTypes'
import withCustomHandlers from '../withCustomHandlers'

const FormikSwitch = ({
    field: { value },
    form,
    handleChange,
    handlePostChange,
    handleBlur,
    handleClick,
    tooltipPlacement = 'bottom',
    tooltipTitle,
    ...props
}) => (
    <ConditionalWrapper
        condition={Boolean(tooltipTitle)}
        wrapper={(children) => (
            <ToolTip
                title={tooltipTitle}
                arrow={{ pointAtCenter: true }}
                placement={tooltipPlacement}
            >
                <span
                    style={{
                        display: 'inline',
                        width: 'fit-content',
                    }}
                >
                    {children}
                </span>
            </ToolTip>
        )}
    >
        <Switch
            checked={value}
            onChange={handleChange}
            onBlur={handleBlur}
            {...props}
        />
    </ConditionalWrapper>
)

FormikSwitch.propTypes = FormikWithCustomHandlersPropTypes

export default withCustomHandlers(FormikSwitch)
