import { ReactElement } from 'react'

import { Select } from 'antd'
import classNames from 'classnames'
import every from 'lodash/every'

import { NEGATIVE_MATCH_TYPE_OPTIONS } from 'const/matchTypes'

import { KeywordField } from './localTypes'
import styles from './styles.scss'

interface Props {
    keywords: KeywordField[]
    setFieldValue: (name: string, values: any) => void
}

const CreateCampaignNegativeKeywordFormAllRow = ({
    keywords = [],
    setFieldValue,
}: Props): ReactElement => {
    const matchTypeValue = every(keywords, ['matchType', keywords[0].matchType])
        ? keywords[0].matchType
        : undefined

    const handleChange = (val: string | number, field: string): void => {
        keywords.forEach((_keyword, index) => {
            setFieldValue(`keywords.${index}.${field}`, val)
        })
    }

    return (
        <div
            className={classNames(
                'd-flex align-items-start',
                styles['bulk-edit-row']
            )}
        >
            <div style={{ flex: 1 }} className="mr-1 flex-1" />
            <div
                className={classNames(
                    'd-flex flex-column align-items-center mr-1',
                    styles.field,
                    styles['match-type-column']
                )}
            >
                <Select
                    placeholder="Select Match Type"
                    value={matchTypeValue}
                    onChange={(val) => {
                        handleChange(val, 'matchType')
                    }}
                >
                    {NEGATIVE_MATCH_TYPE_OPTIONS.map(({ value, label }) => (
                        <Select.Option key={value} value={value}>
                            {label}
                        </Select.Option>
                    ))}
                </Select>
            </div>

            <div
                className={classNames(
                    'd-flex flex-column align-items-center mr-1',
                    styles.field,
                    styles['remove-column']
                )}
            />
        </div>
    )
}

export default CreateCampaignNegativeKeywordFormAllRow
