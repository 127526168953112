import { memo, ReactElement } from 'react'

import isEqual from 'lodash/isEqual'
import { useTranslation } from 'react-i18next'

import { ORGANIZATION_PAGE } from 'const/pages'
import { userHasOrgAdminPermissions } from 'helpers/featurePermissions'
import { useUserHasPermissions, useResourceOfPage } from 'hooks'
import { removeMemberFromOrganization } from 'services/cerebroApi/orgScope/resourceApi'
import { Organization, User } from 'types'

import { DeleteField } from '../SharedFields/DeleteField'

interface Props<RecordType> {
    readOnly: boolean
    record: RecordType
    reloadData: () => void
}

function RemoveMemberFromOrganizationField<RecordType extends User>({
    readOnly = false,
    record,
    reloadData,
}: Props<RecordType>): ReactElement {
    const hasPermission = useUserHasPermissions(userHasOrgAdminPermissions)

    const organization: Organization | null =
        useResourceOfPage(ORGANIZATION_PAGE)

    const { t } = useTranslation(['common', 'table'])

    return (
        <DeleteField
            disabled={!hasPermission || readOnly}
            okText={t('common:remove', 'Remove')}
            title={t(
                'table:fields.removeFromOrganization.confirmTxt',
                'Are you sure you want to remove this member from {{orgName}}?',
                { orgName: organization?.name }
            )}
            updateRequestApi={removeMemberFromOrganization}
            updateRequestCallback={reloadData}
            updateRequestData={organization?.id}
            updateRequestParams={record.id}
            updateRequestSuccessMesg={t(
                'table:fields.removeFromOrganization.successMsg',
                'Member successfully removed'
            )}
        />
    )
}

export default memo(
    RemoveMemberFromOrganizationField,
    isEqual
) as typeof RemoveMemberFromOrganizationField
