import { all, call, select } from 'redux-saga/effects'

import {
    makeFetchResourceSuccess,
    makeUpdateResourceSuccess,
} from 'actions/ui/shared/resource'
import { PORTFOLIO_PAGE } from 'const/pages'
import { cerebroApiSaga } from 'sagas/common'
import uiSagaRegistry from 'sagas/ui/registry'
import { selectResourceParamsOfPage } from 'selectors/ui'
import {
    getPortfolio,
    updatePortfolio,
} from 'services/cerebroApi/orgScope/resourceApi'

function* fetchPortfolioSaga() {
    const { portfolioId } = yield select(
        selectResourceParamsOfPage,
        PORTFOLIO_PAGE
    )

    yield call(
        cerebroApiSaga,
        makeFetchResourceSuccess(PORTFOLIO_PAGE),
        getPortfolio,
        portfolioId
    )
}

function* updatePortfolioSaga({ portfolioId }, data) {
    yield call(
        cerebroApiSaga,
        makeUpdateResourceSuccess(PORTFOLIO_PAGE),
        updatePortfolio,
        portfolioId,
        data
    )
}

/**
 * Mounts the Portfolio Page and fetches data
 */
function* mountPageSaga() {
    yield all([
        // Load portfolio
        call(fetchPortfolioSaga),
    ])
}

uiSagaRegistry.registerSagas([PORTFOLIO_PAGE], {
    mountPageSaga,
    updateResourceSaga: updatePortfolioSaga,
    addLabelsSaga: updatePortfolioSaga,
    removeLabelSaga: updatePortfolioSaga,
})
