import { ReactNode } from 'react'

import {
    DeleteLabelField,
    LabelDescriptionField,
    LabelColorField,
} from 'components/EditableFields'
import { ResourceLink } from 'components/Links/ResourceLink'
import { UNDEFINED_VALUE } from 'const/formatting'
import { labelUrl } from 'helpers/urls'
import { isUnset } from 'helpers/utilities'
import { Label, CellRenderProps } from 'types'

type RenderPropsWithDataIndex<RecordType> = {
    cellRenderProps: CellRenderProps<RecordType>
    dataIndex: string[]
}

export function labelNameRenderer<RecordType extends Label>({
    value,
    record,
    undefinedValue = UNDEFINED_VALUE,
}: CellRenderProps<RecordType>): ReactNode {
    return isUnset(value) ? (
        undefinedValue
    ) : (
        <ResourceLink to={labelUrl(record.id)}>{value}</ResourceLink>
    )
}

export function labelDescriptionRenderer<RecordType extends Label>({
    cellRenderProps: { readonly, record },
    dataIndex,
}: RenderPropsWithDataIndex<RecordType>): ReactNode {
    return (
        <LabelDescriptionField
            dataIndex={dataIndex}
            record={record}
            readOnly={readonly}
        />
    )
}

export function labelColorRenderer<RecordType extends Label>({
    cellRenderProps: { readonly, record },
    dataIndex,
}: RenderPropsWithDataIndex<RecordType>): ReactNode {
    return (
        <LabelColorField
            dataIndex={dataIndex}
            record={record}
            readOnly={readonly}
        />
    )
}

export function deleteLabelRenderer<RecordType extends Label>({
    readonly,
    record,
    reloadData,
}: CellRenderProps<RecordType>): ReactNode {
    return (
        <DeleteLabelField
            record={record}
            readOnly={readonly}
            reloadData={reloadData}
        />
    )
}
