import { SEARCH_RESULTS_PER_QUERY } from 'configuration/typeahead'
import { useTypeahead } from 'hooks'
import { getCampaigns } from 'services/cerebroApi/orgScope/resourceApi'
import { Campaign, TypeaheadOption } from 'types'

import { CampaignSearchSelectProps } from '../localTypes'

type CampaignOptions = [
    TypeaheadOption[],
    boolean,
    (value: any) => void,
    () => void,
]

type Arguments = Pick<
    CampaignSearchSelectProps,
    'profileId' | 'isOptionDisabled' | 'optionMetadata' | 'defaultOptions'
>

const useCampaignOptions = ({
    profileId,
    isOptionDisabled = () => false,
    optionMetadata = () => '',
    defaultOptions = [],
}: Arguments): CampaignOptions => {
    const [options, loading, onSearch, resetLoadingState] =
        useTypeahead<Campaign>({
            apiSearchFunc: (query) =>
                getCampaigns(
                    {
                        limit: SEARCH_RESULTS_PER_QUERY,
                        name__similar: query,
                        profile__id: profileId,
                        state__in: 'enabled,paused',
                    },
                    { headers: { noCount: true } }
                ),
            optionFormatter: (campaign) => ({
                value: campaign.id,
                label: campaign.name,
                metadata: optionMetadata(campaign),
                disabled: isOptionDisabled(campaign),
            }),
            defaultOptions,
        })

    return [options, loading, onSearch, resetLoadingState]
}

export default useCampaignOptions
