import { YAxisOptions, XAxisOptions, LegendOptions } from 'highcharts'

import { HC_DATE_FORMAT } from 'helpers/formatting'

interface Options {
    titleText: string | undefined
    color: string
    valueFormatter: (value: number | string) => string
    endOnTick?: boolean
    isRightAxis?: boolean
    isReversed?: boolean
    verticalTitle?: boolean
    reserveTitleSpace?: boolean
}

export const getYAxisOptions = ({
    titleText,
    color,
    valueFormatter,
    endOnTick = true,
    isRightAxis = false,
    isReversed = false,
    verticalTitle = false,
    reserveTitleSpace = false,
}: Options): YAxisOptions => {
    const titleRotation = isRightAxis ? 90 : 270
    const horizontalTextAlign = isRightAxis ? 'right' : 'left'
    return {
        gridLineDashStyle: 'Dash',
        gridLineColor: '#e7e7e7',
        endOnTick,
        title: {
            y: verticalTitle ? 0 : -30,
            x: isRightAxis ? 0 : 5,
            align: verticalTitle ? 'middle' : 'high',
            text: titleText,
            style: {
                whiteSpace: !verticalTitle ? 'nowrap' : 'auto',
                color,
            },
            rotation: verticalTitle ? titleRotation : 0,
            reserveSpace: !!titleText && (verticalTitle || reserveTitleSpace),
            textAlign: verticalTitle ? 'center' : horizontalTextAlign,
        },
        labels: {
            useHTML: true,
            formatter() {
                return `<span style="color: ${color};">${valueFormatter(
                    this.value
                )}</span>`
            },
        },
        ...(isRightAxis && { opposite: true }),
        ...(isReversed && {
            reversed: true,
            showFirstLabel: false,
            showLastLabel: true,
        }),
        showEmpty: false,
    }
}

export const DEFAULT_X_AXIS: XAxisOptions = {
    minorTickLength: 0,
    tickLength: 0,
    labels: {
        style: {
            color: '#888',
        },
    },
    lineColor: '#c1c2c5',
    crosshair: {
        width: 1,
        color: '#c1c2c5',
    },
}

export const DEFAULT_TIME_SERIES_X_AXIS: XAxisOptions = {
    ...DEFAULT_X_AXIS,
    type: 'datetime',
    dateTimeLabelFormats: HC_DATE_FORMAT,
    startOfWeek: 0,
}

export const DEFAULT_LEGEND: LegendOptions = {
    align: 'center',
    verticalAlign: 'bottom',
    symbolPadding: 0,
    symbolHeight: 0,
    symbolRadius: 0,
    symbolWidth: 0,
    useHTML: true,
    maxHeight: 200,
}

export const DEFAULT_HORIZONTAL_LEGEND: LegendOptions = {
    ...DEFAULT_LEGEND,
    itemStyle: {
        width: 200,
    },
    itemMarginBottom: 4,
    itemMarginTop: 4,
    itemDistance: 10,
}
