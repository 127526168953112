import {
    DATES,
    REGIONS,
    COUNTRIES,
    BRANDS,
    CAMPAIGNS,
    CAMPAIGN_STATES,
    CAMPAIGN_TARGETING_TYPES,
    KEYWORD_TEXT,
    KEYWORD_TEXT_EXACT,
    KEYWORDS,
    KEYWORD_MATCH_TYPES,
    KEYWORD_STATES,
    SEARCH_TERM,
    METRIC_FILTERS,
    PORTFOLIOS,
    LABELS,
    FACT_TYPES,
    AD_GROUPS,
    AD_GROUP_STATES,
    ADVANCED_TEXT,
} from 'const/filters'
import { calculateFromDateWithLag } from 'helpers/dateRange'
import { isDefined, isString, isNonEmptyArray } from 'helpers/typeGuard'
import { formatDateFromFilter } from 'helpers/utils'
import { FiltersState, KeywordFactsParams } from 'types'

import { formatMetricFiltersForParams } from './metrics'
import { formatBooleanFilter } from '../filters'

export const formatFilters = (filters: FiltersState): KeywordFactsParams => {
    let params: KeywordFactsParams = {}

    const dates = filters[DATES]
    const { start, end } = formatDateFromFilter(
        dates,
        calculateFromDateWithLag(filters.rangeLag),
        filters.rangeLag
    )
    params.report_date_min = start
    params.report_date_max = end

    const regions = filters[REGIONS]
    if (isNonEmptyArray(regions)) {
        params.profile__region__in = regions.map((item) => item.value).join()
    }

    const countries = filters[COUNTRIES]
    if (isNonEmptyArray(countries)) {
        params.profile__country_code__in = countries
            .map((item) => item.value)
            .join()
    }

    const brands = filters[BRANDS]
    if (isNonEmptyArray(brands)) {
        params.profile__in = brands.map((item) => item.value).join()
    }

    const portfolios = filters[PORTFOLIOS]
    if (isNonEmptyArray(portfolios)) {
        params.campaign__portfolio__id__in = portfolios
            .map((item) => item.value)
            .join()
    }

    const campaigns = filters[CAMPAIGNS]
    if (isNonEmptyArray(campaigns)) {
        params.campaign__in = campaigns.map((item) => item.value).join()
    }

    const campaignStates = filters[CAMPAIGN_STATES]
    if (isNonEmptyArray(campaignStates)) {
        params.campaign__state__in = campaignStates
            .map((item) => item.value)
            .join()
    }

    const campaignTargetingTypes = filters[CAMPAIGN_TARGETING_TYPES]
    if (isNonEmptyArray(campaignTargetingTypes)) {
        params.campaign__targeting_type__in = campaignTargetingTypes
            .map((item) => item.value)
            .join()
    }

    const keywordTextExact = filters[KEYWORD_TEXT_EXACT]
    if (isDefined(keywordTextExact) && isString(keywordTextExact)) {
        params.keyword__text = keywordTextExact
    }

    const keywordText = filters[KEYWORD_TEXT]
    if (isDefined(keywordText) && isString(keywordText)) {
        params.keyword__text__icontains = keywordText
    }

    const keywords = filters[KEYWORDS]
    if (isNonEmptyArray(keywords)) {
        params.keyword__in = keywords.map((item) => item.value).join()
    }

    const keywordMatchTypes = filters[KEYWORD_MATCH_TYPES]
    if (isNonEmptyArray(keywordMatchTypes)) {
        params.keyword__match_type__in = keywordMatchTypes
            .map((item) => item.value)
            .join()
    }

    const keywordStates = filters[KEYWORD_STATES]
    if (isNonEmptyArray(keywordStates)) {
        params.keyword__state__in = keywordStates
            .map((item) => item.value)
            .join()
    }

    const searchTerm = filters[SEARCH_TERM]
    if (isNonEmptyArray(searchTerm)) {
        const query = searchTerm[0].value
        if (isString(query)) {
            params.query__icontains = query
        }
    }

    const metricFilters = filters[METRIC_FILTERS]
    if (isNonEmptyArray(metricFilters)) {
        params = { ...params, ...formatMetricFiltersForParams(metricFilters) }
    }

    const labels = filters[LABELS]
    if (isNonEmptyArray(labels)) {
        params.complex_label_filter = formatBooleanFilter(labels)
    }

    const factTypes = filters[FACT_TYPES]
    if (isNonEmptyArray(factTypes)) {
        params.campaign__campaign_type__in = factTypes
            .map((item) => item.value)
            .join()
    }

    const adGroups = filters[AD_GROUPS]
    if (isNonEmptyArray(adGroups)) {
        params.ad_group__in = adGroups.map((item) => item.value).join()
    }

    const adGroupStates = filters[AD_GROUP_STATES]
    if (isNonEmptyArray(adGroupStates)) {
        params.ad_group__state__in = adGroupStates
            .map((item) => item.value)
            .join()
    }

    const advancedText = filters[ADVANCED_TEXT]
    if (isNonEmptyArray(advancedText)) {
        params.complex_text_filter = formatBooleanFilter(advancedText)
    }

    return params
}
