import find from 'lodash/find'
import forOwn from 'lodash/forOwn'
import { call, put, select, all } from 'redux-saga/effects'

import {
    fetchTriggeredAlertsSuccess,
    fetchTriggeredAlertsFailure,
    fetchAlertSubscriptionsSuccess,
    fetchAlertSubscriptionsFailure,
} from 'actions/ui/alertPage'
import {
    makeFetchResourceSuccess,
    makeUpdateResourceSuccess,
    makeUpdateResourceFailure,
} from 'actions/ui/shared/resource'
import { ALERT_PAGE } from 'const/pages'
import { formatCerebroDate } from 'helpers/formatting'
import { cerebroApiSaga } from 'sagas/common'
import uiSagaRegistry from 'sagas/ui/registry'
import {
    selectResourceParamsOfPage,
    selectDomainValue as selectUiDomainValue,
} from 'selectors/ui'
import {
    getAlert,
    getTriggeredAlerts,
    updateAlert,
    getAlertSubscriptions,
    deleteAlertSubscription,
    createAlertSubscription,
} from 'services/cerebroApi/orgScope/alertsApi'
import moment from 'utilities/moment'

function* fetchAlertSaga() {
    const { alertId } = yield select(selectResourceParamsOfPage, ALERT_PAGE)

    yield call(
        cerebroApiSaga,
        makeFetchResourceSuccess(ALERT_PAGE),
        getAlert,
        alertId
    )
}

function* fetchSubscriptionsSaga() {
    const { alertId } = yield select(selectResourceParamsOfPage, ALERT_PAGE)

    try {
        yield call(
            cerebroApiSaga,
            fetchAlertSubscriptionsSuccess,
            getAlertSubscriptions,
            {
                alert_configuration_id: alertId,
            }
        )
    } catch (error) {
        yield put(fetchAlertSubscriptionsFailure(error))
    }
}

function* updateAlertSaga({ alertId }, data) {
    const { name, config, subscriptions } = data
    const response = yield call(cerebroApiSaga, null, updateAlert, alertId, {
        name,
        config,
    })

    if (response) {
        const currentSubs = yield select(selectUiDomainValue, [
            ALERT_PAGE,
            'subscriptions',
        ])
        const createRequests = []
        const deleteRequests = []
        forOwn(subscriptions, (isEnabled, style) => {
            const foundSub = find(currentSubs, (sub) => sub.style === style)
            if (isEnabled) {
                if (!foundSub) {
                    createRequests.push(
                        call(cerebroApiSaga, null, createAlertSubscription, {
                            style,
                            alert_configuration_id: alertId,
                        })
                    )
                }
            } else if (foundSub) {
                deleteRequests.push(
                    call(
                        cerebroApiSaga,
                        null,
                        deleteAlertSubscription,
                        foundSub.id
                    )
                )
            }
        })
        yield all(deleteRequests)
        yield all(createRequests)
        yield call(fetchSubscriptionsSaga)
        yield put(makeUpdateResourceSuccess(ALERT_PAGE)(response.data))
    } else {
        yield put(
            makeUpdateResourceFailure(ALERT_PAGE)(
                'Failed to update alert configuration'
            )
        )
    }
}

function* fetchTriggeredAlertsSaga() {
    const { alertId } = yield select(selectResourceParamsOfPage, ALERT_PAGE)

    try {
        yield call(
            cerebroApiSaga,
            fetchTriggeredAlertsSuccess,
            getTriggeredAlerts,
            {
                ordering: '-triggered_at',
                alert_configuration_id: alertId,
                triggered_at_min: formatCerebroDate(
                    moment().subtract(2, 'weeks')
                ),
            }
        )
    } catch (error) {
        yield put(fetchTriggeredAlertsFailure(error))
    }
}

/**
 * Mounts the Alert Page and fetches data
 */
function* fetchPageDataSaga() {
    yield all([call(fetchTriggeredAlertsSaga), call(fetchSubscriptionsSaga)])
}

/**
 * Mounts the Alert Page and fetches data
 */
function* mountPageSaga() {
    yield call(fetchAlertSaga)
    yield call(fetchPageDataSaga)
}

uiSagaRegistry.registerSagas([ALERT_PAGE], {
    mountPageSaga,
    fetchPageDataSaga,
    updateResourceSaga: updateAlertSaga,
})
