import { useCallback, useState } from 'react'

type UseLocalStorageReturn<T> = [T, (value: T) => void]

const useLocalStorage = <T>(
    key: string,
    defaultValue: T
): UseLocalStorageReturn<T> => {
    const storedItem = localStorage.getItem(key)
    const [localItem, setLocalItem] = useState(
        storedItem ? (JSON.parse(storedItem) as T) : defaultValue
    )

    const setItem = useCallback(
        (value: T): void => {
            localStorage.setItem(key, JSON.stringify(value))
            setLocalItem(value)
        },
        [key]
    )

    return [localItem, setItem]
}

export default useLocalStorage
