import { ReactElement } from 'react'

import { Link } from 'react-router-dom'

import { campaignUrl } from 'helpers/urls'

import { SelectedSearchTerm } from '../localTypes'
import styles from '../styles.scss'

interface Props {
    selectedSearchTerm: SelectedSearchTerm
}

const CampaignLabel = ({
    selectedSearchTerm: {
        record: { campaign },
    },
}: Props): ReactElement => (
    <div className="d-flex flex-column">
        <div className={styles.label}>Source Campaign</div>
        <div className={styles.value}>
            <Link to={campaignUrl(campaign.id)}>{campaign.name}</Link>
        </div>
    </div>
)

export default CampaignLabel
