import { PENDING_CHANGE_SET_STATES } from 'const/rulebook'
import { RulebookChangeState, ChangeSet } from 'types'

export const isChangeSetPending = (changeSet: ChangeSet): boolean =>
    PENDING_CHANGE_SET_STATES.findIndex((state) => state === changeSet.state) >
    -1

export const isSomeChangeSetsPending = (changeSets: ChangeSet[]): boolean =>
    changeSets.some((update) => isChangeSetPending(update))

// FIXME: This is a temporary fix for the fact that the manually_triggered is null for some records
export const wasChangeSetManuallyTriggered = (record: ChangeSet): boolean =>
    record.manually_triggered !== undefined
        ? record.manually_triggered
        : record.created_by !== 'operations+automation@downstreamimpact.com'

export const isChangeSetDryRun = (update: ChangeSet): boolean =>
    update.state === RulebookChangeState.DRY_RUN ||
    wasChangeSetManuallyTriggered(update) ||
    update.rulebook?.action_policy === 'dry_run'
