import { SVGAttributes, ReactElement } from 'react'

type Props = SVGAttributes<SVGElement>

function SvgUploadIcon(props: Props): ReactElement {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M10 1.70001C10.2122 1.70001 10.4157 1.7843 10.5657 1.93433L14.7324 6.10099C15.0448 6.41341 15.0448 6.91994 14.7324 7.23236C14.4199 7.54478 13.9134 7.54478 13.601 7.23236L10.8 4.43138V12.5C10.8 12.9418 10.4418 13.3 10 13.3C9.55819 13.3 9.20001 12.9418 9.20001 12.5V4.43138L6.39903 7.23236C6.08661 7.54478 5.58008 7.54478 5.26766 7.23236C4.95524 6.91994 4.95524 6.41341 5.26766 6.10099L9.43433 1.93433C9.58436 1.7843 9.78784 1.70001 10 1.70001ZM2.50001 11.7C2.94184 11.7 3.30001 12.0582 3.30001 12.5V13.5C3.30001 14.2133 3.30064 14.7001 3.33142 15.0769C3.36143 15.4443 3.4161 15.636 3.4853 15.7718C3.64829 16.0917 3.90835 16.3517 4.22823 16.5147C4.36404 16.5839 4.55576 16.6386 4.92316 16.6686C5.29991 16.6994 5.78675 16.7 6.50001 16.7H13.5C14.2133 16.7 14.7001 16.6994 15.0769 16.6686C15.4443 16.6386 15.636 16.5839 15.7718 16.5147C16.0917 16.3517 16.3517 16.0917 16.5147 15.7718C16.5839 15.636 16.6386 15.4443 16.6686 15.0769C16.6994 14.7001 16.7 14.2133 16.7 13.5V12.5C16.7 12.0582 17.0582 11.7 17.5 11.7C17.9418 11.7 18.3 12.0582 18.3 12.5V13.5332C18.3 14.2051 18.3 14.7575 18.2633 15.2072C18.2252 15.6735 18.1436 16.0992 17.9403 16.4982C17.624 17.1191 17.1191 17.624 16.4982 17.9403C16.0992 18.1436 15.6735 18.2252 15.2072 18.2633C14.7575 18.3 14.2051 18.3 13.5332 18.3H6.4668C5.79497 18.3 5.2425 18.3 4.79287 18.2633C4.32652 18.2252 3.90081 18.1436 3.50184 17.9403C2.88091 17.624 2.37607 17.1191 2.05969 16.4982C1.85641 16.0992 1.77483 15.6735 1.73673 15.2072C1.69999 14.7575 1.7 14.2051 1.70001 13.5332L1.70001 12.5C1.70001 12.0582 2.05819 11.7 2.50001 11.7Z" />
        </svg>
    )
}

export default SvgUploadIcon
