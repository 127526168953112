import { handleActions } from 'redux-actions'

import { portfoliosSummaryTableColumnsConfig } from 'configuration/tables'
import {
    DATES,
    COUNTRIES,
    REGIONS,
    BRANDS,
    FACT_TYPES,
    METRIC_FILTERS,
    LABELS,
} from 'const/filters'
import { FILTERS, FILTER_SETTINGS } from 'const/reducerKeys'
import { serializeColumns } from 'helpers/tables'

import { defaultPage, getDefaultTable } from '../defaults'

const defaultState = {
    ...defaultPage,

    [FILTERS]: {
        [FACT_TYPES]: [],
        [METRIC_FILTERS]: [],
        [REGIONS]: [],
        [COUNTRIES]: [],
        [BRANDS]: [],
        [LABELS]: [],
    },
    [FILTER_SETTINGS]: {
        anchored: [DATES, FACT_TYPES],
        order: [METRIC_FILTERS, REGIONS, COUNTRIES, BRANDS, LABELS],
        displayState: {
            [METRIC_FILTERS]: true,
            [REGIONS]: true,
            [COUNTRIES]: true,
            [BRANDS]: true,
            [LABELS]: true,
        },
    },

    table: getDefaultTable(
        serializeColumns(portfoliosSummaryTableColumnsConfig),
        { showTotalRow: true }
    ),
}

export default handleActions({}, defaultState)
