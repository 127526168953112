import isNil from 'lodash/isNil'

import {
    removeSovKeywordFromLabelRenderer,
    sovKeywordIdRenderer,
    sovKeywordLabelIdRenderer,
    sovKeywordStateRenderer,
} from 'components/FieldRenderers/Renderers'
import { Flag } from 'components/Flag'
import { COUNTRY_LABELS, LANGUAGE_LABELS } from 'const/codes'
import { SOV_KEYWORD_TEXT_COLUMN_ID } from 'dynamicModules/KeywordResearch/const'
import { formatDayofWeek } from 'helpers/formatting'
import { Field } from 'types'

import {
    createActionField,
    createCerebroDateTimeField,
    createDateField,
    createField,
    createLabelLookupField,
    createTitleField,
} from './createField'
import { FieldCreatorOptions } from './localTypes'

export function sovKeywordId<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['keyword', 'id']
    return createField({
        ...options,
        id: 'keyword_id',
        name: 'Keyword',
        shortName: 'Keyword',
        minWidth: 80,
        dataIndex: options.dataIndex ?? dataIndex,
        renderOptions: options.renderOptions ?? {
            render: (props) => sovKeywordIdRenderer<any>(props),
        },
        width: 100,
    })
}

export function sovKeywordText<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createField({
        ...options,
        id: SOV_KEYWORD_TEXT_COLUMN_ID,
        name: options.name ?? 'Keyword Text',
        shortName: options.shortName ?? options.name ?? 'Text',
        minWidth: options.minWidth ?? 60,
        dataIndex: options.dataIndex ?? ['keyword__text'],
        ...(options.renderOptions
            ? { renderOptions: options.renderOptions }
            : {}),
        width: options.width ?? 100,
    })
}

export function sovKeywordCategory<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createField({
        ...options,
        id: 'category',
        name: options.name ?? 'Dominant Category',
        shortName: options.shortName ?? options.name ?? 'Dominant Category',
        minWidth: options.minWidth ?? 150,
        dataIndex: options.dataIndex ?? ['category'],
        ...(options.renderOptions
            ? { renderOptions: options.renderOptions }
            : {}),
        width: options.width ?? 150,
    })
}

export function sovKeywordPlatform<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['keyword__platform']
    return createTitleField({
        ...options,
        id: 'keyword__platform',
        name: 'Keyword Platform',
        shortName: 'Platform',
        minWidth: 80,
        dataIndex,
        width: 100,
    })
}

export function sovKeywordState<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['keyword__state']
    return createField({
        ...options,
        id: 'keyword__state',
        name: 'Keyword Status',
        shortName: 'Status',
        minWidth: 60,
        dataIndex,
        width: 100,
        renderOptions: options.renderOptions ?? {
            render: (cellRenderProps) =>
                sovKeywordStateRenderer<any>({ cellRenderProps, dataIndex }),
        },
    })
}

export function sovKeywordCreatedAt<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createCerebroDateTimeField({
        ...options,
        id: 'keyword__created_at',
        name: 'Keyword Created Date',
        shortName: 'Created',
        minWidth: 80,
        dataIndex: options.dataIndex ?? ['created_at'],
        width: 200,
    })
}

export function sovKeywordCountryCode<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createField({
        ...options,
        id: 'keyword__country_code',
        name: 'Keyword Country',
        shortName: 'Country',
        minWidth: 80,
        dataIndex: options.dataIndex ?? ['keyword__country_code'],
        width: 150,
        renderOptions: {
            render: ({ value }) => (
                <span className="fg-flag-container">
                    <Flag country={value} />
                    {COUNTRY_LABELS[value]}
                </span>
            ),
        },
    })
}

export function sovKeywordLanguageCode<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createLabelLookupField({
        ...options,
        id: 'keyword__language_code',
        name: 'Keyword Language',
        shortName: 'Language',
        minWidth: 80,
        dataIndex: options.dataIndex ?? ['keyword__language_code'],
        width: 100,
        labels: LANGUAGE_LABELS,
    })
}

export function sovKeywordLabelId<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createField({
        ...options,
        id: 'keyword__label_id',
        name: 'Keyword Label',
        shortName: 'Label',
        minWidth: 50,
        dataIndex: options.dataIndex ?? ['keyword__label_id'],
        width: 100,
        sorter: options.sorter ?? false,
        renderOptions: {
            render: (props) => sovKeywordLabelIdRenderer<any>(props),
        },
    })
}

export function sovAsin<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createField({
        ...options,
        id: 'asin',
        name: 'ASIN',
        shortName: 'ASIN',
        minWidth: 50,
        dataIndex: options.dataIndex ?? ['asin'],
        width: 100,
    })
}

export function resultType<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createTitleField({
        ...options,
        id: 'result_type',
        name: 'Search Result Type',
        shortName: 'Search Result Type',
        minWidth: 50,
        dataIndex: options.dataIndex ?? ['result_type'],
        width: 150,
    })
}

export function amazonChoice<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createField({
        ...options,
        id: 'amazon_choice',
        name: 'Amazon Choice',
        shortName: 'Amazon Choice',
        minWidth: 50,
        renderOptions: {
            render: ({ value }) => {
                return value ? 'Amazon Choice' : 'Not Amazon Choice'
            },
        },
        dataIndex: options.dataIndex ?? ['amazon_choice'],
        width: 100,
    })
}

export function bestSeller<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createField({
        ...options,
        id: 'best_seller',
        name: 'Best Seller',
        shortName: 'Best Seller',
        minWidth: 50,
        dataIndex: options.dataIndex ?? ['best_seller'],
        renderOptions: {
            render: ({ value }) => {
                return value ? 'Best Seller' : 'Not Best Seller'
            },
        },
        width: 100,
    })
}

export function rank<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createField({
        ...options,
        id: 'rank',
        name: 'Overall Result Rank',
        shortName: 'Overall Result Rank',
        minWidth: 30,
        dataIndex: options.dataIndex ?? ['rank'],
        width: 150,
    })
}

export function layout<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createField({
        ...options,
        id: 'layout',
        name: 'Layout',
        shortName: 'Layout',
        minWidth: 30,
        dataIndex: options.dataIndex ?? ['layout'],
        renderOptions: {
            render: ({ value }) => {
                if (isNil(value)) {
                    return '-'
                }

                const column = value.slice(value.indexOf('_') + 1, value.length)
                if (column === '1') {
                    return 'List'
                }

                return `Grid with ${column} Columns`
            },
        },
        width: 100,
    })
}

export function searchTimeWeekDay<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createField({
        ...options,
        id: 'search_time_week_day',
        name: 'Search Time Day of Week',
        shortName: 'Search Time Day of Week',
        minWidth: 50,
        dataIndex: options.dataIndex ?? ['search_time_week_day'],
        renderOptions: {
            render: ({ value }) => formatDayofWeek(value),
        },
        width: 100,
    })
}

export function searchTimeYear<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createDateField({
        ...options,
        id: 'search_time_year',
        name: 'Search Year',
        shortName: 'Search Year',
        minWidth: 50,
        dataIndex: options.dataIndex ?? ['search_time_year'],
        width: 100,
        dateAggregation: 'year',
    })
}

export function searchTimeMonth<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createDateField({
        ...options,
        id: 'search_time_month',
        name: 'Search Month',
        shortName: 'Search Month',
        minWidth: 50,
        dataIndex: options.dataIndex ?? ['search_time_month'],
        dateAggregation: 'month',
        width: 100,
    })
}

export function searchTimeWeek<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createDateField({
        ...options,
        id: 'search_time_week',
        name: 'Search Week',
        shortName: 'Search Week',
        minWidth: 50,
        dataIndex: options.dataIndex ?? ['search_time_week'],
        dateAggregation: 'week',
        width: 100,
    })
}

export function searchTimeDay<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createDateField({
        ...options,
        id: 'search_time_day',
        name: 'Search Day',
        shortName: 'Search Day',
        minWidth: 50,
        dataIndex: options.dataIndex ?? ['search_time_day'],
        dateAggregation: 'day',
        width: 100,
    })
}

export function removeSovKeywordFromLabel<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['keyword', 'id']
    return createActionField({
        ...options,
        id: 'remove_sov_keyword_from_label',
        dataIndex: [],
        isResizeable: false,
        minWidth: 68,
        renderOptions: options.renderOptions ?? {
            render: (cellRenderProps) =>
                removeSovKeywordFromLabelRenderer<any>({
                    cellRenderProps,
                    dataIndex,
                }),
        },
        sorter: false,
        fixed: 'right',
        align: 'center',
    })
}
