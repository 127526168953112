import { useState, ReactElement } from 'react'

import { LoadingOutlined } from '@ant-design/icons'
import noop from 'lodash/noop'
import { useTranslation } from 'react-i18next'

import { TextButton } from 'components/TextButton'
import { useCerebroApiRequest } from 'hooks'
import { resendInvite as resendInviteRequest } from 'services/cerebroApi/orgScope/resourceApi'
import message from 'utilities/message'

interface Props {
    inviteId: string
    reloadData?: () => void
}

function ResendInviteLink({
    inviteId,
    reloadData = noop,
}: Props): ReactElement {
    const makeCerebroApiRequest = useCerebroApiRequest()
    const [resending, setResending] = useState(false)
    const { t } = useTranslation('account')

    async function resendInvite(): Promise<void> {
        setResending(true)

        await makeCerebroApiRequest({
            request: resendInviteRequest(inviteId),
            onRequestSuccess: () => {
                message.success(
                    t(
                        'account:ResendInviteLink.successMsg',
                        'Invitation successfully resent!'
                    )
                )
                reloadData()
            },
        })

        setResending(false)
    }

    if (resending) {
        return <LoadingOutlined />
    }

    return (
        <TextButton onClick={() => resendInvite()} link>
            {t('account:ResendInviteLink.submitBtnTxt', 'Resend')}
        </TextButton>
    )
}

export default ResendInviteLink
