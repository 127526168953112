import { useMemo } from 'react'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { CustomerModuleKey } from 'const/customerModules'
import {
    userHasAmazonAdvertisingPermissions,
    userHasCustomerServicePermissions,
    userHasReadOrManageSegmentPermissions,
    userHasSovReadPermissions,
} from 'helpers/featurePermissions'
import { selectUserOrganizationFeaturePermissions } from 'selectors/auth'
import { selectCurrentOrganization } from 'selectors/orgs'
import { Organization, RootReduxState, UserPermission } from 'types'

export interface CustomerModuleInfo {
    key: CustomerModuleKey
    name: string
    user_has_access: boolean
}

export function useCustomerModules(): Record<
    CustomerModuleKey,
    CustomerModuleInfo
> {
    const { t } = useTranslation()
    const currentOrg = useSelector<RootReduxState, Organization | undefined>(
        (state) => selectCurrentOrganization(state)
    )
    const featurePermissions = useSelector<RootReduxState, UserPermission[]>(
        (state) => selectUserOrganizationFeaturePermissions(state)
    )
    const customerModuleInfo: Record<CustomerModuleKey, CustomerModuleInfo> =
        useMemo(
            () => ({
                internal_only: {
                    key: 'internal_only',
                    name: t(
                        'common:customerModules.internal_only',
                        'Internal Only'
                    ),
                    user_has_access:
                        userHasCustomerServicePermissions(featurePermissions),
                },
                retail_insights: {
                    key: 'retail_insights',
                    name: t(
                        'common:customerModules.retail_insights',
                        'Retail Insights'
                    ),
                    user_has_access: true,
                },
                advertising_intelligence: {
                    key: 'advertising_intelligence',
                    name: t(
                        'common:customerModules.advertising_intelligence',
                        'Ad Accelerator'
                    ),
                    user_has_access:
                        userHasAmazonAdvertisingPermissions(featurePermissions),
                },
                market_intelligence: {
                    key: 'market_intelligence',
                    name: t(
                        'common:customerModules.market_intelligence',
                        'Market Intelligence'
                    ),
                    user_has_access:
                        userHasReadOrManageSegmentPermissions(
                            featurePermissions
                        ),
                },
                shelf_intelligence: {
                    key: 'shelf_intelligence',
                    name: t(
                        'common:customerModules.shelf_intelligence',
                        'Digital Shelf Analytics'
                    ),
                    user_has_access:
                        userHasSovReadPermissions(featurePermissions),
                },
            }),
            [currentOrg, featurePermissions, t]
        )

    return customerModuleInfo
}
