import { SVGAttributes, ReactElement } from 'react'

type Props = SVGAttributes<SVGElement>

function SvgCreateIcon(props: Props): ReactElement {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.114 6.897l1.912-1.92a.347.347 0 01.515.033c.362.494.481 1.035.456 1.457-.03.515-.222 1.52-1.26 2.559-.733.732-1.934 1.26-3.075 1.26-.486 0-.958-.097-1.38-.315a.803.803 0 00-.366-.102c-.145 0-.303.051-.473.217-.375.37-6.162 6.657-6.162 6.657a1.639 1.639 0 01-1.26.558c-.482 0-.972-.188-1.334-.545-.706-.712-.762-1.934.005-2.594 0 0 6.328-5.851 6.652-6.167.315-.315.208-.613.115-.838-.792-1.87.102-3.548.958-4.45.881-.937 2.023-1.235 2.555-1.265.025-.004.055-.004.085-.004.413 0 .924.149 1.354.455.17.12.192.366.043.515l-1.908 1.917a.173.173 0 00-.047.149l.354 1.976a.173.173 0 00.136.136l1.976.358a.177.177 0 00.149-.047zM3.31 16.128a1.048 1.048 0 101.481-1.482 1.048 1.048 0 00-1.481 1.482z"
            />
        </svg>
    )
}

export default SvgCreateIcon
