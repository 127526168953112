import { useState, useEffect } from 'react'

import noop from 'lodash/noop'

import usePrevious from './usePrevious'

/**
 * Custom hook for abstracting "justSaved" state
 *
 * @param {Boolean} saving
 * @param {Function} cb
 * @param {Number} delay
 */
function useJustSaved(saving: boolean, cb = noop, delay = 1000): boolean {
    const [justSaved, setJustSaved] = useState(false)
    const prevSaving = usePrevious(saving)

    useEffect(() => {
        if (prevSaving && !saving) {
            setJustSaved(true)
            setTimeout(() => setJustSaved(false), delay)
            cb()
        }
    }, [cb, delay, prevSaving, saving])

    return justSaved
}

export default useJustSaved
