import { memo, ReactElement } from 'react'

import isEqual from 'lodash/isEqual'
import { useTranslation } from 'react-i18next'

import {
    WALMART_AD_ITEM_BID_MAX,
    WALMART_AD_ITEM_BID_MIN,
    WALMART_AD_ITEM_BID_PRECISION,
    WALMART_AD_ITEM_BID_STEP,
    MANUAL_TARGETING,
} from 'const/walmart'
import { userHasWalmartAdvertisingWritePermissions } from 'helpers/featurePermissions'
import { useUserHasPermissions } from 'hooks'
import { updateWalmartAdItem } from 'services/cerebroApi/orgScope/walmartApi'
import { WalmartAdItem, WalmartCampaign } from 'types'

import { BidField } from '../../SharedFields'

interface Props {
    adItem: WalmartAdItem
    campaign: WalmartCampaign
}

function WalmartAdItemBidField({ adItem, campaign }: Props): ReactElement {
    const { t } = useTranslation('table')
    const hasWalmartPermissions = useUserHasPermissions(
        userHasWalmartAdvertisingWritePermissions
    )

    const isManualCampaign = campaign.targeting_type === MANUAL_TARGETING

    return (
        <BidField<WalmartAdItem>
            bidMax={WALMART_AD_ITEM_BID_MAX}
            bidMin={WALMART_AD_ITEM_BID_MIN}
            bidPrecision={WALMART_AD_ITEM_BID_PRECISION}
            bidStep={WALMART_AD_ITEM_BID_STEP}
            currencyCode="USD"
            fieldName="bid"
            fieldPath={['bid']}
            readOnly={
                !adItem.user_can_manage ||
                !hasWalmartPermissions ||
                isManualCampaign
            }
            record={adItem}
            updateRequestApi={updateWalmartAdItem}
            updateRequestData={adItem.id}
            updateRequestSuccessMesg={t(
                'table:fields.walmartAdItem.update.bidUpdateSuccessMsg',
                'Successfully updated ad item bid'
            )}
        />
    )
}

export default memo(
    WalmartAdItemBidField,
    isEqual
) as typeof WalmartAdItemBidField
