import moment from 'moment'

import useLocalStorage from 'hooks/useLocalStorage'

interface PersistedDismissValue {
    dismiss: () => void
    isDismissed: boolean
}

const usePersistedDismiss = (
    storageKey: string,
    numberOfDaysToHideAfterDismiss?: number
): PersistedDismissValue => {
    const [persistedDismiss, setPersistedDismiss] = useLocalStorage<{
        dismissedAt?: string
    }>(`DismissableAlert:${storageKey}`, {})

    const dismiss = (): void => {
        setPersistedDismiss({ dismissedAt: moment().toISOString() })
    }

    const checkDismissed = (): boolean => {
        const { dismissedAt } = persistedDismiss
        if (!dismissedAt) {
            return false
        }

        if (!numberOfDaysToHideAfterDismiss) {
            return true
        }

        const dismissedAtMoment = moment(dismissedAt)
        if (dismissedAtMoment) {
            return moment().isBefore(
                dismissedAtMoment.add(numberOfDaysToHideAfterDismiss, 'days')
            )
        }

        return false
    }

    return {
        dismiss,
        isDismissed: checkDismissed(),
    }
}

export default usePersistedDismiss
