import { ReactElement } from 'react'

import { HomeFilled } from '@ant-design/icons'
import { Button, ButtonProps } from 'antd'
import { type TooltipPlacement } from 'antd/es/tooltip'
import classNames from 'classnames'

import { ToolTip } from 'components/ToolTip'

import styles from './styles.scss'

interface Props {
    active: boolean
    tooltipPlacement?: TooltipPlacement
}

const HomeDashboardIcon = ({
    active,
    tooltipPlacement = 'top',
    ...btnProps
}: Props & ButtonProps): ReactElement => (
    <ToolTip
        title={active ? 'This is your Home Dashboard' : 'Set as Home Dashboard'}
        placement={tooltipPlacement}
    >
        <Button
            shape="circle"
            type={active ? 'primary' : 'default'}
            className={classNames(
                { 'fg-icon-btn-secondary': !active },
                styles['home-icon-button']
            )}
            {...btnProps}
        >
            <HomeFilled />
        </Button>
    </ToolTip>
)

export default HomeDashboardIcon
