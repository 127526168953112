import { ReactElement } from 'react'

import Icon from '@ant-design/icons'

import PriceIconSvg from './PriceIconSvg'
import { IconProps } from '../localTypes'

const PriceIcon = (props: IconProps): ReactElement => (
    <Icon component={PriceIconSvg} {...props} />
)

export default PriceIcon
