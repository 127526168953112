import { RulebookChangeState } from 'types'

export const PENDING_CHANGE_SET_STATES = [
    RulebookChangeState.GENERATING,
    RulebookChangeState.IN_QUEUE,
    RulebookChangeState.IN_PROGRESS,
    RulebookChangeState.DRAFT,
    RulebookChangeState.COMPLETE,
]

export const ENABLED = 'enabled'
export const DISABLED = 'disabled'
