import { ARCHIVED } from 'const/resourceStates'
import { addCache } from 'helpers/utilities'

import { axiosInstanceOrganizations } from './client'
import { getCerebroHeaders } from './headers'

export const createOrganization = (data) =>
    axiosInstanceOrganizations.post(`/api/user/organizations/`, data)

export const getUserOrganizationGroups = (params) =>
    axiosInstanceOrganizations.get(`/api/user/organization_groups/`, {
        params,
    })

export const getUserOrganizations = (params) =>
    axiosInstanceOrganizations.get(`/api/user/organizations/`, { params })

export const getProfileFeaturePermissions = (profileId) =>
    axiosInstanceOrganizations.get(
        `/api/profile/${profileId}/feature_permissions_verbose/`
    )

export const getSovPermissions = () =>
    axiosInstanceOrganizations.get('/api/user/sov_permissions/')

export const getOrganization = (organizationId, options = {}) =>
    axiosInstanceOrganizations.get(`/api/organization/${organizationId}/`, {
        headers: getCerebroHeaders(options.headers),
    })
addCache(getOrganization)

export const getOrganizationGroups = (organizationId, params) =>
    axiosInstanceOrganizations.get(
        `/api/organization/${organizationId}/groups/`,
        { params }
    )
addCache(getOrganizationGroups)

export const getOrganizationMembers = (organizationId, params) => {
    return organizationId
        ? axiosInstanceOrganizations.get(
              `/api/organization/${organizationId}/members/`,
              {
                  params,
              }
          )
        : null
}
addCache(getOrganizationMembers)

export const updateOrganizationMember = (organizationId, memberId, data) =>
    axiosInstanceOrganizations.patch(
        `/api/organization/${organizationId}/member/${memberId}/`,
        data
    )

export const getOrganizationGroupMembers = (organizationGroupId) =>
    axiosInstanceOrganizations.get(
        `/api/organization_group/${organizationGroupId}/members/`
    )
addCache(getOrganizationGroupMembers)

export const getLabels = (params) =>
    axiosInstanceOrganizations.get(`/api/labels/`, { params })

export const createLabel = (data) =>
    axiosInstanceOrganizations.post(`/api/labels/`, data)

export const getLabel = (labelId) =>
    axiosInstanceOrganizations.get(`/api/label/${labelId}/`)

export const updateLabel = (labelId, data) =>
    axiosInstanceOrganizations.patch(`/api/label/${labelId}/`, data)

export const deleteLabel = (labelId) =>
    axiosInstanceOrganizations.delete(`/api/label/${labelId}/`)

export const inviteMember = (data) =>
    axiosInstanceOrganizations.post('/api/invitations/', data)

export const createVcIntegration = (data) =>
    axiosInstanceOrganizations.post(
        '/api/vendor_central_account_integrations/',
        data
    )

export const resendInvite = (inviteId) =>
    axiosInstanceOrganizations.post(`/api/invitation/${inviteId}/resend/`)

export const updateInvite = (inviteId, data) =>
    axiosInstanceOrganizations.patch(`/api/invitation/${inviteId}/`, data)

export const getInvitationByToken = (token) =>
    axiosInstanceOrganizations.get(`/api/invitation/token/${token}/`)

export const getOrganizationInvitations = (organizationId, params) =>
    axiosInstanceOrganizations.get(
        `/api/organization/${organizationId}/invitations/`,
        {
            params,
        }
    )
addCache(getOrganizationInvitations)

export const getOrganizationChildren = (organizationId, params) =>
    axiosInstanceOrganizations.get(
        `/api/organization/${organizationId}/child_orgs/`,
        {
            params,
        }
    )
addCache(getOrganizationChildren)

export const getOrganizationChildrenSyncExport = (organizationId, params) =>
    axiosInstanceOrganizations.get(
        `/api/organization/${organizationId}/child_orgs/export/csv/`,
        {
            headers: {
                Accept: 'text/csv',
            },
            params,
        }
    )

export const getOrganizationIntegrations = (organizationId, params) =>
    axiosInstanceOrganizations.get(
        `/api/organization/${organizationId}/integrations/`,
        { params }
    )
addCache(getOrganizationIntegrations)

export const updateIntegration = (integrationId, data) =>
    axiosInstanceOrganizations.patch(`/api/integration/${integrationId}/`, data)

export const getIntegration = (integrationId) =>
    axiosInstanceOrganizations.get(`/api/integration/${integrationId}/`)

export const getOrganizationVCIntegrations = (organizationId, params) =>
    axiosInstanceOrganizations.get(
        `/api/organization/${organizationId}/vendor_central_account_integrations/`,
        { params }
    )
addCache(getOrganizationVCIntegrations)

export const updateVCIntegration = (integrationId, data) =>
    axiosInstanceOrganizations.patch(
        `/api/vendor_central_account_integrations/${integrationId}/`,
        data
    )

export const getOrganizationGroup = (organizationGroupId) =>
    axiosInstanceOrganizations.get(
        `/api/organization_group/${organizationGroupId}/`
    )
addCache(getOrganizationGroup)

export const deleteOrganizationGroup = (organizationGroupId) =>
    axiosInstanceOrganizations.delete(
        `/api/organization_group/${organizationGroupId}/`
    )

export const patchOrganizationGroup = (organizationGroupId, params) =>
    axiosInstanceOrganizations.patch(
        `/api/organization_group/${organizationGroupId}/`,
        params
    )

export const removeMemberFromOrganization = (organizationId, memberId) =>
    axiosInstanceOrganizations.delete(
        `/api/organization/${organizationId}/member/${memberId}/`
    )

export const createOrganizationGroup = (data) =>
    axiosInstanceOrganizations.post('/api/organization_groups/', data)

/**
 * Fetch integrated ad accounts for the current organization
 * @param params
 * @param options
 * @returns {Promise<AxiosResponse<Brand>>}
 */
export const getAdAccounts = (
    params = {},
    options = {} // getProfiles?
) =>
    axiosInstanceOrganizations.get(`/api/profiles/`, {
        params,
        headers: getCerebroHeaders(options.headers),
    })
addCache(getAdAccounts)

export const getAdAccount = (adAccountId) =>
    axiosInstanceOrganizations.get(`/api/profile/${adAccountId}/`)
addCache(getAdAccount)

export const updateAdAccount = (adAccountId, data) =>
    axiosInstanceOrganizations.patch(`/api/profile/${adAccountId}/`, data)

export const updateAdAccounts = (data, params) =>
    axiosInstanceOrganizations.patch(`/api/profiles/`, data, { params })

export const syncAdAccount = (adAccountId) =>
    axiosInstanceOrganizations.get(`/api/profile/${adAccountId}/sync/snapshot/`)

export const createBrandGroup = (data) =>
    axiosInstanceOrganizations.post('/api/brand_groups/', data)

export const getBrandGroups = (params, options = {}) =>
    axiosInstanceOrganizations.get(`/api/brand_groups/`, {
        params,
        headers: getCerebroHeaders(options.headers),
    })
addCache(getBrandGroups)

export const updateBrandGroup = (brandGroupId, data) =>
    axiosInstanceOrganizations.patch(`/api/brand_group/${brandGroupId}/`, data)

export const deleteBrandGroup = (brandGroupId) =>
    axiosInstanceOrganizations.delete(`/api/brand_group/${brandGroupId}/`)

export const getPortfolio = (portfolioId) =>
    axiosInstanceOrganizations.get(`/api/portfolio/${portfolioId}/`)

export const getPortfolios = (params, options = {}) =>
    axiosInstanceOrganizations.get('/api/portfolios/', {
        params,
        headers: getCerebroHeaders(options.headers),
    })

export const updatePortfolio = (portfolioId, data) =>
    axiosInstanceOrganizations.patch(`/api/portfolio/${portfolioId}/`, data)

export const getCampaigns = (params, options = {}) =>
    axiosInstanceOrganizations.get('/api/campaigns/', {
        params,
        headers: getCerebroHeaders(options.headers),
    })

export const getCampaignsAsyncExport = (params) =>
    axiosInstanceOrganizations.get(`/api/campaigns/export/async/`, {
        params,
    })

export const createAmazonCampaign = (data) =>
    axiosInstanceOrganizations.post('/api/campaigns/', data)

export const getCampaign = (campaignId) =>
    axiosInstanceOrganizations.get(`/api/campaign/${campaignId}/`)

export const updateCampaign = (campaignId, data) =>
    axiosInstanceOrganizations.patch(`/api/campaign/${campaignId}/`, data)

export const updateCampaigns = (data, params) =>
    axiosInstanceOrganizations.patch('/api/campaigns/', data, { params })

export const getCampaignAutomationCapabilities = (campaignId) =>
    axiosInstanceOrganizations.get(
        `/api/campaign/${campaignId}/automation_capabilities/`
    )

export const getAutomationCapabilities = (params = {}, options = {}) =>
    axiosInstanceOrganizations.get(`/api/automation_capabilities/`, {
        params,
        headers: getCerebroHeaders(options.headers),
    })
addCache(getAutomationCapabilities)

export const getAutomationTasks = (params = {}, options = {}) =>
    axiosInstanceOrganizations.get(`/api/automation_tasks/`, {
        params,
        headers: getCerebroHeaders(options.headers),
    })

export const getAutomationProfile = (profileId, params = {}) =>
    axiosInstanceOrganizations.get(`/api/automation_profile/${profileId}/`, {
        params,
    })

export const getAutomationProfiles = (params = {}, options = {}) =>
    axiosInstanceOrganizations.get(`/api/automation_profiles/`, {
        params,
        headers: getCerebroHeaders(options.headers),
    })
export const createAutomationProfile = (data) =>
    axiosInstanceOrganizations.post(`/api/automation_profiles/`, data)

export const updateAutomationProfile = (profileId, data) =>
    axiosInstanceOrganizations.patch(
        `/api/automation_profile/${profileId}/`,
        data
    )

export const deleteAutomationProfile = (profileId) =>
    axiosInstanceOrganizations.delete(`/api/automation_profile/${profileId}/`)

export const createAutomationTask = (data) =>
    axiosInstanceOrganizations.post(`/api/automation_tasks/`, data)

export const updateAutomationTask = (taskId, data) =>
    axiosInstanceOrganizations.patch(`/api/automation_task/${taskId}/`, data)

export const updateAutomationTasks = (data, params) =>
    axiosInstanceOrganizations.patch(`/api/automation_tasks/`, data, { params })

export const asyncUpdateAutomationTask = (data, params, options = {}) =>
    axiosInstanceOrganizations.patch('/api/automation_tasks/mutate/', data, {
        params,
        headers: getCerebroHeaders(options.headers),
    })

export const updateProduct = (productId, data) =>
    axiosInstanceOrganizations.patch(`/api/product/${productId}/`, data)

export const getProducts = (params, options = {}) =>
    axiosInstanceOrganizations.get('/api/products/', {
        params,
        headers: getCerebroHeaders(options.headers),
    })
addCache(getProducts)

export const getProductsAsyncExport = (params) =>
    axiosInstanceOrganizations.get(`/api/product_catalog/export/async/`, {
        params,
    })

export const updateProducts = (data) =>
    axiosInstanceOrganizations.patch(`/api/products/`, data)

export const getCatalogProducts = (params = {}, options = {}) =>
    axiosInstanceOrganizations.get('/api/product_catalog/', {
        params,
        headers: getCerebroHeaders(options.headers),
    })

export const getProductAdsSearch = (params = {}, options = {}) =>
    axiosInstanceOrganizations.get('/api/product_ads_search/', {
        params,
        headers: getCerebroHeaders(options.headers),
    })

export const getProductAds = (params = {}, options = {}) =>
    axiosInstanceOrganizations.get('/api/product_ads/', {
        params,
        headers: getCerebroHeaders(options.headers),
    })

export const getProductAd = (productId) =>
    axiosInstanceOrganizations.get(`/api/product_ad/${productId}/`)

export const createProductAd = (data) =>
    axiosInstanceOrganizations.post(`/api/product_ads/`, data)

export const updateProductAds = (data) =>
    axiosInstanceOrganizations.patch(`/api/product_ads/`, data)

export const updateProductAd = (productId, data) =>
    axiosInstanceOrganizations.patch(`/api/product_ad/${productId}/`, data)

export const getProductMetadata = (params) =>
    axiosInstanceOrganizations.get(`/api/product_metadata/`, { params })
addCache(getProductMetadata)

/**
 * @param {string} params.marketplace The two-digit code of the marketplace to search for
 * @param {string} params.asins Comma-separated list of ASINs
 * @returns {Promise<CerebroPaginatedResponse<ProductMetadata>>}
 */
export const getProductMetadataOpenSearch = (params) =>
    axiosInstanceOrganizations.get(`/api/product_metadata_opensearch/`, {
        params,
    })
addCache(getProductMetadataOpenSearch)

export const getProductMetadataAsinTitles = (params, options = {}) =>
    axiosInstanceOrganizations.get(`/api/product_metadata/asin_titles/`, {
        params,
        headers: getCerebroHeaders(options.headers),
    })
addCache(getProductMetadataAsinTitles)

export const getKeywords = (params = {}, options = {}) =>
    axiosInstanceOrganizations.get('/api/keywords/', {
        params,
        headers: getCerebroHeaders(options.headers),
    })

export const getKeywordsSearch = (params = {}, options = {}) =>
    axiosInstanceOrganizations.get('/api/keywords_search/', {
        params,
        headers: getCerebroHeaders(options.headers),
    })

export const getSPTargetBidRecommendationsV3 = (profileId, data) =>
    axiosInstanceOrganizations.post(
        `/api/aaa/?endpoint=sp/targets/bid/recommendations&profile_id=${profileId}&api_version=v3`,
        {
            aaa_data: data,
            headers: {
                'Content-Type':
                    'application/vnd.spthemebasedbidrecommendation.v4+json',
            },
        }
    )

export const getSDTargetBidRecommendations = (profileId, data) =>
    axiosInstanceOrganizations.post(
        `/api/aaa/?endpoint=sd/targets/bid/recommendations&profile_id=${profileId}&api_version=v3`,
        {
            aaa_data: data,
            headers: {
                'Content-Type':
                    'application/vnd.sdtargetingrecommendations.v3.2+json',
            },
        }
    )

export const getSBTargetBidRecommendations = (profileId, data) =>
    axiosInstanceOrganizations.post(
        `/api/aaa/?endpoint=sb/recommendations/bids&profile_id=${profileId}&api_version=v3`,
        data
    )

export const getKeyword = (keywordId) =>
    axiosInstanceOrganizations.get(`/api/keyword/${keywordId}/`)

export const createKeyword = (data) =>
    axiosInstanceOrganizations.post(`/api/keywords/`, data)

export const updateKeyword = (keywordId, data) =>
    axiosInstanceOrganizations.patch(`/api/keyword/${keywordId}/`, data)

export const updateKeywords = (data, params) =>
    axiosInstanceOrganizations.patch('/api/keywords/', data, { params })

export const getCampaignNegativeKeywords = (params, options = {}) =>
    axiosInstanceOrganizations.get('/api/campaign_negative_keywords/', {
        params,
        headers: getCerebroHeaders(options.headers),
    })

export const createCampaignNegativeKeyword = (data) =>
    axiosInstanceOrganizations.post(`/api/campaign_negative_keywords/`, data)

export const deleteCampaignNegativeKeyword = (keywordId) =>
    axiosInstanceOrganizations.patch(
        `/api/campaign_negative_keyword/${keywordId}/`,
        {
            state: ARCHIVED,
        }
    )

export const getAdGroups = (params, options = {}) =>
    axiosInstanceOrganizations.get(`/api/ad_groups/`, {
        params,
        headers: getCerebroHeaders(options.headers),
    })

export const getAdGroup = (adGroupId) =>
    axiosInstanceOrganizations.get(`/api/ad_group/${adGroupId}/`)

export const updateAdGroup = (adGroupId, params) =>
    axiosInstanceOrganizations.put(`/api/ad_group/${adGroupId}/`, params)

export const createAdGroup = (data) =>
    axiosInstanceOrganizations.post('/api/ad_groups/', data)

export const getNegativeKeywords = (params, options = {}) =>
    axiosInstanceOrganizations.get('/api/negative_keywords/', {
        params,
        headers: getCerebroHeaders(options.headers),
    })

export const createNegativeKeyword = (data) =>
    axiosInstanceOrganizations.post(`/api/negative_keywords/`, data)

export const deleteNegativeKeyword = (keywordId) =>
    axiosInstanceOrganizations.patch(`/api/negative_keyword/${keywordId}/`, {
        state: ARCHIVED,
    })

export const getSovBrands = (params, options = {}) =>
    axiosInstanceOrganizations.get(`/api/product_metadata/brands/`, {
        params,
        headers: getCerebroHeaders(options.headers),
    })
addCache(getSovBrands)

export const getSovKeywords = (params, options = {}) =>
    axiosInstanceOrganizations.get(`/api/sov/keywords/`, {
        params,
        headers: getCerebroHeaders(options.headers),
    })

export const getSovKeywordsAsyncExport = (params) =>
    axiosInstanceOrganizations.get(`/api/sov/keywords/export/async/`, {
        params,
    })

export const getKeywordAsyncExport = (params) =>
    axiosInstanceOrganizations.get(`/api/keywords/export/async/`, {
        params,
    })

export const getNegativeKeywordsCreationTemplate = () =>
    axiosInstanceOrganizations.get(`/api/negative_keywords/create_template/`)

export const getTargetAsyncExport = (params) =>
    axiosInstanceOrganizations.get(`/api/targets/export/async/`, {
        params,
    })

export const getSovKeyword = (keywordId, params) =>
    axiosInstanceOrganizations.get(`/api/sov/keyword/${keywordId}/`, {
        params,
    })

export const createSovKeyword = (data) =>
    axiosInstanceOrganizations.post(`/api/sov/keywords/`, data)

export const updateSovKeyword = (keywordId, data) =>
    axiosInstanceOrganizations.patch(`/api/sov/keyword/${keywordId}/`, data)

export const updateSovKeywords = (data, params) =>
    axiosInstanceOrganizations.patch(`/api/sov/keywords/`, data, { params })

export const asyncUpdateSovKeywords = (data, params) =>
    axiosInstanceOrganizations.patch(`/api/sov/keywords/mutate/`, data, {
        params,
    })

export const deleteSovKeyword = (keywordId) =>
    axiosInstanceOrganizations.delete(`/api/sov/keyword/${keywordId}/`)

const useProfileTimeZoneId = (
    object,
    propertyToReplace,
    profileTzDateProp
) => ({
    ...object,
    [propertyToReplace]: object[profileTzDateProp],
})

export const getChangelog = (params, options = {}) =>
    axiosInstanceOrganizations
        .get('/api/changelog/', {
            params,
            headers: getCerebroHeaders(options.headers),
        })
        .then((response) => {
            if (response?.data?.results?.length) {
                const existingIds = {}
                response.data.results = response.data.results.map((record) => {
                    // Changelog IDs are not guaranteed to be unique which can break
                    // antd table rendering, resulting in duplicate rows or appearing out of order, etc.
                    if (!existingIds[record.id]) {
                        existingIds[record.id] = 1
                    } else {
                        record.id = `${record.id}_${existingIds[record.id]}`
                        existingIds[record.id] += 1
                    }

                    if (!options.useDateAsIs) {
                        return useProfileTimeZoneId(
                            record,
                            'date',
                            'profile_tz_date'
                        )
                    }
                    return record
                })
            }
            return response
        })
addCache(getChangelog)

export const getChangelogExport = (params, options = {}) =>
    axiosInstanceOrganizations.get('/api/changelog/export/async/', {
        params,
        headers: getCerebroHeaders(options.headers),
    })

export const getDashboards = (params) =>
    axiosInstanceOrganizations.get('/api/dashboards/', {
        params,
    })
addCache(getDashboards)

export const getDashboard = (dashboardId) =>
    axiosInstanceOrganizations.get(`/api/dashboard/${dashboardId}/`)

export const createDashboard = (data) =>
    axiosInstanceOrganizations.post('/api/dashboards/', data)

export const updateDashboard = (dashboardId, data) =>
    axiosInstanceOrganizations.patch(`/api/dashboard/${dashboardId}/`, data)

export const deleteDashboard = (dashboardId) =>
    axiosInstanceOrganizations.delete(`/api/dashboard/${dashboardId}/`)

export const exportDashboardToPdf = (dashboardId, params) =>
    axiosInstanceOrganizations.post(
        `/api/dashboard/${dashboardId}/export/pdf/`,
        {},
        { params }
    )

export const createScheduledExport = (data, params) =>
    axiosInstanceOrganizations.post('/api/export_schedules/', data, { params })

export const updateScheduledExport = (id, data, params) =>
    axiosInstanceOrganizations.patch(`/api/export_schedule/${id}/`, data, {
        params,
    })

export const deleteScheduledExport = (id) =>
    axiosInstanceOrganizations.delete(`/api/export_schedule/${id}/`)

export const getScheduledExports = (params) =>
    axiosInstanceOrganizations.get('/api/export_schedules/', { params })

export const updateSellerCentralIntegration = (integrationId, data) =>
    axiosInstanceOrganizations.patch(
        `/api/seller_central_account_integrations/${integrationId}/`,
        data
    )

export const getSellerCentralIntegrations = (params) =>
    axiosInstanceOrganizations.get(
        '/api/seller_central_account_integrations/',
        {
            params,
        }
    )
addCache(getSellerCentralIntegrations)

export const getSellerCentralOAuthUrl = (data) =>
    axiosInstanceOrganizations.post('/api/lwa/seller_central/', data)

export const initiateSellerCentralIntegrationUpdate = (integrationId) =>
    axiosInstanceOrganizations.put('/api/lwa/seller_central/', {
        integration_id: integrationId,
    })

export const loginWithAmazon = ({ alias }) =>
    axiosInstanceOrganizations.post(`/api/lwa/`, { account_alias: alias })

export const getSellerCentralCatalogItems = (params, options = {}) =>
    axiosInstanceOrganizations.get('/api/seller_central_catalog_items/', {
        params,
        headers: getCerebroHeaders(options.headers),
    })

export const sendIntegrationInvitation = (data) =>
    axiosInstanceOrganizations.post(`/api/integration_invitations/`, data)

export const updateTarget = (targetId, data) =>
    axiosInstanceOrganizations.patch(`/api/target/${targetId}/`, data)

export const updateTargets = (data, params) =>
    axiosInstanceOrganizations.patch('/api/targets/', data, { params })

export const getTargets = (params, options = {}) =>
    axiosInstanceOrganizations.get(`/api/targets/`, {
        params,
        headers: getCerebroHeaders(options.headers),
    })

export const getTarget = (targetId, options = {}) =>
    axiosInstanceOrganizations.get(`/api/target/${targetId}/`, {
        headers: getCerebroHeaders(options.headers),
    })

export const getSegments = (params, options = {}) =>
    axiosInstanceOrganizations.get('/api/segments/', {
        params,
        headers: getCerebroHeaders(options.headers),
    })
addCache(getSegments)

export const deleteSegment = (segmentId) =>
    axiosInstanceOrganizations.delete(`/api/segment/${segmentId}/`)

export const getDashboardTemplates = (params, options = {}) =>
    axiosInstanceOrganizations.get('/api/dashboard_templates/', {
        params,
        headers: getCerebroHeaders(options.headers),
    })

export const getDashboardTemplatesTags = (params, options = {}) =>
    axiosInstanceOrganizations.get('/api/dashboard_templates/tags/', {
        params,
        headers: getCerebroHeaders(options.headers),
    })

export const getDashboardTemplate = (
    dashboardTemplateId,
    params,
    options = {}
) =>
    axiosInstanceOrganizations.get(
        `/api/dashboard_template/${dashboardTemplateId}/`,
        {
            params,
            headers: getCerebroHeaders(options.headers),
        }
    )

export const createDashboardFromTemplate = (
    dashboardTemplateId,
    params,
    options = {}
) => {
    return axiosInstanceOrganizations.post(
        `/api/dashboard_template/${dashboardTemplateId}/dashboards/`,
        {
            params,
            headers: getCerebroHeaders(options.headers),
        }
    )
}
