import { handleActions } from 'redux-actions'

import { portfolioProductsTableColumnsConfig } from 'configuration/tables'
import { DATES, LABELS, PRODUCT_ASINS, PRODUCT_TITLES } from 'const/filters'
import { LOCAL_FILTERS, FILTER_SETTINGS } from 'const/reducerKeys'
import { serializeColumns } from 'helpers/tables'
import { defaultTab, getDefaultTable } from 'reducers/ui/defaults'

const initialState = {
    ...defaultTab,
    [LOCAL_FILTERS]: {
        [PRODUCT_ASINS]: [],
        [PRODUCT_TITLES]: [],
        [LABELS]: [],
    },
    [FILTER_SETTINGS]: {
        anchored: [DATES],
        order: [PRODUCT_ASINS, PRODUCT_TITLES, LABELS],
        displayState: {
            [PRODUCT_ASINS]: true,
            [PRODUCT_TITLES]: true,
            [LABELS]: true,
        },
    },
    table: getDefaultTable(
        serializeColumns(portfolioProductsTableColumnsConfig)
    ),
}

export default handleActions({}, initialState)
