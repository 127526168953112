import { handleActions } from 'redux-actions'

import { walmartAdGroupKeywordsTabTableColumnsConfig } from 'configuration/tables'
import {
    DATES,
    KEYWORD_TEXT,
    KEYWORD_MATCH_TYPES,
    KEYWORD_STATES,
} from 'const/filters'
import { LOCAL_FILTERS, FILTER_SETTINGS } from 'const/reducerKeys'
import { serializeColumns } from 'helpers/tables'
import { defaultTab, getDefaultTable } from 'reducers/ui/defaults'

const defaultState = {
    ...defaultTab,
    [LOCAL_FILTERS]: {
        [KEYWORD_TEXT]: null,
        [KEYWORD_MATCH_TYPES]: [],
        [KEYWORD_STATES]: [],
    },
    [FILTER_SETTINGS]: {
        anchored: [KEYWORD_TEXT, DATES],
        order: [KEYWORD_STATES, KEYWORD_MATCH_TYPES],
        displayState: {
            [KEYWORD_MATCH_TYPES]: true,
            [KEYWORD_STATES]: true,
        },
    },
    table: getDefaultTable(
        serializeColumns(walmartAdGroupKeywordsTabTableColumnsConfig),
        { showTotalRow: true }
    ),
}

export default handleActions({}, defaultState)
