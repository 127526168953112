import { ReactElement } from 'react'

import { TFunction } from 'i18next'
import pluralize from 'pluralize'
import { Translation } from 'react-i18next'

import { COMPLETE_STATES, ERROR_STATES, PENDING_STATES } from 'const/bulkUpdate'
import { UNDEFINED_VALUE } from 'const/formatting'
import { titleCase } from 'helpers/formatting'
import { isUnset } from 'helpers/utilities'
import {
    AsyncUpdate,
    AsyncUpdateType,
    BulkUpdateAction,
    BulkUpdateNumberAction,
    BulkUpdatePayload,
    BulkUpdateState,
    CSVUpdateAction,
} from 'types'

export const formatAsyncUpdatePayload = (
    field: string,
    action: BulkUpdateAction,
    value: string | number | string[],
    dryRun = true
): BulkUpdatePayload => ({
    dry_run: dryRun,
    [field]: {
        action,
        params: {
            value,
        },
    },
})

export const isUpdatePending = (update: AsyncUpdate): boolean =>
    PENDING_STATES.findIndex((state) => state === update.state) > -1

export const isSomeUpdatesPending = (updates: AsyncUpdate[]): boolean =>
    !!updates && updates.some((update) => isUpdatePending(update))

export const isUpdateFailed = (update: AsyncUpdate): boolean =>
    ERROR_STATES.findIndex((state) => state === update.state) > -1

export const isUpdateComplete = (update: AsyncUpdate): boolean =>
    COMPLETE_STATES.findIndex((state) => state === update.state) > -1

export const isUpdateDryRun = (update: AsyncUpdate): boolean =>
    update.state === BulkUpdateState.DRY_RUN

export const managementDetailToFormErrors = (
    resourceName: string,
    userCanManageDetail: string | null
): ReactElement =>
    userCanManageDetail ? (
        <Translation>
            {(t) =>
                t(
                    'table:BulkActions.errorMsg.detailed',
                    '{{errorDetail}} Affects {{resourceName}}',
                    { errorDetail: userCanManageDetail, resourceName }
                )
            }
        </Translation>
    ) : (
        <Translation>
            {(t) =>
                t(
                    'table:BulkActions.errorMsg.general',
                    'Cannot modify {{resourceName}}.',
                    { resourceName }
                )
            }
        </Translation>
    )

export const formatBulkUpdateType = (update: AsyncUpdate): string => {
    if (isUnset(update.type)) {
        return UNDEFINED_VALUE
    }

    if (update.type === AsyncUpdateType.AD_GROUP) {
        return 'Ad Group'
    }

    if (update.type === AsyncUpdateType.PROFILE) {
        return 'Brand'
    }

    if (update.type === AsyncUpdateType.PRODUCT_AD) {
        return 'Product Ad'
    }

    if (update.type === AsyncUpdateType.PRODUCT_AGGREGATE) {
        return 'Product'
    }

    if (
        update.type === AsyncUpdateType.SOV_KEYWORD ||
        update.type === AsyncUpdateType.SOV_KEYWORD_AGGREGATE
    ) {
        return 'SOV Keyword'
    }

    return titleCase(update.type)
}

export const getTranslatedBulkUpdateLabel = (
    updateType: string,
    count: number,
    t: TFunction
): string => {
    if (isUnset(updateType)) {
        return UNDEFINED_VALUE
    }
    let label = ''
    if (updateType === AsyncUpdateType.AD_GROUP) {
        label = t('common:adGroup', 'Ad Group')
    }

    if (updateType === AsyncUpdateType.PROFILE) {
        label = t('common:brand', 'Brand')
    }

    if (updateType === AsyncUpdateType.PRODUCT_AD) {
        label = t('common:productAd', 'Product Ad')
    }

    if (updateType === AsyncUpdateType.PRODUCT_AGGREGATE) {
        label = t('common:product', 'Product')
    }

    if (
        updateType === AsyncUpdateType.SOV_KEYWORD ||
        updateType === AsyncUpdateType.SOV_KEYWORD_AGGREGATE
    ) {
        label = t('common:sovKeyword', 'SOV Keyword')
    }

    if (updateType === AsyncUpdateType.KEYWORD) {
        label = t('common:keyword', 'Keyword')
    }
    label = titleCase(updateType)
    return pluralize(label, count)
}

/**
 * Type guard for number actions
 */
export const isNumberAction = (
    action: BulkUpdateAction
): action is BulkUpdateNumberAction =>
    [
        'increase',
        'decrease',
        'increase_by_pct',
        'decrease_by_pct',
        'set',
        'amazon_suggested',
        'above_amazon_suggested',
        'below_amazon_suggested',
    ].includes(action)

/**
 * Type guard for CSV Update actions
 */
export const isCSVUpdateAction = (
    action: BulkUpdateAction
): action is CSVUpdateAction => action === 'update_asynchronously'
