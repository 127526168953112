import { Field } from 'types'

import { createField } from './createField'
import { FieldCreatorOptions } from './localTypes'

export function placementText<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const key = 'placement'
    const displayName = 'Campaign Placement'
    const dataIndex = options.dataIndex ?? [key]
    return {
        ...createField({
            ...options,
            id: key,
            name: 'Campaign Placement',
            shortName: 'Campaign Placement',
            minWidth: 70,
            dataIndex,
            width: 200,
            sorter: options.sorter ?? false,
        }),
        // This is kind of a hardcoded group by entry shim since
        // this field is not present in some data sources but is selectable
        // and then the data source is swapped out for a different one that does have it.
        // Defining it here ensures that the group by tooltip will render as expected.
        groupBy: {
            id: key,
            metadata_key: key,
            slug: 'campaign-placement',
            metadata_all_names: [key],
            metadata_display_name: displayName,
            metadata_format: 'string',
            metadata_is_deprecated: false,
            metadata_release_date: null,
            metadata_end_of_life_date: null,
            display_name: displayName,
            short_name: displayName,
            definition:
                'Displays the placement of advertisements within a campaign.',
            is_visible: true,
        },
    }
}

export function placementMultiplier<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['multiplier']
    return createField({
        ...options,
        id: 'placement_multiplier',
        name: 'Placement Multiplier',
        shortName: 'Placement Multiplier',
        minWidth: 50,
        dataIndex,
        width: 100,
        sorter: options.sorter ?? false,
    })
}
