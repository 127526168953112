import produce from 'immer'
import set from 'lodash/fp/set'
import { handleActions } from 'redux-actions'

import {
    fetchChildrenRequest,
    fetchChildrenSuccess,
} from 'actions/ui/productsSummaryPage'
import { productsSummaryParentProductsTableColumnsConfig } from 'configuration/tables'
import {
    DATES,
    REGIONS,
    COUNTRIES,
    BRANDS,
    PRODUCT_TITLE,
    PRODUCT_ASINS,
    PRODUCT_TITLES,
    PRODUCT_NUMBER_OF_ITEMS,
    LABELS,
    METRIC_FILTERS,
    PORTFOLIOS,
    FACT_TYPES,
    ADVANCED_TEXT,
} from 'const/filters'
import { FILTERS, FILTER_SETTINGS } from 'const/reducerKeys'
import { serializeColumns } from 'helpers/tables'
import { defaultTab, getDefaultTable } from 'reducers/ui/defaults'

const initialState = {
    [FILTERS]: {
        [PRODUCT_TITLE]: null,
        [FACT_TYPES]: [],
        [REGIONS]: [],
        [COUNTRIES]: [],
        [BRANDS]: [],
        [PORTFOLIOS]: [],
        [PRODUCT_ASINS]: [],
        [PRODUCT_TITLES]: [],
        [PRODUCT_NUMBER_OF_ITEMS]: [],
        [LABELS]: [],
        [ADVANCED_TEXT]: [],
        [METRIC_FILTERS]: [],
    },
    [FILTER_SETTINGS]: {
        anchored: [PRODUCT_TITLE, DATES, FACT_TYPES],
        order: [
            METRIC_FILTERS,
            REGIONS,
            COUNTRIES,
            BRANDS,
            PORTFOLIOS,
            PRODUCT_ASINS,
            PRODUCT_TITLES,
            PRODUCT_NUMBER_OF_ITEMS,
            LABELS,
            ADVANCED_TEXT,
        ],
        displayState: {
            [METRIC_FILTERS]: true,
            [REGIONS]: true,
            [COUNTRIES]: true,
            [BRANDS]: true,
            [PORTFOLIOS]: true,
            [PRODUCT_ASINS]: true,
            [PRODUCT_TITLES]: true,
            [PRODUCT_NUMBER_OF_ITEMS]: false,
            [LABELS]: true,
            [ADVANCED_TEXT]: true,
        },
    },

    table: getDefaultTable(
        serializeColumns(productsSummaryParentProductsTableColumnsConfig)
    ),
    ...defaultTab,
}

export default handleActions(
    {
        // fetch children request
        [fetchChildrenRequest](state, action) {
            const record = action.payload

            const data = state.table.data.map((row) => {
                if (row.primary_asin === record.primary_asin) {
                    return {
                        ...row,
                        children: [
                            {
                                ...produce(row, (draft) => {
                                    if (draft) {
                                        delete draft.children
                                    }
                                }),
                                loading: true,
                                primary_asin: 'LOADING...',
                                primary_metadata_id: 'LOADING...',
                                primary_metadata: {
                                    ...row.primary_metadata,
                                    id: 'LOADING...',
                                    title: 'Loading...',
                                },
                                profile: {
                                    ...row.profile,
                                    country_code: null,
                                },
                            },
                        ],
                    }
                }

                return {
                    ...row,
                }
            })

            return set(['table', 'data'], data, state)
        },
        // fetch children success
        [fetchChildrenSuccess](state, action) {
            const { record, children } = action.payload

            const data = state.table.data.map((row) => {
                if (row.primary_asin === record.primary_asin) {
                    return {
                        ...row,
                        children,
                    }
                }

                return {
                    ...row,
                }
            })

            return set(['table', 'data'], data, state)
        },
    },
    initialState
)
