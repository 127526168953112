import { SVGAttributes, ReactElement } from 'react'

type Props = SVGAttributes<SVGElement>

function SvgPortfolioIcon(props: Props): ReactElement {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M17.4 6.093h-6.731l-2.2-2.053A.155.155 0 008.366 4H3.6c-.332 0-.6.262-.6.585v10.83c0 .323.268.585.6.585h13.8c.332 0 .6-.262.6-.585V6.678a.592.592 0 00-.6-.585z" />
        </svg>
    )
}

export default SvgPortfolioIcon
