import {
    reportNameRenderer,
    reportActionsRenderer,
} from 'components/FieldRenderers'
import { formatCerebroDateTime } from 'helpers/formatting'
import { renderReportDateRange } from 'helpers/reports'
import { Field } from 'types'

import {
    createActionField,
    createCerebroDateTimeField,
    createField,
} from './createField'
import { FieldCreatorOptions } from './localTypes'

export function reportName<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createField({
        ...options,
        id: 'reportName',
        name: 'Report Name',
        shortName: 'Report Name',
        dataIndex: options.dataIndex ?? ['name'],
        minWidth: 150,
        renderOptions: options.renderOptions ?? {
            render: (props) => reportNameRenderer<any>(props),
        },
        width: 200,
    })
}

export function reportDateRange<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createField({
        ...options,
        id: 'reportDateRange',
        name: 'Report Date Range',
        shortName: 'Report Date Range',
        dataIndex: options.dataIndex ?? ['date_range'],
        minWidth: 150,
        renderOptions: options.renderOptions ?? {
            render: ({ record }) => renderReportDateRange<any>(record),
        },
        width: 200,
        sorter: options.sorter ?? false,
        align: 'left',
    })
}

export function reportCreatedDate<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createCerebroDateTimeField({
        ...options,
        id: 'reportCreatedDate',
        name: 'Created Date',
        shortName: 'Created Date',
        dataIndex: options.dataIndex ?? ['created_date'],
        minWidth: 150,
        renderOptions: options.renderOptions ?? {
            render: ({ value }) => formatCerebroDateTime(value),
        },
        align: 'left',
        width: 200,
    })
}

export function reportActions<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createActionField({
        ...options,
        id: 'reportActions',
        dataIndex: options.dataIndex ?? ['id'],
        minWidth: 150,
        renderOptions: options.renderOptions ?? {
            render: (props) => reportActionsRenderer<any>(props),
        },
        align: 'center',
        width: 200,
        sorter: options.sorter ?? false,
    })
}
