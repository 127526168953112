import { connect } from 'react-redux'

import { signOutRequest } from 'actions/auth'
import {
    downloadReport,
    fetchReportsForDrawerRequest,
    fetchTriggeredAlertsForDrawerRequest,
    fetchUpdatesForDrawerRequest,
} from 'actions/ui/app'
import { selectDomainValue as selectAuthDomainValue } from 'selectors/auth'
import { selectDomainValue } from 'selectors/ui'

import PageHeader from './PageHeader'

const mapStateToProps = (state) => {
    return {
        userEmail: selectAuthDomainValue(state, 'email'),
        newReports: selectDomainValue(state, ['app', 'newReports']),
        newUpdates: selectDomainValue(state, ['app', 'newUpdates']),
        triggeredAlerts: selectDomainValue(state, [
            'app',
            'triggeredAlerts',
            'results',
        ]),
        drawerReports: selectDomainValue(state, [
            'app',
            'drawerReports',
            'reports',
        ]),
        drawerReportsLoading: selectDomainValue(state, [
            'app',
            'drawerReports',
            'loading',
        ]),
        drawerUpdates: selectDomainValue(state, [
            'app',
            'drawerUpdates',
            'updates',
        ]),
        drawerUpdatesLoading: selectDomainValue(state, [
            'app',
            'drawerUpdates',
            'loading',
        ]),
    }
}

const mapDispatchToProps = {
    signOutRequest,
    fetchReportsForDrawerRequest,
    fetchUpdatesForDrawerRequest,
    fetchTriggeredAlertsForDrawerRequest,
    downloadReport,
}

export default connect(mapStateToProps, mapDispatchToProps)(PageHeader)
