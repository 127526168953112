import { createAction } from 'redux-actions'

const NAMESPACE = 'CAMPAIGN_PAGE/AUTOMATION'

// Automation capabilities
export const fetchCapabilitiesRequest = createAction(
    `${NAMESPACE}/FETCH_CAPABILITIES_REQUEST`
)
export const fetchCapabilitiesSuccess = createAction(
    `${NAMESPACE}/FETCH_CAPABILITIES_SUCCESS`
)

// Automation tasks
export const fetchTasksRequest = createAction(
    `${NAMESPACE}/FETCH_TASKS_REQUEST`
)
export const fetchTasksSuccess = createAction(
    `${NAMESPACE}/FETCH_TASKS_SUCCESS`
)

// Automation Profiles
export const fetchAutomationProfilesRequest = createAction(
    `${NAMESPACE}/FETCH_AUTOMATION_PROFILES_REQUEST`
)
export const fetchAutomationProfilesSuccess = createAction(
    `${NAMESPACE}/FETCH_AUTOMATION_PROFILES_SUCCESS`
)
export const fetchAutomationProfilesFailure = createAction(
    `${NAMESPACE}/FETCH_AUTOMATION_PROFILES_FAILURE`
)
