import { ReactElement, ReactNode } from 'react'

import { Card } from 'antd'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    children: ReactNode
}

const ContainerWithUpperContent = ({
    className,
    children,
}: Props): ReactElement => (
    <Card bodyStyle={{ padding: 0 }} bordered className={className}>
        {children}
    </Card>
)

export default ContainerWithUpperContent
