import { put, call, select } from 'redux-saga/effects'

import {
    makeFetchResourceSuccess,
    makeUpdateResourceSuccess,
} from 'actions/ui/shared/resource'
import { LABEL_PAGE } from 'const/pages'
import { cerebroApiSaga } from 'sagas/common'
import uiSagaRegistry from 'sagas/ui/registry'
import { selectResourceParamsOfPage } from 'selectors/ui'
import { getLabel, updateLabel } from 'services/cerebroApi/orgScope/resourceApi'

function* updateLabelSaga({ labelId }, params) {
    yield call(
        cerebroApiSaga,
        makeUpdateResourceSuccess(LABEL_PAGE),
        updateLabel,
        labelId,
        params
    )
}

function* fetchLabelSaga() {
    const { labelId } = yield select(selectResourceParamsOfPage, LABEL_PAGE)

    const { data: label } = yield call(cerebroApiSaga, null, getLabel, labelId)

    yield put(makeFetchResourceSuccess(LABEL_PAGE)(label))
}

/**
 * Mounts the Ad Group Page and fetches data
 */
function* mountPageSaga() {
    yield call(fetchLabelSaga)
}

uiSagaRegistry.registerSagas([LABEL_PAGE], {
    mountPageSaga,
    updateResourceSaga: updateLabelSaga,
})
