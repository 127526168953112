import { ReactElement } from 'react'

import { Select } from 'antd'
import classNames from 'classnames'
import every from 'lodash/every'
import map from 'lodash/map'
import pick from 'lodash/pick'

import { STATE_FIELD_OPTIONS, PAUSED, ENABLED } from 'const/resourceStates'
import { Campaign } from 'types'

import { ProductAdField } from './localTypes'
import styles from './styles.scss'
import { AdGroupSelectField } from '../Shared/AdGroupSelectField'

interface Props {
    campaign: Campaign
    products: ProductAdField[]
    setFieldValue: (name: string, values: any) => void
}

const CreateProductAdFormAllRow = ({
    campaign,
    products = [],
    setFieldValue,
}: Props): ReactElement => {
    const adGroupIdValue = every(products, ['adGroupId', products[0].adGroupId])
        ? products[0].adGroupId
        : undefined
    const stateValue = every(products, ['state', products[0].state])
        ? products[0].state
        : undefined

    const handleChange = (val: string | number, field: string): void => {
        products.forEach((_keyword, index) => {
            setFieldValue(`products.${index}.${field}`, val)
        })
    }

    return (
        <div
            className={classNames(
                'd-flex align-items-start',
                styles['bulk-edit-row']
            )}
        >
            {/* TEXT COLUMN PLACEHOLDER */}
            <div style={{ flex: 1 }} className="mr-1 flex-1" />

            <div
                className={classNames(
                    'd-flex flex-column align-items-center mr-1',
                    styles.field,
                    styles['ad-group-column']
                )}
            >
                <AdGroupSelectField
                    selectedAdGroup={adGroupIdValue}
                    campaign={campaign}
                    onChange={(val) => {
                        handleChange(val, 'adGroupId')
                    }}
                />
            </div>

            <div
                className={classNames(
                    'd-flex flex-column align-items-center mr-1',
                    styles.field,
                    styles['state-column']
                )}
            >
                <Select
                    value={stateValue}
                    placeholder="Select State"
                    onChange={(val) => {
                        handleChange(val, 'state')
                    }}
                >
                    {map(
                        pick(STATE_FIELD_OPTIONS, [ENABLED, PAUSED]),
                        (label, value) => (
                            <Select.Option key={value} value={value}>
                                {label}
                            </Select.Option>
                        )
                    )}
                </Select>
            </div>

            <div
                className={classNames(
                    'd-flex flex-column align-items-center mr-1',
                    styles.field,
                    styles['remove-column']
                )}
            />
        </div>
    )
}

export default CreateProductAdFormAllRow
