import { call, put, select } from 'redux-saga/effects'

import { makeFetchTableRequest, makeFetchTableSuccess } from 'actions/ui/shared'
import { AUTOMATION_PROFILE_PAGE } from 'const/pages'
import { formatPagination, formatSorter } from 'helpers/params'
import { formatAutomationsFilters } from 'helpers/ui/automationPage'
import { cerebroApiSaga } from 'sagas/common'
import uiSagaRegistry from 'sagas/ui/registry'
import {
    selectVisibleCombinedFilters,
    selectTableSettings,
    selectResourceParamsOfPage,
} from 'selectors/ui'
import { getAutomationTasks } from 'services/cerebroApi/orgScope/resourceApi'

import { fetchAutomationProfileSaga } from '../page'

const TAB_PATH = [AUTOMATION_PROFILE_PAGE, 'automations']
const TABLE_PATH = [...TAB_PATH, 'table']

/**
 * Fetch table data
 */
function* fetchTableSaga(noCount) {
    const { profileId } = yield select(
        selectResourceParamsOfPage,
        AUTOMATION_PROFILE_PAGE
    )

    const filters = yield select(selectVisibleCombinedFilters, TAB_PATH)
    const { pagination, sorter } = yield select(selectTableSettings, TABLE_PATH)
    const params = {
        ...formatAutomationsFilters(filters),
        ...formatPagination(pagination),
        ...formatSorter(sorter),
        automation_profile: profileId,
    }

    yield call(
        cerebroApiSaga,
        makeFetchTableSuccess(TABLE_PATH),
        getAutomationTasks,
        params,
        { headers: { noCount } }
    )

    // the automation_task_count may change when table is updated, simply refetch the profile data here
    yield call(fetchAutomationProfileSaga)
}

/**
 * Fetches all data for automations tab
 */
function* fetchTabDataSaga() {
    yield put(makeFetchTableRequest(TABLE_PATH)())
}

// Register sagas
uiSagaRegistry.registerSagas(TAB_PATH, {
    fetchTabDataSaga,
})

uiSagaRegistry.registerSagas(TABLE_PATH, {
    fetchTableSaga,
})
