import { combineChildrenReducers } from 'utilities/reducers'

import adItemsReducer from './adItems'
import keywordsReducer from './keywords'
import pageReducer from './page'

export default combineChildrenReducers(pageReducer, {
    keywords: keywordsReducer,
    adItems: adItemsReducer,
})
