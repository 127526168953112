import { ReactElement, ReactNode } from 'react'

import { AutomationTypeContainer } from 'components/AutomationType'
import {
    AutomationStateField,
    AutomationDetachProfileField,
    AutomationDeleteProfileField,
} from 'components/EditableFields/AutomationFields'
import { AutomationProfileLink } from 'components/Links'
import { UNDEFINED_VALUE } from 'const/formatting'
import { isUnset } from 'helpers/utilities'
import {
    AutomationTask,
    AutomationProfile,
    CellRenderProps,
    ChangelogRecord,
} from 'types'

type RenderProps<RecordType> = {
    cellRenderProps: CellRenderProps<RecordType>
    dataIndex?: string[]
}

export function automationCapabilityIdRenderer<
    RecordType extends ChangelogRecord,
>({
    cellRenderProps: { value, record },
}: RenderProps<RecordType>): ReactElement {
    if (isUnset(value)) {
        return <span>{UNDEFINED_VALUE}</span>
    }

    const capability_id = record.automation_capability_id
    return <AutomationTypeContainer capabilityId={capability_id} />
}

export function capabilityIdRenderer<RecordType extends AutomationTask>({
    cellRenderProps: { value, record },
}: RenderProps<RecordType>): ReactElement {
    if (isUnset(value)) {
        return <span>{UNDEFINED_VALUE}</span>
    }

    const capability_id = record.capability.id
    return <AutomationTypeContainer capabilityId={capability_id} />
}

export function automationStateRenderer<RecordType extends AutomationTask>({
    cellRenderProps: { readonly, record },
    dataIndex,
}: RenderProps<RecordType>): ReactElement {
    return (
        <AutomationStateField
            dataIndex={dataIndex}
            readOnly={readonly}
            record={record}
        />
    )
}

export function automationDetachProfileRenderer<
    RecordType extends AutomationTask,
>({
    cellRenderProps: { readonly, record, reloadData },
}: RenderProps<RecordType>): ReactElement {
    return (
        <AutomationDetachProfileField
            record={record}
            readOnly={readonly}
            reloadData={reloadData}
        />
    )
}

export function automationDeleteProfileRenderer<
    RecordType extends AutomationProfile,
>({
    cellRenderProps: { readonly, record, reloadData },
}: RenderProps<RecordType>): ReactElement {
    return (
        <AutomationDeleteProfileField
            record={record}
            readOnly={readonly}
            reloadData={reloadData}
        />
    )
}

export function automationProfileIdRenderer<
    RecordType extends AutomationProfile,
>({ record }: CellRenderProps<RecordType>): ReactNode {
    return <AutomationProfileLink automationProfile={record} />
}
