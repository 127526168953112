import { memo, ReactElement } from 'react'

import isEqual from 'lodash/isEqual'
import { useTranslation } from 'react-i18next'

import {
    LINE_ITEM_BID_MIN,
    LINE_ITEM_BID_MAX,
    LINE_ITEM_BID_STEP,
    LINE_ITEM_BID_PRECISION,
} from 'const/lineItems'
import { updateDspLineItem } from 'services/cerebroApi/orgScope/dspApi'
import { DspLineItemGroupBy, CurrencyCode } from 'types'

import { BidField } from '../../SharedFields/BidField'

interface Props<RecordType> {
    dataIndex: string[]
    readOnly: boolean
    record: RecordType
}

function LineItemBidField<RecordType extends DspLineItemGroupBy>({
    dataIndex,
    readOnly = false,
    record,
}: Props<RecordType>): ReactElement {
    const { t } = useTranslation('table')

    const { line_item_id: lineItemId, line_item: lineItem } = record
    const currencyCode: CurrencyCode = lineItem.currency_code as CurrencyCode

    return (
        <BidField
            bidMax={LINE_ITEM_BID_MAX}
            bidMin={LINE_ITEM_BID_MIN}
            bidPrecision={LINE_ITEM_BID_PRECISION}
            bidStep={LINE_ITEM_BID_STEP}
            currencyCode={currencyCode}
            disabled={!lineItem.user_can_manage}
            fieldName="bid"
            fieldPath={dataIndex}
            readOnly={readOnly}
            record={record}
            updateRequestApi={updateDspLineItem}
            updateRequestData={lineItemId}
            updateRequestSuccessMesg={t(
                'table:fields.lineItemBid.successMsg',
                'Successfully updated line item bid'
            )}
        />
    )
}

export default memo(LineItemBidField, isEqual)
