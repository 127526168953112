import { SVGAttributes, ReactElement } from 'react'

type Props = SVGAttributes<SVGElement>

function SvgCampaignIcon(props: Props): ReactElement {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M13.721 5.225c.093.043.19.063.286.063a.699.699 0 00.638-.424l.81-1.853a.726.726 0 00-.353-.948.693.693 0 00-.924.361l-.81 1.853a.727.727 0 00.353.948z" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.93 3.961a.704.704 0 01.522.45l2.986 8.048a.733.733 0 01-.098.692.692.692 0 01-.625.278l-4.502-.462-.9.351 2.186 2.743a.734.734 0 01.135.64.71.71 0 01-.427.485l-1.96.767a.684.684 0 01-.79-.216l-2.48-3.113-.183.071c-.32.125-.655.189-.996.189-1.152 0-2.203-.744-2.613-1.85a2.922 2.922 0 01.061-2.196 2.81 2.81 0 011.557-1.509l4.419-1.728 3.062-3.42a.687.687 0 01.646-.22zM5.7 12.428l1.307-.512a.723.723 0 00.405-.926.694.694 0 00-.902-.415l-1.307.511a.724.724 0 00-.405.926.7.7 0 00.654.463.684.684 0 00.249-.047z"
            />
            <path d="M16.963 6.486c.36-.14.765.045.902.416a.725.725 0 01-.404.926l-1.96.767a.683.683 0 01-.249.047.7.7 0 01-.653-.463.724.724 0 01.405-.926l1.96-.767zM16.782 10.623l1.805.83c.352.162.51.586.352.948a.698.698 0 01-.639.425.683.683 0 01-.285-.063l-1.805-.83a.726.726 0 01-.352-.949c.159-.361.572-.523.924-.361z" />
        </svg>
    )
}

export default SvgCampaignIcon
