import { handleActions } from 'redux-actions'

import { walmartCampaignsSummaryTableColumnsConfig } from 'configuration/tables'
import {
    AD_ACCOUNTS,
    CAMPAIGN_BUDGET_TYPES,
    CAMPAIGN_NAME,
    CAMPAIGN_STATES,
    CAMPAIGN_TARGETING_TYPES,
    DATES,
} from 'const/filters'
import { FILTER_SETTINGS, FILTERS } from 'const/reducerKeys'
import { serializeColumns } from 'helpers/tables'
import { getDefaultTable } from 'reducers/ui/defaults'

import { defaultPage } from '../defaults'

const defaultState = {
    ...defaultPage,
    [FILTERS]: {
        [CAMPAIGN_NAME]: null,
        [CAMPAIGN_STATES]: [],
        [CAMPAIGN_TARGETING_TYPES]: [],
        [CAMPAIGN_BUDGET_TYPES]: [],
        [AD_ACCOUNTS]: [],
    },
    [FILTER_SETTINGS]: {
        anchored: [CAMPAIGN_NAME, DATES],
        displayState: {
            [CAMPAIGN_STATES]: true,
            [CAMPAIGN_TARGETING_TYPES]: true,
            [CAMPAIGN_BUDGET_TYPES]: true,
            [AD_ACCOUNTS]: true,
        },
        order: [
            CAMPAIGN_STATES,
            CAMPAIGN_TARGETING_TYPES,
            CAMPAIGN_BUDGET_TYPES,
            AD_ACCOUNTS,
        ],
    },
    table: getDefaultTable(
        serializeColumns(walmartCampaignsSummaryTableColumnsConfig),
        { showTotalRow: true }
    ),
}

export default handleActions({}, defaultState)
