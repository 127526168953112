import { call, put, select } from 'redux-saga/effects'

import { makeFetchTableRequest, makeFetchTableSuccess } from 'actions/ui/shared'
import { KEYWORD_PAGE } from 'const/pages'
import { formatFilters } from 'helpers/filters/changelog'
import { cerebroApiSaga } from 'sagas/common'
import uiSagaRegistry from 'sagas/ui/registry'
import {
    selectResourceParamsOfPage,
    selectVisibleCombinedFilters,
} from 'selectors/ui'
import { getChangelog } from 'services/cerebroApi/orgScope/resourceApi'

const TAB_PATH = [KEYWORD_PAGE, 'bidHistory']
const CHART_PATH = [...TAB_PATH, 'chart']

/**
 * Fetch table data
 */
function* fetchChartSaga() {
    const { keywordId } = yield select(selectResourceParamsOfPage, KEYWORD_PAGE)
    const filters = yield select(selectVisibleCombinedFilters, TAB_PATH)

    const params = {
        ...formatFilters(filters),
        limit: 10000,
        offset: 0,
        ordering: 'date',
        object_type: 'keyword',
        object_id: keywordId,
        changed_fields_in: 'base_bid,bid',
    }

    yield call(
        cerebroApiSaga,
        makeFetchTableSuccess(CHART_PATH),
        getChangelog,
        params,
        { headers: { noCount: true } }
    )
}

/**
 * Fetch all data for tab
 */
function* fetchTabDataSaga() {
    yield put(makeFetchTableRequest(CHART_PATH)())
}

uiSagaRegistry.registerSagas(TAB_PATH, {
    fetchTabDataSaga,
})

uiSagaRegistry.registerSagas(CHART_PATH, {
    fetchTableSaga: fetchChartSaga,
})
