import { LegendOptions, Point, Series } from 'highcharts'

import { DEFAULT_HORIZONTAL_LEGEND } from 'helpers/chart'
import { formatTimeSeriesLegendItem } from 'helpers/charts'
import { TimeSeriesPointOptions } from 'types'

export const legend: LegendOptions = {
    ...DEFAULT_HORIZONTAL_LEGEND,
    labelFormatter() {
        function checkHasPointColor(point: Point | Series): point is Point {
            if ((point as Point).color) {
                return true
            }
            return false
        }
        const { visible, name, legendSymbol, legendItem } = this as any
        const { isColumn, isPriorPeriod, color, borderColor } = this
            .options as TimeSeriesPointOptions
        const { legendItemBorderColor, showlegendSymbol } = (this as any)
            .userOptions

        const symbolToShow = showlegendSymbol
            ? legendSymbol ?? legendItem
            : undefined

        if (checkHasPointColor(this)) {
            return formatTimeSeriesLegendItem({
                visible,
                name,
                isColumn,
                isPriorPeriod,
                bgColor: color,
                indicatorBorderColor:
                    isPriorPeriod && isColumn ? borderColor : this.color, // prior period columns use border color bc the series color is #fff
                itemBorderColor: legendItemBorderColor,
                legendSymbol: symbolToShow,
            })
        }

        return formatTimeSeriesLegendItem({
            visible,
            name,
            isColumn,
            isPriorPeriod,
            bgColor: 'transparent',
            indicatorBorderColor: 'transparent',
            itemBorderColor: legendItemBorderColor,
            legendSymbol: symbolToShow,
        })
    },
}
