import { all, takeLatest } from 'redux-saga/effects'

import { fetchOrganizationGroupPendingInvitationsRequest } from 'actions/ui/organizationGroupPage'

import { fetchPendingInvitationsWorker } from './workers'

export default function* organizationGroupPageWatcher(): any {
    yield all([
        takeLatest(
            fetchOrganizationGroupPendingInvitationsRequest.toString(),
            fetchPendingInvitationsWorker
        ),
    ])
}
