import { Translation } from 'react-i18next'

import {
    labelNameRenderer,
    labelDescriptionRenderer,
    labelColorRenderer,
    deleteLabelRenderer,
} from 'components/FieldRenderers'
import { Labels } from 'components/Labels'
import { Field } from 'types'

import {
    createActionField,
    createCerebroDateTimeField,
    createField,
} from './createField'
import { FieldCreatorOptions } from './localTypes'

export function labels<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['labels']
    const name = (
        <Translation>{(t) => t('common:labels', 'Labels')}</Translation>
    )
    return createField({
        ...options,
        id: 'labels',
        name,
        shortName: name,
        minWidth: 80,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: ({ value }) => <Labels labels={value} />,
        },
        width: 150,
        sorter: options.sorter ?? false,
    })
}

export function labelName<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['name']
    return createField({
        ...options,
        id: 'label__name',
        dataIndex,
        name: 'Label Name',
        shortName: 'Label',
        minWidth: 100,
        sorter: true,
        width: 200,
        renderOptions: options.renderOptions ?? {
            render: (props) => labelNameRenderer<any>(props),
        },
    })
}

export function labelDescription<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['description']
    return createField({
        ...options,
        id: 'label__description',
        dataIndex,
        name: 'Description',
        shortName: 'Description',
        minWidth: 200,
        sorter: true,
        renderOptions: options.renderOptions ?? {
            render: (cellRenderProps) =>
                labelDescriptionRenderer<any>({ cellRenderProps, dataIndex }),
        },
    })
}

export function labelColor<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['color']
    return createField({
        ...options,
        id: 'label__color',
        dataIndex,
        name: 'Color',
        shortName: 'Color',
        minWidth: 300,
        sorter: true,
        renderOptions: options.renderOptions ?? {
            render: (cellRenderProps) =>
                labelColorRenderer<any>({ cellRenderProps, dataIndex }),
        },
    })
}

export function labelCreatedDate<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['created_date']
    return createCerebroDateTimeField({
        ...options,
        id: 'label__created_date',
        dataIndex,
        name: 'Created Date',
        shortName: 'Created Date',
        minWidth: 150,
        sorter: true,
    })
}

export function deleteLabel<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createActionField({
        ...options,
        id: 'delete_label',
        dataIndex: [],
        isResizeable: false,
        minWidth: 68,
        renderOptions: options.renderOptions ?? {
            render: (props) => deleteLabelRenderer<any>(props),
        },
        sorter: false,
        fixed: 'right',
        align: 'center',
    })
}
