import { schema } from 'normalizr'

// users
const userSchema = new schema.Entity('users')
export const userListSchema = new schema.Array(userSchema)

// organizations
export const organizationSchema = new schema.Entity('organizations', {
    owner: userSchema,
})
export const organizationListSchema = new schema.Array(organizationSchema)

// org groups
export const organizationGroupSchema = new schema.Entity('groups', {
    organization: organizationSchema,
    members: [userSchema],
})
export const organizationGroupListSchema = new schema.Array(
    organizationGroupSchema
)

// dashboards
const widgetSchema = new schema.Entity('widgets')
export const tabSchema = new schema.Entity('tabs', {
    widgets: [widgetSchema],
})
export const tabListSchema = new schema.Array(tabSchema)
