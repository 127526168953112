import { handleActions } from 'redux-actions'

import { organizationMemberTableColumnsConfig } from 'configuration/tables'
import { serializeColumns } from 'helpers/tables'
import { defaultTab, getDefaultTable } from 'reducers/ui/defaults'

const initialState = {
    ...defaultTab,
    table: getDefaultTable(
        serializeColumns(organizationMemberTableColumnsConfig),
        {
            sorter: {
                field: 'date_joined',
                order: 'descend',
            },
        }
    ),
}

export default handleActions({}, initialState)
