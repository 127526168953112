import produce from 'immer'
import set from 'lodash/set'

import { Field, FieldSerialized, FieldMapping } from 'types'

export function serializeColumns<RecordType>(
    columns: Field<RecordType>[]
): FieldSerialized[] {
    return columns.map((col) => ({
        id: col.id,
        isVisible: col.isVisible,
        antTableColumnOptions: {
            width: col.antTableColumnOptions.width,
        },
        ...(col.childrenFields
            ? {
                  childrenFields: col.childrenFields.map((child) => ({
                      isVisible: child.isVisible,
                      antTableColumnOptions: {
                          width: child.antTableColumnOptions.width,
                      },
                  })),
              }
            : {}),
    }))
}

export function deserializeColumns<RecordType>(
    columnsSerialized: (FieldSerialized | string)[],
    columns: Field<RecordType>[]
): Field<RecordType>[] {
    const mapping: FieldMapping<RecordType> = {}
    columns.forEach((each) => {
        mapping[each.id] = each
    })

    // columnsSerialized may have fields that does not exist in columns if we remove certain things in columns
    // because columnsSerialized is saved in localStorage or rds
    const columnsSerializedFiltered = columnsSerialized.filter((each) => {
        return (typeof each === 'string' ? each : each?.id) in mapping
    })

    return columnsSerializedFiltered
        .filter((col) => {
            const key = typeof col === 'string' ? col : col.id
            return !!mapping[key]
        })
        .map((col) => {
            const key = typeof col === 'string' ? col : col.id
            const columnsDeserialized = mapping[key]!
            return produce(columnsDeserialized, (draft) => {
                const visible =
                    typeof col === 'string' ? undefined : col.isVisible
                if (draft) {
                    draft.isVisible = visible ?? draft.isVisible

                    // if the column is configured to not be resizeable,
                    // always use the official width over the serialized width
                    if (draft.isResizeable) {
                        const width =
                            typeof col === 'string'
                                ? undefined
                                : col?.antTableColumnOptions?.width

                        draft.antTableColumnOptions.width =
                            width ?? draft.antTableColumnOptions.width
                    }

                    if (
                        typeof col !== 'string' &&
                        draft.childrenFields &&
                        col.childrenFields
                    ) {
                        draft.childrenFields.forEach(
                            (_child, idx, children) => {
                                const childSerialized =
                                    col.childrenFields?.[idx]
                                set(
                                    children,
                                    [idx, 'antTableColumnOptions', 'width'],
                                    childSerialized?.antTableColumnOptions
                                        ?.width
                                )
                                set(
                                    children,
                                    [idx, 'isVisible'],
                                    childSerialized?.isVisible
                                )
                            }
                        )
                    }
                }
            })
        })
}
