import { Select, InputNumber } from 'antd'
import { Field } from 'formik'
import { TFunction } from 'i18next'
import map from 'lodash/map'
import pick from 'lodash/pick'

import { FormikSelect, FormikInputNumber } from 'components/formik'
import { MATCH_TYPE_OPTIONS } from 'const/matchTypes'
import { ENABLED, PAUSED, STATE_FIELD_OPTIONS } from 'const/resourceStates'
import {
    WALMART_KEYWORD_BID_MIN,
    WALMART_KEYWORD_BID_MAX,
    WALMART_KEYWORD_BID_STEP,
    WALMART_KEYWORD_BID_PRECISION,
} from 'const/walmart'
import { getMetricValueFormatter } from 'helpers/formatters'
import { parseCurrency } from 'helpers/inputNumber'
import { WalmartCampaign } from 'types'

import { WalmartAdGroupSelectField, WalmartAdGroupSelectFormikField } from '..'
import { ColumnConfig } from '../../CreateKeywordModal/localTypes'

const getBasicWalmartKeywordCreationColumnsConfig = (
    t: TFunction
): ColumnConfig[] => [
    {
        name: 'Match Type',
        dataKey: 'matchType',
        width: '15%',
        renderField: (fieldName) => (
            <Field
                name={fieldName}
                component={FormikSelect}
                placeholder={t(
                    'table:fields.walmartKeyword.match_type.selectionText',
                    'Select Match Type'
                )}
            >
                {MATCH_TYPE_OPTIONS.map(({ value, label }) => (
                    <Select.Option key={value} value={value}>
                        {label}
                    </Select.Option>
                ))}
            </Field>
        ),
        renderBulkField: (initialValue, handleChange) => (
            <Select
                placeholder={t(
                    'table:fields.walmartKeyword.match_type.selectionText',
                    'Select Match Type'
                )}
                value={initialValue}
                onChange={handleChange}
            >
                {MATCH_TYPE_OPTIONS.map(({ value, label }) => (
                    <Select.Option key={value} value={value}>
                        {label}
                    </Select.Option>
                ))}
            </Select>
        ),
    },
    {
        name: 'Bid',
        dataKey: 'bid',
        width: '11%',
        renderField: (fieldName) => (
            <Field
                name={fieldName}
                component={FormikInputNumber}
                formatter={getMetricValueFormatter('currencyAmount')}
                parser={parseCurrency}
                min={WALMART_KEYWORD_BID_MIN}
                max={WALMART_KEYWORD_BID_MAX}
                step={WALMART_KEYWORD_BID_STEP}
                precision={WALMART_KEYWORD_BID_PRECISION}
            />
        ),
        renderBulkField: (initialValue, handleChange) => (
            <InputNumber
                onChange={handleChange}
                value={initialValue}
                formatter={getMetricValueFormatter('currencyAmount')}
                parser={parseCurrency}
                min={WALMART_KEYWORD_BID_MIN}
                max={WALMART_KEYWORD_BID_MAX}
                step={WALMART_KEYWORD_BID_STEP}
                precision={WALMART_KEYWORD_BID_PRECISION}
            />
        ),
    },
    {
        name: 'State',
        dataKey: 'state',
        width: '12%',
        renderField: (fieldName) => (
            <Field
                name={fieldName}
                component={FormikSelect}
                placeholder={t(
                    'table:fields.walmartKeyword.state.selectionText',
                    'Select State'
                )}
            >
                {map(
                    pick(STATE_FIELD_OPTIONS, [ENABLED, PAUSED]),
                    (label, value) => (
                        <Select.Option key={value} value={value}>
                            {label}
                        </Select.Option>
                    )
                )}
            </Field>
        ),
        renderBulkField: (initialValue, handleChange) => (
            <Select
                value={initialValue}
                placeholder={t(
                    'table:fields.walmartKeyword.state.selectionText',
                    'Select State'
                )}
                onChange={handleChange}
            >
                {map(
                    pick(STATE_FIELD_OPTIONS, [ENABLED, PAUSED]),
                    (label, value) => (
                        <Select.Option key={value} value={value}>
                            {label}
                        </Select.Option>
                    )
                )}
            </Select>
        ),
    },
]

export const getWalmartAdGroupKeywordCreationColumnsConfig = (
    t: TFunction
): ColumnConfig[] => getBasicWalmartKeywordCreationColumnsConfig(t)

export const getWalmartCampaignKeywordCreationColumnsConfig = (
    t: TFunction,
    campaign: WalmartCampaign
): ColumnConfig[] => {
    const config = getBasicWalmartKeywordCreationColumnsConfig(t)
    config.splice(2, 0, {
        name: 'Ad Group',
        dataKey: 'adGroupId',
        width: '20%',

        renderField: (fieldName) => (
            <WalmartAdGroupSelectFormikField
                name={fieldName}
                campaign={campaign}
            />
        ),
        renderBulkField: (initialValue, handleChange) => {
            return (
                <WalmartAdGroupSelectField
                    selectedAdGroup={initialValue}
                    campaign={campaign}
                    onChange={handleChange}
                />
            )
        },
    })
    return config
}
