import { ReactElement, useState } from 'react'

import { Button, Drawer, Tooltip } from 'antd'

import {
    AdGroupGroupBy,
    CampaignGroupBy,
    KeywordGroupBy,
    QueryFact,
    ResolvedExpressionGroupBy,
} from 'types'

import {
    KeywordDrawerContent,
    NegativeKeywordDrawerContent,
} from './DrawerContent'

interface Props<RecordType> {
    record: RecordType
}

type BaseRecordType = QueryFact<
    CampaignGroupBy &
        AdGroupGroupBy &
        KeywordGroupBy &
        ResolvedExpressionGroupBy
>

function isSupported<RecordType extends BaseRecordType>(
    record: RecordType
): boolean {
    return (
        !!record.keyword ||
        ['loose-match', 'close-match'].includes(record.resolved_expression)
    )
}

const addKeywordLabel = '+ Add Keyword'
const addNegativeKeywordLabel = '+ Add Negative'

function AddKeywordCell<RecordType extends BaseRecordType>({
    record,
}: Props<RecordType>): ReactElement {
    const [isKeywordDrawerOpen, setKeywordDrawerOpen] = useState(false)
    const [isNegativeKeywordDrawerOpen, setNegativeKeywordDrawerOpen] =
        useState(false)

    if (!isSupported(record)) {
        const toolTipTitle = 'Only available for Keyword Search Terms'
        return (
            <>
                <Tooltip placement="top" title={toolTipTitle}>
                    <Button disabled className="mr-2">
                        {addKeywordLabel}
                    </Button>
                </Tooltip>
                <Tooltip placement="top" title={toolTipTitle}>
                    <Button disabled>{addNegativeKeywordLabel}</Button>
                </Tooltip>
            </>
        )
    }

    const selectedSearchTerm = {
        id: record.query,
        record,
    }

    const toggleKeywordDrawer = (): void => {
        setKeywordDrawerOpen((open) => !open)
    }

    const toggleNegativeKeywordDrawer = (): void => {
        setNegativeKeywordDrawerOpen((open) => !open)
    }

    return (
        <>
            <Drawer
                title="Add Search Term as Keyword"
                placement="right"
                closable
                onClose={toggleKeywordDrawer}
                open={isKeywordDrawerOpen}
                width="700"
            >
                <KeywordDrawerContent
                    selectedSearchTerm={selectedSearchTerm}
                    closeDrawer={toggleKeywordDrawer}
                />
            </Drawer>
            <Drawer
                title="Add Search Term as Negative Keyword"
                placement="right"
                closable
                onClose={toggleNegativeKeywordDrawer}
                open={isNegativeKeywordDrawerOpen}
                width="700"
            >
                <NegativeKeywordDrawerContent
                    selectedSearchTerm={selectedSearchTerm}
                    closeDrawer={toggleNegativeKeywordDrawer}
                />
            </Drawer>
            <Button onClick={toggleKeywordDrawer} className="mr-2">
                {addKeywordLabel}
            </Button>
            <Button onClick={toggleNegativeKeywordDrawer}>
                {addNegativeKeywordLabel}
            </Button>
        </>
    )
}

export default AddKeywordCell
