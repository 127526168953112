import { handleActions } from 'redux-actions'

import { dashboardsTableColumnsConfig } from 'configuration/tables'
import { serializeColumns } from 'helpers/tables'

import { getDefaultTable } from '../../defaults'

const initialState = {
    table: getDefaultTable(serializeColumns(dashboardsTableColumnsConfig), {
        sorter: {
            field: 'created_at',
            order: 'descend',
        },
    }),
}

export default handleActions({}, initialState)
