import { ReactElement } from 'react'

import { Translation } from 'react-i18next'

import {
    DetailTag,
    DetailText,
    DetailWrapper,
} from 'components/ResourceDetailsV2/Renderers/shared'
import { WalmartAdvertiser } from 'types'

export function walmartAdvertiserNameRenderer(
    advertiser: WalmartAdvertiser
): ReactElement | string {
    if (!advertiser.read_only) {
        return advertiser.alias
    }

    return (
        <DetailWrapper
            leftContent={
                <DetailTag
                    text={
                        <Translation>
                            {(t) => t('common:readOnly', 'Read Only')}
                        </Translation>
                    }
                />
            }
            rightContent={<DetailText text={advertiser.alias} />}
        />
    )
}
