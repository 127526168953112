import { ReactElement } from 'react'

import Icon from '@ant-design/icons'

import MoreIconSvg from './MoreIconSvg'
import { IconProps } from '../localTypes'

const MoreIcon = (props: IconProps): ReactElement => (
    <Icon component={MoreIconSvg} {...props} />
)

export default MoreIcon
