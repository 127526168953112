import { memo, ReactElement } from 'react'

import { AxiosResponse } from 'axios'
import isEqual from 'lodash/isEqual'

import { UNDEFINED_VALUE } from 'const/formatting'
import { isUnset } from 'helpers/utilities'
import { CurrencyCode, SuggestedBid } from 'types'

import SuggestedBidFieldBody from './SuggestedBidFieldBody'

interface Props<RecordType> {
    currencyCode: CurrencyCode
    disabled?: boolean
    fieldName: keyof RecordType
    readOnly?: boolean
    record: RecordType
    reloadData: () => void
    rowIndex: number
    serializeFieldValues?: (params: { bid: number }) => object
    updateRecord: (data: { rowIndex: number; record: RecordType }) => void
    updateRequestApi: (...args: any) => Promise<AxiosResponse<any>>
    updateRequestData: any
    updateRequestSuccessMesg: string
    value: SuggestedBid
    actionButtonVisible?: boolean
}

function SuggestedBidField<RecordType>({
    value,
    actionButtonVisible = true,
    ...formBodyProps
}: Props<RecordType>): ReactElement {
    if (isUnset(value) && actionButtonVisible) {
        return <div>Not Available</div>
    }
    if (isUnset(value) && !actionButtonVisible) {
        return <div>{UNDEFINED_VALUE}</div>
    }
    return (
        <SuggestedBidFieldBody
            value={value}
            actionButtonVisible={actionButtonVisible}
            {...formBodyProps}
        />
    )
}

export default memo(SuggestedBidField, isEqual) as typeof SuggestedBidField
