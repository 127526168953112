import { call, select, put } from 'redux-saga/effects'

import { makeFetchTableRequest, makeFetchTableSuccess } from 'actions/ui/shared'
import { walmartAdItemsSummaryTableColumnsConfig } from 'configuration/tables'
import { DATES } from 'const/filters'
import { WALMART_ITEMS_SUMMARY_PAGE } from 'const/pages'
import { formatFilters } from 'helpers/filters/wmAdvertisingFacts'
import {
    formatPagination,
    formatCurrency,
    formatSorter,
    formatMetrics,
    formatPeriodDeltaDateRange,
} from 'helpers/params'
import { cerebroApiSaga } from 'sagas/common'
import uiSagaRegistry from 'sagas/ui/registry'
import {
    selectCurrencyCode,
    selectTableSettings,
    selectVisibleCombinedFilters,
    selectVisibleMetricsOfTable,
} from 'selectors/ui'
import { getWmAdvertisingItemFacts } from 'services/cerebroApi/orgScope/walmartApi'

const TAB_PATH = [WALMART_ITEMS_SUMMARY_PAGE, 'adItems']
const TABLE_PATH = [...TAB_PATH, 'table']

function* fetchTableSaga(noCount) {
    const currency = yield select(selectCurrencyCode)
    const {
        pagination,
        sorter,
        showPeriodDeltas,
        periodDeltaType,
        periodDeltaDateRange,
        showTotalRow,
    } = yield select(selectTableSettings, TABLE_PATH)
    const metrics = yield select(
        selectVisibleMetricsOfTable,
        TABLE_PATH,
        walmartAdItemsSummaryTableColumnsConfig
    )
    const filters = yield select(selectVisibleCombinedFilters, TAB_PATH)

    const params = {
        ...formatFilters(filters),
        ...formatPagination(pagination),
        ...formatSorter(sorter),
        ...formatCurrency(currency),
        ...formatMetrics(metrics, showPeriodDeltas, showTotalRow),
        ...formatPeriodDeltaDateRange(
            showPeriodDeltas,
            periodDeltaType,
            periodDeltaDateRange,
            filters[DATES]
        ),
        group_by: 'item_id,ad_item_id,ad_group_id,campaign_id,advertiser_id',
    }
    yield call(
        cerebroApiSaga,
        makeFetchTableSuccess(TABLE_PATH),
        getWmAdvertisingItemFacts,
        params,
        { headers: { noCount } }
    )
}

function* fetchTabDataSaga() {
    yield put(makeFetchTableRequest(TABLE_PATH)())
}

// Register Sagas
uiSagaRegistry.registerSagas(TAB_PATH, {
    fetchTabDataSaga,
})

uiSagaRegistry.registerSagas(TABLE_PATH, {
    fetchTableSaga,
})
