import { useState, ReactElement } from 'react'

import { LoadingOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

import { TextButton } from 'components/TextButton'
import { useCerebroApiRequest } from 'hooks'
import { updateInvite } from 'services/cerebroApi/orgScope/resourceApi'
import message from 'utilities/message'

interface Props {
    inviteId: string
    reloadData: () => void
}

function RevokeInviteLink({ inviteId, reloadData }: Props): ReactElement {
    const makeCerebroApiRequest = useCerebroApiRequest()
    const [loading, setLoading] = useState(false)
    const { t } = useTranslation('account')

    function revokeInvite(): void {
        setLoading(true)

        makeCerebroApiRequest({
            request: updateInvite(inviteId, { state: 'revoked' }),
            onRequestSuccess: () => {
                setLoading(false)
                reloadData()
                message.success(
                    t(
                        'account:RevokeInviteLink.successMsg',
                        'Invitation successfully revoked!'
                    )
                )
            },
            onRequestFailure: () => {
                setLoading(false)
            },
        })
    }

    if (loading) {
        return <LoadingOutlined />
    }

    return (
        <TextButton onClick={() => revokeInvite()} link>
            {t('account:RevokeInviteLink.submitBtnTxt', 'Revoke')}
        </TextButton>
    )
}

export default RevokeInviteLink
