import { captureException } from '@sentry/browser'
import { message } from 'antd'
import get from 'lodash/get'
import isUndefined from 'lodash/isUndefined'
import { call, put, select, delay, race, spawn, take } from 'redux-saga/effects'

import {
    makeFetchTableFailure,
    makeFetchTableSuccess,
    makeSetTableLoading,
} from 'actions/ui/shared'
import { makeUnmountPageRequest } from 'actions/ui/shared/page'
import { REPORTS_SUMMARY_PAGE } from 'const/pages'
import {
    BACK_OFF_RATE,
    POLL_INTERVAL,
    POLL_INTERVAL_MAX,
    POLL_TIMEOUT,
} from 'const/polling'
import { formatPagination, formatSorter } from 'helpers/params'
import { isReportPending } from 'helpers/reports'
import { cerebroApiSaga } from 'sagas/common'
import uiSagaRegistry from 'sagas/ui/registry'
import {
    selectDomainValue as selectUiDomainValue,
    selectTableSettings,
} from 'selectors/ui'
import { getDownloads } from 'services/cerebroApi/orgScope/downloadsApi'

const TABLE_PATH = [REPORTS_SUMMARY_PAGE, 'table']

const isUnmountPageAction = ({ type, payload }) => {
    const unmountAction = makeUnmountPageRequest(REPORTS_SUMMARY_PAGE)()

    return (
        !isUndefined(get(payload, 'pageName')) &&
        type === unmountAction.type &&
        get(payload, 'pageName') === get(unmountAction, ['payload', 'pageName'])
    )
}

function* fetchTableSaga() {
    const { pagination, sorter } = yield select(selectTableSettings, TABLE_PATH)

    const params = {
        ...formatPagination(pagination),
        ...formatSorter(sorter),
    }

    const response = yield call(cerebroApiSaga, null, getDownloads, params)

    if (response) {
        yield put(makeFetchTableSuccess(TABLE_PATH)(response.data))
        return response.data.results
    }
    return null
}

function* checkUploadStatusSaga() {
    let delayInterval = POLL_INTERVAL
    while (true) {
        try {
            const results = yield call(fetchTableSaga)
            if (results && !results.some(isReportPending)) {
                break
            }
        } catch (error) {
            yield put(makeFetchTableFailure(TABLE_PATH)(error))
            break
        }
        yield delay(Math.min(delayInterval, POLL_INTERVAL_MAX))
        delayInterval += delayInterval * BACK_OFF_RATE
    }
}

function* startPollingSaga() {
    const { data } = yield select(selectUiDomainValue, TABLE_PATH)

    // start polling if there are pending reports
    let hasTimedOut = false
    if (data.some(isReportPending)) {
        const { timedOut } = yield race({
            response: call(checkUploadStatusSaga),
            timedOut: delay(POLL_TIMEOUT),
            unmount: take(isUnmountPageAction),
        })
        hasTimedOut = timedOut
    }

    if (hasTimedOut) {
        const error = {
            message:
                'This page has stopped checking the status of pending reports. Refresh the page to see the current status.',
        }

        yield call(captureException, error)
        const closeMessage = message.warning(error.message, 0)

        // wait until the user navigates away from the page to remove the message
        yield take(isUnmountPageAction)
        closeMessage()
    }
}

function* mountPageSaga() {
    // calling the saga (instead of putting an action) because it's necessary to
    // start polling AFTER initial table data has been fetched
    yield put(makeSetTableLoading(TABLE_PATH)(true))
    yield call(fetchTableSaga)
    yield put(makeSetTableLoading(TABLE_PATH)(false))

    // use spawn to start polling in 'detached' fork mode
    // see https://redux-saga.js.org/docs/advanced/ForkModel.html
    yield spawn(startPollingSaga)
}

uiSagaRegistry.registerSagas([REPORTS_SUMMARY_PAGE], {
    mountPageSaga,
})

uiSagaRegistry.registerSagas(TABLE_PATH, {
    fetchTableSaga,
})
