import { useEffect } from 'react'

import { notification } from 'antd'
import { useTranslation } from 'react-i18next'

function isEmptyValue(value: any): boolean {
    return (
        value === undefined ||
        value === null ||
        value === '' ||
        value === 'null' // for some reason we are persisting 'null' as a string sometimes :/
    )
}

function areSame(a: any, b: any): boolean {
    if (isEmptyValue(a) && isEmptyValue(b)) {
        return true
    }
    return a === b
}

function ImpersonationChangeAlert(): null {
    const { t } = useTranslation()

    useEffect(() => {
        const handleStorageEvent = (event: StorageEvent): void => {
            if (event.key === 'persist:auth') {
                const oldValue = JSON.parse(event.oldValue ?? '{}')
                const newValue = JSON.parse(event.newValue ?? '{}')
                if (
                    !areSame(
                        oldValue?.userIsImpersonating,
                        newValue?.userIsImpersonating
                    ) ||
                    !areSame(
                        oldValue?.organizationId,
                        newValue?.organizationId
                    ) ||
                    !areSame(
                        oldValue?.organizationGroupId,
                        newValue?.organizationGroupId
                    )
                ) {
                    if (window.location.pathname.startsWith('/auth')) {
                        return
                    }
                    notification.warning({
                        key: 'impersonationChangeAlert',
                        message: t(
                            'auth:impersonationChangeAlert.title',
                            'Organization change detected in another tab'
                        ),
                        description: t(
                            'auth:impersonationChangeAlert.description',
                            'Please refresh to ensure you are viewing the correct data'
                        ),
                        placement: 'top',
                        duration: null,
                        style: { width: 500 },
                    })
                }
            }
        }
        window.addEventListener('storage', handleStorageEvent)

        return () => {
            window.removeEventListener('storage', handleStorageEvent)
        }
    }, [t])
    return null
}

export default ImpersonationChangeAlert
