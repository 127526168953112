import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'

import { DATES, RESOURCE_TYPES, CUSTOM_EVENT_DESCRIPTION } from 'const/filters'
import { formatCerebroDate } from 'helpers/formatting'

import { presetRanges } from '../dateRange'

export const formatFilters = (filters) => {
    const params = {}

    if (!isEmpty(filters[DATES])) {
        if (isArray(filters[DATES])) {
            params.date_min = formatCerebroDate(filters[DATES][0])
            params.date_max = formatCerebroDate(filters[DATES][1])
        } else {
            const { range } = presetRanges()[filters[DATES]]
            params.date_min = formatCerebroDate(range[0])
            params.date_max = formatCerebroDate(range[1])
        }
    }

    if (!isEmpty(filters[RESOURCE_TYPES])) {
        params.resource_type__in = filters[RESOURCE_TYPES].map(
            (item) => item.value
        ).join()
    }

    if (!isEmpty(filters[CUSTOM_EVENT_DESCRIPTION])) {
        params.description__icontains = filters[CUSTOM_EVENT_DESCRIPTION]
    }

    return params
}
