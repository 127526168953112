import { ReactElement } from 'react'

import { DeleteOutlined } from '@ant-design/icons'
import { Select } from 'antd'
import classNames from 'classnames'
import { Field, ErrorMessage } from 'formik'

import { IconButton } from 'components/Buttons'
import { FormikSelect } from 'components/formik'
import { NEGATIVE_MATCH_TYPE_OPTIONS } from 'const/matchTypes'

import { KeywordField } from './localTypes'
import styles from './styles.scss'

interface Props {
    keyword: KeywordField
    keywordIndex: number
    remove: (index: number) => void
}

const CreateAdGroupNegativeKeywordFormRow = ({
    keyword,
    keywordIndex,
    remove,
}: Props): ReactElement => {
    return (
        <div className="d-flex align-items-start mt-2">
            <div style={{ flex: 1 }} className="mr-1 flex-1">
                {keyword.searchTerm}
            </div>
            <div
                className={classNames(
                    'd-flex flex-column align-items-center mr-1',
                    styles.field,
                    styles['match-type-column']
                )}
            >
                <Field
                    name={`keywords.${keywordIndex}.matchType`}
                    component={FormikSelect}
                    placeholder="Select Match Type"
                >
                    {NEGATIVE_MATCH_TYPE_OPTIONS.map(({ value, label }) => (
                        <Select.Option key={value} value={value}>
                            {label}
                        </Select.Option>
                    ))}
                </Field>
                <ErrorMessage
                    name={`keywords.${keywordIndex}.matchType`}
                    component="div"
                    className="fg-control-text is-error"
                />
            </div>

            <div
                className={classNames(
                    'd-flex flex-column align-items-center mr-1',
                    styles.field,
                    styles['remove-column']
                )}
            >
                <IconButton
                    icon={<DeleteOutlined />}
                    danger
                    type="link"
                    onClick={() => {
                        remove(keywordIndex)
                    }}
                />
            </div>
        </div>
    )
}

export default CreateAdGroupNegativeKeywordFormRow
