import { handleActions } from 'redux-actions'

import { productsSummaryProductAdsTableColumnsConfig } from 'configuration/tables'
import {
    DATES,
    REGIONS,
    COUNTRIES,
    BRANDS,
    PRODUCT_AD_STATES,
    PRODUCT_TITLE,
    PRODUCT_ASINS,
    PRODUCT_TITLES,
    PRODUCT_NUMBER_OF_ITEMS,
    LABELS,
    METRIC_FILTERS,
    PORTFOLIOS,
    FACT_TYPES,
} from 'const/filters'
import { LOCAL_FILTERS, FILTER_SETTINGS } from 'const/reducerKeys'
import { serializeColumns } from 'helpers/tables'
import { defaultTab, getDefaultTable } from 'reducers/ui/defaults'

const initialState = {
    ...defaultTab,
    [LOCAL_FILTERS]: {
        [PRODUCT_TITLE]: null,
        [FACT_TYPES]: [],
        [REGIONS]: [],
        [COUNTRIES]: [],
        [BRANDS]: [],
        [PORTFOLIOS]: [],
        [PRODUCT_ASINS]: [],
        [PRODUCT_AD_STATES]: [],
        [PRODUCT_TITLES]: [],
        [PRODUCT_NUMBER_OF_ITEMS]: [],
        [LABELS]: [],
        [METRIC_FILTERS]: [],
    },
    [FILTER_SETTINGS]: {
        anchored: [PRODUCT_TITLE, DATES, FACT_TYPES],
        order: [
            METRIC_FILTERS,
            REGIONS,
            COUNTRIES,
            BRANDS,
            PORTFOLIOS,
            PRODUCT_ASINS,
            PRODUCT_AD_STATES,
            PRODUCT_TITLES,
            PRODUCT_NUMBER_OF_ITEMS,
            LABELS,
        ],
        displayState: {
            [METRIC_FILTERS]: true,
            [REGIONS]: true,
            [COUNTRIES]: true,
            [BRANDS]: true,
            [PORTFOLIOS]: true,
            [PRODUCT_ASINS]: true,
            [PRODUCT_AD_STATES]: true,
            [PRODUCT_TITLES]: true,
            [PRODUCT_NUMBER_OF_ITEMS]: false,
            [LABELS]: true,
        },
    },
    table: getDefaultTable(
        serializeColumns(productsSummaryProductAdsTableColumnsConfig)
    ),
}

export default handleActions({}, initialState)
