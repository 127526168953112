import { Translation } from 'react-i18next'

import { getProductTitleOutsideThemeContext } from 'appConfig'
import { ItemImage } from 'components/walmart/ItemImage'
import { UNDEFINED_VALUE } from 'const/formatting'
import { WALMART_ADVERTISER_TYPES } from 'const/walmart'
import { isUnset } from 'helpers/utilities'
import { CellRenderProps, Field } from 'types'

import {
    createField,
    createLabelLookupField,
    createTitleField,
} from './createField'
import { FieldCreatorOptions } from './localTypes'

export function adGroupId<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['ad_group_id']
    return createField({
        ...options,
        id: 'ad_group_id',
        name: 'Ad Group ID',
        shortName: 'Ad Group ID',
        minWidth: 100,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: ({ value, record }: CellRenderProps<any>) =>
                isUnset(value)
                    ? UNDEFINED_VALUE
                    : `${value} - ${record?.ad_group?.name ?? ''}`,
        },
    })
}

export function platform<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['platform']
    return createTitleField({
        ...options,
        id: 'platform',
        name: 'Platform',
        shortName: 'Platform',
        minWidth: 100,
        dataIndex,
    })
}

export function itemImage<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['item_image']
    return createField({
        ...options,
        id: 'item_image',
        name: 'Item Image',
        shortName: 'Item Image',
        minWidth: 100,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: ({ value, record }: CellRenderProps<any>) => (
                <ItemImage imgUrl={value} item={record} />
            ),
        },
    })
}

export function advertiserType<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['advertiser_type']
    const name = (
        <Translation>
            {(t) => t('table:fields.accountType.name', 'Ad Account Type')}
        </Translation>
    )

    return createLabelLookupField({
        ...options,
        id: 'advertiser_type',
        name,
        shortName: name,
        minWidth: 100,
        isTotalSupported: options.isTotalSupported ?? true,
        dataIndex,
        labels: WALMART_ADVERTISER_TYPES,
        width: 160,
    })
}

export function advertiserIntegrationType<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['advertiser_read_only']
    const name = (
        <Translation>
            {(t) =>
                t(
                    'table:fields.integrationType.name',
                    'Ad Account Integration Type'
                )
            }
        </Translation>
    )

    return createField({
        ...options,
        id: 'advertiser_read_only',
        name,
        shortName: name,
        minWidth: 100,
        isTotalSupported: options.isTotalSupported ?? true,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: ({ value }) =>
                value ? (
                    <Translation>
                        {(t) =>
                            t(
                                'table:fields.integrationType.options.legacyDataImport',
                                'Legacy Data Import'
                            )
                        }
                    </Translation>
                ) : (
                    <Translation>
                        {(t) =>
                            t(
                                'table:fields.integrationType.options.newIntegration',
                                '{{ productTitle }} Ad Integration',
                                {
                                    productTitle:
                                        getProductTitleOutsideThemeContext(),
                                }
                            )
                        }
                    </Translation>
                ),
        },
        width: 200,
    })
}
