import { User } from './organization'
import { Rulebook, RulebookChangeState, RulebookVersion } from './rulebooks'

export enum BulkUpdateState {
    DRY_RUN_PENDING = 'dry_run_pending',
    DRY_RUN = 'dry_run',
    GENERATING = 'generating',
    IN_QUEUE = 'in_queue', // sent into cerebro SQS queue
    IN_PROGRESS = 'in_progress', // currently being processed
    COMPLETE = 'complete', // complete with 100% success
    TIMEOUT = 'timeout', // this means that the task timed out before it could be run
    FAILURE = 'failure', // nothing succeeded
    PARTIAL_FAILURE = 'partial_failure', // some succeeded
}

export enum AsyncUpdateType {
    BRAND = 'brand',
    PROFILE = 'profile',
    PORTFOLIO = 'portfolio',
    CAMPAIGN = 'campaign',
    AD_GROUP = 'ad_group',
    PRODUCT_AD = 'product_ad',
    KEYWORD = 'keyword',
    TARGET = 'target',
    PRODUCT = 'product',
    PRODUCT_AGGREGATE = 'product_aggregate',
    SOV_KEYWORD = 'sov_keyword',
    SOV_KEYWORD_AGGREGATE = 'sov_keyword_aggregate',
}

export type CSVDownload = string

type BulkUpdateLabelsAction = 'add' | 'remove' | 'set'

export type BulkUpdateNumberAction =
    | 'increase'
    | 'decrease'
    | 'increase_by_pct'
    | 'decrease_by_pct'
    | 'set'
    | 'amazon_suggested'
    | 'above_amazon_suggested'
    | 'below_amazon_suggested'

type BulkUpdateStringAction = 'set'

export type CSVUpdateAction = 'update_asynchronously'

export type BulkUpdateAction =
    | BulkUpdateLabelsAction
    | BulkUpdateNumberAction
    | BulkUpdateStringAction
    | CSVUpdateAction

interface AsyncUpdateActions {
    dry_run: boolean
    budget?: {
        action: BulkUpdateNumberAction | CSVUpdateAction
        params?: {
            value: number
        }
    }
    bid?: {
        action: BulkUpdateNumberAction | CSVUpdateAction
        params?: {
            value: number
        }
    }
    state?: {
        action: BulkUpdateStringAction | CSVUpdateAction
        params?: {
            value: string
        }
    }
    labels?: {
        action: BulkUpdateLabelsAction | CSVUpdateAction
        params?: {
            value: string[]
        }
    }
}

interface ChangeSetChange {
    resource_type: string
    resource_id: string
    field: string | null
    new_value: string | number | null
    old_value: string | number | null
    rule_id: string | null
}

interface ChangeSetData {
    changes: ChangeSetChange[]
    unused_changes: ChangeSetChange[]
}

export interface ChangeSet {
    id: string
    data: ChangeSetData
    data_hash: string
    description: string
    org_group: null
    org_group_id: null
    organization_id: string
    rulebook: Rulebook | null
    rulebook_version: RulebookVersion | null
    state: RulebookChangeState
    expires_at: string | null
    changed_by: string
    created_at: string
    created_by: string
    updated_at: string
    // eslint-disable-next-line no-use-before-define
    changes?: AsyncUpdate[]
    change_count?: number
    manually_triggered?: boolean
}

export interface AsyncUpdate {
    id: string
    name: string
    type: AsyncUpdateType
    count: number
    state: BulkUpdateState
    full_path: string
    actions: AsyncUpdateActions
    created_date: string
    updated_date: string
    user: User
    change_set: ChangeSet | null
    original_url: string | null
    response_url: string | null
    source_type: string | null
    s3_bucket: string | null
    s3_original_key: string | null
    s3_response_key: string | null
    approved_by_email?: string
    approved_at?: string
}

export interface UpdatePayload {
    items?: {
        id: string | string[]
        [action: string]: string | string[]
    }[]
}

export interface BulkUpdatePayload {
    [field: string]:
        | {
              action: BulkUpdateAction
              params: {
                  value: string | number | boolean | null | string[]
              }
          }
        | boolean
        | string
}
