import {
    makeCountMetricFieldFunc,
    makeCurrencyMetricFieldFunc,
    makePercentageMetricFieldFunc,
} from './localUtils'

export const ad_spend__sum = makeCurrencyMetricFieldFunc({
    name: 'Ad Spend',
    shortName: 'Ad Spend',
    deltas: ['change', 'percentage_change'],
    id: 'ad_spend__sum',
})

export const ad_attibuted_sales__sum = makeCurrencyMetricFieldFunc({
    name: 'Ad Attributed Sales',
    shortName: 'Ad Attributed Sales',
    deltas: ['change', 'percentage_change'],
    id: 'ad_attibuted_sales__sum',
})

export const sales__sum = makeCurrencyMetricFieldFunc({
    name: 'Total Sales',
    shortName: 'Sales',
    deltas: ['change', 'percentage_change'],
    id: 'sales__sum',
})

export const tacos = makePercentageMetricFieldFunc({
    name: 'Total Advertising Cost of Sales',
    shortName: 'TACoS',
    isInverse: true,
    localDefinition: 'The percentage advertising cost of VC and SC Sales.',
    deltas: ['change'],
    id: 'tacos',
})

export const troas = makeCurrencyMetricFieldFunc({
    name: 'Total Return on Ad Spend',
    shortName: 'TRoAS',
    localDefinition:
        'Return, in dollars, on advertising spend in terms of SC Sales and VC Manufacturing Ordered Revenue.',
    deltas: ['change', 'percentage_change'],
    id: 'troas',
})

export const ad_contribution = makePercentageMetricFieldFunc({
    name: 'Advertising Contribution %',
    shortName: 'Ad Contribution',
    localDefinition:
        'The percent that advertising sales contributed to your total sales.',
    deltas: ['change'],
    id: 'ad_contribution',
})

export const organic_sales__sum = makeCurrencyMetricFieldFunc({
    name: 'Organic Sales',
    shortName: 'Organic Sales',
    localDefinition:
        'The VC/SC Sales minus the Ad Attributed Sales. This might be negative if there is only Advertising data for a record',
    deltas: ['change', 'percentage_change'],
    id: 'organic_sales__sum',
})

export const shipped_sales__sum = makeCurrencyMetricFieldFunc({
    name: 'Total Sales (Shipped)',
    shortName: 'Sales (Shipped)',
    deltas: ['change', 'percentage_change'],
    id: 'shipped_sales__sum',
})

export const ad_impressions__sum = makeCountMetricFieldFunc({
    name: 'Ad Impressions',
    shortName: 'Ad Impressions',
    localDefinition: 'Impressions from Advertising',
    deltas: ['change', 'percentage_change'],
    id: 'ad_impressions__sum',
})

export const tacos_shipped = makePercentageMetricFieldFunc({
    name: 'Total Advertising Cost of Sales (Shipped)',
    shortName: 'TACoS (Shipped)',
    isInverse: true,
    localDefinition:
        'The percentage advertising cost of VC Manufacturing Shipped Revenue and SC Sales.',
    deltas: ['change'],
    id: 'tacos_shipped',
})

export const troas_shipped = makeCurrencyMetricFieldFunc({
    name: 'Total Return on Ad Spend (Shipped Revenue)',
    shortName: 'TRoAS (Shipped)',
    localDefinition:
        'Return, in dollars, on advertising spend in terms of Shipped Revenue.',
    deltas: ['change', 'percentage_change'],
    id: 'troas_shipped',
})

export const roas = makeCurrencyMetricFieldFunc({
    name: 'Return on Ad Spend',
    shortName: 'RoAS',
    localDefinition:
        'Return, in dollars, on advertising spend in terms of Attributed Advertising Sales.',
    deltas: ['change', 'percentage_change'],
    id: 'roas',
})

export const ad_contribution_shipped = makePercentageMetricFieldFunc({
    name: 'Advertising Contribution % (Shipped)',
    shortName: 'Ad Contribution (Shipped)',
    localDefinition:
        'The percent that advertising sales contributed to your total sales based on VC Sourcing Shipped Revenue.',
    deltas: ['change'],
    id: 'ad_contribution_shipped',
})

export const organic_shipped_sales__sum = makeCurrencyMetricFieldFunc({
    name: 'Organic Sales (Shipped)',
    shortName: 'Organic Sales (Shipped)',
    localDefinition:
        'The VC Sourcing Shipped Sales minus the Ad Attributed Sales. This might be negative if there is only Advertising data for a record',
    deltas: ['change', 'percentage_change'],
    id: 'organic_shipped_sales__sum',
})
