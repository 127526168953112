import { useMemo } from 'react'

import {
    UseQueryOptions,
    UseQueryResult,
    useQuery,
} from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { renderToString } from 'react-dom/server'

import { ALERTS_DATA_SOURCES } from 'configuration/alerts'
import { makeMapping } from 'configuration/dataSources/helpers'
import {
    createField,
    createFieldOfType,
} from 'configuration/fieldCreators/createField'
import { makeMetricFieldFunc } from 'configuration/fieldCreators/metrics/localUtils'
import { AlertDataSourceKey } from 'const/dataSources'
import useFeatureFlags from 'hooks/useFeatureFlags'
import { DataSourceDescription, Field, FieldMapping, MetricType } from 'types'

import {
    DataSourceGroupByListResponse,
    DataSourceListResponse,
    DataSourceMetricListResponse,
    useMetricsGlossaryApi,
} from './metricsGlossaryApi'
import {
    DataSource,
    DataSourceGroupBy,
    DataSourceMetric,
    DataSourceReport,
} from './types'

type MetricFormatFields = {
    format: string
    shortFormat: string
}

const METRIC_FORMAT_TO_TYPE_MAP: { [key: string]: MetricType } = {
    number: 'count',
    currency: 'currencyAmount',
    percentage: 'percentage',
    percentage_as_is: 'percentage_as_is',
    rank: 'rank',
}

const CUSTOM_METRIC_FORMAT_MAP: { [key: string]: MetricFormatFields } = {
    review_star__avg: {
        format: '0.00',
        shortFormat: '0.[00]',
    },
}

function getMetricFormatStrings(
    format: string,
    metricId: string
): MetricFormatFields {
    if (CUSTOM_METRIC_FORMAT_MAP[metricId]) {
        return CUSTOM_METRIC_FORMAT_MAP[metricId]
    }

    if (format === 'date') {
        return {
            format: 'YYYY-MM-DD',
            shortFormat: 'YYYY-MM-DD',
        }
    }

    if (format === 'currency') {
        return {
            format: '$0,0.00',
            shortFormat: '$0.[0]a',
        }
    }
    if (format === 'percentage' || format === 'percentage_as_is') {
        return {
            format: '0.00%',
            shortFormat: '0.[00]%',
        }
    }
    if (format === 'rank') {
        return {
            format: '0.00',
            shortFormat: '0.[00]',
        }
    }
    return {
        format: '0,0',
        shortFormat: '0.[00]a',
    }
}

function categoryFromSourceReports(
    reports: DataSourceReport[]
): string | undefined {
    if (!reports.length) {
        return undefined
    }

    const categories = reports.map((report) => {
        if (report.metadata_key.includes('Amazon::AdvertisingAPI')) {
            return 'Advertising'
        }
        if (report.metadata_key.includes('Walmart')) {
            return 'Walmart Advertising'
        }
        if (report.metadata_key.includes('SellingPartnerAPI')) {
            return 'Retail'
        }
        if (report.metadata_key.includes('ShelfIntelligence')) {
            return 'Shelf Intelligence'
        }
        if (report.metadata_key.includes('ProductSkuCogs')) {
            return 'COGS'
        }
        return undefined
    })

    const uniqueCategories = Array.from(new Set(categories))
    if (uniqueCategories.length === 1) {
        return uniqueCategories[0]
    }
    return uniqueCategories
        .sort((a, b) => {
            if (a === undefined) {
                return 1
            }
            if (b === undefined) {
                return -1
            }
            return a.localeCompare(b)
        })
        .reduce((acc, category) => {
            let newVal = acc
            newVal += category ? `${category} + ` : ''
            return newVal
        }, '')
        .slice(0, -3)
}

function transformMetrics(
    data: AxiosResponse<DataSourceMetricListResponse, any> | null
): Field<any>[] | undefined {
    return data?.data.results
        .filter((metric) => metric.is_visible)
        .map((metric: DataSourceMetric) =>
            makeMetricFieldFunc<any>({
                id: metric.metadata_key,
                name: metric.display_name,
                shortName: metric.short_name
                    ? metric.short_name
                    : metric.display_name,
                ...getMetricFormatStrings(
                    metric.metadata_format,
                    metric.metadata_key
                ),
                localDefinition: metric.definition,
                type: METRIC_FORMAT_TO_TYPE_MAP[metric.metadata_format],
                isInverse: metric.metadata_lower_is_better,
                isTotalSupported: metric.metadata_supports_total,
                deltas:
                    metric.metadata_format === 'percentage' ||
                    metric.metadata_format === 'percentage_as_is'
                        ? ['change']
                        : ['change', 'percentage_change'],
                isRatio: metric.metadata_type === 'RatioMetric',
                isLastValue:
                    metric.metadata_type === 'LastValueMetric' ||
                    metric.metadata_type === 'LastValueCurrencyMetric',
                releaseDate: metric.metadata_release_date ?? undefined,
                isDeprecated: metric.metadata_is_deprecated,
                endOfLifeDate: metric.metadata_end_of_life_date ?? undefined,
                category: categoryFromSourceReports(metric.data_source_reports),
                metric,
            })({ isVisible: true })
        )
}

function transformGroupBysForDataSource(
    dataSourceConfig: DataSourceDescription | undefined
) {
    return function transformGroupBys(
        data: AxiosResponse<DataSourceGroupByListResponse, any> | null
    ): Field<any>[] | undefined {
        const seenKeys = new Set<string>()
        const columns: Field<any>[] = []
        data?.data.results
            .filter((g) => g.is_visible)
            .forEach((groupBy: DataSourceGroupBy) => {
                const specialRenderer =
                    dataSourceConfig?.specialGroupByColumnRenderers?.find(
                        (field: any) =>
                            groupBy.metadata_all_names.some(
                                (name) => name === field.id
                            )
                    )

                const id = specialRenderer
                    ? specialRenderer.id
                    : groupBy.metadata_key

                if (seenKeys.has(id)) {
                    return
                }
                seenKeys.add(id)

                let renderer: Field<any>
                if (specialRenderer) {
                    renderer = {
                        // let the special renderer define the ID and dataIndex
                        // since it could be deviating from the metadata_key
                        ...specialRenderer,
                        name: groupBy.display_name,
                        shortName: groupBy.short_name
                            ? groupBy.short_name
                            : groupBy.display_name,
                        groupBy,
                    }
                } else if (
                    groupBy.metadata_format === 'currency' ||
                    groupBy.metadata_format === 'date' ||
                    groupBy.metadata_format === 'datetime' ||
                    groupBy.metadata_format === 'timestamp' ||
                    groupBy.metadata_format === 'string' ||
                    groupBy.metadata_format === 'integer' ||
                    groupBy.metadata_format === 'float' ||
                    groupBy.metadata_format === 'percentage'
                ) {
                    renderer = {
                        ...createFieldOfType(groupBy.metadata_format, {
                            id: groupBy.metadata_key,
                            name: groupBy.display_name,
                            shortName: groupBy.short_name
                                ? groupBy.short_name
                                : groupBy.display_name,
                            dataIndex: [groupBy.metadata_key],
                        }),
                        groupBy,
                    }
                } else {
                    renderer = {
                        ...createField({
                            id: groupBy.metadata_key,
                            name: groupBy.display_name,
                            shortName: groupBy.short_name
                                ? groupBy.short_name
                                : groupBy.display_name,
                            dataIndex: [groupBy.metadata_key],
                            minWidth: 100,
                            width: 120,
                        }),
                        groupBy,
                    }
                }
                columns.push(renderer)
            })

        if (dataSourceConfig?.specialGroupByColumnPlaceholders?.length) {
            dataSourceConfig.specialGroupByColumnPlaceholders.forEach(
                (column) => {
                    const { id } = column
                    if (!seenKeys.has(id)) {
                        seenKeys.add(id)
                        columns.push(column)
                    }
                }
            )
        }

        return columns.sort((a, b) => {
            const aName = a.name ?? ''
            const bName = b.name ?? ''

            const aNameStr =
                typeof aName !== 'string' ? renderToString(aName) : aName

            const bNameStr =
                typeof bName !== 'string' ? renderToString(bName) : bName

            return aNameStr.localeCompare(bNameStr)
        })
    }
}

export function useGetDataSourceMetricsColumns(
    dataSourceSlug?: string
): UseQueryResult<Field<any>[] | undefined> {
    const flags = useFeatureFlags()
    const { getDataSourceMetrics } = useMetricsGlossaryApi()
    const params = {
        dataSourceSlug,
        includePreRelease: flags.preReleaseMetrics,
        includeEndOfLife: true,
        includeNotVisible: true,
    }
    return useQuery({
        queryKey: ['dataSourceMetricsList', params],
        queryFn: () => getDataSourceMetrics(params),
        enabled: !!dataSourceSlug,
        select: transformMetrics,
    })
}

export function useGetDataSourceGroupBysColumns(
    dataSourceSlug?: string,
    dataSource?: DataSourceDescription
): UseQueryResult<Field<any>[] | undefined> {
    const flags = useFeatureFlags()
    const { getDataSourceGroupBys } = useMetricsGlossaryApi()
    const params = {
        dataSourceSlug,
        includePreRelease: flags.preReleaseMetrics,
        includeEndOfLife: true,
        includeNotVisible: true,
    }
    const transformGroupBys = useMemo(
        () => transformGroupBysForDataSource(dataSource),
        [dataSource]
    )

    return useQuery({
        queryKey: ['dataSourceGroupBysList', params],
        queryFn: () => getDataSourceGroupBys(params),
        enabled: !!dataSourceSlug,
        select: transformGroupBys,
    })
}

export function useGetDataSourceGroupBysList(
    dataSourceSlug?: string,
    options?: Exclude<
        UseQueryOptions<AxiosResponse<DataSourceGroupByListResponse> | null>,
        'queryKey' | 'queryFn'
    >
): UseQueryResult<AxiosResponse<DataSourceGroupByListResponse> | null> {
    const flags = useFeatureFlags()
    const { getDataSourceGroupBys } = useMetricsGlossaryApi()
    const params = {
        dataSourceSlug,
        includePreRelease: flags.preReleaseMetrics,
        includeEndOfLife: true,
        includeNotVisible: true,
    }
    return useQuery({
        queryKey: ['dataSourceGroupByList', params],
        ...options,
        queryFn: () => getDataSourceGroupBys(params),
        enabled: !!dataSourceSlug && (!options || options.enabled !== false),
    })
}

export function useGetDataSourceMetricsList(
    dataSourceSlug?: string
): UseQueryResult<AxiosResponse<DataSourceMetricListResponse> | null> {
    const flags = useFeatureFlags()
    const { getDataSourceMetrics } = useMetricsGlossaryApi()
    const params = {
        dataSourceSlug,
        includePreRelease: flags.preReleaseMetrics,
        includeEndOfLife: true,
        includeNotVisible: true,
    }
    return useQuery({
        queryKey: ['dataSourceMetricsList', params],
        queryFn: () => getDataSourceMetrics(params),
        enabled: !!dataSourceSlug,
    })
}

export function useGetDataSources({
    groupSlug = '',
    includeEndOfLife = false,
} = {}): UseQueryResult<AxiosResponse<DataSourceListResponse> | null> {
    const flags = useFeatureFlags()
    const { getDataSources } = useMetricsGlossaryApi()
    const params = {
        groupSlug,
        includePreRelease: flags.preReleaseMetrics,
        includeEndOfLife,
    }
    return useQuery({
        queryKey: ['dataSourceList', params],
        queryFn: () => getDataSources(params),
    })
}

export function useAlertsMetrics(dataSource: AlertDataSourceKey): {
    metricsConfig: FieldMapping<any>
    metricsColumns: Field<any>[]
    selectedDataSource: DataSource | undefined
} {
    const { data } = useGetDataSources({ includeEndOfLife: true })
    const selectedDataSource = (data?.data.results ?? []).find(
        (ds) => ds.metadata_key === ALERTS_DATA_SOURCES[dataSource].metadataKey
    )
    const { data: metricsColumnsData } = useGetDataSourceMetricsColumns(
        selectedDataSource?.slug
    )
    const metricsConfig = useMemo(() => {
        return metricsColumnsData
            ? makeMapping(metricsColumnsData)
            : ({} as FieldMapping<any>)
    }, [metricsColumnsData])
    return {
        metricsConfig,
        metricsColumns: metricsColumnsData ?? [],
        selectedDataSource,
    }
}

export function useDataSource(metadataKey: string): DataSource | undefined {
    const { data: dataSourceData } = useGetDataSources({
        includeEndOfLife: true,
    })
    const dataSource = (dataSourceData?.data.results ?? []).find(
        (d) => d.metadata_key === metadataKey
    )
    return dataSource
}
