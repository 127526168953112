import { SVGAttributes, ReactElement } from 'react'

type Props = SVGAttributes<SVGElement>

function CobaltLogo(props: Props): ReactElement {
    const { fill } = props
    const fillColor = fill ?? '#00002B'

    return (
        <svg
            viewBox="0 0 166 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M165.867 23.6653H67.3198C67.2463 23.6653 67.1866 23.7254 67.1866 23.7995V41.8657C67.1866 41.9399 67.2463 42 67.3198 42H165.867C165.94 42 166 41.9399 166 41.8657V23.7995C166 23.7254 165.94 23.6653 165.867 23.6653Z"
                fill="#5561F6"
            />
            <path
                d="M0 17.1303L2.19568 14.8356C3.31083 16.2844 4.42599 17.1048 6.01812 17.1048C7.80877 17.1048 9.02785 15.9017 9.02785 13.3331V0.821739H12.4706V13.4446C12.4706 15.7111 11.7938 17.4056 10.6826 18.5254C9.57144 19.6452 7.96998 20.2118 6.07408 20.2118C3.14562 20.2199 1.24839 18.853 0 17.1317V17.1303Z"
                fill={fillColor}
            />
            <path
                d="M16.1398 14.7013V5.52123H19.4213V13.7185C19.4213 15.9662 20.5365 17.2149 22.4857 17.2149C24.4349 17.2149 25.7113 15.9031 25.7113 13.6554V5.52257H29.0181V19.95H25.7113V17.7023C24.7813 19.0678 23.4623 20.2427 21.2386 20.2427C18.0117 20.2508 16.1411 18.0635 16.1411 14.704L16.1398 14.7013Z"
                fill={fillColor}
            />
            <path
                d="M32.7393 5.51989H36.0208V7.73268C36.9508 6.39265 38.2698 5.21778 40.4934 5.21778C43.719 5.21778 45.5909 7.40371 45.5909 10.7377V19.9446H42.3094V11.7501C42.3094 9.50237 41.1942 8.2241 39.245 8.2241C37.2958 8.2241 36.0195 9.56414 36.0195 11.8051V19.946H32.7379V5.52123L32.7393 5.51989Z"
                fill={fillColor}
            />
            <path
                d="M49.1496 22.6502L50.3713 20.1635C51.9168 21.1208 53.5156 21.6942 55.4675 21.6942C58.3959 21.6942 59.9947 20.1635 59.9947 17.2673V16.1434C58.8036 17.7023 57.3114 18.7939 54.9532 18.7939C51.5917 18.7939 48.4461 16.279 48.4461 12.0495V11.9917C48.4461 7.72864 51.6184 5.21509 54.9532 5.21509C57.37 5.21509 58.8569 6.33894 59.9734 7.67494V5.51989H63.2496V16.996C63.2496 19.4317 62.6274 21.2309 61.4336 22.43C60.1319 23.7418 58.1268 24.3689 55.5514 24.3689C53.3024 24.3944 51.0894 23.8009 49.1522 22.6502H49.1496ZM60.0214 12.0213V11.9676C60.0214 9.61784 58.0975 8.00658 55.8192 8.00658C53.5409 8.00658 51.7516 9.59233 51.7516 11.9676V12.0213C51.7516 14.3724 53.5676 15.9836 55.8192 15.9836C58.0708 15.9836 60.0214 14.3724 60.0214 12.0213Z"
                fill={fillColor}
            />
            <path d="M67.1866 0H70.4601V19.9446H67.1866V0Z" fill={fillColor} />
            <path
                d="M73.779 12.788V12.741C73.779 8.61886 76.6794 5.22584 80.7724 5.22584C85.3276 5.22584 87.6325 8.83236 87.6325 12.9867C87.6325 13.2861 87.6045 13.5882 87.5792 13.9239H77.0605C77.4122 16.2737 79.067 17.5855 81.18 17.5855C82.7802 17.5855 83.918 16.984 85.0584 15.8641L86.9823 17.5855C85.6274 19.2249 83.7568 20.2897 81.1267 20.2897C76.9792 20.2749 73.779 17.2391 73.779 12.7893V12.788ZM84.3763 11.7219C84.1631 9.59099 82.9174 7.92468 80.747 7.92468C78.7406 7.92468 77.3296 9.48223 77.0285 11.7219H84.3763Z"
                fill={fillColor}
            />
            <path
                d="M93.2229 17.1585L95.2294 14.7551C97.0454 16.3408 98.8907 17.2377 101.221 17.2377C103.281 17.2377 104.582 16.2535 104.582 14.8316V14.7779C104.582 13.4123 103.824 12.6739 100.307 11.8548C96.2673 10.8706 93.9864 9.66886 93.9864 6.14425V6.09054C93.9864 2.81164 96.699 0.545139 100.467 0.545139C103.231 0.545139 105.427 1.39239 107.346 2.9486L105.559 5.49303C103.851 4.2094 102.144 3.52596 100.407 3.52596C98.455 3.52596 97.3172 4.53837 97.3172 5.79514V5.84885C97.3172 7.32314 98.1859 7.97973 101.844 8.84712C105.859 9.83133 107.919 11.2828 107.919 14.4476V14.5013C107.919 18.0823 105.13 20.2118 101.14 20.2118C98.2152 20.2279 95.3906 19.139 93.2229 17.1599V17.1585Z"
                fill={fillColor}
            />
            <path
                d="M109.568 12.8135V12.7598C109.568 8.66183 112.712 5.21912 117.024 5.21912C119.708 5.21912 121.388 6.23152 122.745 7.70582L120.685 9.91995C119.68 8.85383 118.624 8.11534 116.997 8.11534C114.612 8.11534 112.849 10.1912 112.849 12.7061V12.7598C112.849 15.3284 114.612 17.3787 117.125 17.3787C118.67 17.3787 119.81 16.6684 120.843 15.6023L122.823 17.5694C121.412 19.1551 119.759 20.2736 116.968 20.2736C112.714 20.2736 109.571 16.9141 109.571 12.8148L109.568 12.8135Z"
                fill={fillColor}
            />
            <path
                d="M141.592 14.7013V5.52122H144.871V13.7185C144.871 15.9662 145.986 17.2149 147.935 17.2149C149.884 17.2149 151.161 15.9031 151.161 13.6554V5.52257H154.467V19.95H151.162V17.7023C150.232 19.0678 148.913 20.2427 146.689 20.2427C143.462 20.2508 141.592 18.0635 141.592 14.704V14.7013Z"
                fill={fillColor}
            />
            <path
                d="M158.868 15.9568V8.36106H157.052V5.52123H158.868V1.55889H162.15V5.52123H166V8.36106H162.15V15.4371C162.15 16.7221 162.8 17.2418 163.912 17.2418C164.618 17.2444 165.317 17.0753 165.944 16.749V19.4545C165.045 19.9675 164.023 20.2226 162.99 20.193C160.577 20.193 158.868 19.1269 158.868 15.9568Z"
                fill={fillColor}
            />
            <path
                d="M124.084 12.8135V12.7598C124.084 8.63766 127.337 5.21912 131.729 5.21912C136.12 5.21912 139.351 8.57992 139.351 12.7141V12.7678C139.351 16.8671 136.097 20.2817 131.678 20.2817C127.309 20.2736 124.084 16.9141 124.084 12.8148V12.8135ZM136.068 12.8135V12.7598C136.068 10.2194 134.252 8.11534 131.675 8.11534C129.039 8.11534 127.365 10.1912 127.365 12.7061V12.7598C127.365 15.2747 129.184 17.3787 131.73 17.3787C134.276 17.3787 136.067 15.3015 136.067 12.8148L136.068 12.8135Z"
                fill={fillColor}
            />
            <path
                d="M131.715 9.26874C132.977 9.59501 133.995 11.0881 133.995 12.7652C133.995 14.2784 133.178 15.6842 132.123 16.2226C134.017 15.9178 135.307 14.1226 135.003 12.2133C134.742 10.5685 133.367 9.33721 131.715 9.26874Z"
                fill={fillColor}
            />
            <path
                d="M74.5451 38.1598C73.5844 37.6335 72.8304 36.9057 72.2854 35.9766C71.7405 35.0474 71.4674 34.0001 71.4674 32.832C71.4674 31.6638 71.7432 30.6165 72.2934 29.6887C72.8437 28.7595 73.6004 28.0318 74.561 27.5054C75.5216 26.9791 76.5982 26.7146 77.7906 26.7146C78.7579 26.7146 79.6399 26.8851 80.4379 27.2248C81.236 27.5645 81.9102 28.0573 82.4617 28.7031L81.0455 30.0458C80.1915 29.1167 79.1402 28.6521 77.8919 28.6521C77.0831 28.6521 76.357 28.8307 75.7162 29.1878C75.0753 29.545 74.5744 30.0405 74.216 30.6756C73.8562 31.3107 73.6764 32.029 73.6764 32.8333C73.6764 33.6376 73.8562 34.3573 74.216 34.9924C74.5757 35.6275 75.0753 36.123 75.7162 36.4788C76.357 36.8359 77.0818 37.0145 77.8919 37.0145C79.1402 37.0145 80.1901 36.5446 81.0455 35.6033L82.4617 36.9635C81.9102 37.6093 81.2333 38.1021 80.4299 38.4418C79.6266 38.7815 78.7406 38.952 77.7733 38.952C76.5822 38.952 75.5057 38.6889 74.5437 38.1612L74.5451 38.1598Z"
                fill="white"
            />
            <path
                d="M90.6249 38.1598C89.6576 37.6335 88.8995 36.9017 88.348 35.9672C87.7964 35.0327 87.5219 33.9867 87.5219 32.832C87.5219 31.6772 87.7977 30.6313 88.348 29.6967C88.8982 28.7622 89.6576 28.0318 90.6249 27.5041C91.5922 26.9777 92.6767 26.7132 93.8798 26.7132C95.0829 26.7132 96.1674 26.9764 97.1347 27.5041C98.1019 28.0304 98.86 28.7595 99.4116 29.6873C99.9619 30.6165 100.238 31.6652 100.238 32.8306C100.238 33.9961 99.9619 35.0461 99.4116 35.9753C98.86 36.9044 98.1019 37.6322 97.1347 38.1585C96.1674 38.6848 95.0829 38.9494 93.8798 38.9494C92.6767 38.9494 91.5922 38.6862 90.6249 38.1585V38.1598ZM96.0048 36.4774C96.635 36.1203 97.1293 35.6221 97.4891 34.9816C97.8488 34.3412 98.0287 33.6242 98.0287 32.832C98.0287 32.0398 97.8488 31.3228 97.4891 30.6823C97.1293 30.0418 96.635 29.5437 96.0048 29.1865C95.3747 28.8293 94.6672 28.6508 93.8798 28.6508C93.0924 28.6508 92.3849 28.8293 91.7547 29.1865C91.1245 29.5437 90.6302 30.0418 90.2705 30.6823C89.9108 31.3228 89.7309 32.0398 89.7309 32.832C89.7309 33.6242 89.9108 34.3425 90.2705 34.9816C90.6302 35.6221 91.1245 36.1203 91.7547 36.4774C92.3849 36.8346 93.0924 37.0132 93.8798 37.0132C94.6672 37.0132 95.3747 36.8346 96.0048 36.4774Z"
                fill="white"
            />
            <path
                d="M116.527 33.6738C116.909 34.1774 117.1 34.8044 117.1 35.5523C117.1 36.5835 116.705 37.3797 115.919 37.9396C115.132 38.5009 113.986 38.7815 112.479 38.7815H106.61V26.8851H112.142C113.536 26.8851 114.609 27.1604 115.362 27.7095C116.115 28.2587 116.492 29.016 116.492 29.9787C116.492 30.5682 116.357 31.0891 116.087 31.5416C115.817 31.9955 115.446 32.3459 114.973 32.5956C115.625 32.8105 116.143 33.1703 116.525 33.6752L116.527 33.6738ZM108.803 28.6185V31.8988H111.906C112.671 31.8988 113.257 31.7605 113.669 31.4825C114.079 31.2046 114.284 30.8004 114.284 30.2674C114.284 29.7343 114.079 29.3127 113.669 29.0348C113.258 28.7568 112.671 28.6185 111.906 28.6185H108.803ZM114.891 35.3321C114.891 34.1881 114.042 33.6161 112.345 33.6161H108.803V37.0494H112.345C114.042 37.0494 114.891 36.4774 114.891 35.3334V35.3321Z"
                fill="white"
            />
            <path
                d="M131.146 36.0276H125.244L124.081 38.7802H121.821L127.133 26.8838H129.292L134.621 38.7802H132.328L131.148 36.0276H131.146ZM130.422 34.2942L128.195 29.0939L125.986 34.2942H130.422Z"
                fill="white"
            />
            <path
                d="M140.168 26.8838H142.359V36.9098H148.531V38.7788H140.166V26.8824L140.168 26.8838Z"
                fill="white"
            />
            <path
                d="M155.615 28.7542H151.703V26.8851H161.719V28.7542H157.808V38.7802H155.616V28.7542H155.615Z"
                fill="white"
            />
            <path
                d="M93.8891 29.8726C94.9257 30.1398 95.7597 31.3657 95.7597 32.7434C95.7597 33.9867 95.0895 35.1401 94.2222 35.5818C95.777 35.3307 96.8362 33.8578 96.5871 32.2909C96.3726 30.9401 95.2441 29.929 93.8878 29.874L93.8891 29.8726Z"
                fill="white"
            />
        </svg>
    )
}

export default CobaltLogo
