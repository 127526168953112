import { SVGAttributes, ReactElement } from 'react'

type Props = SVGAttributes<SVGElement>

function SvgBuildingIcon(props: Props): ReactElement {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 17 17"
            fill="currentColor"
            {...props}
        >
            <path d="M14.779 15.833V1.189L3.242 0v15.833H2V17h14v-1.167h-1.221zM11.242 3.099h1.81v1.825h-1.81V3.099zm0 3.162h1.81v1.825h-1.81V6.261zm0 3.162h1.81v1.825h-1.81V9.423zm0 3.162h1.81v1.826h-1.81v-1.825zM8.105 3.1h1.81v1.825h-1.81V3.099zm0 3.162h1.81v1.825h-1.81V6.261zm0 3.162h1.81v1.825h-1.81V9.423zM4.968 3.1H6.78v1.825h-1.81V3.099zm0 3.162H6.78v1.825h-1.81V6.261zm0 3.162H6.78v1.825h-1.81V9.423zm0 3.162H6.78v1.826h-1.81v-1.825zm3.137 0h1.81v3.248h-1.81v-3.248z" />
        </svg>
    )
}

export default SvgBuildingIcon
