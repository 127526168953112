import { addCache } from 'helpers/utilities'
import {
    CerebroApiOptions,
    BannerRequestBody,
    CerebroResourceResponse,
    CerebroPaginatedResponse,
    Report,
} from 'types'

import { axiosInstanceOrganizations } from './client'
import { getCerebroHeaders } from './headers'

export const getAdGroupFacts = (
    params: BannerRequestBody,
    options: CerebroApiOptions = {}
): Promise<CerebroPaginatedResponse<any>> =>
    axiosInstanceOrganizations.post(
        '/api/ad_group_facts/aggregate/banner/',
        params,
        {
            headers: getCerebroHeaders(options.headers),
        }
    )
addCache(getAdGroupFacts)

export const getAdGroupFactsExport = (
    params: BannerRequestBody
): Promise<CerebroResourceResponse<Report>> =>
    axiosInstanceOrganizations.post(
        '/api/ad_group_facts/aggregate/banner/export/',
        params
    )
