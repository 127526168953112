import { dateCreated } from 'configuration/fieldCreators/date'
import {
    organizationMemberEmail,
    organizationMemberType,
    removeFromOrganization,
} from 'configuration/fieldCreators/organizationMember'
import { Field, Member } from 'types'

export const organizationMemberTableColumnsConfig: Field<Member>[] = [
    organizationMemberEmail({ fixed: true }),
    organizationMemberType(),
    dateCreated({ dataIndex: ['date_joined'], name: 'Date Joined' }),
    removeFromOrganization(),
]
