import { useCallback, DependencyList } from 'react'

import { checkDeps, useDeepCompareMemoize } from 'helpers/hooks'

/**
 * This is a drop-in replacement for React.useCallback when dependencies
 * contain non primitive values and a deep comparison is required.
 *
 * @see https://github.com/kentcdodds/use-deep-compare-effect
 * @see https://github.com/sandiiarov/use-deep-compare
 *
 * @param callback
 * @param dependencies
 */
function useDeepCompareCallback(
    callback: (...args: any[]) => any,
    dependencies: DependencyList
): (...args: any[]) => any {
    if (process.env.NODE_ENV !== 'production') {
        checkDeps(dependencies)
    }
    return useCallback(callback, useDeepCompareMemoize(dependencies))
}

export default useDeepCompareCallback
