import { memo, ReactElement } from 'react'

import isEqual from 'lodash/isEqual'
import { useTranslation } from 'react-i18next'

import { useAppConfig } from 'appConfig'
import { deleteDashboard } from 'services/cerebroApi/orgScope/resourceApi'
import { Dashboard } from 'types'

import { DeleteField } from '../../SharedFields/DeleteField'

interface Props<RecordType> {
    readOnly: boolean
    record: RecordType
    reloadData: () => void
}

function DeleteDashboardField<RecordType extends Dashboard>({
    readOnly = false,
    record,
    reloadData,
}: Props<RecordType>): ReactElement {
    const { t } = useTranslation(['common', 'dashboards'])
    const { productTitle } = useAppConfig()

    const getIsDisabled = (): boolean => {
        return (
            !record.user_can_edit ||
            readOnly ||
            (record.templates_using_this_dashboard_count ?? 0) > 0
        )
    }

    const getTooltipTitle = (): string | undefined => {
        if (!getIsDisabled()) {
            return undefined
        }
        if (record.global_access) {
            return t(
                'dashboards:DashboardDeletion.tooltipDownstreamNoDelete',
                'Can not delete Dashboards managed by {{ productTitle }}',
                { productTitle }
            )
        }
        if (record.agency_access) {
            return t(
                'dashboards:DashboardDeletion.tooltipAgencyNoDelete',
                'Can not delete Dashboards managed by Agency'
            )
        }
        if ((record.templates_using_this_dashboard_count ?? 0) > 0) {
            return t(
                'dashboards:DashboardDeletion.tooltipUsedAsTemplateNoDelete',
                'Can not delete Dashboards that are used as templates'
            )
        }
        return t(
            'dashboards:DashboardDeletion.tooltipNoEditNoDelete',
            'Must have Edit Permissions to Delete'
        )
    }

    return (
        <DeleteField
            disabled={getIsDisabled()}
            okText={t('common:delete', 'Delete')}
            title={t(
                'dashboards:DashboardDeletion.deleteConfirmationMsg',
                'Are you sure you want to delete this Dashboard?'
            )}
            updateRequestApi={deleteDashboard}
            updateRequestCallback={reloadData}
            updateRequestData={record.id}
            updateRequestSuccessMesg={t(
                'dashboards:DashboardDeletion.deleteSuccessMsg',
                'Dashboard successfully deleted'
            )}
            tooltipTitle={getTooltipTitle()}
            tooltipPlacement="left"
        />
    )
}

export default memo(
    DeleteDashboardField,
    isEqual
) as typeof DeleteDashboardField
