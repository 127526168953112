import { ReactElement, ReactNode, SyntheticEvent } from 'react'

import { useTranslation } from 'react-i18next'

import { useAppConfig } from 'appConfig'
import { TextButton } from 'components/TextButton'
import { ToolTip } from 'components/ToolTip'
import { CONTACT_US } from 'configuration/urls'

interface Props {
    icon?: ReactElement
    link?: boolean
    className?: string
    children?: ReactNode
    contactUsPage?: string
}

const ContactUsLink = ({
    children = 'Contact Us',
    ...rest
}: Props): ReactElement => {
    const { t } = useTranslation(['common'])
    const { isChinaBuild } = useAppConfig()

    const openServiceDesk = (e: SyntheticEvent): void => {
        e.preventDefault()

        window.open(CONTACT_US, '_blank')
    }

    if (!isChinaBuild) {
        return (
            <TextButton {...rest} onClick={openServiceDesk}>
                {children}
            </TextButton>
        )
    }
    return (
        <TextButton {...rest}>
            <ToolTip
                title={t('common:contactUsWeChat', 'Contact Us on WeChat')}
            >
                {children}
            </ToolTip>
        </TextButton>
    )
}

export default ContactUsLink
