import { handleActions } from 'redux-actions'

import { walmartCampaignAdItemsTabTableColumnsConfig } from 'configuration/tables'
import { DATES, AD_ITEM_STATES } from 'const/filters'
import { LOCAL_FILTERS, FILTER_SETTINGS } from 'const/reducerKeys'
import { serializeColumns } from 'helpers/tables'
import { defaultTab, getDefaultTable } from 'reducers/ui/defaults'

const defaultState = {
    ...defaultTab,
    [LOCAL_FILTERS]: {
        [AD_ITEM_STATES]: [],
    },
    [FILTER_SETTINGS]: {
        anchored: [DATES, AD_ITEM_STATES],
        order: [],
        displayState: {},
    },
    table: getDefaultTable(
        serializeColumns(walmartCampaignAdItemsTabTableColumnsConfig),
        { showTotalRow: true }
    ),
}

export default handleActions({}, defaultState)
