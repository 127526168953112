import { ReactNode } from 'react'

import isNil from 'lodash/isNil'

import { AtAGlanceKeywordPopover } from 'components/AtAGlancePopover'
import { UNDEFINED_VALUE } from 'const/formatting'
import { KeywordGroupBy, CellRenderProps } from 'types'

export function atAGlanceKeywordRenderer<RecordType extends KeywordGroupBy>({
    value,
    record,
    undefinedValue = UNDEFINED_VALUE,
}: CellRenderProps<RecordType>): ReactNode {
    if (isNil(value)) {
        return undefinedValue
    }
    return <AtAGlanceKeywordPopover keyword={record.keyword} />
}
