import { ReactElement } from 'react'

import { Breadcrumb } from 'antd'
import { Link } from 'react-router-dom'

import { Breadcrumb as BreadcrumbType } from 'types'

interface Props {
    items: BreadcrumbType[]
}

const AppBreadcrumb = ({ items }: Props): ReactElement => (
    <Breadcrumb
        items={items.map(({ name, url, icon }: BreadcrumbType) => ({
            key: name as string,
            title: (
                <>
                    {icon}
                    <span>
                        {url ? (
                            <Link to={url} style={{ background: 'none' }}>
                                {name}
                            </Link>
                        ) : (
                            name
                        )}
                    </span>
                </>
            ),
        }))}
    />
)

export default AppBreadcrumb
