import { ReactElement } from 'react'

import { Select } from 'antd'

import { WalmartCampaign } from 'types'

import { useFetchWalmartAdGroups } from './localHooks'

interface Props {
    campaign: WalmartCampaign
    onChange: (val: string) => void
    selectedAdGroup: string | undefined
}

const WalmartAdGroupSelectField = ({
    campaign,
    onChange,
    selectedAdGroup,
}: Props): ReactElement => {
    const [isFetching, options] = useFetchWalmartAdGroups(campaign)

    return (
        <Select
            value={selectedAdGroup}
            placeholder="Select Target Ad Group"
            loading={isFetching}
            disabled={isFetching}
            onChange={onChange}
            style={{ width: '100%', maxWidth: 200 }}
        >
            {options.map(({ value, label }) => (
                <Select.Option key={value} value={value}>
                    {label}
                </Select.Option>
            ))}
        </Select>
    )
}

export default WalmartAdGroupSelectField
