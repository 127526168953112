import { Translation } from 'react-i18next'

import {
    portfolioIdRenderer,
    portfolioNameRenderer,
    portfolioBudgetRenderer,
    portfolioStartDateRenderer,
    portfolioEndDateRenderer,
    portfolioLabelIdRenderer,
    removePortfolioFromLabelRenderer,
} from 'components/FieldRenderers/Renderers/portfolio'
import { Field } from 'types'

import { createActionField, createField } from './createField'
import { FieldCreatorOptions } from './localTypes'

export function portfolioId<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['portfolio', 'id']
    return createField({
        ...options,
        id: 'portfolio_id',
        name: 'Portfolio',
        shortName: 'Portfolio',
        minWidth: 80,
        isTotalSupported: options.isTotalSupported ?? true,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: (props) => portfolioIdRenderer<any>(props),
        },
        width: 200,
    })
}

export function portfolioName<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['portfolio', 'name']
    const name = (
        <Translation>{(t) => t('common:portfolio', 'Portfolio')}</Translation>
    )
    return createField({
        ...options,
        id: 'portfolioName',
        name,
        shortName: name,
        minWidth: 80,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: (props) => portfolioNameRenderer<any>(props),
        },
        width: 200,
    })
}

export function portfolioBudget<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['portfolio', 'budget']
    return createField({
        ...options,
        id: 'portfolio__budget',
        name: 'Budget Cap',
        shortName: 'Budget Cap',
        minWidth: 80,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: (cellRenderProps) =>
                portfolioBudgetRenderer<any>({ cellRenderProps, dataIndex }),
        },
        width: 120,
        align: 'right',
    })
}

export function portfolioStartDate<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['portfolio', 'start_date']
    return createField({
        ...options,
        id: 'portfolio__start_date',
        name: 'Start Date',
        shortName: 'Start Date',
        minWidth: 80,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: (cellRenderProps) =>
                portfolioStartDateRenderer<any>({ cellRenderProps, dataIndex }),
        },
        width: 110,
    })
}

export function portfolioEndDate<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['portfolio', 'end_date']
    return createField({
        ...options,
        id: 'portfolio__end_date',
        name: 'End Date',
        shortName: 'End Date',
        minWidth: 80,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: (cellRenderProps) =>
                portfolioEndDateRenderer<any>({ cellRenderProps, dataIndex }),
        },
        width: 110,
    })
}

export function portfolioLabelId<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['portfolio', 'label', 'id']
    return createField({
        ...options,
        id: 'portfolio__label_id',
        name: 'Portfolio Label',
        shortName: 'Portfolio Label',
        minWidth: 50,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: (props) => portfolioLabelIdRenderer<any>(props),
        },
        width: 200,
        sorter: options.sorter ?? false,
    })
}

export function removePortfolioFromLabel<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createActionField({
        ...options,
        id: 'remove_portfolio_from_label',
        dataIndex: [],
        isResizeable: false,
        minWidth: 68,
        renderOptions: options.renderOptions ?? {
            render: (props) => removePortfolioFromLabelRenderer<any>(props),
        },
        sorter: false,
        fixed: 'right',
        align: 'center',
    })
}
