import { call, put, select } from 'redux-saga/effects'

import {
    makeFetchTableRequest,
    makeFetchTableSuccess,
    makeDownloadTableSuccess,
} from 'actions/ui/shared'
import { productsSummaryProductsTableColumnsConfig } from 'configuration/tables'
import { DATES } from 'const/filters'
import { PRODUCTS_SUMMARY_PAGE } from 'const/pages'
import { formatFilters } from 'helpers/filters/productAdFacts'
import { generateReportNotification } from 'helpers/notifications'
import {
    formatPagination,
    formatCurrency,
    formatColumns,
    formatSorter,
    formatMetrics,
    formatPeriodDeltaDateRange,
} from 'helpers/params'
import { cerebroApiSaga } from 'sagas/common'
import uiSagaRegistry from 'sagas/ui/registry'
import {
    selectVisibleCombinedFilters,
    selectTableSettings,
    selectCurrencyCode,
    selectVisibleMetricsOfTable,
    selectVisibleColumnsOfTable,
} from 'selectors/ui'
import {
    getProductAdFactsExport,
    getProductAdFacts,
} from 'services/cerebroApi/orgScope/productAdFactsApi'

const TAB_PATH = [PRODUCTS_SUMMARY_PAGE, 'products']
const TABLE_PATH = [...TAB_PATH, 'table']

function* fetchTableSaga(noCount) {
    const filters = yield select(selectVisibleCombinedFilters, TAB_PATH)
    const {
        pagination,
        sorter,
        showPeriodDeltas,
        periodDeltaType,
        periodDeltaDateRange,
        showTotalRow,
    } = yield select(selectTableSettings, TABLE_PATH)
    const metrics = yield select(
        selectVisibleMetricsOfTable,
        TABLE_PATH,
        productsSummaryProductsTableColumnsConfig
    )
    const currency = yield select(selectCurrencyCode)

    // FIXME this is a temporary fix to make sure that products with no ASIN are not included in the table.
    // This should be removed once the API is fixed to not return products with no ASIN.
    const fixedFilters = { product_ad__asin__isnotnull: true }
    const params = {
        ...{ ...formatFilters(filters), ...fixedFilters },
        ...formatPagination(pagination),
        ...formatSorter(sorter),
        ...formatMetrics(metrics, showPeriodDeltas, showTotalRow),
        ...formatCurrency(currency),
        ...formatPeriodDeltaDateRange(
            showPeriodDeltas,
            periodDeltaType,
            periodDeltaDateRange,
            filters[DATES]
        ),
        group_by: 'product_ad__asin,profile__country_code,metadata_id',
    }
    yield call(
        cerebroApiSaga,
        makeFetchTableSuccess(TABLE_PATH),
        getProductAdFacts,
        params,
        {
            headers: { noCount },
        }
    )
}

function* downloadTableSaga(path) {
    const filters = yield select(selectVisibleCombinedFilters, TAB_PATH)
    const { sorter, showPeriodDeltas, periodDeltaType, periodDeltaDateRange } =
        yield select(selectTableSettings, TABLE_PATH)
    const columns = yield select(
        selectVisibleColumnsOfTable,
        path,
        productsSummaryProductsTableColumnsConfig
    )
    const currency = yield select(selectCurrencyCode)
    const reportName = 'All Products Report'
    const params = {
        ...formatFilters(filters),
        ...formatSorter(sorter),
        ...formatColumns(columns, showPeriodDeltas),
        ...formatCurrency(currency),
        ...formatPeriodDeltaDateRange(
            showPeriodDeltas,
            periodDeltaType,
            periodDeltaDateRange,
            filters[DATES]
        ),
        group_by: 'product_ad__asin,profile__country_code',
        async_download_name: reportName,
    }
    yield call(
        cerebroApiSaga,
        makeDownloadTableSuccess(path),
        getProductAdFactsExport,
        params
    )

    generateReportNotification(reportName)
}

function* fetchTabDataSaga() {
    yield put(makeFetchTableRequest(TABLE_PATH)())
}

// Register Sagas
uiSagaRegistry.registerSagas(TAB_PATH, {
    fetchTabDataSaga,
})

uiSagaRegistry.registerSagas(TABLE_PATH, {
    fetchTableSaga,
    downloadTableSaga,
})
