import { all, call, select, put } from 'redux-saga/effects'

import { fetchProfileFeaturePermissionsRequest } from 'actions/auth'
import {
    makeFetchResourceSuccess,
    makeUpdateResourceSuccess,
} from 'actions/ui/shared/resource'
import { PRODUCT_AD_PAGE } from 'const/pages'
import { cerebroApiSaga } from 'sagas/common'
import uiSagaRegistry from 'sagas/ui/registry'
import { selectResourceParamsOfPage, selectResourceOfPage } from 'selectors/ui'
import {
    getProductAd,
    updateProductAd,
} from 'services/cerebroApi/orgScope/resourceApi'

function* fetchProfileFeaturePermissionsSaga() {
    const productAd = yield select(selectResourceOfPage, PRODUCT_AD_PAGE)

    if (productAd) {
        yield put(
            fetchProfileFeaturePermissionsRequest({
                profileId: productAd.profile.id,
            })
        )
    }
}

function* fetchProductAdSaga() {
    const { productAdId } = yield select(
        selectResourceParamsOfPage,
        PRODUCT_AD_PAGE
    )
    yield call(
        cerebroApiSaga,
        makeFetchResourceSuccess(PRODUCT_AD_PAGE),
        getProductAd,
        productAdId
    )
    yield call(fetchProfileFeaturePermissionsSaga)
}

function* updateProductAdSaga({ productAdId }, params) {
    yield call(
        cerebroApiSaga,
        makeUpdateResourceSuccess(PRODUCT_AD_PAGE),
        updateProductAd,
        productAdId,
        params
    )
}

/**
 * Mounts the Products Page and fetches data
 */
function* mountPageSaga() {
    yield all([call(fetchProductAdSaga)])
}

uiSagaRegistry.registerSagas([PRODUCT_AD_PAGE], {
    mountPageSaga,
    updateResourceSaga: updateProductAdSaga,
})
