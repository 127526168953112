import { organizationNameRenderer } from 'components/FieldRenderers/Renderers/organization'
import { Flag } from 'components/Flag'
import { UNDEFINED_VALUE } from 'const/formatting'
import { formatAccountType } from 'helpers/formatting'
import { isUnset } from 'helpers/utilities'
import { Field } from 'types'

import { createField } from './createField'
import { FieldCreatorOptions } from './localTypes'

export function organizationId<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['organization_id']
    return createField({
        ...options,
        id: 'organization_id',
        name: 'Organization',
        shortName: 'Organization',
        minWidth: 50,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: (props) => organizationNameRenderer<any>(props),
        },
        width: 100,
    })
}

export function country<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['country']
    const name = 'Country'
    const shortName = 'Country'
    return createField({
        ...options,
        id: 'country',
        name,
        shortName,
        minWidth: 80,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: ({ value }) =>
                isUnset(value) ? (
                    UNDEFINED_VALUE
                ) : (
                    <span className="fg-flag-container">
                        <Flag country={value} />
                        {value}
                    </span>
                ),
        },
    })
}

export function accountType<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['account_type']
    return createField({
        ...options,
        id: 'account_type',
        name: 'Account Type',
        shortName: 'Account Type',
        minWidth: 50,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: ({ value }) => formatAccountType(value),
        },
        width: 100,
    })
}
