import { SVGAttributes, ReactElement } from 'react'

type Props = SVGAttributes<SVGElement>

function JsLogo(props: Props): ReactElement {
    const { fill } = props
    const fillColor = fill ?? '#000'

    return (
        <svg
            viewBox="0 0 29 25"
            fill="black"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g fill="none" fillRule="evenodd">
                <path
                    fill={fillColor}
                    d="M15.018 0v1.587c-1.342 1.177-2.1 2.874-2.075 4.649v.05c0 1.874.681 3.303 2.07 4.351v1.899l-2.768 3.285c-.247.291-.216.72.066.973.322.282.66.546 1.01.792-.105.118-.212.237-.327.35C11.638 19.286 9.706 20 7.427 20 4.392 20 2.02 18.867.365 16.643l-.365-.5.456-.429 3.295-3.088.551-.509.487.572c.87 1.041 1.517 1.442 2.327 1.442.343 0 1.396 0 1.396-1.857V0h6.506zm5.788.391c3.144 0 5.597.832 7.601 2.43l-2.56 3.572c-1.69-1.173-3.533-1.804-5.172-1.804-1.245.01-1.852.531-1.852 1.182v.05c0 .832.627 1.203 3.188 1.773 4.25.912 6.88 2.275 6.88 5.692v.048c0 3.737-2.984 5.952-7.467 5.952-2.812 0-5.526-.759-7.668-2.256-.342-.238-.67-.495-.982-.77l2.165-2.557.686-.81c1.821 1.433 3.855 2.194 5.99 2.194 1.377 0 2.116-.471 2.116-1.251v-.052c0-.761-.607-1.171-3.116-1.753-2.328-.528-4.342-1.128-5.597-2.31-.863-.81-1.366-1.898-1.366-3.428v-.05c-.023-1.349.464-2.657 1.366-3.667C16.24 1.219 18.234.39 20.806.39z"
                    transform="translate(0, 3)"
                />
            </g>
        </svg>
    )
}

export default JsLogo
