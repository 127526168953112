import { call, put } from 'redux-saga/effects'

import { makeFetchTableRequest, makeFetchListSuccess } from 'actions/ui/shared'
import { makeMountTabSuccess } from 'actions/ui/shared/tab'
import { ALERTS_SUMMARY_PAGE } from 'const/pages'
import { cerebroApiSaga } from 'sagas/common'
import uiSagaRegistry from 'sagas/ui/registry'
import { getAlerts } from 'services/cerebroApi/orgScope/alertsApi'

const TAB_PATH = [ALERTS_SUMMARY_PAGE, 'manageAlerts']
const LIST_PATH = [...TAB_PATH, 'list']

function* fetchAlertsSaga() {
    yield call(cerebroApiSaga, makeFetchListSuccess(LIST_PATH), getAlerts)
}

function* mountTabSaga() {
    yield makeMountTabSuccess(TAB_PATH)
}

function* fetchTabDataSaga() {
    yield put(makeFetchTableRequest(LIST_PATH)())
}

// Register Sagas
uiSagaRegistry.registerSagas(TAB_PATH, {
    mountTabSaga,
    fetchTabDataSaga,
})

uiSagaRegistry.registerSagas(LIST_PATH, {
    fetchDataSaga: fetchAlertsSaga,
})
