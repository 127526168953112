import { addCache } from 'helpers/utilities'
import { axiosInstanceOrganizations } from 'services/cerebroApi/orgScope/client'
import {
    BannerRequestBody,
    CerebroApiOptions,
    CerebroPaginatedResponse,
    CerebroResourceResponse,
    Report,
} from 'types'

import { getCerebroHeaders } from './headers'

export const getSBPurchasedProductsFacts = (
    params: BannerRequestBody,
    options: CerebroApiOptions = {}
): Promise<CerebroPaginatedResponse<any>> =>
    axiosInstanceOrganizations.post(
        '/api/sb_purchased_product_facts/aggregate/banner/',
        params,
        {
            headers: getCerebroHeaders(options.headers),
        }
    )
addCache(getSBPurchasedProductsFacts)

export const getSBPurchasedProductsFactsExport = (
    params: BannerRequestBody
): Promise<CerebroResourceResponse<Report>> =>
    axiosInstanceOrganizations.post(
        '/api/sb_purchased_product_facts/aggregate/banner/export/',
        params
    )
