import has from 'lodash/has'
import { call, put, select } from 'redux-saga/effects'

import { fetchChildrenSuccess } from 'actions/ui/productsSummaryPage'
import {
    makeFetchTableRequest,
    makeFetchTableSuccess,
    makeDownloadTableSuccess,
} from 'actions/ui/shared'
import { productsSummaryParentProductsTableColumnsConfig } from 'configuration/tables'
import { DATES } from 'const/filters'
import { PRODUCTS_SUMMARY_PAGE } from 'const/pages'
import { formatFilters } from 'helpers/filters/productAdFacts'
import { generateReportNotification } from 'helpers/notifications'
import {
    formatPagination,
    formatCurrency,
    formatColumns,
    formatSorter,
    formatMetrics,
    formatPeriodDeltaDateRange,
} from 'helpers/params'
import { cerebroApiSaga } from 'sagas/common'
import uiSagaRegistry from 'sagas/ui/registry'
import {
    selectTableSettings,
    selectVisibleCombinedFilters,
    selectVisibleMetricsOfTable,
    selectVisibleColumnsOfTable,
    selectCurrencyCode,
} from 'selectors/ui'
import {
    getProductAdFactsExport,
    getProductAdFacts,
} from 'services/cerebroApi/orgScope/productAdFactsApi'

const TAB_PATH = [PRODUCTS_SUMMARY_PAGE, 'parentProducts']
const TABLE_PATH = [...TAB_PATH, 'table']

export function* fetchChildrenWorker(action) {
    const record = action.payload

    const filters = yield select(selectVisibleCombinedFilters, TAB_PATH)
    const { sorter, showPeriodDeltas, periodDeltaType, periodDeltaDateRange } =
        yield select(selectTableSettings, TABLE_PATH)
    const metrics = yield select(
        selectVisibleMetricsOfTable,
        TABLE_PATH,
        productsSummaryParentProductsTableColumnsConfig
    )
    const currency = yield select(selectCurrencyCode)

    const params = {
        ...formatFilters(filters),
        ...formatSorter(sorter),
        ...formatMetrics(metrics, showPeriodDeltas),
        ...formatCurrency(currency),
        ...formatPeriodDeltaDateRange(
            showPeriodDeltas,
            periodDeltaType,
            periodDeltaDateRange,
            filters[DATES]
        ),
        limit: 100,
        group_by: 'product_ad__asin,profile__country_code,metadata_id',
        profile__country_code: record.profile__country_code,
        product_ad__metadata__parent_asin: record.primary_asin,
    }

    const response = yield call(cerebroApiSaga, null, getProductAdFacts, params)

    if (response) {
        const children =
            response.data.count > 0
                ? [
                      ...response.data.results.map((child) => ({
                          ...child,
                          primary_metadata: child.metadata,
                          primary_asin: child.product_ad__asin,
                          primary_metadata_id: child.metadata_id,
                      })),
                  ]
                : []

        yield put(
            fetchChildrenSuccess({
                record,
                children,
            })
        )
    }
}

function* fetchTableSaga(noCount) {
    const filters = yield select(selectVisibleCombinedFilters, TAB_PATH)
    const {
        pagination,
        sorter,
        showPeriodDeltas,
        periodDeltaType,
        periodDeltaDateRange,
    } = yield select(selectTableSettings, TABLE_PATH)
    const metrics = yield select(
        selectVisibleMetricsOfTable,
        TABLE_PATH,
        productsSummaryParentProductsTableColumnsConfig
    )
    const currency = yield select(selectCurrencyCode)
    const params = {
        ...formatFilters(filters),
        ...formatPagination(pagination),
        ...formatSorter(sorter),
        ...formatMetrics(metrics, showPeriodDeltas),
        ...formatCurrency(currency),
        ...formatPeriodDeltaDateRange(
            showPeriodDeltas,
            periodDeltaType,
            periodDeltaDateRange,
            filters[DATES]
        ),
        group_by: 'primary_asin,profile__country_code,primary_metadata_id',
    }
    const response = yield call(
        cerebroApiSaga,
        null,
        getProductAdFacts,
        params,
        {
            headers: { noCount },
        }
    )

    if (has(response, ['data', 'results'])) {
        const adjustedData = {
            ...response.data,
            results: response.data.results.map((record) => ({
                ...record,
                ...(record.primary_metadata && record.primary_metadata.is_parent
                    ? { children: [] }
                    : {}),
            })),
        }

        yield put(makeFetchTableSuccess(TABLE_PATH)(adjustedData))
    }
}

function* downloadTableSaga(path) {
    const filters = yield select(selectVisibleCombinedFilters, TAB_PATH)
    const { sorter, showPeriodDeltas, periodDeltaType, periodDeltaDateRange } =
        yield select(selectTableSettings, TABLE_PATH)
    const columns = yield select(
        selectVisibleColumnsOfTable,
        path,
        productsSummaryParentProductsTableColumnsConfig
    )

    const currency = yield select(selectCurrencyCode)
    const reportName = 'All Parent Products Report'
    const params = {
        ...formatFilters(filters),
        ...formatSorter(sorter),
        ...formatColumns(columns, showPeriodDeltas),
        ...formatCurrency(currency),
        ...formatPeriodDeltaDateRange(
            showPeriodDeltas,
            periodDeltaType,
            periodDeltaDateRange,
            filters[DATES]
        ),
        group_by: 'primary_asin,profile__country_code',
        primary_metadata__parent_asin__isnull: true,
        async_download_name: reportName,
    }
    yield call(
        cerebroApiSaga,
        makeDownloadTableSuccess(path),
        getProductAdFactsExport,
        params
    )

    generateReportNotification(reportName)
}

function* fetchTabDataSaga() {
    yield put(makeFetchTableRequest(TABLE_PATH)())
}

// Register Sagas
uiSagaRegistry.registerSagas(TAB_PATH, {
    fetchTabDataSaga,
})

uiSagaRegistry.registerSagas(TABLE_PATH, {
    fetchTableSaga,
    downloadTableSaga,
})
