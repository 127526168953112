import { ReactElement, useCallback, useState } from 'react'

import { Select, Spin } from 'antd'
import { useTranslation } from 'react-i18next'

import { SEARCH_RESULTS_PER_QUERY } from 'configuration/typeahead'
import { useCerebroApiRequest, useTypeahead } from 'hooks'
import { getAutomationProfiles } from 'services/cerebroApi/orgScope/resourceApi'
import { AutomationProfile, CerebroPaginatedResponse } from 'types'

interface Props {
    value?: string
    onChange: (value: string, profile: AutomationProfile) => void
}

const AutomationProfileSearchSelect = ({
    value,
    onChange,
}: Props): ReactElement => {
    const { t } = useTranslation('table')
    const makeCerebroApiRequest = useCerebroApiRequest()
    const [profileMap, setProfileMap] = useState<{
        [id: string]: AutomationProfile
    }>({})

    const getAutomationProfilesAndPopulateMap = useCallback(
        async (
            query: string | undefined,
            limit: number | 'null'
        ): Promise<CerebroPaginatedResponse<AutomationProfile>> => {
            const params: any = {
                limit,
            }
            if (query) {
                params.name__icontains = query
            }
            const response = await makeCerebroApiRequest({
                request: getAutomationProfiles(params, {
                    headers: { noCount: true },
                }),
            })
            const profiles = response?.data?.results || []
            const newProfileMap: { [id: string]: AutomationProfile } =
                profiles.reduce(
                    (actualMap: any, profile: AutomationProfile) => ({
                        ...actualMap,
                        [profile.id]: profile,
                    }),
                    {}
                )
            setProfileMap((currentProfiles) => ({
                ...currentProfiles,
                ...newProfileMap,
            }))
            return response as CerebroPaginatedResponse<AutomationProfile>
        },
        [makeCerebroApiRequest]
    )
    const [options, loading, handleOnSearch, resetLoadingState] =
        useTypeahead<AutomationProfile>({
            apiSearchFunc: (query) =>
                getAutomationProfilesAndPopulateMap(
                    query,
                    SEARCH_RESULTS_PER_QUERY
                ),
            optionFormatter: (profile) => ({
                value: profile.id,
                label: profile.name,
            }),
            prefetchApiFunc: useCallback(
                () => getAutomationProfilesAndPopulateMap(undefined, 'null'),
                [getAutomationProfilesAndPopulateMap]
            ),
        })

    const handleOnChange = (profileId: string): void => {
        const profile = profileMap[profileId]
        onChange(profileId, profile)
    }

    return (
        <Select
            filterOption
            optionFilterProp="title"
            placeholder={t(
                'table:BulkActions.AutomationProfileForm.automation_profile_id.placeholder',
                'Search Automation Profiles...'
            )}
            loading={loading}
            notFoundContent={loading ? <Spin size="small" /> : null}
            onChange={handleOnChange}
            onSearch={handleOnSearch}
            onSelect={resetLoadingState}
            showSearch
            style={{ width: '100%', minWidth: 200 }}
            value={value}
        >
            {options.map((option) => (
                <Select.Option
                    key={option.value}
                    value={option.value}
                    title={option.label}
                >
                    {option.label}
                </Select.Option>
            ))}
        </Select>
    )
}

export default AutomationProfileSearchSelect
