import isNil from 'lodash/isNil'
import { Link } from 'react-router-dom'

import {
    DeleteCustomEventField,
    CustomEventDescriptionField,
    CustomEventNotesField,
} from 'components/EditableFields'
import { AdAccountLink } from 'components/Links'
import { titleCase, formatSovKeywordLabel } from 'helpers/formatting'
import {
    portfolioUrl,
    campaignUrl,
    productUrl,
    productAdUrl,
    keywordUrl,
    sovKeywordUrl,
    adGroupUrl,
} from 'helpers/urls'
import {
    Field,
    CustomEvent,
    Portfolio,
    Campaign,
    Product,
    ProductAd,
    Keyword,
    SovKeyword,
    AdGroup,
} from 'types'

import { createActionField, createField } from './createField'
import { FieldCreatorOptions } from './localTypes'

export function description(
    options: FieldCreatorOptions<CustomEvent> = {}
): Field<CustomEvent> {
    const dataIndex = options.dataIndex ?? ['description']
    const title = 'Event Description'
    return createField({
        ...options,
        id: 'description',
        name: title,
        shortName: title,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: ({ record }) => (
                <CustomEventDescriptionField record={record} />
            ),
        },
        minWidth: 100,
        width: 175,
    })
}

export function resource<RecordType extends CustomEvent>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['resource_id']
    const title = 'Associated Resource'
    return createField({
        ...options,
        id: 'resource_id',
        name: title,
        shortName: title,
        minWidth: 100,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: ({ value, record }) => {
                if (isNil(record.resource)) {
                    return <i>Global Event</i>
                }

                if (record.resource_type === 'profile') {
                    return <AdAccountLink adAccount={record.resource} />
                }

                if (record.resource_type === 'portfolio') {
                    const portfolio = record.resource as Portfolio
                    return (
                        <Link to={portfolioUrl(value)}>{portfolio.name}</Link>
                    )
                }

                if (record.resource_type === 'campaign') {
                    const campaign = record.resource as Campaign
                    return <Link to={campaignUrl(value)}>{campaign.name}</Link>
                }

                if (record.resource_type === 'keyword') {
                    const keyword = record.resource as Keyword
                    return <Link to={keywordUrl(value)}>{keyword.text}</Link>
                }

                if (record.resource_type === 'ad_group') {
                    const adGroup = record.resource as AdGroup
                    return (
                        <Link to={adGroupUrl(adGroup.campaign_id, value)}>
                            {adGroup.name}
                        </Link>
                    )
                }

                if (record.resource_type === 'sov_keyword') {
                    const sovKeyword = record.resource as SovKeyword
                    return (
                        <Link to={sovKeywordUrl(value)}>
                            {formatSovKeywordLabel(sovKeyword)}
                        </Link>
                    )
                }

                if (record.resource_type === 'product') {
                    const product = record.resource as Product
                    return (
                        <Link
                            to={productUrl(product.asin, product.marketplace)}
                        >
                            {product.product_metadata?.title ?? value}
                        </Link>
                    )
                }

                if (record.resource_type === 'product_ad') {
                    const productAd = record.resource as ProductAd
                    return (
                        <Link to={productAdUrl(productAd.campaign_id, value)}>
                            {productAd?.product_metadata?.title ?? value}
                        </Link>
                    )
                }

                return value
            },
        },
        width: 175,
        sorter: options.sorter ?? false,
    })
}

export function resourceType<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['resource_type']
    const title = 'Resource Type'
    return createField({
        ...options,
        id: 'resource_type',
        name: title,
        shortName: title,
        minWidth: 100,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: ({ value }) => {
                if (isNil(value)) {
                    return <i>n/a</i>
                }

                if (value === 'profile') {
                    return 'Brand'
                }

                return titleCase(value)
            },
        },
        width: 125,
        sorter: options.sorter ?? false,
    })
}

export function notes(
    options: FieldCreatorOptions<CustomEvent> = {}
): Field<CustomEvent> {
    const dataIndex = options.dataIndex ?? ['notes']
    const title = 'Notes'
    return createField({
        ...options,
        id: 'notes',
        name: title,
        shortName: title,
        minWidth: 100,
        dataIndex,
        renderOptions: options.renderOptions ?? {
            render: ({ record }) => <CustomEventNotesField record={record} />,
        },
        width: 250,
    })
}

export function deleteCustomEvent(
    options: FieldCreatorOptions<CustomEvent> = {}
): Field<CustomEvent> {
    return createActionField({
        ...options,
        id: 'delete_custom_event',
        dataIndex: [],
        isResizeable: false,
        minWidth: 68,
        renderOptions: options.renderOptions ?? {
            render: ({ record, reloadData }) => (
                <DeleteCustomEventField
                    record={record}
                    reloadData={reloadData}
                />
            ),
        },
        sorter: false,
        fixed: 'right',
        align: 'center',
    })
}
