import { SVGAttributes, ReactElement } from 'react'

type Props = SVGAttributes<SVGElement>

function SvgProductAdIcon(props: Props): ReactElement {
    return (
        <svg
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            fill="none"
            {...props}
        >
            <path
                d="M10 5H6C4.89543 5 4 5.89543 4 7V12C4 13.1046 4.89543 14 6 14H14C15.1046 14 16 13.1046 16 12V7C16 5.89543 15.1046 5 14 5H13V6H14C14.5523 6 15 6.44772 15 7V12C15 12.5523 14.5523 13 14 13H6C5.44772 13 5 12.5523 5 12V7C5 6.44772 5.44772 6 6 6H10V5Z"
                fill="currentColor"
            />
            <path
                d="M2 2H10C10.5523 2 11 2.44772 11 3V8C11 8.55228 10.5523 9 10 9H6V10H10C11.1046 10 12 9.10457 12 8V3C12 1.89543 11.1046 1 10 1H2C0.895431 1 0 1.89543 0 3V8C0 9.10457 0.895431 10 2 10H3V9H2C1.44772 9 1 8.55228 1 8V3C1 2.44772 1.44772 2 2 2Z"
                fill="currentColor"
            />
        </svg>
    )
}

export default SvgProductAdIcon
