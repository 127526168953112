import { ReactElement, useState } from 'react'

import { Button, Row, Col, Input } from 'antd'
import classNames from 'classnames'

import { LoadingIndicator } from 'components/LoadingIndicator'
import { isWalmartItemId } from 'helpers/walmart'
import { useTypeahead, useAuthDomainValue } from 'hooks'
import { searchWalmartItemsCatalog } from 'services/cerebroApi/orgScope/walmartApi'
import { WalmartAdvertiser } from 'types'

import { CatalogItem } from './localTypes'
import styles from './styles.scss'

interface Props {
    addAdItem: (itemId: string, name: string) => void
    addedAdItemIds: string[]
}

function SearchAdItem({ addAdItem, addedAdItemIds }: Props): ReactElement {
    const { advertiser_id } = useAuthDomainValue<WalmartAdvertiser>(
        'latestWalmartAdvertiser'
    )
    const [showInitialState, setShowInitialState] = useState<boolean>(true)

    const [options, loading, onSearch] = useTypeahead<CatalogItem>({
        apiSearchFunc: (query) => {
            const params = isWalmartItemId(query)
                ? {
                      searchItemIds: [query],
                  }
                : {
                      searchText: query,
                  }

            setShowInitialState(false)

            return searchWalmartItemsCatalog(advertiser_id, params)
        },
        optionFormatter: (item) => ({
            value: item.itemId,
            label: item.itemName,
        }),
        resultsDataIndex: 'data',
    })

    const itemAlreadyAdded = (itemId: string): boolean =>
        addedAdItemIds.includes(itemId)

    const renderSearchResults = (): ReactElement => {
        if (loading) {
            return (
                <div className={styles['results-empty-state']}>
                    <LoadingIndicator size="small" fullContent={false} />
                </div>
            )
        }
        if (options.length > 0) {
            return (
                <>
                    {options.map((option) => (
                        <Row
                            className={styles['item-row-result']}
                            gutter={[25, 0]}
                            key={option.value}
                        >
                            <Button
                                type="text"
                                block
                                disabled={itemAlreadyAdded(
                                    option.value as string
                                )}
                                onClick={() => {
                                    const itemId = option.value as string
                                    const name = option.label
                                    addAdItem(itemId, name)
                                }}
                            >
                                <Col span={18}>
                                    <div
                                        className={styles['overflow-ellipsis']}
                                    >
                                        {option.label}
                                    </div>
                                </Col>
                                <Col span={6}>{option.value}</Col>
                            </Button>
                        </Row>
                    ))}
                </>
            )
        }

        return (
            <div className={styles['results-empty-state']}>
                No Items match this search.
            </div>
        )
    }

    return (
        <>
            <Input.Search
                onSearch={onSearch}
                onChange={(e) => {
                    const searchTerm = e.target.value

                    if (searchTerm.length === 0) {
                        setShowInitialState(true)
                    }

                    onSearch(searchTerm)
                }}
                loading={loading}
                placeholder="Search Product Name or Item ID"
                allowClear
                className={styles['item-search-input']}
            />

            <div
                className={classNames(styles['item-search-body'], {
                    [styles['initial-state']]: showInitialState,
                })}
            >
                {showInitialState ? (
                    'Search above by Product Name or Item ID to add items.'
                ) : (
                    <>
                        <Row
                            className={styles['item-row-header']}
                            gutter={[25, 0]}
                        >
                            <Col span={18}>Name</Col>
                            <Col span={6}>Item ID</Col>
                        </Row>
                        {renderSearchResults()}
                    </>
                )}
            </div>
        </>
    )
}

export default SearchAdItem
