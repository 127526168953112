import { createAction } from 'redux-actions'

import { curryActionForPage, curryActionForResource } from 'helpers/curry'

// Fetch
export const fetchResourceSuccess = createAction('FETCH_RESOURCE_SUCCESS')
export const makeFetchResourceSuccess = curryActionForPage(fetchResourceSuccess)

// Update
export const updateResourceRequest = createAction('UPDATE_RESOURCE_REQUEST')
export const updateResourceSuccess = createAction('UPDATE_RESOURCE_SUCCESS')
export const updateResourceFailure = createAction('UPDATE_RESOURCE_FAILURE')

export const makeUpdateResourceRequest = curryActionForResource(
    updateResourceRequest
)
export const makeUpdateResourceSuccess = curryActionForResource(
    updateResourceSuccess
)
export const makeUpdateResourceFailure = curryActionForResource(
    updateResourceFailure
)
