import { SVGAttributes, ReactElement } from 'react'

type Props = SVGAttributes<SVGElement>

function SvgUserIcon(props: Props): ReactElement {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M10 3.30001C8.37077 3.30001 7.05001 4.62077 7.05001 6.25001C7.05001 7.87925 8.37077 9.20001 10 9.20001C11.6293 9.20001 12.95 7.87925 12.95 6.25001C12.95 4.62077 11.6293 3.30001 10 3.30001ZM5.45001 6.25001C5.45001 3.73712 7.48712 1.70001 10 1.70001C12.5129 1.70001 14.55 3.73712 14.55 6.25001C14.55 8.76291 12.5129 10.8 10 10.8C7.48712 10.8 5.45001 8.76291 5.45001 6.25001ZM10 14.1333C7.33062 14.1333 4.89253 15.2953 3.08209 17.2155C2.779 17.537 2.27269 17.5519 1.95121 17.2488C1.62974 16.9457 1.61484 16.4394 1.91794 16.1179C4.00048 13.9091 6.84776 12.5333 10 12.5333C13.1523 12.5333 15.9996 13.9091 18.0821 16.1179C18.3852 16.4394 18.3703 16.9457 18.0488 17.2488C17.7273 17.5519 17.221 17.537 16.9179 17.2155C15.1075 15.2953 12.6694 14.1333 10 14.1333Z" />
        </svg>
    )
}

export default SvgUserIcon
