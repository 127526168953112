import { handleActions } from 'redux-actions'

import { defaultTab, getDefaultList } from 'reducers/ui/defaults'

const initialState = {
    ...defaultTab,
    list: getDefaultList(),
}

export default handleActions({}, initialState)
