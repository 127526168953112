import { combineChildrenReducers } from 'utilities/reducers'

// Tab reducers
import allDashboardsReducer from './allDashboards'
import managedByDownstreamReducer from './managedByDownstream'
import myDashboardsReducer from './myDashboards'
import pageReducer from './page'
import sharedWithMeReducer from './sharedWithMe'

export default combineChildrenReducers(pageReducer, {
    allDashboards: allDashboardsReducer,
    managedByDownstream: managedByDownstreamReducer,
    myDashboards: myDashboardsReducer,
    sharedWithMe: sharedWithMeReducer,
})
