import { ReactElement } from 'react'

import isEmpty from 'lodash/isEmpty'
import queryString from 'query-string'
import { Link } from 'react-router-dom'

import { UNDEFINED_VALUE } from 'const/formatting'
import { automationProfileUrl } from 'helpers/urls'
import { AutomationProfile } from 'types'

interface Props {
    automationProfile: AutomationProfile | null
    queryParams?: {}
}

const AutomationProfileLink = ({
    automationProfile = null,
    queryParams = {},
}: Props): ReactElement => {
    if (!automationProfile || isEmpty(automationProfile)) {
        return <span>{UNDEFINED_VALUE}</span>
    }

    const pathname = automationProfileUrl(automationProfile.id)
    const search = queryString.stringify(queryParams)

    return <Link to={{ pathname, search }}>{automationProfile.name}</Link>
}

export default AutomationProfileLink
