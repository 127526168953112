import {
    makeCountMetricFieldFunc,
    makeCurrencyMetricFieldFunc,
    makePercentageMetricFieldFunc,
} from './localUtils'

// Count Metrics
export const num_ads_shown__sum = makeCountMetricFieldFunc({
    name: 'Impressions',
    shortName: 'Impressions',
    deltas: ['change', 'percentage_change'],
    id: 'num_ads_shown__sum',
})

export const num_ads_clicks__sum = makeCountMetricFieldFunc({
    name: 'Clicks',
    shortName: 'Clicks',
    deltas: ['change', 'percentage_change'],
    id: 'num_ads_clicks__sum',
})

export const units_sold_3_days__sum = makeCountMetricFieldFunc({
    name: 'Attributed Units Ordered (3 Day)',
    shortName: 'Units Ordered (3d)',
    deltas: ['change', 'percentage_change'],
    id: 'units_sold_3_days__sum',
})

export const units_sold_14_days__sum = makeCountMetricFieldFunc({
    name: 'Attributed Units Ordered (14 Day)',
    shortName: 'Units Ordered (14d)',
    deltas: ['change', 'percentage_change'],
    id: 'units_sold_14_days__sum',
})

export const units_sold_30_days__sum = makeCountMetricFieldFunc({
    name: 'Attributed Units Ordered (30 Day)',
    shortName: 'Units Ordered (30d)',
    deltas: ['change', 'percentage_change'],
    id: 'units_sold_30_days__sum',
})

// Currency Metrics
export const ad_spend__sum = makeCurrencyMetricFieldFunc({
    name: 'Ad Spend',
    shortName: 'Ad Spend',
    deltas: ['change', 'percentage_change'],
    id: 'ad_spend__sum',
})

export const view_revenue_3_days__sum = makeCurrencyMetricFieldFunc({
    name: 'View Revenue (3 Day)',
    shortName: 'View Revenue (3d)',
    deltas: ['change', 'percentage_change'],
    id: 'view_revenue_3_days__sum',
})

export const view_revenue_14_days__sum = makeCurrencyMetricFieldFunc({
    name: 'View Revenue (14 Day)',
    shortName: 'View Revenue (14d)',
    deltas: ['change', 'percentage_change'],
    id: 'view_revenue_14_days__sum',
})

export const view_revenue_30_days__sum = makeCurrencyMetricFieldFunc({
    name: 'View Revenue (30 Day)',
    shortName: 'View Revenue (30d)',
    deltas: ['change', 'percentage_change'],
    id: 'view_revenue_30_days__sum',
})

export const ad_revenue_3_days__sum = makeCurrencyMetricFieldFunc({
    name: 'Attributed Sales (3 Day)',
    shortName: 'Attributed Sales (3d)',
    deltas: ['change', 'percentage_change'],
    id: 'ad_revenue_3_days__sum',
})

export const ad_revenue_14_days__sum = makeCurrencyMetricFieldFunc({
    name: 'Attributed Sales (14 Day)',
    shortName: 'Attributed Sales (14d)',
    deltas: ['change', 'percentage_change'],
    id: 'ad_revenue_14_days__sum',
})

export const ad_revenue_30_days__sum = makeCurrencyMetricFieldFunc({
    name: 'Attributed Sales (30 Day)',
    shortName: 'Attributed Sales (30d)',
    deltas: ['change', 'percentage_change'],
    id: 'ad_revenue_30_days__sum',
})

export const brand_view_revenue_3_days__sum = makeCurrencyMetricFieldFunc({
    name: 'Brand View Revenue (3 Day)',
    shortName: 'Brand View Revenue (3d)',
    deltas: ['change', 'percentage_change'],
    id: 'brand_view_revenue_3_days__sum',
})

export const brand_view_revenue_14_days__sum = makeCurrencyMetricFieldFunc({
    name: 'Brand View Revenue (14 Day)',
    shortName: 'Brand View Revenue (14d)',
    deltas: ['change', 'percentage_change'],
    id: 'brand_view_revenue_14_days__sum',
})

export const brand_view_revenue_30_days__sum = makeCurrencyMetricFieldFunc({
    name: 'Brand View Revenue (30 Day)',
    shortName: 'Brand View Revenue (30d)',
    deltas: ['change', 'percentage_change'],
    id: 'brand_view_revenue_30_days__sum',
})

export const ctr = makePercentageMetricFieldFunc({
    name: 'Click Through Rate',
    shortName: 'CTR',
    deltas: ['change'],
    id: 'ctr',
})

export const acos = makePercentageMetricFieldFunc({
    name: 'Advertising Cost of Sales',
    shortName: 'ACoS',
    format: '0.00%',
    shortFormat: '0.[00]%',
    isInverse: true,
    deltas: ['change'],
    id: 'acos',
})

export const roas = makeCurrencyMetricFieldFunc({
    name: 'Return on Ad Spend',
    shortName: 'RoAS',
    deltas: ['change', 'percentage_change'],
    id: 'roas',
})

export const roasp = makePercentageMetricFieldFunc({
    name: 'Return on Ad Spend Percentage',
    shortName: 'RoAS',
    deltas: ['change'],
    id: 'roasp',
})

export const spc = makeCurrencyMetricFieldFunc({
    name: 'Sales per Click',
    shortName: 'SPC',
    deltas: ['change', 'percentage_change'],
    id: 'spc',
})

export const cpc = makeCurrencyMetricFieldFunc({
    name: 'Cost per Click',
    shortName: 'CPC',
    deltas: ['change', 'percentage_change'],
    id: 'cpc',
})

export const cpm = makeCurrencyMetricFieldFunc({
    name: 'Cost per Thousand Impressions',
    shortName: 'CPM',
    deltas: ['change', 'percentage_change'],
    id: 'cpm',
})

export const spm = makeCurrencyMetricFieldFunc({
    name: 'Sales per Thousand Impressions',
    shortName: 'SPM',
    deltas: ['change', 'percentage_change'],
    id: 'spm',
})

export const advertised_sku_sales_3_days__sum = makeCurrencyMetricFieldFunc({
    name: 'Advertised SKU Sales (3 Day)',
    shortName: 'Advertised SKU Sales (3d)',
    deltas: ['change', 'percentage_change'],
    id: 'advertised_sku_sales_3_days__sum',
})

export const advertised_sku_sales_14_days__sum = makeCurrencyMetricFieldFunc({
    name: 'Advertised SKU Sales (14 Day)',
    shortName: 'Advertised SKU Sales (14d)',
    deltas: ['change', 'percentage_change'],
    id: 'advertised_sku_sales_14_days__sum',
})

export const advertised_sku_sales_30_days__sum = makeCurrencyMetricFieldFunc({
    name: 'Advertised SKU Sales (30 Day)',
    shortName: 'Advertised SKU Sales (30d)',
    deltas: ['change', 'percentage_change'],
    id: 'advertised_sku_sales_30_days__sum',
})

export const advertised_sku_units_3_days__sum = makeCountMetricFieldFunc({
    name: 'Advertised SKU Units (3 Day)',
    shortName: 'Advertised SKU Units (3d)',
    deltas: ['change', 'percentage_change'],
    id: 'advertised_sku_units_3_days__sum',
})

export const advertised_sku_units_14_days__sum = makeCountMetricFieldFunc({
    name: 'Advertised SKU Units (14 Day)',
    shortName: 'Advertised SKU Units (14d)',
    deltas: ['change', 'percentage_change'],
    id: 'advertised_sku_units_14_days__sum',
})

export const advertised_sku_units_30_days__sum = makeCountMetricFieldFunc({
    name: 'Advertised SKU Units (30 Day)',
    shortName: 'Advertised SKU Units (30d)',
    deltas: ['change', 'percentage_change'],
    id: 'advertised_sku_units_30_days__sum',
})

export const attributed_orders_3_days__sum = makeCountMetricFieldFunc({
    name: 'Attributed Orders (3 Day)',
    shortName: 'Orders (3d)',
    deltas: ['change', 'percentage_change'],
    id: 'attributed_orders_3_days__sum',
})

export const attributed_orders_14_days__sum = makeCountMetricFieldFunc({
    name: 'Attributed Orders (14 Day)',
    shortName: 'Orders (14d)',
    deltas: ['change', 'percentage_change'],
    id: 'attributed_orders_14_days__sum',
})

export const attributed_orders_30_days__sum = makeCountMetricFieldFunc({
    name: 'Attributed Orders (30 Day)',
    shortName: 'Orders (30d)',
    deltas: ['change', 'percentage_change'],
    id: 'attributed_orders_30_days__sum',
})

export const ntb_orders_3_days__sum = makeCountMetricFieldFunc({
    name: 'New-to-brand (NTB) Orders (3 Day)',
    shortName: 'NTB Orders (3 Day)',
    deltas: ['change', 'percentage_change'],
    id: 'ntb_orders_3_days__sum',
})

export const ntb_orders_14_days__sum = makeCountMetricFieldFunc({
    name: 'New-to-brand (NTB) Orders (14 Day)',
    shortName: 'NTB Orders (14 Day)',
    deltas: ['change', 'percentage_change'],
    id: 'ntb_orders_14_days__sum',
})

export const ntb_orders_30_days__sum = makeCountMetricFieldFunc({
    name: 'New-to-brand (NTB) Orders (30 Day)',
    shortName: 'NTB Orders (30 Day)',
    deltas: ['change', 'percentage_change'],
    id: 'ntb_orders_30_days__sum',
})

export const ntb_revenue_3_days__sum = makeCurrencyMetricFieldFunc({
    name: 'New-to-brand (NTB) Sales (3 Day)',
    shortName: 'NTB Sales (3 Day)',
    deltas: ['change', 'percentage_change'],
    id: 'ntb_revenue_3_days__sum',
})

export const ntb_revenue_14_days__sum = makeCurrencyMetricFieldFunc({
    name: 'New-to-brand (NTB) Sales (14 Day)',
    shortName: 'NTB Sales (14 Day)',
    deltas: ['change', 'percentage_change'],
    id: 'ntb_revenue_14_days__sum',
})

export const ntb_revenue_30_days__sum = makeCurrencyMetricFieldFunc({
    name: 'New-to-brand (NTB) Sales (30 Day)',
    shortName: 'NTB Sales (30 Day)',
    deltas: ['change', 'percentage_change'],
    id: 'ntb_revenue_30_days__sum',
})

export const ntb_units_3_days__sum = makeCountMetricFieldFunc({
    name: 'New-to-brand (NTB) Units (3 Day)',
    shortName: 'NTB Units (3 Day)',
    deltas: ['change', 'percentage_change'],
    id: 'ntb_units_3_days__sum',
})

export const ntb_units_14_days__sum = makeCountMetricFieldFunc({
    name: 'New-to-brand (NTB) Units (14 Day)',
    shortName: 'NTB Units (30 Day)',
    deltas: ['change', 'percentage_change'],
    id: 'ntb_units_14_days__sum',
})

export const ntb_units_30_days__sum = makeCountMetricFieldFunc({
    name: 'New-to-brand (NTB) Units (30 Day)',
    shortName: 'NTB Units (30 Day)',
    deltas: ['change', 'percentage_change'],
    id: 'ntb_units_30_days__sum',
})

export const other_sku_sales_3_days__sum = makeCurrencyMetricFieldFunc({
    name: 'Other SKU Sales (3 Day)',
    shortName: 'Other SKU Sales (3 Day)',
    deltas: ['change', 'percentage_change'],
    id: 'other_sku_sales_3_days__sum',
})

export const other_sku_sales_14_days__sum = makeCurrencyMetricFieldFunc({
    name: 'Other SKU Sales (14 Day)',
    shortName: 'Other SKU Sales (14 Day)',
    deltas: ['change', 'percentage_change'],
    id: 'other_sku_sales_14_days__sum',
})

export const other_sku_sales_30_days__sum = makeCurrencyMetricFieldFunc({
    name: 'Other SKU Sales (30 Day)',
    shortName: 'Other SKU Sales (30 Day)',
    deltas: ['change', 'percentage_change'],
    id: 'other_sku_sales_30_days__sum',
})

export const other_sku_units_3_days__sum = makeCountMetricFieldFunc({
    name: 'Other SKU Units (3 Day)',
    shortName: 'Other SKU Units (3 Day)',
    deltas: ['change', 'percentage_change'],
    id: 'other_sku_units_3_days__sum',
})

export const other_sku_units_14_days__sum = makeCountMetricFieldFunc({
    name: 'Other SKU Units (14 Day)',
    shortName: 'Other SKU Units (14 Day)',
    deltas: ['change', 'percentage_change'],
    id: 'other_sku_units_14_days__sum',
})

export const other_sku_units_30_days__sum = makeCountMetricFieldFunc({
    name: 'Other SKU Units (30 Day)',
    shortName: 'Other SKU Units (30 Day)',
    deltas: ['change', 'percentage_change'],
    id: 'other_sku_units_30_days__sum',
})
