import { labelProfileRemoveFromLabelRenderer } from 'components/FieldRenderers'
import {
    brandCountryCode,
    brandCurrencyCode,
    brandEntityId,
    brandId,
    brandRegion,
    brandTimezone,
    brandType,
    removeBrandFromLabel,
} from 'configuration/fieldCreators/brand'
import { labels } from 'configuration/fieldCreators/labels'
import { Field, ProfileFact } from 'types'

import { AMS_METRICS_COLUMNS_PAGES } from '../dataSources/ams'

export const brandsSummaryTableColumnsConfig: Field<ProfileFact>[] = [
    brandId({ fixed: 'left' }),
    brandCountryCode(),
    brandCurrencyCode(),
    brandRegion(),
    brandTimezone(),
    brandType(),
    brandEntityId(),
    labels({ dataIndex: ['profile', 'labels'] }),
    ...(AMS_METRICS_COLUMNS_PAGES as Field<ProfileFact>[]),
]

export const labelBrandsTableColumnsConfig: Field<ProfileFact>[] = [
    brandId({
        fixed: 'left',
    }),
    brandCountryCode(),
    brandCurrencyCode(),
    brandRegion(),
    brandTimezone(),
    brandType(),
    brandEntityId(),
    labels({ dataIndex: ['profile', 'labels'] }),
    ...(AMS_METRICS_COLUMNS_PAGES as Field<ProfileFact>[]),
    removeBrandFromLabel({
        renderOptions: {
            render: (cellRenderProps) =>
                labelProfileRemoveFromLabelRenderer({ cellRenderProps }),
        },
    }),
]
