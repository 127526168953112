import type { ReactElement } from 'react'

import { CountryCode } from 'types'

import { ReactComponent as AeIcon } from './ae.svg'
import { ReactComponent as AuIcon } from './au.svg'
import { ReactComponent as BeIcon } from './be.svg'
import { ReactComponent as BrIcon } from './br.svg'
import { ReactComponent as CaIcon } from './ca.svg'
import { ReactComponent as DeIcon } from './de.svg'
import { ReactComponent as EsIcon } from './es.svg'
import { ReactComponent as FrIcon } from './fr.svg'
import { ReactComponent as InIcon } from './in.svg'
import { ReactComponent as ItIcon } from './it.svg'
import { ReactComponent as JpIcon } from './jp.svg'
import { ReactComponent as MxIcon } from './mx.svg'
import { ReactComponent as NlIcon } from './nl.svg'
import { ReactComponent as PlIcon } from './pl.svg'
import { ReactComponent as SaIcon } from './sa.svg'
import { ReactComponent as SeIcon } from './se.svg'
import { ReactComponent as SgIcon } from './sg.svg'
import { ReactComponent as TrIcon } from './tr.svg'
import { ReactComponent as UkIcon } from './uk.svg'
import { ReactComponent as UsIcon } from './us.svg'

export const flagComponents: Record<CountryCode, ReactElement> = {
    AE: <AeIcon />,
    AU: <AuIcon />,
    BE: <BeIcon />,
    BR: <BrIcon />,
    CA: <CaIcon />,
    DE: <DeIcon />,
    ES: <EsIcon />,
    FR: <FrIcon />,
    IN: <InIcon />,
    IT: <ItIcon />,
    JP: <JpIcon />,
    MX: <MxIcon />,
    NL: <NlIcon />,
    PL: <PlIcon />,
    SA: <SaIcon />,
    SE: <SeIcon />,
    SG: <SgIcon />,
    TR: <TrIcon />,
    UK: <UkIcon />,
    US: <UsIcon />,
} as Record<CountryCode, ReactElement>
