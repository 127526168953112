import { ReactElement } from 'react'

import Icon from '@ant-design/icons'

import PaintBrushIconSvg from './PaintBrushIconSvg'
import { IconProps } from '../localTypes'

const PaintBrushIcon = (props: IconProps): ReactElement => (
    <Icon component={PaintBrushIconSvg} {...props} />
)

export default PaintBrushIcon
