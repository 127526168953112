export const ASIN_DOMAINS = {
    AE: 'www.amazon.ae',
    AU: 'www.amazon.com.au',
    BE: 'www.amazon.be',
    BR: 'www.amazon.com.br',
    CA: 'www.amazon.ca',
    DE: 'www.amazon.de',
    ES: 'www.amazon.es',
    FR: 'www.amazon.fr',
    IN: 'www.amazon.in',
    IT: 'www.amazon.it',
    JP: 'www.amazon.co.jp',
    MX: 'www.amazon.com.mx',
    NL: 'www.amazon.nl',
    PL: 'www.amazon.pl',
    SA: 'www.amazon.sa',
    SE: 'www.amazon.se',
    SG: 'www.amazon.sg',
    TR: 'www.amazon.com.tr',
    UK: 'www.amazon.co.uk',
    US: 'www.amazon.com',
}

export const WALMART_ITEM_DOMAIN = 'www.walmart.com'

export const HELP_CENTER = 'https://cobaltjs.zendesk.com'

export const CONTACT_US = `${HELP_CENTER}/hc/en-us/requests/new`
