import { handleActions } from 'redux-actions'

import { DATES } from 'const/filters'
import { AD_GROUP_PAGE } from 'const/pages'
import { LOCAL_FILTERS, FILTER_SETTINGS } from 'const/reducerKeys'
import {
    defaultResourcePage,
    defaultResourcePageTabs,
    defaultResourcePageWidgets,
} from 'reducers/ui/defaults'

const defaultState = {
    [LOCAL_FILTERS]: {},
    [FILTER_SETTINGS]: {
        anchored: [DATES],
        order: [],
        displayState: {},
    },
    ...defaultResourcePage,
    tabs: defaultResourcePageTabs,
    widgets: defaultResourcePageWidgets(AD_GROUP_PAGE),
}

export default handleActions({}, defaultState)
