import { memo, ReactElement } from 'react'

import isEqual from 'lodash/isEqual'
import pick from 'lodash/pick'
import { useTranslation } from 'react-i18next'

import {
    WALMART_AD_ITEM_STATE_FIELD_OPTIONS,
    DISABLED,
    ENABLED,
} from 'const/resourceStates'
import { userHasWalmartAdvertisingWritePermissions } from 'helpers/featurePermissions'
import { formatWalmartResourceStateField } from 'helpers/walmart'
import { useUserHasPermissions } from 'hooks'
import { updateWalmartAdItem } from 'services/cerebroApi/orgScope/walmartApi'
import { WalmartAdItemGroupBy } from 'types'

import { StateField } from '../../SharedFields'

interface Props {
    dataIndex: string[]
    record: WalmartAdItemGroupBy
}

function WalmartAdItemStateField({
    dataIndex = ['ad_item', 'state'],
    record,
}: Props): ReactElement {
    const { t } = useTranslation('table')
    const hasWalmartPermissions = useUserHasPermissions(
        userHasWalmartAdvertisingWritePermissions
    )

    const resourceId = record.ad_item.id

    const availableOptions = pick(WALMART_AD_ITEM_STATE_FIELD_OPTIONS, [
        ENABLED,
        DISABLED,
    ])

    return (
        <StateField
            fieldName="state"
            fieldPath={dataIndex}
            formatFieldValue={() =>
                formatWalmartResourceStateField(record, 'ad_item')
            }
            options={availableOptions}
            placeholder="Ad Item State"
            readOnly={!record.ad_item.user_can_manage || !hasWalmartPermissions}
            record={record}
            updateRequestApi={updateWalmartAdItem}
            updateRequestData={resourceId}
            updateRequestSuccessMesg={t(
                'table:fields.walmartAdItem.update.stateUpdateSuccessMsg',
                'Successfully updated ad item state'
            )}
        />
    )
}

export default memo(
    WalmartAdItemStateField,
    isEqual
) as typeof WalmartAdItemStateField
