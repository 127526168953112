import { ReactElement } from 'react'

import { InfoCircleOutlined } from '@ant-design/icons'
import { Tag } from 'antd'
import get from 'lodash/get'
import { Trans } from 'react-i18next'
import { generatePath, Link } from 'react-router-dom'

import { ToolTip } from 'components/ToolTip'
import { UNDEFINED_VALUE } from 'const/formatting'
import { SEGMENT_PAGE } from 'const/pages'
import { getPath } from 'helpers/pages'
import { isUnset } from 'helpers/utilities'
import { CellRenderProps, Segment } from 'types'

type RenderProps<RecordType> = {
    cellRenderProps: CellRenderProps<RecordType>
}

export function segmentNameRenderer<RecordType extends Segment>(
    props: RenderProps<RecordType>,
    pathToSegment: string[] = []
): ReactElement {
    const {
        cellRenderProps: { record },
    } = props
    const segment =
        !!record && pathToSegment?.length ? get(record, pathToSegment) : record

    if (isUnset(segment)) {
        return <span>{UNDEFINED_VALUE}</span>
    }

    const tooltipMessage = (
        <Trans i18nKey="segments:Tooltip.Table.Demo">
            ASINs in this segment do not count against your monthly total.
        </Trans>
    )

    const demoTag = segment.is_demo ? (
        <ToolTip title={tooltipMessage}>
            <Tag className="fg-default-tag" bordered={false}>
                Demo
            </Tag>
        </ToolTip>
    ) : null

    return (
        <div className="d-flex align-items-center">
            {demoTag}
            <Link
                to={{
                    pathname: generatePath(getPath(SEGMENT_PAGE), {
                        segmentId: segment.id,
                    }),
                }}
            >
                {segment.name}
            </Link>
        </div>
    )
}

export function segmentAsinsRenderer(
    props: RenderProps<Segment>,
    pathToSegment: string[] = []
): ReactElement {
    const {
        cellRenderProps: { record },
    } = props
    const segment =
        !!record && pathToSegment?.length ? get(record, pathToSegment) : record

    const tooltipMessage = (
        <Trans i18nKey="segments:Tooltip.Table.Demo">
            ASINs in this segment do not count against your monthly total.
        </Trans>
    )

    const notCountedMsg = segment.is_demo ? (
        <span>
            Not counted{' '}
            <ToolTip title={tooltipMessage}>
                <InfoCircleOutlined />
            </ToolTip>
        </span>
    ) : null

    return (
        <div>
            <div>{record.asin_count ?? UNDEFINED_VALUE}</div>
            <small>{notCountedMsg}</small>
        </div>
    )
}
