import { captureException } from '@sentry/browser'
import { call, put, select } from 'redux-saga/effects'

import {
    makeAddLabelsFailure,
    makeAddLabelsSuccess,
} from 'actions/ui/shared/label'
import uiSagaRegistry from 'sagas/ui/registry'
import { selectResourceParamsOfPage } from 'selectors/ui'

/**
 * Add Labels
 */
export function* addLabelsWorker(action) {
    const { pageName, data } = action.payload

    try {
        const addLabelsSaga = uiSagaRegistry.lookupSaga(
            [pageName],
            'addLabelsSaga'
        )

        const resourceParams = yield select(
            selectResourceParamsOfPage,
            pageName
        )

        if (addLabelsSaga && resourceParams) {
            yield call(addLabelsSaga, resourceParams, data)
        }

        yield put(makeAddLabelsSuccess(pageName)())
    } catch (error) {
        yield call(captureException, error)
        yield put(makeAddLabelsFailure(pageName)(error))
    }
}

/**
 * Add Labels
 */
export function* removeLabelWorker(action) {
    const { pageName, data } = action.payload

    try {
        const removeLabelSaga = uiSagaRegistry.lookupSaga(
            [pageName],
            'removeLabelSaga'
        )

        const resourceParams = yield select(
            selectResourceParamsOfPage,
            pageName
        )

        if (removeLabelSaga && resourceParams) {
            yield call(removeLabelSaga, resourceParams, data)
        }
    } catch (error) {
        yield call(captureException, error)
    }
}
