import { ReactElement } from 'react'

import { DeleteOutlined } from '@ant-design/icons'
import { Select } from 'antd'
import classNames from 'classnames'
import { Field, ErrorMessage } from 'formik'
import map from 'lodash/map'
import pick from 'lodash/pick'

import { IconButton } from 'components/Buttons'
import { FormikSelect } from 'components/formik'
import { STATE_FIELD_OPTIONS, PAUSED, ENABLED } from 'const/resourceStates'
import { Campaign } from 'types'

import { ProductAdField } from './localTypes'
import styles from './styles.scss'
import { AdGroupSelectFormikField } from '../Shared/AdGroupSelectField'

interface Props {
    campaign: Campaign
    product: ProductAdField
    productIndex: number
    remove: (index: number) => void
}

const CreateProductAdFormRow = ({
    campaign,
    product,
    productIndex,
    remove,
}: Props): ReactElement => {
    return (
        <div className="d-flex align-items-start mt-2">
            <div style={{ flex: 1 }} className="mr-1 flex-1">
                {product.asin}
            </div>

            <div
                className={classNames(
                    'd-flex flex-column align-items-center mr-1',
                    styles.field,
                    styles['ad-group-column']
                )}
            >
                <AdGroupSelectFormikField
                    name={`products.${productIndex}.adGroupId`}
                    campaign={campaign}
                />
                <ErrorMessage
                    name={`products.${productIndex}.adGroupId`}
                    component="div"
                    className="fg-control-text is-error"
                />
            </div>

            <div
                className={classNames(
                    'd-flex flex-column align-items-center mr-1',
                    styles.field,
                    styles['state-column']
                )}
            >
                <Field
                    name={`products.${productIndex}.state`}
                    component={FormikSelect}
                    placeholder="Select State"
                >
                    {map(
                        pick(STATE_FIELD_OPTIONS, [ENABLED, PAUSED]),
                        (label, value) => (
                            <Select.Option key={value} value={value}>
                                {label}
                            </Select.Option>
                        )
                    )}
                </Field>
                <ErrorMessage
                    name={`products.${productIndex}.state`}
                    component="div"
                    className="fg-control-text is-error"
                />
            </div>

            <div
                className={classNames(
                    'd-flex flex-column align-items-center mr-1',
                    styles.field,
                    styles['remove-column']
                )}
            >
                <IconButton
                    icon={<DeleteOutlined />}
                    danger
                    type="link"
                    onClick={() => {
                        remove(productIndex)
                    }}
                />
            </div>
        </div>
    )
}

export default CreateProductAdFormRow
