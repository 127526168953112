import { ReactNode } from 'react'

import isEmpty from 'lodash/isEmpty'

import { ResourceLink } from 'components/Links/ResourceLink'
import { UNDEFINED_VALUE } from 'const/formatting'
import { labelUrl, productUrl } from 'helpers/urls'
import { isUnset } from 'helpers/utilities'
import { ProductMetadataGroupBy, CellRenderProps, Label } from 'types'

export function metadataIdRenderer<RecordType extends ProductMetadataGroupBy>({
    value,
    record,
    undefinedValue = UNDEFINED_VALUE,
}: CellRenderProps<RecordType>): ReactNode {
    return isUnset(value) || isEmpty(record.metadata) ? (
        undefinedValue
    ) : (
        <ResourceLink
            to={productUrl(record.metadata.asin, record.metadata.marketplace)}
        >
            {record.metadata.title}
        </ResourceLink>
    )
}

export function productLabelIdRenderer<
    RecordType extends ProductMetadataGroupBy & {
        product__label: Label
    },
>({
    value,
    record,
    undefinedValue = UNDEFINED_VALUE,
}: CellRenderProps<RecordType>): ReactNode {
    if (isUnset(value)) {
        return undefinedValue
    }
    return (
        <ResourceLink
            to={{
                pathname: labelUrl(value),
                search: '?tab=products',
            }}
        >
            {record.product__label?.name}
        </ResourceLink>
    )
}
