import find from 'lodash/find'
import { all, call, select, put } from 'redux-saga/effects'

import {
    fetchTasksRequest,
    fetchAutomationProfilesRequest,
} from 'actions/ui/campaignPage/automation'
import {
    makeFetchTableRequest,
    makeFetchTableSuccess,
    makeDownloadTableSuccess,
} from 'actions/ui/shared'
import { keywordsTableColumnsConfig } from 'configuration/tables'
import { DATES } from 'const/filters'
import { CAMPAIGN_PAGE } from 'const/pages'
import { formatFilters } from 'helpers/filters/keywordFacts'
import { generateReportNotification } from 'helpers/notifications'
import {
    formatPagination,
    formatSorter,
    formatCurrency,
    formatMetrics,
    formatColumns,
    formatPeriodDeltaDateRange,
} from 'helpers/params'
import { cerebroApiSaga } from 'sagas/common'
import uiSagaRegistry from 'sagas/ui/registry'
import { fetchKeywordBidRecommendationsSaga } from 'sagas/ui/shared/bid/workers'
import {
    selectTableSettings,
    selectVisibleMetricsOfTable,
    selectVisibleColumnsOfTable,
    selectCurrencyCode,
    selectResourceOfPage,
    selectResourceParamsOfPage,
    selectVisibleCombinedFilters,
} from 'selectors/ui'
import {
    getKeywordFactsExport,
    getKeywordFacts,
} from 'services/cerebroApi/orgScope/keywordFactsApi'

const TAB_PATH = [CAMPAIGN_PAGE, 'keywords']
const TABLE_PATH = [...TAB_PATH, 'table']

function* fetchTableSaga(noCount) {
    const { campaignId } = yield select(
        selectResourceParamsOfPage,
        CAMPAIGN_PAGE
    )
    const filters = yield select(selectVisibleCombinedFilters, TAB_PATH)
    const {
        pagination,
        sorter,
        showPeriodDeltas,
        periodDeltaType,
        periodDeltaDateRange,
    } = yield select(selectTableSettings, TABLE_PATH)
    const metrics = yield select(
        selectVisibleMetricsOfTable,
        TABLE_PATH,
        keywordsTableColumnsConfig
    )
    const currency = yield select(selectCurrencyCode)
    const params = {
        ...formatPagination(pagination),
        ...formatSorter(sorter),
        ...formatFilters(filters),
        ...formatMetrics(metrics, showPeriodDeltas),
        ...formatCurrency(currency),
        ...formatPeriodDeltaDateRange(
            showPeriodDeltas,
            periodDeltaType,
            periodDeltaDateRange,
            filters[DATES]
        ),
        campaign: campaignId,
        group_by: 'keyword_id,profile_id,ad_group_id',
    }

    const response = yield call(cerebroApiSaga, null, getKeywordFacts, params, {
        headers: { noCount },
    })

    if (response) {
        yield put(makeFetchTableSuccess(TABLE_PATH)(response.data))

        const columns = yield select(
            selectVisibleColumnsOfTable,
            TABLE_PATH,
            keywordsTableColumnsConfig
        )

        if (find(columns, { id: 'keyword_suggested_bid' })) {
            yield call(
                fetchKeywordBidRecommendationsSaga,
                TABLE_PATH,
                response.data.results
            )
        }
    }
}

function* mountTabSaga() {
    yield all([
        // load automation tasks and bidding profiles, so Keywords table can
        // be conditionally formatted based on dayparting settings
        put(fetchTasksRequest()),
        put(fetchAutomationProfilesRequest()),
    ])
}

function* fetchTabDataSaga() {
    yield put(makeFetchTableRequest(TABLE_PATH)())
}

function* downloadTableSaga() {
    const campaign = yield select(selectResourceOfPage, CAMPAIGN_PAGE)
    const filters = yield select(selectVisibleCombinedFilters, TAB_PATH)
    const { sorter, showPeriodDeltas, periodDeltaType, periodDeltaDateRange } =
        yield select(selectTableSettings, TABLE_PATH)
    const columns = yield select(
        selectVisibleColumnsOfTable,
        TABLE_PATH,
        keywordsTableColumnsConfig
    )

    const currency = yield select(selectCurrencyCode)
    const reportName = `Keyword Report - ${campaign.name} `
    const params = {
        ...formatFilters(filters),
        ...formatSorter(sorter),
        ...formatColumns(columns, showPeriodDeltas),
        ...formatCurrency(currency),
        ...formatPeriodDeltaDateRange(
            showPeriodDeltas,
            periodDeltaType,
            periodDeltaDateRange,
            filters[DATES]
        ),
        campaign: campaign.id,
        group_by: 'keyword_id',
        async_download_name: reportName,
    }

    yield call(
        cerebroApiSaga,
        makeDownloadTableSuccess(TABLE_PATH),
        getKeywordFactsExport,
        params
    )

    generateReportNotification(reportName)
}

// Register Sagas
uiSagaRegistry.registerSagas(TAB_PATH, {
    mountTabSaga,
    fetchTabDataSaga,
})

uiSagaRegistry.registerSagas(TABLE_PATH, {
    fetchTableSaga,
    downloadTableSaga,
})
