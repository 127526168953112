import { ReactElement } from 'react'

import { Modal, Radio, Space } from 'antd'
import { Field, Formik } from 'formik'
import { useTranslation } from 'react-i18next'

import { CURRENCIES } from 'configuration/currency'

interface Props {
    modalVisible: boolean
    closeModal: () => void
    currencyCode?: string | null
    handleChangeCurrencyCode: (currencyCode: string) => void
}

const CurrencyModal = ({
    modalVisible,
    closeModal,
    currencyCode,
    handleChangeCurrencyCode,
}: Props): ReactElement => {
    const { t } = useTranslation('navigation')

    return (
        <Formik
            initialValues={{
                currencyCode,
            }}
            onSubmit={(values) => {
                if (values.currencyCode) {
                    handleChangeCurrencyCode(values.currencyCode)
                    closeModal()
                }
            }}
        >
            {({ setFieldValue, handleSubmit, values }) => (
                <Modal
                    title={t(
                        'navigation:SideMenu.CurrencyModal.title',
                        'Select Currency'
                    )}
                    open={modalVisible}
                    onCancel={closeModal}
                    onOk={() => handleSubmit()}
                >
                    <Field name="currencyCode">
                        {() => (
                            <Radio.Group
                                onChange={(event) => {
                                    event.preventDefault()
                                    setFieldValue(
                                        'currencyCode',
                                        event.target.value
                                    )
                                }}
                                value={values.currencyCode}
                            >
                                <Space direction="vertical">
                                    {Object.entries(CURRENCIES).map(
                                        ([code, currency]) => (
                                            <Radio value={code} key={code}>
                                                {code} | {currency.name}
                                            </Radio>
                                        )
                                    )}
                                </Space>
                            </Radio.Group>
                        )}
                    </Field>
                </Modal>
            )}
        </Formik>
    )
}

export default CurrencyModal
