import { SVGAttributes, ReactElement } from 'react'

type Props = SVGAttributes<SVGElement>

function SvgKeywordIcon(props: Props): ReactElement {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M11.267 5.517c-.5 0-.9.4-.9.9s.4.9.9.9c.783 0 1.433.65 1.433 1.433 0 .5.4.9.9.9s.9-.4.9-.9a3.236 3.236 0 00-3.233-3.233z" />
            <path d="M2.367 16.217l3.55-3.534a6.602 6.602 0 01-1.3-3.95 6.658 6.658 0 016.65-6.65 6.658 6.658 0 016.65 6.65 6.658 6.658 0 01-6.65 6.65 6.639 6.639 0 01-3.95-1.3l-3.534 3.534c-.2.2-.45.283-.7.283-.25 0-.516-.1-.7-.283a.981.981 0 01-.016-1.4zm13.566-7.484a4.68 4.68 0 00-4.666-4.666A4.68 4.68 0 006.6 8.733a4.68 4.68 0 004.667 4.667 4.67 4.67 0 004.666-4.667z" />
        </svg>
    )
}

export default SvgKeywordIcon
