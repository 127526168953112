import { ReactElement } from 'react'

import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { ColumnConfig } from './localTypes'
import styles from './styles.scss'

interface Props {
    columnsConfig: ColumnConfig[]
}

const CreateAdItemFormHeader = ({ columnsConfig }: Props): ReactElement => {
    const { t } = useTranslation('common')

    return (
        <div className="d-flex align-items-start">
            <div
                style={{ width: '18%' }}
                className={classNames(
                    'mt-2 mr-1 pl-2',
                    styles['ad-item-row-header']
                )}
            >
                {t('common:itemId', 'Item ID')}
            </div>
            <div
                style={{ flex: 1 }}
                className={classNames(
                    'mt-2 mr-1 pl-2',
                    styles['ad-item-row-header']
                )}
            >
                {t('common:name', 'Name')}
            </div>

            {columnsConfig.map(({ width, name, dataKey }) => {
                return (
                    <div
                        className={classNames(
                            'mt-2 mr-1',
                            styles['ad-item-row-header']
                        )}
                        style={{
                            width,
                        }}
                        key={dataKey}
                    >
                        {name}
                    </div>
                )
            })}

            <div
                className={classNames('mt-2 mr-1 ', styles['remove-column'])}
            />
        </div>
    )
}

export default CreateAdItemFormHeader
