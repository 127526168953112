import { ReactElement, useState } from 'react'

import { Skeleton } from 'antd'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'

import { ImageNotFoundIcon, RelationshipIcon } from 'components/Icons'
import { AsinLink } from 'components/Links'
import { UNDEFINED_VALUE } from 'const/formatting'
import { CellRenderProps, CountryCode, ProductAdGroupBy } from 'types'

import styles from './styles.scss'

type DefaultGroupBy = ProductAdGroupBy

interface Props<RecordType> extends Pick<CellRenderProps<RecordType>, 'value'> {
    productAsin: string
    productMarketplace?: CountryCode
    productImg?: string | null
    showImage?: boolean
    numChildren?: number
}

function SegmentProductTitleCell<
    RecordType extends DefaultGroupBy = DefaultGroupBy,
>({
    value,
    productAsin,
    productMarketplace,
    productImg,
    showImage = true,
    numChildren = 0,
}: Props<RecordType>): ReactElement {
    const { t } = useTranslation(['segments'])
    const [imageOK, setImageOK] = useState(true)
    const isLoading = value === 'Loading...'
    const isParent = numChildren > 0
    const title =
        value ??
        (isParent
            ? t(
                  'segments:fields.product.parent',
                  'Parent ASIN ({{count}} variant)',
                  {
                      count: numChildren,
                  }
              )
            : value) ??
        UNDEFINED_VALUE

    return (
        <div className={styles.container}>
            {isLoading && (
                <div className={styles.text}>
                    <div className={styles.title}>
                        <Skeleton
                            active
                            title={{
                                width: 140,
                            }}
                            paragraph={{ rows: 1 }}
                        />
                    </div>
                </div>
            )}
            {!isLoading && (
                <>
                    {showImage && (
                        <div className={styles['image-outer']}>
                            {numChildren > 0 ? (
                                <div
                                    className={classnames(
                                        styles['image-wrapper'],
                                        styles['stack-base']
                                    )}
                                />
                            ) : null}
                            <div className={styles['image-wrapper']}>
                                {numChildren > 0 ? (
                                    <div className={styles['child-count']}>
                                        {numChildren}
                                    </div>
                                ) : null}
                                <div className={styles.image}>
                                    {productImg && imageOK ? (
                                        <img
                                            src={productImg}
                                            alt={title}
                                            onError={() => setImageOK(false)}
                                        />
                                    ) : (
                                        <ImageNotFoundIcon
                                            className={
                                                styles['missing-img-icon']
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                    <div className={styles.text}>
                        <div className={styles.title}>{title}</div>
                        {productAsin && productMarketplace && (
                            <div className={styles.link}>
                                {isParent && (
                                    <>
                                        <RelationshipIcon />
                                        <span>Parent:</span>
                                    </>
                                )}
                                <AsinLink
                                    asin={productAsin}
                                    countryCode={productMarketplace}
                                />
                            </div>
                        )}
                    </div>
                </>
            )}
        </div>
    )
}

export default SegmentProductTitleCell
