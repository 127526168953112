import { ReactElement } from 'react'

import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Alert } from 'antd'
import { Translation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router'

import { IconButton } from 'components/Buttons'
import { CloseIcon } from 'components/Icons'
import { TextButton } from 'components/TextButton'
import { ORGANIZATION_PAGE } from 'const/pages'
import { useOrgContext } from 'context/OrgContext'
import { userHasDashboardsOnlyPermissions } from 'helpers/featurePermissions'
import { getPath } from 'helpers/pages'
import { useUserHasPermissions } from 'hooks'
import usePersistedDismiss from 'hooks/usePersistedDismiss'

import styles from './styles.scss'

const MAP_INTEGRATION_KEY_TO_TAB = {
    walmart_advertising: 'wmAdvertisingIntegrations',
    seller_central: 'scIntegrations',
    vendor_central: 'scIntegrations',
    amazon_advertising: 'integrations',
    amazon_advertising_dsp: 'integrations',
}

export default function IntegrationIssuesBanner(): ReactElement | null {
    const dashboardOnlyExperience = useUserHasPermissions(
        userHasDashboardsOnlyPermissions
    )

    const orgContext = useOrgContext()
    const history = useHistory()
    const location = useLocation()
    const { dismiss, isDismissed } = usePersistedDismiss(
        'integrationIssuesBanner',
        1
    )

    if (
        dashboardOnlyExperience ||
        !orgContext?.orgAccountStatus?.integration_issues?.length ||
        location.pathname === '/account' ||
        location.pathname.includes('/print')
    ) {
        return null
    }

    const distinctIntegrationsWithIssues = new Set<number>()
    let tab: string | undefined =
        MAP_INTEGRATION_KEY_TO_TAB[
            orgContext.orgAccountStatus.integration_issues[0].integration_key
        ]

    orgContext.orgAccountStatus.integration_issues.forEach((issue) => {
        if (MAP_INTEGRATION_KEY_TO_TAB[issue.integration_key] !== tab) {
            tab = undefined
        }
        distinctIntegrationsWithIssues.add(issue.integration_id)
    })
    const subPath = tab ? `?tab=${tab}` : ''
    const pathToIntegrationsWithIssues = `${getPath(
        ORGANIZATION_PAGE
    )}${subPath}`

    if (isDismissed) {
        return null
    }

    return (
        <Alert
            type="warning"
            banner
            showIcon={false}
            message={
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <div
                        style={{
                            flex: 1,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <ExclamationCircleOutlined
                            className={styles['warning-icon']}
                        />
                        <span>
                            <Translation>
                                {(t) =>
                                    t(
                                        'account:Banners.integrationIssuesBanner.message',
                                        '{{count}} integration requires attention.',
                                        {
                                            count: distinctIntegrationsWithIssues.size,
                                        }
                                    )
                                }
                            </Translation>{' '}
                            <TextButton
                                link
                                onClick={() =>
                                    history.push(pathToIntegrationsWithIssues)
                                }
                            >
                                <Translation>
                                    {(t) =>
                                        t(
                                            'account:Banners.integrationIssuesBanner.cta',
                                            'Fix integrations'
                                        )
                                    }
                                </Translation>
                            </TextButton>
                        </span>
                    </div>
                    <IconButton
                        icon={<CloseIcon />}
                        onClick={dismiss}
                        size="small"
                        type="link"
                    />
                </div>
            }
            className={styles['warning-banner']}
        />
    )
}
