import { ReactElement } from 'react'

import Icon from '@ant-design/icons'

import LogOutIconSvg from './LogOutIconSvg'
import { IconProps } from '../localTypes'

const LogOutIcon = (props: IconProps): ReactElement => (
    <Icon component={LogOutIconSvg} {...props} />
)

export default LogOutIcon
