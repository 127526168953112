// widget types
export const METRIC = 'metric'
export const TABLE = 'table'
export const TIME_SERIES = 'line_chart'
export const TEXT = 'text'
export const CATEGORY_CHART = 'pie'

// chart types
export const SPLINE = 'spline'
export const COLUMN = 'column'
export const SPLINE_AND_COLUMN = 'spline_and_column'
export const AREA = 'areaspline'
export const PIE = 'pie'
export const BUBBLE = 'bubble'
export const BAR = 'bar'

export const TIMESERIES_CHART_TYPE_OPTIONS = [
    { label: 'Line', value: SPLINE },
    { label: 'Column', value: COLUMN },
    { label: 'Both', value: SPLINE_AND_COLUMN },
]

export const CATEGORY_CHART_TYPE_OPTIONS = [
    { label: 'Pie', value: PIE },
    { label: 'Column', value: COLUMN },
    { label: 'Bubble', value: BUBBLE },
]

// widget grid
export const WIDGET_GRID_ROW_HEIGHT = 30
export const WIDGET_GRID_X_MARGIN = 10
export const WIDGET_GRID_Y_MARGIN = 10

export const MAX_DAYS_RANGE_FOR_HOURLY_DATA = 14

export const REPORT_DATE_DAY_ID = 'report_date_day'
export const REPORT_DATE_HOUR_ID = 'report_date_hour'
export const REPORT_DATE_WEEK_ID = 'report_date_week'

export const SEARCH_TIME_HOUR_ID = 'search_time_hour'
export const SEARCH_TIME_WEEK_ID = 'search_time_week'
