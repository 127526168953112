import { all, takeLatest } from 'redux-saga/effects'

import { applySuggestedBidRequest } from 'actions/ui/shared/bid'

import { applySuggestedBidWorker } from './workers'

export default function* bidWatcher() {
    yield all([
        takeLatest(
            applySuggestedBidRequest.toString(),
            applySuggestedBidWorker
        ),
    ])
}
