import { ReactElement, useEffect, useState } from 'react'

import { Button } from 'antd'
import classNames from 'classnames'
import { ErrorMessage, Form, Formik } from 'formik'
import { Trans, useTranslation } from 'react-i18next'
import { Redirect, useHistory } from 'react-router'
import { object, string } from 'yup'

import { resendCodeRequest, signInMfaRequest } from 'actions/auth'
import CodeInput from 'components/CodeInput/CodeInput'
import { CONTACT_US } from 'configuration/urls'
import { AUTH_PAGE } from 'const/pages'
import { getPath } from 'helpers/pages'
import { useAction, useAuthDomainValue } from 'hooks'
import { useAnalytics } from 'services/analytics'
import { AuthError, SubmitButton } from 'views/Auth/Shared'

import styles from '../styles.scss'

interface ChallengeParameters {
    CODE_DELIVERY_DESTINATION: string
}

interface Tokens {
    ChallengeName: string
    Session: string
    ChallengeParameters: ChallengeParameters
    ResponseMetadata: object
}

interface SmsCodeFormState {
    tokens: Tokens
    email: string
    password: string
}

const SmsCodeForm = (): ReactElement => {
    const { t } = useTranslation('account')
    const { location } = useHistory()
    const [hasRetried, setHasRetried] = useState(false)
    const smsChallengeContext = location.state as SmsCodeFormState | undefined
    const tokens = smsChallengeContext?.tokens
    const email = smsChallengeContext?.email
    const password = smsChallengeContext?.password

    const validationSchema = object().shape({
        smsCode: string()
            .required('Please enter the code we sent you.')
            .label(t('account:Sm.smsCode', 'smsCode')),
    })

    const signInMfa = useAction(signInMfaRequest)
    const signIn = useAction(resendCodeRequest)
    const isFetchingCode = useAuthDomainValue('isFetchingCode')
    const { trackEvent, trackCtaClick } = useAnalytics()

    const triggerCTAEvent = (destination: string, text: string): void => {
        trackCtaClick({
            destination,
            text,
            type: 'button',
            location: 'MFA - Insert SMS Code',
        })
    }

    const handleResendClick = (e: { preventDefault: () => void }): void => {
        triggerCTAEvent('MFA - Insert SMS Code', 'Resend Code')
        e.preventDefault()

        if (!isFetchingCode) {
            signIn({ email, password })
            setHasRetried(true)
        }
    }

    useEffect(() => {
        trackEvent('MFA - Insert SMS Code Started', {
            text: 'MFA - Insert SMS Code Started',
        })
    }, [])

    if (!tokens) {
        return <Redirect to={getPath(AUTH_PAGE)} />
    }

    return (
        <div>
            <div className={styles.title_darker}>
                <h1>{t('account:SmsForm:title', 'Enter SMS Code')}</h1>
                <p>
                    {t(
                        'account:SmsForm:message',
                        'We sent a 6-digit SMS code to your phone number: {{codeDeliveryDestination}}. Please enter it to complete authentication.',
                        {
                            codeDeliveryDestination:
                                tokens?.ChallengeParameters?.CODE_DELIVERY_DESTINATION?.replace(
                                    '+',
                                    ''
                                ),
                        }
                    )}
                </p>
            </div>
            <Formik
                initialValues={{ smsCode: '' }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    triggerCTAEvent('Signed in - Landing page', 'Submit')
                    signInMfa({
                        email,
                        password,
                        tokens,
                        code: values.smsCode,
                    })
                }}
            >
                {({ setFieldValue }) => (
                    <Form autoComplete="off">
                        <div className="mb-2">
                            <CodeInput
                                length={6}
                                onChange={(code) =>
                                    setFieldValue('smsCode', code)
                                }
                            />
                            <ErrorMessage
                                name="smsCode"
                                component="div"
                                className="fg-control-text is-error"
                            />
                        </div>
                        <SubmitButton>
                            {t('account:Sm.submitBtnText', 'Log In')}
                        </SubmitButton>
                    </Form>
                )}
            </Formik>

            <AuthError />

            <div className={classNames(styles.footer, styles.compressed)}>
                {!hasRetried && (
                    <Trans i18nKey="account:SmsCodeForm.resendCodeLink">
                        Didn&rsquo;t receive a code?{' '}
                        <Button
                            type="link"
                            size="small"
                            onClick={handleResendClick}
                            style={{ padding: 0 }}
                        >
                            <span>Resend</span>
                        </Button>
                    </Trans>
                )}
                {hasRetried && isFetchingCode && (
                    <Trans i18nKey="account:SmsCodeForm.resendingCode">
                        <span>Resending...</span>
                    </Trans>
                )}
                {hasRetried && !isFetchingCode && (
                    <Trans i18nKey="account:SmsCodeForm.contacUs">
                        Still having issues?{' '}
                        <Button
                            href={CONTACT_US}
                            target="_blank"
                            rel="noopener noreferrer"
                            type="link"
                            size="small"
                            style={{ padding: 0 }}
                        >
                            <span>Contact us</span>
                        </Button>
                    </Trans>
                )}
            </div>
        </div>
    )
}

export default SmsCodeForm
