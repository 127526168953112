import { createAction } from 'redux-actions'

// fetch orgs
export const fetchOrganizationsRequest = createAction(
    'FETCH_ORGANIZATIONS_REQUEST'
)
export const fetchOrganizationsSuccess = createAction(
    'FETCH_ORGANIZATIONS_SUCCESS'
)
export const fetchOrganizationSuccess = createAction(
    'FETCH_ORGANIZATION_SUCCESS'
)

// fetch brand options
export const fetchBrandOptionsSuccess = createAction(
    'FETCH_ORGANIZATION_BRAND_OPTIONS_SUCCESS'
)

// fetch integration options
export const fetchIntegrationOptionsSuccess = createAction(
    'FETCH_ORGANIZATION_INTEGRATION_OPTIONS_SUCCESS'
)

// fetch dsp advertisers options
export const fetchDspAdvertiserOptionsSuccess = createAction(
    'FETCH_ORGANIZATION_DSP_ADVERTISERS_OPTIONS_SUCCESS'
)

export type FetchBrandOptionsAction = ReturnType<
    typeof fetchBrandOptionsSuccess
>
export type FetchIntegrationOptionsAction = ReturnType<
    typeof fetchIntegrationOptionsSuccess
>
export type FetchDspAdvertiserOptionsAction = ReturnType<
    typeof fetchDspAdvertiserOptionsSuccess
>
