import { handleActions } from 'redux-actions'

import { keywordsSummaryTableColumnsConfig } from 'configuration/tables'
import {
    DATES,
    COUNTRIES,
    REGIONS,
    BRANDS,
    CAMPAIGNS,
    CAMPAIGN_STATES,
    CAMPAIGN_TARGETING_TYPES,
    CAMPAIGN_AD_FORMATS,
    KEYWORD_TEXT,
    KEYWORDS,
    KEYWORD_MATCH_TYPES,
    FACT_TYPES,
    METRIC_FILTERS,
    KEYWORD_STATES,
    PORTFOLIOS,
    LABELS,
    AD_GROUPS,
    AD_GROUP_STATES,
    ADVANCED_TEXT,
} from 'const/filters'
import { FILTER_SETTINGS, FILTERS } from 'const/reducerKeys'
import { ENABLED, PAUSED, KEYWORD_STATE_LABELS } from 'const/resourceStates'
import { serializeColumns } from 'helpers/tables'
import { defaultTab, getDefaultTable } from 'reducers/ui/defaults'

const initialState = {
    ...defaultTab,
    [FILTERS]: {
        [KEYWORD_TEXT]: null,
        [METRIC_FILTERS]: [],
        [FACT_TYPES]: [],
        [REGIONS]: [],
        [COUNTRIES]: [],
        [BRANDS]: [],
        [PORTFOLIOS]: [],
        [CAMPAIGNS]: [],
        [CAMPAIGN_STATES]: [],
        [CAMPAIGN_TARGETING_TYPES]: [],
        [KEYWORDS]: [],
        [KEYWORD_MATCH_TYPES]: [],
        [KEYWORD_STATES]: [
            {
                value: ENABLED,
                label: KEYWORD_STATE_LABELS[ENABLED],
            },
            {
                value: PAUSED,
                label: KEYWORD_STATE_LABELS[PAUSED],
            },
        ],
        [LABELS]: [],
        [AD_GROUPS]: [],
        [AD_GROUP_STATES]: [],
        [ADVANCED_TEXT]: [],
    },
    [FILTER_SETTINGS]: {
        anchored: [KEYWORD_TEXT, DATES, FACT_TYPES],
        order: [
            METRIC_FILTERS,
            LABELS,
            REGIONS,
            COUNTRIES,
            BRANDS,
            PORTFOLIOS,
            CAMPAIGNS,
            CAMPAIGN_STATES,
            CAMPAIGN_TARGETING_TYPES,
            CAMPAIGN_AD_FORMATS,
            KEYWORDS,
            KEYWORD_MATCH_TYPES,
            KEYWORD_STATES,
            AD_GROUPS,
            AD_GROUP_STATES,
            ADVANCED_TEXT,
        ],
        displayState: {
            [METRIC_FILTERS]: true,
            [LABELS]: true,
            [REGIONS]: true,
            [COUNTRIES]: true,
            [BRANDS]: true,
            [PORTFOLIOS]: true,
            [ADVANCED_TEXT]: true,
            [CAMPAIGNS]: true,
            [CAMPAIGN_STATES]: true,
            [CAMPAIGN_TARGETING_TYPES]: true,
            [KEYWORDS]: true,
            [KEYWORD_MATCH_TYPES]: false,
            [KEYWORD_STATES]: true,
            [AD_GROUPS]: false,
            [AD_GROUP_STATES]: false,
        },
    },
    table: getDefaultTable(
        serializeColumns(keywordsSummaryTableColumnsConfig),
        {
            showTotalRow: true,
        }
    ),
}

export default handleActions({}, initialState)
