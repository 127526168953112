import {
    asyncUpdateStatusRenderer,
    asyncUpdateActionRenderer,
    asyncUpdateDescriptionRenderer,
    asyncUpdateTypeRenderer,
    asyncUpdateSourceTypeRenderer,
    asyncUpdateCountRenderer,
} from 'components/FieldRenderers'
import { Timestamp } from 'components/Timestamp'
import {
    asyncUpdateActions,
    asyncUpdateCount,
    asyncUpdateDate,
    asyncUpdateDescription,
    asyncUpdateSourceType,
    asyncUpdateStatus,
    asyncUpdateType,
} from 'configuration/fieldCreators/asyncUpdates'
import { userId } from 'configuration/fieldCreators/user'
import { AsyncUpdate, Field } from 'types'

export const bulkUpdatesTableColumnsConfig: Field<AsyncUpdate>[] = [
    asyncUpdateDate({
        fixed: 'left',
        renderOptions: {
            render: ({ value }) => <Timestamp time={value} />,
        },
    }),
    asyncUpdateDescription({
        renderOptions: {
            render: (props) => asyncUpdateDescriptionRenderer(props),
        },
    }),
    asyncUpdateSourceType({
        renderOptions: {
            render: (props) => asyncUpdateSourceTypeRenderer(props),
        },
    }),
    asyncUpdateType({
        renderOptions: {
            render: (props) => asyncUpdateTypeRenderer(props),
        },
    }),
    asyncUpdateCount({
        renderOptions: {
            render: (props) => asyncUpdateCountRenderer(props),
        },
    }),
    asyncUpdateStatus({
        renderOptions: {
            render: (props) => asyncUpdateStatusRenderer(props),
        },
    }),
    userId(),
    asyncUpdateActions({
        renderOptions: {
            render: (props) => asyncUpdateActionRenderer(props),
        },
    }),
]
