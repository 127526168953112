import { ReactElement } from 'react'

import Icon from '@ant-design/icons'

import ArrowIconSvg from './ArrowIconSvg'
import { IconProps } from '../localTypes'

const ArrowIcon = (props: IconProps): ReactElement => (
    <Icon component={ArrowIconSvg} {...props} />
)

export default ArrowIcon
