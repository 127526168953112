import { ReactElement } from 'react'

import Icon from '@ant-design/icons'

import HomeIconSvg from './HomeIconSvg'
import { IconProps } from '../localTypes'

const HomeIcon = (props: IconProps): ReactElement => (
    <Icon component={HomeIconSvg} {...props} />
)

export default HomeIcon
