import { ReactElement } from 'react'

import Icon from '@ant-design/icons'

import ContactSupportIconSvg from './ContactSupportIconSvg'
import { IconProps } from '../localTypes'

const ContactSupportIcon = (props: IconProps): ReactElement => (
    <Icon component={ContactSupportIconSvg} {...props} />
)

export default ContactSupportIcon
