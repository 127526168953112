import { handleActions } from 'redux-actions'

import { eventsChangelogTableColumnsConfig } from 'configuration/tables'
import {
    REGIONS,
    COUNTRIES,
    CAMPAIGN_NAME,
    BRANDS,
    PORTFOLIOS,
    LABELS,
    AUTOMATIONS,
    RESOURCE_TYPES,
} from 'const/filters'
import { FILTER_SETTINGS, DATES, FILTERS } from 'const/reducerKeys'
import { serializeColumns } from 'helpers/tables'
import { defaultTab, getDefaultTable } from 'reducers/ui/defaults'

const initialState = {
    ...defaultTab,
    [FILTERS]: {
        [CAMPAIGN_NAME]: null,
        [REGIONS]: [],
        [COUNTRIES]: [],
        [BRANDS]: [],
        [PORTFOLIOS]: [],
        [LABELS]: [],
        [AUTOMATIONS]: [],
        [RESOURCE_TYPES]: [],
    },
    [FILTER_SETTINGS]: {
        anchored: [CAMPAIGN_NAME, DATES],
        order: [
            REGIONS,
            COUNTRIES,
            BRANDS,
            PORTFOLIOS,
            LABELS,
            AUTOMATIONS,
            RESOURCE_TYPES,
        ],
        displayState: {
            [REGIONS]: true,
            [COUNTRIES]: true,
            [BRANDS]: true,
            [PORTFOLIOS]: true,
            [LABELS]: true,
            [AUTOMATIONS]: true,
            [RESOURCE_TYPES]: true,
        },
    },
    table: getDefaultTable(
        serializeColumns(eventsChangelogTableColumnsConfig),
        {
            sorter: {
                field: ['date'],
                order: 'descend',
            },
        }
    ),
}

export default handleActions({}, initialState)
