import { ReactNode } from 'react'

import {
    ArchiveKeywordField,
    KeywordBidField,
    KeywordStateField,
    KeywordSuggestedBidField,
} from 'components/EditableFields/KeywordFields'
import { UNDEFINED_VALUE } from 'const/formatting'
import { isUnset } from 'helpers/utilities'
import { CellRenderProps, KeywordGroupBy } from 'types'

import { ActiveBidCell } from '../ActiveBidCell'
import { KeywordIdCell } from '../KeywordIdCell/KeywordIdCell'

type RenderPropsWithDataIndex<RecordType> = {
    cellRenderProps: CellRenderProps<RecordType>
    dataIndex: string[]
    actionButtonVisible?: boolean
    useFixedHeight?: boolean
}

export function keywordIdRenderer<RecordType extends KeywordGroupBy>({
    record,
}: CellRenderProps<RecordType>): ReactNode {
    return <KeywordIdCell keyword={record.keyword} />
}

export function keywordStateRenderer<RecordType extends KeywordGroupBy>({
    cellRenderProps: { readonly, record },
    dataIndex,
}: RenderPropsWithDataIndex<RecordType>): ReactNode {
    return (
        <KeywordStateField
            dataIndex={dataIndex}
            readOnly={readonly}
            record={record}
        />
    )
}

export function keywordBidRenderer<RecordType extends KeywordGroupBy>({
    cellRenderProps: { readonly, record },
    dataIndex,
}: RenderPropsWithDataIndex<RecordType>): ReactNode {
    return (
        <KeywordBidField
            dataIndex={dataIndex}
            readOnly={readonly}
            record={record}
        />
    )
}

export function keywordSuggestedBidRenderer<RecordType extends KeywordGroupBy>({
    cellRenderProps: {
        readonly,
        record,
        reloadData,
        rowIndex,
        updateRecord,
        value,
    },
    dataIndex,
    actionButtonVisible = true,
    useFixedHeight = false,
}: RenderPropsWithDataIndex<RecordType>): ReactNode {
    const fieldElement = (
        <KeywordSuggestedBidField
            dataIndex={dataIndex}
            readOnly={readonly}
            record={record}
            reloadData={reloadData}
            rowIndex={rowIndex}
            updateRecord={updateRecord}
            value={value}
            actionButtonVisible={actionButtonVisible}
        />
    )

    return useFixedHeight ? (
        <div
            style={{
                height: '36px',
                display: 'flex',
            }}
        >
            {fieldElement}
        </div>
    ) : (
        fieldElement
    )
}

export function keywordActiveBidRenderer<RecordType extends KeywordGroupBy>({
    record,
    value,
    undefinedValue = UNDEFINED_VALUE,
}: CellRenderProps<RecordType>): ReactNode {
    if (isUnset(value)) {
        return undefinedValue
    }
    const { currency_code, timezone } = record.keyword.profile
    const { active_bid, base_bid, campaign } = record.keyword
    return (
        <ActiveBidCell
            activeBid={active_bid}
            baseBid={base_bid}
            campaign={campaign}
            currencyCode={currency_code}
            timezone={timezone}
        />
    )
}

export function archiveKeywordRenderer<RecordType extends KeywordGroupBy>({
    cellRenderProps: { readonly, record, reloadData },
    dataIndex,
}: RenderPropsWithDataIndex<RecordType>): ReactNode {
    return (
        <ArchiveKeywordField
            dataIndex={dataIndex}
            record={record}
            readOnly={readonly}
            reloadData={reloadData}
        />
    )
}
