import {
    AMAZON_AD_GROUP_SP_BID_LIMITS,
    AD_GROUP_BID_MIN,
    AD_GROUP_BID_MAX,
} from 'const/adGroup'
import { CountryCode } from 'types'

export const getSPAdGroupBudgetLimits = (
    countryCode: CountryCode
): { min: number; max: number } => {
    const countriesWithSpecificBudgets = Object.keys(
        AMAZON_AD_GROUP_SP_BID_LIMITS
    )

    if (countriesWithSpecificBudgets.includes(countryCode)) {
        return AMAZON_AD_GROUP_SP_BID_LIMITS[countryCode]
    }

    return { min: AD_GROUP_BID_MIN, max: AD_GROUP_BID_MAX }
}
