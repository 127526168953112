import { ReactElement, useState } from 'react'

import { Skeleton } from 'antd'

import { ImageNotFoundIcon } from 'components/Icons'
import { CellRenderProps, WalmartAdItemGroupBy } from 'types'

import styles from './styles.scss'

type DefaultGroupBy = WalmartAdItemGroupBy

interface Props<RecordType> extends Pick<CellRenderProps<RecordType>, 'value'> {
    productImg?: string | null
}

function WalmartItemTitleCell<
    RecordType extends DefaultGroupBy = DefaultGroupBy,
>({ value, productImg }: Props<RecordType>): ReactElement {
    const [imageOK, setImageOK] = useState(true)
    const isLoading = value === 'Loading...'

    if (value && productImg && imageOK) {
        return (
            <div className={styles.container}>
                {isLoading && (
                    <Skeleton
                        active
                        avatar={{
                            size: 'large',
                            shape: 'square',
                        }}
                        className="mr-2"
                        title={{
                            width: 140,
                        }}
                        paragraph={false}
                    />
                )}
                {!isLoading && (
                    <>
                        <div className={styles.image}>
                            <img
                                src={productImg}
                                alt={value}
                                onError={() => setImageOK(false)}
                            />
                        </div>
                        {value}
                    </>
                )}
            </div>
        )
    }

    return (
        <div className={styles.container}>
            <ImageNotFoundIcon className={styles['missing-img-icon']} />
            {value}
        </div>
    )
}

export default WalmartItemTitleCell
