import { UserGroupType } from 'types'

// organization group resource types
export const RESOURCE_TYPE_ALL = 'all'
export const RESOURCE_TYPE_REGIONS = 'regions'
export const RESOURCE_TYPE_COUNTRIES = 'countries'
export const RESOURCE_TYPE_BRANDS = 'brands'
export const RESOURCE_TYPE_INTEGRATIONS = 'integrations'
export const RESOURCE_TYPE_DSP_ADVERTISERS = 'dsp_advertisers'
export const NO_ACCESS = 'no_access'

export const RESOURCE_TYPE_LABELS = {
    [RESOURCE_TYPE_ALL]: 'All Access',
    [RESOURCE_TYPE_REGIONS]: 'Regions',
    [RESOURCE_TYPE_COUNTRIES]: 'Countries',
    [RESOURCE_TYPE_BRANDS]: 'Brands',
    [RESOURCE_TYPE_INTEGRATIONS]: 'Integrations',
    [RESOURCE_TYPE_DSP_ADVERTISERS]: 'DSP Advertisers',
    [NO_ACCESS]: 'No Access',
}

export const USER_GROUP_TYPE_NAMES: Record<UserGroupType, string> = {
    standard: 'Standard',
    read_only: 'Read Only',
    dashboard_only: 'Dashboard Only',
}
