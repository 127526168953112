import { ReactElement, useEffect, useRef, useState } from 'react'

import { Divider, Popover } from 'antd'
import classNames from 'classnames'
import noop from 'lodash/noop'

import { IconButton } from 'components/Buttons'
import { ConditionalWrapper } from 'components/ConditionalWrapper'
import { HierarchicalFilterState } from 'const/filters'

import styles from './styles.scss'
import { InfoIcon, SimpleLeftArrowIcon, SimpleRightArrowIcon } from '../Icons'
import { EditIcon } from '../Icons/EditIcon'

export type InfoItem = {
    state: HierarchicalFilterState
    label: string | ReactElement
    hint?: string
}

export enum InfoRowStyle {
    PDF_EXPORT = 'pdfExport',
    WIDGET_DISPLAY = 'widgetDisplay',
    CONFIGURATION_MODAL = 'configurationModal',
}

type Props = {
    items: InfoItem[]
    styleType: InfoRowStyle
    title?: string
    prefix?: ReactElement
    onEdit?: () => void
    showEditWidgetButton?: boolean
}

const InfoRow = ({
    items,
    styleType,
    title,
    prefix,
    onEdit = noop,
    showEditWidgetButton = false,
}: Props): ReactElement => {
    const contentBox = useRef<HTMLDivElement>(null)
    const scroller = useRef<HTMLDivElement>(null)
    const [showRightSideFade, setShowRightSideFade] = useState<boolean>(false)
    const [showLeftSideFade, setShowLeftSideFade] = useState<boolean>(false)
    const [showRightScrollArrow, setShowRightScrollArrow] =
        useState<boolean>(false)
    const [showLeftScrollArrow, setShowLeftScrollArrow] =
        useState<boolean>(false)

    const [scrollStep, setScrollStep] = useState<number>(30)
    const clientScroller = scroller.current

    useEffect(() => {
        const clientContentBox = contentBox.current
        if (!clientScroller || !clientContentBox) {
            return noop
        }

        const updateScrollingEffects = (): void => {
            const { scrollLeft, scrollWidth, clientWidth } = clientScroller
            setShowLeftScrollArrow(scrollLeft > 0)
            setShowLeftSideFade(scrollLeft > 0)

            const largerThanScrollWidth =
                scrollLeft + clientWidth >= scrollWidth
            setShowRightScrollArrow(!largerThanScrollWidth)
            setShowRightSideFade(!largerThanScrollWidth)

            setScrollStep(clientWidth - 30 < 0 ? 30 : clientWidth - 30)
        }

        const resizeObserver = new ResizeObserver((entries) => {
            const contentBoxHasBeenResized = entries.some(
                (entry) => entry.target.id === 'contentBox'
            )
            if (contentBoxHasBeenResized) {
                updateScrollingEffects()
            }
        })

        updateScrollingEffects()

        clientScroller.addEventListener('scroll', updateScrollingEffects, false)
        resizeObserver.observe(clientContentBox)

        return () => {
            clientScroller.removeEventListener(
                'scroll',
                updateScrollingEffects,
                false
            )
            resizeObserver.unobserve(clientContentBox)
        }
    }, [clientScroller])

    const handleScrollRight = (): void => {
        if (scroller?.current) {
            scroller.current.scrollBy({ left: scrollStep, behavior: 'smooth' })
        }
    }

    const handleScrollLeft = (): void => {
        if (scroller?.current) {
            scroller.current.scrollBy({ left: -scrollStep, behavior: 'smooth' })
        }
    }

    const renderHint = (hintText: string): ReactElement => (
        <div className={styles['hint-container']}>
            <Popover
                trigger="hover"
                placement="bottom"
                content={
                    <span className={styles['popover-text']}>{hintText}</span>
                }
                overlayClassName="info-row-popover"
            >
                <InfoIcon className={styles['info-icon']} />
            </Popover>
        </div>
    )

    const renderItem = (
        { label, hint }: InfoItem,
        renderDivider: boolean,
        key: string
    ): ReactElement => (
        <div
            key={typeof label === 'string' ? label : key}
            className={styles['filter-item']}
        >
            <span
                className={
                    hint && styleType === InfoRowStyle.CONFIGURATION_MODAL
                        ? styles.strikethrough
                        : undefined
                }
            >
                {label}
            </span>
            {styleType !== InfoRowStyle.PDF_EXPORT && hint && renderHint(hint)}
            {renderDivider && (
                <Divider
                    type="vertical"
                    className={styles['filter-box-divider']}
                />
            )}
        </div>
    )

    const renderItems = (): ReactElement => {
        if (styleType !== InfoRowStyle.CONFIGURATION_MODAL) {
            const { APPLIED_BY_WIDGET, OVERRIDDEN_BY_WIDGET } =
                HierarchicalFilterState

            const widgetItems = items.filter((item) =>
                [APPLIED_BY_WIDGET, OVERRIDDEN_BY_WIDGET].includes(item.state)
            )
            const tabItems = items.filter((item) => !widgetItems.includes(item))

            return (
                <>
                    <div className={styles['filter-box']}>
                        {showEditWidgetButton && (
                            <>
                                <IconButton
                                    className={styles['edit-button']}
                                    onClick={() => onEdit()}
                                    icon={<EditIcon />}
                                    type="link"
                                >
                                    Edit
                                </IconButton>
                                {widgetItems.length > 0 && (
                                    <Divider
                                        type="vertical"
                                        className={
                                            styles['edit-button-divider']
                                        }
                                    />
                                )}
                            </>
                        )}
                        {widgetItems.length > 0 && (
                            <Popover
                                className={classNames(
                                    styles['item-box'],
                                    'd-flex'
                                )}
                                trigger="hover"
                                overlayClassName="info-row-popover"
                                content={
                                    <span className={styles['popover-text']}>
                                        Widget-level filters
                                    </span>
                                }
                            >
                                {widgetItems.map((item, index) =>
                                    renderItem(
                                        item,
                                        index !== widgetItems.length - 1,
                                        `widget-item-${index}`
                                    )
                                )}
                            </Popover>
                        )}
                    </div>
                    {tabItems.length > 0 && (
                        <Popover
                            className={classNames(
                                styles['filter-box'],
                                styles['item-box'],
                                styles.shaded
                            )}
                            trigger="hover"
                            overlayClassName="info-row-popover"
                            content={
                                <span className={styles['popover-text']}>
                                    Tab-level filters
                                </span>
                            }
                        >
                            {tabItems.map((item, index) =>
                                renderItem(
                                    item,
                                    index !== tabItems.length - 1,
                                    `tab-item-${index}`
                                )
                            )}
                        </Popover>
                    )}
                </>
            )
        }

        return (
            <>
                {items.map((item, index) =>
                    renderItem(
                        item,
                        index !== items.length - 1,
                        `item-${index}`
                    )
                )}
            </>
        )
    }

    return (
        <ConditionalWrapper
            condition={styleType === InfoRowStyle.CONFIGURATION_MODAL}
            wrapper={(children) => (
                <div style={{ padding: '6px 14px' }} className={styles.shaded}>
                    {children}
                </div>
            )}
        >
            <div className={styles['info-row']}>
                {prefix && <div className={styles.prefix}>{prefix}</div>}
                {title && <span className={styles.title}>{`${title}:`}</span>}
                <div
                    id="contentBox"
                    className={styles.content}
                    ref={contentBox}
                >
                    {showLeftSideFade && (
                        <div
                            className={`
                                ${styles['filter-fade']}
                                ${styles['left-fade']}
                            `}
                        />
                    )}
                    {showLeftScrollArrow && (
                        <SimpleLeftArrowIcon
                            className={`
                                ${styles['scroll-arrow']}
                                ${styles['left-scroll-arrow']}
                            `}
                            onClick={handleScrollLeft}
                        />
                    )}
                    <div
                        className={styles['scrollable-container']}
                        ref={scroller}
                    >
                        {renderItems()}
                    </div>
                    {showRightSideFade && (
                        <div
                            className={`
                                ${styles['filter-fade']}
                                ${styles['right-fade']}
                            `}
                        />
                    )}
                    {showRightScrollArrow && (
                        <SimpleRightArrowIcon
                            className={`
                                ${styles['scroll-arrow']}
                                ${styles['right-scroll-arrow']}
                            `}
                            onClick={handleScrollRight}
                        />
                    )}
                </div>
            </div>
        </ConditionalWrapper>
    )
}

export default InfoRow
