import { memo, ReactElement } from 'react'

import isEqual from 'lodash/isEqual'

import {
    KEYWORD_BID_MIN,
    KEYWORD_BID_MAX,
    KEYWORD_BID_STEP,
    KEYWORD_BID_PRECISION,
} from 'const/keywords'
import { updateKeyword } from 'services/cerebroApi/orgScope/resourceApi'
import { KeywordGroupBy } from 'types'

import { BidField } from '../../SharedFields/BidField'

interface Props<RecordType> {
    dataIndex: string[]
    readOnly: boolean
    record: RecordType
}

function KeywordBidField<RecordType extends KeywordGroupBy>({
    dataIndex = ['keyword', 'base_bid'],
    readOnly = false,
    record,
}: Props<RecordType>): ReactElement {
    const fieldName = 'bid' // field to update
    const currencyCode = record.keyword.profile.currency_code
    const resourceId = record.keyword_id

    const getIsDisabled = (): boolean => {
        const userCanManageKeyword = record.keyword.user_can_manage
        return !userCanManageKeyword
    }

    return (
        <BidField
            bidMax={KEYWORD_BID_MAX}
            bidMin={KEYWORD_BID_MIN}
            bidPrecision={KEYWORD_BID_PRECISION}
            bidStep={KEYWORD_BID_STEP}
            currencyCode={currencyCode}
            disabled={getIsDisabled()}
            fieldName={fieldName}
            fieldPath={dataIndex}
            readOnly={readOnly}
            record={record}
            updateRequestApi={updateKeyword}
            updateRequestData={resourceId}
            updateRequestSuccessMesg="Successfully updated keyword bid"
        />
    )
}

export default memo(KeywordBidField, isEqual) as typeof KeywordBidField
