import { ReactElement, ReactNode } from 'react'

import {
    ExclamationCircleOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
} from '@ant-design/icons'
import { Divider } from 'antd'
import classNames from 'classnames'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { ResendInviteLink, RevokeInviteLink } from 'components/Links'
import { OrganizationInvitation } from 'types'

import styles from './styles.scss'

interface Props {
    invite: OrganizationInvitation
    reloadData: () => void
}

type CalculatedInviteState = OrganizationInvitation['state'] | 'expired'

const getStatusIcon = (text: CalculatedInviteState): ReactNode => {
    const STATUS_ICONS = {
        pending: ExclamationCircleOutlined,
        accepted: CheckCircleOutlined,
        revoked: CloseCircleOutlined,
        expired: ExclamationCircleOutlined,
    }
    const Icon = STATUS_ICONS[text]
    return Icon ? <Icon /> : null
}

function OrgInviteStatusDescription(props: {
    invite: OrganizationInvitation
}): ReactElement {
    const { invite } = props
    const { t } = useTranslation()
    const statusTexts: Record<CalculatedInviteState, string> = {
        pending: t('common:pending', 'Pending'),
        accepted: t('common:accepted', 'Accepted'),
        revoked: t('common:revoked', 'Revoked'),
        expired: t('common:expired', 'Expired'),
    }
    const isExpired = moment(invite.expiration_date).isBefore(moment())
    const state =
        invite.state === 'pending' && isExpired ? 'expired' : invite.state

    return (
        <div
            className={classNames('mr-3', {
                [styles.success]: state === 'accepted',
                [styles.warning]: state === 'pending',
                [styles.danger]: state === 'revoked',
                [styles.danger]: state === 'expired',
            })}
        >
            {getStatusIcon(invite.state)} {statusTexts[state]}
        </div>
    )
}

function OrgInviteStatusCell({ invite, reloadData }: Props): ReactElement {
    return (
        <div className="d-flex align-items-center justify-content-start">
            <OrgInviteStatusDescription invite={invite} />
            {invite.state === 'pending' && (
                <>
                    <ResendInviteLink
                        inviteId={invite.id}
                        reloadData={reloadData}
                    />
                    <Divider type="vertical" />
                    <RevokeInviteLink
                        inviteId={invite.id}
                        reloadData={reloadData}
                    />
                </>
            )}
        </div>
    )
}

export default OrgInviteStatusCell
