import { call, select } from 'redux-saga/effects'

import { makeFetchResourceSuccess } from 'actions/ui/shared/resource'
import { DSP_ADVERTISER_PAGE } from 'const/pages'
import { cerebroApiSaga } from 'sagas/common'
import uiSagaRegistry from 'sagas/ui/registry'
import { selectResourceParamsOfPage } from 'selectors/ui'
import { getDspAdvertiser } from 'services/cerebroApi/orgScope/dspApi'

function* fetchAdvertiserSaga() {
    const { advertiserId } = yield select(
        selectResourceParamsOfPage,
        DSP_ADVERTISER_PAGE
    )

    yield call(
        cerebroApiSaga,
        makeFetchResourceSuccess(DSP_ADVERTISER_PAGE),
        getDspAdvertiser,
        advertiserId
    )
}

/**
 * Mounts the Ad Group Page and fetches data
 */
function* mountPageSaga() {
    yield call(fetchAdvertiserSaga)
}

uiSagaRegistry.registerSagas([DSP_ADVERTISER_PAGE], {
    mountPageSaga,
})
