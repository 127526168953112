import { all, takeLatest } from 'redux-saga/effects'

import { updateResourceRequest } from 'actions/ui/shared/resource'

import { updateResourceWorker } from './workers'

export default function* resourceWatcher() {
    yield all([
        takeLatest(updateResourceRequest.toString(), updateResourceWorker),
    ])
}
