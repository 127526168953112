import flow from 'lodash/fp/flow'
import set from 'lodash/fp/set'

import {
    fetchTabDataFailure,
    FetchTabDataFailureAction,
    fetchTabDataRequest,
    FetchTabDataRequestAction,
    fetchTabDataSuccess,
    FetchTabDataSuccessAction,
    mountTabFailure,
    MountTabFailureAction,
    mountTabRequest,
    MountTabRequestAction,
    mountTabSuccess,
    MountTabSuccessAction,
} from 'actions/ui/shared/tab'
import { LOADED_AT } from 'const/reducerKeys'
import { getCurrentTimestamp } from 'helpers/utils'

type TabStateSettableProp = 'mounting' | 'loading' | 'error' | typeof LOADED_AT

interface TabState {
    mounting?: boolean
    loading?: boolean
    [LOADED_AT]?: number
    error?: string
    [path: string]: TabState | boolean | number | string | undefined
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function setTabStateProp(
    path: string[],
    prop: TabStateSettableProp,
    value: any
) {
    return set([...path, prop], value)
}

export default {
    // Mount
    [mountTabRequest.toString()](
        state: TabState,
        action: MountTabRequestAction
    ) {
        return setTabStateProp(
            [...action.payload.path],
            'mounting',
            true
        )(state)
    },
    [mountTabSuccess.toString()](
        state: TabState,
        action: MountTabSuccessAction
    ) {
        return setTabStateProp(
            [...action.payload.path],
            'mounting',
            false
        )(state)
    },
    [mountTabFailure.toString()](
        state: TabState,
        action: MountTabFailureAction
    ) {
        return flow(
            setTabStateProp([...action.payload.path], 'mounting', false),
            setTabStateProp(
                [...action.payload.path],
                'error',
                action.payload.data?.message
            )
        )(state)
    },

    // Fetch data
    [fetchTabDataRequest.toString()](
        state: TabState,
        action: FetchTabDataRequestAction
    ) {
        return setTabStateProp([...action.payload.path], 'loading', true)(state)
    },
    [fetchTabDataSuccess.toString()](
        state: TabState,
        action: FetchTabDataSuccessAction
    ) {
        return flow(
            setTabStateProp([...action.payload.path], 'loading', false),
            setTabStateProp(
                [...action.payload.path],
                LOADED_AT,
                getCurrentTimestamp()
            )
        )(state)
    },
    [fetchTabDataFailure.toString()](
        state: TabState,
        action: FetchTabDataFailureAction
    ) {
        return flow(
            setTabStateProp([...action.payload.path], 'loading', false),
            setTabStateProp(
                [...action.payload.path],
                'error',
                action.payload.data?.message
            )
        )(state)
    },
}
