import { ReactNode } from 'react'

import { captureException } from '@sentry/browser'
import has from 'lodash/has'
import isString from 'lodash/isString'

import AsinLink from 'components/Links/AsinLink'
import { Campaign, Expression, TargetGroupBy } from 'types'

import { extractValue } from './utils'

type Params = {
    expr: Expression
    campaign: Campaign
    resolvedExpression?: string
    record?: TargetGroupBy & { resolved_expression?: string }
}

interface ResolvedExpressionMap {
    [key: string]: {
        toReactNode?: (params: Params) => ReactNode
        toString: (params: Omit<Params, 'campaign'>) => string
    }
}

type ViewExpressionType =
    | 'asinCategorySameAs'
    | 'exactProduct'
    | 'similarProduct'
    | 'audienceSameAs'
    | 'relatedProduct'
    | 'lookback'
    | 'asinPriceLessThan'
    | 'asinPriceBetween'
    | 'asinPriceGreaterThan'
    | 'asinReviewRatingLessThan'
    | 'asinReviewRatingBetween'
    | 'asinReviewRatingGreaterThan'
    | 'asinExpandedFrom'
    | 'asinSameAs'
    | 'asinBrandSameAs'
    | 'asinIsPrimeShippingEligible'
    | 'asinAgeRangeSameAs'
    | 'asinGenreSameAs'

const VIEW_EXPRESSION_HANDLERS: Record<
    ViewExpressionType,
    (expr: Expression, suffix: string) => string
> = {
    asinCategorySameAs: (_expr, suffix = 'Views') =>
        `Category ${suffix}`.trim(),
    exactProduct: (_expr, suffix = 'Views') => `Exact Product ${suffix}`.trim(),
    similarProduct: (_expr, suffix = 'Views') =>
        `Similar Product ${suffix}`.trim(),
    audienceSameAs: (_expr, _suffix = 'Views') => `Same Audience`.trim(),
    relatedProduct: (_expr, suffix = 'Views') =>
        `Related Product ${suffix}`.trim(),
    lookback: (expr) => `Lookback: ${expr.value} days`,
    asinPriceLessThan: (expr) => `Price Less Than: ${expr.value}`,
    asinPriceBetween: (expr) => `Price Between: ${expr.value}`,
    asinPriceGreaterThan: (expr) => `Price Greater Than: ${expr.value}`,
    asinReviewRatingLessThan: (expr) =>
        `Review Rating Less Than: ${expr.value}`,
    asinReviewRatingBetween: (expr) => `Review Rating Between: ${expr.value}`,
    asinReviewRatingGreaterThan: (expr) =>
        `Review Rating Greater Than: ${expr.value}`,
    asinExpandedFrom: (expr: Expression) =>
        `Expanded From: ${String(expr.value)}`,
    asinSameAs: (expr) => String(expr.value),
    asinBrandSameAs: (expr) => `Brand: ${expr.value}`,
    asinIsPrimeShippingEligible: (expr) => `Is Prime Eligible: ${expr.value}`,
    asinAgeRangeSameAs: (expr) => `Age Range: ${expr.value}`,
    asinGenreSameAs: (expr) => `Genre: ${expr.value}`,
}

export const parseViewsExpression = (
    exprValue: string,
    suffix = 'Views'
): string => {
    // Amazon returns invalid JSON string with single quoted strings
    const parsed = JSON.parse(exprValue.replace(/'/g, '"'))

    const expressions: string[] = []
    parsed.forEach((expr: Expression) => {
        const expressionHandler =
            VIEW_EXPRESSION_HANDLERS[expr.type as ViewExpressionType]
        if (expressionHandler) {
            expressions.push(expressionHandler(expr, suffix))
        } else {
            captureException(
                Error(
                    `Unknown audience targeting views expression type: ${expr.type}`
                )
            )
        }
    })
    if (expressions.length) {
        return expressions.join(', ')
    }
    captureException(
        Error(`Unknown audience targeting views expression value: ${exprValue}`)
    )
    return exprValue
}

function extractValueFromExpressionDescription(
    expressionDescription?: string
): string {
    if (!expressionDescription) {
        return ''
    }
    const expressionDescriptionParts = expressionDescription.split(',')
    const categoryPart = expressionDescriptionParts.find((part) =>
        part.includes('Category')
    )
    if (categoryPart && categoryPart.includes(':')) {
        return categoryPart.split(':')[1].trim()
    }
    return ''
}

const RESOLVED_EXPRESSIONS: ResolvedExpressionMap = {
    asinCategorySameAs: {
        toReactNode: ({ expr, resolvedExpression, record }) => {
            if (!resolvedExpression) {
                // Fall back to the expression description to check if a resolved category name is present
                if (record && record.target) {
                    const resolvedCategory =
                        extractValueFromExpressionDescription(
                            record.target.expression_description
                        )
                    if (resolvedCategory) {
                        return `Category: ${resolvedCategory}`
                    }
                }
                return `Category: ${expr.value}`
            }
            if (!isString(resolvedExpression)) {
                return resolvedExpression
            }

            return `Category: ${extractValue(
                resolvedExpression,
                'category="',
                '"'
            )}`
        },
        toString: ({ expr }) => `Category: ${expr.value}`,
    },
    asinBrandSameAs: {
        toReactNode: ({ expr, resolvedExpression, record }) => {
            if (!resolvedExpression) {
                if (record && record.target) {
                    const resolvedBrand = extractValueFromExpressionDescription(
                        record.target.expression_description
                    )
                    if (resolvedBrand) {
                        return `Brand: ${resolvedBrand}`
                    }
                }

                return `Brand ${expr.value} `
            }
            if (!isString(resolvedExpression)) {
                return resolvedExpression
            }

            return `Brand: ${extractValue(resolvedExpression, 'brand="', '"')}`
        },
        toString: ({ expr }) => `Brand: ${expr.value}`,
    },
    asinPriceLessThan: {
        toString: ({ expr }) => `Price Less Than: ${expr.value}`,
    },
    asinPriceBetween: {
        toString: ({ expr }) => `Price Between: ${expr.value}`,
    },
    asinPriceGreaterThan: {
        toString: ({ expr }) => `Price Greater Than: ${expr.value}`,
    },
    asinReviewRatingLessThan: {
        toString: ({ expr }) => `Review Rating Less Than: ${expr.value}`,
    },
    asinReviewRatingBetween: {
        toString: ({ expr }) => `Review Rating Between: ${expr.value}`,
    },
    asinReviewRatingGreaterThan: {
        toString: ({ expr }) => `Review Rating Greater Than: ${expr.value}`,
    },
    asinExpandedFrom: {
        toReactNode: ({ expr, campaign }) => (
            <span>
                Expanded From:{' '}
                <AsinLink
                    asin={typeof expr.value === 'string' ? expr.value : ''}
                    countryCode={campaign?.profile?.country_code}
                />
            </span>
        ),
        toString: ({ expr }) => `Expanded From: ${String(expr.value)}`,
    },
    asinSameAs: {
        toReactNode: ({ expr, campaign }) => (
            <AsinLink
                asin={typeof expr.value === 'string' ? expr.value : ''}
                countryCode={campaign?.profile?.country_code}
            />
        ),
        toString: ({ expr }) => String(expr.value),
    },
    asinIsPrimeShippingEligible: {
        toString: ({ expr }) => `Is Prime Eligible: ${expr.value}`,
    },
    asinAgeRangeSameAs: {
        toString: ({ expr }) => `Age Range: ${expr.value}`,
    },
    asinGenreSameAs: {
        toString: ({ expr }) => `Genre: ${expr.value}`,
    },

    // enhanced auto targeting options
    queryHighRelMatches: {
        toString: () => 'Enhanced Auto Targeting: Close match',
    },
    queryBroadRelMatches: {
        toString: () => 'Enhanced Auto Targeting: Loose match',
    },
    asinSubstituteRelated: {
        toString: () => 'Enhanced Auto Targeting: Substitutes',
    },
    asinAccessoryRelated: {
        toString: () => 'Enhanced Auto Targeting: Complements',
    },

    // sponsored display audience targeting
    views: {
        toReactNode: ({ expr, resolvedExpression }) => {
            const category = resolvedExpression
                ? extractValue(resolvedExpression, 'category="', '"')
                : ''
            return (
                <div>
                    <div>
                        {`Audience Targeting: ${parseViewsExpression(
                            String(expr.value)
                        )}`}
                    </div>
                    {category && <div>Category: {category}</div>}
                </div>
            )
        },
        toString: ({ expr }) =>
            `Audience Targeting: ${parseViewsExpression(String(expr.value))}`,
    },
    similarProduct: {
        toString: () => 'Audience that has viewed similar products',
    },
    audience: {
        toString: ({ expr }) =>
            `Audience Targeting: ${parseViewsExpression(String(expr.value))}`,
    },
    purchases: {
        toString: ({ expr }) =>
            `Audience that has bought: ${parseViewsExpression(
                String(expr.value),
                ''
            )}`,
    },
}

export const resolvedExpressionToReactNode = (
    expr: Expression,
    record: TargetGroupBy & { resolved_expression?: string }
): ReactNode => {
    if (has(RESOLVED_EXPRESSIONS, expr.type)) {
        const nodeRenderer = RESOLVED_EXPRESSIONS[expr.type].toReactNode
        return nodeRenderer
            ? nodeRenderer?.({
                  expr,
                  campaign: record.target.campaign,
                  resolvedExpression: record.resolved_expression,
                  record,
              })
            : RESOLVED_EXPRESSIONS[expr.type].toString({
                  expr,
                  resolvedExpression: record.resolved_expression,
                  record,
              })
    }
    captureException(
        Error(`Unknown product targeting expression type: ${expr.type}`)
    )
    return expr.type
}

export const resolvedExpressionToString = (expr: Expression): string => {
    if (has(RESOLVED_EXPRESSIONS, expr.type)) {
        return RESOLVED_EXPRESSIONS[expr.type].toString({ expr })
    }
    captureException(
        Error(`Unknown product targeting expression type: ${expr.type}`)
    )
    return expr.type
}
