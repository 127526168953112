import { ReactElement } from 'react'

import { InfoCircleOutlined } from '@ant-design/icons'
import { Popover, Table } from 'antd'

import { formatCurrency, formatMultiplier, titleCase } from 'helpers/formatting'
import { getEffectiveMultiplier, getAbcIfEnabled } from 'helpers/timeParting'
import { Campaign, CurrencyCode, AutomationTask } from 'types'
import moment from 'utilities/moment'

import styles from './styles.scss'

interface Props {
    automationTasks: AutomationTask[]
    currencyCode: CurrencyCode
    campaign: Campaign
    baseBudget: number
    activeBudget: number
}

const renderKeyColumn = (text: string): ReactElement => {
    let asterisk
    switch (text) {
        case 'Daily Budget':
            asterisk = <sup>*</sup>
            break
        case 'Active Budget':
            asterisk = <sup>**</sup>
            break
        default:
            asterisk = null
    }
    return (
        <span className={styles.key}>
            {text}
            {asterisk}
        </span>
    )
}

const renderNotes = (): ReactElement => (
    <div className={styles.notes}>
        <div>
            <sup>*</sup>
            This is the total budget for the day after applying the automation
            multiplier.
        </div>
        <div>
            <sup>**</sup>
            If <strong>Distribute Budget by Hour</strong> is turned on, this is
            the budget cap for the current time of day.{' '}
            <strong>Active Budget</strong> will converge to Daily Budget by
            midnight.
        </div>
    </div>
)

const ActiveBudgetCell = ({
    automationTasks,
    currencyCode,
    baseBudget,
    activeBudget,
    campaign,
}: Props): ReactElement => {
    const popoverContent = (): ReactElement => {
        const automationTask = getAbcIfEnabled(automationTasks, campaign.state)

        if (!automationTask) {
            return (
                <div className={styles['popover-text-container']}>
                    <strong>Active Budget</strong> is the same as{' '}
                    <strong>Budget</strong> when Advanced Budget Control is{' '}
                    <em>Disabled</em>.
                </div>
            )
        }

        if (!automationTask.enabled) {
            return (
                <div className={styles['popover-text-container']}>
                    <strong>Active Budget</strong> is the same as{' '}
                    <strong>Budget</strong> when the Campaign is{' '}
                    <em>{titleCase(campaign.state)}</em>. The budget control
                    system only makes budget changes for <em>Enabled</em>{' '}
                    campaigns.
                </div>
            )
        }

        const { timezone } = campaign.profile
        const daily_multipliers =
            automationTask.automation_profile?.params?.daily_multiplier
        // if no hourly_enabled, use 1 as multipliers
        const hourly_multipliers = automationTask.automation_profile?.params
            ?.hourly_enabled
            ? automationTask.automation_profile?.params?.hourly_multiplier
            : Array(24).fill(1)

        const { effectiveTimeFormatted, effectiveDailyMultiplier, multiplier } =
            getEffectiveMultiplier(
                {
                    daily_multiplier: daily_multipliers,
                    hourly_multiplier: hourly_multipliers,
                },
                timezone,
                moment()
            )

        return (
            <div className={styles['popover-table-container']}>
                <div className={styles.header}>
                    Advanced Budget Control Details
                </div>
                <div className={styles.table}>
                    <Table
                        columns={[
                            {
                                title: 'Key',
                                dataIndex: 'key',
                                key: 'key',
                                render: renderKeyColumn,
                            },
                            {
                                title: 'value',
                                dataIndex: 'value',
                                key: 'value',
                            },
                        ]}
                        dataSource={[
                            {
                                key: 'Timezone',
                                value: timezone,
                            },
                            {
                                key: 'Effective Time',
                                value: effectiveTimeFormatted,
                            },
                            {
                                key: 'Budget',
                                value: formatCurrency(baseBudget, {
                                    decimal: true,
                                    currencyCode,
                                }),
                            },
                            {
                                key: 'Current Multiplier',
                                value: formatMultiplier(multiplier),
                            },
                            {
                                key: 'Daily Budget',
                                value: formatCurrency(
                                    (effectiveDailyMultiplier ?? 0) *
                                        baseBudget,
                                    {
                                        decimal: true,
                                        currencyCode,
                                    }
                                ),
                            },
                            {
                                key: 'Active Budget',
                                value: formatCurrency(activeBudget, {
                                    decimal: true,
                                    currencyCode,
                                }),
                            },
                        ]}
                        size="small"
                        bordered={false}
                        showHeader={false}
                        pagination={false}
                    />
                    {renderNotes()}
                </div>
            </div>
        )
    }

    return (
        <div className={styles['cell-container']}>
            <div>
                {formatCurrency(activeBudget, {
                    decimal: true,
                    currencyCode,
                })}
            </div>
            <div>
                <Popover content={popoverContent()}>
                    <InfoCircleOutlined />
                </Popover>
            </div>
        </div>
    )
}

export default ActiveBudgetCell
