import cloneDeep from 'lodash/cloneDeep'
import flow from 'lodash/fp/flow'
import set from 'lodash/fp/set'
import get from 'lodash/get'
import {
    Action,
    combineActions,
    handleActions,
    ReducerMap,
} from 'redux-actions'

import {
    acceptInvitationFailure,
    acceptInvitationRequest,
    acceptInvitationSuccess,
    acceptSignUpFormInvitationFailure,
    acceptSignUpFormInvitationRequest,
    acceptSignUpFormInvitationSuccess,
    changeOrganizationFailure,
    changeOrganizationGroupFailure,
    changeOrganizationGroupRequest,
    changeOrganizationRequest,
    createAuthPageOrganizationFailure,
    createAuthPageOrganizationRequest,
    createAuthPageOrganizationSuccess,
    fetchLatestOrganizationAdIntegrationsSuccess,
    fetchLatestOrganizationIntegrationSuccess,
    fetchLatestWalmartAdvertiserSuccess,
    fetchPendingInvitationsSuccess,
    fetchProfileFeaturePermissionsSuccess,
    fetchUserFeaturePermissionsSuccess,
    fetchUserOrganizationFeaturePermissionsSuccess,
    fetchUserSettingsSuccess,
    getSignUpFormInvitationSuccess,
    impersonationModeFailure,
    loadAuthFailure,
    loadAuthFinish,
    LoadAuthFinishPayload,
    loadAuthRequest,
    resendCodeRequest,
    resendCodeSuccess,
    resendSignUpCodeSuccess,
    resetAuthErrors,
    resetPasswordFailure,
    resetPasswordRequest,
    sendResetEmailFailure,
    sendResetEmailRequest,
    sendResetEmailSuccess,
    setOrganizationId,
    setUserIsImpersonating,
    signInFailure,
    signInMfaRequest,
    signInMfaRequired,
    signInRequest,
    signInSuccess,
    SignInSuccessPayload,
    signOutFailure,
    signOutRequest,
    signOutSuccess,
    signUpFailure,
    signUpRequest,
    signUpSuccess,
    verifyEmailFailure,
    verifyEmailRequest,
    verifyEmailSuccess,
} from 'actions/auth'

const defaultState = {
    signedIn: false,
    username: null,
    organizationId: null,
    organizationGroupId: null,
    userFeaturePermissions: [],
    featurePermissions: [],
    profileFeaturePermissions: {},
    latestOrganizationIntegration: null,
    latestOrganizationAdIntegrations: null,
    latestWalmartAdvertiser: null,
    email: null,
    email_verified: null,
    name: null,
    phone_number: null,
    phone_number_verified: null,
    displayMaintenancePage: false,
    isLoadingAuth: true, // defaults to true so loading indicator is always shown by default when application loads
    isFetching: false,
    loadAuthError: null,
    error: null,
    password: null,
    invitation: null,
    ipAddress: null,
    date_joined: null,
    id: null,
    pendingInvitations: [],
    userIsImpersonating: false, // persists to local storage
    settings: null,
    isFetchingCode: false,
}

export default handleActions(
    {
        [loadAuthRequest.toString()](state) {
            return {
                ...state,
                isLoadingAuth: true,
            }
        },

        [loadAuthFinish.toString()](
            state,
            action: Action<LoadAuthFinishPayload>
        ) {
            const {
                displayMaintenancePage = false,
                displayDowntimePage = false,
            } = action.payload || {}
            return {
                ...state,
                isLoadingAuth: false,
                displayMaintenancePage,
                displayDowntimePage,
            }
        },

        [loadAuthFailure.toString()](state, action) {
            const { error } = action.payload
            return {
                ...state,
                isLoadingAuth: false,
                loadAuthError: error,
            }
        },

        [signOutSuccess.toString()]() {
            return { ...defaultState, isLoadingAuth: false }
        },

        [resetAuthErrors.toString()](state) {
            return {
                ...state,
                error: null,
            }
        },

        [combineActions(
            signUpRequest,
            signInRequest,
            signInMfaRequest,
            signOutRequest,
            resetPasswordRequest,
            createAuthPageOrganizationRequest,
            verifyEmailRequest,
            acceptSignUpFormInvitationRequest,
            acceptInvitationRequest
        ).toString()](state) {
            return {
                ...state,
                isFetching: true,
                error: null,
            }
        },

        [combineActions(
            createAuthPageOrganizationSuccess,
            verifyEmailSuccess,
            sendResetEmailSuccess,
            acceptInvitationSuccess
        ).toString()](state) {
            return {
                ...state,
                isFetching: false,
                error: null,
            }
        },

        [combineActions(signUpSuccess, resendSignUpCodeSuccess).toString()](
            state,
            action
        ) {
            const { email, password } = action.payload
            return {
                ...state,
                isFetching: false,
                error: null,
                email,
                password,
            }
        },

        [signInSuccess.toString()](
            state,
            action: Action<SignInSuccessPayload>
        ) {
            const {
                currentUser: {
                    username,
                    date_joined,
                    id,
                    email,
                    first_name,
                    last_name,
                    phone_number,
                },
                organizationId,
                organizationGroupId,
            } = action.payload

            return {
                ...state,
                signedIn: true,
                isFetching: false,
                error: null,
                password: null,

                username,
                date_joined,
                id,
                organizationId,
                organizationGroupId,
                email,
                email_verified: true,
                name: `${first_name} ${last_name}`,
                phone_number: phone_number || null,
                phone_number_verified: false,
            }
        },

        [sendResetEmailRequest.toString()](state, action) {
            const { email } = action.payload
            return {
                ...state,
                isFetching: true,
                error: null,
                email,
            }
        },

        [combineActions(
            signUpFailure,
            signOutFailure,
            signInFailure,
            sendResetEmailFailure,
            resetPasswordFailure,
            createAuthPageOrganizationFailure,
            verifyEmailFailure,
            acceptSignUpFormInvitationFailure,
            acceptInvitationFailure,
            impersonationModeFailure
        ).toString()](state, action) {
            const { error } = action.payload
            return {
                ...state,
                isFetching: false,
                error,
            }
        },

        [signInMfaRequired.toString()](state) {
            return {
                ...state,
                isFetching: false,
            }
        },

        [resendCodeRequest.toString()](state) {
            return {
                ...state,
                isFetching: false,
                isFetchingCode: true,
            }
        },

        [resendCodeSuccess.toString()](state) {
            return {
                ...state,
                isFetching: false,
                isFetchingCode: false,
            }
        },

        [fetchUserFeaturePermissionsSuccess.toString()](state, action) {
            const userFeaturePermissions = action.payload
            return {
                ...state,
                userFeaturePermissions,
            }
        },

        [fetchUserOrganizationFeaturePermissionsSuccess.toString()](
            state,
            action
        ) {
            const featurePermissions = action.payload
            return {
                ...state,
                featurePermissions,
            }
        },

        [fetchUserSettingsSuccess.toString()](state, action) {
            const settings = action.payload
            return {
                ...state,
                settings,
            }
        },

        [combineActions(
            changeOrganizationRequest,
            setOrganizationId
        ).toString()](state, action) {
            const { organizationId } = action.payload
            return {
                ...state,
                organizationId,
            }
        },

        [changeOrganizationGroupRequest.toString()](state, action) {
            const { organizationId, organizationGroupId } = action.payload
            return {
                ...state,
                organizationId,
                organizationGroupId,
            }
        },

        [combineActions(
            changeOrganizationFailure,
            changeOrganizationGroupFailure
        ).toString()](state, action) {
            const { error } = action.payload
            return {
                ...state,
                error,
            }
        },

        [fetchLatestOrganizationIntegrationSuccess.toString()](state, action) {
            const latestIntegrationCreatedDate = get(
                action,
                ['payload', 'results', '0', 'created_at'],
                null
            )
            return set(
                ['latestOrganizationIntegration'],
                latestIntegrationCreatedDate,
                state
            )
        },

        [fetchLatestWalmartAdvertiserSuccess.toString()](state, action) {
            const latestWalmartAdvertiser = get(
                action,
                ['payload', 'results', '0'],
                null
            )
            return set(
                ['latestWalmartAdvertiser'],
                latestWalmartAdvertiser,
                state
            )
        },

        [fetchLatestOrganizationAdIntegrationsSuccess.toString()](
            state,
            action
        ) {
            const latestAdIntegrations = get(
                action,
                ['payload', 'results'],
                null
            )
            return set(
                ['latestOrganizationAdIntegrations'],
                latestAdIntegrations,
                state
            )
        },

        [getSignUpFormInvitationSuccess.toString()](state, action) {
            return flow(
                set(['invitation'], action.payload),
                set(['isFetching'], false),
                set(['error'], null)
            )(state)
        },

        [acceptSignUpFormInvitationSuccess.toString()](state) {
            return flow(
                set(['invitation'], null),
                set(['isFetching'], false),
                set(['error'], null)
            )(state)
        },

        [fetchProfileFeaturePermissionsSuccess.toString()](state, action) {
            const { profileId, permissions } = action.payload
            return set(
                ['profileFeaturePermissions', profileId],
                permissions,
                state
            )
        },

        [fetchPendingInvitationsSuccess.toString()](state, action) {
            return set(['pendingInvitations'], action.payload.results)(state)
        },

        [setUserIsImpersonating.toString()](state, action) {
            const isImpersonating = action.payload
            return flow(
                set(['userIsImpersonating'], isImpersonating),
                set(['isLoadingAuth'], true)
            )(state)
        },
    } as ReducerMap<typeof defaultState, any>,
    cloneDeep(defaultState) // create clone, so the defaultState is not mutated
)
