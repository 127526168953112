import { ReactElement } from 'react'

import { Alert } from 'antd'

import { GlobalNotification } from 'types'

interface Props {
    globalNotification?: GlobalNotification | null
}

const GlobalNotificationBanner = ({
    globalNotification = null,
}: Props): ReactElement | null => {
    if (!globalNotification || !globalNotification.enabled) {
        return null
    }

    return (
        <Alert
            banner
            type={globalNotification.type}
            message={
                <span
                    dangerouslySetInnerHTML={{
                        __html: globalNotification.message,
                    }}
                />
            }
            closable
        />
    )
}

export default GlobalNotificationBanner
