import { ReactElement, CSSProperties } from 'react'

import classnames from 'classnames'
import { useTranslation } from 'react-i18next'

import styles from './styles.scss'

export type FeatureStatus =
    | 'new'
    | 'internal'
    | 'beta'
    | 'deprecated'
    | 'updated'
    | 'pre_release'
interface FeatureStatusTagProps {
    status: FeatureStatus
    isSelected?: boolean
    size?: 'small' | 'default'
    location?: string
    style?: CSSProperties
}

export default function FeatureStatusTag({
    status,
    isSelected,
    size = 'default',
    location,
    style,
}: FeatureStatusTagProps): ReactElement {
    const { t } = useTranslation()

    const labels: Record<FeatureStatus, string> = {
        new: t('common:featureStatus.new', 'New'),
        beta: t('common:featureStatus.beta', 'Beta'),
        internal: t('common:featureStatus.internal', 'Internal'),
        deprecated: t('common:featureStatus.deprecated', 'Deprecated'),
        updated: t('common:featureStatus.updated', 'Updated'),
        pre_release: t('common:featureStatus.preRelease', 'Pre-release'),
    }

    return (
        <span
            className={classnames(
                styles['feature-status-tag'],
                styles[status],
                {
                    [styles.selected]: isSelected,
                    [styles.small]: size === 'small',
                    [styles[location ?? '']]: location,
                }
            )}
            style={style}
        >
            {labels[status]}
        </span>
    )
}
