import { ReactElement } from 'react'

import { acceptSignUpFormInvitationRequest } from 'actions/auth'
import { LoadingIndicator } from 'components/LoadingIndicator'
import { AUTH_ORGANIZATION_PAGE } from 'const/pages'
import {
    useMountPage,
    useMountingState,
    useAuthDomainValue,
    useAction,
} from 'hooks'
import { OrganizationInvitation } from 'types'

import { JoinOrganizationForm, CreateOrganizationForm } from './Forms'

const AuthOrganizationPage = (): ReactElement => {
    useMountPage(AUTH_ORGANIZATION_PAGE)
    const mounting = useMountingState(AUTH_ORGANIZATION_PAGE)
    const invitation: OrganizationInvitation = useAuthDomainValue('invitation')
    const acceptInvite = useAction(acceptSignUpFormInvitationRequest)

    if (mounting) {
        return <LoadingIndicator size="small" />
    }

    return invitation ? (
        <JoinOrganizationForm invitation={invitation} onSubmit={acceptInvite} />
    ) : (
        <CreateOrganizationForm />
    )
}

export default AuthOrganizationPage
