import isEmpty from 'lodash/isEmpty'

import { DATES, METRIC_FILTERS } from 'const/filters'
import { formatDateFromFilter } from 'helpers/utils'
import { FiltersState, MetricFilter, WmMarketplaceFactsParams } from 'types'

import { formatMetricFiltersForParams } from './metrics'

export const formatFilters = (
    filters: FiltersState
): WmMarketplaceFactsParams => {
    let params: WmMarketplaceFactsParams = {}

    const dates = filters[DATES]
    const { start, end } = formatDateFromFilter(dates)
    params.report_date_min = start
    params.report_date_max = end

    const metricFilters = filters[METRIC_FILTERS]
    if (!isEmpty(metricFilters)) {
        params = {
            ...params,
            ...formatMetricFiltersForParams(metricFilters as MetricFilter[]),
        }
    }

    return params
}
