import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'

import {
    COUNTRIES,
    DATES,
    LABELS,
    METRIC_FILTERS,
    PRODUCT_ASINS,
    PRODUCT_TITLES,
    VC_INTEGRATIONS,
} from 'const/filters'
import { PRODUCT_LABELS } from 'const/resourceTypes'
import { formatCerebroDate } from 'helpers/formatting'

import { formatMetricFiltersForParams } from './metrics'
import { presetRanges, calculateFromDateWithLag } from '../dateRange'
import { formatBooleanFilter } from '../filters'

export const formatFilters = (filters) => {
    let params = {}

    if (!isEmpty(filters[DATES])) {
        if (isArray(filters[DATES])) {
            params.report_date_min = formatCerebroDate(filters[DATES][0])
            params.report_date_max = formatCerebroDate(filters[DATES][1])
        } else {
            const { range } = presetRanges(
                calculateFromDateWithLag(filters.rangeLag),
                filters.rangeLag
            )[filters[DATES]]
            params.report_date_min = formatCerebroDate(range[0])
            params.report_date_max = formatCerebroDate(range[1])
        }
    }
    if (!isEmpty(filters[COUNTRIES])) {
        params.marketplace__in = filters[COUNTRIES].map(
            (item) => item.value
        ).join()
    }
    if (!isEmpty(filters[PRODUCT_ASINS])) {
        params.asin__in = filters[PRODUCT_ASINS].map(
            (item) => item.value
        ).join()
    }
    if (!isEmpty(filters[LABELS])) {
        params.complex_label_filter = formatBooleanFilter(
            filters[LABELS],
            PRODUCT_LABELS
        )
    }
    if (!isEmpty(filters[PRODUCT_TITLES])) {
        params.metadata__id__in = filters[PRODUCT_TITLES].map(
            (item) => item.value
        ).join()
    }
    if (!isEmpty(filters[VC_INTEGRATIONS])) {
        params.request__account_integration__in = filters[VC_INTEGRATIONS].map(
            (item) => item.value
        ).join()
    }

    const metricFilters = filters[METRIC_FILTERS]
    if (!isEmpty(metricFilters)) {
        params = { ...params, ...formatMetricFiltersForParams(metricFilters) }
    }

    return params
}
