import {
    createContext,
    ReactElement,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react'

import { LDContext, useLDClient } from 'launchdarkly-react-client-sdk'
import noop from 'lodash/noop'
import { useSelector } from 'react-redux'

import { useCerebroApiRequest } from 'hooks'
import { selectUserSettings } from 'selectors/auth'
import { selectCurrentOrganization } from 'selectors/orgs'
import { getUserMfaSettings } from 'services/cerebroApi/noScope/resourceApi'
import {
    AuthState,
    CerebroResourceResponse,
    Organization,
    RootReduxState,
    UserMfaSettings,
} from 'types'

export interface UserContextSummary {
    userId: string
    userEmail: string
    orgId: string
    orgName: string
}

interface UserContext {
    userId?: string
    userMfaSettings?: UserMfaSettings & {
        needsToSetUpMfa: boolean
    }
    currentUser?: AuthState
    setMfaConfigured: () => void
    isInternalUser: boolean
}

const UserContextInstance = createContext<UserContext>({
    userId: undefined,
    userMfaSettings: undefined,
    currentUser: undefined,
    setMfaConfigured: noop,
    isInternalUser: false,
})

export const useUserContext = (): UserContext => useContext(UserContextInstance)

export function UserContextProvider(props: {
    onContextChanged: (context: LDContext) => void
    children: ReactNode
}): ReactElement {
    const { children, onContextChanged } = props
    const ldClient = useLDClient()
    const [userMfaSettings, setUserMfaSettings] = useState<UserMfaSettings>()
    const makeCerebroApiRequest = useCerebroApiRequest()
    const currentUser = useSelector<RootReduxState, AuthState>((state) =>
        selectUserSettings(state)
    )
    const currentOrg = useSelector<RootReduxState, Organization | undefined>(
        (state) => selectCurrentOrganization(state)
    )

    const userId = currentUser?.id ?? undefined
    const orgId = currentOrg?.id
    const orgName = currentOrg?.name

    const userEmail = (currentUser?.email ?? '').toLowerCase()
    const isInternalUser =
        userEmail.endsWith('@junglescout.com') ||
        userEmail.endsWith('@junglescout.cn') ||
        userEmail.endsWith('@downstreamimpact.com') ||
        userEmail.endsWith('@downstreamimpact.cn')

    const fetchUserMfaSettings = useCallback(async (): Promise<void> => {
        await makeCerebroApiRequest({
            request: getUserMfaSettings(),
            onRequestSuccess: (
                response: CerebroResourceResponse<UserMfaSettings>
            ) => {
                setUserMfaSettings(response.data)
            },
        })
    }, [makeCerebroApiRequest])

    useEffect(() => {
        if (userId) {
            setUserMfaSettings(undefined)
            fetchUserMfaSettings()
        }
    }, [userId, orgId, fetchUserMfaSettings])

    useEffect(() => {
        if (userId && orgId) {
            const ldContext = {
                kind: 'multi',
                user: {
                    kind: 'user',
                    key: userId,
                    email: userEmail,
                },
                organization: {
                    kind: 'organization',
                    key: orgId,
                    name: orgName ?? '',
                },
            }
            onContextChanged(ldContext)
            ldClient?.identify(ldContext)
        }
    }, [userId, userEmail, orgId, orgName, onContextChanged, ldClient])

    const setMfaConfigured = useCallback(() => {
        setUserMfaSettings((prev) => {
            if (prev) {
                return {
                    ...prev,
                    user_has_mfa_configured: true,
                }
            }
            return prev
        })
    }, [])

    const userContext = useMemo(() => {
        return {
            userId,
            userMfaSettings: userMfaSettings
                ? {
                      ...userMfaSettings,
                      needsToSetUpMfa:
                          !userMfaSettings.user_has_mfa_configured &&
                          userMfaSettings.user_requires_mfa,
                  }
                : undefined,
            currentUser,
            isInternalUser,
            setMfaConfigured,
        }
    }, [userId, userMfaSettings, currentUser, isInternalUser, setMfaConfigured])

    return (
        <UserContextInstance.Provider value={userContext}>
            {children}
        </UserContextInstance.Provider>
    )
}
