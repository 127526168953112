import { QuestionCircleOutlined } from '@ant-design/icons'
import { Translation } from 'react-i18next'

import { ToolTip } from 'components/ToolTip'
import { REPORT_DATE_DAY_ID, REPORT_DATE_HOUR_ID } from 'const/widgets'
import { formatDate } from 'helpers/dates'
import {
    formatDay,
    formatDayofWeek,
    formatHourOfDay,
    formatMonthName,
    formatQuarter,
} from 'helpers/formatting'
import { Field } from 'types'

import { createDateField, createField } from './createField'
import { FieldCreatorOptions } from './localTypes'

export function reportDateYear<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createDateField({
        ...options,
        id: options.dataIndex ? options.dataIndex[0] : 'report_date_year',
        name: 'Report Date Year',
        shortName: 'Report Date Year',
        minWidth: 50,
        dataIndex: options.dataIndex ?? ['report_date_year'],
        dateAggregation: 'year',
        width: 100,
    })
}

export function reportDateMonth<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createDateField({
        ...options,
        id: options.dataIndex ? options.dataIndex[0] : 'report_date_month',
        name: 'Report Date Month',
        shortName: 'Report Date Month',
        minWidth: 50,
        dataIndex: options.dataIndex ?? ['report_date_month'],
        width: 100,
        dateAggregation: 'month',
    })
}

export function reportDateWeek<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createDateField({
        ...options,
        id: options.dataIndex ? options.dataIndex[0] : 'report_date_week',
        name: 'Report Date Week',
        shortName: 'Report Date Week',
        minWidth: 50,
        dataIndex: options.dataIndex ?? ['report_date_week'],
        width: 100,
        dateAggregation: 'week',
    })
}

export function reportDateDay<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createDateField({
        ...options,
        id: options.dataIndex ? options.dataIndex[0] : REPORT_DATE_DAY_ID,
        name: 'Report Date Day',
        shortName: 'Report Date Day',
        minWidth: 50,
        dataIndex: options.dataIndex ?? [REPORT_DATE_DAY_ID],
        width: 100,
        dateAggregation: 'day',
    })
}

export function reportDateQuarter<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createDateField({
        ...options,
        id: options.dataIndex ? options.dataIndex[0] : 'report_date_quarter',
        name: 'Report Date Quarter',
        shortName: 'Report Date Quarter',
        minWidth: 50,
        dataIndex: options.dataIndex ?? ['report_date_quarter'],
        width: 100,
        dateAggregation: 'quarter',
    })
}

export function reportDateHour<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createDateField({
        ...options,
        id: REPORT_DATE_HOUR_ID,
        name: 'Report Date Hour',
        shortName: (isDisabled: boolean) => (
            <>
                Report Date Hour &nbsp;
                <ToolTip
                    title={
                        isDisabled
                            ? undefined
                            : "The displayed time zone will be the timezone of the Campaign's Ad Account."
                    }
                >
                    <QuestionCircleOutlined />
                </ToolTip>
            </>
        ),
        minWidth: 50,
        dataIndex: options.dataIndex ?? [REPORT_DATE_HOUR_ID],
        columnTitle: 'Report Hour',
        width: 100,
        dateAggregation: 'hour',
    })
}

export function reportDateDayHour<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createField({
        ...options,
        id: 'report_date_hour_of_day',
        name: 'Report Date Hour of Day',
        shortName: 'Report Date Hour of Day',
        minWidth: 50,
        dataIndex: options.dataIndex ?? ['report_date_hour_of_day'],
        renderOptions: {
            render: ({ value }) => formatHourOfDay(value),
        },
        width: 100,
    })
}

export function reportDateWeekDay<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createField({
        ...options,
        id: 'report_date_week_day',
        name: 'Report Date Day of Week',
        shortName: 'Report Date Day of Week',
        minWidth: 50,
        dataIndex: options.dataIndex ?? ['report_date_week_day'],
        renderOptions: {
            render: ({ value }) => formatDayofWeek(value),
        },
        width: 100,
    })
}

export function reportDateQuarterNumber<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createField({
        ...options,
        id: 'report_date_quarter_number',
        name: 'Report Date Quarter Number',
        shortName: 'Report Date Quarter Number',
        minWidth: 50,
        dataIndex: options.dataIndex ?? ['report_date_quarter_number'],
        renderOptions: {
            render: ({ value }) => formatQuarter(value),
        },
        width: 100,
    })
}

export function reportDateMonthName<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createField({
        ...options,
        id: options.dataIndex
            ? options.dataIndex[0]
            : 'report_date_month_number',
        name: 'Report Month Name',
        shortName: 'Report Month',
        minWidth: 50,
        dataIndex: options.dataIndex ?? ['report_date_month_number'],
        renderOptions: {
            render: ({ value }) => formatMonthName(value),
        },
        width: 100,
    })
}

export function dateYear<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createDateField({
        ...options,
        id: 'date_year',
        name: 'Year',
        shortName: 'Year',
        minWidth: 50,
        dataIndex: options.dataIndex ?? ['date_year'],
        width: 100,
        dateAggregation: 'year',
    })
}

export function dateQuarter<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createDateField({
        ...options,
        id: 'date_quarter',
        name: 'Quarter',
        shortName: 'Quarter',
        minWidth: 50,
        dataIndex: options.dataIndex ?? ['date_quarter'],
        width: 100,
        dateAggregation: 'quarter',
    })
}

export function dateMonth<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createDateField({
        ...options,
        id: 'date_month',
        name: 'Month',
        shortName: 'Month',
        minWidth: 50,
        dataIndex: options.dataIndex ?? ['date_month'],
        renderOptions: {
            render: ({ value }) => formatDate(value, 'month'),
        },
        width: 100,
        dateAggregation: 'month',
    })
}

export function dateWeek<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createDateField({
        ...options,
        id: 'date_week',
        name: 'Week',
        shortName: 'Week',
        minWidth: 50,
        dataIndex: options.dataIndex ?? ['date_week'],
        renderOptions: {
            render: ({ value }) => formatDate(value, 'week'),
        },
        width: 100,
        dateAggregation: 'week',
    })
}

export function date<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createDateField({
        ...options,
        id: 'date',
        name: 'Date',
        shortName: 'Date',
        minWidth: 50,
        dataIndex: options.dataIndex ?? ['date'],
        width: 100,
        dateFormat: 'M/DD/YYYY h:mm:ss A',
    })
}

export function dateDay<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createDateField({
        ...options,
        id: 'date_day',
        name: 'Day',
        shortName: 'Day',
        minWidth: 50,
        dataIndex: options.dataIndex ?? ['date_day'],
        renderOptions: {
            render: ({ value }) => formatDate(value, 'day'),
        },
        width: 100,
        dateAggregation: 'day',
    })
}

export function dateCreated<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const name = options.name ?? (
        <Translation>
            {(t) => t('table:fields.dateCreated.name', 'Created Date')}
        </Translation>
    )
    return createField({
        ...options,
        id: 'created_at_date',
        name,
        shortName: name,
        minWidth: 65,
        dataIndex: options.dataIndex ?? ['created_at'],
        renderOptions: options.renderOptions ?? {
            render: ({ value }) => formatDay(value),
        },
        width: 125,
    })
}

export function dateUpdated<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const name = (
        <Translation>
            {(t) => t('table:fields.dateUpdated.name', 'Last Updated')}
        </Translation>
    )
    return createField({
        ...options,
        id: 'updated_at_date',
        name,
        shortName: name,
        minWidth: 65,
        dataIndex: options.dataIndex ?? ['updated_at'],
        renderOptions: options.renderOptions ?? {
            render: ({ value }) => formatDay(value),
        },
        width: 125,
    })
}
