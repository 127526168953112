/**
 * The number of search results that are returned for a given Typeahead query
 *
 * @type {number}
 */
export const SEARCH_RESULTS_PER_QUERY = 25

/**
 * The number of default options to "pre-fetch" for Search Select components
 *
 * @type {number}
 */
export const PREFETCH_RESULTS_PER_QUERY = 25

/**
 * The minimum number of characters required before making a search request
 *
 * @type {number}
 */
export const MIN_SEARCH_CHARS = 1

/**
 * The number of milliseconds to delay invoking the input change handler since
 * it was last invoked
 *
 * @type {number}
 */
export const DEBOUNCE_TIMEOUT = 750
