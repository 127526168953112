import { PIE, BUBBLE } from 'const/widgets'
import { formatTooltip } from 'helpers/charts'
import { formatNumber } from 'helpers/formatting'
import { elementToString } from 'helpers/utilities'
import { transformToString } from 'helpers/widgets'
import { FieldMapping, Widget } from 'types'

interface PointType {
    x: number
    y?: number
    z?: number
    name?: string
    color?: string
    percentage?: number
}

interface chartTooltipFormatterParams {
    point: PointType
    widget: Widget
    metricsConfig: FieldMapping
}

const bubbleChartTooltipFormatter = ({
    point,
    widget,
    metricsConfig,
}: chartTooltipFormatterParams): string => {
    const { y, x, z } = point

    const widgetMetrics = transformToString(widget.metrics)
    const metric = widgetMetrics[0]
    const metricConfig = metricsConfig[metric]

    const tooltipData = [
        {
            label: elementToString(metricConfig?.shortName),
            value: formatNumber(y, metricConfig?.metricOptions?.format),
        },
    ]
    if (widgetMetrics.length > 1) {
        tooltipData.push({
            label: elementToString(metricsConfig[widgetMetrics[1]]?.shortName),
            value: formatNumber(
                x,
                metricsConfig[widgetMetrics[1]]?.metricOptions?.format
            ),
        })
    }
    if (widgetMetrics.length > 2) {
        tooltipData.push({
            label: elementToString(metricsConfig[widgetMetrics[2]]?.shortName),
            value: formatNumber(
                z,
                metricsConfig[widgetMetrics[2]]?.metricOptions?.format
            ),
        })
    }
    const tooltip = tooltipData
        .map((record) => formatTooltip('#000000', record.label, record.value))
        .join(' ')

    return `
            <h3 style="color: ${point.color};">${point.name}</h3>
            <div class="points">${tooltip}</div>
        `
}

const categoricalChartTooltipFormatter = ({
    point,
    widget,
    metricsConfig,
}: chartTooltipFormatterParams): string => {
    const { y } = point
    const widgetMetrics = transformToString(widget.metrics)
    const metric = widgetMetrics[0]
    const metricConfig = metricsConfig[metric]
    const countTypes = ['count', 'currencyAmount']
    const showPercent =
        metricConfig?.metricOptions?.type &&
        countTypes.includes(metricConfig.metricOptions.type)
    const getPercentage = (
        percentage: number | undefined
    ): string | undefined => {
        if (percentage === undefined) {
            return undefined
        }
        if ((!widget.chart_type || widget.chart_type === PIE) && showPercent) {
            return formatNumber(percentage / 100, '0%')
        }
        return undefined
    }
    const tooltip = formatTooltip(
        point.color,
        point.name,
        formatNumber(y, metricConfig?.metricOptions?.format),
        getPercentage(point.percentage)
    )
    return `
            <h3>${elementToString(metricConfig?.shortName ?? '')}</h3>
            <div class="points">${tooltip}</div>
        `
}

export const chartTooltipFormatter = (
    params: chartTooltipFormatterParams
): string => {
    if ((params.widget.chart_type as string) === BUBBLE) {
        return bubbleChartTooltipFormatter(params)
    }
    return categoricalChartTooltipFormatter(params)
}
