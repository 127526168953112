import { call, select, put } from 'redux-saga/effects'

import { getSignUpFormInvitationSuccess } from 'actions/auth'
import { AUTH_ORGANIZATION_PAGE } from 'const/pages'
import { cerebroApiSaga } from 'sagas/common'
import uiSagaRegistry from 'sagas/ui/registry'
import { selectDomainValue as selectAuthDomainValue } from 'selectors/auth'
import { getInvitations } from 'services/cerebroApi/noScope/resourceApi'

function* mountPageSaga() {
    const email = yield select(selectAuthDomainValue, 'email')
    const invitation = yield select(selectAuthDomainValue, 'invitation')

    if (email && !invitation) {
        // Check if user has any pending invitations
        const { data } = yield call(cerebroApiSaga, null, getInvitations, {
            state: 'pending',
            user_email: email,
        })
        const results = data?.results ?? []
        // Set invitation in state
        if (results.length > 0) {
            yield put(getSignUpFormInvitationSuccess(results[0]))
        }
    }
}

uiSagaRegistry.registerSagas([AUTH_ORGANIZATION_PAGE], {
    mountPageSaga,
})
