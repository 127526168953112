import { ReactElement } from 'react'

import { Empty, type EmptyProps } from 'antd'

import styles from './styles.scss'

const EmptyComponent = ({
    description,
    children,
    className,
}: EmptyProps): ReactElement => (
    <Empty
        className={className}
        description={<span className={styles.description}>{description}</span>}
        image={Empty.PRESENTED_IMAGE_SIMPLE}
    >
        <div className={styles.content}>{children}</div>
    </Empty>
)

export default EmptyComponent
