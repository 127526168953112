import {
    COUNTRIES,
    DATES,
    METRIC_FILTERS,
    SOV_ASINS,
    SOV_METADATA_BRANDS,
    PRODUCT_TITLES,
    ADVANCED_TEXT,
    SEGMENTS,
    ADVANCED_PRODUCT_TITLE,
    PARENT_ASIN,
} from 'const/filters'
import { calculateFromDateWithLag } from 'helpers/dateRange'
import { addToComplexTextFilter } from 'helpers/filters'
import { isNonEmptyArray } from 'helpers/typeGuard'
import { formatDateFromFilter } from 'helpers/utils'
import {
    FiltersState,
    AsinSalesEstimateFactsParams,
    FilterOption,
    MetricFilter,
} from 'types'

import { formatMetricFiltersForParams } from './metrics'

const stringifyFilterOptions = (
    options: (FilterOption | MetricFilter)[]
): string => options.map((item) => item.value).join()

export const formatFilters = (
    filters: FiltersState
): AsinSalesEstimateFactsParams => {
    let params: AsinSalesEstimateFactsParams = {}
    let complex_text_filter: any = null

    const dates = filters[DATES]
    const { start, end } = formatDateFromFilter(
        dates,
        calculateFromDateWithLag(filters.rangeLag),
        filters.rangeLag
    )
    params.report_date_min = start
    params.report_date_max = end

    const asins = filters[SOV_ASINS]
    if (isNonEmptyArray(asins)) {
        params.asin__in = stringifyFilterOptions(asins)
    }

    const parentAsin = filters[PARENT_ASIN]
    if (parentAsin) {
        params.parent_asin = parentAsin === 'none' ? null : parentAsin
    }

    const titles = filters[PRODUCT_TITLES]
    if (isNonEmptyArray(titles)) {
        params.product_id__in = stringifyFilterOptions(titles)
    }

    const brands = filters[SOV_METADATA_BRANDS]
    if (isNonEmptyArray(brands)) {
        params.brand__in = stringifyFilterOptions(brands)
    }

    const countries = filters[COUNTRIES]
    if (isNonEmptyArray(countries)) {
        params.country__in = stringifyFilterOptions(countries)
    }

    const metricFilters = filters[METRIC_FILTERS]
    if (isNonEmptyArray(metricFilters)) {
        params = { ...params, ...formatMetricFiltersForParams(metricFilters) }
    }

    const advancedText = filters[ADVANCED_TEXT]
    if (isNonEmptyArray(advancedText)) {
        complex_text_filter = addToComplexTextFilter(
            complex_text_filter,
            advancedText
        )
    }

    const segments = filters[SEGMENTS]
    if (isNonEmptyArray(segments)) {
        params.segment_id__in = stringifyFilterOptions(segments)
    }

    const advancedProductTitle = filters[ADVANCED_PRODUCT_TITLE]
    if (isNonEmptyArray(advancedProductTitle)) {
        complex_text_filter = addToComplexTextFilter(
            complex_text_filter,
            advancedProductTitle
        )
    }

    if (complex_text_filter !== null) {
        params.complex_text_filter = JSON.stringify(complex_text_filter)
    }

    return params
}
