import isEqual from 'lodash/isEqual'
import { useSelector, useDispatch } from 'react-redux'

import { makeMountTabRequest } from 'actions/ui/shared/tab'
import { selectDomainValue } from 'selectors/ui'
import { RootReduxState } from 'types'

import useDeepCompareEffect from '../useDeepCompareEffect'

interface TabStateObject {
    mounting: boolean
    loading: boolean
}

export const useTabState = (tabPath: string[]): TabStateObject => ({
    mounting: useSelector(
        (state: RootReduxState) =>
            selectDomainValue(state, [...tabPath, 'mounting']),
        isEqual
    ),
    loading: useSelector(
        (state: RootReduxState) =>
            selectDomainValue(state, [...tabPath, 'loading']),
        isEqual
    ),
})

export const useDispatchMountTab = (
    tabPath: string[],
    active: boolean,
    mounting: boolean
): void => {
    const dispatch = useDispatch()

    useDeepCompareEffect(() => {
        dispatch(makeMountTabRequest(tabPath)({ active, mounting }))
    }, [dispatch, tabPath])
}
