import { handleActions } from 'redux-actions'

import { COUNTRIES, LABELS, PRODUCT_ASINS } from 'const/filters'
import { FILTERS, FILTER_SETTINGS } from 'const/reducerKeys'
import { defaultTab } from 'reducers/ui/defaults'

const initialState = {
    ...defaultTab,
    [FILTERS]: {
        [COUNTRIES]: [],
        [LABELS]: [],
        [PRODUCT_ASINS]: [],
    },
    [FILTER_SETTINGS]: {
        anchored: [COUNTRIES, PRODUCT_ASINS, LABELS],
        order: [COUNTRIES, LABELS, PRODUCT_ASINS],
        displayState: {
            [COUNTRIES]: true,
            [LABELS]: true,
            [PRODUCT_ASINS]: true,
        },
    },
}

export default handleActions({}, initialState)
