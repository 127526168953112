import { ReactElement, useState } from 'react'

import { Modal, Select, Button, message } from 'antd'
import { AxiosResponse } from 'axios'
import { Formik, Field } from 'formik'
import { array, object, string } from 'yup'

import { FormikSelect } from 'components/formik'
import { TextButton } from 'components/TextButton'
import { useCerebroApiRequest } from 'hooks'
import { Label } from 'types'

import { CreateLabelModal } from '../CreateLabelModal'

interface FormValues {
    label_ids: string[]
}

interface Props {
    isModalVisible: boolean
    closeModal: () => void
    labelOptions: Label[]
    addLabelsRequest: (values: FormValues) => Promise<AxiosResponse>
    onSuccess: (response: AxiosResponse) => void
}

const AddLabelsModal = ({
    isModalVisible,
    labelOptions,
    closeModal,
    addLabelsRequest,
    onSuccess,
}: Props): ReactElement => {
    const makeCerebroApiRequest = useCerebroApiRequest()
    const [isCreateModalVisible, setCreateModalVisible] = useState(false)

    const validationSchema = object({
        label_ids: array(string()).required(
            'Please select one or more labels to add.'
        ),
    })

    return (
        <Formik
            enableReinitialize
            initialValues={{
                label_ids: [],
            }}
            validationSchema={validationSchema}
            onSubmit={async (values: FormValues, { resetForm }) => {
                await makeCerebroApiRequest({
                    request: addLabelsRequest(values),
                    onRequestSuccess: (response) => {
                        resetForm()
                        closeModal()
                        onSuccess(response)
                        message.success(
                            `Successfully added ${
                                values.label_ids.length === 1
                                    ? 'label'
                                    : 'labels'
                            }`
                        )
                    },
                })
            }}
        >
            {({
                handleSubmit,
                values,
                setFieldValue,
                isSubmitting,
                resetForm,
            }) => (
                <>
                    <Modal
                        open={isModalVisible}
                        title="Add Labels"
                        onCancel={() => {
                            closeModal()
                            resetForm()
                        }}
                        footer={
                            <div className="d-flex justify-content-between align-items-center">
                                <TextButton
                                    link
                                    onClick={() => setCreateModalVisible(true)}
                                >
                                    Create New Label
                                </TextButton>

                                <div>
                                    <Button
                                        onClick={() => {
                                            closeModal()
                                            resetForm()
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        className="ml-2"
                                        type="primary"
                                        loading={isSubmitting}
                                        disabled={isSubmitting}
                                        onClick={() => {
                                            handleSubmit()
                                        }}
                                    >
                                        Add
                                    </Button>
                                </div>
                            </div>
                        }
                    >
                        <Field
                            name="label_ids"
                            placeholder="Search labels..."
                            component={FormikSelect}
                            mode="multiple"
                            optionFilterProp="children"
                            style={{ width: '100%' }}
                        >
                            {labelOptions.map((label) => (
                                <Select.Option key={label.id} value={label.id}>
                                    {label.name}
                                </Select.Option>
                            ))}
                        </Field>
                    </Modal>
                    <CreateLabelModal
                        isModalVisible={isCreateModalVisible}
                        closeModal={() => setCreateModalVisible(false)}
                        onSuccess={(response) =>
                            setFieldValue('label_ids', [
                                ...values.label_ids,
                                response.data.id,
                            ])
                        }
                    />
                </>
            )}
        </Formik>
    )
}

export default AddLabelsModal
