import { Translation } from 'react-i18next'

import {
    memberTypeRenderer,
    removeFromOrganizationRenderer,
} from 'components/FieldRenderers'
import { Field, Member } from 'types'

import { createActionField, createField } from './createField'
import { FieldCreatorOptions } from './localTypes'

export function organizationMemberEmail<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['email']
    const name = (
        <Translation>
            {(t) =>
                t('table:fields.organizationMemberEmail.name', 'Email Address')
            }
        </Translation>
    )
    return createField({
        ...options,
        id: 'organization_member_email',
        name,
        shortName: name,
        minWidth: 200,
        width: 300,
        dataIndex,
    })
}

export function organizationMemberType<RecordType extends Member>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['member_type']
    return createField({
        ...options,
        id: 'organization_member_type',
        name: 'Member Type',
        shortName: 'Member Type',
        minWidth: 150,
        dataIndex,
        renderOptions: {
            render: (cellRenderProps) =>
                memberTypeRenderer({ cellRenderProps, dataIndex }),
        },
    })
}

export function removeFromOrganization<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createActionField({
        ...options,
        id: 'remove_member_from_organization',
        dataIndex: [],
        isResizeable: false,
        minWidth: 68,
        renderOptions: options.renderOptions ?? {
            render: (props) => removeFromOrganizationRenderer<any>(props),
        },
        sorter: false,
        fixed: 'right',
        align: 'center',
    })
}
