import { combineChildrenReducers } from 'utilities/reducers'

import changelogReducer from './changelog'
import customEventsReducer from './custom'
import pageReducer from './page'

export default combineChildrenReducers(pageReducer, {
    custom: customEventsReducer,
    changelog: changelogReducer,
})
