import numeral from 'numeral'

import { CURRENCIES } from 'configuration/currency'

const delimiters = {
    thousands: ',',
    decimal: '.',
}

const abbreviations = {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
}

const ordinal = (number) => {
    const b = number % 10
    if ((number % 100) / 10 === 1) {
        return 'th'
    }
    if (b === 1) {
        return 'st'
    }
    if (b === 2) {
        return 'nd'
    }
    if (b === 3) {
        return 'rd'
    }
    return 'th'
}

Object.entries(CURRENCIES).forEach((each) => {
    const [code, { symbol }] = each
    numeral.register('locale', code, {
        delimiters,
        abbreviations,
        ordinal,
        currency: {
            symbol,
        },
    })
})

export default numeral
