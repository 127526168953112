import { useCallback } from 'react'

import { shallowEqual, useSelector } from 'react-redux'

import {
    makeUpdateListSorter,
    makeFetchListRequest,
    makeDownloadListRequest,
} from 'actions/ui/shared'
import { selectDomainValue } from 'selectors/ui'
import { selectListSettings } from 'selectors/ui/list'
import { List, Path, RootReduxState, Sorter } from 'types'

import { useAction } from './useAction'

interface ListDispatch {
    updateSorter: (sorter: Sorter) => void
    reloadData: (options?: { noCount?: boolean }) => void
    downloadData: () => void
}

export const useListDispatch = (listPath: Path): ListDispatch => ({
    updateSorter: useCallback(
        useAction<Sorter>(makeUpdateListSorter(listPath)),
        [listPath]
    ),
    reloadData: useCallback(
        useAction<{ noCount?: boolean }>(makeFetchListRequest(listPath)),
        [listPath]
    ),
    downloadData: useCallback(
        useAction<{ noCount?: boolean }>(makeDownloadListRequest(listPath)),
        [listPath]
    ),
})

export const useListState = <T>(
    listPath: Path
): Omit<List<T>, 'listSettings'> & List<T>['listSettings'] => ({
    data: useSelector(
        (state: RootReduxState) =>
            selectDomainValue(state, [...listPath, 'data']),
        shallowEqual
    ),
    loading: useSelector(
        (state: RootReduxState) =>
            selectDomainValue(state, [...listPath, 'loading']),
        shallowEqual
    ),
    error: useSelector(
        (state: RootReduxState) =>
            selectDomainValue(state, [...listPath, 'error']),
        shallowEqual
    ),
    downloading: useSelector(
        (state: RootReduxState) =>
            selectDomainValue(state, [...listPath, 'downloading']),
        shallowEqual
    ),
    sorter: useSelector(
        (state: RootReduxState) => selectListSettings(state, listPath).sorter,
        shallowEqual
    ),
})
