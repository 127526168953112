import { ReactElement } from 'react'

import { FormikHelpers } from 'formik'
import { useTranslation } from 'react-i18next'

import { ENABLED } from 'const/resourceStates'
import { WALMART_AD_ITEM_BID_MIN } from 'const/walmart'
import { useAuthDomainValue, useCerebroApiRequest } from 'hooks'
import { createWalmartAdItem } from 'services/cerebroApi/orgScope/walmartApi'
import { WalmartAdGroup, WalmartAdItem, WalmartAdvertiser } from 'types'
import { getBulkCreationShapeValidatorForAdGroup } from 'types/schemaShapeValidators/walmartAdItem'
import displayMessage from 'utilities/message'

import { FormValues, AdItemField } from './localTypes'
import { CreateAdItemModal } from '../CreateAdItemModal'
import { getWalmartAdGroupAdItemCreationColumnsConfig } from '../Shared/CreateAdItemModalColumnsConfig'

interface Props {
    adGroup: WalmartAdGroup
    isModalVisible: boolean
    onModalCancel: () => void
    toggleModalVisible: () => void
    reloadData: () => void
}

const CreateWalmartAdGroupAdItemsModal = ({
    adGroup,
    isModalVisible,
    onModalCancel,
    reloadData,
    toggleModalVisible,
}: Props): ReactElement => {
    const { t } = useTranslation()
    const makeCerebroApiRequest = useCerebroApiRequest()
    const { advertiser_id } = useAuthDomainValue<WalmartAdvertiser>(
        'latestWalmartAdvertiser'
    )

    const closeModal = (callback: any): void => {
        callback()
        toggleModalVisible()
        reloadData()
    }

    const submitForm = async (
        { adItems }: FormValues,
        { resetForm }: FormikHelpers<FormValues>
    ): Promise<void> => {
        const ads: Partial<WalmartAdItem>[] = adItems.map(
            (adItem) =>
                ({
                    item_id: adItem.itemId,
                    ad_group_id: adGroup.id,
                    advertiser_id,
                    campaign_id: adGroup.campaign_id,
                    state: adItem.state,
                    bid: adItem.bid,
                }) as WalmartAdItem
        )
        await makeCerebroApiRequest({
            request: createWalmartAdItem({
                items: ads,
            }),
            onRequestSuccess: (response) => {
                if (response.status === 207) {
                    const updated =
                        'successful_updates' in response.data
                            ? response.data.successful_updates
                            : [response.data]
                    displayMessage.success(
                        t(
                            'table:fields.walmartAdItem.creation.partialSuccessMsg',
                            'Successfully created {{successfulCount}} out of {{adItemsTotal}} Ad Items.',
                            {
                                successfulCount: updated.length,
                                adItemsTotal: adItems.length,
                            }
                        )
                    )
                } else {
                    displayMessage.success(
                        t(
                            'table:fields.walmartAdItem.creation.successMsg',
                            'Successfully created {{count}} Ad Item',
                            {
                                count: adItems.length,
                            }
                        )
                    )
                }

                closeModal(resetForm)
            },
        })
    }

    const initialValues: FormValues = { adItems: [] }

    const serializeAdItemField = (itemId: string): AdItemField => {
        return {
            itemId,
            state: ENABLED,
            bid: WALMART_AD_ITEM_BID_MIN,
        }
    }

    return (
        <CreateAdItemModal<AdItemField>
            isModalVisible={isModalVisible}
            onModalCancel={onModalCancel}
            toggleModalVisible={toggleModalVisible}
            columnsConfig={getWalmartAdGroupAdItemCreationColumnsConfig(
                t,
                adGroup.campaign
            )}
            initialValues={initialValues}
            validationSchema={getBulkCreationShapeValidatorForAdGroup(
                adGroup.campaign.targeting_type
            )}
            submitForm={submitForm}
            serializeAdItemField={serializeAdItemField}
        />
    )
}

export default CreateWalmartAdGroupAdItemsModal
