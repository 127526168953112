import { ReactElement, ReactNode, useState } from 'react'

import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import classnames from 'classnames'
import { useSelector } from 'react-redux'

import { selectIsSidebarCollapsed } from 'selectors/ui'
import { RootReduxState } from 'types'

import styles from './styles.scss'

interface Props {
    topOffset: number
    className?: string
    children: ReactNode
}

const STICKY_PADDING_Y = 10

const StickyHeader = ({
    topOffset = 0,
    className,
    children,
}: Props): ReactElement | null => {
    const [visible, setVisible] = useState(false)
    const isSidebarCollapsed = useSelector((state: RootReduxState) =>
        selectIsSidebarCollapsed(state)
    )

    useScrollPosition(({ currPos }: { currPos: { x: number; y: number } }) => {
        setVisible(currPos.y < -topOffset + STICKY_PADDING_Y)
    })

    return (
        <div
            className={classnames([styles.base, className], {
                [styles.visible]: visible,
                [styles['sidebar-collapsed']]: isSidebarCollapsed,
            })}
            style={{
                paddingTop: STICKY_PADDING_Y,
                paddingBottom: STICKY_PADDING_Y,
            }}
        >
            {children}
        </div>
    )
}

export default StickyHeader
