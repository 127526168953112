import { ReactElement } from 'react'

import { Select, Spin } from 'antd'

import { SEARCH_RESULTS_PER_QUERY } from 'configuration/typeahead'
import { formatBrandName } from 'helpers/formatting'
import { useTypeahead } from 'hooks'
import { getAdAccounts } from 'services/cerebroApi/orgScope/resourceApi'
import { Brand } from 'types'

interface Props {
    value?: string
    onChange: (value: string) => void
}

const BrandSearchSelect = ({ value, onChange }: Props): ReactElement => {
    const [options, loading, onSearch, resetLoadingState] = useTypeahead<Brand>(
        {
            apiSearchFunc: (query) =>
                getAdAccounts(
                    {
                        limit: SEARCH_RESULTS_PER_QUERY,
                        brand_name__similar: query,
                    },
                    { headers: { noCount: true } }
                ),
            optionFormatter: (brand) => ({
                value: brand.id,
                label: formatBrandName(brand),
            }),
        }
    )

    return (
        <Select
            filterOption={false}
            placeholder="Search Advertising Accounts..."
            loading={loading}
            notFoundContent={loading ? <Spin size="small" /> : null}
            onChange={onChange}
            onSearch={onSearch}
            onSelect={resetLoadingState}
            showSearch
            style={{ width: '100%' }}
            value={value}
        >
            {options.map((option) => (
                <Select.Option key={option.value} value={option.value}>
                    {option.label}
                </Select.Option>
            ))}
        </Select>
    )
}

export default BrandSearchSelect
