import { Translation } from 'react-i18next'

import { Field } from 'types'

import { createField, createTitleField } from './createField'
import { FieldCreatorOptions } from './localTypes'

export function asyncUpdateDate<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['updated_date']
    const name = <Translation>{(t) => t('common:date', 'Date')}</Translation>

    return createField({
        ...options,
        id: 'updated_date',
        name,
        shortName: name,
        dataIndex,
        minWidth: 100,
        width: 200,
    })
}

export function asyncUpdateDescription<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const name = (
        <Translation>
            {(t) => t('common:description', 'Description')}
        </Translation>
    )
    return createField({
        ...options,
        id: 'name',
        name,
        shortName: name,
        dataIndex: options.dataIndex ?? ['id'],
        minWidth: 100,
        sorter: false,
    })
}

export function asyncUpdateSourceType<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['source_type']
    const name = (
        <Translation>{(t) => t('common:source', 'Source')}</Translation>
    )

    return createTitleField({
        ...options,
        id: 'source_type',
        name,
        shortName: name,
        dataIndex,
        minWidth: 80,
        emptyValue: '',
        width: 80,
        sorter: false,
        fixed: options.fixed ?? false,
    })
}

export function asyncUpdateCount<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['count']
    const name = (
        <Translation>
            {(t) => t('common:recordCount', 'Record Count')}
        </Translation>
    )
    return createField({
        ...options,
        id: 'asyncUpdateCount',
        name,
        shortName: (
            <Translation>{(t) => t('common:count', 'Count')}</Translation>
        ),
        dataIndex,
        minWidth: 60,
        width: 80,
        sorter: false,
        fixed: options.fixed ?? false,
    })
}

export function asyncUpdateType<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['type']
    const name = (
        <Translation>
            {(t) => t('common:recordType', 'Record Type')}
        </Translation>
    )
    return createField({
        ...options,
        id: 'type',
        name,
        shortName: name,
        dataIndex,
        minWidth: 100,
        width: 120,
        sorter: options.sorter ?? false,
    })
}

export function asyncUpdateStatus<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    const dataIndex = options.dataIndex ?? ['state']
    const name = (
        <Translation>{(t) => t('common:status', 'Status')}</Translation>
    )
    return createField({
        ...options,
        id: 'state',
        name,
        shortName: name,
        dataIndex,
        minWidth: 80,
        width: 120,
        sorter: options.sorter ?? false,
    })
}

export function asyncUpdateActions<RecordType>(
    options: FieldCreatorOptions<RecordType> = {}
): Field<RecordType> {
    return createField({
        ...options,
        id: 'asyncUpdateActions',
        name: '',
        shortName: '',
        dataIndex: options.dataIndex ?? ['id'],
        minWidth: 150,
        width: 68,
        sorter: false,
        fixed: 'right',
        align: 'center',
    })
}
