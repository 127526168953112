import { ReactNode } from 'react'

import { ReportsDownloadField } from 'components/EditableFields'
import { Report, CellRenderProps } from 'types'

export function reportNameRenderer<RecordType extends Report>({
    value,
    record,
}: CellRenderProps<RecordType>): ReactNode {
    return (
        <>
            <span className="fg-default-tag">{record.type}</span>
            {value}
        </>
    )
}

export function reportActionsRenderer<RecordType extends Report>({
    record,
}: CellRenderProps<RecordType>): ReactNode {
    return <ReportsDownloadField record={record} />
}
