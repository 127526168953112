import { ReactElement } from 'react'

import classNames from 'classnames'
import { Link } from 'react-router-dom'

import { WhitelabelLogoIcon } from 'components/Icons'
import { HOME_PAGE } from 'const/pages'
import { getOrganizationTheme } from 'helpers/organizationTheme'
import { getPath } from 'helpers/pages'
import CobaltLogoHorizontalCompressed from 'images/logo/CobaltLogoHorizontalCompressed'
import JsLogo from 'images/logo/JsLogo'
import { Organization } from 'types'

import styles from './styles.scss'

interface Props {
    currentOrganization?: Organization
    isSidebarCollapsed: boolean
}

const Branding = ({
    currentOrganization,
    isSidebarCollapsed,
}: Props): ReactElement => {
    const orgTheme = getOrganizationTheme(currentOrganization)
    const whiteLabelLogoUrl = orgTheme?.logo_url
    const whiteLabelLogoCondensedUrl = orgTheme?.logo_url_condensed

    return (
        <div className={styles['branding-container']}>
            <Link to={getPath(HOME_PAGE)}>
                {whiteLabelLogoUrl ? (
                    <div className="d-flex flex-column">
                        <div className={styles['ds-logo-container-cobalt']}>
                            {isSidebarCollapsed ? (
                                <JsLogo
                                    fill="#FFF"
                                    className={styles['logo-icon']}
                                    style={{ opacity: '70%', height: 30 }}
                                />
                            ) : (
                                <CobaltLogoHorizontalCompressed
                                    fill="#FFF"
                                    className={styles['logo-icon']}
                                    style={{ opacity: '70%', height: 24 }}
                                />
                            )}
                        </div>
                        <div
                            className={classNames(
                                styles.divider,
                                styles['full-width']
                            )}
                        />
                        <div className={styles['whitelabel-logo-container']}>
                            <WhitelabelLogoIcon
                                src={
                                    isSidebarCollapsed &&
                                    whiteLabelLogoCondensedUrl
                                        ? whiteLabelLogoCondensedUrl
                                        : whiteLabelLogoUrl
                                }
                                alt={currentOrganization?.name ?? ''}
                            />
                        </div>
                    </div>
                ) : (
                    <>
                        <div className={styles['ds-logo-container-cobalt']}>
                            {isSidebarCollapsed ? (
                                <JsLogo
                                    fill="#FFF"
                                    className={styles['logo-icon']}
                                    style={{ height: 30 }}
                                />
                            ) : (
                                <CobaltLogoHorizontalCompressed
                                    fill="#FFF"
                                    className={styles['logo-icon']}
                                    style={{ height: 24 }}
                                />
                            )}
                        </div>
                        <div
                            className={classNames(
                                styles.divider,
                                styles['full-width']
                            )}
                        />
                    </>
                )}
            </Link>
        </div>
    )
}

export default Branding
