import { ReactElement } from 'react'

import { Select } from 'antd'
import { Field } from 'formik'

import { FormikSelect } from 'components/formik'
import { SelectOptionContent } from 'components/SelectOptionContent'
import { AdGroup } from 'types'

import { useAdGroupOptions } from './localHooks'
import { AdGroupSelectFieldProps } from './localTypes'

interface OptionMetadata {
    disabled: boolean
    metadata: string
}

const getOptionMetadata = (_adGroup: AdGroup): OptionMetadata => {
    return { disabled: false, metadata: '' }
}

const AdGroupForSearchTermNegativeKeywordSelectField = ({
    name,
    campaignId,
    disabled = false,
    style = { width: '100%' },
    isOptionDisabled = (option) => getOptionMetadata(option).disabled,
    optionMetadata = (option) => getOptionMetadata(option).metadata,
}: AdGroupSelectFieldProps): ReactElement => {
    const [loading, options] = useAdGroupOptions({
        name,
        campaignId,
        isOptionDisabled,
        optionMetadata,
    })

    return (
        <Field
            name={name}
            component={FormikSelect}
            placeholder="Select Target Ad Group"
            style={style}
            loading={loading}
            disabled={disabled || loading}
        >
            {options.map((option, idx) => (
                <Select.Option
                    key={idx}
                    value={option.value}
                    title={option.label}
                    disabled={option.disabled}
                >
                    <SelectOptionContent
                        label={option.label}
                        metadata={option.metadata}
                    />
                </Select.Option>
            ))}
        </Field>
    )
}

export default AdGroupForSearchTermNegativeKeywordSelectField
