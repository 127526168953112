import { ReactElement } from 'react'

import { Select, Spin } from 'antd'

import { SEARCH_RESULTS_PER_QUERY } from 'configuration/typeahead'
import { useTypeahead } from 'hooks'
import { getProducts } from 'services/cerebroApi/orgScope/resourceApi'
import { Product } from 'types'

interface Props {
    value?: string
    onChange: (value: string) => void
}

const ProductSearchSelect = ({ value, onChange }: Props): ReactElement => {
    const [options, loading, onSearch, resetLoadingState] =
        useTypeahead<Product>({
            apiSearchFunc: (query) =>
                getProducts(
                    {
                        limit: SEARCH_RESULTS_PER_QUERY,
                        asin__istartswith: query,
                    },
                    { headers: { noCount: true } }
                ),
            optionFormatter: (product) => ({
                value: product.id,
                label: `${product.asin} (${product.marketplace})`,
                metadata: product.product_metadata?.title ?? '',
            }),
        })

    return (
        <Select
            filterOption={false}
            optionLabelProp="title"
            placeholder="Search Products by ASIN..."
            loading={loading}
            notFoundContent={loading ? <Spin size="small" /> : null}
            onChange={onChange}
            onSearch={onSearch}
            onSelect={resetLoadingState}
            showSearch
            style={{ width: '100%' }}
            value={value}
        >
            {options.map((option) => (
                <Select.Option
                    key={option.value}
                    value={option.value}
                    title={option.label}
                >
                    <b>{option.label}</b> <i>({option.metadata})</i>
                </Select.Option>
            ))}
        </Select>
    )
}

export default ProductSearchSelect
