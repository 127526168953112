import { memo, ReactElement } from 'react'

import isEqual from 'lodash/isEqual'
import { useTranslation } from 'react-i18next'

import { useResource } from 'components/EditableFields/localHooks'
import { HEADLINE_SEARCH } from 'const/factTypes'
import { updateCampaign } from 'services/cerebroApi/orgScope/resourceApi'
import { CampaignGroupBy } from 'types'

import { DateField } from '../../SharedFields/DateField'

type CampaignRecord = CampaignGroupBy

interface Props<RecordType> {
    dataIndex: string[]
    readOnly: boolean
    record: RecordType
}

function CampaignEndDateField<RecordType extends CampaignRecord>({
    dataIndex = ['campaign', 'end_date'],
    readOnly = false,
    record,
}: Props<RecordType>): ReactElement {
    const { fieldName, getFromResource, resourceId } = useResource(
        record,
        dataIndex,
        'campaign'
    )
    const { t } = useTranslation(['campaigns', 'common'])

    const getIsDisabled = (): boolean => {
        const isHeadlineSearch =
            getFromResource(['campaign_type']) === HEADLINE_SEARCH
        const userCanManageCampaign = getFromResource(['user_can_manage'], true)
        return isHeadlineSearch || !userCanManageCampaign
    }

    return (
        <DateField
            disabled={getIsDisabled()}
            fieldName={fieldName}
            fieldPath={dataIndex}
            placeholder={t('common:endDate', 'End Date')}
            readOnly={readOnly}
            required={false}
            record={record}
            updateRequestApi={updateCampaign}
            updateRequestData={resourceId}
            updateRequestSuccessMesg={t(
                'campaigns:successfulRequestMsg.updateEndDate',
                'Successfully updated campaign end date'
            )}
        />
    )
}

export default memo(
    CampaignEndDateField,
    isEqual
) as typeof CampaignEndDateField
